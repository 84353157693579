import React from "react";
import InputStandard from "components/Input/InputStandard";
import SelectStandard from "apps/broker/components/Select";
import estadosBrasileiros from "utils/arrays/estadosBrasileiros";
import maskCep from "utils/inputMasks/maskCep";
import maskData from "utils/inputMasks/maskData";
import removeMask from "utils/inputMasks/removeMask";
import { AbaDadosEnderecoWrapper } from "./styles-abaDadosEndereco";

const AbaDadosEndereco = (
  { values,
    setInputValue,
    setSelectValue,
    camposInclusao
  }) => {


  const optionsUf = estadosBrasileiros.map((item) => ({
    option: item.nome, value: item.uf
  }));

  const resetInputEndereco = () => {
    const eventInputEndereco = {
      target: {
        name: 'endereco',
        value: ''
      }
    }

    const eventInputBairro = {
      target: {
        name: 'bairro',
        value: '',
      }
    }

    const eventInputUf = {
      target: {
        name: 'uf',
        value: ''
      }
    }

    const eventInputCidade = {
      target: {
        name: 'cidade',
        value: '',
      }
    }

    setInputValue(eventInputEndereco);
    setInputValue(eventInputBairro);
    setInputValue(eventInputUf);
    setInputValue(eventInputCidade);
  }

  const setInputCep = (e) => {
    setInputValue(e)

    const unmaskedCep = removeMask(e.target.value)
      // console.log("mudou input")
      if (unmaskedCep.length === 8) {
        const url = `https://viacep.com.br/ws/${unmaskedCep}/json/`;

        fetch(url)
          .then((response) => response.json())
          .then((response) => {
            if ('erro' in response) {
              resetInputEndereco()
              return;
            } else {
              const endereco = response?.logradouro;
              const bairro = response?.bairro;
              const uf = response?.uf;
              const cidade = response?.localidade;

              const eventInputEndereco = {
                target: {
                  name: 'endereco',
                  value: endereco
                }
              }

              const eventInputBairro = {
                target: {
                  name: 'bairro',
                  value: bairro,
                }
              }

              const eventInputUf = {
                target: {
                  name: 'uf',
                  value: uf
                }
              }

              const eventInputCidade = {
                target: {
                  name: 'cidade',
                  value: cidade
                }
              }

              setInputValue(eventInputEndereco);
              setInputValue(eventInputBairro);
              setInputValue(eventInputUf);
              setInputValue(eventInputCidade);
            }
          })
      } else {
        resetInputEndereco()
        return
      }
  }

  // React.useEffect(() => {
  //   const cep = document.querySelector("input[name=cep")
  //   cep.addEventListener('change', ({ target }) => {
  //     const unmaskedCep = removeMask(target.value)
  //     console.log("mudou input")
  //     if (unmaskedCep.length === 8) {
  //       const url = `https://viacep.com.br/ws/${unmaskedCep}/json/`;

  //       fetch(url)
  //         .then((response) => response.json())
  //         .then((response) => {
  //           if ('erro' in response) {
  //             resetInputEndereco()
  //             return;
  //           } else {
  //             const endereco = response?.logradouro;
  //             const bairro = response?.bairro;
  //             const uf = response?.uf;
  //             const cidade = response?.localidade;

  //             const eventInputEndereco = {
  //               target: {
  //                 name: 'endereco',
  //                 value: endereco
  //               }
  //             }

  //             const eventInputBairro = {
  //               target: {
  //                 name: 'bairro',
  //                 value: bairro,
  //               }
  //             }

  //             const eventInputUf = {
  //               target: {
  //                 name: 'uf',
  //                 value: uf
  //               }
  //             }

  //             const eventInputCidade = {
  //               target: {
  //                 name: 'cidade',
  //                 value: cidade
  //               }
  //             }

  //             setInputValue(eventInputEndereco);
  //             setInputValue(eventInputBairro);
  //             setInputValue(eventInputUf);
  //             setInputValue(eventInputCidade);
  //           }
  //         })
  //     } else {
  //       resetInputEndereco()
  //       return
  //     }
  //   })
  // }, [])

  return (
    <AbaDadosEnderecoWrapper>
      <div className="aba-container">
        <div className="aba-title-container">
          <span>2. Dados de Endereço</span>
        </div>
        <div className="aba-input-container">
          <div className="aba-input-row-endereco">
            {
              camposInclusao['cep'] ? (
                <InputStandard
                  value={values['cep']}
                  setValue={setInputCep}
                  label={"CEP"}
                  name="cep"
                  maskFunction={maskCep}
                  maxLength={8}
                />
              ) : ""
            }
            {
              camposInclusao['endereco'] ? (
                <InputStandard
                  label="Endereço"
                  value={values['endereco']}
                  setValue={setInputValue}
                  name="endereco"
                />
              ) : ""
            }
          </div>
          <div className="aba-input-row-complemento">
            {
              camposInclusao['numero_endereco'] ? (
                <InputStandard
                  value={values['numero_endereco']}
                  setValue={setInputValue}
                  label={"N.º do endereço"}
                  name="numero_endereco"
                />
              ) : ""
            }
            {
              camposInclusao['complemento_endereco'] ? (
                <InputStandard
                  value={values['complemento_endereco']}
                  setValue={setInputValue}
                  label={"Complemento"}
                  name="complemento_endereco"
                />
              ) : ""
            }
            {
              camposInclusao['bairro'] ? (
                <InputStandard
                  value={values['bairro']}
                  setValue={setInputValue}
                  label={"Bairro"}
                  name="bairro"
                />
              ) : ""
            }
          </div>
          <div className="aba-input-last-row">
            {
              camposInclusao['uf'] ? (
                <SelectStandard
                  value={values['uf']}
                  setValue={setSelectValue}
                  label={"UF"}
                  name="uf"
                  options={optionsUf}
                />
              ) : ""
            }
            {
              camposInclusao['cidade'] ? (
                <InputStandard
                  value={values['cidade']}
                  setValue={setInputValue}
                  label={"Cidade"}
                  name="cidade"
                />
              ) : ""
            }

          </div>
        </div>
      </div>
    </AbaDadosEnderecoWrapper>
  )
}

export default AbaDadosEndereco;
