import styled from 'styled-components';
import colors from 'apps/broker/styles/colors';

export const Section = styled.section`
  border-top: 1px solid #ccc;
  margin: 1rem 0;
  padding: 1rem 0;
  padding-left: 59px;
  display: grid;
  grid-template-columns: 1fr 2fr;

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;

    input {
      min-width: 230px;
      border: 2px solid ${colors.genoaBlue};
      border-radius: 20px;
      padding: 0.25rem 0.5rem;
    }
  }

  p {
    > span {
      font-family: 'Avenir Next GEO W05 Bold';
    }
  }

  .send-infos {
    background: ${colors.genoaBlue};
    padding: 0.5rem;
    color: #fff;
    border: none;
    border-radius: 0.5rem;
    font-family: 'Avenir Next GEO W05 Demi';
    cursor: pointer;
    transition: filter 0.2s;
    margin-top: 5px;

    &:hover {
      filter: contrast(120%);
    }
  }

  .align-left {
    align-items: flex-start !important;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  .infos-inclusion-beneficiary {
    align-items: center;

    .type-plan {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      position: relative;

      span.select-type {
        color: ${colors.genoaBlue} !important;
      }

      .select-type,
      .icon-type-plan {
        color: #c2c2c2;
        font-size: 1.25rem;
        cursor: pointer;

        span {
          visibility: hidden;
          width: max-content;
          background-color: ${colors.genoaBlue};
          color: #fff;
          font-size: 0.75rem;
          text-align: center;
          padding: 0.25rem;
          border-radius: 0.25rem;

          position: absolute;
          top: -100%;
          margin-left: -40px;
          z-index: 999;
        }

        &:hover {
          span {
            visibility: visible;
          }
        }
      }
    }

    .name-type-contract {
      color: ${colors.genoaBlue};
      font-family: 'Avenir Next GEO W05 Bold';

      .contrato-icon {
        margin-right: 5px;
      }
    }

    .box-show-infos-plans {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: center;
      gap: 0.75rem;
    }
  }

  .first-column-container {
    font-size: 0.9rem;
    color: ${colors.genoaGrey};

    .paragraph-info {
      margin-top: 3px;
    }

    .input-info {
      margin-top: 8px;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
      background-color: white;
      color: ${colors.genoaBlue};
      font-weight: bold;
    }

    .react-datepicker__day--selected,
    .react-datepicker__month-text--selected,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__year-text--selected {
      border-radius: 0.3rem;

      background-color: ${colors.genoaBlue};
      color: #fff;
    }

    .plano-title-span {
      margin-right: 5px;
    }
  }
`;
