import { CircularProgress } from '@material-ui/core';

import { Wrapper } from './styles';
import TicketMessages from 'apps/broker/pages/TicketsManage/components/TicketMessages';
import DialogConfirmation from 'apps/broker/pages/TicketsManage/components/DialogConfirmation';
import DialogEditOrReopen from 'apps/broker/pages/TicketsManage/components/DialogEditOrReopen';

const MovimentDetails = ({
  open,
  setOpen,
  setOpenEdit,
  loadingDetalhes,
  confirm,
  reopen,
  status,
  setStatus,
  openEdit,
  data,
  getData,
  details,
  messages,
  setMessages,
  handleReopen,
  handleCloseDetalhes,
  closeTicket,
  handleClickConfirm,
  handleCloseConfirm,
  archiveTicket,
  handleCloseReopen,
  reopenTicket,
  handleChangeStatus
}) => {

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleEdit = () => {
    setOpenEdit((prevState) => !prevState);
  };

  return (
    <Wrapper>
      {!loadingDetalhes ? (
        <TicketMessages
          data={data}
          getData={getData}
          setOpen={handleClickOpen}
          details={details}
          messages={messages}
          setMessages={setMessages}
          handleEdit={handleEdit}
          handleClickConfirm={handleClickConfirm}
          handleReopen={handleReopen}
        />
      ) : (
        <div className="circular-spinner-container-detalhes">
          <CircularProgress
            className="circular-spinner-detalhes"
            size={100}
            thickness={2.5}
          />
          <span>Carregando...</span>
        </div>
      )}
      <DialogConfirmation
        open={open}
        onClose={handleCloseDetalhes}
        question={'Confirma o encerramento do ticket?'}
        confirm={closeTicket}
      />

      <DialogConfirmation
        open={confirm}
        onClose={handleCloseConfirm}
        question={'Confirma o arquivamento do ticket?'}
        confirm={archiveTicket}
      />

      <DialogEditOrReopen
        open={reopen}
        onClose={handleCloseReopen}
        question={'Confirma a reabertura do ticket?'}
        status={status}
        setStatus={setStatus}
        confirm={reopenTicket}
      />

      <DialogEditOrReopen
        open={openEdit}
        onClose={handleEdit}
        question={'Alterar o status do ticket'}
        status={status}
        setStatus={setStatus}
        confirm={handleChangeStatus}
      />
    </Wrapper>
  );
};

export default MovimentDetails;
