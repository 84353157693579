import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import api from "services/api";

import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";

import { ContainerModal } from "./styles";
import CloseIcon from "@material-ui/icons/Close";
import { BiPlus } from "react-icons/bi";

const toolbar = {
  options: ["inline", "blockType", "fontSize", "list", "textAlign", "link"],
  inline: { inDropdown: true },
  list: { inDropdown: true },
  textAlign: { inDropdown: true },
  link: { inDropdown: true },
  history: { inDropdown: true },
};

const editorStyle = {
  border: "1.5px solid rgb(110,91,205)",
  borderRadius: "8px",
  height: "120px",
};

const NewTicket = (props) => {
  const [corretoras, setCorretoras] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [files, setFiles] = useState([]);
  const [errorEmptyMsg, setErrorEmptyMsg] = useState(false);
  const [success, setSuccess] = useState(false);
  const [submittedData, setSubmittedData] = useState({});

  const user = useSelector((state) => state.userReducers.user);

  const {
    register,
    handleSubmit,
    formState,
    reset,
    formState: { errors },
  } = useForm();

  const history = useHistory();

  const onSubmit = ({ assunto, corretora }) => {
    const text = convertToRaw(editorState.getCurrentContent()).blocks[0].text;
    if (text.length === 0) {
      setErrorEmptyMsg(true);
      return;
    }
    const htmlMessage = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );

    const formData = new FormData();

    formData.append("assunto", assunto);
    formData.append("message", htmlMessage);
    files.forEach((file, index) => {
      formData.append(index, file);
    });

    const headers = {
      corretora: corretora,
      estipulante: user.estipulante,
      uuid: user.uuid,
      authorization: `Bearer ${user.token}`,
    };

    api
      .post("/rh/create-ticket", formData, { headers: headers })
      .then((response) => {
        if (props.getTickets) {
          props.getTickets();
        }

        setSuccess(true);
        setSubmittedData({ assunto });
        setEditorState(EditorState.createEmpty());
        history.push("/rh/tickets");
      })
      .catch((err) => {
        console.log(err);
      });

  };

  const handleFile = (target) => {
    if (target.files?.length === 0 || target.files === null) {
      return;
    }
    if (target.files.length > 1) {
      const newFiles = [];
      Array.from(target.files).forEach((file) => {
        newFiles.push(file);
      });
      const oldFiles = files;
      setFiles([...oldFiles, ...newFiles]);
    } else {
      const oldFiles = files;
      setFiles([...oldFiles, target.files[0]]);
    }
  };

  const deleteFile = (index) => {
    const newArray = files;
    newArray.splice(index, 1);
    if (newArray.length === 0) setFiles([]);
    setFiles([...newArray]);
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  // Buscando Dados da API para o select do Formulário

  function getData() {
    api
      .get("/rh/corretoras", {
        headers: {
          id: user.estipulante,
          authorization: `Bearer ${user.token}`,
        },
      })
      .then((resp) => {
        setCorretoras(resp.data);
      })
      .catch((err) => {
        console.log(`Error: ${err.message}`);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
    }
  }, [formState, submittedData, reset]);

  return (
    <ContainerModal>
      <h1>Novo Ticket</h1>
      {success ? (
        <div className="msgSuccess">
          <span className="message">Ticket aberto com sucesso!</span>

          <div className="buttons">
            <p className="newTicket" onClick={() => setSuccess(!success)}>
              Você quer criar outro ticket?
            </p>

            <button className="btn-close" onClick={() => props.closeModal()}>
              Fechar
            </button>
          </div>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input-group">
            <label>Corretora</label>
            {errors.assunto && (
              <span className="errorMsg">Campo obrigatório!</span>
            )}

            <select
              name="corretora"
              {...register("corretora", { required: true })}
            >
              <option>Selecione uma corretora</option>
              {corretoras.map((corretora, index) => (
                <option key={index} value={corretora.id_corretora}>
                  {corretora.nome_completo}
                </option>
              ))}
            </select>
          </div>

          <div className="input-group">
            <label>Assunto do Ticket</label>
            {errors.assunto && (
              <span className="errorMsg">Campo obrigatório!</span>
            )}
            <input
              type="text"
              name="assunto"
              {...register("assunto", { required: true })}
            />
          </div>

          <div className="input-group">
            <label>Ticket</label>
            {errorEmptyMsg && (
              <span className="errorMsg">Campo obrigatório!</span>
            )}
            <Editor
              toolbar={toolbar}
              editorState={editorState}
              editorStyle={editorStyle}
              wrapperClassName="text-editor-wrapper"
              editorClassName="text-area-editor"
              onEditorStateChange={onEditorStateChange}
            />
          </div>

          <div className="input-group">
            {files.length > 0
              ? files.map((file, index) => {
                  return (
                    <div className="file" key={index}>
                      <span>{file?.name}</span>
                      <button
                        className="btn-clear"
                        onClick={() => deleteFile(index)}
                      >
                        <CloseIcon />
                      </button>
                    </div>
                  );
                })
              : null}
            <label className="input-label">
              <span>
                <BiPlus /> Documentos
              </span>
              <input
                className="input-file"
                type="file"
                onChange={({ target }) => handleFile(target)}
                onClick={({ target }) => (target.value = null)}
                multiple
              />
            </label>
          </div>

          <button type="submit">Enviar!</button>
        </form>
      )}
    </ContainerModal>
  );
};

export default NewTicket;
