import { Checkbox, createTheme, ThemeProvider } from '@mui/material';
import React from 'react';
import { useMemo } from 'react';
import {
  RiCheckboxBlankCircleLine,
  RiCheckboxCircleFill
} from 'react-icons/ri';
import SelectStandard from 'apps/broker/components/Select/index.js';
import colors from 'styles/colors';
import { AbaPlanosAdicionaisWrapper } from './styles-PlanosAdicionais.js';

const theme = createTheme({
  palette: {
    rhBlue: {
      main: colors.rhBlue
    },
    darkGenoaBlue: {
      main: colors.genoaDarkBlue
    },
    action: {
      disabled: colors.genoaDisabledGrey
    }
  }
});

const AbaPlanosAdicionais = ({
  beneficiarioPage,
  values,
  setBeneficiariosAdicionais,
  beneficiariosAdicionais,
  setSelectValue,
  contratosEstipulante,
  incluirSaude,
  setIncluirSaude,
  incluirOdonto,
  setIncluirOdonto
}) => {
  const [height, setHeight] = React.useState(window.innerHeight);

  const beneficiarioAdicional = useMemo(() => {
    return beneficiariosAdicionais[beneficiarioPage];
  }, [beneficiariosAdicionais, beneficiarioPage]);

  const tipoBeneficiario = values?.tipoBeneficiario;
  const titular = values?.titular;

  const contratosTitular = useMemo(() => {
    const contratoSaude = titular?.contratos?.filter(
      (item) => item.produto_id === 1
    );
    const contratoOdonto = titular?.contratos?.filter(
      (item) => item.produto_id === 2
    );

    return {
      saude: contratoSaude ? contratoSaude : [],
      odonto: contratoOdonto ? contratoOdonto : []
    };
  }, [titular]);

  const contratosSaude = useMemo(() => {
    return tipoBeneficiario === 'Titular'
      ? // Se for Titular, renderizar contratos de estipulante
        contratosEstipulante?.saude.filter(
          (item) =>
            item.contrato_estipulante_id === values?.contrato_estipulante_saude
        )
      : // Se for Dependente, renderizar apenas contrato do Titular (se possuir)
        contratosEstipulante?.saude.filter(
          (item) =>
            item.contrato_estipulante_id ===
            contratosTitular?.saude[0]?.contrato_estipulante_id
        );
  }, [
    contratosEstipulante?.saude,
    contratosTitular,
    tipoBeneficiario,
    values?.contrato_estipulante_saude
  ]);

  React.useEffect(() => {
    const optionsSaude = contratosSaude.map((item) => ({
      option: `${item.seguradora_nome} (${item.numero_apolice})`,
      value: item.contrato_estipulante_id
    }));

    setOptionsContratosSaude(optionsSaude);
  }, [contratosSaude]);

  React.useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
  });

  React.useEffect(() => {
    if (tipoBeneficiario === 'Dependentes') {
      const contratosTitular = titular?.contratos;
      const contratoSaude = contratosTitular?.find(
        (item) => item.produto_id === 1
      );
      const optionsTipoPlanoSaude = [
        {
          option: contratoSaude?.tipo_plano,
          value: contratoSaude?.tipo_plano
        }
      ];
      if (optionsTipoPlanoSaude?.length) {
        setOptionsTipoPlanoSaude(optionsTipoPlanoSaude);
      } else {
        setOptionsTipoPlanoSaude(undefined);
      }
    }

    if (tipoBeneficiario === 'Titular') {
      const optionsTipoPlanoSaude = [
        {
          option: values['tipo_plano_saude'],
          value: values['tipo_plano_saude']
        }
      ];
      if (values['tipo_plano_saude']) {
        setOptionsTipoPlanoSaude(optionsTipoPlanoSaude);
      } else {
        setOptionsTipoPlanoSaude(undefined);
      }
    }
  }, [
    beneficiarioAdicional?.contrato_estipulante_saude,
    contratosSaude,
    tipoBeneficiario,
    titular
  ]);

  const [optionsContratosSaude, setOptionsContratosSaude] = React.useState([]);
  const [optionsTipoPlanoSaude, setOptionsTipoPlanoSaude] = React.useState([]);

  const contratosOdonto = useMemo(() => {
    return tipoBeneficiario === 'Titular'
      ? // Se for Titular, renderizar contratos de estipulante
        contratosEstipulante?.odonto.filter(
          (item) =>
            item.contrato_estipulante_id === values?.contrato_estipulante_odonto
        )
      : // Se for Dependente, renderizar apenas contrato do Titular (se possuir)
        contratosEstipulante?.odonto.filter(
          (item) =>
            item.contrato_estipulante_id ===
            contratosTitular?.odonto[0]?.contrato_estipulante_id
        );
  }, [
    tipoBeneficiario,
    contratosEstipulante?.odonto,
    contratosTitular?.odonto,
    values?.contrato_estipulante_odonto
  ]);

  React.useEffect(() => {
    const optionsOdonto = contratosOdonto.map((item) => ({
      option: `${item.seguradora_nome} (${item.numero_apolice})`,
      value: item.contrato_estipulante_id
    }));

    setOptionsContratosOdonto(optionsOdonto);
  }, [contratosOdonto]);

  React.useEffect(() => {
    if (tipoBeneficiario === 'Dependentes') {
      const contratosTitular = titular?.contratos;
      const contratoSaude = contratosTitular?.find(
        (item) => item.produto_id === 2
      );
      const optionsTipoPlanoSaude = [
        {
          option: contratoSaude?.tipo_plano,
          value: contratoSaude?.tipo_plano
        }
      ];
      setOptionsTipoPlanoOdonto(optionsTipoPlanoSaude);
    }

    if (tipoBeneficiario === 'Titular') {
      const optionsTipoPlanoOdonto = [
        {
          option: values['tipo_plano_odonto'],
          value: values['tipo_plano_odonto']
        }
      ];
      if (values['tipo_plano_odonto']) {
        setOptionsTipoPlanoOdonto(optionsTipoPlanoOdonto);
      } else {
        setOptionsTipoPlanoOdonto(undefined);
      }
    }
  }, [
    beneficiarioAdicional?.contrato_estipulante_saude,
    contratosSaude,
    tipoBeneficiario,
    titular
  ]);

  const [optionsContratosOdonto, setOptionsContratosOdonto] = React.useState(
    []
  );
  const [optionsTipoPlanoOdonto, setOptionsTipoPlanoOdonto] = React.useState(
    []
  );

  const camposInclusaoSaude = useMemo(
    () => beneficiarioAdicional?.camposInclusaoSaude,
    [beneficiarioAdicional]
  );

  const camposInclusaoOdonto = useMemo(
    () => beneficiarioAdicional?.camposInclusaoOdonto,
    [beneficiarioAdicional]
  );

  React.useEffect(() => {
    let keysCampoInclusaoGeral;

    if (camposInclusaoSaude) {
      const keys = Object.keys(camposInclusaoSaude);
      keysCampoInclusaoGeral = keys.filter(
        (item) => item !== 'contrato_estipulante_id'
      );
    } else if (camposInclusaoOdonto) {
      const keys = Object.keys(camposInclusaoOdonto);
      keysCampoInclusaoGeral = keys.filter(
        (item) => item !== 'contrato_estipulante_id'
      );
    }

    let camposInclusaoGeral = {};

    keysCampoInclusaoGeral?.forEach((chave) => {
      if (
        (camposInclusaoSaude && camposInclusaoSaude[chave]) ||
        (camposInclusaoOdonto && camposInclusaoOdonto[chave])
      ) {
        return (camposInclusaoGeral[chave] = true);
      }
      return (camposInclusaoGeral[chave] = false);
    });

    setBeneficiariosAdicionais((prevState) => ({
      ...prevState,
      [beneficiarioPage]: {
        ...prevState[beneficiarioPage],
        camposInclusao: camposInclusaoGeral
      }
    }));
  }, [camposInclusaoSaude, camposInclusaoOdonto]);

  const checkboxSaudeClickHandler = () => {
    let camposInclusaoSaude;

    if (tipoBeneficiario === 'Titular') {
      camposInclusaoSaude = contratosSaude[0]?.campos_inclusao;
    } else {
      const contratoEstipulanteSaude = contratosEstipulante?.saude?.find(
        (item) =>
          item.contrato_estipulante_id ===
          contratosSaude[0]?.contrato_estipulante_id
      );
      camposInclusaoSaude = contratoEstipulanteSaude?.campos_inclusao;
    }

    setBeneficiariosAdicionais((prevState) => {
      let contrato_estipulante_saude;
      let tipo_plano_saude;

      if (values?.tipoBeneficiario === 'Dependentes') {
        const titular_contrato_saude = values?.titular?.contratos.find(
          (item) => item.produto_id === 1
        );
        contrato_estipulante_saude =
          titular_contrato_saude?.contrato_estipulante_id;
        tipo_plano_saude = titular_contrato_saude?.tipo_plano;
      } else {
        contrato_estipulante_saude = values?.contrato_estipulante_saude;
        tipo_plano_saude = values?.tipo_plano_saude;
      }

      return {
        ...prevState,
        [beneficiarioPage]: {
          ...prevState[beneficiarioPage],
          incluirSaude: !prevState[beneficiarioPage]?.incluirSaude,
          contrato_estipulante_saude: !prevState[beneficiarioPage]?.incluirSaude
            ? contrato_estipulante_saude
            : undefined,
          tipo_plano_saude: !prevState[beneficiarioPage]?.incluirSaude
            ? tipo_plano_saude
            : undefined,
          camposInclusaoSaude: !prevState[beneficiarioPage]?.incluirSaude
            ? camposInclusaoSaude
            : undefined
        }
      };
    });
  };

  const checkboxOdontoClickHandler = () => {
    let camposInclusaoOdonto;
    if (tipoBeneficiario === 'Titular') {
      camposInclusaoOdonto = contratosOdonto[0]?.campos_inclusao;
    } else {
      const contratoEstipulanteOdonto = contratosEstipulante?.odonto?.find(
        (item) =>
          item.contrato_estipulante_id ===
          contratosOdonto[0]?.contrato_estipulante_id
      );
      camposInclusaoOdonto = contratoEstipulanteOdonto?.campos_inclusao;
    }

    setBeneficiariosAdicionais((prevState) => {
      let contrato_estipulante_odonto;
      let tipo_plano_odonto;

      if (values?.tipoBeneficiario === 'Dependentes') {
        const titular_contrato_odonto = values?.titular?.contratos.find(
          (item) => item.produto_id === 2
        );
        contrato_estipulante_odonto =
          titular_contrato_odonto?.contrato_estipulante_id;
        tipo_plano_odonto = titular_contrato_odonto?.tipo_plano;
      } else {
        contrato_estipulante_odonto = values?.contrato_estipulante_odonto;
        tipo_plano_odonto = values?.tipo_plano_odonto;
      }

      return {
        ...prevState,
        [beneficiarioPage]: {
          ...prevState[beneficiarioPage],
          incluirOdonto: !prevState[beneficiarioPage]?.incluirOdonto,
          contrato_estipulante_odonto: !prevState[beneficiarioPage]
            ?.incluirOdonto
            ? contrato_estipulante_odonto
            : undefined,
          tipo_plano_odonto: !prevState[beneficiarioPage]?.odonto
            ? tipo_plano_odonto
            : undefined,
          camposInclusaoOdonto: !prevState[beneficiarioPage]?.incluirOdonto
            ? camposInclusaoOdonto
            : undefined
        }
      };
    });
  };

  const renderContratosOptions = () => {
    return (
      <>
        {incluirSaude && contratosSaude?.length ? (
          <div className="aba-input-container">
            <div className="title-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  name="saude"
                  checked={beneficiarioAdicional['incluirSaude']}
                  onChange={checkboxSaudeClickHandler}
                  icon={
                    <RiCheckboxBlankCircleLine
                      className="uncheckedIcon"
                      size={(20 * height) / 745}
                    />
                  }
                  checkedIcon={
                    <RiCheckboxCircleFill size={(20 * height) / 745} />
                  }
                  sx={{ p: 0 }}
                  color={'rhBlue'}
                />
              </ThemeProvider>
              <span className="title">Incluir plano de Saúde?</span>
            </div>
            <div className="aba-contrato-planos">
              <SelectStandard
                value={beneficiarioAdicional['contrato_estipulante_saude']}
                setValue={setSelectValue}
                label={'Seguradora'}
                name="contrato_estipulante_saude"
                options={optionsContratosSaude}
                disabled={!beneficiarioAdicional['incluirSaude']}
              />

              <SelectStandard
                value={beneficiarioAdicional['tipo_plano_saude']}
                setValue={setSelectValue}
                label={'Plano'}
                name="tipo_plano_saude"
                options={optionsTipoPlanoSaude}
                selectPlaceholder={
                  beneficiarioAdicional['contrato_estipulante_saude']
                    ? tipoBeneficiario === 'Titular'
                      ? 'Selecione um Plano para o Titular'
                      : 'Selecione um Plano'
                    : 'Selecione uma Seguradora'
                }
                disabled={!beneficiarioAdicional['incluirSaude']}
              />
            </div>
          </div>
        ) : (
          ''
        )}
        {incluirOdonto && contratosOdonto?.length ? (
          <div className="aba-input-container">
            <div className="title-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  name="odonto"
                  checked={beneficiarioAdicional['incluirOdonto']}
                  onChange={checkboxOdontoClickHandler}
                  icon={
                    <RiCheckboxBlankCircleLine
                      className="uncheckedIcon"
                      size={(20 * height) / 745}
                    />
                  }
                  checkedIcon={
                    <RiCheckboxCircleFill size={(20 * height) / 745} />
                  }
                  sx={{ p: 0 }}
                  color={'rhBlue'}
                />
              </ThemeProvider>
              <span className="title">Incluir plano Odontológico?</span>
            </div>
            <div className="aba-contrato-planos">
              <SelectStandard
                value={beneficiarioAdicional['contrato_estipulante_odonto']}
                setValue={setSelectValue}
                label={'Seguradora'}
                name="contrato_estipulante_odonto"
                options={optionsContratosOdonto}
                disabled={!beneficiarioAdicional['incluirOdonto']}
              />

              <SelectStandard
                value={beneficiarioAdicional['tipo_plano_odonto']}
                setValue={setSelectValue}
                label={'Plano'}
                name="tipo_plano_odonto"
                options={optionsTipoPlanoOdonto}
                selectPlaceholder={
                  beneficiarioAdicional['contrato_estipulante_odonto']
                    ? tipoBeneficiario === 'Titular'
                      ? 'Selecione um Plano para o Titular'
                      : 'Selecione um Plano'
                    : 'Selecione uma Seguradora'
                }
                disabled={!beneficiarioAdicional['incluirOdonto']}
              />
            </div>
          </div>
        ) : (
          ''
        )}
      </>
    );
  };

  const renderMensagensErro = () => {
    if (
      values &&
      !(
        values['tipoBeneficiario'] === 'Titular' ||
        values['tipoBeneficiario'] === 'Dependentes'
      )
    ) {
      return (
        <>
          <div className="mensagens-erro-container">
            <span>
              Faça a escolha do <strong>Tipo de Beneficiário</strong> em página{' '}
              <strong>principal</strong>
            </span>
          </div>
        </>
      );
    }
    if (values && values['tipoBeneficiario'] === 'Titular') {
      if (!incluirSaude && !incluirOdonto) {
        return (
          <>
            <div className="mensagens-erro-container">
              <span>
                Realize pelo menos uma seleção de inclusão de{' '}
                <strong>Plano de Saúde</strong> ou <strong>Odontológico</strong>{' '}
                em página <strong>principal</strong> na aba de{' '}
                <strong>Escolha de Plano(s)</strong>
              </span>
            </div>
          </>
        );
      }

      if (
        !values[
          'contrato_estipulante_saude' && !values['contrato_estipulante_odonto']
        ]
      ) {
        return (
          <>
            <div className="mensagens-erro-container">
              <span>
                Selecione a <strong>Seguradora</strong> na qual será realizada a
                inclusão em página <strong>principal</strong> na aba de{' '}
                <strong>Escolha de Plano(s)</strong>
              </span>
            </div>
          </>
        );
      }
    }

    if (
      values &&
      values['tipoBeneficiario'] === 'Dependentes' &&
      !values['titular']
    ) {
      return (
        <>
          <div className="mensagens-erro-container">
            <span>
              Selecione o <strong>Titular</strong> dos dependentes a serem
              adicionados na página <strong>principal</strong> em aba{' '}
              <strong>Selecione Titular</strong>
            </span>
          </div>
        </>
      );
    }
  };

  return (
    <AbaPlanosAdicionaisWrapper>
      <div className="aba-container">
        <div className="aba-title-container">
          <span>Seleção de plano(s)</span>
        </div>
        {(values['tipoBeneficiario'] === 'Titular' &&
          ((incluirSaude && contratosSaude?.length) ||
            (incluirOdonto && contratosOdonto?.length))) ||
        (values['tipoBeneficiario'] === 'Dependentes' && values['titular'])
          ? renderContratosOptions()
          : renderMensagensErro()}
      </div>
    </AbaPlanosAdicionaisWrapper>
  );
};

export default AbaPlanosAdicionais;
