import http from 'services/http';

const updateContactInformation = async (data) => {
  const res = await http.put('/corretor/concierge/tickets', data);

  return res;
};

const getAll = async () => {
  const res = await http.get('/corretor/concierge/tickets');

  return res;
}

const modules = {
  updateContactInformation,
  getAll,
};

export default modules;
