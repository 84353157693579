import React from 'react';
import SidemenuRh from 'apps/broker/components/Sidebar';
import Header from 'apps/broker/components/Header';
import { Content, Main, Wrapper } from './styles';
import ConciergeTicketsList from './components/TableResult';

const Concierge = () => {
  return (
    <Wrapper>
      <SidemenuRh />
      <Content>
        <Header />
        <Main>
          <ConciergeTicketsList />
        </Main>
      </Content>
    </Wrapper>
  );
};

export default Concierge;
