import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import { Wraper } from './styles';
import {  BsArrowRight } from "react-icons/bs";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import InputMask from 'react-input-mask';
import api from 'services/api';
import { useParams, useHistory } from 'react-router-dom';
import { serialize } from 'object-to-formdata';
import Loading from 'components/Loading';
import { useEmitTicket } from "contexts/emitTicket";

const dados = {
  nomeDependete: '',
  grauParentesco: '',
  cpf: '',
  rg: '',
  dataExpedicao: '',
  orgaoEmissor: '',
  sexo: '',
  dataNascimento: new Date(),
  arquivo: {}
}

const AddDependentes = ({ formExt }) => {
  const { corretoraId, estipulanteId, contratoId } = useParams();
  const { externalForm, updateExternalFormStage, setEmitTicket } = useEmitTicket();
  const [nomeTitular, setNomeTitular] = useState(" ");
  const [uuid, setUuid] = useState();
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const options = {
    indices: true,
    nullsAsUndefineds: false,
    booleansAsIntegers: false,
    allowEmptyArrays: false,
  };

  const { dependentes } = externalForm;
  // console.log('dependentes', dependentes)
  const { register, handleSubmit, control, formState: { errors } } = useForm({
    defaultValues: {
      dependents: dependentes.length > 0 ? dependentes : [dados]
    }
  });
  const { fields, append, remove } = useFieldArray(
    { control, name: "dependents" }
  );

  const changeStage = (stage) => {
    updateExternalFormStage(stage);
  }

  const sendData = (data) => {
    setLoading(true)
    const body = serialize({ ...data, uuid }, options)
    // console.log(body)
    api.post(`/dependentes/ticket/${corretoraId}/${estipulanteId}/${contratoId}`, body)
      .then(resp => {
        if (data.formExt[0]) {

          const formData = new FormData()
          formData.append('file', data.formExt[0])

          const headersFormExt = {
            ticket: resp.data,
          }

          api.post('/ticket/formext', formData, { headers: headersFormExt })
        }
        setEmitTicket({ ticket: resp.data });
        history.push(`/questionario-inclusao-ticket/${corretoraId}/${estipulanteId}/${contratoId}`)
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        setLoading(false)
      })
  }

  const handleCPF = (fun, target) => {
    fun.onChange(target.value)
    if (target.value.length === 14) {
      const body = {
        cpf: target.value
      }
      api.post(`/search/${corretoraId}/${estipulanteId}/${contratoId}`, body)
        .then(resp => {
          setNomeTitular(resp.data.nome)
          setUuid(resp.data.uuid)
        }).catch(err => {
          setNomeTitular(err.data.response)
        })
    }
  }

  return (
    <Wraper>
      <Loading open={loading} setOpen={() => setLoading(false)} />
      <fieldset>
        <legend>Insira o CPF do Titular</legend>
        <div className="input-group">
          {errors.cpfTitularr && <span className="errorsMsg">Campo obrigatório</span>}
          <Controller
            name="cpfTitular"
            control={control}
            rules={{ required: true }}
            render={({ field }) => <InputMask
              value={field.value || ''}
              maskChar={null}
              mask="999.999.999-99"
              onChange={({ target }) => handleCPF(field, target)}
              type="text"
              placeholder="CPF" />}
          />
        </div>
        <div className="nomeTitular">{nomeTitular ? nomeTitular : <span className="erroPlanos">CPF não encontrado</span>}</div>

        {/* <div className="nomeTitular">{nomeTitular && nomeTitular}</div> */}
        <br></br>
      </fieldset>
      {fields.map((item, index) => {
        return (
          <fieldset key={index} className={fields.length > 1 ? 'fieldDepends' : ''}>
            {index === 0 ? <legend>Adicione os seus  dependentes</legend> :
              <button type="button" className="remove"
                onClick={() => { remove(dados) }}>Remover</button>}


            <div className="input-group">
              {errors.dependents?.[index]?.nomeDependete && <span className="errorsMsg">Campo obrigatório</span>}
              <input type="text" name="nomeDependete"
                {...register(`dependents.${index}.nomeDependete`, { required: true })}
                defaultValue={item.nomeDependete}
                placeholder="Nome do primeiro dependente"></input>
            </div>
            <div className="select-group">
              <label >Grau de Parentesco</label>
              {errors.dependents?.[index]?.grauParentesco && <span className="errorsMsg">Campo obrigatório</span>}
              <select
                {...register(`dependents.${index}.grauParentesco`, { required: true })}
                defaultValue={item.grauParentesco}>
                <option value="">Selecione</option>
                <option value="Conjuge">Conjuge</option>
                <option value="Filho">Filho</option>
              </select>
            </div>
            <div className="input-group">
              {errors.dependents?.[index]?.cpf && <span className="errorsMsg">Campo obrigatório</span>}
              <Controller
                name={`dependents.${index}.cpf`}
                control={control}
                defaultValue={item.cpf}
                rules={{ required: true }}
                render={({ field }) => <InputMask
                  {...field}
                  maskChar={null}
                  mask="999.999.999-99"
                  type="text"
                  placeholder="CPF" />}
              />
            </div>
            <div className="input-group">
              {errors.dependents?.[index]?.rg && <span className="errorsMsg">Campo obrigatório</span>}
              <input type="text"
                {...register(`dependents.${index}.rg`)}
                defaultValue={item.rg}
                placeholder="RG"
              ></input>
            </div>
            <div className="inline-group-input">
              <div>
                {errors.dependents?.[index]?.orgaoEmissor && <span className="errorsMsg">Campo obrigatório</span>}
                <input type="text"
                  {...register(`dependents.${index}.orgaoEmissor`,
                    { required: true },
                  )}
                  defaultValue={item.orgaoEmissor}
                  maxLength={13}
                  placeholder="Órgão Emissor" />
              </div>
              <div>
                {errors.dependents?.[index]?.dataExpedicao && <span className="errorsMsg">Campo obrigatório</span>}
                <Controller
                  name={`dependents.${index}.dataExpedicao`}
                  control={control}
                  defaultValue={item.dataExpedicao ? new Date(item.dataExpedicao) : null}
                  rules={{ required: true }}
                  render={({ field: { onChange, value, } }) => <DatePicker
                    className="input-data"
                    selected={value}
                    locale="ptBR"
                    onChange={onChange}
                    placeholderText="Data de Expedição"
                    dateFormat="dd/MM/yyyy"
                  />}
                />
              </div>
            </div>
            <div className="select-group">
              <label >Sexo/Gênero</label>
              {errors.dependents?.[index]?.sexo && <span className="errorsMsg">Campo obrigatório</span>}
              <select
                {...register(`dependents.${index}.sexo`, { required: true })}
                defaultValue={item.sexo}>
                <option value="">Selecione</option>
                <option value="Masculino">Masculino</option>
                <option value="Feminino">Feminino</option>
              </select>
            </div>

            <div className="input-date-group">
              <label className="normal-label">Data de Nascimento</label>
              {errors.dependents?.[index]?.dataNascimento && <span className="errorsMsg">Campo obrigatório</span>}
              <Controller
                name={`dependents.${index}.dataNascimento`}
                control={control}
                defaultValue={item.dataNascimento ? new Date(item.dataNascimento) : null}
                rules={{ required: true }}
                render={({ field: { onChange, value, } }) => <DatePicker
                  className="input-data"
                  selected={value}
                  onChange={onChange}
                  placeholderText="Data de Nascimento"
                  dateFormat="dd/MM/yyyy"
                />}
              />
            </div>

            {/* <div className="input-files-group">
              {watch(`dependents.${index}.grauParentesco`) &&
                <div>{`${watch(`dependents.${index}.grauParentesco`) === 'Conjuge' ? 'Certidão de casamento' : 'Certidão de nascimento'}`}</div>
              }
              {errors.dependents?.[index]?.arquivo && <span className="errorsMsg">Campo obrigatório</span>}

              <label className="label-file">

                {watch(`dependents.${index}.arquivo`)[0]?.name ? <span><BiFolderOpen /> {watch(`dependents.${index}.arquivo`)[0]?.name} </span> :
                  <span>Carregue seu documento <BiUpload /></span>
                }
                <input className="input-file" name="arquivo"
                  {...register(`dependents.${index}.arquivo`, { required: true })}
                  type="file" />
              </label>
            </div> */}

          </fieldset>

        )
      })}

      {/* {
        formExt
          ?
          <div className="input-files-group formExt">
            <br/>
            <legend>Faça o upload do formulário baixado anteriormente
              <a href={formExt}>clique aqui para baixar ele</a>
            </legend>
            <label className="label-file">
              {watch('formExt')?.[0]?.name ?
                <span><BiFolderOpen /> {watch('formExt')[0]?.name} </span> :
                <span>Formulário externo <BiUpload /></span>
              }
              <input className="input-file"
                {...register('formExt', { required: false })}
                type="file" />
            </label>
          </div>
          :
          null
      } */}

      <div className="add-depend">
        <button type="button" onClick={() => { append(dados) }}>Adicionar mais um dependente</button>
      </div>
      <div className="button-areas">
        <button type="button" onClick={() => changeStage('programaBeneficios')}  >Voltar</button>
        <button type="button" onClick={handleSubmit(sendData)} >Finalizar <BsArrowRight/></button>
      </div>
    </Wraper>
  )
}

export default AddDependentes
