import styled from 'styled-components';
import colors from 'styles/colors';

export const ScanDocumentosAdicionaisWrapper = styled.div`

  @media (max-height: 580px) {
    display: grid;
    grid-template-rows: 17.32px 1fr 26.68px;
    row-gap: 30px;

    .content-title {
      span {
        font-size: 12px;
        color: ${colors.rhBlue};
        font-family: Avenir Next GEO W05 Heavy;
      }
    }

    .aba-container {
      display: grid;
      grid-template-rows: 17.32px 1fr;
      row-gap: 40px;

      .aba-title-container {
        span {
          font-size: 12px;
          font-family: Avenir Next GEO W05 Bold;
          color: ${colors.genoaDarkBlue};
        }
      }

      .aba-input-container {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        row-gap: 16.675px;

        .aba-input-row-cargo {
          display: grid;
          width: max-content;
          grid-template-columns: 266.68px 133.34px;
          column-gap: 33.35px;
        }


        .aba-input-last-row {
          display: grid;
          width: max-content;
          grid-template-columns: 200px 200px;
          column-gap: 33.35px;
        }

      }
    }
    
  }

  @media (min-height: 580px) and (max-height: 650px) {
    display: grid;
    grid-template-rows: 20.8px 1fr 32px;
    row-gap: 36px;

    .content-title {
      span {
        font-size: 14.4px;
        color: ${colors.rhBlue};
        font-family: Avenir Next GEO W05 Heavy;
      }
    }

    .aba-container {
      display: grid;
      grid-template-rows: 20.8px 1fr;
      row-gap: 48px;

      .aba-title-container {
        span {
          font-size: 14.4px;
          font-family: Avenir Next GEO W05 Bold;
          color: ${colors.genoaDarkBlue};
        }
      }

      .aba-input-container {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        row-gap: 20px;

        .aba-input-row-cargo {
          display: grid;
          width: max-content;
          grid-template-columns: 320px 160px;
          column-gap: 40px;
        }


        .aba-input-last-row {
          display: grid;
          width: max-content;
          grid-template-columns: 240px 240px;
          column-gap: 40px;
        }

      }
    }
  }

  @media (min-height: 650px) and (max-height: 720px) {
    display: grid;
    grid-template-rows: 23.4px 1fr 36px;
    row-gap: 40.5px;
  
    .content-title {
      span {
        font-size: 16.2px;
        color: ${colors.rhBlue};
        font-family: Avenir Next GEO W05 Heavy;
      }
    }
  
    .aba-container {
      display: grid;
      grid-template-rows: 23.4px 1fr;
      row-gap: 54px;
  
      .aba-title-container {
        span {
          font-size: 16.2px;
          font-family: Avenir Next GEO W05 Bold;
          color: ${colors.genoaDarkBlue};
        }
      }
  
      .aba-input-container {
        width: 100%;
        height: 100%;
  
        display: flex;
        flex-direction: column;
        row-gap: 22.5px;
  
        .aba-input-row-cargo {
          display: grid;
          width: max-content;
          grid-template-columns: 360px 180px;
          column-gap: 45px;
        }
  
  
        .aba-input-last-row {
          display: grid;
          width: max-content;
          grid-template-columns: 270px 270px;
          column-gap: 45px;
        }
  
      }
    }
  }

  @media (min-height: 720px) {
    display: grid;
  
    .content-title {
      span {
        font-size: 18px;
        color: ${colors.rhBlue};
        font-family: Avenir Next GEO W05 Heavy;
      }
    }
  
    .aba-container {
      display: grid;
      grid-template-rows: 26px 1fr;
      row-gap: 60px;
  
      .aba-title-container {
        span {
          font-size: 18px;
          font-family: Avenir Next GEO W05 Bold;
          color: ${colors.genoaDarkBlue};
        }
      }
  
      .aba-input-container {
        width: 100%;
        height: 100%;
  
        display: flex;
        flex-direction: column;
        row-gap: 25px;
  
        .aba-input-row-cargo {
          display: grid;
          width: max-content;
          grid-template-columns: 400px 200px;
          column-gap: 50px;
        }
  
  
        .aba-input-last-row {
          display: grid;
          width: max-content;
          grid-template-columns: 300px 300px;
          column-gap: 50px;
        }
  
      }
    }

    .documentos_content_container {
      display: grid;
      grid-template-columns: 3fr 2fr;
      column-gap: 15px;

      .coluna_lista {
        display: grid;
        grid-template-rows: minmax(0, max-content) 20px;
        row-gap: 25px;
        
        .lista_container {

          overflow: auto;
          display: flex;
          flex-wrap: wrap;
          column-gap: 70px;
          row-gap: 10px;
          // outline: 2px solid red;
          height: max-content;

          .documento_option {
            display: flex;
            column-gap: 5px;
            align-items: center;
            height: 30px;
          }
          
          ::-webkit-scrollbar {
            width: 4px;
            background: rgba(0, 0, 0, 0);
          }
          ::-webkit-scrollbar-thumb {
            background: ${colors.rhBlue};
            box-shadow: none;
            
          }
          ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 2px rgb(0, 0 , 0, 0);
            
          }
        }

        .input-label {
          width: max-content;
          span {
            display: flex;
            align-items: center;
            column-gap: 7px;
            color: ${colors.rhBlue};
            font-family: Avenir Next Geo W05 Demi;
            width: max-content;
            :hover {
              cursor: pointer;
            }
          }
          

          input {
            display: none;
          }

          
        }
      }



      .coluna_documentos {
        display: flex;
        flex-direction: column;
        row-gap: 1px;
        overflow: auto;
        border-left: 2px solid ${colors.rhTabDarkGrey};
        border-radius: 4px;
        padding-left: 15px;
        // box-shadow: 0 2px 5px rgba(0,0,0,0.16), 0 2px 5px rgba(0,0,0,0.23);

        .coluna_documentos__title {
          font-family: Avenir Next Geo W05 Demi;
          margin-bottom: 7px;
          
        }

        .documento_file_option {
          display: flex;
          column-gap: 10px;
          align-items: center;
          font-size: 0.9em;

          button {
            background: none;
            color: inherit;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;
          }

          .remove_icon {
            font-size: 14px;
            color: ${colors.genoaPink};
            transform: translateY(1px);
          }
        }

        ::-webkit-scrollbar {
          width: 4px;
          background: rgba(0, 0, 0, 0);
        }
        ::-webkit-scrollbar-thumb {
          background: ${colors.rhBlue};
          box-shadow: none;
          
        }
        ::-webkit-scrollbar-track {
          box-shadow: inset 0 0 2px rgb(0, 0 , 0, 0);
          
        }
      }
    }
  }
`