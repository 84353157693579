import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FiUsers } from 'react-icons/fi';
import { TableDemandas } from './styles';
import { useFilters } from 'contexts/filters';
import { useActivitiesFilters } from 'contexts/activities';
import { useTasksFilters } from 'contexts/tasks';
import services from 'apps/broker/services';

const DemandsByUser = () => {
  const filters = useFilters();
  const activities = useActivitiesFilters();
  const tasks = useTasksFilters();
  const history = useHistory();

  const [demandas, setDemandas] = useState([]);

  const getDemandasPorUsuario = async () => {
    const response = await services.user.getRequestsCompaniesToEachUser();
    if (response.status === 200) {
      setDemandas(response.data);
    }
  };

  const redirectCorretoraToTickets = (corretor) => {
    const filtersToDispatch = {
      redirected: true,
      corretor,
      ticket_type: 'outras demandas'
    };

    filters.updateHomeFilters(filtersToDispatch);

    history.push('/corretor/gestao-tickets');
  };

  const redirectToMovsTickets = (corretor) => {
    const filtersToDispatch = {
      redirected: true,
      ticket_type: 'Movimentação',
      corretor
    };

    filters.updateHomeFilters(filtersToDispatch);

    history.push('/corretor/gestao-tickets');
  };

  const redirectToConcierge = () => {
    history.push('/corretor/concierge');
  };

  const redirectToAtividades = (corretor) => {
    const filtersToDispatch = {
      redirected: true,
      corretor
    };

    activities.updateActivitiesFilters(filtersToDispatch);
    history.push('/corretor/negocios');
  };

  const redirectToTasks = (corretor) => {
    const filtersToDispatch = {
      redirected: true,
      corretor
    };

    tasks.updateTasksFilters(filtersToDispatch);

    history.push('/corretor/tasks');
  };

  useEffect(() => {
    getDemandasPorUsuario();
  }, []);

  return (
    <div>
      <div className="icon_users_demandas">
        <FiUsers />
      </div>
      <div>
        <div className="vidas-boxes-resp">
          <div className="title_usuario_demandas">
            <span>Demandas por usuário</span>
          </div>

          <div>
            <TableDemandas>
              <thead>
                <tr>
                  <th className="icon_user"></th>
                  <th>Tickets</th>
                  <th>Movs</th>
                  <th>Concierge</th>
                  <th>Atividades</th>
                  <th>Tasks</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {demandas?.map((item) => (
                  <tr>
                    <td className="icon_user">{item?.corretor?.nome}</td>
                    <td
                      className="ticket_corretor"
                      onClick={() =>
                        redirectCorretoraToTickets(item?.corretor?.nome)
                      }
                    >
                      {item?.tickets}
                    </td>
                    <td
                      className="ticket_corretor"
                      onClick={() =>
                        redirectToMovsTickets(item?.corretor?.nome)
                      }
                    >
                      {item?.movimentacao}
                    </td>
                    <td
                      className="ticket_corretor"
                      onClick={() => redirectToConcierge()}
                    >
                      {item?.concierge}
                    </td>
                    <td
                      className="ticket_corretor"
                      onClick={() => redirectToAtividades(item?.corretor?.id)}
                    >
                      {item?.atividades}
                    </td>
                    <td
                      className="ticket_corretor"
                      onClick={() => redirectToTasks(item?.corretor?.id)}
                    >
                      {item?.tasks}
                    </td>
                    <td>{item?.total}</td>
                  </tr>
                ))}
              </tbody>
            </TableDemandas>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemandsByUser;
