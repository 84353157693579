import React from 'react';
import {
  Checkbox,
  CircularProgress,
  ClickAwayListener,
  createTheme,
  TextField,
  ThemeProvider
} from '@mui/material';
import { Paper, Popper } from '@material-ui/core';
import { CamposInclusaoWrapper } from './styles-camposInclusaoContrato';
import colors from 'styles/colors';
import { MdOutlineDoubleArrow } from 'react-icons/md';
import InputFileCamposInclusao from 'components/Input/InputFileCamposInclusao';
import { RiSendPlaneFill } from 'react-icons/ri';
import { BsCheck2Circle } from 'react-icons/bs';
import { AiOutlineDownload } from 'react-icons/ai';
import { BiPlus } from 'react-icons/bi';
import { ImCheckmark, ImCross } from 'react-icons/im';
import { HiTrash } from 'react-icons/hi';
import services from 'apps/broker/services';
import TopBar from 'apps/broker/components/HorizontalBar/topBar';
import BottomBar from 'apps/broker/components/HorizontalBar/bottomBar';

const theme = createTheme({
  palette: {
    genoaBlue: {
      main: colors.genoaBlue
    },
    darkGenoaBlue: {
      main: colors.genoaDarkBlue
    },
    action: {
      disabled: colors.genoaDisabledGrey
    }
  }
});

const CamposInclusaoContrato = ({ contrato, getData, setData }) => {
  // console.log('contrato', contrato)
  const camposSeguradora = contrato.campos_inclusao_contrato;

  const [openFichaProposta, setOpenFichaProposta] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [camposSelected, setCamposSelected] = React.useState({
    // Dados básicos
    nome: Boolean(camposSeguradora.nome),
    cpf: Boolean(camposSeguradora.cpf),
    rg: Boolean(camposSeguradora.rg),
    orgao_emissor_rg: Boolean(camposSeguradora.orgao_emissor_rg),
    sexo: Boolean(camposSeguradora.sexo),
    data_expedicao_rg: Boolean(camposSeguradora.data_expedicao_rg),
    data_nascimento: Boolean(camposSeguradora.data_nascimento),
    estado_civil: Boolean(camposSeguradora.estado_civil),
    nacionalidade: Boolean(camposSeguradora.nacionalidade),
    pis_pasep: Boolean(camposSeguradora.pis_pasep),
    nome_mae: Boolean(camposSeguradora.nome_mae),
    // Dados de endereço
    cep: Boolean(camposSeguradora.cep),
    endereco: Boolean(camposSeguradora.endereco),
    numero_endereco: Boolean(camposSeguradora.numero_endereco),
    complemento_endereco: Boolean(camposSeguradora.complemento_endereco),
    bairro: Boolean(camposSeguradora.bairro),
    uf: Boolean(camposSeguradora.uf),
    cidade: Boolean(camposSeguradora.cidade),
    // Dados de contato
    email: Boolean(camposSeguradora.email),
    telefone_fixo: Boolean(camposSeguradora.telefone_fixo),
    telefone_celular: Boolean(camposSeguradora.telefone_celular),
    // Dados de vínculo
    cargo: Boolean(camposSeguradora.cargo),
    data_admissao: Boolean(camposSeguradora.data_admissao),
    plano: Boolean(camposSeguradora.plano),
    matricula_funcional: Boolean(camposSeguradora.matricula_funcional),
    // Dados bancários
    agencia: Boolean(camposSeguradora.agencia),
    conta: Boolean(camposSeguradora.conta),
    digito_conta: Boolean(camposSeguradora.digito_conta),
    banco: Boolean(camposSeguradora.banco),
    // Dados IMC
    altura: Boolean(camposSeguradora.altura),
    peso: Boolean(camposSeguradora.peso),
    // Documentos
    documento_scan_rg: Boolean(camposSeguradora.documento_scan_rg),
    scan_comprovante_residencia: Boolean(
      camposSeguradora.scan_comprovante_residencia
    ),
    scan_carteira_trabalho: Boolean(camposSeguradora.scan_carteira_trabalho),
    scan_passaporte: Boolean(camposSeguradora.scan_passaporte),
    scan_carta_inclusao: Boolean(camposSeguradora.scan_carta_inclusao),
    scan_carta_permanencia: Boolean(camposSeguradora.scan_carta_permanencia),
    ficha_proposta: Boolean(camposSeguradora.ficha_proposta)
  });
  const [makeUpdateRequest, setMakeUpdateRequest] = React.useState(false);
  const [documentoAdicionalSelecionado, setDocumentoAdicionalSelecionado] =
    React.useState(undefined);
  const [documentosAdicionais, setDocumentosAdicionais] = React.useState([]);
  const [documentoNomeWidth, setDocumentoNomeWidth] = React.useState(undefined);
  const [nomeDocumentoSelecionadoEditado, setNomeDocumentoSelecionadoEditado] =
    React.useState('');
  const [file, setFile] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);
  const [messageSuccess, setMessageSuccess] = React.useState(false);

  React.useEffect(() => {
    const documentosParsed = JSON.parse(
      camposSeguradora?.campos_personalizados
    );
    if (documentosParsed?.length) {
      setDocumentosAdicionais(documentosParsed);
    }
  }, []);

  const inputFileChangeHandler = ({ target }) => {
    // console.log(target.files);
    if (!target?.files) {
      setFile(undefined);
    }
    if (target?.files) {
      setFile(target?.files[0]);
    }
  };

  const removeCampoInclusaoFichaProposta = () => {
    const novosCamposSelecionados = {
      ...camposSelected,
      ficha_proposta: false
    };

    const data = {
      novosCamposSelecionados,
      contrato
    };

    services.policyOwner.updateInformationSettings(data);

    getData();

    return setCamposSelected((prevState) => ({
      ...prevState,
      ficha_proposta: false
    }));
  };

  const deleteFichaPropostaContratoEstipulante = () => {
    const { contrato_estipulante_id } = contrato;

    services.policyOwner
      .deleteContractProposal(contrato_estipulante_id)
      .then(() => setFile(undefined));
  };

  const fichaPropostaCheckboxClickHandler = ({ target }) => {
    // console.log(target.checked);
    if (target.checked === true) {
      setOpenFichaProposta(true);
      setAnchorEl(target);
    } else {
      removeCampoInclusaoFichaProposta();
      deleteFichaPropostaContratoEstipulante();
    }
  };

  const handleCheckboxClickHandler = async ({ target }) => {
    const novosCamposSelecionados = {
      ...camposSelected,
      [target.name]: target.checked
    };
    const data = {
      novosCamposSelecionados,
      contrato
    };

    services.policyOwner.updateInformationSettings(data);

    getData();

    return setCamposSelected((prevState) => ({
      ...prevState,
      [target.name]: target.checked
    }));
  };

  const sendFichaProposta = async () => {
    const novosCamposSelecionados = {
      ...camposSelected,
      ficha_proposta: true
    };

    const formData = new FormData();

    formData.append('file', file);
    formData.append('contrato', JSON.stringify(contrato));

    let body = {
      novosCamposSelecionados,
      contrato
    };
    setLoading(true);

    services.policyOwner.updateInformationSettings(body);

    body = formData;

    services.policyOwner.updateProposalInformationSetting(body).then((resp) => {
      setData((prevState) => {
        const newState = prevState.map((item) => {
          if (item.id === contrato.id) {
            item.ficha_proposta = resp.data;
            return item;
          }
          return item;
        });

        return newState;
      });
      setLoading(false);
      setMessageSuccess(true);
      setTimeout(() => {
        setOpenFichaProposta(false);
        setMessageSuccess(false);
      }, 2000);
    });

    getData();

    return setCamposSelected((prevState) => ({
      ...prevState,
      ficha_proposta: true
    }));
  };

  const renderFichaPropostaPopper = () => {
    if (loading) {
      return (
        <div className="circular_spinner__container">
          <CircularProgress
            size={50}
            thickness={2}
            className="circular-spinner"
          />
        </div>
      );
    } else {
      if (messageSuccess) {
        return (
          <div className="confirmation_message__container">
            <BsCheck2Circle size={50} className="check-icon blink" />
          </div>
        );
      }

      return (
        <>
          <span className="paper_title">Insira a ficha proposta</span>
          <div className="input_file__container">
            <InputFileCamposInclusao
              value={file}
              setValue={inputFileChangeHandler}
            />
          </div>
          <div className="send_btn__container">
            <span
              className={`send_btn ${file ? 'active' : 'disabled'}`}
              onClick={sendFichaProposta}
            >
              Enviar
              <RiSendPlaneFill size={10} />
            </span>
          </div>
        </>
      );
    }
  };

  const downloadFichaPropostaClickHandler = () => {
    const { ficha_proposta } = contrato;
    window.open(ficha_proposta.file_link, '_blank');
  };

  const selecionarDocumentoClickHandler = (index) => {
    const label = document.getElementById(`name-span-${index}`);
    setDocumentoNomeWidth(label?.offsetWidth + 5);
    setNomeDocumentoSelecionadoEditado(documentosAdicionais[index]?.name);
    setDocumentoAdicionalSelecionado(index);
  };

  const checkboxDocumentoClickHandler = (indexSelecionado) => {
    setMakeUpdateRequest(true);
    setDocumentosAdicionais((prevState) => {
      const newState = prevState?.map((item, index) => {
        if (index === indexSelecionado) {
          return {
            ...item,
            selected: !item.selected
          };
        }
        return item;
      });

      return newState;
    });
  };

  const deleteDocumentoClickHandler = (indexSelecionado) => {
    setMakeUpdateRequest(true);
    setDocumentosAdicionais((prevState) => {
      const newState = prevState?.filter(
        (item, index) => index !== indexSelecionado
      );
      return newState;
    });
  };

  const adicionarDocumentoAdicional = () => {
    setMakeUpdateRequest(true);
    setDocumentosAdicionais((prevState) => [
      ...prevState,
      {
        name: 'Novo documento',
        selected: false,
        type: 'file'
      }
    ]);
  };

  const resetDocumentoSelecionado = () => {
    setDocumentoAdicionalSelecionado(undefined);
    setNomeDocumentoSelecionadoEditado('');
  };

  const sendDocumentosAdicionaisUpdate = () => {
    setMakeUpdateRequest(true);
    saveNomeDocumentoAdicionalHandler();
    resetDocumentoSelecionado();
  };

  const saveNomeDocumentoAdicionalHandler = () => {
    setDocumentosAdicionais((prevState) => {
      const newState = prevState?.map((item, index) => {
        if (index === documentoAdicionalSelecionado) {
          return {
            ...item,
            name: nomeDocumentoSelecionadoEditado
          };
        }
        return item;
      });

      return newState;
    });
  };

  const editNomeDocumentoAdicionalHandler = (e) => {
    setNomeDocumentoSelecionadoEditado(e.target.value);
  };

  const keyPressDocumentoInputHandler = (e) => {
    // console.log(e.key)
    if (e.key === 'Enter') {
      sendDocumentosAdicionaisUpdate();
    }
  };

  React.useEffect(() => {
    if (makeUpdateRequest) {
      // console.log("dispatch update")

      const body = {
        documentosAdicionais,
        contrato_estipulante_id: contrato?.contrato_estipulante_id
      };

      services.policyOwner.updateCustomInformationSetting(body).then(() => {
        setMakeUpdateRequest(false);
        getData();
      });
    }
  }, [documentosAdicionais, makeUpdateRequest]);

  React.useEffect(() => {
    const hiddenSpan = document.getElementById('hidden-span');
    // console.log(hiddenSpan)

    setDocumentoNomeWidth(hiddenSpan?.offsetWidth + 5);
  }, [nomeDocumentoSelecionadoEditado]);

  return (
    <CamposInclusaoWrapper>
      <TopBar title={'Campos de Inclusão de Contrato'} />
      <div className="input-categories-columns-container">
        <div className="input-categories-checkbox-container">
          <span className="input-checkbox-row-title">
            <MdOutlineDoubleArrow className="arrow-icon" /> Dados Básicos
          </span>
          <div className="input-checkbox-container-row">
            <div className="input-checkbox-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  name="nome"
                  checked={camposSelected['nome']}
                  onChange={handleCheckboxClickHandler}
                  // icon={<IoMdRadioButtonOff size={20} />}
                  // checkedIcon={<IoMdRadioButtonOn size={20} />}
                  sx={{ p: 0 }}
                  color={'darkGenoaBlue'}
                />
                <label>Nome completo</label>
              </ThemeProvider>
            </div>
            <div className="input-checkbox-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  name="cpf"
                  checked={camposSelected['cpf']}
                  onChange={handleCheckboxClickHandler}
                  // icon={<IoMdRadioButtonOff size={20} />}
                  // checkedIcon={<IoMdRadioButtonOn size={20} />}
                  sx={{ p: 0 }}
                  color={'darkGenoaBlue'}
                />
                <label>CPF</label>
              </ThemeProvider>
            </div>
            <div className="input-checkbox-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  name="rg"
                  checked={camposSelected['rg']}
                  onChange={handleCheckboxClickHandler}
                  // icon={<IoMdRadioButtonOff size={20} />}
                  // checkedIcon={<IoMdRadioButtonOn size={20} />}
                  sx={{ p: 0 }}
                  color={'darkGenoaBlue'}
                />
                <label>RG</label>
              </ThemeProvider>
            </div>
            <div className="input-checkbox-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  name="orgao_emissor_rg"
                  checked={camposSelected['orgao_emissor_rg']}
                  onChange={handleCheckboxClickHandler}
                  // icon={<IoMdRadioButtonOff size={20} />}
                  // checkedIcon={<IoMdRadioButtonOn size={20} />}
                  sx={{ p: 0 }}
                  color={'darkGenoaBlue'}
                />
                <label>Orgão Emissor RG</label>
              </ThemeProvider>
            </div>
            <div className="input-checkbox-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  name="sexo"
                  checked={camposSelected['sexo']}
                  onChange={handleCheckboxClickHandler}
                  // icon={<IoMdRadioButtonOff size={20} />}
                  // checkedIcon={<IoMdRadioButtonOn size={20} />}
                  sx={{ p: 0 }}
                  color={'darkGenoaBlue'}
                />
                <label>Sexo</label>
              </ThemeProvider>
            </div>
            <div className="input-checkbox-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  name="data_expedicao_rg"
                  checked={camposSelected['data_expedicao_rg']}
                  onChange={handleCheckboxClickHandler}
                  // icon={<IoMdRadioButtonOff size={20} />}
                  // checkedIcon={<IoMdRadioButtonOn size={20} />}
                  sx={{ p: 0 }}
                  color={'darkGenoaBlue'}
                />
                <label>Data de expedição RG</label>
              </ThemeProvider>
            </div>
            <div className="input-checkbox-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  name="data_nascimento"
                  checked={camposSelected['data_nascimento']}
                  onChange={handleCheckboxClickHandler}
                  // icon={<IoMdRadioButtonOff size={20} />}
                  // checkedIcon={<IoMdRadioButtonOn size={20} />}
                  sx={{ p: 0 }}
                  color={'darkGenoaBlue'}
                />
                <label>Data de nascimento</label>
              </ThemeProvider>
            </div>
            <div className="input-checkbox-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  name="estado_civil"
                  checked={camposSelected['estado_civil']}
                  onChange={handleCheckboxClickHandler}
                  // icon={<IoMdRadioButtonOff size={20} />}
                  // checkedIcon={<IoMdRadioButtonOn size={20} />}
                  sx={{ p: 0 }}
                  color={'darkGenoaBlue'}
                />
                <label>Estado civil</label>
              </ThemeProvider>
            </div>
            <div className="input-checkbox-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  name="nacionalidade"
                  checked={camposSelected['nacionalidade']}
                  onChange={handleCheckboxClickHandler}
                  // icon={<IoMdRadioButtonOff size={20} />}
                  // checkedIcon={<IoMdRadioButtonOn size={20} />}
                  sx={{ p: 0 }}
                  color={'darkGenoaBlue'}
                />
                <label>Nacionalidade</label>
              </ThemeProvider>
            </div>
            <div className="input-checkbox-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  name="pis_pasep"
                  checked={camposSelected['pis_pasep']}
                  onChange={handleCheckboxClickHandler}
                  // icon={<IoMdRadioButtonOff size={20} />}
                  // checkedIcon={<IoMdRadioButtonOn size={20} />}
                  sx={{ p: 0 }}
                  color={'darkGenoaBlue'}
                />
                <label>PIS/Pasep</label>
              </ThemeProvider>
            </div>
            <div className="input-checkbox-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  name="nome_mae"
                  checked={camposSelected['nome_mae']}
                  onChange={handleCheckboxClickHandler}
                  // icon={<IoMdRadioButtonOff size={20} />}
                  // checkedIcon={<IoMdRadioButtonOn size={20} />}
                  sx={{ p: 0 }}
                  color={'darkGenoaBlue'}
                />
                <label>Nome da mãe</label>
              </ThemeProvider>
            </div>
          </div>
        </div>
        <div className="input-categories-checkbox-container">
          <span className="input-checkbox-row-title">
            <MdOutlineDoubleArrow className="arrow-icon" /> Dados de Endereço
          </span>
          <div className="input-checkbox-container-row">
            <div className="input-checkbox-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  name="cep"
                  checked={camposSelected['cep']}
                  onChange={handleCheckboxClickHandler}
                  // icon={<IoMdRadioButtonOff size={20} />}
                  // checkedIcon={<IoMdRadioButtonOn size={20} />}
                  sx={{ p: 0 }}
                  color={'darkGenoaBlue'}
                />
                <label>CEP</label>
              </ThemeProvider>
            </div>
            <div className="input-checkbox-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  name="endereco"
                  checked={camposSelected['endereco']}
                  onChange={handleCheckboxClickHandler}
                  // icon={<IoMdRadioButtonOff size={20} />}
                  // checkedIcon={<IoMdRadioButtonOn size={20} />}
                  sx={{ p: 0 }}
                  color={'darkGenoaBlue'}
                />
                <label>Endereço</label>
              </ThemeProvider>
            </div>
            <div className="input-checkbox-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  name="numero_endereco"
                  checked={camposSelected['numero_endereco']}
                  onChange={handleCheckboxClickHandler}
                  // icon={<IoMdRadioButtonOff size={20} />}
                  // checkedIcon={<IoMdRadioButtonOn size={20} />}
                  sx={{ p: 0 }}
                  color={'darkGenoaBlue'}
                />
                <label>Nº Endereço</label>
              </ThemeProvider>
            </div>
            <div className="input-checkbox-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  name="complemento_endereco"
                  checked={camposSelected['complemento_endereco']}
                  onChange={handleCheckboxClickHandler}
                  // icon={<IoMdRadioButtonOff size={20} />}
                  // checkedIcon={<IoMdRadioButtonOn size={20} />}
                  sx={{ p: 0 }}
                  color={'darkGenoaBlue'}
                />
                <label>Complemento do Endereço</label>
              </ThemeProvider>
            </div>
            <div className="input-checkbox-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  name="bairro"
                  checked={camposSelected['bairro']}
                  onChange={handleCheckboxClickHandler}
                  // icon={<IoMdRadioButtonOff size={20} />}
                  // checkedIcon={<IoMdRadioButtonOn size={20} />}
                  sx={{ p: 0 }}
                  color={'darkGenoaBlue'}
                />
                <label>Bairro</label>
              </ThemeProvider>
            </div>
            <div className="input-checkbox-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  name="uf"
                  checked={camposSelected['uf']}
                  onChange={handleCheckboxClickHandler}
                  // icon={<IoMdRadioButtonOff size={20} />}
                  // checkedIcon={<IoMdRadioButtonOn size={20} />}
                  sx={{ p: 0 }}
                  color={'darkGenoaBlue'}
                />
                <label>UF</label>
              </ThemeProvider>
            </div>
            <div className="input-checkbox-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  name="cidade"
                  checked={camposSelected['cidade']}
                  onChange={handleCheckboxClickHandler}
                  // icon={<IoMdRadioButtonOff size={20} />}
                  // checkedIcon={<IoMdRadioButtonOn size={20} />}
                  sx={{ p: 0 }}
                  color={'darkGenoaBlue'}
                />
                <label>Cidade</label>
              </ThemeProvider>
            </div>
          </div>
        </div>

        <div className="input-categories-checkbox-container">
          <span className="input-checkbox-row-title">
            <MdOutlineDoubleArrow className="arrow-icon" />
            Contato
          </span>
          <div className="input-checkbox-container-row">
            <div className="input-checkbox-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  name="email"
                  checked={camposSelected['email']}
                  onChange={handleCheckboxClickHandler}
                  // icon={<IoMdRadioButtonOff size={20} />}
                  // checkedIcon={<IoMdRadioButtonOn size={20} />}
                  sx={{ p: 0 }}
                  color={'darkGenoaBlue'}
                />
                <label>E-mail</label>
              </ThemeProvider>
            </div>
            <div className="input-checkbox-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  name="telefone_fixo"
                  checked={camposSelected['telefone_fixo']}
                  onChange={handleCheckboxClickHandler}
                  // icon={<IoMdRadioButtonOff size={20} />}
                  // checkedIcon={<IoMdRadioButtonOn size={20} />}
                  sx={{ p: 0 }}
                  color={'darkGenoaBlue'}
                />
                <label>Telefone fixo</label>
              </ThemeProvider>
            </div>
            <div className="input-checkbox-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  name="telefone_celular"
                  checked={camposSelected['telefone_celular']}
                  onChange={handleCheckboxClickHandler}
                  // icon={<IoMdRadioButtonOff size={20} />}
                  // checkedIcon={<IoMdRadioButtonOn size={20} />}
                  sx={{ p: 0 }}
                  color={'darkGenoaBlue'}
                />
                <label>Telefone celular</label>
              </ThemeProvider>
            </div>
          </div>

          <div className="input-categories-checkbox-container second-row">
            <span className="input-checkbox-row-title">
              <MdOutlineDoubleArrow className="arrow-icon" /> Dados de Vínculo
            </span>
            <div className="input-checkbox-container-row">
              <div className="input-checkbox-container">
                <ThemeProvider theme={theme}>
                  <Checkbox
                    size="small"
                    name="cargo"
                    checked={camposSelected['cargo']}
                    onChange={handleCheckboxClickHandler}
                    // icon={<IoMdRadioButtonOff size={20} />}
                    // checkedIcon={<IoMdRadioButtonOn size={20} />}
                    sx={{ p: 0 }}
                    color={'darkGenoaBlue'}
                  />
                  <label>Cargo</label>
                </ThemeProvider>
              </div>
              <div className="input-checkbox-container">
                <ThemeProvider theme={theme}>
                  <Checkbox
                    size="small"
                    name="data_admissao"
                    checked={camposSelected['data_admissao']}
                    onChange={handleCheckboxClickHandler}
                    // icon={<IoMdRadioButtonOff size={20} />}
                    // checkedIcon={<IoMdRadioButtonOn size={20} />}
                    sx={{ p: 0 }}
                    color={'darkGenoaBlue'}
                  />
                  <label>Data de admissão</label>
                </ThemeProvider>
              </div>
              <div className="input-checkbox-container">
                <ThemeProvider theme={theme}>
                  <Checkbox
                    size="small"
                    name="plano"
                    checked={camposSelected['plano']}
                    onChange={handleCheckboxClickHandler}
                    // icon={<IoMdRadioButtonOff size={20} />}
                    // checkedIcon={<IoMdRadioButtonOn size={20} />}
                    sx={{ p: 0 }}
                    color={'darkGenoaBlue'}
                  />
                  <label>Plano(s) contratado(s)</label>
                </ThemeProvider>
              </div>
              <div className="input-checkbox-container">
                <ThemeProvider theme={theme}>
                  <Checkbox
                    size="small"
                    name="matricula_funcional"
                    checked={camposSelected['matricula_funcional']}
                    onChange={handleCheckboxClickHandler}
                    // icon={<IoMdRadioButtonOff size={20} />}
                    // checkedIcon={<IoMdRadioButtonOn size={20} />}
                    sx={{ p: 0 }}
                    color={'darkGenoaBlue'}
                  />
                  <label>Matrícula</label>
                </ThemeProvider>
              </div>
            </div>
          </div>
        </div>

        <div className="input-categories-checkbox-container">
          <span className="input-checkbox-row-title">
            <MdOutlineDoubleArrow className="arrow-icon" /> Dados Bancários
          </span>
          <div className="input-checkbox-container-row">
            <div className="input-checkbox-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  name="agencia"
                  checked={camposSelected['agencia']}
                  onChange={handleCheckboxClickHandler}
                  // icon={<IoMdRadioButtonOff size={20} />}
                  // checkedIcon={<IoMdRadioButtonOn size={20} />}
                  sx={{ p: 0 }}
                  color={'darkGenoaBlue'}
                />
                <label>Agência</label>
              </ThemeProvider>
            </div>
            <div className="input-checkbox-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  name="conta"
                  checked={camposSelected['conta']}
                  onChange={handleCheckboxClickHandler}
                  // icon={<IoMdRadioButtonOff size={20} />}
                  // checkedIcon={<IoMdRadioButtonOn size={20} />}
                  sx={{ p: 0 }}
                  color={'darkGenoaBlue'}
                />
                <label>Conta corrente</label>
              </ThemeProvider>
            </div>
            <div className="input-checkbox-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  name="digito_conta"
                  checked={camposSelected['digito_conta']}
                  onChange={handleCheckboxClickHandler}
                  // icon={<IoMdRadioButtonOff size={20} />}
                  // checkedIcon={<IoMdRadioButtonOn size={20} />}
                  sx={{ p: 0 }}
                  color={'darkGenoaBlue'}
                />
                <label>Dígito da Conta corrente</label>
              </ThemeProvider>
            </div>
            <div className="input-checkbox-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  name="banco"
                  checked={camposSelected['banco']}
                  onChange={handleCheckboxClickHandler}
                  // icon={<IoMdRadioButtonOff size={20} />}
                  // checkedIcon={<IoMdRadioButtonOn size={20} />}
                  sx={{ p: 0 }}
                  color={'darkGenoaBlue'}
                />
                <label>Banco</label>
              </ThemeProvider>
            </div>
          </div>

          <div className="input-categories-checkbox-container second-row">
            <span className="input-checkbox-row-title">
              <MdOutlineDoubleArrow className="arrow-icon" />
              IMC
            </span>
            <div className="input-checkbox-container-row">
              <div className="input-checkbox-container">
                <ThemeProvider theme={theme}>
                  <Checkbox
                    size="small"
                    name="altura"
                    checked={camposSelected['altura']}
                    onChange={handleCheckboxClickHandler}
                    // icon={<IoMdRadioButtonOff size={20} />}
                    // checkedIcon={<IoMdRadioButtonOn size={20} />}
                    sx={{ p: 0 }}
                    color={'darkGenoaBlue'}
                  />
                  <label>Altura</label>
                </ThemeProvider>
              </div>
              <div className="input-checkbox-container">
                <ThemeProvider theme={theme}>
                  <Checkbox
                    size="small"
                    name="peso"
                    checked={camposSelected['peso']}
                    onChange={handleCheckboxClickHandler}
                    // icon={<IoMdRadioButtonOff size={20} />}
                    // checkedIcon={<IoMdRadioButtonOn size={20} />}
                    sx={{ p: 0 }}
                    color={'darkGenoaBlue'}
                  />
                  <label>Peso</label>
                </ThemeProvider>
              </div>
            </div>
          </div>
        </div>

        <div className="input-categories-checkbox-container">
          <span className="input-checkbox-row-title">
            <MdOutlineDoubleArrow className="arrow-icon" /> Documentos
          </span>
          <div className="input-checkbox-container-row">
            <div className="input-checkbox-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  name="documento_scan_rg"
                  checked={camposSelected['documento_scan_rg']}
                  onChange={handleCheckboxClickHandler}
                  // icon={<IoMdRadioButtonOff size={20} />}
                  // checkedIcon={<IoMdRadioButtonOn size={20} />}
                  sx={{ p: 0 }}
                  color={'darkGenoaBlue'}
                />
                <label>Scan RG</label>
              </ThemeProvider>
            </div>
            <div className="input-checkbox-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  name="scan_comprovante_residencia"
                  checked={camposSelected['scan_comprovante_residencia']}
                  onChange={handleCheckboxClickHandler}
                  // icon={<IoMdRadioButtonOff size={20} />}
                  // checkedIcon={<IoMdRadioButtonOn size={20} />}
                  sx={{ p: 0 }}
                  color={'darkGenoaBlue'}
                />
                <label>Comprovante de residência</label>
              </ThemeProvider>
            </div>
            <div className="input-checkbox-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  name="scan_carteira_trabalho"
                  checked={camposSelected['scan_carteira_trabalho']}
                  onChange={handleCheckboxClickHandler}
                  // icon={<IoMdRadioButtonOff size={20} />}
                  // checkedIcon={<IoMdRadioButtonOn size={20} />}
                  sx={{ p: 0 }}
                  color={'darkGenoaBlue'}
                />
                <label>Carteira de trabalho</label>
              </ThemeProvider>
            </div>
            <div className="input-checkbox-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  name="scan_passaporte"
                  checked={camposSelected['scan_passaporte']}
                  onChange={handleCheckboxClickHandler}
                  // icon={<IoMdRadioButtonOff size={20} />}
                  // checkedIcon={<IoMdRadioButtonOn size={20} />}
                  sx={{ p: 0 }}
                  color={'darkGenoaBlue'}
                />
                <label>Passaporte</label>
              </ThemeProvider>
            </div>
            <div className="input-checkbox-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  name="scan_carta_inclusao"
                  checked={camposSelected['scan_carta_inclusao']}
                  onChange={handleCheckboxClickHandler}
                  // icon={<IoMdRadioButtonOff size={20} />}
                  // checkedIcon={<IoMdRadioButtonOn size={20} />}
                  sx={{ p: 0 }}
                  color={'darkGenoaBlue'}
                />
                <label>Carta de inclusão</label>
              </ThemeProvider>
            </div>
            <div className="input-checkbox-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  name="scan_carta_permanencia"
                  checked={camposSelected['scan_carta_permanencia']}
                  onChange={handleCheckboxClickHandler}
                  // icon={<IoMdRadioButtonOff size={20} />}
                  // checkedIcon={<IoMdRadioButtonOn size={20} />}
                  sx={{ p: 0 }}
                  color={'darkGenoaBlue'}
                />
                <label>Carta de permanência</label>
              </ThemeProvider>
            </div>
            <ClickAwayListener onClickAway={() => setOpenFichaProposta(false)}>
              <div className="input-checkbox-container">
                <ThemeProvider theme={theme}>
                  <Checkbox
                    size="small"
                    name="scan_carta_permanencia"
                    checked={camposSelected['ficha_proposta']}
                    indeterminate={openFichaProposta}
                    onChange={fichaPropostaCheckboxClickHandler}
                    // icon={<IoMdRadioButtonOff size={20} />}
                    // checkedIcon={<IoMdRadioButtonOn size={20} />}
                    sx={{ p: 0 }}
                    color={'darkGenoaBlue'}
                  />
                  <label>Ficha proposta</label>
                  {camposSelected['ficha_proposta'] ? (
                    <AiOutlineDownload
                      onClick={downloadFichaPropostaClickHandler}
                      className="download_icon"
                      size={14}
                    />
                  ) : (
                    ''
                  )}
                  <Popper
                    anchorEl={anchorEl}
                    open={openFichaProposta}
                    placement="bottom-start"
                    transition
                    disablePortal
                  >
                    <Paper elevation={4} className="ficha-proposta-paper">
                      <div className="paper_content">
                        {renderFichaPropostaPopper()}
                      </div>
                    </Paper>
                  </Popper>
                </ThemeProvider>
              </div>
            </ClickAwayListener>
          </div>
        </div>

        <div className="input-categories-checkbox-container documentos-adicionais-column">
          <span className="input-checkbox-row-title">
            <MdOutlineDoubleArrow className="arrow-icon" />
            Documentos adicionais
          </span>
          {documentosAdicionais?.map((item, index) => (
            <div className="input-checkbox-container-row">
              <ClickAwayListener
                onClickAway={
                  index === documentoAdicionalSelecionado
                    ? resetDocumentoSelecionado
                    : () => {}
                }
              >
                <div className="input-checkbox-container">
                  <ThemeProvider theme={theme}>
                    <Checkbox
                      size="small"
                      name="altura"
                      checked={item['selected']}
                      onChange={() => checkboxDocumentoClickHandler(index)}
                      // icon={<IoMdRadioButtonOff size={20} />}
                      // checkedIcon={<IoMdRadioButtonOn size={20} />}
                      sx={{ p: 0 }}
                      color={'darkGenoaBlue'}
                    />
                    <div
                      onClick={() =>
                        index !== documentoAdicionalSelecionado
                          ? selecionarDocumentoClickHandler(index)
                          : {}
                      }
                      className="documento-adicional-info"
                    >
                      {index === documentoAdicionalSelecionado ? (
                        <>
                          <TextField
                            type="text"
                            autoFocus="true"
                            value={nomeDocumentoSelecionadoEditado}
                            className="input-name"
                            id={index}
                            multiline
                            unstyled
                            variant="standard"
                            onKeyDownCapture={keyPressDocumentoInputHandler}
                            onChange={editNomeDocumentoAdicionalHandler}
                            style={{
                              width: documentoNomeWidth,
                              fontFamily: 'Avenir Next Geo W05 Regular'
                            }}
                          />
                          <span id="hidden-span">
                            {nomeDocumentoSelecionadoEditado}
                          </span>
                        </>
                      ) : (
                        <label id={`name-span-${index}`}>{item['name']}</label>
                      )}
                    </div>
                    <div className="buttons-container">
                      {index === documentoAdicionalSelecionado ? (
                        <>
                          <ImCheckmark
                            className="check-icon"
                            onClick={sendDocumentosAdicionaisUpdate}
                          />
                          <ImCross
                            className="cross-icon"
                            onClick={resetDocumentoSelecionado}
                          />
                        </>
                      ) : (
                        <HiTrash
                          className="thrash-icon"
                          onClick={() => deleteDocumentoClickHandler(index)}
                        />
                      )}
                    </div>
                  </ThemeProvider>
                </div>
              </ClickAwayListener>
            </div>
          ))}
          <div className="adicionar-documento-row">
            <button onClick={adicionarDocumentoAdicional}>
              <BiPlus className="plus-icon" />
              <span>adicionar documento</span>
            </button>
          </div>
        </div>
      </div>
      <BottomBar />
    </CamposInclusaoWrapper>
  );
};

export default CamposInclusaoContrato;
