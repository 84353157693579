import { Button } from '@material-ui/core';
import ReactDatePicker from 'react-datepicker';
import { BiMale } from 'react-icons/bi';
import { FaTooth } from 'react-icons/fa';
import { GiHealthNormal } from 'react-icons/gi';
import ReactInputMask from 'react-input-mask';
import relatives from 'utils/arrays/relatives';
import HealthEditForm from './HealthEditForm';
import DentalEditForm from './DentalEditForm';

const FormEditPersonalInfo = ({
  data,
  contratos,
  nome,
  setNome,
  cpf,
  setCpf,
  email,
  setEmail,
  telefone,
  setTelefone,
  dataNascimento,
  setDataNascimento,
  dataVinculo,
  setDataVinculo,
  sexo,
  setSexo,
  grauParentesco,
  setGrauParentesco,
  showConfirmationMessage,
  confirmationMessage,
  showErrorMessage,
  errorMessage,
  planoSaude,
  setPlanoSaude,
  planoOdonto,
  setPlanoOdonto,
  seguradoraSaude,
  setSeguradoraSaude,
  seguradoraOdonto,
  setSeguradoraOdonto,
  numeroCarteirinhaSaude,
  setNumeroCarteirinhaSaude,
  numeroCarteirinhaOdonto,
  setNumeroCarteirinhaOdonto,
  dataEntradaSaude,
  setDataEntradaSaude,
  dataEntradaOdonto,
  setDataEntradaOdonto,
  onSubmit,
  initialContractsOptions,
  renderHealthContractsOptionsToEdit,
  renderDentalContractsOptionsToEdit,
  setInitialContractsOptions,
  planToEdit,
  setPlanToEdit
}) => {
  const renderMessage = () => {
    if (showConfirmationMessage) {
      return (
        <div className="message-container">
          <span className="confirmation-message">{confirmationMessage}</span>
        </div>
      );
    }

    if (showErrorMessage) {
      return (
        <div className="message-container">
          <span className="error-message">{errorMessage}</span>
        </div>
      );
    }
  };

  const seguros = {
    1: <GiHealthNormal size={14} />,
    2: <FaTooth size={14} />,
    3: <BiMale size={14} />
  };

  const contratoTipo = {
    1: 'Plano de saúde',
    2: 'Plano odontológico',
    3: 'Seguro de vida'
  };

  const renderContratoTitle = (contrato) => {
    return (
      <span>
        {seguros[contrato.produto_id]}
        {contratoTipo[contrato.produto_id]}
      </span>
    );
  };

  const phoneMask = () => {
    return telefone[5] !== '9' ? '(99) 9999 - 9999' : '(99) 99999 - 9999';
  };

  const updatePlanoSaudeInitialValue = (newSeguradoraSaude) => {
    const planosSeguradoraSaude = contratos.find(
      (contrato) =>
        contrato.produto_id === 1 &&
        contrato.contrato_id === Number(newSeguradoraSaude)
    );

    setPlanoSaude(planosSeguradoraSaude?.tipo_planos[0].tipo);
  };

  const updatePlanoOdontoInitialValue = (newSeguradoraOdonto) => {
    const planosSeguradoraOdonto = contratos.find(
      (contrato) =>
        contrato.produto_id === 2 &&
        contrato.contrato_id === Number(newSeguradoraOdonto)
    );

    setPlanoOdonto(planosSeguradoraOdonto?.tipo_planos[0].tipo);
  };

  const renderSeguradoraOptions = (userContrato) => {
    if (data.tipo_beneficiario === 'Titular') {
      if (userContrato.produto_id === 1) {
        const seguradorasVida = contratos.filter(
          (contrato) => contrato.produto_id === 1
        );
        return seguradorasVida?.map((contrato) => {
          return contrato?.numero_apolice ? (
            <option value={contrato.contrato_id}>
              {`${contrato.seguradora_nome} (${contrato.numero_apolice})`}
            </option>
          ) : (
            <option value={contrato.contrato_id}>
              {contrato.seguradora_nome}
            </option>
          );
        });
      }

      if (userContrato.produto_id === 2) {
        const seguradorasVida = contratos.filter(
          (contrato) => contrato.produto_id === 2
        );
        return seguradorasVida?.map((contrato) => {
          return contrato?.numero_apolice ? (
            <option value={contrato.contrato_id}>
              {`${contrato.seguradora_nome} (${contrato.numero_apolice})`}
            </option>
          ) : (
            <option value={contrato.contrato_id}>
              {contrato.seguradora_nome}
            </option>
          );
        });
      }
    } else {
      if (userContrato.produto_id === 1) {
        const seguradorasVida = data?.contratos.filter(
          (contrato) => contrato.produto_id === 1
        );
        return seguradorasVida?.map((contrato) => {
          return contrato?.numero_apolice ? (
            <option value={contrato.contrato_id}>
              {`${contrato.seguradora_nome} (${contrato.numero_apolice})`}
            </option>
          ) : (
            <option value={contrato.contrato_id}>
              {contrato.seguradora_nome}
            </option>
          );
        });
      }

      if (userContrato.produto_id === 2) {
        const seguradorasVida = data?.contratos.filter(
          (contrato) => contrato.produto_id === 2
        );
        return seguradorasVida?.map((contrato) => {
          return contrato?.numero_apolice ? (
            <option value={contrato.contrato_id}>
              {`${contrato.seguradora_nome} (${contrato.numero_apolice})`}
            </option>
          ) : (
            <option value={contrato.contrato_id}>
              {contrato.seguradora_nome}
            </option>
          );
        });
      }
    }
  };

  const renderPlanosOptions = (userContrato) => {
    if (data?.tipo_beneficiario === 'Titular') {
      if (userContrato.produto_id === 1 && seguradoraSaude) {
        const planosSeguradoraSaude = contratos?.filter(
          (contrato) =>
            contrato.produto_id === 1 &&
            contrato.contrato_id === Number(seguradoraSaude)
        );

        return planosSeguradoraSaude[0]?.tipo_planos?.map((planoSaude) => (
          <option value={planoSaude.tipo}>{planoSaude.tipo}</option>
        ));
      }

      if (userContrato.produto_id === 2 && seguradoraOdonto) {
        const planosSeguradoraOdonto = contratos.filter(
          (contrato) =>
            contrato.produto_id === 2 &&
            contrato.contrato_id === Number(seguradoraOdonto)
        );

        return planosSeguradoraOdonto[0]?.tipo_planos?.map((planoOdonto) => (
          <option value={planoOdonto.tipo}>{planoOdonto.tipo}</option>
        ));
      }
    } else {
      if (userContrato.produto_id === 1 && seguradoraSaude) {
        const planosSeguradoraSaude = data?.contratos.find(
          (contrato) =>
            contrato.produto_id === 1 &&
            contrato.contrato_id === Number(seguradoraSaude)
        );

        return (
          <option value={planosSeguradoraSaude.tipo_plano}>
            {planosSeguradoraSaude.tipo_plano}
          </option>
        );
      }

      if (userContrato.produto_id === 2 && seguradoraOdonto) {
        const planosSeguradoraOdonto = data?.contratos?.find(
          (contrato) =>
            contrato.produto_id === 2 &&
            contrato.contrato_id === Number(seguradoraOdonto)
        );

        return (
          <option value={planosSeguradoraOdonto.tipo_plano}>
            {planosSeguradoraOdonto.tipo_plano}
          </option>
        );
      }
    }
  };

  return (
    <>
      <section className="infos">
        <div className="row">
          <div className="column">
            <label>Nome</label>
            <input
              type={'text'}
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
          </div>
          <div className="column">
            <label>CPF</label>
            <ReactInputMask
              mask="999.999.999-99"
              value={cpf}
              onChange={(e) => setCpf(e.target.value)}
              maskChar={false}
              type={'text'}
            />
          </div>
        </div>
        <div className="row">
          <div className="column">
            <label>E-mail</label>
            <input
              type={'text'}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="column">
            <label>Telefone</label>
            <ReactInputMask
              type={'text'}
              value={telefone}
              mask={phoneMask()}
              maskChar={null}
              onChange={(e) => setTelefone(e.target.value)}
              autoComplete="new-email"
            />
          </div>
        </div>
        <div className="row">
          <div className="column">
            <label>Data de nascimento</label>
            <ReactDatePicker
              className="input-data"
              selected={dataNascimento}
              locale="ptBR"
              onChange={(date) => setDataNascimento(date)}
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className="column">
            <label>Data de vínculo</label>
            <ReactDatePicker
              className="input-data"
              selected={dataVinculo}
              locale="ptBR"
              onChange={(date) => setDataVinculo(date)}
              dateFormat="dd/MM/yyyy"
            />
          </div>
        </div>

        <div className="row">
          <div className="column">
            <label>Sexo</label>
            <select value={sexo} onChange={(e) => setSexo(e.target.value)}>
              <option disabled value="" style={{ fontWeight: 'bold' }}>
                Selecione
              </option>
              <option>Masculino</option>
              <option>Feminino</option>
              <option>Outros</option>
            </select>
          </div>
          {data?.tipo_beneficiario === 'Dependente' ? (
            <div className="column">
              <label>Grau de parentesco</label>
              <select
                value={grauParentesco}
                onChange={(e) => setGrauParentesco(e.target.value)}
              >
                <option disabled value="" style={{ fontWeight: 'bold' }}>
                  Selecione
                </option>
                {relatives.map((relative) => (
                  <option value={relative.value}>{relative.option}</option>
                ))}
              </select>
            </div>
          ) : (
            ''
          )}
        </div>
      </section>
      {initialContractsOptions === 'initial' ? (
        <div className="infos-planos">
          {renderHealthContractsOptionsToEdit()}
          {renderDentalContractsOptionsToEdit()}
        </div>
      ) : (
        <section className="infos-planos">
          {data?.contratos?.map((contrato) => {
            if (
              contrato.contrato_beneficiario_status === 'Inativo' &&
              contrato.movimentacao_remocao &&
              contrato.contrato_beneficiario_data_exclusao
            ) {
              return '';
            }
            if (contrato.produto_id === 1) {
              return (
                <HealthEditForm
                  contrato={contrato}
                  renderContratoTitle={renderContratoTitle}
                  seguradoraSaude={seguradoraSaude}
                  setSeguradoraSaude={setSeguradoraSaude}
                  updatePlanoSaudeInitialValue={updatePlanoSaudeInitialValue}
                  renderSeguradoraOptions={renderSeguradoraOptions}
                  planoSaude={planoSaude}
                  setPlanoSaude={setPlanoSaude}
                  renderPlanosOptions={renderPlanosOptions}
                  numeroCarteirinhaSaude={numeroCarteirinhaSaude}
                  setNumeroCarteirinhaSaude={setNumeroCarteirinhaSaude}
                  dataEntradaSaude={dataEntradaSaude}
                  setDataEntradaSaude={setDataEntradaSaude}
                  setInitialContractsOptions={setInitialContractsOptions}
                  planToEdit={planToEdit}
                  setPlanToEdit={setPlanToEdit}
                />
              );
            }
            if (contrato.produto_id === 2) {
              return (
                <DentalEditForm
                  contrato={contrato}
                  renderContratoTitle={renderContratoTitle}
                  seguradoraOdonto={seguradoraOdonto}
                  setSeguradoraOdonto={setSeguradoraOdonto}
                  updatePlanoOdontoInitialValue={updatePlanoOdontoInitialValue}
                  renderSeguradoraOptions={renderSeguradoraOptions}
                  planoOdonto={planoOdonto}
                  setPlanoOdonto={setPlanoOdonto}
                  renderPlanosOptions={renderPlanosOptions}
                  numeroCarteirinhaOdonto={numeroCarteirinhaOdonto}
                  setNumeroCarteirinhaOdonto={setNumeroCarteirinhaOdonto}
                  dataEntradaOdonto={dataEntradaOdonto}
                  setDataEntradaOdonto={setDataEntradaOdonto}
                  setInitialContractsOptions={setInitialContractsOptions}
                  planToEdit={planToEdit}
                  setPlanToEdit={setPlanToEdit}
                />
              );
            }
          })}
        </section>
      )}

      <div className="button-container">
        <Button className={'send-button'} onClick={onSubmit}>
          <span>Editar usuário</span>
        </Button>
        {renderMessage()}
      </div>
    </>
  );
};

export default FormEditPersonalInfo;
