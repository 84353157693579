import { BiSearchAlt } from 'react-icons/bi';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { Controller } from 'react-hook-form';
import { FormControl } from '@material-ui/core';
import ReactDatePicker from 'react-datepicker';
import { Consult, FilterList } from './styles';

const InitialFilters = ({
  control,
  errors,
  setDateFilterValue,
  addFilters,
  dateFilterValue,
  handleAllFiltersSearch,
  searchFiltersOnClick
}) => {
  const dateFilterChangeHandler = ({ target }) => {
    setDateFilterValue(target.value);
  };

  return (
    <Consult>
      <div className="search">
        <label>Consultar por título de Ticket</label>
        <input
          type="text"
          value={addFilters.id_ticket}
          name="id_ticket"
          placeholder="Pesquise por Tickets..."
          onChange={(e) => handleAllFiltersSearch(e)}
        ></input>
      </div>
      <FilterList>
        <div className="date-filter-container">
          <div className="date-filter">
            <label>Filtrar por data de criação</label>
            <div className="data-checkbox-container">
              <FormControl>
                <RadioGroup
                  className="checkbox-data-group-container"
                  onChange={dateFilterChangeHandler}
                  value={dateFilterValue}
                >
                  <FormControlLabel
                    value="desde inicio"
                    control={<Radio size="small" className="radio-checkbox" />}
                    label="Desde o Início"
                  />
                  <FormControlLabel
                    value="ultima semana"
                    control={<Radio size="small" className="radio-checkbox" />}
                    label="Última Semana"
                  />
                  <FormControlLabel
                    value="ultimo mes"
                    control={<Radio size="small" className="radio-checkbox" />}
                    label="Último Mês"
                  />
                  <FormControlLabel
                    value="data personalizada"
                    control={<Radio size="small" className="radio-checkbox" />}
                    label="Data Personalizada:"
                  />
                </RadioGroup>
                <div className="datepicker-box-space">
                  <div
                    className={
                      dateFilterValue === 'data personalizada'
                        ? 'datepicker-group-container'
                        : 'datepicker-group-container-hidden'
                    }
                  >
                    <div className="datepicker-selector-container">
                      <div className="span-date-container">
                        <span>Data Inicial:</span>
                      </div>
                      {errors.initial_date && (
                        <span className="errorsMsg">Campo obrigatório</span>
                      )}
                      <Controller
                        name="initial_date"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <ReactDatePicker
                            className="input-data"
                            selected={value}
                            locale="ptBR"
                            onChange={onChange}
                            placeholderText="..."
                            maxDate={new Date()}
                            dateFormat="dd/MM/yyyy"
                          />
                        )}
                      />
                    </div>
                    <div className="datepicker-selector-container">
                      <div className="span-date-container">
                        <span>Data Final:</span>
                      </div>
                      {errors.final_date && (
                        <span className="errorsMsg">Campo obrigatório</span>
                      )}
                      <Controller
                        name="final_date"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <ReactDatePicker
                            className="input-data"
                            selected={value}
                            locale="ptBR"
                            onChange={onChange}
                            placeholderText="..."
                            maxDate={new Date()}
                            dateFormat="dd/MM/yyyy"
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </FormControl>
            </div>
          </div>
        </div>
      </FilterList>

      <div className="filters">
        <button
          className="searchFilter"
          type="button"
          onClick={() => searchFiltersOnClick()}
        >
          <BiSearchAlt />
        </button>
      </div>
    </Consult>
  );
};

export default InitialFilters;
