import Button from 'components/DS/Button';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import { typeColumns } from '../utils';

const DownloadPersonalInformation = ({ personalInformation }) => {
  const downloadExcelFile = () => {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Beneficiários', {
      properties: { tabColor: { argb: '456AED' } }
    });

    worksheet.columns = typeColumns;

    personalInformation?.titulares?.forEach((titular) => {
      // Adiciona o titular à planilha
      const titularData = {};
      worksheet.columns.forEach((column) => {
        titularData[column.key] = titular[column.key];
      });
      worksheet.addRow(titularData);

      // Adiciona os dependentes do titular à planilha
      titular?.dependentes?.forEach((dependente) => {
        const dependenteData = {};
        worksheet.columns.forEach((column) => {
          dependenteData[column.key] = dependente[column.key];
        });
        worksheet.addRow(dependenteData);
      });
    });

    // Itera sobre cada linha e célula para aplicar a máscara de CPF
    // worksheet.eachRow({ includeEmpty: false }, function (row, rowNum) {
    //   row.eachCell({ includeEmpty: false }, function (cell, colNumber) {
    //     const column = worksheet.getColumn(colNumber);
    //     if (column.key === 'cpf' && typeof cell.value === 'string') {
    //       cell.value = Number(cell.value);
    //     }
    //   });
    // });

    saveFile('Planilha de Dados Pessoais', workbook);
  };

  async function saveFile(fileName, workbook) {
    const xls64 = await workbook.xlsx.writeBuffer({ base64: true });
    saveAs(
      new Blob([xls64], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }),
      fileName
    );
  }

  return (
    <Button variant="transparent" onClick={downloadExcelFile}>
      <img
        width="25"
        height="25"
        src="https://img.icons8.com/color/48/microsoft-excel-2019--v1.png"
        alt="microsoft-excel-2019--v1"
      />
      Download dos formulários aprovados
    </Button>
  );
};

export default DownloadPersonalInformation;
