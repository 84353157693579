import styled from 'styled-components';
import colors from 'styles/colors';

export const Wrapper = styled.div`
  padding: 10px;

  .back {
    margin-top: 10px;
    margin-bottom: 20px;
    .back-btn {
      display: flex;
      align-items: center;
      background: none;
      border: none;
      color: ${colors.genoaDarkBlue};
      border-radius: 13px;
    }
    span {
      font-size: 14px;
      cursor: pointer;
      font-family: 'Avenir Next GEO W05 Bold';
      color: ${colors.genoaDarkBlue};
    }
    svg {
      font-size: 1.5rem;
      cursor: pointer;
    }
  }

  form {
    .grids {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 3rem;

      .col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        row-gap: 10px;
      }
    }
    .atualizacao-title {
      color: ${colors.genoaBlue};
      font-size: 17px;
      font-family: 'Avenir Next GEO W05 Bold';
      margin-bottom: 30px;
    }
    input {
      font-family: 'Avenir Next GEO W05 Regular';
    }
  }

  .planos-container {
    margin-top: 30px;

    .atualizacao-title {
      color: ${colors.genoaBlue};
      font-size: 17px;
      font-family: 'Avenir Next GEO W05 Bold';
      margin-bottom: 30px;
    }
  }
  .row {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .dados-receita {
    margin: 10px 0;
  }
  .label-fields {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .remove-btn-area {
    display: flex;
    justify-content: flex-end;
    padding-right: 100px;
    margin-top: 5px;
  }
  button.remove-field {
    background: none;
    border: none;
    color: #ff0757;
    cursor: pointer;
    font-size: 12px;
    font-family: 'Avenir Next GEO W05 Demi';
  }

  label.default {
    color: ${colors.genoaGrey};
    font-size: 17px;
    font-family: 'Avenir Next GEO W05 Demi';
    margin-bottom: 10px;
    display: block;
  }

  .input-group {
    display: flex;
    flex-direction: column;
    input {
      border: 2px solid ${colors.genoaBlue};
      padding: 12px 13px;
      border-radius: 48px;
      margin: 5px 0 10px 0;
      font-size: 16px;
      height: 48px;
    }
  }

  .input-group-planos {
    display: flex;
    flex-direction: column;
    width: 431.33px;

    input {
      transition: all 200ms ease-in-out;
      border: 2px solid ${colors.genoaBlue};
      padding: 12px 13px;
      border-radius: 48px;
      margin: 5px 0 10px 0;
      font-size: 16px;
      height: 48px;
    }

    input.deactivated {
      border: 2px solid ${colors.genoaDisabledGrey};
      color: ${colors.rhLightGrey};
      background-color: rgba(255, 255, 255, 0.7);
    }

    .plano-container {
      display: grid;
      grid-template-columns: 1fr 70px;
      column-gap: 20px;

      .icon-container {
        display: flex;
        column-gap: 30px;

        button {
          background-color: transparent;
          border-width: 0;
          font-family: inherit;
          font-size: inherit;
          font-style: inherit;
          font-weight: inherit;
          line-height: inherit;
          padding: 0;
          height: 17px;
          width: 17px;
        }

        .bulb-icon {
          font-size: 18px;
          padding-top: 17px;
          transition: color 200ms ease-in-out;

          :hover {
            cursor: pointer;
          }
        }

        .trash-icon {
          padding-top: 17px;
          font-size: 18px;
          color: ${colors.genoaPink};

          :hover {
            cursor: pointer;
          }
        }

        .activated {
          color: ${colors.genoaBlueHover};
        }

        .deactivated {
          color: ${colors.rhLightGrey};
        }
      }
    }
  }

  button.send-btn-odonto {
    background-color: ${colors.white};
    border: 1px ${colors.genoaBlue} solid;
    cursor: pointer;
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 1.4rem;
    color: ${colors.genoaDarkBlue};
    padding: 4px 15px;
    border-radius: 6px;
    margin-left: 15px;
    margin-top: 50px;

    :hover {
      background-color: ${colors.genoaBlue};
      color: white;
      padding: 4px 15px;
      border-radius: 4px;
      cursor: pointer;
    }
  }

  .input-default {
    display: flex;
    font-size: 16px;
    background-color: #fff;
    border: 0.5px solid #62a0e2;
    border-radius: 10px;
    width: 100%;
    height: 48px;
    color: #3b3838;
    justify-content: center;
    box-sizing: border-box;
    align-items: center;
    padding: 10px;
    cursor: pointer;
  }
  .input-default.active {
    background: rgba(110, 62, 193, 0.8);
    color: #fff;
  }

  .button-download {
    display: flex;
    button {
      border: 1px solid #62a0e2;
      padding: 12px 13px;
      border-radius: 10px;
      margin: 5px 0 10px 0;
      font-size: 16px;
      height: 48px;
    }
  }

  .checkbox-area {
    // margin: 10px 0;
    .checkbox-group {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px 0px 20px 0px;
      label.select-label {
        display: flex;
        font-size: 16px;
        background-color: #fff;
        border: 2px solid ${colors.genoaBlue};
        border-radius: 48px;
        width: 100%;
        height: 48px;
        color: #3b3838;
        justify-content: center;
        text-align: center;
        box-sizing: border-box;
        align-items: center;
        padding: 10px;
        cursor: pointer;
      }
      label.select-label.active {
        background: ${colors.genoaBlue};
        color: #fff;
      }
    }
    .coparticipacao-details-active {
      background-color: #fff;
      border: 2px solid ${colors.genoaBlue};
      border-radius: 24px;
      width: 100%;
      opacity: 1;
      font-size: 16px;
      padding: 12px;
      box-sizing: border-box;
      color: ${colors.genoaGrey};
      font-family: 'Avenir Next GEO W05 Regular';
      margin-bottom: 10px;

      ::placeholder {
        text-align: center;
        color: #c1c1c1;
      }

      :focus-visible {
        outline-color: ${colors.genoaBlue};
      }
    }

    .coparticipacao-details-disabled {
      background-color: #bbbbbb;
      border: 2px solid #c3c3c3;
      color: #bbbbbb;
      border-radius: 24px;
      opacity: 0.25;
      padding: 12px;
      box-sizing: border-box;
      text-align: center;
      width: 100%;
      height: 173.6px;
      margin-bottom: 10px;
    }

    .custeio-details {
      background-color: #fff;
      border: 2px solid ${colors.genoaBlue};
      border-radius: 48px;
      opacity: 1;
      font-size: 16px;
      color: ${colors.genoaGrey};
      text-align: center;
      width: 100%;
      height: 48px;
      ::placeholder {
        color: #c1c1c1;
      }
    }
  }

  .multa-details {
    background-color: #fff;
    border: 2px solid ${colors.genoaBlue};
    border-radius: 24px;
    padding: 12px;
    box-sizing: border-box;
    opacity: 1;
    font-size: 16px;
    color: ${colors.genoaGrey};
    font-family: 'Avenir Next GEO W05 Regular';
    width: 100%;

    ::placeholder {
      text-align: center;
      color: #c1c1c1;
    }

    :focus-visible {
      outline-color: ${colors.genoaBlue};
    }
  }

  .apolice-details {
    background-color: #fff;
    border: 2px solid ${colors.genoaBlue};
    border-radius: 24px;
    margin-bottom: 6px;
    padding: 12px;
    opacity: 1;
    font-size: 16px;
    color: ${colors.genoaGrey};
    font-family: 'Avenir Next GEO W05 Regular';
    width: 100%;
    text-align: center;
    height: 48px;
    ::placeholder {
      color: #c1c1c1;
    }

    :focus-visible {
      outline-color: ${colors.genoaBlue};
    }
  }

  .select-group {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    margin-bottom: 6px;
    // margin-top: 15px;

    :first-child {
      margin-top: 0px;
    }

    label {
      margin-bottom: 10px;
    }

    select {
      font-family: 'Avenir Next GEO W05 Regular';
      width: 100%;
      height: 48px;
      text-align: center;
      appearance: none;
      background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 9L0.73686 0.75L10.2631 0.75L5.5 9Z' fill='%23456AED'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position-x: 96%;
      background-position-y: 17px;
      font-size: 16px;
      padding: 0px 10px;
      color: ${colors.genoaGrey};
      border: 2px solid ${colors.genoaBlue};
      border-radius: 48px;
      /* text-align-last: center; */

      :focus-visible {
        outline: none;
      }
    }
  }

  .react-datepicker-wrapper {
    color: ${colors.genoaGrey};
    width: 100%;

    .input-data {
      color: ${colors.genoaGrey};
      border-radius: 48px;
      width: 100%;
      height: 48px;
      background: #ffffff;
      background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 9L0.73686 0.75L10.2631 0.75L5.5 9Z' fill='%23456AED'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position-x: 96%;
      background-position-y: 17px;
      border: 2px solid ${colors.genoaBlue};
      text-align: center;
      margin: 0px 0 6px 0;
      font-size: 16px;

      ::placeholder {
        color: ${colors.genoaGrey};
      }
    }
  }

  .react-datepicker__day--selected {
    background-color: ${colors.genoaBlue};
  }

  .react-datepicker__day--keyboard-selected {
    color: ${colors.genoaBlue};
    background-color: white;
    font-weight: bold;
  }

  .subs-estip {
    .dados-receita {
      margin-top: 0px;
    }
  }

  .email-prefix {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    span {
      border-radius: 10px;
      padding: 12px 13px;
      height: 48px;
      border: 1px solid #62a0e2;
      border-right: none;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      font-size: 1.2rem;
      box-sizing: border-box;
      background: #fff;
    }
    input {
      border-left: none;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      margin: 0px;
      padding: 12px 13px 12px 5px;
      font-size: 16px;
      height: 48px;
      flex: 1;
    }
  }

  .descricao {
    margin-bottom: 10px;
  }
  button.add {
    cursor: pointer;
    border: none;
    color: ${colors.genoaDarkBlue};
    background: none;
    padding: 10px 10px;
    margin: 10px 0 0 0;
    border-radius: 10px;
    font-family: 'Avenir Next GEO W05 bold';
    :hover {
      background: #efefef;
      text-decoration: underline;
    }
  }

  margin-bottom: 30px;

  button.send-btn {
    // background-color: ${colors.white};
    border: none;
    cursor: pointer;
    font-family: 'Avenir Next GEO W05 Bold';
    padding: 10px 10px;
    font-size: 1.3rem;
    color: ${colors.genoaDarkBlue};
    border-radius: 6px;

    :hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .custeio-details-odonto {
    background-color: #fff;
    border: 2px solid ${colors.genoaBlue};
    border-radius: 24px;
    opacity: 1;
    font-size: 16px;
    padding: 12px;
    box-sizing: border-box;
    font-family: 'Avenir Next GEO W05 Regular';
    color: ${colors.genoaGrey};
    text-align: center;
    width: 100%;
    ::placeholder {
      color: #c1c1c1;
    }
  }
  .col-links {
    max-width: 600px;

    .pdb-3 {
      padding-bottom: 3rem;
    }

    input:read-only {
      background: #f3ecff;
    }

    .label-btn {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        cursor: pointer;
        font-family: 'Avenir Next GEO W05 Bold';
        color: #62a0e2;
        font-size: 15px;
      }
    }
  }

  .input-file-group {
    display: flex;
  }

  .label-file {
    font-size: 16px;
    background-color: #f3ecff;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    padding: 5px 5px;
    border: 1px #62a0e2 solid;

    input {
      display: none;
    }
    span {
      display: flex;
      align-items: center;
      color: #62a0e2;

      svg {
        font-size: 1.3rem;
      }
    }
  }

  .btn-vidas {
    padding: 5px 5px;
    font-size: 16px;
    background-color: #f3ecff;
    border-radius: 5px;
    color: #62a0e2;
    cursor: pointer;
    padding: 5px 5px;
    border: 1px #62a0e2 solid;
    font-family: 'Avenir Next GEO W05 Regular';
  }

  .submit-vidas {
    margin-top: 20px;
  }

  .data-resp {
    margin-top: 30px;
    color: #62a0e2;
    font-size: 16px;
    font-family: 'Avenir Next GEO W05 Bold';
  }

  .div-input-files {
    display: flex;
    align-items: center;
  }
  .label-file {
    font-size: 16px;
    background-color: #f3ecff;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    padding: 5px 5px;
    border: 1px #62a0e2 solid;

    input {
      display: none;
    }
    span {
      display: flex;
      align-items: center;
      color: #62a0e2;

      svg {
        font-size: 1.3rem;
      }
    }
  }
  span.error-msg {
    color: #f7365a;
    font-size: 13px;
    margin-bottom: 5px;
    display: inline-block;
  }

  .preview {
    padding: 20px 0;
    box-sizing: border-box;
    width: 100%;

    .logoPreview {
      width: 220px;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  button.btn {
    font-family: 'Avenir Next GEO W05 bold';
    padding: 7px 15px;
    background: #f3ecff;
    border: 1px #62a0e2 solid;
    border-radius: 5px;
    color: #62a0e2;
    cursor: pointer;
    :hover {
      background: #e3d2ff;
    }
    :disabled {
      opacity: 0.5;
    }
  }

  button.btn-clear {
    background: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f76767;
    margin-right: 16px;
    cursor: pointer;
  }

  .MuiSnackbarContent-root {
    background-color: #d8c1ff;
    color: #4c2d82;
    font-family: 'Avenir Next GEO W05 Bold';
  }
`;

export const InputFile = styled.div`
  margin: 20px 0;

  .div-input-files {
    margin-top: 10px;
    display: flex;
    align-items: center;
  }

  .label-file {
    font-size: 16px;
    background-color: #f3ecff;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    padding: 5px 5px;
    border: 1px #62a0e2 solid;

    input {
      display: none;
    }
    span {
      display: flex;
      align-items: center;
      color: #62a0e2;

      svg {
        font-size: 1.3rem;
      }
    }
  }
`;
