import { useState, useEffect } from 'react';
import { DrawerDetalhesWrapper } from './styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { BsCheck, BsCircle, BsFillCheckCircleFill } from 'react-icons/bs';
import { MdClose } from 'react-icons/md';
import CheckBox from '@mui/material/Checkbox';
import moment from 'moment';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import ReactDatePicker from 'react-datepicker';
import { ClickAwayListener } from '@mui/material';
import api from 'services/api';
import services from 'apps/broker/services';

const DrawerTaskDetalhes = ({ isSelected, setTaskSelected, getTasksData }) => {
  const user = useSelector((state) => state.userReducers.user);

  const [checklistValue, setChecklistValue] = useState('');
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [editedDate, setEditedDate] = useState('');
  const [openEditDate, setOpenEditDate] = useState(false);
  const [defaultDescricao, setDefaultDescricao] = useState('');
  const [editedDescricao, setEditedDescricao] = useState('');
  const [openEditDescricao, setOpenEditDescricao] = useState(false);

  const createChecklistHandler = async () => {
    const body = {
      descricao: checklistValue,
      task_id: isSelected?.id,
      usuario_id: isSelected?.usuario_id
    };

    await services.tasks.createChecklist(body);
    setChecklistValue('');
    getTasksData();
  };

  const updateStatusChecklistHandler = async (target, checklist_id) => {
    const checked = target.checked;
    setCheckboxChecked(checked);

    const body = {
      checklist_id
    };

    await services.tasks.updateStatusChecklist(body);
    setChecklistValue('');
    setCheckboxChecked(false);
    getTasksData();
  };

  const reopenChecklistHandler = async (checklist_id) => {
    const body = {
      checklist_id
    };

    await services.tasks.updateStatusReopenChecklist(body);
    setChecklistValue('');
    setCheckboxChecked(false);
    getTasksData();
  };

  const deleteChecklistHandler = (checklist_id) => {
    // await services.tasks.deleteChecklist(checklist_id);
    api
      .delete('/corretor/tasks/checklist', {
        headers: {
          id: checklist_id,
          authorization: `Bearer ${user.token}`
        }
      })
      .then((resp) => {
        getTasksData();
      })
      .catch((err) => console.error(err));
  };

  const updateDateTaskHandler = async () => {
    const body = {
      data_task: new Date(editedDate),
      task_id: isSelected.id
    };

    const response = await services.tasks.updateDate(body);
    if (response.status === 200) {
      setOpenEditDate(false);
      toast.dismiss();
      toast.success('Data da task atualizada com sucesso!', {
        duration: 2500
      });
      getTasksData();
    } else {
      toast.dismiss();
      toast.error('Algo deu errado, tente novamente!', {
        duration: 2500
      });
    }
  };

  const updateDescricaoTaskHandler = async () => {
    const body = {
      descricao: editedDescricao,
      task_id: isSelected.id
    };

    const response = await services.tasks.updateDescription(body);
    if (response.status === 200) {
      setOpenEditDescricao(false);
      toast.dismiss();
      toast.success('A descrição da task foi atualizada!', {
        duration: 2500
      });
      getTasksData();
    } else {
      toast.dismiss();
      toast.error('Algo deu errado, tente novamente mais tarde!', {
        duration: 2500
      });
    }
  };

  useEffect(() => {
    if (openEditDate) {
      updateDateTaskHandler();
    }
  }, [editedDate]);

  useEffect(() => {
    setDefaultDescricao(isSelected?.descricao);
    setEditedDescricao(isSelected?.descricao);
  }, [isSelected]);

  return (
    <DrawerDetalhesWrapper>
      <div className="drawer_header">
        <div className="header_title">{isSelected?.titulo_task}</div>
        <div className="header_subtitle">{`Criada por: ${isSelected?.nome}`}</div>
        <div className="back">
          <span
            onClick={() => setTaskSelected(undefined)}
            className="icon-back"
          >
            <ArrowBackIcon /> Voltar
          </span>
        </div>
      </div>
      <div className="drawer_content">
        <div className="primeira_linha">
          <div className="primeira_coluna">
            <span className="label">Data da task:</span>
            {openEditDate ? (
              <ClickAwayListener onClickAway={() => setOpenEditDate(false)}>
                <div>
                  <ReactDatePicker
                    className={`custom_datepicker ${
                      editedDate ? 'active' : ''
                    }`}
                    autoFocus="true"
                    selected={new Date(isSelected?.data_task)}
                    locale={'ptBR'}
                    minDate={new Date()}
                    onChange={(date) => setEditedDate(date)}
                    onKeyDown={(e) => {
                      if (e?.key === 'Enter') {
                        updateDateTaskHandler();
                      }
                    }}
                    dateFormat="dd/MM/yy"
                  />
                </div>
              </ClickAwayListener>
            ) : (
              <span className="info" onClick={() => setOpenEditDate(true)}>
                {moment(isSelected?.data_task).format('DD/MM/YY')}
              </span>
            )}
          </div>
          <div className="segunda_coluna">
            <span className="label">Data de conclusão:</span>
            <span className="info">
              {isSelected?.data_conclusao
                ? moment(isSelected?.data_conclusao).format('DD/MM/YY')
                : null}
            </span>
          </div>
        </div>

        <div className="segunda_linha">
          <div>
            <span className="label">Descrição da task:</span>
          </div>
          {openEditDescricao ? (
            <div className="descricao_container">
              <div>
                <input
                  type="text"
                  autoFocus="true"
                  className="text_area info"
                  value={editedDescricao}
                  onChange={({ target }) => {
                    setEditedDescricao(target.value);
                  }}
                />
              </div>
              <div className="btn_container">
                <button
                  className="btn_salvar_desc"
                  onClick={() => {
                    if (editedDescricao !== defaultDescricao) {
                      updateDescricaoTaskHandler();
                    }
                  }}
                >
                  Salvar
                </button>

                <button
                  className="btn_salvar_desc"
                  onClick={() => setOpenEditDescricao(false)}
                >
                  Cancelar
                </button>
              </div>
            </div>
          ) : (
            <div
              className="text_area"
              onClick={() => setOpenEditDescricao(true)}
            >
              <div className="info">{isSelected?.descricao}</div>
            </div>
          )}
        </div>

        <div className="terceira_linha">
          <div className="primeira_coluna">
            <span className="label">Estipulante/ Lead:</span>
            <span className="info">
              {isSelected?.estipulante_id
                ? isSelected?.estipulante_razao_social
                : null || isSelected?.lead_potencial_id
                ? isSelected?.lead_potencial_razao_social
                : null}
            </span>
          </div>
          <div className="segunda_coluna">
            <span className="label">Nome fantasia:</span>
            <span className="info">
              {isSelected?.estipulante_id
                ? isSelected?.estipulante_nome_fantasia?.nome_fantasia
                : null || isSelected?.lead_potencial_id
                ? isSelected?.lead_potencial_nome_fantasia?.nome_fantasia
                : null}
            </span>
          </div>
          <div className="terceira_coluna">
            <span className="label">Produto:</span>
            <span className="info">
              {isSelected?.tipo_produto ? isSelected?.tipo_produto : null}
            </span>
          </div>
          <div className="quarta_coluna">
            <span className="label">Contrato:</span>
            <span className="info">
              {isSelected?.seguradora ? isSelected?.seguradora : null}
            </span>
          </div>
          <div className="quarta_coluna">
            <span className="label">Apólice:</span>
            <span className="info">
              {isSelected?.numero_apolice ? isSelected?.numero_apolice : null}
            </span>
          </div>
        </div>
        <div className="quarta_linha">
          <div className="primeira_coluna">
            <span className="label">Checklist</span>
            <div className="container_checklist">
              <input
                className="input_checklist"
                type="text"
                value={checklistValue}
                onChange={(e) => setChecklistValue(e.target.value)}
                defaultValue={''}
              />
              <div className="btn_container">
                <button
                  className="btn_confirm"
                  onClick={() => createChecklistHandler()}
                >
                  <BsCheck />
                </button>
              </div>
            </div>
          </div>
          <div className="segunda_coluna">
            <ul>
              {isSelected?.checklist?.length > 0
                ? isSelected?.checklist?.map((item) => (
                    <li className="check_container">
                      {!item.checklist_closed_at ? (
                        <CheckBox
                          checked={checkboxChecked}
                          icon={<BsCircle />}
                          checkedIcon={<BsFillCheckCircleFill />}
                          onChange={({ target }) => {
                            updateStatusChecklistHandler(
                              target,
                              item.checklist_id
                            );
                          }}
                        />
                      ) : (
                        <CheckBox
                          checked={true}
                          icon={<BsCircle />}
                          checkedIcon={<BsFillCheckCircleFill />}
                          onChange={() => {
                            reopenChecklistHandler(item.checklist_id);
                          }}
                        />
                      )}

                      <span
                        className={`info ${
                          item.checklist_closed_at ? 'checked' : ''
                        }`}
                      >
                        {item.checklist_descricao}
                      </span>
                      <button
                        className="btn_del"
                        onClick={() => {
                          deleteChecklistHandler(item.checklist_id);
                        }}
                      >
                        <MdClose />
                      </button>
                    </li>
                  ))
                : null}
            </ul>
          </div>
        </div>
      </div>
    </DrawerDetalhesWrapper>
  );
};

export default DrawerTaskDetalhes;
