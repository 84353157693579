import styled from 'styled-components';
import colors from 'styles/colors';

export const Div = styled.div`
  .estipulantes-info {
    padding: 5px;
    height: 400px;
  }

  .cols-estipulantes-info {
    display: grid;
    margin-top: 30px;
    grid-template-columns: 425px 90px;
    gap: 15px;
    color: ${colors.genoaDarkBlue};

    > div {
      .title-col {
        font-family: 'Avenir Next GEO W05 Demi';
      }

      .estipulante-name {
        height: 50px;
        font-size: 15px;
        display: flex;
        column-gap: 15px;
      }

      .estipulante-vidas {
        height: 50px;
        font-size: 15px;
        text-align: center;
      }

      .caracteristicas {
        display: grid;
        grid-template-columns: 1.5fr 1fr;

        > div {
          line-height: 26px;
        }
      }
    }
  }
`;
