import React from "react";
import { TabOptionsWrapper } from "./styles-tabOptions";
import { BiBody, BiListCheck, BiX } from "react-icons/bi";
import { FaBuilding, FaHome, FaHospitalUser, FaPeopleArrows, FaUserAlt } from "react-icons/fa";
import { MdAlternateEmail, MdWork } from "react-icons/md";
import { RiBankCard2Fill } from "react-icons/ri";
import { AiOutlineCheck, AiOutlineScan, AiOutlineUpload } from "react-icons/ai";
import { BsCheckLg } from "react-icons/bs";
import { usePostHog } from 'posthog-js/react';

const TabOptions = (
  {
    abaSelected,
    setCount,
    closeDrawer,
    abasDisponiveis,
    setBeneficiariosAdicionais,
    abasCompletas
  }) => {
  const posthog = usePostHog();
  const [height, setHeight] = React.useState(window.innerHeight);

  React.useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight)

    }
    window.addEventListener('resize', handleResize)
  });

  const renderAbaEstipulante = () => {
    return (
      <div
        className={abaSelected === "estipulante" ?
          "aba-option selected" : "aba-option"
        }
        onClick={() => {
          posthog?.capture('Clicou em "Estipulante" (Menu de Navegação)')
          setCount(0)
        }}
      >
        <div className="icon-container">
          <FaBuilding size={19 * height / 745} />
        </div>
        <span>Seleção de Estipulante</span>
        {
          abasCompletas['estipulanteSelecionado'] ? (
            <BsCheckLg
              className="check_icon"
              size={13 * height / 745}
            />
          ) : <div></div>
        }
      </div>
    )
  }

  const renderAbaTipoBeneficiario = () => {
    return (
      <div
        className={abaSelected === "tipo beneficiario" ?
          "aba-option selected" : "aba-option"
        }
        onClick={() => {
          posthog?.capture('Clicou em "Tipo Beneficiário" (Menu de Navegação)')
          setCount(1)
        }}
      >
        <div className="icon-container">
          <FaPeopleArrows size={19 * height / 745} />
        </div>
        <span>Tipo de Beneficiário</span>
        {
          abasCompletas['tipoBeneficiarioSelecionado'] ? (
            <BsCheckLg
              className="check_icon"
              size={13 * height / 745}
            />
          ) : <div></div>
        }
      </div>
    )
  }

  const renderAbaSelecioneTitular = () => {
    if (abasDisponiveis['showSelecioneTitular']) {
      return (
        <div
          className={abaSelected === "escolha titular" ?
            "aba-option selected" : "aba-option"
          }
          onClick={() => {
            posthog?.capture('Clicou em "Titular" (Menu de Navegação)')
            setCount(2)
          }}
        >
          <div className="icon-container">
            <FaHospitalUser size={19 * height / 745} />
          </div>
          <span>Selecione Titular</span>
          {
            abasCompletas['titular'] ? (
              <BsCheckLg
                className="check_icon"
                size={13 * height / 745}
              />
            ) : <div></div>
          }
        </div>
      )
    }
  }

  const renderAbaPlanos = () => {
    if (abasDisponiveis['showDadosPlanos']) {
      return (
        <div
          className={abaSelected === "planos" ?
            "aba-option selected" : "aba-option"
          }
          onClick={() => {
            posthog?.capture('Clicou em "Planos" (Menu de Navegação)')
            setCount(3)
          }}
        >
          <div className="icon-container">
            <BiListCheck size={25 * height / 745} />
          </div>
          <span>Escolha de Plano(s)</span>
          {
            abasCompletas['planoSelecionado'] ? (
              <BsCheckLg
                className="check_icon"
                size={13 * height / 745}
              />
            ) : <div></div>
          }
        </div>
      )
    }
  }

  const renderAbaDadosBasicos = () => {
    if (abasDisponiveis['showDadosBasicos']) {
      return (
        <div
          className={abaSelected === "dados basicos" ?
            "aba-option selected" : "aba-option"
          }
          onClick={() => {
            posthog?.capture('Clicou em "Dados Básicos" (Menu de Navegação)')
            setCount(4)
          }}
        >
          <div className="icon-container">
            <FaUserAlt size={17 * height / 745} />
          </div>
          <span>Dados Básicos</span>
          {
            abasCompletas['dadosBasicos'] ? (
              <BsCheckLg
                className="check_icon"
                size={13 * height / 745}
              />
            ) : <div></div>
          }
        </div>
      )
    }
  }

  const renderAbaDadosEndereco = () => {
    if (abasDisponiveis['showDadosEndereco']) {
      return (
        <div
          className={abaSelected === "endereco" ?
            "aba-option selected" : "aba-option"
          }
          onClick={() => {
            posthog?.capture('Clicou em "Dados Endereço" (Menu de Navegação)')
            setCount(5)
          }}
        >
          <div className="icon-container">
            <FaHome size={21 * height / 745} />
          </div>
          <span>Endereço</span>
          {
            abasCompletas['endereco'] ? (
              <BsCheckLg
                className="check_icon"
                size={13 * height / 745}
              />
            ) : <div></div>
          }
        </div>
      )
    }
  }

  const renderAbaDadosContato = () => {
    if (abasDisponiveis['showDadosContato']) {
      return (
        <div
          onClick={() => {
            posthog?.capture('Clicou em "Dados Contato" (Menu de Navegação)')
            setCount(6)
          }}
          className={abaSelected === "contato" ?
            "aba-option selected" : "aba-option"
          }
        >
          <div className="icon-container">
            <MdAlternateEmail size={21 * height / 745} />
          </div>
          <span>Contato</span>
          {
            abasCompletas['contato'] ? (
              <BsCheckLg
                className="check_icon"
                size={13 * height / 745}
              />
            ) : <div></div>
          }
        </div>
      )
    }
  }

  const renderAbaDadosVinculo = () => {
    if (abasDisponiveis['showDadosVinculo']) {
      return (
        <div
          onClick={() => {
            posthog?.capture('Clicou em "Dados Vínculo" (Menu de Navegação)')
            setCount(7)
          }}
          className={abaSelected === "vinculo" ?
            "aba-option selected" : "aba-option"
          }
        >
          <div className="icon-container">
            <MdWork size={21 * height / 745} />
          </div>
          <span>Vínculo</span>
          {
            abasCompletas['vinculo'] ? (
              <BsCheckLg
                className="check_icon"
                size={13 * height / 745}
              />
            ) : <div></div>
          }
        </div>
      )
    }
  }

  const renderAbaDadosImc = () => {
    if (abasDisponiveis['showDadosImc']) {
      return (
        <div
          onClick={() => {
            posthog?.capture('Clicou em "Dados IMC" (Menu de Navegação)')
            setCount(8)
          }}
          className={abaSelected === "imc" ?
            "aba-option selected" : "aba-option"
          }
        >
          <div className="icon-container">
            <BiBody size={24 * height / 745} />
          </div>
          <span>IMC</span>
          {
            abasCompletas['imc'] ? (
              <BsCheckLg
                className="check_icon"
                size={13 * height / 745}
              />
            ) : <div></div>
          }
        </div>
      )
    }
  }

  const renderAbaDadosBancarios = () => {
    if (abasDisponiveis['showDadosBancarios']) {
      return (
        <div
          onClick={() => {
            posthog?.capture('Clicou em "Dados Bancários" (Menu de Navegação)')
            setCount(9)
          }}
          className={abaSelected === "dados bancarios" ?
            "aba-option selected" : "aba-option"
          }
        >
          <div className="icon-container">
            <RiBankCard2Fill size={21 * height / 745} />
          </div>
          <span>Dados Bancários</span>
          {
            abasCompletas['dadosBancarios'] ? (
              <BsCheckLg
                className="check_icon"
                size={13 * height / 745}
              />
            ) : <div></div>
          }
        </div>
      )
    }
  }

  const renderAbaScanDocumentos = () => {
    if (abasDisponiveis['showScanDocumentos']) {
      return (
        <div
          onClick={() => {
            posthog?.capture('Clicou em "Scan Documentos" (Menu de Navegação)')
            setCount(10)
          }}
          className={abaSelected === "scan documentos" ?
            "aba-option selected" : "aba-option"
          }
        >
          <div className="icon-container">
            <AiOutlineScan size={21 * height / 745} />
          </div>
          <span>Cópia de Documentos</span>


          {
            abasCompletas['scanDocumentos'] ? (
              <BsCheckLg
                className="check_icon"
                size={13 * height / 745}
              />
            ) : <div></div>
          }



        </div>
      )
    }
  }

  const renderAbaFichaProposta = () => {
    if (abasDisponiveis['showFichaProposta']) {
      return (
        <div
          onClick={() => {
            posthog?.capture('Clicou em "Ficha Proposta" (Menu de Navegação)')
            setCount(11)
          }}
          className={abaSelected === "ficha proposta" ?
            "aba-option selected" : "aba-option"
          }
        >
          <div className="icon-container">
            <AiOutlineUpload size={21 * height / 745} />
          </div>
          <span>Ficha proposta</span>
          {
            abasCompletas['fichaProposta'] ? (
              <BsCheckLg
                className="check_icon"
                size={13 * height / 745}
              />
            ) : <div></div>
          }
        </div>
      )
    }
  }

  return (
    <TabOptionsWrapper>
      <div className="close-icon-container">
        <BiX
          className="close-icon"
          size={30 * height / 745}
          onClick={closeDrawer}
        />
      </div>
      <div className="scrollable">
        <div className="aba-options-container">
          {
            renderAbaEstipulante()
          }
          {
            renderAbaTipoBeneficiario()
          }
          {
            renderAbaSelecioneTitular()
          }
          {
            renderAbaPlanos()
          }
          {
            renderAbaDadosBasicos()
          }
          {
            renderAbaDadosEndereco()
          }
          {
            renderAbaDadosContato()
          }
          {
            renderAbaDadosVinculo()
          }
          {
            renderAbaDadosImc()
          }
          {
            renderAbaDadosBancarios()
          }
          {
            renderAbaScanDocumentos()
          }
          {
            renderAbaFichaProposta()
          }
        </div>
      </div>
    </TabOptionsWrapper>
  )
}

export default TabOptions;