import styled from 'styled-components';
import colors from 'styles/colors';

export const Wrapper = styled.div`
  width: 700px;
  box-sizing: content-box;
  min-height: 50vh;
  padding-bottom: 200px;

  header {
    background-color: ${colors.genoaBlue};
    padding: 15px;
    padding-left: 60px;
    padding-right: 60px;
    height: 110px;

    .headerUser {
      margin-top: 10px;
    }

    .nameUser {
      display: flex;
      flex-direction: column;
      color: #fff;
      margin-top: 25px;

      svg {
        font-size: 2.5rem;
        margin-left: -5px;
      }
      .user-company {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        span {
          color: #fff;
          font-size: 18px;
          font-family: 'Avenir Next GEO W05 Bold';
        }

        .header-title-second-row {
          display: flex;
          justify-content: space-between;
          width: 100%;
          line-height: 12px;

          .subtitle-container {
            display: flex;
            align-items: flex-start;

            .header-title-hifen-space {
              display: flex;
              height: 12px;
              align-items: center;
            }
          }

          .movimentacao-button-container {
            span {
              font-size: 14px;
              font-family: 'Avenir Next GEO W05 Demi';
              display: flex;

              :hover {
                cursor: pointer;
                text-decoration: underline;
              }
            }
          }
        }

        .header-title-name {
          font-family: 'Avenir Next GEO W05 Heavy';
          font-size: 18px;
          margin-bottom: 10px;
        }

        .header-title-estipulante {
          display: inline-block;
          font-size: 12px;
          font-family: 'Avenir Next GEO W05 Regular';
          max-width: 290px;
        }

        .header-title-hifen-space {
          margin-right: 9px;
          margin-left: 9px;
        }
      }
    }

    h2 {
      font-family: 'Avenir Next GEO W05 Bold';
      color: #fff;
      font-size: 35px;
      margin: 0px 0 2px 0;
    }
    span.tipo {
      color: #fff;
    }
  }

  section.infos {
    padding: 20px;
    padding-left: 60px;
    padding-right: 60px;
    display: flex;
    flex-direction: column;
    row-gap: 25px;

    h3 {
      font-family: 'Avenir Next GEO W05 bold';
      font-size: 20px;
      color: #3b3838;
    }

    .row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 30px;

      .column {
        display: flex;
        flex-direction: column;

        label {
          font-size: 14px;
          color: ${colors.genoaGrey};
          font-family: 'Avenir Next GEO W05 Demi';
          margin-bottom: 10px;
        }

        input,
        select {
          padding: 0 14px;
          font-family: 'Avenir Next GEO W05 Regular';
          border: 1.5px ${colors.genoaBlue} solid;
          font-size: 12px;
          height: 28px;
          border-radius: 10px;
        }

        .input-data {
          width: 100%;
        }

        .react-datepicker__day--today {
          color: ${colors.genoaBlue};
        }

        .react-datepicker__day--keyboard-selected,
        .react-datepicker__month-text--keyboard-selected,
        .react-datepicker__quarter-text--keyboard-selected,
        .react-datepicker__year-text--keyboard-selected {
          background-color: white;
          color: ${colors.genoaBlue};
          font-weight: bold;
        }

        .react-datepicker__day--selected,
        .react-datepicker__month-text--selected,
        .react-datepicker__quarter-text--selected,
        .react-datepicker__year-text--selected {
          border-radius: 0.3rem;
          background-color: ${colors.genoaBlue};
          color: #fff;
          font-weight: bold;
        }

        select {
          background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 9L0.73686 0.75L10.2631 0.75L5.5 9Z' fill='%23456AED'/%3E%3C/svg%3E%0A");
          background-repeat: no-repeat;
          background-position-x: 96%;
          background-position-y: 9px;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;

          option {
            font-size: 12px;
          }
          :focus-visible {
            outline: 1px ${colors.genoaBlue}f5 auto;
          }
        }
      }
    }

    .message-confirmation-container {
      margin-top: 20px;
      margin-bottom: 50px;

      .sucess-message {
        color: ${colors.genoaBlue};
        font-size: 15px;
        font-family: 'Avenir Next GEO W05 Demi';
      }

      .error-message {
        color: ${colors.red};
        font-size: 15px;
        font-family: 'Avenir Next GEO W05 Demi';
      }
    }
  }

  .infos-planos {
    padding-top: 5px;
    padding-bottom: 20px;
    padding-left: 60px;
    padding-right: 60px;
    display: flex;
    flex-direction: column;

    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-top: 50px;

    .plans-options__button {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 5px;
      background-color: transparent;
      border: none;
      cursor: pointer;

      &:hover {
        color: ${colors.genoaBlue};
      }

      span {
        font-family: 'Avenir Next GEO W05 Regular';
        text-align: left;
        font-size: 16px;
      }

      .health__icon {
        color: black;
        height: 25px;
        width: 25px;
      }

      .dental__icon {
        height: 30px;
        width: 30px;
      }
    }

    .title-container {
      display: flex;
      flex-direction: row;
      column-gap: 20px;
      span {
        display: flex;
        column-gap: 5px;
        align-items: center;
        font-size: 16px;
        color: ${colors.genoaBlue};
        font-family: 'Avenir Next GEO W05 Bold';
      }
    }

    .subtitle-container {
      margin-bottom: 10px;
    }

    .row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 30px;
      margin-top: 15px;

      .column {
        display: flex;
        flex-direction: column;

        label {
          font-size: 14px;
          color: ${colors.genoaGrey};
          font-family: 'Avenir Next GEO W05 Demi';
          margin-bottom: 10px;
        }

        input,
        select {
          padding: 0 14px;
          font-family: 'Avenir Next GEO W05 Regular';
          border: 1.5px ${colors.genoaBlue} solid;
          font-size: 12px;
          height: 28px;
          border-radius: 10px;
          padding-right: 25px;
          max-width: 275px;
          text-overflow: clip;
          white-space: nowrap;
        }

        .input-data {
          width: 100%;
        }

        .react-datepicker__day--today {
          color: ${colors.genoaBlue};
        }

        .react-datepicker__day--keyboard-selected,
        .react-datepicker__month-text--keyboard-selected,
        .react-datepicker__quarter-text--keyboard-selected,
        .react-datepicker__year-text--keyboard-selected {
          background-color: white;
          color: ${colors.genoaBlue};
          font-weight: bold;
        }

        .react-datepicker__day--selected,
        .react-datepicker__month-text--selected,
        .react-datepicker__quarter-text--selected,
        .react-datepicker__year-text--selected {
          border-radius: 0.3rem;
          background-color: ${colors.genoaBlue};
          color: #fff;
          font-weight: bold;
        }

        select {
          background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 9L0.73686 0.75L10.2631 0.75L5.5 9Z' fill='%23456AED'/%3E%3C/svg%3E%0A");
          background-repeat: no-repeat;
          background-position-x: 96%;
          background-position-y: 9px;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;

          option {
            font-size: 12px;
          }
          :focus-visible {
            outline: 1px ${colors.genoaBlue}f5 auto;
          }
        }
      }
    }
  }

  .button-container {
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 15px;
    padding-bottom: 90px;
    display: grid;
    grid-template-columns: 141px 1fr;
    column-gap: 40px;
    align-items: center;
    margin-top: 50px;

    .send-button {
      padding: 5px 10px;
      background-color: ${colors.genoaBlue};
      color: ${colors.white};
      font-size: 14px;
      border-radius: 15px;
      font-family: 'Avenir Next GEO W05 Demi';
    }
  }

  .message-container {
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 15px;

    .confirmation-message {
      color: ${colors.genoaBlue};
    }

    .error-message {
      color: ${colors.red};
    }
  }

  .aba-adicionar-plano {
    padding-top: 20px;
    padding-left: 60px;
    padding-right: 60px;

    .first-row-container {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .aba-adicionar-plano-title {
        color: ${colors.genoaBlue};
        font-family: 'Avenir Next GEO W05 Bold';
        font-size: 16px;
      }

      .voltar-button {
        margin-right: 30px;
        width: max-content;

        :hover {
          cursor: pointer;
        }

        .back-arrow {
          color: ${colors.genoaDarkBlue};
        }
      }
    }

    .planos-options {
      padding-top: 30px;
      display: flex;
      column-gap: 30px;

      .checkbox-container {
        display: flex;
        align-items: center;
        column-gap: 5px;

        span {
          padding: 0;
        }

        svg {
          height: 17px;
        }

        label {
          font-family: 'Avenir Next GEO W05 Medium';
          font-size: 14px;
          margin-bottom: 2px;
          color: ${colors.genoaGrey};
        }

        label.disabled {
          color: ${colors.genoaDisabledGrey};
        }
      }
    }

    .contrato-options-container {
      padding-top: 30px;

      .contratos-options-title {
        font-size: 14px;
        color: ${colors.genoaGrey};
        font-family: 'Avenir Next GEO W05 Demi';
      }

      select {
        margin-top: 20px;
        padding: 0 14px;
        font-family: 'Avenir Next GEO W05 Regular';
        border: 1.5px ${colors.genoaBlue} solid;
        font-size: 12px;
        height: 28px;
        border-radius: 10px;
        padding-right: 25px;
        max-width: 350px;
        text-overflow: clip;
        white-space: nowrap;
        background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 9L0.73686 0.75L10.2631 0.75L5.5 9Z' fill='%23456AED'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position-x: 96%;
        background-position-y: 9px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        min-width: 350px;
        option {
          font-size: 12px;
        }
        :focus-visible {
          outline: 1px ${colors.genoaBlue}f5 auto;
        }
      }
    }

    .plano-info-row {
      display: flex;
      flex-direction: column;
      row-gap: 30px;
      margin-top: 35px;

      .column {
        display: flex;
        flex-direction: column;

        label {
          font-size: 14px;
          color: ${colors.genoaGrey};
          font-family: 'Avenir Next GEO W05 Demi';
          margin-bottom: 10px;
        }

        input,
        select {
          padding: 0 14px;
          font-family: 'Avenir Next GEO W05 Regular';
          border: 1.5px ${colors.genoaBlue} solid;
          font-size: 12px;
          height: 28px;
          border-radius: 10px;
          padding-right: 25px;
          max-width: 275px;
          text-overflow: clip;
          white-space: nowrap;
        }

        .input-data {
          width: 100%;
        }

        .react-datepicker__day--today {
          color: ${colors.genoaBlue};
        }

        .react-datepicker__day--keyboard-selected,
        .react-datepicker__month-text--keyboard-selected,
        .react-datepicker__quarter-text--keyboard-selected,
        .react-datepicker__year-text--keyboard-selected {
          background-color: white;
          color: ${colors.genoaBlue};
          font-weight: bold;
        }

        .react-datepicker__day--selected,
        .react-datepicker__month-text--selected,
        .react-datepicker__quarter-text--selected,
        .react-datepicker__year-text--selected {
          border-radius: 0.3rem;
          background-color: ${colors.genoaBlue};
          color: #fff;
          font-weight: bold;
        }

        select {
          background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 9L0.73686 0.75L10.2631 0.75L5.5 9Z' fill='%23456AED'/%3E%3C/svg%3E%0A");
          background-repeat: no-repeat;
          background-position-x: 96%;
          background-position-y: 9px;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;

          option {
            font-size: 12px;
          }
          :focus-visible {
            outline: 1px ${colors.genoaBlue}f5 auto;
          }
        }
      }
    }

    .enviar-button-container {
      margin-top: 35px;

      span {
        display: flex;
        align-items: baseline;
        column-gap: 3px;
        color: ${colors.genoaDarkBlue};
        font-family: 'Avenir Next GEO W05 Demi';

        :hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }

  .mensagens-container {
    margin-top: 15px;
    margin-left: 30px;
    span {
      font-size: 14px;
      font-family: 'Avenir Next GEO W05 Demi';
    }

    .error {
      color: ${colors.red};
    }

    .success {
      color: ${colors.genoaBlue};
    }
  }
`;
