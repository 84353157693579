import React from 'react';
import { ClickAwayListener } from '@material-ui/core';
import { BsDot } from 'react-icons/bs';
import {
  DarkenBackground,
  DrawerMessagesConversation,
  DrawerMessagesHeader,
  DrawerMessagesWrapper
} from './styles';
import { BiX } from 'react-icons/bi';

const ConciergeDrawerMessage = ({
  ticketInfo,
  setOpenMessageDrawer,
  setConciergeTicketSelected
}) => {
  const {
    nome_contato,
    numero_telefone,
    protocolo,
    empresa,
    nome_atendente,
    created_at,
    closed_at,
    mensagens
  } = ticketInfo;

  const createdDate = new Date(created_at);

  const createdDateSring =
    ('0' + createdDate.getDate()).slice(-2) +
    '/' +
    ('0' + (createdDate.getMonth() + 1)).slice(-2) +
    '/' +
    createdDate.getFullYear() +
    ' ' +
    ('0' + createdDate.getHours()).slice(-2) +
    ':' +
    ('0' + createdDate.getMinutes()).slice(-2);

  const closedDate = new Date(closed_at);

  const closedDateSring =
    ('0' + closedDate.getDate()).slice(-2) +
    '/' +
    ('0' + (closedDate.getMonth() + 1)).slice(-2) +
    '/' +
    closedDate.getFullYear() +
    ' ' +
    ('0' + closedDate.getHours()).slice(-2) +
    ':' +
    ('0' + closedDate.getMinutes()).slice(-2);

  const formatPhoneNumber = (number) => {
    const match = number.match(/^(\d{2})(\d{2})(\d{4}|\d{5})(\d{4})$/);

    if (match) {
      return ['(', match[2], ') ', match[3], ' - ', match[4]].join('');
    }

    return '';
  };

  const renderRemetente = (item) => {
    switch (item?.remetente) {
      case 'cliente':
        return nome_contato;

      case 'atendente':
        return nome_atendente;

      case 'bot':
        return 'Bot';

      default:
        return ' ';
    }
  };

  return (
    <DarkenBackground>
      <ClickAwayListener
        onClickAway={() => {
          setOpenMessageDrawer(false);
          setConciergeTicketSelected(undefined);
        }}
      >
        <DrawerMessagesWrapper>
          <DrawerMessagesHeader>
            <div className="first_column">
              <div className="header_title">
                <span className="demi">{nome_contato}</span>
                <BsDot size={17} className="dot_icon" />
                <span className="demi">{empresa}</span>
              </div>
              <div className="header_subtitle">
                <div>
                  <span>Protocolo: </span>
                  <span className="demi">{protocolo}</span>
                </div>
                <div>
                  <span>Whatsapp: </span>
                  <span className="demi">
                    {formatPhoneNumber(numero_telefone)}
                  </span>
                </div>
                <div className="ticket_dates">
                  <div>
                    <span>Criado em: </span>
                    <span className="demi">
                      {created_at ? createdDateSring : ''}
                    </span>
                  </div>
                  {closed_at && (
                    <>
                      <BsDot size={11} className="dot_icon" />
                      <span>
                        <span>Finalizado em: </span>
                        <span className="demi">
                          {closed_at ? closedDateSring : ''}
                        </span>
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="second_column">
              <div className="close_icon_container">
                <BiX
                  size={30}
                  className="close_icon"
                  onClick={() => {
                    setOpenMessageDrawer(false);
                    setConciergeTicketSelected(undefined);
                  }}
                />
              </div>
            </div>
          </DrawerMessagesHeader>
          <DrawerMessagesConversation>
            <div className="conversation_container">
              {mensagens
                .map((item) => {
                  return (
                    <div
                      className={`imessage ${
                        item?.envio_cliente ? 'from_client' : 'from_attendant'
                      }`}
                    >
                      <div className="remetente_nome">
                        <span>{renderRemetente(item)}</span>
                      </div>
                      <span>{item?.mensagem}</span>
                    </div>
                  );
                })
                .reverse()}
            </div>
          </DrawerMessagesConversation>
        </DrawerMessagesWrapper>
      </ClickAwayListener>
    </DarkenBackground>
  );
};

export default ConciergeDrawerMessage;
