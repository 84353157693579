import styled from "styled-components";
import colors from "styles/colors";

export const ListWrapper = styled.table`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 180px);
  background-color: white;
  border-radius: 7px;

  thead {
    color: ${colors.genoaDarkBlue};
    tr {
      display: flex;
      text-align: flex-start;
      display: grid;
      grid-template-columns: 1fr 2fr 2fr 1fr 3fr 2fr 4fr 2fr 3fr 3fr 2fr 1fr;
      column-gap: 10px;
      padding: 10px 0px;
      font-size: 0.8em;

      // th:nth-child(4), th:nth-child(5) {
      //   text-align: left;
      // }
    }
  }

  tbody {
    max-width: calc(100%);
    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 4px;
      background: rgba(0, 0, 0, 0);
    }
    ::-webkit-scrollbar-thumb {
      background: ${colors.lightGrey};
      box-shadow: none;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 2px rgb(0, 0, 0, 0);
    }

    tr: nth-child(n) {
      background-color: ${colors.rhTabLightGrey};
    }

    tr: nth-child(2n) {
      background-color: white;
    }
  }
`;

export const CheckboxHeader = styled.div`
  display: flex;
  justify-content: center;

  .edit_box {
    display: flex;
    flex-direction: row;
    height: 15.9px;
    width: 16px;

    :hover {
      cursor: pointer;
    }
  }

  .div_checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .checkbox {
    padding: 0;
    color: ${colors.genoaDarkBlue};
    position: relative;
    z-index: 1000;

    &.Mui-checked {
      color: ${colors.genoaBlue};
    }

    svg {
      height: 15.9px;
      width: 16px;
    }
  }

  .close_checkbox {
    border: 2px solid ${colors.genoaDarkBlue};
    border-left: none;
    border-radius: 3px;
    padding-left: 3px;
    height: 9.5px;
    width: 10px;

    transform: translateX(-4px);

    background-color: ${colors.genoaDarkBlue};
    color: white;

    :hover {
      cursor: pointer;
    }
  }
`;
