import styled from 'styled-components';

export const FormSection = styled.div`
  
  min-width: 570px;


  .div-input-files {
    display: flex;
    align-items: center;
  }
  .label-file {
    font-size: 16px;
    background-color: #f3ecff;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
      
    padding: 5px 5px;
    border: 1px #62A0E2 solid;
    
    span.error-msg {
    color: #f7365a;
    font-size: 13px;
    margin-bottom: 5px;
    display: inline-block;
  }  


    input{display: none;}
    span {
      display: flex;
      align-items: center;
      color: #62A0E2;
              
      svg{
        font-size: 1.3rem;
        }
      }
    }

    .preview{
      padding: 10px;
      box-sizing: border-box;
      width: 100%;

      .logoPreview {
        width: 220px;
        margin: auto;

        img {
          width: 100%;
          height: auto;
        }
      }
    }


    button.btn {
        font-family: 'Avenir Next GEO W05 bold';
        padding: 7px 15px;
        background: #f3ecff;
        border: 1px #62A0E2 solid;
        border-radius: 5px;
        color: #62A0E2;
        cursor: pointer;
        display: flex;
        align-items: center;
        :hover{
          background: #e3d2ff;
        }
        :disabled{
          opacity: 0.5;
        }
        span {color:inherit}
        .MuiCircularProgress-root{
          margin-left: 10px;
        }
      }

    button.btn-clear {
      background: none;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #f76767;
      margin-right: 1rem;
      cursor: pointer;
    }

`

export const MsgSuccess = styled.div`
 min-width: 570px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 10px 20px 10px;

  .icon-area{
    font-size: 3rem;
    color: #62A0E2;
  }

  .message{
    font-size: 1.5rem;
    font-family: 'Avenir Next GEO W05 bold';
  }
`