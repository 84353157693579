import styled from 'styled-components';
import colors from 'apps/broker/styles/colors';

export const SeguradorasListContainer = styled.div`
  margin-bottom: 50px;
`;

export const SeguradoraCard = styled.div`
  border-radius: 30px;
  margin-top: 15px;
  border: solid 3px ${colors.genoaBlue};
  background-color: ${colors.white};

  .card-content {
    padding: 20px;
    overflow: hidden;

    .first-row {
      display: flex;
      justify-content: space-between;

      .edit-icon-open {
        color: ${colors.genoaBlue};
        margin-right: 25px;

        :hover {
          cursor: pointer;

        }

      }

      .seguradora-name {
        font-size: 1rem;
        font-family: "Avenir Next GEO W05 Demi";
      }
    }

    .second-row {
      margin-top: 20px;
      .table {
        height 80px;
        width: 100%;
        margin-bottom: 15px;

        .table-header-row {
          font-size: 0.9rem;
          margin-bottom: 5px;
          font-family: "Avenir Next GEO W05 Demi";
          display: grid;
          grid-template-columns: 1.2fr 0.7fr 1.5fr 1.4fr 70px;
          text-align: left;
          user-select: none;
          -moz-user-select: none;
          -webkit-user-select: none;
        }

        .table-body {

          .table-body-row {
            margin-top: 10px;
            display: grid;
            grid-template-columns: 1.2fr 0.7fr 1.5fr 1.4fr 70px;
            text-align: left;
            font-size: 0.9rem;
            line-height: 22px;
            
            td {
              .icons-container {
                display: flex;

                .delete-icon {
                  color: red;

                  :hover {
                    cursor: pointer;
                  }

                }

                .edit-icon {
                  margin-left: 10px;

                  :hover {
                    cursor: pointer;
                  }

                }

              }

            }

          }

        }
        
      }

    }
  }

  .card-content-closed {
    padding: 20px;
    overflow: hidden;
    height: 20px;

    .first-row {
      display: flex;
      justify-content: space-between;

      .edit-icon-open {
        color: ${colors.genoaBlue};
        margin-right: 25px;

        :hover {
          cursor: pointer;

        }

      }

      .seguradora-name {
        font-size: 1rem;
        font-family: "Avenir Next GEO W05 Demi";
      }
    }

    .second-row {
      margin-top: 20px;
      .table {
        height 80px;
        width: 100%;
        margin-bottom: 15px;

        .table-header-row {
          font-size: 0.9rem;
          margin-bottom: 5px;
          font-family: "Avenir Next GEO W05 Demi";
          display: grid;
          grid-template-columns: 1.2fr 0.7fr 1.5fr 1.4fr 70px;
          text-align: left;
          user-select: none;
          -moz-user-select: none;
          -webkit-user-select: none;
        }

        .table-body {

          .table-body-row {
            margin-top: 10px;
            display: grid;
            grid-template-columns: 1.2fr 0.7fr 1.5fr 1.4fr 70px;
            text-align: left;
            font-size: 0.9rem;
            line-height: 22px;
            
            td {
              .icons-container {
                display: flex;

                .delete-icon {
                  color: red;

                  :hover {
                    cursor: pointer;
                  }

                }

                .edit-icon {
                  margin-left: 10px;

                  :hover {
                    cursor: pointer;
                  }

                }

              }

            }

          }

        }
        
      }

    }
  }
`;
