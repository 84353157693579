const maskData = (value) => {
    value = value.replace(/\D/g,"");
    value = value.replace(/(\d{2})(\d)/,"$1/$2");
    value = value.replace(/(\d{2})(\d)/,"$1/$2");

    value = value.replace(/(\d{2})(\d{2})$/,"$1$2");
    return value;
}

module.exports = maskData;
