import ExclusionMovimentCard from './ExclusionMovimentCard';
import InclusionMovimentCard from './InclusionMovimentCard';

const ExpandCardMoviment = ({
  item,
  expand,
  nCarteirinha,
  setNCarteirinha,
  selectedDateInclusionPlan,
  setSelectedDateInclusionPlan,
  activeBeneficiaryContract,
  selectedDateExclusionPlan,
  setSelectedDateExclusionPlan,
  selectedPlan,
  removeBeneficiaryContract
}) => {
  return (
    <>
      {expand === `${item.movimentacao_id}&${item.nome_produto}` ? (
        item.tipo_movimentacao === 'Inclusão' ? (
          <InclusionMovimentCard
            item={item}
            nCarteirinha={nCarteirinha}
            setNCarteirinha={setNCarteirinha}
            selectedDateInclusionPlan={selectedDateInclusionPlan}
            setSelectedDateInclusionPlan={setSelectedDateInclusionPlan}
            activeBeneficiaryContract={activeBeneficiaryContract}
          />
        ) : (
          item.tipo_movimentacao === 'Exclusão' && (
            <ExclusionMovimentCard
              item={item}
              selectedDateExclusionPlan={selectedDateExclusionPlan}
              setSelectedDateExclusionPlan={setSelectedDateExclusionPlan}
              selectedPlan={selectedPlan}
              removeBeneficiaryContract={removeBeneficiaryContract}
            />
          )
        )
      ) : null}
    </>
  );
};

export default ExpandCardMoviment;
