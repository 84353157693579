import styled from "styled-components";
import colors from "styles/colors";

export const DarkenBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 101vh;
  background-color: rgba(0, 0, 0, 0.5);

  z-index: 1000;
`;

export const PopperWrapper = styled.div`
  position: absolute;

  width: calc(500px - 40px);
  height: calc(100px - 30px);
  padding: 20px 15px;

  top: calc(50% - 50px);
  left: calc(50% - 250px);

  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.3), 0 22px 25px rgba(0, 0, 0, 0.22);
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .content-title {
    display: flex;
    column-gap: 10px;
    font-size: 1em;
    font-family: "Avenir Next GEO W05 Demi";
    color: ${colors.genoaGrey};

    .span-plano {
      color: ${colors.genoaBlue};
      padding-right: 2px;
    }
  }

  .icon-container {
    display: flex;
    column-gap: 30px;
    justify-content: center;

    .icon {
      :hover {
        cursor: pointer;
      }
    }

    .check {
      color: ${colors.luminousGreen};
      font-size: 18px;
    }

    .cross {
      color: ${colors.genoaPink};
      font-size: 16px;
    }
  }

  .message {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 1.1em;
    font-family: "Avenir Next GEO W05 Bold";
  }

  .success {
    color: ${colors.genoaBlue};
  }

  .failure {
    color: ${colors.genoaPink};
  }

  .loading {
    color: ${colors.genoaBlue};
  }
`;
