import { Dialog } from '@material-ui/core';
import { Content } from './styles';

const DialogEditOrReopen = ({
  open,
  onClose,
  question,
  status,
  setStatus,
  confirm
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Content>
        <div className="title">
          <h3>{question}</h3>
        </div>
        <div className="form-edit">
          <select
            value={status}
            onChange={({ target }) => setStatus(target.value)}
          >
            <option value="" selected disabled>
              Selecione um status
            </option>
            <option value={4}>Aguardando RH</option>
            <option value={1}>Aguardando corretora</option>
          </select>
        </div>
        <div className="actions">
          <div className="buttons2">
            <button className="btn btn-confirm2" onClick={confirm}>
              Confirmar
            </button>
            <button className="btn btn-canlcel2" onClick={onClose}>
              Cancelar
            </button>
          </div>
        </div>
      </Content>
    </Dialog>
  );
};

export default DialogEditOrReopen;
