import Excel from 'exceljs';
import moment from 'moment';
import ButtonDownloadExcel from 'apps/broker/components/DownloadExcelFile';
import saveFile from 'hooks/saveXlsxFile';

const DownloadBeneficiariesData = ({
  beneficiariesFilter,
}) => {
  const downloadExcelFile = () => {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Beneficiários', {
      properties: { tabColor: { argb: '456AED' } }
    });

    worksheet.columns = [
      {
        header: 'Beneficiário',
        key: 'nome_beneficiario',
        style: { alignment: { vertical: 'middle', horizontal: 'left' } },
        width: 40
      },
      {
        header: 'CPF',
        key: 'cpf',
        style: { alignment: { vertical: 'middle', horizontal: 'left' } },
        width: 25
      },
      {
        header: 'Data do Pedido',
        key: 'created_at',
        style: {
          numFmt: 'dd/mm/yyyy',
          alignment: { vertical: 'middle', horizontal: 'center' }
        },
        width: 25
      },
      {
        header: 'Tipo',
        key: 'tipo',
        style: { alignment: { vertical: 'middle', horizontal: 'left' } },
        width: 25
      },
      {
        header: 'Estipulante',
        key: 'estipulante',
        style: { alignment: { vertical: 'middle', horizontal: 'left' } },
        width: 60
      },
      {
        header: 'Sub-Estipulante',
        key: 'sub_estipulante',
        style: { alignment: { vertical: 'middle', horizontal: 'left' } },
        width: 60
      },
      {
        header: 'Seguradora',
        key: 'seguradora',
        style: { alignment: { vertical: 'middle', horizontal: 'left' } },
        width: 50
      },
      {
        header: 'Produto',
        key: 'produto',
        style: { alignment: { vertical: 'middle', horizontal: 'left' } },
        width: 20
      },
      {
        header: 'Carteirinha',
        key: 'numero_carteirinha',
        style: { alignment: { vertical: 'middle', horizontal: 'left' } },
        width: 25
      },
      {
        header: 'Tipo Movimentação',
        key: 'tipo_movimentacao',
        style: { alignment: { vertical: 'middle', horizontal: 'left' } },
        width: 25
      },
      {
        header: 'Status Movimentação',
        key: 'status_movimentacao',
        style: { alignment: { vertical: 'middle', horizontal: 'left' } },
        width: 25
      }
    ];

    beneficiariesFilter?.map((item) => {
      item?.contratos?.map((contrato) => {
        worksheet.addRow({
          nome_beneficiario: item?.nome_beneficiario,
          cpf: item?.dados?.cpf,
          created_at: moment(item?.created_at).format('DD/MM/YYYY'),
          tipo: item?.tipo,
          estipulante: !item?.estipulantePrincipal
            ? item?.estipulante
            : item?.estipulantePrincipal,
          sub_estipulante: !item?.estipulantePrincipal ? '' : item?.estipulante,
          seguradora: contrato?.nome,
          produto: contrato?.search,
          numero_carteirinha: contrato?.numero_carteirinha,
          status_movimentacao: item?.status_movimentacao,
          tipo_movimentacao: item?.tipo_movimentacao
        });
      });
    });
    
    worksheet.eachRow({ includeEmpty: true }, function (Row, rowNum) {
      Row.eachCell({ includeEmpty: true }, function (Cell, colNumber) {
        if (rowNum === 1) {
          Cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '456AED' }
          };

          Cell.font = {
            bold: true,
            size: 14,
            color: { argb: 'FFFFFF' }
          };
        }

        Cell.alignment = {
          vertical: 'middle',
          horizontal: 'center'
        };
      });

      Row.height = 30;
    });

    saveFile('Planilha de Movimentações', workbook);
  };

  return (
    <ButtonDownloadExcel
      downloadExcelFile={downloadExcelFile}
    />
  );
};

export default DownloadBeneficiariesData;
