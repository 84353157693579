import styled from "styled-components";
import colors from "styles/colors";

export const TabelaConciergeContainer = styled.div`
  table {
    width: 100%;

    tbody {
      // display: block;
      // height: 400px;
      // overflow-y: scroll;

      ::-webkit-scrollbar {
        width: 4px;
        background: rgba(0, 0, 0, 0);
      }
      ::-webkit-scrollbar-thumb {
        // background: ${colors.lightGrey};
        box-shadow: none;
        
      }
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px rgb(0, 0 , 0, 0);
        
      }
    }

    .thead_row {
      display: grid;
      grid-template-columns: 1fr 140px;
      text-transform: uppercase;
      font-size: 0.65em;
      background-color: ${colors.genoaBlue};
      color: white;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      padding: 5px 0px;
    }

    .tbody_row {
      display: grid;
      grid-template-columns: 1fr 140px;
      font-size: 0.65em;
      padding: 4px 0px;
      text-transform: uppercase;
      height: 2.5em;
      align-items: center;
      color: ${colors.genoaGrey};
      transition: 0.2s ease;
      text-shadow: 0px 0px 0px;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;

      :hover {
        transition: 0.5s ease;
        font-family: 'Avenir Next GEO W05 Demi';
        
        cursor: pointer;
      }
    }

    .selected {
      font-family: 'Avenir Next GEO W05 Demi';
      color: ${colors.genoaBlue};
      text-shadow: 0.4px 0.2px 0.4px #a8b4dc66,
      -0.4px 0.2px 0.4px #a8b4dc66,
      -0.4px -0.2px 0.4px #a8b4dc66,
      0.4px -0.2px 0.4px #a8b4dc66;
    }

    .tbody_row:nth-child(2n + 1) {
      background-color: ${colors.genoaMediumBackgroundGrey};
    }

    .tbody_row > td:last-child {
      text-align: center;
    }

    .thead_row > th:first-child, .tbody_row > td:first-child {
      text-align: left;
      padding-left: 10px;
      text-overflow: ellipsis;
      // white-space: nowrap;
    }

    .thead_row > th:last-child, .tbody_row > td:last-child  {
      padding-right: 10px;
    }
  }
`