import React, { useEffect } from 'react'
import DatePicker from "react-datepicker";
import { Wraper } from './styles'
import { BiUpload, BiFolderOpen } from "react-icons/bi";
import { BsArrowRight } from "react-icons/bs";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import InputMask from 'react-input-mask';
import { useEmitTicket } from "contexts/emitTicket";

const dados = {
  nomeDependete: '',
  grauParentesco: '',
  cpf: '',
  rg: '',
  dataExpedicao: '',
  orgaoEmissor: '',
  sexo: '',
  dataNascimento: '',
}

const Dependentes = ({ setFileDependents }) => {
  const {
    externalForm,
    updateExternalForm,
    updateExternalFormStage,
  } = useEmitTicket();
  const { dependentes } = externalForm;
  const { register, handleSubmit, control, setValue, watch, formState: { errors } } = useForm({
    defaultValues: {
      dependents: dependentes.length ? dependentes : [dados],
    }
  });

  const { fields, append, remove } = useFieldArray(
    { control, name: "dependents" }
  );

  const changeStage = (stage) => {
    updateExternalFormStage(stage);
  }

  const goForward = (data) => {
    let formatFiledata = [];

    // console.log(data)

    data.files.forEach((file) => {
      formatFiledata.push(file[0]);
    });

    setFileDependents({ filesDependents: formatFiledata });
    updateExternalForm({ dependentes: data.dependents });
    changeStage('EscolhaPlano');
  }

  const handleChange = ({ target }, index) => {
    setValue(`grauParentesco${index}`, target.value)
    if (target.value === `Conjuge${index}`) {
      setValue(`certidao_casamento${index}`, '')
    }
    if (target.value === `Filho${index}`) {
      setValue(`certidao_nascimento${index}`, '')
    }
  }

  useEffect(() => {
    fields?.forEach((item, index) => {
      setValue(`grauParentesco${index}`, item.grauParentesco);
    })
  }, [fields, setValue]);

  return (
    <Wraper>
      {fields?.map((item, index) => {
        // console.log(item.grauParentesco.split(index)[0])
        return (
          <fieldset key={index} className={fields.length > 1 ? 'fieldDepends' : ''}>
            {index === 0 ? <legend>Adicione os seus  dependentes</legend> :
              <button type="button" className="remove"
                onClick={() => { remove(dados) }}>Remover</button>}

            <div className="input-group">
              {errors.dependents?.[index]?.nomeDependete && <span className="errorsMsg">Campo obrigatório</span>}
              <input type="text" name="nomeDependete"
                {...register(`dependents.${index}.nomeDependete`, { required: true })}
                defaultValue={item.nomeDependete}
                placeholder="Nome do primeiro dependente"></input>
            </div>

            <div className="select-group">
              <label>Grau de Parentesco</label>
              {errors.dependents?.[index]?.grauParentesco && <span className="errorsMsg">Campo obrigatório</span>}
              <select
                {...register(`dependents.${index}.grauParentesco`, { required: true })}
                defaultValue={item.grauParentesco.split(index)[0]}
                onChange={(e) => {
                  handleChange(e, index);
                }}>
                <option value="" disabled={errors.dependents?.[index]?.grauParentesco} hidden={errors.dependents?.[index]?.grauParentesco}>Selecione</option>
                <option value={`Conjuge${index}`}>Conjuge</option>
                <option value={`Filho${index}`}>Filho</option>
              </select>
            </div>

            <div className="input-group">
              {errors.dependents?.[index]?.cpf && <span className="errorsMsg">Campo obrigatório</span>}
              <Controller
                name={`dependents.${index}.cpf`}
                control={control}
                defaultValue={item.cpf}
                rules={{ required: true }}
                render={({ field }) => <InputMask
                  {...field}
                  maskChar={null}
                  mask="999.999.999-99"
                  type="text"
                  placeholder="CPF"
                />}
              />
            </div>

            <div className="input-group">
              {errors.dependents?.[index]?.rg && <span className="errorsMsg">Campo obrigatório</span>}
              <input
                {...register(`dependents.${index}.rg`, { required: true })}
                defaultValue={item.rg}
                maskChar={null}
                mask="99.999.999-9"
                type="text"
                maxLength={13}
                placeholder="RG"
              ></input>
            </div>

            <div className="inline-group-input">
              <div>
                {errors.dependents?.[index]?.orgaoEmissor && <span className="errorsMsg">Campo obrigatório</span>}
                <input type="text"
                  {...register(`dependents.${index}.orgaoEmissor`,
                    { required: true },
                  )}
                  defaultValue={item.orgaoEmissor}
                  maxLength={13}
                  placeholder="Órgão Emissor" />
              </div>
              <div>
                {errors.dependents?.[index]?.dataExpedicao && <span className="errorsMsg">Campo obrigatório</span>}
                <Controller
                  name={`dependents.${index}.dataExpedicao`}
                  control={control}
                  defaultValue={item?.dataExpedicao ? new Date(item?.dataExpedicao) : null}
                  rules={{ required: true }}
                  render={({ field: { onChange, value, } }) => <DatePicker
                    className="input-data"
                    selected={value === '' ? '' : new Date(value)}
                    locale="ptBR"
                    maxDate={new Date()}
                    onChange={onChange}
                    placeholderText="Data de Expedição"
                    dateFormat="dd/MM/yyyy"
                  />}
                />
              </div>
            </div>
            <div className="select-group">
              <label>Sexo/Gênero</label>
              {errors.dependents?.[index]?.sexo && <span className="errorsMsg">Campo obrigatório</span>}
              <select
                {...register(`dependents.${index}.sexo`, { required: true })}
                defaultValue={item.sexo}>
                <option value=""
                  disabled={errors.dependents?.[index]?.sexo}
                  hidden={errors.dependents?.[index]?.sexo}
                >
                  Selecione
                </option>
                <option value="Masculino">Masculino</option>
                <option value="Feminino">Feminino</option>
              </select>
            </div>

            <div>
              {errors.dependents?.[index]?.dataNascimento && <span className="errorsMsg">Campo obrigatório</span>}
              <Controller
                name={`dependents.${index}.dataNascimento`}
                control={control}
                defaultValue={item?.dataNascimento ? new Date(item?.dataNascimento) : null}
                rules={{ required: true }}
                render={({ field: { onChange, value, } }) => <DatePicker
                  className="input-data"
                  selected={value === '' ? '' : new Date(value)}
                  locale="ptBR"
                  onChange={onChange}
                  maxDate={new Date()}
                  placeholderText="Data de Nascimento"
                  dateFormat="dd/MM/yyyy"
                />
                }
              />
            </div>

            <div className="input-files-group">
              {(watch(`grauParentesco${index}`) === `Conjuge${index}`) && <>
                {errors.files?.[index] && <span className="errorsMsg">Campo obrigatório</span>}
                <label className="label-file" htmlFor={`certidao_casamento${index}`}>
                  {watch(`files.${index}`)?.[0]?.name ?
                    <span><BiFolderOpen /> {watch(`files.${index}`)[0]?.name} </span> :
                    <span>Certidão de casamento <BiUpload /></span>
                  }
                  <input className="input-file"
                    id={`certidao_casamento${index}`}
                    name={`files.${index}`}
                    {...register(`files.${index}`, { required: true })}
                    type="file"
                  />
                </label>
              </>}
            </div>

            <div className="input-files-group">
              {(watch(`grauParentesco${index}`) === `Filho${index}`) && <>
                {errors.files?.[index] && <span className="errorsMsg">Campo obrigatório</span>}
                <label className="label-file" htmlFor={`certidao_nascimento${index}`}>
                  {watch(`files.${index}`)?.[0]?.name ?
                    <span><BiFolderOpen /> {watch(`files.${index}`)[0]?.name} </span> :
                    <span>Certidão de nascimento <BiUpload /></span>
                  }
                  <input className="input-file"
                    id={`certidao_nascimento${index}`}
                    name={`files.${index}`}
                    {...register(`files.${index}`, { required: true })}
                    type="file"
                  />
                </label>
              </>}
            </div>
          </fieldset>
        )
      })}

      <div className="add-depend">
        <button type="button" onClick={() => { append(dados) }}>Adicionar mais um dependente</button>
      </div>
      <div className="button-areas">
        <button type="button" onClick={() => changeStage('VinculoEmpresa')}>Voltar</button>
        <button type="button" onClick={handleSubmit(goForward)} >Avançar <BsArrowRight /></button>
      </div>
    </Wraper>
  )
}

export default Dependentes
