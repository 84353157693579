import { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import parse from 'html-react-parser';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Wrapper } from './stylesDrawer';
import { dataDiff } from 'utils/calculateFunctions';
import { FaAngleDoubleRight, FaPen } from 'react-icons/fa';
import TextEditor from 'components/TextEditor';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Dialog, CircularProgress } from '@material-ui/core';
import { ModalCloseTicket, ModalEditTicket } from './stylesDrawer';
import { io } from 'socket.io-client';
import api from 'services/api';
import services from 'apps/hr/services';
import { useUser } from 'contexts/user';

const DrawerTicketContent = ({ onClose, closeDrawer, ...props }) => {
  const { user } = useUser();

  const data = props.data;
  const [status, setStatus] = useState();
  const [details, setDetails] = useState([]);
  const [messages, setMessages] = useState([]);
  // const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [files, setFiles] = useState([]);
  const [openEditor, setOpenEditor] = useState(false);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setOpenEdit(!openEdit);
  };

  // const Success = () => {

  // }

  // const handleCloseEdit = () => {
  //   setOpenEdit(false);
  // };

  const closeTicket = () => {
    api
      .put('/tickets/atualizar', {
        headers: {
          authorization: `Bearer ${user.token}`
        },
        id_ticket: data.id_ticket,
        status_id: 3
      })
      .then((resp) => {
        getData();
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function getData() {
    setLoading(true);
    api
      .get('/rh/ticket', {
        headers: {
          id: data.id_ticket,
          authorization: `Bearer ${user.token}`
        }
      })
      .then((resp) => {
        setDetails(resp.data);
        setMessages(resp.data.messages);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const editStatus = (e) => {
    e.preventDefault();
    const body = {
      ticket: details.id_ticket,
      status: status
    };
    const headers = {
      authorization: `Bearer ${user.token}`
    };
    api
      .put('/corretor/ticket/status', body, { headers: headers })
      .then((resp) => {
        getData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sendMessageRh = async () => {
    const htmlMessage = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );

    const formData = new FormData();
    formData.append('mensagem', htmlMessage);
    files.forEach((file, index) => {
      formData.append(index, file);
    });

    const response = await services.tickets.createMessageByHr(
      formData,
      data,
      user,
      'RespRH',
      'normal'
    );

    if (response.status === 200) {
      setFiles([]);
      setEditorState(!editorState);
      getData();
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const socket = io(process.env.REACT_APP_BASE_URL_API);

  socket.on('updateFront', () => {
    getData();
  });

  return (
    <Wrapper>
      {!loading ? (
        <>
          <header>
            <div className="back">
              <span onClick={() => closeDrawer(false)}>
                <ArrowBackIcon color="inherit" fontSize="inherit" />
              </span>
            </div>

            <div className="title">
              <h2>Detalhes do Ticket</h2>
              <span>
                Criado em{' '}
                {moment(details.created_at).utc().format('DD/MM/YYYY')} (
                {dataDiff(details.created_at)} dias)
              </span>
            </div>
          </header>
          <section className="infos">
            <div className="author">
              Ticket aberto por {details.nome + ' ' + details.sobrenome}
            </div>
            <div className="status">
              <div className="text-icon">
                <FaAngleDoubleRight /> Status: {details.descricao}{' '}
                {details.status_id !== 3 && (
                  <span className="btn-edit">
                    <FaPen onClick={(e) => handleEdit(e)} />
                  </span>
                )}
              </div>
            </div>
          </section>

          <section className="bodyTicket">
            <div className="titleTicket">
              {details.assunto} - #{details.id_ticket}
            </div>

            <div className="messages">
              {messages.map((message, index) => (
                <div className="single-message" key={index}>
                  <div className="titleTicket">
                    {message.corretora_id
                      ? message.nome
                      : message.nome + ' ' + message.sobrenome}{' '}
                    em {moment(message.created_at).utc().format('DD/MM/YYYY')}
                  </div>

                  {message.corretora_id ? (
                    <div className="messageText  resp-msg">
                      {message.file !== undefined ? (
                        <div>
                          {message.file?.map((file, index) => (
                            <div className="messageFile">
                              <a
                                href={file.attachment_url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {file.name}
                              </a>
                            </div>
                          ))}
                        </div>
                      ) : null}
                      {parse(message.mensagem)}
                    </div>
                  ) : (
                    <div className="messageText  open-msg">
                      <div>
                        {message.file?.map((file, index) => {
                          return (
                            <div className="messageFile">
                              <a
                                href={file.attachment_url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {file.name}
                              </a>
                            </div>
                          );
                        })}
                      </div>

                      {parse(message.mensagem)}
                    </div>
                  )}
                </div>
              ))}
            </div>

            <div className="actions">
              {details.status_id !== 3 && (
                <div className="buttons">
                  <button
                    className="newMsg"
                    disabled={details.status_id === 3}
                    onClick={() => setOpenEditor(!openEditor)}
                  >
                    + Nova mensagem
                  </button>

                  <button className="btn-close" onClick={handleClickOpen}>
                    Fechar Ticket
                  </button>
                </div>
              )}

              {openEditor && (
                <TextEditor
                  handleSendMessage={sendMessageRh}
                  editorState={editorState}
                  setEditorState={setEditorState}
                  files={files}
                  setFiles={setFiles}
                />
              )}
            </div>
          </section>
        </>
      ) : (
        <div className="loading">
          <CircularProgress /> <p>Carregando...</p>
        </div>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ModalCloseTicket>
          <div className="title">
            <h3>Confirma o encerramento do ticket ?</h3>
          </div>
          <div className="content"></div>
          <div className="actions">
            <div className="buttons">
              <button className="btn btn-confirm" onClick={closeTicket}>
                Confirmar
              </button>
              <button className="btn btn-cancel" onClick={handleClose}>
                Cancelar
              </button>
            </div>
          </div>
        </ModalCloseTicket>
      </Dialog>

      <Dialog
        open={openEdit}
        onClose={handleEdit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ModalEditTicket>
          <div className="title">
            <h3>Alterar o status do ticket</h3>
          </div>
          <form onSubmit={(e) => editStatus(e)}>
            <div className="form-edit">
              <label>Selecione o status do ticket</label>
              <select
                value={status}
                onChange={({ target }) => setStatus(target.value)}
              >
                <option selected disabled>
                  Selecione um status
                </option>
                <option value="Aguardando RH">Aguardando RH</option>
                <option value="Aguardando Corretora">
                  Aguardando corretora
                </option>
              </select>
            </div>
            <div className="actions">
              <div className="buttons">
                <button
                  className="btn btn-confirm"
                  type="submit"
                  onClick={() => setOpenEdit(!openEdit)}
                >
                  Confirmar
                </button>
                <button
                  className="btn btn-cancel"
                  onClick={(e) => handleEdit(e)}
                >
                  Cancelar.
                </button>
              </div>
            </div>
          </form>
        </ModalEditTicket>
      </Dialog>
    </Wrapper>
  );
};

export default DrawerTicketContent;
