import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { ContainerModal } from "./styles";
import api from "services/api";
import { useSelector } from "react-redux";

const NewCollaborator = (props) => {
  const [contratos, setContratos] = useState([]);
  const [corretoras, setCorretoras] = useState([]);

  const [livreEscolha, setLivreEscolha] = useState(true);
  const [success, setSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    formState,
    reset,
  } = useForm();

  const user = useSelector((state) => state.userReducers.user);

  function getData() {
    api
      .get("/rh/corretoras", {
        headers: {
          id: user.estipulante,
          authorization: `Bearer ${user.token}`,
        },
      })
      .then((resp) => {
        setCorretoras(resp.data);
      })
      .catch((err) => {
        console.log(`Error: ${err.message}`);
      });
  }

  const getContratos = (value) => {
    const headers = {
      estipulante: user.estipulante,
      corretora: value,
      authorization: `Bearer ${user.token}`,
    };
    api
      .get("/rh/corretoras/contratos", { headers: headers })
      .then((resp) => {
        setContratos(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });

    // console.log(value);
  };


  const onSubmit = (data) => {
    const headers = {
      estipulante: user.estipulante,
      authorization: `Bearer ${user.token}`,
    };

    const body = {
      corretora: data.corretora,
      contrato: data.contrato,
      livreEscolha: livreEscolha === true ? "sim" : "nao",
      email: data.email,
    };

    // console.log(user)


    api
      .post("/rh/email-movimentacao", body, { headers: headers })
      .then(() => {
        if (props.getTickets) {
          props.getTickets();

        }
        setSuccess(true);
      })
      .catch((err) => console.log(err));
  };


  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
    }
  }, [formState, reset]);


  return (
    <ContainerModal>
      <h1>Novo Colaborador</h1>

      {success ? (
        <div className="msgSuccess">
          <span className="message">
            O link para o formulário do programa de benefícios foi enviado.
          </span>

          <p className="newTicket" onClick={() => setSuccess(!success)}>
            Enviar para um novo email
          </p>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input-group">
            <label>Corretora</label>
            <select
              name="corretora"
              {...register("corretora", { required: true })}
              onChange={(e) => getContratos(e.target.value)}
            >
              <option value="">Selecione corretora</option>

              {corretoras.map((corretora, index) => (
                <option key={index} value={corretora.id_corretora}>
                  {corretora.nome_completo}
                </option>
              ))}
            </select>
          </div>
          <div className="input-group">
            <label>Contrato</label>
            <select
              name="corretora"
              {...register("contrato", { required: true })}
            >
              <option value="" defaultValue>
                Selecione um contrato
              </option>

              {contratos.map((contrato, index) => (
                <option key={index} value={contrato.idref_contrato} 
                onChange={(e) => setLivreEscolha(e.target.checked)}
                checked={livreEscolha}>
                  {contrato.nome_completo} - {contrato.nome_produto}
                </option>
              ))}
            </select>
          </div>

          {/* <div className="input-group checkbox">
            <label>Plano livre escolha</label>
            <GreenCheckbox
              name="checkbox"
              onChange={(e) => setLivreEscolha(e.target.checked)}
              checked={livreEscolha}
              id="termos"
            />
          </div> */}

          <div className="input-group">
            <label>Email do colaborador a ser incluído</label>
            <input type="text" {...register("email")} />
          </div>

          <button type="submit">Enviar!</button>
        </form>
      )}
    </ContainerModal>
  );
};

export default NewCollaborator;
