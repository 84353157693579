import React from "react";
import InputStandard from "components/Input/InputStandard";
import { AbaDadosBancarioWrapper } from "./styles-abaDadosBancarios";

const AbaDadosBancarios = (
  { values,
    setInputValue,
    setSelectValue,
    camposInclusao
  }) => {

  return (
    <AbaDadosBancarioWrapper>
      <div className="aba-container">
        <div className="aba-title-container">
          <span>6. Dados Bancários</span>
        </div>
        <div className="aba-input-container">
          <div className="aba-input-row-imc">
            {
              camposInclusao['agencia'] ? (
                <InputStandard
                  value={values['agencia']}
                  setValue={setInputValue}
                  label={"Agência"}
                  name="agencia"
                />
              ) : ""
            }

            {
              camposInclusao['conta'] ? (
                <InputStandard
                  value={values['conta']}
                  setValue={setInputValue}
                  label={"Conta corrente"}
                  name="conta"
                />
              ) : ""
            }

            {
              camposInclusao['digito_conta'] ? (
                <InputStandard
                  value={values['digito_conta']}
                  setValue={setInputValue}
                  label={"Dígito"}
                  name="digito_conta"
                />
              ) : ""
            }

            {
              camposInclusao['banco'] ? (
                <InputStandard
                  value={values['banco']}
                  setValue={setInputValue}
                  label={"Banco"}
                  name="banco"
                />
              ) : ""
            }

          </div>
        </div>
      </div>
    </AbaDadosBancarioWrapper>
  )
}

export default AbaDadosBancarios;
