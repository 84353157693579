import { useState, useEffect } from 'react';
import { WrapperTabelaSeguradora } from './styles-TabelaSeguradora';

export const TabelaSeguradora = (props) => {
  const {
    tipo,
    relatorioSelecionado,
    tableSeguradora,
    movimentacoesSeguradora,
    tempoMedioSeguradora
  } = props;

  const [abertas, setAbertas] = useState([]);
  const [concluidas, setConcluidas] = useState([]);
  const [tempo, setTempo] = useState([]);

  const renderTipoRelatorio = () => {
    switch (relatorioSelecionado) {
      case 'movimentacoes abertas':
        return abertas;

      case 'movimentacoes concluidas':
        return concluidas;

      case 'tempo de resolucao':
        return tempo;

      default:
        return [];
    }
  };

  const orderArray = (array) => {
    const ordered = array.sort((a, b) => b.total - a.total);

    return ordered;
  };

  const renderSeguradoraAbertas = () => {
    const array = [];

    for (let index in tableSeguradora) {
      const total =
        tableSeguradora[index]['Inclusão'] + tableSeguradora[index]['Exclusão'];

      const nome = index.toUpperCase();

      const data = tableSeguradora[index];

      array.push({ nome, total, data });
    }
    orderArray(array);
    setAbertas(array);
  };

  const renderSeguradoraConcluidas = () => {
    const array = [];

    for (let index in movimentacoesSeguradora) {
      const total =
        movimentacoesSeguradora[index]['Inclusão'] +
        movimentacoesSeguradora[index]['Exclusão'];

      const nome = index.toUpperCase();

      const data = movimentacoesSeguradora[index];

      array.push({ nome, total, data });
    }
    // console.log(array)
    orderArray(array);
    setConcluidas(array);
  };

  const renderSeguradoraTempo = () => {
    const array = [];
    // console.log("tempoMedioSeguradora", tempoMedioSeguradora)

    for (let index in tempoMedioSeguradora) {
      const seguradoraDados = {
        data: {
          Inclusão: 0,
          Exclusão: 0
        }
      };

      seguradoraDados.nome = index.toUpperCase();

      let tempoTotalInclusao = 0;
      let tempoTotalExclusao = 0;

      if (tempoMedioSeguradora[index]['Inclusão']['count'] !== 0) {
        tempoTotalInclusao =
          tempoMedioSeguradora[index]['Inclusão']['tempo_total'] /
          1000 /
          60 /
          60 /
          24;
        const mediaInclusao = Math.ceil(
          tempoTotalInclusao / tempoMedioSeguradora[index]['Inclusão']['count'],
          0
        );
        seguradoraDados.data['Inclusão'] = mediaInclusao;
      }

      if (tempoMedioSeguradora[index]['Exclusão']['count'] !== 0) {
        tempoTotalExclusao =
          tempoMedioSeguradora[index]['Exclusão']['tempo_total'] /
          1000 /
          60 /
          60 /
          24;
        const mediaExclusao = Math.ceil(
          tempoTotalExclusao / tempoMedioSeguradora[index]['Exclusão']['count'],
          0
        );
        seguradoraDados.data['Exclusão'] = mediaExclusao;
      }

      let mediaTotal = 0;
      if (
        tempoMedioSeguradora[index]['Inclusão']['count'] !== 0 ||
        tempoMedioSeguradora[index]['Exclusão']['count'] !== 0
      ) {
        mediaTotal =
          (tempoTotalInclusao + tempoTotalExclusao) /
          (tempoMedioSeguradora[index]['Inclusão']['count'] +
            tempoMedioSeguradora[index]['Exclusão']['count']);
        seguradoraDados.total = Math.ceil(mediaTotal, 0);
      }

      array.push(seguradoraDados);
    }
    orderArray(array);
    setTempo(array);
  };

  useEffect(() => {
    renderSeguradoraAbertas();
    renderSeguradoraConcluidas();
    renderSeguradoraTempo();
  }, [tableSeguradora, movimentacoesSeguradora, relatorioSelecionado]);

  return (
    <WrapperTabelaSeguradora>
      <table>
        <thead>
          <tr>
            <th className="seguradora">Seguradora</th>
            <th>Inclusões {tipo === 'dias' ? '(Dias)' : ''}</th>
            <th>Exclusões {tipo === 'dias' ? '(Dias)' : ''}</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {renderTipoRelatorio()?.map((element, index) => (
            <tr key={index}>
              <td>{element.nome}</td>
              <td>{element.data['Inclusão']}</td>
              <td>{element.data['Exclusão']}</td>
              <td>{element.total}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </WrapperTabelaSeguradora>
  );
};
