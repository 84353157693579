import styled from 'styled-components'
import media from 'styled-media-query';

export const Wraper = styled.div`

footer {
  background-color: #f5f5f5;
  color: #3B3838;
  font-size: .9rem;
  padding: 2.5rem 0px;
  //margin-top: 3rem;
 
}



footer h6 {
  font-size: 0.9rem;
  margin-bottom: 15px;
}

ul.list-footer {
  list-style: none;
  padding: 0;
}
.logo-footer {
  font-size: 20px;
}

.logo-footer{
  width: 150px;
}
.logo-aws{
  width: 130px;
  height: 62px;

}

.contact-info {
  margin-bottom: 20px;
  padding: 10px 0px;
  
}

.flex-content-end {
    display: flex;
    justify-content: flex-end;
}

.section-pt{
padding-top: 3.5rem;
}


  ${media.lessThan("600px")`
  .logo-footer {
      width: 90px;
    }
    .logo-aws {
      width: 100px;
      height: 47px;
    }

    .section-pt{
      padding-top: 1.5rem;
    }

  `}

`

export const GridFooter = styled.div`

  display:grid;
  grid-gap: 30px;
  grid-template-columns:1.5fr 1fr 1fr 1fr;
  grid-template-rows:1fr 1.5fr;
  grid-template-areas: 
  "contato seguros beneficios patrimoniais"
  "contato termos termos termos";

  .link{
  font-family: "Avenir Next GEO W05 Demi";
  margin:.5rem 0;
  display:block
  
}

  .contato{
    grid-area:contato
  }
  .seguros{
    grid-area:seguros
  }
  .beneficios{
    grid-area:beneficios
  }
  .patrimoniais{
    grid-area:patrimoniais
  }
  .termos{
    grid-area:termos;
    p{
    font-size: 1rem ;
    line-height:1.5rem;
    }
  }

  @media (max-width: 600px){
    grid-gap: 5px;
    grid-template-columns:50%  50% ;
    grid-template-rows:1fr .5fr 1fr;
    grid-template-areas: 
    "contato seguros"
    "beneficios patrimoniais"
    "termos termos";
  }

`