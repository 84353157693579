import React from 'react'

import logo from 'assets/imgs/logo-coinsure.png'

import {Footer} from './styles'

const FooterPainel = () => {
  return (
    <Footer>
        <div className="image">
          <img src={logo} alt="logo coinsure" />
        </div>
    </Footer>
  )
}

export default FooterPainel
