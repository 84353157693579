import styled from 'styled-components'
import colors from 'apps/broker/styles/colors'

export const TableDemandas = styled.table`
  max-width: 600px;
  max-height: 100%;
  margin: 0;
  border-collapse: collapse;

  thead {
    text-align: left;
    width: 100%;
    position: sticky;
    top: 0;
    background: ${colors.genoaLightBackgroundGrey};

    .icon_user {
      text-align: center;
    }

    th {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-family: 'Avenir Next GEO W05 Regular';
      color: ${colors.genoaBlue};
      font-size: 0.9em;
      padding: 5px;
    }
  }

  tbody {
    text-align: left;
    width: 100%;

    .icon_user {
      text-align: left;
    }

    td {
      font-family: 'Avenir Next Geo W05 Demi';
      font-size: 0.85em;
      padding: 5px;
    }
  }

  tr {
    display: grid;
    grid-template-columns: 160px 90px 90px 90px 90px 90px 90px;
  }

  .ticket_corretor {
    cursor: pointer;

    :hover {
      font-family: 'Avenir Next GEO W05 Demi';
      color: ${colors.genoaBlue};
      cursor: pointer;
    }
  }
`
