import styled from 'styled-components';
import colors from 'styles/colors';

export const ContentTab = styled.div`
    min-height: 60vh;

    display: flex;
    flex-direction: column;
    row-gap: 20px;

    .back{
      margin-bottom: 10px;
      span{
        display: flex;
        align-items: center;
        color: #62A0E2;
        cursor: pointer;
      }
    }

    .title {
      display: grid;
      align-items: flex-start;
      margin-bottom: 25px;
      .nome-status-container {
        display: flex;
        align-items: flex-start;
        h3{
          font-family: 'Avenir Next GEO W05 Regular';
          font-size: 17px;
          color: ${colors.genoaDarkBlue};
        }
        span{
          margin-left: 10px;
          font-size: 15px;
          margin-top: 1px;
          color: ${colors.genoaDarkBlue};
        }

        .icon-toggle-info-display {
          
          margin-right: 5px;
          svg {
            margin-top: 1px;
            color: ${colors.genoaBlue};
          }
        }
        
        svg{
          font-size: 18px;
          cursor: pointer;
          color: ${colors.genoaDarkBlue};
        }

        .icon-edit {
          margin-top: 1px;
        }

        .status-contrato {
          margin-top: 1px;
        }
        
      }

      .status-in-cancel-process-circle-icon {
        animation: blinking-in-cancel-process-icon 1s infinite;
      }

      .status-canceled-active-circle-icon {
        animation: blinking-canceled-icon 1s infinite;
      }

      .status-active-circle-icon>circle {
        animation: blinking-active-icon 1s infinite;
      }
      

      @keyframes blinking-active-icon {
        0% { color: #008631; }
        50% { color: #00C04B; }
        100% { color: #008631; }
      }

      @keyframes blinking-in-cancel-process-icon {
        0% { color: #D29E00; }
        50% { color: #FFD900; }
        100% { color: #D29E00; }
      }

      @keyframes blinking-canceled-icon {
        0% { color: #8B0000; }
        50% { color: #DC143C; }
        100% { color: #8B0000; }
      }

      .textCancelar {
        margin-left: 60px;
        display: inline-block;
        color: ${colors.genoaBlue};
        cursor: pointer;
      }

      .sub-title {
        margin-top: 10px;
        padding-left: 25px;
        display: flex;

        .sub-title-item-title {
          font-family: 'Avenir Next GEO W05 Bold';
        }

        .sub-title-item-value {
          margin-left: 10px;
        }

        .sub-title-item-container {
          margin-right: 20px;
          font-size: 17px;
        }
      }
    }

    .container-cancelar-hidden {
      display: none;
    }

    .container-cancelar-visible {
      display: flex;
      flex-direction: row;
      align-items: center;
      h3 {
        font-family: 'Avenir Next GEO W05 Regular';
        font-size: 20px;
      }

      .text-vigencia {
        font-family: 'Avenir Next GEO W05 Demi';
        margin-bottom: 2rem;
      }

      .delete-btn {
        background-color: ${colors.genoaBlue};
        border: 1px ${colors.genoaBlue} solid;
        cursor: pointer;
        font-family: 'Avenir Next GEO W05 Demi';
        font-size: 1rem;
        color: ${colors.white};
        padding: 4px 15px;
        border-radius: 6px;
        :hover{
          background-color: ${colors.white};
          color: ${colors.genoaBlue};
          padding: 4px 15px;
          border-radius: 4px;
          cursor: pointer;
        }
      }

      .react-datepicker-wrapper {
        width: 300px;
      }

      .react-datepicker__tab-loop {
        display: inline-block;
      }
    
      .delete-input-visible {
        border: 1px solid #62A0E2;
        display: inline-block;
        text-align: center;
        font-family: 'Avenir Next GEO W05 Regular';
        padding: 6px 13px;
        border-radius: 10px;
        margin-left: 15px;
        font-size: 1rem;
      }
    }

    .delete-confirmation-icon {
      margin-left: 30px;
      color: green;
    }

    .reajuste-container {
      margin-left: 40px;
      .reajuste-title {
        color: ${colors.genoaBlue};
        font-size: 17px;
        font-family: "Avenir Next GEO W05 Bold";
      }

      .adicionar-reajuste-container {
        width: max-content;
        span {
          font-size: 15px;
          display: flex;
          align-items: center;
          color: ${colors.genoaBlue};

          :hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }

    .section-reajuste {
      display: flex;
      flex-direction: column;
      row-gap: 40px;
      // margin-top: 50px;
      transition: all .6s ease-in-out;
      
      .reajuste-tabela-title {
        font-family: 'Avenir Next GEO W05 Demi';
        margin-left: 40px;
      }
  
      .cols-data-reajuste-form-aberto {
        display: flex;
        max-height: 200px;
        margin-left: 40px;
        
        transition-delay: 0.6s;
        transition: all .6s ease-in-out;
      }

      .cols-data-reajuste-form-fechado {
        display: flex;
        max-height: 200px;
        margin-left: 40px;
        transition: all .4s ease-in-out;
      }

      .form-table-visible {
        transition: all .4s ease-in-out;
        overflow: hidden;
        height: 320px;
      }

      .form-table-hidden {
        height: 0px;
        overflow: hidden;
        transition: all .6s ease-in-out;
      }

      
    }

    .section-reajuste-comissionamento {
      // display: grid;
      // grid-template-columns: 450px 600px;
      // column-gap: 40px;
      margin-left: 40px;
      transition: all .6s ease-in-out;
      
      .reajuste-tabela-title {
        font-family: 'Avenir Next GEO W05 Demi';
        margin-left: 40px;
      }
  
      .cols-data-reajuste-form-aberto {
        display: flex;
        // justify-content: center;
        // margin-bottom: 60px;
        transition-delay: 0.6s;
        transition: all .6s ease-in-out;
      }

      .cols-data-reajuste-form-fechado {
        // margin-top: 10px;
        display: flex;
        // justify-content: center;
        transition: all .4s ease-in-out;
        // margin-bottom: 90px;
      }

      .form-table-visible {
        transition: all .4s ease-in-out;
        overflow: hidden;
        height: 120px;
      }

      .form-table-hidden {
        height: 0px;
        overflow: hidden;
        transition: all .6s ease-in-out;
      }

      
    }

    .dados-container {

      .dados-gerais-title {
        color: ${colors.genoaBlue};
        font-size: 17px;
        font-family: "Avenir Next GEO W05 Bold";

        .icon-contrato {
          margin-left: 5px;
          color: ${colors.genoaDarkBlue};
        }
      } 

      .dados-gerais-estipulantes-info {
        margin-bottom: 5px;

        .cols-estipulantes-info {
          display: grid;
          margin-top: 30px;

          grid-template-columns: 425px 90px;
          gap: 15px;
          color: ${colors.genoaDarkBlue};
    
          >div{
            .title-col{
              font-family: 'Avenir Next GEO W05 Demi';
            }    
    
            .estipulante-name {
              height: 50px;
              font-size: 15px;
              display: flex;
              column-gap: 15px;
            }

            .estipulante-vidas {
              height: 50px;
              font-size: 15px;
              text-align: center;
            }
    
            .caracteristicas{
              display: grid;
              grid-template-columns: 1.5fr 1fr;
              
              >div{
                line-height: 26px;
              }
            }
          }
        }
      }

      .dados-gerais-contratos {
        height: 400px;
        .contrato-caracteristicas {
          margin-top: 30px;

          padding-left: 15px;
          padding-right: 15px;
          display: grid;
          grid-template-columns: 2fr 2.5fr 140px;
          column-gap: 40px;
          color: ${colors.genoaGrey};

          .caracteristica-title {
            font-family: "Avenir Next GEO W05 Bold";
          }

          .caracteristica-container {
            margin-bottom: 10px;

            .caracteristica-title {
              margin-right: 15px;
            }
          }

          .caracteristica-datas-container {
            display: grid;
            grid-template-columns: 120px 20px;
            margin-bottom: 10px;
          }
        }
      }
    }

    .close-delete-mode {
      margin-left: 600px;
      margin-top: 20px;
      height: 5px;
      width: 30px;
      text-align: center;
      font-size: 1.8rem;
      color: ${colors.genoaBlue};
      :hover {
        cursor: pointer;
      }
    }
    
    .sectionContentReajuste{
      display: flex;
      flex-direction: column;
      row-gap: 15px;
      position: relative;

      margin-top: 10px;
    }
    
    .arrow-close-container {
      background-color: red;
    }
    
    .fechar-reajuste {
      color: ${colors.genoaBlue};
      font-family: 'Avenir Next GEO W05 Bold';
      :hover {
        cursor: pointer;
      }
      transform: rotate(45deg);
      transition: all 0.5s;
    }

    .adicionar-reajuste {
      color: ${colors.genoaBlue};
      font-family: 'Avenir Next GEO W05 Bold';
      :hover {
        cursor: pointer;
        text-decoration: underline;
      }
      transition: all 0.5s;
    }

    .cols-header-container {
      display: flex;
      justify-content: center;
      justify-content: flex-start;
      z-index: -1;
      font-size: 16px;
    }

    .reajustes-table-container{
      max-height: 200px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      overflow-y: overlay;
      font-size: 16px;

      ::-webkit-scrollbar {
        width: 6px;
        background: transparent;
      }
      ::-webkit-scrollbar-thumb {
        background: ${colors.genoaBlue};
        box-shadow: none;
        border-radius: 14px;
      }
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px grey;
        border-radius: 14px;
      }
    }

    .cols-data-container {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid ${colors.businessPageGrey};
      border-radius: 10px;
      margin-top: 5px;
      min-height: 50px;
      max-height: 50px;
    }

    // .cols-header-reajuste {
    //   display: grid;
    //   grid-template-columns: 250px 1fr 1fr 1fr 1fr 70px;
    //   font-family: 'Avenir Next GEO W05 Demi';
    //   gap: 10px;
    //   width: 1200px;
    //   text-align: center;
    //   justify-content: center;
    //   margin-left: -110px;
    //   margin-top: 30px;
    // }

    .cols-header-reajuste-inner {
      display: grid;
      grid-template-columns: 100px 100px 170px 100px 300px 100px;
      font-family: 'Avenir Next GEO W05 Demi';
      background-color: ${colors.rhBlue};
      border: 2px solid ${colors.rhBlue};
      border-radius: 10px;
      text-align: center;
      align-items: center;
      gap: 10px;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      height: 40px;
    }

    .cols-header-comissionamento-inner {
      display: grid;
      grid-template-columns: 1fr 1fr;
      font-family: 'Avenir Next GEO W05 Demi';
      column-gap: 10px;
      border: 2px solid ${colors.rhBlue};
      border-radius: 10px;
      background-color: ${colors.rhBlue};
      text-align: center;
      align-items: center;
      height: 50px;
      width: 500px;
    }

    .contrato-data-table {
      min-height: 20vh;
      margin-top: 20px;
    }

    .contrato-data-table-inner {
      min-height: 20vh;
      width: 100%;
    }

    .contrato-data-table-comissionamento-inner {
      min-height: 216px;
    }

    .cols-data-comissionamento-inner {
      display: grid;
      grid-template-columns: 1fr 1fr;
      line-height: 15px;
      font-size: 16px;
      column-gap: 10px;
      align-items: center;
      text-align: center;
      margin-top: 5px;
      width: 500px;
    }

    .ano-icon-container {
      display: grid;
      grid-template-columns: 40px 60px;
      justify-content: end;

      .open-checkboxes-icon {
        color: red;
        opacity: 0.7;
        margin-left: 35px;
      }

      .delete-icon {
        margin-left: 3px;
        color: red;
        margin-left: 40px;
        :hover {
          cursor: pointer;
        }
      }

      .start-delete-mode {
        color: red;
        opacity: 0.7;
        z-index: 1;
        :hover {
          cursor: pointer;
        }
      }
    }

    .col-nome-contrato {
      text-align: initial;
      font-size: 20px;
      max-height: 27.2px;
      text-decoration: underline;
      text-underline-offset: 3px;
      font-family: 'Avenir Next GEO W05 Bold';
    }

    .cols-header-contrato {
      color: white;
      font-size: 18px;
    }

    .cols-header-comissionamento {
      color: white;
      font-size: 16px;
    }

    // .cols-data-reajuste {
    //   display: grid;
    //   grid-template-columns: 250px 1fr 1fr 1fr 1fr 70px;
    //   line-height: 35px;
    //   font-size: 17px;
    //   gap: 10px;
    //   width: 1200px;
    //   justify-content: center;
    //   text-align: center;
    //   margin-left: -110px;
    //   margin-top: 5px;
    // }

    .cols-data-reajuste-inner {
      display: grid;
      grid-template-columns: 100px 100px 170px 100px 300px 100px;
      justify-content: center;
      column-gap: 10px;
      line-height: 15px;
      font-size: 1em;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      margin-top: 5px;
      p {
        text-align: center;
      }
    }

    .cols-reajuste {
      width: 1200px;
      display: grid;
      margin-top: 20px;
      margin-left: -10vw;
      grid-template-columns: 300px 1fr 1fr 1fr 15px 1fr 15px;
      // background-color: red;
      gap:40px;
    
      .check-icon {
        color: green;
        margin-top: 20px;
        :hover {
          cursor: pointer;
        }
      }
      
      .form-control {
        background-color: #fff;
        border: 0.5px solid ${colors.genoaBlue};
        border-radius: 10px;
        opacity: 1;
        font-size: 1rem;
        margin-bottom: 10px;
        color: ${colors.genoaGrey};
        text-align: center;
        width: 100%;
        height: 30px;
        ::placeholder {
          color: #c1c1c1;
        }
      }

      .equal-icon {
        margin-top: 25px;
      }

      .title-col {
        text-align: center;
      }

      .title-col-reajuste {
        margin-bottom: 30px;
        text-align: center;
        font-family: 'Avenir Next GEO W05 Demi';
      }

      .reajuste-submit-btn {
        border: none;
        background: transparent;
      }

      .select-contrato-reajuste {
        background-color: #fff;
        border: 0.5px solid ${colors.genoaBlue};
        border-radius: 10px;
        opacity: 1;
        font-size: 0.8rem;
        margin-bottom: 10px;
        color: ${colors.genoaGrey};
        text-align: center;
        width: 100%;
        height: 30px;
        ::placeholder {
          color: #c1c1c1;
        }
      }
      }

      .input-reajuste {
        background-color: #fff;
        border: 2px solid ${colors.genoaBlue};
        border-radius: 10px;
        opacity: 1;
        font-size: 1rem;
        margin-bottom: 10px;
        color: ${colors.genoaGrey};
        text-align: center;
        width: 100%;
        height: 30px;
        ::placeholder {
          color: #c1c1c1;
        }
      }

      >div{
        .title-col{
          font-family: 'Avenir Next GEO W05 Demi';
          margin-bottom: 20px;
        }    

        

        .caracteristicas{
          display: grid;
          grid-template-columns: 1.5fr 1fr;
          gap: 10px;
          margin-top: -6px;

          .coluna-central-caracteristicas {
            max-width: 100%;
            >p {
              margin-bottom: 5px;
            }
          }
          .coluna-lateral-caracteristicas {
            max-width: 100%;
            >p {
              margin-bottom: 5px;
            }
          }
        }
      }
    }

    .cols-comissionamento-inner {
      display: grid;
      grid-template-columns: 160px 160px 20px;
      // background-color: red;
      gap: 60px;
      font-size: 16px;
    
      .check-icon {
        color: green;
        margin-top: 20px;
        :hover {
          cursor: pointer;
        }
      }
      
      .form-control {
        background-color: #fff;
        border: 0.5px solid ${colors.genoaBlue};
        border-radius: 10px;
        opacity: 1;
        font-size: 1rem;
        margin-bottom: 10px;
        color: ${colors.genoaGrey};
        text-align: center;
        width: 100%;
        height: 30px;
        ::placeholder {
          color: #c1c1c1;
        }
      }

      .equal-icon {
        margin-top: 25px;
      }

      .title-col {
        text-align: center;
      }

      .title-col-comissionamento {
        margin-bottom: 30px;
        // text-align: center;
        font-family: 'Avenir Next GEO W05 Demi';
      }

      .comissionamento-submit-btn {
        border: none;
        background: transparent;
      }

      .col-comissionamento {
        display: flex;
        flex-direction: column;
        // align-items: center;
      }

      .select-contrato-reajuste {
        background-color: #fff;
        border: 0.5px solid ${colors.genoaBlue};
        border-radius: 10px;
        opacity: 1;
        font-size: 0.8rem;
        margin-bottom: 10px;
        color: ${colors.genoaGrey};
        text-align: center;
        width: 100%;
        height: 30px;
        ::placeholder {
          color: #c1c1c1;
        }
      }
      }

      .input-comissionamento {
        border: 2px solid ${colors.genoaBlue};
        border-radius: 10px;
        opacity: 1;
        font-size: 16px;
        margin-bottom: 10px;
        color: ${colors.genoaGrey};
        text-align: center;
        height: 30px;
        ::placeholder {
          color: #c1c1c1;
        }
      }

      >div{
        .title-col{
          font-family: 'Avenir Next GEO W05 Demi';
          margin-bottom: 20px;
        }    

        

        .caracteristicas{
          display: grid;
          grid-template-columns: 1.5fr 1fr;
          gap: 10px;
          margin-top: -6px;

          .coluna-central-caracteristicas {
            max-width: 100%;
            >p {
              margin-bottom: 5px;
            }
          }
          .coluna-lateral-caracteristicas {
            max-width: 100%;
            >p {
              margin-bottom: 5px;
            }
          }
        }
      }
    }
    
    .cols-reajuste-inner {
      display: grid;
      grid-template-columns: 125px 125px 135px 15px 125px 15px;
      margin-top: 10px;
      column-gap: 20px;
    }
      
      .form-control {
        background-color: #fff;
        border: 0.5px solid ${colors.genoaBlue};
        border-radius: 10px;
        opacity: 1;
        font-size: 16px;
        margin-bottom: 10px;
        color: ${colors.genoaGrey};
        text-align: center;
        width: 100%;
        height: 30px;
        ::placeholder {
          color: #c1c1c1;
        }
      }

      .equal-icon {
        margin-top: 25px;
      }

      .title-col {
        text-align: center;
      }

      .title-col-reajuste {
        margin-bottom: 30px;
        font-size: 15px;
        // text-align: center;
        font-family: 'Avenir Next GEO W05 Demi';
      }


      .select-contrato-reajuste {
        background-color: #fff;
        border: 0.5px solid ${colors.genoaBlue};
        border-radius: 10px;
        opacity: 1;
        font-size: 0.8rem;
        margin-bottom: 10px;
        color: ${colors.genoaGrey};
        text-align: center;
        width: 100%;
        height: 30px;
        ::placeholder {
          color: #c1c1c1;
        }
      }
      }

      .input-reajuste {
        background-color: #fff;
        border: 2px solid ${colors.genoaBlue};
        border-radius: 10px;
        opacity: 1;
        font-size: 16px;
        margin-bottom: 10px;
        color: ${colors.genoaGrey};
        text-align: center;
        width: 100%;
        height: 30px;
        ::placeholder {
          color: #c1c1c1;
        }
      }

      .container_observacoes {
        .title-col-reajuste {
          margin-bottom: 30px;
          text-align: left;
          font-family: 'Avenir Next GEO W05 Demi';
        }

        textarea {
          resize: none;
          border: 2px solid ${colors.genoaBlue};
          border-radius: 10px;
          font-size: 1em;
          padding: 10px;
          height: 50px;
          width: 450px;
      
          :focus-visible {
            outline: 1px ${colors.genoaBlue} auto;
          }
        }
      }

      .btn_container {
        display: flex;
        justify-content: flex-start;

        .reajuste-submit-btn {
          background: none;
          color: inherit;
          border: none;
          padding: 0;
          font: inherit;
          cursor: pointer;
          outline: inherit;
          color: ${colors.genoaBlue};
          padding: 4px 8px;
          font-size: 16px;
          font-family: 'Avenir Next GEO W05 Bold';
        }
      }

      >div{
        .title-col{
          font-family: 'Avenir Next GEO W05 Demi';
          margin-bottom: 20px;
        }    

        

        .caracteristicas{
          display: grid;
          grid-template-columns: 1.5fr 1fr;
          gap: 10px;
          margin-top: -6px;

          .coluna-central-caracteristicas {
            max-width: 100%;
            >p {
              margin-bottom: 5px;
            }
          }
          .coluna-lateral-caracteristicas {
            max-width: 100%;
            >p {
              margin-bottom: 5px;
            }
          }
        }
      }
    }

    .cols {
      display: grid;
      margin-top: 20px;
      grid-template-columns: 1.8fr 0.5fr 4.3fr;
      gap:40px;

      >div{
        .title-col{
          font-family: 'Avenir Next GEO W05 Demi';
        }    

        .estipulante-name {
          margin-top: 30px;
          margin-bottom: 20px;
          height: 50px;
        }

        .caracteristicas{
          display: grid;
          grid-template-columns: 1.5fr 1fr;
          
          >div{
            line-height: 26px;
          }
        }
      }
    }
    .header-abas-container {
      height: 40px;
      padding: 0 40px;
      justify-content: space-between;
      display: flex;
      user-select: none;
      background: #EDEDED;
      -moz-user-select: none;
      -webkit-user-select: none;

      .aba-title {
        // margin-right: 40px;
        cursor: pointer;
        // padding: 1px 5px;
        line-height: 40px;
        align-self: center;
        margin-bottom: 1px;
        font-family: 'Avenir Next GEO W05 Bold';
        font-size: 13px;
        color: #878787;
        background: none;
        border: none;
        :hover {
          cursor: pointer;
        }
        // border-bottom: 2px #878787 solid;
      }
      .aba-title-active {
        // margin-right: 40px;
        cursor: pointer;
        // padding: 1px 5px;
        line-height: 40px;
        align-self: center;
        margin-bottom: 1px;
        font-family: 'Avenir Next GEO W05 Bold';
        font-size: 13px;
        color: ${colors.genoaBlue};
        background: none;
        border: none;
        :hover {
          cursor: pointer;
        }
        // border-bottom: 2px ${colors.genoaBlue} solid;
      }
    }
  .sectionContentPerguntas{
    
    display: grid;
    grid-template-columns: 3fr 1fr;
  }
    .textVidas {
      color: #62A0E2;
      cursor: pointer;
      padding-left: 40px;
    }
    .reajuste-open-close-container {
      height: 20px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .contrato-content {
      margin-left: 25px;
      margin-right: 25px;
      min-height: 450px;
      max-height: 450px;
      margin-top: 30px;
      transition: height 2s ease-in-out;
    }
`;
export const Documents = styled.div`
  .sectionContent {
    min-height: 400px;
    max-height: 700px;
    margin-bottom: 2rem;
    overflow-y: overlay;
  }

  .documentos-title {
    color: ${colors.genoaBlue};
    font-size: 17px;
    font-family: 'Avenir Next GEO W05 Bold';
  }

  .title {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    h3 {
      font-family: 'Avenir Next GEO W05 Bold';
      font-size: 20px;
    }
    span {
      margin-left: 10px;
    }
  }

  .listFile {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    padding-left: 15px;
    padding-right: 15px;


    .tipo {
      font-family: 'Avenir Next GEO W05 Demi';
      text-decoration: underline;
      margin-bottom: 10px;
    }

    ul {
      padding-top: 30px;
      padding-bottom: 20px;
      list-style: none;
      li {
        line-height: 1.8rem;
      }
    }
  }
  .div-input-file {
    max-width: 600px;
    form {
      display: flex;
      margin-top: 10px;
      margin-bottom: 10px;
      flex-direction: column;
      justify-content: space-between;

      span.error-msg {
        color: #ff1e5c;
        font-size: 13px;
        margin-bottom: 5px;
      }
    }

    .div-input-files {
      display: flex;
      flex-direction: row;
      column-gap: 30px;
    }

    .label-file {
      font-size: 16px;
      font-family: 'Avenir Next GEO W05 Bold';
      color: #fff;

      input {
        display: none;
      }
      span {
        display: flex;
        width: max-content;
        align-items: center;
        color: ${colors.genoaBlue};
        :hover {
          cursor: pointer;
          text-decoration: underline;
        }

        svg {
          font-size: 1.3rem;
        }
      }
    }

    .buttons {
      display: flex;
      gap: 5px;

      .btn-clear {
        font-size: 16px;
        font-family: 'Avenir Next GEO W05 Demi';
        background: transparent;
        border: none;
        color: #f72757;

        :hover {
          cursor: pointer;
        }
        :disabled {
          opacity: 0.5;
        }
      }
    }
    button.btn {
      font-size: 16px;
      font-family: 'Avenir Next GEO W05 Bold';
      background: transparent;
      border: none;
      color: ${colors.genoaBlue};

      :hover {
        cursor: pointer;
      }
      :disabled {
        opacity: 0.5;
      }
    }

    .btn-clear {
      font-size: 16px;
      font-family: 'Avenir Next GEO W05 Demi';
      background: transparent;
      border: none;
      color: #f72757;

      :hover {
        cursor: pointer;
      }
      :disabled {
        opacity: 0.5;
      }
    }

    span.errorsMsg {
      font-size: 12px;
      color: #f72757;
    }

    .icon-folder {
      margin-right: 5px;
    }
  }

  .document-name {
    display: flex;
    font-size: 15px;
    background-color: #fff;
    border: none;
    border-radius: 30px;
    height: 33px;
    width: 330px;
    color: #3b3838;
    justify-content: center;
    box-sizing: border-box;
    align-items: center;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    font-family: 'Avenir Next GEO W05 Regular';

    ::placeholder {
      font-family: 'Avenir Next GEO W05 Regular';
    }

    :focus-visible {
      outline: 2px ${colors.genoaBlue}f5 auto;
    }
  }
  .input-default.active {
    background: #878787;
    color: #fff;
  }

  .file-document {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .link-text {
      text-decoration: none;
      font-family: 'Avenir Next GEO W05 Bold';
      color: ${colors.genoaGrey};
      font-size: 15px;
      margin-right: 20px;

      :hover {
        text-decoration: underline;
      }
    }

    .icon-documento {
      color: ${colors.genoaGrey};
      margin-right: 10px;
    }

    .delete-file {
      color: red;
      margin-right: 20px;

      :hover {
        cursor: pointer;
      }
    }

    .edit-file {
      color: ${colors.genoaDarkBlue};

      :hover {
        cursor: pointer;
      }
    }
  }
`;
export const ContentTabs = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  .menuContentTab {
    display: flex;
    flex: 1;
    padding: 10px 50px;

    place-content: center;
    > div {
      padding: 0px 50px;
      > button {
        cursor: pointer;
        padding: 1px 5px;
        font-family: 'Avenir Next GEO W05 Bold';
        font-size: 18px;
        color: #878787;
        background: none;
        border: none;
        border-bottom: 2px #878787 solid;
      }
      > button.active {
        color: #62a0e2;
        border-bottom: 2px #62a0e2 solid;
      }
    }
  }

  button.addSeguro {
    background: none;
    border: none;
    width: 210px;
    cursor: pointer;
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 1rem;
    color: ${colors.genoaBlue};
    padding: 6px 15px;
    border-radius: 6px;
    margin-left: 0px;

    :hover {
      text-decoration: underline;
      text-underline-position: ;
    }
  }
  .back {
    .back-btn {
      display: flex;
      align-items: center;
      background: none;
      border: none;
      color: #62a0e2;
      background: #ece7f3;
      border-radius: 13px;
      padding: 2px 10px 2px 5px;
    }
    span {
      font-size: 14px;
      cursor: pointer;
      font-family: 'Avenir Next GEO W05 Bold';
      color: #3b3838;
    }
    svg {
      font-size: 1.5rem;
      cursor: pointer;
    }
  }
`;

export const TabsPerguntas = styled.div`
  .tab-menu {
    display: flex;
    .btn {
      font-family: 'Avenir Next GEO W05 Demi';
      cursor: pointer;
      border: none;
      background-color: #ececec;
      display: flex;
      align-items: center;
      padding: 10px 20px;
      border-bottom: 2px transparent solid;
    }
    .active {
      border-bottom: 2px #62a0e2 solid;
    }
  }
`;
export const ListaPerguntas = styled.div`
  margin-top: 20px;
  display: grid;
  margin-left: 20px;
  max-height: 400px;
  overflow-y: overlay;
  ::-webkit-scrollbar {
    display: none;
  }

  grid-template-columns: 1fr 1fr;

  .card {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    margin-right: 20px;
    .content {
      min-height: 81px;
      .pergunta {
        font-size: 16px;
        font-family: 'Avenir Next GEO W05 Demi';
      }
      .resposta {
        font-size: 15px;
        margin-top: 5px;
        font-family: 'Avenir Next GEO W05 Regular';
      }
    }
    .options {
      display: flex;
      flex-direction: column;
      align-items: center;
      visibility: hidden;

      button.edit {
        cursor: pointer;
        background: none;
        border: none;
        font-size: 1rem;
        color: #62a0e2;
      }
      button.remove {
        cursor: pointer;
        background: none;
        border: none;
        font-size: 1rem;
        color: #e46292;
      }
    }

    :hover {
      .options {
        visibility: visible;
      }
    }
  }
`;
export const AddPerguntas = styled.div`
  padding-top: 20px;

  .titleTab {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    color: ${colors.genoaBlue};

    svg {
      margin-bottom: 3px;
      margin-right: 5px;
    }
  }

  form {
    display: flex;
    flex-direction: column;
  }
  .fieldset-group {
    max-width: 1000px;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 2rem;
  }
  fieldset {
    max-width: 400px;
    border: none;
  }
  .input-group {
    display: flex;
    flex-direction: column;
    label {
      font-family: 'Avenir Next GEO W05 Demi';
      margin-bottom: 6px;
    }
    span.errorsMsg {
      color: #f00;
      font-size: 12px;
    }
    input,
    textarea {
      font-family: 'Avenir Next GEO W05 Regular';
      margin-right: 30px;
      border: 1px ${colors.genoaBlue} solid;
      border-radius: 4px;
      padding: 5px 8px;
      margin-bottom: 10px;
    }
  }

  span.remove {
    background-color: #f5c7c7;
    padding: 1px 10px;
    border-radius: 15px;
    font-size: 12px;
    color: #f34545;
    cursor: pointer;
  }

  .addmore {
    padding: 20px 0;

    button.add-more {
      border: none;
      background: no-repeat;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-bottom: 2rem;
      font-family: 'Avenir Next GEO W05 Demi';
      color: #62a0e2;
    }
  }

  button.send-btn {
    font-family: 'Avenir Next GEO W05 Demi';
    display: flex;
    align-self: flex-end;
    margin-right: 30px;
    margin-top: 15px;
    align-items: center;
    font-size: 18px;
    justify-content: center;
    max-width: 100px;
    padding: 3px 10px;
    border: 2px ${colors.genoaBlue} solid;
    background: ${colors.genoaBlue};
    color: #fff;
    border-radius: 4px;
    cursor: pointer;

    :hover {
      color: ${colors.genoaBlue};
      border: 2px ${colors.genoaBlue} solid;
      background: ${colors.white};
    }
  }
`;
export const DialogDeleteContato = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 20px 20px;

  .title {
    h3 {
      font-family: 'Avenir Next GEO W05 Bold';
    }
  }

  .content {
    padding: 20px 0;
  }

  form {
    .input-group {
      display: flex;
      flex-direction: column;
      label {
        font-family: 'Avenir Next GEO W05 Demi';
        margin-bottom: 6px;
      }
      span.errorsMsg {
        color: #f00;
        font-size: 12px;
      }
      input,
      textarea {
        font-family: 'Avenir Next GEO W05 Regular';
        max-width: 300px;
        border: 2px #d3c5ea solid;
        border-radius: 4px;
        padding: 5px 8px;
        margin-bottom: 10px;
        font-size: 1rem;
      }
    }

    button.send-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 100px;
      padding: 7px 8px;
      border: 2px #6118de solid;
      background: #62a0e2;
      color: #fff;
      border-radius: 4px;
      cursor: pointer;
    }
  }

  .actions {
    display: flex;

    button {
      cursor: pointer;
      background: none;
      border: none;
      font-family: 'Avenir Next GEO W05 Demi';
      font-size: 1rem;

      :last-child {
        margin-left: 50px;
      }
    }
  }
`;

export const CancelarContratoContainer = styled.div`
  .cancel-form-container {
    height: 400px;
  }

  form {
    margin-top: 30px;
    margin-left: 20px;

    .form-first-row {
      color: ${colors.genoaGrey};
      font-family: 'Avenir Next GEO W05 Regular';
    }

    .form-second-row {
      display: flex;
      align-items: center;
      margin-top: 20px;

      .delete-input-visible {
        font-family: 'Avenir Next GEO W05 Regular';
        display: inline-block;
        padding: 10px;
        padding-left: 20px;
        background: white;
        border-radius: 20px;
        text-align: center;
        border: none;
        font-size: 0.8rem;

        :focus-visible {
          outline: 2px ${colors.genoaBlue}f5 auto;
        }
      }

      .react-datepicker-wrapper {
        width: 200px;
      }

      .react-datepicker__input-container {
        position: relative;
        display: inline-block;
        width: 100%;
      }

      .react-datepicker__day--selected {
        background-color: ${colors.genoaBlue};
      }

      .react-datepicker__day--keyboard-selected {
        color: ${colors.genoaBlue};
        background-color: white;
        font-weight: bold;
      }

      .delete-btn {
        border: none;
        background: transparent;
        font-size: 16px;
        padding: 0;
        color: ${colors.genoaBlue};
        font-family: 'Avenir Next GEO W05 Bold';

        :hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }

      .delete-confirmation-icon {
        height: 20px;
      }
    }
  }
`;
