import styled from 'styled-components';
import colors from 'styles/colors'

export const Container = styled.main`
     
     margin: 0px 50px;
     padding: 70px 0 30px 0;
     min-height: calc(100vh - 450px);

     h1 {
        color: #3B3838;
        font-size: 1.75rem;
        margin-bottom: 1rem;
    }

    .loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;


    p {
      color: ${colors.lightBlue};
    }
  }
    


     @media(min-width: 1750px) {
          margin: 0px auto;
          max-width: 1750px;
          padding: 70px 50px 30px 50px;
     }

`