import { createContext, useContext, useState } from "react";

export const tasksContext = createContext();

const HOME_DEFAULT_TASKS = {
  redirected: false,
  venc: 'vencidas',
  data_inicial: '',
  data_final: '',
  status: 'Aberta',
  corretor: undefined
}

export const TasksContextProvider = ({ children }) => {
  const [tasksFilters, setTasksFilters] = useState(HOME_DEFAULT_TASKS);

  const updateTasksFilters = (data) => {
    setTasksFilters({
      ...tasksFilters,
      ...data
    });
  }

  const resetTasksFilters = () => {
    setTasksFilters(HOME_DEFAULT_TASKS);
  }

  return (
    <tasksContext.Provider value={{ tasksFilters, updateTasksFilters, resetTasksFilters }}>
      {children}
    </tasksContext.Provider>
  );
};

export const useTasksFilters = () => useContext(tasksContext);
