const todosCamposPreenchidos = ({ abasDisponiveis, abasCompletas }) => {
    if (!abasCompletas['estipulanteSelecionado']) {
      return false;
    }

    if (!abasCompletas['tipoBeneficiarioSelecionado']) {
      return false;
    }

    if (abasDisponiveis['showSelecioneTitular'] && !abasCompletas['titular']) {
      return false;
    }

    if (abasDisponiveis['showDadosPlanos'] && !abasCompletas['planoSelecionado']) {
      return false;
    }

    if (abasDisponiveis['showDadosBasicos'] && !abasCompletas['dadosBasicos']) {
      return false;
    }

    if (abasDisponiveis['showDadosEndereco'] && !abasCompletas['endereco']) {
      return false;
    }

    if (abasDisponiveis['showDadosContato'] && !abasCompletas['contato']) {
      return false;
    }

    if (abasDisponiveis['showDadosVinculo'] && !abasCompletas['dadosBasicos']) {
      return false;
    }

    if (abasDisponiveis['showDadosImc'] && !abasCompletas['imc']) {
      return false;
    }

    if (abasDisponiveis['showDadosBancarios'] && !abasCompletas['dadosBancarios']) {
      return false;
    }

    if (abasDisponiveis['showScanDocumentos'] && !abasCompletas['scanDocumentos']) {
      return false;
    }

    if (abasDisponiveis['showFichaProposta'] && !abasCompletas['fichaProposta']) {
      return false;
    }

    return true;
  }

  export default todosCamposPreenchidos;