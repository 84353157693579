import { blue } from '@material-ui/core/colors';
import { Checkbox } from '@mui/material';
import { BiPlus } from 'react-icons/bi';
import { RiSubtractFill } from 'react-icons/ri';
import moment from 'moment';
import { GiHealthNormal } from 'react-icons/gi';
import { FaTooth } from 'react-icons/fa';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { ReactComponent as FireIcon } from 'assets/imgs/svg/fire-svg.svg';
import { Div, TypeMovement } from './styles';

const MovimentsResults = ({
  item,
  selectedCheckboxes,
  setSelectedCheckboxes,
  editMode,
  expand,
  openDetails
}) => {
  const clickUniqueCheckboxHandler = ({ target }) => {
    const checked = target.checked;
    const movimentacaoId = target.name;

    if (checked) {
      setSelectedCheckboxes((prevState) => [...prevState, movimentacaoId]);
    }

    if (!checked) {
      setSelectedCheckboxes((prevState) => {
        const filter = prevState?.filter(
          (elementName) => elementName !== movimentacaoId
        );
        return filter;
      });
    }
  };

  const dateSubtraction = (recentDate, olderDate) => {
    const recentDateFormatted = recentDate?.setHours(0, 0, 0, 0);
    const olderDateFormatted = olderDate?.setHours(0, 0, 0, 0);

    const subtraction = recentDateFormatted - olderDateFormatted;

    const daysExtracted = subtraction / (1000 * 60 * 60 * 24);

    return daysExtracted;
  };

  const isMovimentacaoOnFire = (movimentacao) => {
    const { status_movimentacao, data_movimentacao } = movimentacao;

    if (status_movimentacao !== 'Aberto') {
      return false;
    }

    const today = new Date();
    const createdAt = new Date(data_movimentacao);
    const isTicketOlderThanFiveDays = dateSubtraction(today, createdAt) >= 5;

    if (isTicketOlderThanFiveDays) {
      return true;
    }

    return false;
  };

  return (
    <Div>
      <div>
        {editMode === true ? (
          <Checkbox
            className="checkbox"
            name={item.movimentacao_id}
            sx={{
              color: blue[500],
              '&.Mui-checked': {
                color: blue[600]
              }
            }}
            onChange={clickUniqueCheckboxHandler}
            checked={selectedCheckboxes.some((checkboxName) => {
              return Number(checkboxName) === item.movimentacao_id;
            })}
          />
        ) : (
          ''
        )}
      </div>
      <TypeMovement type={item.tipo_movimentacao} className="tipo-paragraph">
        {item.tipo_movimentacao === 'Inclusão' ? (
          <BiPlus />
        ) : (
          item.tipo_movimentacao === 'Exclusão' && <RiSubtractFill />
        )}
        <span>{item.tipo_movimentacao}</span>
      </TypeMovement>
      <p>{item.nome_beneficiario}</p>
      <p>{moment(item.data_movimentacao).format('DD/MM/YYYY')}</p>
      <p>{item.tipo}</p>
      <p>{item.estipulante}</p>
      {item.contratos && (
        <p className="flex-products">
          <span>
            {item.contratos?.find(
              (contrato) => contrato.nome_produto === 'Plano de Saúde'
            ) ? (
              <GiHealthNormal size={18} />
            ) : null}
          </span>
          <span>
            {item.contratos?.find(
              (contrato) => contrato.nome_produto === 'Plano Odontológico'
            ) ? (
              <FaTooth size={18} />
            ) : null}
          </span>
        </p>
      )}
      <p>{item.status_movimentacao}</p>
      {expand === `${item.movimentacao_id}&${item.nome_produto}` ? (
        <div>
          <p className="more-infos">
            <AiFillEyeInvisible
              onClick={() =>
                openDetails(item.movimentacao_id, item.nome_produto)
              }
            />
          </p>
          {isMovimentacaoOnFire(item) ? <FireIcon className="fire-icon" /> : ''}
        </div>
      ) : (
        <div>
          <p className="more-infos">
            <AiFillEye
              onClick={() =>
                openDetails(item.movimentacao_id, item.nome_produto)
              }
            />
          </p>
          {isMovimentacaoOnFire(item) ? <FireIcon className="fire-icon" /> : ''}
        </div>
      )}
    </Div>
  );
};

export default MovimentsResults;
