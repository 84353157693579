import styled from 'styled-components';

export const Wraper = styled.div` 
// width: 490px;
 

  fieldset{
    border: none;
    width: 100%;
  }
  input{ 
    font-family: 'Avenir Next GEO W05 Regular';
    :read-only{
    background-color: #ede2ff !important;
    }
  }

  .fichaProposta{
    .desc {
      text-align: left;
      font-size: 1rem;

      b{
        font-family: 'Avenir Next GEO W05 Demi';
      }
    }

    .link{
      font-family: 'Avenir Next GEO W05 Demi';
      text-align: left;
      padding: 20px 0;

      a{
        color: #62A0E2;
      }
    }

    .actions{
      display: flex;
    //  justify-content: center;
      gap: 40px;
      
      span{
        font-family: 'Avenir Next GEO W05 Demi';
        font-size: 1rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }
 

  .nomeTitular{
    text-align: center;
    margin-bottom: 1rem;
  }

  .fieldDepends{
    margin-bottom: 50px;
    border-bottom: 1px #ccc solid;
    padding-bottom: 50px;
   
    :last-of-type {
      margin-bottom: 0px;
      border-bottom: none;
      padding-bottom: 0px;
    }
  }

  legend{
    font-family: 'Avenir Next GEO W05 Demi';
    margin-bottom: 10px;
  }

  .descricao{
    font-size: 14px;
    margin-bottom: 5px;
  }

  .checkbox-group{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 0px 20px 0px;
    label.select-label{
      display: flex;
      font-size: 1rem;
      background-color: #fff;
      border: 0.5px solid #62A0E2;
      border-radius: 5px;
      width: 100%;
      height: 48px;
      color: #3B3838;
      //justify-content: center;
      //text-align: center;
      box-sizing: border-box;
      align-items: center;
      padding: 10px;
      cursor: pointer;
    }
    label.select-label.active{
      background: rgba(110,62,193,0.8);
      color: #FFF;
    }
  }

  .button-areas{
    display: flex;
    align-items: center;
   // justify-content: center;
    margin-top: 30px;
    margin-bottom: 40px;

    button{
      margin: 0px 30px;
      border: none;
      background: none;
      color: #3B3838;
      font-size: 1rem;
      font-family: 'Avenir Next GEO W05 Demi';
      cursor: pointer;
      display: flex;
      align-items: center;

      svg{
        margin-left: 5px;
      }

      :first-child{
        margin-left: 0px;
      }
    }
  }
  .add-depend{
    display: flex;
    align-items: center;
   // justify-content: center;
    margin-top: 30px;
    margin-bottom: 40px;

    button{
      margin: 0px 5px;
      border: none;
      background: none;
      color: #62A0E2;
      font-size: 1rem;
      font-family: 'Avenir Next GEO W05 Regular';
      cursor: pointer;
    }
  }
  .input-group{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 480px;
    >div{
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  .inline-group-input{
      display: grid !important;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;
    }

  .react-datepicker-wrapper {
    width: 100%;
  }

  span.errorsMsg {
    margin-top: 8px;
    color: #ff548b;
    margin-bottom: 2px;
    font-size: 13px;
  }
  input[type="text"], input[type="email"] {
    border-radius: 5px;
    width: 100%;
    padding: 12px 15px;
    background: #FFFFFF;
    border: 0.5px solid #62A0E2;
    //text-align: center;
    margin: 0px 0 10px 0;
    font-size: 1rem;
  }

 .select-group{
    display: flex;
    flex-direction: column;
  //  align-items: center;
    margin-bottom: 20px;
    margin-top: 15px;

    label{
      margin-bottom: 10px;
    }

    select{
      font-family: 'Avenir Next GEO W05 Regular';
      width: 100%;
      height: 48px;
      //text-align: center;
      font-size: 1rem;
      padding: 0px 10px;
      border: 0.5px solid #62A0E2;
      border-radius: 5px;
     //text-align-last: center;
    }
  }

  .input-date-group{
      display: flex;
      flex-direction: column;
      //align-items: center;
      margin-bottom: 20px;
      margin-top: 15px;
      label{
      margin-bottom: 10px;
    }
      label{
      margin-bottom: 10px;
    }
  }

  .input-files-group{
    display: flex;
    //align-items: center;
    justify-content: center;
    flex-direction: column;

    .label-file {
      font-size: 16px;
      background-color: #fff;
      border-radius: 5px;
      color: #fff;
      cursor: pointer;
      width: 100%;
      height: 48px;
      box-sizing: border-box;
      display: flex;
      padding: 8px 15px;
      border: 2px #d4bef9 solid;
      color: #62A0E2;
      font-family: 'Avenir Next GEO W05 Demi';
      margin-top: 10px;

      input{display: none;}
      span {
        display: flex;
        align-items: center;
        color: #915fe8;

        svg{
          margin:0px 8px;
          font-size: 1.3rem;
        }
      }
    } 
    .listFiles{
      width: 100%;
      .itemFile {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #e3d2ff;
          padding: 10px;
          border-radius: 5px;
          margin-top: 10px;
          font-family: 'Avenir Next GEO W05 Demi';
          span{
            color: #62A0E2;
            display: flex;
            align-items: center;

            svg{
              margin-right: 5px;
            }
          }
          button {
            display: flex;
            background: #9767e8;
            border: 1px #ccc solid;
            color: #fff;
            padding: 2px;
            border-radius: 50%;
            cursor: pointer;
          }
        }
    }
    
  }
 
  .text-desc{
    padding: 20px 0;
  }

  button.remove {
    font-family: 'Avenir Next GEO W05 Regular';
    display: flex;
    margin: auto;
    margin-bottom: 10px;
    background: none;
    border: none;
    font-size: 1rem;
    padding: 3px 6px;
    border-radius: 5px;
    color: #62A0E2;
    cursor: pointer;
}

div.dados-endereco{
  //width: 482px;
  display: grid;
  column-gap: 1rem;
 // grid-template-columns: 3fr 1fr;
  grid-template-areas: 
            "r n"
            "c comp"
            ;

  .rua{grid-area: r;}
  .numero{grid-area: n;}
  .cidade{grid-area: c;}          
  .complemento{grid-area: comp;}     
  
  input[type="text"]{
    //width: auto;
  }     
}

.erroPlanos{
  text-align: center;
  color: #f34656;
}


@media (max-width: 1024px) {
  width: 300px;

  .input-group{
    width: 300px;
  }
}

@media (max-width: 900px){
    width: 100%;
  
}

`