import { Dialog } from '@material-ui/core';
import { Content } from './styles';

const DialogConfirmation = ({
  open,
  onClose,
  question,
  confirm,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Content>
        <div className="title">
          <h3>{question}</h3>
        </div>
        <div className="content"></div>
        <div className="actions">
          <div className="buttons">
            <button className="btn btn-confirm" onClick={confirm}>
              Confirmar
            </button>
            <button className="btn btn-canlcel" onClick={onClose}>
              Cancelar
            </button>
          </div>
        </div>
      </Content>
    </Dialog>
  );
};

export default DialogConfirmation;
