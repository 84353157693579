import Button from 'apps/hr/components/Form/Button';
import Input from 'apps/hr/components/Form/Input';
import { useFormContext, useFieldArray } from 'react-hook-form';
import './style.scss';

const PersonalDataForm = () => {
  const { control, formState, clearErrors } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'beneficiarios'
  });

  // console.log(fields);

  const handleDeleteForm = (indexNumber) => {
    remove(indexNumber);

    clearErrors([
      `beneficiarios[${indexNumber}].nome`,
      `beneficiarios[${indexNumber}].cpf`,
      `beneficiarios[${indexNumber}].email`
    ]);
  };

  return (
    <div className="personal-data-forms">
      <div>
        <span>Adicione os titulares do(s) plano(s)</span>
      </div>
      {fields?.map((field, index) => (
        <div
          className={`${
            index > 0
              ? 'personal-data-form personal-data-form--plus'
              : 'personal-data-form personal-data-form--principal'
          }`}
          key={field.id}
        >
          {index > 0 && (
            <Button
              className="personal-data-form__button-remove"
              variant="remove"
              onClick={() => handleDeleteForm(index)}
            >
              x
            </Button>
          )}
          <div
            className={`${
              index > 0
                ? 'personal-data-form__inputs'
                : 'personal-data-form__inputs-principal'
            }`}
          >
            <div>
              <Input
                label="Nome Completo"
                validation="name"
                isRequired
                name={`beneficiarios[${index}].nome`}
                classNameLabel="personal-data-form__input-label"
                classNameInput="personal-data-form__input"
              />
              <span className="personal-data-form__span-error">
                {formState.errors?.beneficiarios?.[index]?.nome &&
                  formState.errors.beneficiarios[index].nome.message}
              </span>
            </div>
            <div>
              <Input
                label="CPF"
                isRequired
                mask="cpf"
                validation="cpf"
                name={`beneficiarios[${index}].cpf`}
                classNameLabel="personal-data-form__input-label"
                classNameInput="personal-data-form__input"
              />
              <span className="personal-data-form__span-error">
                {formState.errors?.beneficiarios?.[index]?.cpf &&
                  formState.errors.beneficiarios[index].cpf.message}
              </span>
            </div>
          </div>
          <div>
            <div>
              <Input
                label="E-mail"
                isRequired
                validation="email"
                name={`beneficiarios[${index}].email`}
                classNameLabel="personal-data-form__input-label"
                classNameInput="personal-data-form__input"
              />
              <span className="personal-data-form__span-error">
                {formState.errors?.beneficiarios?.[index]?.email &&
                  formState.errors.beneficiarios[index].email.message}
              </span>
            </div>
          </div>
        </div>
      ))}

      <div>
        <Button
          type="button"
          variant="hr"
          onClick={() => {
            append({});
          }}
        >
          + Adicionar Beneficiario
        </Button>
      </div>
    </div>
  );
};

export default PersonalDataForm;
