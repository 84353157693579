import styled from 'styled-components';
import colors from 'apps/broker/styles/colors';

export const Wrapper = styled.div`
  background-color: ${colors.genoaBackgroundGrey};
  width: 100vw;
  height: 100vh;
`;

export const LogoRow = styled.div`
  padding: 5.24vh 8.11vw;
  padding-bottom: 3.84vh;

  @media (max-height: 700px) {
    padding: 5.24vh 8.11vw;
    padding-bottom: 3.84vh;
    
    svg {
      width: 90px;
      height: 91px;
    }
  }

  @media (max-height: 600px) {
    padding: 5.24vh 8.11vw;
    padding-bottom: 3.84vh;
    
    svg {
      width: 80px;
      height: 80.5px;
    }
  }

  @media (max-height: 500px) {
    padding: 5.24vh 8.11vw;
    padding-bottom: 3.84vh;

    svg {
      width: 70px;
      height: 70.5px;
    }
  }
`;

export const SecondRow = styled.div`
  padding-left: 22.94vw;
  width: calc(100% - 22.94vw);
`;

export const LoginGrid = styled.div`
  display: grid;
  grid-template-columns: 27.41vw 1fr;

  .worker-img {
    padding-left: 10.7vw;
    padding-top: 106px;

    @media (max-height: 700px) {
        padding-top: 95.4px;
        width: 370px;
        height: 280px;
    }

    @media (max-height: 600px) {
        padding-top: 84.8px;
        width: 326.4px;
        height: 248px;
    }

    @media (max-height: 500px) {
        padding-top: 70.70px;
        width: 272px;
        height: 206.67px;
    }
  }
`;

export const LoginInputColumn = styled.div`
  .title {
    display: flex;
    column-gap: 13px;
    align-items: end;
    color: ${colors.genoaBlue};
    font-size: 40px;
    font-family: 'Avenir Next GEO W05 Regular';
    white-space: nowrap;

    @media (max-height: 700px) {
      font-size: 36px;
      column-gap: 11.7px;
    }

    @media (max-height: 600px) {
      font-size: 32px;
      column-gap: 10.4px;
    }

    @media (max-height: 500px) {
      font-size: 26.68px;
      column-gap: 8.671px;
    }

    .heavy-title {
      font-family: 'Avenir Next GEO W05 Heavy';
    }
  }

  .subtitle {
    font-size: 20px;
    padding-left: 4px;
    width: 35vw;
    font-family: 'Avenir Next GEO W05 Regular';
    margin-top: 30px;

    @media (max-height: 700px) {
      font-size: 18px;
      padding-left: 3.6px;
      margin-top: 27px;
    }

    @media (max-height: 600px) {
      font-size: 16px;
      padding-left: 3.2px;
      margin-top: 24px;
    }

    @media (max-height: 500px) {
      font-size: 13.34px;
      padding-left: 2.668px;
      margin-top: 20px;
    }

    color: ${colors.genoaDarkBlue};
    // white-space: nowrap;
  }

  .login-input-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    row-gap: 20px;

    @media (max-height: 700px) {
      margin-top: 36px;
      row-gap: 18px;
    }

    @media (max-height: 600px) {
      margin-top: 32px;
      row-gap: 16px;
    }

    @media (max-height: 500px) {
      margin-top: 26.68px;
      row-gap: 13.34px;
    }

    .login-input-container {
      display: flex;
      flex-direction: column;
      font-family: 'Avenir Next GEO W05 Regular';
      row-gap: 15px;
      font-size: 16px;

      @media (max-height: 700px) {
        row-gap: 13.5px;
        font-size: 14.4px;
      }
  
      @media (max-height: 600px) {
        row-gap: 12px;
        font-size: 12.8px;
      }
  
      @media (max-height: 500px) {
        row-gap: 10px;
        font-size: 10.672px;
      }

      label {
        color: ${colors.genoaDarkBlue};
        font-family: 'Avenir Next GEO W05 Regular';
        font-size: 16px;
        padding-left: 28px;

        @media (max-height: 700px) {
          font-size: 14.4px;
          padding-left: 25.2px;
        }
    
        @media (max-height: 600px) {
          font-size: 12.8px;
          padding-left: 22.4px;
        }
    
        @media (max-height: 500px) {
          font-size: 10.672px;
          padding-left: 18.592px;
        }
      }

      input {
        border: 3px ${colors.genoaBlue} solid;
        font-family: 'Avenir Next GEO W05 Regular';
        color: ${colors.genoaGrey};
        border: 3px ${colors.genoaBlue} solid;
        border-radius: 16px;
        height: 43.2px;
        padding: 0 20px;
        font-size: 16px;

        @media (max-height: 700px) {
          border: 2.7px ${colors.genoaBlue} solid;
          border-radius: 14.4px;
          height: 38.882px;
          padding: 0 18px;
          font-size: 14.4px;
        }
    
        @media (max-height: 600px) {
          border: 2.4px ${colors.genoaBlue} solid;
          border-radius: 12.8px;
          height: 34.56px;
          padding: 0 16px;
          font-size: 12.8px;
        }
    
        @media (max-height: 500px) {
          border: 2px ${colors.genoaBlue} solid;
          border-radius: 10.672px;
          height: 28.82px;
          padding: 0 13.34px;
          font-size: 10.672px;
        }

        ::placeholder {
          font-family: 'Avenir Next GEO W05 Regular';
          font-size: 16px;

          @media (max-height: 700px) {
            font-size: 14.4px;
          }
      
          @media (max-height: 600px) {
            font-size: 12.8px;
          }
      
          @media (max-height: 500px) {
            font-size: 10.672px;
          }
        }

      }

      input:-webkit-autofill {
        font-family: 'Avenir Next GEO W05 Regular';
        font-size: 16px;
        
        @media (max-height: 700px) {
          font-size: 14.4px;
        }
    
        @media (max-height: 600px) {
          font-size: 12.8px;
        }
    
        @media (max-height: 500px) {
          font-size: 10.672px;
        }
      }
    }
  }

  .MuiButton-root:hover {
    background-color: ${colors.genoaDarkBlue};
  }

  .login-button {
    background-color: ${colors.genoaDarkBlue};
    color: ${colors.white};
    display: flex;
    align-items: center;
    transition: opacity 200ms ease-in-out;
    opacity: 1;
    justify-content: center;
    width: 100%;
    margin-top: 40px;
    height: 53.6px;
    border-radius: 20px;

    @media (max-height: 700px) {
      margin-top: 36px;
      height: 48.24px;
      border-radius: 18px;
    }

    @media (max-height: 600px) {
      margin-top: 32px;
      height: 42.88px;
      border-radius: 16px;
    }

    @media (max-height: 500px) {
      margin-top: 26.68px;
      height: 35.75px;
      border-radius: 13.34px;
    }

    span {
      font-family: 'Avenir Next GEO W05 Demi';

      @media (max-height: 700px) {
        font-size: 12.6px !important;
      }
  
      @media (max-height: 600px) {
        font-size: 11.2px !important;
      }
  
      @media (max-height: 500px) {
        font-size: 9.338px !important;
      }
    }

    :hover {
      cursor: pointer;
      opacity: 0.92;
    }
  }

  .reset-password-button {
    color: ${colors.genoaDarkBlue};
    font-family: 'Avenir Next GEO W05 Demi';
    margin-top: 30px;

    @media (max-height: 700px) {
      font-size: 14.4px;
      margin-top: 27px;
    }

    @media (max-height: 600px) {
      font-size: 12.8px;
      margin-top: 26px;
    }

    @media (max-height: 500px) {
      font-size: 10.672px;
      margin-top: 20px;
    }

    :hover {
      cursor: pointer;
    }
  }

  .erro-msg{
    color: ${colors.genoaPink};
    font-family: 'Avenir Next GEO W05 Bold';
    margin-top: 35px;
    font-size: 14px;
    text-align: center;

    @media (max-height: 700px) {
      margin-top: 31.5px;
      font-size: 12.6px;
    }

    @media (max-height: 600px) {
      padding-left: 22.4px;
      margin-top: 28px;
      font-size: 11.2px;
    }

    @media (max-height: 500px) {
      margin-top: 23.345px;
      font-size: 9.338px;
    }
  }

  .sucesso-msg {
    color: ${colors.rhBlueStrong};
    font-family: 'Avenir Next GEO W05 Bold';
    margin-top: 35px;
    font-size: 14px;
    text-align: center;

    @media (max-height: 700px) {
      margin-top: 31.5px;
      font-size: 12.6px;
    }

    @media (max-height: 600px) {
      margin-top: 28px;
      font-size: 11.2px;
    }

    @media (max-height: 500px) {
      margin-top: 23.345px;
      font-size: 9.338px;
    }
  }
`;
