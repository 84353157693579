import styled from 'styled-components';
import colors from 'apps/broker/styles/colors';

export const Wrapper = styled.div`
  width: 950px;
  box-sizing: content-box;
  min-height: 101vh;

  .circular-spinner-container-detalhes {
    margin-top: 15vh;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    

    .circular-spinner-detalhes{
      color: ${colors.genoaBlue};
      margin-bottom: 30px;
    }
    
    span {
      font-size: 1.2rem;
    }
  }

`;
