import './styles.scss';

const Checkbox = (props) => {
  const {
    label,
    checked,
    onChange,
    variant = 'broker',
    size = 'large',
    fontSize = 'large',
    ...rest
  } = props;

  return (
    <div className="ds-checkbox">
      <input
        type="checkbox"
        id={props.id}
        checked={checked}
        onChange={onChange}
        className={`ds-checkbox__input`}
        {...rest}
      />
      <div
        className={`ds-checkbox__custom ds-checkbox__custom--${size} ${
          checked ? `ds-checkbox__custom--checked-${variant}` : ''
        }`}
        onClick={onChange}
      ></div>
      <div>
        <label
          htmlFor={props.id}
          className={`ds-checkbox__label ds-checkbox__label--${fontSize}`}
        >
          {label}
        </label>
      </div>
    </div>
  );
};

export default Checkbox;
