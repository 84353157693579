import { useState, useMemo, useEffect } from 'react';
import { TableAtividadesWrapper } from './styles';
import DrawerNegocios from '../../../Business/components/BusinessDetails';
import CardAtividade from '../Cards';
import { SwipeableDrawer } from '@mui/material';
import { Toaster, toast } from 'react-hot-toast';
import colors from 'styles/colors';

const TabelaAtividades = ({
  negociosInfo,
  getNegociosData,
  isNewActivity,
  setNewActivity,
  corretores,
  setNewAtividadeNegocio,
  vencimento,
  responsavel,
  dataInicial,
  dataFinal,
  resetActivitiesFilters
}) => {
  const [atividades, setAtividades] = useState([]);
  const [atividadesFilter, setAtividadesFilter] = useState([]);
  const [negocioSelected, setNegocioSelected] = useState(undefined);
  const [isSelected, setSelected] = useState(undefined);
  const [editAtividade, setEditAtividade] = useState(0);

  const formatDate = (date) => {
    const newDate = date.slice(0, 10).split('-');

    return `${newDate[2]}/${newDate[1]}/${newDate[0]}`;
  };

  const iconAtividadeHandler = (item) => {
    const timeStampInicial = new Date().setHours(0, 0, 0, 0);

    const timeStampFinal = new Date().setHours(23, 59, 0, 0);

    const dataAgendada = new Date(item.data_agendada).getTime();

    if (dataAgendada >= timeStampInicial && dataAgendada < timeStampFinal) {
      return 'green';
    }
    if (dataAgendada < timeStampInicial) {
      return 'red';
    }
    if (dataAgendada > timeStampFinal) {
      return 'grey';
    }
  };

  const FilterAtividadesPorVencimento = (atividades) => {
    const timeStampInicial = new Date().setHours(0, 0, 0, 0);

    const timeStampFinal = new Date().setHours(23, 59, 0, 0);

    const timeStampMais1Dia =
      new Date(timeStampInicial).getTime() + 1 * 24 * 60 * 60 * 1000;

    const dataPeriodoInicial = new Date(dataInicial).getTime();

    const dataPeriodoFinal = new Date(dataFinal).getTime();

    if (vencimento === 'vencidas') {
      return atividades?.filter(
        (item) => new Date(item.data_agendada).getTime() < timeStampInicial
      );
    }
    if (vencimento === 'hoje') {
      return atividades?.filter(
        (item) =>
          new Date(item.data_agendada).getTime() >= timeStampInicial &&
          new Date(item.data_agendada).getTime() < timeStampFinal
      );
    }
    if (vencimento === 'amanha') {
      return atividades?.filter(
        (item) => new Date(item.data_agendada).getTime() === timeStampMais1Dia
      );
    }
    if (vencimento === 'periodo') {
      return atividades?.filter(
        (item) =>
          new Date(item.data_agendada).getTime() >= dataPeriodoInicial &&
          new Date(item.data_agendada).getTime() <= dataPeriodoFinal
      );
    }
  };

  const filterAtividadesPorResponsavel = (atividades) => {
    const responsavelId = Number(responsavel);

    const validId = Number.isInteger(responsavelId);
    return atividades?.filter((item) => {
      if (validId) {
        return item.usuario_id === Number(responsavel);
      } else {
        return item;
      }
    });
  };

  useMemo(() => {
    if (Boolean(isSelected)) {
      const newSelected = negociosInfo?.find(
        (item) => isSelected?.id === item.id
      );

      if (newSelected) {
        setSelected(newSelected);
      }
    }

    // Esta condição abrirá um drawer de nova atividade
    if (Boolean(isNewActivity)) {
      const newActivity = negociosInfo?.find(
        (item) => isNewActivity?.id === item.id
      );
      
      if (newActivity) {
        setNewActivity(newActivity);
      }
    }
  }, [negociosInfo]);

  useMemo(() => {
    if (negocioSelected) {
      const selected = negociosInfo?.find(
        (item) => item.id === negocioSelected
      );
      setSelected(selected);
    } else {
      setSelected(undefined);
    }
  }, [negocioSelected]);

  useEffect(() => {
    toast.dismiss();
  }, []);

  useEffect(() => {
    const filtroVencimento = FilterAtividadesPorVencimento(atividades);
    const filtroResponsavel = filterAtividadesPorResponsavel(filtroVencimento);

    const orderAtividades = filtroResponsavel?.sort((a, b) => {
      if (a.data_agendada > b.data_agendada) {
        return 1;
      }

      return -1;
    });

    setAtividadesFilter(orderAtividades);

    resetActivitiesFilters();
  }, [atividades, vencimento, responsavel, dataInicial, dataFinal]);

  useEffect(() => {
    const atividadesTotal = negociosInfo.reduce((acc, curr) => {
      const negocioAtividades = curr?.atividades.map((item) => ({
        ...item,
        estipulante_razao_social: (curr.estipulante_razao_social ??=
          curr.lead_razao_social),
        produto: curr.produto,
        vidas: curr.vidas,
        estagio_id: Number(curr.estagio_id),
        estagio: curr.estagio
      }));

      return [...acc, ...negocioAtividades];
    }, []);

    setAtividades(atividadesTotal);
  }, [negociosInfo]);

  return (
    <TableAtividadesWrapper>
      <thead>
        <tr>
          <th>Conc</th>
          <th>Atividade</th>
          <th>Venc</th>
          <th>Estipulante/ Nome fantasia</th>
          <th>Produto</th>
          <th>Vidas</th>
          <th>Estágio</th>
          <th>Resp</th>
          <th>Atividade</th>
          <div></div>
        </tr>
      </thead>
      <tbody>
        {atividadesFilter?.map((item) => {
          if (!item.data_fechamento) {
            return (
              <CardAtividade
                key={item.atividade_id}
                atividadeInfo={item}
                negociosInfo={negociosInfo}
                setNegocioSelected={setNegocioSelected}
                setNewAtividadeNegocio={setNewAtividadeNegocio}
                getNegociosData={getNegociosData}
                corretores={corretores}
                setEditAtividade={setEditAtividade}
                editAtividade={editAtividade}
              />
            );
          }
          return '';
        })}
      </tbody>
      <SwipeableDrawer
        open={Boolean(isSelected)}
        onClose={() => setNegocioSelected(undefined)}
        anchor="right"
      >
        <DrawerNegocios
          formatDate={formatDate}
          isSelected={isSelected}
          getNegociosData={getNegociosData}
          iconAtividadeHandler={iconAtividadeHandler}
          corretores={corretores}
        />
      </SwipeableDrawer>
      <Toaster
        position={'bottom-center'}
        toastOptions={{
          style: {
            background: colors.genoaBlue,
            padding: '0 12px',
            color: '#fff',
            maxWidth: '450px',
            height: '60px',
            marginTop: '0.6em'
          }
        }}
      />
    </TableAtividadesWrapper>
  );
};

export default TabelaAtividades;
