import './style.scss';

const TableHeader = () => {
  return (
    <div className="manager-request-table-header">
      <div></div>
      <div>
        <span className="manager-request-table-header__span">
          Nome
        </span>
      </div>
      <div>
        <span className="manager-request-table-header__span">
          Produto
        </span>
      </div>
      <div>
        <span className="manager-request-table-header__span">
          Status
        </span>
      </div>
      <div>
        <span className="manager-request-table-header__span">
          Revisar
        </span>
      </div>
      <div></div>
    </div>
  );
}

export default TableHeader;
