import React from 'react';
import { Container, ContentTasksWrapper } from './styles';
import FilterTasks from '../Filters';
import TabelaTasks from '../TableResult';
import DrawerNovaTask from '../CreateNewTask';

const ContentTasks = ({
  getTasksData,
  setOpenDrawer,
  openDrawer,
  corretores,
  estipulantes,
  setSelectEstipulante,
  selectEstipulante,
  leads,
  setSelectLead,
  selectLead,
  estipulanteContratos,
  tasksInfo,
  setVencimento,
  vencimento,
  setDataInicial,
  dataInicial,
  setDataFinal,
  dataFinal,
  setStatus,
  status,
  setResponsavel,
  responsavel,
  resetTasksFilters
}) => {
  return (
    <ContentTasksWrapper>
      <Container>
        <div className="title_content">
          <h1>Tasks</h1>
        </div>
        <div className="btn">
          <button className="btn_new_task" onClick={() => setOpenDrawer(true)}>
            + Tasks
          </button>
        </div>
      </Container>
      <FilterTasks
        corretores={corretores}
        setVencimento={setVencimento}
        vencimento={vencimento}
        setResponsavel={setResponsavel}
        responsavel={responsavel}
        setDataInicial={setDataInicial}
        dataInicial={dataInicial}
        setDataFinal={setDataFinal}
        dataFinal={dataFinal}
        setStatus={setStatus}
        status={status}
        resetTasksFilters={resetTasksFilters}
      />
      <TabelaTasks
        corretores={corretores}
        getTasksData={getTasksData}
        tasksInfo={tasksInfo}
        vencimento={vencimento}
        responsavel={responsavel}
        dataInicial={dataInicial}
        dataFinal={dataFinal}
        status={status}
        resetTasksFilters={resetTasksFilters}
      />
      {openDrawer && (
        <DrawerNovaTask
          setOpenDrawer={setOpenDrawer}
          corretores={corretores}
          estipulantes={estipulantes}
          leads={leads}
          setSelectEstipulante={setSelectEstipulante}
          selectEstipulante={selectEstipulante}
          setSelectLead={setSelectLead}
          selectLead={selectLead}
          estipulanteContratos={estipulanteContratos}
          getTasksData={getTasksData}
        />
      )}
    </ContentTasksWrapper>
  );
};

export default ContentTasks;
