import { keysAbaDadosBasicos, keysAbaDadosImc, keysAbaScanDocumentos } from "./camposInclusaoPorAbaAdicionais";

const determinaPlanoSelecionado = ({ beneficiarioAdicional }) => {
  if (!beneficiarioAdicional) {
    return true
  }
  
  const {
    incluirSaude,
    incluirOdonto,
    tipo_plano_saude,
    tipo_plano_odonto
  } = beneficiarioAdicional;

  if (incluirSaude && !tipo_plano_saude) {
    return false
  }

  if (incluirOdonto && !tipo_plano_odonto) {
    return false
  }

  if (!incluirSaude && !incluirOdonto) {
    return false
  }

  return true
}

const determinaDadosBasicosCompleto = ({
  beneficiarioAdicional,
  camposInclusao
}) => {
  const abaDisponivel = keysAbaDadosBasicos.some((item) => camposInclusao && camposInclusao[item]);
  // console.log(abaDisponivel)

  if (!abaDisponivel) {
    return true
  }

  const response = keysAbaDadosBasicos.every((item) => {
    // console.log("DADOS BASICOS", camposInclusao, beneficiarioAdicional[item], item)
    if (beneficiarioAdicional[item]) {
      return true;
    }

    if (!camposInclusao[item]) {
      if (item === 'vinculo' || item === 'dataVinculo') {
        return false
      }
      return true 
    }
    return false;
  })

  return response
}

const determinaImcCompleto = ({
  beneficiarioAdicional,
  camposInclusao
}) => {
  const abaDisponivel = keysAbaDadosImc.some((item) => camposInclusao && camposInclusao[item]);
  
  if (!abaDisponivel) {
    return true
  }

  const response = keysAbaDadosImc.every((item) => {
    // console.log("DADOS BASICOS", beneficiarioAdicional[item], item, camposInclusao)
    if (beneficiarioAdicional[item]) {
      return true;
    }
    return false;
  })

  return response
}

const determinaScanCompleto = ({
  beneficiarioAdicional,
  camposInclusao
}) => {
  const abaDisponivel = keysAbaScanDocumentos.some((item) => camposInclusao && camposInclusao[item]);

  if(!abaDisponivel) {
    return true
  }

  const response = [
    "documento_scan_rg",
    "documento_comprovacao_vinculo",
    "documento_scan_cpf"
  ].every((item) => {
    if (item === "documento_comprovacao_vinculo") {
      return beneficiarioAdicional[item]
    }
    return true;
  })

  return response;
}

const determinaAbasCompletasAdicionais = ({
  camposInclusao,
  beneficiarioAdicional,
  beneficiarioPage,
  setAbasCompletasBeneficiariosAdicionais,
}) => {
  // console.log("ABAS COMPLETAS", camposInclusao, beneficiarioAdicional)

  const planoSelecionado = determinaPlanoSelecionado({ beneficiarioAdicional, camposInclusao });
  const dadosBasicos = determinaDadosBasicosCompleto({ beneficiarioAdicional, camposInclusao });
  const imc = determinaImcCompleto({ beneficiarioAdicional, camposInclusao })
  const scanDocumentos = determinaScanCompleto({ beneficiarioAdicional, camposInclusao });

  const abasCompletas = {
    planoSelecionado,
    dadosBasicos,
    imc,
    scanDocumentos
  }

  return abasCompletas

  // setAbasCompletasBeneficiariosAdicionais((prevState) => {
  //   return {
  //     ...prevState,
  //     [beneficiarioPage]: abasCompletas,
  //   }
  // })
}

export default determinaAbasCompletasAdicionais;