import styled from 'styled-components';

export const Container = styled.div `

  margin-top: -75px;
  display: flex;
  justify-content: center;

  .container{
    width: 80%;
    height: 110px;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 20px 40px;
    display: flex;
    align-items: center;
  }

  ul.menu{
    flex: 1;
    list-style: none;
    display: flex;
    justify-content: space-between;


    a{
      display: flex;
      flex-direction: column;
      align-items: center;
      text-decoration: none;
      :hover > svg{
        color: #6DA2EB;
        transform: scale(1.2)
      }

      svg{
        font-size: 1.5rem;
        color: #7C7C7C;
        transition: 300ms;
      }
      span{
        margin-top: 15px;
      }
    }
    .active > svg{
        color: #6DA2EB;
        transform: scale(1.2)
      }

  }

  @media (max-width: 720px) {

    .container {
      height: 90px;
    }

    ul.menu {

      a {
        svg {
          font-size: 1.25rem;
        }
        span {
          font-size: 0.75rem;
        }
      }
    }
  }


`