import { useState, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import moment from 'moment';
import { BsSlash } from 'react-icons/bs';
import { RiFilter3Fill } from 'react-icons/ri';
import AsideMenuConcierge from './AsideMenu';
import DateFilterPopper from './components/DateFilter';
import RelatorioContent from './RelatorioContent';
import { RelatorioConciergeWrapper } from './styles-RelatorioConcierge';
import monthsObject from 'utils/objects/optionsMonth';
import services from 'apps/broker/services';

const RelatorioConcierge = ({ setRelatorioToDisplay }) => {
  const [loading, setLoading] = useState(false);

  // Valores referentes ao comportamento da página
  const [relatorioSelecionado, setRelatorioSelecionado] = useState(
    'atendimentos criados'
  );
  const [openFilter, setOpenFilter] = useState(false);

  // Arrays de valores retornados da requisição do Backend
  const [data, setData] = useState(undefined);
  const [servicesCountByMonths, setServicesCountByMonths] = useState([]);
  const [servicesCountOfNewUsersByMonths, setServicesCountOfNewUsersByMonths] =
    useState([]);
  const [servicesByPeriod, setServicesByPeriod] = useState([]);
  const [averageFirstResponseTime, setAverageFirstResponseTime] = useState([]);
  const [averageConclusionTime, setAverageConclusionTime] = useState([]);
  const [usersByPeriod, setUsersByPeriod] = useState([]);
  const [newUsersByPeriod, setNewUsersByPeriod] = useState([]);
  const [evaluationsByPeriod, setEvaluationsByPeriod] = useState([]);

  // Valores únicos para renderização na tela
  const [servicesOpenedCount, setServicesOpenedCount] = useState(0);
  const [servicesClosedCount, setServicesClosedCount] = useState(0);
  const [firstResponseTime, setFirstResponseTime] = useState(0);
  const [conclusionTime, setConclusionTime] = useState(0);
  const [usersCount, setUsersCount] = useState(0);
  const [newUsersCount, setNewUsersCount] = useState(0);
  const [netPromoterScore, setNetPromoterScore] = useState(0);

  // Valores para estipulante selecionado ao clicar em alguma tabela
  const [estipulanteSelected, setEstipulanteSelected] = useState(undefined);
  const [servicesOpenedCountEstipulante, setServicesOpenedEstipulante] =
    useState(undefined);
  const [servicesClosedCountEstipulante, setServicesClosedEstipulante] =
    useState(undefined);
  const [firstResponseTimeEstipulante, setFirstResponseTimeEstipulante] =
    useState(undefined);
  const [conclusionTimeEstipulante, setConclusionTimeEstipulante] =
    useState(undefined);
  const [usersCountEstipulante, setUsersCountEstipulante] = useState(undefined);
  const [newUsersCountEstipulante, setNewUsersCountEstipulante] =
    useState(undefined);
  const [netPromoterScoreEstipulante, setNetPromoterScoreEstipulante] =
    useState(undefined);

  // Valores de filtragem de data
  const [startDate, setStartDate] = useState(undefined);
  const [finalDate, setFinalDate] = useState(undefined);
  const [lastFilterDates, setLastFilterDates] = useState({
    initialDate: undefined,
    finalDate: undefined
  });

  const getServicesCountEstipulanteFiltrado = (array) => {
    const count = array?.reduce(
      (acc, currentValue) => {
        const { count_abertos, count_finalizados } = currentValue;

        acc.abertos += Number(count_abertos);
        acc.finalizados += Number(count_finalizados);

        return acc;
      },
      {
        abertos: 0,
        finalizados: 0
      }
    );

    setServicesOpenedEstipulante(count.abertos);
    setServicesClosedEstipulante(count.finalizados);
  };

  const getServicesCount = (array) => {
    const count = array?.reduce(
      (acc, currentValue) => {
        const { count_abertos, count_finalizados } = currentValue;

        acc.abertos += Number(count_abertos);
        acc.finalizados += Number(count_finalizados);

        return acc;
      },
      {
        abertos: 0,
        finalizados: 0
      }
    );

    setServicesOpenedCount(count.abertos);
    setServicesClosedCount(count.finalizados);
  };

  const getAverageTime = (array) => {
    if (array?.length) {
      const average = array?.reduce(
        (acc, currentValue, index) => {
          const { average_time } = currentValue;

          acc.count += 1;
          acc.total_time += average_time ?? 0;

          // Se for último index retornar valor formatado
          if (index === array.length - 1) {
            return acc.total_time / acc.count;
          }

          return acc;
        },
        {
          count: 0,
          total_time: 0
        }
      );

      return Math.round(average);
    }

    return 0;
  };

  const getUsersCount = (array) => {
    const count = array?.reduce((acc, currentValue) => {
      const { count_usuarios } = currentValue;
      acc += count_usuarios;

      return acc;
    }, 0);

    return count;
  };

  const getNetPromoterScore = (array) => {
    const promotersDetractorsCount = array?.reduce(
      (acc, currentValue, index) => {
        const { notas_atendimento } = currentValue;

        notas_atendimento.forEach((item) => {
          if (item > 8) {
            acc.count_total += 1;
            acc.count_promoters += 1;
            return;
          }
          if (item < 7) {
            acc.count_total += 1;
            acc.count_detractors += 1;
            return;
          }

          acc.count_total += 1;
          return acc;
        });

        return acc;
      },
      {
        count_promoters: 0,
        count_detractors: 0,
        count_total: 0
      }
    );

    const promotersCount = promotersDetractorsCount.count_promoters;

    const detractorsCount = promotersDetractorsCount.count_detractors;

    const totalCount = promotersDetractorsCount.count_total;

    const nps = ((promotersCount - detractorsCount) / totalCount) * 100;

    return Math.round(nps);
  };

  // console.log({
  //   servicesOpenedCount,
  //   servicesClosedCount,
  //   firstResponseTime,
  //   conclusionTime,
  //   usersCount,
  //   newUsersCount
  // })

  const formatTimeValue = (valueInMinutes) => {
    const hours = Math.trunc(valueInMinutes / 60);
    const minutes = Math.round(valueInMinutes % 60);

    return {
      hours,
      minutes
    };
  };

  const setDisplayValues = (data) => {
    const {
      atendimentosEstipulantePeriodo,
      tempoMedioPrimeiraResposta,
      tempoMedioConclusao,
      usuariosPorEmpresaPeriodo,
      novosUsuariosPorEmpresaPeriodo,
      notasAtendimentoPeriodo
    } = data;

    getServicesCount(atendimentosEstipulantePeriodo);

    setFirstResponseTime(getAverageTime(tempoMedioPrimeiraResposta));

    setConclusionTime(getAverageTime(tempoMedioConclusao));

    setUsersCount(getUsersCount(usuariosPorEmpresaPeriodo));

    setNewUsersCount(getUsersCount(novosUsuariosPorEmpresaPeriodo));

    setNetPromoterScore(getNetPromoterScore(notasAtendimentoPeriodo));
  };

  const getEstipulantesNps = (array) => {
    array.map((item) => {
      const { notas_atendimento } = item;

      const npsValues = notas_atendimento?.reduce(
        (acc, currentValue, index) => {
          if (Number(currentValue) > 8) {
            acc.count_total += 1;
            acc.count_promotors += 1;
            return acc;
          }
          if (Number(currentValue) < 7) {
            acc.count_total += 1;
            acc.count_detractors += 1;
            return acc;
          }
          acc.count_total += 1;
          return acc;
        },
        {
          count_promotors: 0,
          count_detractors: 0,
          count_total: 0
        }
      );

      const promotors = npsValues.count_promotors;
      const detractors = npsValues.count_detractors;
      const total = npsValues.count_total;

      item.nps = ((promotors - detractors) / total) * 100;

      return item;
    });

    return array;
  };

  const setTableValues = (data) => {
    const {
      atendimentosCriadosCronologia,
      atendimentosCriadosPorUsuariosNovosCronologia,
      atendimentosEstipulantePeriodo,
      tempoMedioPrimeiraResposta,
      tempoMedioConclusao,
      usuariosPorEmpresaPeriodo,
      novosUsuariosPorEmpresaPeriodo,
      notasAtendimentoPeriodo
    } = data;

    setServicesCountByMonths(atendimentosCriadosCronologia);
    setServicesCountOfNewUsersByMonths(
      atendimentosCriadosPorUsuariosNovosCronologia
    );
    setServicesByPeriod(atendimentosEstipulantePeriodo);
    setAverageFirstResponseTime(tempoMedioPrimeiraResposta);
    setAverageConclusionTime(tempoMedioConclusao);
    setUsersByPeriod(usuariosPorEmpresaPeriodo);
    setNewUsersByPeriod(novosUsuariosPorEmpresaPeriodo);
    setEvaluationsByPeriod(getEstipulantesNps(notasAtendimentoPeriodo));
  };

  const reqRelatorioConcierge = async (filterDates) => {
    const body = {
      filterDates
    };

    setLastFilterDates(filterDates);

    const response = await services.reports.getConciergeByFilterDate(body);

    if (response.status === 200) {
      setEstipulanteSelected(undefined);
      setData(response.data);
      setDisplayValues(response.data);
      setTableValues(response.data);
    }
  };

  const getRelatorioConcierge = async () => {
    const filterDates = {
      initialDate: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
      finalDate: moment(finalDate).format('YYYY-MM-DD HH:mm:ss')
    };

    await reqRelatorioConcierge(filterDates);
  };

  const filterIconClickHandler = (e) => {
    setOpenFilter((prevState) => !prevState);
  };

  const renderFilterPopper = () => {
    return (
      <DateFilterPopper
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        startDate={startDate}
        finalDate={finalDate}
        setStartDate={setStartDate}
        setFinalDate={setFinalDate}
        getRelatorioConcierge={getRelatorioConcierge}
      />
    );
  };

  const renderInfoPeriodoFiltrado = () => {
    if (lastFilterDates?.initialDate && lastFilterDates?.finalDate) {
      const yearStart = lastFilterDates?.initialDate?.slice(0, 4);
      const monthStart = lastFilterDates?.initialDate?.slice(5, 7);
      const dayStart = lastFilterDates?.initialDate?.slice(8, 10);

      const yearEnd = lastFilterDates?.finalDate?.slice(0, 4);
      const monthEnd = lastFilterDates?.finalDate?.slice(5, 7);
      const dayEnd = lastFilterDates?.finalDate?.slice(8, 10);

      return (
        <>
          <span>{`${dayStart} ${monthsObject[monthStart]}, ${yearStart}`}</span>
          <span>-</span>
          <span>{`${dayEnd} ${monthsObject[monthEnd]}, ${yearEnd}`}</span>
        </>
      );
    }
  };

  const getTodayReport = async () => {
    const today = new Date();
    const lastMonth = new Date();

    lastMonth.setMonth(lastMonth.getMonth() - 1);

    const filterDates = {
      initialDate: moment(lastMonth).format('YYYY-MM-DD HH:mm:ss'),
      finalDate: moment(today).format('YYYY-MM-DD HH:mm:ss')
    };

    setLastFilterDates(filterDates);

    const body = {
      filterDates
    };

    setLoading(true);

    const response = await services.reports.getConciergeByFilterDate(body);

    if (response.status === 200) {
      setData(response.data);
      setDisplayValues(response.data);
      setTableValues(response.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data) {
      const {
        atendimentosEstipulantePeriodo,
        tempoMedioPrimeiraResposta,
        tempoMedioConclusao,
        usuariosPorEmpresaPeriodo,
        novosUsuariosPorEmpresaPeriodo,
        notasAtendimentoPeriodo
      } = data;

      const atendimentosFiltrados = atendimentosEstipulantePeriodo?.filter(
        (item) => item.estipulante_id === estipulanteSelected
      );
      const tempoMedioPrimeiraRespostaFiltrado =
        tempoMedioPrimeiraResposta?.filter(
          (item) => item.estipulante_id === estipulanteSelected
        );
      const tempoMedioConclusaoFiltrado = tempoMedioConclusao?.filter(
        (item) => item.estipulante_id === estipulanteSelected
      );
      const usuariosPorEmpresaPeriodoFiltrado =
        usuariosPorEmpresaPeriodo?.filter(
          (item) => item.estipulante_id === estipulanteSelected
        );
      const novosUsuariosPorEmpresaPeriodoFiltrado =
        novosUsuariosPorEmpresaPeriodo?.filter(
          (item) => item.estipulante_id === estipulanteSelected
        );
      const notasAtendimentoPeriodoFiltrado = notasAtendimentoPeriodo?.filter(
        (item) => item.estipulante_id === estipulanteSelected
      );

      getServicesCountEstipulanteFiltrado(atendimentosFiltrados);

      setFirstResponseTimeEstipulante(
        tempoMedioPrimeiraResposta.length
          ? getAverageTime(tempoMedioPrimeiraRespostaFiltrado)
          : 0
      );

      setConclusionTimeEstipulante(getAverageTime(tempoMedioConclusaoFiltrado));

      setUsersCountEstipulante(
        getUsersCount(usuariosPorEmpresaPeriodoFiltrado)
      );

      setNewUsersCountEstipulante(
        getUsersCount(novosUsuariosPorEmpresaPeriodoFiltrado)
      );

      setNetPromoterScoreEstipulante(
        getNetPromoterScore(notasAtendimentoPeriodoFiltrado)
      );
    }
  }, [estipulanteSelected]);

  useEffect(() => {
    getTodayReport();
  }, []);

  return loading ? (
    <RelatorioConciergeWrapper>
      <div className="circular-spinner-container">
        <CircularProgress
          size={150}
          thickness={1}
          className="circular-spinner"
        />
        <span>Gerando relatório ...</span>
      </div>
    </RelatorioConciergeWrapper>
  ) : (
    <RelatorioConciergeWrapper>
      <div className="title_container">
        <span
          className="return_link"
          onClick={() => setRelatorioToDisplay(undefined)}
        >
          Todos os relatórios
        </span>
        <BsSlash className="slash_icon" size={20} />
        <span>Relatório Concierge</span>
      </div>
      <div className="relatorio_concierge__wrapper">
        <div className="relatorio_concierge__header">
          <div className="header_info_wrapper">
            <div className="info_filter__container">
              <span className="info_filter__title">Filtrado por período:</span>
              <div className="info_filter__period">
                {renderInfoPeriodoFiltrado()}
              </div>
            </div>
            <button className="filter_icon_btn">
              <RiFilter3Fill
                className="filter_icon"
                size={16}
                onClick={filterIconClickHandler}
              />
            </button>
          </div>
        </div>

        <div className="relatorio_concierge__content">
          <AsideMenuConcierge
            relatorioSelecionado={relatorioSelecionado}
            setRelatorioSelecionado={setRelatorioSelecionado}
            servicesOpenedCount={servicesOpenedCount}
            servicesClosedCount={servicesClosedCount}
            firstResponseTime={firstResponseTime}
            conclusionTime={conclusionTime}
            usersCount={usersCount}
            newUsersCount={newUsersCount}
            netPromoterScore={netPromoterScore}
            formatTimeValue={formatTimeValue}
            estipulanteSelected={estipulanteSelected}
            servicesOpenedCountEstipulante={servicesOpenedCountEstipulante}
            servicesClosedCountEstipulante={servicesClosedCountEstipulante}
            firstResponseTimeEstipulante={firstResponseTimeEstipulante}
            conclusionTimeEstipulante={conclusionTimeEstipulante}
            usersCountEstipulante={usersCountEstipulante}
            newUsersCountEstipulante={newUsersCountEstipulante}
            netPromoterScoreEstipulante={netPromoterScoreEstipulante}
          />
          <RelatorioContent
            relatorioSelecionado={relatorioSelecionado}
            servicesByPeriod={servicesByPeriod}
            averageFirstResponseTime={averageFirstResponseTime}
            averageConclusionTime={averageConclusionTime}
            usersByPeriod={usersByPeriod}
            newUsersByPeriod={newUsersByPeriod}
            evaluationsByPeriod={evaluationsByPeriod}
            formatTimeValue={formatTimeValue}
            servicesCountByMonths={servicesCountByMonths}
            servicesCountOfNewUsersByMonths={servicesCountOfNewUsersByMonths}
            estipulanteSelected={estipulanteSelected}
            setEstipulanteSelected={setEstipulanteSelected}
          />
        </div>
      </div>
      {openFilter ? renderFilterPopper() : ''}
    </RelatorioConciergeWrapper>
  );
};

export default RelatorioConcierge;
