import { useState, useEffect } from 'react';
import { CardTasksWrapper } from './styles';
import {
  Checkbox,
  ClickAwayListener,
  ThemeProvider,
  createTheme
} from '@mui/material';
import { BsCheck, BsCircle, BsFillCheckCircleFill } from 'react-icons/bs';
import { TbEyeglass } from 'react-icons/tb';
import moment from 'moment';
import { toast } from 'react-hot-toast';
import ReactDatePicker from 'react-datepicker';
import services from 'apps/broker/services';

const theme = createTheme({
  palette: {
    genoaBlue: {
      main: '#456AED'
    },
    darkGenoaBlue: {
      main: '#1E2552'
    }
  }
});

const CardTasks = ({ corretores, tasks, setTaskSelected, getTasksData }) => {
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [editedDate, setEditedDate] = useState('');
  const [openEditDate, setOpenEditDate] = useState(false);
  const [openEditTitle, setOpenEditTitle] = useState(false);
  const [defaultTitle, setDefaultTitle] = useState('');
  const [editedTitle, setEditedTitle] = useState('');

  const selectTaskHandler = () => {
    setTaskSelected(tasks?.id);
  };

  const renderCorretoresOptions = () => {
    return corretores?.map((item, index) => (
      <option key={`${item.id}-${index}`} value={item.id}>
        {item.nome}
      </option>
    ));
  };

  const selectResponsavelChangeHandler = async (e) => {
    if (e.target.value !== undefined) {
      const body = {
        usuario_id: e.target.value,
        task_id: tasks?.id
      };

      const response = await services.tasks.updateResponsable(body);
      if (response.status === 200) {
        toast.dismiss();
        toast.success('Resposável atualizado com sucesso!', {
          duration: 2500
        });
        getTasksData();
      } else {
        toast.dismiss();
        toast.error('Algo deu errado, tente novamente!', {
          duration: 2500
        });
      }
    }
  };

  const updateTaskStatusHandler = async (e) => {
    const checked = e.target.checked;
    setCheckboxChecked(checked);

    const body = {
      task_id: tasks.id
    };

    const response = await services.tasks.updateStatusClose(body);
    if (response.status === 200) {
      setCheckboxChecked(false);
      toast.dismiss();
      toast.success('Task atualizada com sucesso!', {
        duration: 2500
      });
      getTasksData();
    } else {
      toast.dismiss();
      toast.error('Algo deu errado, tente novamente!', {
        duration: 2500
      });
    }
  };

  const reopenTaskUpdateHandler = async () => {
    const body = {
      task_id: tasks.id
    };

    const response = await services.tasks.updateStatusReopen(body);
    if (response.status === 200) {
      setCheckboxChecked(false);
      toast.dismiss();
      toast.success('Task atualizada com sucesso!', {
        duration: 2500
      });
      getTasksData();
    } else {
      toast.dismiss();
      toast.error('Algo deu errado, tente novamente!', {
        duration: 2500
      });
    }
  };

  const updateDateTaskHandler = async () => {
    const body = {
      data_task: new Date(editedDate),
      task_id: tasks.id
    };

    const response = await services.tasks.updateDate(body);
    if (response.status === 200) {
      setOpenEditDate(false);
      toast.dismiss();
      toast.success('Data da task atualizada com sucesso!', {
        duration: 2500
      });
      getTasksData();
    } else {
      toast.dismiss();
      toast.error('Algo deu errado, tente novamente!', {
        duration: 2500
      });
    }
  };

  const updateTitleTask = async () => {
    const body = {
      titulo_task: editedTitle,
      id: tasks?.id
    };

    await services.tasks.updateTitle(body);
    setOpenEditTitle(false);
    getTasksData();
  };

  useEffect(() => {
    if (openEditDate) {
      updateDateTaskHandler();
    }
  }, [editedDate]);

  return (
    <CardTasksWrapper>
      <td>
        {!tasks?.data_conclusao ? (
          <Checkbox
            checked={checkboxChecked}
            icon={<BsCircle />}
            checkedIcon={<BsFillCheckCircleFill />}
            onChange={updateTaskStatusHandler}
          />
        ) : (
          <Checkbox
            checked={true}
            icon={<BsCircle />}
            checkedIcon={<BsFillCheckCircleFill />}
            onChange={reopenTaskUpdateHandler}
          />
        )}
      </td>
      <td>
        {openEditTitle ? (
          <ClickAwayListener onClickAway={() => setOpenEditTitle(false)}>
            <div className="input_descricao_container">
              <input
                className="input_descricao"
                type="text"
                autoFocus="true"
                value={editedTitle}
                onChange={({ target }) => {
                  setEditedTitle(target.value);
                }}
              />
              <button
                className="btn_check"
                onClick={() => {
                  if (editedTitle !== defaultTitle) {
                    updateTitleTask();
                    setOpenEditTitle(false);
                  }
                }}
              >
                <BsCheck />
              </button>
            </div>
          </ClickAwayListener>
        ) : (
          <span onClick={() => setOpenEditTitle(true)}>
            {tasks?.titulo_task}
          </span>
        )}
      </td>
      <td>
        {openEditDate ? (
          <ClickAwayListener onClickAway={() => setOpenEditDate(false)}>
            <div>
              <ReactDatePicker
                className={`custom_datepicker ${editedDate ? 'active' : ''}`}
                autoFocus="true"
                selected={new Date(tasks?.data_task)}
                locale={'ptBR'}
                minDate={new Date()}
                onChange={(date) => setEditedDate(date)}
                onKeyDown={(e) => {
                  if (e?.key === 'Enter') {
                    updateDateTaskHandler();
                  }
                }}
                dateFormat="dd/MM/yyyy"
              />
            </div>
          </ClickAwayListener>
        ) : (
          <span onClick={() => setOpenEditDate(true)}>
            {moment(tasks?.data_task).format('DD/MM/YYYY')}
          </span>
        )}
      </td>
      <td>
        {tasks?.estipulante_id
          ? tasks?.estipulante_razao_social
          : null || tasks?.lead_potencial_id
          ? tasks?.lead_potencial_razao_social
          : null}
      </td>
      <td>{tasks?.tipo_produto ? tasks?.tipo_produto : null}</td>
      <td>{tasks?.seguradora ? tasks?.seguradora : null}</td>
      <td>
        <ThemeProvider theme={theme}>
          <div>
            <select
              className="select-arrow"
              value={tasks?.usuario_id}
              onChange={selectResponsavelChangeHandler}
            >
              <option value={undefined}>Selecione</option>
              {renderCorretoresOptions()}
            </select>
          </div>
        </ThemeProvider>
      </td>
      <td>
        {tasks?.data_conclusao
          ? moment(tasks?.data_conclusao).format('DD/MM/YYYY')
          : null}
      </td>
      <td>
        <button className="details_button" onClick={selectTaskHandler}>
          <TbEyeglass />
        </button>
      </td>
    </CardTasksWrapper>
  );
};

export default CardTasks;
