import styled from 'styled-components';
import colors from 'styles/colors';

export const NegociosNavWrapper = styled.div`
  width: calc(100% - 25px);
  height: max-content;
  display: flex;
  justify-content: space-between;
  column-gap: 100px;

  .nav_content {
    display: flex;
    justify-content: space-between;
    column-gap: 100px;
  }

  h1 {
    font-family: "Avenir Next GEO W05 Heavy";
    font-size: 1.2rem;
    color: ${colors.genoaGrey};
  }

  .title_container {
    display: flex;
    align-items: end;
  }

  .negocios__tab_options {
    display: flex;
    column-gap: 70px;

    button {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
    }

    .option {
      font-size: 0.9em;
      display: flex;
      align-items: end;
      transform: translateY(-2px);
      font-family: Avenir Next Geo W05 Demi;
      color: ${colors.genoaGrey};
      text-decoration: underline;
      text-underline-offset: 2px;

      :hover {
        cursor: pointer;
      }
    }

    .selected {
      color: ${colors.genoaBlue};
    }
  }

  .btn {
    font-family: 'Avenir Next GEO W05 Demi';
    border: none;
    cursor: pointer;
    background: none;
    transition: 0.1s ease-in;
    justify-self: end;
  }
`