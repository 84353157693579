import { useState } from 'react';
import { RemoveBeneficiarioContainer } from './styles';
import { RiHeartPulseFill } from 'react-icons/ri';
import { ReactComponent as IconTooth } from 'assets/imgs/svg/teeth.svg';
import DatePicker from 'react-datepicker';
import { BiPlusCircle } from 'react-icons/bi';
import { AiFillCaretLeft } from 'react-icons/ai';
import { Button } from '@material-ui/core';

const optionsMotivoExclusao = {
  // Os números das chaves correspondem ao ID da tabela `motivo_exclusao_contrato`
  0: 'Selecione',
  1: 'Demissão sem Justa Causa',
  2: 'Demissão com Justa Causa',
  3: 'Saída do Contrato Social',
  4: 'Recisão de Contrato de Prestação de Serviços',
  5: 'A Pedido de Segurado',
  6: 'Falecimento'
};

const FormRemoveBeneficiary = ({
  beneficiario,
  beneficiarioContratos,
  planosToRemove,
  setPlanosToRemove,
  motivoExclusao,
  setMotivoExclusao,
  date,
  setDate,
  exclusaoContratoHandler
}) => {
  const [showDependentesTitular, setShowDependentesTitular] = useState(false);

  const renderOptionsMotivoExclusao = () => {
    const optionKeys = Object.keys(optionsMotivoExclusao);
    const defaultValue = optionKeys.shift();
    const validOptionKeys = optionKeys;
    return (
      <>
        <option value={defaultValue} style={{ fontWeight: 'bold' }} disabled>
          {optionsMotivoExclusao[defaultValue]}
        </option>
        {validOptionKeys.map((option) => (
          <option key={option} value={option}>
            {optionsMotivoExclusao[option]}
          </option>
        ))}
      </>
    );
  };

  const selectMotivoChangeHandler = (event) => {
    setMotivoExclusao(event.target.value);
  };

  const renderSelectMotivoExclusao = () => {
    return (
      <select value={motivoExclusao} onChange={selectMotivoChangeHandler}>
        {renderOptionsMotivoExclusao()}
      </select>
    );
  };

  const renderIconeTipoProduto = (contrato) => {
    switch (contrato.tipo_produto) {
      case 'Vida':
        return (
          <div>
            <span>
              {' '}
              <RiHeartPulseFill className="plano-icon" size={30} />{' '}
            </span>
          </div>
        );
      case 'Odonto':
        return (
          <div>
            <span>
              {' '}
              <IconTooth className="plano-icon" size={30} />{' '}
            </span>
          </div>
        );
      default:
        return (
          <div>
            <span>
              {' '}
              <BiPlusCircle className="plano-icon" size={30} />{' '}
            </span>
          </div>
        );
    }
  };

  const planoOptionClickHandler = (contrato) => {
    setPlanosToRemove((prevState) => ({
      ...prevState,
      [contrato.contrato_id]: !prevState[contrato.contrato_id]
    }));
  };

  const renderHealthPlanInfo = () => {
    const filteredHealth = beneficiarioContratos.filter(
      (item) => item.produto_id === 1
    );

    return filteredHealth.map((contrato, index) => {
      if (!contrato) {
        return '';
      }
      return contrato.movimentacao_remocao ? (
        <div className={'remove-plano-option-inactive'} key={index}>
          {renderIconeTipoProduto(contrato)}
          <div>
            {contrato?.numero_apolice ? (
              <span>{`${contrato.seguradora_nome} (Apólice: ${contrato.numero_apolice})`}</span>
            ) : (
              <span>{contrato.seguradora_nome}</span>
            )}
          </div>
        </div>
      ) : (
        <div
          className={
            planosToRemove[contrato.contrato_id]
              ? 'remove-plano-option-selected'
              : 'remove-plano-option'
          }
          onClick={() => planoOptionClickHandler(contrato)}
          key={index}
        >
          {renderIconeTipoProduto(contrato)}
          <div>
            {contrato?.numero_apolice ? (
              <span>{`${contrato.seguradora_nome} (Apólice: ${contrato.numero_apolice}) - ${contrato.tipo_plano}`}</span>
            ) : (
              <span>{`${contrato.seguradora_nome} - ${contrato.tipo_plano}`}</span>
            )}
          </div>
        </div>
      );
    });
  };

  const renderDentalPlanInfo = () => {
    const filteredDental = beneficiarioContratos.filter(
      (item) => item.produto_id === 2
    );

    return filteredDental.map((contrato, index) => {
      if (!contrato) {
        return '';
      }
      return contrato.movimentacao_remocao ? (
        <div className={'remove-plano-option-inactive'} key={index}>
          {renderIconeTipoProduto(contrato)}
          <div>
            {contrato?.numero_apolice ? (
              <span>{`${contrato.seguradora_nome} (Apólice: ${contrato.numero_apolice})`}</span>
            ) : (
              <span>{contrato.seguradora_nome}</span>
            )}
          </div>
        </div>
      ) : (
        <div
          className={
            planosToRemove[contrato.contrato_id]
              ? 'remove-plano-option-selected'
              : 'remove-plano-option'
          }
          onClick={() => planoOptionClickHandler(contrato)}
          key={index}
        >
          {renderIconeTipoProduto(contrato)}
          <div>
            {contrato?.numero_apolice ? (
              <span>{`${contrato.seguradora_nome} (Apólice: ${contrato.numero_apolice}) - ${contrato.tipo_plano}`}</span>
            ) : (
              <span>{`${contrato.seguradora_nome} - ${contrato.tipo_plano}`}</span>
            )}
          </div>
        </div>
      );
    });
  };

  // const renderContratoOptions = () => {
  //   const contratosSaude = beneficiarioContratos.filter(
  //     (item) => item.produto_id === 1
  //   );
  //   const maisRecenteSaude = contratosSaude
  //     .sort(
  //       (a, b) =>
  //         Number(new Date(b.contrato_beneficiario_created_at)) -
  //         Number(new Date(a.contrato_beneficiario_created_at))
  //     )
  //     .shift();

  //   const contratosOdonto = beneficiarioContratos.filter(
  //     (item) => item.produto_id === 2
  //   );
  //   const maisRecenteOdonto = contratosOdonto
  //     .sort(
  //       (a, b) =>
  //         Number(new Date(b.contrato_beneficiario_created_at)) -
  //         Number(new Date(a.contrato_beneficiario_created_at))
  //     )
  //     .shift();
  //   const contratosMaisRecentes = [maisRecenteSaude, maisRecenteOdonto];
  //   return contratosMaisRecentes.map((contrato, index) => {
  //     if (!contrato) {
  //       return '';
  //     }
  //     return contrato.movimentacao_remocao ? (
  //       <div className={'remove-plano-option-inactive'} key={index}>
  //         {renderIconeTipoProduto(contrato)}
  //         <div>
  //           {contrato?.numero_apolice ? (
  //             <span>{`${contrato.seguradora_nome} (Apólice: ${contrato.numero_apolice})`}</span>
  //           ) : (
  //             <span>{contrato.seguradora_nome}</span>
  //           )}
  //         </div>
  //       </div>
  //     ) : (
  //       <div
  //         className={
  //           planosToRemove[contrato.contrato_id]
  //             ? 'remove-plano-option-selected'
  //             : 'remove-plano-option'
  //         }
  //         onClick={() => planoOptionClickHandler(contrato)}
  //         key={index}
  //       >
  //         {renderIconeTipoProduto(contrato)}
  //         <div>
  //           {contrato?.numero_apolice ? (
  //             <span>{`${contrato.seguradora_nome} (Apólice: ${contrato.numero_apolice}) - ${contrato.tipo_plano}`}</span>
  //           ) : (
  //             <span>{`${contrato.seguradora_nome} - ${contrato.tipo_plano}`}</span>
  //           )}
  //         </div>
  //       </div>
  //     );
  //   });
  // };

  const isAnyContratoSelected = () => {
    const keys = Object.keys(planosToRemove);
    return keys.some((key) => planosToRemove[key]);
  };

  const renderLimparDataButton = () => {
    if (date !== '') {
      return (
        <div className="clear-date-option-container">
          <span className="clear-date-option" onClick={() => setDate('')}>
            Limpar data
          </span>
        </div>
      );
    }
    return (
      <div className="clear-date-option-container">
        <span className="clear-date-option"></span>
      </div>
    );
  };

  const formatNomesTitulares = (titulares) => {
    const nomesTitularesFormatados = titulares.map((titular) => {
      const nome = titular.nome;
      const nomeArr = nome.split(' ');
      const nomeArrFormatado = nomeArr.map((palavraDoNome) => {
        if (palavraDoNome.length > 2 && palavraDoNome.toLowerCase() !== 'dos') {
          const nomeLowered = palavraDoNome
            .slice(1, palavraDoNome.length)
            .toLowerCase();
          const nomeFormatado = palavraDoNome[0].toUpperCase();
          return nomeFormatado + nomeLowered;
        } else {
          const nomeLowered = palavraDoNome.toLowerCase();
          return nomeLowered;
        }
      });
      const nomeTotalFormatado = nomeArrFormatado.join(' ');
      return {
        ...titular,
        nome: nomeTotalFormatado,
        // cpf: titular.cpf
      };
    });
    return nomesTitularesFormatados;
  };

  const showDependentesTitularesClickHandler = () => {
    setShowDependentesTitular((prevState) => !prevState);
  };
  const renderTitularDependentes = () => {
    let nomesDependentesFormatados = [];

    if (beneficiario && beneficiario.dependentes) {
      nomesDependentesFormatados = formatNomesTitulares(
        beneficiario.dependentes
      );
    }
    return (
      <div
        className={
          showDependentesTitular
            ? 'dependente-beneficiario-container-open'
            : 'dependente-beneficiario-container'
        }
        style={{
          height: `${
            showDependentesTitular
              ? beneficiario &&
                beneficiario.dependentes &&
                beneficiario.dependentes.length
                ? beneficiario.dependentes.length * 30 + 31
                : 31
              : beneficiario &&
                beneficiario.dependentes &&
                beneficiario.dependentes.length
              ? 31
              : 0
          }px`
        }}
      >
        {beneficiario && beneficiario.dependentes ? (
          <div className="first-row-container">
            <div className="dependentes-titular-first-row">
              <span>Dependentes: </span>
              <span className="dependentes-quantidade-info">
                {beneficiario.dependentes.length}
              </span>
              <div className="arrow-container">
                <AiFillCaretLeft
                  className={
                    showDependentesTitular
                      ? 'arrow-close-icon'
                      : 'arrow-open-icon'
                  }
                  size={15}
                  onClick={showDependentesTitularesClickHandler}
                />
              </div>
            </div>
            <div className="dependentes-titular-second-row">
              <div
                className="dependentes-titular-lista"
                style={{
                  height: `${showDependentesTitular ? 'max-content' : 0}`
                }}
              >
                {nomesDependentesFormatados.map((dependente) => (
                  <div>
                    <span className="dependente-nome">- {dependente.nome}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  };

  return (
    <RemoveBeneficiarioContainer>
      <div className="beneficiario-info-container">
        <span className="beneficiario-info-title">{beneficiario.tipo}</span>
        <div>
          <span className="beneficiario-info-title">Nome: </span>
          <span>{beneficiario.nome}</span>
        </div>
        <div>
          <span className="beneficiario-info-title">Id do Beneficiario: </span>
          <span>{beneficiario.beneficiario_id}</span>
        </div>
        <div>
          <span className="beneficiario-info-title">Estipulante: </span>
          <span>{beneficiario.razao_social}</span>
        </div>
        {renderTitularDependentes()}
      </div>
      <div className="remove-beneficiario-container">
        <div>
          <span className="remove-info-title">
            De qual plano o colaborador será excluído?
          </span>
        </div>
        <div className="remove-planos-options">
          <div>{renderHealthPlanInfo()}</div>
          <div>{renderDentalPlanInfo()}</div>
        </div>
      </div>
      <div className="remove-beneficiario-container">
        <div>
          <span className="remove-info-title">Qual o motivo da exclusão?</span>
        </div>
        <div className="remove-planos-options">
          {renderSelectMotivoExclusao()}
        </div>
      </div>
      <div className="remove-beneficiario-container">
        <div className="span-date-title">
          <span className="remove-info-title">
            A partir de qual data se tornará inativo?
          </span>
        </div>
        <div className="datepicker-selector-container">
          <DatePicker
            className="input-data"
            selected={date}
            value={date}
            locale="ptBR"
            onChange={(date) => setDate(date)}
            placeholderText="..."
            dateFormat="dd/MM/yyyy"
          />
          {renderLimparDataButton()}
        </div>
      </div>
      <div className="remove-beneficiario-button-container">
        <Button
          className={isAnyContratoSelected() ? 'send-btn' : 'send-btn-disabled'}
          variant="contained"
          onClick={exclusaoContratoHandler}
          disabled={!isAnyContratoSelected()}
        >
          Remover
        </Button>
      </div>
    </RemoveBeneficiarioContainer>
  );
};

export default FormRemoveBeneficiary;
