import styled from "styled-components";
import colors from "styles/colors";

export const RelatorioContentContainer = styled.div`
  padding: 20px 35px 20px 0px;
  // background-color: red;
  min-height: 650px;
  display: grid;
  grid-template-columns: 50% 50%;
  column-gap: 15px;


  .graphs_column {
    display: flex;
    flex-direction: column;
    row-gap: 40px;

    .graphs_container {
      display: flex;
      flex-direction: column;
      row-gap: 40px;
      min-height: 500px;

    }
  }


`

export const ConciergeTooltip = styled.div`
  border: none;
  outline: none;

  .tooltip-card{
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    padding: 10px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

    .tooltip-info-container {
      display: flex;
      column-gap: 10px;
      font-size: 11px;
      
      .tooltip-info-title {
        font-family: "Avenir Next GEO W05 Bold";
        color: ${colors.genoaGrey};
      }

      .tooltip-info-value-important {
        font-family: "Avenir Next GEO W05 Bold";
        color: ${colors.genoaBlue};
      }

      .tooltip-info-value {
        font-family: "Avenir Next GEO W05 Regular";
        color: ${colors.genoaGrey};
      }
    }
  }
`

