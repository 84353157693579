import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  width: 700px;
  box-sizing: content-box;
  min-height: 100.1vh;

  header {
    background-color: ${colors.rhBlue};
    padding: 15px;
    padding-left: 60px;
    height: 110px;

    .header-title-container {
      margin-top: 45px;
      color: ${colors.white};

      span {
        font-family: "Avenir Next GEO W05 Heavy";
        font-size: 20px;
      }
    }

  }

  section {
    padding: 20px;
  }

  section.infos {
    padding: 20px;
    padding-left: 60px;

    display: flex;
    flex-direction: column;
    row-gap: 25px;

    form {
      display: flex;
      flex-direction: column;
      row-gap: 25px;
    }

    .input-container {
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      label {
        color: ${colors.genoaGrey};
        font-size: 15px;  
        font-family: "Avenir Next GEO W05 Demi";
      }

      input {
        padding: 0 15px;
        font-family: "Avenir Next GEO W05 Regular";
        border: 1.5px ${colors.rhBlue} solid;
        font-size: 15px;
        height: 32px;
        border-radius: 16px;
        width: 420px;
      }

      .input-password-container {
        display: flex;
        align-items: center;

        -moz-user-select: none;
        -webkit-user-select: none;
        user-select: none;

        input {
          ::-ms-reveal {
            display: none;
          }
        }

        .show-icon {
          position: relative;
          left: -30px;
          color: ${colors.genoaDarkBlue};

          :hover {
            cursor: pointer;
          }
        }
      }

      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-family: "Avenir Next GEO W05 Regular";
        height: 32px;
        padding-left: 20px;
        padding-right: 35px;
        background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 9L0.73686 0.75L10.2631 0.75L5.5 9Z' fill='%2393A9F5'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position-x: 94%;
        background-position-y: 10px;
        width: 150px;
        border-radius: 20px;
        border: 1.5px ${colors.rhBlue} solid;
        font-size: 15px;
        :focus-visible {
          outline: 1px ${colors.rhBlue}f5 auto;
        }
      }
    }

    .send-btn {
      margin-top: 15px;
      width: max-content;
      color: ${colors.genoaDarkBlue};
      font-family: "Avenir Next GEO W05 Demi";
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      display: flex;
      align-items: center;

      .send-icon {
        margin-left: 5px;
      }

      :hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .error-message-span {
      font-family: "Avenir Next GEO W05 Demi";
      color: ${colors.red};
      font-size: 15px;
    }

    .confirmation-message-span {
      font-family: "Avenir Next GEO W05 Demi";
      color: ${colors.rhBlue};
      font-size: 15px;
    }

  }
`;


