import { createContext, useContext, useState } from 'react';

export const UrgentDemandsContext = createContext();

const URGENT_DEMANDS_DEFAULT = {
  redirected: false,
  page: undefined,
  demand_id: undefined
};

export const UrgentDemandsTicketsProvider = ({ children }) => {
  const [urgentDemandsTickets, setUrgentDemandsTickets] = useState(
    URGENT_DEMANDS_DEFAULT
  );

  const resetUrgentDemandsTickets = () => {
    setUrgentDemandsTickets(URGENT_DEMANDS_DEFAULT);
  };

  return (
    <UrgentDemandsContext.Provider
      value={{
        urgentDemandsTickets,
        setUrgentDemandsTickets,
        resetUrgentDemandsTickets
      }}
    >
      {children}
    </UrgentDemandsContext.Provider>
  );
};

export const useUrgentDemandsTickets = () => useContext(UrgentDemandsContext);
