import http, { BROKERAGE_UUID } from 'services/http';

const addNewContract = async (data, estipulante) => {
  const res = await http.post('/corretor/add/contrato', data, {
    headers: {
      estipulante: estipulante,
      corretora: BROKERAGE_UUID
    }
  });

  return res;
};

const addContractFiles = async (formData, contratoId, type) => {
  const res = await http.post('/corretor/file-contrato', formData, {
    headers: {
      contrato: contratoId,
      corretora: BROKERAGE_UUID,
      type
    }
  });

  return res;
};

const modules = {
  addNewContract,
  addContractFiles
};

export default modules;
