import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Wraper } from './styles';
import { useForm } from "react-hook-form";
import { BsArrowRight } from "react-icons/bs";
import { useEmitTicket } from "contexts/emitTicket";

const ProgramaBeneficios = ({ formExt, produto }) => {
  const {
    externalForm,
    updateExternalForm,
    externalFormStage,
    updateExternalFormStage,
  } = useEmitTicket();

  const { aderente: valueAderente } = externalForm;
  const { downloadedForm } = externalFormStage;
  const nomeProduto = produto === 'Plano de Saúde' ? 'Seguro Saúde' : 'Seguro Odontológico';

  const { register, handleSubmit, watch, formState: { errors } } = useForm({
    defaultValues: { aderente: valueAderente }
  });

  const changeStage = (stage) => {
    updateExternalFormStage(stage);
  }

  const goForward = (data) => {
    updateExternalForm(data);
    if (data.aderente === "inclusao-dependentes") {
      changeStage('AddDependentes');
      return;
    }
    if (data.aderente === "add-plano") {
      changeStage('AddPlano');
      return;
    }
    changeStage('InfosTitular');
  }

  return (
    <Wraper>
      {(formExt && downloadedForm === false) ? <div className="fichaProposta">
        <div className="desc">
          Para entrar no <b>{nomeProduto}</b>,  a seguradora pede uma ficha proposta preenchida e assinada.
          Baixe ela aqui e quando ela estiver pronta, avance no formulário.
        </div>
        <div className="link">
          <a href={formExt}>Baixar Ficha Proposta</a>
        </div>
        <div className="actions">
          <span onClick={() => updateExternalFormStage({ downloadedForm: true })}>Avançar<BsArrowRight />  </span>
        </div>
      </div>
        :
        <>
          <fieldset>
            <legend>Quem irá aderir  ao {nomeProduto}?</legend>
            <div className="checkbox-area">
              <div className="checkbox-group">
                <label
                  htmlFor="solicitante"
                  className={watch('aderente') === 'solicitante' ? 'select-label active' : 'select-label'}>
                  Apenas eu.
                </label>
                <input
                  id="solicitante"
                  type='radio'
                  value="solicitante"
                  {...register('aderente', { required: true })}
                />
              </div>
              <div className="checkbox-group">
                <label
                  htmlFor="solicitante-dependentes"
                  className={watch('aderente') === 'solicitante-dependentes' ? 'select-label active' : 'select-label'}>
                  Eu e meus dependentes.
                </label>
                <input
                  id="solicitante-dependentes"
                  type='radio'
                  value="solicitante-dependentes"
                  {...register('aderente', { required: true })}
                />
              </div>
              {/* <div className="checkbox-group">
                <label
                  htmlFor="inclusao"
                  className={watch('aderente') === 'inclusao-dependentes' ? 'select-label active' : 'select-label'}>
                  Já estou no {nomeProduto} agora irei incluir meus dependentes.
                </label>
                <input
                  id="inclusao"
                  type='radio'
                  value="inclusao-dependentes"
                  {...register('aderente', { required: true })}
                />
              </div> */}
              {/* <div className="checkbox-group">
                <label
                  htmlFor="add-plano"
                  className={watch('aderente') === 'add-plano' ? 'select-label active' : 'select-label'}>
                  {
                    produto === 'Plano de Saúde'
                      ?
                      "Já estou no seguro dental, quero entrar no seguro saúde."
                      :
                      "Já estou no seguro saúde, quero entrar no seguro dental."
                  }
                </label>
                <input
                  id="add-plano"
                  type='radio'
                  value="add-plano"
                  {...register('aderente', { required: true })}
                />
              </div> */}
            </div>
          </fieldset>

          {errors.aderente && <div className="erroPlanos">
            <span className="erroPlanos">Selecione quem irá aderir ao programa de benefícios</span>
          </div>}

          <div className="button-areas">
            {formExt && <button type="button" onClick={() => updateExternalFormStage({ downloadedForm: false })}>Voltar</button>}

            <button type="button" onClick={handleSubmit(goForward)} >Avançar <BsArrowRight /></button>
          </div>
        </>
      }
    </Wraper>
  )
}

export default ProgramaBeneficios
