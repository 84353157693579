import React from "react";
import { Button, Dialog, } from "@material-ui/core";
import { AiFillCaretRight, AiOutlinePlus } from "react-icons/ai";

import api from "services/api";
import { useSelector } from "react-redux";
import { DrawerAdicionarBeneficiarioWrapper, Content, SideStripe, MainContent, NavButtonsContainer } from "./styles-drawerAdicionarBeneficiarioRh";

import TabOptions from "./AbasDrawerAdicionarBeneficiario/NavTab/TabOptions";
import AbaDadosBasicos from "./AbasDrawerAdicionarBeneficiario/DadosBasicos/AbaDadosBasicos";
import AbaDadosEndereco from "./AbasDrawerAdicionarBeneficiario/DadosEndereco/AbaDadosEndereco";
import AbaDadosContato from "./AbasDrawerAdicionarBeneficiario/DadosContatos/AbaDadosContato";
import AbaDadosVinculo from "./AbasDrawerAdicionarBeneficiario/DadosVinculo/AbaDadosVinculo";
import AbaDadosImc from "./AbasDrawerAdicionarBeneficiario/DadosImc/AbaDadosImc";
import AbaDadosBancarios from "./AbasDrawerAdicionarBeneficiario/DadosBancarios/AbaDadosBancarios";
import AbaPlanos from "./AbasDrawerAdicionarBeneficiario/Planos/AbaPlanos";
import determinaAbasDisponiveis from "./AbasDrawerAdicionarBeneficiario/utils/determinaAbasDisponiveis";
import { findNextAbaDisponivel, findPreviousAbaDisponivel } from "./AbasDrawerAdicionarBeneficiario/utils/switchAbasFunctions";
import AbaScanDocumentos from "./AbasDrawerAdicionarBeneficiario/ScanDocumentos/AbaScanDocumentos";
import AbaFichaProposta from "./AbasDrawerAdicionarBeneficiario/FichaProposta/AbaFichaProposta";
import AbaTipoBeneficiario from "./AbasDrawerAdicionarBeneficiario/TipoBeneficiario/AbaTipoBeneficiario";
import AbaSelecioneTitular from "./AbasDrawerAdicionarBeneficiario/SelecioneTitular/AbaSelecioneTitular";
import TabOptionsAdicionais from "./AbasDrawerAdicionarBeneficiario/BeneficiariosAdicionais/NavTab/NavTabAdicionais";
import AbaPlanosAdicionais from "./AbasDrawerAdicionarBeneficiario/BeneficiariosAdicionais/Planos/PlanosAdicionais";
import AbaDadosBasicosAdicionais from "./AbasDrawerAdicionarBeneficiario/BeneficiariosAdicionais/DadosBasicos/DadosBasicosAdicionais";
import DadosImcAdicionais from "./AbasDrawerAdicionarBeneficiario/BeneficiariosAdicionais/DadosImc/DadosImcAdicionais";
import ScanDocumentosAdicionais from "./AbasDrawerAdicionarBeneficiario/BeneficiariosAdicionais/ScanDocumentos/ScanDocumentosAdicionais";
import determinaAbasDisponiveisDepAdicionais from "./AbasDrawerAdicionarBeneficiario/utils/determinaAbasDisponiveisAdicionais";
import AbaEstipulante from "./AbasDrawerAdicionarBeneficiario/Estipulante/AbaEstipulante";
import { HiTrash } from "react-icons/hi";
import DialogConfirmRequest from "./DialogPopper";

import determinaAbasCompletas from "./AbasDrawerAdicionarBeneficiario/utils/determinaAbasCompletas";
import determinaAbasCompletasAdicionais from "./AbasDrawerAdicionarBeneficiario/utils/determinaAbasCompletasAdicionais";
import todosCamposPreenchidos from "./AbasDrawerAdicionarBeneficiario/utils/todosCamposPreenchidos";
import todosCamposPreenchidosAdicionais from "./AbasDrawerAdicionarBeneficiario/utils/todosCamposPreenchidosAdicionais";
import { usePostHog } from "posthog-js/react";


const DrawerAdicionarBeneficiarioRh = (
  {
    closeDrawer,
    contratosEstipulante,
    estipulanteAndSubs,
    getMovimentacoes
  }) => {
  const posthog = usePostHog();
  const [height, setHeight] = React.useState(window.innerHeight);
  const user = useSelector((state) => state.userReducers.user);
  const [state, setState] = React.useState({});
  const [incluirSaude, setIncluirSaude] = React.useState(false);
  const [incluirOdonto, setIncluirOdonto] = React.useState(false);
  const [abaSelected, setAbaSelected] = React.useState("planos");
  const [count, setCount] = React.useState(0);

  const contrato_estipulante_saude = state['contrato_estipulante_saude'];
  const contrato_estipulante_odonto = state['contrato_estipulante_odonto'];

  const [contratoEstipulanteSaudeInfo, setContratosEstipulanteSaudeInfo] = React.useState({});
  const [contratoEstipulanteOdontoInfo, setContratosEstipulanteOdontoInfo] = React.useState({});

  const [camposInclusao, setCamposInclusao] = React.useState({});
  const [
    camposInclusaoPersonalizados,
    setCamposInclusaoPersonalizados] = React.useState({})
  const [abasDisponiveis, setAbasDisponiveis] = React.useState({});
  const [abasCompletas, setAbasCompletas] = React.useState({})
  const [beneficiarios, setBeneficiarios] = React.useState([]);
  const [beneficiariosFilter, setBeneficiariosFilter] = React.useState([])
  const [nameFilter, setNameFilter] = React.useState("");

  const [beneficiariosAdicionais, setBeneficiariosAdicionais] = React.useState({})
  const [beneficiariosAdicionaisKeys, setBeneficiariosAdicionaisKeys] = React.useState([])
  const [beneficiarioPage, setBeneficiarioPage] = React.useState(0)
  const [envioInclusao, setEnvioInclusao] = React.useState(false);
  const [openConfirmationPopper, setOpenConfirmationPopper] = React.useState(false);
  const [files, setFiles] = React.useState([])
  const [filesAdicionais, setFilesAdicionais] = React.useState({})

  const [abasCompletasBeneficiariosAdicionais, setAbasCompletasBeneficiariosAdicionais] = React.useState({})

  const [loading, setLoading] = React.useState(false);
  const [requestCompleted, setRequestCompleted] = React.useState(false)

  const estipulante_id = state?.estipulante_id;
  const tipoBeneficiario = state?.tipoBeneficiario;

  const resetBeneficiaryInfo = () => {
    setOpenConfirmationPopper(false)
    setCount(0);
    setState({});
    setIncluirSaude(false);
    setIncluirOdonto(false);
    setNameFilter("");
    setBeneficiariosAdicionais({});
    setBeneficiarioPage(0);
    setEnvioInclusao(false);
    setRequestCompleted(false);
    setFiles([])
  }

  React.useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight)

    }
    window.addEventListener('resize', handleResize)
  });

  const appendDocuments = (formData) => {
    const nomeSnakeCase = state?.nome?.replaceAll(" ", "_")
    files?.forEach((item) => {
      formData.append(`${nomeSnakeCase}_${item?.name}`, item)
    })

    
    if (camposInclusao['ficha_proposta'] && state['ficha_proposta_saude']) {
      formData.append(`${nomeSnakeCase}_ficha_proposta_saude`, state['ficha_proposta_saude'])
    }

    if (camposInclusao['ficha_proposta'] && state['ficha_proposta_odonto']) {
      formData.append(`${nomeSnakeCase}_ficha_proposta_odonto`, state['ficha_proposta_odonto'])
    }
  }

  const appendBeneficiariosAdicionaisDocuments = (formData) => {
    // console.log('formData', formData)

    beneficiariosAdicionaisKeys?.forEach((item) => {
      const dependente = beneficiariosAdicionais[Number(item)];
      const dependenteNomeSnakeCase = dependente?.nome.replaceAll(" ", "_")
      const filesDependente = filesAdicionais[Number(item)];
      filesDependente?.forEach((item) => {
        formData.append(`${dependenteNomeSnakeCase}_${item?.name}`, item)
      })
    })
  }

  const formatBeneficiarioPrincipal = (newState, state) => {
    // console.log(newState, state)
    const keys = Object.keys(state);
    const keysToNotBeIncluded = [
      'documento_scan_rg',
      'ficha_proposta_saude',
      'ficha_proposta_odonto',
      'scan_carta_inclusao',
      'scan_carta_permanencia',
      'scan_carteira_trabalho',
      'scan_comprovante_residencia',
      'scan_passaporte',
      'documento_comprovacao_vinculo',
      'documento_scan_cpf'
    ]
    keys.forEach((item) => {
      if (!keysToNotBeIncluded.includes(item)) {
        return newState[item] = state[item]
      }
      return
    })
  }

  const generateInclusao = () => {
    const principal = state;
    const dependentesAdicionais = beneficiariosAdicionais;

    // console.log('principal', principal)
    // console.log('dependentesAdicionais', dependentesAdicionais)

    principal['incluirSaude'] = incluirSaude;
    principal['incluirOdonto'] = incluirOdonto;

    const estipulanteFiltrado = estipulanteAndSubs
      ?.find((item) => item.id === principal?.estipulante_id)

    principal['estipulante'] = estipulanteFiltrado?.razao_social

    if (incluirSaude) {
      const contratoFiltrado = contratosEstipulante?.saude
        ?.find((item) => item?.contrato_estipulante_id === principal['contrato_estipulante_saude'])
      principal['seguradora_saude'] = contratoFiltrado?.seguradora_nome
    }

    if (incluirOdonto) {
      const contratoFiltrado = contratosEstipulante?.odonto
        ?.find((item) => item?.contrato_estipulante_id === principal['contrato_estipulante_odonto'])
      principal['seguradora_odonto'] = contratoFiltrado?.seguradora_nome
    }

    const dependentesAdicionaisKeys = Object.keys(dependentesAdicionais);

    if (principal?.tipoBeneficiario === 'Titular') {
      dependentesAdicionaisKeys?.map((key) => {
        dependentesAdicionais[Number(key)].titular = principal?.nome
      });
    }

    // console.log('dependentesAdicionais', dependentesAdicionais)

    dependentesAdicionaisKeys?.map((key) => {
      const dependenteAdicional = dependentesAdicionais[Number(key)]

      if (dependenteAdicional?.incluirSaude) {
        const contratoFiltrado = contratosEstipulante?.saude
          ?.find((item) => item?.contrato_estipulante_id === dependenteAdicional['contrato_estipulante_saude'])
        dependentesAdicionais[Number(key)]['seguradora_saude'] = contratoFiltrado?.seguradora_nome
      }

      if (dependenteAdicional?.incluirOdonto) {
        const contratoFiltrado = contratosEstipulante?.odonto
          ?.find((item) => item?.contrato_estipulante_id === dependenteAdicional['contrato_estipulante_odonto'])
        dependentesAdicionais[Number(key)]['seguradora_odonto'] = contratoFiltrado?.seguradora_nome
      }
    })

    const formData = new FormData();

    const newState = {};

    formatBeneficiarioPrincipal(newState, state)

    formData.append('principal', JSON.stringify(newState));
    formData.append('dependentesAdicionais', JSON.stringify(dependentesAdicionais));
    formData.append('usuario_rh', JSON.stringify(user));
    formData.append('camposInclusao', JSON.stringify(camposInclusao));

    appendDocuments(formData)

    if (beneficiariosAdicionaisKeys?.length) {
      appendBeneficiariosAdicionaisDocuments(formData)
    }
    setLoading(true)
    api.post('/rh/estipulante-sub/movimentacoes/inclusao', formData, {
      headers: {
        authorization: `Bearer ${user.token}`,
      }
    }).then((resp) => {
      // console.log(resp)
      if (getMovimentacoes) {
        getMovimentacoes()
      }
      setLoading(false)
      setRequestCompleted(true);
    })

  }


  React.useEffect(() => {
    if (tipoBeneficiario === "Dependentes") {

    }
  }, [tipoBeneficiario, estipulante_id])

  React.useEffect(() => {
    if (envioInclusao) {
      // console.log('Enviou')
      generateInclusao()
    }
  }, [envioInclusao])

  React.useEffect(() => {
    let camposInclusaoSaude;
    let camposPersonalizadosSaude;
    let camposInclusaoOdonto;
    let camposPersonalizadosOdonto;

    // console.log("contratosEstipulante?.saude", contratosEstipulante?.saude)
    // console.log("contratosEstipulante?.odonto", contratosEstipulante?.odonto)

    if (incluirSaude) {
      const contratoSaudeFiltrado = contratosEstipulante?.saude
        ?.find((item) => item.contrato_estipulante_id === contrato_estipulante_saude);
      camposInclusaoSaude = contratoSaudeFiltrado?.campos_inclusao;
      const campos_personalizados = contratoSaudeFiltrado?.campos_inclusao?.campos_personalizados
      camposPersonalizadosSaude = campos_personalizados?.length ? JSON.parse(campos_personalizados) : []

      setContratosEstipulanteSaudeInfo(contratoSaudeFiltrado);
    }

    if (incluirOdonto) {
      const contratoOdontoFiltrado = contratosEstipulante?.odonto
        ?.find((item) => item.contrato_estipulante_id === contrato_estipulante_odonto);

      camposInclusaoOdonto = contratoOdontoFiltrado?.campos_inclusao;
      const campos_personalizados = contratoOdontoFiltrado?.campos_inclusao?.campos_personalizados
      camposPersonalizadosOdonto = campos_personalizados?.length ? JSON.parse(campos_personalizados) : []

      setContratosEstipulanteOdontoInfo(contratoOdontoFiltrado);
    }

    let keysCampoInclusaoGeral;

    if (camposInclusaoSaude) {
      const keys = Object.keys(camposInclusaoSaude);
      keysCampoInclusaoGeral = keys.filter((item) => item !== 'contrato_estipulante_id' &&
        item !== 'campos_personalizados')
    } else if (camposInclusaoOdonto) {
      const keys = Object.keys(camposInclusaoOdonto);
      keysCampoInclusaoGeral = keys.filter((item) => item !== 'contrato_estipulante_id' &&
        item !== 'campos_personalizados')
    }

    let camposPersonalizadosGeral = {}

    if (camposPersonalizadosSaude?.length) {
      // console.log("camposPersonalizadosSaude", camposPersonalizadosSaude)
      camposPersonalizadosSaude?.forEach((item) => {
        if (item?.selected) {
          camposPersonalizadosGeral[item?.name] = item?.selected
        }
      })
    }

    if (camposPersonalizadosOdonto?.length) {
      // console.log("camposPersonalizadosOdonto", camposPersonalizadosOdonto)
      camposPersonalizadosOdonto?.forEach((item) => {
        if (!camposPersonalizadosGeral[item?.name] && item?.selected) {
          camposPersonalizadosGeral[item?.name] = item?.selected
        }
      })
    }

    setCamposInclusaoPersonalizados(camposPersonalizadosGeral)

    // console.log("camposPersonalizadosGeral", camposPersonalizadosGeral)

    let camposInclusaoGeral = {};

    keysCampoInclusaoGeral?.forEach((chave) => {
      if (
        (camposInclusaoSaude && camposInclusaoSaude[chave]) ||
        (camposInclusaoOdonto && camposInclusaoOdonto[chave])) {
        return camposInclusaoGeral[chave] = true;
      }
      return camposInclusaoGeral[chave] = false;
    })

    // console.log("camposInclusaoGeral", camposInclusaoGeral)

    setCamposInclusao(camposInclusaoGeral);

  }, [
    contrato_estipulante_saude,
    contrato_estipulante_odonto,
    incluirSaude,
    incluirOdonto
  ])
  const beneficiarioAdicional = beneficiariosAdicionais[beneficiarioPage]
  const beneficiarioAdicionalCamposInclusao = beneficiarioAdicional?.camposInclusao;

  React.useEffect(() => {
    if (beneficiarioPage === 0) {
      determinaAbasDisponiveis({
        camposInclusao,
        setAbasDisponiveis,
        state,
      })

      determinaAbasCompletas({
        camposInclusao,
        state,
        incluirSaude,
        incluirOdonto,
        contratoEstipulanteSaudeInfo,
        contratoEstipulanteOdontoInfo,
        setAbasCompletas,
        camposInclusaoPersonalizados
      })

    } else if (beneficiarioPage !== 0) {

      determinaAbasDisponiveisDepAdicionais({
        camposInclusao: beneficiarioAdicionalCamposInclusao,
        setAbasDisponiveis,
        beneficiariosAdicionais,
        beneficiarioPage
      })
    }

  }, [camposInclusao, state, beneficiarioPage, beneficiariosAdicionais, beneficiarioAdicionalCamposInclusao])

  React.useEffect(() => {
    const newState = {};

    beneficiariosAdicionaisKeys.forEach((item) => {
      const beneficiarioAdicional = beneficiariosAdicionais[Number(item)];

      const abasCompletas = determinaAbasCompletasAdicionais({
        camposInclusao: beneficiarioAdicional?.camposInclusao,
        beneficiarioAdicional,
        beneficiarioPage: Number(item),
        setAbasCompletasBeneficiariosAdicionais,
        beneficiariosAdicionaisKeys
      })

      newState[item] = abasCompletas;
    })

    setAbasCompletasBeneficiariosAdicionais(newState)
  }, [camposInclusao, state, beneficiarioPage, beneficiariosAdicionais, beneficiariosAdicionaisKeys])

  const inputValueChangeHandler = ({ target }) => {
    setState((prevState) => ({
      ...prevState,
      [target.name]: target.value
    }))
  }

  const beneficiarioAdicionalInputChangeHandler = ({ target }) => {
    setBeneficiariosAdicionais((prevState) => ({
      ...prevState,
      [beneficiarioPage]: {
        ...prevState[beneficiarioPage],
        [target.name]: target.value
      }
    }))
  }

  const selectValueChangeHandler = (object) => {
    setState((prevState) => ({
      ...prevState,
      [object.name]: object.value
    }))
  }

  const beneficiarioAdicionalSelectChangeHandler = (object) => {
    setBeneficiariosAdicionais((prevState) => ({
      ...prevState,
      [beneficiarioPage]: {
        ...prevState[beneficiarioPage],
        [object.name]: object.value
      }
    }))
  }

  const selectContratoValueChangeHandler = (object) => {
    setState((prevState) => ({
      ...prevState,
      [object.name]: object.value
    }))

    if (object?.name === 'contrato_estipulante_saude') {
      setState((prevState) => ({
        ...prevState,
        tipo_plano_saude: ""
      }))

      if (tipoBeneficiario === "Titular") {

        if (beneficiariosAdicionaisKeys?.length) {
          setBeneficiariosAdicionais((prevState) => {
            const newState = prevState;

            beneficiariosAdicionaisKeys.map((key) => {
              const incluirSaude = newState[Number(key)]['incluirSaude']
              if (incluirSaude) {
                newState[Number(key)]['contrato_estipulante_saude'] = object.value;
                newState[Number(key)]['tipo_plano_saude'] = "";
              }
            })

            return newState
          })
        }
      }
    } else if (object?.name === 'contrato_estipulante_odonto') {
      setState((prevState) => ({
        ...prevState,
        tipo_plano_odonto: ""
      }))

      if (tipoBeneficiario === "Titular") {

        if (beneficiariosAdicionaisKeys?.length) {
          setBeneficiariosAdicionais((prevState) => {
            const newState = prevState;

            beneficiariosAdicionaisKeys.map((key) => {
              const incluirOdonto = newState[Number(key)]['incluirOdonto']
              if (incluirOdonto) {
                newState[Number(key)]['contrato_estipulante_odonto'] = object.value;
                newState[Number(key)]['tipo_plano_odonto'] = "";
              }
            })

            return newState
          })
        }
      }
    }

    if (object?.name === 'tipo_plano_saude') {
      if (tipoBeneficiario === "Titular") {

        if (beneficiariosAdicionaisKeys?.length) {
          setBeneficiariosAdicionais((prevState) => {
            const newState = prevState;

            beneficiariosAdicionaisKeys.map((key) => {
              const incluirSaude = newState[Number(key)]['incluirSaude']
              if (incluirSaude) {
                newState[Number(key)]['tipo_plano_saude'] = object.value;
              }
            })

            return newState
          })
        }
      }
    } else if (object?.name === 'tipo_plano_odonto') {
      if (tipoBeneficiario === "Titular") {

        if (beneficiariosAdicionaisKeys?.length) {
          setBeneficiariosAdicionais((prevState) => {
            const newState = prevState;

            beneficiariosAdicionaisKeys.map((key) => {
              const incluirOdonto = newState[Number(key)]['incluirOdonto']
              if (incluirOdonto) {
                newState[Number(key)]['tipo_plano_odonto'] = object.value;
              }
            })

            return newState
          })
        }
      }
    }

  }

  React.useEffect(() => {
    if (state['tipoBeneficiario'] === "Titular") {

      if (beneficiariosAdicionaisKeys?.length) {
        setBeneficiariosAdicionais((prevState) => {
          const newState = prevState;

          beneficiariosAdicionaisKeys.map((key) => {
            const incluirOdonto = newState[Number(key)]['incluirOdonto']
            if (incluirOdonto) {
              newState[Number(key)]['tipo_plano_odonto'] = state['tipo_plano_odonto'];
            }
          })

          return newState
        })
      }
    }
  }, [state['tipo_plano_odonto']])

  const selectBeneficiarioAdicionalContratoChangeHandler = (object) => {
    setBeneficiariosAdicionais((prevState) => ({
      ...prevState,
      [beneficiarioPage]: {
        ...prevState[beneficiarioPage],
        [object.name]: object.value
      }
    }))
  }

  const selectTipoBeneficiarioChangeHandler = (object) => {
    let previousValue;

    setState((prevState) => {
      previousValue = prevState[object.name]
      return {
        ...prevState,
        [object.name]: object.value
      }
    })

    const { tipoBeneficiario } = state;
    if (previousValue !== object.value) {
      if (tipoBeneficiario === 'Dependentes') {
        setIncluirSaude(false)
        setIncluirOdonto(false)

        setState((prevState) => ({
          ...prevState,
          contrato_estipulante_saude: undefined,
          contrato_estipulante_odonto: undefined,
          tipo_plano_saude: undefined,
          tipo_plano_odonto: undefined,
        }))
      } else {
        setNameFilter("")

        setIncluirSaude(false)
        setIncluirOdonto(false)

        setState((prevState) => ({
          ...prevState,
          contrato_estipulante_saude: undefined,
          contrato_estipulante_odonto: undefined,
          tipo_plano_saude: undefined,
          tipo_plano_odonto: undefined,
          titular: undefined
        }))
      }

      if (beneficiariosAdicionaisKeys?.length) {
        setBeneficiariosAdicionais((prevState) => {
          const newState = prevState;

          beneficiariosAdicionaisKeys.map((key) => {
            newState[Number(key)]['incluirSaude'] = false;
            newState[Number(key)]['incluirOdonto'] = false;
            newState[Number(key)]['contrato_estipulante_saude'] = undefined;
            newState[Number(key)]['contrato_estipulante_odonto'] = undefined;
            newState[Number(key)]['tipo_plano_saude'] = undefined;
            newState[Number(key)]['tipo_plano_odonto'] = undefined;
            newState[Number(key)]['camposInclusao'] = {};
            newState[Number(key)]['camposInclusaoOdonto'] = {};
            newState[Number(key)]['camposInclusaoSaude'] = {};
          })

          return newState
        })
      }
    }
  }

  const selectEstipulanteChangeHandler = (object) => {
    let previousValue;

    setState((prevState) => {
      previousValue = prevState[object.name]
      return {
        ...prevState,
        [object.name]: object.value
      }
    })

    if (previousValue && previousValue !== object.value) {
      if (tipoBeneficiario === 'Dependentes') {
        setIncluirSaude(false)
        setIncluirOdonto(false)

        setState((prevState) => ({
          ...prevState,
          contrato_estipulante_saude: undefined,
          contrato_estipulante_odonto: undefined,
          tipo_plano_saude: undefined,
          tipo_plano_odonto: undefined,
          titular: undefined,
        }))

        setNameFilter("")
      }

      if (beneficiariosAdicionaisKeys?.length) {
        setBeneficiariosAdicionais((prevState) => {
          const newState = prevState;

          beneficiariosAdicionaisKeys.map((key) => {
            newState[Number(key)]['incluirSaude'] = false;
            newState[Number(key)]['incluirOdonto'] = false;
            newState[Number(key)]['contrato_estipulante_saude'] = undefined;
            newState[Number(key)]['contrato_estipulante_odonto'] = undefined;
            newState[Number(key)]['tipo_plano_saude'] = undefined;
            newState[Number(key)]['tipo_plano_odonto'] = undefined;
            newState[Number(key)]['camposInclusao'] = {};
            newState[Number(key)]['camposInclusaoOdonto'] = {};
            newState[Number(key)]['camposInclusaoSaude'] = {};
          })

          return newState
        })
      }
    }
  }

  const handleFile = (target) => {
    // console.log("target", target)
    if (target.files?.length === 0 || target.files === null) {
      return;
    }
    if (target.files.length > 1) {
      posthog?.capture('Adicionou um documento à criação do Ticket')
      const newFiles = [];
      Array.from(target.files).forEach((file) => {
        newFiles.push(file);
      });

      setFiles((prevState) => ([...prevState, ...newFiles]));
    } else {
      setFiles((prevState) => ([...prevState, target.files[0]]));
    }
  };

  const handleFileAdicionais = (target) => {
    // console.log("target", target)
    if (target.files?.length === 0 || target.files === null) {
      return;
    }
    if (target.files.length > 1) {
      posthog?.capture('Adicionou um documento à criação do Ticket')
      const newFiles = [];
      Array.from(target.files).forEach((file) => {
        newFiles.push(file);
      });

      setFilesAdicionais((prevState) => {
        const previousBeneficiarioFiles = prevState[beneficiarioPage];

        return {
          ...prevState,
          [beneficiarioPage]: [
            ...previousBeneficiarioFiles,
            ...newFiles
          ]
        }
      });
    } else {
      setFilesAdicionais((prevState) => {
        const previousBeneficiarioFiles = prevState[beneficiarioPage];
        return {
          ...prevState,
          [beneficiarioPage]: [
            ...previousBeneficiarioFiles,
            target.files[0]
          ]
        }
      });
    }
  }

  const deleteFile = (index) => {
    const newArray = files;
    newArray.splice(index, 1);
    if (newArray.length === 0) {
      setFiles([])
    } else {
      setFiles([...newArray]);
    }
  };

  const deleteFileAdicionais = (index) => {
    setFilesAdicionais((prevState) => {
      const newArray = prevState[beneficiarioPage]
      newArray.splice(index, 1)

      if (newArray.length === 0) {
        return {
          ...prevState,
          [beneficiarioPage]: []
        }
      } else {
        return {
          ...prevState,
          [beneficiarioPage]: [
            ...newArray
          ]
        }
      }
    })
  }

  const inputFileChangeHandler = ({ target }) => {
    if (!target?.files) {
      setState((prevState) => ({
        ...prevState,
        [target.name]: undefined
      }))
    }
    if (target?.files) {
      setState((prevState) => ({
        ...prevState,
        [target.name]: target.files[0]
      }))
    }
  }

  const checkboxChangeHandler = ({ target }) => {
    setState((prevState) => ({
      ...prevState,
      [target.name]: target.checked
    }))
  }

  const beneficiarioAdicionalCheckboxChangeHandler = ({ target }) => {
    setBeneficiariosAdicionais((prevState) => ({
      ...prevState,
      [beneficiarioPage]: {
        ...prevState[beneficiarioPage],
        [target.name]: target.checked,
      }
    }))
  }

  const beneficiarioAdicionalInputFileChangeHandler = ({ target }) => {
    if (!target?.files) {
      setBeneficiariosAdicionais((prevState) => ({
        ...prevState,
        [beneficiarioPage]: {
          ...prevState[beneficiarioPage],
          [target.name]: undefined,
        }
      }))
    }
    if (target?.files) {
      setBeneficiariosAdicionais((prevState) => ({
        ...prevState,
        [beneficiarioPage]: {
          ...prevState[beneficiarioPage],
          [target.name]: target.files[0]
        }
      }))
    }
  }

  const abasObj = {
    0: 'estipulante',
    1: 'tipo beneficiario',
    2: 'escolha titular',
    3: 'planos',
    4: 'dados basicos',
    5: 'endereco',
    6: 'contato',
    7: 'vinculo',
    8: 'imc',
    9: 'dados bancarios',
    10: 'scan documentos',
    11: 'ficha proposta',
  }

  React.useEffect(() => {

    setAbaSelected(abasObj[count])
  }, [count])

  const voltarAbaClickHandler = () => {
    posthog?.capture('Clicou em "Voltar" aba em DrawerAdicionarBeneficiario')
    setCount((prevState) => {
      let abaCount;

      if (prevState > 0) {
        const previousAba = findPreviousAbaDisponivel(prevState, abasObj, abasDisponiveis, beneficiarioPage)
        return previousAba;
      }

      abaCount = prevState;
      return abaCount;
    })
  }

  const proximaAbaClickHandler = () => {
    posthog?.capture('Clicou em "Próxima" aba em DrawerAdicionarBeneficiario')
    setCount((prevState) => {
      let abaCount;
      const quantidadeMaxAbas = Object.keys(abasObj).length;
      if (prevState + 1 < quantidadeMaxAbas) {
        const nextAba = findNextAbaDisponivel(prevState, abasObj, abasDisponiveis, beneficiarioPage)
        if (nextAba) {
          return nextAba
        }
        return prevState
      }
      abaCount = prevState;
      return abaCount;
    })
  }

  const renderAbaSelecionada = () => {
    switch (abaSelected) {
      case 'tipo beneficiario':
        return (
          <AbaTipoBeneficiario
            values={state}
            setState={setState}
            setInputValue={inputValueChangeHandler}
            setSelectValue={selectTipoBeneficiarioChangeHandler}
            setInputFile={inputFileChangeHandler}
            setNameFilter={setNameFilter}
            setIncluirSaude={setIncluirSaude}
            setIncluirOdonto={setIncluirOdonto}
            camposInclusao={camposInclusao}
            contratoEstipulanteSaudeInfo={contratoEstipulanteSaudeInfo}
            contratoEstipulanteOdontoInfo={contratoEstipulanteOdontoInfo}
          />
        )

      case 'escolha titular':
        return (
          <AbaSelecioneTitular
            state={state}
            setState={setState}
            setIncluirSaude={setIncluirSaude}
            setIncluirOdonto={setIncluirOdonto}
            beneficiariosFilter={beneficiariosFilter}
            titularNameFilter={nameFilter}
            setTitularNameFilter={inputTitularNameChangeHandler}
            setInputValue={inputValueChangeHandler}
            setBeneficiariosAdicionais={setBeneficiariosAdicionais}
            setSelectValue={selectValueChangeHandler}
            contratosEstipulante={contratosEstipulante}
            incluirSaude={incluirSaude}
            incluirOdonto={incluirOdonto}
          />
        )

      case 'planos':
        if (beneficiarioPage === 0) {
          return (
            <AbaPlanos
              beneficiariosAdicionais={beneficiariosAdicionais}
              values={state}
              setValues={setState}
              setBeneficiariosAdicionais={setBeneficiariosAdicionais}
              setInputValue={inputValueChangeHandler}
              setSelectValue={selectContratoValueChangeHandler}
              contratosEstipulante={contratosEstipulante}
              incluirSaude={incluirSaude}
              setIncluirSaude={setIncluirSaude}
              incluirOdonto={incluirOdonto}
              setIncluirOdonto={setIncluirOdonto}
            />
          )
        }
        return (
          beneficiariosAdicionais[beneficiarioPage] ? (
            <AbaPlanosAdicionais
              beneficiarioPage={beneficiarioPage}
              values={state}
              setBeneficiariosAdicionais={setBeneficiariosAdicionais}
              beneficiariosAdicionais={beneficiariosAdicionais}
              setInputValue={inputValueChangeHandler}
              setSelectValue={selectBeneficiarioAdicionalContratoChangeHandler}
              contratosEstipulante={contratosEstipulante}
              incluirSaude={incluirSaude}
              setIncluirSaude={setIncluirSaude}
              incluirOdonto={incluirOdonto}
              setIncluirOdonto={setIncluirOdonto}
            />
          ) : <div></div>
        )

      case 'dados basicos':
        if (beneficiarioPage === 0) {
          return (
            <AbaDadosBasicos
              values={state}
              setInputValue={inputValueChangeHandler}
              setSelectValue={selectValueChangeHandler}
              camposInclusao={camposInclusao}
            />
          )
        }

        return (
          <AbaDadosBasicosAdicionais
            beneficiarioPage={beneficiarioPage}
            values={state}
            beneficiariosAdicionais={beneficiariosAdicionais}
            setInputValue={beneficiarioAdicionalInputChangeHandler}
            setSelectValue={beneficiarioAdicionalSelectChangeHandler}
            camposInclusao={camposInclusao}
          />
        )

      case 'endereco':

        return (
          <AbaDadosEndereco
            values={state}
            setInputValue={inputValueChangeHandler}
            setSelectValue={selectValueChangeHandler}
            camposInclusao={camposInclusao}
          />
        )

      case 'contato':

        return (
          <AbaDadosContato
            values={state}
            setInputValue={inputValueChangeHandler}
            setSelectValue={selectValueChangeHandler}
            camposInclusao={camposInclusao}
          />
        )

      case 'vinculo':

        return (
          <AbaDadosVinculo
            values={state}
            setInputValue={inputValueChangeHandler}
            setSelectValue={selectValueChangeHandler}
            camposInclusao={camposInclusao}
          />
        )

      case 'imc':

        if (beneficiarioPage === 0) {
          return (
            <AbaDadosImc
              values={state}
              setInputValue={inputValueChangeHandler}
              setSelectValue={selectValueChangeHandler}
              camposInclusao={camposInclusao}
            />
          )
        }

        return (
          <DadosImcAdicionais
            beneficiarioPage={beneficiarioPage}
            values={state}
            beneficiariosAdicionais={beneficiariosAdicionais}
            setInputValue={beneficiarioAdicionalInputChangeHandler}
            setSelectValue={beneficiarioAdicionalSelectChangeHandler}
            camposInclusao={camposInclusao}
          />
        )

      case 'dados bancarios':

        return (
          <AbaDadosBancarios
            values={state}
            setInputValue={inputValueChangeHandler}
            setSelectValue={selectValueChangeHandler}
            camposInclusao={camposInclusao}
          />
        )

      case 'scan documentos':
        if (beneficiarioPage === 0) {
          return (
            <AbaScanDocumentos
              values={state}
              setInputValue={inputValueChangeHandler}
              setSelectValue={selectValueChangeHandler}
              setInputFile={inputFileChangeHandler}
              setCheckboxValue={checkboxChangeHandler}
              camposInclusao={camposInclusao}
              camposInclusaoPersonalizados={camposInclusaoPersonalizados}
              files={files}
              handleFile={handleFile}
              deleteFile={deleteFile}
            />
          )
        }

        return (
          <ScanDocumentosAdicionais
            beneficiarioPage={beneficiarioPage}
            values={state}
            beneficiariosAdicionais={beneficiariosAdicionais}
            setInputValue={beneficiarioAdicionalInputChangeHandler}
            setSelectValue={beneficiarioAdicionalSelectChangeHandler}
            setInputFile={beneficiarioAdicionalInputFileChangeHandler}
            camposInclusao={camposInclusao}
            setCheckboxValue={beneficiarioAdicionalCheckboxChangeHandler}
            filesAdicionais={filesAdicionais}
            handleFileAdicionais={handleFileAdicionais}
            deleteFileAdicionais={deleteFileAdicionais}
          />
        )

      case 'ficha proposta':
        return (
          <AbaFichaProposta
            values={state}
            setInputValue={inputValueChangeHandler}
            setSelectValue={selectValueChangeHandler}
            setInputFile={inputFileChangeHandler}
            camposInclusao={camposInclusao}
            incluirSaude={incluirSaude}
            incluirOdonto={incluirOdonto}
            contratoEstipulanteSaudeInfo={contratoEstipulanteSaudeInfo}
            contratoEstipulanteOdontoInfo={contratoEstipulanteOdontoInfo}
          />
        )

      default:
        return (
          <AbaEstipulante
            values={state}
            setState={setState}
            estipulanteAndSubs={estipulanteAndSubs}
            setInputValue={inputValueChangeHandler}
            setSelectValue={selectEstipulanteChangeHandler}
            setInputFile={inputFileChangeHandler}
            setNameFilter={setNameFilter}
            setIncluirSaude={setIncluirSaude}
            setIncluirOdonto={setIncluirOdonto}
            camposInclusao={camposInclusao}
            contratoEstipulanteSaudeInfo={contratoEstipulanteSaudeInfo}
            contratoEstipulanteOdontoInfo={contratoEstipulanteOdontoInfo}
          />
        )
    }
  }

  const renderTabOptions = () => {
    return (
      <TabOptions
        closeDrawer={closeDrawer}
        abaSelected={abaSelected}
        setCount={setCount}
        abasDisponiveis={abasDisponiveis}
        setBeneficiariosAdicionais={setBeneficiariosAdicionais}
        abasCompletas={abasCompletas}
      />
    )
  }

  const renderTabOptionsBeneficiarioAdicional = () => {
    return (
      <TabOptionsAdicionais
        closeDrawer={closeDrawer}
        abaSelected={abaSelected}
        setCount={setCount}
        abasDisponiveis={abasDisponiveis}
        setBeneficiariosAdicionais={setBeneficiariosAdicionais}
        beneficiarioPage={beneficiarioPage}
        abasCompletasBeneficiariosAdicionais={abasCompletasBeneficiariosAdicionais}
      />
    )
  }

  const inputTitularNameChangeHandler = ({ target }) => {
    setNameFilter(target.value)
  }

  React.useEffect(() => {
    getBeneficiarios(user.mainEstipulante)
  }, [])

  React.useEffect(() => {

    const beneficiariosDeEstipulante = beneficiarios?.filter((item) => {
      if (estipulante_id) {
        if (estipulante_id === item?.estipulante_id) {
          return item;
        }
        return false
      }
      return item
    })
    const beneficiariosFiltrados = beneficiariosDeEstipulante.filter((item) => {
      if ((item?.nome.toLowerCase()).includes(nameFilter.toLowerCase())) {
        return item
      }
      return false
    })
    setBeneficiariosFilter(beneficiariosFiltrados);
  }, [nameFilter, beneficiarios, estipulante_id])

  async function getBeneficiarios(estipulante_id) {
    api
      .get("/rh/estipulante-sub/beneficiarios", {
        headers: {
          id: estipulante_id,
          authorization: `Bearer ${user.token}`,
        },
      })
      .then((resp) => {
        const { beneficiarios, subEstipulantes } = resp.data;
        let allBeneficiarios = [...beneficiarios];
        if (subEstipulantes.length) {
          subEstipulantes.forEach((subEstipulante) => {
            if (subEstipulante.beneficiarios.length) {
              allBeneficiarios = [
                ...allBeneficiarios,
                ...subEstipulante.beneficiarios,
              ]
            }

          })
        }

        const beneficiariosTitularesAtivos = allBeneficiarios
          .filter((item) => item?.status === "Ativo" && item?.tipo === "Titular")

        setBeneficiarios(beneficiariosTitularesAtivos);
      })
  }


  React.useEffect(() => {
    const dependentesKeys = Object.keys(beneficiariosAdicionais);

    setBeneficiariosAdicionaisKeys(dependentesKeys);


  }, [beneficiariosAdicionais])

  const renderPaginationBeneficiariosAdicionais = React.useCallback(() => {

    return beneficiariosAdicionaisKeys?.map((item) => beneficiariosAdicionais[Number(item)] &&
      (
        <div className="dependente_item_wrapper">
          <div
            className="icon-container-remove"
            onClick={() => {
              posthog?.capture('Clicou p/ remover "dependente adicional"')
              removeDependenteClickHandler(item)
            }}
          >
            <HiTrash />
          </div>
          <div
            className={`pagination-item-container ${beneficiarioPage === Number(item) ? "selected" : ""}`}
            onClick={() => {
              posthog?.capture('Clicou em botão de "dependente adicional"')
              setBeneficiarioPage(Number(item))
              setCount(3)
            }}
          >
            <span
              className={`pagination-item`}
            >
              {beneficiariosAdicionais[item]?.nome}
            </span>
          </div>
        </div>
      ))
  }, [beneficiariosAdicionais, beneficiarioPage, beneficiariosAdicionaisKeys])

  const allFieldsFullfilled = () => {

    const principalFullfilled = todosCamposPreenchidos({
      abasDisponiveis,
      abasCompletas
    })

    const dependentesFullfilled = todosCamposPreenchidosAdicionais({
      abasCompletasBeneficiariosAdicionais,
      beneficiariosAdicionaisKeys
    })

    if (principalFullfilled && dependentesFullfilled) {
      return true
    }

    return false
  }

  const renderNavButtons = () => {
    return (
      <NavButtonsContainer>
        <div className="button-row">
          <Button
            variant="contained"
            className="botao-aba voltar"
            onClick={voltarAbaClickHandler}
          >
            Voltar
          </Button>
          {
            findNextAbaDisponivel(count, abasObj, abasDisponiveis, beneficiarioPage) ? (
              <Button
                variant="contained"
                className="botao-aba proximo"
                onClick={proximaAbaClickHandler}
              >
                Próximo
                <AiFillCaretRight
                  className="icone-caret-direita"
                  size={15 * height / 745}
                />
              </Button>
            ) : ""


          }

        </div>
        <div className="caroussel-row">
          <div className="pagination_item_wrapper">
            <div
              onClick={() => {
                posthog?.capture('Clicou em botão "principal"')
                setBeneficiarioPage(0)
                setCount(() => {
                  if (state['tipoBeneficiario'] === "Titular") {
                    // Aba de seleção de plano(s)
                    return 3
                  }
                  if (state['tipoBeneficiario'] === "Dependentes" && state['titular']) {
                    return 3
                  } else if (state['tipoBeneficiario'] === "Dependentes" && !state['titular']) {
                    return 2
                  }
                  // Aba de seleção de tipoBeneficiário
                  return 1
                })
              }}
              className={`pagination-item-container ${beneficiarioPage === 0 ? "selected" : ""}`}
            >
              <span
                className={`pagination-item`}
              >
                Principal
              </span>
            </div>
          </div>
          {
            renderPaginationBeneficiariosAdicionais()
          }

          <div className="pagination_item_wrapper">
            <div

              className={
                `pagination-item-container ${allFieldsFullfilled() ? "finalizar" : "disabled-container"}`
              }
              onClick={
                allFieldsFullfilled() ? (
                  () => {
                    setOpenConfirmationPopper(true)
                  }
                ) : (
                  () => { }
                )
              }
            >
              <span
                className={
                  `pagination-item ${allFieldsFullfilled() ? "finalizar" : "disabled"}`
                }

              >
                Enviar
              </span>
            </div>
          </div>

        </div>
      </NavButtonsContainer>
    )
  }

  const adicionarDependenteClickHandler = () => {
    posthog?.capture('Clicou em adicionar dependente')
    setBeneficiariosAdicionais((prevState) => {
      const prevStateKeys = Object.keys(prevState)
      const lastPrevStateKey = prevStateKeys.pop()

      const nextIndex = lastPrevStateKey ? Number(lastPrevStateKey) + 1 : 1;

      setAbasCompletasBeneficiariosAdicionais((prevState) => {
        return {
          ...prevState,
          [nextIndex]: {

          }
        }
      })

      setFilesAdicionais((prevState) => {
        return {
          ...prevState,
          [nextIndex]: []
        }
      })

      return {
        ...prevState,
        [nextIndex]: {
          tipoBeneficiario: "Dependentes",
          incluirSaude: false,
          incluirOdonto: false,
          camposInclusao: undefined,
          nome: `Dependente ${nextIndex}`
        }
      }
    })
  }

  const removeDependenteClickHandler = (item) => {

    // Mudar essa função.. Realizar lógica de outra maneira
    setBeneficiariosAdicionais((prevState) => {
      const keys = Object.keys(prevState)
      const newState = {}
      keys.forEach((key) => {
        if (key !== item) {
          newState[Number(key)] = prevState[Number(key)]
        }
      })
      if (beneficiarioPage === Number(item)) {
        setBeneficiarioPage(0)
        setCount(() => {
          if (state['tipoBeneficiario'] === "Titular") {
            // Aba de seleção de plano(s)
            return 3
          }
          if (state['tipoBeneficiario'] === "Dependentes" && state['titular']) {
            return 3
          } else if (!state['titular']) {
            return 2
          }
          // Aba de seleção de tipoBeneficiário
          return 1
        })
      }
      return newState;
    })

    setFilesAdicionais((prevState) => {
      const keys = Object.keys(prevState)
      const newState = {}
      keys.forEach((key) => {
        if (key !== item) {
          newState[Number(key)] = prevState[Number(key)]
        }
      })

      return newState
    })
  }

  const renderAdicionarDependente = () => {
    return (
      <div
        className={"aba-option"}
      >

        <div
          className="icon-container"
          onClick={adicionarDependenteClickHandler}
        >
          <AiOutlinePlus size={14 * height / 745} />
          <span>Dependente</span>
        </div>
      </div>
    )
  }


  const renderBeneficiarioAdicionadoPrincipal = () => {
    return (
      <>
        <MainContent>
          <div className="content-title">
            <div>
              <span>Inclusão de novos beneficiários</span>
            </div>
            <div>
              {
                renderAdicionarDependente()
              }
            </div>
          </div>
          {
            renderAbaSelecionada()
          }
          {
            renderNavButtons()
          }
        </MainContent>
        {
          renderTabOptions()
        }
      </>
    )
  }

  const renderDependenteAdicionalContent = () => {
    return (
      <>
        <MainContent>
          <div className="content-title">
            <div>
              <span>Inclusão de novos beneficiários</span>
            </div>
            <div>
              {
                renderAdicionarDependente()
              }
            </div>
          </div>
          {
            renderAbaSelecionada()
          }
          {
            renderNavButtons()
          }
        </MainContent>
        {
          renderTabOptionsBeneficiarioAdicional()
        }
      </>
    )
  }

  return (
    <DrawerAdicionarBeneficiarioWrapper>
      <SideStripe />
      <Content>
        {
          beneficiarioPage === 0 ? (

            renderBeneficiarioAdicionadoPrincipal()

          ) : (
            renderDependenteAdicionalContent()
          )
        }
      </Content>
      <Dialog
        open={openConfirmationPopper}
        onClose={() => setOpenConfirmationPopper(false)}

      >
        <DialogConfirmRequest
          setOpenConfirmationPopper={setOpenConfirmationPopper}
          setEnvioInclusao={setEnvioInclusao}
          loading={loading}
          requestCompleted={requestCompleted}
          resetBeneficiaryInfo={resetBeneficiaryInfo}
          closeDrawer={closeDrawer}
        />
      </Dialog>
    </DrawerAdicionarBeneficiarioWrapper>

  )
}

export default DrawerAdicionarBeneficiarioRh;
