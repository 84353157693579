import React from 'react'
import { Header } from './styles'

const HeaderQuestionario = ({estipulante, corretora}) => {
  return (
    <Header>

      <div>
        <div className="logo logoEstipulante"><span><img className="" src={estipulante?.logo} alt="Logo Coinsure" /></span></div>
      </div>
    
    </Header>
  )
}

export default HeaderQuestionario
