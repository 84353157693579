import styled from 'styled-components';
import colors from 'styles/colors';

export const AbaDadosBancarioWrapper = styled.div`

  @media (max-height: 580px) {
    display: grid;
    grid-template-rows: 17.342px 1fr 26.68px;
    row-gap: 30px;
  
    .content-title {
      span {
        font-size: 12px;
        color: ${colors.rhBlue};
        font-family: Avenir Next GEO W05 Heavy;
      }
    }
  
    .aba-container {
      display: grid;
      grid-template-rows: 17.342px 1fr;
      row-gap: 40px;
  
      .aba-title-container {
        span {
          font-size: 12px;
          font-family: Avenir Next GEO W05 Bold;
          color: ${colors.genoaDarkBlue};
        }
      }
  
      .aba-input-container {
        width: 100%;
        height: 100%;
  
        display: flex;
        flex-direction: column;
        row-gap: 20px;
  
        .aba-input-row-imc {
          display: grid;
          width: max-content;
          grid-template-columns: 66.67px 73.37px 33.35px 133.34px;
          column-gap: 33.35px;
        }
  
      }
    }
  }

  @media (min-height: 580px) and (max-height: 650px) {
    display: grid;
    grid-template-rows: 20.8px 1fr 32px;
    row-gap: 36px;

    .content-title {
      span {
        font-size: 14.4px;
        color: ${colors.rhBlue};
        font-family: Avenir Next GEO W05 Heavy;
      }
    }

    .aba-container {
      display: grid;
      grid-template-rows: 20.8px 1fr;
      row-gap: 48px;

      .aba-title-container {
        span {
          font-size: 14.4px;
          font-family: Avenir Next GEO W05 Bold;
          color: ${colors.genoaDarkBlue};
        }
      }

      .aba-input-container {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        row-gap: 20px;

        .aba-input-row-imc {
          display: grid;
          width: max-content;
          grid-template-columns: 80px 88px 40px 160px;
          column-gap: 40px;
        }

      }
    }
  }

  @media (min-height: 650px) and (max-height: 720px) {
    display: grid;
    grid-template-rows: 23.4px 1fr 36px;
    row-gap: 40.5px;
  
    .content-title {
      span {
        font-size: 16.2px;
        color: ${colors.rhBlue};
        font-family: Avenir Next GEO W05 Heavy;
      }
    }
  
    .aba-container {
      display: grid;
      grid-template-rows: 23.4px 1fr;
      row-gap: 54px;
  
      .aba-title-container {
        span {
          font-size: 16.2px;
          font-family: Avenir Next GEO W05 Bold;
          color: ${colors.genoaDarkBlue};
        }
      }
  
      .aba-input-container {
        width: 100%;
        height: 100%;
  
        display: flex;
        flex-direction: column;
        row-gap: 22.5px;
  
        .aba-input-row-imc {
          display: grid;
          width: max-content;
          grid-template-columns: 90px 99px 45px 180px;
          column-gap: 45px;
        }
  
      }
    }
  }

  @media (min-height: 720px) {
    display: grid;
    grid-template-rows: 26px 1fr 40px;
    row-gap: 45px;
  
    .content-title {
      span {
        font-size: 18px;
        color: ${colors.rhBlue};
        font-family: Avenir Next GEO W05 Heavy;
      }
    }
  
    .aba-container {
      display: grid;
      grid-template-rows: 26px 1fr;
      row-gap: 60px;
  
      .aba-title-container {
        span {
          font-size: 18px;
          font-family: Avenir Next GEO W05 Bold;
          color: ${colors.genoaDarkBlue};
        }
      }
  
      .aba-input-container {
        width: 100%;
        height: 100%;
  
        display: flex;
        flex-direction: column;
        row-gap: 25px;
  
        .aba-input-row-imc {
          display: grid;
          width: max-content;
          grid-template-columns: 100px 110px 50px 200px;
          column-gap: 50px;
        }
  
      }
    }
  }
`