import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Wraper, Footer } from './styles';
import HeaderQuestionario from 'apps/lp/components/Header-Questionario';
import ProgramaBeneficios from 'apps/lp/pages/Questionario/components/FormStages/ProgramaBeneficios';
import InfosTitular from 'apps/lp/pages/Questionario/components/FormStages/InfosTitular';
import VinculoEmpresa from 'apps/lp/pages/Questionario/components/FormStages/VinculoEmpresa';
import Dependentes from 'apps/lp/pages/Questionario/components/FormStages/Dependentes';
import EscolhaPlano from 'apps/lp/pages/Questionario/components/FormStages/EscolhaPlano';
import AddDependentes from 'apps/lp/pages/Questionario/components/FormStages/AddDependentes';
import AddPlano from 'apps/lp/pages/Questionario/components/FormStages/AddPlanos';
import logo_zeca_colorido from 'assets/imgs/svg/logo_zeca_colorido.svg';
import PerguntasRespostas from 'apps/lp/components/PerguntasRespostas';
import { HiOutlineLightBulb, HiOutlineMail } from 'react-icons/hi';
import {
  BiHomeSmile,
  BiCar,
  BiHeart,
  BiTab,
  BiMinus,
  BiInfoCircle
} from 'react-icons/bi';
import { MdAirplanemodeActive } from 'react-icons/md';
import { FaWhatsapp } from 'react-icons/fa';
import { RiFileEditLine } from 'react-icons/ri';
import { useEmitTicket } from 'contexts/emitTicket';
import api from 'services/api';

const seguros = {
  seguroViagem: {
    name: 'Seguro Viagem',
    icon: <MdAirplanemodeActive />
  },
  seguroTablet: {
    name: 'Seguro de Tablets',
    icon: <BiTab />
  },
  outros: {
    name: 'Outros',
    icon: <BiMinus />
  },
  seguroAuto: {
    name: 'Seguro Auto',
    icon: <BiCar />
  },
  seguroResidencial: {
    name: 'Seguro Residencial',
    icon: <BiHomeSmile />
  },
  seguroVida: {
    name: 'Seguro de Vida',
    icon: <BiHeart />
  }
};

const Questionario = () => {
  const { externalFormStage } = useEmitTicket();
  const { stage } = externalFormStage;
  const { corretoraId, estipulanteId, contratoId } = useParams();

  const [data, setData] = useState({});
  const [vinculo, setVinculo] = useState([]);
  const [formExt, setFormExt] = useState();
  const [fileDependents, setFileDependents] = useState([]);
  const [perguntas, setPerguntas] = useState();
  const [produtos, setProdutos] = useState();

  const getData = () => {
    api
      .get(`/beneficiario/ticket/${corretoraId}/${estipulanteId}/${contratoId}`)
      .then((resp) => {
        setFormExt(resp.data.formExt);
        setData(resp.data);
        setVinculo(resp.data.vinculos);
        setProdutos(resp.data.produtosCorretora);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getPergunta = () => {
    const headers = {
      contrato: contratoId
      // Authorization: `Bearer ${user.token}`
    };
    api
      .get('/corretor/perguntas', { headers: headers })
      .then((resp) => {
        setPerguntas(resp.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };

  const renderProduto = (produto) => {
    return produto === 'Plano de Saúde'
      ? 'Seguro Saúde'
      : 'Seguro Odontológico';
  };

  useEffect(() => {
    getData();
    getPergunta();
  }, []);

  return (
    <>
      <HeaderQuestionario estipulante={data?.estipulante} />
      <Wraper>
        <main>
          <section className="forms">
            <div className="title">
              <h1>
                <RiFileEditLine />
                Formulário de {data.produto && renderProduto(data.produto)}
              </h1>
            </div>
            <form>
              <div className="stages">
                {stage === 'programaBeneficios' && (
                  <ProgramaBeneficios
                    formExt={formExt}
                    produto={data.produto}
                  />
                )}
                {stage === 'InfosTitular' && <InfosTitular />}
                {stage === 'VinculoEmpresa' && (
                  <VinculoEmpresa data={vinculo} />
                )}
                {stage === 'Dependentes' && (
                  <Dependentes setFileDependents={setFileDependents} />
                )}
                {stage === 'AddDependentes' && (
                  <AddDependentes formExt={formExt} />
                )}
                {stage === 'AddPlano' && <AddPlano formExt={formExt} />}
                {stage === 'EscolhaPlano' && (
                  <EscolhaPlano
                    formExt={formExt}
                    fileDependents={fileDependents}
                  />
                )}
              </div>
            </form>
          </section>
          <section className="infos border">
            <div className="title">
              <h2>
                <HiOutlineLightBulb /> Perguntas e Respostas
              </h2>
              <PerguntasRespostas perguntas={perguntas} />
            </div>
          </section>
          <section className="infos">
            <div className="title-logo">
              <div className="title">
                <h2>
                  <BiInfoCircle />
                  Você sabia...
                </h2>
              </div>
              <div className="logo">
                <img src={data.corretora?.logo} alt="logo corretora" />
              </div>
            </div>

            <div className="content">
              <p>
                A {data.corretora?.razao_social} é a corretora que atende a{' '}
                {data.estipulante?.razao_social} no seguro saúde. Ela trabalha
                com outros tipos de cobertura e caso precise é só entrar em
                contato conosco.
              </p>
              <ul className="listSeguros">
                {produtos &&
                  produtos.map((item, index) => {
                    return (
                      <li key={index}>
                        {seguros[item.produto].icon}{' '}
                        {seguros[item.produto].name}
                      </li>
                    );
                  })}
              </ul>
              <div className="contatos">
                {data.contato?.whatsapp ? (
                  <div>
                    {' '}
                    <FaWhatsapp />{' '}
                    <p>
                      {data.contato.whatsapp} (
                      <a
                        href={`https://web.whatsapp.com/send?phone=55${data.contato?.whatsapp
                          .replace(' ', '')
                          .replace('-', '')}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Whatsapp
                      </a>
                      )
                    </p>
                  </div>
                ) : null}
                {data.contato?.email ? (
                  <div>
                    {' '}
                    <HiOutlineMail /> <p>{data.contato.email}</p>{' '}
                  </div>
                ) : null}
              </div>
            </div>
          </section>
        </main>
      </Wraper>
      <Footer>
        <div className="logo">
          <img src={logo_zeca_colorido} alt="Logo Zeca" />
        </div>
      </Footer>
    </>
  );
};

export default Questionario;
