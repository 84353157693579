/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { FaUserPlus } from 'react-icons/fa';
import { WrapperAddBeneficiary } from './styles';
import services from 'apps/broker/services';
import FormAddBeneficiaries from './FormAddBeneficiaries';

let COUNT = 0;

const DrawerAddBeneficiaries = () => {
  const [estipulantes, setEstipulantes] = useState([]);
  const [allSubEstipulantes, setAllSubEstipulantes] = useState([]);
  const [selectedEstipulante, setSelectedEstipulante] = useState();
  const [selectedSubEstipulante, setSelectedSubEstipulante] = useState();
  const [selectedContract, setSelectedContract] = useState(null);
  const [triggerGetSubs, setTriggerGetSubs] = useState(false);
  const [typeUser, setTypeUser] = useState('');
  // Dados do Beneficiário (Titular || Dependente):
  const [beneficiaryName, setBeneficiaryName] = useState('');
  const [beneficiaryCPF, setBeneficiaryCPF] = useState('');
  const [beneficiaryBirthDate, setBeneficiaryBirthDate] = useState();
  const [bondDate, setBondDate] = useState();
  const [dateStartPlan, setDateStartPlan] = useState();
  const [nCarteirinha, setNCarteirinha] = useState();
  const [beneficiaryBondWithHolder, setBeneficiaryBondWithHolder] =
    useState('');
  const [infosTitularOfDependente, setInfosTitularOfDependente] = useState({});
  const [nomeDependentesDoTitular, setNomeDependentesDoTitular] = useState({});
  const [cpfDependentesDoTitular, setCpfDependentesDoTitular] = useState({});
  const [dataNascimentoAllDependentes, setDataNascimentoAllDependentes] =
    useState({});
  const [vinculoDependenteWithTitular, setVinculoDependenteWithTitular] =
    useState({});
  const [dataVinculoAllDependentes, setDataVinculoAllDependentes] = useState(
    {}
  );
  const [nCarteirinhaAllDependentes, setNCarteirinhaAllDependentes] = useState(
    {}
  );
  const [dateStartPlanAllDependentes, setDateStartPlanAllDependentes] =
    useState({});
  const [allHolders, setAllHolders] = useState([]);
  const [triggerFilterInfos, setTriggerFilterInfos] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [messageSuccess, setMessageSuccess] = useState('');
  const [allowSendInfos, setAllowSendInfos] = useState(false);

  const getEstipulantes = async () => {
    const response = await services.policyOwner.getAll();
    setEstipulantes(reduceEstipulantes2(response.data));
  };

  const reduceEstipulantes2 = (data) => {
    const arr2 = data.sort((a, b) =>
      a.razao_social?.localeCompare(b.razao_social)
    );
    return arr2;
  };

  const getAllSubEstipulantes = async () => {
    const { id_estipulante } = selectedEstipulante;

    const response = await services.policyOwner.getAllSubs(id_estipulante);
    setSelectedEstipulante(response.data);
    setAllSubEstipulantes(response.data.subestipulantes);
  };

  const createArrAllDependents = () => {
    let arrAllInfosDependents = [];
    Object.entries(nomeDependentesDoTitular).forEach((item) => {
      arrAllInfosDependents.push({
        identification: item[0],
        nome: item[1]
      });
    });

    Object.entries(cpfDependentesDoTitular).forEach((vinculo) => {
      arrAllInfosDependents.forEach((eachItem, index) => {
        if (vinculo[0] === eachItem.identification) {
          eachItem = {
            ...eachItem,
            cpf: vinculo[1]
          };
          arrAllInfosDependents[index] = eachItem;
        }
      });
    });

    Object.entries(vinculoDependenteWithTitular).forEach((vinculo) => {
      arrAllInfosDependents.forEach((eachItem, index) => {
        if (vinculo[0] === eachItem.identification) {
          eachItem = {
            ...eachItem,
            vinculo: vinculo[1]
          };
          arrAllInfosDependents[index] = eachItem;
        }
      });
    });

    Object.entries(dataNascimentoAllDependentes).forEach((vinculo) => {
      arrAllInfosDependents.forEach((eachItem, index) => {
        if (vinculo[0] === eachItem.identification) {
          eachItem = {
            ...eachItem,
            dataNascimento: vinculo[1]
          };
          arrAllInfosDependents[index] = eachItem;
        }
      });
    });

    Object.entries(dataVinculoAllDependentes).forEach((vinculo) => {
      arrAllInfosDependents.forEach((eachItem, index) => {
        if (vinculo[0] === eachItem.identification) {
          eachItem = {
            ...eachItem,
            dataVinculo: vinculo[1]
          };
          arrAllInfosDependents[index] = eachItem;
        }
      });
    });

    Object.entries(nCarteirinhaAllDependentes).forEach((vinculo) => {
      arrAllInfosDependents.forEach((eachItem, index) => {
        if (vinculo[0] === eachItem.identification) {
          eachItem = {
            ...eachItem,
            nCarteirinha: vinculo[1]
          };
          arrAllInfosDependents[index] = eachItem;
        }
      });
    });

    Object.entries(dateStartPlanAllDependentes).forEach((vinculo) => {
      arrAllInfosDependents.forEach((eachItem, index) => {
        if (vinculo[0] === eachItem.identification) {
          eachItem = {
            ...eachItem,
            inicioPlano: vinculo[1]
          };
          arrAllInfosDependents[index] = eachItem;
        }
      });
    });

    return arrAllInfosDependents;
  };

  const sendInfosNewBeneficiary = async () => {
    let objInfosBeneficiary;
    let subEstipulante;
    const infosAllDependents = createArrAllDependents();

    if (typeUser === 'Titular') {
      objInfosBeneficiary = {
        tipo: typeUser,
        nome: beneficiaryName,
        cpf: beneficiaryCPF,
        dataNascimento: beneficiaryBirthDate,
        dataVinculo: bondDate,
        inicioPlano: dateStartPlan,
        nCarteirinha: nCarteirinha?.length === 0 ? null : nCarteirinha,
        dependentes: typeUser === 'Titular' ? infosAllDependents : null
      };
    }

    if (typeUser === 'Dependente') {
      objInfosBeneficiary = {
        ...infosTitularOfDependente,
        dependentes: [
          {
            tipo: typeUser,
            nome: beneficiaryName,
            vinculo: beneficiaryBondWithHolder,
            cpf: beneficiaryCPF,
            dataNascimento: beneficiaryBirthDate,
            dataVinculo: bondDate,
            inicioPlano: dateStartPlan,
            nCarteirinha: nCarteirinha?.length === 0 ? null : nCarteirinha
          }
        ]
      };
    }

    if (selectedSubEstipulante) {
      subEstipulante = allSubEstipulantes.find(
        (item) => item.id === Number(selectedSubEstipulante)
      );
    }
    // console.log('selectedContract', selectedContract)
    const infosEstipulante = {
      estipulante: selectedSubEstipulante
        ? { razao_social: subEstipulante.razao_social, id: subEstipulante.id }
        : {
            razao_social: selectedEstipulante.razao_social,
            id: selectedEstipulante.id
          },
      seguro: selectedContract
    };

    const body = {
      objInfosBeneficiary,
      infosEstipulante
    };

    setMessageError(null);

    const response = await services.beneficiaries.createByCompanyInfo(body);

    if (response) {
      setMessageSuccess('Beneficiário adicionado com sucesso!');
    } else {
      setMessageError(
        'Não foi possível adicionar o Beneficiário, tente novamente mais tarde.'
      );
    }
  };

  const getTitularByEstipulante = () => {
    if (selectedSubEstipulante) {
      const params = {
        estipulante_id: Number(selectedSubEstipulante),
        contrato_id: selectedContract[0].id,
        plano: selectedContract[0].nome
      };
      services.beneficiaries
        .getTitularByCompanyId(params)
        .then((resp) => {
          const allTitulares = resp.data.filter(
            (item) => item.tipo === 'Titular'
          );

          setAllHolders(allTitulares);
          setTriggerFilterInfos(true);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const params = {
        estipulante_id: selectedEstipulante.id,
        contrato_id: selectedContract[0].id,
        plano: selectedContract[0].nome
      };
      services.beneficiaries
        .getTitularByCompanyId(params)
        .then((resp) => {
          const allTitulares = resp.data.filter(
            (item) => item.tipo === 'Titular'
          );

          setAllHolders(allTitulares);
          setTriggerFilterInfos(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleFilterInfosAllTitulares = () => {
    let filteredHolders = [];
    allHolders.forEach((item) => {
      item = {
        uuid: item.uuid,
        nome: item.nome,
        id: item.id,
        estipulante_id: item.estipulante_id,
        contratos: item.contratos
      };
      filteredHolders.push(item);
    });

    setAllHolders(filteredHolders);
  };

  useEffect(() => {
    getEstipulantes();
    COUNT = 0;
  }, []);

  useEffect(() => {
    if (triggerGetSubs) {
      getAllSubEstipulantes();
      setTriggerGetSubs(false);
    }
  }, [triggerGetSubs]);

  useEffect(() => {
    if (typeUser === 'Dependente') {
      getTitularByEstipulante();
    }
  }, [typeUser, selectedContract, selectedEstipulante, selectedSubEstipulante]);

  useEffect(() => {
    if (triggerFilterInfos) {
      handleFilterInfosAllTitulares(setTriggerFilterInfos(false));
    }
  }, [triggerFilterInfos]);

  useEffect(() => {
    if (allowSendInfos) {
      setMessageError(null);
      sendInfosNewBeneficiary();
      setAllowSendInfos(false);
    }
  }, [allowSendInfos]);

  return (
    <WrapperAddBeneficiary>
      <header>
        <div className="headerAdd">
          <div className="titleHeader">
            <FaUserPlus />

            <h2>Adicionar beneficiário</h2>
          </div>
        </div>
      </header>
      <FormAddBeneficiaries
        count={COUNT}
        estipulantes={estipulantes}
        allSubEstipulantes={allSubEstipulantes}
        setSelectedEstipulante={setSelectedEstipulante}
        selectedEstipulante={selectedEstipulante}
        setSelectedSubEstipulante={setSelectedSubEstipulante}
        selectedContract={selectedContract}
        setSelectedContract={setSelectedContract}
        setTriggerGetSubs={setTriggerGetSubs}
        typeUser={typeUser}
        setTypeUser={setTypeUser}
        beneficiaryName={beneficiaryName}
        setBeneficiaryName={setBeneficiaryName}
        beneficiaryCPF={beneficiaryCPF}
        setBeneficiaryCPF={setBeneficiaryCPF}
        beneficiaryBirthDate={beneficiaryBirthDate}
        setBeneficiaryBirthDate={setBeneficiaryBirthDate}
        bondDate={bondDate}
        setBondDate={setBondDate}
        dateStartPlan={dateStartPlan}
        setDateStartPlan={setDateStartPlan}
        nCarteirinha={nCarteirinha}
        setNCarteirinha={setNCarteirinha}
        beneficiaryBondWithHolder={beneficiaryBondWithHolder}
        setBeneficiaryBondWithHolder={setBeneficiaryBondWithHolder}
        infosTitularOfDependente={infosTitularOfDependente}
        setInfosTitularOfDependente={setInfosTitularOfDependente}
        nomeDependentesDoTitular={nomeDependentesDoTitular}
        setNomeDependentesDoTitular={setNomeDependentesDoTitular}
        cpfDependentesDoTitular={cpfDependentesDoTitular}
        setCpfDependentesDoTitular={setCpfDependentesDoTitular}
        dataNascimentoAllDependentes={dataNascimentoAllDependentes}
        setDataNascimentoAllDependentes={setDataNascimentoAllDependentes}
        vinculoDependenteWithTitular={vinculoDependenteWithTitular}
        dataVinculoAllDependentes={dataVinculoAllDependentes}
        setVinculoDependenteWithTitular={setVinculoDependenteWithTitular}
        setDataVinculoAllDependentes={setDataVinculoAllDependentes}
        setNCarteirinhaAllDependentes={setNCarteirinhaAllDependentes}
        dateStartPlanAllDependentes={dateStartPlanAllDependentes}
        setDateStartPlanAllDependentes={setDateStartPlanAllDependentes}
        setAllowSendInfos={setAllowSendInfos}
        allHolders={allHolders}
        messageError={messageError}
        setMessageError={setMessageError}
        messageSuccess={messageSuccess}
      />
    </WrapperAddBeneficiary>
  );
};

export default DrawerAddBeneficiaries;
