import axios from "axios";
// const token = localStorage.getItem("jwt_token_user");
// const user = JSON.parse(token)

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_API,
  // baseURL: "https:" + process.env.REACT_APP_BASE_URL_API,

  // timeout: 1000,
  // headers: { Authorization: `Bearer ${user.token}` },
});

export default api;
