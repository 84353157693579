import styled from 'styled-components';
import colors from 'styles/colors';

export const CardWrapper = styled.div`
  width: 420px;
  height: 60px;
  padding: 10px 16px;
  background-color: ${colors.white};
  border: 2px solid ${colors.genoaBlue};
  border-radius: 12px;

  display: grid;
  grid-template-columns: 280px 125px;
  column-gap: 15px;

  .title-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .card-title {
    
    display: grid;
    align-items: center;
    grid-template-columns: 13.5px 1fr;
    column-gap: 8px;
    font-family: "Avenir Next GEO W05 Regular";

    .nome, .estipulante {
      font-size: 11px;
      line-height: 11.5px;
      text-transform: uppercase;
    }

    .person-icon {
      font-size: 13px;
      color: ${colors.genoaBlue};
    }

    .estipulante-icon {
      width: 17px;
      height: 17px;
      font-size: 13px;
      margin-left: -2.5px;

      g {
        fill: ${colors.genoaBlue};
      }
    }
  }

  .value-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 5px;

    .value {
      font-size: 13px;
      font-family: "Avenir Next GEO W05 Bold";
      color: ${colors.genoaRed};
    }

    button {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      font-size: 10px;
      font-family: "Avenir Next GEO W05 Demi";
      border-bottom: 1px solid ${colors.genoaGrey};
    }
  }
`