import { useEffect, useState } from 'react';
import { ReactComponent as IconTooth } from 'assets/imgs/svg/teeth.svg';
import { BiCheck, BiPlus, BiPlusCircle, BiX } from 'react-icons/bi';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@material-ui/core';
import { Dependente, Titular } from './styles';
import ReactInputMask from 'react-input-mask';
import ReactDatePicker from 'react-datepicker';
import { Autocomplete } from '@mui/material';

const FormAddBeneficiaries = ({
  count,
  estipulantes,
  allSubEstipulantes,
  setSelectedEstipulante,
  selectedEstipulante,
  setSelectedSubEstipulante,
  selectedContract,
  setSelectedContract,
  setTriggerGetSubs,
  typeUser,
  setTypeUser,
  beneficiaryName,
  setBeneficiaryName,
  beneficiaryCPF,
  setBeneficiaryCPF,
  beneficiaryBirthDate,
  setBeneficiaryBirthDate,
  bondDate,
  setBondDate,
  dateStartPlan,
  setDateStartPlan,
  nCarteirinha,
  setNCarteirinha,
  beneficiaryBondWithHolder,
  setBeneficiaryBondWithHolder,
  infosTitularOfDependente,
  setInfosTitularOfDependente,
  nomeDependentesDoTitular,
  setNomeDependentesDoTitular,
  cpfDependentesDoTitular,
  setCpfDependentesDoTitular,
  dataNascimentoAllDependentes,
  setDataNascimentoAllDependentes,
  vinculoDependenteWithTitular,
  dataVinculoAllDependentes,
  setVinculoDependenteWithTitular,
  setDataVinculoAllDependentes,
  setNCarteirinhaAllDependentes,
  dateStartPlanAllDependentes,
  setDateStartPlanAllDependentes,
  setAllowSendInfos,
  allHolders,
  messageError,
  setMessageError,
  messageSuccess
}) => {

  const [isMoreDependents, setIsMoreDependents] = useState([]);
  const [isSelectedOdonto, setIsSelectedOdonto] = useState(null);
  const [isSelectedSaude, setIsSelectedSaude] = useState(null);
  const [odontoSelectedEstipulante, setOdontoSelectedEstipulante] =
    useState(false);
  const [saudeSelectedEstipulante, setSaudeSelectedEstipulante] =
    useState(false);

  const selecionarContrato = (target, type, contractId) => {
    if (type === 'Odonto') {
      setSelectedContract([{ type: type, nome: target.value, id: contractId }]);
    } else {
      setSelectedContract([{ type: type, nome: target.value, id: contractId }]);
    }
  };

  const getSegurosEstipulante = (selectedEstipulanteId) => {
    const choosenEstipulante = estipulantes.filter(
      (item) => item.id === Number(selectedEstipulanteId)
    );

    setIsSelectedOdonto(null);
    setIsSelectedSaude(null);
    setSelectedContract(null);

    setSelectedEstipulante(choosenEstipulante[0]);
    setOdontoSelectedEstipulante(choosenEstipulante[0]?.contratoOdonto);
    setSaudeSelectedEstipulante(choosenEstipulante[0]?.contratoSaude);
    setTriggerGetSubs(true);
  };

  const selectedOdonto = (contrato_id) => {
    setIsSelectedSaude(null);
    if (isSelectedOdonto === contrato_id) {
      setIsSelectedOdonto(null);
    } else {
      setIsSelectedOdonto(contrato_id);
    }
  };

  const selectedSaude = (contrato_id) => {
    setIsSelectedOdonto(null);
    if (isSelectedSaude === contrato_id) {
      setIsSelectedSaude(null);
    } else {
      setIsSelectedSaude(contrato_id);
    }
  };

  const handleChangeTypeUser = (newTypeUser) => {
    setTypeUser(newTypeUser);
    if (typeUser?.length) {
      setBeneficiaryName(undefined);
      setBeneficiaryCPF(undefined);
      setBeneficiaryBirthDate(undefined);
      setBondDate(undefined);
      setDateStartPlan(undefined);
      setNCarteirinha(undefined);
      setInfosTitularOfDependente(undefined);
    }
  };

  const handleShowMoreDependents = () => {
    if (count <= 5) {
      count++;
    }
    if (count > 5) {
      return;
    }
    setIsMoreDependents([
      ...isMoreDependents,
      {
        identification: `Dependente${count}`
      }
    ]);
  };

  const validationExistsInfo = (state, msgError) => {
    if (state === undefined) throw msgError;
    if (!state) throw msgError;
    if (Array.isArray(state) && state.length === 0) throw msgError;
    if (typeof state === 'string' && !state.trim()) throw msgError;
  };

  const verifyInfosToSend = (e) => {
    e.preventDefault();
    try {
      validationExistsInfo(selectedContract, 'Você não selecionou um Seguro');
      typeUser === 'Dependente' &&
        validationExistsInfo(
          infosTitularOfDependente,
          'Você não selecionou um Titular'
        );
      validationExistsInfo(beneficiaryName, 'Preencha o nome do Beneficiário');
      validationExistsInfo(beneficiaryCPF, 'Preencha o CPF do Beneficiário');
      typeUser === 'Dependente' &&
        validationExistsInfo(
          vinculoDependenteWithTitular,
          'Você não selecionou o vínculo com Titular'
        );
      validationExistsInfo(
        beneficiaryBirthDate,
        'Preencha a data de nascimento do Beneficiário'
      );
      validationExistsInfo(
        bondDate,
        'Preencha a data de vínculo do Beneficiário'
      );
      setAllowSendInfos(true);
    } catch (err) {
      setMessageError(err);
    }
  };

  return (
    <main>
      <div className="select-company">
        <span>Estipulante:</span>
        <select
          defaultValue=""
          onChange={({ target }) => getSegurosEstipulante(target.value)}
        >
          <option value="" disabled>
            Selecione um Estipulante
          </option>
          {estipulantes &&
            estipulantes.map((item, index) => (
              <option value={item.id} key={index}>
                {item.razao_social}
              </option>
            ))}
        </select>
      </div>

      {allSubEstipulantes.length ? (
        <div className="select-company">
          <span>Sub estipulante:</span>
          <select
            defaultValue=""
            onChange={({ target }) => setSelectedSubEstipulante(target.value)}
          >
            <option value="" disabled>
              Selecione um Subestipulante
            </option>
            {allSubEstipulantes?.map((item, index) => (
              <option value={item.id} key={index}>
                {item.razao_social}
              </option>
            ))}
          </select>
        </div>
      ) : null}

      {selectedEstipulante ? (
        <div className="choose-insurance">
          <div className="container-select-insurance">
            {odontoSelectedEstipulante &&
              selectedEstipulante.contratos?.odonto
                ?.filter((item) => item.status === 'Ativo')
                ?.map((contrato) => (
                  <div className="box-select-insurance">
                    <button
                      className={`btn-action ${
                        isSelectedOdonto === contrato.id
                          ? 'select-custom'
                          : 'span'
                      }`}
                      onClick={() => selectedOdonto(contrato.id)}
                    >
                      <IconTooth />
                      <span id={contrato.id}>
                        {`${contrato.nome} (${contrato.numero_apolice})` ??
                          `Seguro odontológico`}
                      </span>
                    </button>
                    {isSelectedOdonto === contrato.id ? (
                      <select
                        defaultValue=""
                        onChange={({ target }) => {
                          selecionarContrato(target, 'Odonto', contrato.id);
                        }}
                      >
                        <option value="" disabled>
                          Escolha o seguro
                        </option>
                        {contrato.contrato_tipos.map((item, index) => (
                          <option key={index} value={item.tipo}>
                            {item.tipo}
                          </option>
                        ))}
                      </select>
                    ) : null}
                  </div>
                ))}
            {saudeSelectedEstipulante &&
              selectedEstipulante.contratos?.saude
                ?.filter((item) => item.status === 'Ativo')
                ?.map((contrato) => (
                  <div className="box-select-insurance">
                    <button
                      className={`btn-action ${
                        isSelectedSaude === contrato.id
                          ? 'select-custom'
                          : 'span'
                      }`}
                      onClick={() => selectedSaude(contrato.id)}
                    >
                      <BiPlusCircle />
                      <span id={contrato.id}>
                        {`${contrato.nome} (${contrato.numero_apolice})` ??
                          `Seguro saúde`}
                      </span>
                    </button>
                    {isSelectedSaude === contrato.id ? (
                      <select
                        defaultValue=""
                        onChange={({ target }) => {
                          selecionarContrato(target, 'Saúde', contrato.id);
                        }}
                      >
                        <option value="" disabled>
                          Escolha o seguro
                        </option>
                        {contrato.contrato_tipos.map((item, index) => (
                          <option key={index} value={item.tipo}>
                            {item.tipo}
                          </option>
                        ))}
                      </select>
                    ) : null}
                  </div>
                ))}
          </div>
          <div className="container-select-typeUser">
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue=""
                name="radio-buttons-group"
                onChange={({ target }) => handleChangeTypeUser(target.value)}
              >
                <FormControlLabel
                  value="Titular"
                  control={<Radio />}
                  label="Titular"
                />
                <FormControlLabel
                  value="Dependente"
                  control={<Radio />}
                  label="Dependente"
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      ) : null}

      {typeUser && (isSelectedOdonto !== null || isSelectedSaude !== null) ? (
        typeUser === 'Titular' ? (
          <Titular onSubmit={verifyInfosToSend}>
            <h2>{typeUser}</h2>
            <input
              className="gap"
              type="text"
              placeholder="* Nome completo do beneficiário"
              onChange={({ target }) => setBeneficiaryName(target.value)}
              value={beneficiaryName}
            />

            <ReactInputMask
              mask="999.999.999-99"
              placeholder="* CPF do Beneficiário"
              value={beneficiaryCPF}
              onChange={({ target }) => setBeneficiaryCPF(target.value)}
            />

            <div className="box-content-titular gap">
              <div>
                <ReactDatePicker
                  className="input-data"
                  selected={beneficiaryBirthDate}
                  locale="ptBR"
                  onChange={(date) => setBeneficiaryBirthDate(date)}
                  placeholderText="* Selecione a data de nascimento"
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div>
                <ReactDatePicker
                  className="input-data"
                  selected={bondDate}
                  locale="ptBR"
                  onChange={(date) => setBondDate(date)}
                  placeholderText="* Selecione a data de vínculo"
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </div>

            <input
              type="text"
              placeholder="Nº de carteirinha"
              onChange={(e) => setNCarteirinha(e.target.value)}
            />
            <div className="w-100">
              <ReactDatePicker
                className="input-data"
                selected={dateStartPlan}
                locale="ptBR"
                onChange={(date) => setDateStartPlan(date)}
                placeholderText="Selecione a data de ínicio do Plano"
                dateFormat="dd/MM/yyyy"
              />
            </div>

            {count > 0
              ? isMoreDependents &&
                isMoreDependents?.map((item, index) => (
                  <Dependente key={index}>
                    <h2>Dependente</h2>

                    <input
                      className="gap"
                      type="text"
                      readOnly
                      placeholder="Nome do Titular associado"
                      value={beneficiaryName}
                    />

                    <input
                      className="gap"
                      type="text"
                      placeholder="* Nome completo do Dependente"
                      onChange={({ target }) =>
                        setNomeDependentesDoTitular((prev) => ({
                          ...prev,
                          [item.identification]: target.value
                        }))
                      }
                      value={nomeDependentesDoTitular[item.identification]}
                    />

                    <ReactInputMask
                      mask="999.999.999-99"
                      placeholder="* CPF do Beneficiário"
                      onChange={({ target }) =>
                        setCpfDependentesDoTitular((prev) => ({
                          ...prev,
                          [item.identification]: target.value
                        }))
                      }
                      value={cpfDependentesDoTitular[item.identification]}
                    />

                    <div className="box-content-titular">
                      <div>
                        <ReactDatePicker
                          className="input-data"
                          selected={
                            dataNascimentoAllDependentes[item.identification]
                          }
                          locale="ptBR"
                          setDataNascimentoAllDependentes
                          onChange={(date) =>
                            setDataNascimentoAllDependentes((prev) => ({
                              ...prev,
                              [item.identification]: date
                            }))
                          }
                          placeholderText="* Data de nascimento do Dependente"
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                      <select
                        defaultValue=""
                        onChange={({ target }) =>
                          setVinculoDependenteWithTitular((prev) => ({
                            ...prev,
                            [item.identification]: target.value
                          }))
                        }
                        value={
                          vinculoDependenteWithTitular[item.identification]
                        }
                      >
                        <option value="" disabled>
                          Grau de Parentesco
                        </option>
                        <option value="Cônjuge">Cônjuge</option>
                        <option value="Companheiro">Companheiro</option>
                        <option value="Filho">Filho</option>
                      </select>
                    </div>
                    <div className="box-content-titular">
                      <div>
                        <ReactDatePicker
                          className="input-data"
                          selected={
                            dataVinculoAllDependentes[item.identification]
                          }
                          locale="ptBR"
                          onChange={(date) =>
                            setDataVinculoAllDependentes((prev) => ({
                              ...prev,
                              [item.identification]: date
                            }))
                          }
                          placeholderText="* Data de vínculo do Dependente"
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>

                      <div>
                        <ReactDatePicker
                          className="input-data"
                          selected={
                            dateStartPlanAllDependentes[item.identification]
                          }
                          locale="ptBR"
                          onChange={(date) =>
                            setDateStartPlanAllDependentes((prev) => ({
                              ...prev,
                              [item.identification]: date
                            }))
                          }
                          placeholderText="Selecione a data de ínicio do Plano"
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </div>

                    <input
                      type="text"
                      placeholder="Nº de carteirinha do Dependente"
                      onChange={({ target }) =>
                        setNCarteirinhaAllDependentes((prev) => ({
                          ...prev,
                          [item.identification]: target.value
                        }))
                      }
                    />
                  </Dependente>
                ))
              : null}

            <footer>
              <button type="submit" className="btn-send-infos">
                Cadastrar {isMoreDependents ? 'beneficiários' : 'beneficiário'}
              </button>
              <p className="text-small">Campos com * são obrigatórios</p>
              <p
                className={
                  messageError ? 'text-error' : messageSuccess && 'text-success'
                }
              >
                {messageError ? (
                  <>
                    <BiX /> {messageError}
                  </>
                ) : (
                  messageSuccess && (
                    <>
                      <BiCheck /> {messageSuccess}
                    </>
                  )
                )}
              </p>
            </footer>

            <button
              type="button"
              className="btn-more-dependentes"
              onClick={handleShowMoreDependents}
            >
              <BiPlus />
              Dependente
            </button>
          </Titular>
        ) : typeUser === 'Dependente' ? (
          <Dependente onSubmit={verifyInfosToSend}>
            <h2>Dependente</h2>

            <Autocomplete
              className="box-select-titular"
              disablePortal
              options={allHolders}
              getOptionLabel={(option) => option.nome || ''}
              onChange={(_, titular) => {
                setInfosTitularOfDependente(titular);
              }}
              renderInput={(params) => (
                <div ref={params.InputProps.ref}>
                  <input
                    {...params.inputProps}
                    type="text"
                    name="listCorretores"
                    placeholder={'Selecione o Titular do Dependente'}
                  />
                </div>
              )}
            />
            <input
              type="text"
              placeholder="* Nome completo do Dependente"
              onChange={({ target }) => setBeneficiaryName(target.value)}
              value={beneficiaryName}
            />

            <ReactInputMask
              mask="999.999.999-99"
              placeholder="* CPF do Beneficiário"
              value={beneficiaryCPF}
              onChange={({ target }) => setBeneficiaryCPF(target.value)}
            />

            <div className="box-content-titular">
              <div>
                <ReactDatePicker
                  className="input-data"
                  selected={beneficiaryBirthDate}
                  locale="ptBR"
                  onChange={(date) => setBeneficiaryBirthDate(date)}
                  placeholderText="* Data de nascimento do Dependente"
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <select
                defaultValue=""
                onChange={({ target }) =>
                  setBeneficiaryBondWithHolder(target.value)
                }
                value={beneficiaryBondWithHolder}
              >
                <option value="" disabled>
                  Grau de Parentesco
                </option>
                <option value="Cônjuge">Cônjuge</option>
                <option value="Companheiro">Companheiro</option>
                <option value="Filho">Filho</option>
              </select>
            </div>
            <div className="box-content-titular">
              <div>
                <ReactDatePicker
                  className="input-data"
                  selected={bondDate}
                  locale="ptBR"
                  onChange={(date) => setBondDate(date)}
                  placeholderText="* Data de vínculo do Dependente"
                  dateFormat="dd/MM/yyyy"
                />
              </div>

              <div>
                <ReactDatePicker
                  className="input-data"
                  selected={dateStartPlan}
                  locale="ptBR"
                  onChange={(date) => setDateStartPlan(date)}
                  placeholderText="Selecione a data de ínicio do Plano"
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </div>

            <input
              type="text"
              placeholder="Nº de carteirinha do Dependente"
              onChange={({ target }) => setNCarteirinha(target.value)}
              value={nCarteirinha}
            />

            <footer>
              <button type="submit" className="btn-send-infos">
                Cadastrar beneficiário
              </button>
              <p className="text-small">Campos com * são obrigatórios</p>
              <p
                className={
                  messageError ? 'text-error' : messageSuccess && 'text-success'
                }
              >
                {messageError ? (
                  <>
                    <BiX /> {messageError}
                  </>
                ) : (
                  messageSuccess && (
                    <>
                      <BiCheck /> {messageSuccess}
                    </>
                  )
                )}
              </p>
            </footer>
          </Dependente>
        ) : null
      ) : null}
    </main>
  );
};

export default FormAddBeneficiaries;
