import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FooterPainel from "apps/hr/components/FooterPainel";
import HeaderRh from "apps/hr/components/HeaderRH";
import MainContainer from "apps/hr/components/MainContainer";
import MenuRh from "apps/hr/components/MenuRh";
import api from "services/api";
import { BoxData } from "./styles";

const DadosCadastrais = () => {
  const user = useSelector((state) => state.userReducers.user);

  const [estipulante, setEstipulante] = useState({
    cnpj: "",
    razaoSocial: "",
    endereco: "",
  });
  const [subestipulantes, setSubestipulantes] = useState([]);

  function getData() {
    api
      .get("/rh/estipulante", {
        headers: {
          id: user.estipulante,
          authorization: `Bearer ${user.token}`,
        },
      })
      .then((resp) => {
        const dataEstipulante = resp.data.estipulante;
        setEstipulante({
          cnpj: dataEstipulante.cnpj,
          razaoSocial: dataEstipulante.razaoSocial,
          endereco: dataEstipulante.endereco,
        });
        setSubestipulantes(resp.data.subestipulantes);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <HeaderRh />
      <MenuRh />

      <MainContainer>
        <h1>Dados Cadastrais</h1>

        <BoxData>
          <div className="title">
            <h2>Informações do Estipulante</h2>
          </div>
          <div className="box-data">
            <div className="data">
              <p>Razão Social</p>
              <h3>{estipulante.razaoSocial}</h3>
            </div>

            <div className="data">
              <p>CNPJ</p>
              <h3>{estipulante.cnpj}</h3>
            </div>

            <div className="data">
              <p>Endereço</p>
              <h3>{estipulante.endereco}</h3>
            </div>
          </div>
        </BoxData>

        <BoxData>
          {subestipulantes.length > 0 && (
            <div className="title">
              <h2>Sub estipulantes</h2>
            </div>
          )}

          {subestipulantes.map((sub, i) => (
            <div className="box-data" key={i}>
              <div className="data">
                <p>Razão Social</p>
                <h3>{sub.razaoSocial}</h3>
              </div>

              <div className="data">
                <p>CNPJ</p>
                <h3>{sub.cnpj}</h3>
              </div>
            </div>
          ))}
        </BoxData>
      </MainContainer>
      <FooterPainel />
    </>
  );
};

export default DadosCadastrais;
