import { useState, useMemo } from 'react';
import { DarkBackground } from '../CreateNewBusiness/styles';
import { ClickAwayListener } from '@mui/material';
import { DrawerRenMesmaCiaWrapper } from './styles';
import toast from 'react-hot-toast';
import services from 'apps/broker/services';

const DrawerRenMesmaCia = ({
  setRenMesmaCiaDrawer,
  isSelected,
  getNegociosData
}) => {
  const [transitionLeave, setTransitionLeave] = useState(false);
  const [ano, setAno] = useState(`${new Date().getFullYear()}`);
  const [vcmh, setVcmh] = useState('');
  const [sinistralidade, setSinistralidade] = useState('');
  const [total, setTotal] = useState('');
  const [observacoes, setObservacoes] = useState('');
  const [disabled, setDisabled] = useState(true);

  const filterOnlyDigits = (string) => {
    return string.replace(/[^0-9]/g, '');
  };

  const filterDigitsAndComma = (string) => {
    return string.replace(/[^0-9\\.\\,]+/g, '');
  };

  const inputChangeAnoHandler = (e) => {
    const numeroFiltrado = filterOnlyDigits(e.target.value);
    setAno(numeroFiltrado);
  };

  const inputChangeVcmhHandler = (e) => {
    const numeroFiltrado = filterDigitsAndComma(e.target.value);
    setVcmh(numeroFiltrado);
  };

  const inputChangeSinistralidadeHandler = (e) => {
    const numeroFiltrado = filterDigitsAndComma(e.target.value);
    setSinistralidade(numeroFiltrado);
  };

  const inputChangeTotalHandler = (e) => {
    const numeroFiltrado = filterDigitsAndComma(e.target.value);
    setTotal(numeroFiltrado);
  };

  const observacoesChangeHandler = ({ target }) => {
    setObservacoes(target.value);
  };

  const sendReajusteClickHandler = async () => {
    const body = {
      negocio_id: isSelected.id,
      estipulante_id: isSelected.estipulante_id,
      contrato_id: isSelected.contrato_id,
      ano,
      vcmh,
      sinistralidade,
      total,
      observacoes
    };

    const response =
      await services.insuranceCompanies.createAdjustmentByBusinessId(body);

    if (response) {
      getNegociosData();
      toast.dismiss();
      toast.success('Renovação inserida com sucesso!');
    } else {
      toast.dismiss();
      toast.error('Algo deu errado, tente novamente!');
    }
  };

  useMemo(() => {
    if (ano && vcmh && sinistralidade && total && observacoes) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [ano, vcmh, sinistralidade, total, observacoes]);

  return (
    <DarkBackground
      style={{
        animation: transitionLeave ? 'fade-out 0.225s forwards' : ''
      }}
    >
      <ClickAwayListener
        onClickAway={() => {
          setTransitionLeave(true);
          setTimeout(() => {
            setRenMesmaCiaDrawer(false);
          }, 200);
        }}
      >
        <DrawerRenMesmaCiaWrapper className={transitionLeave ? 'leave' : ''}>
          <div>
            <span className="title">Renovação na mesma Cia.</span>
          </div>
          <div className="input_reajuste_container">
            <div className="input_option">
              <label>Ano</label>
              <input type="text" value={ano} onChange={inputChangeAnoHandler} />
            </div>
            <div className="input_option">
              <label>{'VCMH (%)'}</label>
              <input
                type="text"
                value={vcmh}
                onChange={inputChangeVcmhHandler}
              />
            </div>
            <div className="math_symbol">
              <span>+</span>
            </div>
            <div className="input_option">
              <label>{'Sinistralidade (%)'}</label>
              <input
                type="text"
                value={sinistralidade}
                onChange={inputChangeSinistralidadeHandler}
              />
            </div>
            <div className="math_symbol">
              <span>=</span>
            </div>
            <div className="input_option">
              <label>{'Total (%)'}</label>
              <input
                type="text"
                value={total}
                onChange={inputChangeTotalHandler}
              />
            </div>
          </div>
          <div className="input_obs">
            <span>Observações</span>
            <textarea onChange={observacoesChangeHandler} />
          </div>
          <div>
            <button
              className={`send_reajuste_btn ${disabled ? 'disabled' : ''}`}
              onClick={sendReajusteClickHandler}
            >
              enviar
            </button>
          </div>
        </DrawerRenMesmaCiaWrapper>
      </ClickAwayListener>
    </DarkBackground>
  );
};

export default DrawerRenMesmaCia;
