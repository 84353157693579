import { useState, useEffect } from 'react';
import { TabelaMovimentacoesWrapper } from './styles-TabelaMovimentacoes';
import AsideUserIcon from '../Icons/AsideUserIcon';

const TabelaMovimentacoes = (props) => {
  const {
    tipo,
    relatorioSelecionado,
    tableEstipulanteValue,
    movimentacoesConcluidas,
    tempoMedio
  } = props;

  const [abertas, setAbertas] = useState([]);
  const [concluidas, setConcluidas] = useState([]);
  const [tempo, setTempo] = useState([]);

  const renderTipoRelatorio = () => {
    switch (relatorioSelecionado) {
      case 'movimentacoes abertas':
        return abertas;

      case 'movimentacoes concluidas':
        return concluidas;

      case 'tempo de resolucao':
        return tempo;

      default:
        return [];
    }
  };

  const orderArray = (array) => {
    const ordered = array.sort((a, b) => b.total - a.total);

    return ordered;
  };

  const renderEstipulanteAbertas = () => {
    const array = [];
    for (let index in tableEstipulanteValue) {
      const total =
        tableEstipulanteValue[index]['Rh']['Inclusão'] +
        tableEstipulanteValue[index]['Rh']['Exclusão'] +
        tableEstipulanteValue[index]['Corretora']['Inclusão'] +
        tableEstipulanteValue[index]['Corretora']['Exclusão'];

      const totalInclusao =
        tableEstipulanteValue[index]['Rh']['Inclusão'] +
        tableEstipulanteValue[index]['Corretora']['Inclusão'];

      const totalExclusao =
        tableEstipulanteValue[index]['Rh']['Exclusão'] +
        tableEstipulanteValue[index]['Corretora']['Exclusão'];

      const estipulanteData = {
        razao_social: index.toUpperCase(),
        data: tableEstipulanteValue[index],
        total,
        totalInclusao,
        totalExclusao
      };

      array.push(estipulanteData);
    }

    orderArray(array);
    setAbertas(array);
  };

  const renderEstipulanteConcluidas = () => {
    const array = [];

    for (let index in movimentacoesConcluidas) {
      const total =
        movimentacoesConcluidas[index]['Rh']['Inclusão'] +
        movimentacoesConcluidas[index]['Rh']['Exclusão'] +
        movimentacoesConcluidas[index]['Corretora']['Inclusão'] +
        movimentacoesConcluidas[index]['Corretora']['Exclusão'];

      const totalInclusao =
        movimentacoesConcluidas[index]['Rh']['Inclusão'] +
        movimentacoesConcluidas[index]['Corretora']['Inclusão'];

      const totalExclusao =
        movimentacoesConcluidas[index]['Rh']['Exclusão'] +
        movimentacoesConcluidas[index]['Corretora']['Exclusão'];

      const estipulanteData = {
        razao_social: index.toUpperCase(),
        data: movimentacoesConcluidas[index],
        total,
        totalInclusao,
        totalExclusao
      };

      array.push(estipulanteData);
    }

    orderArray(array);
    setConcluidas(array);
  };

  const renderTempoConclusao = () => {
    const array = [];

    for (let index in tempoMedio) {
      const estipulanteDados = {
        data: {
          Rh: {},
          Corretora: {}
        }
      };
      estipulanteDados.razao_social = index.toUpperCase();

      let tempoTotalInclusao = 0;
      let tempoTotalExclusao = 0;
      let countTotalInclusao = 0;
      let countTotalExclusao = 0;

      if (tempoMedio[index]['Rh']['Inclusão']['count'] !== 0) {
        const tempoInclusaoRh =
          tempoMedio[index]['Rh']['Inclusão']['tempo_total'] /
          1000 /
          60 /
          60 /
          24;
        const mediaInclusaoRh = Math.ceil(
          tempoInclusaoRh / tempoMedio[index]['Rh']['Inclusão']['count'],
          0
        );

        tempoTotalInclusao += tempoInclusaoRh;
        countTotalInclusao += tempoMedio[index]['Rh']['Inclusão']['count'];
        estipulanteDados.data['Rh']['Inclusão'] = mediaInclusaoRh;
      } else {
        estipulanteDados.data['Rh']['Inclusão'] = 0;
      }

      if (tempoMedio[index]['Rh']['Exclusão']['count'] !== 0) {
        const tempoExclusaoRh =
          tempoMedio[index]['Rh']['Exclusão']['tempo_total'] /
          1000 /
          60 /
          60 /
          24;

        const mediaExclusaoRh = Math.ceil(
          tempoExclusaoRh / tempoMedio[index]['Rh']['Exclusão']['count'],
          0
        );

        tempoTotalExclusao += tempoExclusaoRh;
        countTotalExclusao += tempoMedio[index]['Rh']['Exclusão']['count'];
        estipulanteDados.data['Rh']['Exclusão'] = mediaExclusaoRh;
      } else {
        estipulanteDados.data['Rh']['Exclusão'] = 0;
      }

      if (tempoMedio[index]['Corretora']['Inclusão']['count'] !== 0) {
        const tempoInclusaoCorretora =
          tempoMedio[index]['Corretora']['Inclusão']['tempo_total'] /
          1000 /
          60 /
          60 /
          24;
        const mediaInclusaoCorretora = Math.ceil(
          tempoInclusaoCorretora /
            tempoMedio[index]['Corretora']['Inclusão']['count'],
          0
        );

        tempoTotalInclusao += tempoInclusaoCorretora;
        countTotalInclusao +=
          tempoMedio[index]['Corretora']['Inclusão']['count'];
        estipulanteDados.data['Corretora']['Inclusão'] = mediaInclusaoCorretora;
      } else {
        estipulanteDados.data['Corretora']['Inclusão'] = 0;
      }

      if (tempoMedio[index]['Corretora']['Exclusão']['count'] !== 0) {
        const tempoExclusaoCorretora =
          tempoMedio[index]['Corretora']['Exclusão']['tempo_total'] /
          1000 /
          60 /
          60 /
          24;
        const mediaExclusaoCorretora = Math.ceil(
          tempoExclusaoCorretora /
            tempoMedio[index]['Corretora']['Exclusão']['count'],
          0
        );

        tempoTotalExclusao += tempoExclusaoCorretora;
        countTotalExclusao +=
          tempoMedio[index]['Corretora']['Exclusão']['count'];
        estipulanteDados.data['Corretora']['Exclusão'] = mediaExclusaoCorretora;
      } else {
        estipulanteDados.data['Corretora']['Exclusão'] = 0;
      }

      let mediaTempoTotalInclusao = 0;

      if (countTotalInclusao !== 0) {
        mediaTempoTotalInclusao = tempoTotalInclusao / countTotalInclusao;
      }

      let mediaTempoTotalExclusao = 0;

      if (countTotalExclusao !== 0) {
        mediaTempoTotalExclusao = tempoTotalExclusao / countTotalExclusao;
      }
      estipulanteDados.totalInclusao = Math.ceil(mediaTempoTotalInclusao, 0);
      estipulanteDados.totalExclusao = Math.ceil(mediaTempoTotalExclusao, 0);

      let mediaTotal = 0;
      if (countTotalInclusao !== 0 || countTotalExclusao !== 0) {
        mediaTotal =
          (tempoTotalInclusao + tempoTotalExclusao) /
          (countTotalInclusao + countTotalExclusao);
      }

      estipulanteDados.total = Math.ceil(mediaTotal, 0);
      array.push(estipulanteDados);
    }

    orderArray(array);
    setTempo(array);
  };

  useEffect(() => {
    renderEstipulanteAbertas();
    renderEstipulanteConcluidas();
    renderTempoConclusao();
  }, [tableEstipulanteValue, movimentacoesConcluidas, relatorioSelecionado]);

  return (
    <TabelaMovimentacoesWrapper>
      <table>
        <thead>
          <tr>
            <th className="estipulante">Estipulante</th>
            <th>
              <div className="geral">
                <div className="primeira">
                  Rh {tipo === 'dias' ? '(Dias)' : ''}
                </div>
                <div className="segunda">
                  <AsideUserIcon tipo={'Inclusão'} />
                  <AsideUserIcon tipo={'Exclusão'} />
                </div>
              </div>
            </th>
            <th>
              <div className="geral">
                <div className="primeira">
                  Corretora {tipo === 'dias' ? '(Dias)' : ''}
                </div>
                <div className="segunda">
                  <AsideUserIcon tipo={'Inclusão'} />
                  <AsideUserIcon tipo={'Exclusão'} />
                </div>
              </div>
            </th>
            <th>
              <div className="geral">
                <div className="primeira">
                  Total {tipo === 'dias' ? '(Dias)' : ''}
                </div>
                <div className="segunda_especial">
                  <AsideUserIcon tipo={'Inclusão'} />
                  <AsideUserIcon tipo={'Exclusão'} />
                  <div></div>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {renderTipoRelatorio()?.map((element, index) => (
            <tr key={index}>
              <td>{element.razao_social}</td>
              <td>{element.data['Rh']['Inclusão']}</td>
              <td>{element.data['Rh']['Exclusão']}</td>
              <td>{element.data['Corretora']['Inclusão']}</td>
              <td>{element.data['Corretora']['Exclusão']}</td>
              <td>{element.totalInclusao}</td>
              <td>{element.totalExclusao}</td>
              <td>{element.total}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </TabelaMovimentacoesWrapper>
  );
};

export default TabelaMovimentacoes;
