import { LuAlarmClock } from 'react-icons/lu';
import { TableUsuario } from './styles';
import { useHistory } from 'react-router-dom';
import { useActivitiesFilters } from 'contexts/activities';
import { useTasksFilters } from 'contexts/tasks';

const MyActivities = ({
  setVencimento,
  vencimento,
  user,
  filters,
  countDUserLogado
}) => {
  const history = useHistory();
  const activities = useActivitiesFilters();
  const tasks = useTasksFilters();

  const redirectToTicketsUserLogado = (tipo) => {
    let date_atuation = 'all';

    if (vencimento === 'vencidas') {
      date_atuation = 'vencido';
    }

    if (vencimento === 'hoje') {
      date_atuation = 'hoje';
    }

    if (vencimento === 'amanha') {
      date_atuation = 'amanha';
    }

    const filtersToDispatch = {
      redirected: true,
      corretor: user?.nome,
      ticket_type: tipo,
      date_atuation
    };

    filters.updateHomeFilters(filtersToDispatch);
    history.push('/corretor/gestao-tickets');
  };

  const redirectToAtividadesUserLogado = () => {
    let filtersToDispatch = {};

    if (user.nome === 'Rodrigo Pedroni') {
      filtersToDispatch = {
        redirected: true,
        venc: vencimento,
        corretor: 17
      };
    } else {
      filtersToDispatch = {
        redirected: true,
        venc: vencimento,
        corretor: user?.usuario_id
      };
    }

    activities.updateActivitiesFilters(filtersToDispatch);
    history.push('/corretor/negocios');
  };

  const redirectToTasksUserLogado = () => {
    let filtersToDispatch = {};

    if (user.nome === 'Rodrigo Pedroni') {
      filtersToDispatch = {
        redirected: true,
        venc: vencimento,
        corretor: 17
      };
    } else {
      filtersToDispatch = {
        redirected: true,
        venc: vencimento,
        corretor: user?.usuario_id
      };
    }

    tasks.updateTasksFilters(filtersToDispatch);

    history.push('/corretor/tasks');
  };

  return (
    <div className="usuario_content">
      <div className="icon_users_demandas">
        <LuAlarmClock />
      </div>
      <div className="boxes_usuario">
        <div>
          <span className="title_usuario">Para você</span>
        </div>
        <TableUsuario>
          <thead>
            <tr>
              <th className="icon_user"></th>
              <th
                className="title_filter_usuario"
                onClick={() => setVencimento('vencidas')}
              >
                Vencidas
              </th>
              <th
                className="title_filter_usuario"
                onClick={() => setVencimento('hoje')}
              >
                Hoje
              </th>
              <th
                className="title_filter_usuario"
                onClick={() => setVencimento('amanha')}
              >
                Amanhã
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="title_tbody">Tickets</td>
              <td
                className="ticket_corretor"
                onClick={() => redirectToTicketsUserLogado('outras demandas')}
              >
                {vencimento === 'vencidas'
                  ? countDUserLogado?.ticket?.length
                  : ''}
              </td>
              <td
                className="ticket_corretor"
                onClick={() => redirectToTicketsUserLogado('outras demandas')}
              >
                {vencimento === 'hoje' ? countDUserLogado?.ticket?.length : ''}
              </td>
              <td
                className="ticket_corretor"
                onClick={() => redirectToTicketsUserLogado('outras demandas')}
              >
                {vencimento === 'amanha'
                  ? countDUserLogado?.ticket?.length
                  : ''}
              </td>
            </tr>
            <tr>
              <td className="title_tbody">Movs</td>
              <td
                className="ticket_corretor"
                onClick={() => redirectToTicketsUserLogado('Movimentação')}
              >
                {vencimento === 'vencidas' ? countDUserLogado?.mov?.length : ''}
              </td>
              <td
                className="ticket_corretor"
                onClick={() => redirectToTicketsUserLogado('Movimentação')}
              >
                {vencimento === 'hoje' ? countDUserLogado?.mov?.length : ''}
              </td>
              <td
                className="ticket_corretor"
                onClick={() => redirectToTicketsUserLogado('Movimentação')}
              >
                {vencimento === 'amanha' ? countDUserLogado?.mov?.length : ''}
              </td>
            </tr>
            <tr>
              <td className="title_tbody">Tasks</td>
              <td
                className="ticket_corretor"
                onClick={redirectToTasksUserLogado}
              >
                {vencimento === 'vencidas'
                  ? countDUserLogado?.task?.length
                  : ''}
              </td>
              <td
                className="ticket_corretor"
                onClick={redirectToTasksUserLogado}
              >
                {vencimento === 'hoje' ? countDUserLogado?.task?.length : ''}
              </td>
              <td
                className="ticket_corretor"
                onClick={redirectToTasksUserLogado}
              >
                {vencimento === 'amanha' ? countDUserLogado?.task?.length : ''}
              </td>
            </tr>
            <tr>
              <td className="title_tbody">Atividades</td>
              <td
                className="ticket_corretor"
                onClick={redirectToAtividadesUserLogado}
              >
                {vencimento === 'vencidas'
                  ? countDUserLogado?.atividade?.length
                  : ''}
              </td>
              <td
                className="ticket_corretor"
                onClick={redirectToAtividadesUserLogado}
              >
                {vencimento === 'hoje'
                  ? countDUserLogado?.atividade?.length
                  : ''}
              </td>
              <td
                className="ticket_corretor"
                onClick={redirectToAtividadesUserLogado}
              >
                {vencimento === 'amanha'
                  ? countDUserLogado?.atividade?.length
                  : ''}
              </td>
            </tr>
          </tbody>
        </TableUsuario>
      </div>
    </div>
  );
};

export default MyActivities;
