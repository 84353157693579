import styled from 'styled-components';
import colors from 'styles/colors';

export const DrawerDetalhesWrapper = styled.div`
  width: 900px;

  .drawer_header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 10px;
    padding: 20px;
    padding-left: 50px;
    background-color: ${colors.genoaBlue};
    height: 110px;
    color: ${colors.white};

    .header_title {
      font-family: Avenir Next Geo W05 Heavy;
      font-size: 1.2em;
    }
  
    .header_subtitle {
      font-family: Avenir Next Geo W05 Medium;
      font-size: 0.95em;
    }
  
    .back {
      padding: 10px 0px 0px 0px;
      height: 30px;
  
      .icon-back {
        text-align: center;
        color: #fff;
        cursor: pointer;
        font-size: 0.9em;
        svg {
          font-size: 0.9em;
        }
      }
    }
  }

  .drawer_content {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    padding: 30px;
    padding-left: 50px;
    padding-right: 50px;

    .primeira_linha {
      display: flex;
      flex-direction: row;
      column-gap: 50px;

      .primeira_coluna {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 10px;

        .custom_datepicker {
          border: none;
          background-color: inherit;
          width: 55px;
          font-family: Avenir Next Geo W05 Regular;
          font-size: 0.8em;
        }
      }

      .segunda_coluna {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 10px;
      }
    }

    .segunda_linha {
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      .descricao_container {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
      }

      .btn_container {
        display: flex;
        flex-direction: row;
        column-gap: 10px;

        .btn_salvar_desc {
          background: none;
          color: inherit;
          border: none;
          padding: 0;
          font: inherit;
          cursor: pointer;
          outline: inherit;
  
          background-color: ${colors.genoaBlue};
          color: ${colors.white};
          padding: 4px 8px;
          font-family: "Avenir Next Geo W05 Demi";
          // text-transform: uppercase;
          font-size: 0.8em;
          border-radius: 5px;
        }
      }

      .text_area {
        border: 1px solid ${colors.genoaBlue};
        border-radius: 10px;
        padding: 15px;
        min-height: 50px;
        max-height: 60px;
        width: 80%;

        ::-webkit-scrollbar {
          width: 3px;
          padding: 15px 0px;
          background: rgba(0, 0, 0, 0);
        }
        ::-webkit-scrollbar-thumb {
          background: ${colors.rhLightGrey};
          padding: 15px 0px;
          box-shadow: none;
          
        }
        ::-webkit-scrollbar-track {
          margin-top: 15px;
          margin-bottom: 15px;
          box-shadow: inset 0 0 2px rgb(0, 0 , 0, 0);
          
        }
      }
    }

    .terceira_linha {
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      .primeira_coluna {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 10px;
      }

      .segunda_coluna {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 10px;
      }

      .terceira_coluna {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 10px;
      }

      .quarta_coluna {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 10px;
      }
    }

    .quarta_linha {
      display: flex;
      flex-direction: column;
      row-gap: 20px;

      .primeira_coluna {
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        .container_checklist {
          display: flex;
          flex-direction: row;
          align-items: center;
          column-gap: 10px;
          width: 80%;

          .input_checklist {
            font-size: 0.85em;
            font-family: Avenir Next Geo W05 Regular;
            border: none;
            border-bottom: 1px solid ${colors.genoaBlue};
            padding: 0px 5px;
            width: 70%;
          }

          .btn_container {
            display: flex;
            flex-direction: row;
            align-items: center;
            column-gap: 8px;

            .btn_confirm {
              border: none;
              background: none;
              font-size: 1.3em;
              color: green;

              :hover {
                cursor: pointer;
              }
            }
          }
        }
      }

      .segunda_coluna {
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        .check_container {
          display: flex;
          flex-direction: row;
          align-items: center;
          column-gap: 8px;
          width: 80%;

          .checked {
            text-decoration: line-through;
          }

          .checkbox {
            height: 10px;
            width: 10px;
          }

          .btn_del {
            display: flex;
            justify-content: center;
            border: none;
            background: none;
            font-size: 1em;
            color: red;

            :hover {
              cursor: pointer;
            }
          }
        }
      }

    }

    .label {
      font-family: Avenir Next Geo W05 Demi;
      font-size: 0.85em;
    }

    .info {
      font-family: Avenir Next Geo W05 Regular;
      font-size: 0.85em;
    }
  }
  
`