import { useState } from 'react';
import { FaAngleDoubleRight, FaPen } from 'react-icons/fa';
import toast from 'react-hot-toast';
import parse from 'html-react-parser';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import TextEditor from 'components/TextEditor';
import moment from 'moment';
import { Div } from './styles';
import services from 'apps/broker/services';
import { useUser } from 'contexts/user';
import Button from 'components/DS/Button';

const TicketMessages = ({
  data,
  getData,
  setOpen,
  details,
  messages,
  setMessages,
  handleEdit,
  handleClickConfirm,
  handleReopen
}) => {
  const { user } = useUser();

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [files, setFiles] = useState([]);
  const [openEditor, setOpenEditor] = useState(false);

  const sendMessageCorretor = async () => {
    const htmlMessage = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );

    const formData = new FormData();
    formData.append('mensagem', htmlMessage);
    files.forEach((file, index) => {
      formData.append(index, file);
    });

    const response = await services.tickets.createMessageByBroker(
      formData,
      data,
      'RespCorretor',
      'normal'
    );

    if (response.status === 200) {
      setFiles([]);
      setEditorState(!editorState);
      getData();
    }
  };

  const handleClickOpen = () => {
    if (!data.seguradora_nome) {
      toast.error('É necessário incluir o nome da seguradora para concluir', {
        style: {
          fontFamily: 'Avenir Next Geo W05 Demi'
        }
      });
    } else {
      setOpen(true);
    }
  };

  const mensagemDataFusoHorario = (created_at) => {
    const timestamp = new Date(created_at).getTime();

    return moment.tz(timestamp, 'America/Sao_Paulo').format('DD/MM/YYYY');
  };

  return (
    <Div>
      <section className="infos">
        <div className="open">
          Ticket aberto por{' '}
          {details.nome
            ? details.nome + ' ' + details.sobrenome
            : details.nome_criador}
        </div>
        <div className="status">
          <div className="text-icon">
            <FaAngleDoubleRight /> Status: {details.descricao}{' '}
            {details.status_id === 3 || details.status_id === 7 ? null : (
              <span className="btn-edit">
                <FaPen onClick={handleEdit} />
              </span>
            )}
          </div>
        </div>
      </section>
      <section>
        <div className="titleTicket">
          {details.assunto} - #{details.id_ticket}
        </div>
        <div id="messages">
          {messages?.map((message) => {
            return (
              <div
                className="messageUser"
                key={`${message.ticket_id}-${message.id}`}
              >
                <div className="user-date">
                  {message.usuario_rh_id
                    ? message.nome + ' ' + message.sobrenome + ' '
                    : message.autor
                    ? message.autor + ' '
                    : null || message.corretora_id
                    ? message.nome + ' '
                    : message.autor
                    ? message.autor + ' '
                    : null}
                  em {mensagemDataFusoHorario(message.created_at)}
                </div>
                {message.usuario_rh_id === null ? (
                  <div className="messageText  resp-msg">
                    <div key={message.id}>{parse(message.mensagem)}</div>
                    {message.file !== undefined ? (
                      <div>
                        {message.file?.map((file, index) => {
                          return (
                            <div key={index} id="messageFile">
                              <a
                                href={file.attachment_url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {file.name}
                              </a>
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div className="messageText  open-msg">
                    {message.file !== undefined ? (
                      <div>
                        <div key={message.id}>{parse(message.mensagem)}</div>
                        {message.file?.map((file, index) => {
                          return (
                            <div id="messageFile">
                              <a
                                href={file.attachment_url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {file.name}
                              </a>
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            );
          })}
        </div>

        <div className="actions">
          <div className="buttons">
            {details.status_id === 3 || details.status_id === 7 ? null : (
              <Button
                variant="transparent"
                onClick={() => {
                  setOpenEditor(!openEditor);
                }}
              >
                + Nova mensagem
              </Button>
            )}
          </div>
          <div className="buttons2">
            {details.status_id === 3 ||
            details.status_id === 7 ||
            (user.usuario_id !== 17 && data.estipulante_id === 19) ? null : (
              <Button onClick={handleClickOpen}>Resolver Ticket</Button>
            )}

            {details.status_id === 3 || details.status_id === 7 ? null : (
              <Button onClick={handleClickConfirm}>Arquivar ticket</Button>
            )}

            {details.status_id === 7 ? (
              <Button onClick={handleReopen}>Reabrir ticket</Button>
            ) : null}
          </div>

          {openEditor === true ? (
            <TextEditor
              handleSendMessage={sendMessageCorretor}
              editorState={editorState}
              setEditorState={setEditorState}
              files={files}
              setFiles={setFiles}
            />
          ) : null}
        </div>
      </section>
    </Div>
  );
};

export default TicketMessages;
