import http, { BROKERAGE_UUID } from 'services/http';

const getAll = async () => {
  const res = await http.get('/corretor/negocios');

  return res;
};

const updateStageByBusinessId = async (data) => {
  const res = await http.put('/corretor/negocios/estagio', data);

  return res;
};

const updateResponsableByBusinessId = async (data) => {
  const res = await http.put('/corretor/negocios', data);

  return res;
};

const updateDescriptionByBusinessId = async (data) => {
  const res = await http.put('/corretor/negocios', data);

  return res;
};

const updateClosedContractByBusinessId = async (data) => {
  const res = await http.put('/corretor/contrato/cancelar', data);

  return res;
};

const createNewBusiness = async (data) => {
  const res = await http.post('/corretor/negocios', data);

  return res;
};

const createConclusionDateToNewBusinessId = async (data) => {
  const res = await http.post('/corretor/contrato/cancelar', data);

  return res;
};

const insertInsuranceCompanyByCompanyId = async (data) => {
  const res = await http.post('/corretor/negocios/seguradoras', data);

  return res;
};

const updateInsuranceStatusByInsuranceId = async (data) => {
  const res = await http.put('/corretor/negocios/seguradoras', data);

  return res;
};

const deleteBusiness = async (item) => {
  const res = await http.delete('/corretor/negocios', {
    data: {
      negocio_item: item
    }
  });

  return res;
};

const modules = {
  getAll,
  updateStageByBusinessId,
  updateResponsableByBusinessId,
  updateDescriptionByBusinessId,
  updateClosedContractByBusinessId,
  createNewBusiness,
  createConclusionDateToNewBusinessId,
  insertInsuranceCompanyByCompanyId,
  updateInsuranceStatusByInsuranceId,
  deleteBusiness
};

export default modules;
