import styled from 'styled-components';
import colors from 'styles/colors';

export const DrawerNegociosWrapper = styled.div`
  width: 1065px;

  .drawer_header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 5px;
    padding: 20px;
    padding-left: 65px;
    background-color: ${colors.genoaBlue};
    height: 110px;
    color: ${colors.white};
  }

  .header_title {
    font-family: Avenir Next Geo W05 Heavy;
  }

  .header_subtitle {
    font-family: Avenir Next Geo W05 Medium;
    font-size: 0.9em;
  }

  .seguradora_nome {
    padding-top: 6px;
    margin-top: 6px;
    border-top: 1px solid white;
    width: max-content;
    font-size: 0.9em;
  }

  .drawer_content {
    display: flex;
    flex-direction: column;
    row-gap: 15px;

    padding: 40px;
    padding-left: 65px;
  }

  .primeira_linha {
    display: flex;
    justify-content: space-between;
  }

  .primeira_coluna {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  .estagio_select {

    select {
      width: 250px;
      appearance: none;
      border: 1px solid ${colors.genoaGrey};
      padding: 5px 12px 5px 5px;
      background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 9L0.73686 0.75L10.2631 0.75L5.5 9Z' fill='%23D9D9D9'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      
      background-position-x: 94%;
      background-position-y: center;
      background-size: 8px;
      border-radius: 20px;

      :focus-visible {
        outline: 1px ${colors.genoaLightBlue}f5 auto;
      }
    }
  }

  .column-info {
    display: flex;
    column-gap: 40px;
    font-size: 0.9em;
  }

  .span_header {
    width: 60px;
    font-family: Avenir Next Geo W05 Bold;
  }

  .segunda_coluna {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    button {
      font-family: Avenir Next Geo W05 Medium;
      border: none;
      border-radius: 10px;
      cursor: pointer;
      transition: 0.15s ease-in;
      font-size: 0.8em;
      padding: 8px 0px;
      width: 160px;

      :hover {
        filter: brightness(110%) saturate(110%);
      }
    }

    .verde {
      background-color: ${colors.green};
      color: ${colors.white};
    }

    .cinza {
      background-color: ${colors.lightGrey};
      color: ${colors.white};
    }

    .vermelho {
      background-color: ${colors.red};
      color: ${colors.white};
    }
  }

  .segunda_linha {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    font-size: 0.9em;
  }

  .header_atividades {
    font-family: Avenir Next Geo W05 Bold;
  }

  .p-top {
    padding-top: 25px;
  }

  .conteiner_descricao_atividade {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .input_descricao_atividade {
    border-radius: 16px;
    border: 1px solid;
    padding: 12px;
    font-family: Avenir Next Geo W05 Regular;
    top: 5px;
    height: 28px;
    width: calc(100% - 50px);

    :focus-visible {
      outline: 1px ${colors.genoaLightBlue}f5 auto;
    }
  }

  .datepicker_containers {
    display: flex;
    align-items: center;
  }

  .custom_datepicker {
    border: none;
    border-bottom: 1px solid ${colors.genoaBlue};
    display: inline-block;
    overflow: hidden;
    font-family: 'Avenir Next GEO W05 Regular';
    height: 22px;

    width: 80px;
  }

  .active {
    border-bottom: 1px solid ${colors.genoaBlue};
  }

  .react-datepicker__day--selected:hover {
    color: white;
    background-color: ${colors.genoaBlueHover};
    font-weight: bold;
  }

  .react-datepicker__day--selected {
    color: white;
    background-color: ${colors.genoaBlue};
    font-weight: bold;
  }
  .react-datepicker__day--keyboard-selected:hover {
    color: ${colors.genoaBlue};
    background-color: #f0f0f0;
  }

  .react-datepicker__day--keyboard-selected {
    color: ${colors.genoaBlue};
    background-color: white;
    font-weight: bold;
  }


  .react-datepicker__triangle::after{
    padding: 0px;
  }

  .select {
    align-self: center;
    appearance: none; 
    border: none;
    font-family: Avenir Next Geo W05 Regular;
    width: 130px;
    height: 20px;

    .select__trigger {
      border-bottom: none;
      background-position-y: 3px;
      height: 24px;

      :hover{
        border-bottom: none;
      }
    }
  }


  .select__trigger {
    padding-bottom: 0px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 9L0.73686 0.75L10.2631 0.75L5.5 9Z' fill='%23456AED'/%3E%3C/svg%3E%0A");
    background-position-x: 100%;
    background-position-y: 50%;

    :focus {
      outline: none;
    }
  }

  option:hover {
    background-color: red;
    color: yellow;
  }


  .options {
    display: inline-block;
    font-size: 0.95em;
    
    row-gap: 2px;
    border: none;

    ::-webkit-scrollbar-thumb {
      background: ${colors.genoaBlue};
    }
  }

  .lista_atividades {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  .buttons_container {
    display: flex;
    column-gap: 20px;
  }

  .primeira_coluna_atividades {
    display: grid;
    grid-template-columns: 50px 4fr 100px 2fr 1fr;
    column-gap: 20px;

    .close_edit_btn {
      color: red;
    }

    button {
      border: none;
      background: none;
      cursor: pointer;
    }

    button.save_observacoes_btn {
      background-color: ${colors.genoaBlue};
    }

    button.disabled {
      background-color: ${colors.genoaDisabledGrey};
    }
  }

  // .button_atividade {
  //   background: none;
  //   color: inherit;
  //   border: none;
  //   padding: 0;
  //   font: inherit;
  //   cursor: pointer;
  //   outline: inherit;
  //   font-family: Avenir Next Geo W05 Demi;
  //   display: flex;
  //   align-items: center;
  //   column-gap: 3px;
  //   color: ${colors.genoaLightBlue};

  //   svg {
  //     font-size: 0.9em;
  //     transform: translateY(1px);
  //   }

  //   :hover {
  //     cursor: pointer;
  //   }
  // }

  .check {
    font-size: 0.8em;
    color: ${colors.genoaDarkBlue};
  }

  .trash {
    font-size: 0.9em;
    color: ${colors.genoaDarkBlue};
  }

  .plus_atividade {
    font-family: 'Avenir Next GEO W05 Demi';
    border: none;
    cursor: pointer;
    color: ${colors.genoaBlueHover};
    background: none;
    transition: 0.1s ease-in;
  }

  .atividade_grey {
    color: ${colors.genoaGrey};
  }

  .atividade_green {
    font-family: Avenir Next Geo W05 Demi;
    color: #008631;
  }

  .atividade_yellow {
    font-family: Avenir Next Geo W05 Demi;
    color: #FFF266;
  }

  .atividade_red {
    font-family: Avenir Next Geo W05 Demi;
    color: #FF1919; 
  }

  .segunda_coluna_atividades {
    display: grid;
    grid-template-columns: 15px 350px 80px 125px 30px;
    column-gap: 20px;

    button {
      border: none;
      background: none;
      cursor: pointer;
    }
  }
  
  .check_concluidas {
    font-size: 0.95em;
    color: ${colors.green};
  }

  .terceira_linha {
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    font-size: 0.9em;
  }

  .lista_seguradoras {
    display: grid;
    grid-template-columns: 330px 330px;
    column-gap: 90px;
    row-gap: 10px;

    .seguradora_option {
      display: grid;
      grid-template-columns: 150px 160px;
      column-gap: 20px;

      select {
        width: 100%;
        appearance: none;
        border: 1px solid ${colors.genoaGrey};
        padding: 5px 12px 5px 5px;
        background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 9L0.73686 0.75L10.2631 0.75L5.5 9Z' fill='%23D9D9D9'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        
        background-position-x: 94%;
        background-position-y: center;
        background-size: 8px;
        border-radius: 20px;

        :focus-visible {
          outline: 1px ${colors.genoaLightBlue}f5 auto;
        }
      }
    }
  }

  .quarta_linha {
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    font-size: 0.9em;

    padding-bottom: 100px;
  }

  textarea {
    resize: none;
    padding: 16px;
    border-radius: 16px;
    font-family: Avenir Next Geo W05 Regular;

    :focus-visible {
      outline: 1px ${colors.genoaLightBlue}f5 auto;
    }

    ::-webkit-scrollbar {
      width: 3px;
      padding: 16px 0px;
      background: rgba(0, 0, 0, 0);
    }
    ::-webkit-scrollbar-thumb {
      background: ${colors.rhLightGrey};
      padding: 16px 0px;
      box-shadow: none;
      
    }
    ::-webkit-scrollbar-track {
      margin-top: 16px;
      margin-bottom: 16px;
      box-shadow: inset 0 0 2px rgb(0, 0 , 0, 0);
      
    }
  }

  .container_button {
    height: 25px;
  }

  .save_observacoes_btn {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    background-color: ${colors.genoaBlue};
    color: ${colors.white};
    padding: 4px 8px;
    font-family: "Avenir Next Geo W05 Demi";
    text-transform: uppercase;
    font-size: 0.9em;
    border-radius: 5px;
  }

`