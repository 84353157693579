import styled from 'styled-components';
import colors from 'apps/broker/styles/colors';

export const Wrapper = styled.div`
  display: flex;
  // width: calc(100% - 80px);

  .animeLeft {
    opacity: 0;
    transform: translateX(-20px);
    animation: animeLeft 0.3s forwards;
  }
  @keyframes animeLeft {
    to {
      opacity: 1;
      transform: initial;
    }
  }
  .loading {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 10vh;
    gap: 15px;
    span {
      font-size: 1rem;
    }
  }
  .loading-spinner {
    margin-bottom: 15px;
    color: ${colors.genoaBlue};
  }
`;

export const Content = styled.div`
  width: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  flex: 1;
  //color: #3B3838;

  .main-painel {
    // max-width: 1400px;
    // margin: auto;
    //padding: 20px 100px;
  }

  .estipulantes-multiple-checkbox-row {
    display: flex;
    max-height: 130px;
    margin-top: 12px;
    gap: 5px;
    flex-wrap: nowrap;
    .checkbox-container {
      white-space: nowrap;
    }

    .caroussel-container {
      display: flex;
      align-items: center;

      span {
        padding: 5px;
      }

      .caroussel-items-column {
        display: flex;
        max-width: 760px;
        overflow-x: hidden;
        margin-right: 8px;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
      }

      .caroussel-item {
        white-space: nowrap;
      }

      svg {
        margin-top: -2px;
        padding: 5px;
        :hover {
          cursor: pointer;
          padding: 5px;
          background-color: #f0f0f0;
          border-radius: 17px;
        }
      }
    }

    label {
      font-size: 0.7rem;
      line-height: 13px;
      font-family: 'Avenir Next GEO W05 Regular';
    }

    ::-webkit-scrollbar {
      width: 4px;
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: ${colors.genoaDarkBlue};
      box-shadow: none;
      border-radius: 14px;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 2px grey;
      border-radius: 14px;
    }

    .produto-seguradora-nome-label {
      font-size: 0.7rem;
    }
  }

  #sentinela {
    width: 100%;
    height: 200px;
    margin-bottom: 10px;
    background: ${colors.genoaBackgroundGrey};
  }

  #sentinela-loading {
    width: 100%;
    height: 200px;
    margin-bottom: 10px;
    background: ${colors.genoaBackgroundGrey};
  }
`;
export const Consult = styled.section`
  display: flex;
  align-items: center;
  margin-bottom: 1.7rem;
  width: 100%;

  .title {
    width: 100%;
    margin-right: 10px;
    color: ${colors.genoaGrey};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
    h1 {
      font-family: 'Avenir Next GEO W05 Heavy';
      font-size: 1.2rem;
    }

    > button {
      background: none;
      border: none;
      border-radius: 1rem;
      display: flex;
      align-items: center;
      gap: 0.25rem;
      padding: 0.25rem 0.5rem;
      color: #455b71;
      font-size: 1rem;
      cursor: pointer;
      transition: all 0.4s;
      font-family: 'Avenir Next GEO W05 Regular';
      font-weight: 600;

      > svg {
        color: #455b71;
        transition: all 0.4s;
        font-size: 1.5rem;
      }

      &:hover {
        // background: ${colors.genoaBlue};
        // color: #fff;
        text-decoration: underline;

        // > svg {
        //   color: #fff;
        // }
      }
    }
  }
`;

export const Filter = styled.div`
  display: flex;
  justify-content: flex-end;

  margin-bottom: 10px;

  .filter-button {
    cursor: pointer;
    font-family: 'Avenir Next GEO W05 Demi';
    display: flex;
    background: none;
    border: none;
    font-size: 1rem;
    color: #3b3838;
    align-items: center;
    gap: 6px;
  }
`;
export const FormLabel = styled.div`
  padding: 10px;
  font-family: Avenir Next GEO W05 Demi;
`;

export const SearchBeneficiarios = styled.div`
  font-family: Avenir Next GEO W05 Demi;
  display: grid;
  grid-template-columns: 350px 1fr;
  column-gap: 30px;
  margin-bottom: 15px;
  height: 180px;
  width: max-content;

  #status-label {
    // margin-left: 9px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
  }

  .edit-buttons-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: end;
    .button-edit,
    .delete-button {
      margin-bottom: 7px;
    }
  }

  .selectbox-checkbox-options {
    display: flex;
    flex-direction: column;

    margin-bottom: 20px;

    select {
      font-family: 'Avenir Next GEO W05 Regular';
      padding: 10px;
      width: 307px;
      border-radius: 8px;
      border: 1px ${colors.genoaBlue} solid;
      margin-top: 12px;

      :focus-visible {
        outline: 1px ${colors.genoaBlue}f5 auto;
      }
    }
  }

  .paper-subestipulante-checkboxes {
    width: 402px;
    height: 80px;
    overflow-y: auto;
    background-color: ${colors.white};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    white-space: nowrap;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;

    ::-webkit-scrollbar {
      width: 4px;
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #grey;
      box-shadow: none;
      border-radius: 14px;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 2px grey;
      border-radius: 14px;
    }

    .paper-container {
      padding-top: 5px;
    }

    .checkbox-option {
      padding: 0px;
      padding-right: 5px;
    }

    .option-span-container {
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 2.5px;
      padding-top: 2.5px;
      max-width: 370px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .option-span {
      font-family: 'Avenir Next GEO W05 Regular';
      font-size: 0.8rem;
      max-width: 320px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .selectbox {
    .title-label {
      margin-left: 10px;
    }

    .selectbox-first-row-container {
      display: grid;
      // grid-template-columns: 760px 210px;
      column-gap: 20px;
    }

    .planos-contratos-container {
      margin-top: 25px;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
    }

    display: flex;
    flex-direction: column;

    .status-options-container {
      display: flex;
      gap: 15px;
      margin-top: 20px;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
    }

    .checkbox-container {
      font-size: 0.8rem;
      line-height: 13px;

      .radio-input-container {
        display: flex;
        align-items: center;
        font-size: 0.7rem;

        span {
          padding: 0;
        }
      }

      label {
        margin-left: 5px;
        font-family: 'Avenir Next GEO W05 Regular';
        height: max-content;
      }
    }

    .select-icon {
      margin-left: -30px;
      transform: translateY(3px);
      color: ${colors.genoaBlue};
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      font-family: 'Avenir Next GEO W05 Regular';
      padding: 10px;
      padding-left: 20px;
      padding-right: 35px;
      background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 9L0.73686 0.75L10.2631 0.75L5.5 9Z' fill='%23456AED'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position-x: 96%;
      background-position-y: 12px;
      width: 350px;
      border-radius: 20px;
      border: none;
      font-size: 0.7rem;
      margin-top: 12px;
      :focus-visible {
        outline: 1px ${colors.genoaBlue}f5 auto;
      }
    }
  }

  .buttonSearch {
    display: flex;
    align-items: center;

    .searchFilter {
      display: flex;
      align-items: center;
      background: transparent;
      color: ${colors.genoaBlue};

      padding: 0.5rem;
      border-radius: 100%;
      border: 1px solid ${colors.genoaBlue};

      font-size: 1.5rem;
      cursor: pointer;

      transition: all 0.2s;

      &:hover {
        background: ${colors.genoaBlue};
        color: ${colors.white};
      }
    }
  }

  .button-edit {
    display: flex;
    align-items: center;
    background-color: ${colors.genoaGrey};
    border: 0;
    margin: 0;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    color: ${colors.white};
    justify-content: center;
    &:hover {
      cursor: pointer;
    }
  }

  .button-edit > span {
    margin-right: 10px;
  }

  .delete-button {
    margin-left: 30px;
    color: ${colors.genoaDarkBlue};
    &:hover {
      cursor: pointer;
    }
  }

  .paper-popup-delete-confirmation {
    width: 500px;
    height: 80px;
    background-color: ${colors.white};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .popup-options-container {
    margin-top: 5px;
    font-family: 'Avenir Next GEO W05 Regular';
  }

  .popup-message {
    font-weight: bold;
  }

  .popup-options {
    margin-left: 10px;
    margin-right: 10px;
    align-itens: center;

    &:hover {
      font-weight: bold;
      cursor: pointer;
    }
  }

  .check-icon {
    margin-right: 2px;
    color: green;
  }

  .cross-icon {
    margin-right: 2px;
    color: red;
  }

  .inputSearch {
    margin-top: 30px;
    .search {
      display: flex;
      flex-direction: column;

      .input-button {
        display: flex;

        align-items: center;

        input {
          font-family: 'Avenir Next GEO W05 Regular';
          padding: 10px;
          padding-left: 20px;
          background: white;
          width: 350px;
          border-radius: 20px;
          border: none;
          margin-top: 12px;
          font-size: 0.8rem;

          :focus-visible {
            outline: 1px ${colors.genoaBlue}f5 auto;
          }
        }
        input.error {
          border: 1px #ff5959 solid;
        }
        .btn-search {
          margin-left: 10px;
          margin-top: 10px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          height: fit-content;
          padding: 8px;
          background: ${colors.genoaBlue};
          color: #fff;
          border: none;
          border-radius: 50%;
          border: 2px ${colors.genoaBlue} solid;

          :hover {
            border: 2px #ba97f7 solid;
          }
        }
      }
    }
  }
  .inputStatus {
    display: flex;
    flex-direction: column;

    select {
      font-family: 'Avenir Next GEO W05 Regular';
      padding: 10px;
      width: 100%;
      border-radius: 8px;
      border: 1px ${colors.genoaBlue} solid;
      margin-top: 12px;

      :focus-visible {
        outline: 1px ${colors.genoaBlue}f5 auto;
      }
    }
  }

  .first-row-container {
    height: 180px;
  }
`;

export const ModalRemoveBeneficiary = styled.main`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  h1 {
    font-size: 1rem;
    font-weight: normal;
    color: #3b3838;

    > span {
      text-transform: capitalize;
      border-bottom: 1px solid ${colors.genoaBlue};
    }
  }

  .text-error {
    color: #d32f2f;
    font-family: 'Avenir Next GEO W05 Demi';
  }

  .text-success {
    color: #388e3c;
    font-family: 'Avenir Next GEO W05 Demi';
  }

  div {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    button {
      border: none;
      border-radius: 0.5rem;

      padding: 0.5rem;

      transition: filter 0.4s;
      cursor: pointer;

      &:hover {
        filter: brightness(0.9);
      }
    }
    button:nth-of-type(1) {
      background: #d32f2f;
      color: #fff;
    }

    button:nth-of-type(2) {
      background: #cfd8dc;
      color: #3b3838;
    }
  }
`;
