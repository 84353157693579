import styled from "styled-components";
import colors from "styles/colors";

export const HeaderTicket = styled.section`

  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;

  h1 {
    margin-bottom: 0;
  }

  .search {
    width: 256px;
    margin-left: 2rem;


    input {
      width: 100%;
      border: 1px solid ${colors.purple};
      border-radius: 1rem;
      padding: 0.375rem 0.5rem;
      font-size: 1rem;
      text-align: center;

      transition: all 0.4s;

      &:focus {
        outline: 1px ${colors.lightBlue};
        border: 1px solid ${colors.lightBlue};
      }
    }
  }
  
  .btnFilters {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;


    .searchFilter {
    display: flex;
    align-items: center;
    background: transparent;
    color: ${colors.purple};

    padding: 0.5rem;
    border-radius: 100%;
    border: 1px solid ${colors.purple};

    font-size: 1.5rem;
    cursor: pointer;

    transition: all 0.2s;

    &:hover {
      background: ${colors.purple};
      color: ${colors.lightBlue};
    }
  }

  .resetFilters {

    color: ${colors.lightBlue};
    font-size: 0.75rem;

  }

  .resetNone {
    content: "* Filtros resetados.";
  }
  }
  
`

export const Container = styled.div`
  

  .box-single-ticket {
    display: flex;
    justify-content: space-between;
    box-shadow: -3px 3px 4px rgba(0, 0, 0, 0.25);

    padding: 1rem 2rem;

    border: 1px solid rgb(110, 91, 205);
    border-radius: 1rem;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      color: #3b3838;

      h3 {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        gap: 0.5rem;
        font-size: 1.25rem;
      }
      p {
        display: flex;
        align-items: center;
        gap: 0.3rem;
        font-size: 1rem;
      }
    }

    .cols {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      text-align: left;

      min-width: 300px;

      ul {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 0.5rem;

        li {
          display: flex;
          flex-direction: row;
          align-items: center;

          font-size: 1.25rem;
          gap: 0.25rem;
        }

        .box-details {
          color: ${colors.lightBlue};
          cursor: pointer;
          align-items: center;

          transition: filter 0.2s;

          &:hover {
            text-decoration: underline;
            filter: brightness(0.7);
          }
          &:before {
            content: "";
            width: 1.25rem;
            height: 1.25rem;
          }
        }
      }
    }

    & + main {
      margin-top: 1.5rem;
    }
  }

  @media (max-width: 900px) {
    .box-single-ticket {
      > div {
        h3 {
          font-size: 1rem;
        }
        p {
          font-size: 0.75rem;
        }
      }
      .cols {

        min-width: 240px;
        ul {
          li {
            font-size: 1rem;
          }
          .box-details {
            &:before {
              width: 1rem;
              height: 1rem;
            }
          }
        }
      }

    }
  }

  @media (max-width: 720px) {
    h1 {
      font-size: 1.25rem;
    }

    .box-single-ticket {
      padding: 0.5rem 1rem;

      > div {
        h3 {
          font-size: 0.75rem;
        }
        p {
          font-size: 0.625rem;
        }
      }
      .cols {
        ul {
          li {
            font-size: 0.75rem;
          }
          .box-details {
            &:before {
              width: 0.75rem;
              height: 0.75rem;
            }
          }
        }
      }

    }
  }
`;

export const Filter = styled.div`
  text-align: end;
  padding: 1rem;

  .options {

    div {
      .filter-button {
        background: transparent;
        border: none;
        svg {
          position: relative;
          top: 6px;
        }
      }
    }
  }
`;

export const ContentList = styled.div`
  .options {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 2rem;

    padding: 1rem;
  }

  .text-icon {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    cursor: pointer;
  }
`;
