import { ClickAwayListener } from "@material-ui/core";
import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { InputFileComponent, InputFileComponentWrapper } from "./styles-inputFileCamposInclusao";

const InputFileCamposInclusao = (
  {
    value,
    setValue,
    label,
    name,
  }) => {

  const [focused, setFocused] = React.useState(false);
  const ref = React.useRef();

  const renderInputFileClassname = () => {
    if (value) {
      return "focused"
    }
    if (!value) {
      if (focused) {
        return "focused"
      }
      return "empty"
    }
  }

  const resetInputFile = () => {
    const target = {
      name,
      files: undefined
    }
    const event = {
      target
    }
    setValue(event)

    ref.current.value = ""
  }

  return (
    <ClickAwayListener onClickAway={() => setFocused(false)}>
      <InputFileComponentWrapper>
        <InputFileComponent>
          <label className={value || focused ? "translate" : ""}>{label}</label>
          <input
            type="file"
            ref={ref}
            className={renderInputFileClassname()}
            onChange={setValue}
            onClick={() => setFocused(true)}
            name={name}
            autoComplete="new-telephone"
          />

        </InputFileComponent>
        {
          value ? (
            <AiOutlineCloseCircle
              className="remove-file-icon"
              size={14}
              onClick={resetInputFile}
            />
          ) : <></>
        }
      </InputFileComponentWrapper>
    </ClickAwayListener>

  )
}

export default InputFileCamposInclusao;
