import styled from 'styled-components';
// import media from 'styled-media-query'
import colors from 'styles/colors';

export const Wrapper = styled.div `
  display: flex;

  .animeLeft {
      opacity: 0;
      transform: translateX(-20px);
      animation: animeLeft 0.3s forwards;
  }
  @keyframes animeLeft {
    to {
      opacity: 1;
      transform: initial;
    }
  }
`
export const Content = styled.div `
  width: calc(100% - 80px);

  .usuarios-list-wrapper {
    background-color: red;
  }

`

export const Consult = styled.section`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  width: 100%;

  .title {
    width: 100%;
    margin-right: 10px;
    color: #3b3838;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

    h1 {
      font-family: "Avenir Next GEO W05 Heavy";
      font-size: 1.2rem;
    }

    .add-user-button {
      color: ${colors.genoaDarkBlue};
      display: flex;
      align-items: center;

      svg {
        position: relative;
        top: 1px;
      }

      :hover {
        cursor: pointer;
      }
    }

  }
`;

export const UsuariosListWrapper = styled.div`

  .text-center-movement {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    color: #3b3838;
    font-size: 1.125rem;
    margin-top: 10vh;

    .circular-progress-loading {
      color: ${colors.rhBlue};
      margin-bottom: 30px;
    }
  }

  table {
    width: 100%;
    border-spacing: 0;

    thead {
      display: grid;
      grid-template-columns: 1.5fr 2fr 1fr 0.7fr;
      height: 49px;
      padding-bottom: 10px;
      align-items: center;

      th {
        text-align: left;
        font-size: 16px;
        font-family: "Avenir Next GEO W05 Demi";
        color: ${colors.rhBlue};
      }

      th:nth-child(1) {
        padding-left: 20px;
      }

    }

    tbody {
      display: flex;
      flex-direction: column;
      row-gap: 25px;

      tr {
        display: grid;
        grid-template-columns: 1.5fr 2fr 1fr 0.7fr;
        background-color: ${colors.white};
        border-radius: 15px;

        td {
          font-size: 15px;
          display: flex;
          align-items: center;
          height: 50px;

          .buttons-container {
            display: flex;
            align-items: center;
            column-gap: 35px;

            button.btn {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 4px;
              border: none;
              font-size: 1.3rem;
              cursor: pointer;
              background: none;
              transition: 0.1s ease-in;
              border-radius: 50%;
      
              :hover {
                background: rgb(204 204 204 / 25%);
              }
            }
            
            .close {
              color: ${colors.red};
            }

            .edit {
              color: ${colors.genoaDarkBlue};
            }

            .icon {
              width: 28.80px;
            }

          }
          
        }

        td:nth-child(1) {
          padding-left: 20px;
        }
      }

    }
  }
`