import { Button, Divider, makeStyles, SwipeableDrawer } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '0.5rem 0.7rem'
  },
  closeButton: {
    borderRadius: '50%',
    padding: '0.8125',
    minWidth: 0,
  },
  drawerPaper: {
    width: '100%',
    maxWidth: 600
  },
}));

export default function Drawer({ open = false, onClose, onOpen, children, ...rest }) {
  const { window } = rest;
  const classes = useStyles();

  // const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <SwipeableDrawer
      container={container}
      anchor='right'
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      // disableBackdropTransition={!iOS} 
      // disableDiscovery={iOS}
      ModalProps={{
        keepMounted: true,
      }}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <div className={classes.header}> 
        <Button 
          className={classes.closeButton} 
          onClick={onClose}
        >
          <ChevronRight fontSize="large" />
        </Button>
      </div>
      <Divider />
      {children}
    </SwipeableDrawer>
  )
}