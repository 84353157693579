import { useState, useEffect } from 'react';
import SelectStandard from '../../../../components/Select';
import { DrawerContent } from './styles';
import { DarkBackground } from '../CreateNewBusiness/styles';
import { ThemeProvider, createTheme } from '@mui/material';
import { ClickAwayListener } from '@material-ui/core';
import ReactDatePicker from 'react-datepicker';
import { toast } from 'react-hot-toast';
import services from 'apps/broker/services';

const theme = createTheme({
  palette: {
    genoaBlue: {
      main: '#456AED'
    },
    darkGenoaBlue: {
      main: '#1E2552'
    }
  }
});

const DrawerNovaAtividade = ({
  setNewActivity,
  corretores,
  isNewActivity,
  getNegociosData,
  setNewAtividadeNegocio
}) => {
  const [transitionLeave, setTransitionLeave] = useState(false);
  const [optionsResponsavel, setOptionsResponsavel] = useState([]);
  const [responsavelSelecionado, setResponsavelSelecionado] =
    useState('0');
  const [dataAtividade, setDataAtividade] = useState(new Date());
  const [descricaoAtividade, setDescricaoAtividade] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  const criarAtividadeHandler = async () => {
    const body = {
      negocio_id: isNewActivity.id,
      descricao: descricaoAtividade,
      usuario_id: responsavelSelecionado,
      data_agendada: dataAtividade
    };

    const response = await services.activities.createByBusinessId(body);

    if (response.status === 201) {
      toast.dismiss();
      toast.success('Atividade adicionada com sucesso!');
      getNegociosData();
    } else {
      toast.dismiss();
      toast.error('Algo deu errado, tente novamente mais tarde!');
    }
  };

  const selectValueChangeHandler = (object) => {
    setResponsavelSelecionado(object.value);
  };

  useEffect(() => {
    const naoAlocado = {
      value: '0',
      option: 'Selecione'
    };
    if (corretores?.length) {
      const responsaveis = corretores?.map((item) => ({
        value: item.usuario_id,
        option: `${item.nome}`
      }));

      setOptionsResponsavel([naoAlocado, ...responsaveis]);
    }
  }, [corretores]);

  useEffect(() => {
    if (responsavelSelecionado !== '0' && dataAtividade && descricaoAtividade) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [responsavelSelecionado, dataAtividade, descricaoAtividade]);

  return (
    <DarkBackground
      style={{
        animation: transitionLeave ? 'fade-out 0.225s forwards' : ''
      }}
    >
      <ClickAwayListener
        onClickAway={() => {
          setTransitionLeave(true);
          setTimeout(() => {
            setNewActivity(undefined);
          }, 200);
        }}
      >
        <DrawerContent className={transitionLeave ? 'leave' : ''}>
          <div className="title">
            <span>Nova Atividade</span>
          </div>
          <div className="form_container">
            <div className="form_option">
              <div className="form_option_title">Responsável</div>
              <ThemeProvider theme={theme}>
                <div className="custom_select">
                  <SelectStandard
                    name={'responsaveis'}
                    options={optionsResponsavel}
                    value={responsavelSelecionado}
                    setValue={selectValueChangeHandler}
                  />
                </div>
              </ThemeProvider>
            </div>
            <div className="form_option">
              <div className="form_option_title">Data Agendada</div>
              <ThemeProvider theme={theme}>
                <ReactDatePicker
                  className={`custom_datepicker ${
                    dataAtividade ? 'active' : ''
                  }`}
                  selected={dataAtividade}
                  locale="ptBR"
                  minDate={new Date()}
                  onChange={(data) => setDataAtividade(data)}
                  dateFormat="dd/MM/yyyy"
                />
              </ThemeProvider>
            </div>
            <div className="form_option">
              <div className="textarea_container">
                <div className="form_option_title">Descrição da atividade</div>

                <textarea
                  rows={2}
                  maxLength={255}
                  value={descricaoAtividade}
                  onChange={(e) => setDescricaoAtividade(e.target.value)}
                />
              </div>
            </div>
            <div className="button_container">
              <button
                className={`criar_btn ${isDisabled ? 'disabled' : ''}`}
                onClick={isDisabled ? () => {} : criarAtividadeHandler}
              >
                Criar
              </button>
            </div>
          </div>
        </DrawerContent>
      </ClickAwayListener>
    </DarkBackground>
  );
};

export default DrawerNovaAtividade;
