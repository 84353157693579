import { useState } from 'react';
import DatePicker from 'react-datepicker';
import { MenuItem, FormControl, Select, SvgIcon } from '@material-ui/core';
import { BiSortDown } from 'react-icons/bi';
import { FilterList } from './styles';

const FilterCardList = ({
  classes,
  ticketsFilter,
  setTicketsFilter,
  addFilters,
  setAddFilters,
  corretores,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  allEstipulantes,
  handleAllFiltersSearch
}) => {
  const [ordered, setOrdered] = useState(false);

  const orderBy = () => {
    let order = ticketsFilter.sort((a, b) => {
      let dateA = new Date(a.created_at).getTime();
      let dateB = new Date(b.created_at).getTime();
      if (!ordered) {
        return dateA > dateB ? 1 : -1;
      }
      return dateA < dateB ? 1 : -1;
    });
    setTicketsFilter([...order]);
    setOrdered(!ordered);
  };

  const naoAlocadosClickHandler = () => {
    setAddFilters((prevState) => {
      if (!prevState.naoAlocados) {
        return {
          id_ticket: '',
          status: 'all',
          corretor: 'all',
          ticket: 'tickets',
          dateAtuation: 'all',
          estipulante: prevState.estipulante,
          naoAlocados: true
        };
      } else {
        return {
          ...prevState,
          naoAlocados: false
        };
      }
    });
  };

  const customSvgIcon = (props) => {
    return (
      <SvgIcon {...props} className={classes.icon}>
        <path d="M5.5 9L0.73686 0.75L10.2631 0.75L5.5 9Z" fill="#456AED" />
      </SvgIcon>
    );
  };

  return (
    <FilterList>
      <div className="filters">
        <div className="options">
          <div className="filters-options-container">
            <div>
              <FormControl className={classes.formControl}>
                <Select
                  value={addFilters.ticket}
                  name="ticket"
                  onChange={(e) => handleAllFiltersSearch(e)}
                  displayEmpty
                  IconComponent={customSvgIcon}
                  autoWidth={true}
                  disableUnderline={true}
                  className={classes.select}
                >
                  <MenuItem className={classes.select} value="tickets">
                    Tickets
                  </MenuItem>
                  <MenuItem className={classes.select} value="Movimentação">
                    Movimentação
                  </MenuItem>
                  <MenuItem className={classes.select} value="Rh">
                    Outras Demandas
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <Select
                  value={addFilters.status}
                  name="status"
                  onChange={(e) => handleAllFiltersSearch(e)}
                  displayEmpty
                  disableUnderline={true}
                  IconComponent={customSvgIcon}
                  className={classes.select}
                >
                  <MenuItem className={classes.select} value="all">
                    Aberto
                  </MenuItem>
                  <MenuItem className={classes.select} value={1}>
                    Aguardando corretora
                  </MenuItem>
                  <MenuItem className={classes.select} value={4}>
                    Aguardando RH
                  </MenuItem>
                  <MenuItem className={classes.select} value={3}>
                    Resolvido
                  </MenuItem>
                  <MenuItem className={classes.select} value={7}>
                    Arquivado
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <Select
                  value={addFilters.corretor}
                  name="corretor"
                  onChange={(e) => handleAllFiltersSearch(e)}
                  displayEmpty
                  disableUnderline={true}
                  IconComponent={customSvgIcon}
                  className={classes.selectEstipulante}
                >
                  <MenuItem
                    value="all"
                    className={classes.optionSelectCorretora}
                  >
                    Todos corretores
                  </MenuItem>
                  {corretores.map(({ id, label }) => (
                    <MenuItem
                      key={id}
                      value={id}
                      className={classes.optionSelectCorretora}
                    >
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <Select
                  value={addFilters.dateAtuation}
                  name="dateAtuation"
                  onChange={(e) => handleAllFiltersSearch(e)}
                  displayEmpty
                  disableUnderline={true}
                  IconComponent={customSvgIcon}
                  className={classes.select}
                >
                  <MenuItem className={classes.select} value="all">
                    Atuação
                  </MenuItem>
                  <MenuItem className={classes.select} value="vencido">
                    Vencido
                  </MenuItem>
                  <MenuItem className={classes.select} value="hoje">
                    Hoje
                  </MenuItem>
                  <MenuItem className={classes.select} value="amanha">
                    Amanhã
                  </MenuItem>
                  <MenuItem className={classes.select} value="periodo">
                    Período
                  </MenuItem>
                </Select>
                {addFilters.dateAtuation === 'periodo' && (
                  <div className="rangedate">
                    <DatePicker
                      className="input-data"
                      locale="ptBR"
                      selected={startDate}
                      placeholderText="Data Início"
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => {
                        setStartDate(date);
                        setEndDate(date);
                      }}
                    />
                    <DatePicker
                      disabled={startDate === ''}
                      selected={endDate}
                      minDate={startDate}
                      className="input-data"
                      locale="ptBR"
                      placeholderText="Data Final"
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => setEndDate(date)}
                    />
                  </div>
                )}
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <Select
                  value={addFilters.estipulante}
                  name="estipulante"
                  onChange={(e) => handleAllFiltersSearch(e)}
                  displayEmpty
                  IconComponent={customSvgIcon}
                  disableUnderline={true}
                  className={classes.selectEstipulante}
                >
                  <MenuItem
                    className={classes.optionsSelectEstipulante}
                    value="all"
                  >
                    Selecione um Estipulante
                  </MenuItem>
                  {allEstipulantes.map((estipulante) => (
                    <MenuItem
                      className={classes.optionsSelectEstipulante}
                      value={estipulante.razao_social}
                    >
                      {estipulante.razao_social}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <button
              className={`alocados ${
                !addFilters?.naoAlocados ? '' : 'ativado'
              }`}
              onClick={naoAlocadosClickHandler}
            >
              Não alocados
            </button>
          </div>

          <div className="data-order-container">
            <span className="text-icon" onClick={orderBy}>
              {' '}
              Data <BiSortDown />
            </span>
          </div>
        </div>
      </div>
    </FilterList>
  );
};

export default FilterCardList;
