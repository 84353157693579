import styled from "styled-components";
import colors from "styles/colors";


export const PainelWrapper = styled.div`
  background-color: white;
  padding: 20px 15px 20px 15px;

  .title {
    margin: 0;
    padding: 0;
    font-size: 0.8em;
    text-align: center;
    font-family: "Avenir Next Geo W05 Bold";
  }

  .graph__row {
    width: 100%;
    height: 181px;
    display: grid;
    grid-template-columns: 1fr 1fr;

  }

  .expand_icon {
    position: absolute;
    transform: translateY(200px);
    color: ${colors.lightGrey};

    :hover {
      cursor: pointer;
    }
  }

  .legenda__container {
    display: flex;
    justify-content: center;
    width: 100%;

    .legenda {
      position: absolute;
      transform: translateY(15px);
      display: flex;
      column-gap: 15px;

      .legenda_col {
        display: grid;
        grid-template-columns: 11px 1fr;
        column-gap: 5px;
        align-items: center;

        font-size: 0.8em;

        .hifen {
          width: 11px;
          height: 4px;
          margin-top: 4px;
        }

        .corretora {
          background-color: ${colors.genoaBlue};
        }

        .rh {
          background-color: ${colors.rhBlue};
        }
      }
    }
  }

`