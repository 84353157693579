import styled from "styled-components";
import colors from "styles/colors";

export const DrawerMessagesWrapper = styled.div`
  position: absolute;

  width: calc(800px - 50px);
  height: calc(600px - 50px);
  padding: 25px 25px;

  top: calc(50% - 300px);
  left: calc(50% - 400px);

  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.3), 0 22px 25px rgba(0, 0, 0, 0.22);
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

export const DarkenBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 101vh;
  background-color: rgba(0, 0, 0, 0.7);

  z-index: 1000;
`;

export const DrawerMessagesHeader = styled.div`
  width: 100%;
  height: 100px;
  display: grid;
  grid-template-columns: 1fr 80px;

  .second_column {
    .close_icon_container {
      display: flex;
      flex-direction: row-reverse;
      width: 100%;

      .close_icon {
        color: ${colors.genoaPink};

        :hover {
          cursor: pointer;
        }
      }
    }
  }

  .demi {
    font-family: "Avenir Next GEO W05 Demi";
  }

  .header_title {
    display: flex;
    align-items: center;
    font-size: 1em;
    padding-bottom: 8px;

    column-gap: 5px;

    .dot_icon {
      margin-bottom: -3px;
    }
  }

  .header_subtitle {
    font-size: 0.8em;

    .demi {
      color: ${colors.genoaGrey};
    }

    .ticket_dates {
      display: flex;
      align-items: center;
      column-gap: 10px;

      .dot_icon {
        margin-bottom: -3px;
      }
    }
  }
`;

export const DrawerMessagesConversation = styled.div`
  background-color: ${colors.rhTabLightGrey};
  width: calc(100%);
  height: calc(100% - 130px);

  .conversation_container {
    padding: 20px 15px 20px 19px;
    max-height: calc(100% - 40px);
    width: calc(100% - 34px);
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column-reverse;
    row-gap: 15px;

    ::-webkit-scrollbar {
      width: 4px;
      background: rgba(0, 0, 0, 0);
    }
    ::-webkit-scrollbar-thumb {
      background: ${colors.genoaBlue};
      box-shadow: none;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 2px rgb(0, 0, 0, 0);
    }
  }

  .imessage {
    border: 1px solid #e5e5ea;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    flex-direction: column;
    font-family: "Avenir Next GEO W05 Regular";
    font-size: 0.85em;
    width: max-content;
    min-width: 150px;
    max-width: 400px;

    .remetente_nome {
      font-size: 0.77em;
      font-family: "Avenir Next GEO W05 Bold";
      padding-bottom: 5px;
      margin-bottom: 9px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    }
  }

  .imessage span {
    color: white;
    overflow-wrap: break-word;
  }

  .from_client {
    display: block;
    background-color: ${colors.genoaGrey};
    background-opacity: 0.6;
    border-bottom-right-radius: 10px;
    padding: 0.8em 1.1em 1em 1.3em;
  }

  .from_attendant {
    display: block;
    background-color: ${colors.genoaBlue};
    border-bottom-left-radius: 10px;
    align-self: end;
    padding: 0.8em 1.3em 1em 1.1em;
  }
`;
