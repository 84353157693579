import styled from "styled-components";
import colors from "apps/broker/styles/colors";

export const TypeMovement = styled.p`

text-align: center;

  position: relative;
  z-index: 0;

  svg {
    color: ${props => props.type === 'Inclusão' ? '#43a047' : '#e53935'} !important;
  }

  span {
    visibility: hidden;
    width: 80px;
    background-color: ${colors.rhBlue};
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
  
    position: absolute;
    top: 100%;
    margin-left: -40px;
    z-index: 999;

    opacity: 0.8;
  }

  &:hover {
    span {
      visibility: visible;
    }
  }

`

export const Wrapper = styled.div`
  display: flex;

  .animeLeft {
    opacity: 0;
    transform: translateX(-20px);
    animation: animeLeft 0.3s forwards;
  }
  @keyframes animeLeft {
    to {
      opacity: 1;
      transform: initial;
    }
  }
  .loading {
    display: flex;
    align-items: center;
    gap: 15px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  //color: #3B3838;

  .main-painel {
    max-width: calc(100vw - 170px);
    // margin: auto;
    //padding: 20px 100px;
  }

  .filters-infos-second-row {
    margin-top: 10px;
    .MuiFormGroup-root {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
    }
    .MuiFormControl-root {
      border: 0;
      margin: 0;
      display: inline-flex;
      padding: 0;
      position: relative;
      min-width: 0;
      flex-direction: column;
    }
    .data-checkbox-container {
      height: 193px;
      display: flex;
      justify-content: end;
      flex-direction: row;
      span {
        font-size: 0.7rem;
        height: 1rem;
        display: flex;
        justify-content: center;
      }

      .checkbox-data-group-container {
        display: flex;
        margin-left: 10px;
        align-items: baseline;
        flex-direction: column;
        row-gap: 20px;

      }

      .radio-checkbox {
        color: rgb(133, 133, 133);
        margin-right: 10px;
        &.Mui-checked {
          color: ${colors.rhBlue};
        }
      }
    }

    .datepicker-box-space{
 
    }

    .datepicker-group-container {
      margin-top: 20px;
      overflow: hidden;
      height: 41px;
      transition: all .3s cubic-bezier(0.4, 0, 0.2, 1);
      display: grid;
      grid-template-columns: 90px 90px;
      column-gap: 20px;
    }

    .datepicker-group-container-hidden {
      margin-top: 10px;
      height: 0px;
      overflow: hidden;
      transition: all .3s cubic-bezier(0.4, 0, 0.2, 1);
      display: grid;
      column-gap: 20px;
      grid-template-columns: 90px 90px;
    }

    .input-data {
      width: 90px;
      height: 25px;
      border: 2px solid ${colors.rhBlue};
      display: inline-block;
      text-align: center;
      font-family: 'Avenir Next GEO W05 Regular';
      border-radius: 20px;
      font-size: 0.7rem;
    }

    .input-data-disabled {
      width: 90px;
      height: 25px;
      border: 2px solid ${colors.lightGrey};
      display: inline-block;
      text-align: center;
      font-family: 'Avenir Next GEO W05 Regular';
      border-radius: 20px;
      font-size: 0.7rem;
    }

    .react-datepicker-wrapper {
      display: inline-block;
      padding: 0;
      border: 0;
      width: min-content;
      
    }

    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
      background-color: white;
      font-weight: bold;
      color: ${colors.rhBlue}
    }


    .react-datepicker__day--selected, .react-datepicker__month-text--selected,  .react-datepicker__quarter-text--selected, .react-datepicker__year-text--selected {
      border-radius: 0.3rem;
      background-color: ${colors.rhBlue};
      color: #fff;
    }

    .datepicker-selector-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 0.7rem;
      span {
        font-size: 0.7rem;
        font-family: "Avenir Next GEO W05 Demi";
      }
    }
  }

  .filters-infos-second-row-hidden {
    height: 0px;
    overflow: hidden;
    transition: height 0.4s ease-in-out;
    .MuiFormGroup-root {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
    }
    .MuiFormControl-root {
      border: 0;
      margin: 0;
      display: inline-flex;
      padding: 0;
      position: relative;
      min-width: 0;
      flex-direction: row;
      align-items: flex-end;
      min-width: 1000px;
    }
    .data-checkbox-container {
      height: 193px;
      display: flex;
      justify-content: end;
      flex-direction: row;
      span {
        font-size: 0.8rem;
        height: 1rem;
        display: flex;
        justify-content: center;
      }

      .checkbox-data-group-container {
        display: flex;
        flex-direction: row;
        margin-left: 30px;
        align-items: baseline
      }

      .radio-checkbox {
        color: rgb(133, 133, 133);
        &.Mui-checked {
          color: ${colors.rhBlue};
        }
      }
    }

    .datepicker-box-space{
      margin-left: 10px;
    }

    .datepicker-group-container {
      margin-top: 10px;
      width: 300px;
      overflow: hidden;
      transition: all .6s cubic-bezier(0.4, 0, 0.2, 1);
      display: grid;
      grid-template-columns: 125px 125px;
    }

    .datepicker-group-container-hidden {
      margin-top: 10px;
      width: 0px;
      overflow: hidden;
      transition: all .6s cubic-bezier(0.4, 0, 0.2, 1);
      display: grid;
      grid-template-columns: 125px 125px;
    }

    .input-data {
      width: 100px;
      height: 25px;
      border: 1px solid ${colors.rhBlue};
      display: inline-block;
      text-align: center;
      font-family: 'Avenir Next GEO W05 Regular';
      border-radius: 7px;
    }

    .react-datepicker-wrapper {
      display: inline-block;
      padding: 0;
      border: 0;
      width: min-content;
      
    }

    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
      background-color: white;
      color: ${colors.rhBlue}
    }


    .react-datepicker__day--selected, .react-datepicker__month-text--selected,  .react-datepicker__quarter-text--selected, .react-datepicker__year-text--selected {
      border-radius: 0.3rem;
      background-color: ${colors.rhBlue};
      color: #fff;
    }

    .datepicker-selector-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      font-size: 0.7rem;
      span {
        font-size: 0.7rem;
        font-family: "Avenir Next GEO W05 Demi";
      }
    }
  }
`;

export const Main = styled.main`

  width: calc(100% - 90px);
  height: 100%;
  background: ${colors.genoaLightBackgroundGrey};
  padding: 20px 45px;


  .text-center-movement {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    color: #3b3838;
    font-size: 1.125rem;
    margin 2rem 0;
    margin-top: 15vh;

    .circular-progress-loading {
      color: ${colors.rhBlue};
      margin-bottom: 30px;
    }
  }
`;

export const Consult = styled.section`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  width: 100%;

  .title {
    width: 100%;
    margin-right: 10px;
    color: #3b3838;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
    h1 {
      font-family: "Avenir Next GEO W05 Heavy";
      font-size: 1.2rem;
    }

    > button {
      background: none;
      border: 1px solid ${colors.rhBlue};
      border-radius: 1rem;
      display: flex;
      align-items: center;
      gap: 0.25rem;
      padding: 0.25rem 0.5rem;
      color: #3b3838;
      font-size: 1rem;
      cursor: pointer;
      transition: all 0.4s;

      > svg {
        color: ${colors.rhBlue};
        transition: all 0.4s;
        font-size: 1.5rem;

      }

      &:hover {
        background: ${colors.rhBlue};
        color: #fff;

        > svg {
          color: #fff;
        }
      }

    }
  }
`;

export const FiltersInfos = styled.section`
  display: grid;
  grid-template-columns: 350px 280px 300px 200px 105px;
  grid-gap: 30px;
  align-items: end;
  color: ${colors.genoaGrey};
  margin-bottom: 15px;

  .edit-buttons-container {
    display: grid;
    grid-template-columns: 120px 120px;
    align-items: center;
    justify-content: center;
  }

  .button-edit {
    display: flex;
    align-items: center;
    background-color: ${colors.genoaGrey};
    border:0;
    margin: 0;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    color: ${colors.white};
    justify-content: center;
    &:hover {
      cursor: pointer;
    }
  }

  .delete-button {
    color: ${colors.rhBlue};
    &:hover {
      cursor: pointer;
    }
  }

  .paper-popup-delete-confirmation {
    width: 500px;
    height: 80px;
    background-color: ${colors.white};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .data-filter-options {
    margin-top: 7px;
    width: 230px;
    height: 220px;
    background-color: ${colors.white};
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .data-filter-selection {
    margin-top: 5px;
    margin-left: 10px;
    margin-top: 60px;
    border-radius: 15px;
    width: 150px;
    height: 90px;
    background-color: ${colors.white};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .popup-options-container {
    margin-top: 5px;
  }

  .popup-options {
    margin-left: 10px;
    margin-right: 10px;
    align-items: center;
    &:hover {
      font-weight: bold;
      cursor: pointer;
    }
  }

  .popup-message {
    font-weight: bold;
  }

  .check-icon {
    margin-right: 2px;
    color: green;
  }

  .cross-icon {
    margin-right: 2px;
    color: red;
  }


  div.box-input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    label {
      font-family: "Avenir Next GEO W05 Demi";
    }

    .name-input {
      font-family: "Avenir Next GEO W05 Regular";
      padding: 10px;
      height: 38px;
      padding-left: 20px;
      background: white;
      width: 350px;
      border-radius: 20px;
      border: none;
      margin-top: 20px;
      font-size: 0.8rem;
      color: ${colors.genoaGrey};

      :focus-visible {
        outline: 1px ${colors.rhBlue}f5 auto;
      }

      ::placeholder {
        color: ${colors.genoaGrey};
        font-family: 'Avenir Next GEO W05 Regular';
      }
    }

    .select-estipulante {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      font-family: "Avenir Next GEO W05 Regular";
      padding: 10px;
      padding-left: 20px;
      padding-right: 35px;
      background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 9L0.73686 0.75L10.2631 0.75L5.5 9Z' fill='%23456AED'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position-x: 96%;
      background-position-y: 12px;
      width: 350px;
      border-radius: 20px;
      border: none;
      font-size: 0.7rem;
      margin-top: 12px;
      :focus-visible {
        outline: 1px ${colors.rhBlue}f5 auto;
      }
    }

    .label-data-filters {
      margin-left: 10px;
    }

    .data-filters-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
      font-size: 0.8rem;
      font-family: 'Avenir Next GEO W05 Regular';
    }
    
  }

  .filtro-data-container {
    margin-bottom: 8px;
    width: max-content;

    :hover {
      cursor: pointer;
    }
  }

  .btn-download {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    margin-bottom: 5px;
    
    button{
      background: ${colors.rhBlue};
      color: #fff;
      padding: 0.25rem 0.5rem;
      border: none;
      border-radius: 0.5rem;
  
      font-size: 1.125rem;
      font-family: "Avenir Next GEO W05 Demi";
  
      transition: filter 0.4s;
      cursor: pointer;
  
      &:hover {
        filter: brightness(0.8);
      }
    }
  }

  .status-options-container {
    color: ${colors.genoaGrey}; 
    display: flex;
    gap: 15px;
    margin-top: 20px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
  }

  .label-title {
    color: ${colors.genoaGrey};
    margin-left: 9px;
    font-family: "Avenir Next GEO W05 Demi";
  }

  .checkbox-container {
    label {
      font-size: 0.7rem;
      line-height: 13px;
      font-family: 'Avenir Next GEO W05 Regular';
    }
  }
`

export const ContainerBeneficiary = styled.section`

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  margin-top: 30px;
  min-height: 80vh;

  .filter-data-abertura-icon {
    :hover {
      cursor: pointer;
    }
  }

  div.title-box-beneficiary {
    min-height: 42px;
    width: calc(100% - 24px);
    display: grid;
    grid-template-columns: 26px 60px 1.5fr .75fr .5fr 1.5fr .5fr .5fr 80px;
    grid-gap: 10px;
    align-items: center;

    .master-checkbox-container {
      padding-left: 16px;
      svg {
        height: 18px;
        width: 18px;
      }
    }

    .checkbox {
      width: 25px;
      height: 30px;
      margin-left: -3px;
      margin-bottom: -2px;

      svg {
        height: 18px;
        width: 18px;
      }
    }

    .button-edit-on-table {
      color: ${colors.genoaDarkBlue};
      margin: 5px;
      margin-bottom: 0;
      margin-bottom: -1px;
      margin-left: 0;
      border-radius: 4px;
    }
  
    .button-edit-on-table: hover {
      color: ${colors.white};
      background-color: ${colors.genoaDarkBlue};
      padding: 0;
      margin: 5px;
      margin-bottom: 0;
      margin-bottom: -1px;
      margin-left: 0;
      border-radius: 4px;
      cursor: pointer;
    }

    > p {
      font-size: 1rem;
      color: ${colors.genoaGrey};
      font-family: "Avenir Next GEO W05 Demi";
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
    }
    .tipo-paragraph {
      text-align: center;
      svg {
        text-align: center;
      }
    }

    .filter-icon {
      :hover {
        cursor: pointer;
        color: ${colors.rhBlue};
      }
    }

    .edit-buttons-container {
      display: grid;
      margin-left: -10px;
      width: 100px;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      .button-edit, .delete-button {
        // margin-bottom: 7px;
        color: ${colors.genoaDarkBlue};
        :hover {
          cursor: pointer;
        }
      }
    }

    
  }

  .paper-filter {
    width: 300px;
    height: 50px;
    background-color: white;
  }

  .box-beneficiary {
    width: calc(100% - 24px);
    background: #fff;
    border: 3px solid ${colors.rhBlue};
    border-radius: 42px;
    padding: 0.75rem;

    div.header-box-beneficiary {
    display: grid;
    grid-template-columns: 26px 60px 1.5fr .75fr .5fr 1.5fr .5fr .5fr 80px;
    grid-gap: 10px;
    align-items: center;
    min-height: 42px;
    font-size: 0.8rem;

    .margin-auto {
      margin: 0 auto;
    }

    > p {
      > svg {
        color: ${colors.genoaDarkBlue};
        font-size: 1.3rem;  
      }
    }

    .more-infos {
      cursor: pointer;
      svg {
        margin-left: 15px;
      }
    }

    .flex-products {
      width: 55%;
      display: flex;
      align-items: flex-end;
      justify-content: center;

      span {
        width: 45%;
        color: ${colors.genoaDarkBlue};
        display: flex;
        justify-content: center;

        > svg {
          font-size: 1.5rem;
        }
      }
    }

    .checkbox {
      margin-left: 7px;
      svg {
        height: 18px;
        width: 18px;
        color: ${colors.genoaDarkBlue};
      }
    }
    }

    .more-infos-beneficiary {
      border-top: 1px solid #ccc;
      margin: 1rem 0;
      padding: 1rem 0;
      padding-left: 59px;
      display: grid;
      grid-template-columns: 1fr 2fr;

      > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;

        input {
          min-width: 230px;
          border: 2px solid ${colors.rhBlue};
          border-radius: 20px;
          padding: 0.25rem 0.5rem;
        }

      }

      p {
        > span {
          font-family: "Avenir Next GEO W05 Bold";
        }
      }

      .send-infos {
        background: ${colors.rhBlue};
        padding: 0.5rem;
        color: #fff;
        border: none;
        border-radius: 0.5rem;
        font-family: "Avenir Next GEO W05 Demi";
        cursor: pointer;
        transition: filter 0.2s;
        margin-top: 5px;

        &:hover {
          filter: contrast(120%);
        }
      }

      .align-left {
        align-items: flex-start !important;
        display: flex;
        flex-direction: column;
        row-gap: 10px;

      }

      .infos-inclusion-beneficiary {
        align-items: center;


        .type-plan {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          position: relative;

          span.select-type {
            color: ${colors.rhBlue} !important;
          }
        

          .select-type, .icon-type-plan {
            color: #c2c2c2;
            font-size: 1.25rem;
            cursor: pointer;

            span {
              visibility: hidden;
              width: max-content;
              background-color: ${colors.rhBlue};
              color: #fff;
              font-size: 0.75rem;
              text-align: center;
              padding: 0.25rem;
              border-radius: 0.25rem;
            
              position: absolute;
              top: -100%;
              margin-left: -40px;
              z-index: 999;

            }

            &:hover {
              span {
                visibility: visible;
              }
            }
          }
        }

        .name-type-contract {
          color: ${colors.rhBlue};
          font-family: 'Avenir Next GEO W05 Bold';

          .contrato-icon {
            margin-right: 5px;
          }
        }

        .box-show-infos-plans {
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr;
          justify-content: center;
          gap: 0.75rem;
        }
      }

      .first-column-container-plano {
        font-size: 0.9rem;
        color: ${colors.genoaGrey};

        .paragraph-info {
          margin-top: 3px;
        }
        
        .input-info {
          margin-top: 8px;
        }

        .plano-title-span {
          margin-right: 5px
        }
      }

      .first-column-container {
        height: 42.2px;
        font-size: 0.9rem;
        color: ${colors.genoaGrey};

        .paragraph-info {
          margin-top: 3px;
        }
        
        .input-info {
          margin-top: 8px;
        }

        .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
          background-color: white;
          color: ${colors.rhBlue};
          font-weight: bold;
        }
    
    
        .react-datepicker__day--selected, .react-datepicker__month-text--selected,  .react-datepicker__quarter-text--selected, .react-datepicker__year-text--selected {
          border-radius: 0.3rem;
          
          background-color: ${colors.rhBlue};
          color: #fff;
        }

        
      }
    }
  }
`
