import { useState } from 'react';
import { ClickAwayListener } from '@material-ui/core';
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';
import { Div } from './styles';

// Esse filtro é renderizado no form de inclusão de dependentes
const FilterTitular = ({
  selectedEstipulante,
  planoSaudeSelected,
  setPlanoSaudeSelected,
  tipoPlanoSaudeSelected,
  setTipoPlanoSaudeSelected,
  planoOdontoSelected,
  setPlanoOdontoSelected,
  tipoPlanoOdontoSelected,
  setTipoPlanoOdontoSelected,
  selectedSubEstipulante,
  titularDependente,
  setTitularDependente,
  formatNomesTitulares
}) => {
  const [titularSelectionOpen, setTitularSelectionOpen] = useState(false);
  const [nomeTitularFiltro, setNomeTitularFiltro] = useState('');

  const handleClose = (event) => {
    if (event && event.target) {
      const parentTargetNodeClassname = event.target.parentNode.className;
      const isPlanosOptionsContainer =
        parentTargetNodeClassname === 'planos-options-container' ||
        parentTargetNodeClassname === 'planos-options-container-selected';
      const isPlanoOptionSelect =
        parentTargetNodeClassname === 'plano-option-select';
      const isOptionsContainer =
        parentTargetNodeClassname === 'options-container';
      const isEstipulanteSelectRow =
        parentTargetNodeClassname === 'estipulante-select-row';
      const isSubEstipulanteSelectContainer =
        parentTargetNodeClassname === 'sub-estipulante-select-container';
      if (
        titularSelectionOpen &&
        !(
          isPlanosOptionsContainer ||
          isPlanoOptionSelect ||
          isOptionsContainer ||
          isEstipulanteSelectRow ||
          isSubEstipulanteSelectContainer
        )
      ) {
        setTitularSelectionOpen(false);
      }
    }
  };

  const clickTitularHandler = (titular) => {
    const { contratos } = titular;
    contratos.forEach((contrato) => {
      const { contrato_id, tipo_plano } = contrato;
      const planosSaude = selectedEstipulante.contratos.saude;
      const planosOdonto = selectedEstipulante.contratos.odonto;
      const isContratoSaude = planosSaude.some(
        (contrato) => contrato.id === contrato_id
      );
      const isContratoOdonto = planosOdonto.some(
        (contrato) => contrato.id === contrato_id
      );
      if (isContratoSaude) {
        setPlanoSaudeSelected(contrato_id);
        setTipoPlanoSaudeSelected(tipo_plano);
      }

      if (isContratoOdonto) {
        setPlanoOdontoSelected(contrato_id);
        setTipoPlanoOdontoSelected(tipo_plano);
      }
    });
  };

  const filterTitularesByName = (titulares) => {
    const filteredByNameAndCpf = titulares.filter((titular) =>
      titular.nome.toLowerCase().includes(nomeTitularFiltro.toLowerCase())
    );
    return filteredByNameAndCpf;
  };

  const filterTitularesAtualizada = () => {
    let allTitulares = [];

    if (selectedEstipulante) {
      if (
        selectedEstipulante.titulares &&
        selectedEstipulante.titulares.estipulanteTitulares
      ) {
        allTitulares = selectedEstipulante.titulares.estipulanteTitulares;
      }
    }
    if (selectedSubEstipulante) {
      if (
        selectedEstipulante.titulares &&
        selectedEstipulante.titulares.subEstipulanteTitulares
      ) {
        allTitulares =
          selectedEstipulante.titulares.subEstipulanteTitulares.filter(
            (titular) =>
              titular.estipulante_id === Number(selectedSubEstipulante)
          );
      }
    }

    if (planoSaudeSelected) {
      if (tipoPlanoSaudeSelected) {
        allTitulares = allTitulares.filter((titular) => {
          return titular.contratos.some(
            (contrato) =>
              contrato.contrato_id === planoSaudeSelected &&
              contrato.tipo_plano === tipoPlanoSaudeSelected
          );
        });
      } else {
        allTitulares = allTitulares.filter((titular) => {
          return titular.contratos.some(
            (contrato) => contrato.contrato_id === planoSaudeSelected
          );
        });
      }
    }

    if (planoOdontoSelected) {
      if (tipoPlanoOdontoSelected) {
        allTitulares = allTitulares.filter((titular) => {
          return titular.contratos.some(
            (contrato) =>
              contrato.contrato_id === planoOdontoSelected &&
              contrato.tipo_plano === tipoPlanoOdontoSelected
          );
        });
      } else {
        allTitulares = allTitulares.filter((titular) => {
          return titular.contratos.some(
            (contrato) => contrato.contrato_id === planoOdontoSelected
          );
        });
      }
    }
    return allTitulares;
  };

  const renderTitularesFiltrados = () => {
    const titularesFiltrados = filterTitularesAtualizada();

    if (titularesFiltrados.length) {
      const titularesComNomesFormatados =
        formatNomesTitulares(titularesFiltrados);
      const filtradosPorNome = filterTitularesByName(
        titularesComNomesFormatados
      );
      return filtradosPorNome;
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Div>
        <div className="selecao-titular">
          <div
            className="selecao-titular-hero-title"
            onClick={() => {
              setTitularSelectionOpen((prevState) => !prevState);
            }}
          >
            {titularDependente ? (
              <span className="titular-dependente-nome">
                {titularDependente.nome}
              </span>
            ) : (
              <span>* Selecione o Titular do Dependente</span>
            )}
            {titularSelectionOpen ? (
              <RiArrowUpSLine className="arrow-icon" size={17} />
            ) : (
              <RiArrowDownSLine className="arrow-icon" size={17} />
            )}
          </div>
          <div
            className={
              titularSelectionOpen
                ? 'tabela-titulares-container-open'
                : 'tabela-titulares-container'
            }
          >
            <div className="titular-filtro-row">
              <input
                className="input-titular-filtro"
                value={nomeTitularFiltro}
                onChange={(e) => setNomeTitularFiltro(e.target.value)}
                placeholder="Filtrar por nome de Titular"
              />
            </div>
            <div className="titular-tabela-row">
              <div className="titular-tabela-container">
                <table className="titular-tabela">
                  <thead>
                    <tr className="table-header-row">
                      <th>Nome</th>
                      <th>CPF</th>
                    </tr>
                  </thead>
                  <tbody className="titular-table-body">
                    {renderTitularesFiltrados()?.map((titular) => (
                      <tr className="table-data-row">
                        <td
                          onClick={() => {
                            clickTitularHandler(titular);
                            setTitularDependente(titular);
                          }}
                        >
                          {titular.nome}
                        </td>
                        <td>{titular.cpf}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Div>
    </ClickAwayListener>
  );
};

export default FilterTitular;
