import React from "react";
import { useSelector } from "react-redux";
import moment from "moment-timezone";

import Header from "apps/hr/components/Header-RH-Novo";
import SidemenuRh from "apps/hr/components/Sidebar-RH";
import MainRh from "apps/hr/components/MainContentRh";
import { MdOutlineOpenInNew } from "react-icons/md";
import { BsCircleFill } from "react-icons/bs";
import { GiHealthNormal } from "react-icons/gi"

import { CircularProgress, SwipeableDrawer } from "@material-ui/core";

import api from "services/api";

import { Checkbox, createTheme, Radio, ThemeProvider } from '@mui/material';

import {
  Wrapper,
  Content,
  Consult,
  ContentList,
  ItemList,
  SearchContratos,
} from "./styles";
import { useState } from "react";
import { IoMdRadioButtonOff, IoMdRadioButtonOn } from "react-icons/io";
import { FaTooth } from "react-icons/fa";
import DrawerContratoDetalhes from "./DrawerContratoDetalhes";
import { usePostHog } from "posthog-js/react";

const theme = createTheme({
  palette: {
    genoaBlue: {
      main: "#456AED",
    },
    darkGenoaBlue: {
      main: "#1E2552",
    }

  },
  backdrop: {
    zIndex: 2
  }
});

const Contratos = () => {
  const posthog = usePostHog()

  const user = useSelector((state) => state.userReducers.user);
  // const [width, setWidth] = React.useState(window.innerWidth);
  const [loading, setLoading] = React.useState(false);

  const [contratos, setContratos] = React.useState([]);
  const [contratosFilter, setContratosFilter] = React.useState([]);
  // const [carousselPage, setCarousselPage] = React.useState(0);
  const [planosSelected, setPlanosSelected] = React.useState([]);
  const [planos, setPlanos] = React.useState([]);

  const [openContratoDetalhes, setOpenContratoDetalhes] = React.useState(false);
  const [contratoDetalheSelected, setContratoDetalheSelected] = React.useState({});
  const [addFilters, setAddFilters] = React.useState({
    status: "Ativo",
  });
  const [estipulante, setEstipulante] = useState({
    cnpj: "",
    razaoSocial: "",
    endereco: ""
  })
  const [subestipulantes, setSubestipulantes] = useState([])

  const changeStatusClickHandler = ({ target }) => {
    posthog?.capture(`Clicou em filtrar status por "${target?.value}"`)
    // console.log(target.value);
    setAddFilters((prevState) => ({
      ...prevState,
      status: target.value,
    }))
  }

  const produtosIds = [1, 2];

  const planosCheckboxClickHandler = ({ target }) => {
    if (target.value === 'todos' && target.checked) {
      posthog?.capture('Clicou p/ ativar filtro de "Todos" produtos')
      return setPlanosSelected(produtosIds)
    }

    if (target.value === 'todos' && !target.checked) {
      posthog?.capture('Clicou p/ desativar filtro de "Todos" produtos')
      return setPlanosSelected([])
    }

    if (target.checked) {
      posthog?.capture(`Clicou p/ ativar filtro de produtos "${target?.value === 1 ? "Saúde" : "Odontológico"}"`)
      return setPlanosSelected(
        (prevState) => {
          const newState = [...prevState, Number(target.value)];
          newState.sort((a, b) => a - b);

          return newState
        })
    }

    if (!target.checked) {
      posthog?.capture(`Clicou p/ desativar filtro de produtos "${target?.value === 1 ? "Saúde" : "Odontológico"}"`)
      return setPlanosSelected(
        (prevState) => {
          const filter = prevState.filter((elementId) => elementId !== Number(target.value));
          return filter;
        })
    }
  }

  const renderPlanoDeSaudeIcon = (contrato) => {
    if (contrato?.produto_id === 1) {
      return (
        <span className="active-icon">
          <GiHealthNormal />
        </span>
      )
    }

    if (contrato?.produto_id === 2) {
      return (
        <span className="active-icon">
          <FaTooth />
        </span>
      )
    }
  }

  const meses = {
    'Janeiro': 0,
    'Fevereiro': 1,
    'Março': 2,
    'Abril': 3,
    'Maio': 4,
    'Junho': 5,
    'Julho': 6,
    'Agosto': 7,
    'Setembro': 8,
    'Outubro': 9,
    'Novembro': 10,
    'Dezembro': 11
  }

  const renderProximoReajuste = (contrato) => {
    const mesAniversario = meses[contrato?.mes_aniversario];
    const mesAtual = moment().locale('pt-br').month();
    const anoAtual = moment().locale('pt-br').year();
    const anoAtualResumido = Number(String(anoAtual).slice(2, 4))

    if (Number(mesAtual) > Number(mesAniversario)) {

      return `${contrato.mes_aniversario} / ${anoAtualResumido + 1}`
    }

    if (Number(mesAtual) <= Number(mesAniversario)) {

      return `${contrato.mes_aniversario} / ${anoAtualResumido}`
    }
  }

  const renderContratoIconStatus = (contrato) => {
    return (
      <>
        {
          contrato?.vigencia_final ? (
            <span>
              {(moment(contrato.vigencia_final) > moment(new Date())) ?
                (
                  <BsCircleFill className='status-in-cancel-process-circle-icon icon-status' size={15} />
                ) : (
                  <BsCircleFill className='status-canceled-active-circle-icon icon-status' size={15} />
                )}
            </span>
          ) : (
            <span>
              <BsCircleFill className='status-active-circle-icon icon-status' size={15} />
            </span>
          )
        }
      </>
    )
  }

  const renderVidasAtivas = (contrato) => {
    const vidasAtivasFiltradas = contrato?.vidas?.filter((contrato) => contrato.status === "Ativo");
    const vidasAtivas = vidasAtivasFiltradas?.length

    if (vidasAtivas === 1) {
      if (contrato?.vidas[0]) {
        return 1;
      }
      return 0;
    }

    return vidasAtivas;
  }

  const filterContratos = () => {
    const contratosFiltrados = contratos
      .filter((item) => {
        if (item.status === addFilters['status']) {
          return true
        }

        if (addFilters['status'] === 'Todos') {
          return true
        }
        return false;
      })
      .filter((item) => {
        // console.log(planosSelected, item)
        if (planosSelected.some((plano) => plano === item.produto_id)) {
          return item;
        }
        return false
      });

    // console.log(contratosFiltrados);
    setContratosFilter(contratosFiltrados);
  }

  const openContratoDetalhesClickHandler = (contrato) => {
    posthog?.capture('Abriu "DrawerDetalhesContrato"')
    setOpenContratoDetalhes(true);
    setContratoDetalheSelected(contrato);
  }

  const contentContratoDetalhes = () => {
    return (
      <DrawerContratoDetalhes
        contrato={contratoDetalheSelected}
        estipulante={estipulante}
        subestipulantes={subestipulantes}
        toggleOpen={setOpenContratoDetalhes}
        getData={getDataAfterContratoUpdate}
      />
    )
  }

  const getEstipulanteInfo = async () => {
    api.get('/rh/estipulante',
      {
        headers: {
          id: user.estipulante,
          authorization: `Bearer ${user.token}`
        }
      }
    ).then(resp => {
      const dataEstipulante = resp.data.estipulante
      setEstipulante({
        cnpj: dataEstipulante.cnpj,
        razaoSocial: dataEstipulante.razaoSocial,
        endereco: dataEstipulante.endereco
      })
      setSubestipulantes(resp.data.subestipulantes)
    }).catch(err => {
      console.log(err)
    })
  }

  const getContratos = async (estipulante_id) => {
    setLoading(true)
    api.get(
      "/rh/estipulante-sub/contratos/more-info",
      {
        headers: {
          id: estipulante_id,
          authorization: `Bearer ${user.token}`,
        }
      }
    ).then((resp) => {
      // console.log(resp.data)
      setContratos(resp.data.contratos);

      const contratosAtivos = resp.data.contratos.filter(
        (contrato) => contrato.status === "Ativo");

      setContratosFilter(contratosAtivos);
      setPlanos(produtosIds);
      setPlanosSelected(produtosIds);
    }).catch((error) => {
      console.log(error)
    }).finally(() => setLoading(false))
  }

  const getDataAfterContratoUpdate = () => {
    api.get(
      "/rh/estipulante-sub/contratos/more-info",
      {
        headers: {
          id: user.mainEstipulante,
          authorization: `Bearer ${user.token}`,
        }
      }
    ).then((resp) => {
      // console.log(resp.data)
      setContratos(resp.data.contratos);
      // console.log(contratoDetalheSelected);
      const contratoUpdated = resp.data.contratos
        .find((item) => item.contrato_id === contratoDetalheSelected.contrato_id);
      // console.log(contratoUpdated)
      setContratoDetalheSelected(contratoUpdated);

    }).catch((error) => {
      console.log(error)
    })
  }



  React.useEffect(() => {
    posthog?.capture('Acessou "/rh/contratos"')
    getEstipulanteInfo();
    getContratos(user.mainEstipulante);
  }, [])

  React.useEffect(() => {
    filterContratos()
  }, [addFilters, planosSelected, contratos])

  // React.useEffect(() => {
  //   const handleResize = () => {
  //     setWidth(window.innerWidth)
  //   }
  //   window.addEventListener('resize', handleResize)
  // })

  return (
    <Wrapper>
      <SidemenuRh />
      <Content>
        <Header />
        <MainRh>
          <div className="main-painel">
            <Consult>
              <div className="title">
                <h1>Consulta Contratos</h1>
              </div>
            </Consult>
            <SearchContratos>
              <div className="selectbox">
                <div className="selectbox-first-row-container">
                  <div>
                    <label id="status-label">Status</label>
                    <ThemeProvider theme={theme}>
                      <div className="status-options-container">
                        <div className="checkbox-container">
                          <div
                            className="radio-input-container"
                          >
                            <Radio
                              size="small"
                              className="radio-input"
                              onClick={changeStatusClickHandler}
                              value={"Todos"}
                              color={"darkGenoaBlue"}
                              checked={addFilters['status'] === "Todos"}
                            // checked={showTitulares}
                            />
                            <label>Todos</label>
                          </div>
                        </div>
                        <div className="checkbox-container">
                          <div
                            className="radio-input-container"
                          // onClick={showTitularesClickHandler}
                          >
                            <Radio
                              size="small"
                              className="radio-input"
                              onClick={changeStatusClickHandler}
                              value={"Ativo"}
                              color={"darkGenoaBlue"}
                              checked={addFilters['status'] === "Ativo"}
                            // checked={showTitulares}
                            />
                            <label>Ativo</label>
                          </div>
                        </div>
                        <div className="checkbox-container">
                          <div
                            className="radio-input-container"
                          // onClick={showTitularesClickHandler}
                          >
                            <Radio
                              size="small"
                              className="radio-input"
                              value={"Inativo"}
                              onClick={changeStatusClickHandler}
                              color={"darkGenoaBlue"}
                              checked={addFilters['status'] === "Inativo"}
                            // checked={showTitulares}
                            />
                            <label>Inativo</label>
                          </div>
                        </div>
                      </div>
                    </ThemeProvider>
                  </div>
                  <div className="planos-contratos-container">
                    <label className="title-label">Produtos</label>
                    <div className="estipulantes-multiple-checkbox-row">
                      <ThemeProvider theme={theme}>
                        <div className="checkbox-container">
                          <Checkbox
                            size="small"
                            value={"todos"}
                            icon={<IoMdRadioButtonOff size={20} />}
                            checkedIcon={<IoMdRadioButtonOn size={20} />}

                            color={"darkGenoaBlue"}

                            checked={planos.length === planosSelected.length}
                            onClick={planosCheckboxClickHandler}
                          />
                          <label className="produto-seguradora-nome-label">Todos</label>
                        </div>
                        <div className="checkbox-container">
                          <Checkbox
                            size="small"
                            value={1}
                            icon={<IoMdRadioButtonOff size={20} />}
                            checkedIcon={<IoMdRadioButtonOn size={20} />}
                            color={"darkGenoaBlue"}
                            checked={planosSelected.some((item) => item === 1)}
                            onChange={planosCheckboxClickHandler}
                          />
                          <label className="produto-seguradora-nome-label">{"Saúde"}</label>
                        </div>
                        <div className="checkbox-container">
                          <Checkbox
                            size="small"
                            value={2}
                            icon={<IoMdRadioButtonOff size={20} />}
                            checkedIcon={<IoMdRadioButtonOn size={20} />}
                            color={"darkGenoaBlue"}
                            checked={planosSelected.some((item) => item === 2)}
                            onChange={planosCheckboxClickHandler}
                          />
                          <label className="produto-seguradora-nome-label">{"Odontológico"}</label>
                        </div>
                      </ThemeProvider>
                    </div>
                  </div>
                </div>
              </div>
            </SearchContratos>
            <ContentList>
              <div className="headerList">
                <div>Seguradora</div>
                <div className="header-col">Produto</div>
                <div>Próximo Reajuste</div>
                <div className="header-col">Vidas Ativas</div>
                <div className="header-col">Status</div>
              </div>

              {
                loading ? (
                  <div className="loading">
                    <CircularProgress size={110} thickness={2.5} className="loading-spinner" />
                    <span>Carregando Contratos</span>
                  </div>
                ) : (
                  contratosFilter?.map((contrato) => (
                    <>
                      <ItemList>
                        <div className="data">
                          <div className="data-nome">{contrato?.nome}</div>
                          <div className="beneficios data-col">{renderPlanoDeSaudeIcon(contrato)}</div>
                          <div className="data-nome">{renderProximoReajuste(contrato)}</div>
                          <div className="data-col">{renderVidasAtivas(contrato)}</div>
                          <div className="data-col">{renderContratoIconStatus(contrato)}</div>
                          <div className="icon" >
                            <button
                              className="btn search"
                              onClick={() => openContratoDetalhesClickHandler(contrato)}
                            >
                              <MdOutlineOpenInNew />
                            </button>

                          </div>
                        </div>
                      </ItemList>
                    </>
                  ))
                )
              }
            </ContentList>
          </div>
        </MainRh>
      </Content>
      <SwipeableDrawer
        anchor="right"
        open={openContratoDetalhes}
        onClose={() => {
          setOpenContratoDetalhes(false)
          posthog?.capture('Fechou "DrawerDetalhesContrato"')
        }}
        onOpen={() => {
          setOpenContratoDetalhes(true)
        }}
      >
        {contentContratoDetalhes()}
      </SwipeableDrawer>
    </Wrapper>
  )
};

export default Contratos;
