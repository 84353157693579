import React, { useEffect } from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';
import { Wraper } from './styles';
import { useForm, Controller } from "react-hook-form";
import { BiUpload, BiFolderOpen } from "react-icons/bi";
import { BsArrowRight } from "react-icons/bs";
import { useEmitTicket } from "contexts/emitTicket";

registerLocale('ptBR', ptBR);

const VinculoEmpresa = ({ data }) => {
  const {
    externalForm,
    updateExternalForm,
    updateExternalFormStage
  } = useEmitTicket();
  const { aderente, vinculo } = externalForm;

  const { register, handleSubmit, watch, setValue, control, formState: { errors } } = useForm();

  const changeStage = (stage) => {
    updateExternalFormStage(stage);
  }

  const goForward = (data) => {
    updateExternalForm({ vinculo: data });

    if (aderente === 'solicitante') {
      changeStage('EscolhaPlano')
    } else {
      changeStage('Dependentes')
    }
  }
  const tipoContrato = register('tipoContrato',);

  const handleChange = ({ target }) => {
    setValue('tipoContrato', target.value)
    if (target.value === 'CLT') {
      setValue('contrato_prest_servico', '')
      setValue('contrato_social', '')
      setValue('termo_contrat_estag', '')
    }
    if (target.value === 'Estagiário') {
      setValue('contrato_prest_servico', '')
      setValue('contrato_social', '')
    }
    if (target.value === 'Prestador de Serviço' || target.value === 'Sócio' || target.value === 'Estagiário') {
      setValue('pis', '')
    }
  }

  useEffect(() => {
    setValue(`tipoContrato`, vinculo.tipoContrato);
  }, [vinculo, setValue]);

  return (
    <Wraper>
      <fieldset>
        <legend>Vínculo com a empresa</legend>
        {/* <div className="select-group">
          <label >Com qual empresa você possui vínculo?</label>
          {errors.vinculo && <span className="errorsMsg">Campo obrigatório</span>}
          <select defaultValue={vinculo.vinculo}  {...register("vinculo", { required: true })}>
            <option value="" disabled={vinculo.vinculo} hidden={vinculo.vinculo}>Selecione</option>
            {
              data.map((vinculo, index) => (
                <option key={index} value={vinculo.id_estipulante}>{vinculo.razao_social}</option>)
              )
            }
          </select>
        </div> */}
        <div className="select-group">
          <label>Qual o seu vínculo com a empresa?</label>
          {errors.tipoContrato && <span className="errorsMsg">Campo obrigatório</span>}
          <select
            defaultValue={vinculo.tipoContrato}
            {...register("tipoContrato", { required: true })}
            name="tipoContrato"
            onChange={(e) => {
              tipoContrato.onChange(e);
              handleChange(e);
            }}
          >
            <option value="" disabled={vinculo.tipoContrato} hidden={vinculo.tipoContrato}>Selecione</option>
            <option value="CLT">CLT</option>
            <option value="Prestador de Serviço">Prestador de Serviço</option>
            <option value="Sócio">Sócio</option>
            <option value="Estagiário">Estagiário</option>
          </select>
        </div>
        <div className="input-date-group">
          <label className="normal-label">Data de Início do Vínculo?</label>
          <span className="descricao">Essa é a data em que o profissional que atua na empresa passou a ter vínculo (ingresso no contrato social se sócio, data de admissão se CLT, data do contrato de prestação de serviços se prestador ou data do termo de estágio se estagiário).</span>
          {errors.dataAdmissao && <span className="errorsMsg">Campo obrigatório</span>}
          <Controller
            name="dataAdmissao"
            control={control}
            defaultValue={vinculo.dataAdmissao ? new Date(vinculo.dataAdmissao) : null}
            rules={{ required: true }}
            render={({ field: { onChange, value, } }) => <DatePicker
              className="input-data"
              selected={value}
              locale="ptBR"
              onChange={onChange}
              maxDate={new Date()}
              placeholderText="Seleciona uma data"
              dateFormat="dd/MM/yyyy"
            />}
          />
        </div>
        {(watch('tipoContrato') === 'CLT') &&
          <div className="input-group">
            <div>
              {errors.pis && <span className="errorsMsg">Campo obrigatório</span>}
              <input type="text"
                {...register("pis", { required: true })}
                defaultValue={vinculo.pis}
                maxLength={50}
                placeholder="Número PIS/PAESP" />
            </div>
          </div>
        }
        <div className="input-files-group">
          {(watch('tipoContrato') === 'Prestador de Serviço') && <>
            {errors.contrato_prest_servico && <span className="errorsMsg">Campo obrigatório</span>}
            <label className="label-file">
              {watch('contrato_prest_servico')?.[0]?.name ?
                <span><BiFolderOpen /> {watch('contrato_prest_servico')[0]?.name} </span> :
                <span>Contrato de Prestação de Serviços <BiUpload /></span>
              }
              <input className="input-file"
                {...register('contrato_prest_servico', { required: true })}
                type="file"
              />
            </label>
            {errors.contrato_social && <span className="errorsMsg">Campo obrigatório</span>}
            <label className="label-file">
              {watch('contrato_social')?.[0]?.name ?
                <span><BiFolderOpen /> {watch('contrato_social')[0]?.name} </span> :
                <span>Contrato Social <BiUpload /></span>
              }
              <input className="input-file"
                {...register('contrato_social', { required: true })}
                type="file"
              />
            </label>
          </>}
          {(watch('tipoContrato') === 'Sócio') && <>
            {errors.contrato_social && <span className="errorsMsg">Campo obrigatório</span>}
            <label className="label-file">
              {watch('contrato_social')?.[0]?.name ?
                <span><BiFolderOpen /> {watch('contrato_social')[0]?.name} </span> :
                <span>Contrato Social <BiUpload /></span>
              }
              <input className="input-file"
                {...register('contrato_social', { required: true })}
                type="file" />
            </label>
          </>}
          {(watch('tipoContrato') === 'Estagiário') && <>
            {errors.termo_contrat_estag && <span className="errorsMsg">Campo obrigatório</span>}
            <label className="label-file">
              {watch('termo_contrat_estag')?.[0]?.name ?
                <span><BiFolderOpen /> {watch('termo_contrat_estag')[0]?.name} </span> :
                <span>Termo de Contrato de Estágio <BiUpload /></span>
              }
              <input className="input-file"
                {...register('termo_contrat_estag', { required: true })}
                type="file" />
            </label>
          </>}
        </div>
      </fieldset>
      <div className="button-areas">
        <button type="button" onClick={() => changeStage('InfosTitular')}  >Voltar</button>
        <button type="button" onClick={handleSubmit(goForward)} >Avançar <BsArrowRight /></button>
      </div>
    </Wraper>
  )
}

export default VinculoEmpresa
