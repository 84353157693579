import { useMemo } from 'react';
import CardNegocios from '../Cards';
import { DropCardsContainer, MainColumn } from './styles';
import { SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const Coluna = ({
  column,
  cards,
  setNewActivity,
  iconAtividadeHandler,
  setSelected,
  removerNegocioHandler
}) => {
  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: column.id,
    data: {
      type: 'Column',
      column
    }
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform)
  };

  const cardsIds = useMemo(() => {
    return cards?.map((negocio) => negocio.id);
  }, [cards]);

  return (
    <MainColumn>
      <div>
        <span className="estagio_title">{column.title}</span>
      </div>
      <DropCardsContainer ref={setNodeRef} style={style}>
        <ol style={{ listStyle: 'none' }} {...attributes} {...listeners}>
          <SortableContext items={cardsIds}>
            {cards.length > 0
              ? cards?.map((negocio) => (
                  <CardNegocios
                    key={negocio.id}
                    negocio={negocio}
                    setNewActivity={setNewActivity}
                    iconAtividadeHandler={iconAtividadeHandler}
                    setSelected={setSelected}
                    removerNegocioHandler={removerNegocioHandler}
                  />
                ))
              : null}
          </SortableContext>
        </ol>
      </DropCardsContainer>
    </MainColumn>
  );
};

export default Coluna;
