import http from 'services/http';

const getAll = async () => {
  const res = await http.get('/corretor/tasks');

  return res;
};

const createNewTask = async (data) => {
  const res = await http.post('/corretor/tasks', data);

  return res;
};

const createChecklist = async (data) => {
  const res = await http.post('/corretor/tasks/checklist', data);

  return res;
};

const updateResponsable = async (data) => {
  const res = await http.put('/corretor/tasks/responsavel', data);

  return res;
};

const updateStatusClose = async (data) => {
  const res = await http.put('/corretor/tasks', data);

  return res;
};

const updateStatusReopen = async (data) => {
  const res = await http.put('/corretor/tasks/reopen', data);

  return res;
};

const updateDate = async (data) => {
  const res = await http.put('/corretor/tasks/data-task', data);

  return res;
};

const updateTitle = async (data) => {
  const res = await http.put('/corretor/tasks/titulo', data);

  return res;
};

const updateStatusChecklist = async (data) => {
  const res = await http.put('/corretor/tasks/checklist', data);

  return res;
};

const updateStatusReopenChecklist = async (data) => {
  const res = await http.put('/corretor/tasks/reopen-checklist', data);

  return res;
};

const updateDescription = async (data) => {
  const res = await http.put('/corretor/tasks/descricao', data);

  return res;
};

// refatorar backend
const deleteChecklist = async (checklist_id) => {
  const res = await http.delete('/corretor/tasks/checklist', {
    id: checklist_id
  });

  return res;
};

const modules = {
  getAll,
  createNewTask,
  createChecklist,
  updateResponsable,
  updateStatusClose,
  updateStatusReopen,
  updateDate,
  updateTitle,
  updateStatusChecklist,
  updateStatusReopenChecklist,
  updateDescription,
  deleteChecklist
};

export default modules;
