import { useState, useEffect } from 'react';
import { RelatorioContentContainer } from './styles-RelatorioContent';
import colors from 'styles/colors';
import GraficoConcierge from './components/MovimentGraphic/GraficoConcierge';
import PainelTitularesDependentes from './components/PanelRanking/PainelAdesaoRh';
import TabelaMovimentacoes from './components/TableResult/TabelaMovimentacoes';
import { TabelaSeguradora } from './components/TableResult/TabelaSeguradora';
import monthsObject from 'utils/objects/optionsMonth';

const RelatorioContent = ({
  relatorioSelecionado,
  servicesCountByMonths,
  servicesCountOfNewUsersByMonths,
  estipulanteSelected,
  setEstipulanteSelected,
  tableEstipulanteValue,
  tableSeguradora,
  movimentacoesConcluidas,
  movimentacoesSeguradora,
  tempoMedio,
  tempoMedioSeguradora,
  graficoAbertas,
  graficoConcluidos
}) => {
  const [servicesByMonthsToPlot, setServicesByMonthsToPlot] = useState([]);
  const [
    servicesByNewUsersByMonthsToPlot,
    setServicesByNewUsersByMonthsToPlot
  ] = useState([]);

  // Estados para gráficos
  const [graphsDomainY, setGraphsDomainY] = useState([]);

  // Estado para funcionalidade de arrasto
  const [mesesArrastadosAtendimento, setMesesArrastadosAtendimento] =
    useState(0);
  const [storedDeltaAtendimento, setStoredDeltaAtendimento] = useState(0);
  const [draggable, setDraggable] = useState(false);
  const [positionStart, setPositionStart] = useState(undefined);

  const prepareDataToPlot = (array) => {
    const orderedArray = array?.sort((a, b) => {
      const { movimentacoes_month: date_a } = a;
      const { movimentacoes_month: date_b } = b;

      const monthA = Number(date_a.slice(0, 2));
      const monthB = Number(date_b.slice(0, 2));

      const yearA = Number(date_a.slice(3));
      const yearB = Number(date_b.slice(3));
      // Se anoA menor que anoB, item A virá antes no Array
      if (yearA < yearB) {
        return -1;
      }
      // Se anoA maior que anoB, item A virá depois no Array
      if (yearA > yearB) {
        return 1;
      }
      // Se anoA igual a anoB, item A virá antes no Array se mesA for menor
      if (monthA < monthB) {
        return -1;
      }
      // Se anoA igual a anoB, item A virá depois no Array se mesA for maior
      return 1;
    });

    const formattedArray = orderedArray?.map((item, index) => {
      const { movimentacoes_month } = item;
      const monthNumber = movimentacoes_month.slice(0, 2);
      item.month = monthsObject[monthNumber];
      item.index = index;
      return item;
    });

    return formattedArray;
  };

  const renderAtendimentoMonthsDomain = () => {
    const data = prepareDataToPlot(graficoAbertas);
    const oldestReajuste = data[0]?.index;
    const latestReajuste = data[data.length - 1]?.index;
    let oldestDomain;
    let latestDomain;

    const interval = latestReajuste - oldestReajuste;

    // constante 0.3 é referente ao espaçamento do ponto nas laterais do gráfico
    // para não cortar os pontos
    if (interval > 7) {
      if (
        latestReajuste - mesesArrastadosAtendimento - storedDeltaAtendimento >
        interval
      ) {
        setMesesArrastadosAtendimento(0);
        setStoredDeltaAtendimento(0);

        return [latestReajuste - 7 - 0.25, latestReajuste + 0.25];
      }

      // 0.6 considerando 0.3 de margem do lado esquerdo e 0.3 p/ lado direito
      else if (
        interval - 7 - mesesArrastadosAtendimento - storedDeltaAtendimento <
        0
      ) {
        setMesesArrastadosAtendimento(0);
        setStoredDeltaAtendimento(interval - 7);

        return [oldestReajuste - 0.25, oldestReajuste + 7 + 0.25];
      }

      oldestDomain =
        latestReajuste -
        7 -
        0.25 -
        mesesArrastadosAtendimento -
        storedDeltaAtendimento;
      latestDomain =
        latestReajuste +
        0.25 -
        mesesArrastadosAtendimento -
        storedDeltaAtendimento;

      return [oldestDomain, latestDomain];
    }

    return [oldestReajuste - 0.25, latestReajuste + 0.25];
  };

  const renderXDomain = () => {
    const xDomain = renderAtendimentoMonthsDomain();
    const oldestDomain = xDomain[0];
    const latestDomain = xDomain[1];
    const yearInterval = latestDomain - oldestDomain;
    const ticks = [];

    for (let index = 0; index <= yearInterval; index += 1) {
      const indexDomain = Number(oldestDomain.toFixed(0)) + index;
      ticks.push(indexDomain);
    }
    return ticks;
  };

  const draggableMovementHandler = (e) => {
    const ticks = document.querySelectorAll(
      '.recharts-cartesian-axis-tick-line'
    );

    const firstTick = ticks[0]?.getAttribute('x1');
    const secondTick = ticks[1]?.getAttribute('x1');
    const tickScale = Math.round(secondTick - firstTick);

    if (draggable) {
      if (!e || !e?.xValue) {
        setStoredDeltaAtendimento(
          (prevState) => prevState + mesesArrastadosAtendimento
        );
        setMesesArrastadosAtendimento(0);
        setPositionStart(undefined);
        return setDraggable(false);
      }
      if (e && e?.xValue) {
        const delta = window.event.x - positionStart;

        // 1 ano a cada tickScale pixels arrastado
        const deltaAnos = delta / tickScale;

        setMesesArrastadosAtendimento(deltaAnos);
      }
    }
  };

  const returnTipoTabela = () => {
    switch (relatorioSelecionado) {
      case 'movimentacoes abertas':
        return 'contagem';

      case 'movimentacoes concluidas':
        return 'contagem';

      case 'tempo de resolucao':
        return 'dias';

      default:
        return '';
    }
  };

  useEffect(() => {
    const servicesCountToPlot = prepareDataToPlot(graficoAbertas);

    const formattedCountToPlot = servicesCountToPlot?.map((item) => {
      let totalRh = 0;
      let inclusoesRh = 0;
      let exclusoesRh = 0;

      let totalCorretora = 0;
      let inclusoesCorretora = 0;
      let exclusoesCorretora = 0;

      const { movimentacoes_empresas } = item;

      for (let index in movimentacoes_empresas) {
        const empresaRh = movimentacoes_empresas[index].rh;
        const empresaCorretora = movimentacoes_empresas[index].corretora;
        totalRh += empresaRh.total;
        inclusoesRh += empresaRh.inclusoes;
        exclusoesRh += empresaRh.exclusoes;

        totalCorretora += empresaCorretora.total;
        inclusoesCorretora += empresaCorretora.inclusoes;
        exclusoesCorretora += empresaCorretora.exclusoes;
      }

      return {
        ...item,
        totalRh,
        inclusoesRh,
        exclusoesRh,
        totalCorretora,
        inclusoesCorretora,
        exclusoesCorretora
      };
    });

    if (!estipulanteSelected) {
      const maxCountValueRh = formattedCountToPlot.reduce(
        (acc, currentValue) => {
          const currentCount = currentValue?.totalRh;

          return currentCount > acc ? currentCount : acc;
        },
        0
      );

      const maxCountValueCorretora = formattedCountToPlot.reduce(
        (acc, currentValue) => {
          const currentCount = currentValue?.totalCorretora;

          return currentCount > acc ? currentCount : acc;
        },
        0
      );

      const maxCountValue =
        maxCountValueRh > maxCountValueCorretora
          ? maxCountValueRh
          : maxCountValueCorretora;

      // console.log(maxCountValue)

      setGraphsDomainY([0, maxCountValue + (10 - (maxCountValue % 5))]);

      setServicesByMonthsToPlot(servicesCountToPlot);
    } else {
      // console.log('servicesCountToPlot', servicesCountToPlot)
      // const estipulanteServicesCountToPlot = servicesCountToPlot?.map((item) => {
      //   return {
      //     concierge_month: item?.concierge_month,
      //     index: item?.index,
      //     month: item?.month,
      //     count: item?.ticket_empresas[estipulanteSelected] ? (
      //       item?.ticket_empresas[estipulanteSelected]?.count
      //     ) : 0
      //   }
      // })
      // const maxCountValue = estipulanteServicesCountToPlot.reduce((acc, currentValue) => {
      //   const currentCount = currentValue?.count;
      //   return currentCount > acc ? currentCount : acc;
      // }, 0)
      // setGraphsDomainY([0 - 0.5, maxCountValue + (5)])
      // console.log(estipulanteServicesCountToPlot)
      // setServicesByMonthsToPlot(estipulanteServicesCountToPlot);
    }
  }, [graficoAbertas, estipulanteSelected]);

  return (
    <RelatorioContentContainer>
      <div className="first_row">
        <div className="graphs_column">
          <div className="graphs_container">
            <GraficoConcierge
              draggableMovementHandler={draggableMovementHandler}
              setDraggable={setDraggable}
              setPositionStart={setPositionStart}
              setStoredDeltaAtendimento={setStoredDeltaAtendimento}
              mesesArrastadosAtendimento={mesesArrastadosAtendimento}
              setMesesArrastadosAtendimento={setMesesArrastadosAtendimento}
              servicesByMonthsToPlot={servicesByMonthsToPlot}
              renderXDomain={renderXDomain}
              renderAtendimentoMonthsDomain={renderAtendimentoMonthsDomain}
              graphsDomainY={graphsDomainY}
              graphData={servicesByMonthsToPlot}
              lineColorCorretora={colors.genoaBlue}
              dotColorCorretora={colors.genoaBlue}
              lineColorRh={colors.rhBlue}
              dotColorRh={colors.rhBlue}
              graphTitle={'Movimentações abertas x mês'}
            />
          </div>
        </div>
        <PainelTitularesDependentes
          tableEstipulanteValue={tableEstipulanteValue}
        />
      </div>
      <div className="second_row">
        <TabelaMovimentacoes
          tipo={returnTipoTabela()}
          relatorioSelecionado={relatorioSelecionado}
          tableEstipulanteValue={tableEstipulanteValue}
          movimentacoesConcluidas={movimentacoesConcluidas}
          tempoMedio={tempoMedio}
        />
      </div>
      <div className="third_row">
        <TabelaSeguradora
          tipo={returnTipoTabela()}
          relatorioSelecionado={relatorioSelecionado}
          tableSeguradora={tableSeguradora}
          movimentacoesSeguradora={movimentacoesSeguradora}
          tempoMedioSeguradora={tempoMedioSeguradora}
        />
      </div>
    </RelatorioContentContainer>
  );
};

export default RelatorioContent;
