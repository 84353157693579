import styled from 'styled-components';
import colors from 'styles/colors';

export const ContentTasksWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 40px 60px 0px 60px;

  .title_content {
    margin-top: 1px;
    margin-bottom: 2.5em;

    h1{
      font-family: "Avenir Next GEO W05 Heavy";
      font-size: 1.2rem;
      color: #3B3838;
    }
  }

  .btn {
    margin-top: 3px;
    margin-bottom: 2em;
  }

  .btn_new_task {
    text-align: end;
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 1rem;
    color: #3B3838;
    border: none;
    cursor: pointer;
    background: none;
    transition: 0.1s ease-in;
  }
`