import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import services from 'apps/hr/services';
import { useUser } from 'contexts/user';

export const InclusionRequestsContext = createContext();

const statusRequests = [
  {
    value: 1,
    option: 'Formulário Enviado'
  },
  // {
  //   value: 2,
  //   option: 'E-mail Lido'
  // },
  // {
  //   value: 3,
  //   option: 'Preenchimento Expirado'
  // },
  {
    value: 4,
    option: 'Aguardando Revisão do RH'
  },
  {
    value: 5,
    option: 'Aguardando correção do beneficiário'
  },
  {
    value: 6,
    option: 'Movimentação de Inclusão Enviada'
  }
];

export const InclusionContextProvider = ({ children }) => {
  const { user } = useUser();

  const [status, setStatus] = useState({
    1: true,
    // 2: true,
    // 3: true,
    4: true,
    5: true,
    6: true
  });
  const [holdersInformation, setHoldersInformation] = useState([]);

  const getInclusionRequests = useCallback(async () => {
    if (!user || !user.estipulante) return;

    try {
      const response = await services.inclusionRequest.getAllInclusionRequests(
        user.estipulante
      );

      const requests = response;
      const statusFilterOptions = [];

      statusRequests.forEach((item) => {
        if (status[item.value]) {
          statusFilterOptions.push(item.option);
        }
      });

      const filteredRequests = requests.filter((item) =>
        statusFilterOptions.includes(item.status_atual)
      );
      setHoldersInformation(filteredRequests);
    } catch (error) {
      console.error('Failed to fetch inclusion requests', error);
    }
  }, [status, user]);

  useEffect(() => {
    getInclusionRequests();
  }, [getInclusionRequests]);

  return (
    <InclusionRequestsContext.Provider
      value={{
        holdersInformation,
        statusRequests,
        status,
        setStatus,
        getInclusionRequests
      }}
    >
      {children}
    </InclusionRequestsContext.Provider>
  );
};

export const useInclusionRequests = () => useContext(InclusionRequestsContext);
