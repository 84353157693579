import styled from 'styled-components';
import colors from 'styles/colors';

export const MainWrapper = styled.div`
  width: 100vw;
  max-width: 1400px;
  box-sizing: content-box;
`;

export const Header = styled.div`
  background-color: ${colors.genoaBlue};
  padding: 20px 65px;

  .back {
    height: 30px;
    .icon-back {
      color: #fff;
      cursor: pointer;
      font-size: 2rem;
      svg {
        font-size: 30px;
      }
    }
  }

  .nameUser {
    display: flex;
    justify-content: space-between;
    color: #fff;
    margin-top: 15px;

    svg {
      font-size: 2.5rem;
      margin-left: -5px;
    }
    .user-company {
      display: flex;
      align-items: baseline;
      span {
        color: #fff;
        margin-left: 6px;
        font-size: 18px;
        font-family: 'Avenir Next GEO W05 Bold';
      }
    }
    .user-contact > div {
      font-family: 'Avenir Next GEO W05 Demi';
      color: #fff;
      font-size: 20px;
      margin-bottom: 10px;
    }
  }
  .header-menu {
    margin-top: 27px;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 3fr 1fr;
    line-height: 23px;

    .header-nav-container {
      display: grid;
      grid-template-columns: 95px 145px 85px;
      color: white;
      column-gap: 30px;

      .nav-option {
        display: flex;
        width: max-content;
        align-items: center;

        .icon-container {
          display: flex;
          align-items: center;
        }

        svg {
          width: 18px;
          height: 18px;
        }
      }
    }

    span {
      color: #fff;
      font-size: 15px;
      margin-left: 5px;
      cursor: pointer;
    }
    .active {
      font-family: 'Avenir Next GEO W05 Demi';
      // text-shadow: 1px 1px 1px rgb(2 2 2 / 46%);
    }
    .adicionar-seguro {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      button {
        border: none;
        display: flex;
        align-items: center;
        font-family: 'Avenir Next GEO W05 Demi';
        font-size: 15px;

        background-color: transparent;
        color: white;

        .add-icon {
          display: flex;
          margin-top: 5px;
          align-items: center;
        }

        span {
          margin-left: 3px;
        }

        :hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
  h2 {
    font-family: 'Avenir Next GEO W05 Heavy';
    color: #fff;
    font-size: 20px;
    margin: 0px 0 2px 0;
  }
  span.tipo {
    color: #fff;
    font-size: 15px;
  }
`;

export const TabsMenu = styled.section`
  display: flex;
  padding-left: 65px;
  padding-right: 90px;
  height: 60px;

  .tab-menu-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .adicionar-beneficio-container {
    display: flex;
    align-items: center;
    column-gap: 5px;
    height: 100%;
    color: ${colors.genoaDarkBlue};
    font-family: 'Avenir Next GEO W05 Demi';
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;

    .add-icon {
      display: flex;
      margin-top: 3px;
      align-items: center;
    }

    .span-menu {
      display: flex;
      align-items: center;

      :hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .paper-popup-content-container {
      width: 230px;
      display: flex;
      justify-content: center;

      .adicionar-seguro-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        padding-top: 5px;
        padding-bottom: 5px;
        // align-items: center;

        .btn-container {
          height: 30px;
          width: 100%;
          display: flex;
          align-items: center;

          .addSeguro {
            display: flex;
            column-gap: 10px;
          }

          :hover {
            background-color: ${colors.genoaBackgroundGrey};
            cursor: pointer;
          }
        }
        button {
          border: none;
          display: flex;
          padding-left: 30px;
          align-items: center;
          font-family: 'Avenir Next GEO W05 Regular';
          font-size: 15px;

          background-color: transparent;
          color: ${colors.genoaGrey};

          span {
            margin-left: 3px;
          }

          :hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .nav-options-container {
    display: flex;
    height: 100%;
  }

  .nav-option {
    box-sizing: border-box;
    display: flex;
    margin-right: 80px;
    justify-content: center;
    width: max-content;
    background-color: ${colors.white};
    align-items: center;
    cursor: pointer;

    svg {
      margin-right: 10px;
      font-size: 17px;
      color: ${colors.genoaDarkBlue};
    }
    span {
      font-family: 'Avenir Next GEO W05 bold';
      font-size: 17px;
      color: ${colors.genoaDarkBlue};
    }
  }
  .active {
    span {
      color: ${colors.genoaBlue};
    }
  }
`;
export const ContentTabs = styled.section`
  display: flex;
  background-color: ${colors.genoaBackgroundGrey};
  justify-content: space-between;
  place-content: center;
  padding-top: 0px;
  padding-bottom: 20px;
  padding-left: 65px;
  padding-right: 65px;
  flex-direction: column;
  min-height: 800px;
  max-height: 1400px;

  hr.divider {
    border-style: hidden;
    border-bottom: 1px #ccc solid;
    margin: 20px 0px 40px 0px;
  }

  hr.divider-delete {
    border-style: hidden;
    border-bottom: 1px #ccc solid;
    margin: 30px 0px 20px 0px;
  }

  .menuContentTab {
    display: flex;
    padding: 10px 50px;
    // margin-bottom: 50px;

    place-content: center;
    > div {
      padding: 0px 50px;
      > button {
        cursor: pointer;
        padding: 1px 5px;

        font-family: 'Avenir Next GEO W05 Bold';
        font-size: 20px;
        color: rgba(110, 62, 193, 0.8);
        background: none;
        border: none;
        border-bottom: 2px #8b65cd solid;
      }
    }
  }

  .rows {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 50px;
  }

  .dadosCadastrais {
    padding-top: 40px;
    min-height: 1420px;
    .card {
      margin-top: 20px;
      padding-left: 20px;
      max-width: 500px;
      margin-bottom: 2rem;
      h3 {
        font-family: 'Avenir Next GEO W05 Bold';
        margin-bottom: 10px;
        padding-right: 10px;
      }
      .card-one-column {
        margin-bottom: 18px;

        .name {
          font-family: 'Avenir Next GEO W05 Demi';
          margin-bottom: 5px;
        }
      }
      .gridcard {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 15px;
        margin-bottom: 18px;

        .name {
          font-family: 'Avenir Next GEO W05 Demi';
          margin-bottom: 5px;
        }
      }
    }

    .header-card {
      display: flex;
      align-items: baseline;

      .header-card-title {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        h3 {
          margin-bottom: 0;
        }
      }
      button {
        background: transparent;
        border: none;
        cursor: pointer;
        font-size: 20px;
        display: flex;
      }
    }

    .btn-area {
      display: flex;
      margin-top: 20px;
      justify-content: space-between;
      margin-bottom: 15px;
    }
    .addGestor {
      margin-top: 30px;
      width: 360px;
    }

    .input-group {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      label {
        font-family: 'Avenir Next GEO W05 Demi';
        color: #3b3838;
        font-size: 14px;
      }
      input {
        border: 2px solid ${colors.genoaBlue};
        padding: 10px 12px;
        border-radius: 24px;
        margin: 5px 0 10px 0;
        font-size: 15px;
      }
    }

    .react-datepicker-wrapper .input-data {
      width: 100%;
    }

    .remove-btn-area {
      display: flex;
      justify-content: flex-end;
      margin-top: 5px;
    }
    button.remove-field {
      background: none;
      border: none;
      color: #ff0757;
      cursor: pointer;
      font-size: 12px;
      font-family: 'Avenir Next GEO W05 Demi';
    }
    button.add {
      cursor: pointer;
      border: none;
      background: none;
      padding: 10px 10px;
      margin: 10px 0 0 0;
      border-radius: 10px;
      font-family: 'Avenir Next GEO W05 Regular';
      :hover {
        background: #efefef;
      }
    }

    button.send-btn {
      display: flex;
      align-items: center;
      column-gap: 5px;

      // background-color: ${colors.white};
      border: none;
      cursor: pointer;
      font-family: 'Avenir Next GEO W05 Bold';
      padding: 10px 10px;
      font-size: 16px;
      color: ${colors.genoaDarkBlue};
      border-radius: 6px;

      span {
        margin-bottom: 3px;
      }

      :hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
    button.btn-add {
      display: flex;
      align-items: center;
      background: none;
      border: none;
      cursor: pointer;
      font-family: 'Avenir Next GEO W05 Regular';
      font-size: 16px;
      color: ${colors.genoaBlue};
      border-radius: 6px;

      :hover {
        // background: #d3c1f3;
      }
    }

    .add-sub {
      padding-left: 20px;
    }

    button.send-btnRemove {
      display: flex;
      align-items: center;
      column-gap: 5px;

      // background-color: ${colors.white};
      border: none;
      cursor: pointer;
      font-family: 'Avenir Next GEO W05 Bold';
      padding: 10px 10px;
      font-size: 16px;
      color: ${colors.genoaDarkBlue};
      border-radius: 6px;

      span {
        margin-bottom: 3px;
      }

      :hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .pdg-bt {
      padding-bottom: 10px;
    }

    .btn-remove {
      display: flex;
      justify-content: space-between;
      margin-top: 2px;
    }

    .txt-jt {
      text-align: justify;
    }

    .btn-select {
      flex-direction: column;
      display: flex;
      justify-content: end;
      align-items: end;
      margin-left: 35px;
      button {
        padding: 3px;
      }

      .red {
        color: #ff4366 !important;
      }
    }

    span.message {
      margin: 10px 0;
      display: inline-block;
      background: #e3d3ff;
      padding: 10px;
      border-radius: 10px;
      border: 1px #bc95ff solid;
      color: #371f60;
    }
    .animeLeft {
      opacity: 0;
      transform: translateX(-20px);
      animation: animeLeft 0.3s forwards;
    }
    @keyframes animeLeft {
      to {
        opacity: 1;
        transform: initial;
      }
    }
  }

  h3 {
    font-family: 'Avenir Next GEO W05 Bold';
  }
  .col-form {
    flex: 1;
  }

  .div-input-files {
    margin-top: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  .label-file {
    // font-size: 16px;
    //background-color: #f3ecff;
    // border-radius: 5px;
    // color: #fff;
    // cursor: pointer;
    // padding: 5px 0px;
    //border: 1px ${colors.genoaBlue} solid;

    input {
      display: none;
    }
    span {
      display: flex;
      align-items: center;
      color: ${colors.genoaBlue};

      svg {
        font-size: 1.3rem;
      }
    }
  }
  span.error-msg {
    color: #f7365a;
    font-size: 13px;
    margin-bottom: 5px;
    display: inline-block;
  }

  .preview {
    padding: 10px 0;
    box-sizing: border-box;
    width: 100%;

    .logoPreview {
      width: 200px;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
  button.btn-clear {
    background: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
    margin-right: 30px;
    cursor: pointer;
    svg {
      width: 17px;
    }
  }

  .logoAtual {
    width: 200px;
    margin-top: 30px;
    padding: 10px 0;
    img {
      max-width: 200px;
      max-height: 100px;
    }
  }

  .dados-cadastrais-title {
    color: ${colors.genoaBlue};
    font-size: 17px;
    font-family: 'Avenir Next GEO W05 Bold';
    margin-bottom: 30px;
  }
`;

export const BlockInfo = styled.section`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 50px 0px;

  .divIcon {
    svg {
      font-size: 26px;
      color: ${colors.genoaDarkBlue};
    }
  }

  .infos {
    padding-left: 20px;

    .estipulante {
      color: ${colors.genoaDarkBlue};
    }

    .blok-info {
      display: grid;
      grid-template-columns: 40px 1fr;
      margin-bottom: 40px;

      .header-info {
        margin-bottom: 10px;
        display: flex;

        h4 {
          font-family: 'Avenir Next GEO W05 Bold';
          margin-bottom: 30px;
        }

        span {
          font-size: 18px;
          font-family: 'Avenir Next GEO W05 Demi';

          .subsEs {
            font-size: 14px;
            color: ${colors.genoaBlue};
          }
        }

        button {
          display: flex;
          background: none;
          border: none;
          font-size: 18px;
          margin-left: 10px;
          cursor: pointer;
        }
      }

      .dados {
        display: flex;
        flex-direction: column;

        row-gap: 3px;
      }

      .add-sub {
        padding-left: 20px;
        margin-top: 30px;
      }

      .subestipulante_column {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
      }
    }

    .gridcard {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
    }
  }

  .contatos-container {
    padding-left: 20px;
    .contato-info {
      display: grid;
      grid-template-columns: 40px 1fr;

      .infos-container {
        .blok-info {
          display: grid;
          grid-template-columns: 40px 1fr;
          margin-bottom: 40px;

          .header-info {
            margin-bottom: 10px;
            display: flex;

            h4 {
              font-family: 'Avenir Next GEO W05 Bold';
              margin-bottom: 30px;
            }

            span {
              font-size: 18px;
              font-family: 'Avenir Next GEO W05 Demi';

              .subsEs {
                font-size: 14px;
                color: ${colors.genoaBlue};
              }
            }

            button {
              display: flex;
              background: none;
              border: none;
              font-size: 18px;
              margin-left: 10px;
              cursor: pointer;
            }
          }

          .dados {
            display: flex;
            flex-direction: column;

            row-gap: 3px;
          }

          .add-sub {
            margin-top: 30px;
          }
        }

        .gridcard {
          display: flex;
          align-items: center;
          margin-bottom: 30px;
        }
      }
    }
  }
`;
export const DialogDeleteContato = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 20px 20px;

  .title {
    h3 {
      font-family: 'Avenir Next GEO W05 Bold';
    }
  }

  .content {
    padding: 20px 0;
  }

  .actions {
    display: flex;

    button {
      cursor: pointer;
      background: none;
      border: none;
      font-family: 'Avenir Next GEO W05 Demi';
      font-size: 1rem;

      :last-child {
        margin-left: 50px;
      }
    }
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 20px;
  .back{
    margin-top: 10px;
    margin-bottom: 20px;
    .back-btn{
      display: flex;
      align-items: center;
      background: none;
      border: none;
      color: ${colors.genoaDarkBlue};
      border-radius: 13px;
    }
    span{
      font-size: 14px;
      cursor: pointer;
      font-family: 'Avenir Next GEO W05 Bold';
      color: ${colors.genoaDarkBlue};
    }
    svg{
      font-size: 1.5rem;
      cursor: pointer;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    .grids{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 3rem;

      .col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        row-gap: 10px;
      }
    }
    input{font-family: 'Avenir Next GEO W05 Regular';}
    label.default{
      font-family: 'Avenir Next GEO W05 Demi';
      font-size: 17px;
      color: ${colors.genoaGrey};
      margin-bottom: 10px;
      display: block;
    }

    .custeio-details {
      background-color: #fff;
      border: 2px solid ${colors.genoaBlue};
      border-radius: 24px;
      width: 100%;
      opacity: 1;
      font-size: 16px;
      padding: 12px;
      box-sizing: border-box;
      color: ${colors.genoaGrey};
      font-family: 'Avenir Next GEO W05 Regular';
      margin-bottom: 10px;

      ::placeholder {
        text-align: center;
        color: #c1c1c1;
      }
    }
  }

  .form-cadastro-estipulante {
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 30px;

    .grids{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 5rem;

      .col {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        row-gap: 20px;
      }
    }
  }

  .planos-container {
    margin-top: 30px;

    .atualizacao-title {
      color: ${colors.genoaBlue};
      font-size: 17px;
      font-family: "Avenir Next GEO W05 Bold";
      margin-bottom: 30px;
    }
  }

  .novo-beneficio-title {
    color: ${colors.genoaBlue};
    font-size: 17px;
    font-family: "Avenir Next GEO W05 Bold";
    margin-bottom: 30px;
  }

  .form-novas-vidas {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    flex-direction: column;

    .vidas-title {
      color: ${colors.genoaBlue};
      font-size: 17px;
      font-family: "Avenir Next GEO W05 Bold";
    }

    .buttons-container {
      display: grid;
      align-items: flex-start;
      grid-template-columns: 48px 320px 120px;
      column-gap: 10px;
      margin-top: 30px;
      height: 25px;

      .hover-pointer {
        :hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    .grids{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 3rem;
    }

    input{font-family: 'Avenir Next GEO W05 Regular';}
    label.default{
      color: ${colors.genoaGrey};
      font-family: 'Avenir Next GEO W05 Demi';
      font-size: 1rem;
      margin-bottom: 10px;
      display: block;
    }
  }

  .custeio-details {
    background-color: #fff;
    border: 0.5px solid ${colors.genoaBlue};
    border-radius: 10px;
    opacity: 1;
    font-size: 1rem;
    margin-bottom: 10px;
    color: ${colors.genoaGrey};
    width: 100%;
    height: 48px;

    ::placeholder {
      text-align: center;
      color: #c1c1c1;
    }
  }

  .row{
    margin-top: 5px;
    margin-bottom: 5px;
    font-family: 'Avenir Next GEO W05 Bold';
    span {
      font-family: 'Avenir Next GEO W05 Demi';
    }
  }

  .row-cadastro-estipulante {
    a {
      font-size: 17px;
    }

    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 5px;
    font-family: 'Avenir Next GEO W05 Bold';
    span {
      font-family: 'Avenir Next GEO W05 Demi';
    }
  }

  .multa-details {
    background-color: #fff;
    border: 2px solid ${colors.genoaBlue};
    border-radius: 24px;
    padding: 12px;
    box-sizing: border-box;
    opacity: 1;
    font-size: 16px;
    color: ${colors.genoaGrey};
    font-family: 'Avenir Next GEO W05 Regular';
    width: 100%;

    ::placeholder {
      text-align: center;
      color: #c1c1c1;
    }

    :focus-visible {
      outline-color: ${colors.genoaBlue};
    }
  }

  .apolice-details {
    background-color: #fff;
    border: 2px solid ${colors.genoaBlue};
    border-radius: 24px;
    margin-bottom: 6px;
    padding: 12px;
    opacity: 1;
    font-size: 16px;
    color: ${colors.genoaGrey};
    font-family: 'Avenir Next GEO W05 Regular';
    width: 100%;
    text-align: center;
    height: 48px;
    ::placeholder {
      color: #c1c1c1;
    }

    :focus-visible {
      outline-color: ${colors.genoaBlue};
    }
  }

  .row > a {
    color: #878787;
  }

  .row-cadastro-estipulante > a {
    color: #878787;
  }

  .dados-receita{
    margin: 10px 0;
  }
  .label-fields{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .remove-btn-area{
    display: flex;
   justify-content: flex-end;
   margin-top: 5px;
  }
  button.remove-field{
    background: none;
    margin-bottom: 5px;
    border: none;
    color: #ff0757;
    cursor: pointer;
    font-size: 12px;
    font-family: 'Avenir Next GEO W05 Demi';
  }

  label.default{
    color: ${colors.genoaGrey};
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 1rem;
    // margin: 10px 0;
    display: block;
  }

  .input-group{
    display: flex;
    flex-direction: column;
    input{
      border: 2px solid ${colors.genoaBlue};
      padding: 12px 13px;
      border-radius: 48px;
      margin: 5px 0 10px 0;
      font-size: 16px;
      height: 48px;

      :focus-visible {
        outline: 2px ${colors.genoaBlue}f5 auto;
      }
    }

    textarea {
      :focus-visible {
        outline: 1px ${colors.genoaBlue}f5 auto;
      }
    }
  }

  .third-col {
    margin-top: 37px;
  }

  .input-group-planos {
    display: flex;
    flex-direction: column;
    width: 391.33px;

    input{
      border: 2px solid ${colors.genoaBlue};
      padding: 12px 13px;
      border-radius: 48px;
      margin: 5px 0 10px 0;
      font-size: 16px;
      height: 48px;
    }
  }


  .input-default {
    display: flex;
    font-size: 1rem;
    background-color: #fff;
    border: 0.5px solid ${colors.genoaBlue};
    border-radius: 10px;
    width: 100%;
    height: 48px;
    color: ${colors.genoaGrey};
    justify-content: center;
    box-sizing: border-box;
    align-items: center;
    padding: 10px;
    cursor: pointer;
  }
  .input-default.active{
    background: ${colors.genoaBlue};
    color: #FFF;
  }


  .button-download{
    display: flex;
    button{
      border: 1px solid ${colors.genoaBlue};
      padding: 12px 13px;
      border-radius: 10px;
      margin: 5px 0 10px 0;
      font-size: 1rem;
      height: 48px;
    }
  }

  .checkbox-area{
    // margin: 10px 0;
    .checkbox-group{
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px 0px 20px 0px;
      label.select-label{
        display: flex;
        font-size: 16px;
        background-color: #fff;
        border: 2px solid ${colors.genoaBlue};
        border-radius: 48px;
        width: 100%;
        height: 48px;
        color: ${colors.genoaGrey};
        justify-content: center;
        text-align: center;
        box-sizing: border-box;
        align-items: center;
        padding: 10px;
        cursor: pointer;
      }
      label.select-label.active{
        background: ${colors.genoaBlue};
        color: #FFF;
      }
    }

    .coparticipacao-details-active {
      background-color: #fff;
      border: 2px solid ${colors.genoaBlue};
      border-radius: 24px;
      width: 100%;
      opacity: 1;
      font-size: 16px;
      padding: 12px;
      box-sizing: border-box;
      color: ${colors.genoaGrey};
      text-align: center;
      font-family: 'Avenir Next GEO W05 Regular';
      margin-bottom: 10px;

      ::placeholder {
        color: #c1c1c1;
      }

      :focus-visible {
        outline-color: ${colors.genoaBlue};
      }
    }

    .coparticipacao-details-disabled {
      background-color: #BBBBBB;
      border: 2px solid #c3c3c3;
      color: #BBBBBB;
      border-radius: 24px;
      opacity: 0.25;
      padding: 12px;
      box-sizing: border-box;
      text-align: center;
      width: 100%;
      height: 173.6px;
      margin-bottom: 10px;
    }

    .custeio-details {
      background-color: #fff;
      border: 2px solid ${colors.genoaBlue};
      border-radius: 24px;
      width: 100%;
      opacity: 1;
      font-size: 16px;
      padding: 12px;
      box-sizing: border-box;
      color: ${colors.genoaGrey};
      font-family: 'Avenir Next GEO W05 Regular';
      margin-bottom: 10px;

      ::placeholder {
        text-align: center;
        color: #c1c1c1;
      }

      :focus-visible {
        outline-color: ${colors.genoaBlue};
      }
    }
  }

  .select-group{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    margin-bottom: 6px;
    // margin-top: 15px;

    :first-child{
      margin-top: 0px;
    }

    label{
      margin-bottom: 10px;
    }

    select{
      font-family: 'Avenir Next GEO W05 Regular';
      width: 100%;
      height: 48px;
      appearance: none;
      text-align: center;
      background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 9L0.73686 0.75L10.2631 0.75L5.5 9Z' fill='%23456AED'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position-x: 96%;
      background-position-y: 17px;
      font-size: 16px;
      padding: 0px 10px;
      color: ${colors.genoaGrey};
      border: 2px solid ${colors.genoaBlue};
      border-radius: 24px;
      /* text-align-last: center; */

      :focus-visible{
        outline: none;
      }
    }
  }

  .react-datepicker-wrapper {
    color: ${colors.genoaGrey};
    width: 100%;

    .input-data{
      color: ${colors.genoaGrey};
      border-radius: 48px;
      width: 100%;
      height: 48px;
      background: #FFFFFF;
      background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 9L0.73686 0.75L10.2631 0.75L5.5 9Z' fill='%23456AED'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position-x: 96%;
      background-position-y: 17px;
      border: 2px solid ${colors.genoaBlue};
      text-align: center;
      margin: 0px 0 6px 0;
      font-size: 16px;

      ::placeholder {
        color: ${colors.genoaGrey};
      }
    }


  }

  .react-datepicker__day--selected {
    background-color: ${colors.genoaBlue};
  }

  .react-datepicker__day--keyboard-selected {
    color: ${colors.genoaBlue};
    background-color: white;
    font-weight: bold;
  }

  .subs-estip{
    .dados-receita{
      margin-top: 0px;
    }
  }

  .email-prefix{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    background-color: white;
    border: none;
    border-radius: 24px;

    span{
      border-radius: 10px;
      padding: 11px 13px;
      height: 48px;
      border: 2px solid ${colors.genoaBlue};
      border-right: none;
      border-radius: 24px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      font-size: 1.2rem;
      box-sizing: border-box;
    }
    input{
      border-left: none;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      margin: 0px;
      padding: 12px 13px 12px 5px;
      font-size: 1rem;
      height: 48px;
      flex: 1;
      :focus-visible {
        outline-left: 1px solid ${colors.genoaBlue};
      }
    }
  }

  .descricao{
    margin-bottom: 10px;
  }
  button.add {
    cursor: pointer;
    border: none;
    color: ${colors.genoaDarkBlue};
    background: none;
    padding: 10px 10px;
    margin: 10px 0 0 0;
    border-radius: 10px;
    font-family: 'Avenir Next GEO W05 bold';
    :hover{
      text-decoration: underline;
    }
  }

  button.send-btn{
    // background-color: ${colors.white};
    border: none;
    cursor: pointer;
    font-family: 'Avenir Next GEO W05 Bold';
    padding: 10px 10px;
    font-size: 1.3rem;
    color: ${colors.genoaDarkBlue};
    border-radius: 6px;

    :hover{
      text-decoration: underline;
      cursor: pointer;
    }
  }

  button.send-btn-odonto{
    background-color: ${colors.white};
    border: 1px ${colors.genoaBlue} solid;
    cursor: pointer;
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 1.4rem;
    color: ${colors.genoaDarkBlue};
    padding: 4px 15px;
    border-radius: 6px;
    margin-left: 15px;
    margin-top: 50px;

    :hover{
      background-color: ${colors.genoaBlue};
      color: white;
      padding: 4px 15px;
      border-radius: 4px;
      cursor: pointer;
    }
  }

  .col-links{
    max-width: 600px;

    .pdb-3{
      padding-bottom: 3rem;
    }

    input:read-only{
      background: #f3ecff;
    }

    .label-btn{
      display: flex;
      justify-content: space-between;
      align-items: center;

      span{
        cursor: pointer;
        font-family: 'Avenir Next GEO W05 Bold';
        color: ${colors.genoaBlue};
        font-size: 15px;
      }
    }
  }

  .input-file-group {
    display: flex;
  }

  .label-file {
    font-size: 16px;
    color: ${colors.genoaBlue};

    cursor: pointer;

    border: none;
    font-family: 'Avenir Next GEO W05 Demi';
    &:hover {

    }

    input{display: none;}
    span {
      display: flex;
      align-items: center;
      color: ${colors.genoaBlue};
      font-size: 16px;
      svg{
        font-size: 1.3rem;
      }
    }
  }



  .submit-vidas {

  }

  .data-resp {
    margin-top: 50px;
    color: ${colors.genoaBlue};
    font-size: 16px;
    font-family: 'Avenir Next GEO W05 Bold';
  }

  .div-input-files {
    display: flex;
    align-items: center;
  }

    span.error-msg {
      color: #f7365a;
      font-size: 13px;
      margin-bottom: 5px;
      display: inline-block;
    }

    .preview{
      padding: 20px 0;
      box-sizing: border-box;
      width: 100%;

      .logoPreview {
        width: 220px;

        img {
          width: 100%;
          height: auto;
        }
      }
    }


    button.btn {
      font-family: 'Avenir Next GEO W05 bold';
      padding: 7px 15px;
      background: #f3ecff;
      border: 1px ${colors.genoaBlue} solid;
      border-radius: 5px;
      color: ${colors.genoaBlue};
      cursor: pointer;
      :hover{
        background: #e3d2ff;
      }
      :disabled{
        opacity: 0.5;
      }
    }


  .MuiSnackbarContent-root{
    background-color: #d8c1ff;
    color: #4c2d82;
    font-family: 'Avenir Next GEO W05 Bold';
  }

  .switch-planos {
    cursor: pointer;
    font-family: 'Avenir Next GEO W05 Bold';
    color: ${colors.genoaBlue};
    font-size: 15px;
  }
  .data-resp-errors {
    .data-error-columns {
      overflow-y: auto;
      max-height: 350px;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    .main-error-message {
      font-family: 'Avenir Next GEO W05 Bold';
      margin-bottom: 30px;
      color: ${colors.genoaBlue};
      font-size: 20px;
      text-decoration: underline;
      align-items: center;
    }
    .line-error-message {
      margin-top: 15px;
    }

    .warning-icon {
      margin-right: 10px;
      margin-bottom: -2px;
    }

    .error-column-message {
      margin-top: 5px;
      margin-left: 10px;
      font-family: 'Avenir Next GEO W05 Regular';
    }
  }
  .line-error-message {
    font-family: 'Avenir Next GEO W05 Demi';
  }

  .send-btn-documentos {
    display: flex;
    align-items: center;
    column-gap: 5px;

    // background-color: ${colors.white};
    border: none;
    cursor: pointer;
    font-family: 'Avenir Next GEO W05 Bold';
    padding: 10px 10px;
    font-size: 1.3rem;
    color: ${colors.genoaDarkBlue};
    border-radius: 6px;

    span {
      margin-bottom: 3px;
    }

    :hover{
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .send-btn-cadastro-estipulantes {
    display: flex;
    align-items: center;
    column-gap: 10px;

    // background-color: ${colors.white};
    border: none;
    cursor: pointer;
    font-family: 'Avenir Next GEO W05 Bold';
    padding: 10px 10px;
    font-size: 17px;
    color: ${colors.genoaDarkBlue};
    border-radius: 6px;

    svg {

    }

    :hover{
      text-decoration: underline;
      cursor: pointer;
    }
  }

`;

export const InputFile = styled.div`
  margin: 20px 0 0 0;

  .title-default {
    color: ${colors.genoaBlue};
    font-size: 17px;
    font-family: 'Avenir Next GEO W05 Bold';
    margin-bottom: 30px;
  }

  .documentos-span {
    color: ${colors.genoaGrey};
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 17px;
  }

  .div-input-files {
    margin-top: 10px;
    display: flex;
    align-items: center;
    padding: 10px 10px;
    margin: 20px 0 30px 0;
    column-gap: 10px;
    height: 44px;
  }

  .label-file-documentos {
    cursor: pointer;
    border: none;
    font-size: 17px;
    color: ${colors.genoaDarkBlue};
    background: none;
    border-radius: 10px;
    font-family: 'Avenir Next GEO W05 bold';
    :hover {
      background: #efefef;
      text-decoration: underline;
    }

    span {
      font-size: 13px;
    }

    input {
      display: none;
    }

    svg {
      font-size: 17px;
    }

    .documento-nome-span {
      display: flex;
      align-items: center;
      column-gap: 5px;
    }
  }
`;
