import React from 'react'

import logoAWS from 'assets/imgs/aws.png'
import coinsure_logo from 'assets/imgs/logo-coinsure.svg'

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import { Wraper, GridFooter } from './styles'
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <Wraper>
      <footer>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <img src={coinsure_logo} className="logo-footer" alt="logo" />
            </Grid>
            <Grid item xs={6}>
              <div className="flex-content-end"><img className="logo-aws" src={logoAWS} alt="aws" /></div>
            </Grid>
          </Grid>
          
          <GridFooter>
            <div className="list-contact contato">
              <div className="contact-info">
                  A Coinsure é uma corretora de seguros online.<br /><br />
                  COINSURE CORRETORA DE SEGUROS E SOLUCOES EM TECNOLOGIA LTDA.
                  <br /> 43.304.589/0001-24
                  <br />Registrada na SUSEP sob o nº 212124171
                </div>
              <div className="contact-info">
                Rua Álvaro Rodrigues 182 conj. 91
                  <br /> Brooklin Paulista - 04582-000
                  <br /> São Paulo - SP Brasil
                </div>
              <div className="contact-info">
                  oi@coinsure.com.br
                  <br /> Whatsapp: (11) 94378-6364
                </div>
            </div>



            <div className="seguros">
              <h6>Seguros financeiros</h6>
              <ul className="list-footer">
                <li>D&O - RC Administradores</li>
                <li>E&O - RC Profissional</li>
                <li>Seguro Garantia</li>
                <li>Seguro Cyber</li>
                <li>Seguro de Crédito</li>
              </ul>
            </div>


            <div className="beneficios">
              <h6>Benefícios</h6>
              <ul className="list-footer">
                <li>Seguro Saúde</li>
                <li>Seguro de Vida</li>
                <li>Seguro Odontológico</li>
                <li>Previdência Capitalização</li>
              </ul>
            </div>

            <div className="patrimoniais">
              <h6>Patrimoniais</h6>
              <ul className="list-footer">
                <li>Seguro para frota</li>
                <li>Seguro de transportes</li>
                <li>Seguro para equipamentos</li>
                <li>Seguro de Incêndio e Lucros Cessantes</li>
              </ul>
            </div>
            <div className="termos">
              <h6>Termos</h6>
              <p>A plataforma <b>COINSURE</b> conecta os seus usuários e as seguradoras em uma ambiente digital. Com base nas informações que os usuários fornecem nos respectivos questionários de avaliação risco de cada seguro, a <b>COINSURE</b> apresenta propostas iniciais de seguro e, estando o proponente de acordo com alguma delas a <b>COINSURE</b> endereça a proposta para análise da seguradora, que poderá acatá-la da forma como foi redigida, rejeitá-la ou propor alterações. Nessa última hipótese, a <b>COINSURE</b> levará tais alterações aos clientes e fará a interface da negociação das condições do seguro junto à seguradora. E, em caso de rejeição, a <b>COINSURE</b> auxiliará o proponente a buscar outro seguro. </p>

              <Link className="link" to="/politica-de-privacidade" target="blank" >Política de Privacidade da Plataforma Coinsure</Link>
            </div>


          </GridFooter>

        </Container>
      </footer>
    </Wraper>
  )
}

export default Footer;