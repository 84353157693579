import Button from 'components/DS/Button';
import ReactDatePicker from 'react-datepicker';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { formatUTC } from 'utils/calculateFunctions';

const DentalEditForm = ({
  contrato,
  renderContratoTitle,
  seguradoraOdonto,
  setSeguradoraOdonto,
  updatePlanoOdontoInitialValue,
  renderSeguradoraOptions,
  planoOdonto,
  setPlanoOdonto,
  renderPlanosOptions,
  numeroCarteirinhaOdonto,
  setNumeroCarteirinhaOdonto,
  dataEntradaOdonto,
  setDataEntradaOdonto,
  setInitialContractsOptions,
  planToEdit,
  setPlanToEdit
}) => {
  return (
    <div>
      {planToEdit === contrato.contrato_id ? (
        <>
          <div className="title-container">
            <Button
              variant={'transparent'}
              onClick={() => {
                setPlanToEdit('');
                setInitialContractsOptions('initial');
              }}
            >
              <FaArrowLeftLong />
            </Button>
            <span>{renderContratoTitle(contrato)}</span>
          </div>
          <div className="row">
            <div className="column">
              <label>Seguradora</label>
              <select
                value={seguradoraOdonto}
                onChange={(e) => {
                  setSeguradoraOdonto(e.target.value);
                  updatePlanoOdontoInitialValue(e.target.value);
                }}
              >
                <option disabled value="" style={{ fontWeight: 'bold' }}>
                  Selecione
                </option>
                {renderSeguradoraOptions(contrato)}
              </select>
            </div>
            <div className="column">
              <label>Plano</label>
              <select
                value={planoOdonto}
                onChange={(e) => {
                  setPlanoOdonto(e.target.value);
                }}
              >
                {renderPlanosOptions(contrato)}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="column">
              <label>Número de carteirinha</label>
              <input
                type={'text'}
                value={numeroCarteirinhaOdonto}
                onChange={(e) => {
                  setNumeroCarteirinhaOdonto(e.target.value);
                }}
              />
            </div>
            <div className="column">
              <label>Data de entrada</label>
              <ReactDatePicker
                className="input-data"
                selected={
                  dataEntradaOdonto ? formatUTC(dataEntradaOdonto, true) : null
                }
                onChange={(date) => {
                  setDataEntradaOdonto(formatUTC(date, true));
                }}
                dateFormat="dd/MM/yyyy"
                locale="ptBR"
              />
            </div>
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export default DentalEditForm;
