import { useState, useEffect } from 'react';
import { DarkBackground } from './styles';
import { createTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import api from 'services/api';
import services from 'apps/broker/services';
import FormCreateBusiness from './FormCreateBusiness';

const theme = createTheme({
  palette: {
    genoaBlue: {
      main: '#456AED'
    },
    darkGenoaBlue: {
      main: '#1E2552'
    }
  }
});

const DrawerNovoNegocio = ({
  setOpenDrawer,
  getNegociosData,
  corretores,
  isNewCard,
  setIsNewCard
}) => {
  const user = useSelector((state) => state.userReducers.user);

  const [transitionLeave, setTransitionLeave] = useState(false);
  const [tipo, setTipo] = useState('renovacao');
  const [produto, setProduto] = useState('saude');
  const [vidas, setVidas] = useState('');
  const [nomeFantasia, setNomeFantasia] = useState(undefined);
  const [cadastrado, setCadastrado] = useState(false);
  const [cnpj, setCnpj] = useState('');
  const [buscaCnpj, setBuscaCnpj] = useState('');
  const [estipulantes, setEstipulantes] = useState([]);
  const [estipulanteSelecionado, setEstipulanteSelecionado] = useState('0');
  const [optionsResponsavel, setOptionsResponsavel] = useState([]);
  const [responsavelSelecionado, setResponsavelSelecionado] = useState('0');
  const [isDisabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [dataContratos, setDataContratos] = useState([]);
  const [contratoSelecionado, setContratoSelecionado] = useState(null);

  const getProdutoId = () => {
    switch (produto) {
      case 'dental':
        return 2;
      case 'vida':
        return 3;
      default:
        return 1;
    }
  };

  const getTipoId = () => {
    switch (tipo) {
      case 'novo':
        return 2;
      default:
        return 1;
    }
  };

  const createNewNegociosHandler = async () => {
    const produto_id = getProdutoId();
    const tipo_id = getTipoId();

    let body = {};

    if (nomeFantasia !== undefined) {
      body = {
        tipo_id,
        produto_id,
        vidas,
        usuario_id: responsavelSelecionado,
        cadastrado,
        buscaCnpj,
        estipulanteSelecionado,
        nomeFantasia,
        contratoSelecionado
      };
    } else {
      body = {
        tipo_id,
        produto_id,
        vidas,
        usuario_id: responsavelSelecionado,
        cadastrado,
        buscaCnpj,
        estipulanteSelecionado,
        nomeFantasia: null,
        contratoSelecionado
      };
    }

    setLoading(true);

    const response = await services.business.createNewBusiness(body);
    
    if (response.status === 201) {
      setLoading(false);
      setIsNewCard(true);
      setConfirmation(true);
      setTimeout(() => {
        setConfirmation(false);
        setOpenDrawer(false);
      }, 2500);
    } else {
      setLoading(false);
      setConfirmation(false);
      setIsNewCard(false);
    }
  };

  const getCnpjInfo = async (cnpjNumbers) => {
    const response = await services.policyOwner.getRevenueServiceCNPJ(
      cnpjNumbers
    );
    if (response.status === 200) {
      setBuscaCnpj(response.data);
    }
  };

  const getAllCompanies = async () => {
    const response = await services.policyOwner.getAll();
    const estipulantesFormatado = response?.data
      ?.sort((a, b) => {
        if (a.razao_social > b.razao_social) {
          return 1;
        }
        return -1;
      })
      .map((item) => ({
        option: item.razao_social,
        value: item.id
      }));

    setEstipulantes(estipulantesFormatado);
  };

  const getCompanyAndContracts = async () => {
    // Precisa modificar o backend que recebe o valor no headers id.

    // const response = await services.policyOwner.getPolicyOwnerSelected(estipulanteSelecionado);

    // const contratosFormatados = response?.data[0]?.contratos?.map((item) => ({
    //   option: `${item.produto_nome} - ${item.seguradora_nome}`,
    //   value: item.contrato_id
    // }));

    // setContratoSelecionado(undefined);
    // setDataContratos(contratosFormatados);

    await api
      .get('/corretor/estipulante/atualizado', {
        headers: {
          id: estipulanteSelecionado,
          authorization: `Bearer ${user.token}`
        }
      })
      .then((resp) => {
        const contratosFormatados = resp?.data[0]?.contratos?.map((item) => ({
          option: `${item.produto_nome} - ${item.seguradora_nome} - ${item.numero_apolice}`,
          value: item.contrato_id
        }));

        setContratoSelecionado(null);
        setDataContratos(contratosFormatados);
      })
      .catch((err) => {
        throw err;
      });
  };

  useEffect(() => {
    const naoAlocado = {
      value: '0',
      option: 'Selecione'
    };
    if (corretores?.length) {
      const responsaveis = corretores?.map((item) => ({
        value: item.usuario_id,
        option: `${item.nome}`
      }));
      setOptionsResponsavel([naoAlocado, ...responsaveis]);
    }
  }, [corretores]);

  useEffect(() => {
    const cnpjNumbers = cnpj.replace(/[^0-9]/g, '');
    if (cnpjNumbers.length === 14) {
      getCnpjInfo(cnpjNumbers);
    } else {
      setBuscaCnpj(undefined);
    }
  }, [cnpj]);

  useEffect(() => {
    if (estipulanteSelecionado !== '0') {
      getCompanyAndContracts();
    }
  }, [estipulanteSelecionado]);

  useEffect(() => {
    setEstipulanteSelecionado('0');
  }, [tipo]);

  useEffect(() => {
    if (tipo && produto && vidas) {
      if (responsavelSelecionado !== '0') {
        if (tipo === 'renovacao') {
          if (estipulanteSelecionado && contratoSelecionado) {
            setDisabled(false);
          } else {
            setDisabled(true);
          }
        }
        if (tipo === 'novo') {
          if (cadastrado) {
            if (estipulanteSelecionado) {
              setDisabled(false);
            } else {
              setDisabled(true);
            }
          } else {
            if (buscaCnpj && buscaCnpj.nome) {
              setDisabled(false);
            } else {
              setDisabled(true);
            }
          }
        }
      }
    } else {
      setDisabled(true);
    }
  }, [
    tipo,
    produto,
    vidas,
    responsavelSelecionado,
    cadastrado,
    buscaCnpj,
    estipulanteSelecionado,
    contratoSelecionado
  ]);

  useEffect(() => {
    getAllCompanies();
  }, []);

  useEffect(() => {
    getNegociosData();
  }, [isNewCard]);

  return (
    <DarkBackground
      style={{
        animation: transitionLeave ? 'fade-out 0.225s forwards' : ''
      }}
    >
      <FormCreateBusiness
        theme={theme}
        transitionLeave={transitionLeave}
        setTransitionLeave={setTransitionLeave}
        setOpenDrawer={setOpenDrawer}
        tipo={tipo}
        setTipo={setTipo}
        produto={produto}
        setProduto={setProduto}
        buscaCnpj={buscaCnpj}
        setBuscaCnpj={setBuscaCnpj}
        cnpj={cnpj}
        setCnpj={setCnpj}
        estipulanteSelecionado={estipulanteSelecionado}
        setEstipulanteSelecionado={setEstipulanteSelecionado}
        cadastrado={cadastrado}
        setCadastrado={setCadastrado}
        vidas={vidas}
        setVidas={setVidas}
        nomeFantasia={nomeFantasia}
        setNomeFantasia={setNomeFantasia}
        contratoSelecionado={contratoSelecionado}
        setContratoSelecionado={setContratoSelecionado}
        responsavelSelecionado={responsavelSelecionado}
        setResponsavelSelecionado={setResponsavelSelecionado}
        optionsResponsavel={optionsResponsavel}
        estipulantes={estipulantes}
        dataContratos={dataContratos}
        isDisabled={isDisabled}
        loading={loading}
        confirmation={confirmation}
        createNewNegociosHandler={createNewNegociosHandler}
      />
    </DarkBackground>
  );
};

export default DrawerNovoNegocio;
