import React, { useState } from 'react';
import { Wrapper } from './styles-ticketsContent';
import { FaAngleDoubleRight } from 'react-icons/fa';
import { dataDiff } from 'utils/calculateFunctions';

import TextEditor from 'components/TextEditor';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import parse from 'html-react-parser';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { CircularProgress } from '@material-ui/core';
import moment from 'moment-timezone';
import { useUser } from 'contexts/user';
import api from 'services/api';
import services from 'apps/hr/services';

const DrawerTicketContent = ({ data, onClose }) => {
  const { user } = useUser();

  const [details, setDetails] = React.useState([]);
  const [messages, setMessages] = React.useState([]);
  const [openEditor, setOpenEditor] = React.useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = React.useState(false);

  function getData() {
    setLoading(true);
    api
      .get('/rh/ticket', {
        headers: {
          id: data.id_ticket,
          authorization: `Bearer ${user.token}`
        }
      })
      .then((resp) => {
        setDetails(resp.data);
        // console.log('mensagem corretora', resp.data.messages)

        const orderMessages = resp.data?.messages?.sort((a, b) => {
          if (a.created_at > b.created_at) {
            return -1;
          }
          return 1;
        });

        setMessages(orderMessages);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const sendMessageRh = async () => {
    const htmlMessage = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );

    const formData = new FormData();
    formData.append('mensagem', htmlMessage);
    files.forEach((file, index) => {
      formData.append(index, file);
    });

    const response = await services.tickets.createMessageByHr(
      formData,
      data,
      user,
      'PendenciaCorretor',
      'pendencia'
    );

    if (response.status === 200) {
      setEditorState(false);
      setFiles([]);
      getData();
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  const mensagemDataFusoHorario = (created_at) => {
    const timestamp = new Date(created_at).getTime();

    return moment.tz(timestamp, 'America/Sao_Paulo').format('DD/MM/YYYY HH:mm');
  };

  return (
    <Wrapper>
      {!loading ? (
        <>
          <header>
            <div className="back">
              <span onClick={() => onClose(false)} className="icon-back">
                <ArrowBackIcon fontSize="inherit" />
              </span>
            </div>
            <div className="title">
              <h2>Detalhes do Ticket</h2>
              <div className="detalhes-second-row">
                <span className="date">
                  Criado em{' '}
                  {moment(details.created_at).utc().format('DD/MM/YYYY')} (
                  {dataDiff(details.created_at)} dias)
                </span>
              </div>
            </div>
          </header>
          <section className="infos">
            <div className="open">
              Ticket aberto por{' '}
              {details.nome
                ? details.nome + ' ' + details.sobrenome
                : details.nome_criador}
            </div>
            <div className="status">
              <div className="text-icon">
                <FaAngleDoubleRight /> Status: {details.descricao}{' '}
                {details.status_id === 3 || details.status_id === 7 ? null : ''}
              </div>
              {/* <div className="text-icon"> <MdTraffic />Prioridade: <span className="red">{details.prioridade}</span></div> */}
            </div>
          </section>
          <section>
            <div className="titleTicket">
              {details.assunto} - #{details.id_ticket}
            </div>
            <div className="messages">
              {messages?.map((message, index) => {
                return (
                  <div className="messageUser" key={index}>
                    <div className="user-date">
                      {message.usuario_rh_id
                        ? message.nome + ' ' + message.sobrenome + ' '
                        : message.autor
                        ? message.autor + ' '
                        : null || message.corretora_id
                        ? message.nome + ' '
                        : message.autor
                        ? message.autor + ' '
                        : null}
                      em {mensagemDataFusoHorario(message.created_at)}
                    </div>
                    {message.corretora_id ? (
                      <div className="messageText  resp-msg">
                        {message.file !== undefined ? (
                          <div>
                            {message.file?.map((file, index) => {
                              return (
                                <div key={index} className="messageFile">
                                  <a
                                    href={file.attachment_url}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {file.name}
                                  </a>
                                </div>
                              );
                            })}
                          </div>
                        ) : null}
                        {parse(message.mensagem)}
                      </div>
                    ) : (
                      <div className="messageText  open-msg">
                        {message.file !== undefined ? (
                          <div>
                            {message.file?.map((file, index) => {
                              return (
                                <div className="messageFile">
                                  <a
                                    href={file.attachment_url}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {file.name}
                                  </a>
                                </div>
                              );
                            })}
                          </div>
                        ) : null}
                        {parse(message.mensagem)}
                      </div>
                    )}
                  </div>
                );
              })}

              {/* <div className="messageUser">
            <div className="user-date">
              Rodrigo Pedroni em 25/05/2021
            </div>
            <div className="messageText  open-msg">
              Prezados, boa tarde.<br></br><br></br>

              Solicito a Fatura do mês de Abril para pagamento.
            </div>
          </div>
          <div className="messageUser">
            <div className="user-date">
              Débora Grossi em 26/05/2021
            </div>
            <div className="messageText resp-msg">
              Oi Rodrigo, boa tarde.<br></br><br></br>

              Solicitado para a segurado, assim que recebido encaminhamos para vocês.<br></br>

              Obrigada!
            </div>
          </div> */}
            </div>

            <div className="actions">
              <div className="buttons">
                {details.status_id === 3 || details.status_id === 7 ? null : (
                  <button
                    className="btn-toogle"
                    disabled={
                      details.status_id === 3 || details.status_id === 7
                    }
                    onClick={() => setOpenEditor(true)}
                  >
                    + Nova mensagem
                  </button>
                )}
              </div>

              {openEditor === true ? (
                <TextEditor
                  handleSendMessage={sendMessageRh}
                  editorState={editorState}
                  setEditorState={setEditorState}
                  files={files}
                  setFiles={setFiles}
                />
              ) : null}
            </div>
          </section>
        </>
      ) : (
        <div className="loading">
          <CircularProgress
            className="circular-spinner"
            size={120}
            thickness={2.3}
          />{' '}
          <p>Carregando...</p>
        </div>
      )}
    </Wrapper>
  );
};

export default DrawerTicketContent;
