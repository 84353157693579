import styled from 'styled-components';
import colors from 'styles/colors';

export const DrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  border-left: 10px solid ${colors.genoaBlue};
  border-radius: 10px;
  background-color: ${colors.white};
  width: 450px;
  position: fixed;
  left: calc(60% - 305px);
  padding: 25px;

  .title_content {
    margin-bottom: 20px;
  }

  .title {
    font-family: Avenir Next Geo W05 Heavy;
    font-size: 1.2em;
  }

  .title_client {
    font-family: Avenir Next Geo W05 Heavy;
    font-size: 1em;
  }

  animation: up-down 0.2s forwards;


  @keyframes up-down {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: calc(50% - 200px);
    }
  }

  .cancelamento_container {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }

  .date_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
  }

  .button_container {
    display: flex;
    flex-direction: row;
    column-gap: 15px;

    .cancel_btn {
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      background-color: inherit;
      color: ${colors.genoaBlue};
  
      font-family: Avenir Next Geo W05 Demi;
      text-transform: uppercase;
      font-size: 0.75em;
      top: 0;
      right: 0;

      :hover {
        text-decoration: underline;
      }
    }
  }

  .check_confirm {
    color: green;
    font-size: 0.80em;
  }

  .custom_datepicker {
    width: 150px;
  }

  .datepicker {
    border: none;
    border-bottom: 1px solid ${colors.genoaBlue};
    display: inline-block;
    overflow: hidden;
    text-align: center;
    font-family: 'Avenir Next GEO W05 Regular';
    height: 22px;
    padding-bottom: 6px;
  }

  .cliente_container {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }

  .button_container_cliente {
    display: flex;
    flex-direction: row;
    column-gap: 30px;
  }

  .btn {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
  }

  .check_yes {
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    background-color: inherit;
    color: green;

    font-family: Avenir Next Geo W05 Demi;
    text-transform: uppercase;
    font-size: 0.75em;
    top: 0;
    right: 0;

    :hover {
      text-decoration: underline;
    }
  }

  .cancel_btn {
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    background-color: inherit;
    color: red;

    font-family: Avenir Next Geo W05 Demi;
    text-transform: uppercase;
    font-size: 0.85em;
    top: 0;
    right: 0;

    :hover {
      text-decoration: underline;
    }
  }

`