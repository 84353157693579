import styled from 'styled-components';
import colors from 'apps/broker/styles/colors';

export const Wrapper = styled.div`
  width: 1065px;
  box-sizing: content-box;
  min-height: 101vh;

  .loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin: 25% auto;

    .circular-spinner {
      color: ${colors.rhBlue};
    }

    p {
      margin-top: 5px;
      color: ${colors.genoaGrey};
      font-size: 1.2rem;
    }
  }
    
  header{
    background-color: ${colors.rhBlue};
    padding: 20px;
    padding-left: 65px;
   
    h2{
      font-family: 'Avenir Next GEO W05 Bold';
      color:#fff;
      font-size: 35px;
      margin: 10px 0 20px 0;
    }
    span.date{
      color:#fff
    }
    .back{
      .icon-back{
        color: #fff;
        cursor: pointer;
        font-size: 2rem;
      }
    }

    .detalhes-second-row {
      display: flex;
      justify-content: space-between;
      color: white;
    }

    .status-change-handler-container {
      color: white;
      display: flex;
      margin-right: 10px;

      .mudar-status-title {
        margin-right: 10px;

        :hover {
          cursor: pointer;
        }
      }

      .arrow-icon {
        width: 13px;
        text-align: center;
        :hover {
          cursor: pointer;
          background-color: #1c2a3929;
        }
      }

      .width-transition {
        overflow: hidden;
        height: 22px;
        transition: width 0.4s ease-in-out;
        span {
          white-space: nowrap;
        }
      }
    }

    .status-change-options-container {
      span {
        margin-left: 10px;

        :hover {
          cursor: pointer;
          border-bottom: solid 1px white;
        }
      }
    }

  }
  section{
    padding: 20px;
    padding-left: 65px;
  }
  .linkFile{
    color: blue;
  }
  section.infos{
    padding: 20px;
    padding-left: 65px;
      
    .open{
      font-family: 'Avenir Next GEO W05 Demi';
      text-decoration: underline;
      margin-bottom: 20px;
    }
    .status{
      >div{
        margin-bottom: 10px;
      }
       .text-icon{
        display: flex;
        align-items: center;
        svg{
          margin-right: 5px;
          font-size: 1.2rem;
        }
        span{
          margin-left: 4px;
          font-family: 'Avenir Next GEO W05 Bold';
        }
        .red{color:red}
      }
    }
   
  }

  .btn-edit {
    display: flex;
    svg {
      margin-left: 10px;
      max-width: 15px;
      max-height: 15px;
    }
  }

  .titleTicket{
    font-family: 'Avenir Next GEO W05 Bold';
    margin-bottom: 20px;
  }

  .dependentes {
    margin-top: 30px;
  }

  .messageUser{
    margin-bottom: 25px;;
    .messageText{
      border-radius: 10px;
      box-sizing: border-box;
      padding: 20px;
      max-height: 450px;
      overflow: auto;
    }
    .open-msg{
      background: #ecf0ff;
    }
    .resp-msg{
      background: #F5F5F5;
    }
    .user-date {
      margin-bottom: 6px;
    }
  }

  .newMessage{
    button.btn-toogle{
      font-size: 1rem;
      font-family: 'Avenir Next GEO W05 Demi';
      background: none;
      border: none;
      cursor: pointer;
      text-decoration: underline;
      color: ${colors.rhBlue};
    }
  }
  .actions{
    margin-bottom: 250px;
    .buttons{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    

    button.btn-toogle{
      font-size: 1rem;
      font-family: 'Avenir Next GEO W05 Demi';
      background: none;
      border: none;
      cursor: pointer;
      text-decoration: underline;
      color: ${colors.rhBlue};

      :disabled{
        opacity: 0.6;

      }
    }
    .buttons2{
      display: flex;
      flex-direction: row-reverse;
      margin-right: 20px;
    }

    button.btn-close {
      display: flex;
      padding: 5px 7px;
      margin-right: 20px;
      color: #ffffff;
      border: ${colors.rhBlue} 1px solid;
      border-radius: 5px;
      background: ${colors.rhBlue};
      cursor: pointer;
      font-family: 'Avenir Next GEO W05 Demi';
      :hover{
        color: ${colors.rhBlue};
        background-color: ${colors.white};
      }
    }

    button.btn-archived {
      display: flex;
      padding: 5px 7px;
      margin-right: 20px;
      color: #ffffff;
      border: ${colors.rhBlue} 1px solid;
      border-radius: 5px;
      background: ${colors.rhBlue};
      cursor: pointer;
      font-family: 'Avenir Next GEO W05 Demi';
      :hover{
        color: ${colors.rhBlue};
        background-color: ${colors.white};
      }
    }

    button.btn-reopen {
      display: flex;
      padding: 5px 7px;
      color: #ffffff;
      border: ${colors.rhBlue} 1px solid;
      border-radius: 5px;
      background: ${colors.rhBlue};
      cursor: pointer;
      font-family: 'Avenir Next GEO W05 Demi';
      :hover{
        color: ${colors.rhBlue};
        background-color: ${colors.white};
      }
    }
  }  
  
  .details{
    margin-top: 10px;
  }

  .plano{
    font-family: 'Avenir Next GEO W05 Demi';
    margin-bottom: 5px;
  }

  .inputs{
    margin-top: 20px;
    max-width: 300px;
    .input-group{
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      label{
        font-family: 'Avenir Next GEO W05 Demi';
        color: #3B3838;
        font-size: 14px;
      }
      input{
        border: 1px solid ${colors.rhBlue};
        padding: 10px 10px;
        border-radius: 10px;
        margin: 5px 0 10px 0;
        font-size: 1rem;
      
      }
    }

    .errorsMsg{
      color: #ff1b5a;
      font-size: 13px;
      margin-top: 10px;
    }
    .react-datepicker-wrapper {
      width: 100%;
      .input-data{
        border-radius: 10px;
        background: #FFFFFF;
        border: 0.5px solid ${colors.rhBlue};
       // text-align: center;
        margin: 10px 0 10px 0;
        font-size: 1rem;
        padding: 10px 10px;
    }
  }
  .input-date-area{
    display: flex;
    flex-direction: column;    

  }

  }

  .btn-area{
    margin:1rem 0 2rem 0
  }

  button.send-btn{
    background: #eadeff;
    border: none;
    cursor: pointer;
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 1rem;
    color: ${colors.rhBlue};
    padding: 4px 15px;
    border-radius: 6px;
  }

  .files{
    padding: 20px 0px;

    .file-item{
      a{
        text-decoration: none;
        color: ${colors.rhBlue};
        display: flex;
        align-items: center;
        gap: 5px;

        span{
          color: inherit;
        }
        svg{
          color: inherit;
        }
      }
    }
  }

  .messageUserAlt {
    margin-top: 20px;
    .messageText{
      border-radius: 10px;
      box-sizing: border-box;
      padding: 20px;
      max-height: 230px;
      overflow: auto;
    }
    .open-msg{
      background: #F5F5F5;
    }
    .resp-msg{
      background: #DCEEFF;
    }
    .user-date {
      margin-bottom: 6px;

    }
  }

  button.open-btn{
    background: #eadeff;
    border: none;
    cursor: pointer;
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 1rem;
    color: ${colors.rhBlue};
    padding: 4px 15px;
    border-radius: 6px;
    :hover{
      background: #eadeff;
    }
  } 

  .btn-movimentacao {
    margin-left: 20px;
  }

  button.btn-archived-mov {
    background: #eadeff;
    border: none;
    cursor: pointer;
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 1rem;
    color: ${colors.rhBlue};
    padding: 4px 15px;
    border-radius: 6px;
    margin-left: 20px;
    :hover{
      background: #eadeff;
    }
  }
  
  button.btn-reopen-mov {
    background: #eadeff;
    border: none;
    cursor: pointer;
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 1rem;
    color: ${colors.rhBlue};
    padding: 4px 15px;
    border-radius: 6px;
    margin-left: 20px;
    :hover{
      background: #eadeff;
    }
  }

  select{
    font-family: 'Avenir Next GEO W05 Regular';
    font-size: 1rem;
    padding: 10px 10px;
    border: 0.5px solid ${colors.rhBlue};
    border-radius: 5px;
  }

  .inner-abas-movimentacoes {
    width: 100%;
    height: 67px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    font-family: 'Avenir Next GEO W05 bold';
    font-size: 20px;
    color: #3B3838;

    .inner-aba-option {
      background-color: #ccc;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px #a0a0a0 solid;
      :hover {
        cursor: pointer;
      }

      .aba-option-title {
        margin-left: 5px;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
      }
    }

    .inner-aba-option-selected {
      background-color: #ececec;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px #a0a0a0 solid;
      :hover {
        cursor: pointer;
      }

      .aba-option-title {
        margin-left: 5px;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
      }
    }
  }

  .estipulante-select-container {
    min-height: 75.6px;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    color: ${colors.genoaGrey};
    .estipulante-select-row{
      display: flex;
      align-items: center;
      .circular-spinner-container {
        margin-top: 8px;
        padding-left: 20px;
        display: flex;
        align-items: center;
  
        .circular-spinner{
          color: ${colors.rhBlue};
        }
      }
    }
    .estipulante-title {
      font-size: 1.1rem;
      font-weight: bold;
    }

    .estipulante-select {
      margin-top: 10px;
      
      font-size: 0.9rem;
      width: 500px;
      border-radius: 10px;
    }
  }

  .sub-estipulante-select-container {
    height: 0px;
    overflow: hidden;
    transition: height 0.8s ease-in-out;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    color: ${colors.genoaGrey};
    
    .estipulante-title {
      font-size: 1.1rem;
      font-weight: bold;
    }

    .estipulante-select {
      margin-top: 10px;
      
      font-size: 0.9rem;
      width: 500px;
      border-radius: 10px;
    }
  }

  .options-container {
    height: 0;
    overflow: hidden;
    transition: height 0.8s ease-in-out;
  }

  .planos-options-container {
    height: 26px;
    padding-top: 20px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    width: max-content;
    color: ${colors.genoaGrey};
    display: grid;
    grid-template-columns: 25px 400px 100px;
    align-items: center;
    font-size: 1.2rem;
    margin-left: 40px;
    svg {
      fill: currentColor;
      width: 20px;
    }

    :hover {
      cursor: pointer;
    }

    .plano-option-icon {
      margin-right: 5px;
    }

    .plano-option-select {
      width: 0px;
      overflow: hidden;
      transition: width 0.4s ease-out;
      select {
        font-size: 0.9rem;
        width: 240px;
        margin-left: 60px;
        height: 24.8px;
        padding: 0px;
      }
    }
  }

  .planos-options-container-selected {
    height: 26px;
    padding-top: 20px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    width: max-content;
    color: ${colors.rhBlue};
    display: grid;
    grid-template-columns: 25px 400px 100px;
    align-items: center;
    font-size: 1.2rem;
    margin-left: 40px;
    svg {
      fill: currentColor;
      width: 20px;
    }

    :hover {
      cursor: pointer;
    }

    .plano-option-icon {
      margin-right: 5px;
    }

    .plano-option-select {
      width: 300px;
      overflow: hidden;
      transition: width 0.4s ease-in;
      select {
        font-size: 0.9rem;
        width: 240px;
        margin-left: 60px;
        height: 24.8px;
        padding: 0px;
      }
    }
  }

  .form-beneficiario-container {
    color: ${colors.genoaGrey};
    padding-top: 40px;
    display: flex;
    justify-content: center;
    font-size: 1.1rem;

    .beneficiario-option-container {
      width: 250px;
      display: flex;
      justify-content: space-between;
    }
    
    .checkbox-label {
      display: flex;
      align-items: center;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      span {
        margin-left: 5px;
      }
      :hover {
        cursor: pointer;
      }
    }

    .checkbox-label-selected {
      display: flex;
      align-items: center;
      color: ${colors.rhBlue};
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      span {
        margin-left: 5px;
      }
      :hover {
        cursor: pointer;
      }
    }
  }

  .form-beneficiario {
    width: 600px;

    .datepicker-row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 10px;
      padding-bottom: 10px;

      .input-data {
        width: 100%;
        text-align: center;
        font-family: 'Avenir Next GEO W05 Regular';
        font-size: 1rem;
        padding: 5px 10px;
        border: 0.5px solid ${colors.rhBlue};
        border-radius: 5px;
        ::placeholder {
          color: #b8b9bb;
          text-align: center;
        }
      }
    }

    .grau-parentesco-row {
      select{
        .options-titulares-container {
          color: red;
        }
      }
      .valid-options {
        color: ${colors.genoaGrey};
        
      }
    }

    .datepicker-start-date-row-container {
      display: flex;
      justify-content: center;
      padding-bottom: 10px;
    }
    .datepicker-start-date-row {
      
      .input-data {
        width: 295px;
        text-align: center;
        font-family: 'Avenir Next GEO W05 Regular';
        font-size: 1rem;
        padding: 5px 10px;
        border: 0.5px solid ${colors.rhBlue};
        border-radius: 5px;
        ::placeholder {
          color: #b8b9bb;
          text-align: center;
        }
      }
    }

    .form-first-line {
      display: grid;
      grid-template-columns: 2fr 1fr;
      column-gap: 20px;
    }

    .form-line-row {
      padding-bottom: 10px;
    }

    .input {
      width: 100%;
      text-align: center;
      font-family: 'Avenir Next GEO W05 Regular';
      font-size: 1rem;
      padding: 5px 10px;
      border: 0.5px solid ${colors.rhBlue};
      border-radius: 5px;
      ::placeholder {
        color: #b8b9bb;
        text-align: center;
      }
    }

    .titular-dependente-form-container {
      height: 0px;
      transition: height 0.5s ease-in-out;
      overflow: hidden;

      .titular-dependente-form {
        padding-top: 25px;

        .remover-dependente-row {
          display: flex;
          justify-content: end;
          align-items: center;
          font-size: 0.8rem;
          letter-spacing: 0.2px;
          color: red;
          padding-bottom: 10px;
          svg {
            margin-bottom: -2px;
          }

          span {
            margin-left: 4px;
            :hover{
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }
      }
    }

    .send-btn-container {
      display: flex;
      justify-content: center;
      padding-top: 15px;
    }

    .send-btn {
      color: white;
      margin-top: 5px;
      margin-bottom: 30px;
      padding: 4px 10px;
      background-color: ${colors.rhBlue};
      :hover {
        filter: contrast(120%);
      }
    }

    .confirmation-message {
      min-height: 40px;
      margin-bottom: 200px;
      color: ${colors.rhBlue};
      font-family: 'Avenir Next GEO W05 Demi';
      display: flex;
      font-size: 1.2rem;
      justify-content: center;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;

      .error-message {
        font-size: 1rem;
        color: red;
      }
    }

    .react-datepicker__day--selected:hover {
      color: white;
      background-color: #1c76d1;
      font-weight: bold;
    }

    .react-datepicker__day--selected {
      color: white;
      background-color: #1e88f2;
      font-weight: bold;
    }

    .react-datepicker__day--keyboard-selected:hover {
      color: #1e88f2;
      background-color: #f0f0f0;
    }

    .react-datepicker__day--keyboard-selected {
      color: #1e88f2;
      background-color: white;
      font-weight: bold;
    }

    .selecao-titular {
      font-family: 'Avenir Next GEO W05 Regular';
      font-size: 1rem;
      padding: 5px 10px;
      border: 0.5px solid ${colors.rhBlue};
      border-radius: 5px;


      .selecao-titular-hero-title {
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        color: #b8b9bb;
        text-align: center;
        display: grid;
        grid-template-columns: 570px 30px;
        align-items: center;

        .arrow-icon {
          color: #4c4c4c;
        }

        .titular-dependente-nome{
          color: ${colors.genoaGrey};
        }
        :hover {
          cursor: pointer;
        }
      }

      .titular-filtro-row {
        max-height: 47.4px;
        display: flex;
        justify-content: center;
      }

      .titular-tabela-row {
        padding-top: 10px;
        max-height: 540px;
        height: 235px; 
      }

      .titular-tabela {
        width: 100%;
        background-color: #white;
        height: 235px;
        border-radius: 10px;
        max-height: 235px;
        overflow-y: scroll;

        .table-header-row {
          border-top-right-radius: 5px;
          border-top-left-radius: 5px;
          height: 25px;
          align-items: center;
          background-color: ${colors.rhBlue};
          color: white;
          display: grid;
          grid-template-columns: 1.5fr 1fr;
        }

        .titular-table-body {
          max-height: 210px;
          overflow-y: scroll;
          display: block;

          tr: nth-child(2n) {
            background-color: #89a9cc1c;
          }

          td: first-child {
            width: max-content;
            user-select: none;
            -moz-user-select: none;
            -webkit-user-select: none;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            
            :hover {
              cursor: pointer;
              font-weight: bold;
            }
          }

          ::-webkit-scrollbar {
            width: 6px;
            background: transparent;
          }
          ::-webkit-scrollbar-thumb {
            background: ${colors.rhBlue};
            box-shadow: none;
            border-radius: 14px;
          }
          ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 2px grey;
            border-radius: 14px;
          }
        }

        .table-data-row {
          display: grid;
          grid-template-columns: 1.5fr 1fr;
        }

        th: first-child {
          text-align: left;
          padding-left: 30px;
        }
        th: last-child {
          text-align: center;
        }

        td: first-child {
          text-align: left;
          padding-left: 30px;
        }
        td: last-child {
          text-align: center;
          padding-left: 8px;
          user-select: none;
          -moz-user-select: none;
          -webkit-user-select: none;
        }
      }

      .input-titular-filtro {
        margin-top: 15px;
        width: 60%;
        text-align: center;
        font-family: 'Avenir Next GEO W05 Regular';
        font-size: 1rem;
        padding: 5px 10px;
        border: 0.5px solid ${colors.rhBlue};
        border-radius: 5px;
        ::placeholder {
          color: #b8b9bb;
          text-align: center;
        }
      }
    }

    .tabela-titulares-container {
      height: 0px;
      overflow: hidden;
      transition: 0.7s height ease-in-out;
    }

    .tabela-titulares-container-open {
      height: 300px;
      overflow: hidden;
      transition: 0.7s height ease-in-out;
    }

    .error-message-container {
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      font-size: 0.9rem;
      color: ${colors.rhBlue};
      padding-top: 15px;
      height: 27px;  
      display: flex;
      align-items: center;
      justify-content: end;
      font-family: 'Avenir Next GEO W05 Demi';

      div {
        height: 21.2px;
        span {
          margin-left: 4px;
          :hover {
            cursor: pointer;
            border-bottom: 1px solid;
          }
        }
      } 
    }
  }

  .form-remocao-beneficiario-container {
    color: ${colors.genoaGrey};
    padding-top: 30px;
    display: flex;
    justify-content: center;
    font-size: 1.1rem;
  }

  .form-remocao-beneficiario {
    width: 600px;

    .form-remocao-line-row {
      padding-bottom: 10px;
      :hover {
        cursor: pointer;
      }
    }

    .selecao-beneficiario {
      font-family: 'Avenir Next GEO W05 Regular';
      font-size: 1rem;
      padding: 5px 10px;
      border: 0.5px solid ${colors.rhBlue};
      border-radius: 5px;

      .selecao-beneficiario-hero-title {
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        text-align: center;
        display: grid;
        grid-template-columns: 570px 30px;
        align-items: center;

        .span-placeholder {
          color: #b8b9bb;
        }

        .span-hero-title {
          color: ${colors.genoaGrey};
        }

        :hover {
          cursor: pointer;
        }

        .arrow-icon {
          color: #4c4c4c;
        }
      }

      .tabela-beneficiarios-container {
        height: 0px;
        overflow: hidden;
        transition: 0.7s height ease-in-out;
      }

      .tabela-beneficiarios-container-open {
        height: 400px;
        overflow: hidden;
        transition: 0.7s height ease-in-out;
      }

      .beneficiario-tabela-row {
        padding-top: 20px;
        max-height: 540px;
        height: 235px; 
      }

      .beneficiario-tabela {
        width: 100%;
        background-color: #white;
        height: 235px;
        border-radius: 10px;
        max-height: 235px;
        overflow-y: scroll;

        .table-header-first-row {
          border-top-right-radius: 5px;
          border-top-left-radius: 5px;
          padding-top: 10px;
          padding-left: 20px;
          padding-bottom: 15px;
          height: 25px;
          align-items: center;
          background-color: ${colors.rhBlue};
          color: white;
          display: grid;
          grid-template-columns: 1.3fr 1fr;
        }

        .table-header-row {
          height: 25px;
          align-items: center;
          background-color: ${colors.rhBlue};
          color: white;
          display: grid;
          grid-template-columns: 1.9fr 1fr;
          padding-bottom: 3px;
        }

        th: first-child {
          text-align: left;
          padding-left: 30px;
        }
        th: last-child {
          text-align: center;
        }

        td: first-child {
          text-align: left;
          padding-left: 30px;
        }
        td: last-child {
          text-align: center;
          padding-left: 8px;
          user-select: none;
          -moz-user-select: none;
          -webkit-user-select: none;
        }

        .table-name-input {
          border-color: transparent;
          padding-top: 3px;
          padding-bottom: 3px;
          padding-left: 8px;
          padding-right: 8px;
          text-align: center;
          font-size: 0.9 rem;
          border-radius: 5px;

          ::placeholder {
            color: #b8b9bb;
            text-align: center;
          }
        }

        .radio-input-group-container {
          display: flex;
        }

        .radio-input-container {
          padding-left: 10px;
          display: flex;
          align-items: center;

          :hover {
            cursor: pointer;
          }

          span {
            font-size: 0.9rem;
            user-select: none;
            -moz-user-select: none;
            -webkit-user-select: none;
          }
        }

        .radio-input {
          color: white;
        }

        .table-beneficiarios-data-row {
          display: grid;
          grid-template-columns: 1.9fr 1fr;
        }

        .beneficiario-table-body {
          max-height: 285px;
          overflow-y: scroll;
          display: block;

          tr: nth-child(2n) {
            background-color: #89a9cc1c;
          }

          td: first-child {
            user-select: none;
            -moz-user-select: none;
            -webkit-user-select: none;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            
            :hover {
              cursor: pointer;
              font-weight: bold;
            }
          }

          ::-webkit-scrollbar {
            width: 6px;
            background: transparent;
          }
          ::-webkit-scrollbar-thumb {
            background: ${colors.rhBlue};
            box-shadow: none;
            border-radius: 14px;
          }
          ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 2px grey;
            border-radius: 14px;
          }
        }
      }
    }
  }

  .beneficiario-planos-info-container {
    height: 0px;
    overflow: hidden;
    transition: height 0.7s ease-in-out 0.7s;
  }

  .beneficiario-planos-info-container-open {
    overflow: hidden;
    transition: height 0.7s ease-in-out 0.7s;
  }

  .remove-beneficiario-container {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    align-items: center;
  }

  .remove-info-title {
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 1.3rem;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
  }

  .remove-planos-options {
    padding-top: 20px;
    select {
      font-family: "Avenir Next GEO W05 Regular";
      padding: 8px;
      width: 320px;
      border-radius: 8px;
      border: 1px ${colors.rhBlue} solid;
      margin-top: 12px;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;

      :focus-visible {
        outline: 1px ${colors.rhBlue}f5 auto;
      }
    }
  }

  .remove-plano-option {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    .plano-icon {
      width: 20px;
      height: 20px;
      padding-right: 5px;
    }
    :hover {
      cursor: pointer;
    }
  }

  .remove-plano-option-selected {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    color: ${colors.rhBlue};
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    .plano-icon {
      width: 20px;
      height: 20px;
      padding-right: 5px;
      fill: currentColor;
    }
    :hover {
      cursor: pointer;
    }
  }

  .span-tipo-plano {
    padding-left: 20px;
  }

  .remove-plano-option-inactive {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    color: ${colors.genoaDisabledGrey};

    .plano-icon {
      width: 20px;
      height: 20px;
      padding-right: 5px;
      fill: currentColor;
    } 
  }

  .input-data {
    width: 120px;
    border: 1px solid ${colors.rhBlue};
    display: inline-block;
    overflow: hidden;
    text-align: center;
    font-family: 'Avenir Next GEO W05 Regular';
    border-radius: 7px;
    line-height: 1.2rem;
    padding: 6px;

    .react-datepicker-wrapper {
      display: inline-block;
      padding: 0;
      border: 0;
      width: min-content;
    }

    :hover {
      cursor: pointer;
    }
  }

  .span-date-container {
    padding-top: 20px;
    width: 120px;
    height: 33px;
    display: flex;
    justify-content: space-between;
    transition: all .4s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .span-date-title {
    padding-top: 10px;
  }

  .datepicker-selector-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
  }

  .clear-date-option {
    font-size: 0.8rem;
    color: ${colors.rhBlue};
    height: 22.8px;
    font-weight: bold;
    padding-top: 5px;
    :hover {
      cursor: pointer;
      border-bottom: 1px ${colors.rhBlue} solid;
    }
  }

  .clear-date-option-container {
    min-height: 23px;
  }

  .dependente-transition-container{
    transition: height 0.4s ease-in-out 0.7s;
    overflow: hidden;
  }

  .dependente-beneficiario-container-open {
    height: 100px;
    padding-top: 30px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    transition: height 0.3s ease-out;
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 1.3rem;
    color: ${colors.genoaGrey};
    .dependentes-titular-first-row {
      display: flex;
      align-items: center;
      justify-content: center;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;

      .arrow-container {
        margin-left: 5px;
        margin-bottom: -2px;
      }

      .arrow-open-icon {
        transition: all 0.3s;
      }

      .arrow-close-icon {
        transform: rotate(-90deg);
        transition: all 0.3s;
      }
      :hover {
        cursor: pointer;
      }
    }

    .dependentes-titular-second-row {
      padding-top: 10px;
    }
  }

  .dependentes-quantidade-info {
    margin-left: 7px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
  }

  .dependente-beneficiario-container {
    height: 40px;
    overflow: hidden;
    padding-top: 30px;
    display: flex;
    display: flex;
    justify-content: center;
    transition: height 0.3s ease-out;
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 1.3rem;
    color: ${colors.genoaGrey};
    .dependentes-titular-first-row {
      display: flex;
      align-items: center;
      justify-content: center;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;

      .arrow-container {
        margin-left: 5px;
        margin-bottom: -2px;
      }

      .arrow-open-icon {
        transition: all 0.3s;
      }

      .arrow-close-icon {
        transform: rotate(-90deg);
        transition: all 0.3s;
      }
      :hover {
        cursor: pointer;
      }
    }

    .dependentes-titular-second-row {
      padding-top: 10px;
    }
  }

  .dependentes-titular-lista {
    color: ${colors.genoaGrey};
    margin-top: 10px;
    font-family: Avenir Next GEO W05 Regular;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;

    .titular-nome {
      font-size: 1.1rem;
      
    }
  }

  .remove-beneficiario-button-container {
    height: 0px;
    display: flex;
    justify-content: center;
    padding-top: 30px;
    .send-btn {
      color: white;
      margin-top: 12px;
      padding: 4px 10px;
      background-color: #1e88f2;
      :hover {
        background-color: #1976d2;
      }
    }

    .send-btn-disabled {
      color: white;
      margin-top: 12px;
      padding: 4px 10px;
      }
    }
  }

  .remove-beneficiario-button-container-open {
    height: 48px;
    display: flex;
    justify-content: center;
    padding-top: 30px;
    .send-btn-remocao {
      color: white;
      margin-top: 12px;
      padding: 4px 10px;
      background-color: ${colors.rhBlue};
      :hover {
        filter: contrast(120%);
      }
    }

    .send-btn-remocao-disabled {
      color: white;
      margin-top: 12px;
      padding: 4px 10px;
      }
    }
  }

  .circular-spinner-container-detalhes {
    margin-top: 15vh;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    

    .circular-spinner-detalhes{
      color: ${colors.rhBlue};
      margin-bottom: 30px;
    }
    
    span {
      font-size: 1.2rem;
    }
  }

  .aba-remocao-margin-bottom {
    min-height: 250px;
    max-height: 250px;
  }

  .third-row-container{
    display: flex;
    justify-content: end;
    min-height: 22px;
  }
`

export const ModalCloseTicket = styled.div`
  padding: 15px 20px;

  .title{
    color: #3B3838;
    font-family: 'Avenir Next GEO W05 Bold';
    margin-bottom: 20px;
  }

  .actions {
   padding: 10px 0;
    .buttons{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .btn{
        font-family: 'Avenir Next GEO W05 Bold';
        cursor: pointer;
        padding: 6px 8px;
        border-radius: 5px;
        min-width: 120px;

        :last-child{
          margin-left:20px;
        }
      }

      .btn-confirm{
        color: #ffffff;
        border: ${colors.rhBlue} 1px solid;
        background: ${colors.rhBlue};
        :hover{
          color: ${colors.rhBlue};
          background-color: ${colors.white};
        }
      }
      .btn-canlcel{
        color: #eb3535;
        border: #eb3535 1px solid;
        background: white;
        :hover {
          color: white;
          background-color: #eb3535;
        }
      }

     
    }
  }
`

export const ModalEditTicket = styled.div`
  padding: 15px 20px;

  .title{
    color: #3B3838;
    font-family: 'Avenir Next GEO W05 Bold';
    margin-bottom: 20px;
  }

  .form-edit {
    display: flex;
    flex-direction: column;
    label {
      margin-bottom: 10px;
    }
    select {
      font-family: 'Avenir Next GEO W05 Regular';
      padding: 10px;
      width: 307px;
      border-radius: 8px;
      border: 1px ${colors.rhBlue} solid;
      margin-top: 10px;

      :focus-visible{
        outline: 1px ${colors.rhBlue}f5  auto;
      }
    }
  }

  .actions {
   padding: 10px 0;
    .buttons{
      .btn{
        font-family: 'Avenir Next GEO W05 Bold';
        cursor: pointer;
        padding: 6px 8px;
        border-radius: 5px;
        min-width: 120px;

        :last-child{
          margin-left:20px;
        }
      }

      .btn-confirm{
        color: #ffffff;
        border: ${colors.rhBlue} 1px solid;
        background: ${colors.rhBlue};
        :hover{
          color: ${colors.rhBlue};
          background-color: ${colors.white};
        }
      }
      .btn-canlcel{
        color: ${colors.rhBlue};
        border: ${colors.rhBlue} 1px solid;
        background: #f1e9ff;
      }
    }
  }
  
`
export const ModalArchiveTicket = styled.div`
  padding: 15px 20px;

  .title{
    color: #3B3838;
    font-family: 'Avenir Next GEO W05 Bold';
    margin-bottom: 20px;
  }

  .form-edit {
    display: flex;
    flex-direction: column;
    label {
      margin-bottom: 10px;
    }
    select {
      font-family: 'Avenir Next GEO W05 Regular';
      padding: 10px;
      width: 307px;
      border-radius: 8px;
      border: 1px ${colors.rhBlue} solid;
      margin-top: 10px;

      :focus-visible{
        outline: 1px ${colors.rhBlue}f5  auto;
      }
    }
  }

  .title-archive {
    margin-bottom: 20px;
  }

  .actions {
  padding: 10px 0;
    .buttons2{

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .btn{
        font-family: 'Avenir Next GEO W05 Bold';
        cursor: pointer;
        padding: 6px 8px;
        border-radius: 5px;
        min-width: 120px;

        :last-child{
          margin-left:20px;
        }
      }

      .btn-confirm2{
        color: #ffffff;
        border: ${colors.rhBlue} 1px solid;
        background: ${colors.rhBlue};
        :hover{
          color: ${colors.rhBlue};
          background-color: ${colors.white};
        }
      }
      .btn-canlcel2{
        color: #eb3535;
        border: #eb3535 1px solid;
        background-color: white;
        :hover{
          color: white;
          background-color: #eb3535;
        }
      }
    }
  }
`

export const ModalReopenTicket = styled.div`
padding: 15px 20px;

.title{
  color: #3B3838;
  font-family: 'Avenir Next GEO W05 Bold';
  margin-bottom: 20px;
}

.form-edit {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  label {
    margin-bottom: 10px;
  }
  select {
    font-family: 'Avenir Next GEO W05 Regular';
    padding: 10px;
    width: 307px;
    border-radius: 8px;
    border: 1px ${colors.rhBlue} solid;
    margin-top: 10px;

    :focus-visible{
      outline: 1px ${colors.rhBlue}f5  auto;
    }
  }
}

.actions {
padding: 10px 0;
  .buttons2{
    display: flex;
    justify-content: center;

    .btn{
      font-family: 'Avenir Next GEO W05 Bold';
      cursor: pointer;
      padding: 6px 8px;
      border-radius: 5px;
      min-width: 120px;

      :last-child{
        margin-left:20px;
      }
    }

    .btn-confirm2{
      color: #ffffff;
      border: ${colors.rhBlue} 1px solid;
      background: ${colors.rhBlue};

      :hover {
        color: ${colors.rhBlue};
        background-color: white;
      }
    }
    .btn-canlcel2{
      color: #eb3535;
      border: #eb3535 1px solid;
      background-color: white;

      :hover{
        color: white;
        background-color: #eb3535;
      }
    }
  }
}
`