import React from 'react';
import Sidebar from 'apps/broker/components/Sidebar';
import Header from 'apps/broker/components/Header';
import { Content, Main, Wrapper } from './styles';
import MainContentRelatorio from 'apps/broker/pages/Reports/components/MainMenuContent/MainContentRelatorio';

const Relatorios = () => {
  return (
    <Wrapper>
      <Sidebar />
      <Content>
        <Header />

        <Main>
          <MainContentRelatorio />
        </Main>
      </Content>
    </Wrapper>
  );
};

export default Relatorios;
