import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  min-width: 520px;
  color: #3b3838;

  .loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin: 50% auto;

    p {
      color: ${colors.lightBlue};
    }
  }

  header {
    background: ${colors.purple};
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 0.75rem;
    gap: 1.5rem;

    span {
      font-size: 2rem;
      cursor: pointer;
      transition: filter 0.4s;
      color: #fff;

      &:hover {
        .back {
          span {
            filter: brightness(0.8);
          }
        }
      }
    }

    .title {
      padding: 1rem;

      h2 {
        color: #feefff;
        font-family: "Avenir Next GEO W05 Demi";
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
      }

      span {
        color: #feefff;
        font-family: "Avenir Next GEO W05 Regular";
        font-size: 1rem;
      }
    }
  }

  section {
    padding: 1rem;

    .titleTicket {
      font-size: 1.25rem;
      font-family: "Avenir Next GEO W05 Demi";
    }
  }

  .infos {
    .author {
      font-size: 1.25rem;
      font-family: "Avenir Next GEO W05 Demi";
      text-align: center;
      padding: 1rem 0;
    }
    .status {
      font-size: 1.25rem;

      .text-icon {
        display: flex;
        align-items: center;
        gap: 0.75rem;

        svg {
          font-size: 1rem;
        }

        .btn-edit {
          cursor: pointer;
        }
      }
    }
  }

  .bodyTicket {
  }

  .bodyDetails {
    .type-beneficiary {
      .details {
        display: flex;
        flex-direction: column;
        gap: 0.375rem;

        h3 {
          font-size: 1rem;
          font-family: "Avenir Next GEO W05 Demi";
          color: ${colors.lightBlue};
        }

        .docs {
          a {
            color: ${colors.lightBlue};
            display: flex;
            align-items: center;
            gap: 0.25rem;
          }
        }

      }

      
      &+ .type-beneficiary {
          margin-top: 1.5rem;
        }
    }

    .files {
      padding: 0.5rem 0;
      .file-item {
        a {
          color: ${colors.lightBlue};
          display: flex;
          align-items: center;
          gap: 0.25rem;

          span {
            color: ${colors.lightBlue};
          }
        }
      }
    }
  }

  .messages {
    .single-message {
      padding: 0.5rem 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .titleTicket {
        font-size: 1rem;
        font-family: "Avenir Next GEO W05 Demi";
        color: ${colors.darkGray};
      }

      .messageText {
        background: #ededed;
        border-radius: 0.5rem;
        padding: 0.75rem 1rem;
      }
    }
  }

  .actions {
    padding: 1rem 0;
    button {
      cursor: pointer;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .newMsg {
        background: transparent;
        border: none;
        color: ${colors.purple};
        font-size: 1rem;

        &:hover {
          text-decoration: underline;
        }
      }

      .btn-close {
        background: transparent;
        border: 1px solid ${colors.purple};
        border-radius: 0.75rem;
        color: ${colors.purple};
        padding: 0.25rem 0.5rem;

        transition: all 0.4s;

        &:hover {
          background: ${colors.purple};
          color: ${colors.white};
        }
      }
    }
  }

  .btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin: 0.5rem auto;

    button {
      border: none;
      border-radius: 0.5rem;
      padding: 0.25rem 0.5rem;
      cursor: pointer;
      transition: all 0.2s;
      font-size: 1rem;

      &:hover {
        filter: brightness(0.8);
      }
    }
  }

  .btn-accept {
    background: rgba(110, 91, 205, 0.3);
    color: ${colors.purple};
  }

  .btn-refuse {
    background: rgba(198, 40, 40, 0.3);
    color: #c62828;
  }
`;


export const ModalCloseTicket = styled.div`
  padding: 15px 20px;

  .title {
    color: #3b3838;
    font-family: "Avenir Next GEO W05 Bold";
    margin-bottom: 20px;
  }

  .actions {
    padding: 10px 0;
    .buttons {
      display: flex;
      align-items: center;
      justify-content: space-around;
      .btn {
        font-family: "Avenir Next GEO W05 Bold";
        cursor: pointer;
        padding: 6px 8px;
        border-radius: 5px;
        min-width: 120px;

        :last-child {
          margin-left: 20px;
        }
      }

      .btn-confirm {
        color: #ffffff;
        border: #5a31a2 1px solid;
        background: #62A0E2;
        transition: filter 0.2s;

        &:hover {
          filter: brightness(0.9);
        }
      }
      .btn-cancel {
        color: #62A0E2;
        border: #bfa3f1 1px solid;
        background: #f1e9ff;
        transition: filter 0.2s;

        &:hover {
          filter: brightness(0.9);
        }
      }
    }
  }
`;

export const ModalEditTicket = styled.div`
  padding: 15px 20px;

  .title {
    color: #3b3838;
    font-family: "Avenir Next GEO W05 Bold";
    margin-bottom: 20px;
  }

  .form-edit {
    display: flex;
    flex-direction: column;
    label {
      margin-bottom: 10px;
    }
    select {
      font-family: "Avenir Next GEO W05 Regular";
      padding: 10px;
      width: 307px;
      border-radius: 8px;
      border: 1px #a26cff solid;
      margin-top: 10px;
      cursor: pointer;

      :focus {
        outline: none;
      }

      :focus-visible {
        outline: 1px #62A0E2f5 auto;
        
      }
    }
  }

  .actions {
    padding: 10px 0;
    .buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      .btn {
        font-family: "Avenir Next GEO W05 Bold";
        cursor: pointer;
        padding: 6px 8px;
        border-radius: 5px;
        min-width: 120px;
        transition: filter 0.2s;
        &:hover {
          filter: brightness(0.9);
        }
      }

      .btn-confirm {
        color: #ffffff;
        border: #5a31a2 1px solid;
        background: #62A0E2;
      }
      .btn-cancel {
        color: #62A0E2;
        border: #bfa3f1 1px solid;
        background: #f1e9ff;
      }
    }
  }
`;
