import { DrawerContent } from './styles';
import { CircularProgress, Radio, ThemeProvider } from '@mui/material';
import { ClickAwayListener } from '@material-ui/core';
import SelectStandard from 'apps/broker/components/Select';
import InputStandard from 'components/Input/InputStandard';
import FormNewBusiness from './FormNewBusiness';
import Button from 'components/DS/Button';

const FormCreateBusiness = ({
  theme,
  transitionLeave,
  setTransitionLeave,
  setOpenDrawer,
  tipo,
  setTipo,
  produto,
  setProduto,
  buscaCnpj,
  setBuscaCnpj,
  cnpj,
  setCnpj,
  estipulanteSelecionado,
  setEstipulanteSelecionado,
  cadastrado,
  setCadastrado,
  vidas,
  setVidas,
  nomeFantasia,
  setNomeFantasia,
  setContratoSelecionado,
  responsavelSelecionado,
  setResponsavelSelecionado,
  optionsResponsavel,
  estipulantes,
  dataContratos,
  contratoSelecionado,
  isDisabled,
  loading,
  confirmation,
  createNewNegociosHandler
}) => {
  const radioTipoClickHandler = (e) => {
    switch (e.target.name) {
      case 'tipo':
        return setTipo(e.target.value);

      case 'produto':
        return setProduto(e.target.value);

      default:
        break;
    }
  };

  const keyPressInputNumberHandler = (e) => {
    const oldValue = e.target.value;
    const newValue = oldValue?.replace(/[^0-9]/g, '');
    setVidas(newValue);
  };

  const keyPressInputNomeFantasiaHandler = (e) => {
    const newValue = e.target.value;
    setNomeFantasia(newValue);
  };

  const selectValueChangeHandler = (target) => {
    setEstipulanteSelecionado(target.value);
  };

  const selectRespValueHandler = (object) => {
    setResponsavelSelecionado(object.value);
  };

  const selectValueChangeContrato = (object) => {
    setContratoSelecionado(object.value);
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        setTransitionLeave(true);
        setTimeout(() => {
          setOpenDrawer(false);
        }, 200);
      }}
    >
      <DrawerContent className={transitionLeave ? 'leave' : ''}>
        <div className="popper_title">
          <span>Novo negócio</span>
        </div>
        <div className="form_container">
          <div className="form_option">
            <div className="form_option__title">Tipo:</div>
            <div className="filter_type__options">
              <ThemeProvider theme={theme}>
                <div className="radio_container">
                  <Radio
                    value="renovacao"
                    onClick={radioTipoClickHandler}
                    name="tipo"
                    color={'darkGenoaBlue'}
                    checked={tipo === 'renovacao'}
                  />
                  <label>Renovação</label>
                </div>
                <div className="radio_container">
                  <Radio
                    value="novo"
                    onClick={radioTipoClickHandler}
                    name="tipo"
                    className="radio-input dont-close"
                    color={'darkGenoaBlue'}
                    checked={tipo === 'novo'}
                  />
                  <label>Novo</label>
                </div>
              </ThemeProvider>
            </div>
          </div>
          <div className="form_option">
            <div className="form_option__title">Produto:</div>
            <div className="filter_type__options">
              <ThemeProvider theme={theme}>
                <div className="radio_container">
                  <Radio
                    value="saude"
                    onClick={radioTipoClickHandler}
                    name="produto"
                    className="radio-input dont-close"
                    color={'darkGenoaBlue'}
                    checked={produto === 'saude'}
                  />
                  <label>Saúde</label>
                </div>
                <div className="radio_container">
                  <Radio
                    value="dental"
                    onClick={radioTipoClickHandler}
                    name="produto"
                    className="radio-input dont-close"
                    color={'darkGenoaBlue'}
                    checked={produto === 'dental'}
                  />
                  <label>Dental</label>
                </div>
                <div className="radio_container">
                  <Radio
                    value="vida"
                    onClick={radioTipoClickHandler}
                    name="produto"
                    className="radio-input dont-close"
                    color={'darkGenoaBlue'}
                    checked={produto === 'vida'}
                  />
                  <label>Vida</label>
                </div>
              </ThemeProvider>
            </div>
          </div>
          <div className="form_option">
            <div className="form_option__title">Vidas:</div>
            <div className="custom_input__container">
              <InputStandard
                name={'vidas'}
                value={vidas}
                setValue={keyPressInputNumberHandler}
              />
            </div>
          </div>
          <div className="form_option z_index_top">
            <div className="form_option__title">Responsável</div>
            <ThemeProvider theme={theme}>
              <div className="custom_input__select">
                <SelectStandard
                  name={'responsaveis'}
                  options={optionsResponsavel}
                  value={responsavelSelecionado}
                  setValue={selectRespValueHandler}
                />
              </div>
            </ThemeProvider>
          </div>
          {tipo === 'renovacao' ? (
            <>
              <div className="form_option z_index_middle_first">
                <div className="form_option__title">Estipulante:</div>
                <div className="input__select">
                  <select
                    className="select-arrow"
                    value={estipulanteSelecionado}
                    onChange={({ target }) => selectValueChangeHandler(target)}
                  >
                    <option value={0}>Selecione</option>
                    {estipulantes?.map(({ option, value }) => (
                      <option value={value}>{option}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form_option z_index_middle">
                <div className="form_option__title">Nome fantasia:</div>
                <div className="custom_input_nome_fantasia_container">
                  <InputStandard
                    name={'nomeFantasia'}
                    value={nomeFantasia}
                    setValue={keyPressInputNomeFantasiaHandler}
                  />
                </div>
              </div>
              <div className="form_option">
                <div className="form_option__title">Contrato:</div>
                <div className="custom_input__select">
                  <SelectStandard
                    name={'contrato'}
                    options={dataContratos}
                    value={contratoSelecionado}
                    setValue={selectValueChangeContrato}
                  />
                </div>
              </div>
            </>
          ) : (
            <FormNewBusiness
              buscaCnpj={buscaCnpj}
              setBuscaCnpj={setBuscaCnpj}
              cnpj={cnpj}
              setCnpj={setCnpj}
              setEstipulanteSelecionado={setEstipulanteSelecionado}
              cadastrado={cadastrado}
              setCadastrado={setCadastrado}
              estipulantes={estipulantes}
              nomeFantasia={nomeFantasia}
              estipulanteSelecionado={estipulanteSelecionado}
              selectValueChangeHandler={selectValueChangeHandler}
              keyPressInputNomeFantasiaHandler={
                keyPressInputNomeFantasiaHandler
              }
            />
          )}
          <div className="button_container">
            <Button
              isDisabled={isDisabled}
              onClick={isDisabled ? () => {} : createNewNegociosHandler}
            >
              criar negócio
            </Button>
            {loading ? (
              <CircularProgress
                size={25}
                thickness={2}
                className="circular_spinner"
              />
            ) : (
              ''
            )}
            {confirmation && (
              <span className="message_success">
                Novo negócio criado com sucesso
              </span>
            )}
          </div>
        </div>
      </DrawerContent>
    </ClickAwayListener>
  );
};

export default FormCreateBusiness;
