import React, { useEffect } from "react";
import HeaderRh from "apps/broker/components/Header";
import SidemenuRh from "apps/hr/components/Sidebar-RH";
import MainRh from "apps/hr/components/MainContentRh";
import { Wrapper, Content, Consult, UsuariosListWrapper } from "./styles";
import api from "services/api";
import { useSelector } from "react-redux";
import { BiX } from "react-icons/bi";
import { BsPencilSquare, BsPlus } from "react-icons/bs";
import { CircularProgress, SwipeableDrawer } from "@material-ui/core";
import DrawerAdicionarUsuario from "./DrawerAdicionarUsuario";
import DrawerEditarUsuario from "./DrawerEditarUsuario";
import DrawerRemoverUsuario from "./DrawerRemoverUsuario";
import SlideModal from "components/Modal";
import { usePostHog } from "posthog-js/react";

const GestaoUsuarios = () => {
  const posthog = usePostHog();
  const user = useSelector((state) => state.userReducers.user);
  const [openAdicionarUsuario, setOpenAdicionarUsuario] = React.useState(false);
  const [openEditarUsuario, setOpenEditarUsuario] = React.useState(false);
  const [openExcluirUsuario, setOpenExcluirUsuario] = React.useState(false);
  const [usuarios, setUsuarios] = React.useState([]);
  const [selectedUserToEdit, setSelectedUserToEdit] = React.useState({});
  const [selectedUserToRemove, setSelectedUserToRemove] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const contentAdicionarUsuario = () => {
    return <DrawerAdicionarUsuario getData={getData} />;
  };

  const contentEditarUsuario = () => {
    return <DrawerEditarUsuario data={selectedUserToEdit} getData={getData} />;
  };

  const contentExcluirUsuario = () => {
    return (
      <DrawerRemoverUsuario
        data={selectedUserToRemove}
        getData={getData}
        closeModal={handleCloseRemoverUsuarioModal}
      />
    );
  };

  const openDrawerEditarUsuario = (usuario) => {
    setSelectedUserToEdit(usuario);
    setOpenEditarUsuario(true);
  };

  const openDrawerExcluirUsuario = (usuario) => {
    setSelectedUserToRemove(usuario);
    setOpenExcluirUsuario(true);
  };

  const handleCloseRemoverUsuarioModal = () => {
    setOpenExcluirUsuario(false);
    setSelectedUserToRemove({});
  };

  const getData = () => {
    const headers = {
      estipulante: user.mainEstipulante,
      authorization: `Bearer ${user.token}`,
    };

    setLoading(true);
    api
      .get("/rh/usuarios", { headers })
      .then((resp) => {
        // console.log(resp.data)
        setUsuarios(resp.data?.usuarios);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    posthog?.capture('Acessou "/rh/usuarios"');
    getData();
  }, []);

  return (
    <Wrapper>
      <SidemenuRh />
      <Content>
        <HeaderRh />
        <MainRh>
          <Consult>
            <div className="title">
              <h1>Gestão de usuários</h1>
              <span
                className="add-user-button"
                onClick={() => setOpenAdicionarUsuario(true)}
              >
                <BsPlus size={17} />
                adicionar usuário
              </span>
            </div>
          </Consult>
          <UsuariosListWrapper>
            <table>
              <thead>
                <th>Nome</th>
                <th>E-mail</th>
                <th>Tipo de Usuário</th>
                <th></th>
              </thead>
              {loading ? (
                <div className="text-center-movement">
                  <CircularProgress
                    size={100}
                    thickness={2.5}
                    className="circular-progress-loading"
                  />
                  Carregando usuários...
                </div>
              ) : (
                <tbody>
                  {usuarios &&
                    usuarios.map((item) => (
                      <tr>
                        <td>
                          <span>{`${item.nome} ${item.sobrenome}`}</span>
                        </td>
                        <td>
                          <span>{item.email}</span>
                        </td>
                        <td>
                          <span>{item.cargo}</span>
                        </td>
                        <td>
                          <div className="buttons-container">
                            {/* {console.log(item, user)} */}
                            {item.uuid !== user.uuid ? (
                              <div className="icon">
                                <button
                                  className="btn close"
                                  onClick={() => openDrawerExcluirUsuario(item)}
                                >
                                  <BiX />
                                </button>
                              </div>
                            ) : (
                              <div className="icon">
                                <button
                                  className="btn close"
                                  // onClick={() => openDrawerExcluirUsuario(item)}
                                ></button>
                              </div>
                            )}
                            <div className="icon">
                              <button
                                className="btn edit"
                                onClick={() => openDrawerEditarUsuario(item)}
                              >
                                {" "}
                                <BsPencilSquare size={19} />
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              )}
            </table>
          </UsuariosListWrapper>
        </MainRh>
      </Content>
      <SwipeableDrawer
        anchor="right"
        open={openAdicionarUsuario}
        onClose={() => {
          setOpenAdicionarUsuario(false);
          posthog?.capture('Fechou "DrawerAdicionarUsuário"');
        }}
        onOpen={() => {
          setOpenAdicionarUsuario(true);
          posthog?.capture('Abriu "DrawerAdicionarUsuário"');
        }}
      >
        {contentAdicionarUsuario()}
      </SwipeableDrawer>
      <SwipeableDrawer
        anchor="right"
        open={openEditarUsuario}
        onClose={() => {
          setOpenEditarUsuario(false);
          posthog?.capture('Fechou "DrawerEditarUsuário"');
        }}
        onOpen={() => {
          setOpenEditarUsuario(true);
          posthog?.capture('Abriu "DrawerEditarUsuário"');
        }}
      >
        {contentEditarUsuario()}
      </SwipeableDrawer>
      <SlideModal
        anchor="bottom"
        openModal={openExcluirUsuario}
        setOpenModal={handleCloseRemoverUsuarioModal}
      >
        {contentExcluirUsuario()}
      </SlideModal>
    </Wrapper>
  );
};

export default GestaoUsuarios;
