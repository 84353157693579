import styled from 'styled-components';
import colors from 'styles/colors';

export const MainColumn = styled.div`

  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin: 5px;

  border-radius: 15px;
  background-color: ${colors.businessPageGrey};
  box-shadow: 2px ${colors.businessPageGrey};

  padding: 10px 20px;
  height: 590px;
  width: 330px;
  max-width: 330px;

  overflow-y: auto;

  ::-webkit-scrollbar-track {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.businessPageGrey};
    border: ${colors.businessPageGrey};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${colors.businessPageGrey};
    border: ${colors.businessPageGrey};
  }

  overflow-x: hidden;

`;

export const DropCardsContainer = styled.div`
  height: 100%;
  width: 200px;
`;