import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Box from '@mui/material/Box';
import { VscChromeClose, VscCheck } from 'react-icons/vsc';
import { MdEditOff } from 'react-icons/md';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import { Div } from './styles';

const DeleteButtonWithPopUp = ({
  anchorEl,
  selectedCheckboxes,
  editModeClickHandler,
  clickDeleteHandler,
  popupPositiveClickHandler,
  popupNegativeClickHandler,
  handleDisplayMessage,
  handleClose
}) => {
  return (
    <Div>
      <MdEditOff
        className="button-edit"
        size={22}
        onClick={editModeClickHandler}
      />
      <div>
        <RiDeleteBin6Fill
          className="delete-button"
          size={25}
          onClick={clickDeleteHandler}
        />
      </div>
      <Popper
        id={'id'}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom-end"
        transition
        disablePortal
      >
        <Box>
          <ClickAwayListener onClickAway={handleClose}>
            <Paper elevation={3} className="paper-popup-delete-confirmation">
              <span className="popup-message">{handleDisplayMessage()}</span>
              {selectedCheckboxes.length > 0 ? (
                <div className="popup-options-container">
                  <span
                    className="popup-options"
                    onClick={popupPositiveClickHandler}
                  >
                    <VscCheck className="check-icon" size={12} />
                    Sim
                  </span>
                  <span
                    className="popup-options"
                    onClick={popupNegativeClickHandler}
                  >
                    <VscChromeClose className="cross-icon" size={12} />
                    Não
                  </span>
                </div>
              ) : (
                ''
              )}
            </Paper>
          </ClickAwayListener>
        </Box>
      </Popper>
    </Div>
  );
};

export default DeleteButtonWithPopUp;
