export const keysAbaDadosBasicos = [
    'nome',
    'data_nascimento',
    'sexo',
    'cpf',
    'estado_civil',
    'nacionalidade',
    'rg',
    'orgao_emissor_rg',
    'data_expedicao_rg',
    'nome_mae',
    'pis_pasep'
  ];
  
  export const keysAbaEndereco = [
    'cep',
    'endereco',
    'numero_endereco',
    'complemento_endereco',
    'bairro',
    'uf',
    'cidade'
  ];
  
  export const keysAbaDadosContato = [
    'email',
    'telefone_fixo',
    'telefone_celular'
  ];
  
  export const keysAbaDadosVinculo = [
    'cargo',
    'data_admissao'
  ]
  
  export const keysAbaDadosImc = [
    'peso',
    'altura'
  ]
  
  export const keysAbaDadosBancarios = [
    'agencia',
    'conta',
    'digito_conta',
    'banco'
  ]
  
  export const keysAbaScanDocumentos = [
    'documento_scan_rg',
    'scan_comprovante_residencia',
    'scan_carteira_trabalho',
    'scan_carta_inclusao',
    'scan_passaporte',
    'scan_carta_inclusao',
    'scan_carta_permanencia'
  ]
  
  export const keysAbaFichaProposta = [
    'ficha_proposta'
  ]