import { Checkbox, ThemeProvider } from '@mui/material';
import ReactDatePicker from 'react-datepicker';
import { BsArrowLeft } from 'react-icons/bs';
import { IoMdRadioButtonOff, IoMdRadioButtonOn } from 'react-icons/io';
import { RiSendPlaneFill } from 'react-icons/ri';

const FormAddNewPlanInfo = ({
  theme,
  data,
  contratos,
  setContratoOption,
  contratoOption,
  setContratoPlanoOption,
  contratoPlanoOption,
  setAdicionarPlano,
  adicionarPlano,
  setNumeroCarteirinhaNovoPlano,
  numeroCarteirinhaNovoPlano,
  setDataEntradaNovoPlano,
  dataEntradaNovoPlano,
  setAbaEditarBeneficiario,
  enviarPlanoAdicionadoClickHandler
}) => {

  const hasHealthContract = () => {
    let hasContract = false;

    const contracts = contratos?.filter(
      (contract) => contract.produto_id === 1
    );

    const contractsFiltered = contracts?.filter(
      (contract) =>
        !data?.contratos?.some(
          (contractBeneficiary) =>
            contract.contrato_id === contractBeneficiary.contrato_id
        )
    );

    if (contractsFiltered.length) {
      hasContract = false;
    } else {
      hasContract = true;
    }

    return hasContract;
  };

  const hasDentalContract = () => {
    let hasContract = false;

    const contracts = contratos?.filter(
      (contract) => contract.produto_id === 2
    );

    const contractsFiltered = contracts?.filter(
      (contract) =>
        !data?.contratos?.some(
          (contractBeneficiary) =>
            contract.contrato_id === contractBeneficiary.contrato_id
        )
    );

    if (contractsFiltered.length) {
      hasContract = false;
    } else {
      hasContract = true;
    }

    return hasContract;
  };

  const adicionarPlanoClickHandler = ({ target }) => {
    setContratoOption('');
    setContratoPlanoOption('');
    setAdicionarPlano((prevState) => {
      if (prevState === target.value) {
        return undefined;
      }
      return target.value;
    });
  };

  const adicionarPlanoClickHandlerDependente = ({ target }) => {
    setAdicionarPlano((prevState) => {
      if (prevState === target.value) {
        setContratoOption('');
        setContratoPlanoOption('');
        return undefined;
      }

      const contratosFiltered = contratos?.filter(
        (contrato) => contrato.produto_id === Number(target.value)
      );
      
      setContratoOption(contratosFiltered[0].contrato_id);
      setContratoPlanoOption(contratosFiltered[0].tipo_planos);
      return target.value;
    });
  };

  const renderContratoOptions = () => {
    const contracts = contratos?.filter(
      (contract) => contract.produto_id === Number(adicionarPlano)
    );

    const contractsFiltered = contracts?.filter(
      (contract) =>
        !data?.contratos.some(
          (contractBeneficiary) =>
            contract.contrato_id === contractBeneficiary.contrato_id
        )
    );

    return (
      <>
        <option style={{ fontWeight: 'bold' }} disabled value="">
          Selecione
        </option>
        {contractsFiltered.map((item) => {
          return item?.numero_apolice ? (
            <option
              value={item.contrato_id}
            >{`${item.seguradora_nome} (${item.numero_apolice})`}</option>
          ) : (
            <option value={item.contrato_id}>{item.seguradora_nome}</option>
          );
        })}
      </>
    );
  };

  const renderContratoOptionsDependente = () => {
    const contratosFiltered = contratos?.filter(
      (contrato) => contrato.produto_id === Number(adicionarPlano)
    );

    return (
      <>
        <option style={{ fontWeight: 'bold' }} disabled value="">
          Selecione
        </option>
        {contratosFiltered?.map((item) => {
          return item?.numero_apolice ? (
            <option
              value={item.contrato_id}
            >{`${item.seguradora_nome} (${item.numero_apolice})`}</option>
          ) : (
            <option value={item.contrato_id}>{item.seguradora_nome}</option>
          );
        })}
      </>
    );
  };

  const contratoOptionClickHandler = ({ target }) => {
    setContratoOption(target.value);
    const planosFiltrados = contratos?.find(
      (item) => item.contrato_id === Number(target.value)
    );

    setContratoPlanoOption(planosFiltrados?.tipo_planos[0]?.tipo);
  };

  const contratoOptionClickHandlerDependente = ({ target }) => {
    setContratoOption(target.value);
    const planoFiltrado = contratos?.find(
      (item) => item.contrato_id === Number(target.value)
    );

    setContratoPlanoOption(planoFiltrado?.tipo_planos);
  };

  const renderContratoPlanosOptions = () => {
    const planosFiltrados = contratos?.find(
      (item) => item.contrato_id === Number(contratoOption)
    );

    return (
      <>
        <option style={{ fontWeight: 'bold' }} disabled value="">
          Selecione
        </option>
        {planosFiltrados?.tipo_planos?.map((item) => (
          <option value={item.tipo}>{item.tipo}</option>
        ))}
      </>
    );
  };

  const renderContratoPlanosOptionsDependente = () => {
    const planoFiltrado = contratos?.find(
      (item) => item.contrato_id === Number(contratoOption)
    );

    return (
      <>
        <option style={{ fontWeight: 'bold' }} disabled value="">
          Selecione
        </option>
        {planoFiltrado ? (
          planoFiltrado?.tipo_planos?.map((item) => (
            <option value={item.tipo}>{item.tipo}</option>
          ))
        ) : (
          ''
        )}
      </>
    );
  };

  return (
    <>
      {data.tipo_beneficiario === 'Titular' ? (
        <section className="aba-adicionar-plano">
          <div className="first-row-container">
            <div className="aba-adicionar-plano-title">
              <span>Adicionar plano</span>
            </div>
            <div
              className="voltar-button"
              onClick={() => setAbaEditarBeneficiario('dados gerais')}
            >
              <BsArrowLeft className="back-arrow" size={22} />
            </div>
          </div>
          <div className="planos-options">
            <div className="checkbox-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  value={'1'}
                  checked={adicionarPlano === '1'}
                  onClick={adicionarPlanoClickHandler}
                  disabled={hasHealthContract()}
                  icon={<IoMdRadioButtonOff size={20} />}
                  checkedIcon={<IoMdRadioButtonOn size={20} />}
                  color={'darkGenoaBlue'}
                />
                <label className={hasHealthContract() ? 'disabled' : ''}>
                  Plano de Saúde
                </label>
              </ThemeProvider>
            </div>
            <div className="checkbox-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  value={'2'}
                  checked={adicionarPlano === '2'}
                  onClick={adicionarPlanoClickHandler}
                  disabled={hasDentalContract()}
                  icon={<IoMdRadioButtonOff size={20} />}
                  checkedIcon={<IoMdRadioButtonOn size={20} />}
                  color={'darkGenoaBlue'}
                />
                <label className={hasDentalContract() ? 'disabled' : ''}>
                  Plano odontológico
                </label>
              </ThemeProvider>
            </div>
          </div>
          <div className="contrato-options-container">
            <div className="contratos-options-title">
              <span>Contratos</span>
            </div>
            <select
              value={contratoOption}
              disabled={adicionarPlano ? false : true}
              onChange={contratoOptionClickHandler}
            >
              {renderContratoOptions()}
            </select>
          </div>
          <div className="contrato-options-container">
            <div className="contratos-options-title">
              <span>Planos</span>
            </div>
            <select
              value={contratoPlanoOption}
              disabled={contratoOption ? false : true}
              onChange={(e) => setContratoPlanoOption(e.target.value)}
            >
              {renderContratoPlanosOptions()}
            </select>
          </div>
          <div className="plano-info-row">
            <div className="column">
              <label>Número de carteirinha</label>
              <input
                type={'text'}
                value={numeroCarteirinhaNovoPlano}
                onChange={(e) => setNumeroCarteirinhaNovoPlano(e.target.value)}
              />
            </div>
            <div className="column">
              <label>Data de entrada</label>
              <ReactDatePicker
                className="input-data"
                selected={dataEntradaNovoPlano}
                onChange={(date) => setDataEntradaNovoPlano(date)}
                dateFormat="dd/MM/yyyy"
                locale="ptBR"
              />
            </div>
          </div>
          <div className="enviar-button-container">
            <span onClick={enviarPlanoAdicionadoClickHandler}>
              Enviar
              <RiSendPlaneFill className="send-icon" size={12} />
            </span>
          </div>
        </section>
      ) : (
        <section className="aba-adicionar-plano">
          <div className="first-row-container">
            <div className="aba-adicionar-plano-title">
              <span>Adicionar plano</span>
            </div>
            <div
              className="voltar-button"
              onClick={() => setAbaEditarBeneficiario('dados gerais')}
            >
              <BsArrowLeft className="back-arrow" size={22} />
            </div>
          </div>
          <div className="planos-options">
            <div className="checkbox-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  value={'1'}
                  checked={adicionarPlano === '1'}
                  onClick={adicionarPlanoClickHandlerDependente}
                  disabled={hasHealthContract()}
                  icon={<IoMdRadioButtonOff size={20} />}
                  checkedIcon={<IoMdRadioButtonOn size={20} />}
                  color={'darkGenoaBlue'}
                />
                <label className={hasHealthContract() ? 'disabled' : ''}>
                  Plano de Saúde
                </label>
              </ThemeProvider>
            </div>
            <div className="checkbox-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  value={'2'}
                  checked={adicionarPlano === '2'}
                  onClick={adicionarPlanoClickHandlerDependente}
                  disabled={hasDentalContract()}
                  icon={<IoMdRadioButtonOff size={20} />}
                  checkedIcon={<IoMdRadioButtonOn size={20} />}
                  color={'darkGenoaBlue'}
                />
                <label className={hasDentalContract() ? 'disabled' : ''}>
                  Plano odontológico
                </label>
              </ThemeProvider>
            </div>
          </div>
          <div className="contrato-options-container">
            <div className="contratos-options-title">
              <span>Contratos</span>
            </div>
            <select
              value={contratoOption}
              disabled={adicionarPlano ? false : true}
              onChange={contratoOptionClickHandlerDependente}
            >
              {renderContratoOptionsDependente()}
            </select>
          </div>
          <div className="contrato-options-container">
            <div className="contratos-options-title">
              <span>Planos</span>
            </div>
            <select
              value={contratoPlanoOption}
              disabled={contratoOption ? false : true}
              onChange={(e) => setContratoPlanoOption(e.target.value)}
            >
              {renderContratoPlanosOptionsDependente()}
            </select>
          </div>
          <div className="plano-info-row">
            <div className="column">
              <label>Número de carteirinha</label>
              <input
                type={'text'}
                value={numeroCarteirinhaNovoPlano}
                onChange={(e) => setNumeroCarteirinhaNovoPlano(e.target.value)}
              />
            </div>
            <div className="column">
              <label>Data de entrada</label>
              <ReactDatePicker
                className="input-data"
                selected={dataEntradaNovoPlano}
                onChange={(date) => setDataEntradaNovoPlano(date)}
                dateFormat="dd/MM/yyyy"
                locale="ptBR"
              />
            </div>
          </div>
          <div className="enviar-button-container">
            <span onClick={enviarPlanoAdicionadoClickHandler}>
              Enviar
              <RiSendPlaneFill className="send-icon" size={12} />
            </span>
          </div>
        </section>
      )}
    </>
  );
};

export default FormAddNewPlanInfo;
