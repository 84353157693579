import { ClickAwayListener } from '@mui/material';
import moment from 'moment';
import './style.scss';

const ModalLogs = ({ setShowLogs, logs }) => {
  return (
    <ClickAwayListener onClickAway={() => setShowLogs(false)}>
      <div className="modal-logs">
        <div className="modal-log__top-stripe"></div>
        {logs.map((log, index) => (
          <div key={index} className="modal-logs__item">
            <span className="modal-logs__name">{log.nome}</span>

            <span className="modal-logs__date">
              {moment(log.created_at).format('DD/MM/YYYY HH:mm:ss')}
            </span>
          </div>
        ))}
      </div>
    </ClickAwayListener>
  );
};

export default ModalLogs;
