import styled from 'styled-components';
import colors from 'apps/broker/styles/colors';

export const Div = styled.div`
  padding-bottom: 10px;

  .selecao-titular {
    font-family: 'Avenir Next GEO W05 Regular';
    font-size: 1rem;
    padding: 5px 10px;
    border: 0.5px solid ${colors.genoaBlue};
    border-radius: 5px;

    .selecao-titular-hero-title {
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      color: #b8b9bb;
      text-align: center;
      display: grid;
      grid-template-columns: 570px 30px;
      align-items: center;

      .arrow-icon {
        color: #4c4c4c;
      }

      .titular-dependente-nome {
        color: ${colors.genoaGrey};
      }
      :hover {
        cursor: pointer;
      }
    }

    .titular-filtro-row {
      max-height: 47.4px;
      display: flex;
      justify-content: center;
    }

    .titular-tabela-row {
      padding-top: 10px;
      max-height: 540px;
      height: 235px;
    }

    .titular-tabela {
      width: 100%;
      background-color: #white;
      height: 235px;
      border-radius: 10px;
      max-height: 235px;
      overflow-y: scroll;

      .table-header-row {
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        height: 25px;
        align-items: center;
        background-color: ${colors.genoaBlue};
        color: white;
        display: grid;
        grid-template-columns: 1.5fr 1fr;
      }

      .titular-table-body {
        max-height: 210px;
        overflow-y: scroll;
        display: block;

        tr: nth-child(2n) {
          background-color: #89a9cc1c;
        }

        td: first-child {
          width: max-content;
          user-select: none;
          -moz-user-select: none;
          -webkit-user-select: none;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;

          :hover {
            cursor: pointer;
            font-weight: bold;
          }
        }

        ::-webkit-scrollbar {
          width: 6px;
          background: transparent;
        }
        ::-webkit-scrollbar-thumb {
          background: ${colors.genoaBlue};
          box-shadow: none;
          border-radius: 14px;
        }
        ::-webkit-scrollbar-track {
          box-shadow: inset 0 0 2px grey;
          border-radius: 14px;
        }
      }

      .table-data-row {
        display: grid;
        grid-template-columns: 1.5fr 1fr;
      }

      th: first-child {
        text-align: left;
        padding-left: 30px;
      }
      th: last-child {
        text-align: center;
      }

      td: first-child {
        text-align: left;
        padding-left: 30px;
      }
      td: last-child {
        text-align: center;
        padding-left: 8px;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
      }
    }

    .input-titular-filtro {
      margin-top: 15px;
      width: 60%;
      text-align: center;
      font-family: 'Avenir Next GEO W05 Regular';
      font-size: 1rem;
      padding: 5px 10px;
      border: 0.5px solid ${colors.genoaBlue};
      border-radius: 5px;
      ::placeholder {
        color: #b8b9bb;
        text-align: center;
      }
    }
  }

  .tabela-titulares-container-open {
    height: 300px;
    overflow: hidden;
    transition: 0.7s height ease-in-out;
  }

  .tabela-titulares-container {
    height: 0px;
    overflow: hidden;
    transition: 0.7s height ease-in-out;
  }
`;
