import styled from 'styled-components';
import colors from 'styles/colors';

export const Wraper = styled.div`

    /* background: rgb(248,192,0);
    background: -moz-linear-gradient(180deg, rgba(248,192,0,1) 0%, rgba(255,178,29,1) 88%);
    background: -webkit-linear-gradient(180deg, rgba(248,192,0,1) 0%, rgba(255,178,29,1) 88%);
    background: linear-gradient(180deg, rgba(248,192,0,1) 0%, rgba(255,178,29,1) 88%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f8c000",endColorstr="#ffb21d",GradientType=1); */
    background-color: #f5f5f5;
    height: 100vh;
  
    main {
      margin-top: 5rem;

      @media (max-height: 700px) {
        margin-top: 4rem;
      }

      @media (max-height: 600px) { 
        margin-top: 3.0rem;
      }

      @media (max-height: 500px) {
        margin-top: 1.5rem;
      }
    }

    .title{
      margin-top: 1.5rem;
      text-align:center;
      font-family: 'Avenir Next GEO W05 Bold';
      color: #3B3838;
    }

    .top-area{

      .nav-logo {
        width: 100px;
        height: 102px;
        padding: 2rem 3rem 0.5rem 3rem;

        :hover {
          cursor: pointer;
        }

        @media (max-height: 700px) {
          width: 90px;
          height: 91px;
          padding: 1.8rem 2.7rem 0.45rem 2.7rem;
        }

        @media (max-height: 600px) { 
          width: 80px;
          height: 80.5px;
          padding: 1.6rem 2.4rem 0.4rem 2.4rem;
        }

        @media (max-height: 500px) {
          width: 70px;
          height: 70.5px;
          padding: 1.334rem 2rem 0.335rem 2rem;
        }
      }
    }

    .form{
      padding: 2rem;


      .form-controll{
        margin-bottom: 2rem;

        .MuiFormLabel-root.Mui-focused {
          color: ${colors.genoaBlue};
        }

        .MuiInput-underline:after {
          border-bottom-color: ${colors.genoaBlue};
        }

        .MuiInput-underline:hover:not(.Mui-disabled):before {
          border-bottom-color: ${colors.genoaDarkBlue};
        }
      }

      .erro-msg{
        color: #f80039;
        font-size: 1rem;
        font-family: 'Avenir Next GEO W05 Demi';
        margin-bottom: 1rem;
      }
      
      button {
        font-family: 'Avenir Next GEO W05 Bold';
        border: none;
        padding-top: 10px;
        background: transparent;
        font-weight: bold;
        color: ${colors.genoaBlue};
        font-size: 1.2rem;
        display: inline-flex;
        align-items: center;
        cursor: pointer;

        &:focus{
          outline:none
        }

        .icon{
          display: inherit;
          margin-left: 5px;
          margin-top: 3px;
        }
      }

    }

    .flex-center {
        display: flex;
        justify-content: center;
        
    }

    .mb-1{
      margin-bottom:1rem;
    }

    .form-link{
      display:flex;
      flex-direction: column;
      align-items: center;

      div{
        margin-bottom:1rem;
      }  

      a{
        font-size: 1.2rem;
        font-family: 'Avenir Next GEO W05 Bold';
        color: #3B3838;
      }

    }

    @media (max-width: 576px) {
      main {
         margin-top: 3rem;
       }

    .title{
        font-size: .8rem
     
      }

    }

    .PrivateTabIndicator-colorPrimary-5 {
      background-color: #62A0E2;
    }

    .resp-success {
      text-align: center;
      font-size: 1.2rem;
      font-weight: 600;
      color: ${colors.luminousGreen};
    }
`

