import styled from 'styled-components';
import colors from 'styles/colors';


export const FilterNegociosWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.8fr 0.8fr 0.8fr 0.8fr;
  column-gap: 40px;
  
  padding-left: 10px;
  padding-right: 30px;
  
  
  .filter_type__container {
    display: flex;
    flex-direction: column;
    row-gap: 15px;

    .filter_type__title {
      font-family: Avenir Next Geo W05 Demi;
      font-size: 0.8em;
    }

    .checkmark_container {
      width: 100%;
      height: 50px;
    }

    .filter_type__options {
      display: flex;
      font-family: "Avenir Next GEO W05 Regular";
      column-gap: 30px;
      font-size: 0.8em;

      .MuiRadio-root {
        padding: 0;
      }

      .radio_container {
        display: flex;
        column-gap: 5px;
        align-items: center;
      }

      .MuiSvgIcon-root {
        font-size: 1em;
      }
    }
  }

  select {
    font-family: 'Avenir Next GEO W05 Regular';
    appearance: none;
    border-radius: 10px;
    border: none;
    padding: 2px;
    padding-left: 8px;
    width: 140px;

    :focus-visible {
      outline: 1px ${colors.genoaBlue}f5 auto;
    }
  }
  
  .select-arrow {
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 9L0.73686 0.75L10.2631 0.75L5.5 9Z' fill='%23456AED'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    
    background-position-x: 96%;
    background-position-y: center;
    background-size: 8px;
  }

  .btn {
    font-family: 'Avenir Next GEO W05 Demi';
    border: none;
    cursor: pointer;
    background: none;
    transition: 0.1s ease-in;
  }
`