import styled from 'styled-components';
import colors from 'styles/colors';

export const AbaPlanosAdicionaisWrapper = styled.div`

  @media (max-height: 580px) {
    display: grid;
    grid-template-rows: 17.342px 1fr 26.68px;
    row-gap: 30px;
  
    .content-title {
      span {
        font-size: 12px;
        color: ${colors.rhBlue};
        font-family: Avenir Next GEO W05 Heavy;
      }
    }
  
    .aba-container {
      display: grid;
      grid-template-rows: 17.342px 1fr;
      row-gap: 40px;
  
      .aba-title-container {
        span {
          font-size: 12px;
          font-family: Avenir Next GEO W05 Bold;
          color: ${colors.genoaDarkBlue};
        }
      }
  
      .aba-input-container {
        width: 100%;
        height: 100%;
  
        display: flex;
        flex-direction: column;
        row-gap: 16.675px;
        
        .title-container {
          display: flex;
          column-gap: 6.67px;
          align-items: flex-start;
  
          .MuiCheckbox-root {
            color: #BABABA;
            margin-top: 0.67px;
          }
  
          .Mui-checked {
            color: ${colors.rhBlue};
          }
  
          .title {
            font-family: Avenir Next GEO W05 Demi;
            color: ${colors.genoaGrey};
            font-size: .667em;
          }
          
        }
  
        .aba-contrato-planos {
          display: grid;
          width: 70%;
          padding-left: 6.67px;
          grid-template-columns: 200px 200px;
          column-gap: 33.35px;
        }
  
      }
  
      .mensagens-erro-container {
        width: 90%;
        line-height: 17.342px;
        font-size: .667em;
      }
    }
  }

  @media (min-height: 580px) and (max-height: 650px) {
    display: grid;
    grid-template-rows: 20.8px 1fr 32px;
    row-gap: 36px;
  
    .content-title {
      span {
        font-size: 14.4px;
        color: ${colors.rhBlue};
        font-family: Avenir Next GEO W05 Heavy;
      }
    }
  
    .aba-container {
      display: grid;
      grid-template-rows: 20.8px 1fr;
      row-gap: 48px;
  
      .aba-title-container {
        span {
          font-size: 14.4px;
          font-family: Avenir Next GEO W05 Bold;
          color: ${colors.genoaDarkBlue};
        }
      }
  
      .aba-input-container {
        width: 100%;
        height: 100%;
  
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        
        .title-container {
          display: flex;
          column-gap: 8px;
          align-items: flex-start;
  
          .MuiCheckbox-root {
            color: #BABABA;
            margin-top: .8px;
          }
  
          .Mui-checked {
            color: ${colors.rhBlue};
          }
  
          .title {
            font-family: Avenir Next GEO W05 Demi;
            color: ${colors.genoaGrey};
            font-size: .8em;
          }
          
        }
  
        .aba-contrato-planos {
          display: grid;
          width: 70%;
          padding-left: 8px;
          grid-template-columns: 240px 240px;
          column-gap: 40px;
        }
  
      }
  
      .mensagens-erro-container {
        width: 90%;
        line-height: 20.8px;
        font-size: .8em;
      }
    }
  }

  @media (min-height: 650px) and (max-height: 720px) {
    display: grid;
    grid-template-rows: 23.4px 1fr 36px;
    row-gap: 40.5px;
  
    .content-title {
      span {
        font-size: 16.2px;
        color: ${colors.rhBlue};
        font-family: Avenir Next GEO W05 Heavy;
      }
    }
  
    .aba-container {
      display: grid;
      grid-template-rows: 23.4px 1fr;
      row-gap: 54px;
  
      .aba-title-container {
        span {
          font-size: 16.2px;
          font-family: Avenir Next GEO W05 Bold;
          color: ${colors.genoaDarkBlue};
        }
      }
  
      .aba-input-container {
        width: 100%;
        height: 100%;
  
        display: flex;
        flex-direction: column;
        row-gap: 22.5px;
        
        .title-container {
          display: flex;
          column-gap: 9px;
          align-items: flex-start;
  
          .MuiCheckbox-root {
            color: #BABABA;
            margin-top: .9px;
          }
  
          .Mui-checked {
            color: ${colors.rhBlue};
          }
  
          .title {
            font-family: Avenir Next GEO W05 Demi;
            color: ${colors.genoaGrey};
            font-size: .9em;
          }
          
        }
  
        .aba-contrato-planos {
          display: grid;
          width: 70%;
          padding-left: 9px;
          grid-template-columns: 270px 270px;
          column-gap: 45px;
        }
  
      }
  
      .mensagens-erro-container {
        width: 90%;
        line-height: 23.4px;
        font-size: .9em;
      }
    }
  }

  @media (min-height: 720px) {
    display: grid;
    grid-template-rows: 26px 1fr 40px;
    row-gap: 45px;
  
    .content-title {
      span {
        font-size: 18px;
        color: ${colors.rhBlue};
        font-family: Avenir Next GEO W05 Heavy;
      }
    }
  
    .aba-container {
      display: grid;
      grid-template-rows: 26px 1fr;
      row-gap: 60px;
  
      .aba-title-container {
        span {
          font-size: 18px;
          font-family: Avenir Next GEO W05 Bold;
          color: ${colors.genoaDarkBlue};
        }
      }
  
      .aba-input-container {
        width: 100%;
        height: 100%;
  
        display: flex;
        flex-direction: column;
        row-gap: 25px;
        
        .title-container {
          display: flex;
          column-gap: 10px;
          align-items: flex-start;
  
          .MuiCheckbox-root {
            color: #BABABA;
            margin-top: 1px;
          }
  
          .Mui-checked {
            color: ${colors.rhBlue};
          }
  
          .title {
            font-family: Avenir Next GEO W05 Demi;
            color: ${colors.genoaGrey};
            font-size: 1em;
          }
          
        }
  
        .aba-contrato-planos {
          display: grid;
          width: 70%;
          padding-left: 10px;
          grid-template-columns: 300px 300px;
          column-gap: 50px;
        }
  
      }
  
      .mensagens-erro-container {
        width: 90%;
        line-height: 26px;
        font-size: 1em;
      }
    }
  }
`