import styled from "styled-components";
import colors from "styles/colors";

export const FilterContainer = styled.div`
  position: absolute;
  background-color: white;
  align-self: right;
  z-index: 1000;

  width: calc(200px - 40px);
  height: 500px;
  box-shadow:
    -3px 0 6px 0px rgba(0,0,0,0.23),
    0 2px 6px 0px rgba(0,0,0,0.23);
  padding: 15px 20px;
  
  .filter_header {
    display: flex;
    height: 20px;
    justify-content: space-between;
    align-items: center;

    .filter_header__title {
      font-size: 0.8em;
      color: ${colors.lightGrey};
      font-family: "Avenir Next GEO W05 Demi";
    }

    .filter_header__close_btn {
      color: ${colors.genoaPink};

      :hover {
        cursor: pointer;
      }
    }
  }

  .filter_content {
    padding-top: 25px;

    .filter_content__title {
      font-size: 0.8em;
      color: ${colors.genoaGrey};
      font-family: "Avenir Next GEO W05 Demi";
    }

    .date_picker__container {
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      
    }

    .date_picker__span {
      font-family: "Avenir Next GEO W05 Demi";
      font-size: 0.8em;
    }

    .react-datepicker__input-container {
      margin-top: 5px;
      input {
        border-radius: 5px;
        font-size: 0.9em;
        border-color: ${colors.genoaGrey};
        padding: 5px 10px;
        width: 100%;
        text-align: center;

        :focus {
          border-color: ${colors.genoaBlue};
        }
      }
    }

    .date-selected {
      border-color: ${colors.genoaBlue};
    }

    .react-datepicker-popper {
      translate: -40px;
    }

    .react-datepicker__day--selected:hover {
      color: white;
      background-color: ${colors.genoaBlueHover};
      font-weight: bold;
    }

    .react-datepicker__day--selected {
      color: white;
      background-color: ${colors.genoaBlue};
      font-weight: bold;
    }

    .react-datepicker__day--keyboard-selected:hover {
      color: ${colors.genoaBlue};
      background-color: #f0f0f0;
    }

    .react-datepicker__day--keyboard-selected {
      color: ${colors.genoaBlue};
      background-color: white;
      font-weight: bold;
    }

  }

  .date_picker__btn_container {
    padding-top: 50px;
  }

  .date_picker__btn {
    background-color: ${colors.genoaBlue};
    color: white;
    width: 100%;
    font-size: 0.8em;
    padding: 0;
    padding: 5px 0px;

    :hover {
      background-color: ${colors.genoaBlueHover};
    }
  }

  .date_picker__btn_disabled {
    background-color: ${colors.genoaDisabledGrey};
    width: 100%;
    font-size: 0.8em;
    padding: 0;
    padding: 5px 0px;
  }
`