import styled from 'styled-components';
import media from 'styled-media-query'
import colors from 'apps/broker/styles/colors';

export const Wrapper = styled.div`
  display: flex;

  .animeLeft {
    opacity: 0;
    transform: translateX(-20px);
    animation: animeLeft 0.3s forwards;
  }
  .input-data {
    width: 145px;
    border: none;
    border-radius: 20px;
    color: ${colors.genoaGrey};
    font-family: 'Avenir Next GEO W05 Regular';
    font-size: 1rem;
  }

  .react-datepicker-popper{
    z-index:3;
  }

  .rangedate {
    background: #fff;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #ccc;
  }

  @keyframes animeLeft {
    to {
      opacity: 1;
      transform: initial;
    }
  }

`

export const Content = styled.div`
    width: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    flex: 1;
    //color: #3B3838;
    background-color: ${colors.genoaLightBackgroundGrey};
    
    #sentinela {
      width: 100%;
      height: 200px;
      margin-bottom: 10px;
      background: ${colors.genoaBackgroundGrey};
    }
    
    #sentinela-loading {
      width: 100%;
      height: 200px;
      margin-bottom: 10px;
      background: transparent;
    }

    .circular-spinner-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 25vh;

      .circular-spinner{
        color: ${colors.rhBlue};
        margin-bottom: 20px;
      }

      h3 {
        color: ${colors.genoaGrey};
        margin-top: 10px;
      }
    }

    .main-painel {
      // max-width: 1200px;
      margin: auto;
      //padding: 20px 100px;

      ${media.greaterThan('1280px')`
        // max-width: 1400px;
      `}

      .title{
        margin-top: 1px;
        margin-bottom: 3.7rem;
        h1{
          font-family: "Avenir Next GEO W05 Heavy";
          font-size: 1.2rem;
          color: #3B3838;
        }
      }
    }
`
export const Consult = styled.section`
    display: grid;
    grid-template-columns: 350px 3fr 1fr;
    column-gap: 50px;
    align-items: center;
    margin-bottom: 1rem;

    .search{
      height: 75px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      label {
        font-family: "Avenir Next GEO W05 Demi";
        color: ${colors.genoaGrey};
      }

      input{
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-family: "Avenir Next GEO W05 Regular";
        padding: 10px;
        padding-left: 20px;
        padding-right: 35px;
        // background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 9L0.73686 0.75L10.2631 0.75L5.5 9Z' fill='%23456AED'/%3E%3C/svg%3E%0A");
        // background-repeat: no-repeat;
        // background-position-x: 96%;
        // background-position-y: 12px;
        width: 350px;
        border-radius: 20px;
        border: none;
        font-size: 0.7rem;
        margin-top: 12px;
        :focus-visible {
          outline: 1px ${colors.rhBlue}f5 auto;
        }

        ::placeholder {
          font-family: "Avenir Next GEO W05 Regular";
          color: ${colors.genoaGrey};
        }
      }
    }

    .searchFilter {
      margin-bottom: 7px;
      margin-right: 15px;
      display: flex;
      align-items: center;
      background: transparent;
      color: ${colors.rhBlue};

      padding: 0.5rem;
      border-radius: 100%;
      border: 1px solid ${colors.rhBlue};

      font-size: 1.5rem;
      cursor: pointer;

      transition: all 0.2s;

      &:hover {
        background: ${colors.rhBlue};
        color: ${colors.white};
      }
    }
    .filters{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 75px;
      align-items: flex-end;
      

      .options{
        display: inherit;
        justify-content: flex-start;
        
        .text-icon{
          color: ${colors.genoaGrey}
          display: flex;
          align-items: center;
          cursor: pointer;
          svg{
            margin-left: 5px;
            font-size: 1.2rem;
          }
        }
        
      }
    }
`
export const ContentList = styled.section`
    display: flex;
    align-items: center;

    .headerList{
      box-sizing: border-box;
      width: 100%;
      padding: 10px 15px;
      display: grid;
      grid-template-columns: 1fr 1.1fr 1fr 280px 1fr 1fr 1fr 1fr;
      font-family: 'Avenir Next GEO W05 Demi';
    }

    .filters{
      display: flex;
      justify-content: flex-end;

      

      .options{
        display: inherit;
        justify-content: flex-start;
        

        .text-icon{
          display: flex;
          align-items: center;
          cursor: pointer;
          svg{
            margin-left: 5px;
            font-size: 1.2rem;
          }
          
        }
      }

      .MuiSelect-select.MuiSelect-select {
          font-family: 'Avenir Next GEO W05 Regular';
          padding-top: 2px;
          padding-bottom: 1px;
      }
    }
  

`

export const FilterList = styled.section`
  .filters{
    margin-top: 25px;
    .options{
      display: grid;
      grid-template-columns: 1000px 1fr;
      justify-content: space-between;
      padding-bottom: 40px;
      align-items: center;
      
      >div{
        text-align: center;
      }

      .data-order-container{
        justify-self: end;
        padding-right: 15px;  
      }

      .text-icon{
        display: flex;
        align-items: center;
        cursor: pointer;
        color: ${colors.genoaGrey};
        svg{
          margin-left: 5px;
          font-size: 1.2rem;
        }
      }
    }

    .filters-options-container {
      display: grid;
      grid-template-columns: 166px 194px 250px 102px 250px;
      column-gap: 20px;
    }

    .MuiSelect-select.MuiSelect-select {
        font-family: 'Avenir Next GEO W05 Regular';
        padding-top: 2px;
        padding-bottom: 1px;
    }
    .estipulante-select-filter > .MuiSelect-select {
      max-width: 300px;
    }
  }

  .date-filter-container {
    display: flex;
    align-items: flex-end;
    height: 75px;
  }

  .date-filter {
    padding-bottom: 2px;

    label {
      font-family: "Avenir Next GEO W05 Demi";
      color: ${colors.genoaGrey};
    }

    .MuiFormGroup-root {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
    }
    .MuiFormControl-root {
      border: 0;
      margin: 0;
      display: inline-flex;
      padding: 0;
      position: relative;
      min-width: 0;
      flex-direction: row;
      align-items: flex-end;
    }
    .data-checkbox-container {
      display: flex;
      justify-content: space-evenly;
      flex-direction: row;
      span {
        font-size: 0.7rem;
        height: 1rem;
        display: flex;
        justify-content: center;
      }

      .checkbox-data-group-container {
        display: flex;
        flex-direction: row;
        min-width: 510px;
      }

      .radio-checkbox {
        color: ${colors.lightGrey};
        &.Mui-checked {
          color: ${colors.genoaDarkBlue};
        }
      }
    }

    .datepicker-box-space{
      height: 51px;
      margin-left: 10px;
    }

    .datepicker-group-container {
      margin-top: 3px;
      overflow: hidden;
      transition: all .6s cubic-bezier(0.4, 0, 0.2, 1);
      display: grid;
      grid-template-columns: 125px 125px;
    }

    .datepicker-group-container-hidden {
      margin-top: 3px;
      width: 0px;
      overflow: hidden;
      transition: all .6s cubic-bezier(0.4, 0, 0.2, 1);
      display: grid;
      grid-template-columns: 125px 125px;
    }

    .input-data {
      width: 100px;
      height: 25px;
      border: 2px solid ${colors.lightGrey};
      display: inline-block;
      text-align: center;
      font-size: 0.75rem;
      font-family: 'Avenir Next GEO W05 Regular';
      border-radius: 7px;

      :focus-visible {
        outline: 1px ${colors.rhBlue} auto;
      }
    }

    .react-datepicker-wrapper {
      display: inline-block;
      padding: 0;
      border: 0;
      width: min-content;
      height: 25px;
      
    }

    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
      background-color: white;
      color: ${colors.rhBlue};
      font-weight: bold;
    }


    .react-datepicker__day--selected, .react-datepicker__month-text--selected,  .react-datepicker__quarter-text--selected, .react-datepicker__year-text--selected {
      border-radius: 0.3rem;
      background-color: ${colors.rhBlue};
      color: #fff;
    }

    .datepicker-selector-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      font-size: 0.8rem;

      .span-date-container {
        padding-bottom: 2px;
        font-family: "Avenir Next GEO W05 bold";
        color: ${colors.genoaGrey};
      }
    }

  }
}
`

export const ItemList = styled.div`
  display: flex;
  background: #fff;
  border: 3px ${colors.rhBlue} solid;
  border-radius: 30px;
  padding: 25px 30px;
  box-sizing: border-box;
  margin-bottom: 20px;
  height: 160px;
  justify-content: space-between;
  
  .select-estipulante-container-visible{

    select {
      font-family: "Avenir Next GEO W05 Regular";
      background-color: ${colors.white};
      border: 1.5px solid ${colors.rhBlue};
      border-radius: 10px;
      opacity: 1;
      font-size: 0.8rem;
      color: #3B3838;
      text-align: center;
      width: 100%;
      height: 30px;

      :focus-visible {
        outline: 1px ${colors.rhBlue}f5 auto;
      }
    }
  }

  .edit-estipulante-ticket {
    color: ${colors.genoaDarkBlue};
    margin-left: 5px;
    :hover {
      cursor: pointer;
    }
  }
  .second-line {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      position: absolute;
      top: -11px;
      z-index: 2;
      left: 16px;
      color: ${colors.rhBlue};
      font-size: 14px;
      background: #fff;
      letter-spacing: 0.4px;
    }
  }

  .group-lb {
    position: relative;
    margin-left: 16px;
    font-size: 0.9rem;

    span {
      position: relative;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
    }

    // span::before {
    //   content: '';
    //   position: absolute;
    //   top: 6px;
    //   right: -10px;
    //   width: 4px;
    //   height: 4px;
    //   border-left: 1px solid ${colors.rhBlue};
    //   border-top: 1px solid ${colors.rhBlue};
    //   z-index: 25;
    //   transform: rotateZ(225deg);
    // }

    p {
      color: ${colors.rhBlue};
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-family: "Avenir Next GEO W05 Bold";
    }

    &:hover>.dropMn {
      display: block;
    }

    .dropMn {
      z-index: 8;
      display: none;
      width: 160px;
      position: absolute;
      top: 21px;
      background: #fff;
      padding: 8px 0;
      border-radius: 6px;
      border: 1px solid #ccc;
    }

    li {
      list-style: none;
      cursor: pointer;
    }
    
    a {
      position: relative;
      color: ${colors.genoaGrey};
      display: block;
      width: auto;
      margin: 2px 0;
      padding: 8px 10px;
    }

    a:hover {
      background: #f1f3f9;
    }
  }

  .searchSeguradora {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 0px;
    margin-left: 40px;
    margin-top: -40px;

    .autocomplete-seguradora {
      height: 0px;

    }

    .MuiAutocomplete-listbox {
      li {
        // color: red;
        font-size: 14px;
        min-height: 33px;
      }
    }

    label {
      position: absolute;
      top: -11px;
      left: 18px;
      color: ${colors.rhBlue};
      font-size: 14px;
      background: #fff;
      letter-spacing: 0.4px;
    }

    input {
      border: 1px #ccc solid;
      border-radius: 20px;
      padding: 8px 0 8px 10px;
      text-align: left;
      min-width: 270px;
      font-size: 12px;
    }
  }

  .searchCorretor {
    position: relative;
    display: flex;
    align-items: center;
    column-gap: 15px;
    justify-content: center;

    label {
      position: absolute;
      top: -10px;
      left: 18px;
      color: ${colors.rhBlue};
      font-size: 14px;
      background: #fff;
      letter-spacing: 0.4px;
    }

    input {
      border: none;
      // border-radius: 20px;
      text-align: left;
      color: ${colors.genoaGrey};
      font-family: 'Avenir Next GEO W05 Regular';
      font-size: 1rem;
      min-width: 270px;

      ::placeholder {
        color: ${colors.genoaGrey};
        font-family: 'Avenir Next GEO W05 Regular';
      }
    }
  }

  .atuacao-data-container {
    display: flex;
    column-gap: 15px;
    align-items: center;
    height: 23px;

    .react-datepicker-wrapper {
      display: inline-block;
      padding: 0;
      border: 0;
      width: 150px;
    }

    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
      background-color: white;
      color: ${colors.rhBlue};
      font-weight: bold;
    }


    .react-datepicker__day--selected, .react-datepicker__month-text--selected,  .react-datepicker__quarter-text--selected, .react-datepicker__year-text--selected {
      border-radius: 0.3rem;
      background-color: ${colors.rhBlue};
      color: #fff;
    }
  }

  .col{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    
    .text-icon{
      height: 30px;
      display: flex;
      align-items: center;
      svg{
        margin-right: 15px;
        font-size: 1.2rem;
      }

      span{
        margin-left: 4px;
        font-family: 'Avenir Next GEO W05 Bold';
      }

      .span-status {
        font-family: 'Avenir Next GEO W05 Regular';
        font-size: 1rem;
      }

      .info-icon {
        color: ${colors.genoaDarkBlue};
      }

      .ticket-title {
        margin: 0;
        margin-left: 10px;
        font-family: 'Avenir Next GEO W05 Regular';
        font-size: 1rem;
        color: ${colors.genoaGrey};
      }
    }

    button{
      border: none;
      background: none;
      cursor: pointer;
      
      color: ${colors.genoaGrey};
      font-family: inherit;
      font-size: 1rem;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      column-gap: 15px;
      span {
        color: ${colors.genoaGrey};
        font-family: 'Avenir Next GEO W05 Bold';
      }
    }
    
    .red{color:red}
  }

  .status{
    min-width: 290px;
    color: ${colors.genoaGrey};
    svg{
      color: ${colors.genoaDarkBlue};
    }
  }
`
export const DialogDeleteContato = styled.div`
    display: flex;
    flex-direction: column;
    width: 500px;
    padding: 20px 20px;

    .estipulante-paragraph {
      font-family: 'Avenir Next GEO W05 Demi';
      margin-top: 5px;
      margin-bottom: 5px;
      color: ${colors.rhBlue};
      min-height: 21.6px;
    }

    .content {
      margin-top: 15px;
    }

    .edit-estipulante-confirmation {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      .check-icon {
        margin-right: 50px;
        color: #00b346;
        :hover {
          cursor: pointer;
        }
      }

      .cancel-icon {
        color: red;
        :hover {
          cursor: pointer;
        }
      }
    }

`