import { createContext, useContext, useState } from 'react';

export const BrokerNotificationsContext = createContext();

const NOTIFICATIONS_DEFAULT = {
  sidemenuNotifications: {
    movimentacoesAbertas: 0,
    ticketsAbertos: 0,
    conciergesAbertos: 0,
    negociosEmAndamentoAberto: {},
    negocioSemResponsavel: 0
  }
};

export const BrokerNotificationsContextProvider = ({ children }) => {
  const [brokerNotifications, setBrokerNotifications] = useState(
    NOTIFICATIONS_DEFAULT
  );

  const updateBrokerNotifications = (data) => {
    setBrokerNotifications({
      ...data
    });
  };

  return (
    <BrokerNotificationsContext.Provider
      value={{ brokerNotifications, updateBrokerNotifications }}
    >
      {children}
    </BrokerNotificationsContext.Provider>
  );
};

export const useBrokerNotifications = () =>
  useContext(BrokerNotificationsContext);

