import React from 'react';
import { Card } from '@material-ui/core';
import {
  Dot,
  Tooltip,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  XAxis,
  YAxis
} from 'recharts';
import { ConciergeTooltip, GraphBox } from './styles';

const GraficoConcierge = ({
  draggableMovementHandler,
  setDraggable,
  setPositionStart,
  setStoredDeltaAtendimento,
  mesesArrastadosAtendimento,
  setMesesArrastadosAtendimento,
  servicesByMonthsToPlot,
  renderXDomain,
  renderAtendimentoMonthsDomain,
  graphsDomainY,
  graphData,
  lineColor,
  dotColor,
  graphTitle,
  lineDash
}) => {
  class CustomizedTooltip extends React.PureComponent {
    render() {
      const {
        payload: [data],
        active
      } = this.props;

      if (active) {
        const {
          payload: { concierge_month, count, month }
        } = data;

        const year = concierge_month?.slice(3);

        return (
          <ConciergeTooltip>
            <Card className="tooltip-card">
              <div className="tooltip-info-container">
                <span className="tooltip-info-title">Mês:</span>
                <span className="tooltip-info-value-important">{`${month}/${year}`}</span>
              </div>
              <div className="tooltip-info-container">
                <span className="tooltip-info-title">Atendimentos:</span>
                <span className="tooltip-info-value-important">{count}</span>
              </div>
            </Card>
          </ConciergeTooltip>
        );
      }

      return null;
    }
  }

  class CustomizedAxisTick extends React.PureComponent {
    render() {
      const { x, y, payload } = this.props;

      const data = servicesByMonthsToPlot[payload.value];

      const year = data?.concierge_month?.slice(5);

      return (
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={0}
            dy={16}
            textAnchor="end"
            fill="#666"
            transform="rotate(-25)"
            style={{ fontSize: 10, textAlign: 'right' }}
            className="legend_text_x"
          >
            {data?.month}
          </text>
          <text
            x={0}
            y={0}
            dy={26}
            textAnchor="end"
            fill="#666"
            transform="rotate(-25)"
            style={{ fontSize: 10, textAlign: 'right' }}
            className="legend_text_x"
          >
            {year}
          </text>
        </g>
      );
    }
  }

  class CustomizedShape extends React.PureComponent {
    render() {
      const { cx, cy } = this.props;
      return <Dot cx={cx} cy={cy} fill={dotColor} r={4.5} />;
    }
  }

  return (
    <GraphBox>
      <span className="graph_title">{graphTitle}</span>
      <ResponsiveContainer width="100%" height="100%">
        <ScatterChart
          width={350}
          height={310}
          margin={{
            top: 10,
            right: 20,
            bottom: 20,
            left: 0
          }}
          onMouseMove={draggableMovementHandler}
          onMouseDown={() => {
            setDraggable(true);
            setPositionStart(window.event.x);
          }}
          onMouseUp={() => {
            setStoredDeltaAtendimento(
              (prevState) => prevState + mesesArrastadosAtendimento
            );
            setMesesArrastadosAtendimento(0);
            setPositionStart(undefined);
            setDraggable(false);
          }}
          onMouseLeave={() => {
            setStoredDeltaAtendimento(
              (prevState) => prevState + mesesArrastadosAtendimento
            );
            setMesesArrastadosAtendimento(0);
            setPositionStart(undefined);
            setDraggable(false);
          }}
        >
          <XAxis
            className="eixo-x"
            type="number"
            interval={0}
            tick={<CustomizedAxisTick />}
            tickFormatter={(index) => {
              return servicesByMonthsToPlot[index]?.month;
            }}
            dataKey="index"
            name="Ano"
            tickSize={2}
            ticks={renderXDomain()}
            scale="linear"
            domain={renderAtendimentoMonthsDomain()}
            allowDataOverflow={true}
          />
          <YAxis
            type="number"
            tick={{ fontSize: 11 }}
            dataKey="count"
            name="Total"
            unit=""
            interval={0}
            tickMargin={5}
            tickSize={4}
            scale="linear"
            domain={graphsDomainY}
          ></YAxis>

          <Tooltip
            className="recharts-tooltip-wrapper"
            cursor={{ strokeDasharray: '3 3' }}
            content={<CustomizedTooltip />}
            wrapperStyle={{ outline: 'none' }}
            contentStyle={{ color: 'red' }}
          />
          <Scatter
            name="Reajuste"
            data={graphData}
            lineJointType="linear"
            fill={lineColor}
            line={{
              strokeWidth: '0.7',
              strokeDasharray: lineDash ? lineDash : false
            }}
            shape={<CustomizedShape />}
          />
        </ScatterChart>
      </ResponsiveContainer>
    </GraphBox>
  );
};

export default GraficoConcierge;
