import { useState, useEffect } from 'react';
import { ContentTab } from './styles';
import { useForm } from 'react-hook-form';
import { AiOutlineCheck } from 'react-icons/ai';
import 'react-datetime/css/react-datetime.css';
import { BiPlus } from 'react-icons/bi';
import services from 'apps/broker/services';
import TopBar from 'apps/broker/components/HorizontalBar/topBar';
import BottomBar from 'apps/broker/components/HorizontalBar/bottomBar';

const ComissionamentoInner = ({ title, data, getData }) => {
  // Estado do Array renderizado na tela
  const [comissionamentos, setComissionamentos] = useState([]);
  const [openTable, setOpenTable] = useState(false);

  const { register, handleSubmit, control, getValues } = useForm({
    defaultValues: {
      ano: new Date().getFullYear(),
      contrato_id: data.length > 0 ? data[0]['id'] : '',
      estipulante_id: data.length > 0 ? data[0]['estipulante_id'] : ''
    }
  });

  const toggleOpenTable = () => {
    setOpenTable((prevState) => !prevState);
  };

  const getComissionamento = async () => {
    const { estipulante_id, contrato_id } = getValues();

    await services.insuranceCompanies
      .getCommissionContractId(estipulante_id, contrato_id)
      .then((resp) => {
        if (resp.data.length > 0) {
          setComissionamentos(resp.data);
        } else {
          setComissionamentos([]);
        }
      });
  };

  // UseEffect do primeiro carregamento de tela
  useEffect(() => getComissionamento(), []);

  async function onSubmit(dataForm) {
    const body = {
      comissionamento: dataForm
    };

    await services.insuranceCompanies.createCommissioning(body).then(() => {
      getComissionamento();
    });
  }

  return (
    <ContentTab>
      <TopBar title={'Remuneração'} />
      <div className="section-reajuste-comissionamento">
        <div
          className={
            openTable
              ? 'cols-data-reajuste-form-aberto'
              : 'cols-data-reajuste-form-fechado'
          }
        >
          {
            // Esse map só renderiza uma vez, foi usado como base o componente Reajuste que renderizava vários de uma vez
            data &&
              data.map((contrato) => {
                return (
                  <div className="contrato-data-table-comissionamento-inner">
                    <div className="cols-header-container">
                      <div className="cols-header-comissionamento-inner">
                        <p className="cols-header-comissionamento">
                          Agenciamento
                        </p>
                        <p className="cols-header-comissionamento">Vitalício</p>
                      </div>
                    </div>
                    <div className="reajustes-table-container">
                      {comissionamentos.map((comissao, index) => {
                        return (
                          <div className="cols-data-container">
                            <div className="cols-data-comissionamento-inner">
                              <p>
                                {comissao['vigenciamento'].replace('.', ',')}%
                              </p>
                              <p>{comissao['vitalicio'].replace('.', ',')}%</p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })
          }
        </div>

        <div className="reajuste-container">
          <div className="adicionar-reajuste-container">
            <span onClick={toggleOpenTable}>
              <BiPlus
                className={openTable ? 'fechar-reajuste' : 'adicionar-reajuste'}
                size={16}
              />
              Remuneração
            </span>
          </div>
          <form
            className={openTable ? 'form-table-visible' : 'form-table-hidden'}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="sectionContentReajuste">
              <div className="cols-comissionamento-inner">
                <div className="col-comissionamento">
                  <div className="title-col-comissionamento">
                    Agenciamento (%)
                  </div>
                  <input
                    type="text"
                    placeholder="Vigenciamento"
                    className="input-comissionamento"
                    {...register('vigenciamento', { required: true })}
                    onChange={() => console.log(control._formValues)}
                  />
                </div>
                <div className="col-comissionamento">
                  <div className="title-col-comissionamento">Vitalício (%)</div>
                  <input
                    type="text"
                    placeholder="Vitalício"
                    className="input-comissionamento"
                    {...register('vitalicio', { required: true })}
                    onChange={() => console.log(control._formValues)}
                  />
                </div>
                <div>
                  <div className="title-col-comissionamento"></div>
                  <button type="submit" className="comissionamento-submit-btn">
                    <AiOutlineCheck size={25} className="check-icon" />
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <BottomBar />
    </ContentTab>
  );
};

export default ComissionamentoInner;
