import styled from 'styled-components';
import colors from 'apps/broker/styles/colors';

export const Div = styled.div`
  display: grid;
  margin-left: -10px;
  width: 100px;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  .button-edit,
  .delete-button {
    color: ${colors.genoaDarkBlue};
    :hover {
      cursor: pointer;
    }
  }

  .paper-popup-delete-confirmation {
    width: 500px;
    height: 80px;
    background-color: ${colors.white};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .popup-message {
    font-weight: bold;
  }

  .popup-options-container {
    margin-top: 5px;
  }

  .popup-options {
    margin-left: 10px;
    margin-right: 10px;
    align-items: center;
    &:hover {
      font-weight: bold;
      cursor: pointer;
    }
  }

  .check-icon {
    margin-right: 2px;
    color: green;
  }

  .cross-icon {
    margin-right: 2px;
    color: red;
  }
`;
