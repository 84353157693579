import styled from "styled-components";
import colors from "styles/colors";

export const WrapperTabelaSeguradora = styled.div`
  background-color: ${colors.white};
  width: 100%;

  font-size: 0.77em;

  table {
    padding: 10px 20px;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    border-radius: 0.5%;
    width: 100%;
  }

  thead {

    tr {
      display: grid;
      grid-template-columns: 6fr 2.5fr 2.5fr 2.5fr;
      column-gap: 3px;
    }

    .seguradora {
      display: flex;
      align-items: end;
    }
  }

  tbody {

    tr {
      display: grid;
      grid-template-columns: 6fr 2.5fr 2.5fr 2.5fr;
      line-height: 0.95em;
      height: 2.5em;
      column-gap: 3px;
    }

    tr:nth-child(2n + 1) {
      background-color: ${colors.genoaMediumBackgroundGrey};
    }

    td {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    td:first-child {
      justify-content: flex-start;
    }

    .planos_col {
      text-align: center;
      font-size: 0.95em;
    }
  }

`