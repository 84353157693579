import styled from 'styled-components';
import colors from 'apps/broker/styles/colors';

export const NegociosWrapper = styled.div`
  display: flex;

  .animeLeft {
    opacity: 0;
    transform: translateX(-20px);
    animation: animeLeft 0.3s forwards;
  }

  @keyframes animeLeft {
    to {
      opacity: 1;
      transform: initial;
    }
  }

  .MuiDrawer-paper {
    width: 550px;
    left: "calc(50% - 275px)";
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 101vh;
  background: ${colors.genoaLightBackgroundGrey};

  .title{
    margin-top: 1px;
    margin-bottom: 2.5em;
    h1{
      font-family: "Avenir Next GEO W05 Heavy";
      font-size: 1.2rem;
      color: #3B3838;
    }
  }

`

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  row-gap: 35px;
  width: calc(100% - 90px);
  height: 100%;
  background: ${colors.genoaLightBackgroundGrey};
  padding: 25px 45px 25px 45px;

`