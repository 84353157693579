import styled from 'styled-components';
import colors from 'apps/broker/styles/colors';

export const ContentList = styled.section`
  display: flex;
  flex-direction: column;
  min-height: 60vh;

  .headerList {
    box-sizing: border-box;
    width: 100%;
    padding: 10px 15px;
    height: 69px;
    display: grid;
    grid-template-columns: 20px 1.5fr 0.4fr 0.5fr 2.2fr 40px 0.5fr 0.3fr 50px 50px 50px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;

    .header-col {
      justify-content: center;
      text-align: center;
    }

    font-family: 'Avenir Next GEO W05 Demi';
    color: ${colors.genoaGrey};
    grid-gap: 10px;
    align-items: center;

    .edit-buttons-container {
      display: grid;
      margin-left: 15px;
      grid-template-columns: 1fr 1fr;
      align-items: end;
      .button-edit,
      .delete-button {
        margin-bottom: 7px;
      }
    }

    .button-edit {
      display: flex;
      align-items: center;
      // background-color: ${colors.genoaGrey};
      border: 0;
      margin: 0;
      border-radius: 5px;
      color: ${colors.genoaDarkBlue};
      justify-content: center;
      &:hover {
        cursor: pointer;
      }
    }

    .button-edit > span {
      margin-right: 10px;
    }

    .delete-button {
      display: flex;
      align-items: center;
      margin-left: 30px;
      color: ${colors.genoaDarkBlue};
      &:hover {
        cursor: pointer;
      }
    }

    .paper-popup-delete-confirmation {
      width: 500px;
      height: 80px;
      background-color: ${colors.white};
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .popup-options-container {
      margin-top: 5px;
      font-family: 'Avenir Next GEO W05 Regular';
    }

    .popup-message {
      font-weight: bold;
    }

    .popup-options {
      margin-left: 10px;
      margin-right: 10px;
      align-itens: center;

      &:hover {
        font-weight: bold;
        cursor: pointer;
      }
    }

    .check-icon {
      margin-right: 2px;
      color: green;
    }

    .cross-icon {
      margin-right: 2px;
      color: red;
    }

    .checkbox-on-table {
      margin-left: 3px;
      .checkbox {
        svg {
          height: 18px;
          width: 18px;
        }
      }
    }
  }

  .button-edit-on-table {
    color: ${colors.genoaDarkBlue};
    margin: 5px;
    margin-bottom: 0;
    margin-bottom: -1px;
    margin-left: 0;
    border-radius: 4px;
  }

  .button-edit-on-table: hover {
    color: ${colors.white};
    background-color: ${colors.genoaDarkBlue};
    padding: 0;
    margin: 5px;
    margin-bottom: 0;
    margin-bottom: -1px;
    margin-left: 0;
    border-radius: 4px;
    cursor: pointer;
  }

  .checkbox {
    width: 16px;
    padding: 0px;
  }
`;
export const ItemList = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border: 3px ${colors.genoaBlue} solid;
  border-radius: 30px;
  padding: 13px 15px;
  box-sizing: border-box;
  margin-bottom: 35px;
  font-size: 0.9rem;

  .data {
    .data-nome {
      margin-left: -2px;
    }

    .data-checkbox {
      margin-left: 1px;
    }

    width: 100%;
    display: grid;
    line-height: 29px;
    grid-template-columns: 20px 1.5fr 0.4fr 0.5fr 2.2fr 40px 0.5fr 0.3fr 50px 50px 50px;
    font-size: 0.8rem;
    .data-col {
      justify-content: center;
      text-align: center;
    }
    grid-gap: 10px;
    > div {
      display: flex;
      align-items: center;
    }

    .beneficios {
      display: flex;

      .active-icon {
        display: flex;
        justify-content: center;

        align-items: center;
        margin-left: 5px;
        margin-right: 5px;
        color: ${colors.genoaDarkBlue};

        svg {
          width: 18px;
          height: 18px;
          fill: currentColor;
        }
      }
      .in-removal-icon {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 5px;
        color: #f8c000;

        svg {
          width: 20px;
          height: 20px;
          fill: currentColor;
        }
      }

      .inactive-icon {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 5px;
        color: ${colors.genoaDisabledGrey};

        svg {
          width: 20px;
          height: 20px;
          fill: currentColor;
        }
      }
    }

    .icon {
      font-size: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;

      button.btn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px;
        border: none;
        font-size: 1.3rem;
        cursor: pointer;
        background: none;
        transition: 0.1s ease-in;
        border-radius: 50%;

        :hover {
          background: rgb(204 204 204 / 25%);
        }
      }
      .close {
        color: red;
      }
      .search {
        color: ${colors.genoaBlue};
      }
      .detalhes {
        color: ${colors.genoaDarkBlue};
      }
    }

    .delete-beneficiary {
      > button {
        background: none;
        border: none;

        > svg {
          font-size: 1.5rem;
          color: #d32f2f;
          border-radius: 50%;
          cursor: pointer;

          transition: all 0.4s;

          :hover {
            background: rgb(204 204 204 / 25%);
          }
        }
      }
    }

    .checkbox {
      margin-bottom: -1px;
      svg {
        width: 18px;
        height: 18px;
      }
    }
  }

  .itemContent {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    padding: 1rem 0;
    border-top: 1px #ccc solid;
    margin-top: 20px;

    .col {
      font-size: 0.8rem;
      div {
        margin-bottom: 1rem;
      }

      .beneficiario-info {
        display: flex;

        svg {
          color: ${colors.genoaDarkBlue};
          margin-right: 10px;
          width: 20px;
          height: 20px;
          fill: currentColor;
        }
      }
    }
    .documentos {
      grid-area: 2 / 1 / 2 / 4;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .btn-deteils {
        border: none;
        background: ${colors.white};
        cursor: pointer;
        font-size: inherit;
        font-family: inherit;
        margin-right: 30px;
        display: flex;
        align-items: center;
        padding: 2px 10px;
        border-radius: 5px;
        color: ${colors.genoaBlue};
        column-gap: 5px;
        :hover {
          text-decoration: underline;
        }
      }
      span {
        font-size: 14px;
        font-family: 'Avenir Next GEO W05 Medium';
      }
    }

    .valores {
      padding-left: 10px;
      display: flex;
      align-items: center;

      svg {
        color: ${colors.genoaDarkBlue};
        margin-right: 10px;
        width: 20px;
        height: 20px;
        fill: currentColor;
      }
    }

    .text-color {
      color: ${colors.genoaBlue};
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      span {
        padding: 0.25rem 0;
        transition: all 0.4s;
        a {
          text-decoration: none;
          color: #3b3838;
        }

        &:hover {
          text-decoration: underline;
          filter: brightness(0.8);
        }
      }
    }
  }
`;
