import React from 'react';
import { Wrapper } from './styles-DrawerUrgentDemands';
import { ReactComponent as WarningSignSvg } from 'assets/imgs/svg/warning-sign.svg';
import { useUser } from 'contexts/user';
import MovimentacaoCard from './MovimentacaoCard';

const DrawerUrgentDemands = (props) => {
  const { setOpenDrawer } = props;
  const { urgentMovimentacoes } = props.urgentDemands;
  const { user } = useUser();

  return (
    <Wrapper>
      <header>
        <div className="header-grid">
          <div className="title-container">
            <span>{user?.nome}!</span>
            <span className="title-info">
              Existem movimentações que precisam da sua atenção.
            </span>
          </div>
          <div className="icon-container">
            <WarningSignSvg />
          </div>
        </div>
      </header>
      <section>
        {urgentMovimentacoes?.length ? (
          <div className="movimentacao-container">
            <span className="section-title">
              Movimentações abertas há mais de 5 dias
            </span>
            <div className="demandas-lista">
              {urgentMovimentacoes?.map((item) => (
                <MovimentacaoCard
                  movimentacao={item}
                  setOpenDrawer={setOpenDrawer}
                />
              ))}
            </div>
          </div>
        ) : (
          ''
        )}
      </section>
    </Wrapper>
  );
};

export default DrawerUrgentDemands;
