import styled from 'styled-components';
import colors from 'styles/colors';

export const DrawerAdicionarPlanilhaWrapper = styled.div`
  background-color: ${colors.white};
  
  width: 90%;
  height: 90%;
  max-width: 980px;
  max-height: 663px;

  display: grid;

  @media (max-height: 580px) {
    margin-left: 2.68vw;
    border-radius: 13px;
    grid-template-columns: 13px 1fr;
  }

  @media (min-height: 580px) and (max-height: 650px) {
    margin-left: 3.2vw;
    border-radius: 16px;
    grid-template-columns: 16px 1fr;
  }

  @media (min-height: 650px) and (max-height: 720px) {
    margin-left: 3.6vw;
    border-radius: 18px;
    grid-template-columns: 18px 1fr;
  }

  @media (min-height: 720px) {
    margin-left: 4vw;
    border-radius: 20px;
    grid-template-columns: 20px 1fr;
  }
`

export const Content = styled.div`
  height: 100%;
  width: 100%;
  display: grid;

  .close-icon-container {
    display: flex;

    position: fixed;
    transform: translateY(-40px) translateX(900px);
    

    .close-icon {
      color: ${colors.genoaPink};

      :hover {
        cursor: pointer;
      }
    }
  }

  h3 {
    font-family: Avenir Next GEO W05 Heavy;
    font-size: 1.3em;
    color: ${colors.rhBlue};
    padding: 15px;
  }

  .container_select {
    display: flex;
    flex-direction: column;
    text-algn: center;
    column-gap: 10px;
    padding-left: 40px;

    width: 300px;

    .select__trigger {
      border-bottom: none;
      background-position-y: 10px;
      height: 24px;

      :hover{
        border-bottom: none;
        cursor: pointer;
      }
    }

    .options__wrapper {
      font-size: 0.95em;
      row-gap: 2px;
      border: none;
      width: 200px;

      span:hover {
        background-color: ${colors.genoaBlue};
      }

      ::-webkit-scrollbar-thumb {
        background: ${colors.genoaBlue};
      }
    }
    
  }

  .btn {
    background: none;
    border: none;
    font-family: Avenir Next GEO W05 Demi;
    font-size: 0.9em;
    color: ${colors.rhBlue};
    
    padding: 40px;

    :hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .container_btn_doc {
    display: flex;
    flex-direction: row;
  }

  .doc_container {
    display: flex;
    flex-direction: row;
    text-algn: center;
    column-gap: 10px;
    padding: 40px;

    .remove_icon {
      color: ${colors.genoaPink};
  
      :hover {
        cursor: pointer;
      }
    }
  }


  .lista_bene_container {
    margin-left: 30px;

    width: 380px;

    .lista_titulo {
      font-family: Avenir Next GEO W05 Demi;
      font-size: 1em;
      padding: 6px;

      height: 20px;

      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='12px' height='10px' viewBox='0 0 7 6' version='1.1'%3E%3Cg id='surface1'%3E%3Cpath style='stroke:none;fill-rule:nonzero;fill:%2393A9F5;fill-opacity:1;' d='M 3.5 6 L 0.46875 0.5 L 6.53125 0.5 Z M 3.5 6 '/%3E%3C/g%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position-x: 350px;
      background-position-y: 9.5px;

      :hover {
        border-radius: 6px;
        box-shadow: 2px 4px ${colors.rhBlue};
        cursor: pointer;
      }
    }
  }

  .btn_enviar {
    text-align: center;
    background-color: ${colors.rhBlue};
    font-family: Avenir Next GEO W05 Demi;
    font-size: 0.95em;
    color: ${colors.white};

    cursor: pointer;
    border: none;
    border-radius: 8px;
    padding: 5px;
    height: 30px;
    width: 60px;
  }

  .disabled {
    background-color: ${colors.genoaDisabledGrey};
  }

  .label-file {
    font-family: Avenir Next GEO W05 Demi;
    font-size: 0.9em;
    color: ${colors.rhBlue};
    
    cursor: pointer;
    
    border: none;
      
    input{display: none;}
    span {
      display: flex;
      align-items: center;
      color: ${colors.genoaBlue};
      font-size: 16px;
      svg{
        font-size: 1.3rem;
      }
    }
  }

  
  @media (max-height: 580px) {
    grid-template-rows: 30px 50px 1fr;
  }

  @media (min-height: 580px) and (max-height: 650px) {
    grid-template-rows: 40px 60px 1fr;
  }

  @media (min-height: 650px) and (max-height: 720px) {
    grid-template-rows: 50px 70px 1fr;
  }

  @media (min-height: 720px) {
    grid-template-rows: 60px 80px 1fr;
  }
`

export const AbaPlanos = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 50px;
  margin-left: 40px;
  margin-top: 20px;

  .aba_btn {
    font-family: Avenir Next GEO W05 Demi;
    font-size: 1em;
    cursor: pointer;

    :hover {
      color: ${colors.rhBlue};
      cursor: pointer;
    }
  }

  .btn_aba_selected {
    color: ${colors.rhBlue};
    font-size: 1.2em;
    font-family: Avenir Next GEO W05 Demi;
  }
  
`