import styled from 'styled-components';
import colors from 'apps/broker/styles/colors';

export const ItemList = styled.div`
  display: flex;
  background: #fff;
  border: 3px ${colors.genoaBlue} solid;
  border-radius: 40px;
  padding: 35px 40px;
  box-sizing: border-box;
  margin-bottom: 30px;
  height: 220px;
  justify-content: space-between;

  .select-estipulante-container-visible {
    select {
      font-family: 'Avenir Next GEO W05 Regular';
      background-color: ${colors.white};
      border: 1.5px solid ${colors.genoaBlue};
      border-radius: 10px;
      opacity: 1;
      font-size: 0.8rem;
      color: #3b3838;
      text-align: center;
      width: 100%;
      height: 30px;

      :focus-visible {
        outline: 1px ${colors.genoaBlue}f5 auto;
      }
    }
  }

  .select-is-visible-to-rh {
    display: flex;
    flex-direction: row;

    .visivel-title {
      margin: 0;
      height: 22.4px;
      font-family: 'Avenir Next GEO W05 Regular';
      font-size: 1rem;
      color: ${colors.genoaGrey};
      width: 220px;
    }

    .visivel-rh {
      text-align: left;
      margin: 0;
      height: 22.4px;
      font-family: 'Avenir Next GEO W05 Regular';
      font-size: 1rem;
      color: ${colors.genoaGrey};
      width: 40px;
    }

    select {
      font-family: 'Avenir Next GEO W05 Regular';
      background-color: ${colors.white};
      border: 1.5px solid ${colors.genoaBlue};
      border-radius: 10px;
      opacity: 1;
      font-size: 0.8rem;
      color: #3b3838;
      text-align: center;
      width: 30%;
      height: 25px;

      :focus-visible {
        outline: 1px ${colors.genoaBlue}f5 auto;
      }
    }
  }

  .edit-estipulante-ticket {
    color: ${colors.genoaDarkBlue};
    margin-left: 5px;
    :hover {
      cursor: pointer;
    }
  }
  .second-line {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      position: absolute;
      top: -11px;
      z-index: 2;
      left: 16px;
      color: ${colors.genoaBlue};
      font-size: 14px;
      background: #fff;
      letter-spacing: 0.4px;
    }
  }

  .group-lb {
    position: relative;
    margin-left: 16px;
    font-size: 0.9rem;

    span {
      cursor: pointer;
      position: relative;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
    }

    // span::before {
    //   content: '';
    //   position: absolute;
    //   top: 6px;
    //   right: -10px;
    //   width: 4px;
    //   height: 4px;
    //   border-left: 1px solid ${colors.genoaBlue};
    //   border-top: 1px solid ${colors.genoaBlue};
    //   z-index: 25;
    //   transform: rotateZ(225deg);
    // }

    p {
      color: ${colors.genoaBlue};
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-family: 'Avenir Next GEO W05 Bold';
    }

    &:hover > .dropMn {
      display: block;
    }

    .dropMn {
      z-index: 8;
      display: none;
      width: 160px;
      position: absolute;
      top: 21px;
      background: #fff;
      padding: 8px 0;
      border-radius: 6px;
      border: 1px solid #ccc;
    }

    li {
      list-style: none;
      cursor: pointer;
    }

    a {
      position: relative;
      color: ${colors.genoaGrey};
      display: block;
      width: auto;
      margin: 2px 0;
      padding: 8px 10px;
    }

    a:hover {
      background: #f1f3f9;
    }
  }

  .searchSeguradora {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 0px;
    margin-left: 40px;
    margin-top: -40px;

    .autocomplete-seguradora {
      height: 0px;
    }

    .MuiAutocomplete-listbox {
      li {
        // color: red;
        font-size: 14px;
        min-height: 33px;
      }
    }

    label {
      position: absolute;
      top: -11px;
      left: 18px;
      color: ${colors.genoaBlue};
      font-size: 14px;
      background: #fff;
      letter-spacing: 0.4px;
    }

    input {
      border: 1px #ccc solid;
      border-radius: 20px;
      padding: 8px 0 8px 10px;
      text-align: left;
      min-width: 270px;
      font-size: 12px;
    }
  }

  .searchCorretor {
    position: relative;
    display: flex;
    align-items: center;
    column-gap: 15px;
    justify-content: center;

    label {
      position: absolute;
      top: -10px;
      left: 18px;
      color: ${colors.genoaBlue};
      font-size: 14px;
      background: #fff;
      letter-spacing: 0.4px;
    }

    input {
      border: none;
      // border-radius: 20px;
      text-align: left;
      color: ${colors.genoaGrey};
      font-family: 'Avenir Next GEO W05 Regular';
      font-size: 1rem;
      min-width: 270px;

      ::placeholder {
        color: ${colors.genoaGrey};
        font-family: 'Avenir Next GEO W05 Regular';
      }
    }
  }

  .atuacao-data-container {
    display: flex;
    column-gap: 15px;
    align-items: center;
    height: 23px;

    .react-datepicker-wrapper {
      display: inline-block;
      padding: 0;
      border: 0;
      width: 150px;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
      background-color: white;
      color: ${colors.genoaBlue};
      font-weight: bold;
    }

    .react-datepicker__day--selected,
    .react-datepicker__month-text--selected,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__year-text--selected {
      border-radius: 0.3rem;
      background-color: ${colors.genoaBlue};
      color: #fff;
    }
  }

  .col {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    justify-content: space-between;
    align-items: flex-start;

    .text-icon {
      height: 30px;
      display: flex;
      align-items: center;
      svg {
        margin-right: 15px;
        font-size: 1.2rem;
      }

      span {
        margin-left: 4px;
        font-family: 'Avenir Next GEO W05 Bold';
      }

      .span-status {
        font-family: 'Avenir Next GEO W05 Regular';
        font-size: 1rem;
      }

      .info-icon {
        color: ${colors.genoaDarkBlue};
      }

      .ticket-title {
        margin: 0;
        margin-left: 10px;
        height: 22.4px;
        font-family: 'Avenir Next GEO W05 Regular';
        font-size: 1rem;
        color: ${colors.genoaGrey};
      }

      .ticket-title-input-container {
        display: flex;
        flex-direction: column;
      }

      .input-column {
        display: flex;
        align-items: center;
      }

      .update-confirm-btn {
        font-size: 22.4px;
        color: ${colors.green};

        :hover {
          cursor: pointer;
        }
      }

      .update-cancel-btn {
        font-size: 19px;
        color: ${colors.genoaPink};

        :hover {
          cursor: pointer;
        }
      }

      .subject-update-btns {
        padding-left: 15px;
        display: flex;
        align-items: center;
        column-gap: 5px;
      }

      .ticket-title-input {
        margin: 0;
        margin-left: 10px;
        font-family: 'Avenir Next GEO W05 Regular';
        font-size: 1rem;
        color: ${colors.genoaGrey};
        border: none;
        border-bottom: 2px solid ${colors.genoaBlue};
      }

      .ticket-title-hidden-span {
        margin: 0;
        margin-left: 10px;
        font-family: 'Avenir Next GEO W05 Regular';
        font-size: 1rem;
        color: ${colors.genoaGrey};
        opacity: 0;
        height: 0;
        width: max-content;
      }
    }

    button {
      border: none;
      background: none;
      cursor: pointer;

      color: ${colors.genoaGrey};
      font-family: inherit;
      font-size: 1rem;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      column-gap: 15px;
      span {
        color: ${colors.genoaGrey};
        font-family: 'Avenir Next GEO W05 Bold';
      }
    }

    .red {
      color: red;
    }
  }

  .status {
    min-width: 280px;
    color: ${colors.genoaGrey};
    svg {
      color: ${colors.genoaDarkBlue};
    }
  }

  .fire-icon {
    width: 30px;
    height: 30px;

    position: absolute;
    // position 1
    // transform: translate(260px, -20px);

    // position 2
    transform: translate(295px, -17px);
  }
`;