import React from "react";
import removeMask from "utils/inputMasks/removeMask";
import { InputComponent } from "./styles-inputStandard";


const InputStandard = (
  {
    value,
    setValue,
    label,
    name,
    maskFunction,
    maxLength
  }) => {

  const [focused, setFocused] = React.useState(false);

  const inputChangeHandler = (event) => {
    const { target } = event;

    if (maxLength) {
      if (maskFunction) {
        const valueUnmasked = removeMask(target.value)
        if (valueUnmasked.length > maxLength) {
          const maxValueUnmasked = valueUnmasked.slice(0, maxLength)
          target.value = maskFunction(maxValueUnmasked);
          return event
        }
      } else {
        if (target.value.length > maxLength) {
          target.value.slice(0, maxLength);
          return event;
        }
      }
    }

    if (maskFunction) {
      const valueUnmasked = removeMask(target.value)
      // console.log(valueUnmasked);
      target.value = maskFunction(valueUnmasked);
      return setValue(event);
    }
    return setValue(event);
  }

  return (
    <InputComponent>
      <label className={value?.length || focused ? "translate" : ""}>{label}</label>
      <input
        className={value?.length || focused ? "focused" : ""}
        value={value}
        onChange={inputChangeHandler}
        onBlur={() => setFocused(false)}
        onFocus={() => setFocused(true)}
        name={name}
        autoComplete="new-telephone"
      />
    </InputComponent>
  )
}

export default InputStandard;
