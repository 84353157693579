import tickets from './tickets';
import user from './user';
import inclusionRequest from './inclusionRequest';
import beneficiaries from './beneficiaries';

const modules = {
  tickets,
  user,
  inclusionRequest,
  beneficiaries
};

export default modules;
