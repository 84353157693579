import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';

import { FiLogIn } from "react-icons/fi";
import { Wraper } from './styles'
import LogoCoinsure from 'assets/imgs/logo-coinsure.png'
import api from 'services/api.js'

import { makeStyles } from '@material-ui/core/styles';
import InputMask from 'react-input-mask';

import { useForm, Controller } from "react-hook-form";
import { FiCheckCircle } from "react-icons/fi";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  header: {
    boxShadow: 'none',
    backgroundColor: '#f0e7ff'
  },
  tabs: {
    fontFamily: 'Avenir Next GEO W05 Demi'
  }
}));


export default function CadastroRh(props) {

  const classes = useStyles();
  const { control, handleSubmit } = useForm();

  const [stateSucess, setStateSucess] = useState(false)

  const {token} = useParams()
  const [estipulante, setEstipulante] = useState("")
  const [error, setError] = useState();

  const onSubmit = data => {
    const headers = {
      estipulante: estipulante
    }
    api.post('/cadastro-rh',
     data,
     {headers: headers}
     ).then(resp => {
      setStateSucess(true)
    }).catch(err => {
      console.log(err)
      setError('Usuário já Cadastrado no banco de Dados!')
    })
  };

  const getInfos = () => {
    // console.log('getInfos')
    api.get(`/gestor/${token}`)
    .then(resp => {
      setEstipulante(resp.data.estipulante_id)
      // console.log(resp.data)
    }).catch(err => {
      console.log('Error GetInfos', err)
    })
  }

  React.useEffect(() => {
    getInfos()
  }, [])

  // console.log(estipulante)

  return (
    <Wraper>
      <header>
        <Container maxWidth="xl">
          <div className="top-area">
            <a className="nav-logo" href="/">
              <img src={LogoCoinsure} width="200" alt="" loading="lazy" />
            </a>
          </div>
        </Container>

      </header>
      <main>
        <Container>
          <Grid container justify="center" spacing={3}>
            <Grid item xs={12} md={6}>
              <Card>
              <div className={classes.root}>
                  {
                    stateSucess
                    ?
                    <CardContent>
                      <div className="largeIcon">
                        <FiCheckCircle />
                      </div>
                      <div className="title"><h1>Cadastro realizado</h1></div>
                      <div className="msg-success">
                  

                        <span>Você receberá um email para confirmação da corretora.</span>
                      
                      </div>
                    </CardContent>
                    :
                    <CardContent>
                    <div className="title"><h1>Cadastro Rh</h1></div>
                    <div className="form">
                      <form validate="true" autoComplete="off" onSubmit={handleSubmit(onSubmit)} >
                        <div className="flex-center">
                        </div>
                        <label className="default">E-mail</label>
                        <FormControl fullWidth className="form-controll">
                            <Controller
                              name="email"
                              control={control}
                              defaultValue={""}
                              render={({ field }) => <InputMask {...field}
                                type="email"
                                label="email"
                                required />}
                            />
                        </FormControl>

                        <label className="default">Nome</label>
                        <FormControl fullWidth className="form-controll">
                            <Controller
                              name="nome"
                              control={control}
                              defaultValue=""
                              render={({ field }) => <InputMask {...field}
                                type="text"
                                label="nome"
                                required />}
                            />
                        </FormControl>

                        <label className="default">Sobrenome</label>
                        <FormControl fullWidth className="form-controll">
                            <Controller
                              name="sobrenome"
                              control={control}
                              defaultValue=""
                              render={({ field }) => <InputMask {...field}
                                type="text"
                                label="sobrenome"
                                required />}
                            />
                        </FormControl>

                        <label className="default">Senha</label>
                        <FormControl fullWidth className="form-controll" >
                          <Controller
                            name="password"
                            control={control}
                            defaultValue=""
                            render={({ field }) => <InputMask {...field}
                              type="password"
                              label="Senha"
                              required />}
                          />
                        </FormControl>

                        <label className="default">Telefone</label>
                        <FormControl fullWidth className="form-controll" >
                        <Controller
                            name="telefone"
                            control={control}
                            defaultValue={''}
                            rules={{ required: false }}
                            render={({ field }) => <InputMask
                              {...field}
                              maskChar={null}
                              mask="(99) 99999-9999"
                              type="text"
                              placeholder="" />}
                          />
                        </FormControl>

                        <div className="flex-center mb-1" style={ { display: 'block' } }>
                          <button type="submit" >Cadastrar <span className="icon"><FiLogIn /></span> </button>
                          {error ? <div style={ { marginTop: '5px' } }><span style={ { color: 'red' } }>{error}</span></div> : ''}
                        </div>
                      </form>
                    </div>

                  </CardContent>
                  }
                </div>
              </Card>
            </Grid>

          </Grid>
        </Container>



      </main>

    </Wraper>
  )
}
