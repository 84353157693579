import React from "react";
import InputStandard from "components/Input/InputStandard";
import maskData from "utils/inputMasks/maskData";
import { AbaDadosVinculoWrapper } from "./styles-abaDadosVinculo.js";

const AbaDadosVinculo = (
  { values,
    setInputValue,
    setSelectValue,
    camposInclusao
  }) => {
  // console.log('camposInclusao', camposInclusao)
  return (
    <AbaDadosVinculoWrapper>
      <div className="aba-container">
        <div className="aba-title-container">
          <span>4. Dados de Vínculo</span>
        </div>
        <div className="aba-input-container">
          <div className="aba-input-row-cargo">
            {
              camposInclusao['cargo'] ? (
                <InputStandard
                  value={values['cargo']}
                  setValue={setInputValue}
                  label={"Cargo"}
                  name="cargo"
                />
              ) : ""
            }

            {
              camposInclusao['data_admissao'] ? (
                <InputStandard
                  value={values['data_admissao']}
                  setValue={setInputValue}
                  label={"Data de admissão"}
                  name="data_admissao"
                  maskFunction={maskData}
                  maxLength={8}
                />
              ) : ""
            }
          </div>
          {
            camposInclusao['matricula_funcional'] ? (
              <div className="container_matricula_funcional">
                <div className="aba-title-container">
                  <span>Informe a matrícula da sua ficha de registro</span>
                </div>
                <div className="aba-input-container">
                  <div className="aba-input-row-cargo">
                    <InputStandard
                      value={values['matricula_funcional']}
                      setValue={setInputValue}
                      label={"Matrícula"}
                      name="matricula_funcional"
                    />
                  </div>
                </div>
              </div>
            ) : null
          }
        </div>
      </div>

    </AbaDadosVinculoWrapper>
  )
}

export default AbaDadosVinculo;
