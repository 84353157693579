import { useState } from 'react';
import {
  FormControl,
  makeStyles,
  TextField,
  CircularProgress
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import logoZeca from 'assets/imgs/svg/zeca.svg';
import peopleWorking from 'assets/imgs/svg/people-working.svg';
import buildingImg from 'assets/imgs/svg/building.svg';
import talkBalloonsImg from 'assets/imgs/svg/talk-balloons.svg';
import ticketsImg from 'assets/imgs/svg/tickets.svg';
import baseballPlayer from 'assets/imgs/svg/baseball-player.svg';
import mailSentImg from 'assets/imgs/svg/mail-sent.svg';
import attachedFileImg from 'assets/imgs/svg/attached-file.svg';
import Drawer from 'apps/lp/components/Drawer';
import PhoneTextField from 'components/PhoneTextField';
import Header from '../../components/Header';
import Footer from 'apps/lp/components/Footer';
import {
  Container,
  InfoTitle,
  InfoText,
  Demonstracao,
  Message
} from './styles';
import services from 'apps/lp/services';

const useStyles = makeStyles({
  content: {
    padding: 32
  },
  description: {
    fontSize: '1.025rem',
    fontFamily: 'Avenir Next GEO W05 Demi',
    marginBottom: 16
  },
  form: {
    display: 'flex',
    flexDirection: 'column'
  },
  formControl: {
    marginTop: '1rem'
  },
  button: {
    margin: '2rem auto',
    borderRadius: '0.9365rem',
    background: '#f8c011',
    width: '100%',
    fontFamily: 'Avenir Next GEO W05 Demi',
    border: 'none',
    lineHeight: '2.6rem',
    fontSize: '1.25rem',
    cursor: 'pointer',
    transition: '0.2s',
    '&:hover': {
      filter: 'brightness(0.8)'
    }
  }
});

const ZecaHome = () => {
  const classes = useStyles();

  const [openDrawer, setOpenDrawer] = useState();
  const [message, setMessage] = useState({});

  const {
    control,
    formState: { isSubmitting },
    handleSubmit
  } = useForm();

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleRequestDemonstration = async (data) => {
    setMessage({});

    const response = await services.client.createDemonstrationRequest(data);

    if (response.status === 200) {
      setMessage({
        color: '#81dd9f',
        message: 'Obrigado por sua solicitação, em breve entraremos em contato!'
      });
    } else {
      setMessage({
        color: '#f76767',
        message:
          'Falha ao fazer a solicitação, por favor tente novamente mais tarde!'
      });
    }
  };

  return (
    <>
      <Drawer
        onOpen={handleOpenDrawer}
        onClose={handleCloseDrawer}
        open={openDrawer}
      >
        <div className={classes.content}>
          <p className={classes.description}>
            Preencha os dados abaixo para solicitar uma demonstração do ZECA.
          </p>
          <form
            className={classes.form}
            onSubmit={handleSubmit(handleRequestDemonstration)}
          >
            <FormControl fullWidth className={classes.formControl}>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField {...field} label="Nome" required />
                )}
              />
            </FormControl>
            <FormControl fullWidth className={classes.formControl}>
              <Controller
                name="corretora"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField {...field} label="Corretora" required />
                )}
              />
            </FormControl>
            <FormControl fullWidth className={classes.formControl}>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField {...field} type="email" label="E-mail" required />
                )}
              />
            </FormControl>
            <FormControl fullWidth className={classes.formControl}>
              <Controller
                name="cellphone"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <PhoneTextField {...field} label="Celular" required />
                )}
              />
            </FormControl>
            <button
              type="submit"
              className={classes.button}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <CircularProgress size={20} />
              ) : (
                'Solicitar demonstração'
              )}
            </button>
            <div style={{ textAlign: 'center' }}>
              {message.message && (
                <Message color={message.color}>{message.message}</Message>
              )}
            </div>
          </form>
        </div>
      </Drawer>
      <Header />
      <Container>
        <section className="welcome-section">
          <div className="welcome-container">
            <div className="welcome-title">
              <h1>Bem vindo ao</h1>
              <img src={logoZeca} alt="ZECA" className="zeca-img" />
            </div>
            <p>
              A plataforma que vai mudar a forma como a sua corretora se
              relaciona com os RHs das empresas
            </p>
            <button onClick={handleOpenDrawer}>Eu quero!</button>
          </div>
          <div className="welcome-img">
            <img
              src={peopleWorking}
              alt="One person in the computer and other person reading"
              className="people-working-img"
            />
          </div>
        </section>
        <section className="info-1-section">
          <h2>Como o Zeca ajuda a sua corretora?</h2>
          <div className="info-1-container">
            <img src={buildingImg} alt="Building" className="building-img" />
            <div className="info-1-text-container">
              <InfoTitle>Facilidade na informação</InfoTitle>
              <InfoText>
                O Zeca te dá uma visão inteligente e simples de todos os
                clientes de seguro saúde, odontológico e vida. Além disso, todas
                as informações dos beneficiários ativos e dos contratos são
                vistas aqui.
              </InfoText>
            </div>
          </div>
        </section>
        <section className="info-2-section">
          <div className="info-2-text-container">
            <InfoTitle>Receba e responda as demandas pelo Zeca.</InfoTitle>
            <InfoText>
              Chega de procurar pendências e demandas nos e-mails. O Zeca
              concentra tudo que é seguro saúde, odontológico e vida aqui.
            </InfoText>
          </div>
          <img
            src={talkBalloonsImg}
            alt="Talk balloons"
            className="talk-balloons-img"
          />
        </section>
        <section className="info-3-section">
          <div className="info-3-img">
            <img src={ticketsImg} alt="Tickets" className="tickets-img" />
          </div>

          <div className="info-3-text-container">
            <InfoTitle>
              Quantas demandas sua corretora possui em aberto?
            </InfoTitle>
            <InfoText>
              Pois é, o Zeca te resume isso o tempo todo e toda vez que um
              cliente abre um novo Ticket, o Zeca atualiza a informação.
            </InfoText>
          </div>
        </section>
        <section className="info-4-section">
          <div className="info-4-text-container">
            <InfoTitle>
              Quer dar visibilidade para a sua corretora? Chame o Zeca.
            </InfoTitle>
            <InfoText>
              Os colaboradores dos seus clientes poderão conhecer a sua
              corretora e os outros produtos que você vende!
            </InfoText>
          </div>
          <img
            src={baseballPlayer}
            alt="Baseball player"
            className="baseball-player-img"
          />
        </section>
        <section className="info-5-section">
          <div className="info-5-container">
            <div className="info-5-text-container">
              <InfoTitle>O Zeca te dá um e-mail grátis =)</InfoTitle>
              <InfoText>
                Se o seu RH enviar um e-mail para a sua corretora, o Zeca abre o
                ticket para você na plataforma!
              </InfoText>
            </div>
            <img src={mailSentImg} alt="Mail sent" className="mail-sent-img" />
          </div>

          <div className="info-5-container">
            <div className="info-5-text-container">
              <InfoTitle>Em qual pasta eu salvei o documento?</InfoTitle>
              <InfoText>
                Você dá os documentos para o Zeca e ele guarda para a sua
                corretora.
              </InfoText>
            </div>
            <img
              src={attachedFileImg}
              alt="Attached file"
              className="attached-file-img"
            />
          </div>
        </section>
      </Container>
      <Demonstracao>
        <InfoTitle>Quer agendar uma demonstração?</InfoTitle>
        <button onClick={handleOpenDrawer}>Eu quero!</button>
      </Demonstracao>
      <Footer />
    </>
  );
};

export default ZecaHome;
