import axios from 'axios';

export const TOKEN_LABEL = 'genoa-token';
export const BROKERAGE_ID = 6;
export const BROKERAGE_UUID = 'a0ddc2c02634da2f84d2';

const http = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_API
});

http.interceptors.request.use(
  async (config) => {
    const tokenFromStorage = sessionStorage.getItem(TOKEN_LABEL);
    let authToken = JSON.parse(tokenFromStorage || '{}');

    if (!tokenFromStorage || tokenFromStorage === null) {
      return config;
    }

    const hasAuth = config.headers['Authorization'];
    const hasId = config.headers['Id'] || config.headers['id'];
    const hasAuthTokenInSessionStorage = Object.keys(authToken).length;

    if (!hasAuth && !hasId && hasAuthTokenInSessionStorage) {
      config.headers['Authorization'] = `Bearer ${authToken.token}`;
      config.headers['Id'] = `${authToken.corretora || authToken.estipulante}`;
      config.headers['Content-Type'] = 'application/json';
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

export default http;
