import React from "react";
import { useMemo } from "react";
import InputStandard from "components/Input/InputStandard";
import { DadosImcAdicionaisWrapper } from "./styles-DadosImcAdicionais.js";

const DadosImcAdicionais = (
  {
    beneficiarioPage,
    values,
    beneficiariosAdicionais,
    setInputValue,
    setSelectValue,
    camposInclusao
  }) => {

  const beneficiarioAdicional = useMemo(() => {
    return beneficiariosAdicionais[beneficiarioPage]
  }, [beneficiariosAdicionais, beneficiarioPage])


  return (
    <DadosImcAdicionaisWrapper>
      <div className="aba-container">
        <div className="aba-title-container">
          <span>5. Dados de IMC</span>
        </div>
        <div className="aba-input-container">
          <div className="aba-input-row-imc">
            {
              beneficiarioAdicional?.camposInclusao &&
                beneficiarioAdicional?.camposInclusao['peso'] ? (
                <InputStandard
                  value={beneficiarioAdicional['peso']}
                  setValue={setInputValue}
                  label={"Peso (kg)"}
                  name="peso"
                />
              ) : ""
            }

            {
              beneficiarioAdicional?.camposInclusao &&
                beneficiarioAdicional?.camposInclusao['altura'] ? (
                <InputStandard
                  value={beneficiarioAdicional['altura']}
                  setValue={setInputValue}
                  label={"Altura (m)"}
                  name="altura"
                />
              ) : ""
            }

          </div>
        </div>
      </div>
    </DadosImcAdicionaisWrapper>
  )
}

export default DadosImcAdicionais;
