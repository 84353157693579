import styled from "styled-components";
import colors from "styles/colors";

export const CamposInclusaoWrapper = styled.div`
  .input-categories-columns-container {
    display: flex;
    column-gap: 20px;
    margin-bottom: 20px;

    padding-left: 10px;

    .input-categories-checkbox-container {
      margin-top: 20px;
  
      .input-checkbox-row-title {
        font-size: 14px;
        font-family: "Avenir Next GEO W05 Demi";
        display: flex;
        column-gap: 5px;
        align-items: end;
        
        .arrow-icon {
          margin-bottom: 1px;
        }
      }
  
      .input-checkbox-container-row {
        display: flex;
        flex-direction: column;
        margin-top: 20px; 
        column-gap: 30px;
        row-gap: 15px;
        max-width: 570px;
  
        .input-checkbox-container {
          display: flex;
          column-gap: 10px;
          align-items: center;
          svg {
            width: 16px;
          }

          .download_icon {
            color: ${colors.genoaDarkBlue};
            svg{
              width: inherit;
            }

            :hover {
              cursor: pointer;
            }
          }

          .buttons-container {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 9px;
          }

          .check-icon {
            font-size: 9px;
            color: ${colors.luminousGreen};

            :hover {
              cursor: pointer;
            }
          }

          .thrash-icon {
            font-size: 13px;
            color: ${colors.genoaPink};

            :hover {
              cursor: pointer;
            }
          }

          .cross-icon {
            font-size: 9px;
            color: ${colors.genoaPink};

            :hover {
              cursor: pointer;
            }
          }
        }
  
        label {
          font-size: 14px;
          margin-bottom: 2px
          height: 20px;

        }
      }

      .ficha-proposta-paper {
        position: relative;
        top: 40px;
        background-color: white;
        width: 200px;
        height: 110px;
        border-radius: 7px;

        .paper_content {
          padding: 8px 15px;
        }

        .circular_spinner__container {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 94px;

          svg {
            width: inherit;
            color: ${colors.genoaBlue};
          }
        }

        .confirmation_message__container {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 94px;

          @keyframes blink {
            0% {
              opacity: 0.0
            }

            25% {
              opacity: 1.0
            }

            50% {
              opacity: 0
            }

            75% {
              opacity: 1
            }

            
            100% {
              opacity: 0.0
            }
          }

          .blink {
            animation: blink 1s step-end 0s infinite;
          }

          svg {
            width: inherit;
            color: ${colors.luminousGreen};
          }
        }

        .paper_title {
          font-size: 14px;
          font-weight: bold;
          color: ${colors.genoaBlue};
        }

        .input_file__container {
          width: 100%;
        }

        .send_btn__container {
          margin-top: 5px;
          .send_btn {
            font-size: 14px;
            color: ${colors.genoaDarkBlue};
            font-family: "Avenir Next GEO W05 Demi";
          }

          .active {
            :hover {
              cursor: pointer;
              text-decoration: underline;
            }
          }

          .disabled {
            color: ${colors.genoaDisabledGrey};
            :hover {
              cursor: context-menu;
              user-select: none;
              -moz-user-select: none;
              -webkit-user-select: none;
            }
          }
        }
        
      }
    }

    .second-row {
      margin-top: 40px;
    }

    .documentos-adicionais-column {
      
      .input-checkbox-container {
        height: 21.6px;
      }
      
      .documento-adicional-info {
        min-width: 20px;
        min-height: 21.6px;
        max-width: 150px;

        .MuiInputBase-root {
          font-family: "Avenir Next GEO W05 Regular";
          font-size: 14px;
          padding: 0;
          transform: translateY(1px);
        }

        .input-name {
          color: ${colors.genoaGrey};
          border-top: none;
          border-right: none;
          border-left: none;
          border-image: initial;
          outline: none;
          border: none;
          background-color: inherit;
          max-width: 150px;

        }

        #hidden-span {
          margin: 0;
          font-family: 'Avenir Next GEO W05 Regular';
          font-size: 14px;
          color: ${colors.genoaGrey};
          opacity: 0;
          height: 0;
          width: max-content;
          display: block;
        }

        label {
          margin-bottom: 2px;
        }
      }
      
      .input-checkbox-row-title {
        width: 220px;
      }

      .adicionar-documento-row {
        
        button {
          background: none;
          color: inherit;
          border: none;
          padding: 0;
          font: inherit;
          cursor: pointer;
          outline: inherit;

          display: flex;
          align-items: end;
          margin-top: 20px;
          column-gap: 5px;
          font-size: 14px;
          font-family: Avenir Next Geo W05 Demi;
          width: max-content;

          color: ${colors.genoaBlue};

          .plus-icon {
            font-size: 14px;
            margin-bottom: 1px;
          }

          :hover {
          
          }
        }

        
        
      }
    }
  
  }

  .enviar-button-container {
    margin-top: 35px;
    
    span {
      display: flex;
      align-items: baseline;
      column-gap: 3px;
      color: ${colors.genoaDarkBlue};
      font-family: "Avenir Next GEO W05 Demi";
      width: max-content;

      :hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  
`;
