import React from "react";
import { Route, Redirect } from "react-router";
import { useSelector } from "react-redux";


const PrivateRoute = (props) => {
    const user  = useSelector(state => state.userReducers.user)
    const isLogged = !!user.token
    return isLogged ? <Route {...props} /> : <Redirect to="/login" />;
};

export default PrivateRoute;