import { useState } from 'react';
import { DarkBackground } from '../CreateNewBusiness/styles';
import { DrawerContent } from './styles';
import ReactDatePicker from 'react-datepicker';
import { BsCheckLg } from 'react-icons/bs';
import { ClickAwayListener } from '@mui/material';
import toast from 'react-hot-toast';
import { AiOutlineClose } from 'react-icons/ai';
import services from 'apps/broker/services';

const DrawerCancelamentoContrato = ({
  setCancelamentoDrawer,
  isSelected,
  getNegociosData
}) => {
  const [transitionLeave, setTransitionLeave] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [dataCancelamento, setDataCancelamento] = useState(new Date());

  const sendDataCancelamentoHandle = async () => {
    if (dataCancelamento) {
      const body = {
        negocio_id: isSelected.id,
        contrato_id: isSelected.contrato_id,
        vigencia_final: new Date(dataCancelamento)
      };
      const response = await services.business.updateClosedContractByBusinessId(
        body
      );

      if (response.status === 200) {
        toast.dismiss();
        toast.success('Data de cancelamento incluído!');
        setConfirmation(true);
        getNegociosData();
      } else {
        toast.dismiss();
        toast.error('Algo deu errado, tente novamente.');
      }
    } else {
      toast.dismiss();
      toast.error('Inserir data de vigência final');
    }
  };

  const sendCancelamentoNovoCliente = async () => {
    if (isSelected.tipo === 'Novo') {
      const body = {
        negocio_id: isSelected.id
      };

      const response =
        await services.business.createConclusionDateToNewBusinessId(body);
      if (response.status === 200) {
        toast.dismiss();
        toast.success('Data de fechamento incluído!');
        setCancelamentoDrawer(false);
        getNegociosData();
      } else {
        toast.dismiss();
        toast.error('Algo deu errado, tente novamente.');
      }
    }
  };

  return (
    <DarkBackground
      style={{
        animation: transitionLeave ? 'fade-out 0.225s forwards' : ''
      }}
    >
      <ClickAwayListener
        onClickAway={() => {
          setTransitionLeave(true);
          setTimeout(() => {
            setCancelamentoDrawer(false);
          }, 200);
        }}
      >
        <DrawerContent className={transitionLeave ? 'leave' : ''}>
          {isSelected.tipo === 'Renovação' ? (
            <div>
              <div className="title_content">
                <span className="title">Cancelamento Contrato</span>
              </div>
              <div className="cancelamento_container">
                <div>Data de vigência final</div>
                <div className="date_container">
                  <div className="custom_datepicker">
                    <ReactDatePicker
                      className="datepicker"
                      locale="ptBR"
                      minDate={new Date()}
                      selected={dataCancelamento}
                      onChange={(date) => setDataCancelamento(date)}
                      dateFormat="dd/MM/yyyy"
                    />
                  </div>
                  <div className="button_container">
                    <button
                      className="cancel_btn"
                      onClick={() => sendDataCancelamentoHandle()}
                    >
                      Cancelar contrato
                    </button>
                    {confirmation ? (
                      <div className="check_confirm">
                        <BsCheckLg />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="cliente_container">
              <div>
                <span className="title_client">Perdemos o cliente</span>
              </div>
              <div>
                <span>Tem certeza que deseja cancelar?</span>
              </div>
              <div className="button_container_cliente">
                <div className="btn">
                  <button
                    className="check_yes"
                    onClick={() => sendCancelamentoNovoCliente()}
                  >
                    <BsCheckLg />
                  </button>
                  <span>Sim</span>
                </div>

                <div className="btn">
                  <button
                    className="cancel_btn"
                    onClick={() => {
                      setTransitionLeave(true);
                    }}
                  >
                    <AiOutlineClose />
                  </button>
                  <span>Não</span>
                </div>
              </div>
            </div>
          )}
        </DrawerContent>
      </ClickAwayListener>
    </DarkBackground>
  );
};

export default DrawerCancelamentoContrato;
