import { createContext, useContext, useState } from "react";

export const ActivitiesContext = createContext();

const HOME_DEFAULT_ACTIVITIES = {
  redirected: false,
  venc: 'vencidas',
  corretor: undefined,
  data_inicial: '',
  data_final: ''
}

export const ActivitiesContextProvider = ({ children }) => {
  const [activitiesFilters, setActivitiesFilters] = useState(HOME_DEFAULT_ACTIVITIES);

  const updateActivitiesFilters = (data) => {
    setActivitiesFilters({
      ...activitiesFilters,
      ...data
    });
  }

  const resetActivitiesFilters = () => {
    setActivitiesFilters(HOME_DEFAULT_ACTIVITIES);
  }

  return (
    <ActivitiesContext.Provider value={{ activitiesFilters, updateActivitiesFilters, resetActivitiesFilters }}>
      {children}
    </ActivitiesContext.Provider>
  );
};

export const useActivitiesFilters = () => useContext(ActivitiesContext);
