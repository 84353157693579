import React, { useState } from 'react';
import {
  Wrapper,
  ModalArchiveTicket,
  ModalReopenTicket
} from './styles-ticketsContent';
// import { MdTraffic } from "react-icons/md";
import { FaAngleDoubleRight } from 'react-icons/fa';
import { BiFileBlank } from 'react-icons/bi';
import { dataDiff } from 'utils/calculateFunctions';
// import DatePicker, { registerLocale } from "react-datepicker";
// import { Controller } from "react-hook-form";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useForm } from 'react-hook-form';
import parse from 'html-react-parser';
import moment from 'moment-timezone';
import { Dialog, CircularProgress } from '@material-ui/core';
import TextEditor from 'components/TextEditor';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import api from 'services/api';
import services from 'apps/hr/services';
import { useUser } from 'contexts/user';

const DrawerTicketMovimentacao = ({ data, getDataAll, onClose }) => {
  const { user } = useUser();
  // const [data, setData] = React.useState(props.data)
  const [ticket, setTicket] = React.useState({});
  const [details, setDetails] = React.useState([]);
  // const [messages, setMessages] = React.useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [files, setFiles] = useState([]);
  const [openEditor, setOpenEditor] = React.useState(false);
  const [planos, setPlanos] = React.useState([]);

  // const [open, setOpen] = React.useState(false);
  const [confirm, setConfirm] = React.useState(false);
  const [reopen, setReopen] = React.useState(false);
  // const [openEdit, setOpenEdit] = React.useState(false);
  const [status, setStatus] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const handleClickConfirm = () => {
    setConfirm(true);
  };

  const handleCloseConfirm = () => {
    setConfirm(false);
    onClose(false);
  };

  const handleReopen = () => {
    setReopen(true);
  };

  const handleCloseReopen = () => {
    setReopen(false);
  };

  const { register, handleSubmit } = useForm();

  function getData() {
    setLoading(true);
    api
      .get('/corretor/ticket', {
        headers: {
          id: data.id_ticket,
          authorization: `Bearer ${user.token}`
        }
      })
      .then((resp) => {
        setTicket(resp.data.ticket);
        setDetails(resp.data.beneficiarios);
        getPlanos(resp.data.beneficiarios[0].contrato_id);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  // const handleEdit = (e) => {
  //   e.preventDefault();
  //   setOpenEdit(!openEdit);
  // };

  const beneficiarioType = {
    Titular: (titular) => formatTitular(titular),
    Dependente: (dependente) => formatDependentes(dependente)
  };

  const arrayStatus = ['Aguardando Corretora', 'Aguardando corretora'];

  function formatTitular(titular) {
    return (
      <div className="details">
        <h3>Titular</h3>
        <p>Nome : {titular.nome}</p>
        <p>
          Data de contratação :{' '}
          {moment(titular.dados.data_contratacao).utc().format('DD/MM/YYYY')}
        </p>
        <p>
          Endereço: {titular.dados.logradouro}, {titular.dados.numero} -{' '}
          {titular.dados.complemento} - {titular.dados.cep}
        </p>
        <p>
          Data de nascimento:{' '}
          {moment(titular.dados.data_nascimento).utc().format('DD/MM/YYYY')}
        </p>
        <p>CPF: {titular.dados.cpf}</p>
        <p>
          RG: {titular.dados.rg} {titular.dados.orgao_emissor}{' '}
          {moment(titular.dados.data_emissao).utc().format('DD/MM/YYYY')}
        </p>
        <p>Telefone: {titular.dados.telefone}</p>
        <p>Sexo: {titular.dados.sexo}</p>
        <p>Estado Civil: {titular.dados.estado_civil}</p>
        <p>PIS/PASEP: {titular.dados.pis_pasep}</p>
        <p>Nome da mãe: {titular.dados.nome_mae}</p>
        <p>
          Dados bancarios: {titular.dados.banco} {titular.dados.agencia}{' '}
          {titular.dados.conta}
        </p>
        {titular.documentos.map((documento, index) => {
          return (
            <p>
              <a href={documento.file_link}>{documento.file_type}</a>
            </p>
          );
        })}
        {titular.contratos.map((contratos, index) => {
          return (
            <p>
              {contratos.nome_produto} - {contratos.tipo_plano}
            </p>
          );
        })}
        {arrayStatus.includes(ticket.descricao) && (
          <div className="inputs">
            {titular.contratos.map((contrato, index) => {
              if (contrato.search === 'Saúde') {
                return (
                  <>
                    <div className="plano">Plano de Saúde</div>
                    <div className="input-group">
                      <label>
                        Número de Carteirinha - {contrato.tipo_plano}
                      </label>
                      <input
                        type="hidden"
                        {...register(`${titular.uuid}.beneficiario_id`, {
                          required: false,
                          value: titular.beneficiario_id
                        })}
                      ></input>
                      <input
                        type="hidden"
                        {...register(`${titular.uuid}.contratoSaude`, {
                          required: false,
                          value: contrato.contrato_id
                        })}
                      ></input>
                      <input
                        maxLength={20}
                        {...register(`${titular.uuid}.carteiraSaude`, {
                          required: false
                        })}
                      ></input>
                      <label>Data de entrada no plano</label>
                      <input
                        type="date"
                        {...register(`${titular.uuid}.data_entrada`, {
                          required: false
                        })}
                      ></input>
                      {!contrato.tipo_plano && (
                        <>
                          <label>Escolha o plano</label>
                          <select
                            {...register(`${titular.uuid}.planoEscolhido`, {
                              required: true
                            })}
                          >
                            <option value="">Selecione</option>
                            {planos.map((element, index) => {
                              return (
                                <option value={element.tipo}>
                                  {element.tipo}
                                </option>
                              );
                            })}
                          </select>
                        </>
                      )}
                    </div>
                  </>
                );
              } else if (contrato.search === 'Odonto') {
                return (
                  <>
                    <div className="plano">Plano Odontológico</div>
                    <div className="input-group">
                      <label>Número de Carteirinha</label>
                      <input
                        type="hidden"
                        {...register(`${titular.uuid}.beneficiario_id`, {
                          required: false,
                          value: titular.beneficiario_id
                        })}
                      ></input>
                      <input
                        type="hidden"
                        {...register(`${titular.uuid}.contratoOdonto`, {
                          required: false,
                          value: contrato.contrato_id
                        })}
                      ></input>
                      <input
                        maxLength={20}
                        {...register(`${titular.uuid}.carteiraOdonto`, {
                          required: false
                        })}
                      ></input>
                      <label>Data de entrada no plano</label>
                      <input
                        type="date"
                        {...register(`${titular.uuid}.dataEntrada`, {
                          required: false
                        })}
                      ></input>
                      {!contrato.tipo_plano && (
                        <>
                          <label>Escolha o plano</label>
                          <select
                            {...register(`${titular.uuid}.planoEscolhido`, {
                              required: true
                            })}
                          >
                            <option value="">Selecione</option>
                            {planos.map((element, index) => {
                              return (
                                <option value={element.tipo}>
                                  {element.tipo}
                                </option>
                              );
                            })}
                          </select>
                        </>
                      )}
                    </div>
                  </>
                );
              }
              return '';
            })}
          </div>
        )}
      </div>
    );
  }

  function formatDependentes(dependentes) {
    return (
      <div className="details">
        <h3>Dependente</h3>
        <p>Nome : {dependentes.nome}</p>
        <p>
          Data de nascimento:{' '}
          {moment(dependentes.dados.data_nascimento).utc().format('DD/MM/YYYY')}
        </p>
        <p>CPF: {dependentes.dados.cpf}</p>
        <p>
          RG: {dependentes.dados.rg} {dependentes.dados.orgao_emissor}{' '}
          {moment(dependentes.dados.data_emissao).utc().format('DD/MM/YYYY')}
        </p>

        <p>
          <a href={dependentes.dados.file_dependente}>Documento</a>
        </p>

        {dependentes.contratos.map((contratos, index) => {
          return (
            <p>
              {contratos.nome_produto} - {contratos.tipo_plano}
            </p>
          );
        })}

        {ticket.descricao === 'Aguardando corretora' ? (
          <div className="inputs">
            {dependentes.contratos.map((contrato, index) => {
              if (contrato.search === 'Saúde') {
                return (
                  <>
                    <div className="plano">Plano de Saúde</div>
                    <div className="input-group">
                      <label>Número de Carteirinha</label>

                      <input
                        type="hidden"
                        {...register(`${dependentes.uuid}.beneficiario_id`, {
                          required: false,
                          value: dependentes.beneficiario_id
                        })}
                      ></input>

                      <input
                        type="hidden"
                        {...register(`${dependentes.uuid}.contratoSaude`, {
                          required: false,
                          value: contrato.contrato_id
                        })}
                      ></input>

                      <input
                        maxLength={20}
                        {...register(`${dependentes.uuid}.carteiraSaude`, {
                          required: false
                        })}
                      ></input>
                      <label>Data de entrada no plano</label>
                      <input
                        type="date"
                        {...register(`${dependentes.uuid}.data_entrada`, {
                          required: false
                        })}
                      ></input>
                      {!contrato.tipo_plano && (
                        <>
                          <label>Escolha o plano</label>
                          <select
                            {...register(`${dependentes.uuid}.planoEscolhido`, {
                              required: true
                            })}
                            onClick={getPlanos(details.contrato_id)}
                          >
                            <option value="">Selecione</option>
                            {planos.map((element, index) => {
                              return (
                                <option value={element.tipo}>
                                  {element.tipo}
                                </option>
                              );
                            })}
                          </select>
                        </>
                      )}
                    </div>
                  </>
                );
              } else if (contrato.search === 'Odonto') {
                return (
                  <>
                    <div className="plano">Plano Odontológico</div>
                    <div className="input-group">
                      <label>
                        Número de Carteirinha - {contrato.tipo_plano}
                      </label>
                      <input
                        type="hidden"
                        {...register(`${dependentes.uuid}.beneficiario_id`, {
                          required: false,
                          value: dependentes.beneficiario_id
                        })}
                      ></input>

                      <input
                        type="hidden"
                        {...register(`${dependentes.uuid}.contratoOdonto`, {
                          required: false,
                          value: contrato.contrato_id
                        })}
                      ></input>
                      <input
                        maxLength={20}
                        {...register(`${dependentes.uuid}.carteiraOdonto`, {
                          required: false
                        })}
                      ></input>
                      <label>Data de entrada no plano</label>
                      <input
                        type="date"
                        {...register(`${dependentes.uuid}.dataEntrada`, {
                          required: false
                        })}
                      ></input>
                      {!contrato.tipo_plano && (
                        <>
                          <label>Escolha o plano</label>
                          <select
                            {...register(`${dependentes.uuid}.planoEscolhido`, {
                              required: true
                            })}
                          >
                            <option value="">Selecione</option>
                            {planos.map((element, index) => {
                              return (
                                <option value={element.tipo}>
                                  {element.tipo}
                                </option>
                              );
                            })}
                          </select>
                        </>
                      )}
                    </div>
                  </>
                );
              }

              return <p>Teste</p>;
            })}
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }

  const aprovar = (data) => {
    // console.log(data)
    const headers = {
      ticket: ticket.id_ticket,
      corretora: user.corretora,
      authorization: `Bearer ${user.token}`
    };
    api
      .post('/corretor/aceita-movimentacao', data, { headers: headers })
      .then((resp) => {
        getData();
        getDataAll();
      })
      .catch((err) => console.log(err));
  };

  function getPlanos(contratoId) {
    api
      .get('/corretor/planos', {
        headers: {
          contrato: contratoId,
          authorization: `Bearer ${user.token}`
        }
      })
      .then((resp) => {
        setPlanos(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // const editStatus = (e) => {
  //   e.preventDefault();
  //   const body = {
  //     ticket: data.id_ticket,
  //     status: status,
  //   };
  //   const headers = {
  //     // id: user.corretora, BackEnd não pede id
  //     authorization: `Bearer ${user.token}`,
  //   };
  //   api
  //     .put("/corretor/ticket/status", body, { headers: headers })
  //     .then((resp) => {
  //       getData();
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const archiveTicket = () => {
    api
      .put('/tickets/arquivado', {
        headers: {
          authorization: `Bearer ${user.token}`
        },
        id_ticket: data.id_ticket,
        status_id: 7
      })
      .then((resp) => {
        getData();
        handleCloseConfirm();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const reopenTicket = () => {
    const body = {
      ticket: data.id_ticket,
      status: status
    };
    const headers = {
      authorization: `Bearer ${user.token}`
    };
    api
      .put('/corretor/ticket/reopen', body, { headers: headers })
      .then((resp) => {
        getData();
        handleCloseReopen();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sendMessageRh = async () => {
    const htmlMessage = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );

    const formData = new FormData();
    formData.append('mensagem', htmlMessage);
    files.forEach((file, index) => {
      formData.append(index, file);
    });

    const response = await services.tickets.createMessageByHr(
      formData,
      data,
      user,
      'PendenciaCorretor',
      'pendencia'
    );

    if (response.status === 200) {
      setFiles([]);
      setEditorState(!editorState);
      getData();
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <Wrapper>
      {!loading ? (
        <>
          <header>
            <div className="back">
              <span onClick={() => onClose(false)} className="icon-back">
                <ArrowBackIcon fontSize="inherit" />
              </span>
            </div>
            <div className="title">
              <h2>Detalhes do Ticket</h2>
              <span className="date">
                Criado em {moment(ticket.created_at).utc().format('DD/MM/YYYY')}{' '}
                ({dataDiff(ticket.created_at)} dias)
              </span>
            </div>
          </header>
          <section className="infos">
            {/* <div className="open">
          Ticket aberto por {ticket.nome + " " + ticket.sobrenome}
        </div> */}
            <div className="status">
              <div className="text-icon">
                <FaAngleDoubleRight /> Status: {ticket.descricao}{' '}
                {/* {ticket.descricao ===
              "Aguardando Corretora" ||
              ticket.descricao === "Aguardando RH" ? (
              <span className="btn-edit">
                <FaPen onClick={handleEdit} />
              </span>
            ) : null} */}
              </div>
              {/* <div  className="text-icon"> <MdTraffic />Prioridade: <span className="red">{ticket.prioridade}</span></div> */}
            </div>
          </section>
          <section>
            <div className="titleTicket">
              {ticket.assunto} - #{ticket.id_ticket}
            </div>
            <div className="titleTicket">
              Estipulante: {data.razaoSocialEstipulante}
              <br />
              Gestor Rh: {ticket.nome_criador}
            </div>
            {details.map((beneficiario, index) => {
              return beneficiarioType[beneficiario.tipo](beneficiario);
            })}
            {ticket.arquivos && ticket.arquivos.length > 0 && (
              <div className="files">
                {ticket.arquivos.map((file, index) => {
                  return (
                    <div key={index} className="file-item">
                      <a href={file.file}>
                        {' '}
                        <BiFileBlank /> <span>{file.file_name}</span>
                      </a>
                    </div>
                  );
                })}
              </div>
            )}

            {/* {ticket.status_id !== 3 &&
          <div className="inputs">
            <div className="input-date-area">
              <label className="default">Selecione a data a partir do qual o colaborador não terá mais cobertura no plano.</label>
              {errors.dataExclusao && <span className="errorsMsg">Campo obrigatório</span>}
              <Controller
                name="dataExclusao"
                control={control}
                //defaultValue={new Date()}
                rules={{ required: true }}
                render={({ field: { onChange, value, } }) => <DatePicker
                  className="input-data"
                  selected={value}
                  locale="ptBR"
                  onChange={onChange}
                  placeholderText="Selecione um data"
                  dateFormat="dd/MM/yyyy"
                />}
              />
            </div>
          </div>} */}

            <div className="btn-area">
              <div className="buttons">
                {ticket.status_id === 3 || ticket.status_id === 7 ? null : (
                  <button className="send-btn" onClick={handleSubmit(aprovar)}>
                    Aprovar
                  </button>
                )}

                {ticket.status_id === 3 || ticket.status_id === 7 ? null : (
                  <button
                    className="open-btn btn-movimentacao"
                    onClick={() => setOpenEditor(!openEditor)}
                  >
                    Retornar pêndencias
                  </button>
                )}

                {ticket.status_id === 3 || ticket.status_id === 7 ? null : (
                  <button
                    className="btn-archived-mov"
                    onClick={handleClickConfirm}
                  >
                    Arquivar ticket
                  </button>
                )}

                {ticket.status_id === 7 ? (
                  <button className="btn-reopen-mov" onClick={handleReopen}>
                    Reabrir ticket
                  </button>
                ) : null}
              </div>
            </div>

            {ticket.messages
              ? ticket.messages.map((message, index) => {
                  return (
                    <div className="messageUserAlt" key={index}>
                      <div className="user-date">
                        {message.corretora_id
                          ? message.nome
                          : message.nome + ' ' + message.sobrenome}{' '}
                        em{' '}
                        {moment(message.created_at).utc().format('DD/MM/YYYY')}
                      </div>
                      {message.corretora_id ? (
                        <div className="messageText  resp-msg">
                          {
                            <div>
                              {message.files
                                ? message.files.map((file) => (
                                    <div key={index}>
                                      <a
                                        href={file.attachment_url}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {
                                          <h3>
                                            {file.name
                                              ? file.name
                                              : file.file_name}
                                          </h3>
                                        }
                                      </a>
                                    </div>
                                  ))
                                : message.file.map((file) => (
                                    <div key={index}>
                                      <a
                                        href={file.attachment_url}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {
                                          <h3>
                                            {file.name
                                              ? file.name
                                              : file.file_name}
                                          </h3>
                                        }
                                      </a>
                                    </div>
                                  ))}
                            </div>
                          }
                          {parse(message.mensagem)}
                        </div>
                      ) : (
                        <div className="messageText  open-msg">
                          <div>
                            {message.files?.map((file, index) => {
                              return (
                                <div className="messageFile">
                                  <a
                                    href={file.attachment_url}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {file.name ? file.name : file.file_name}
                                  </a>
                                </div>
                              );
                            })}
                          </div>

                          {parse(message.mensagem)}
                        </div>
                      )}
                    </div>
                  );
                })
              : null}
            {openEditor && (
              <TextEditor
                handleSendMessage={sendMessageRh}
                editorState={editorState}
                setEditorState={setEditorState}
                files={files}
                setFiles={setFiles}
              />
            )}
          </section>
        </>
      ) : (
        <div className="loading">
          <CircularProgress /> <p>Carregando...</p>
        </div>
      )}

      <Dialog
        open={confirm}
        onClose={handleCloseConfirm}
        aria-labelledby="alert-dialog-title2"
        aria-describedby="alert-dialog-description2"
      >
        <ModalArchiveTicket>
          <div className="title-archive">
            <h3>Confirma o arquivamento do ticket?</h3>
          </div>
          <div className="content2"></div>
          <div className="actions">
            <div className="buttons2">
              <button className="btn btn-confirm2" onClick={archiveTicket}>
                Confirmar
              </button>
              <button className="btn btn-canlcel2" onClick={handleCloseConfirm}>
                Cancelar
              </button>
            </div>
          </div>
        </ModalArchiveTicket>
      </Dialog>

      <Dialog
        open={reopen}
        onClose={handleCloseReopen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ModalReopenTicket>
          <div className="title">
            <h3>Confirma a reabertura do ticket?</h3>
          </div>
          <div className="form-edit">
            <select
              value={status}
              onChange={({ target }) => setStatus(target.value)}
            >
              <option value="" selected disabled>
                Selecione um status
              </option>
              <option value={4}>Aguardando RH</option>
              <option value={1}>Aguardando corretora</option>
            </select>
          </div>
          <div className="actions">
            <div className="buttons2">
              <button className="btn btn-confirm2" onClick={reopenTicket}>
                Confirmar
              </button>
              <button className="btn btn-canlcel2" onClick={handleCloseReopen}>
                Cancelar
              </button>
            </div>
          </div>
        </ModalReopenTicket>
      </Dialog>

      {/* <Dialog
        open={openEdit}
        onClose={handleEdit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ModalEditTicket>
          <div className="title">
            <h3>Alterar o status do ticket</h3>
          </div>
          <form onSubmit={(e) => editStatus(e)}>
            <div className="form-edit">
              <label>Selecione o status do ticket</label>
              <select
                value={status}
                onChange={({ target }) => setStatus(target.value)}
              >
                <option
                  value={1}
                  selected
                >
                  Aguardando Corretora
                </option>
                <option value={4}>
                  Aguardando RH
                </option>
              </select>
            </div>
            <div className="actions">
              <div className="buttons">
                <button className="btn btn-confirm" type="submit">
                  Confirmar
                </button>
                <button
                  className="btn btn-canlcel"
                  onClick={(e) => handleEdit(e)}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </form>
        </ModalEditTicket>
      </Dialog> */}
    </Wrapper>
  );
};

export default DrawerTicketMovimentacao;
