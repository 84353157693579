import styled from "styled-components";
import colors from "styles/colors";

export const ListComponent = styled.div`

  transform: translateY(10px) translateX(10px);

  .lista {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    background-color: ${colors.genoaBackgroundGrey};
    border-radius: 6px;
    
    height: 300px;
    max-height: 300px;
    width: 700px;
    
    font-family: Avenir Next GEO W05 Regular;
    font-size: 0.95em;
    list-style: none;
    padding: 10px;
    
    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${colors.genoaLightBackgroundGrey};
    }

    .item_lista {
      span {
        padding-right: 10px;
      }

      .btn_lista {
        background: none;
        border: none;
        font-family: Avenir Next GEO W05 Demi;
        color: ${colors.rhBlue};

        :hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .alerta_concluido {
      background-color: #00B200;
      font-size: 0.95em;
      font-family: Avenir Next GEO W05 Demi;
      border-radius: 8px;
      padding: 5px;
      width: 100px;
    }
  }
`