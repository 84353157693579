import styled from 'styled-components';
import colors from 'styles/colors';


export const PopUpContent = styled.div`
  display: grid;
  grid-template-columns: 13px 1fr;

  position: fixed;
  transform: translateY(-250px) translateX(360px);

  background: ${colors.white};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  padding: 0px;
  height: 600px;
  width: 800px;

  .content {
    display: grid;
    grid-template-rows: 70px 1fr 60px;
  }

  .container_header {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .title_name {
    font-family: Avenir Next GEO W05 Heavy;
    font-size: 1.1em;
    color: ${colors.rhBlue};
    padding: 15px;
  }

  .close-container {
    display: flex;

    position: fixed;
    transform: translateY(-6px) translateX(750px);
    

    .close-icon {
      color: ${colors.genoaPink};

      :hover {
        cursor: pointer;
      }
    }
  }

  .coluna_documentos {
    display: flex;
    flex-direction: column;
    
  }

  .checklist_container {
    padding-left: 45px;

    label {
      font-size: 0.9em;
    }
  }

  .content_doc {
    display: flex;
    flex-direction: row;
    column-gap: 15px;
    background-position-y: 10px;

    ::-webkit-scrollbar-thumb {
      background: ${colors.genoaBlue};
    }

    span {
      font-size: 0.9em;
      text-transform: lowercase;
    }
  }

  .close_container_doc {

    .close-icon {
      color: ${colors.genoaPink};
  
      :hover {
        cursor: pointer;
      }
    }
  }

  .container_doc {
    display: flex;
    flex-direction: column;
    text-algn: center;
    column-gap: 10px;
    padding: 40px;
  }
  
  .btn_salvar_container {
    padding-left: 30px;
  }
  
  .btn_salvar {
    text-align: center;
    background-color: ${colors.rhBlue};
    font-family: Avenir Next GEO W05 Demi;
    font-size: 0.95em;
    color: ${colors.white};

    cursor: pointer;
    border: none;
    border-radius: 8px;
    padding: 5px;
    height: 30px;
    width: 60px;
  }
`