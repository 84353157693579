import styled from "styled-components";
import colors from "styles/colors";

export const RelatorioConciergeAsideMenu = styled.div`
  background-color: ${colors.genoaLightBackgroundGrey};

  .end {
    border-right: 1px solid ${colors.rhTabLightGrey};
    height: 100%;
  }

  .menu_aside__option {
    display: flex;
    row-gap: 2px;
    padding-left: 25px;
    padding-right: 15px;
    padding-top: 8px;
    padding-bottom: 10px;
    flex-direction: column;
    justify-content: center;
    height: 50px;
    background-color: ${colors.rhTabLightGrey};
    border-bottom: 1px solid ${colors.rhTabDarkGrey};
    border-right: 1px solid ${colors.rhTabDarkGrey};
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;

    .option__info {
      display: flex;
      align-items: flex-end;
      -webkit-align-items: flex-end;
      column-gap: 2px;
      

      .option__quantity {
        font-family: 'Avenir Next GEO W05 Bold';
        color: ${colors.genoaGrey};
        font-size: 1.4em;
      }

      .option_null {
        color: ${colors.genoaGrey};
        font-size: 1.4em;
        font-family: 'Avenir Next GEO W05 Regular';
      }

      .option__unity {
        font-family: 'Avenir Next GEO W05 Demi';
        color: ${colors.genoaGrey};
        font-size: 0.8em;
        margin-bottom: 0.16em;
      }
    }

    .option__title {
      font-size: 0.65em;
      color: ${colors.subtitleGrey};
      font-family: 'Avenir Next GEO W05 Demi';
      text-transform: uppercase;
    }

    :hover{
      cursor: pointer;
    }
  }

  .selected {
    background-color: white;
    border-left: 3px solid black;
    padding-left: 22.6px;
    padding-right: 16px;
    border-right: none;
  }

`