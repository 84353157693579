import styled from 'styled-components';
import colors from 'styles/colors';

export const CardTasksWrapper = styled.tr`
  .custom_datepicker {
    border: none;
    background-color: inherit;
    width: 80px;
    font-family: Avenir Next Geo W05 Regular;
    font-size: inherit;
  }

  .input_descricao_container {
    display: grid;
    grid-template-columns: 1fr 40px;
    width: 100%;

    button {
      border: none;
      background-color: inherit;

      :hover {
        cursor: pointer;
      }
    }

    .btn_check {
      color: green;
      font-size: 1.5em;
    }

  }

  .input_descricao {
    font-size: inherit;
    font-family: inherit;
    border: none;
    border-bottom: 2px solid ${colors.genoaBlue};
    width: 270px;
  }


  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
    color: rgb(2, 205, 2);
  }

  select {
    font-family: 'Avenir Next GEO W05 Regular';
    appearance: none;
    border-radius: 10px;
    border: none;
    padding: 2px;
    padding-left: 5px;
    cursor: pointer;
    width: 100px;

    :focus-visible {
      outline: 1px ${colors.genoaBlue}f5 auto;
      cursor: pointer;
    }
  }

  .select-arrow {
    background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 9L0.73686 0.75L10.2631 0.75L5.5 9Z' fill='%23456AED'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    
    background-position-x: 96%;
    background-position-y: center;
    background-size: 8px;
  }
`