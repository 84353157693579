import React from "react";
import { useMemo } from "react";
import InputStandard from "components/Input/InputStandard";
import SelectStandard from "apps/broker/components/Select";
import estadosCivis from "utils/arrays/estadosCivis";
import nacionalidades from "utils/arrays/nacionalidades";
import maskCpf from "utils/inputMasks/maskCpf";
import maskData from "utils/inputMasks/maskData";
import { AbaDadosBasicosAdicionaisWrapper } from "./styles-DadosBasicosAdicionais";


const AbaDadosBasicosAdicionais = (
  {
    beneficiarioPage,
    values,
    beneficiariosAdicionais,
    setInputValue,
    setSelectValue,
    camposInclusao,
  }) => {

  const beneficiarioAdicional = useMemo(() => {
    return beneficiariosAdicionais[beneficiarioPage]
  }, [beneficiariosAdicionais, beneficiarioPage])

  const optionsSexo = [
    { option: "Masculino", value: "Masculino" },
    { option: "Feminino", value: "Feminino" },
  ];

  const optionsNacionalidade = nacionalidades.map((item) => ({
    option: item.nome, value: item.nome
  }));

  const optionsVinculo = [
    { option: "Cônjuge", value: "Cônjuge" },
    { option: "Filho(a)", value: "Filho(a)" },
    { option: "Mãe", value: "Mãe" },
    { option: "Pai", value: "Pai" },
    { option: "Sogro(a)", value: "Sogro(a)" },
    { option: "Tutelado(a)", value: "Tutelado(a)" },
    { option: "Outros", value: "Outros" },
  ]

  return (
    <AbaDadosBasicosAdicionaisWrapper>
      <div className="aba-container">
        <div className="aba-title-container">
          <span>1. Dados básicos</span>
        </div>
        <div className="aba-input-container">
          <div className="aba-input-first-row">
            {
              beneficiarioAdicional?.camposInclusao &&
                beneficiarioAdicional?.camposInclusao['nome'] ? (
                <InputStandard
                  value={beneficiarioAdicional['nome']}
                  setValue={setInputValue}
                  label={"Nome"}
                  name="nome"
                />
              ) : ""
            }
            {
              beneficiarioAdicional?.camposInclusao &&
                beneficiarioAdicional?.camposInclusao['data_nascimento'] ? (
                <InputStandard
                  value={beneficiarioAdicional['data_nascimento']}
                  setValue={setInputValue}
                  label={"Data de nascimento"}
                  name="data_nascimento"
                  maskFunction={maskData}
                  maxLength={8}
                  id={beneficiarioPage}
                />
              ) : ""
            }
            {
              beneficiarioAdicional?.camposInclusao &&
                beneficiarioAdicional?.camposInclusao['sexo'] ? (
                <SelectStandard
                  value={beneficiarioAdicional['sexo']}
                  setValue={setSelectValue}
                  label={"Sexo"}
                  name="sexo"
                  options={optionsSexo}
                />
              ) : ""
            }
          </div>

          <div className="aba-input-row-nacionalidade">
            {
              beneficiarioAdicional?.camposInclusao &&
                beneficiarioAdicional?.camposInclusao['cpf'] ? (
                <InputStandard
                  label="CPF"
                  value={beneficiarioAdicional['cpf']}
                  setValue={setInputValue}
                  name="cpf"
                  maskFunction={maskCpf}
                  maxLength={11}
                />
              ) : ""
            }
            {
              beneficiarioAdicional?.camposInclusao &&
                beneficiarioAdicional?.camposInclusao['estado_civil'] ? (
                <SelectStandard
                  value={beneficiarioAdicional['estado_civil']}
                  setValue={setSelectValue}
                  label={"Estado civil"}
                  name="estado_civil"
                  options={estadosCivis}
                />
              ) : ""
            }
            {
              beneficiarioAdicional?.camposInclusao &&
                beneficiarioAdicional?.camposInclusao['nacionalidade'] ? (
                <SelectStandard
                  value={beneficiarioAdicional['nacionalidade']}
                  setValue={setSelectValue}
                  label={"Nacionalidade"}
                  name="nacionalidade"
                  options={optionsNacionalidade}
                />
              ) : ""
            }
          </div>
          <div className="aba-input-row-rg">
            <SelectStandard
              value={beneficiarioAdicional['vinculo']}
              setValue={setSelectValue}
              label={"Vínculo"}
              name="vinculo"
              options={optionsVinculo}
            />
            <InputStandard
              value={beneficiarioAdicional['dataVinculo']}
              setValue={setInputValue}
              label={"Data de Vínculo"}
              name="dataVinculo"
              maskFunction={maskData}
              maxLength={8}
            />
          </div>
          {/* <div className="aba-input-row-rg">
            {
              beneficiarioAdicional?.camposInclusao &&
               beneficiarioAdicional?.camposInclusao['rg'] ? (
                <InputStandard
                  value={beneficiarioAdicional['rg']}
                  setValue={setInputValue}
                  label={"RG"}
                  name="rg"
                  maskFunction={maskRg}
                  maxLength={9}
                />
              ) : ""
            }
            {
              beneficiarioAdicional?.camposInclusao &&
               beneficiarioAdicional?.camposInclusao['orgao_emissor_rg'] ? (
                <InputStandard
                  value={beneficiarioAdicional['orgao_emissao_rg']}
                  setValue={setInputValue}
                  label={"Orgão emissor RG"}
                  name="orgao_emissao_rg"
                />
              ) : ""
            }
            {
              beneficiarioAdicional?.camposInclusao &&
               beneficiarioAdicional?.camposInclusao['data_expedicao_rg'] ? (
                <InputStandard
                  value={beneficiarioAdicional['data_expedicao_rg']}
                  setValue={setInputValue}
                  label={"Data de expedição de RG"}
                  name="data_expedicao_rg"
                  maskFunction={maskData}
                  maxLength={8}
                />
              ) : ""
            }
          </div> */}
          <div className="aba-input-row">
            {
              beneficiarioAdicional?.camposInclusao &&
                beneficiarioAdicional?.camposInclusao['nome_mae'] ? (
                <InputStandard
                  value={beneficiarioAdicional['nome_mae']}
                  setValue={setInputValue}
                  label={"Nome da mãe"}
                  name="nome_mae"
                />
              ) : ""
            }
            {/* {
              beneficiarioAdicional?.camposInclusao &&
               beneficiarioAdicional?.camposInclusao['pis_pasep'] ? (
                <InputStandard
                  value={beneficiarioAdicional['pis_pasep']}
                  setValue={setInputValue}
                  label={"PIS / PASEP"}
                  name="pis_pasep"
                />
              ) : ""
            } */}
          </div>
        </div>
      </div>
    </AbaDadosBasicosAdicionaisWrapper>
  )
}

export default AbaDadosBasicosAdicionais;
