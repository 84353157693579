module.exports = [
  {
    "ordem": 31,
    "nome": "Brasil",
    "sigla2": "BR",
    "sigla3": "BRA",
    "codigo": "076"
  },
  {
    "ordem": 1,
    "nome": "Afeganistão",
    "sigla2": "AF",
    "sigla3": "AFG",
    "codigo": "004"
  },
  {
    "ordem": 2,
    "nome": "África do Sul",
    "sigla2": "ZA",
    "sigla3": "ZAF",
    "codigo": "710"
  },
  {
    "ordem": 3,
    "nome": "Albânia",
    "sigla2": "AL",
    "sigla3": "ALB",
    "codigo": "008"
  },
  {
    "ordem": 4,
    "nome": "Alemanha",
    "sigla2": "DE",
    "sigla3": "DEU",
    "codigo": "276"
  },
  {
    "ordem": 5,
    "nome": "Andorra",
    "sigla2": "AD",
    "sigla3": "AND",
    "codigo": "020"
  },
  {
    "ordem": 6,
    "nome": "Angola",
    "sigla2": "AO",
    "sigla3": "AGO",
    "codigo": "024"
  },
  {
    "ordem": 7,
    "nome": "Anguilla",
    "sigla2": "AI",
    "sigla3": "AIA",
    "codigo": "660"
  },
  {
    "ordem": 8,
    "nome": "Antártida",
    "sigla2": "AQ",
    "sigla3": "ATA",
    "codigo": "010"
  },
  {
    "ordem": 9,
    "nome": "Antígua e Barbuda",
    "sigla2": "AG",
    "sigla3": "ATG",
    "codigo": "028"
  },
  {
    "ordem": 10,
    "nome": "Antilhas Holandesas",
    "sigla2": "AN",
    "sigla3": "ANT",
    "codigo": "530"
  },
  {
    "ordem": 11,
    "nome": "Arábia Saudita",
    "sigla2": "SA",
    "sigla3": "SAU",
    "codigo": "682"
  },
  {
    "ordem": 12,
    "nome": "Argélia",
    "sigla2": "DZ",
    "sigla3": "DZA",
    "codigo": "012"
  },
  {
    "ordem": 13,
    "nome": "Argentina",
    "sigla2": "AR",
    "sigla3": "ARG",
    "codigo": "032"
  },
  {
    "ordem": 14,
    "nome": "Armênia",
    "sigla2": "AM",
    "sigla3": "ARM",
    "codigo": "51"
  },
  {
    "ordem": 15,
    "nome": "Aruba",
    "sigla2": "AW",
    "sigla3": "ABW",
    "codigo": "533"
  },
  {
    "ordem": 16,
    "nome": "Austrália",
    "sigla2": "AU",
    "sigla3": "AUS",
    "codigo": "036"
  },
  {
    "ordem": 17,
    "nome": "Áustria",
    "sigla2": "AT",
    "sigla3": "AUT",
    "codigo": "040"
  },
  {
    "ordem": 18,
    "nome": "Azerbaijão",
    "sigla2": "AZ  ",
    "sigla3": "AZE",
    "codigo": "31"
  },
  {
    "ordem": 19,
    "nome": "Bahamas",
    "sigla2": "BS",
    "sigla3": "BHS",
    "codigo": "044"
  },
  {
    "ordem": 20,
    "nome": "Bahrein",
    "sigla2": "BH",
    "sigla3": "BHR",
    "codigo": "048"
  },
  {
    "ordem": 21,
    "nome": "Bangladesh",
    "sigla2": "BD",
    "sigla3": "BGD",
    "codigo": "050"
  },
  {
    "ordem": 22,
    "nome": "Barbados",
    "sigla2": "BB",
    "sigla3": "BRB",
    "codigo": "052"
  },
  {
    "ordem": 23,
    "nome": "Belarus",
    "sigla2": "BY",
    "sigla3": "BLR",
    "codigo": "112"
  },
  {
    "ordem": 24,
    "nome": "Bélgica",
    "sigla2": "BE",
    "sigla3": "BEL",
    "codigo": "056"
  },
  {
    "ordem": 25,
    "nome": "Belize",
    "sigla2": "BZ",
    "sigla3": "BLZ",
    "codigo": "084"
  },
  {
    "ordem": 26,
    "nome": "Benin",
    "sigla2": "BJ",
    "sigla3": "BEN",
    "codigo": "204"
  },
  {
    "ordem": 27,
    "nome": "Bermudas",
    "sigla2": "BM",
    "sigla3": "BMU",
    "codigo": "060"
  },
  {
    "ordem": 28,
    "nome": "Bolívia",
    "sigla2": "BO",
    "sigla3": "BOL",
    "codigo": "068"
  },
  {
    "ordem": 29,
    "nome": "Bósnia-Herzegóvina",
    "sigla2": "BA",
    "sigla3": "BIH",
    "codigo": "070"
  },
  {
    "ordem": 30,
    "nome": "Botsuana",
    "sigla2": "BW",
    "sigla3": "BWA",
    "codigo": "072"
  },
  {
    "ordem": 32,
    "nome": "Brunei",
    "sigla2": "BN",
    "sigla3": "BRN",
    "codigo": "096"
  },
  {
    "ordem": 33,
    "nome": "Bulgária",
    "sigla2": "BG",
    "sigla3": "BGR",
    "codigo": "100"
  },
  {
    "ordem": 34,
    "nome": "Burkina Fasso",
    "sigla2": "BF",
    "sigla3": "BFA",
    "codigo": "854"
  },
  {
    "ordem": 35,
    "nome": "Burundi",
    "sigla2": "BI",
    "sigla3": "BDI",
    "codigo": "108"
  },
  {
    "ordem": 36,
    "nome": "Butão",
    "sigla2": "BT",
    "sigla3": "BTN",
    "codigo": "064"
  },
  {
    "ordem": 37,
    "nome": "Cabo Verde",
    "sigla2": "CV",
    "sigla3": "CPV",
    "codigo": "132"
  },
  {
    "ordem": 38,
    "nome": "Camarões",
    "sigla2": "CM",
    "sigla3": "CMR",
    "codigo": "120"
  },
  {
    "ordem": 39,
    "nome": "Camboja",
    "sigla2": "KH",
    "sigla3": "KHM",
    "codigo": "116"
  },
  {
    "ordem": 40,
    "nome": "Canadá",
    "sigla2": "CA",
    "sigla3": "CAN",
    "codigo": "124"
  },
  {
    "ordem": 41,
    "nome": "Cazaquistão",
    "sigla2": "KZ",
    "sigla3": "KAZ",
    "codigo": "398"
  },
  {
    "ordem": 42,
    "nome": "Chade",
    "sigla2": "TD",
    "sigla3": "TCD",
    "codigo": "148"
  },
  {
    "ordem": 43,
    "nome": "Chile",
    "sigla2": "CL",
    "sigla3": "CHL",
    "codigo": "152"
  },
  {
    "ordem": 44,
    "nome": "China",
    "sigla2": "CN",
    "sigla3": "CHN",
    "codigo": "156"
  },
  {
    "ordem": 45,
    "nome": "Chipre",
    "sigla2": "CY",
    "sigla3": "CYP",
    "codigo": "196"
  },
  {
    "ordem": 46,
    "nome": "Cingapura",
    "sigla2": "SG",
    "sigla3": "SGP",
    "codigo": "702"
  },
  {
    "ordem": 47,
    "nome": "Colômbia",
    "sigla2": "CO",
    "sigla3": "COL",
    "codigo": "170"
  },
  {
    "ordem": 48,
    "nome": "Congo",
    "sigla2": "CG",
    "sigla3": "COG",
    "codigo": "178"
  },
  {
    "ordem": 49,
    "nome": "Coréia do Norte",
    "sigla2": "KP",
    "sigla3": "PRK",
    "codigo": "408"
  },
  {
    "ordem": 50,
    "nome": "Coréia do Sul",
    "sigla2": "KR",
    "sigla3": "KOR",
    "codigo": "410"
  },
  {
    "ordem": 51,
    "nome": "Costa do Marfim",
    "sigla2": "CI",
    "sigla3": "CIV",
    "codigo": "384"
  },
  {
    "ordem": 52,
    "nome": "Costa Rica",
    "sigla2": "CR",
    "sigla3": "CRI",
    "codigo": "188"
  },
  {
    "ordem": 53,
    "nome": "Croácia (Hrvatska)",
    "sigla2": "HR",
    "sigla3": "HRV",
    "codigo": "191"
  },
  {
    "ordem": 54,
    "nome": "Cuba",
    "sigla2": "CU",
    "sigla3": "CUB",
    "codigo": "192"
  },
  {
    "ordem": 55,
    "nome": "Dinamarca",
    "sigla2": "DK",
    "sigla3": "DNK",
    "codigo": "208"
  },
  {
    "ordem": 56,
    "nome": "Djibuti",
    "sigla2": "DJ",
    "sigla3": "DJI",
    "codigo": "262"
  },
  {
    "ordem": 57,
    "nome": "Dominica",
    "sigla2": "DM",
    "sigla3": "DMA",
    "codigo": "212"
  },
  {
    "ordem": 58,
    "nome": "Egito",
    "sigla2": "EG",
    "sigla3": "EGY",
    "codigo": "818"
  },
  {
    "ordem": 59,
    "nome": "El Salvador",
    "sigla2": "SV",
    "sigla3": "SLV",
    "codigo": "222"
  },
  {
    "ordem": 60,
    "nome": "Emirados Árabes Unidos",
    "sigla2": "AE",
    "sigla3": "ARE",
    "codigo": "784"
  },
  {
    "ordem": 61,
    "nome": "Equador",
    "sigla2": "EC",
    "sigla3": "ECU",
    "codigo": "218"
  },
  {
    "ordem": 62,
    "nome": "Eritréia",
    "sigla2": "ER",
    "sigla3": "ERI",
    "codigo": "232"
  },
  {
    "ordem": 63,
    "nome": "Eslováquia",
    "sigla2": "SK",
    "sigla3": "SVK",
    "codigo": "703"
  },
  {
    "ordem": 64,
    "nome": "Eslovênia",
    "sigla2": "SI",
    "sigla3": "SVN",
    "codigo": "705"
  },
  {
    "ordem": 65,
    "nome": "Espanha",
    "sigla2": "ES",
    "sigla3": "ESP",
    "codigo": "724"
  },
  {
    "ordem": 66,
    "nome": "Estados Unidos",
    "sigla2": "US",
    "sigla3": "USA",
    "codigo": "840"
  },
  {
    "ordem": 67,
    "nome": "Estônia",
    "sigla2": "EE",
    "sigla3": "EST",
    "codigo": "233"
  },
  {
    "ordem": 68,
    "nome": "Etiópia",
    "sigla2": "ET",
    "sigla3": "ETH",
    "codigo": "231"
  },
  {
    "ordem": 69,
    "nome": "Fiji",
    "sigla2": "FJ",
    "sigla3": "FJI",
    "codigo": "242"
  },
  {
    "ordem": 70,
    "nome": "Filipinas",
    "sigla2": "PH",
    "sigla3": "PHL",
    "codigo": "608"
  },
  {
    "ordem": 71,
    "nome": "Finlândia",
    "sigla2": "FI",
    "sigla3": "FIN",
    "codigo": "246"
  },
  {
    "ordem": 72,
    "nome": "França",
    "sigla2": "FR",
    "sigla3": "FRA",
    "codigo": "250"
  },
  {
    "ordem": 73,
    "nome": "Gabão",
    "sigla2": "GA",
    "sigla3": "GAB",
    "codigo": "266"
  },
  {
    "ordem": 74,
    "nome": "Gâmbia",
    "sigla2": "GM",
    "sigla3": "GMB",
    "codigo": "270"
  },
  {
    "ordem": 75,
    "nome": "Gana",
    "sigla2": "GH",
    "sigla3": "GHA",
    "codigo": "288"
  },
  {
    "ordem": 76,
    "nome": "Geórgia",
    "sigla2": "GE",
    "sigla3": "GEO",
    "codigo": "268"
  },
  {
    "ordem": 77,
    "nome": "Gibraltar",
    "sigla2": "GI",
    "sigla3": "GIB",
    "codigo": "292"
  },
  {
    "ordem": 78,
    "nome": "Grã-Bretanha (Reino Unido, UK)",
    "sigla2": "GB",
    "sigla3": "GBR",
    "codigo": "826"
  },
  {
    "ordem": 79,
    "nome": "Granada",
    "sigla2": "GD",
    "sigla3": "GRD",
    "codigo": "308"
  },
  {
    "ordem": 80,
    "nome": "Grécia",
    "sigla2": "GR",
    "sigla3": "GRC",
    "codigo": "300"
  },
  {
    "ordem": 81,
    "nome": "Groelândia",
    "sigla2": "GL",
    "sigla3": "GRL",
    "codigo": "304"
  },
  {
    "ordem": 82,
    "nome": "Guadalupe",
    "sigla2": "GP",
    "sigla3": "GLP",
    "codigo": "312"
  },
  {
    "ordem": 83,
    "nome": "Guam (Território dos Estados Unidos)",
    "sigla2": "GU",
    "sigla3": "GUM",
    "codigo": "316"
  },
  {
    "ordem": 84,
    "nome": "Guatemala",
    "sigla2": "GT",
    "sigla3": "GTM",
    "codigo": "320"
  },
  {
    "ordem": 85,
    "nome": "Guernsey",
    "sigla2": "G",
    "sigla3": "GGY",
    "codigo": "832"
  },
  {
    "ordem": 86,
    "nome": "Guiana",
    "sigla2": "GY",
    "sigla3": "GUY",
    "codigo": "328"
  },
  {
    "ordem": 87,
    "nome": "Guiana Francesa",
    "sigla2": "GF",
    "sigla3": "GUF",
    "codigo": "254"
  },
  {
    "ordem": 88,
    "nome": "Guiné",
    "sigla2": "GN",
    "sigla3": "GIN",
    "codigo": "324"
  },
  {
    "ordem": 89,
    "nome": "Guiné Equatorial",
    "sigla2": "GQ",
    "sigla3": "GNQ",
    "codigo": "226"
  },
  {
    "ordem": 90,
    "nome": "Guiné-Bissau",
    "sigla2": "GW",
    "sigla3": "GNB",
    "codigo": "624"
  },
  {
    "ordem": 91,
    "nome": "Haiti",
    "sigla2": "HT",
    "sigla3": "HTI",
    "codigo": "332"
  },
  {
    "ordem": 92,
    "nome": "Holanda",
    "sigla2": "NL",
    "sigla3": "NLD",
    "codigo": "528"
  },
  {
    "ordem": 93,
    "nome": "Honduras",
    "sigla2": "HN",
    "sigla3": "HND",
    "codigo": "340"
  },
  {
    "ordem": 94,
    "nome": "Hong Kong",
    "sigla2": "HK",
    "sigla3": "HKG",
    "codigo": "344"
  },
  {
    "ordem": 95,
    "nome": "Hungria",
    "sigla2": "HU",
    "sigla3": "HUN",
    "codigo": "348"
  },
  {
    "ordem": 96,
    "nome": "Iêmen",
    "sigla2": "YE",
    "sigla3": "YEM",
    "codigo": "887"
  },
  {
    "ordem": 97,
    "nome": "Ilha Bouvet (Território da Noruega)",
    "sigla2": "BV",
    "sigla3": "BVT",
    "codigo": "074"
  },
  {
    "ordem": 98,
    "nome": "Ilha do Homem",
    "sigla2": "IM",
    "sigla3": "IMN",
    "codigo": "833"
  },
  {
    "ordem": 99,
    "nome": "Ilha Natal",
    "sigla2": "CX",
    "sigla3": "CXR",
    "codigo": "162"
  },
  {
    "ordem": 100,
    "nome": "Ilha Pitcairn",
    "sigla2": "PN",
    "sigla3": "PCN",
    "codigo": "612"
  },
  {
    "ordem": 101,
    "nome": "Ilha Reunião",
    "sigla2": "RE",
    "sigla3": "REU",
    "codigo": "638"
  },
  {
    "ordem": 102,
    "nome": "Ilhas Aland",
    "sigla2": "AX",
    "sigla3": "ALA",
    "codigo": "248"
  },
  {
    "ordem": 103,
    "nome": "Ilhas Cayman",
    "sigla2": "KY",
    "sigla3": "CYM",
    "codigo": "136"
  },
  {
    "ordem": 104,
    "nome": "Ilhas Cocos",
    "sigla2": "CC",
    "sigla3": "CCK",
    "codigo": "166"
  },
  {
    "ordem": 105,
    "nome": "Ilhas Comores",
    "sigla2": "KM",
    "sigla3": "COM",
    "codigo": "174"
  },
  {
    "ordem": 106,
    "nome": "Ilhas Cook",
    "sigla2": "CK",
    "sigla3": "COK",
    "codigo": "184"
  },
  {
    "ordem": 107,
    "nome": "Ilhas Faroes",
    "sigla2": "FO",
    "sigla3": "FRO",
    "codigo": "234"
  },
  {
    "ordem": 108,
    "nome": "Ilhas Falkland (Malvinas)",
    "sigla2": "FK",
    "sigla3": "FLK",
    "codigo": "238"
  },
  {
    "ordem": 109,
    "nome": "Ilhas Geórgia do Sul e Sandwich do Sul",
    "sigla2": "GS",
    "sigla3": "SGS",
    "codigo": "239"
  },
  {
    "ordem": 110,
    "nome": "Ilhas Heard e McDonald (Território da Austrália)",
    "sigla2": "HM",
    "sigla3": "HMD",
    "codigo": "334"
  },
  {
    "ordem": 111,
    "nome": "Ilhas Marianas do Norte",
    "sigla2": "MP",
    "sigla3": "MNP",
    "codigo": "580"
  },
  {
    "ordem": 112,
    "nome": "Ilhas Marshall",
    "sigla2": "MH",
    "sigla3": "MHL",
    "codigo": "584"
  },
  {
    "ordem": 113,
    "nome": "Ilhas Menores dos Estados Unidos",
    "sigla2": "UM",
    "sigla3": "UMI",
    "codigo": "581"
  },
  {
    "ordem": 114,
    "nome": "Ilhas Norfolk",
    "sigla2": "NF",
    "sigla3": "NFK",
    "codigo": "574"
  },
  {
    "ordem": 115,
    "nome": "Ilhas Seychelles",
    "sigla2": "SC",
    "sigla3": "SYC",
    "codigo": "690"
  },
  {
    "ordem": 116,
    "nome": "Ilhas Solomão",
    "sigla2": "SB",
    "sigla3": "SLB",
    "codigo": "090"
  },
  {
    "ordem": 117,
    "nome": "Ilhas Svalbard e Jan Mayen",
    "sigla2": "SJ",
    "sigla3": "SJM",
    "codigo": "744"
  },
  {
    "ordem": 118,
    "nome": "Ilhas Tokelau",
    "sigla2": "TK",
    "sigla3": "TKL",
    "codigo": "772"
  },
  {
    "ordem": 119,
    "nome": "Ilhas Turks e Caicos",
    "sigla2": "TC",
    "sigla3": "TCA",
    "codigo": "796"
  },
  {
    "ordem": 120,
    "nome": "Ilhas Virgens (Estados Unidos)",
    "sigla2": "VI",
    "sigla3": "VIR",
    "codigo": "850"
  },
  {
    "ordem": 121,
    "nome": "Ilhas Virgens (Inglaterra)",
    "sigla2": "VG",
    "sigla3": "VGB",
    "codigo": "092"
  },
  {
    "ordem": 122,
    "nome": "Ilhas Wallis e Futuna",
    "sigla2": "WF",
    "sigla3": "WLF",
    "codigo": "876"
  },
  {
    "ordem": 123,
    "nome": "índia",
    "sigla2": "IN",
    "sigla3": "IND",
    "codigo": "356"
  },
  {
    "ordem": 124,
    "nome": "Indonésia",
    "sigla2": "ID",
    "sigla3": "IDN",
    "codigo": "360"
  },
  {
    "ordem": 125,
    "nome": "Irã",
    "sigla2": "IR",
    "sigla3": "IRN",
    "codigo": "364"
  },
  {
    "ordem": 126,
    "nome": "Iraque",
    "sigla2": "IQ",
    "sigla3": "IRQ",
    "codigo": "368"
  },
  {
    "ordem": 127,
    "nome": "Irlanda",
    "sigla2": "IE",
    "sigla3": "IRL",
    "codigo": "372"
  },
  {
    "ordem": 128,
    "nome": "Islândia",
    "sigla2": "IS",
    "sigla3": "ISL",
    "codigo": "352"
  },
  {
    "ordem": 129,
    "nome": "Israel",
    "sigla2": "IL",
    "sigla3": "ISR",
    "codigo": "376"
  },
  {
    "ordem": 130,
    "nome": "Itália",
    "sigla2": "IT",
    "sigla3": "ITA",
    "codigo": "380"
  },
  {
    "ordem": 131,
    "nome": "Jamaica",
    "sigla2": "JM",
    "sigla3": "JAM",
    "codigo": "388"
  },
  {
    "ordem": 132,
    "nome": "Japão",
    "sigla2": "JP",
    "sigla3": "JPN",
    "codigo": "392"
  },
  {
    "ordem": 133,
    "nome": "Jersey",
    "sigla2": "JE",
    "sigla3": "JEY",
    "codigo": "832"
  },
  {
    "ordem": 134,
    "nome": "Jordânia",
    "sigla2": "JO",
    "sigla3": "JOR",
    "codigo": "400"
  },
  {
    "ordem": 135,
    "nome": "Kênia",
    "sigla2": "KE",
    "sigla3": "KEN",
    "codigo": "404"
  },
  {
    "ordem": 136,
    "nome": "Kiribati",
    "sigla2": "KI",
    "sigla3": "KIR",
    "codigo": "296"
  },
  {
    "ordem": 137,
    "nome": "Kuait",
    "sigla2": "KW",
    "sigla3": "KWT",
    "codigo": "414"
  },
  {
    "ordem": 138,
    "nome": "Laos",
    "sigla2": "LA",
    "sigla3": "LAO",
    "codigo": "418"
  },
  {
    "ordem": 139,
    "nome": "Látvia",
    "sigla2": "LV",
    "sigla3": "LVA",
    "codigo": "428"
  },
  {
    "ordem": 140,
    "nome": "Lesoto",
    "sigla2": "LS",
    "sigla3": "LSO",
    "codigo": "426"
  },
  {
    "ordem": 141,
    "nome": "Líbano",
    "sigla2": "LB",
    "sigla3": "LBN",
    "codigo": "422"
  },
  {
    "ordem": 142,
    "nome": "Libéria",
    "sigla2": "LR",
    "sigla3": "LBR",
    "codigo": "430"
  },
  {
    "ordem": 143,
    "nome": "Líbia",
    "sigla2": "LY",
    "sigla3": "LBY",
    "codigo": "434"
  },
  {
    "ordem": 144,
    "nome": "Liechtenstein",
    "sigla2": "LI",
    "sigla3": "LIE",
    "codigo": "438"
  },
  {
    "ordem": 145,
    "nome": "Lituânia",
    "sigla2": "LT",
    "sigla3": "LTU",
    "codigo": "440"
  },
  {
    "ordem": 146,
    "nome": "Luxemburgo",
    "sigla2": "LU",
    "sigla3": "LUX",
    "codigo": "442"
  },
  {
    "ordem": 147,
    "nome": "Macau",
    "sigla2": "MO",
    "sigla3": "MAC",
    "codigo": "446"
  },
  {
    "ordem": 148,
    "nome": "Macedônia (República Yugoslava)",
    "sigla2": "MK",
    "sigla3": "MKD",
    "codigo": "807"
  },
  {
    "ordem": 149,
    "nome": "Madagascar",
    "sigla2": "MG",
    "sigla3": "MDG",
    "codigo": "450"
  },
  {
    "ordem": 150,
    "nome": "Malásia",
    "sigla2": "MY",
    "sigla3": "MYS",
    "codigo": "458"
  },
  {
    "ordem": 151,
    "nome": "Malaui",
    "sigla2": "MW",
    "sigla3": "MWI",
    "codigo": "454"
  },
  {
    "ordem": 152,
    "nome": "Maldivas",
    "sigla2": "MV",
    "sigla3": "MDV",
    "codigo": "462"
  },
  {
    "ordem": 153,
    "nome": "Mali",
    "sigla2": "ML",
    "sigla3": "MLI",
    "codigo": "466"
  },
  {
    "ordem": 154,
    "nome": "Malta",
    "sigla2": "MT",
    "sigla3": "MLT",
    "codigo": "470"
  },
  {
    "ordem": 155,
    "nome": "Marrocos",
    "sigla2": "MA",
    "sigla3": "MAR",
    "codigo": "504"
  },
  {
    "ordem": 156,
    "nome": "Martinica",
    "sigla2": "MQ",
    "sigla3": "MTQ",
    "codigo": "474"
  },
  {
    "ordem": 157,
    "nome": "Maurício",
    "sigla2": "MU",
    "sigla3": "MUS",
    "codigo": "480"
  },
  {
    "ordem": 158,
    "nome": "Mauritânia",
    "sigla2": "MR",
    "sigla3": "MRT",
    "codigo": "478"
  },
  {
    "ordem": 159,
    "nome": "Mayotte",
    "sigla2": "YT",
    "sigla3": "MYT",
    "codigo": "175"
  },
  {
    "ordem": 160,
    "nome": "México",
    "sigla2": "MX",
    "sigla3": "MEX",
    "codigo": "484"
  },
  {
    "ordem": 161,
    "nome": "Micronésia",
    "sigla2": "FM",
    "sigla3": "FSM",
    "codigo": "583"
  },
  {
    "ordem": 162,
    "nome": "Moçambique",
    "sigla2": "MZ",
    "sigla3": "MOZ",
    "codigo": "508"
  },
  {
    "ordem": 163,
    "nome": "Moldova",
    "sigla2": "MD",
    "sigla3": "MDA",
    "codigo": "498"
  },
  {
    "ordem": 164,
    "nome": "Mônaco",
    "sigla2": "MC",
    "sigla3": "MCO",
    "codigo": "492"
  },
  {
    "ordem": 165,
    "nome": "Mongólia",
    "sigla2": "MN",
    "sigla3": "MNG",
    "codigo": "496"
  },
  {
    "ordem": 166,
    "nome": "Montenegro",
    "sigla2": "ME",
    "sigla3": "MNE",
    "codigo": "499"
  },
  {
    "ordem": 167,
    "nome": "Montserrat",
    "sigla2": "MS",
    "sigla3": "MSR",
    "codigo": "500"
  },
  {
    "ordem": 168,
    "nome": "Myanma",
    "sigla2": "MM",
    "sigla3": "MMR",
    "codigo": "104"
  },
  {
    "ordem": 169,
    "nome": "Namíbia",
    "sigla2": "NA",
    "sigla3": "NAM",
    "codigo": "516"
  },
  {
    "ordem": 170,
    "nome": "Nauru",
    "sigla2": "NR",
    "sigla3": "NRU",
    "codigo": "520"
  },
  {
    "ordem": 171,
    "nome": "Nepal",
    "sigla2": "NP",
    "sigla3": "NPL",
    "codigo": "524"
  },
  {
    "ordem": 172,
    "nome": "Nicarágua",
    "sigla2": "NI",
    "sigla3": "NIC",
    "codigo": "558"
  },
  {
    "ordem": 173,
    "nome": "Níger",
    "sigla2": "NE",
    "sigla3": "NER",
    "codigo": "562"
  },
  {
    "ordem": 174,
    "nome": "Nigéria",
    "sigla2": "NG",
    "sigla3": "NGA",
    "codigo": "566"
  },
  {
    "ordem": 175,
    "nome": "Niue",
    "sigla2": "NU",
    "sigla3": "NIU",
    "codigo": "570"
  },
  {
    "ordem": 176,
    "nome": "Noruega",
    "sigla2": "NO",
    "sigla3": "NOR",
    "codigo": "578"
  },
  {
    "ordem": 177,
    "nome": "Nova Caledônia",
    "sigla2": "NC",
    "sigla3": "NCL",
    "codigo": "540"
  },
  {
    "ordem": 178,
    "nome": "Nova Zelândia",
    "sigla2": "NZ",
    "sigla3": "NZL",
    "codigo": "554"
  },
  {
    "ordem": 179,
    "nome": "Omã",
    "sigla2": "OM",
    "sigla3": "OMN",
    "codigo": "512"
  },
  {
    "ordem": 180,
    "nome": "Palau",
    "sigla2": "PW",
    "sigla3": "PLW",
    "codigo": "585"
  },
  {
    "ordem": 181,
    "nome": "Panamá",
    "sigla2": "PA",
    "sigla3": "PAN",
    "codigo": "591"
  },
  {
    "ordem": 182,
    "nome": "Papua-Nova Guiné",
    "sigla2": "PG",
    "sigla3": "PNG",
    "codigo": "598"
  },
  {
    "ordem": 183,
    "nome": "Paquistão",
    "sigla2": "PK",
    "sigla3": "PAK",
    "codigo": "586"
  },
  {
    "ordem": 184,
    "nome": "Paraguai",
    "sigla2": "PY",
    "sigla3": "PRY",
    "codigo": "600"
  },
  {
    "ordem": 185,
    "nome": "Peru",
    "sigla2": "PE",
    "sigla3": "PER",
    "codigo": "604"
  },
  {
    "ordem": 186,
    "nome": "Polinésia Francesa",
    "sigla2": "PF",
    "sigla3": "PYF",
    "codigo": "258"
  },
  {
    "ordem": 187,
    "nome": "Polônia",
    "sigla2": "PL",
    "sigla3": "POL",
    "codigo": "616"
  },
  {
    "ordem": 188,
    "nome": "Porto Rico",
    "sigla2": "PR",
    "sigla3": "PRI",
    "codigo": "630"
  },
  {
    "ordem": 189,
    "nome": "Portugal",
    "sigla2": "PT",
    "sigla3": "PRT",
    "codigo": "620"
  },
  {
    "ordem": 190,
    "nome": "Qatar",
    "sigla2": "QA",
    "sigla3": "QAT",
    "codigo": "634"
  },
  {
    "ordem": 191,
    "nome": "Quirguistão",
    "sigla2": "KG",
    "sigla3": "KGZ",
    "codigo": "417"
  },
  {
    "ordem": 192,
    "nome": "República Centro-Africana",
    "sigla2": "CF",
    "sigla3": "CAF",
    "codigo": "140"
  },
  {
    "ordem": 193,
    "nome": "República Democrática do Congo",
    "sigla2": "CD",
    "sigla3": "COD",
    "codigo": "180"
  },
  {
    "ordem": 194,
    "nome": "República Dominicana",
    "sigla2": "DO",
    "sigla3": "DOM",
    "codigo": "214"
  },
  {
    "ordem": 195,
    "nome": "República Tcheca",
    "sigla2": "CZ",
    "sigla3": "CZE",
    "codigo": "203"
  },
  {
    "ordem": 196,
    "nome": "Romênia",
    "sigla2": "RO",
    "sigla3": "ROM",
    "codigo": "642"
  },
  {
    "ordem": 197,
    "nome": "Ruanda",
    "sigla2": "RW",
    "sigla3": "RWA",
    "codigo": "646"
  },
  {
    "ordem": 198,
    "nome": "Rússia (antiga URSS) - Federação Russa",
    "sigla2": "RU",
    "sigla3": "RUS",
    "codigo": "643"
  },
  {
    "ordem": 199,
    "nome": "Saara Ocidental",
    "sigla2": "EH",
    "sigla3": "ESH",
    "codigo": "732"
  },
  {
    "ordem": 200,
    "nome": "Saint Vincente e Granadinas",
    "sigla2": "VC",
    "sigla3": "VCT",
    "codigo": "670"
  },
  {
    "ordem": 201,
    "nome": "Samoa Americana",
    "sigla2": "AS",
    "sigla3": "ASM",
    "codigo": "016"
  },
  {
    "ordem": 202,
    "nome": "Samoa Ocidental",
    "sigla2": "WS",
    "sigla3": "WSM",
    "codigo": "882"
  },
  {
    "ordem": 203,
    "nome": "San Marino",
    "sigla2": "SM",
    "sigla3": "SMR",
    "codigo": "674"
  },
  {
    "ordem": 204,
    "nome": "Santa Helena",
    "sigla2": "SH",
    "sigla3": "SHN",
    "codigo": "654"
  },
  {
    "ordem": 205,
    "nome": "Santa Lúcia",
    "sigla2": "LC",
    "sigla3": "LCA",
    "codigo": "662"
  },
  {
    "ordem": 206,
    "nome": "São Bartolomeu",
    "sigla2": "BL",
    "sigla3": "BLM",
    "codigo": "652"
  },
  {
    "ordem": 207,
    "nome": "São Cristóvão e Névis",
    "sigla2": "KN",
    "sigla3": "KNA",
    "codigo": "659"
  },
  {
    "ordem": 208,
    "nome": "São Martim",
    "sigla2": "MF",
    "sigla3": "MAF",
    "codigo": "663"
  },
  {
    "ordem": 209,
    "nome": "São Tomé e Príncipe",
    "sigla2": "ST",
    "sigla3": "STP",
    "codigo": "678"
  },
  {
    "ordem": 210,
    "nome": "Senegal",
    "sigla2": "SN",
    "sigla3": "SEN",
    "codigo": "686"
  },
  {
    "ordem": 211,
    "nome": "Serra Leoa",
    "sigla2": "SL",
    "sigla3": "SLE",
    "codigo": "694"
  },
  {
    "ordem": 212,
    "nome": "Sérvia",
    "sigla2": "RS",
    "sigla3": "SRB",
    "codigo": "688"
  },
  {
    "ordem": 213,
    "nome": "Síria",
    "sigla2": "SY",
    "sigla3": "SYR",
    "codigo": "760"
  },
  {
    "ordem": 214,
    "nome": "Somália",
    "sigla2": "SO",
    "sigla3": "SOM",
    "codigo": "706"
  },
  {
    "ordem": 215,
    "nome": "Sri Lanka",
    "sigla2": "LK",
    "sigla3": "LKA",
    "codigo": "144"
  },
  {
    "ordem": 216,
    "nome": "St. Pierre and Miquelon",
    "sigla2": "PM",
    "sigla3": "SPM",
    "codigo": "666"
  },
  {
    "ordem": 217,
    "nome": "Suazilândia",
    "sigla2": "SZ",
    "sigla3": "SWZ",
    "codigo": "748"
  },
  {
    "ordem": 218,
    "nome": "Sudão",
    "sigla2": "SD",
    "sigla3": "SDN",
    "codigo": "736"
  },
  {
    "ordem": 219,
    "nome": "Suécia",
    "sigla2": "SE",
    "sigla3": "SWE",
    "codigo": "752"
  },
  {
    "ordem": 220,
    "nome": "Suíça",
    "sigla2": "CH",
    "sigla3": "CHE",
    "codigo": "756"
  },
  {
    "ordem": 221,
    "nome": "Suriname",
    "sigla2": "SR",
    "sigla3": "SUR",
    "codigo": "740"
  },
  {
    "ordem": 222,
    "nome": "Tadjiquistão",
    "sigla2": "TJ",
    "sigla3": "TJK",
    "codigo": "762"
  },
  {
    "ordem": 223,
    "nome": "Tailândia",
    "sigla2": "TH",
    "sigla3": "THA",
    "codigo": "764"
  },
  {
    "ordem": 224,
    "nome": "Taiwan",
    "sigla2": "TW",
    "sigla3": "TWN",
    "codigo": "158"
  },
  {
    "ordem": 225,
    "nome": "Tanzânia",
    "sigla2": "TZ",
    "sigla3": "TZA",
    "codigo": "834"
  },
  {
    "ordem": 226,
    "nome": "Território Britânico do Oceano índico",
    "sigla2": "IO",
    "sigla3": "IOT",
    "codigo": "086"
  },
  {
    "ordem": 227,
    "nome": "Territórios do Sul da França",
    "sigla2": "TF",
    "sigla3": "ATF",
    "codigo": "260"
  },
  {
    "ordem": 228,
    "nome": "Territórios Palestinos Ocupados",
    "sigla2": "PS",
    "sigla3": "PSE",
    "codigo": "275"
  },
  {
    "ordem": 229,
    "nome": "Timor Leste",
    "sigla2": "TP",
    "sigla3": "TMP",
    "codigo": "626"
  },
  {
    "ordem": 230,
    "nome": "Togo",
    "sigla2": "TG",
    "sigla3": "TGO",
    "codigo": "768"
  },
  {
    "ordem": 231,
    "nome": "Tonga",
    "sigla2": "TO",
    "sigla3": "TON",
    "codigo": "776"
  },
  {
    "ordem": 232,
    "nome": "Trinidad and Tobago",
    "sigla2": "TT",
    "sigla3": "TTO",
    "codigo": "780"
  },
  {
    "ordem": 233,
    "nome": "Tunísia",
    "sigla2": "TN",
    "sigla3": "TUN",
    "codigo": "788"
  },
  {
    "ordem": 234,
    "nome": "Turcomenistão",
    "sigla2": "TM",
    "sigla3": "TKM",
    "codigo": "795"
  },
  {
    "ordem": 235,
    "nome": "Turquia",
    "sigla2": "TR",
    "sigla3": "TUR",
    "codigo": "792"
  },
  {
    "ordem": 236,
    "nome": "Tuvalu",
    "sigla2": "TV",
    "sigla3": "TUV",
    "codigo": "798"
  },
  {
    "ordem": 237,
    "nome": "Ucrânia",
    "sigla2": "UA",
    "sigla3": "UKR",
    "codigo": "804"
  },
  {
    "ordem": 238,
    "nome": "Uganda",
    "sigla2": "UG",
    "sigla3": "UGA",
    "codigo": "800"
  },
  {
    "ordem": 239,
    "nome": "Uruguai",
    "sigla2": "UY",
    "sigla3": "URY",
    "codigo": "858"
  },
  {
    "ordem": 240,
    "nome": "Uzbequistão",
    "sigla2": "UZ",
    "sigla3": "UZB",
    "codigo": "860"
  },
  {
    "ordem": 241,
    "nome": "Vanuatu",
    "sigla2": "VU",
    "sigla3": "VUT",
    "codigo": "548"
  },
  {
    "ordem": 242,
    "nome": "Vaticano",
    "sigla2": "VA",
    "sigla3": "VAT",
    "codigo": "336"
  },
  {
    "ordem": 243,
    "nome": "Venezuela",
    "sigla2": "VE",
    "sigla3": "VEN",
    "codigo": "862"
  },
  {
    "ordem": 244,
    "nome": "Vietnã",
    "sigla2": "VN",
    "sigla3": "VNM",
    "codigo": "704"
  },
  {
    "ordem": 245,
    "nome": "Zâmbia",
    "sigla2": "ZM",
    "sigla3": "ZMB",
    "codigo": "894"
  },
  {
    "ordem": 246,
    "nome": "Zimbábue",
    "sigla2": "ZW",
    "sigla3": "ZWE",
    "codigo": "716"
  }
]