import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import { FaTooth } from 'react-icons/fa';
import { GiHealthNormal } from 'react-icons/gi';
import { Section } from './styles';

const InclusionMovimentCard = ({
  item,
  nCarteirinha,
  setNCarteirinha,
  selectedDateInclusionPlan,
  setSelectedDateInclusionPlan,
  activeBeneficiaryContract
}) => {
  const renderBeneficiarioExpandedContratoInfoInclusao = (contratos) => {
    const contratosSaude = contratos.filter((item) => item.produto_id === 1);
    const maisRecenteSaude = contratosSaude
      .sort(
        (a, b) =>
          Number(new Date(b.contrato_beneficiario_created_at)) -
          Number(new Date(a.contrato_beneficiario_created_at))
      )
      .shift();

    const contratosOdonto = contratos.filter((item) => item.produto_id === 2);
    const maisRecenteOdonto = contratosOdonto
      .sort(
        (a, b) =>
          Number(new Date(b.contrato_beneficiario_created_at)) -
          Number(new Date(a.contrato_beneficiario_created_at))
      )
      .shift();

    const contratosMaisRecentes = [maisRecenteSaude, maisRecenteOdonto];

    return contratosMaisRecentes.map((contrato) => {
      if (!contrato) {
        return <div></div>;
      }
      return contrato.data_entrada ? (
        <div className="align-left">
          <p className="name-type-contract">
            {contrato.produto_id === 1 ? (
              <GiHealthNormal size={13} className="contrato-icon" />
            ) : (
              <FaTooth size={13} className="contrato-icon" />
            )}
            {contrato?.numero_apolice ? (`${contrato.nome} (${contrato?.numero_apolice})`) : (`${contrato.nome}`)}
          </p>
          <div className="first-column-container">
            <p>
              <span className="plano-title-span">Plano</span>
              {contrato.tipo_plano}
            </p>
          </div>

          <div className="first-column-container">
            <p>
              <span>Data de inclusão no Plano:</span>
            </p>
            <p className="paragraph-info">
              {moment(contrato.data_entrada).format('DD/MM/YYYY')}
            </p>
          </div>

          {contrato.numero_carteirinha ? (
            <div className="first-column-container">
              <p>
                <span>Nº de carteirinha:</span>
              </p>
              <p className="paragraph-info">{contrato.numero_carteirinha}</p>
            </div>
          ) : (
            <div>
              <div className="first-column-container">
                <p>
                  <span>Nº de carteirinha:</span>
                </p>
                <input
                  placeholder="Nº de carteirinha do Beneficiário"
                  selected={nCarteirinha[contrato.id]}
                  className="input-info"
                  onChange={({ target }) => {
                    setNCarteirinha((prev) => ({
                      ...prev,
                      [contrato.id]: target.value
                    }));

                    setSelectedDateInclusionPlan((prev) => ({
                      ...prev,
                      [contrato.id]: contrato.data_entrada
                    }));
                  }}
                />
              </div>
              <button
                type="submit"
                className="send-infos"
                onClick={() => activeBeneficiaryContract(contrato)}
              >
                Adicionar carteirinha
              </button>
            </div>
          )}
        </div>
      ) : (
        <div className="align-left" key={contrato.id}>
          <p className="name-type-contract">
            {contrato.produto_id === 1 ? (
              <GiHealthNormal size={13} className="contrato-icon" />
            ) : (
              <FaTooth size={13} className="contrato-icon" />
            )}
            {contrato?.numero_apolice ? (`${contrato.nome} (${contrato?.numero_apolice})`) : (`${contrato.nome}`)}
          </p>
          <div className="first-column-container">
            <p>
              <span>Data de inclusão no Plano:</span>
            </p>
            <ReactDatePicker
              dateFormat="dd/MM/yyyy"
              placeholderText="Data de inclusão no plano"
              className="input-info"
              selected={selectedDateInclusionPlan[contrato.id] ?? ''}
              onChange={(date) =>
                setSelectedDateInclusionPlan((prev) => ({
                  ...prev,
                  [contrato.id]: date
                }))
              }
            />
          </div>

          <div className="first-column-container">
            <p>
              <span>Nº de carteirinha:</span>
            </p>
            <input
              placeholder="Nº de carteirinha do Beneficiário"
              selected={nCarteirinha[contrato.id]}
              className="input-info"
              onChange={({ target }) =>
                setNCarteirinha((prev) => ({
                  ...prev,
                  [contrato.id]: target.value
                }))
              }
            />
          </div>

          <button
            type="submit"
            className="send-infos"
            onClick={() => activeBeneficiaryContract(contrato)}
          >
            Adicionar Beneficiário
          </button>
        </div>
      );
    });
  };

  return (
    <Section>
      <div className="first-column-container">
        <p>
          <span>CPF do Beneficiário:</span>{' '}
          {item.tipo === 'Titular' ? item.cpfTitular : item.cpfDependente}
        </p>

        {item.tipo === 'Titular' && item.data_vinculo_titular !== null ? (
          <p>
            <span>Data de vínculo:</span>{' '}
            {moment(item.data_vinculo_titular).format('DD/MM/YYYY')}
          </p>
        ) : null}

        {item.tipo !== 'Titular' && item.data_vinculo_dependente !== null ? (
          <p>
            <span>Data de vínculo:</span>{' '}
            {moment(item.data_vinculo_dependente).format('DD/MM/YYYY')}
          </p>
        ) : null}
      </div>

      <div
        className={`infos-inclusion-beneficiary ${
          item.data_exclusao && 'align-left'
        }`}
      >
        <div className="box-show-infos-plans">
          {renderBeneficiarioExpandedContratoInfoInclusao(item?.contratos)}
        </div>
      </div>
    </Section>
  );
};

export default InclusionMovimentCard;
