import './styles.scss';

const CheckboxGroup = ({ text, variant = 'primary', ...rest }) => {
  return (
    <div className="checkbox-group">
      <label
        className={`checkbox-group__label checkbox-group__label--${variant}`}
      >
        <input type="radio" {...rest} />
        {text}
      </label>
    </div>
  );
};

export default CheckboxGroup;
