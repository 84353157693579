import { useState, useEffect } from 'react';
import SideMenuCorretora from 'apps/broker/components/Sidebar';
import Header from 'apps/broker/components/Header';
import ContentTasks from 'apps/broker/pages/Tasks/components/TasksContent';
import { Content, TasksWrapper } from './styles';
import { useTasksFilters } from 'contexts/tasks';
import { useUser } from 'contexts/user';
import api from 'services/api';
import services from 'apps/broker/services';

const Tasks = () => {
  const { user } = useUser();
  const { tasksFilters, resetTasksFilters } = useTasksFilters();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [corretores, setCorretores] = useState([]);
  const [estipulantes, setEstipulantes] = useState([]);
  const [selectEstipulante, setSelectEstipulante] = useState(0);
  const [leads, setLeads] = useState([]);
  const [selectLead, setSelectLead] = useState(0);
  const [estipulanteContratos, setEstipulanteContratos] = useState([]);
  const [tasksInfo, setTasksInfo] = useState([]);
  // Filtro tasks
  const [vencimento, setVencimento] = useState('hoje');
  const [dataInicial, setDataInicial] = useState('');
  const [dataFinal, setDataFinal] = useState('');
  const [status, setStatus] = useState('Aberta');
  const [responsavel, setResponsavel] = useState(undefined);

  const getCorretores = async () => {
    const response = await services.user.getBrokerUsersList();
    setCorretores(response.data);
  };

  const getEstipulantes = async () => {
    const response = await services.policyOwner.getPolicyOwnersList();
    if (response.status === 200) {
      const estipulanteFormatado = response?.data
        ?.map((item) => ({
          option: item.estipulante_razao_social,
          value: item.estipulante_id
        }))
        .sort((a, b) => {
          if (b.option < a.option) {
            return 1;
          }

          return -1;
        });
      setEstipulantes(estipulanteFormatado);
    }
  };

  const getLeads = async () => {
    const response = await services.policyOwner.getPotencialLeads();
    if (response.status === 200) {
      const leadsFormatado = response?.data
        ?.map((item) => ({
          option: item.lead_potencial_razao_social,
          value: item.lead_potencial_id
        }))
        .sort((a, b) => {
          if (b.option < a.option) {
            return 1;
          }

          return -1;
        });
      setLeads(leadsFormatado);
    }
  };

  const getTasksData = async () => {
    const response = await services.tasks.getAll();
    
    if (response.status === 200) {
      setTasksInfo(response.data);

      const { redirected, venc, data_inicial, data_final, status, corretor } =
        tasksFilters;

      if (redirected) {
        setVencimento(venc);
        setResponsavel(corretor);
        setStatus(status);
        setDataInicial(data_inicial);
        setDataFinal(data_final);
      } else if (!redirected) {
        setResponsavel(user.usuario_id);
      }
    }
  };

  const getAllContracts = () => {
    // const response = await services.insuranceCompanies.getListContracts();
    api
      .get('/corretor/lista-contratos', {
        headers: {
          id: selectEstipulante,
          authorization: `Bearer ${user.token}`
        }
      })
      .then((resp) => {
        const contratosFormatados = resp?.data?.map((item) => ({
          option: `${item.tipo_produto} - ${item.seguradora_nome} (${item.numero_apolice})`,
          value: item.contrato_id
        }));
        setEstipulanteContratos(contratosFormatados);
      })
      .catch((err) => {
        throw err;
      });
  };

  useEffect(() => {
    getCorretores();
    getEstipulantes();
    getLeads();
    getTasksData();
  }, []);

  useEffect(() => {
    if (selectEstipulante !== 0) {
      getAllContracts();
    }
  }, [selectEstipulante]);

  return (
    <TasksWrapper>
      <SideMenuCorretora />
      <Content>
        <Header />
        <ContentTasks
          getTasksData={getTasksData}
          setOpenDrawer={setOpenDrawer}
          openDrawer={openDrawer}
          corretores={corretores}
          estipulantes={estipulantes}
          setSelectEstipulante={setSelectEstipulante}
          selectEstipulante={selectEstipulante}
          leads={leads}
          setSelectLead={setSelectLead}
          selectLead={selectLead}
          estipulanteContratos={estipulanteContratos}
          tasksInfo={tasksInfo}
          setVencimento={setVencimento}
          vencimento={vencimento}
          setDataInicial={setDataInicial}
          dataInicial={dataInicial}
          setDataFinal={setDataFinal}
          dataFinal={dataFinal}
          setStatus={setStatus}
          status={status}
          setResponsavel={setResponsavel}
          responsavel={responsavel}
          resetTasksFilters={resetTasksFilters}
        />
      </Content>
    </TasksWrapper>
  );
};

export default Tasks;
