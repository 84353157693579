import React from 'react';
import { Wrapper } from './styles-draweBeneficiarios';
import { usePostHog } from 'posthog-js/react';
import { AiOutlineCreditCard, AiOutlineUser } from 'react-icons/ai';
import { MdFamilyRestroom } from 'react-icons/md';
import { useState } from 'react';
import { RiArrowDownSLine } from 'react-icons/ri';
import CloseIcon from '@material-ui/icons/Close';
import { ImAttachment } from 'react-icons/im';
import { IoCalendar } from 'react-icons/io5';
import { CircularProgress, ClickAwayListener, Popper } from '@material-ui/core';
import AgendamentoPopper from 'apps/hr/components/AgendamentoPopper/AgendamentoPopper';
import { IoMdAlert } from 'react-icons/io';
import { HiTrash } from 'react-icons/hi';
import api from 'services/api';
import { useUser } from 'contexts/user';

const DrawerRemoveBeneficiarios = (props) => {
  const posthog = usePostHog();
  const { user } = useUser();

  const { beneficiario } = props;
  const { dados } = beneficiario;

  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingRemoverAgendamento, setLoadingRemoverAgendamento] =
    useState(false);

  const [errors, setErrors] = useState({
    contratosLength: false,
    filesLength: false,
    serverResponse: false
  });
  const [confirmation, setConfirmation] = useState(false);
  const [confirmationAgendamento, setConfirmationAgendamento] = useState(false);
  const [confirmationRemoveAgendamento, setConfirmationRemoveAgendamento] =
    useState(false);

  const [contratosToRemove, setContratosToRemove] = useState([]);

  const [agendarExclusao, setAgendarExclusao] = useState(false);
  const [dataExclusao, setDataExclusao] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const [hoverDisplayInfo, setHoverDisplayInfo] = useState({
    beneficiario_id: undefined,
    contrato_id: undefined,
    produto_id: undefined,
    numero_carteirinha: undefined
  });

  const removerAgendamentoClickHandler = () => {
    const body = hoverDisplayInfo;

    setLoadingRemoverAgendamento(true);
    api
      .post('/rh/beneficiarios/remover-agendamento', body, {
        headers: {
          authorization: `Bearer ${user.token}`
        }
      })
      .then((resp) => {
        // console.log(resp.status)
        let respAgendamento;
        if (resp.status === 200) {
          respAgendamento = true;
        }

        props.getBeneficiariosAfterUpdate(user?.mainEstipulante).then(() => {
          setLoadingRemoverAgendamento(false);
          if (respAgendamento) {
            setConfirmation(false);
            setConfirmationAgendamento(false);
            setConfirmationRemoveAgendamento(true);
            setTimeout(() => {
              setConfirmationRemoveAgendamento(false);
            }, 3000);
          }
        });
      });
  };

  const toggleContratoOption = (contrato) => {
    // console.log(contrato)
    setContratosToRemove((prevState) => {
      const isContratoToBeRemoved = contratosToRemove.some(
        (item) => item === contrato.contrato_id
      );
      if (isContratoToBeRemoved) {
        return prevState.filter((item) => item !== contrato.contrato_id);
      } else {
        return [...prevState, contrato.contrato_id];
      }
    });
  };

  const setContratoOptionClassName = (contrato) => {
    if (contrato.movimentacao_remocao) {
      return 'contrato-card-option-disabled';
    }
    const isContratoToBeRemoved = contratosToRemove.some(
      (item) => item === contrato.contrato_id
    );
    // console.log(isContratoToBeRemoved)
    if (isContratoToBeRemoved) {
      return 'contrato-card-option-active';
    } else {
      return 'contrato-card-option';
    }
  };

  const deleteFile = (index) => {
    const newArray = files;
    newArray.splice(index, 1);
    if (newArray.length === 0) setFiles([]);
    setFiles([...newArray]);
  };

  const handleFile = (target) => {
    posthog?.capture('Clicou p/ anexar documento');
    if (target.files?.length === 0 || target.files === null) {
      return;
    }
    if (target.files.length > 1) {
      const newFiles = [];
      Array.from(target.files).forEach((file) => {
        newFiles.push(file);
      });
      const oldFiles = files;
      setFiles([...oldFiles, ...newFiles]);
    } else {
      const oldFiles = files;
      setFiles([...oldFiles, target.files[0]]);
    }
  };

  const renderHoverAgendamentoInfo = () => {
    return (
      <div className="agendamento-info-wrapper">
        <div className="top-stripe"></div>
        <div className="display-content">
          <span className="disclaimer">
            Este plano já está com um agendamento de exclusão
          </span>
          <span className="disclaimer">
            Caso seja realizado um novo <b>agendamento</b>, ou pedido de{' '}
            <b>exclusão imediato</b>, o agendamento anterior será removido
          </span>
        </div>
        <div className="remove-agendamento-content">
          <button
            onClick={
              !loadingRemoverAgendamento
                ? removerAgendamentoClickHandler
                : undefined
            }
          >
            Remover agendamento
            {!loadingRemoverAgendamento ? (
              <HiTrash className="trash-icon" />
            ) : (
              <CircularProgress className="loading-spinner" size={14} />
            )}
          </button>
        </div>
      </div>
    );
  };

  const renderBeneficiarioContratosSaude = () => {
    const contratosSaude = beneficiario?.contratos?.filter(
      (item) => item.produto_id === 1
    );

    return (
      <>
        {contratosSaude?.map((item) => {
          if (!item) {
            return '';
          }

          const contratoHasMovimentacaoAgendada =
            beneficiario?.movimentacoes_agendadas?.some(
              (movimentacao) => movimentacao?.contrato_id === item?.contrato_id
            );

          return (
            <div className="contrato-container">
              <div
                className={setContratoOptionClassName(item)}
                onClick={() => {
                  if (!item.movimentacao_remocao) {
                    toggleContratoOption(item);
                  }
                }}
              >
                {item?.numero_apolice ? (
                  <span>{`${item.search} - ${item.nome} (Apólice: ${item?.numero_apolice})`}</span>
                ) : (
                  <span>{`${item.search} - ${item.nome}`}</span>
                )}
              </div>
              {contratoHasMovimentacaoAgendada && (
                <div
                  className="alert-icon-container"
                  onMouseEnter={() => {
                    // console.log("entrou", item)
                    setHoverDisplayInfo({
                      beneficiario_id: item?.beneficiario_id,
                      contrato_id: item?.contrato_id,
                      produto_id: item?.produto_id,
                      numero_carteirinha: item?.numero_carteirinha
                    });
                  }}
                  onMouseLeave={() => {
                    // console.log("saiu")
                    setHoverDisplayInfo({
                      beneficiario_id: undefined,
                      contrato_id: undefined,
                      produto_id: undefined,
                      numero_carteirinha: undefined
                    });
                  }}
                >
                  <IoMdAlert className="alert-icon" />
                  {hoverDisplayInfo?.contrato_id === item?.contrato_id
                    ? renderHoverAgendamentoInfo()
                    : ''}
                </div>
              )}
            </div>
          );
        })}
      </>
    );
  };

  const renderBeneficiarioContratosDental = () => {
    const contratosOdonto = beneficiario?.contratos?.filter(
      (item) => item.produto_id === 2
    );

    return (
      <>
        {contratosOdonto?.map((item) => {
          if (!item) {
            return '';
          }

          const contratoHasMovimentacaoAgendada =
            beneficiario?.movimentacoes_agendadas?.some(
              (movimentacao) => movimentacao?.contrato_id === item?.contrato_id
            );

          return (
            <div className="contrato-container">
              <div
                className={setContratoOptionClassName(item)}
                onClick={() => {
                  if (!item.movimentacao_remocao) {
                    toggleContratoOption(item);
                  }
                }}
              >
                {item?.numero_apolice ? (
                  <span>{`${item.search} - ${item.nome} (Apólice: ${item?.numero_apolice})`}</span>
                ) : (
                  <span>{`${item.search} - ${item.nome}`}</span>
                )}
              </div>
              {contratoHasMovimentacaoAgendada && (
                <div
                  className="alert-icon-container"
                  onMouseEnter={() => {
                    // console.log("entrou", item)
                    setHoverDisplayInfo({
                      beneficiario_id: item?.beneficiario_id,
                      contrato_id: item?.contrato_id,
                      produto_id: item?.produto_id,
                      numero_carteirinha: item?.numero_carteirinha
                    });
                  }}
                  onMouseLeave={() => {
                    // console.log("saiu")
                    setHoverDisplayInfo({
                      beneficiario_id: undefined,
                      contrato_id: undefined,
                      produto_id: undefined,
                      numero_carteirinha: undefined
                    });
                  }}
                >
                  <IoMdAlert className="alert-icon" />
                  {hoverDisplayInfo?.contrato_id === item?.contrato_id
                    ? renderHoverAgendamentoInfo()
                    : ''}
                </div>
              )}
            </div>
          );
        })}
      </>
    );
  };

  // const renderBeneficiarioContratos = () => {
  //   const contratosSaude = beneficiario?.contratos?.filter(
  //     (item) => item.produto_id === 1
  //   );
  //   const maisRecenteSaude = contratosSaude
  //     .sort(
  //       (a, b) =>
  //         Number(new Date(b.contrato_beneficiario_created_at)) -
  //         Number(new Date(a.contrato_beneficiario_created_at))
  //     )
  //     .shift();

  //   const contratosOdonto = beneficiario?.contratos?.filter(
  //     (item) => item.produto_id === 2
  //   );
  //   const maisRecenteOdonto = contratosOdonto
  //     .sort(
  //       (a, b) =>
  //         Number(new Date(b.contrato_beneficiario_created_at)) -
  //         Number(new Date(a.contrato_beneficiario_created_at))
  //     )
  //     .shift();

  //   const contratosMaisRecentes = [maisRecenteSaude, maisRecenteOdonto];

  //   return (
  //     <>
  //       {contratosMaisRecentes?.map((item) => {
  //         if (!item) {
  //           return '';
  //         }

  //         const contratoHasMovimentacaoAgendada =
  //           beneficiario?.movimentacoes_agendadas?.some(
  //             (movimentacao) => movimentacao?.contrato_id === item?.contrato_id
  //           );

  //         return (
  //           <div className="contrato-container">
  //             <div
  //               className={setContratoOptionClassName(item)}
  //               onClick={() => {
  //                 if (!item.movimentacao_remocao) {
  //                   toggleContratoOption(item);
  //                 }
  //               }}
  //             >
  //               {item?.numero_apolice ? (
  //                 <span>{`${item.search} - ${item.nome} (Apólice: ${item?.numero_apolice})`}</span>
  //               ) : (
  //                 <span>{`${item.search} - ${item.nome}`}</span>
  //               )}
  //             </div>
  //             {contratoHasMovimentacaoAgendada && (
  //               <div
  //                 className="alert-icon-container"
  //                 onMouseEnter={() => {
  //                   // console.log("entrou", item)
  //                   setHoverDisplayInfo({
  //                     beneficiario_id: item?.beneficiario_id,
  //                     contrato_id: item?.contrato_id,
  //                     produto_id: item?.produto_id,
  //                     numero_carteirinha: item?.numero_carteirinha
  //                   });
  //                 }}
  //                 onMouseLeave={() => {
  //                   // console.log("saiu")
  //                   setHoverDisplayInfo({
  //                     beneficiario_id: undefined,
  //                     contrato_id: undefined,
  //                     produto_id: undefined,
  //                     numero_carteirinha: undefined
  //                   });
  //                 }}
  //               >
  //                 <IoMdAlert className="alert-icon" />
  //                 {hoverDisplayInfo?.contrato_id === item?.contrato_id
  //                   ? renderHoverAgendamentoInfo()
  //                   : ''}
  //               </div>
  //             )}
  //           </div>
  //         );
  //       })}
  //     </>
  //   );
  // };

  const renderConfirmationMessage = () => {
    if (confirmation) {
      return (
        <div className="sucess-message">
          Solicitação de exclusão enviada com sucesso!
        </div>
      );
    }
    if (confirmationAgendamento) {
      return (
        <div className="sucess-message">
          Agendamento de exclusão enviado com sucesso!
        </div>
      );
    }
    if (confirmationRemoveAgendamento) {
      return (
        <div className="sucess-message">
          Agendamento de exclusão removido com sucesso
        </div>
      );
    }
  };

  const renderErrorMessage = () => {
    switch (JSON.stringify(errors)) {
      case JSON.stringify({
        contratosLength: true,
        filesLength: true,
        serverResponse: false
      }):
        return (
          <div className="error-message">
            Por favor escolher pelo menos um plano para remoção e anexar a carta
            de exclusão
          </div>
        );

      case JSON.stringify({
        contratosLength: true,
        filesLength: false,
        serverResponse: false
      }):
        return (
          <div className="error-message">
            Por favor escolher pelo menos um plano para remoção
          </div>
        );

      case JSON.stringify({
        contratosLength: false,
        filesLength: true,
        serverResponse: false
      }):
        return (
          <div className="error-message">
            Por favor anexar a carta de exclusão
          </div>
        );

      case JSON.stringify({
        contratosLength: false,
        filesLength: false,
        serverResponse: true
      }):
        return (
          <div className="error-message">Ocorreu um erro com o seu pedido</div>
        );

      default:
        return '';
    }
  };

  const resetErrors = () => {
    setErrors({
      contratosLength: false,
      filesLength: false,
      serverResponse: false
    });
  };

  const submitRemovalRequest = () => {
    posthog?.capture('Clicou p/ "Enviar exclusão"');
    if (!contratosToRemove.length) {
      setErrors({
        contratosLength: !contratosToRemove.length ? true : false,
        serverResponse: false
      });

      return setTimeout(resetErrors, 4000);
    }

    resetErrors();
    const headers = {
      authorization: `Bearer ${user.token}`,
      corretora: 6
    };
    const formData = new FormData();
    formData.append('beneficiario', JSON.stringify(beneficiario));
    formData.append('contratosToRemove', JSON.stringify(contratosToRemove));
    formData.append('corretora_id', JSON.stringify(6));
    formData.append('estipulante_id', JSON.stringify(user.mainEstipulante));
    formData.append('usuario_rh', JSON.stringify(user));
    if (agendarExclusao) {
      formData.append('data_agendada', JSON.stringify(dataExclusao));
    }

    files.forEach((file, index) => {
      formData.append(index, file);
    });

    setLoading(true);
    // corretora_id => id de corretora, inicialmente apenas 6 porque é o id da Genoa
    // Futuramente poderá ter mais corretoras
    api
      .post('/rh/create-ticket/removal-request', formData, headers)
      .then((resp) => {
        // console.log(resp.status)
        if (resp.status === 200) {
          if (!agendarExclusao) {
            setConfirmationRemoveAgendamento(false);
            setConfirmationAgendamento(false);
            setConfirmation(true);
            setTimeout(() => {
              setConfirmation(false);
            }, 3000);
          } else {
            setConfirmationRemoveAgendamento(false);
            setConfirmation(false);
            setConfirmationAgendamento(true);
            setTimeout(() => {
              setConfirmationAgendamento(false);
            }, 3000);
          }
          // updateBeneficiarioAfterRequest()
          props.getBeneficiariosAfterUpdate(user?.mainEstipulante);
          setContratosToRemove([]);
          setFiles([]);
        }
      })
      .catch((error) => {
        // console.log(error)
        setErrors({
          contratosLength: !contratosToRemove.length ? true : false,
          filesLength: false,
          serverResponse: true
        });
        setTimeout(resetErrors, 3000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Wrapper>
      <header>
        <div className="headerUser">
          <div className="nameUser">
            <div className="user-company">
              <span className="header-title-name">Solicitação de Exclusão</span>
            </div>
          </div>
        </div>
      </header>
      <section className="infos">
        <div className="info-section-container-beneficiario">
          <div className="infos-section-title-container">
            <span className="infos-section-title">Beneficiário</span>
          </div>
          <div className="beneficiario-info-container">
            <div className="beneficiario-info-grid">
              <span className="icone">
                <AiOutlineUser size={21} />
              </span>
              <span className="beneficiario-nome">{beneficiario?.nome}</span>
            </div>
            <div className="beneficiario-info-grid">
              <span className="icone">
                <AiOutlineCreditCard size={21} />
              </span>
              <span className="beneficiario-nome">{dados?.cpf}</span>
            </div>
            <div className="beneficiario-info-grid">
              <span className="icone">
                <MdFamilyRestroom size={21} />
              </span>
              <span className="beneficiario-nome">{beneficiario?.tipo}</span>
            </div>
          </div>
        </div>
        {beneficiario?.dependentes?.length ? (
          <div className="info-section-container-dependente">
            <div className="infos-section-title-container">
              <span className="infos-section-title">
                Dependentes que serão excluídos
              </span>
            </div>
            <div className="beneficiario-info-container">
              {beneficiario?.dependentes?.map((item) => (
                <div className="beneficiario-info-grid">
                  <span className="icone">
                    <AiOutlineUser size={21} />
                  </span>
                  <span className="beneficiario-nome">{item?.nome}</span>
                </div>
              ))}
            </div>
          </div>
        ) : (
          ''
        )}
        <div className="contratos-section-container">
          <div className="infos-section-title-container">
            <span className="infos-section-title">
              Selecione o plano que será excluído
            </span>
          </div>
          <div className="contrato-info-container">
            {renderBeneficiarioContratosSaude()}
            {renderBeneficiarioContratosDental()}
          </div>
          <div className="input-group">
            {files.length > 0
              ? files.map((file, index) => {
                  return (
                    <div className="file" key={index}>
                      <span>{file?.name}</span>
                      <button
                        className="btn-clear"
                        onClick={() => deleteFile(index)}
                      >
                        <CloseIcon size={13} />
                      </button>
                    </div>
                  );
                })
              : null}
            <label className="input-label">
              <span className="attach-documents">
                Anexar documentos <ImAttachment size={14} />
              </span>
              <input
                className="input-file"
                type="file"
                onChange={({ target }) => handleFile(target)}
                onClick={({ target }) => (target.value = null)}
                multiple
              />
            </label>
          </div>
          {/* <div className="agendamento-row">
            <div className={`agendamento-container ${agendarExclusao ? "selected" : ""}`}>
              <button
                className="agendamento-button"
                onClick={
                  () => setAgendarExclusao((prevState) => !prevState)
                }
              >
                <span className="agendamento-span">Agendar exclusão</span>
                <IoCalendar className="calendar-icon" />
              </button>
            </div>
            {
              agendarExclusao && (
                <div>
                  <ReactDatePicker
                    className="input-data"
                    minDate={new Date()}
                    selected={dataExclusao}
                    value={dataExclusao}
                    locale="ptBR"
                    onChange={(date => setDataExclusao(date))}
                    placeholderText="..."
                    dateFormat="dd/MM/yyyy"
                  />
                  {renderLimparDataButton()}
                </div>
              )
            }
          </div> */}
          <button
            className={`${anchorEl ? 'popper-open' : 'button-submit-removal'}`}
          >
            <span onClick={!loading ? submitRemovalRequest : ''}>
              Enviar exclusão
            </span>
            <RiArrowDownSLine
              className="send-icon"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            />
          </button>
          {
            <Popper
              id={'id'}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              className="popper"
              placement="bottom-start"
              transition
              disablePortal
            >
              <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                <div className="popper-agendamento-wrapper">
                  <div
                    className="menu-option"
                    onClick={() => {
                      setAnchorEl(null);
                      setAgendarExclusao(true);
                    }}
                  >
                    <span>Enviar mais tarde</span>
                    <IoCalendar className="calendar-icon" />
                  </div>
                </div>
              </ClickAwayListener>
            </Popper>
          }

          {agendarExclusao && (
            <AgendamentoPopper
              setAgendarExclusao={setAgendarExclusao}
              dataExclusao={dataExclusao}
              setDataExclusao={setDataExclusao}
              submitRemovalRequest={submitRemovalRequest}
              loading={loading}
              contratosToRemove={contratosToRemove}
            />
          )}
        </div>
        <div className="message-confirmation-container">
          {/* {console.log(errors)} */}
          {renderErrorMessage()}
          {renderConfirmationMessage()}
        </div>
      </section>
    </Wrapper>
  );
};

export default DrawerRemoveBeneficiarios;
