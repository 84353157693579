import { useState, useMemo, useEffect } from 'react';
import { Content, Main, NegociosWrapper } from './styles';
import SideMenuCorretora from 'apps/broker/components/Sidebar';
import Header from 'apps/broker/components/Header';
import NegociosNav from 'apps/broker/pages/Business/components/Nav';
import ContentSwitcher from 'apps/broker/pages/Business/components/ContentSwitcher';
import DrawerNovaAtividade from 'apps/broker/pages/Business/components/CreateNewActivity';
import services from 'apps/broker/services';
import { useUser } from 'contexts/user';
import { useActivitiesFilters } from 'contexts/activities';

const Negocios = () => {
  const { user } = useUser();
  const { activitiesFilters, resetActivitiesFilters } = useActivitiesFilters();

  const [abaSelecionada, setAbaSelecionada] = useState('atividades');
  const [negociosInfo, setNegociosInfo] = useState([]);
  const [newAtividadeNegocio, setNewAtividadeNegocio] = useState(undefined);
  const [isNewActivity, setNewActivity] = useState(undefined);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [corretores, setCorretores] = useState([]);

  // Filtros das atividades
  const [vencimento, setVencimento] = useState('hoje');
  const [responsavel, setResponsavel] = useState(undefined);
  const [dataInicial, setDataInicial] = useState('');
  const [dataFinal, setDataFinal] = useState('');

  const getCorretores = async () => {
    const response = await services.user.getBrokerUsers();
    setCorretores(response.data);
  };

  const orderNegociosByAtividadeData = (negocios) => {
    const orderNegocios = negocios?.sort((a, b) => {
      if (a.created_at > b.created_at) {
        return 1;
      }
      return -1;
    });

    return orderNegocios;
  };

  const { redirected, venc, corretor, data_inicial, data_final } =
    activitiesFilters;

  const getNegociosData = async () => {
    const response = await services.business.getAll();

    const negocios = orderNegociosByAtividadeData(response.data);

    setNegociosInfo(negocios);

    if (redirected) {
      setVencimento(venc);
      setResponsavel(corretor);
      setDataInicial(data_inicial);
      setDataFinal(data_final);
    } else if (!redirected) {
      setResponsavel(user?.usuario_id);
    }
  };

  useMemo(() => {
    if (!newAtividadeNegocio) {
      return setNewActivity(undefined);
    } else {
      const negocioId = newAtividadeNegocio?.negocio_id;

      const negocio = negociosInfo?.find((item) => item.id === negocioId);

      setNewActivity(negocio);
    }
  }, [newAtividadeNegocio]);

  useMemo(() => {
    if (!isNewActivity) {
      setNewAtividadeNegocio(undefined);
    }
  }, [isNewActivity]);

  useEffect(() => {
    getCorretores();
    getNegociosData();
  }, []);

  return (
    <NegociosWrapper>
      <SideMenuCorretora />
      <Content>
        <Header />
        <Main>
          <NegociosNav
            setAbaSelecionada={setAbaSelecionada}
            abaSelecionada={abaSelecionada}
            setOpenDrawer={setOpenDrawer}
          />
          <ContentSwitcher
            abaSelecionada={abaSelecionada}
            isNewActivity={isNewActivity}
            setNewActivity={setNewActivity}
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            corretores={corretores}
            negociosInfo={negociosInfo}
            getNegociosData={getNegociosData}
            setNewAtividadeNegocio={setNewAtividadeNegocio}
            vencimento={vencimento}
            responsavel={responsavel}
            setVencimento={setVencimento}
            setResponsavel={setResponsavel}
            setDataInicial={setDataInicial}
            dataInicial={dataInicial}
            setDataFinal={setDataFinal}
            dataFinal={dataFinal}
            resetActivitiesFilters={resetActivitiesFilters}
          />
        </Main>
      </Content>
      {isNewActivity && (
        <DrawerNovaAtividade
          setNewActivity={setNewActivity}
          setNewAtividadeNegocio={setNewAtividadeNegocio}
          isNewActivity={isNewActivity}
          corretores={corretores}
          getNegociosData={getNegociosData}
        />
      )}
    </NegociosWrapper>
  );
};

export default Negocios;
