import styled from "styled-components";
import colors from "styles/colors";

export const InputFileComponentWrapper = styled.div`
 
  @media (max-height: 580px) {
    display: grid;
    grid-template-columns: 246.79px 13.34px;
    column-gap: 6.67px;
    align-items: end;
  
    .remove-file-icon {
      color: ${colors.genoaPink};
      padding-bottom: 5.336px;
      
      :hover {
        cursor: pointer;
      }
    }
  }

  @media (min-height: 580px) and (max-height: 650px) {
    display: grid;
    grid-template-columns: 296px 16px;
    column-gap: 8px;
    align-items: end;
  
    .remove-file-icon {
      color: ${colors.genoaPink};
      padding-bottom: 6.4px;
      
      :hover {
        cursor: pointer;
      }
    }
  }

  @media (min-height: 650px) and (max-height: 720px) {
    display: grid;
    grid-template-columns: 333px 18px;
    column-gap: 9px;
    align-items: end;

    .remove-file-icon {
      color: ${colors.genoaPink};
      padding-bottom: 7.2px;
      
      :hover {
        cursor: pointer;
      }
    }
  }

  @media (min-height: 720px) {
    display: grid;
    grid-template-columns: 370px 20px;
    column-gap: 10px;
    align-items: end;

    .remove-file-icon {
      color: ${colors.genoaPink};
      padding-bottom: 8px;
      
      :hover {
        cursor: pointer;
      }
    }
  }
`

export const InputFileComponent = styled.div`

  @media (max-height: 580px) {
    display: flex;
    flex-direction: column;
    height: 33.35px;
  
    label {
      display: flex;
      align-items: center;
      column-gap: 6.67px;
      color: ${colors.genoaGrey};
      white-space: nowrap;
      position: relative;
      font-size: 10.672px;
      transform-origin: top left;
      top: 15.341px;
      transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      
      .upload_icon {
        color: #575757;
      }
    }
  
    label.translate {
      transform: translate(0, -25px) scale(0.75);
      font-family: Avenir Next GEO W05 Demi;
      letter-spacing: 0.2px;
      transform-origin: top left;
      color: ${colors.rhBlue};
      transition: color 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    }
  
  
    input {
      border: none;
      z-index: 1000;
      height: 18.81px;
      font-size: 10.672px;
      background-color: transparent !important;
      font-family: Avenir Next GEO W05 Regular;
      padding-bottom: 3.35px;  
      border-bottom: 2px ${colors.rhTabLightGrey} solid;
  
      :hover{
        border-bottom: 2px ${colors.rhTabDarkGrey} solid;
      }
    }
  
    span {
      border: none;
      z-index: 1000;
      
      font-size: 10.672px;
      background-color: transparent !important;
      font-family: Avenir Next GEO W05 Regular;
      padding-bottom: 3.35px;  
      border-bottom: 1.334px ${colors.rhBlue} solid;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
  
    input:-webkit-autofill,
    input:-webkit-autofill:focus {
    
      transition: background-color 600000s 0s, color 600000s 0s;
    }
  
    input.focused {
      display: none;
      transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      border-bottom: 1.334px solid ${colors.rhBlue};
    }
  
    input::-webkit-file-upload-button {
      display: none;
    }
  
    input::file-selector-button{
      visibility: hidden;
      color: transparent;
    }
  
    input[type='file'].empty {
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      color: rgba(0, 0, 0, 0)
    }
  }

  @media (min-height: 580px) and (max-height: 650px) {
    display: flex;
    flex-direction: column;
    height: 40px;
  
    label {
      display: flex;
      align-items: center;
      column-gap: 8px;
      color: ${colors.genoaGrey};
      white-space: nowrap;
      position: relative;
      font-size: 12.8px;
      transform-origin: top left;
      top: 18.4px;
      transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      
      .upload_icon {
        color: #575757;
      }
    }
  
    label.translate {
      transform: translate(0, -25px) scale(0.75);
      font-family: Avenir Next GEO W05 Demi;
      letter-spacing: 0.24px;
      transform-origin: top left;
      color: ${colors.rhBlue};
      transition: color 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    }
  
  
    input {
      border: none;
      z-index: 1000;
      height: 22.56x;
      font-size: 12.8px;
      background-color: transparent !important;
      font-family: Avenir Next GEO W05 Regular;
      padding-bottom: 4px;  
      border-bottom: 2.4px ${colors.rhTabLightGrey} solid;
  
      :hover{
        border-bottom: 2.4px ${colors.rhTabDarkGrey} solid;
      }
    }
  
    span {
      border: none;
      z-index: 1000;
      
      font-size: 12.8px;
      background-color: transparent !important;
      font-family: Avenir Next GEO W05 Regular;
      padding-bottom: 4px;  
      border-bottom: 1.6px ${colors.rhBlue} solid;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
  
    input:-webkit-autofill,
    input:-webkit-autofill:focus {
    
      transition: background-color 600000s 0s, color 600000s 0s;
    }
  
    input.focused {
      display: none;
      transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      border-bottom: 1.6px solid ${colors.rhBlue};
    }
  
    input::-webkit-file-upload-button {
      display: none;
    }
  
    input::file-selector-button{
      visibility: hidden;
      color: transparent;
    }
  
    input[type='file'].empty {
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      color: rgba(0, 0, 0, 0)
    }
  }

  @media (min-height: 650px) and (max-height: 720px) {
    display: flex;
    flex-direction: column;
    height: 45px;
  
    label {
      display: flex;
      align-items: center;
      column-gap: 9px;
      color: ${colors.genoaGrey};
      white-space: nowrap;
      position: relative;
      font-size: 14.4px;
      transform-origin: top left;
      top: 20.7px;
      transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      
      .upload_icon {
        color: #575757;
      }
    }
  
    label.translate {
      transform: translate(0, -25px) scale(0.75);
      font-family: Avenir Next GEO W05 Demi;
      letter-spacing: 0.27px;
      transform-origin: top left;
      color: ${colors.rhBlue};
      transition: color 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    }
  
  
    input {
      border: none;
      z-index: 1000;
      height: 25.39px;
      font-size: 14.4px;
      background-color: transparent !important;
      font-family: Avenir Next GEO W05 Regular;
      padding-bottom: 3.35px;  
      border-bottom: 2.7px ${colors.rhTabLightGrey} solid;
  
      :hover{
        border-bottom: 2.7px ${colors.rhTabDarkGrey} solid;
      }
    }
  
    span {
      border: none;
      z-index: 1000;
      
      font-size: 14.4px;
      background-color: transparent !important;
      font-family: Avenir Next GEO W05 Regular;
      padding-bottom: 4.5px;  
      border-bottom: 1.8px ${colors.rhBlue} solid;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
  
    input:-webkit-autofill,
    input:-webkit-autofill:focus {
    
      transition: background-color 600000s 0s, color 600000s 0s;
    }
  
    input.focused {
      display: none;
      transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      border-bottom: 1.8px solid ${colors.rhBlue};
    }
  
    input::-webkit-file-upload-button {
      display: none;
    }
  
    input::file-selector-button{
      visibility: hidden;
      color: transparent;
    }
  
    input[type='file'].empty {
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      color: rgba(0, 0, 0, 0)
    }
  }

  @media (min-height: 720px) {
    display: flex;
    flex-direction: column;
    height: 50px;
  
    label {
      display: flex;
      align-items: center;
      column-gap: 10px;
      color: ${colors.genoaGrey};
      white-space: nowrap;
      position: relative;
      font-size: 16px;
      transform-origin: top left;
      top: 23px;
      transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      
      .upload_icon {
        color: #575757;
      }
    }
  
    label.translate {
      transform: translate(0, -25px) scale(0.75);
      font-family: Avenir Next GEO W05 Demi;
      letter-spacing: 0.3px;
      transform-origin: top left;
      color: ${colors.rhBlue};
      transition: color 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    }
  
  
    input {
      border: none;
      z-index: 1000;
      height: 28.2px;
      font-size: 16px;
      background-color: transparent !important;
      font-family: Avenir Next GEO W05 Regular;
      padding-bottom: 5px;  
      border-bottom: 3px ${colors.rhTabLightGrey} solid;
  
      :hover{
        border-bottom: 3px ${colors.rhTabDarkGrey} solid;
      }
    }
  
    span {
      border: none;
      z-index: 1000;
      
      font-size: 16px;
      background-color: transparent !important;
      font-family: Avenir Next GEO W05 Regular;
      padding-bottom: 5px;  
      border-bottom: 2px ${colors.rhBlue} solid;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
  
    input:-webkit-autofill,
    input:-webkit-autofill:focus {
    
      transition: background-color 600000s 0s, color 600000s 0s;
    }
  
    input.focused {
      display: none;
      transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      border-bottom: 2px solid ${colors.rhBlue};
    }
  
    input::-webkit-file-upload-button {
      display: none;
    }
  
    input::file-selector-button{
      visibility: hidden;
      color: transparent;
    }
  
    input[type='file'].empty {
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      color: rgba(0, 0, 0, 0)
    }
  }
`