import React, { useState } from 'react';
import {
  FormAdicionarContatoSeguradora,
  WrapperAddContatoSeguradora
} from './styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Button, CircularProgress } from '@material-ui/core';
import { AiFillCaretRight } from 'react-icons/ai';
import ReactInputMask from 'react-input-mask';
import services from 'apps/broker/services';

const DrawerAddContatoSeguradora = ({
  seguradorasData,
  setSeguradorasData,
  onClose
}) => {
  const [seguradoraSelectedId, setSeguradoraSelectedId] = useState(
    seguradorasData?.[0].id
  );
  const [nomeContato, setNomeContato] = useState('');
  const [telefoneContato, setTelefoneContato] = useState('');
  const [emailContato, setEmailContato] = useState('');
  const [funcaoContato, setFuncaoContato] = useState('');
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
  const [displayConfirmationMessage, setDisplayConfirmationMessage] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const adicionarContatoClickHandler = async () => {
    if (
      !(
        nomeContato.length &&
        telefoneContato.length &&
        emailContato.length &&
        funcaoContato.length
      )
    ) {
      setDisplayErrorMessage(true);
      setTimeout(() => {
        setDisplayErrorMessage(false);
      }, 4000);

      return;
    }
    setDisplayErrorMessage(false);
    if (seguradoraSelectedId) {
      const newContato = {
        seguradoraSelectedId,
        nomeContato,
        telefoneContato,
        emailContato,
        funcaoContato
      };

      postNewContato(newContato);

      return;
    }
  };

  const postNewContato = async (newContact) => {
    setLoading(true);
    const response = await services.insuranceCompanies.createContactInformation(
      { newContact }
    );

    if (response.status === 200) {
      setDisplayConfirmationMessage(true);
      setTimeout(() => {
        setDisplayConfirmationMessage(false);
      }, 4000);

      if (response.data.newContato) {
        const newContato = response.data.newContato;
        newContato.seguradora_id = Number(newContato.seguradora_id);
        setSeguradorasData((prevState) => {
          const newState = prevState.map((seguradora) => {
            if (seguradora.id === newContato.seguradora_id) {
              seguradora.contatos.push(newContato);
            }
            return seguradora;
          });
          return newState;
        });
      }
    }
    setLoading(false);
  };

  const phoneMask = () => {
    return telefoneContato[5] !== '9'
      ? '(99) 9999 - 9999'
      : '(99) 99999 - 9999';
  };

  const telefoneInputChangeHandler = ({ target }) => {
    setTelefoneContato(target.value);
  };

  const renderMensagemDeErro = () => {
    if (displayErrorMessage) {
      return (
        <div className="mensagem-erro-container">
          <span>* Por favor, preencher todos os campos do formulário</span>
        </div>
      );
    }

    return <div className="mensagem-erro-container"></div>;
  };

  return (
    <WrapperAddContatoSeguradora>
      <header>
        <div className="back">
          <span onClick={onClose} className="icon-back">
            <ArrowBackIcon fontSize="inherit" />
          </span>
        </div>
        <h2>Adicionar Contato de Seguradora</h2>
      </header>
      <FormAdicionarContatoSeguradora>
        <div className="form-container">
          <div className="seguradora-select-container">
            <span className="input-span-title">Seguradora</span>
            <select
              className="select-seguradora"
              value={seguradoraSelectedId}
              onChange={(e) => setSeguradoraSelectedId(e.target.value)}
            >
              <option disabled style={{ fontWeight: 'bold' }}>
                Selecione
              </option>
              {seguradorasData?.map((seguradora) => (
                <option value={seguradora.id}>
                  {seguradora.nome_completo}
                </option>
              ))}
            </select>
          </div>
          <div className="seguradora-nome-contato-container">
            <span className="input-span-title">Nome do Contato</span>
            <input
              type={'text'}
              value={nomeContato}
              onChange={(e) => setNomeContato(e.target.value)}
              className="input-seguradoras-texto"
            />
          </div>
          <div className="seguradora-telefone-contato-container">
            <span className="input-span-title">Telefone</span>
            <ReactInputMask
              type={'text'}
              value={telefoneContato}
              mask={phoneMask()}
              maskChar={null}
              onChange={telefoneInputChangeHandler}
              className="input-seguradoras-texto"
            />
          </div>
          <div className="seguradora-email-contato-container">
            <span className="input-span-title">Email</span>
            <input
              type={'text'}
              value={emailContato}
              onChange={(e) => setEmailContato(e.target.value)}
              className="input-seguradoras-texto"
            />
          </div>
          <div className="seguradora-funcao-contato-container">
            <span className="input-span-title">Função</span>
            <input
              type={'text'}
              value={funcaoContato}
              onChange={(e) => setFuncaoContato(e.target.value)}
              className="input-seguradoras-texto"
            />
          </div>
          {renderMensagemDeErro()}
          <div className="botao-adicionar-contato-container">
            <Button
              variant="contained"
              className="botao-adicionar-contato"
              onClick={adicionarContatoClickHandler}
            >
              {loading ? (
                <>
                  <CircularProgress size={19.2} className="loading-spinner" />
                </>
              ) : (
                <>
                  Adicionar
                  <AiFillCaretRight className="icone-caret-direita" size={15} />
                </>
              )}
            </Button>
            {displayConfirmationMessage ? (
              <div className="mensagem-confirmacao-container">
                <span>Contato Adicionado com Sucesso!</span>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </FormAdicionarContatoSeguradora>
    </WrapperAddContatoSeguradora>
  );
};

export default DrawerAddContatoSeguradora;
