import styled from 'styled-components';
import colors from 'styles/colors';



export const Wrapper = styled.div`
  display: flex;


  .animeLeft {
  opacity: 0;
  transform: translateX(-20px);
  animation: animeLeft 0.3s forwards;
}
@keyframes animeLeft {
  to {
    opacity: 1;
    transform: initial;
  }
}

`

export const Content = styled.div`
    
    display: flex;
    flex-direction: column;
    flex: 1;
    //color: #3B3838;

    .main-painel {
        // margin: auto;
        //padding: 20px 100px;
    }

    section.cadastro{
      margin: 25px 0;

      .title{
        margin: 10px 0px 30px 0px;
        h1{
          color: ${colors.genoaGrey};
          font-family: 'Avenir Next GEO W05 Heavy';
          font-size: 22px;
        }
      }

    
    }


    @media (max-width: 1350px){
    
    }
    @media (max-width: 1280px){
      
    }
    @media (max-width: 900px){
   
    }
`
