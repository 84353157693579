import './style.scss';

const InputUploadFile = ({ name, label, onChange, ...rest }) => {
  return (
    <div className="ds-input-file">
      <label className={`ds-input-file__label ds-input-file`} htmlFor={name}>
        <input
          id={name}
          type="file"
          className={`ds-input-file__input`}
          name={name}
          onChange={onChange}
          {...rest}
        />
        {label}
      </label>
    </div>
  );
};

export default InputUploadFile;
