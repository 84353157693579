import { VscGraphLine } from 'react-icons/vsc';

const DemandsSummary = ({ data }) => {
  return (
    <div className="column-one-row-one">
      <div className="grafico_demandas_totais">
        <VscGraphLine />
      </div>
      <div className="demandas_totais">
        <div className="title_fluxos_demandas">
          <span>Fluxos de Demandas</span>
        </div>
        <div className="tickets">
          <div className="box-ticket">
            <div className="ticket-count">{data.rh}</div>
            <div className="ticket-title">Tickets</div>
          </div>

          <div className="box-ticket">
            <div className="ticket-count">{data.movimentacao}</div>
            <div className="ticket-title">Movs</div>
          </div>

          <div className="box-ticket">
            <div className="ticket-count">{data.concierge}</div>
            <div className="ticket-title">Concierge</div>
          </div>

          <div className="box-ticket">
            <div className="ticket-count">{data.tasks}</div>
            <div className="ticket-title">Tasks</div>
          </div>

          <div className="box-ticket">
            <div className="ticket-count">{data.atividades}</div>
            <div className="ticket-title">Atividades</div>
          </div>

          <div className="box-ticket">
            <div className="ticket-count">{data.all}</div>
            <div className="ticket-title">Total</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemandsSummary;
