import React from 'react';
import { NegociosNavWrapper } from './styles';

const NegociosNav = ({ abaSelecionada, setAbaSelecionada, setOpenDrawer }) => {
  return (
    <NegociosNavWrapper>
      <div className="nav_content">
        <div className="title_container">
          <h1>Negócios em andamento</h1>
        </div>
        <div className="negocios__tab_options">
          <button
            className={`option ${
              abaSelecionada === 'atividades' ? 'selected' : ''
            }`}
            onClick={() => setAbaSelecionada('atividades')}
          >
            <span>Atividades</span>
          </button>
          <button
            className={`option ${
              abaSelecionada === 'negócios' ? 'selected' : ''
            }`}
            onClick={() => setAbaSelecionada('negócios')}
          >
            <span>Negócios</span>
          </button>
        </div>
      </div>
      <div>
        <button className="btn" onClick={() => setOpenDrawer(true)}>
          + Negócios
        </button>
      </div>
    </NegociosNavWrapper>
  );
};

export default NegociosNav;
