import { BiMinus, BiUser } from 'react-icons/bi';
import { AsideUserIconWrapper } from './styles-AsideUserIcon';
import { FaPlus } from 'react-icons/fa';

const AsideUserIcon = (props) => {
  const selected = props.selected;
  const tipo = props.tipo;

  return (
    <AsideUserIconWrapper>
      <BiUser className="user" />
      {tipo === 'Inclusão' ? (
        <FaPlus className={`plus ${selected ? 'selected-option' : ''}`} />
      ) : (
        <BiMinus className={`minus ${selected ? 'selected-option' : ''}`} />
      )}
    </AsideUserIconWrapper>
  );
};

export default AsideUserIcon;
