import React from 'react';
import { CardWrapper } from './styles-MovimentacaoCard';
import { IoPersonSharp } from 'react-icons/io5';
import { ReactComponent as EstipulanteIcon } from 'assets/imgs/svg/estipulante-tag.svg';
import { useLocation, useHistory } from 'react-router-dom';
import { useUrgentDemandsTickets } from 'contexts/urgentDemandsTickets';

const MovimentacaoCard = ({ movimentacao, setOpenDrawer }) => {
  const history = useHistory();
  const location = useLocation();
  const { setUrgentDemandsTickets } = useUrgentDemandsTickets();

  const renderDaysAmount = () => {
    const today = new Date().setHours(0, 0, 0, 0);
    const createdAt = new Date(movimentacao?.ticket_created_at)?.setHours(
      0,
      0,
      0,
      0
    );
    const subtraction = today - createdAt;
    const daysExtracted = subtraction / (1000 * 60 * 60 * 24);
    return daysExtracted;
  };

  const dispatchUrgentDemand = () => {
    const newState = {
      redirected: true,
      page: 'movimentacao',
      demand_id: movimentacao?.movimentacao_id
    };

    setUrgentDemandsTickets(newState);

    if (location?.pathname !== '/corretor/movimentacao-beneficiarios') {
      history.push('/corretor/movimentacao-beneficiarios');
    } else {
      setOpenDrawer(false);
    }
  };

  return (
    <CardWrapper>
      <div className="title-container">
        <div className="card-title">
          <IoPersonSharp className="person-icon" />
          <span className="nome">{movimentacao?.nome}</span>
        </div>
        <div className="card-title">
          <EstipulanteIcon className="estipulante-icon" />
          <span className="estipulante">
            {movimentacao?.estipulante_razao_social}.
          </span>
        </div>
      </div>
      <div className="value-container">
        <span className="value">Aberto há {renderDaysAmount()} dias</span>
        <button onClick={dispatchUrgentDemand}>Ir para movimentação</button>
      </div>
    </CardWrapper>
  );
};

export default MovimentacaoCard;
