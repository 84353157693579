import { ImCross } from 'react-icons/im';
import ReactInputMask from 'react-input-mask';
import colors from 'apps/broker/styles/colors';
import DatePicker from 'react-datepicker';
import relatives from 'utils/arrays/relatives';
import { Div } from './styles-MoreRelativesForm';

const MoreRelativesForm = ({
  dependente,
  nomeDependentesDoTitular,
  setNomeDependentesDoTitular,
  cpfDependentesDoTitular,
  setCpfDependentesDoTitular,
  dataNascimentoAllDependentes,
  setDataNascimentoAllDependentes,
  vinculoDependenteWithTitular,
  setVinculoDependenteWithTitular,
  dataVinculoAllDependentes,
  setDataVinculoAllDependentes,
  nCarteirinhaAllDependentes,
  setNCarteirinhaAllDependentes,
  dateStartPlanAllDependentes,
  setDateStartPlanAllDependentes,
  removerDependenteClickHandler
}) => {

  return (
    <Div>
      <div className="remover-dependente-row">
        <ImCross size={7} />
        <span
          onClick={() => removerDependenteClickHandler(dependente.identification)}
        >
          Remover Dependente
        </span>
      </div>
      <div className="form-line-row">
        <input
          className="input"
          value={nomeDependentesDoTitular[dependente.identification]}
          onChange={({ target }) =>
            setNomeDependentesDoTitular((prev) => ({
              ...prev,
              [dependente.identification]: target.value
            }))
          }
          placeholder="* Nome completo do Dependente"
        />
      </div>

      <div className="form-line-row">
        <ReactInputMask
          className="input"
          mask="999.999.999-99"
          placeholder="* CPF do Dependente"
          value={cpfDependentesDoTitular[dependente.identification]}
          onChange={({ target }) =>
            setCpfDependentesDoTitular((prev) => ({
              ...prev,
              [dependente.identification]: target.value
            }))
          }
        />
      </div>

      <div className="datepicker-row">
        <div>
          <DatePicker
            className="input-data"
            selected={dataNascimentoAllDependentes[dependente.identification]}
            locale="ptBR"
            onChange={(date) =>
              setDataNascimentoAllDependentes((prev) => ({
                ...prev,
                [dependente.identification]: date
              }))
            }
            placeholderText="* Selecione a data de nascimento"
            dateFormat="dd/MM/yyyy"
          />
        </div>
        <div className="grau-parentesco-row">
          <select
            className="input"
            defaultValue=""
            style={{
              color: `${
                vinculoDependenteWithTitular[dependente.identification]
                  ? colors.genoaGrey
                  : '#b8b9bb'
              }`
            }}
            value={vinculoDependenteWithTitular[dependente.identification]}
            onChange={({ target }) =>
              setVinculoDependenteWithTitular((prev) => ({
                ...prev,
                [dependente.identification]: target.value
              }))
            }
          >
            <option value="" disabled style={{ fontWeight: 'bold' }}>
              * Grau de parentesco
            </option>
            {relatives.map(({ option, value }) => (
              <option className="valid-options" value={value}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="datepicker-row">
        <div>
          <DatePicker
            className="input-data"
            selected={dataVinculoAllDependentes[dependente.identification]}
            locale="ptBR"
            onChange={(date) =>
              setDataVinculoAllDependentes((prev) => ({
                ...prev,
                [dependente.identification]: date
              }))
            }
            placeholderText="* Selecione a data de vínculo"
            dateFormat="dd/MM/yyyy"
          />
        </div>
        <div className="datepicker-start-date-row">
          <DatePicker
            className="input-data"
            selected={dateStartPlanAllDependentes[dependente.identification]}
            locale="ptBR"
            onChange={(date) =>
              setDateStartPlanAllDependentes((prev) => ({
                ...prev,
                [dependente.identification]: date
              }))
            }
            placeholderText="Selecione a data de início do plano"
            dateFormat="dd/MM/yyyy"
          />
        </div>
      </div>

      <div className="form-line-row">
        <input
          className="input"
          value={nCarteirinhaAllDependentes[dependente.identification]}
          onChange={({ target }) =>
            setNCarteirinhaAllDependentes((prev) => ({
              ...prev,
              [dependente.identification]: target.value
            }))
          }
          placeholder="Número da carteirinha"
        />
      </div>
    </Div>
  );
};

export default MoreRelativesForm;
