import http from 'services/http';

const createByBusinessId = async (data) => {
  const res = await http.post('/corretor/negocios/atividades', data);

  return res;
};

const updateDescriptionByActivityId = async (data) => {
  const res = await http.put('/corretor/negocios/atividade/descricao', data);

  return res;
};

const updateDateByActivityId = async (data) => {
  const res = await http.put(
    '/corretor/negocios/atividade/data_agendada',
    data
  );

  return res;
};

const updateStatusClosedByActivityId = async (data) => {
  const res = await http.put('/corretor/negocios/atividades', data);

  return res;
};

const updateStatusByActivityId = async (data) => {
  const res = await http.put('/corretor/negocios/atividades/status', data);

  return res;
};

const updateResponsableByActivityId = async (data) => {
  const res = await http.put('/corretor/negocios/atividades', data);

  return res;
};

const deleteActivity = async (item) => {
  const res = await http.delete('/corretor/negocios/atividades', {
    data: {
      atividadeItem: item
    }
  });

  return res;
};

const modules = {
  createByBusinessId,
  updateDescriptionByActivityId,
  updateDateByActivityId,
  updateStatusClosedByActivityId,
  updateStatusByActivityId,
  updateResponsableByActivityId,
  deleteActivity
};

export default modules;
