import http, { BROKERAGE_UUID } from 'services/http';

const createBrokerProducts = async (body) => {
  const res = await http.post('/corretor/produtos', body, {
    headers: {
      corretora: BROKERAGE_UUID
    }
  });

  return res;
};

const getAll = async () => {
  const res = await http.get('/corretor/produtos', {
    headers: {
      corretora: BROKERAGE_UUID
    }
  });

  return res;
};

const modules = {
  createBrokerProducts,
  getAll
};

export default modules;
