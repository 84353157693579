import styled from 'styled-components';
import colors from 'styles/colors';

export const Wraper = styled.div`

    /* background: rgb(248,192,0);
    background: -moz-linear-gradient(180deg, rgba(248,192,0,1) 0%, rgba(255,178,29,1) 88%);
    background: -webkit-linear-gradient(180deg, rgba(248,192,0,1) 0%, rgba(255,178,29,1) 88%);
    background: linear-gradient(180deg, rgba(248,192,0,1) 0%, rgba(255,178,29,1) 88%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f8c000",endColorstr="#ffb21d",GradientType=1); */
    background-color: #f5f5f5;
    height: 100vh;
  
    main {
    margin-top: 5rem;
    }

    .title{
      margin-top: 1.5rem;
      text-align:center;
      font-family: 'Avenir Next GEO W05 Bold';
      color: #3B3838;
     
    }

    .top-area{
      padding: .5rem 1rem;

      img{
        width:150px;
      }

    }

    .form{
      padding: 2rem;


      .form-controll{
        margin-bottom: 2rem;
      }

      .erro-msg{
        color: #f80039;
        font-size: 1rem;
        font-family: 'Avenir Next GEO W05 Demi';
        margin-bottom: 1rem;
      }
      
      button {
        font-family: 'Avenir Next GEO W05 Bold';
        border: none;
        background: transparent;
        color: #ccc;
        font-weight: bold;
        color: #62A0E2;
        font-size: 1.2rem;
        display: inline-flex;
        align-items: center;
        cursor: pointer;

        &:focus{
          outline:none;
        }

        .icon{
          display: inherit;
          margin-left: 5px;
          margin-top: 3px;
        }
      }

    }

    .flex-center {
        display: flex;
        justify-content: center;
        
    }

    .mb-1{
      margin-bottom:1rem;
    }

    .form-link{
      display:flex;
      flex-direction: column;
      align-items: center;

      div{
        margin-bottom:1rem;
      }  

      a{
        font-size: 1.2rem;
        font-family: 'Avenir Next GEO W05 Bold';
        color: #3B3838;
      }

    }

    @media (max-width: 576px) {
      main {
         margin-top: 3rem;
       }

    .title{
        font-size: .8rem
     
      }

    }

    .PrivateTabIndicator-colorPrimary-6 {
      background-color: ${colors.genoaBlue};
    }

`

