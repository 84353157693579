import styled from 'styled-components';
import colors from 'styles/colors';

export const DrawerAdicionarBeneficiarioWrapper = styled.div`
  background-color: ${colors.white};
  
  width: 90%;
  height: 90%;
  max-width: 1367px;
  max-height: 663px;

  display: grid;

  @media (max-height: 580px) {
    margin-left: 2.68vw;
    border-radius: 13px;
    grid-template-columns: 13px 1fr;
  }

  @media (min-height: 580px) and (max-height: 650px) {
    margin-left: 3.2vw;
    border-radius: 16px;
    grid-template-columns: 16px 1fr;
  }

  @media (min-height: 650px) and (max-height: 720px) {
    margin-left: 3.6vw;
    border-radius: 18px;
    grid-template-columns: 18px 1fr;
  }

  @media (min-height: 720px) {
    margin-left: 4vw;
    border-radius: 20px;
    grid-template-columns: 20px 1fr;
  }

`

export const SideStripe = styled.div`
  background-color: ${colors.rhBlue};
  height: 100%;

  @media (max-height: 580px) {
    border-top-left-radius: 13px;
    border-bottom-left-radius: 13px;
  }

  @media (min-height: 580px) and (max-height: 650px) {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  @media (min-height: 650px) and (max-height: 720px) {
    border-top-left-radius: 18px;
    border-bottom-left-radius: 18px;
  }

  @media (min-height: 720px) {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
`

export const Content = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  
  @media (max-height: 580px) {
    grid-template-columns: 1fr 200px;
  }

  @media (min-height: 580px) and (max-height: 650px) {
    grid-template-columns: 1fr 240px;
  }

  @media (min-height: 650px) and (max-height: 720px) {
    grid-template-columns: 1fr 270px;
  }

  @media (min-height: 720px) {
    grid-template-columns: 1fr 300px;
  }
`

export const MainContent = styled.div`
  

  @media (max-height: 580px) {
    display: grid;
    grid-template-rows: 17.42px 1fr 66.7px;
    row-gap: 30px;
    width: calc(100% - 86.71px);
    height: calc(100% - 53.6px);
    padding-left: 43.36px;
    padding-right: 43.36px;
    padding-bottom: 16.675px;
    padding-top: 36.85px;

    .content-title {
      display: flex;
      justify-content: space-between;
      span {
        font-size: 12px;
        color: ${colors.rhBlue};
        font-family: Avenir Next GEO W05 Heavy;
      }

      .aba-option {
        
        column-gap: 13.34px;
        align-items: center;
        
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;

        span {
          font-family: Avenir Next GEO W05 Medium;
        }

        .icon-container {
          display: flex;
          align-items: center;
          justify-content: center;

          // border: 1px solid ${colors.genoaDarkBlue};
          // border-radius: 6px;
          padding: 3.335px 3.335px;

          span {
            font-size: 9.338px;
            font-family: Avenir Next GEO W05 Demi;
            color: ${colors.genoaDarkBlue};
          }

          svg {
            width: 13.34px;
            margin-bottom: -1.334px;
          }
          color: ${colors.genoaDarkBlue};

          :hover {
            cursor: pointer;
            text-decoration: underline
            // background-color: ${colors.rhTabLightGrey};
          }
        }

        .remove {
          // border: 0.667px solid ${colors.genoaPink};
          color: ${colors.genoaPink};
        }
        

      }
    }

    .aba-container {
      display: grid;
      grid-template-rows: 17.342px 1fr;
      row-gap: 40px;

      .aba-title-container {
        span {
          font-size: 12px;
          font-family: Avenir Next GEO W05 Bold;
          color: ${colors.genoaDarkBlue};
        }
      }

      .aba-input-container {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        row-gap: 10px;

        .aba-input-row {
          display: grid;
          width: 70%;
          grid-template-columns: 1fr 1fr;
          column-gap: 33.35px;
        }

      }
    }
  }

  @media (min-height: 580px) and (max-height: 650px) {
    display: grid;
    grid-template-rows: 20.8px 1fr 80px;
    row-gap: 36px;
    width: calc(100% - 104px);
    height: calc(100% - 64px);
    padding-left: 52px;
    padding-right: 52px;
    padding-bottom: 20px;
    padding-top: 44px;

    .content-title {
      display: flex;
      justify-content: space-between;
      span {
        font-size: 14.4px;
        color: ${colors.rhBlue};
        font-family: Avenir Next GEO W05 Heavy;
      }

      .aba-option {
        
        column-gap: 16px;
        align-items: center;
        
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;

        span {
          font-family: Avenir Next GEO W05 Medium;
        }

        .icon-container {
          display: flex;
          align-items: center;
          justify-content: center;

          // border: 0.8px solid ${colors.genoaDarkBlue};
          // border-radius: 4.8px;
          padding: 4px 4px;

          span {
            font-size: 11.2px;
            font-family: Avenir Next GEO W05 Demi;
            color: ${colors.genoaDarkBlue};
          }

          svg {
            width: 16px;
            margin-bottom: -1.6px;
          }
          color: ${colors.genoaDarkBlue};

          :hover {
            cursor: pointer;
            text-decoration: underline
            // background-color: ${colors.rhTabLightGrey};
          }
        }

        .remove {
          // border: 0.8px solid ${colors.genoaPink};
          color: ${colors.genoaPink};
        }
        

      }
    }

    .aba-container {
      display: grid;
      grid-template-rows: 20.8px 1fr;
      row-gap: 48px;

      .aba-title-container {
        span {
          font-size: 14.4px;
          font-family: Avenir Next GEO W05 Bold;
          color: ${colors.genoaDarkBlue};
        }
      }

      .aba-input-container {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        row-gap: 12px;

        .aba-input-row {
          display: grid;
          width: 70%;
          grid-template-columns: 1fr 1fr;
          column-gap: 40px;
        }

      }
    }
  }

  @media (min-height: 650px) and (max-height: 720px) {
    display: grid;
    grid-template-rows: 23.4px 1fr 90px;
    row-gap: 40.5px;
    width: calc(100% - 117px);
    height: calc(100% - 72px);
    padding-left: 58.5px;
    padding-right: 58.5px;
    padding-bottom: 22.5px;
    padding-top: 49.5px;

    .content-title {
      display: flex;
      justify-content: space-between;
      span {
        font-size: 16.2px;
        color: ${colors.rhBlue};
        font-family: Avenir Next GEO W05 Heavy;
      }

      .aba-option {
        
        column-gap: 18px;
        align-items: center;
        
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;

        span {
          font-family: Avenir Next GEO W05 Medium;
        }

        .icon-container {
          display: flex;
          align-items: center;
          justify-content: center;

          // border: 0.9px solid ${colors.genoaDarkBlue};
          // border-radius: 5.4px;
          padding: 4.5px 4.5px;

          span {
            font-size: 12.6px;
            font-family: Avenir Next GEO W05 Demi;
            color: ${colors.genoaDarkBlue};
          }

          svg {
            width: 18px;
            margin-bottom: -1.8px;
          }
          color: ${colors.genoaDarkBlue};

          :hover {
            cursor: pointer;
            text-decoration: underline
            // background-color: ${colors.rhTabLightGrey};
          }
        }

        .remove {
          // border: 0.9px solid ${colors.genoaPink};
          color: ${colors.genoaPink};
        }
        

      }
    }

    .aba-container {
      display: grid;
      grid-template-rows: 23.4px 1fr;
      row-gap: 54px;

      .aba-title-container {
        span {
          font-size: 16.2px;
          font-family: Avenir Next GEO W05 Bold;
          color: ${colors.genoaDarkBlue};
        }
      }

      .aba-input-container {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        row-gap: 13.5px;

        .aba-input-row {
          display: grid;
          width: 70%;
          grid-template-columns: 1fr 1fr;
          column-gap: 45px;
        }

      }
    }
  }

  @media (min-height: 720px) {
    display: grid;
    grid-template-rows: 26px 1fr 100px;
    row-gap: 45px;
    width: calc(100% - 130px);
    height: calc(100% - 80px);
    padding-left: 65px;
    padding-right: 65px;
    padding-bottom: 25px;
    padding-top: 55px;

    .content-title {
      display: flex;
      justify-content: space-between;
      span {
        font-size: 18px;
        color: ${colors.rhBlue};
        font-family: Avenir Next GEO W05 Heavy;
      }

      .aba-option {
        
        column-gap: 20px;
        align-items: center;
        
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;

        span {
          font-family: Avenir Next GEO W05 Medium;
        }

        .icon-container {
          display: flex;
          align-items: center;
          justify-content: center;

          // border: 1px solid ${colors.genoaDarkBlue};
          // border-radius: 6px;
          padding: 5px 5px;

          span {
            font-size: 14px;
            font-family: Avenir Next GEO W05 Demi;
            color: ${colors.genoaDarkBlue};
          }

          svg {
            width: 20px;
            margin-bottom: -2px;
          }
          color: ${colors.genoaDarkBlue};

          :hover {
            cursor: pointer;
            text-decoration: underline
            // background-color: ${colors.rhTabLightGrey};
          }
        }

        .remove {
          // border: 1px solid ${colors.genoaPink};
          color: ${colors.genoaPink};
        }
        

      }
    }

    .aba-container {
      display: grid;
      grid-template-rows: 26px 1fr;
      row-gap: 60px;

      .aba-title-container {
        span {
          font-size: 18px;
          font-family: Avenir Next GEO W05 Bold;
          color: ${colors.genoaDarkBlue};
        }
      }

      .aba-input-container {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        row-gap: 15px;

        .aba-input-row {
          display: grid;
          width: 70%;
          grid-template-columns: 1fr 1fr;
          column-gap: 50px;
        }

      }
    }
  }
  
`

export const NavButtonsContainer = styled.div`
  @media (max-height: 580px) {
    display: flex;
    flex-direction: column;
    row-gap: 13.34px;
  
    .button-row {
      display: flex;
      column-gap: 20px;
  
      .botao-aba {
        height: 20.8px;
        width: 100px;
        font-size: 0.6rem;
        font-family: 'Avenir Next GEO W05 Demi';
        border-radius: 10px;
        padding: 0;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 2px;
        padding-bottom: 2px;
        text-transform: none;
  
        .loading-spinner {
          margin-top: 2px;
          margin-bottom: 2px;
          color: white;
  
        }
  
        .icone-caret-direita {
          margin-left: 3.35px;
  
        }
  
  
      }
  
      .proximo {
        color: white;
        background-color: ${colors.genoaDarkBlue};
      }
  
      .voltar {
        color: ${colors.white};
        // Cor abaixo utilizada apenas uma vez no figma
        background-color: #939393;
      }
    }
    
    .caroussel-row {
      display: flex;
      justify-content: center;
      align-items: end;
      column-gap: 8.671px;
      min-height: 33.5px;
      
  
      .pagination_item_wrapper {
        display: flex;
        flex-direction: column-reverse;
        height: 100%;
      }
  
      .dependente_item_wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        -webkit-justify-content: space-between;
        justify-content: space-between;
      }
  
      .pagination_item_wrapper {
        display: flex;
        flex-direction: column-reverse;
        height: 100%;
      }
  
      .pagination-item-container {
        width: calc(max-content);
        min-width: 13.34px;
        padding: 0px 6.67px;
        height: 12px;
        background-color: ${colors.rhTabDarkGrey};
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        border: 2px solid transparent;
        border-radius: 6px;
  
        :hover {
          cursor: pointer;
        }
  
      }
  
      .icon-container-remove {
        color: ${colors.genoaPink};
        display: flex;
        justify-content: center;

        svg {
          font-size: 9.6px;
        }
  
        :hover {
          cursor: pointer;
        }
      }
  
      .pagination-item {
        font-size: 6.67px;
        font-family: 'Avenir Next GEO W05 Regular';
        color: ${colors.genoaGrey};
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
      }
  
      .selected {
        border: 2px solid ${colors.rhBlue};
        border-radius: 6px;
      }
  
      .finalizar {
        font-family: 'Avenir Next GEO W05 Medium';
        background-color: ${colors.rhBlue};
        color: ${colors.white};
      }
  
      .disabled-container{
        background-color: ${colors.rhBlue};
        opacity: 55%;
        :hover {
          cursor: inherit;
        }
      }
  
      .disabled {
        color: ${colors.white};
      }
    }
  }

  @media (min-height: 580px) and (max-height: 650px) {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  
    .button-row {
      display: flex;
      column-gap: 24px;
  
      .botao-aba {
        height: 25px;
        width: 120px;
        font-size: 0.72rem;
        font-family: 'Avenir Next GEO W05 Demi';
        border-radius: 12px;
        padding: 0;
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 2.4px;
        padding-bottom: 2.4px;
        text-transform: none;
  
        .loading-spinner {
          margin-top: 2.4px;
          margin-bottom: 2.4px;
          color: white;
  
        }
  
        .icone-caret-direita {
          margin-left: 4px;
  
        }
  
  
      }
  
      .proximo {
        color: white;
        background-color: ${colors.genoaDarkBlue};
      }
  
      .voltar {
        color: ${colors.white};
        // Cor abaixo utilizada apenas uma vez no figma
        background-color: #939393;
      }
    }
  
    .caroussel-row {
      display: flex;
      justify-content: center;
      align-items: end;
      column-gap: 10.4px;
      min-height: 40px;
  
      .pagination_item_wrapper {
        display: flex;
        flex-direction: column-reverse;
        height: 100%;
      }
  
      .dependente_item_wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        -webkit-justify-content: space-between;
        justify-content: space-between;
      }
  
      .pagination_item_wrapper {
        display: flex;
        flex-direction: column-reverse;
        height: 100%;
      }
  
      .pagination-item-container {
        width: calc(max-content);
        min-width: 16px;
        padding: 0px 8px;
        height: 14.4px;
        background-color: ${colors.rhTabDarkGrey};
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 7.2px;
        border: 2.4px solid transparent;
        border-radius: 7.2px;
  
        :hover {
          cursor: pointer;
        }
  
      }
  
      .icon-container-remove {
        color: ${colors.genoaPink};
        display: flex;
        justify-content: center;

        svg {
          font-size: 12px;
        }
  
        :hover {
          cursor: pointer;
        }
      }
  
      .pagination-item {
        font-size: 8px;
        font-family: 'Avenir Next GEO W05 Regular';
        color: ${colors.genoaGrey};
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
      }
  
      .selected {
        border: 2.4px solid ${colors.rhBlue};
        border-radius: 7.2px;
      }
  
      .finalizar {
        font-family: 'Avenir Next GEO W05 Medium';
        background-color: ${colors.rhBlue};
        color: ${colors.white};
      }
  
      .disabled-container{
        background-color: ${colors.rhBlue};
        opacity: 55%;
        :hover {
          cursor: inherit;
        }
      }
  
      .disabled {
        color: ${colors.white};
      }
    }
  }

  @media (min-height: 650px) and (max-height: 720px) {
    display: flex;
    flex-direction: column;
    row-gap: 18px;
  
    .button-row {
      display: flex;
      column-gap: 27px;
  
      .botao-aba {
        height: 28.08px;
        width: 135px;
        font-size: 0.81rem;
        font-family: 'Avenir Next GEO W05 Demi';
        border-radius: 13.5px;
        padding: 0;
        padding-left: 13.5px;
        padding-right: 13.5px;
        padding-top: 2.7px;
        padding-bottom: 2.7px;
        text-transform: none;
  
        .loading-spinner {
          margin-top: 2.7px;
          margin-bottom: 2.7px;
          color: white;
  
        }
  
        .icone-caret-direita {
          margin-left: 4.5px;
  
        }
  
  
      }
  
      .proximo {
        color: white;
        background-color: ${colors.genoaDarkBlue};
      }
  
      .voltar {
        color: ${colors.white};
        // Cor abaixo utilizada apenas uma vez no figma
        background-color: #939393;
      }
    }
  
    .caroussel-row {
      display: flex;
      justify-content: center;
      align-items: end;
      column-gap: 11.7px;
      min-height: 45px;
  
      .pagination_item_wrapper {
        display: flex;
        flex-direction: column-reverse;
        height: 100%;
      }
  
      .dependente_item_wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        -webkit-justify-content: space-between;
        justify-content: space-between;
      }
  
      .pagination_item_wrapper {
        display: flex;
        flex-direction: column-reverse;
        height: 100%;
      }
  
      .pagination-item-container {
        width: calc(max-content);
        min-width: 18px;
        padding: 0px 9px;
        height: 16.2px;
        background-color: ${colors.rhTabDarkGrey};
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8.1px;
        border: 2.7px solid transparent;
        border-radius: 8.1px;
  
        :hover {
          cursor: pointer;
        }
  
      }
  
      .icon-container-remove {
        color: ${colors.genoaPink};
        display: flex;
        justify-content: center;

        svg {
          font-size: 13.5px;
        }
  
        :hover {
          cursor: pointer;
        }
      }
  
      .pagination-item {
        font-size: 9px;
        font-family: 'Avenir Next GEO W05 Regular';
        color: ${colors.genoaGrey};
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
      }
  
      .selected {
        border: 2.7px solid ${colors.rhBlue};
        border-radius: 8.1px;
      }
  
      .finalizar {
        font-family: 'Avenir Next GEO W05 Medium';
        background-color: ${colors.rhBlue};
        color: ${colors.white};
      }
  
      .disabled-container{
        background-color: ${colors.rhBlue};
        opacity: 55%;
        :hover {
          cursor: inherit;
        }
      }
  
      .disabled {
        color: ${colors.white};
      }
    }
  }

  @media (min-height: 720px) {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  
    .button-row {
      display: flex;
      column-gap: 30px;
  
      .botao-aba {
        height: 31.2px;
        width: 150px;
        font-size: 0.9rem;
        font-family: 'Avenir Next GEO W05 Demi';
        border-radius: 15px;
        padding: 0;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 3px;
        padding-bottom: 3px;
        text-transform: none;
  
        .loading-spinner {
          margin-top: 3px;
          margin-bottom: 3px;
          color: white;
  
        }
  
        .icone-caret-direita {
          margin-left: 5px;
  
        }
  
  
      }
  
      .proximo {
        color: white;
        background-color: ${colors.genoaDarkBlue};
      }
  
      .voltar {
        color: ${colors.white};
        // Cor abaixo utilizada apenas uma vez no figma
        background-color: #939393;
      }
    }
  
    .caroussel-row {
      display: flex;
      justify-content: center;
      align-items: end;
      column-gap: 13px;
      min-height: 50px;
  
      .pagination_item_wrapper {
        display: flex;
        flex-direction: column-reverse;
        height: 100%;
      }
  
      .dependente_item_wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        -webkit-justify-content: space-between;
        justify-content: space-between;
      }
  
      .pagination_item_wrapper {
        display: flex;
        flex-direction: column-reverse;
        height: 100%;
      }
  
      .pagination-item-container {
        width: calc(max-content);
        min-width: 20px;
        padding: 0px 10px;
        height: 18px;
        background-color: ${colors.rhTabDarkGrey};
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 9px;
        border: 3px solid transparent;
        border-radius: 9px;
  
        :hover {
          cursor: pointer;
        }
  
      }
  
      .icon-container-remove {
        color: ${colors.genoaPink};
        display: flex;
        justify-content: center;

        svg {
          font-size: 15px;
        }
  
        :hover {
          cursor: pointer;
        }
      }
  
      .pagination-item {
        font-size: 10px;
        font-family: 'Avenir Next GEO W05 Regular';
        color: ${colors.genoaGrey};
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
      }
  
      .selected {
        border: 3px solid ${colors.rhBlue};
        border-radius: 9px;
      }
  
      .finalizar {
        font-family: 'Avenir Next GEO W05 Medium';
        background-color: ${colors.rhBlue};
        color: ${colors.white};
      }
  
      .disabled-container{
        background-color: ${colors.rhBlue};
        opacity: 55%;
        :hover {
          cursor: inherit;
        }
      }
  
      .disabled {
        color: ${colors.white};
      }
    }
  }
`