import styled from 'styled-components';
import colors from 'styles/colors';

export const AbaEstipulanteWrapper = styled.div`

  @media (max-height: 580px) {
    display: grid;
    grid-template-rows: 17.342px 1fr 26.68px;
    row-gap: 45px;
  
    .aba-container {
      display: grid;
      grid-template-rows: 17.342px 1fr;
      row-gap: 26.68px;
  
      .aba-title-container {
        span {
          font-size: 12px;
          font-family: Avenir Next GEO W05 Bold;
          color: ${colors.genoaDarkBlue};
        }
      }
  
      .aba-input-container {
        width: 100%;
        height: 100%;
  
        display: flex;
        flex-direction: column;
        row-gap: 16.675px;
  
        .aba-input-row-select {
          width: 70%;
          min-width: 244px;
  
          .options__wrapper {
            font-size: 9.338px;
          }
        }
  
        .aba-input-row-table {
          width: 70%;
          table {
            
            
            display: flex;
            flex-direction: column;
            width: 100%;
            thead {
              border-top-left-radius: 4px;
              border-top-right-radius: 4px;
              background-color: ${colors.rhBlue};
              padding: 5.336px 10px;
              color: white;
              tr {
                font-size: 9.338px;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr
              }
              th:first-child {
                text-align: left;
              }
            }
  
            tbody {
              background-color: white;
              display: flex;
              padding-top: 2px;
              flex-direction: column;
              max-height: 133.4px;
              overflow-y: auto;
  
              tr {
                font-size: 9.338px;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                padding: 4.67px 10px;
                background-color: #89a9cc1c;
                table-layout: fixed;
  
              }
  
              tr:nth-child(2n) {
                background-color: white;
              }
  
              td:last-child {
                display: flex;
                justify-content: center;
              }
  
              ::-webkit-scrollbar {
  
                width: 4px;
                background: transparent;
              }
              ::-webkit-scrollbar-thumb {
                background: ${colors.rhBlue};
                box-shadow: none;
                border-radius: 9.338px;
              }
              ::-webkit-scrollbar-track {
                box-shadow: inset 0 0 2px grey;
                border-radius: 9.338px;
              }
            }
          }
        }
        
      }
    }
  }

  @media (min-height: 580px) and (max-height: 650px) {
    display: grid;
    grid-template-rows: 20.8px 1fr 32px;
    row-gap: 36px;
  
    .aba-container {
      display: grid;
      grid-template-rows: 20.8px 1fr;
      row-gap: 32px;
  
      .aba-title-container {
        span {
          font-size: 14.4px;
          font-family: Avenir Next GEO W05 Bold;
          color: ${colors.genoaDarkBlue};
        }
      }
  
      .aba-input-container {
        width: 100%;
        height: 100%;
  
        display: flex;
        flex-direction: column;
        row-gap: 20px;
  
        .aba-input-row-select {
          width: 70%;
          min-width: 292.8px;
  
          .options__wrapper {
            font-size: 11.2px;
          }
        }
  
        .aba-input-row-table {
          width: 70%;
          table {
            
            
            display: flex;
            flex-direction: column;
            width: 100%;
            thead {
              border-top-left-radius: 4.8px;
              border-top-right-radius: 4.8px;
              background-color: ${colors.rhBlue};
              padding: 6.4px 12px;
              color: white;
              tr {
                font-size: 11.2px;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr
              }
              th:first-child {
                text-align: left;
              }
            }
  
            tbody {
              background-color: white;
              display: flex;
              padding-top: 2.4px;
              flex-direction: column;
              max-height: 160px;
              overflow-y: auto;
  
              tr {
                font-size: 11.2px;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                padding: 5.6px 12px;
                background-color: #89a9cc1c;
                table-layout: fixed;
  
              }
  
              tr:nth-child(2n) {
                background-color: white;
              }
  
              td:last-child {
                display: flex;
                justify-content: center;
              }
  
              ::-webkit-scrollbar {
  
                width: 4.8px;
                background: transparent;
              }
              ::-webkit-scrollbar-thumb {
                background: ${colors.rhBlue};
                box-shadow: none;
                border-radius: 11.2px;
              }
              ::-webkit-scrollbar-track {
                box-shadow: inset 0 0 2px grey;
                border-radius: 11.2px;
              }
            }
          }
        }
        
      }
    }
  }

  @media (min-height: 650px) and (max-height: 720px) {
    display: grid;
    grid-template-rows: 23.4px 1fr 36px;
    row-gap: 40.5px;
  
    .aba-container {
      display: grid;
      grid-template-rows: 23.4px 1fr;
      row-gap: 36px;
  
      .aba-title-container {
        span {
          font-size: 16.2px;
          font-family: Avenir Next GEO W05 Bold;
          color: ${colors.genoaDarkBlue};
        }
      }
  
      .aba-input-container {
        width: 100%;
        height: 100%;
  
        display: flex;
        flex-direction: column;
        row-gap: 22.5px;
  
        .aba-input-row-select {
          width: 70%;
          min-width: 329.4px;
  
          .options__wrapper {
            font-size: 12.6px;
          }
        }
  
        .aba-input-row-table {
          width: 70%;
          table {
            
            
            display: flex;
            flex-direction: column;
            width: 100%;
            thead {
              border-top-left-radius: 5.4px;
              border-top-right-radius: 5.4px;
              background-color: ${colors.rhBlue};
              padding: 7.2px 13.5px;
              color: white;
              tr {
                font-size: 12.6px;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr
              }
              th:first-child {
                text-align: left;
              }
            }
  
            tbody {
              background-color: white;
              display: flex;
              padding-top: 2.7px;
              flex-direction: column;
              max-height: 180px;
              overflow-y: auto;
  
              tr {
                font-size: 12.6px;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                padding: 6.3px 13.5px;
                background-color: #89a9cc1c;
                table-layout: fixed;
  
              }
  
              tr:nth-child(2n) {
                background-color: white;
              }
  
              td:last-child {
                display: flex;
                justify-content: center;
              }
  
              ::-webkit-scrollbar {
  
                width: 5.4px;
                background: transparent;
              }
              ::-webkit-scrollbar-thumb {
                background: ${colors.rhBlue};
                box-shadow: none;
                border-radius: 12.6px;
              }
              ::-webkit-scrollbar-track {
                box-shadow: inset 0 0 1.8px grey;
                border-radius: 12.6px;
              }
            }
          }
        }
        
      }
    }
  }

  @media (min-height: 720px) {
    display: grid;
    grid-template-rows: 26px 1fr 40px;
    row-gap: 45px;
  
    .aba-container {
      display: grid;
      grid-template-rows: 26px 1fr;
      row-gap: 40px;
  
      .aba-title-container {
        span {
          font-size: 18px;
          font-family: Avenir Next GEO W05 Bold;
          color: ${colors.genoaDarkBlue};
        }
      }
  
      .aba-input-container {
        width: 100%;
        height: 100%;
  
        display: flex;
        flex-direction: column;
        row-gap: 25px;
  
        .aba-input-row-select {
          width: 70%;
          min-width: 366px;
  
          .options__wrapper {
            font-size: 14px;
          }
        }
  
        .aba-input-row-table {
          width: 70%;
          table {
            
            
            display: flex;
            flex-direction: column;
            width: 100%;
            thead {
              border-top-left-radius: 6px;
              border-top-right-radius: 6px;
              background-color: ${colors.rhBlue};
              padding: 8px 15px;
              color: white;
              tr {
                font-size: 14px;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr
              }
              th:first-child {
                text-align: left;
              }
            }
  
            tbody {
              background-color: white;
              display: flex;
              padding-top: 3px;
              flex-direction: column;
              max-height: 200px;
              overflow-y: auto;
  
              tr {
                font-size: 14px;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                padding: 7px 15px;
                background-color: #89a9cc1c;
                table-layout: fixed;
  
              }
  
              tr:nth-child(2n) {
                background-color: white;
              }
  
              td:last-child {
                display: flex;
                justify-content: center;
              }
  
              ::-webkit-scrollbar {
  
                width: 6px;
                background: transparent;
              }
              ::-webkit-scrollbar-thumb {
                background: ${colors.rhBlue};
                box-shadow: none;
                border-radius: 14px;
              }
              ::-webkit-scrollbar-track {
                box-shadow: inset 0 0 2px grey;
                border-radius: 14px;
              }
            }
          }
        }
        
      }
    }
  }
`