import React from 'react';
import { CardWrapper } from './styles-TicketCard';
import { ReactComponent as TicketTag } from 'assets/imgs/svg/ticket-tag.svg';
import { ReactComponent as EstipulanteIcon } from 'assets/imgs/svg/estipulante-tag.svg';
import { useLocation, useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import toast from 'react-hot-toast';
import { useUrgentDemandsTickets } from 'contexts/urgentDemandsTickets';
import services from 'apps/broker/services';

const TicketCard = ({ ticket, setOpenDrawer, getDemandasUrgentes }) => {
  const history = useHistory();
  const location = useLocation();
  const { setUrgentDemandsTickets } = useUrgentDemandsTickets();
  const [loading, setLoading] = React.useState(false);


  const renderDaysAmount = () => {
    const today = new Date().setHours(0, 0, 0, 0);
    const createdAt = new Date(ticket?.created_at)?.setHours(0, 0, 0, 0);
    const subtraction = today - createdAt;
    const daysExtracted = subtraction / (1000 * 60 * 60 * 24);
    return daysExtracted;
  };

  const dispatchUrgentDemand = () => {
    const newState = {
      redirected: true,
      page: 'tickets',
      demand_id: ticket?.ticket_id
    };

    setUrgentDemandsTickets(newState);

    if (location?.pathname !== '/corretor/gestao-tickets') {
      history.push('/corretor/gestao-tickets');
    } else {
      setOpenDrawer(false);
    }
  };

  const closeTicket = async () => {
    // console.log(ticket)
    if (!ticket.seguradora_id) {
      toast.error('É necessário incluir o nome da seguradora para concluir', {
        style: {
          fontFamily: 'Avenir Next Geo W05 Demi'
        }
      });
    } else {
      setLoading(true);

      const { ticket_id } = ticket;
      await services.tickets.updateStatusToClose(ticket_id);
      getDemandasUrgentes();
      setLoading(false);
    }
  };

  return (
    <CardWrapper>
      <div className="title-container">
        <div className="card-title">
          <TicketTag className="ticket-icon" />
          <span className="nome">{ticket?.assunto}</span>
        </div>
        <div className="card-title">
          <EstipulanteIcon className="estipulante-icon" />
          <span className="estipulante">
            {ticket?.estipulante_razao_social}
          </span>
        </div>
      </div>
      <div className="value-container">
        <span className="value">Aberto há {renderDaysAmount()} dias</span>
        <button onClick={dispatchUrgentDemand}>Ir para ticket</button>
      </div>
      <div className="value-container">
        {ticket.estipulante_razao_social ===
        'GENOA CORRETORA DE SEGUROS LTDA' ? null : loading ? (
          <CircularProgress thickness={2} className="loading-spinner" />
        ) : (
          <button onClick={closeTicket}>Resolver</button>
        )}
      </div>
    </CardWrapper>
  );
};

export default TicketCard;
