import React, { useEffect, useState } from "react";
import { ReactComponent as LogoGenoa } from "assets/imgs/svg/logo-genoa-login-rh.svg";
import { ReactComponent as WorkerGenoa } from "assets/imgs/svg/worker-genoa-login-rh.svg";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import api from "services/api";
import { Backdrop, Button, CircularProgress, makeStyles } from "@material-ui/core";
import { LoginGrid, LoginInputColumn, LogoRow, SecondRow, Wrapper } from "./styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const RecuperarSenhaRh = () => {
  const userKey = "jwt_token_user";
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [loading, setLoading] = React.useState(false);
  
  const [errorRh, setErrorRh] = useState();
  const [resp, setResp] = useState()

  const [userCredential, setUserCredential] = useState("");

  const RHonSubmit = data => {
    setLoading(true);
    const body = {
      email: userCredential,
    }
    api.post('/recuperar-senha/rh', body)
      .then(resp => {
        setErrorRh()
        setResp("Solicitação enviada com sucesso para seu email")
        setTimeout(() => {
          setResp("");
        }, 3000)
      })
      .catch(err => {
        setErrorRh(err.response.data)
        setTimeout(() => {
          setErrorRh("");
        }, 3000)
      }).finally(() => {
        setLoading(false);
      })
  };
  const handleClose = () => {
    setLoading(false);
  };

  const checkSubmit = (e) => {
    if (e.key === "Enter") {
      e.preventDefault()
      RHonSubmit()
    }
  }

  useEffect(() => {
    const json = localStorage.getItem(userKey);
    const userData = JSON.parse(json);

    if (userData?.corretora) {
      history.push("/corretor/home");
    }
    if (userData?.estipulante) {
      history.push("/rh/home");
    }
  }, [history])

  return (
    <Wrapper>
      <Backdrop
        className={classes.backdrop}
        open={loading}
        onClick={handleClose}
      >
        <CircularProgress size={120} thickness={2} color="inherit" />
      </Backdrop>
      <LogoRow>
        <LogoGenoa />
      </LogoRow>
      <SecondRow>
        <LoginGrid>
          <LoginInputColumn>
            <div className="title">
              <span>
                Boas vindas ao
              </span>
              <span className="heavy-title">
                Zeca!
              </span>
            </div>
            <div className="subtitle">
              <span>Insira seu e-mail cadastrado. Nós lhe enviaremos um link para recuperar a senha. </span>
            </div>
            <form autoComplete="off">
              <div className="login-input-wrapper">
                <div className="login-input-container">
                  <label>Email</label>
                  <input
                    type={"text"}
                    value={userCredential}
                    auto-complete="new-email"
                    onKeyDown={checkSubmit}
                    onChange={(e) => setUserCredential(e.target.value)}
                  />
                </div>
              </div>
            </form>
            <Button className="login-button" onClick={RHonSubmit}>
              <span>Enviar</span>
            </Button>

            <div className="flex-center">
              {errorRh && (
                <div className="erro-msg"> {errorRh}</div>
              )}
              {resp && <div className="sucesso-msg">{resp}</div>}
            </div>
          </LoginInputColumn>
          <WorkerGenoa className="worker-img" />
        </LoginGrid>
      </SecondRow>
    </Wrapper>
  )
}

export default RecuperarSenhaRh