export const keysAbaDadosBasicos = [
    'nome',
    'data_nascimento',
    'sexo',
    'cpf',
    'estado_civil',
    'nacionalidade',
    'nome_mae',
    'vinculo',
    'dataVinculo'
  ];
  
  export const keysAbaDadosImc = [
    'peso',
    'altura'
  ]
  
  export const keysAbaScanDocumentos = [
    'documento_scan_rg',
    'scan_comprovante_residencia',
    'scan_carteira_trabalho',
    'scan_carta_inclusao',
    'scan_passaporte',
    'scan_carta_inclusao',
    'scan_carta_permanencia'
  ]
  
  export const keysAbaFichaProposta = [
    'ficha_proposta'
  ]