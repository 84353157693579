import styled from 'styled-components';
import colors from 'apps/broker/styles/colors';

export const Consult = styled.section`
    display: grid;
    grid-template-columns: 350px 3fr 1fr;
    column-gap: 50px;
    align-items: center;
    margin-bottom: 1rem;

    .search{
      height: 75px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      label {
        font-family: "Avenir Next GEO W05 Demi";
      }

      input{
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-family: "Avenir Next GEO W05 Regular";
        padding: 10px;
        padding-left: 20px;
        padding-right: 35px;
        width: 350px;
        border-radius: 20px;
        border: none;
        font-size: 0.7rem;
        margin-top: 12px;
        :focus-visible {
          outline: 1px ${colors.genoaBlue}f5 auto;
        }
      }
    }

    .searchFilter {
      margin-bottom: 7px;
      margin-right: 15px;
      display: flex;
      align-items: center;
      background: transparent;
      color: ${colors.genoaBlue};

      padding: 0.5rem;
      border-radius: 100%;
      border: 1px solid ${colors.genoaBlue};

      font-size: 1.5rem;
      cursor: pointer;

      transition: all 0.2s;

      &:hover {
        background: ${colors.genoaBlue};
        color: ${colors.white};
      }
    }
    .filters{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 75px;
      align-items: flex-end;
      

      .options{
        display: inherit;
        justify-content: flex-start;
        
        .text-icon{
          color: ${colors.genoaGrey}
          display: flex;
          align-items: center;
          cursor: pointer;
          svg{
            margin-left: 5px;
            font-size: 1.2rem;
          }
        }
      }
    }
`;

export const FilterList = styled.section`
  .filters{
    margin-top: 25px;
    .options{
      display: grid;
      grid-template-columns: 1000px 1fr;
      justify-content: space-between;
      padding-bottom: 40px;
      align-items: center;
      
      >div{
        text-align: center;
      }

      .data-order-container{
        justify-self: end;
        padding-right: 15px;  
      }

      .text-icon{
        display: flex;
        align-items: center;
        cursor: pointer;
        color: ${colors.genoaGrey};
        svg{
          margin-left: 5px;
          font-size: 1.2rem;
        }
      }
    }

    .filters-options-container {
      display: grid;
      grid-template-columns: 166px 194px 250px 102px 250px 110px;
      column-gap: 20px;

      .alocados {
        // Removendo css do botão
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;

        display: flex;
        align-self: center;
        justify-self: center;
        color: ${colors.genoaGrey};
        font-family: 'Avenir Next GEO W05 Regular';
        font-size: 14px;
        height: max-content;
        width: max-content;
        border-bottom: 1px solid ${colors.genoaGrey};

        :hover {
          cursor: pointer;
        }
      }

      .ativado {
        color: ${colors.genoaBlue};
        border-bottom-color: ${colors.genoaBlue};
      }
    }

    .MuiSelect-select.MuiSelect-select {
        font-family: 'Avenir Next GEO W05 Regular';
        padding-top: 2px;
        padding-bottom: 1px;
    }
    .estipulante-select-filter > .MuiSelect-select {
      max-width: 300px;
    }
  }

  .date-filter-container {
    display: flex;
    align-items: flex-end;
    height: 75px;
  }

  .date-filter {
    padding-bottom: 2px;

    label {
      font-family: "Avenir Next GEO W05 Demi";
    }

    .MuiFormGroup-root {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
    }
    .MuiFormControl-root {
      border: 0;
      margin: 0;
      display: inline-flex;
      padding: 0;
      position: relative;
      min-width: 0;
      flex-direction: row;
      align-items: flex-end;
    }
    .data-checkbox-container {
      display: flex;
      justify-content: space-evenly;
      flex-direction: row;
      span {
        font-size: 0.7rem;
        height: 1rem;
        display: flex;
        justify-content: center;
      }

      .checkbox-data-group-container {
        display: flex;
        flex-direction: row;
        min-width: 510px;
      }

      .radio-checkbox {
        color: ${colors.lightGrey};
        &.Mui-checked {
          color: ${colors.genoaDarkBlue};
        }
      }
    }

    .datepicker-box-space{
      height: 51px;
      margin-left: 10px;
    }

    .datepicker-group-container {
      margin-top: 3px;
      overflow: hidden;
      transition: all .6s cubic-bezier(0.4, 0, 0.2, 1);
      display: grid;
      grid-template-columns: 125px 125px;
    }

    .datepicker-group-container-hidden {
      margin-top: 3px;
      width: 0px;
      overflow: hidden;
      transition: all .6s cubic-bezier(0.4, 0, 0.2, 1);
      display: grid;
      grid-template-columns: 125px 125px;
    }

    .input-data {
      width: 100px;
      height: 25px;
      border: 2px solid ${colors.lightGrey};
      display: inline-block;
      text-align: center;
      font-size: 0.75rem;
      font-family: 'Avenir Next GEO W05 Regular';
      border-radius: 7px;

      :focus-visible {
        outline: 1px ${colors.genoaBlue} auto;
      }
    }

    .react-datepicker-wrapper {
      display: inline-block;
      padding: 0;
      border: 0;
      width: min-content;
      height: 25px;
      
    }

    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
      background-color: white;
      color: ${colors.genoaBlue};
      font-weight: bold;
    }


    .react-datepicker__day--selected, .react-datepicker__month-text--selected,  .react-datepicker__quarter-text--selected, .react-datepicker__year-text--selected {
      border-radius: 0.3rem;
      background-color: ${colors.genoaBlue};
      color: #fff;
    }

    .datepicker-selector-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      font-size: 0.8rem;

      .span-date-container {
        padding-bottom: 2px;
        font-family: "Avenir Next GEO W05 bold";
        color: ${colors.genoaGrey};
      }
    }
  }
}
`;
