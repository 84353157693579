export const findPreviousAbaDisponivel = (
  currentCount,
  abasObj,
  abasDisponiveis,
  beneficiarioPage
) => {
  const previousCont = currentCount - 1;
  const previousAba = abasObj[previousCont]

  switch (previousAba) {
    case 'ficha proposta':
      if (abasDisponiveis['showFichaProposta']) {
        return previousCont
      }

      return findPreviousAbaDisponivel(
        previousCont,
        abasObj,
        abasDisponiveis,
        beneficiarioPage
      );

    case 'scan documentos':
      if (abasDisponiveis['showScanDocumentos']) {
        return previousCont
      }

      return findPreviousAbaDisponivel(
        previousCont,
        abasObj,
        abasDisponiveis,
        beneficiarioPage
      );

    case 'dados bancarios':
      if (abasDisponiveis['showDadosBancarios']) {
        return previousCont
      }

      return findPreviousAbaDisponivel(
        previousCont,
        abasObj,
        abasDisponiveis,
        beneficiarioPage
      );

    case 'imc':
      if (abasDisponiveis['showDadosImc']) {
        return previousCont;
      }
      return findPreviousAbaDisponivel(
        previousCont,
        abasObj,
        abasDisponiveis,
        beneficiarioPage
      );

    case 'vinculo':
      if (abasDisponiveis['showDadosVinculo']) {
        return previousCont;
      }
      return findPreviousAbaDisponivel(
        previousCont,
        abasObj,
        abasDisponiveis,
        beneficiarioPage
      );

    case 'contato':
      if (abasDisponiveis['showDadosContato']) {
        return previousCont;
      }
      return findPreviousAbaDisponivel(
        previousCont,
        abasObj,
        abasDisponiveis,
        beneficiarioPage
      );

    case 'endereco':
      if (abasDisponiveis['showDadosEndereco']) {
        return previousCont;
      }
      return findPreviousAbaDisponivel(
        previousCont,
        abasObj,
        abasDisponiveis,
        beneficiarioPage
      );

    case 'dados basicos':
      if (abasDisponiveis['showDadosBasicos']) {
        return previousCont;
      }
      return findPreviousAbaDisponivel(
        previousCont,
        abasObj,
        abasDisponiveis,
        beneficiarioPage
      );

    case 'planos':
      if (abasDisponiveis['showDadosPlanos']) {
        return previousCont;
      }
      return findPreviousAbaDisponivel(
        previousCont,
        abasObj,
        abasDisponiveis,
        beneficiarioPage
      );

    case 'escolha titular':
      if (beneficiarioPage === 0 && abasDisponiveis['showSelecioneTitular']) {
        return previousCont;
      }
      return findPreviousAbaDisponivel(
        previousCont,
        abasObj,
        abasDisponiveis,
        beneficiarioPage
      );

    case 'tipo beneficiario':
      if (beneficiarioPage === 0) {
        return previousCont
      }
      return findPreviousAbaDisponivel(
        previousCont,
        abasObj,
        abasDisponiveis,
        beneficiarioPage
      );

    default:
      if (beneficiarioPage === 0) {
        return 0
      }
      return 3
  }
}

export const findNextAbaDisponivel = (
  currentCount,
  abasObj,
  abasDisponiveis,
  beneficiarioPage
) => {
  const nextCount = currentCount + 1
  const nextAba = abasObj[nextCount]

  switch (nextAba) {
    case 'tipo beneficiario':
      if (beneficiarioPage === 0) {
        return nextCount
      }

      return findNextAbaDisponivel(
        nextCount,
        abasObj,
        abasDisponiveis,
        beneficiarioPage
      );

    case 'escolha titular':
      if (beneficiarioPage === 0 && abasDisponiveis['showSelecioneTitular']) {
        return nextCount;
      }
      return findNextAbaDisponivel(
        nextCount,
        abasObj,
        abasDisponiveis,
        beneficiarioPage
      );

    case 'planos':
      // Sempre irá retornar nextCount, pois é uma aba permanentemente necessária
      if (abasDisponiveis['showDadosPlanos']) {
        return nextCount;
      }
      return findNextAbaDisponivel(
        nextCount,
        abasObj,
        abasDisponiveis,
        beneficiarioPage
      );

    case 'dados basicos':
      if (abasDisponiveis['showDadosBasicos']) {
        return nextCount;
      }
      return findNextAbaDisponivel(
        nextCount,
        abasObj,
        abasDisponiveis,
        beneficiarioPage
      );
    case 'endereco':
      if (abasDisponiveis['showDadosEndereco']) {
        return nextCount;
      }
      return findNextAbaDisponivel(
        nextCount,
        abasObj,
        abasDisponiveis,
        beneficiarioPage
      );

    case 'contato':
      if (abasDisponiveis['showDadosContato']) {
        return nextCount;
      }
      return findNextAbaDisponivel(
        nextCount,
        abasObj,
        abasDisponiveis,
        beneficiarioPage
      );

    case 'vinculo':
      if (abasDisponiveis['showDadosVinculo']) {
        return nextCount;
      }
      return findNextAbaDisponivel(
        nextCount,
        abasObj,
        abasDisponiveis,
        beneficiarioPage
      );

    case 'imc':
      if (abasDisponiveis['showDadosImc']) {
        return nextCount;
      }
      return findNextAbaDisponivel(
        nextCount,
        abasObj,
        abasDisponiveis,
        beneficiarioPage
      );

    case 'dados bancarios':
      if (abasDisponiveis['showDadosBancarios']) {
        return nextCount;
      }
      return findNextAbaDisponivel(
        nextCount,
        abasObj,
        abasDisponiveis,
        beneficiarioPage
      );

    case 'scan documentos':
      if (abasDisponiveis['showScanDocumentos']) {
        return nextCount
      }

      return findNextAbaDisponivel(
        nextCount,
        abasObj,
        abasDisponiveis,
        beneficiarioPage
      );

    case 'ficha proposta':

      if (beneficiarioPage === 0 && abasDisponiveis['showFichaProposta']) {
        return nextCount
      }
      return findNextAbaDisponivel(
        nextCount,
        abasObj,
        abasDisponiveis
      );

    default:

      return 0
  }

}