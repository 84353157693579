import './styles.scss';

const TableHead = () => {
  return (
    <div className="table-contracts__header-list">
      <div></div>
      <div>
        <h4>Produtos</h4>
      </div>
      <div>
        <h4>Vidas</h4>
      </div>
      <div>
        <h4>Seguradora</h4>
      </div>
      <div>
        <h4>Apólice</h4>
      </div>
      <div>
        <h4>Aniversário</h4>
      </div>
      <div></div>
      <div></div>
    </div>
  );
}

export default TableHead;
