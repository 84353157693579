import { useState } from 'react';
import { MainContentRelatorios } from './styles-MainContentRelatorio';
import RelatorioMenu from './RelatorioMenu';
import RelatorioConcierge from '../../../ReportsConcierge/RelatorioConcierge';
import RelatorioMovimentacoes from '../../../ReportsMoviments/RelatorioMovimentacoes';

const MainContentRelatorio = () => {
  const [relatorioToDisplay, setRelatorioToDisplay] = useState(undefined);

  const renderAbaRelatorio = () => {
    switch (relatorioToDisplay) {
      case 'concierge':
        return (
          <RelatorioConcierge setRelatorioToDisplay={setRelatorioToDisplay} />
        );

      case 'movimentacoes':
        return (
          <RelatorioMovimentacoes
            setRelatorioToDisplay={setRelatorioToDisplay}
          />
        );

      default:
        return <RelatorioMenu setRelatorioToDisplay={setRelatorioToDisplay} />;
    }
  };

  return <MainContentRelatorios>{renderAbaRelatorio()}</MainContentRelatorios>;
};

export default MainContentRelatorio;
