import { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FiCheckCircle } from 'react-icons/fi';
import { FormSection, MsgSuccess } from './styles';
import { useForm } from 'react-hook-form';
import services from 'apps/broker/services';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  title: {
    fontFamily: 'Avenir Next GEO W05 Demi',
    color: '#3B3838'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography className={classes.title} variant="h6">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const CustomizedDialogs = ({
  openModalAddCorretor,
  setOpenModalAddCorretor
}) => {
  const { register, handleSubmit } = useForm();
  const [success, setSuccess] = useState(false);
  const [load, setLoad] = useState(false);
  const [errorApi, setErrorApi] = useState(false);

  const handleCloseModal = () => {
    setOpenModalAddCorretor(false);
  };

  const onSubmit = async (data) => {
    const body = {
      email: data.email.toLowerCase()
    };

    const response = await services.user.sendEmailToRegisterUser(body);
    if (response.status === 200) {
      setLoad(false);
      setSuccess(true);
    } else {
      setLoad(false);
      setErrorApi(true);
    }
  };

  useEffect(() => {
    setSuccess(false);
  }, [openModalAddCorretor]);

  return (
    <>
      <Dialog
        onClose={handleCloseModal}
        aria-labelledby="customized-dialog-title"
        open={openModalAddCorretor}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleCloseModal}>
          Adicione aqui o e-mail do seu novo corretor.
        </DialogTitle>
        <DialogContent dividers>
          {success ? (
            <MsgSuccess>
              <div className="icon-area">
                <FiCheckCircle />
              </div>
              <div className="message">E-mail enviado com sucesso.</div>
            </MsgSuccess>
          ) : (
            <FormSection>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="div-input">
                  <div>
                    <div className="actual-email">E-mail do corretor.</div>
                    <input
                      className="input-default"
                      {...register('email', {
                        required: true,
                        pattern: '[A-Za-z]+'
                      })}
                    />
                    {errorApi && <span className="error-msg">{errorApi}</span>}
                  </div>
                </div>
                <button type="submit" className="btn">
                  <span>Enviar</span> {load && <CircularProgress size={15} />}{' '}
                </button>
              </form>
            </FormSection>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CustomizedDialogs;
