import { useState, useEffect } from 'react';
import DialogConfirmation from '../DialogConfirmation';
import DialogEditOrReopen from '../DialogEditOrReopen';
import { Wrapper } from './styles';
import { dataDiff } from 'utils/calculateFunctions';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { CircularProgress } from '@material-ui/core';
import moment from 'moment-timezone';
import io from 'socket.io-client';
import api from 'services/api';
import services from 'apps/broker/services';
import { useUser } from 'contexts/user';
import TicketMessages from '../TicketMessages';

const DrawerTicketContent = ({ data, onClose }) => {
  const { user } = useUser();

  const [details, setDetails] = useState([]);
  const [messages, setMessages] = useState([]);
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [reopen, setReopen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [changeStatus, setChangeStatus] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickConfirm = () => {
    setConfirm(true);
  };

  const handleCloseConfirm = () => {
    setConfirm(false);
  };

  const handleReopen = () => {
    setReopen(true);
  };

  const handleCloseReopen = () => {
    setReopen(false);
  };

  const handleEdit = () => {
    setOpenEdit((prevState) => !prevState);
  };

  const mudarStatusClickHandler = () => {
    setChangeStatus((prevState) => !prevState);
  };

  const socket = io(process.env.REACT_APP_BASE_URL_API);

  const getData = async () => {
    setLoading(true);
    // const response = await services.tickets.getByTicketId(data.id_ticket);
    api
      .get('/corretor/ticket', {
        headers: {
          id: data.id_ticket,
          authorization: `Bearer ${user.token}`
        }
      })
      .then((resp) => {
        setDetails(resp.data);
        const orderMessages = resp.data?.messages?.sort((a, b) => {
          if (a.created_at > b.created_at) {
            return -1;
          }
          return 1;
        });

        setMessages(orderMessages);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const closeTicket = async () => {
    const { id_ticket } = data;
    const response = await services.tickets.updateStatusToCloseByIdTicket(
      id_ticket
    );
    if (response.status === 200) {
      setChangeStatus(false);
      getData();
      handleClose();
    }
  };

  const archiveTicket = async () => {
    const { id_ticket } = data;
    const response = await services.tickets.updateShelveByIdTicket(id_ticket);
    if (response.status === 200) {
      getData();
      handleCloseConfirm();
    }
  };

  const handleChangeStatus = async () => {
    const body = {
      ticket: details.id_ticket,
      status: status
    };
    await services.tickets.updateStatusByIdTicket(body);
    getData();
  };

  const reopenTicket = async () => {
    const body = {
      ticket: details.id_ticket,
      status: status
    };

    const response = await services.tickets.updateReopenIdTicket(body);
    if (response.status === 200) {
      getData();
      handleCloseReopen();
    }
  };

  useEffect(() => {
    getData();
  }, []);

  socket.on('updateFront', () => {
    setOpenEdit(!openEdit);
    getData();
  });

  return (
    <Wrapper>
      {!loading ? (
        <>
          <header>
            <div className="back">
              <span onClick={() => onClose(false)} className="icon-back">
                <ArrowBackIcon fontSize="inherit" />
              </span>
            </div>
            <div className="title">
              <h2>Detalhes do Ticket</h2>
              <div className="detalhes-second-row">
                <span className="date">
                  Criado em{' '}
                  {moment(details.created_at).utc().format('DD/MM/YYYY')} (
                  {dataDiff(details.created_at)} dias)
                </span>
                {details.descricao === 'Resolvido' ? (
                  <div className="status-change-handler-container">
                    <div
                      className="width-transition mudar-status-title"
                      onClick={mudarStatusClickHandler}
                      style={{
                        width: changeStatus ? '0px' : '96px'
                      }}
                    >
                      <span>{!changeStatus ? 'Mudar Status' : ''}</span>
                    </div>

                    <span
                      onClick={mudarStatusClickHandler}
                      className="arrow-icon"
                    >
                      {changeStatus ? '>' : '<'}
                    </span>
                    <div
                      className="status-change-options-container width-transition"
                      style={{
                        width: !changeStatus ? '0px' : '200px'
                      }}
                    >
                      {!changeStatus ? (
                        ''
                      ) : (
                        <>
                          <span onClick={handleReopen}>Reabrir Ticket</span>
                          <span onClick={handleClickConfirm}>Arquivar</span>
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </header>
          <TicketMessages
            data={data}
            getData={getData}
            setOpen={setOpen}
            details={details}
            messages={messages}
            setMessages={setMessages}
            handleEdit={handleEdit}
            handleClickConfirm={handleClickConfirm}
            handleReopen={handleReopen}
          />
        </>
      ) : (
        <div className="loading">
          <CircularProgress
            className="circular-spinner"
            size={120}
            thickness={2.3}
          />{' '}
          <p>Carregando...</p>
        </div>
      )}
      <DialogConfirmation
        open={open}
        onClose={handleClose}
        question={'Confirma o encerramento do ticket?'}
        confirm={closeTicket}
      />

      <DialogConfirmation
        open={confirm}
        onClose={handleCloseConfirm}
        question={'Confirma o arquivamento do ticket?'}
        confirm={archiveTicket}
      />

      <DialogEditOrReopen
        open={reopen}
        onClose={handleCloseReopen}
        question={'Confirma a reabertura do ticket?'}
        status={status}
        setStatus={setStatus}
        confirm={reopenTicket}
      />

      <DialogEditOrReopen
        open={openEdit}
        onClose={handleEdit}
        question={'Alterar o status do ticket'}
        status={status}
        setStatus={setStatus}
        confirm={handleChangeStatus}
      />
    </Wrapper>
  );
};

export default DrawerTicketContent;
