import { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import parse from 'html-react-parser';
import { dataDiff } from 'utils/calculateFunctions';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { BiFileBlank } from 'react-icons/bi';
import { FaAngleDoubleRight, FaPen } from 'react-icons/fa';
// import { ReactComponent as IconTooth } from "assets/imgs/svg/teeth.svg";
import { ModalEditTicket, Wrapper } from './stylesDrawer';
import { Dialog, CircularProgress } from '@material-ui/core';
import TextEditor from 'components/TextEditor';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import api from 'services/api';
import services from 'apps/hr/services';
import { useUser } from 'contexts/user';

const DrawerTicketExclusao = ({ data, getDataAll, onClose, closeDrawer }) => {
  const { user } = useUser();

  const [ticket, setTicket] = useState({});
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [files, setFiles] = useState([]);
  const [openEditor, setOpenEditor] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [status, setStatus] = useState();

  const handleEdit = (e) => {
    e.preventDefault();
    setOpenEdit(!openEdit);
  };

  function getData() {
    setLoading(true);
    api
      .get('/rh/ticket', {
        headers: {
          id: data.id_ticket,
          authorization: `Bearer ${user.token}`
        }
      })
      .then((resp) => {
        setTicket(resp.data.ticket);
        setDetails(resp.data.beneficiarios);

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const beneficiarioType = {
    Titular: (titular) => formatTitular(titular),
    Dependente: (dependente) => formatDependentes(dependente)
  };

  // const seguros = {
  //   1: <BiPlusCircle />,
  //   2: <IconTooth />,
  //   3: <BiMale />,
  // };

  function formatTitular(titular) {
    return (
      <div className="details">
        <h3>Titular</h3>
        <p>Nome : {titular.nome}</p>
        {titular.dados.data_contratacao ? (
          <p>
            Data de contratação :{' '}
            {moment(titular.dados.data_contratacao).utc().format('DD/MM/YYYY')}
          </p>
        ) : null}
        {titular.dados.logradouro ? (
          <p>
            Endereço: {titular.dados.logradouro},{' '}
            {titular.dados.numero ? `nº ${titular.dados.numero} - ` : null}
            {titular.dados.cep && titular.dados.complemento
              ? `${titular.dados.complemento} - `
              : titular.dados.complemento}
            {titular.dados.cep}
          </p>
        ) : null}
        {titular.dados.data_nascimento ? (
          <p>
            Data de nascimento:{' '}
            {moment(titular.dados.data_nascimento).utc().format('DD/MM/YYYY')}
          </p>
        ) : null}
        {titular.dados.cpf ? <p>CPF: {titular.dados.cpf}</p> : null}
        {titular.dados.rg ? (
          <p>
            RG: {titular.dados.rg} {titular.dados.orgao_emissor}{' '}
            {moment(titular.dados.data_emissao).utc().format('DD/MM/YYYY')}
          </p>
        ) : null}
        {titular.dados.telefone ? (
          <p>Telefone: {titular.dados.telefone}</p>
        ) : null}
        {titular.dados.sexo ? <p>Sexo: {titular.dados.sexo}</p> : null}
        {titular.dados.estado_civil ? (
          <p>Estado Civil: {titular.dados.estado_civil}</p>
        ) : null}
        {titular.dados.pis_pasep ? (
          <p>PIS/PASEP: {titular.dados.pis_pasep}</p>
        ) : null}
        {titular.dados.nome_mae ? (
          <p>Nome da mãe: {titular.dados.nome_mae}</p>
        ) : null}
        {titular.dados.banco ? (
          <p>
            Dados bancarios: {titular.dados.banco} {titular.dados.agencia}{' '}
            {titular.dados.conta}
          </p>
        ) : null}
        {titular.contratos.length > 0
          ? titular.contratos.map((contratos, index) => {
              return (
                <p key={index}>
                  {contratos.nome_produto} - {contratos.tipo_plano}
                </p>
              );
            })
          : null}
      </div>
    );
  }

  function formatDependentes(dependentes) {
    return (
      <div className="details">
        <h3>Dependente</h3>
        {dependentes.dados.data_nascimento ? (
          <p>
            Data de nascimento:{' '}
            {moment(dependentes.dados.data_nascimento)
              .utc()
              .format('DD/MM/YYYY')}
          </p>
        ) : null}
        {dependentes.dados.cpf ? <p>CPF: {dependentes.dados.cpf}</p> : null}
        {dependentes.dados.rg ? (
          <p>
            RG: {dependentes.dados.rg} {dependentes.dados.orgao_emissor}{' '}
            {moment(dependentes.dados.data_emissao).utc().format('DD/MM/YYYY')}
          </p>
        ) : null}
        {dependentes.contratos.length > 0
          ? dependentes.contratos.map((contratos, index) => {
              return (
                <p key={index}>
                  {contratos.nome_produto}{' '}
                  {contratos.tipo_plano ? `- ${contratos.tipo_plano}` : null}
                </p>
              );
            })
          : null}
      </div>
    );
  }

  const aprovar = () => {
    const headers = {
      ticket: data.id_ticket,
      estipulante: user.estipulante
    };
    const body = {
      resposta: 'Aceitar',
      beneficiarios: []
    };
    details.map((beneficiario, index) => {
      return body.beneficiarios.push(beneficiario.uuid);
    });
    api
      .post('/rh/aceitar-movimentacao', body, { headers: headers })
      .then((resp) => {
        getData();
        getDataAll();
      })
      .catch((err) => console.log(err));
  };

  const recusar = () => {
    const headers = {
      ticket: data.id_ticket,
      estipulante: user.estipulante,
      authorization: `Bearer ${user.token}`
    };
    const body = {
      resposta: 'Recusar',
      beneficiarios: []
    };
    details.map((beneficiario, index) => {
      return body.beneficiarios.push(beneficiario.uuid);
    });
    api
      .post('/rh/aceitar-movimentacao', body, { headers: headers })
      .then((resp) => {
        getData();
        getDataAll();
      })
      .catch((err) => console.log(err));
  };

  const editStatus = (e) => {
    e.preventDefault();
    const body = {
      ticket: data.id_ticket,
      status: status
    };
    const headers = {
      id: user.corretora,
      authorization: `Bearer ${user.token}`
    };
    api
      .put('/corretor/ticket/status', body, { headers: headers })
      .then((resp) => {
        getData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sendMessageRh = async () => {
    const htmlMessage = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );

    const formData = new FormData();
    formData.append('mensagem', htmlMessage);
    files.forEach((file, index) => {
      formData.append(index, file);
    });

    const response = await services.tickets.createMessageByHr(
      formData,
      data,
      user,
      'PendenciaRH',
      'pendencia'
    );

    if (response.status === 200) {
      setFiles([]);
      setEditorState(!editorState);
      getData();
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Wrapper>
      {!loading ? (
        <>
          <header>
            <div className="back">
              <span onClick={() => closeDrawer(false)} className="icon-back">
                <ArrowBackIcon fontSize="inherit" />
              </span>
            </div>
            <div className="title">
              <h2>Detalhes do Ticket</h2>
              <span className="date">
                Criado em {moment(ticket.created_at).utc().format('DD/MM/YYYY')}{' '}
                ({dataDiff(ticket.created_at)} dias)
              </span>
            </div>
          </header>
          <section className="infos">
            <div className="status">
              <div className="text-icon">
                <FaAngleDoubleRight /> Status: {ticket.descricao}{' '}
                {ticket.descricao ===
                  'Retorno de pendências - Aguardando Corretora' ||
                ticket.descricao === 'Retorno de pendências - Aguardando RH' ? (
                  <span className="btn-edit">
                    <FaPen onClick={handleEdit} />
                  </span>
                ) : null}
              </div>
            </div>
          </section>
          <section className="bodyDetails">
            <div className="titleTicket">
              {ticket.assunto} - #{ticket.id_ticket}
            </div>
            {details.map((beneficiario, index) => (
              <div className="type-beneficiary" key={index}>
                {beneficiarioType[beneficiario.tipo](beneficiario)}
              </div>
            ))}

            {ticket.arquivos && ticket.arquivos.length > 0 && (
              <div className="files">
                {ticket.arquivos.map((file, index) => (
                  <div key={index} className="file-item">
                    <a href={file.file}>
                      {' '}
                      <BiFileBlank /> <span>{file.file_name}</span>
                    </a>
                  </div>
                ))}
              </div>
            )}

            <div className="messages">
              {ticket.messages?.map((message, index) => (
                <div className="single-message" key={index}>
                  <div className="titleTicket">
                    {message.corretora_id
                      ? message.nome
                      : message.nome + ' ' + message.sobrenome}{' '}
                    em {moment(message.created_at).utc().format('DD/MM/YYYY')}
                  </div>
                  {message.corretora_id ? (
                    <div className="messageText  resp-msg">
                      {
                        <div>
                          {message.file?.map((file, index) => (
                            <div className="messageFile" key={index}>
                              <a
                                href={file.attachment_url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {file.name}
                              </a>
                            </div>
                          ))}
                        </div>
                      }
                      {parse(message.mensagem)}
                    </div>
                  ) : (
                    <div className="messageText  open-msg">
                      <div>
                        {message.file?.map((file, index) => {
                          return (
                            <div className="messageFile" key={index}>
                              <a
                                href={file.attachment_url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {file.name}
                              </a>
                            </div>
                          );
                        })}
                      </div>
                      {parse(message.mensagem)}
                    </div>
                  )}
                </div>
              ))}
            </div>

            {ticket.status_id === 4 && (
              <div className="btn">
                <button
                  className="btn btn-accept"
                  type="submit"
                  onClick={aprovar}
                >
                  Aprovar
                </button>
                <button className="btn btn-refuse" onClick={recusar}>
                  Recusar
                </button>
              </div>
            )}

            <div className="actions">
              {details.status_id !== 3 && (
                <div className="buttons">
                  <button
                    className="newMsg"
                    onClick={() => setOpenEditor(!openEditor)}
                  >
                    + Nova mensagem
                  </button>
                </div>
              )}
            </div>

            {openEditor && (
              <TextEditor
                handleSendMessage={sendMessageRh}
                editorState={editorState}
                setEditorState={setEditorState}
                files={files}
                setFiles={setFiles}
              />
            )}
          </section>
        </>
      ) : (
        <div className="loading">
          <CircularProgress /> <p>Carregando...</p>
        </div>
      )}

      <Dialog
        open={openEdit}
        onClose={handleEdit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ModalEditTicket>
          <div className="title">
            <h3>Alterar o status do ticket</h3>
          </div>
          <form onSubmit={(e) => editStatus(e)}>
            <div className="form-edit">
              <label>Selecione o status do ticket</label>
              <select
                value={status}
                onChange={({ target }) => setStatus(target.value)}
              >
                <option
                  value="Retorno de pendências - Aguardando Corretora"
                  selected
                >
                  Retorno de pendências - Aguardando Corretora
                </option>
                <option value="Retorno de pendências - Aguardando RH">
                  Retorno de pendências - Aguardando RH
                </option>
              </select>
            </div>
            <div className="actions">
              {ticket.status_id === 4 ? (
                <div className="buttons">
                  <button className="btn btn-confirm" type="submit">
                    Confirmar
                  </button>
                  <button
                    className="btn btn-cancel"
                    onClick={(e) => handleEdit(e)}
                  >
                    Cancelar
                  </button>
                </div>
              ) : null}
            </div>
          </form>
        </ModalEditTicket>
      </Dialog>
    </Wrapper>
  );
};

export default DrawerTicketExclusao;
