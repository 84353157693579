import { useEffect, useState } from 'react';
import { FaTooth } from 'react-icons/fa';
import { dataDiffYear } from 'utils/calculateFunctions';
import { GiHealthNormal } from 'react-icons/gi';
import { Checkbox } from '@mui/material';
import { MdOutlineOpenInNew } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

const orderOptions = {
  1: 'Nenhum',
  2: 'Decrescente',
  3: 'Crescente'
};

const TableBody = ({
  editMode,
  estipulantes,
  getData,
  inputNameFilter,
  estipulantesOrderSelected,
  vidasOrderSelected,
  ticketsOrderSelected,
  clientePeriodoOrderSelected,
  selectedCheckboxes,
  setSelectedCheckboxes,
  estipulante,
  setEstipulante
}) => {
  const history = useHistory();

  const [policyOwnerId, setPolicyOwnerId] = useState('');

  const handleData = (estipulante_id) => {
    setPolicyOwnerId(estipulante_id);
    // setEstipulante(estipulante_id);
    // toggleDrawer(true)();
  };

  const clickUniqueCheckboxHandler = ({ target }) => {
    const checked = target.checked;
    const name = target.name;
    if (checked) {
      setSelectedCheckboxes((prevState) => [...prevState, name]);
    }

    if (!checked) {
      setSelectedCheckboxes((prevState) => {
        const filter = prevState.filter((elementName) => elementName !== name);
        return filter;
      });
    }
  };

  const filterEstipulantesByName = (estipulantesArray) => {
    return estipulantesArray.filter((item) => {
      if (!item['razao_social']) {
        return false;
      }
      return item['razao_social']
        .toLowerCase()
        .includes(inputNameFilter.toLowerCase());
    });
  };

  const orderEstipulantesByName = (array) => {
    const order = orderOptions[estipulantesOrderSelected];
    switch (order) {
      case 'Decrescente':
        const orderedDecrescente = array.sort((a, b) => {
          if (!b['razao_social']) {
            return -1;
          }
          if (!a['razao_social']) {
            return 1;
          }
          return b['razao_social']
            .toLowerCase()
            .localeCompare(a['razao_social'].toLowerCase());
        });
        return orderedDecrescente;
      case 'Crescente':
        const orderedCrescente = array.sort((a, b) => {
          if (!b['razao_social']) {
            return -1;
          }
          if (!a['razao_social']) {
            return 1;
          }
          return a['razao_social']
            .toLowerCase()
            .localeCompare(b['razao_social'].toLowerCase());
        });
        return orderedCrescente;
      default:
        return array.sort((a, b) => a.id - b.id);
    }
  };

  const orderEstipulantesByVidas = (estipulantesArray) => {
    const order = orderOptions[vidasOrderSelected];
    switch (order) {
      case 'Decrescente':
        const orderedDecrescente = estipulantesArray.sort(
          (a, b) => b.vidas - a.vidas
        );
        return orderedDecrescente;
      case 'Crescente':
        const orderedCrescente = estipulantesArray.sort(
          (a, b) => a.vidas - b.vidas
        );
        return orderedCrescente;
      default:
        return estipulantesArray;
    }
  };

  const orderEstipulantesByTickets = (estipulantesArray) => {
    const order = orderOptions[ticketsOrderSelected];
    switch (order) {
      case 'Decrescente':
        const orderedDecrescente = estipulantesArray.sort(
          (a, b) => b.tickets - a.tickets
        );
        return orderedDecrescente;
      case 'Crescente':
        const orderedCrescente = estipulantesArray.sort(
          (a, b) => a.tickets - b.tickets
        );
        return orderedCrescente;
      default:
        return estipulantesArray;
    }
  };

  const orderEstipulantesByClienteDesde = (estipulantesArray) => {
    const order = orderOptions[clientePeriodoOrderSelected];
    switch (order) {
      case 'Decrescente':
        const orderedDecrescente = estipulantesArray.sort((a, b) => {
          if (!b['clienteDesde']) {
            return -1;
          }
          if (!a['clienteDesde']) {
            return 1;
          }
          if (new Date(a.clienteDesde) > new Date(b.clienteDesde)) {
            return 1;
          }
          return -1;
        });
        return orderedDecrescente;
      case 'Crescente':
        const orderedCrescente = estipulantesArray.sort((a, b) => {
          if (!b['clienteDesde']) {
            return -1;
          }
          if (!a['clienteDesde']) {
            return 1;
          }
          if (new Date(b.clienteDesde) > new Date(a.clienteDesde)) {
            return 1;
          }
          return -1;
        });
        return orderedCrescente;
      default:
        return estipulantesArray;
    }
  };

  // React.useEffect(() => {
  //   renderEstipulantes();
  // }, [
  //   estipulantesOrderSelected,
  //   vidasOrderSelected,
  //   ticketsOrderSelected,
  //   clientePeriodoOrderSelected
  // ]);

  useEffect(() => {
    const estipulante_id = policyOwnerId;
    history.push(`/corretor/estipulantes/${estipulante_id}`);
  }, [policyOwnerId]);

  const renderEstipulantes = () => {
    if (estipulantes.length) {
      const filtradosPorNome = filterEstipulantesByName(estipulantes);
      const ordenadosPorNome = orderEstipulantesByName(filtradosPorNome);
      const ordenadosPorVida = orderEstipulantesByVidas(ordenadosPorNome);
      const ordenadosPorTickets = orderEstipulantesByTickets(ordenadosPorVida);
      const ordenadosPorClienteDesde =
        orderEstipulantesByClienteDesde(ordenadosPorTickets);
      return ordenadosPorClienteDesde.map((estipulante, index) => {
        return (
          <>
            <div className="item-list">
              <div className="item-list__data">
                <div>
                  {editMode === true ? (
                    <Checkbox
                      className="item-list__data-checkbox"
                      size="small"
                      name={estipulante.cnpj}
                      sx={{
                        color: '#1E2552',
                        '&.Mui-checked': {
                          color: '#1E2552'
                        }
                      }}
                      onChange={clickUniqueCheckboxHandler}
                      checked={selectedCheckboxes.some((checkboxName) => {
                        return checkboxName === estipulante.cnpj;
                      })}
                    />
                  ) : (
                    ''
                  )}
                </div>
                <div>{estipulante.razao_social}</div>
                <div>{estipulante.cnpj}</div>
                <div className="beneficios header-col">
                  <span>
                    {estipulante.contratoSaude ? (
                      <GiHealthNormal size={18} />
                    ) : (
                      ''
                    )}
                  </span>
                  <span>
                    {estipulante.contratoOdonto ? <FaTooth size={18} /> : ''}
                  </span>
                  {/* <span>{estipulante.contratoVida ? <BiMale /> : ""}</span> */}
                </div>
                <div className="content-list__header-list-col">
                  {estipulante.vidas}
                </div>
                <div className="content-list__header-list-col">
                  {estipulante.tickets}
                </div>
                <div className="content-list__header-list-col">
                  {estipulante.clienteDesde
                    ? dataDiffYear(estipulante?.clienteDesde)
                    : '0'}

                  {dataDiffYear(estipulante?.clienteDesde) === 1
                    ? ' Ano'
                    : ' Anos'}
                </div>

                <div className="icon">
                  <button
                    className="btn search"
                    onClick={() => handleData(estipulante.id_estipulante)}
                  >
                    <MdOutlineOpenInNew />
                  </button>
                </div>
              </div>
            </div>
          </>
        );
      });
    }
  };

  return (
    <>
      {renderEstipulantes()}
    </>
  );
};

export default TableBody;
