import React from 'react'

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex', justifyContent: 'center',
    marginBottom: '1rem',
    '& > * + *': { marginLeft: theme.spacing(2) },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 10,
    color: '#fff',
  },
  load: { color: '#62A0E2', }
}));

const Loading = ({open, setOpen}) => {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={open} onClick={setOpen}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}

export default Loading
