import styled from 'styled-components';
import colors from 'styles/colors';

export const AbaDadosBasicosWrapper = styled.div`
  @media (max-height: 580px) {
    display: grid;
    grid-template-rows: 17.342px 1fr 26.68px;
    row-gap: 30px;
  
    .content-title {
      span {
        font-size: 12px;
        color: ${colors.rhBlue};
        font-family: Avenir Next GEO W05 Heavy;
      }
    }
  
    .aba-container {
      display: grid;
      grid-template-rows: 17.342px 1fr;
      row-gap: 40px;
  
      .aba-title-container {
        span {
          font-size: 12px;
          font-family: Avenir Next GEO W05 Bold;
          color: ${colors.genoaDarkBlue};
        }
      }
  
      .aba-input-container {
        width: 100%;
        height: 100%;
  
        display: flex;
        flex-direction: column;
        row-gap: 16.675px;
  
        .aba-input-first-row {
          display: grid;
          width: max-content;
          grid-template-columns: 200px 100px 80px;
          column-gap: 33.35px;
        }
  
        .aba-input-row {
          display: grid;
          width: max-content;
          grid-template-columns: 200px 200px;
          column-gap: 33.35px;
        }
  
        .aba-input-row-nacionalidade {
          display: grid;
          width: max-content;
          grid-template-columns: 140px 100px 140px;
          column-gap: 33.35px;
        }
  
        .aba-input-row-rg {
          display: grid;
          width: max-content;
          grid-template-columns: 140px 100px 140px;
          column-gap: 33.35px;
        }
  
        .aba-input-row-vinculo {
          display: grid;
          width: max-content;
          grid-template-columns: 200px 100px;
          column-gap: 33.35px;
        }
  
        .aba-input-last-row {
          display: grid;
          width: 70%;
          grid-template-columns: 120px 200px;
          column-gap: 33.35px;
        }
  
      }
    }
  }

  @media (min-height: 580px) and (max-height: 650px) {
    display: grid;
    grid-template-rows: 20.8px 1fr 32px;
    row-gap: 36px;
  
    .content-title {
      span {
        font-size: 14.4px;
        color: ${colors.rhBlue};
        font-family: Avenir Next GEO W05 Heavy;
      }
    }
  
    .aba-container {
      display: grid;
      grid-template-rows: 20.8px 1fr;
      row-gap: 48px;
  
      .aba-title-container {
        span {
          font-size: 14.4px;
          font-family: Avenir Next GEO W05 Bold;
          color: ${colors.genoaDarkBlue};
        }
      }
  
      .aba-input-container {
        width: 100%;
        height: 100%;
  
        display: flex;
        flex-direction: column;
        row-gap: 20px;
  
        .aba-input-first-row {
          display: grid;
          width: max-content;
          grid-template-columns: 240px 120px 108px;
          column-gap: 40px;
        }
  
        .aba-input-row {
          display: grid;
          width: max-content;
          grid-template-columns: 240px 240px;
          column-gap: 40px;
        }
  
        .aba-input-row-nacionalidade {
          display: grid;
          width: max-content;
          grid-template-columns: 168px 120px 168px;
          column-gap: 40px;
        }
  
        .aba-input-row-rg {
          display: grid;
          width: max-content;
          grid-template-columns: 168px 120px 168px;
          column-gap: 40px;
        }
  
        .aba-input-row-vinculo {
          display: grid;
          width: max-content;
          grid-template-columns: 240px 96px;
          column-gap: 40px;
        }
  
        .aba-input-last-row {
          display: grid;
          width: 70%;
          grid-template-columns: 96px 160px;
          column-gap: 40px;
        }
  
      }
    }
  }

  @media (min-height: 650px) and (max-height: 720px) {
    display: grid;
    grid-template-rows: 23.4px 1fr 36px;
    row-gap: 45px;
  
    .content-title {
      span {
        font-size: 16.2px;
        color: ${colors.rhBlue};
        font-family: Avenir Next GEO W05 Heavy;
      }
    }
  
    .aba-container {
      display: grid;
      grid-template-rows: 23.4px 1fr;
      row-gap: 54px;
  
      .aba-title-container {
        span {
          font-size: 16.2px;
          font-family: Avenir Next GEO W05 Bold;
          color: ${colors.genoaDarkBlue};
        }
      }
  
      .aba-input-container {
        width: 100%;
        height: 100%;
  
        display: flex;
        flex-direction: column;
        row-gap: 22.5px;
  
        .aba-input-first-row {
          display: grid;
          width: max-content;
          grid-template-columns: 270px 135px 108px;
          column-gap: 45px;
        }
  
        .aba-input-row {
          display: grid;
          width: max-content;
          grid-template-columns: 270px 270px;
          column-gap: 45px;
        }
  
        .aba-input-row-nacionalidade {
          display: grid;
          width: max-content;
          grid-template-columns: 189px 135px 189px;
          column-gap: 45px;
        }
  
        .aba-input-row-rg {
          display: grid;
          width: max-content;
          grid-template-columns: 189px 135px 189px;
          column-gap: 45px;
        }
  
        .aba-input-row-vinculo {
          display: grid;
          width: max-content;
          grid-template-columns: 270px 135px;
          column-gap: 45px;
        }
  
        .aba-input-last-row {
          display: grid;
          width: 70%;
          grid-template-columns: 108px 180px;
          column-gap: 45px;
        }
  
      }
    }
  }

  @media (min-height: 720px) {
    display: grid;
    grid-template-rows: 26px 1fr 40px;
    row-gap: 45px;
  
    .content-title {
      span {
        font-size: 18px;
        color: ${colors.rhBlue};
        font-family: Avenir Next GEO W05 Heavy;
      }
    }
  
    .aba-container {
      display: grid;
      grid-template-rows: 26px 1fr;
      row-gap: 60px;
  
      .aba-title-container {
        span {
          font-size: 18px;
          font-family: Avenir Next GEO W05 Bold;
          color: ${colors.genoaDarkBlue};
        }
      }
  
      .aba-input-container {
        width: 100%;
        height: 100%;
  
        display: flex;
        flex-direction: column;
        row-gap: 25px;
  
        .aba-input-first-row {
          display: grid;
          width: max-content;
          grid-template-columns: 300px 150px 120px;
          column-gap: 50px;
        }
  
        .aba-input-row {
          display: grid;
          width: max-content;
          grid-template-columns: 300px 300px;
          column-gap: 50px;
        }
  
        .aba-input-row-nacionalidade {
          display: grid;
          width: max-content;
          grid-template-columns: 210px 150px 210px;
          column-gap: 50px;
        }
  
        .aba-input-row-rg {
          display: grid;
          width: max-content;
          grid-template-columns: 210px 150px 210px;
          column-gap: 50px;
        }
  
        .aba-input-row-vinculo {
          display: grid;
          width: max-content;
          grid-template-columns: 300px 150px;
          column-gap: 50px;
        }
  
        .aba-input-last-row {
          display: grid;
          width: 70%;
          grid-template-columns: 120px 200px;
          column-gap: 50px;
        }
  
      }
    }
  }
`