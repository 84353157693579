import BottomBar from 'apps/broker/components/HorizontalBar/bottomBar';
import TopBar from 'apps/broker/components/HorizontalBar/topBar';
import { Div } from './styles-Subs';

const Subs = ({ estipulantes }) => {
  return (
    <Div>
      <div>
        <TopBar title={'Estipulantes e distribuição de vidas'} />
        <div className="estipulantes-info">
          <div className="cols-estipulantes-info">
            <div>
              {estipulantes?.map((estipulante, index) => {
                return (
                  <div className="estipulante-name">
                    <span>{estipulante.razao_social}</span>
                  </div>
                );
              })}
            </div>
            <div>
              {estipulantes?.map((estipulante, index) => {
                return (
                  <div className="estipulante-vidas">
                    {estipulante &&
                    estipulante.vidas &&
                    estipulante.vidas.qnt &&
                    estipulante.vidas.qnt > 0
                      ? estipulante.vidas.qnt > 1
                        ? `${estipulante.vidas.qnt} vidas`
                        : `1 vida`
                      : 'Sem vidas'}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <BottomBar />
    </Div>
  );
};

export default Subs;
