import { createContext, useContext, useState } from "react";

export const FiltersContext = createContext();

const HOME_DEFAULT_FILTERS = {
  redirected: false,
  custom_dates: {
    initial_date: "",
    final_date: "",
  },
  ticket_status: "criados",
  date_filter: "desde inicio",
  ticket_type: "total",
  date_atuation: "all",
  estipulante: "all",
  corretor: "all"
}
    
export const FiltersContextProvider = ({ children }) => {
  const [homeFilters, setHomeFilters] = useState(HOME_DEFAULT_FILTERS);

  const updateHomeFilters = (data) => {
    setHomeFilters({
      ...homeFilters,
      ...data
    });
  }

  const resetHomeFilters = () => {
    setHomeFilters(HOME_DEFAULT_FILTERS);
  }

  return (
    <FiltersContext.Provider value={{ homeFilters, updateHomeFilters, resetHomeFilters }}>
      {children}
    </FiltersContext.Provider>
  );
};

export const useFilters = () => useContext(FiltersContext);
