import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import {
  FormControl,
  MenuItem,
  Select,
  SwipeableDrawer,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import HeaderRh from "apps/hr/components/HeaderRH";
import MainContainer from "apps/hr/components/MainContainer";
import MenuRh from "apps/hr/components/MenuRh";
import FooterPainel from "apps/hr/components/FooterPainel";

import api from "services/api";
import { dataDiff } from "utils/calculateFunctions";

import { AiOutlineMail } from "react-icons/ai";
import { FaAngleDoubleRight, FaUserAlt } from "react-icons/fa";

import DrawerTicketContent from "./DrawerTicketContent";
import DrawerTicketMovimentacao from "./DrawerTicketMovimentacao";
import DrawerTicketExclusao from "./DrawerTicketExclusao";

import { HeaderTicket, Container, ContentList } from "./styles";
import { BiSortDown, BiSearchAlt } from "react-icons/bi";

const useStyles = makeStyles({
  BackdropProps: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    maxWidth: 1000,
  },
  fullList: {
    width: "auto",
  },
  formControl: {
    minWidth: 120,
  },
});

const Tickets = (props) => {
  const classes = useStyles();
  const user = useSelector((state) => state.userReducers.user);

  const [tickets, setTickets] = useState([]);
  const [ticketsFilter, setTicketsFilter] = useState([]);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawerContent, setDrawerContent] = useState(false);
  const [ordered, setOrdered] = useState(false);

  const [clickBtnSearch, setClickBtnSearch] = useState(false);

  const [addFilters, setAddFilters] = useState({
    ticket: "tickets",
    status: "all",
    id_ticket: "",
  });

  const Drawers = {
    Rh: (ticket) => {
      return (
        <DrawerTicketContent
          data={ticket}
          getDataAll={getData}
          onClose={setOpenDrawer}
          closeDrawer={setOpenDrawer}
        />
      );
    },
    Movimentação: (ticket) => {
      return (
        <DrawerTicketMovimentacao
          data={ticket}
          getDataAll={getData}
          onClose={setOpenDrawer}
          closeDrawer={setOpenDrawer}
        />
      );
    },
    Exclusão: (ticket) => {
      return (
        <DrawerTicketExclusao
          data={ticket}
          getDataAll={getData}
          onClose={setOpenDrawer}
          closeDrawer={setOpenDrawer}
        />
      );
    },
    undefined: () => {
      return;
    },
  };

  function getData() {
    // const result = user.subEstipulante;
    // const formatId = result.map((result) => result.id);
    // const body = JSON.stringify(formatId);
    api
      .get("/rh/tickets", {
        headers: {
          id: user.estipulante,
          // subid: body,
          authorization: `Bearer ${user.token}`,
        },
      })
      .then((resp) => {
        // console.log(resp.data);
        setTickets(resp.data);
        const dataFilter = resp.data.filter((ticket) => ticket.status_id !== 3 && ticket.status_id !== 7);
        setTicketsFilter(dataFilter);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const toggleDrawer = (open, drawer, ticket) => (event) => {
    if (event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (event && event.type === "keydown") {
      return;
    }

    setDrawerContent(Drawers[drawer](ticket));
    setOpenDrawer(open);
  };

  // function filterArray(str) {
  //   if (str.length === 0) {
  //     setTicketsFilter(tickets);
  //     return;
  //   }
  //   const filter = ticketsFilter.filter((ticket) => {
  //     return ticket.id_ticket.toLowerCase().startsWith(str.toLowerCase());
  //   });
  //   setTicketsFilter(filter);
  // }

  const content = () => (
    <div
      className={classes.content}
      role="presentation"
      onKeyDown={toggleDrawer(false)}
    >
      {drawerContent}
    </div>
  );

  const handleDrawerTicket = async (ticket, tipo) => {
    toggleDrawer(true, tipo, ticket)();
  };

  const orderBy = () => {
    let order = ticketsFilter.sort((a, b) => {
      let dateA = new Date(a.created_at).getTime();
      let dateB = new Date(b.created_at).getTime();
      if (!ordered) {
        return dateA > dateB ? 1 : -1;
      }
      return dateA < dateB ? 1 : -1;
    });
    setTicketsFilter([...order]);
    setOrdered(!ordered);
  };

  const handleAllFiltersSearch = ({ target }) => {
    if (
      target.name === "id_ticket" ||
      target.name === "status" ||
      target.name === "ticket"
    ) {
      setAddFilters({
        ...addFilters,
        [target.name]: target.value,
      });
    }
  };

  const resetFiltersOnClick = () => {
    setAddFilters({
      ticket: "tickets",
      status: "all",
      id_ticket: "",
    });
  };

  const searchFiltersOnClick = () => {
    setClickBtnSearch(false);

    if (addFilters.status === "all" && addFilters.ticket === "tickets") {
      if(addFilters.id_ticket === '') {
        setClickBtnSearch(true);
      }
      const filtersAddResults = tickets
        .filter((item) =>
          item.id_ticket
            .toLowerCase()
            .startsWith(addFilters.id_ticket.toLowerCase())
        )
        .filter((item) => [1, 4].includes(item.status_id));
      setTicketsFilter(filtersAddResults);
      return resetFiltersOnClick();
    } else if (addFilters.status !== "" && addFilters.ticket !== "tickets") {
      const filtersAddResults = tickets
        .filter((item) =>
          item.id_ticket
            .toLowerCase()
            .startsWith(addFilters.id_ticket.toLowerCase())
        )
        .filter((item) => addFilters.ticket === item.tipo_ticket)
        .filter((item) =>
          addFilters.status === "all"
            ? [1, 4].includes(item.status_id)
            : addFilters.status === item.status_id
        );

      setTicketsFilter(filtersAddResults);
      return resetFiltersOnClick();
    } else if (addFilters.ticket === "tickets" && addFilters.status !== "") {
      const filtersAddResults = tickets
        .filter((item) =>
          item.id_ticket
            .toLowerCase()
            .startsWith(addFilters.id_ticket.toLowerCase())
        )
        .filter((item) =>
          addFilters.status === "all"
            ? [1, 4].includes(item.status_id)
            : addFilters.status === item.status_id
        );

      setTicketsFilter(filtersAddResults);
      return resetFiltersOnClick();
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <HeaderRh />
      <MenuRh />
      <MainContainer>
        <Container>
          <HeaderTicket>
            <div className="title">
              <h1>Consulta por Tickets</h1>
            </div>
            <div className="search">
              <input
                type="text"
                name="id_ticket"
                value={addFilters.id_ticket}
                placeholder="Pesquisar Tickets por ID..."
                onChange={(e) => handleAllFiltersSearch(e)}
              ></input>
            </div>

            <ContentList>

            <div className="filters">
              <div className="options">
                <div>
                  <span className="text-icon" onClick={orderBy}>
                    {" "}
                    Data <BiSortDown />
                  </span>
                </div>
                <div>
                  <FormControl className={classes.formControl}>
                    <Select
                      value={addFilters.ticket}
                      onClick={(e) => handleAllFiltersSearch(e)}
                      displayEmpty
                      disableUnderline={true}
                      className={classes.select}
                      name="ticket"
                    >
                      <MenuItem value="tickets"> Tickets</MenuItem>
                      <MenuItem value="Movimentação">Inclusão</MenuItem>
                      <MenuItem value="Exclusão">Exclusão</MenuItem>
                      <MenuItem value="Rh">Outros</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div>
                  <FormControl className={classes.formControl}>
                    <Select
                      value={addFilters.status}
                      onClick={(e) => handleAllFiltersSearch(e)}
                      displayEmpty
                      disableUnderline={true}
                      className={classes.select}
                      name="status"
                    >
                      <MenuItem value="all">Aberto</MenuItem>
                      <MenuItem value={1}>Aguardando corretora</MenuItem>
                      <MenuItem value={4}>Aguardando RH</MenuItem>
                      <MenuItem value={3}>Resolvido</MenuItem>
                      <MenuItem value={7}>Arquivado</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              </div>
            </ContentList>

            <div className="btnFilters">
              <button
                className="searchFilter"
                type="button"
                onClick={() => searchFiltersOnClick()}
              >
                <BiSearchAlt />
              </button>

              {clickBtnSearch ? (
                <p className="resetFilters">* Filtros resetados.</p>
              ) : <p className="resetNone"></p>}
            </div>
          </HeaderTicket>

          {ticketsFilter.length < 1 && (
            <div>Não há tickets para este filtro.</div>
          )}
          {/* { console.log(ticketsFilter) } */}
          {ticketsFilter.map((ticket, index) => (
            <main className="box-single-ticket" key={index}>
              <div>
                <h3>
                  <AiOutlineMail />
                  {ticket.assunto} - {ticket.id_ticket}{" "}
                </h3>
                <h3>{ticket.corretora}</h3>
                {ticket.tipo_ticket === "Movimentação" ? (
                  <p>
                    <FaUserAlt /> - Criado há {dataDiff(ticket.created_at)} dias
                  </p>
                ) : (
                  <p>
                    <FaUserAlt />
                    {`${ticket.nome} ${ticket.sobrenome} - Criado há ${dataDiff(ticket.created_at)} dias.`}
                  </p>
                )}
              </div>
              <div className="cols">
                <ul className="icons">
                  <li>
                    <FaAngleDoubleRight /> Status: {ticket.descricao}
                  </li>
                  <li
                    className="box-details"
                    onClick={() =>
                      handleDrawerTicket(ticket, ticket.tipo_ticket)
                    }
                  >
                    Detalhes
                  </li>
                </ul>
              </div>
            </main>
          ))}
        </Container>
      </MainContainer>
      <FooterPainel />

      <SwipeableDrawer
        open={openDrawer}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        anchor="right"
        ModalProps={{
          BackdropProps: {
            classes: {
              root: classes.BackdropProps,
            },
          },
        }}
      >
        {content()}
      </SwipeableDrawer>
    </>
  );
};

export default Tickets;
