import http from 'services/http';

const getBeneficiaries = async (estipulante_id) => {
  const res = await http.get(`/rh/estipulante-sub/beneficiarios/${estipulante_id}`);
  return res;
}

const getBeneficiaryFamilyGroup = async (beneficiario_id) => {
  const res = await http.get(`/rh/beneficiarios/grupo-familiar/${beneficiario_id}`);
  return res;
}

const modules = {
  getBeneficiaries,
  getBeneficiaryFamilyGroup
}

export default modules;
