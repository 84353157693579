import styled from 'styled-components';
import colors from 'apps/broker/styles/colors';

export const Content = styled.div`
  padding: 15px 20px;

  .title {
    color: #3b3838;
    font-family: 'Avenir Next GEO W05 Bold';
    margin-bottom: 20px;
  }

  .actions {
    padding: 10px 0;
    .buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .btn {
        font-family: 'Avenir Next GEO W05 Bold';
        cursor: pointer;
        padding: 6px 8px;
        border-radius: 5px;
        min-width: 120px;

        :last-child {
          margin-left: 20px;
        }
      }

      .btn-confirm {
        color: #ffffff;
        border: ${colors.genoaBlue} 1px solid;
        background: ${colors.genoaBlue};
        :hover {
          color: ${colors.genoaBlue};
          background-color: ${colors.white};
        }
      }
      .btn-canlcel {
        color: #eb3535;
        border: #eb3535 1px solid;
        background: white;
        :hover {
          color: white;
          background-color: #eb3535;
        }
      }
    }
  }
`;
