import styled from 'styled-components';
import colors from 'apps/broker/styles/colors';

export const Wrapper = styled.div`
  display: flex;

  .animeLeft {
    opacity: 0;
    transform: translateX(-20px);
    animation: animeLeft 0.3s forwards;
  }
  @keyframes animeLeft {
    to {
      opacity: 1;
      transform: initial;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 101vh;

  .main-painel {
    max-width: 1400px;
    margin-left: 3.2px;
    margin-top: 5px;
    margin-right: 3.2px;
    width: 100%;
  }
`;

export const Main = styled.main`
  display: flex;
  justify-content: center;
  width: calc(100% - 90px);
  height: 100%;
  background: ${colors.genoaLightBackgroundGrey};
  padding: 45px;
`;

export const Consult = styled.section`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  width: 100%;

  .title {
    width: 100%;
    margin-right: 10px;
    color: #3b3838;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

    h1 {
      font-family: 'Avenir Next GEO W05 Bold';
      font-size: 1.2rem;
    }

    .adicionar-contato-container {
      display: flex;
      align-items: center;
      font-family: 'Avenir Next GEO W05 Demi';
      color: #455b71;
      :hover {
        cursor: pointer;
        text-decoration: underline;
      }

      span {
        margin-left: 3px;
      }
    }
  }
`;
