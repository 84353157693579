import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import { FaTooth } from 'react-icons/fa';
import { GiHealthNormal } from 'react-icons/gi';
import { Section } from './styles';

const ExclusionMovimentCard = ({
  item,
  selectedDateExclusionPlan,
  setSelectedDateExclusionPlan,
  selectedPlan,
  removeBeneficiaryContract
}) => {
  const getProductToInsertInfos = (allContracts, idPlan) => {
    let productSelected = allContracts.find(
      (item) => item.contrato_id === Number(idPlan)
    );

    return productSelected;
  };

  const renderBeneficiarioExpandedContratoInfoExclusao = (contratos) => {
    const contratosExclusao = contratos.filter(
      (item) => item.movimentacao_remocao
    );

    const contratosSaudeExclusao = contratosExclusao.filter(
      (item) => item.produto_id === 1
    );
    const maisRecenteContratoSaudeExclusao = contratosSaudeExclusao
      .sort(
        (a, b) =>
          Number(new Date(b.contrato_beneficiario_created_at)) -
          Number(new Date(a.contrato_beneficiario_created_at))
      )
      .shift();

    const contratosOdontoExclusao = contratosExclusao.filter(
      (item) => item.produto_id === 2
    );
    const maisRecenteContratoOdontoExclusao = contratosOdontoExclusao
      .sort(
        (a, b) =>
          Number(new Date(b.contrato_beneficiario_created_at)) -
          Number(new Date(a.contrato_beneficiario_created_at))
      )
      .shift();

    const contratosMaisRecentesExclusao = [
      maisRecenteContratoSaudeExclusao,
      maisRecenteContratoOdontoExclusao
    ];

    return contratosMaisRecentesExclusao.map((contrato) => {
      if (!contrato) {
        return <div></div>;
      }

      return contrato.dataExclusao || contrato.data_exclusao ? (
        <div className="align-left">
          <p className="name-type-contract">
            {contrato.produto_id === 1 ? (
              <GiHealthNormal size={13} className="contrato-icon" />
            ) : (
              <FaTooth size={13} className="contrato-icon" />
            )}
            {contrato?.numero_apolice ? (`${contrato.nome} (${contrato?.numero_apolice})`) : (`${contrato.nome}`)}
          </p>
          <div className="first-column-container">
            <p>
              <span>Data de exclusão no Plano:</span>
            </p>
            <p className="paragraph-info">
              {moment(contrato.dataExclusao).format('DD/MM/YYYY') ||
                moment(contrato.data_exclusao).format('DD/MM/YYYY')}
            </p>
          </div>

          {contrato.numero_carteirinha ? (
            <div className="first-column-container">
              <p>
                <span>Nº de carteirinha:</span>
              </p>
              <p className="paragraph-info">{contrato.numero_carteirinha}</p>
            </div>
          ) : null}
        </div>
      ) : contrato.movimentacao_remocao ? (
        <div className="align-left" key={contrato.id}>
          <p className="name-type-contract">
            {contrato.produto_id === 1 ? (
              <GiHealthNormal size={13} className="contrato-icon" />
            ) : (
              <FaTooth size={13} className="contrato-icon" />
            )}
            {contrato?.numero_apolice ? (`${contrato.nome} (${contrato?.numero_apolice})`) : (`${contrato.nome}`)}
          </p>
          <div className="first-column-container">
            <p>
              <span>Data de Remoção do Plano:</span>
            </p>
            <ReactDatePicker
              dateFormat="dd/MM/yyyy"
              placeholderText="Data de remoção do plano"
              className="input-info"
              selected={selectedDateExclusionPlan[contrato.id] ?? ''}
              onChange={(date) =>
                setSelectedDateExclusionPlan((prev) => ({
                  ...prev,
                  [contrato.id]: date
                }))
              }
            />
          </div>

          <button
            type="submit"
            className="send-infos"
            onClick={() => removeBeneficiaryContract(contrato)}
          >
            Remover Beneficiário
          </button>
        </div>
      ) : (
        ''
      );
    });
  };

  return (
    <Section>
      <div className="first-column-container">
        <p>
          <span>CPF do Beneficiário:</span>{' '}
          {item.tipo === 'Titular' ? item.cpfTitular : item.cpfDependente}
        </p>

        {item.tipo === 'Titular' && item.data_vinculo_titular !== null ? (
          <p>
            <span>Data de vínculo:</span>{' '}
            {moment(item.data_vinculo_titular).format('DD/MM/YYYY')}
          </p>
        ) : null}

        {item.tipo !== 'Titular' && item.data_vinculo_dependente !== null ? (
          <p>
            <span>Data de vínculo:</span>{' '}
            {moment(item.data_vinculo_dependente).format('DD/MM/YYYY')}
          </p>
        ) : null}
      </div>

      <div
        className={`infos-inclusion-beneficiary ${
          item.data_exclusao && 'align-left'
        }`}
      >
        <div className="box-show-infos-plans">
          {renderBeneficiarioExpandedContratoInfoExclusao(item?.contratos)}

          <div className="box-show-infos-plans">
            {Object.keys(selectedPlan).map((plan) =>
              selectedPlan[plan] ? (
                <div>
                  <p className="name-type-contract">
                    {getProductToInsertInfos(item.contratos, plan).tipo_plano}
                  </p>
                  <div>
                    <p>
                      <span>Data de exclusão no Plano:</span>
                    </p>
                    <ReactDatePicker
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Data de exclusão no plano"
                      selected={selectedDateExclusionPlan[plan] ?? ''}
                      onChange={(date) =>
                        setSelectedDateExclusionPlan((prev) => ({
                          ...prev,
                          [plan]: date
                        }))
                      }
                    />
                  </div>
                </div>
              ) : null
            )}
          </div>
        </div>
      </div>
    </Section>
  );
};

export default ExclusionMovimentCard;
