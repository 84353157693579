import React from 'react'
import { NavLink } from 'react-router-dom'
import { Container } from './styles'
import { BiHome, BiBook, BiFile, BiUser, BiCalendar } from "react-icons/bi";

const MenuRh = () => {
  return (
    <Container>
      <nav className="container">
        <ul className="menu">
          <li>
            <NavLink to="/rh/home" activeClassName="active">
              <BiHome />
              <span>Home</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/rh/beneficiarios" activeClassName="active">
              <BiUser />
              <span>Beneficiários</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/rh/contratos" activeClassName="active">
              <BiFile />
              <span>Contratos</span>
            </NavLink>
          </li>
       
          <li>
            <NavLink to="/rh/tickets" activeClassName="active">
              <BiCalendar />
              <span>Tickets </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/rh/dados-cadastrais"activeClassName="active">
              <BiBook />
              <span>Dados Cadastrais</span>
            </NavLink>
          </li>

        </ul>

      </nav>
    </Container>
  )
}

export default MenuRh
