import { useState, useEffect, useCallback } from 'react';
import { Wrapper, Content } from './styles';
import { AiOutlineUsergroupAdd } from 'react-icons/ai';
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { io } from 'socket.io-client';
import {
  SwipeableDrawer,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import { GrPowerReset, GrTag } from 'react-icons/gr';
import colors from 'apps/broker/styles/colors';
import Header from 'apps/broker/components/Header';
import Sidebar from 'apps/broker/components/Sidebar';
import Main from 'apps/broker/components/MainContent';
import DrawerTicketContent from './components/TicketDetails';
import DrawerMovimentacaoBeneficiario from '../TicketMoviments';
import CustomToast from 'components/ToastCustomized';
import DrawerUrgentDemands from './components/TicketsUrgentDemands/DrawerUrgentDemands';
import DialogPoliceOwnerChange from './components/DialogPoliceOwnerChange';
import CardTicket from './components/Cards';
import InitialFilters from './components/Filters/InitialFilter';
import FilterCardList from './components/Filters/FilterCardList';
import { useUser } from 'contexts/user';
import { useFilters } from 'contexts/filters';
import { useUrgentDemandsTickets } from 'contexts/urgentDemandsTickets';
import services from 'apps/broker/services';

const useStyles = makeStyles({
  BackdropProps: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  content: {
    maxWidth: 1065
  },
  fullList: {
    width: 'auto'
  },
  formControl: {
    '&:focus': {
      backgroundColor: 'transparent'
    }
  },
  select: {
    textAlign: 'left',
    color: `${colors.genoaGrey}`,
    fontSize: '14px'
  },
  selectEstipulante: {
    textAlign: 'left',
    color: `${colors.genoaGrey}`,
    fontSize: '14px',
    maxWidth: '250px'
  },
  optionsSelectEstipulante: {
    textAlign: 'left',
    color: `${colors.genoaGrey}`,
    fontSize: '12px',
    maxWidth: '470px'
  },
  optionSelectCorretora: {
    textAlign: 'left',
    color: `${colors.genoaGrey}`,
    fontSize: '14px',
    maxWidth: '350px'
  },
  icon: {
    marginTop: '15px'
  }
});

const optionsDate = {
  year: 'numeric',
  month: '2-digit',
  day: 'numeric'
};

const itemsPerPage = 15;

const GestaoTicketsCorretor = () => {
  const classes = useStyles();
  const { toasts } = useToasterStore();
  const { urgentDemandsTickets, resetUrgentDemandsTickets } =
    useUrgentDemandsTickets();
  const { user, setAcessedPages } = useUser();
  const acessedPages = user?.acessedPages;
  const { homeFilters, resetHomeFilters } = useFilters();

  const [urgentDemandId, setUrgentDemandId] = useState(false);
  const [socket, setSocket] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawerContent, setDrawerContent] = useState({});
  const [loading, setLoading] = useState(false);
  const [corretores, setCorretores] = useState([]);
  const [endDate, setEndDate] = useState('');
  const [startDate, setStartDate] = useState('');
  const [allEstipulantes, setAllEstipulantes] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [ticketsFilter, setTicketsFilter] = useState([]);
  const [addFilters, setAddFilters] = useState({
    id_ticket: '',
    status: 'all',
    corretor: 'all',
    ticket: 'tickets',
    dateAtuation: 'all',
    estipulante: 'all',
    naoAlocados: false
  });
  const [ticketSeguradora, setTicketSeguradora] = useState({});
  const [seguradoras, setSeguradoras] = useState([]);
  const [estipulantes, setEstipulantes] = useState([]);
  const [editMode, setEditMode] = useState({});
  const [editSeguradoraMode, setEditSeguradoraMode] = useState({});
  const [openModal, setOpenModal] = useState(null);
  const [dateFilterValue, setDateFilterValue] = useState('desde inicio');
  const [previousEstipulante, setPreviousEstipulante] = useState('');
  const [newEstipulante, setNewEstipulante] = useState('');
  const [ticketIdToBeUpdated, setTicketIdToBeUpdated] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [ticketsFilteredExist, setTicketsFilteredExist] = useState(true);
  const [openMovimentacaoDrawer, setOpenMovimentacaoDrawer] = useState(false);
  const [ticketDrawerMovimentacao, setTicketDrawerMovimentacao] = useState({});
  const [openUrgentDemandsDrawer, setOpenUrgentDemandsDrawer] = useState(false);
  const [urgentDemands, setUrgentDemands] = useState(undefined);
  const [isVisibleRhModeEdit, setIsVisibleRhModeEdit] = useState({});
  const [updateSubject, setUpdateSubject] = useState({
    ticket_id: undefined,
    subject: '',
    width: undefined,
    ticket_freshdesk_id: undefined
  });

  const {
    control,
    setValue,
    getValues,
    formState: { errors }
  } = useForm({
    defaultValues: {
      initial_date: '',
      final_date: ''
    }
  });

  const toggleOpenDrawerDetails = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    if (event && event.type === 'keydown') {
      return;
    }
    setOpenDrawer(open);
  };

  const toggleOpenDrawerMoviment = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    if (event && event.type === 'keydown') {
      return;
    }
    setOpenMovimentacaoDrawer(open);
  };

  const toggleUrgentDemandsDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    if (event && event.type === 'keydown') {
      return;
    }
    setOpenUrgentDemandsDrawer(open);
  };

  const getData = async () => {
    setLoading(true);

    const response = await services.tickets.getAll();
    if (response.status === 200) {
      const convertObject = response.data.corretores.map(
        ({ uuid, id_corretora, nome, sobrenome }) => ({
          id: uuid ? uuid : id_corretora,
          label: sobrenome ? `${nome} ${sobrenome}` : `${nome}`
        })
      );
      setCorretores(convertObject);

      setTickets(response.data.tickets);
      if (response.data.tickets.length < 1) {
        setTicketsFilteredExist(false);
      } else {
        setTicketsFilteredExist(true);
      }

      const {
        redirected,
        custom_dates,
        date_filter,
        ticket_status,
        estipulante,
        corretor,
        date_atuation
      } = homeFilters;

      if (redirected) {
        setValue('initial_date', custom_dates.initial_date);
        setValue('final_date', custom_dates.final_date);
        const typeRh =
          homeFilters.ticket_type === 'total'
            ? 'tickets'
            : homeFilters.ticket_type === 'outras demandas'
            ? 'Rh'
            : 'Movimentação';
        setDateFilterValue(date_filter);
        const corretorSelecionado = convertObject.filter((item) => {
          if (item.label === corretor) {
            return true;
          }
          return false;
        });
        setAddFilters((prevState) => ({
          ...prevState,
          status: ticket_status === 'criados' ? 'all' : 3,
          ticket: typeRh,
          dateAtuation: date_atuation,
          estipulante,
          corretor: corretor === 'all' ? 'all' : corretorSelecionado[0]?.id
        }));
        // Está sendo "setado" os valores dos filtros do redirecionamento para modificações de futuras
        // buscas na página de Gestão de Tickets e para o usuário ter visibilidade do que está sendo buscado.
        // Para o "search" feito no redirect é considerado os filtros que vem do estado do Reducer
        // p/ evitar a Assincronicidade crônica dos setValues.
        // Por isso dessa forma é usado, apenas para o redirect, o filtro vindo do Reducer
        searchFiltersOnRedirect(response.data.tickets, homeFilters);
      } else {
        const dataFilter = response.data.tickets.filter(
          (ticket) => ticket.status_id !== 3 && ticket.status_id !== 7
        );
        setTicketsFilter(dataFilter);
      }
      setLoading(false);
      getAllPoliceOwners();
    }
  };

  const getAllPoliceOwners = async () => {
    const response = await services.policyOwner.getAll();
    setEstipulantes(response.data);
  };

  const visivelRhChangeHandler = async (e, ticket) => {
    const body = {
      id: ticket.id,
      value: e.target.value
    };

    await services.tickets.updateSettingHrAccessByTicketId(body);
    getData();
    setIsVisibleRhModeEdit((prevState) => ({
      ...prevState,
      [ticket.id]: !prevState[ticket.id]
    }));
  };

  const getSeguradoras = async () => {
    setLoading(true);
    const response = await services.insuranceCompanies.getAllInsurance();
    if (response.status === 200) {
      const nullOption = [
        {
          label: '',
          id: null
        }
      ];
      const seguradorasFormated = response.data.map((seguradora) => {
        return {
          label: seguradora.nome_completo,
          id: seguradora.id
        };
      });
      setSeguradoras([...nullOption, ...seguradorasFormated]);
      setLoading(false);
    }
  };

  const handleData = async (ticket, tipo, type_form) => {
    setDrawerContent({ ticket, tipo, type_form });
    setOpenDrawer(true);
  };

  const handleAllFiltersSearch = ({ target }) => {
    if (
      target.name === 'id_ticket' ||
      target.name === 'status' ||
      target.name === 'ticket' ||
      target.name === 'corretor' ||
      target.name === 'dateAtuation' ||
      target.name === 'estipulante'
    ) {
      setAddFilters({
        ...addFilters,
        [target.name]: target.value
      });
    }
  };

  // https://medium.com/lucianobragaweb/comparar-datas-em-javascript-9b1d1febbe9a
  const formateDate = (date) => {
    let parts = date.split('/');
    const formatdate = new Date(parts[2], parts[1] - 1, parts[0]);
    return new Date(formatdate);
  };

  const searchFiltersOnRedirect = (tickets, homeFilters) => {
    const date = new Date();

    // Para os filtros de data por Atuação
    const today = date.toLocaleDateString('pt-BR', optionsDate);
    const tomorrow = new Date(
      date.setDate(date.getDate() + 1)
    ).toLocaleDateString('pt-BR', optionsDate);
    const defeated = new Date(
      date.setDate(date.getDate() - 1)
    ).toLocaleDateString('pt-BR', optionsDate);
    const dateStart =
      startDate && startDate.toLocaleDateString('pt-BR', optionsDate);
    const dateEnd = endDate && endDate.toLocaleDateString('pt-BR', optionsDate);

    // Para os filtros de data por Criação
    const lastWeek = new Date(
      date.setDate(date.getDate() - 7)
    ).toLocaleDateString('pt-BR', optionsDate);
    const lastMonth = new Date(
      date.setDate(date.getDate() - 31)
    ).toLocaleDateString('pt-BR', optionsDate);
    const customDates = homeFilters.custom_dates;
    const initialCreatedDatePeriod =
      customDates.initial_date &&
      customDates.initial_date.toLocaleDateString('pt-BR', optionsDate);
    const finalCreatedDatePeriod =
      customDates.final_date &&
      customDates.final_date.toLocaleDateString('pt-BR', optionsDate);

    const typeRh =
      homeFilters.ticket_type === 'outras demandas'
        ? 'Outras Demandas'
        : 'Movimentação';

    const filtersAddResults = tickets
      .filter((item) =>
        homeFilters.ticket_type === 'total' ? item : item.type_form === typeRh
      )
      .filter((item) =>
        homeFilters.ticket_status === 'criados'
          ? [1, 4].includes(item.status_id) && item
          : item.status_id === 3 && item
      )
      .filter((item) =>
        homeFilters.estipulante === 'all'
          ? item
          : item.razaoSocialEstipulante?.includes(homeFilters.estipulante) &&
            item
      )
      .filter((item) => {
        const dateObj = new Date(item.data_atuacao).toLocaleDateString(
          'pt-BR',
          optionsDate
        );

        let returnFilter = {};
        switch (homeFilters.date_atuation) {
          case 'vencido':
            returnFilter = item.data_atuacao && dateObj < defeated && item;
            break;
          case 'hoje':
            returnFilter = dateObj === today && item;
            break;
          case 'periodo':
            returnFilter = dateObj >= dateStart && dateObj <= dateEnd && item;
            break;
          case 'amanha':
            returnFilter = dateObj === tomorrow && item;
            break;
          default:
            returnFilter = item;
            break;
        }
        return returnFilter;
      })
      .filter((item) => {
        const dateCreatedAtobj = new Date(item.created_at).toLocaleDateString(
          'pt-BR',
          optionsDate
        );
        let returnFilter;
        switch (homeFilters.date_filter) {
          case 'ultima semana':
            if (formateDate(dateCreatedAtobj) > formateDate(lastWeek)) {
              returnFilter = item;
            }
            break;
          case 'ultimo mes':
            if (formateDate(dateCreatedAtobj) > formateDate(lastMonth)) {
              returnFilter = item;
            }
            break;
          case 'data personalizada':
            // Chamando custom_dates dentro do case de "data_personalizada" por causa de erro de chave undefined quando
            // para outros cases
            if (
              formateDate(dateCreatedAtobj) >=
                formateDate(initialCreatedDatePeriod) &&
              formateDate(dateCreatedAtobj) <=
                formateDate(finalCreatedDatePeriod)
            ) {
              returnFilter = item;
            }
            break;
          default:
            returnFilter = item;
        }
        return returnFilter;
      })
      .filter((item) =>
        homeFilters.corretor === 'all'
          ? item
          : item.nome_corretor === homeFilters.corretor && item
      );

    if (filtersAddResults.length < 1) {
      setTicketsFilteredExist(false);
    } else {
      setTicketsFilteredExist(true);
    }
    setTicketsFilter(filtersAddResults);
    setCurrentPage(1);
    resetHomeFilters();
  };

  const searchFiltersOnClick = () => {
    const date = new Date();
    const today = date.toLocaleDateString('pt-BR', optionsDate);
    const tomorrow = new Date(
      date.setDate(date.getDate() + 1)
    ).toLocaleDateString('pt-BR', optionsDate);
    const defeated = new Date(
      date.setDate(date.getDate() - 1)
    ).toLocaleDateString('pt-BR', optionsDate);
    const lastWeek = new Date(
      date.setDate(date.getDate() - 7)
    ).toLocaleDateString('pt-BR', optionsDate);
    const lastMonth = new Date(
      date.setDate(date.getDate() - 31)
    ).toLocaleDateString('pt-BR', optionsDate);
    const customDates = getValues();
    const initialCreatedDatePeriod =
      customDates.initial_date &&
      customDates.initial_date.toLocaleDateString('pt-BR', optionsDate);
    const finalCreatedDatePeriod =
      customDates.final_date &&
      customDates.final_date.toLocaleDateString('pt-BR', optionsDate);
    const dateStart =
      startDate && startDate.toLocaleDateString('pt-BR', optionsDate);
    const dateEnd = endDate && endDate.toLocaleDateString('pt-BR', optionsDate);
    const typeRh =
      addFilters.ticket === 'Rh' ? 'Outras Demandas' : addFilters.ticket;
    const filtersAddResults = tickets
      .filter((item) =>
        addFilters.naoAlocados ? !item.id_corretor : item.id_corretor
      )
      .filter((item) => {
        if (
          item.assunto
            .toLowerCase()
            .includes(addFilters.id_ticket.toLowerCase()) ||
          item.id_ticket
            .toLowerCase()
            .includes(addFilters.id_ticket.toLowerCase())
        ) {
          return item;
        }
        return false;
      })
      .filter((item) =>
        addFilters.ticket === 'tickets'
          ? item
          : (typeRh === item.tipo_ticket || item.type_form === typeRh) && item
      )
      .filter((item) =>
        addFilters.status === 'all'
          ? [1, 4].includes(item.status_id) && item
          : addFilters.status === item.status_id && item
      )
      .filter((item) =>
        addFilters.corretor === 'all'
          ? item
          : item.id_corretor?.includes(addFilters.corretor) && item
      )
      .filter((item) =>
        addFilters.estipulante === 'all'
          ? item
          : item.razaoSocialEstipulante?.includes(addFilters.estipulante) &&
            item
      )
      .filter((item) => {
        const dateObj = new Date(item.data_atuacao).toLocaleDateString(
          'pt-BR',
          optionsDate
        );

        let returnFilter = {};
        switch (addFilters.dateAtuation) {
          case 'vencido':
            returnFilter =
              item.data_atuacao &&
              formateDate(dateObj) < formateDate(defeated) &&
              item;
            break;
          case 'hoje':
            returnFilter = dateObj === today && item;
            break;
          case 'periodo':
            returnFilter =
              formateDate(dateObj) >= formateDate(dateStart) &&
              formateDate(dateObj) <= formateDate(dateEnd) &&
              item;
            break;
          case 'amanha':
            returnFilter = dateObj === tomorrow && item;
            break;
          default:
            returnFilter = item;
            break;
        }
        return returnFilter;
      })
      .filter((item) => {
        const dateCreatedAtobj = new Date(item.created_at).toLocaleDateString(
          'pt-BR',
          optionsDate
        );
        let returnFilter;
        switch (dateFilterValue) {
          case 'ultima semana':
            if (formateDate(dateCreatedAtobj) > formateDate(lastWeek)) {
              returnFilter = item;
            }
            break;
          case 'ultimo mes':
            if (formateDate(dateCreatedAtobj) > formateDate(lastMonth)) {
              returnFilter = item;
            }
            break;
          case 'data personalizada':
            // Chamando custom_dates dentro do case de "data_personalizada" por causa de erro de chave undefined quando
            // para outros cases
            if (
              formateDate(dateCreatedAtobj) >=
                formateDate(initialCreatedDatePeriod) &&
              formateDate(dateCreatedAtobj) <=
                formateDate(finalCreatedDatePeriod)
            ) {
              returnFilter = item;
            }
            break;
          default:
            returnFilter = item;
        }
        return returnFilter;
      })
      .filter((item) => {
        if (urgentDemandId) {
          return item.id === urgentDemandId;
        }
        return item;
      });

    setTicketsFilter(filtersAddResults);
    setCurrentPage(1);
  };

  const openUrgentTicketDetails = () => {
    const ticketFiltered = tickets?.find((item) => item.id === urgentDemandId);
    if (ticketFiltered) {
      handleData(
        ticketFiltered,
        ticketFiltered.tipo_ticket,
        ticketFiltered.type_form
      );
    }
  };

  const getDemandasUrgentes = async () => {
    const { user_id } = user;
    const response = await services.tickets.getUrgentDemands(user_id);
    if (response.status === 200) {
      toast.remove();
      setUrgentDemands(response.data?.urgentDemands);
      const { urgentMovimentacoes, urgentTickets } =
        response.data?.urgentDemands;

      if (!acessedPages?.tickets) {
        if (urgentMovimentacoes?.length || urgentTickets?.count) {
          setOpenUrgentDemandsDrawer(true);
          setAcessedPages('tickets');
        }
      } else {
        if (urgentMovimentacoes?.length || urgentTickets?.count) {
          if (!openUrgentDemandsDrawer) {
            // Se mudar duration do toast, procurar por "#2334455"
            toast.custom(
              <CustomToast
                demands={response.data?.urgentDemands}
                setOpenDrawer={setOpenUrgentDemandsDrawer}
              />,
              {
                duration: 5000,
                position: 'bottom-center',
                className: 'toast-wrapper'
              }
            );
          }
        }
      }
    }
  };

  const resetFilters = () => {
    setAddFilters({
      id_ticket: '',
      status: 'all',
      corretor: 'all',
      ticket: 'tickets',
      dateAtuation: 'all',
      estipulante: 'all',
      naoAlocados: false
    });
  };

  const checkRedirectToShowDemand = () => {
    if (urgentDemandsTickets?.redirected) {
      setUrgentDemandId(urgentDemandsTickets?.demand_id);
      resetFilters();
    } else {
      setUrgentDemandId(false);
    }
  };

  const updateTicketByBrokerUserInfo = async (idTicket, gtIndex) => {
    const body = {
      idTicket: idTicket,
      nameCorretor: ticketsFilter[gtIndex].nome_corretor,
      corretorId: ticketsFilter[gtIndex].id_corretor,
      dataAtuacao: new Date(ticketsFilter[gtIndex].data_atuacao)
    };
    await services.tickets.updateTicketByUserInformation(body);
    socket?.emit('getBadgeNotifications');
    setLoading(false);
  };

  const changeTicketType = async (id, dataString) => {
    const body = {
      id,
      dataString
    };

    const response = await services.tickets.updateTicketTypeById(body);
    if (response.status === 200) {
      setTicketsFilter((state) => {
        const indexTicket = state.findIndex((item) => item.id_ticket === id);
        state[indexTicket].type_form = dataString;
        return [...state];
      });
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(null);
    setEditMode({});
  };

  const confirmationChangeHandler = async () => {
    const estipulanteFilter = estipulantes.filter(
      (estipulante) => estipulante.razao_social === newEstipulante
    );

    const body = {
      estipulante_id: estipulanteFilter[0].id
    };

    const response = await services.tickets.updatePoliceOwnerByTicketId(
      ticketIdToBeUpdated,
      body
    );

    if (response.status === 200) {
      handleCloseModal();
      getData();
    }
  };

  const getEstipulantesFromDataBase = async () => {
    setLoading(true);
    const response = await services.policyOwner.getPolicyOwners();

    if (response.status === 200) {
      setAllEstipulantes(response.data.estipulantes);
      setLoading(false);
    }
  };

  const updateSeguradora = async (ticketId, event) => {
    const { id, label } = JSON.parse(event.target.value);
    setTicketSeguradora((prevState) => ({
      ...prevState,
      [ticketId]: event.target.value
    }));
    const body = {
      ticketId,
      seguradoraId: id
    };

    setTicketsFilter((state) => {
      const indexTicket = state.findIndex((item) => item.id === ticketId);
      state[indexTicket].seguradora_nome = label;
      return [...state];
    });
    toast.promise(
      updateInsuranceCompany(body),
      {
        loading: 'Loading...',
        success: () => {
          if (label.length < 1) {
            return (
              <div>
                <strong>{user.nome}</strong> redefiniu a seguradora do ticket{' '}
                <strong>{ticketId}</strong> para <strong>Valor Nulo</strong>
              </div>
            );
          }
          return (
            <div>
              <strong>{user.nome}</strong> alterou a seguradora do ticket{' '}
              <strong>{ticketId}</strong> para <strong>{label}</strong>
            </div>
          );
        },
        error: () => <div>Error!</div>
      },
      {
        duration: 4000,
        position: 'bottom-center'
      }
    );
  };

  const updateInsuranceCompany = async (body) => {
    await services.insuranceCompanies.updateInsuranceByTicketId(body);
  };

  const updateSubjectClickHandler = () => {
    const body = {
      updateSubject
    };
    toast
      .promise(
        updateSubjectTicket(body),
        {
          loading: 'Carregando...',
          success: () => (
            <div>
              Assunto do Ticket
              <strong> {updateSubject?.ticket_freshdesk_id}</strong> foi
              atualizado com sucesso!
            </div>
          ),
          error: () => (
            <div>Não foi posível atualizar o assunto do ticket no momento</div>
          )
        },
        {
          duration: 4000,
          position: 'bottom-center'
        }
      )
      .finally(() => {
        setUpdateSubject((prevState) => ({
          ...prevState,
          ticket_freshdesk_id: undefined
        }));
      });
  };

  const updateSubjectTicket = async (body) => {
    const response = await services.tickets.updateTicketSubject(body);
    if (response.status === 200) {
      getData();
    }
  };

  const checkInputs = useCallback(
    (idTicket) => {
      const gtIndex = ticketsFilter.findIndex(
        (item) => item.id_ticket === idTicket
      );

      const formatDate = new Date(
        ticketsFilter[gtIndex].data_atuacao
      ).toLocaleString('pt-BR', optionsDate);

      if (
        ticketsFilter[gtIndex].id_corretor &&
        ticketsFilter[gtIndex].data_atuacao
      ) {
        toast.promise(
          updateTicketByBrokerUserInfo(idTicket, gtIndex),
          {
            loading: 'Loading...',
            success: () => (
              <div>
                O ticket <strong>{idTicket}</strong>, agora é responsável por{' '}
                <strong>{ticketsFilter[gtIndex].nome_corretor}</strong>, com a
                data de atuação para <strong>{formatDate}</strong>
              </div>
            ),
            error: () => (
              <div>
                Não foi possivel salvar o ticket <strong>{idTicket}</strong>
              </div>
            )
          },
          {
            duration: 7000,
            position: 'bottom-center'
          }
        );
      }
    },
    [ticketsFilter]
  );

  const changeCorretor = useCallback(
    (idTicket, idCorretor, nomeCorretor) => {
      setTicketsFilter((state) => {
        const indexTicket = state.findIndex(
          (item) => item.id_ticket === idTicket
        );
        state[indexTicket].id_corretor = idCorretor;
        state[indexTicket].nome_corretor = nomeCorretor;
        return [...state];
      });
      checkInputs(idTicket);
    },
    [checkInputs]
  );

  const changeDate = useCallback(
    (ticketId, data) => {
      setTicketsFilter((state) => {
        const indexTicket = state.findIndex(
          (item) => item.id_ticket === ticketId
        );
        state[indexTicket].data_atuacao = data;
        return [...state];
      });
      checkInputs(ticketId);
    },
    [checkInputs]
  );

  const isWeekday = useCallback((date) => {
    const day = date.getDay(date);
    return day !== 0 && day !== 6;
  }, []);

  const updateTicketState = useCallback(
    (event, id, dataString, dataTypeForm, ticket) => {
      event.preventDefault();
      if (dataString === 'Movimentação') {
        setOpenMovimentacaoDrawer(true);
        setTicketDrawerMovimentacao(ticket);
      }

      toast.promise(
        changeTicketType(id, dataString),
        {
          loading: 'Loading...',
          success: () => (
            <div>
              <strong>{user.nome}</strong> alterou o tipo do ticket{' '}
              <strong>{id}</strong> para{' '}
              <strong>
                {dataTypeForm === 'Rh' ? 'Outras Demandas' : dataTypeForm}
              </strong>
            </div>
          ),
          error: () => <div>Error!</div>
        },
        {
          duration: 2000,
          position: 'bottom-center'
        }
      );
    },
    [user]
  );

  useEffect(() => {
    const hiddenSpan = document.getElementById('hidden-span');
    setUpdateSubject((prevState) => ({
      ...prevState,
      width: hiddenSpan?.offsetWidth
    }));
  }, [updateSubject?.subject]);

  useEffect(() => {
    getDemandasUrgentes();
  }, [tickets]);

  useEffect(() => {
    searchFiltersOnClick();
    if (urgentDemandId) {
      openUrgentTicketDetails();
    }
  }, [urgentDemandId]);

  useEffect(() => {
    searchFiltersOnClick();
  }, [tickets]);

  useEffect(() => {
    searchFiltersOnClick();
  }, [
    addFilters?.naoAlocados,
    addFilters?.status,
    addFilters?.corretor,
    addFilters?.ticket,
    addFilters?.estipulante,
    addFilters?.dateAtuation
  ]);

  useEffect(() => {
    checkRedirectToShowDemand();
  }, [urgentDemandsTickets]);

  useEffect(() => {
    getData();
    getSeguradoras();
    getEstipulantesFromDataBase();
    setSocket(io(process.env.REACT_APP_BASE_URL_API));
    getDemandasUrgentes();
    checkRedirectToShowDemand();
  }, []);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting)) {
        setCurrentPage((prevState) => prevState + 1);
      }
    });

    intersectionObserver.observe(document.querySelector('#sentinela'));
    return () => intersectionObserver.disconnect();
  }, []);

  useEffect(() => {
    const toastLimit = 1;
    toasts
      .filter((tt) => tt.visible)
      .filter((_, i) => i >= toastLimit)
      .forEach((tt) => toast.remove(tt.id));
  }, [toasts]);

  return (
    <Wrapper>
      <Sidebar />
      <Content>
        <Header />
        {!loading ? (
          <Main>
            <div className="main-painel">
              <div className="title">
                <h1>Todos os pedidos de seus clientes</h1>
              </div>
              {urgentDemands?.urgentMovimentacoes?.length ||
              urgentDemands?.urgentTickets?.count ? (
                <button
                  className="open-urgent-drawer-button"
                  onClick={() => setOpenUrgentDemandsDrawer(true)}
                >
                  <div className="urgent-demand-value">
                    {urgentDemands?.urgentMovimentacoes?.length ? (
                      <div className="value-container">
                        <AiOutlineUsergroupAdd className="movimentacao-icon" />
                        <span>
                          {urgentDemands?.urgentMovimentacoes?.length}
                        </span>
                      </div>
                    ) : (
                      ''
                    )}
                    {urgentDemands?.urgentTickets?.count ? (
                      <div className="value-container">
                        <GrTag className="ticket-icon" />
                        <span>{urgentDemands?.urgentTickets?.count}</span>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </button>
              ) : (
                ''
              )}

              <InitialFilters
                control={control}
                errors={errors}
                setDateFilterValue={setDateFilterValue}
                addFilters={addFilters}
                dateFilterValue={dateFilterValue}
                handleAllFiltersSearch={handleAllFiltersSearch}
                searchFiltersOnClick={searchFiltersOnClick}
              />
              <FilterCardList
                classes={classes}
                ticketsFilter={ticketsFilter}
                setTicketsFilter={setTicketsFilter}
                addFilters={addFilters}
                setAddFilters={setAddFilters}
                corretores={corretores}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                allEstipulantes={allEstipulantes}
                handleAllFiltersSearch={handleAllFiltersSearch}
              />

              {!ticketsFilteredExist && ticketsFilter.length < 1 && (
                <div>Não há tickets para este filtro.</div>
              )}
              {ticketsFilter
                .slice(0, currentPage * itemsPerPage)
                ?.map((ticket, index) => (
                  <CardTicket
                    index={index}
                    ticket={ticket}
                    isVisibleRhModeEdit={isVisibleRhModeEdit}
                    setIsVisibleRhModeEdit={setIsVisibleRhModeEdit}
                    estipulantes={estipulantes}
                    seguradoras={seguradoras}
                    editMode={editMode}
                    setEditMode={setEditMode}
                    editSeguradoraMode={editSeguradoraMode}
                    setEditSeguradoraMode={setEditSeguradoraMode}
                    setOpenModal={setOpenModal}
                    setPreviousEstipulante={setPreviousEstipulante}
                    setNewEstipulante={setNewEstipulante}
                    setTicketIdToBeUpdated={setTicketIdToBeUpdated}
                    ticketSeguradora={ticketSeguradora}
                    corretores={corretores}
                    isWeekday={isWeekday}
                    updateSubject={updateSubject}
                    setUpdateSubject={setUpdateSubject}
                    updateSubjectClickHandler={updateSubjectClickHandler}
                    updateTicketState={updateTicketState}
                    updateSeguradora={updateSeguradora}
                    changeCorretor={changeCorretor}
                    handleData={handleData}
                    visivelRhChangeHandler={visivelRhChangeHandler}
                    changeDate={changeDate}
                  />
                ))}
            </div>
            {ticketsFilter?.length && urgentDemandId ? (
              <div className="button-container">
                <button
                  onClick={() => {
                    setUrgentDemandId(false);
                    resetUrgentDemandsTickets();
                  }}
                >
                  <div>
                    <GrPowerReset className="reset-icon" />
                    <span>Resetar filtros</span>
                  </div>
                </button>
              </div>
            ) : (
              ''
            )}
          </Main>
        ) : (
          <div className="circular-spinner-container">
            <CircularProgress
              className="circular-spinner"
              size={120}
              thickness={2.5}
            />
            <h3>Carregando...</h3>
          </div>
        )}
        <div id={loading ? 'sentinela-loading' : 'sentinela'}></div>
      </Content>
      <SwipeableDrawer
        ModalProps={{
          BackdropProps: {
            classes: {
              root: classes.BackdropProps
            }
          }
        }}
        anchor="right"
        open={openDrawer}
        onClose={toggleOpenDrawerDetails(false)}
        onOpen={toggleOpenDrawerDetails(true)}
      >
        <DrawerTicketContent
          data={drawerContent.ticket}
          getDataAll={getData}
          onClose={setOpenDrawer}
        />
      </SwipeableDrawer>
      <SwipeableDrawer
        anchor="right"
        open={openMovimentacaoDrawer}
        onClose={toggleOpenDrawerMoviment(false)}
        onOpen={toggleOpenDrawerMoviment(true)}
      >
        <DrawerMovimentacaoBeneficiario
          data={ticketDrawerMovimentacao}
          onClose={setOpenMovimentacaoDrawer}
        />
      </SwipeableDrawer>
      <SwipeableDrawer
        anchor="right"
        open={openUrgentDemandsDrawer}
        onClose={toggleUrgentDemandsDrawer(false)}
        onOpen={toggleUrgentDemandsDrawer(true)}
      >
        <DrawerUrgentDemands
          urgentDemands={urgentDemands}
          setOpenDrawer={setOpenUrgentDemandsDrawer}
          getDemandasUrgentes={getDemandasUrgentes}
        />
      </SwipeableDrawer>
      <Toaster
        position={'bottom-center'}
        toastOptions={{
          style: {
            background: colors.genoaBlue,
            padding: '0 12px',
            color: '#fff',
            maxWidth: '450px',
            height: '100px',
            marginTop: '0.6em'
          }
        }}
      />
      <DialogPoliceOwnerChange
        openModal={openModal}
        previousEstipulante={previousEstipulante}
        newEstipulante={newEstipulante}
        confirmationChangeHandler={confirmationChangeHandler}
        handleCloseModal={handleCloseModal}
      />
    </Wrapper>
  );
};

export default GestaoTicketsCorretor;
