import { useState } from 'react';
import NegociosContent from '../../../BusinessKanban';
import AtividadesContent from '../../../Activities';

const ContentSwitcher = ({
  abaSelecionada,
  isNewActivity,
  setNewActivity,
  openDrawer,
  setOpenDrawer,
  corretores,
  negociosInfo,
  getNegociosData,
  setNewAtividadeNegocio,
  vencimento,
  responsavel,
  setVencimento,
  setResponsavel,
  dataInicial,
  setDataInicial,
  dataFinal,
  setDataFinal,
  resetActivitiesFilters
}) => {
  // Estado para "avisar" que há um novo card
  const [isNewCard, setIsNewCard] = useState(false);

  switch (abaSelecionada) {
    case 'atividades':
      return (
        <AtividadesContent
          isNewActivity={isNewActivity}
          setNewActivity={setNewActivity}
          isNewCard={isNewCard}
          setIsNewCard={setIsNewCard}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          corretores={corretores}
          negociosInfo={negociosInfo}
          getNegociosData={getNegociosData}
          setNewAtividadeNegocio={setNewAtividadeNegocio}
          vencimento={vencimento}
          responsavel={responsavel}
          setVencimento={setVencimento}
          setResponsavel={setResponsavel}
          setDataInicial={setDataInicial}
          dataInicial={dataInicial}
          setDataFinal={setDataFinal}
          dataFinal={dataFinal}
          resetActivitiesFilters={resetActivitiesFilters}
        />
      );

    default:
      return (
        <NegociosContent
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          corretores={corretores}
          negociosInfo={negociosInfo}
          getNegociosData={getNegociosData}
          isNewCard={isNewCard}
          setIsNewCard={setIsNewCard}
        />
      );
  }
};

export default ContentSwitcher;
