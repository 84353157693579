import styled from "styled-components";
import colors from "styles/colors";


export const AsideUserIconWrapper = styled.div`


  .user {
    font-size: 12px;
    color: ${colors.rhLightGrey};
  }

  .plus {
    font-size: 6px;
    color: ${colors.green};
    position: absolute;
    transform: translateX(-4px) translateY(12px);
    background-color: ${colors.rhTabLightGrey};
    border-radius: 6px;
  }

  .minus {
    font-size: 7px;
    color: ${colors.red};
    position: absolute;
    transform: translateX(-4px) translateY(11px);
    background-color: ${colors.rhTabLightGrey};
    border-radius: 7px;
  }

  .selected-option {
    background-color: ${colors.white};
  }
`