import styled from 'styled-components'
import colors from 'styles/colors'

export const BoxData = styled.div`

  width: 95%;
  color: ${colors.darkGray};
  margin: 2rem auto;

  .title {
    font-size: 1rem;
    font-family: 'Avenir Next GEO W05 Bold';
    margin-bottom: 1.5rem;

  }

  .box-data {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;

    .data {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;

      p {
        font-weight: bold;
        /* color: ${colors.purple} */
        color: #635d5d;
      }

      h3 {
        font-size: 1.125rem;
        font-family: 'Avenir Next GEO W05 Bold';
      }
    }

    & + .box-data {
      margin-top: 1rem;
    }
  }

`
