import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { useState } from 'react';
import { ContentTabs } from '../../styles';
import { BlockInfo } from './styles';
import CloseIcon from '@material-ui/icons/Close';
import { FiEdit, FiXCircle } from 'react-icons/fi';
import { BiPlus, BiUserPin } from 'react-icons/bi';
import ReactDatePicker from 'react-datepicker';
import { FaBuilding, FaSave } from 'react-icons/fa';
import services from 'apps/broker/services';
import DialogRemoval from './DialogRemoval';
import AddSubestipulante from '../../Atualizacao-Estipulantes/AddSubestipulante-atualizacao';

const RegistrationData = ({
  errors,
  details,
  handleSubmit,
  control,
  register,
  setValue,
  emailsEmpresa,
  openModal,
  setOpenModal,
  estipulante,
  setLoadGestor,
  getData
}) => {
  const [errorFormato, setErrorFormato] = useState();
  const [imagePreviewUrl, setimagePreviewUrl] = useState();
  const [respLogo, setRespLogo] = useState();
  const [addColaborador, setAddColaborador] = useState(false);

  const [editEstipulante, setEditEstipulante] = useState(false);
  const [editSubEstipulante, setEditSubEstipulante] = useState(false);
  const [editGestor, setEditGestor] = useState(false);
  const [delEmail, setDelEmail] = useState(false);

  const [removeGest, setRemoveGest] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successEst, setSuccessEst] = useState(false);
  const [successSubEst, setSuccessSubEst] = useState(false);
  const [idGestorRemove, setIdGestorRemove] = useState(null);

  const fileForm = useForm();
  const saveGestorForm = useForm();
  const updateFormSubEstipulante = useForm();
  const updateGestorInfo = useForm();

  const {
    fields: emailsField,
    append: emailsAppend,
    remove: emailsRemove
  } = useFieldArray({ control, name: 'emails' });

  const handleClickOpenModal = () => {
    setOpenModal(true);
  };

  //form gestor
  const saveGestor = (data) => {
    const getForm = document.querySelector('#formAddGestor');
    setSuccess(false);
    setLoadGestor(true);

    services.policyOwner
      .createManagersByUUID(data, estipulante)
      .then(() => {
        setSuccess(true);
        getForm.reset();
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoadGestor(false);
        setTimeout(() => {
          saveGestorForm.reset({ nome: '', email: '' });
          setSuccess(false);
          setAddColaborador(false);
        }, 1000);
      });
  };

  const updateGestor = (data) => {
    services.policyOwner
      .updateManagerByUUID(data, estipulante)
      .then(() => {
        updateGestorInfo.reset({ telefone: '', email: '' });
        setEditGestor(false);
        getData();
      })
      .catch((err) => console.log(err));
  };

  //form logo
  const arquivo = fileForm.register('arquivo', {
    required: 'Campo Obrigatório'
  });

  const handleImgChange = ({ target }) => {
    setErrorFormato(null);
    setRespLogo(null);
    fileForm.setError('arquivo', null);
    if (['image/jpeg', 'image/png'].includes(target.files[0].type)) {
      fileForm.setValue('arquivo', target.files);
      const reader = new FileReader();
      reader.onload = (e) => {
        const urlImage = URL.createObjectURL(new Blob([e.target.result]));
        console.log('urlImage', urlImage);
        setimagePreviewUrl(urlImage);
      };
      reader.readAsArrayBuffer(target.files[0]);
    } else {
      setErrorFormato('Envie um logo no formato PNG ou JPG');
    }
  };

  const submitLogo = (data) => {
    const formData = new FormData();
    formData.append('file', data.arquivo[0]);

    services.policyOwner
      .createLogoByUUID(formData, estipulante)
      .then((resp) => {
        setRespLogo(resp.data);
        getData();
      })
      .catch((err) => console.log(err));
  };

  const updateEstipulante = (data) => {
    if (data.sub) setSuccessSubEst(false);
    else setSuccessEst(false);

    setLoadGestor(true);

    services.policyOwner
      .updateById(data)
      .then(() => {
        if (data.sub) setSuccessSubEst(true);
        else setSuccessEst(true);
        getData();
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoadGestor(false);
      });
  };

  //remove email
  const removeEmail = (e, email_empresa, id_estipulante) => {
    e.preventDefault();

    const data = {
      email_empresa,
      id_estipulante
    };

    services.policyOwner
      .deleteEmailById(data)
      .then(() => {
        setDelEmail(false);
        getData();
      })
      .catch((err) => console.log(err));
  };

  const removeGestor = (e, rhuser) => {
    services.policyOwner
      .deleteManagerByUUID(rhuser, estipulante)
      .then(() => {
        getData();
        // handleCloseModal();
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <ContentTabs>
        <section className="dadosCadastrais">
          <div className="dados-cadastrais-title">
            <span>Cadastro de Estipulante</span>
          </div>
          <div className="rows">
            <div className="cols">
              <BlockInfo>
                <div className="infos">
                  <div className="blok-info estipulante">
                    <div className="divIcon">
                      <FaBuilding />
                    </div>
                    <div>
                      <div className="header-info">
                        <span>
                          {details.razao_social}
                          {/* {console.log(details)} */}
                        </span>
                        <button
                          className="btn-edit"
                          onClick={(e) => setEditEstipulante(true)}
                        >
                          <FiEdit />
                        </button>
                      </div>
                      {editEstipulante ? (
                        <div className="card">
                          <br />
                          <h3>Editar informações do Estipulante</h3>
                          <div className="addGestor">
                            <form onSubmit={handleSubmit(updateEstipulante)}>
                              {successEst && (
                                <span className="message animeLeft">
                                  Informações salvas.
                                </span>
                              )}
                              <div className="input-group">
                                <label>CNPJ</label>
                                <input
                                  maxLength={50}
                                  defaultValue={details.cnpj}
                                  {...register(`cnpj`, { required: true })}
                                ></input>
                              </div>
                              <div className="input-group">
                                <label>Razão Social</label>
                                <input
                                  maxLength={50}
                                  defaultValue={details.razao_social}
                                  {...register(`razao_social`, {
                                    required: true
                                  })}
                                ></input>
                              </div>

                              <div className="input-group">
                                <label>
                                  Data de inicio de vinculo com o Estipulante
                                </label>
                                {/* {console.log('details.data_inicio_vinculo', details.clienteData.dataVinculo)} */}
                                <Controller
                                  name="dataEstipulante"
                                  control={control}
                                  defaultValue={
                                    details.clienteData.dataVinculo
                                      ? new Date(
                                          details.clienteData.dataVinculo
                                        )
                                      : null
                                  }
                                  rules={{ required: true }}
                                  render={({ field: { onChange, value } }) => (
                                    <ReactDatePicker
                                      className="input-data"
                                      selected={value}
                                      locale="ptBR"
                                      onChange={onChange}
                                      dateFormat="dd/MM/yyyy"
                                    />
                                  )}
                                />
                              </div>
                              <div className="input-group">
                                <label>CEP</label>
                                <input
                                  maxLength={50}
                                  defaultValue={details.cep}
                                  {...register(`cep`, { required: true })}
                                ></input>
                              </div>
                              <div className="input-group">
                                <label>Endereço</label>
                                <input
                                  maxLength={50}
                                  defaultValue={details.logradouro}
                                  {...register(`logradouro`, {
                                    required: true
                                  })}
                                ></input>
                              </div>
                              <div className="input-group">
                                <label>Número</label>
                                <input
                                  type="text"
                                  defaultValue={details.numero}
                                  {...register(`numero`, {
                                    required: true
                                  })}
                                ></input>
                              </div>
                              <div className="input-group">
                                <label>Complemento</label>
                                <input
                                  defaultValue={details.complemento}
                                  {...register(`complemento`, {
                                    required: false
                                  })}
                                ></input>
                              </div>
                              <div className="input-group">
                                <label>Localidade</label>
                                <input
                                  defaultValue={details.localidade}
                                  {...register(`localidade`, {
                                    required: true
                                  })}
                                ></input>
                              </div>
                              <div className="input-group">
                                <br />
                                <label>E-mails da Empresa</label>
                                {emailsEmpresa?.map((item, index) => {
                                  return (
                                    <div className="input-group">
                                      <div className="remove-btn-area">
                                        {' '}
                                        {emailsEmpresa.length > 0 && (
                                          <button
                                            type="button"
                                            className="remove-field"
                                            onClick={() => setDelEmail(index)}
                                          >
                                            Excluir
                                          </button>
                                        )}
                                      </div>
                                      <input
                                        readOnly
                                        defaultValue={
                                          emailsEmpresa[index].email_empresa
                                        }
                                        type="text"
                                        name={`emails${index}.nome`}
                                        {...register(`emails.${index}.nome`, {
                                          required: false
                                        })}
                                      />
                                      {delEmail === index && (
                                        <div>
                                          <p>
                                            Você tem certeza que deseja excluir
                                            esse e-mail?
                                          </p>
                                          <div className="btn-area">
                                            <button
                                              className="send-btn"
                                              type="button"
                                              onClick={(e) =>
                                                removeEmail(
                                                  e,
                                                  emailsEmpresa[index]
                                                    .email_empresa,
                                                  details.id_estipulante
                                                )
                                              }
                                            >
                                              Sim
                                            </button>
                                            <button
                                              className="send-btn"
                                              type="button"
                                              onClick={(e) =>
                                                setDelEmail(false)
                                              }
                                            >
                                              Não
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  );
                                })}
                                {emailsField?.map((item, index) => {
                                  return (
                                    <div className="input-group">
                                      <div className="remove-btn-area">
                                        {' '}
                                        {emailsField.length > 0 && (
                                          <button
                                            type="button"
                                            className="remove-field"
                                            onClick={() => emailsRemove(index)}
                                          >
                                            remover
                                          </button>
                                        )}
                                      </div>
                                      <input
                                        type="text"
                                        name={`emails${index}.nome`}
                                        {...register(`emails.${index}.nome`, {
                                          required: false
                                        })}
                                      />
                                    </div>
                                  );
                                })}
                                <button
                                  type="button"
                                  className="add"
                                  onClick={() => emailsAppend({ nome: '' })}
                                >
                                  + Adicionar mais um e-mail.
                                </button>
                              </div>

                              {setValue('estipulante', details.id_estipulante)}
                              <div className="btn-area">
                                <button className="send-btn" type="submit">
                                  Atualizar
                                </button>
                                <button
                                  className="send-btn"
                                  type="button"
                                  onClick={(e) => {
                                    setSuccessEst(false);
                                    setEditEstipulante(false);
                                  }}
                                >
                                  {successEst ? 'Fechar' : 'Cancelar'}
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      ) : (
                        <div className="dados">
                          <span>{details.cnpj}</span>
                          <span>
                            {details.logradouro +
                              ' ' +
                              details.numero +
                              ' ' +
                              details.complemento}
                          </span>
                          <span>{details.localidade}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="blok-info sub-estipulante">
                    <div className="divIcon">
                      <FaBuilding />
                    </div>
                    <div className="subestipulante_column">
                      {details?.subestipulantes?.map(
                        (subestipulante, index) => {
                          return (
                            <div>
                              <div className="header-info">
                                <span>
                                  {subestipulante.razao_social}
                                  <br />
                                  <span className="subsEs">
                                    (subestipulante)
                                  </span>
                                </span>
                                <button
                                  className="btn-edit"
                                  onClick={(e) => setEditSubEstipulante(index)}
                                >
                                  <FiEdit />
                                </button>
                              </div>
                              {editSubEstipulante === index ? (
                                <div className="addGestor">
                                  <form
                                    onSubmit={updateFormSubEstipulante.handleSubmit(
                                      updateEstipulante
                                    )}
                                  >
                                    {successSubEst && (
                                      <span className="message animeLeft">
                                        Informações salvas.
                                      </span>
                                    )}
                                    <div className="input-group">
                                      <label>CNPJ</label>
                                      <input
                                        maxLength={50}
                                        defaultValue={subestipulante.cnpj}
                                        {...updateFormSubEstipulante.register(
                                          `cnpj`,
                                          { required: false }
                                        )}
                                      ></input>
                                    </div>
                                    <div className="input-group">
                                      <label>Razão Social</label>
                                      <input
                                        maxLength={50}
                                        defaultValue={
                                          subestipulante.razao_social
                                        }
                                        {...updateFormSubEstipulante.register(
                                          `razao_social`,
                                          { required: false }
                                        )}
                                      ></input>
                                    </div>
                                    <div className="input-group">
                                      <label>CEP</label>
                                      <input
                                        maxLength={50}
                                        defaultValue={subestipulante.cep}
                                        {...updateFormSubEstipulante.register(
                                          `cep`,
                                          { required: false }
                                        )}
                                      ></input>
                                    </div>
                                    <div className="input-group">
                                      <label>Endereço</label>
                                      <input
                                        maxLength={50}
                                        defaultValue={subestipulante.logradouro}
                                        {...updateFormSubEstipulante.register(
                                          `logradouro`,
                                          { required: false }
                                        )}
                                      ></input>
                                    </div>
                                    <div className="input-group">
                                      <label>Número</label>
                                      <input
                                        type="text"
                                        defaultValue={subestipulante.numero}
                                        {...updateFormSubEstipulante.register(
                                          `numero`,
                                          { required: false }
                                        )}
                                      ></input>
                                    </div>
                                    <div className="input-group">
                                      <label>Complemento</label>
                                      <input
                                        defaultValue={
                                          subestipulante.complemento
                                        }
                                        {...updateFormSubEstipulante.register(
                                          `complemento`,
                                          { required: false }
                                        )}
                                      ></input>
                                    </div>
                                    <div className="input-group">
                                      <label>Localidade</label>
                                      <input
                                        defaultValue={subestipulante.localidade}
                                        {...updateFormSubEstipulante.register(
                                          `localidade`,
                                          { required: false }
                                        )}
                                      ></input>
                                    </div>

                                    {updateFormSubEstipulante.setValue(
                                      'estipulante',
                                      subestipulante.id_estipulante
                                    )}
                                    {updateFormSubEstipulante.setValue(
                                      'sub',
                                      true
                                    )}

                                    <div className="btn-area">
                                      <button
                                        className="send-btn"
                                        type="submit"
                                      >
                                        Atualizar
                                      </button>
                                      <button
                                        className="send-btn"
                                        type="button"
                                        onClick={(e) =>
                                          setEditSubEstipulante(false)
                                        }
                                      >
                                        {successSubEst ? 'Fechar' : 'Cancelar'}
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              ) : (
                                <div className="dados">
                                  <span>{subestipulante.cnpj}</span>
                                  <span>
                                    {subestipulante.logradouro +
                                      ' ' +
                                      subestipulante.numero +
                                      ' ' +
                                      subestipulante.complemento}
                                  </span>
                                  <span>{subestipulante.localidade}</span>
                                </div>
                              )}
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
                <button
                  className="btn-add add-sub"
                  onClick={() => setOpenModal((prevState) => !prevState)}
                >
                  {' '}
                  + Adicionar Sub
                </button>

                {openModal && (
                  <>
                    <div>
                      <AddSubestipulante
                        estipulante={details.id_estipulante}
                        subs={details.subestipulantes}
                        getDataDrawer={getData}
                        setOpenModal={setOpenModal}
                      />
                    </div>
                  </>
                )}
              </BlockInfo>
              <div className="dados-cadastrais-title">
                <span>Contatos RH e outros</span>
              </div>
              <BlockInfo>
                <div className="contatos-container">
                  {details?.rh?.map((contato, index) => {
                    return (
                      <div className="contato-info">
                        <div className="divIcon">
                          <BiUserPin />
                        </div>
                        <div className="infos-container">
                          <div className="gridcard">
                            <div>
                              <div className="title">
                                <div className="name">
                                  {contato.nome} {contato.sobrenome}
                                </div>
                              </div>
                              <div className="dados">
                                {editGestor === index ? (
                                  <div className="addGestor">
                                    <form
                                      onSubmit={updateGestorInfo.handleSubmit(
                                        updateGestor
                                      )}
                                    >
                                      <div className="input-group">
                                        <label>Telefone</label>
                                        <input
                                          defaultValue={contato.telefone}
                                          maxLength={50}
                                          {...updateGestorInfo.register(
                                            `telefone`,
                                            { required: false }
                                          )}
                                        ></input>
                                      </div>
                                      <div className="input-group">
                                        <label>E-mail</label>
                                        <input
                                          defaultValue={contato.email}
                                          maxLength={50}
                                          {...updateGestorInfo.register(
                                            `email`,
                                            { required: false }
                                          )}
                                        ></input>
                                      </div>
                                      {updateGestorInfo.setValue(
                                        'usuario_rh_id',
                                        contato.usuario_rh_id
                                      )}
                                      <div className="btn-remove">
                                        <button
                                          className="send-btnRemove"
                                          type="submit"
                                        >
                                          Alterar
                                        </button>
                                        <button
                                          className="send-btnRemove"
                                          type="button"
                                          onClick={(e) => setEditGestor(false)}
                                        >
                                          Cancelar
                                        </button>
                                      </div>
                                      <br />
                                    </form>
                                  </div>
                                ) : (
                                  <div>
                                    <p>{contato.telefone}</p>
                                    <p>{contato.email}</p>
                                  </div>
                                )}
                              </div>
                            </div>
                            {removeGest &&
                            idGestorRemove === contato.usuario_rh_id ? (
                              <DialogRemoval
                                contato={contato}
                                removeGestor={removeGestor}
                                setRemoveGest={setRemoveGest}
                              />
                            ) : (
                              <div className="btn-select">
                                <button
                                  className="send-btnRemove"
                                  type="button"
                                  title="Editar"
                                  onClick={(e) => setEditGestor(index)}
                                >
                                  <FiEdit />
                                </button>
                                <button
                                  className="send-btnRemove red"
                                  type="button"
                                  title="Excluir"
                                  onClick={(e) => {
                                    handleClickOpenModal();
                                    setIdGestorRemove(contato.usuario_rh_id);
                                    setRemoveGest(true);
                                  }}
                                >
                                  <FiXCircle />
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <button
                    className="btn-add"
                    onClick={() => setAddColaborador(true)}
                  >
                    {' '}
                    + Adicionar Contato
                  </button>
                  {addColaborador && (
                    <div className="card">
                      <div className="addGestor">
                        <form
                          id="formAddGestor"
                          onSubmit={saveGestorForm.handleSubmit(saveGestor)}
                        >
                          {success && (
                            <span className="message animeLeft">
                              Acesse o email pra confirmar o cadastro.
                            </span>
                          )}
                          <div className="input-group">
                            <label>Nome</label>
                            {errors.nome && (
                              <span className="error-msg">
                                {' '}
                                Campo Obrigatório
                              </span>
                            )}
                            <input
                              id="formAddGestor"
                              maxLength={50}
                              {...saveGestorForm.register(`nome`, {
                                required: true
                              })}
                            />
                          </div>

                          <div className="input-group">
                            <label>Email</label>
                            {errors.email && (
                              <span className="error-msg">
                                {' '}
                                Campo Obrigatório
                              </span>
                            )}
                            <input
                              id="formAddGestor"
                              {...saveGestorForm.register(`email`, {
                                required: true
                              })}
                            />
                          </div>
                          <div className="btn-area">
                            <button className="send-btn" type="submit">
                              <span>Salvar</span>
                              <FaSave />
                            </button>
                            <button
                              className="send-btnRemove"
                              type="button"
                              onClick={() => setAddColaborador(false)}
                            >
                              Cancelar
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}
                </div>
              </BlockInfo>
            </div>
            <div className="cols">
              <h3 className="titileEditoLogo">Logo do Cliente</h3>
              <div className="logoAtual">
                {!imagePreviewUrl && details.logo && (
                  <img src={details.logo} alt="logo" />
                )}
              </div>
              <div>
                <form onSubmit={fileForm.handleSubmit(submitLogo)}>
                  {errorFormato && (
                    <span className="error-msg">{errorFormato}</span>
                  )}
                  {fileForm.formState.errors.arquivo?.message && (
                    <span className="error-msg">
                      {' '}
                      {fileForm.formState.errors.arquivo?.message}
                    </span>
                  )}

                  <div className="preview">
                    <div className="logoPreview">
                      {imagePreviewUrl && (
                        <img src={imagePreviewUrl} alt="logo" />
                      )}
                      {respLogo && (
                        <span className="message animeLeft">
                          Logo adicionado.
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="div-input-files">
                    <label className="label-file">
                      {fileForm.watch('arquivo')?.[0]?.name ? (
                        <span>
                          {' '}
                          {`${fileForm
                            .watch('arquivo')[0]
                            ?.name.slice(0, 45)}`}{' '}
                        </span>
                      ) : (
                        <span>
                          {' '}
                          <BiPlus /> Adicionar ou trocar logo{' '}
                        </span>
                      )}
                      <input
                        ref={arquivo.ref}
                        onChange={(e) => {
                          arquivo.onChange(e);
                          handleImgChange(e);
                        }}
                        className="input-file"
                        type="file"
                      />
                    </label>
                    <div className="buttons">
                      {fileForm.watch('arquivo')?.[0]?.name && (
                        <button
                          type="button"
                          onClick={() => {
                            fileForm.setValue('arquivo', null);
                            setimagePreviewUrl(null);
                            setRespLogo(null);
                          }}
                          className="btn-clear"
                        >
                          <CloseIcon />
                        </button>
                      )}
                    </div>
                  </div>
                  <button className="send-btn" type="submit">
                    Salvar
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
      </ContentTabs>
    </>
  );
};

export default RegistrationData;
