import styled from 'styled-components';
import colors from 'apps/broker/styles/colors';

export const Div = styled.div`
  .form-line-row {
    padding-bottom: 10px;
  }

  .input {
    width: 100%;
    text-align: center;
    font-family: 'Avenir Next GEO W05 Regular';
    font-size: 1rem;
    padding: 5px 10px;
    border: 0.5px solid ${colors.genoaBlue};
    border-radius: 5px;
    ::placeholder {
      color: #b8b9bb;
      text-align: center;
    }
  }

  .datepicker-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    padding-bottom: 10px;
  }

  .input-data {
    width: 295px;
    text-align: center;
    font-family: 'Avenir Next GEO W05 Regular';
    font-size: 1rem;
    padding: 5px 10px;
    border: 0.5px solid ${colors.genoaBlue};
    border-radius: 5px;
    ::placeholder {
      color: #b8b9bb;
      text-align: center;
    }
  }

  .datepicker-start-date-row-container {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
  }

`;
