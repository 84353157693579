import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  height: 200px;

  .remover-usuario-content {
    padding: 15px 20px;
    width: 100%;

    .title-container {
      color: ${colors.rhBlue};
      font-family: "Avenir Next GEO W05 Bold";
      font-size: 20px;
    }

    .usuario-info-container {
      margin-top: 20px;
      color: ${colors.genoaGrey};
      font-family: "Avenir Next GEO W05 Regular";
      font-size: 16px;
      display: flex;
      flex-direction: column;
      row-gap: 5px;

      .usuario-info {
        display: grid;
        grid-template-columns: 60px 1fr;
      }

      .info-title {
        font-family: "Avenir Next GEO W05 Demi";
      }
    }

    .buttons-container {
      display: flex;
      margin-top: 20px;
      justify-content: center;
      column-gap: 40px;

      .icon-container {
        cursor: pointer;
      }

      .check {
        color: ${colors.green};
      }

      .close {
        color: ${colors.red};
      }
    }
  }
`;