import { Div } from "./styles";

const ButtonDownloadExcel = ({
  downloadExcelFile
}) => {
  return (
    <Div>
      <button onClick={downloadExcelFile}>download</button>
      <img
        width="30"
        height="30"
        src="https://img.icons8.com/color/48/microsoft-excel-2019--v1.png"
        alt="microsoft-excel-2019--v1"
      />
    </Div>
  )
}

export default ButtonDownloadExcel;
