import { useState, useEffect } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { BsArrowLeftShort } from 'react-icons/bs';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { BiFolderOpen } from 'react-icons/bi';
import CloseIcon from '@material-ui/icons/Close';
import { FaSave } from 'react-icons/fa';
import Button from 'components/DS/Button';
import services from 'apps/broker/services';
import './styles.scss';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 10,
    color: '#fff'
  }
}));

const SeguroDental = ({ estipulante, subs, onBack }) => {
  const classes = useStyles();
  const [loadApi, setLoadApi] = useState(false);
  const [seguradoras, setSeguradoras] = useState([]);

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      planos: [{ nome: '' }],
      produto_id: 2,
      'custeio-details': '',
      'multa-details': '',
      'numero-apolice': ''
    }
  });

  const {
    fields: planosField,
    append: planosAppend,
    remove: planosRemove
  } = useFieldArray({ control, name: 'planos' });

  const onSubmit = async (data) => {
    setLoadApi(true);

    const body = { ...data, subsestipulantes: [...subs] };
    const response = await services.contracts.addNewContract(body, estipulante);

    if (response.status === 200) {
      if (data.formExt !== null && data.formExt[0]) {
        const formData = new FormData();
        formData.append('file', data.formExt[0]);

        await services.contracts.addContractFiles(
          formData,
          response.data.id,
          'formExt'
        );
      }
      if (data.arquivo !== null && data.arquivo[0]) {
        const formData = new FormData();
        formData.append('file', data.arquivo[0]);

        await services.contracts.addContractFiles(
          formData,
          response.data.id,
          'Documento'
        );
      }
      setLoadApi(false);
      onBack();
    }
  };

  const getSeguradora = async () => {
    const response =
      await services.insuranceCompanies.getInsuranceCompanyByType(
        'SeguroDental'
      );

    setSeguradoras(response.data);
  };

  useEffect(() => {
    getSeguradora();
  }, []);

  return (
    <div className="create-dental-insurance">
      <Backdrop
        className={classes.backdrop}
        open={loadApi}
        onClick={() => setLoadApi(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="wrapper">
        <form onSubmit={handleSubmit(onSubmit)} className="wrapper__form">
          <div className="wrapper__back">
            <button className="wrapper__back-btn" onClick={onBack}>
              <BsArrowLeftShort size={25} />
              <span>Voltar</span>
            </button>
          </div>
          <h2 className="wrapper__title">Cadastro de novo benefício</h2>
          <div className="wrapper__grids">
            <div className="wrapper__col">
              <div className="ds-select">
                <label className="ds-select__label">Seguradora</label>
                <select
                  className="ds-select__select ds-select__select--broker"
                  {...register('seguradora', { required: false })}
                >
                  <option value="0">Selecione</option>
                  {seguradoras.map((seguradora) => {
                    return (
                      <option value={seguradora.id}>{seguradora.nome}</option>
                    );
                  })}
                </select>
              </div>

              <div className="ds-input">
                <label className="ds-input__label">Número da Apólice</label>
                <input
                  className="ds-input__input"
                  placeholder="Digite aqui o Número da Apólice  ..."
                  {...register('numero-apolice', { required: false })}
                />
              </div>

              <div>
                <label className="wrapper__label">Vigência Inicial</label>
                {errors.vigenciaInicial && (
                  <span className="errorsMsg">Campo obrigatório</span>
                )}

                <Controller
                  name="vigenciaInicial"
                  control={control}
                  //defaultValue={new Date()}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      className="wrapper__date-picker"
                      selected={value}
                      locale="ptBR"
                      onChange={onChange}
                      placeholderText="Selecione um data"
                      dateFormat="dd/MM/yyyy"
                    />
                  )}
                />
              </div>
            </div>

            <div className="wrapper__col">
              <div className="ds-textarea">
                <label className="ds-textarea__label">Forma de custeio</label>
                <textarea
                  className="ds-textarea__textarea ds-textarea__textarea"
                  rows={5}
                  type="text"
                  placeholder="Digite aqui os detalhes da Forma de Custeio..."
                  {...register('custeio-details', { required: false })}
                  onChange={() => console.log(control._formValues)}
                />
              </div>

              <div className="ds-select">
                <label className="ds-select__label">Mês de Aniversário</label>
                <select
                  className="ds-select__select ds-select__select--broker"
                  {...register('mesAniversario', { required: false })}
                >
                  <option value="0">Selecione</option>
                  {[
                    'Janeiro',
                    'Fevereiro',
                    'Março',
                    'Abril',
                    'Maio',
                    'Junho',
                    'Julho',
                    'Agosto',
                    'Setembro',
                    'Outubro',
                    'Novembro',
                    'Dezembro'
                  ].map((i) => {
                    return <option value={i}>{i}</option>;
                  })}
                </select>
              </div>
            </div>

            <div className="wrapper__col">
              <div className="ds-textarea">
                <label className="ds-textarea__label">
                  Multa Contratual de Recisão
                </label>
                <textarea
                  className="ds-textarea__textarea ds-textarea__textarea"
                  rows={1}
                  type="text"
                  placeholder="Digite aqui a Multa Contratual de Recisão..."
                  {...register('multa-details', { required: false })}
                  onChange={() => console.log(control._formValues)}
                />
              </div>

              <div className="ds-select">
                <label className="ds-select__label">Qual o dia de corte?</label>
                <select
                  className="ds-select__select ds-select__select--broker"
                  {...register('diaCorte', { required: false })}
                >
                  <option value="0">Selecione</option>
                  {Array.from(Array(32).keys()).map((i) => {
                    if (i !== 0) return <option value={i}>{i}</option>;
                    return null;
                  })}
                </select>
              </div>
              <div className="ds-select">
                <label className="ds-select__label">
                  Dia de vencimento da fatura
                </label>
                <select
                  className="ds-select__select ds-select__select--broker"
                  {...register('vencimento', { required: false })}
                >
                  <option value="0">Selecione</option>
                  {Array.from(Array(32).keys()).map((i) => {
                    if (i !== 0) return <option value={i}>{i}</option>;
                    return null;
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className="input-file">
            <div className="input-file__title">
              <span>Inclusão de planos</span>
            </div>
            <div className="input-area">
              <label className="wrapper__label">
                Coloque os planos que os beneficiários desse contrato poderão
                escolher:
              </label>
              {planosField.map((item, index) => {
                return (
                  <div className="input-group-planos">
                    <div className="remove-btn-area">
                      {' '}
                      {index !== 0 && (
                        <Button
                          variant={'remove'}
                          onClick={() => planosRemove(index)}
                        >
                          remover
                        </Button>
                      )}
                    </div>
                    <div className="ds-input">
                      <input
                        className="ds-input__input"
                        style={{ width: 390 }}
                        placeholder="Ex.: TNQQ"
                        name={`planos${index}.nome`}
                        {...register(`planos.${index}.nome`, {
                          required: false
                        })}
                      />
                    </div>
                  </div>
                );
              })}

              <Button
                variant={'transparent'}
                onClick={() => planosAppend({ nome: '' })}
              >
                + Adicionar mais um plano.
              </Button>
            </div>
          </div>

          <div className="input-file">
            <div className="input-area">
              <div className="input-file__title">
                <label>Documentos</label>
              </div>
              <label className="wrapper__label">
                Adicione os documentos e contratos que foram utilizados para
                fechar o contrato.
              </label>
              <div className="input-file__files">
                <label className="input-file__label-file-documents">
                  {watch('arquivo')?.[0]?.name ? (
                    <span className="input-file__name">
                      <BiFolderOpen />{' '}
                      {`${watch('arquivo')[0]?.name.slice(0, 40)}`}{' '}
                    </span>
                  ) : (
                    <span>+ Adicionar um documento. </span>
                  )}
                  <input
                    {...register('arquivo', { required: false })}
                    className="input-file"
                    type="file"
                  />
                </label>
                <div className="buttons">
                  {watch('arquivo')?.[0]?.name && (
                    <button
                      type="button"
                      onClick={() => {
                        setValue('arquivo', null);
                      }}
                      className="btn-clear"
                    >
                      <CloseIcon />
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="input-area">
              <label className="wrapper__label">
                Possui algum formulário externo para adição de novos
                colaboradores? faça o upload dele
              </label>
              <div className="input-file__files">
                <label className="input-file__label-file-documents">
                  {watch(`formExt`)?.[0]?.name ? (
                    <span className="input-file__name">
                      <BiFolderOpen />{' '}
                      {`${watch(`formExt`)[0]?.name.slice(0, 40)}`}{' '}
                    </span>
                  ) : (
                    <span>+ Adicionar um formulário. </span>
                  )}
                  <input
                    {...register(`formExt`, { required: false })}
                    className="input-file"
                    type="file"
                  />
                </label>
                <div className="buttons">
                  {watch(`formExt`)?.[0]?.name && (
                    <button
                      type="button"
                      onClick={() => {
                        setValue(`formExt`, null);
                      }}
                      className="btn-clear"
                    >
                      <CloseIcon />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <Button type="submit">
              <span>Salvar</span>
              <FaSave />
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SeguroDental;
