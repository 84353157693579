import { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { FiCheckCircle } from 'react-icons/fi';
import { FormSection, MsgSuccess } from './styles';
import { useForm } from 'react-hook-form';
import CircularProgress from '@material-ui/core/CircularProgress';
import services from 'apps/broker/services';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  title: {
    fontFamily: 'Avenir Next GEO W05 Demi',
    color: '#3B3838'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography className={classes.title} variant="h6">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const CustomizedDialogs = ({ openModal, setOpenModal }) => {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    watch,
    reset,
    formState,
    formState: { errors }
  } = useForm();
  const arquivo = register('arquivo', { required: 'Campo Obrigatório.' });

  const [imagePreviewUrl, setimagePreviewUrl] = useState();
  const [errorFormato, setErrorFormato] = useState();
  const [success, setSuccess] = useState(false);
  const [load, setLoad] = useState(false);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleImgChange = ({ target }) => {
    setErrorFormato(null);
    setError('arquivo', null);
    if (['image/jpeg', 'image/png'].includes(target.files[0].type)) {
      setValue('arquivo', target.files);
      const reader = new FileReader();
      reader.onloadend = () => {
        setimagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(target.files[0]);
    } else {
      setErrorFormato('Envie um logo no formato PNG ou JPG');
    }
  };

  const onSubmit = async (data) => {
    if (data.arquivo === null || !data.arquivo[0]) {
      return;
    }

    setLoad(true);
    const formData = new FormData();
    formData.append('file', data.arquivo[0]);

    const response = await services.user.createUploadLogo(formData);
    if (response.status === 201) {
      setLoad(false);
      setSuccess(true);
      setimagePreviewUrl(null);
    }
  };

  useEffect(() => {
    setSuccess(false);
  }, [openModal]);

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
    }
  }, [formState, reset]);

  return (
    <div>
      <Dialog
        onClose={handleCloseModal}
        aria-labelledby="customized-dialog-title"
        open={openModal}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleCloseModal}>
          Edite o logo da corretora
        </DialogTitle>
        <DialogContent dividers>
          {success ? (
            <MsgSuccess>
              <div className="icon-area">
                <FiCheckCircle />
              </div>
              <div className="message">Logo salvo com sucesso</div>
            </MsgSuccess>
          ) : (
            <FormSection>
              <form onSubmit={handleSubmit(onSubmit)}>
                {errorFormato && (
                  <span className="error-msg">{errorFormato}</span>
                )}
                {errors.arquivo && (
                  <span className="error-msg">{errors.arquivo.message}</span>
                )}
                <div className="div-input-files">
                  <label className="label-file">
                    {watch('arquivo')?.[0]?.name ? (
                      <span>
                        {`${watch('arquivo')[0]?.name.slice(0, 45)}`}{' '}
                      </span>
                    ) : (
                      <span> Selecione um Logo </span>
                    )}
                    <input
                      ref={arquivo.ref}
                      onChange={(e) => {
                        arquivo.onChange(e);
                        handleImgChange(e);
                      }}
                      className="input-file"
                      type="file"
                    />
                  </label>
                  <div className="buttons">
                    {watch('arquivo')?.[0]?.name && (
                      <button
                        type="button"
                        onClick={() => {
                          setValue('arquivo', null);
                          setimagePreviewUrl(null);
                        }}
                        className="btn-clear"
                      >
                        <CloseIcon />
                      </button>
                    )}
                  </div>
                </div>
                <div className="preview">
                  <div className="logoPreview">
                    {imagePreviewUrl && (
                      <img src={imagePreviewUrl} alt="logo" />
                    )}
                  </div>
                </div>
                <button type="submit" className="btn">
                  <span>Salvar</span> {load && <CircularProgress size={15} />}{' '}
                </button>
              </form>
            </FormSection>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CustomizedDialogs;
