import styled from 'styled-components';
import colors from 'apps/broker/styles/colors';

export const Wrapper = styled.div`
  display: flex;

  .animeLeft {
    opacity: 0;
    transform: translateX(-20px);
    animation: animeLeft 0.3s forwards;
  }
  @keyframes animeLeft {
    to {
      opacity: 1;
      transform: initial;
    }
  }
`;

export const MainHome = styled.div`
  @media (max-height: 580px) {
    background: ${colors.genoaLightBackgroundGrey};
    // height: 100%;
    padding: 13.4px;
    padding-left: 30.15px;
    padding-right: 30.15px;
  }

  @media (min-height: 580px) and (max-height: 650px) {
    background: ${colors.genoaLightBackgroundGrey};
    // height: 100%;
    padding: 16px;
    padding-left: 36px;
    padding-right: 36px;
  }

  @media (min-height: 650px) and (max-height: 720px) {
    background: ${colors.genoaLightBackgroundGrey};
    // height: 100%;
    padding: 18px;
    padding-left: 40.5px;
    padding-right: 40.5px;
  }

  @media (min-height: 720px) and (max-height: 1000px) {
    background: ${colors.genoaLightBackgroundGrey};
    // height: 100%;
    padding: 20px;
    padding-left: 45px;
    padding-right: 45px;
  }

  @media (min-height: 1000px) and (max-height: 1500px) {
    background: ${colors.genoaLightBackgroundGrey};
    // height: 100%;
    padding: 22px;
    padding-left: 50px;
    padding-right: 50px;
  }

  @media (min-height: 1500px) and (max-height: 1900px) {
    background: ${colors.genoaLightBackgroundGrey};
    // height: 100%;
    padding: 24px;
    padding-left: 55px;
    padding-right: 55px;
  }
`;

export const Content = styled.div`
  max-height: 105vh;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 0;

  .circular-spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25vh;

    .circular-spinner{
      color: ${colors.genoaBlue};
      margin-bottom: 20px;
    }

    h3 {
      color: ${colors.genoaGrey};
      margin-top: 10px;
    }
  }

  .main-painel {
    max-width: 100%;
    // margin: auto;
    //padding: 20px 100px;
  }

  section.clientes {
    // margin: 25px 0;
    margin-top: 30px;
  }
  .largeText{
    color: ${colors.genoaBlue};
    text-align: center;
    font-family: 'Avenir Next GEO W05 Heavy';
    font-size: 30px;
    margin-bottom: 20px;
    
  }

  .grafico_demandas_totais {
    font-size: 2em;
    padding: 5px 15px;
  }

  .demandas_totais {
    display: flex;
    flex-direction: column;

    background-color: ${colors.genoaBlue};
    border-radius: 15px;
    padding: 35px 30px;
  }

  .title_fluxos_demandas {
    span {
      font-size: 1.3em;
      font-family: 'Avenir Next GEO W05 Bold';
      color: white;
    }
  }

  .tickets {
    //::cue() place-content: center;
    display: grid;
    margin-top: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    text-align: center;
    justify-content: center;
    

    .box-ticket {
      border-radius: 15px;
      display: flex;
      flex-direction: column;

    }
    .ticket-title {
      color: ${colors.white};
      font-family: 'Avenir Next GEO W05 Demi';
      font-size: 1.1em;
      margin-top: 20px;
    }
    .ticket-count {
      // max-width: 220px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Avenir Next GEO W05 Demi';
      // background: #fff;
      font-size: 3em;
      color: ${colors.white};
      border-radius: 15px;
    }
  }

  .vidas-boxes{
    place-content: center;
    display: flex;
    flex-direction: row;
    column-gap: 50px;
    padding-top: 15px;
    text-align: center;
    

    .box-vidas {
      display: flex;
      flex-direction: column;
      padding: 0px;
    }
    .vidas-title {
      color: ${colors.white};
      font-size: 1.1em;
      margin: 0;
      font-family: 'Avenir Next GEO W05 Demi';
      margin-top: 20px;
    }
    .vidas-count {
      font-family: 'Avenir Next GEO W05 Demi';
      font-size: 3em;
      color: ${colors.white};
      border-radius: 15px;
    }
  }
  
  .vidas-estipulantes {
    // margin: 25px 0;
  }
  
  .vidas-boxes-resp-estipulantes {
    // place-content: center;
    display: grid;
    grid-gap: 10px;
    text-align: center;
  }


  .table-resp-estipulantes {
    max-width: 550px;
    // margin-left: 20px;
    max-height: 250px;
    // margin-left: 30px;
    border-collapse: collapse;

    thead {
      text-align: left;
      max-width: 100%;
      width: 100%;
      height: 20px;
      position: sticky;
      top: 0;
      // background: ${colors.genoaLightBackgroundGrey};
      display: block;
      margin-bottom: 10px;
    }

    tr {
      display: grid;
      grid-template-columns: 2fr 20px 14px;
      column-gap: 15px;
    }

    th {
      font-family: 'Avenir Next GEO W05 Bold';
      color: ${colors.genoaDarkBlue};
      font-size: 1.25rem;
      padding-bottom: 12px;
    }

    th:first-child{
      min-width: 100%;
      max-width: 100%;
      border-right: 20px solid transparent;
    }

    th:nth-child(2) {
      min-width: 200px;
      max-width: 200px;
      text-align: center;
    }

    th:last-child {
      min-width: 20px;
      max-width: 20px;
      text-align: center;
    }

    tbody {
      max-height: 190px;
      min-height: 190px;
      overflow-y: auto;
      display: block;

      ::-webkit-scrollbar {
        width: 6px;
        background: transparent;
      }
      ::-webkit-scrollbar-thumb {
        background: ${colors.genoaBlue};
        box-shadow: none;
        border-radius: 14px;
      }
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px grey;
        border-radius: 14px;
      }
    }
    
    td {
      color: #7D7D7D;
      font-size: 0.8rem;
      padding-bottom: 8px;
      :hover {
        color: ${colors.genoaBlue};
        font-family: 'Avenir Next GEO W05 Regular';
        cursor: pointer;
      }
    }

    td:first-child {
      min-width: 100%;
      max-width: 100%;
      border-right: 20px solid transparent;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: left;
    }

    td:last-child {
      text-align: center;
      min-width: 100%;
      max-width: 100%;
      display: block;
    }

    .filter-icon {
      margin-bottom: -3px;
      :hover {
        cursor: pointer;
      } 
    }
  }

  .popper {
    margin-left: 15px;
  }

  .paper-popup-filter-change {
    width: 250px;
    height: 500px;
    background-color: ${colors.white};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    h4 {
      margin-bottom: 15px;
      margin-top: 15px;
    }
    hr.divider {
      border-style: hidden;
      border-bottom: 1px #c5c3c3 solid;
      margin-bottom: 10px;
      margin-top: 10px;
      width: 160px;
    }
    .data-header {
      margin-bottom: 5px;
      margin-top: 10px;
    }
    .select-ticket-tipo-container{
      margin-bottom: 15px;
      select {
        margin-left: 10px;
        font-family: "Avenir Next GEO W05 Regular";
        background-color: #FFF;
        border: 0.5px solid ${colors.genoaBlue};
        border-radius: 10px;
        opacity: 1;
        font-size: 1rem;
        color: ${colors.genoaGrey};
        text-align: center;
        width: 100%;
        height: 30px;

        select option:hover {
          background-color: red;
        }
      }
    }

    .order-checkbox-container{
      display: flex;
      width: 200px;
      justify-content: space-evenly;
      flex-direction: row;
      span {
        font-size: 0.8rem;
        height: 1rem;
        display: flex;
        justify-content: center;
      }

      .checkbox-group-container {
        display: flex;
        flex-direction: row;
      }

      .radio-checkbox {
        color: rgb(133, 133, 133);
        &.Mui-checked {
          color: ${colors.genoaBlue};
        }
      }
    }

    .data-checkbox-container {
      display: flex;
      width: 200px;
      justify-content: space-evenly;
      flex-direction: column;
      span {
        font-size: 0.8rem;
        height: 1rem;
        display: flex;
        justify-content: center;
      }

      .checkbox-data-group-container {
        display: flex;
        flex-direction: row;
        width: 160px;
        margin-left: 30px;
      }

      .radio-checkbox {
        color: rgb(133, 133, 133);
        &.Mui-checked {
          color: ${colors.genoaBlue};
        }
      }
    }

    .datepicker-box-space{
      min-height: 45px;
      max-height: 45px;
    }

    .datepicker-group-container-hidden {
      margin-top: 10px;
      height: 0px;
      overflow: hidden;
      transition: all .4s cubic-bezier(0.4, 0, 0.2, 1);
      display: grid;
      grid-template-columns: 125px 125px;
      
      .input-data {
        width: 90px;
        border: 1px solid ${colors.genoaBlue};
        display: inline-block;
        text-align: center;
        font-family: 'Avenir Next GEO W05 Regular';
        border-radius: 7px;
      }

      .react-datepicker-wrapper {
        display: inline-block;
        padding: 0;
        border: 0;
        width: min-content;
        
      }

      .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
        background-color: white;
        color: ${colors.genoaBlue}
      }


      .react-datepicker__day--selected, .react-datepicker__month-text--selected,  .react-datepicker__quarter-text--selected, .react-datepicker__year-text--selected {
        border-radius: 0.3rem;
        background-color: ${colors.genoaBlue};
        color: #fff;
      }

      .datepicker-selector-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 0.8rem;
      }
    }

    .date-filter-send-btn {
      color: white;
      margin-top: 12px;
      padding: 2px 8px;
      background-color: ${colors.genoaBlue};
      :hover {
        background-color: #3c59c1;
      }
    }
      
    }

    .datepicker-group-container {
      margin-top: 10px;
      height: 35px;
      overflow: hidden;
      transition: all .4s cubic-bezier(0.4, 0, 0.2, 1);
      display: grid;
      grid-template-columns: 125px 125px;
      
      .input-data {
        width: 90px;
        border: 1px solid ${colors.genoaBlue};
        display: inline-block;
        text-align: center;
        font-family: 'Avenir Next GEO W05 Regular';
        border-radius: 7px;
      }

      .react-datepicker-wrapper {
        display: inline-block;
        padding: 0;
        border: 0;
        width: min-content;
        
      }

      .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
        background-color: white;
        color: ${colors.genoaBlue};
        font-weight: bold;
        :hover {
          border-radius: 0.3rem;
          background-color: #f0f0f0;
        }
      }


      .react-datepicker__day--selected, .react-datepicker__month-text--selected,  .react-datepicker__quarter-text--selected, .react-datepicker__year-text--selected {
        border-radius: 0.3rem;
        background-color: ${colors.genoaBlue};;
        color: #fff;
      }

      .datepicker-selector-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 0.8rem;
      }
    }

    .date-filter-send-btn {
      margin-top: 12px;
      padding: 2px 8px;
      background-color: ${colors.genoaBlue};
      :hover {
        background-color: #1976d2;
      }
    }
  }

  .vidas-boxes-resp {
    display: flex;
    flex-direction: column;
    max-width: 700px;
    max-height: 100%;
    overflow: inherit;
    margin: 0;

    // background-color: ${colors.genoaBlue};
    border-radius: 15px;
    padding: 25px 20px;

    .title_usuario_demandas {
      padding: 5px;

      span {
        font-size: 1.3em;
        font-family: 'Avenir Next GEO W05 Bold';
      }
    }
  }

  .boxes_usuario {
    // background-color: ${colors.genoaBlue};
    border-radius: 15px;
    padding: 32px 20px;
    width: 405px;

    .title_usuario {
      color: black;
      font-size: 1.3em;
      font-family: 'Avenir Next GEO W05 Bold';
    }
  }

  .home-main-content-container {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;

    height: 100%;
    max-height: 100%;

    .title {
      margin-top: 30px;
      span {
        font-size: 1.3em;
        font-family: 'Avenir Next GEO W05 Bold';
        color: black;
      }
    }

    .main-content-column-one {
      display: grid;
      grid-template-columns: 3fr 1.5fr;
      column-gap: 80px;
      max-height: 100%;
      height: 30px;

      .column-one-row-one {
        width: 100%;
      }

      .column-one-row-two {
        max-width: 100%;
        width: 400px;

        .trofeu_pessoas_cuidadas {
          font-size: 2.1em;
          padding: 5px 15px;
        }
    
        .pessoas_cuidadas {
          display: flex;
          flex-direction: column;
          background-color: ${colors.genoaBlue};
          border-radius: 15px;
          padding: 35px 30px;
          
        }
      }
    }

    .main-content-column-two {
      display: grid;
      grid-template-columns: 0.5fr 1.5fr;
      column-gap: 70px;
      max-height: 100%;

      .usuario_content {
        width: 405px;
      }
      
      .icon_users_demandas {
        font-size: 2em;
        padding: 5px 15px;
      }
    }

    .main-content-column-three {
      padding: 30px 20px;
    }
  }

  @media (min-width: 1280px and max-width: 1350px) {
    .home-main-content-container {
      display: grid;
      grid-template-rows: 1fr 1fr 1fr;
  
      height: 100%;
      max-height: 100%;
  
      .title {
        margin-top: 30px;
        span {
          font-size: 1.3em;
          font-family: 'Avenir Next GEO W05 Bold';
          color: black;
        }
      }
  
      .main-content-column-one {
        display: grid;
        grid-template-columns: 3fr 1.5fr;
        column-gap: 80px;
        max-height: 100%;
        height: 30px;
  
        .column-one-row-one {
          
        }
  
        .column-one-row-two {
          max-width: 100%;
          width: 400px;
  
          .trofeu_pessoas_cuidadas {
            font-size: 2.1em;
            padding: 5px 15px;
          }
      
          .pessoas_cuidadas {
            display: flex;
            flex-direction: column;
            background-color: ${colors.genoaBlue};
            border-radius: 15px;
            padding: 35px 30px;
            
          }
        }
      }
  
      .main-content-column-two {
        display: grid;
        grid-template-columns: 0.5fr 1.5fr;
        column-gap: 50px;
        max-height: 100%;
  
        .usuario_content {
          width: 405px;
        }
        
        .icon_users_demandas {
          font-size: 2em;
          padding: 5px 15px;
        }
      }
  
      .main-content-column-three {
        padding: 30px 20px;
      }
    }

    .tickets {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-gap: 10px;
      .box-ticket {
        padding: 0px 10px;
      }
    }

    .vidas-boxes {
      place-content: center;
      display: flex;
      flex-direction: row;
      column-gap: 50px;
      padding-top: 15px;
      text-align: center;
      

      .box-vidas {
        display: flex;
        flex-direction: column;
        padding: 0px;
      }
    }
    
  }

  @media (min-width: 900px and max-width: 1280px) {
    .home-main-content-container {
      display: grid;
      grid-template-rows: 1fr 1fr 1fr;
  
      height: 100%;
      max-height: 100%;
  
      .title {
        margin-top: 20px;
        span {
          font-size: 1em;
          font-family: 'Avenir Next GEO W05 Bold';
          color: black;
        }
      }
  
      .main-content-column-one {
        display: grid;
        grid-template-columns: 3fr 1.5fr;
        column-gap: 80px;
        max-height: 100%;
        width: 300px;
  
        .column-one-row-one {
          width: 300px;
        }
  
        .column-one-row-two {
          max-width: 100%;
          width: 300px;
  
          .trofeu_pessoas_cuidadas {
            font-size: 2.1em;
            padding: 5px 15px;
          }
      
          .pessoas_cuidadas {
            display: flex;
            flex-direction: column;
            background-color: ${colors.genoaBlue};
            border-radius: 15px;
            padding: 35px 30px;
            
          }
        }
      }
  
      .main-content-column-two {
        display: grid;
        grid-template-columns: 0.5fr 1.5fr;
        column-gap: 50px;
        max-height: 100%;
  
        .usuario_content {
          width: 405px;
        }
        
        .icon_users_demandas {
          font-size: 2em;
          padding: 5px 15px;
        }
      }
  
      .main-content-column-three {
        padding: 30px 20px;
      }
    }

    .tickets {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-gap: 0px;
      .box-ticket {
        padding: 0px 15px;
        max-width: 250px;
      }
    }

    .vidas-boxes {
      place-content: center;
      display: flex;
      flex-direction: row;
      column-gap: 50px;
      padding-top: 15px;
      text-align: center;
      

      .box-vidas {
        display: flex;
        flex-direction: column;
        padding: 0px;
      }
    }
  }
  @media (min-width: 750px and max-width: 900px){
    .tickets {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-gap: 0px;
      .box-ticket {
        padding: 0px 10px;
        max-width: 200px;
      }
    }
    .vidas-boxes{
      place-content: center;
      display: flex;
      flex-direction: row;
      column-gap: 50px;
      padding-top: 15px;
      text-align: center;
      

      .box-vidas {
        display: flex;
        flex-direction: column;
        padding: 0px;
      }
      
    }
  }

`;
