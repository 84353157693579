import {
  keysAbaDadosBancarios,
  keysAbaDadosBasicos,
  keysAbaDadosContato,
  keysAbaDadosImc,
  keysAbaDadosVinculo,
  keysAbaEndereco,
  keysAbaFichaProposta,
  keysAbaScanDocumentos
} from "./camposInclusaoPorAba";


const determinaAbasDisponiveis = (
  {
    camposInclusao,
    setAbasDisponiveis,
    state,
  }
) => {
  const showSelecioneTitular = state['tipoBeneficiario'] && state['tipoBeneficiario'] === "Dependentes";
  const showDadosPlanos = (state['tipoBeneficiario'] && state['tipoBeneficiario'] !== "Dependentes") ||
    (state['tipoBeneficiario'] && state['tipoBeneficiario'] === "Dependentes" && state['titular'])
  const showDadosBasicos = keysAbaDadosBasicos.some((item) => camposInclusao[item]);
  const showDadosEndereco = state['tipoBeneficiario'] && state['tipoBeneficiario'] === "Titular" &&
    keysAbaEndereco.some((item) => camposInclusao[item]);
  const showDadosContato = state['tipoBeneficiario'] && state['tipoBeneficiario'] === "Titular" &&
    keysAbaDadosContato.some((item) => camposInclusao[item]);
  const showDadosVinculo = state['tipoBeneficiario'] && state['tipoBeneficiario'] === "Titular" &&
    keysAbaDadosVinculo.some((item) => camposInclusao[item]);
  const showDadosImc = keysAbaDadosImc.some((item) => camposInclusao[item]);
  const showDadosBancarios = state['tipoBeneficiario'] && state['tipoBeneficiario'] === "Titular" &&
    keysAbaDadosBancarios.some((item) => camposInclusao[item]);
  const showScanDocumentos = keysAbaScanDocumentos.some((item) => camposInclusao[item]);
  const showFichaProposta = keysAbaFichaProposta.some((item) => camposInclusao[item])

  setAbasDisponiveis({
    showSelecioneTitular,
    showDadosPlanos,
    showDadosBasicos,
    showDadosEndereco,
    showDadosContato,
    showDadosVinculo,
    showDadosImc,
    showDadosBancarios,
    showScanDocumentos,
    showFichaProposta
  })

};

export default determinaAbasDisponiveis;
