import './style.scss';

const Button = (props) => {
  const {
    children,
    variant = 'broker',
    type = 'button',
    onClick,
    className = '',
    isDisabled,
    ...rest
  } = props;

  return (
    <button
      type={type}
      className={`ds-button ds-button--${variant} ${
        isDisabled ? 'ds-button--disabled' : ''
      } ${className}`}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
