import { ImCross, ImCheckmark } from 'react-icons/im';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent } from './styles';

const DialogPoliceOwnerChange = ({
  openModal,
  previousEstipulante,
  newEstipulante,
  confirmationChangeHandler,
  handleCloseModal
}) => {
  return (
    <Dialog
      open={openModal === 'estipulante-edit'}
      onClose={handleCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <div className="title">
          <h3>Confirmar troca de Estipulante</h3>
        </div>
        <div className="content">
          <p>Trocar Estipulante</p>
          <p className="estipulante-paragraph">
            {previousEstipulante ? previousEstipulante : ''}
          </p>
          <p>por</p>
          <p className="estipulante-paragraph">{newEstipulante}?</p>
        </div>
        <div className="edit-estipulante-confirmation">
          <ImCheckmark
            size={25}
            className="check-icon"
            onClick={confirmationChangeHandler}
          />
          <ImCross
            size={21}
            className="cancel-icon"
            onClick={handleCloseModal}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DialogPoliceOwnerChange;
