import React from "react";
import {
  ConfirmationButtonsContainer,
  DialogLoadingSpinnerContainer,
  DialogSubTitle,
  DialogTitle,
  DialogWrapper,
  RequestCompletedContainer
} from "./styles-DialogPopper";

import { ImCheckmark, ImCross } from "react-icons/im";
import { CircularProgress } from "@mui/material";
import colors from "styles/colors";


const DialogConfirmRequest = (
  {
    setOpenConfirmationPopper,
    setEnvioInclusao,
    loading,
    requestCompleted,
    resetBeneficiaryInfo,
    closeDrawer,
  }
) => {
  return (
    <DialogWrapper>
      {
        loading ? (
          <DialogLoadingSpinnerContainer>
            <CircularProgress
              size={60}
              thickness={3}
              sx={{
                color: colors.rhBlue,
              }}
            />
          </DialogLoadingSpinnerContainer>
        ) : (
          !requestCompleted ? (
            <>
              <DialogTitle>
                <h3>Confirma o envio do pedido de inclusão?</h3>
              </DialogTitle >
              <ConfirmationButtonsContainer>
              <div
                  className="button__container"
                  onClick={() => {
                    setEnvioInclusao(true)
                  }}
                >
                  <span
                    className="button__confirm_request"
                  >
                    Sim
                  </span>
                  <ImCheckmark
                    className="button__confirm_request"
                    size={19}
                  />
                </div>
                <div
                  className="button__container"
                  onClick={() => setOpenConfirmationPopper(false)}
                >
                  <span
                    className="button__hold_request"
                  >
                    Não
                  </span>
                  <ImCross
                    className="button__hold_request"
                    size={16}

                  />
                </div>
              </ConfirmationButtonsContainer>
            </>
          ) : (
            <RequestCompletedContainer>
              <div>
                <DialogTitle>
                  <h3>Pedido realizado com sucesso!</h3>
                </DialogTitle>
                <DialogSubTitle>
                  <span>Deseja fazer uma nova inclusão?</span>
                </DialogSubTitle>
              </div>
              <ConfirmationButtonsContainer>
                <div
                  className="button__container"
                  onClick={() => {
                    resetBeneficiaryInfo()
                  }}
                >
                  <span
                    className="button__confirm_request"
                  >
                    Sim
                  </span>
                  <ImCheckmark
                    className="button__confirm_request"
                    size={19}
                  />
                </div>
                <div
                  className="button__container"
                  onClick={closeDrawer}
                >
                  <span
                    className="button__hold_request"
                  >
                    Não
                  </span>
                  <ImCross
                    className="button__hold_request"
                    size={16}

                  />
                </div>
              </ConfirmationButtonsContainer>
            </RequestCompletedContainer>
          )
        )
      }
    </DialogWrapper >
  )
}

export default DialogConfirmRequest;