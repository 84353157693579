import { combineReducers } from 'redux';

import { userReducers } from './user';


const reducers = combineReducers({
  userReducers
});

export { reducers };
