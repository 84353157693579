import React, { useState } from 'react';
import { Wraper } from './styles';
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";

import api from 'services/api';
import { useParams } from 'react-router-dom';
import InputMask from 'react-input-mask';
import Loading from 'components/Loading';
import { BiUpload, BiFolderOpen } from "react-icons/bi";
import { BsArrowRight } from "react-icons/bs";
import { useEmitTicket } from "contexts/emitTicket";


const AddPlano = ({ formExt, planosBloqueados }) => {
  const history = useHistory();
  const {
    externalForm, 
    updateExternalForm,
    resetExternalForm, 
    updateExternalFormStage,
    resetExternalFormStage, 
    setEmitTicket,
  } = useEmitTicket();

  const { register, handleSubmit, control, formState: { errors }, watch } = useForm();

  const formData = externalForm;

  // const planoSaude = register("planoSaude");
  // const planoOdonto = register("planoOdonto");
  const [erroCampos, setErrorCampos] = useState(false);
  const [error, setError] = useState();

  const [planos, setPlanos] = useState([]);
  const [details, setDetails] = useState({});
  const [nomeTitular, setNomeTitular] = useState(" ");
  const [uuid, setUuid] = useState();
  const { corretoraId, estipulanteId, contratoId } = useParams();

  const [loading, setLoading] = useState(false)

  const options = {
    indices: true,
    nullsAsUndefineds: false,
    booleansAsIntegers: false,
    allowEmptyArrays: false,
  };

  const sendData = (data) => {
    setLoading(true)
    if (!data.plano && planosBloqueados === false) {
      setErrorCampos(true)
      return
    }

    const body = {
      nome: nomeTitular,
      uuid: uuid,
      plano: data.plano,
      ...options
    };
    api.post(`/planos/ticket/${corretoraId}/${estipulanteId}/${contratoId}`,
      body,
    ).then(resp => {
      if (data.formExt[0]) {

        const formData = new FormData();
        formData.append('file', data.formExt[0]);

        const headersFormExt = {
          ticket: resp.data,
        }
        api.post('/ticket/formext', formData, { headers: headersFormExt })
      }
      updateExternalForm({ planos: data });
      resetExternalForm();
      resetExternalFormStage();
      setEmitTicket({ ticket: resp.data });

      history.push(`/questionario-inclusao-ticket/${corretoraId}/${estipulanteId}/${contratoId}`)
    }).catch(err => {
      setError(err.response?.data)
      console.log(err)
    }).finally(() => {
      setLoading(false)
    })
  }

  const getData = () => {
    const estipulante_id = formData.vinculo.vinculo
    const headers = {
      estipulante: estipulante_id,
      corretora: corretoraId,
      contrato: contratoId,
    }
    api.get('/ticket/values', { headers: headers })
      .then(resp => {
        setDetails(resp.data.contrato)
        setPlanos(resp.data.planos)
      }).catch(err => {
        console.log(err)
      })
  }

  const handleCPF = (fun, target) => {
    fun.onChange(target.value)

    if (target.value.length === 14) {
      const body = {
        cpf: target.value
      }
      api.post(`/search/${corretoraId}/${estipulanteId}/${contratoId}`, body)
        .then(resp => {
          setNomeTitular(resp.data.nome)
          setUuid(resp.data.uuid)
        }).catch(err => {
          setNomeTitular(err.message)
        })
    }

  }

  React.useEffect(() => {
    getData()
  }, [])

  const typeProduct = {
    'Saúde': (planos) => {
      return (
        <div className="select-group">
          <label >Qual plano  de saúde você deseja adquirir?</label>

          <select name="plano" {...register("plano", { required: true })}>
            <option value="">Selecione</option>
            {
              planos.map((plano, index) => {
                return (
                  <option value={plano.id}>{plano.tipo}</option>
                )
              })
            }
          </select>
        </div>
      )
    },
    'Odonto': (planos) => {
      return (
        <div className="select-group">
          <label>Qual plano odontológico você deseja adquirir?</label>

          <select name="plano" {...register("plano", { required: true })}>
            <option value="">Selecione</option>
            {
              planos.map((plano, index) => {
                return (
                  <option value={plano.id}>{plano.tipo}</option>
                )
              })

            }
          </select>
        </div>
      )
    },
    'undefined': () => {
      return
    }
  }

  const changeStage = (stage) => {
    updateExternalFormStage(stage);
  }

  return (
    <Wraper>
      <Loading open={loading} setOpen={() => setLoading(false)} />
      <fieldset>
        <legend>Insira o CPF do Titular</legend>
        <div className="input-group">
          {errors.cpfTitularr && <span className="errorsMsg">Campo obrigatório</span>}
          <Controller
            name="cpfTitular"
            control={control}
            rules={{ required: false }}
            render={({ field }) => <InputMask
              value={field.value || ''}
              maskChar={null}
              mask="999.999.999-99"
              onChange={({ target }) => handleCPF(field, target)}
              type="text"
              placeholder="CPF" />}
          />
        </div>
        <div className="nomeTitular">{nomeTitular ? nomeTitular : <span className="erroPlanos">CPF não encontrado</span>}</div>
        <br></br>
      </fieldset>
      <fieldset>
        {planosBloqueados ? null : <legend>Escolha o seu plano</legend>}
        {planosBloqueados ? null : typeProduct[details?.search](planos)}
        {/* <div className="select-group">
            <label >Qual plano  de saúde você deseja adquirir?</label>

            <select name="planoSaude" onChange={(e) => {
              planoSaude.onChange(e);
              console.log(e)
              setErrorCampos(false)
            }}
            >
              <option value="">Selecione</option>
              {
                saude.map((plano, index) => {
                  return (
                    <option value={plano.id}>{plano.tipo}</option>
                  )
                })
              }
              <option value={false}>Não quero plano de saúde</option>
            </select>
          </div> */}

        {/* <div className="select-group">
            <label>Qual plano odontológico você deseja adquirir?</label>
            <select name="planoOdonto" onChange={(e) => {
              planoOdonto.onChange(e);
              setErrorCampos(false)
            }}>
              <option value="">Selecione</option>
              {
                odonto.map((plano, index) => {
                  return (
                    <option value={plano.id}>{plano.tipo} - {plano.preco}</option>
                  )
                })
                
              }
              <option value={false}>Não quero plano dental</option>
            </select>
          </div> */}
      </fieldset>
      {error && <div className="erroPlanos">
        <span className="erroPlanos">{error}</span>
      </div>}


      {erroCampos && <div className="erroPlanos">
        <span className="erroPlanos">Escolha pelo menos um plano</span>
      </div>}

      <div className="text-desc">
        <p> Caso haja, os seus dependentes irão aderir ao mesmo plano que você.</p>
      </div>

      {
        formExt
          ?
          <div className="input-files-group formExt">
            {/* <legend>É necessário preencher um formulário externo da seguradora <a href={formExt}>clique aqui para baixar ele</a></legend> */}
            <legend>Faça o upload do formulário baixado anteriormente

            </legend>
            <label className="label-file">
              {watch('formExt')?.[0]?.name ?
                <span><BiFolderOpen /> {watch('formExt')[0]?.name} </span> :
                <span>Formulário externo <BiUpload /></span>
              }
              {/* <input className="input-file"
                {...register('formExt', { required: true })}
                type="file" /> */}

              <input
                accept="image/jpeg,
                image/jpg,
                image/png,
                image/pjpeg,
                application/pdf,
                application/msword,
                application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                text/plain,
                application/vnd.ms-excel"
                className="input-file"
                {...register('formExt', { required: true })}
                type="file" />
            </label>
          </div>
          :
          null
      }


      <div className="button-areas">
        <button type="button" onClick={() => changeStage('programaBeneficios')}>Voltar</button>
        <button type="button" onClick={handleSubmit(sendData)}>Finalizar <BsArrowRight /></button>
      </div>
    </Wraper>
  )
}

export default AddPlano
