import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FiLogIn } from "react-icons/fi";
import { Wraper } from './styles'
import LogoCoinsure from 'assets/imgs/logo-coinsure.png'
import api from 'services/api.js'

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from './TabPanel'

import { useForm, Controller } from "react-hook-form";

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const regexCNPJ = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/;
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  header: {
    boxShadow: 'none',
    backgroundColor: '#f0e7ff'
  },
  tabs: {
    fontFamily: 'Avenir Next GEO W05 Demi'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));


export default function RecuperarSenha(props) {

  const userKey = "jwt_token_user";

  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => { setValue(newValue) };
  const [loading, setLoading] = React.useState(false);

  const [errorRH, setErrorRH] = useState()
  const [errorCR, setErrorCR] = useState()
  const [resp, setResp] = useState()

  const { control, handleSubmit } = useForm();

  const history = useHistory()

  const RHonSubmit = data => {
    setLoading(true);
    const body = {
      email: data.rhEmail,
    }
    api.post('/recuperar-senha/rh', body)
      .then(resp => {
        setErrorRH()
        setResp("Solicitação enviada com sucesso para seu email")
      })
      .catch(err => {
        setErrorRH(err.response.data)
      }).finally(() => {
        setLoading(false);
      })
  };

  const CRonSubmit = data => {
    setLoading(true);
    const body = {
      cnpj: data.corretorCnpj.replace(regexCNPJ, "$1.$2.$3/$4-$5"),
      password: data.corretorPassword
    }
    api.post('/recuperar-senha/corretora', body)
      .then(resp => {
        setErrorCR()
        setResp("Solicitação enviada com sucesso para seu email")
      })
      .catch(err => {
        setErrorCR(err.response.data)
      }).finally(() => {
        setLoading(false);
      })
  };

  const handleClose = () => {
    setLoading(false);
  };

  useEffect(() => {
    const json = localStorage.getItem(userKey)
    const userData = JSON.parse(json)
    if (userData?.corretora) {
      history.push('/corretor/home')
    }
    if (userData?.estipulante) {
      history.push('/rh/home')
    }
  })


  return (
    <Wraper>
      <Backdrop className={classes.backdrop} open={loading} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <header>
        <Container maxWidth="xl">
          <div className="top-area">
            <a className="nav-logo" href="/">
              <img src={LogoCoinsure} width="200" alt="" loading="lazy" />
            </a>
          </div>
        </Container>

      </header>
      <main>
        <Container>
          <Grid container justify="center" spacing={3}>
            <Grid item xs={12} md={6}>
              <Card>
                <div className={classes.root}>
                  <AppBar className={classes.header} position="static" color="default">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="fullWidth"
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example"
                    >
                      <Tab className={classes.tabs} label="RH" {...a11yProps(0)} />
                      <Tab className={classes.tabs} label="Corretor" {...a11yProps(1)} />
                    </Tabs>
                  </AppBar>


                  <TabPanel value={value} index={0}>
                    <CardContent>
                      <div className="title">Insira seu email cadastrado</div>
                      <div className="title">Nós lhe enviaremos um email com link para recadastrar sua senha.</div>
                      <div className="form">
                        <form validate="true" autoComplete="off" onSubmit={handleSubmit(RHonSubmit)} >
                          <div className="flex-center">
                            {errorRH && <div className="erro-msg"> {errorRH}</div>}
                          </div>
                          <FormControl fullWidth className="form-controll">
                            <Controller
                              name="rhEmail"
                              control={control}
                              defaultValue=""
                              render={({ field }) => <TextField {...field}
                                type="email"
                                label="E-mail"
                                required />}
                            />
                          </FormControl>

                          <div className="flex-center mb-1">
                            <button type="submit" >Enviar <span className="icon"><FiLogIn /></span> </button>
                          </div>
                          {resp && <div className="resp-success">{resp}</div>}
                        </form>
                      </div>

                      {/* <div className="form-link">
                        <div>
                          <Link to="/forgot"> Esqueci a minha senha</Link>
                        </div>
                        <div>
                          <a href="/">Ainda não sou cliente</a>
                        </div>
                      </div> */}

                    </CardContent>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <CardContent>

                      <div className="title">Insira o CNPJ ou E-MAIL</div>
                      <div className="title">Nós lhe enviaremos link de recadastro de senha para o email cadastrado na corretora.</div>
                      <div className="form">
                        <form validate="true" autoComplete="off" onSubmit={handleSubmit(CRonSubmit)}  >
                          <div className="flex-center">
                            {errorCR && <div className="erro-msg"> {errorCR}</div>}
                          </div>
                          {/* <FormControl fullWidth className="form-controll">
                            <Controller
                              name="corretorEmail"
                              control={control}
                              defaultValue=""
                              render={({ field }) => <TextField {...field}
                                type="email"
                                label="E-mail"
                                required />}
                            />
                          </FormControl> */}

                          <FormControl fullWidth className="form-controll">
                            <Controller
                              name="corretorCnpj"
                              control={control}
                              defaultValue=""
                              render={({ field }) => 
                              <TextField 
                                {...field}
                                label="CNPJ / E-MAIL"
                                value={ field.value.replace(regexCNPJ, "$1.$2.$3/$4-$5") }
                                required 
                              />}
                            />
                          </FormControl>

                          <div className="flex-center mb-1">
                            <button type="submit" >Enviar<span className="icon"><FiLogIn /></span> </button>
                          </div>
                          {resp && <div className="resp-success">{resp}</div>}
                        </form>
                      </div>

                      {/* <div className="form-link">
                        <div>
                          <Link to="/forgot"> Esqueci a minha senha</Link>
                        </div>
                        <div>
                          <a href="/">Ainda não sou cliente</a>
                        </div>
                      </div> */}

                    </CardContent>
                  </TabPanel>
                </div>

              </Card>

            </Grid>

          </Grid>
        </Container>



      </main>

    </Wraper>
  )
}
