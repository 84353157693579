import { CircularProgress, ClickAwayListener } from "@material-ui/core";
import React from "react";

import {
  ContentContainer,
  DarkenBackground, PopperWrapper,

} from "./styles-UpdatePopper";
import { ImCheckmark, ImCross } from "react-icons/im";

const UpdatePopper = ({
  selectedPlan,
  setOpenPopper,
  deactivatePlan,
  activatePlan,
  removePlan
}) => {
  const { plano, index, type } = selectedPlan;
  const [loading, setLoading] = React.useState(false)
  const [requestDone, setRequestDone] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState(undefined);
  const [confirmationMessage, setConfirmationMessage] = React.useState(undefined)

  const deactivate = async () => {
    setRequestDone(true)
    setLoading(true)
    const resp = await deactivatePlan(plano, index)

    if (resp?.status !== 200) {
      setConfirmationMessage(undefined)
      setErrorMessage(resp?.message)
      setTimeout(() => {
        setOpenPopper(false)
      }, 1500)
    } else {
      setErrorMessage(undefined)
      setConfirmationMessage("Plano inativado com sucesso!")
      setTimeout(() => {
        setOpenPopper(false)
      }, 1500)
    }
    setLoading(false)
  }

  const remove = async () => {
    setRequestDone(true)
    setLoading(true)
    const resp = await removePlan(plano, index)

    if (resp?.status !== 200) {
      setConfirmationMessage(undefined)
      setErrorMessage(resp?.message)
      setTimeout(() => {
        setOpenPopper(false)
      }, 1500)
    } else {
      setErrorMessage(undefined)
      setConfirmationMessage("Plano removido com sucesso!")
      setTimeout(() => {
        setOpenPopper(false)
      }, 1500)
    }
    setLoading(false)
  }

  const activate = async () => {
    setRequestDone(true)
    setLoading(true)
    const resp = await activatePlan(plano, index)

    if (resp?.status !== 200) {
      setConfirmationMessage(undefined)
      setErrorMessage(resp?.message)
      setTimeout(() => {
        setOpenPopper(false)
      }, 1500)
    } else {
      setErrorMessage(undefined)
      setConfirmationMessage("Plano ativado com sucesso!")
      setTimeout(() => {
        setOpenPopper(false)
      }, 1500)
    }
    setLoading(false)
  }

  const renderContent = (
    {
      type,
      updateRequest
    }
  ) => {
    return (
      <ContentContainer>
        {
          !requestDone && !loading? (
            <>
              <div className="content-title">
                <span>Deseja {type}: </span>
                <div>
                  <span className="span-plano">{plano?.tipo}</span>
                  <span>?</span>
                </div>
              </div>
              <div className="icon-container">
                <ImCheckmark
                  className="icon check"
                  onClick={updateRequest}
                />
                <ImCross
                  className="icon cross"
                  onClick={() => setOpenPopper(false)}
                />
              </div>
            </>
          ) : (
            loading ? (
              <div className="message">
                <CircularProgress size={50} thickness={1.4} className="loading"/>
              </div>
            ) : (
              <>
                {
                  errorMessage && (
                    <div className="message failure">
                      <span>{errorMessage}</span>
                    </div>
                  )
                }
                {
                  confirmationMessage && (
                    <div className="message success">
                      <span>{confirmationMessage}</span>
                    </div>
                  )
                }
              </>
            )
          )
        }
      </ContentContainer>
    )
  }

  const renderUpdateContent = () => {
    switch (type) {
      case "inativar":
        return renderContent({
          type,
          updateRequest: deactivate
        })
      case "remover":
        return renderContent({
          type,
          updateRequest: remove
        })

      default:
        return renderContent({
          type,
          updateRequest: activate
        })
    }
  }

  return (
    <DarkenBackground>
      <ClickAwayListener
        onClickAway={() => {
          setOpenPopper(false)
        }}
      >
        <PopperWrapper>
          {
            renderUpdateContent()
          }
        </PopperWrapper>
      </ClickAwayListener>
    </DarkenBackground>
  )
}

export default UpdatePopper;
