import { useState } from 'react';
import { AiOutlineMinus, AiFillCaretLeft } from 'react-icons/ai';
import DatePicker from 'react-datepicker';
import { Button } from '@material-ui/core';
import { ReactComponent as IconTooth } from 'assets/imgs/svg/teeth.svg';
import { RiHeartPulseFill } from 'react-icons/ri';
import { BiPlusCircle } from 'react-icons/bi';
import { Radio } from '@mui/material';
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import optionsMotivoExclusao from 'utils/objects/optionsExclusionReason';
import { Div } from './styles';

const MovimentExclusion = ({
  selectedEstipulante,
  selectedSubEstipulante,
  formatNomesTitulares,
  selectedBeneficiario,
  setSelectedBeneficiario,
  beneficiarioContratos,
  setBeneficiariosContratos,
  planosToRemove,
  setPlanosToRemove,
  motivoExclusao,
  setMotivoExclusao,
  date,
  setDate,
  postExclusionMoviment
}) => {
  const [nomeBeneficiarioFiltro, setNomeBeneficiarioFiltro] = useState('');
  const [beneficiarioSelectionOpen, setBeneficiarioSelectionOpen] =
    useState(false);
  const [showTitulares, setShowTitulares] = useState(true);
  const [showDependentes, setShowDependentes] = useState(true);
  const [showDependentesTitular, setShowDependentesTitular] = useState(false);

  const filterBeneficiarioByName = (beneficiarios) => {
    const filteredByNameAndCpf = beneficiarios.filter((beneficiario) =>
      beneficiario.nome
        .toLowerCase()
        .includes(nomeBeneficiarioFiltro.toLowerCase())
    );
    return filteredByNameAndCpf;
  };

  const filterBeneficiariosByTipo = (beneficiarios) => {
    let beneficiariosFiltrados = [];
    if (!showTitulares) {
      const filteredByTipo = beneficiarios.filter(
        (beneficiario) => beneficiario.tipo !== 'Titular'
      );
      beneficiariosFiltrados = filteredByTipo;
    } else {
      beneficiariosFiltrados = beneficiarios;
    }

    if (!showDependentes) {
      const filteredByTipo = beneficiariosFiltrados.filter(
        (beneficiario) => beneficiario.tipo !== 'Dependente'
      );
      beneficiariosFiltrados = filteredByTipo;
    }

    return beneficiariosFiltrados;
  };

  const filterBeneficiarios = () => {
    let allBeneficiarios = [];

    if (selectedEstipulante) {
      if (
        selectedEstipulante.beneficiarios &&
        selectedEstipulante.beneficiarios.estipulanteBeneficiarios
      ) {
        allBeneficiarios =
          selectedEstipulante.beneficiarios.estipulanteBeneficiarios;
      }
    }
    if (selectedSubEstipulante) {
      if (
        selectedEstipulante.beneficiarios &&
        selectedEstipulante.beneficiarios.subEstipulanteBeneficiarios
      ) {
        allBeneficiarios =
          selectedEstipulante.beneficiarios.subEstipulanteBeneficiarios.filter(
            (titular) =>
              titular.estipulante_id === Number(selectedSubEstipulante)
          );
      }
    }

    return allBeneficiarios;
  };

  const renderBeneficiariosFiltrados = () => {
    const beneficiariosFiltrados = filterBeneficiarios();

    if (beneficiariosFiltrados.length) {
      const beneficiariosNomesFormatados = formatNomesTitulares(
        beneficiariosFiltrados
      );
      const filtradosPorNome = filterBeneficiarioByName(
        beneficiariosNomesFormatados
      );
      const filtradosPorTipo = filterBeneficiariosByTipo(filtradosPorNome);
      return filtradosPorTipo;
    }
  };

  const handleCloseBeneficiarioTable = (event) => {
    if (
      event &&
      event.target &&
      event.target.parentNode &&
      event.target.parentNode.className
    ) {
      const parentTargetNodeClassname = event.target.parentNode.className;
      const isSelecaoBeneficiarioHeroTitle =
        parentTargetNodeClassname === 'selecao-beneficiario-hero-title';
      const isEstipulanteSelectRow =
        parentTargetNodeClassname === 'estipulante-select-row';
      const isSubEstipulanteSelectContainer =
        parentTargetNodeClassname === 'sub-estipulante-select-container';
      if (
        beneficiarioSelectionOpen &&
        !(
          isEstipulanteSelectRow ||
          isSubEstipulanteSelectContainer ||
          isSelecaoBeneficiarioHeroTitle
        )
      ) {
        setBeneficiarioSelectionOpen(false);
      }
    }
  };

  const showTitularesClickHandler = () => {
    setShowTitulares((prevState) => !prevState);
  };

  const showDependentesClickHandler = () => {
    setShowDependentes((prevState) => !prevState);
  };

  const renderIconeTipoProduto = (contrato) => {
    switch (contrato.nome_produto) {
      case 'Plano de Vida':
        return (
          <div>
            <span>
              {' '}
              <RiHeartPulseFill className="plano-icon" size={30} />{' '}
            </span>
          </div>
        );

      case 'Plano Odontológico':
        return (
          <div>
            <span>
              {' '}
              <IconTooth className="plano-icon" size={30} />{' '}
            </span>
          </div>
        );

      default:
        return (
          <div>
            <span>
              {' '}
              <BiPlusCircle className="plano-icon" size={30} />{' '}
            </span>
          </div>
        );
    }
  };

  const planoOptionClickHandler = (contrato) => {
    setPlanosToRemove((prevState) => ({
      ...prevState,
      [contrato.contrato_id]: !prevState[contrato.contrato_id]
    }));
  };

  const renderContratoOptions = () => {
    return beneficiarioContratos.map((contrato, index) => {
      return contrato.movimentacao_remocao ? (
        <div className={'remove-plano-option-inactive'} key={index}>
          {renderIconeTipoProduto(contrato)}
          <div>
            {contrato?.numero_apolice ? (
              <span>{`${contrato.nome_completo} (${contrato.numero_apolice})`}</span>
            ) : (
              <span>{contrato.nome_completo}</span>
            )}
            <span className="span-tipo-plano">
              <AiOutlineMinus size={13} />
            </span>
            <span className="span-tipo-plano">{contrato.tipo_plano}</span>
          </div>
        </div>
      ) : (
        <div
          className={
            planosToRemove[contrato.contrato_id]
              ? 'remove-plano-option-selected'
              : 'remove-plano-option'
          }
          onClick={() => planoOptionClickHandler(contrato)}
          key={index}
        >
          {renderIconeTipoProduto(contrato)}
          <div>
            {contrato?.numero_apolice ? (
              <span>{`${contrato.nome_completo} (${contrato.numero_apolice})`}</span>
            ) : (
              <span>{contrato.nome_completo}</span>
            )}

            <span className="span-tipo-plano">
              <AiOutlineMinus size={13} />
            </span>
            <span className="span-tipo-plano">{contrato.tipo_plano}</span>
          </div>
        </div>
      );
    });
  };

  const renderOptionsMotivoExclusao = () => {
    const optionKeys = Object.keys(optionsMotivoExclusao);
    const defaultValue = optionKeys.shift();
    const validOptionKeys = optionKeys;
    return (
      <>
        <option value={defaultValue} style={{ fontWeight: 'bold' }} disabled>
          {optionsMotivoExclusao[defaultValue]}
        </option>
        {validOptionKeys.map((option) => (
          <option key={option} value={option}>
            {optionsMotivoExclusao[option]}
          </option>
        ))}
      </>
    );
  };

  const selectMotivoChangeHandler = (event) => {
    setMotivoExclusao(event.target.value);
  };

  const renderSelectMotivoExclusao = () => {
    return (
      <select value={motivoExclusao} onChange={selectMotivoChangeHandler}>
        {renderOptionsMotivoExclusao()}
      </select>
    );
  };

  const renderLimparDataButton = () => {
    if (date !== '') {
      return (
        <div className="clear-date-option-container">
          <span className="clear-date-option" onClick={() => setDate('')}>
            Limpar data
          </span>
        </div>
      );
    }

    return (
      <div className="clear-date-option-container">
        <span className="clear-date-option"></span>
      </div>
    );
  };

  const isAnyContratoSelected = () => {
    const keys = Object.keys(planosToRemove);
    return keys.some((key) => planosToRemove[key]);
  };

  const exclusaoContratoHandler = async () => {
    await postExclusionMoviment();
  };

  const renderBeneficiarioPlanoInfo = () => {
    return (
      <div
        className={
          selectedBeneficiario
            ? 'beneficiario-planos-info-container-open'
            : 'beneficiario-planos-info-container'
        }
        style={{
          height: selectedBeneficiario
            ? `${beneficiarioContratos.length * 44 + 57 + 120 + 264}px`
            : 0
        }}
      >
        {selectedBeneficiario ? (
          <>
            <div className="remove-beneficiario-container">
              <div>
                <span className="remove-info-title">
                  De qual plano o colaborador será excluído?
                </span>
              </div>
              <div className="remove-planos-options">
                {renderContratoOptions()}
              </div>
            </div>
            <div className="remove-beneficiario-container">
              <div>
                <span className="remove-info-title">
                  Qual o motivo da exclusão?
                </span>
              </div>
              <div className="remove-planos-options">
                {renderSelectMotivoExclusao()}
              </div>
            </div>
            <div className="remove-beneficiario-container">
              <div className="span-date-title">
                <span className="remove-info-title">
                  A partir de qual data se tornará inativo?
                </span>
              </div>
              <div className="datepicker-selector-container">
                <DatePicker
                  className="input-data"
                  selected={date}
                  value={date}
                  locale="ptBR"
                  onChange={(date) => setDate(date)}
                  placeholderText="..."
                  dateFormat="dd/MM/yyyy"
                />
                {renderLimparDataButton()}
              </div>
              {renderRemocaoPlano()}
            </div>
          </>
        ) : (
          ''
        )}
      </div>
    );
  };

  const renderRemocaoPlano = () => {
    return (
      <div
        className={
          selectedBeneficiario
            ? 'remove-beneficiario-button-container-open'
            : 'remove-beneficiario-button-container'
        }
      >
        <Button
          className={
            isAnyContratoSelected()
              ? 'send-btn-remocao'
              : 'send-btn-remocao-disabled'
          }
          variant="contained"
          onClick={exclusaoContratoHandler}
          disabled={!isAnyContratoSelected()}
        >
          Remover
        </Button>
      </div>
    );
  };

  const showDependentesTitularesClickHandler = () => {
    setShowDependentesTitular((prevState) => !prevState);
  };

  const renderTitularDependentes = () => {
    let nomesDependentesFormatados = [];

    if (selectedBeneficiario && selectedBeneficiario.dependentes) {
      nomesDependentesFormatados = formatNomesTitulares(
        selectedBeneficiario.dependentes
      );
    }

    return (
      <div
        className={
          showDependentesTitular
            ? 'dependente-beneficiario-container-open'
            : 'dependente-beneficiario-container'
        }
        style={{
          height: `${
            showDependentesTitular
              ? selectedBeneficiario &&
                selectedBeneficiario.dependentes &&
                selectedBeneficiario.dependentes.length
                ? selectedBeneficiario.dependentes.length * 30 + 48
                : 48
              : selectedBeneficiario &&
                selectedBeneficiario.dependentes &&
                selectedBeneficiario.dependentes.length
              ? 38
              : 0
          }px`
        }}
      >
        {selectedBeneficiario && selectedBeneficiario.dependentes ? (
          <div className="first-row-container">
            <div className="dependentes-titular-first-row">
              <span>Dependentes: </span>
              <span className="dependentes-quantidade-info">
                {selectedBeneficiario.dependentes.length}
              </span>
              <div className="arrow-container">
                <AiFillCaretLeft
                  className={
                    showDependentesTitular
                      ? 'arrow-close-icon'
                      : 'arrow-open-icon'
                  }
                  size={20}
                  onClick={showDependentesTitularesClickHandler}
                />
              </div>
            </div>
            <div className="dependentes-titular-second-row">
              <div className="dependentes-titular-lista">
                {nomesDependentesFormatados.map((dependente) => (
                  <div>
                    <span className="titular-nome">{dependente.nome}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  };

  const renderBeneficiarioRemocao = () => {
    return (
      <div className="">
        {renderTitularDependentes()}
        {renderBeneficiarioPlanoInfo()}
      </div>
    );
  };

  return (
    <Div>
      <div className="form-remocao-beneficiario-container">
        <form className="form-remocao-beneficiario">
          <div className="selecao-beneficiario">
            <div
              className="selecao-beneficiario-hero-title"
              onClick={() => {
                setBeneficiarioSelectionOpen((prevState) => !prevState);
              }}
            >
              {selectedBeneficiario ? (
                <span className="span-hero-title">
                  {selectedBeneficiario.nome}
                </span>
              ) : (
                <span className="span-placeholder">
                  Selecione o Beneficiário a ser removido
                </span>
              )}

              {beneficiarioSelectionOpen ? (
                <RiArrowUpSLine className="arrow-icon" size={17} />
              ) : (
                <RiArrowDownSLine className="arrow-icon" size={17} />
              )}
            </div>

            <div
              className={
                beneficiarioSelectionOpen
                  ? 'tabela-beneficiarios-container-open'
                  : 'tabela-beneficiarios-container'
              }
            >
              <ClickAwayListener onClickAway={handleCloseBeneficiarioTable}>
                <div className="beneficiario-tabela-row">
                  <table className="beneficiario-tabela">
                    <thead>
                      <tr className="table-header-first-row">
                        <input
                          className="table-name-input"
                          placeholder="Filtrar por Nome"
                          value={nomeBeneficiarioFiltro}
                          onChange={(e) =>
                            setNomeBeneficiarioFiltro(e.target.value)
                          }
                        />
                        <div className="radio-input-group-container">
                          <div
                            className="radio-input-container"
                            onClick={showTitularesClickHandler}
                          >
                            <Radio
                              size="small"
                              className="radio-input"
                              checked={showTitulares}
                            />
                            <span>Titular</span>
                          </div>
                          <div
                            className="radio-input-container"
                            onClick={showDependentesClickHandler}
                          >
                            <Radio
                              size="small"
                              className="radio-input"
                              checked={showDependentes}
                            />
                            <span>Dependente</span>
                          </div>
                        </div>
                      </tr>
                      <tr className="table-header-row">
                        <th>Nome</th>
                        <th>Tipo</th>
                      </tr>
                    </thead>
                    <tbody className="beneficiario-table-body">
                      {renderBeneficiariosFiltrados()?.map((beneficiario) => (
                        <tr className="table-beneficiarios-data-row">
                          <td
                            onClick={() => {
                              setSelectedBeneficiario(beneficiario);
                              setBeneficiariosContratos(beneficiario.contratos);
                              setBeneficiarioSelectionOpen(false);
                            }}
                          >
                            {beneficiario.nome}
                          </td>
                          <td>{beneficiario.tipo}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </ClickAwayListener>
            </div>
          </div>
        </form>
      </div>
      {renderBeneficiarioRemocao()}
      <div className="aba-remocao-margin-bottom"></div>
    </Div>
  );
};

export default MovimentExclusion;
