import styled from 'styled-components';

export const Header = styled.header`
    background: #fff;
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;


    .logo {
    background: none;
    padding: 5px 20px;
    max-width: 150px;
    max-height: 80px;

   img{
       width:100%;
       max-height: 80px;
    }
}

`