import ReactInputMask from 'react-input-mask';
import colors from 'apps/broker/styles/colors';
import ReactDatePicker from 'react-datepicker';
import relatives from 'utils/arrays/relatives';
import { Div } from './styles';

const RelativeForm = ({
  beneficiarioNome,
  setBeneficiarioNome,
  cpf,
  setCpf,
  beneficiaryBirthDate,
  setBeneficiaryBirthDate,
  grauParentesco,
  setGrauParentesco,
  dataVinculo,
  setDataVinculo,
  dataInicio,
  setDataInicio,
  numeroDeCarteirinha,
  setNumeroDeCarteirinha
}) => {
  return (
    <Div>
      <div className="form-line-row">
        <input
          className="input"
          value={beneficiarioNome}
          onChange={(e) => setBeneficiarioNome(e.target.value)}
          placeholder="* Nome completo do Dependente"
        />
      </div>

      <div className="form-line-row">
        <ReactInputMask
          className="input"
          mask="999.999.999-99"
          placeholder="* CPF do Dependente"
          value={cpf}
          onChange={(e) => setCpf(e.target.value)}
        />
      </div>

      <div className="datepicker-row">
        <div>
          <ReactDatePicker
            className="input-data"
            selected={beneficiaryBirthDate}
            locale="ptBR"
            onChange={(date) => setBeneficiaryBirthDate(date)}
            placeholderText="* Selecione a data de nascimento"
            dateFormat="dd/MM/yyyy"
          />
        </div>
        <div className="grau-parentesco-row">
          <select
            className="input"
            defaultValue=""
            style={{
              color: `${grauParentesco === '' ? '#b8b9bb' : colors.genoaGrey}`
            }}
            value={grauParentesco}
            onChange={(e) => setGrauParentesco(e.target.value)}
          >
            <option value="" disabled style={{ fontWeight: 'bold' }}>
              * Grau de parentesco
            </option>
            {relatives.map(({ option, value }) => (
              <option className="valid-options" value={value}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="datepicker-row">
        <div>
          <ReactDatePicker
            className="input-data"
            selected={dataVinculo}
            locale="ptBR"
            onChange={(date) => setDataVinculo(date)}
            placeholderText="* Selecione a data de vínculo"
            dateFormat="dd/MM/yyyy"
          />
        </div>
        <div className="datepicker-start-date-row">
          <ReactDatePicker
            className="input-data"
            selected={dataInicio}
            locale="ptBR"
            onChange={(date) => setDataInicio(date)}
            placeholderText="Selecione a data de início do plano"
            dateFormat="dd/MM/yyyy"
          />
        </div>
      </div>

      <div className="form-line-row">
        <input
          className="input"
          value={numeroDeCarteirinha}
          onChange={(e) => setNumeroDeCarteirinha(e.target.value)}
          placeholder="Número da carteirinha"
        />
      </div>
    </Div>
  );
};

export default RelativeForm;
