import React from "react";
import SelectStandard from "apps/broker/components/Select";
import { AbaEstipulanteWrapper } from "./styles-abaEstipulante";

const AbaEstipulante = (
  { values,
    setState,
    setInputValue,
    setInputFile,
    setSelectValue,
    setNameFilter,
    setIncluirSaude,
    setIncluirOdonto,
    camposInclusao,
    estipulanteAndSubs
  }) => {


  const optionsEstipulanteAndSubs = estipulanteAndSubs.map((item) => ({
    option: item?.razao_social, value: item.id
  }))

  return (
    <AbaEstipulanteWrapper>
      <div className="aba-container">
        <div className="aba-input-container">
          <div className="aba-title-container">
            <span>Seleção de Estipulante</span>
          </div>
        </div>
        <div className="aba-input-container">
          <div className="aba-input-row-select">
            <SelectStandard
              value={values['estipulante_id']}
              setValue={setSelectValue}
              label={"Estipulante a ser realizado a inclusão do beneficiário"}
              name="estipulante_id"
              options={optionsEstipulanteAndSubs}
              posthogSelectClickHandlerEvent={'Clicou em Select p/ mudar "Estipulante"'}
              posthogOptionClickHandlerEvent={'Clicou na opção de "Estipulante"'}
            />
          </div>
        </div>
      </div>
    </AbaEstipulanteWrapper>
  )
}

export default AbaEstipulante;
