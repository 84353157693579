import { useState, useEffect, useRef } from 'react';
import Popper from '@material-ui/core/Popper';
import Box from '@mui/material/Box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import { BsFilter } from 'react-icons/bs';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import DatePicker from 'react-datepicker';
import Button from '@mui/material/Button';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useFilters } from 'contexts/filters';
import services from 'apps/broker/services';

const TicketsByCompanies = () => {
  const [anchorElFilter, setanchorElFilterPopover] = useState(null);
  const [ticketStatusFilter, setTicketStatusFilter] = useState('criados');
  const [estipulantesTickets, setEstipulantesTickets] = useState([]);
  const [dateFilterValue, setDateFilterValue] = useState('desde inicio');
  const [filterOrder, setFilterOrder] = useState('DESC');
  const [filterCountBy, setFilterCountBy] = useState('total');
  const stateRef = useRef();

  const filters = useFilters();
  const history = useHistory();

  const {
    control,
    getValues,
    formState: { errors }
  } = useForm({
    defaultValues: {
      initial_date: '',
      final_date: ''
    }
  });

  stateRef.current = filterOrder;
  stateRef.current = filterCountBy;

  const clickChangeFilterHandler = ({ target }) => {
    setanchorElFilterPopover((prevState) => (prevState ? null : target));
  };

  const dateFilterChangeHandler = ({ target }) => {
    setDateFilterValue(target.value);
  };

  const ticketStatusChangeHandler = ({ target }) => {
    setTicketStatusFilter(target.value);
  };

  const clickFilterHandler = () => {
    getTicketsByEstipulante();
  };

  const orderCountDecrescently = (ticketsCount) => {
    switch (filterCountBy) {
      case 'outras demandas':
        return ticketsCount.sort(
          (a, b) => b.outrasDemandasCount - a.outrasDemandasCount
        );

      case 'movimentações':
        return ticketsCount.sort(
          (a, b) => b.movimentacaoCount - a.movimentacaoCount
        );

      default:
        return ticketsCount.sort((a, b) => b.total - a.total);
    }
  };

  const orderCountAscendently = (ticketsCount) => {
    switch (filterCountBy) {
      case 'outras demandas':
        return ticketsCount.sort(
          (a, b) => a.outrasDemandasCount - b.outrasDemandasCount
        );

      case 'movimentações':
        return ticketsCount.sort(
          (a, b) => a.movimentacaoCount - b.movimentacaoCount
        );

      default:
        return ticketsCount.sort((a, b) => a.total - b.total);
    }
  };

  const returnValueByType = (total, movimentacaoCount, outrasDemandasCount) => {
    switch (filterCountBy) {
      case 'outras demandas':
        return outrasDemandasCount;

      case 'movimentações':
        return movimentacaoCount;

      default:
        return total;
    }
  };

  const redirectToTicketsManagement = (estipulante) => {
    const filtersToDispatch = {
      redirected: true,
      custom_dates: {
        initial_date: getValues('initial_date'),
        final_date: getValues('final_date')
      },
      date_filter: dateFilterValue,
      ticket_status: ticketStatusFilter,
      estipulante,
      ticket_type: filterCountBy,
      corretor: 'all'
    };

    filters.updateHomeFilters(filtersToDispatch);

    history.push('/corretor/gestao-tickets');
  };

  const renderEstipulantesTickets = () => {
    const newOrder =
      filterOrder === 'DESC'
        ? orderCountDecrescently(estipulantesTickets)
        : orderCountAscendently(estipulantesTickets);

    return newOrder?.map(
      (
        { razao_social, total, movimentacaoCount, outrasDemandasCount },
        index
      ) =>
        razao_social && (
          <tr key={index}>
            <td
              className="estipulante-redirect"
              onClick={() => redirectToTicketsManagement(razao_social)}
            >
              {razao_social}
            </td>
            <td
              className="estipulante-redirect"
              onClick={() => redirectToTicketsManagement(razao_social)}
            >
              {returnValueByType(total, movimentacaoCount, outrasDemandasCount)}
            </td>
          </tr>
        )
    );
  };

  const handleClose = () => {
    setanchorElFilterPopover(null);
  };

  const getTicketsByEstipulante = async () => {
    const ticketStatus = JSON.stringify(ticketStatusFilter);
    const customDates = JSON.stringify(getValues());

    const response = await services.tickets.getAllByCompanies(
      dateFilterValue,
      ticketStatus,
      customDates
    );

    if (response.status === 200) {
      setEstipulantesTickets(response.data.ticketsCount);
    }
  };

  useEffect(() => {
    getTicketsByEstipulante();
  }, []);

  return (
    <div className="main-content-column-three">
      <div>
        <div className="title">
          <span>Tickets por empresa</span>
        </div>
        <section className="vidas-estipulantes">
          <div className="vidas-boxes-resp-estipulantes">
            <table className="table-resp-estipulantes">
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th>
                    <BsFilter
                      className="filter-icon"
                      onClick={clickChangeFilterHandler}
                    />
                  </th>
                  <Popper
                    id={'id'}
                    open={Boolean(anchorElFilter)}
                    anchorEl={anchorElFilter}
                    className="popper"
                    placement="right"
                    transition
                    disablePortal
                  >
                    <Box>
                      <ClickAwayListener onClickAway={handleClose}>
                        <Paper
                          elevation={3}
                          className="paper-popup-filter-change"
                        >
                          <h4>Filtrar por:</h4>
                          <div className="select-ticket-tipo-container">
                            <select
                              onChange={(e) => {
                                setFilterCountBy(e.target.value);
                              }}
                              value={filterCountBy}
                            >
                              <option value="total">Total</option>
                              <option value="outras demandas">
                                Outras Demandas
                              </option>
                              <option value="movimentações">
                                Movimentações
                              </option>
                            </select>
                          </div>
                          <div className="order-checkbox-container">
                            <FormControl>
                              <RadioGroup
                                className="checkbox-group-container"
                                onChange={(e) => {
                                  setFilterOrder(e.target.value);
                                }}
                                value={filterOrder}
                              >
                                <FormControlLabel
                                  value="DESC"
                                  control={<Radio className="radio-checkbox" />}
                                  label="DESC"
                                />
                                <FormControlLabel
                                  value="ASC"
                                  control={<Radio className="radio-checkbox" />}
                                  label="ASC"
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                          <div>
                            <hr className="divider" />
                          </div>
                          <div className="data-checkbox-container">
                            <FormControl>
                              <RadioGroup
                                className="checkbox-data-group-container"
                                onChange={ticketStatusChangeHandler}
                                value={ticketStatusFilter}
                              >
                                <FormControlLabel
                                  value="criados"
                                  control={<Radio className="radio-checkbox" />}
                                  label="Criados"
                                />
                                <FormControlLabel
                                  value="resolvidos"
                                  control={<Radio className="radio-checkbox" />}
                                  label="Resolvidos"
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                          <div>
                            <h4 className="data-header">Data:</h4>
                          </div>
                          <div className="data-checkbox-container">
                            <FormControl>
                              <RadioGroup
                                className="checkbox-data-group-container"
                                onChange={dateFilterChangeHandler}
                                value={dateFilterValue}
                              >
                                <FormControlLabel
                                  value="desde inicio"
                                  control={<Radio className="radio-checkbox" />}
                                  label="Desde o Início"
                                />
                                <FormControlLabel
                                  value="ultima semana"
                                  control={<Radio className="radio-checkbox" />}
                                  label="Última Semana"
                                />
                                <FormControlLabel
                                  value="ultimo mes"
                                  control={<Radio className="radio-checkbox" />}
                                  label="Último Mês"
                                />
                                <FormControlLabel
                                  value="data personalizada"
                                  control={<Radio className="radio-checkbox" />}
                                  label="Data Personalizada"
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                          <div className="datepicker-box-space">
                            <div
                              className={
                                dateFilterValue === 'data personalizada'
                                  ? 'datepicker-group-container'
                                  : 'datepicker-group-container-hidden'
                              }
                            >
                              <div className="datepicker-selector-container">
                                <div className="span-date-container">
                                  <span>Data Inicial:</span>
                                </div>
                                {errors.initial_date && (
                                  <span className="errorsMsg">
                                    Campo obrigatório
                                  </span>
                                )}
                                <Controller
                                  name="initial_date"
                                  control={control}
                                  // defaultValue=""
                                  rules={{ required: true }}
                                  render={({ field: { onChange, value } }) => (
                                    <DatePicker
                                      className="input-data"
                                      selected={value}
                                      locale="ptBR"
                                      onChange={onChange}
                                      placeholderText="..."
                                      maxDate={new Date()}
                                      dateFormat="dd/MM/yyyy"
                                    />
                                  )}
                                />
                              </div>
                              <div className="datepicker-selector-container">
                                <div className="span-date-container">
                                  <span>Data Final:</span>
                                </div>
                                {errors.final_date && (
                                  <span className="errorsMsg">
                                    Campo obrigatório
                                  </span>
                                )}
                                <Controller
                                  name="final_date"
                                  control={control}
                                  // defaultValue=""
                                  rules={{ required: true }}
                                  render={({ field: { onChange, value } }) => (
                                    <DatePicker
                                      className="input-data"
                                      selected={value}
                                      locale="ptBR"
                                      onChange={onChange}
                                      placeholderText="..."
                                      maxDate={new Date()}
                                      dateFormat="dd/MM/yyyy"
                                    />
                                  )}
                                />
                              </div>
                            </div>
                          </div>

                          <div>
                            <Button
                              variant="contained"
                              className="date-filter-send-btn"
                              onClick={clickFilterHandler}
                            >
                              Filtrar
                            </Button>
                          </div>
                        </Paper>
                      </ClickAwayListener>
                    </Box>
                  </Popper>
                </tr>
              </thead>
              <tbody>{renderEstipulantesTickets()}</tbody>
            </table>
          </div>
        </section>
      </div>
    </div>
  );
};

export default TicketsByCompanies;
