import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import parse from 'html-react-parser';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { CircularProgress } from '@material-ui/core';
import TextEditor from 'components/TextEditor';
import { Wrapper } from './stylesDrawer';
import { dataDiff } from 'utils/calculateFunctions';
import { FaAngleDoubleRight, FaPen } from 'react-icons/fa';
import { BiFileBlank } from 'react-icons/bi';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import api from 'services/api';
import services from 'apps/hr/services';
import { useUser } from 'contexts/user';

const DrawerTicketMovimentacao = ({
  data,
  getDataAll,
  onClose,
  closeDrawer
}) => {
  const { user } = useUser();

  const [ticket, setTicket] = useState({});
  const [details, setDetails] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  // const [status, setStatus] = useState();
  const [openEditor, setOpenEditor] = useState();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const headers = {
    id: data.id_ticket,
    authorization: `Bearer ${user.token}`
  };

  function getData() {
    setLoading(true);
    api
      .get('/rh/ticket', {
        headers: headers
      })
      .then((resp) => {
        setTicket(resp.data.ticket);
        setDetails(resp.data.beneficiarios);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const beneficiarioType = {
    Titular: (titular) => formatTitular(titular),
    Dependente: (dependente) => formatDependentes(dependente)
  };

  function formatTitular(titular) {
    // console.log(titular);
    return (
      <div className="details titular">
        <h3>Titular</h3>
        <p>Nome : {titular.nome}</p>
        <p>
          Data de contratação :{' '}
          {moment(titular.dados.data_contratacao).utc().format('DD/MM/YYYY')}
        </p>
        <p>
          Endereço: {titular.dados.logradouro},{' '}
          {titular.dados.numero ? `nº ${titular.dados.numero} - ` : null}
          {titular.dados.cep && titular.dados.complemento
            ? `${titular.dados.complemento} - `
            : titular.dados.complemento}
          {titular.dados.cep}
        </p>
        <p>
          Data de Nascimento:{' '}
          {moment(titular.dados.data_nascimento).utc().format('DD/MM/YYYY')}
        </p>
        <p>CPF: {titular.dados.cpf}</p>
        <p>
          RG: {titular.dados.rg} {titular.dados.orgao_emissor}{' '}
          {moment(titular.dados.data_emissao).utc().format('DD/MM/YYYY')}
        </p>
        <p>Telefone: {titular.dados.telefone}</p>
        <p>Sexo: {titular.dados.sexo}</p>
        <p>Estado Civil: {titular.dados.estado_civil}</p>
        <p>PIS/PASEP: {titular.dados.pis_pasep}</p>
        <p>Nome da mãe: {titular.dados.nome_mae}</p>
        <p>
          Dados bancários: {titular.dados.banco} {titular.dados.agencia}{' '}
          {titular.dados.conta}
        </p>
        {titular.documentos.map((documento, index) => (
          <p key={index} className="docs">
            <a href={documento.file_link}>
              <BiFileBlank />
              {documento.file_type}
            </a>
          </p>
        ))}
        {titular.contratos.map((contratos, index) => (
          <p key={index}>
            {contratos.nome_produto}{' '}
            {contratos.tipo_plano ? `- ${contratos.tipo_plano}` : null}
          </p>
        ))}
      </div>
    );
  }

  function formatDependentes(dependentes) {
    return (
      <div className="details dependentes">
        <h3>Dependente</h3>
        <p>Nome : {dependentes.nome}</p>
        <p>
          Data de nascimento:{' '}
          {moment(dependentes.dados.data_nascimento).utc().format('DD/MM/YYYY')}
        </p>
        <p>CPF: {dependentes.dados.cpf}</p>
        <p>
          RG: {dependentes.dados.rg} {dependentes.dados.orgao_emissor}{' '}
          {moment(dependentes.dados.data_emissao).utc().format('DD/MM/YYYY')}
        </p>
        {/* {dependentes.documentos.map((documento, index) => {
          return (
            <p className="docs">
              <a href={documento.file_link}>
                <BiFileBlank />
                {documento.file_type}
              </a>
            </p>
          );
        })} */}
        <p className="docs">
          <a href={dependentes.dados.file_dependente}>
            <BiFileBlank />
            Documento
          </a>
        </p>
        {dependentes.contratos.map((contratos, index) => {
          return (
            <p>
              {contratos.nome_produto} - {contratos.tipo_plano}
            </p>
          );
        })}
      </div>
    );
  }

  const handleEdit = (e) => {
    e.preventDefault();
    setOpenEdit(!openEdit);
  };

  const aprovar = () => {
    const headers = {
      ticket: data.id_ticket,
      estipulante: user.estipulante,
      nome: `${user.nome} ${user.sobrenome}`,
      authorization: `Bearer ${user.token}`
    };

    const body = {
      resposta: 'Aceitar',
      beneficiarios: []
    };

    details.map((beneficiario, index) => {
      return body.beneficiarios.push(beneficiario.uuid);
    });

    api
      .post('/rh/aceitar-movimentacao', body, { headers: headers })
      .then((resp) => {
        getData();
        getDataAll();
      })
      .catch((err) => console.log(err));
  };

  const recusar = () => {
    const headers = {
      ticket: data.id_ticket,
      estipulante: user.estipulante,
      authorization: `Bearer ${user.token}`
    };
    const body = {
      resposta: 'Recusar',
      beneficiarios: []
    };
    details.map((beneficiario, index) => {
      return body.beneficiarios.push(beneficiario.uuid);
    });
    api
      .post('/rh/aceitar-movimentacao', body, { headers: headers })
      .then((resp) => {
        getData();
        getDataAll();
      })
      .catch((err) => console.log(err));
  };

  // const editStatus = (e) => {
  //   e.preventDefault();
  //   const body = {
  //     ticket: data.id_ticket,
  //     status: status,
  //   };
  //   const headers = {
  //     id: user.corretora,
  //     authorization: `Bearer ${user.token}`,
  //   };
  //   api
  //     .put("/corretor/ticket/status", body, { headers: headers })
  //     .then((resp) => {
  //       getData();
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const sendMessageRh = async () => {
    const htmlMessage = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );

    const formData = new FormData();
    formData.append('mensagem', htmlMessage);
    files.forEach((file, index) => {
      formData.append(index, file);
    });

    const response = await services.tickets.createMessageByHr(
      formData,
      data,
      user,
      'RespRH',
      'normal'
    );

    if (response.status === 200) {
      setFiles([]);
      setEditorState(!editorState);
      getData();
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Wrapper>
      {!loading ? (
        <>
          <header>
            <div className="back">
              <span onClick={() => closeDrawer(false)}>
                <ArrowBackIcon color="inherit" fontSize="inherit" />
              </span>
            </div>

            <div className="title">
              <h2>Detalhes do Ticket</h2>
              <span>
                Criado em{' '}
                {moment(ticket?.created_at).utc().format('DD/MM/YYYY')} (
                {dataDiff(ticket.created_at)} dias)
              </span>
            </div>
          </header>

          <section className="infos">
            <div className="status">
              <div className="text-icon">
                <FaAngleDoubleRight /> <p> Status: {ticket.descricao} </p>
                {ticket.descricao ===
                  'Retorno de pendências - Aguardando Corretora' ||
                ticket.descricao === 'Retorno de pendências - Aguardando RH' ? (
                  <span className="btn-edit">
                    <FaPen onClick={handleEdit} />
                  </span>
                ) : null}
              </div>
              {/* <div className="text-icon"> <MdTraffic />Prioridade: <span className="red">{ticket.prioridade}</span></div> */}
            </div>
          </section>

          <section className="bodyDetails">
            <div className="titleTicket">
              {ticket.assunto} - #{ticket.id_ticket}
            </div>

            {details.map((beneficiario, index) => (
              <div className="type-beneficiary" key={index}>
                {beneficiarioType[beneficiario.tipo](beneficiario)}
              </div>
            ))}

            <div className="messages">
              {ticket.messages?.map((message, index) => (
                <div className="single-message" key={index}>
                  <div className="titleTicket">
                    {message.corretora_id
                      ? message.nome
                      : message.nome + ' ' + message.sobrenome}{' '}
                    em {moment(message.created_at).utc().format('DD/MM/YYYY')}
                  </div>
                  {message.corretora_id ? (
                    <div className="messageText  resp-msg">
                      <div>
                        {message.files?.map((file, index) => (
                          <div className="messageFile" key={index}>
                            <a
                              href={file.attachment_url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {file.name}
                            </a>
                          </div>
                        ))}
                      </div>
                      {parse(message.mensagem)}
                    </div>
                  ) : (
                    <div className="messageText  open-msg">
                      <div>
                        {message.files?.map((file, index) => {
                          return (
                            <div className="messageFile" key={index}>
                              <a
                                href={file.attachment_url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {file.name}
                              </a>
                            </div>
                          );
                        })}
                      </div>
                      {parse(message.mensagem)}
                    </div>
                  )}
                </div>
              ))}
            </div>

            {ticket.status_id === 4 && (
              <div className="btn">
                <button className="btn btn-accept" onClick={aprovar}>
                  Aprovar
                </button>
                <button className="btn btn-refuse" onClick={recusar}>
                  Recusar
                </button>
              </div>
            )}

            <div className="actions">
              {details.status_id !== 3 && (
                <div className="buttons">
                  <button
                    className="newMsg"
                    onClick={() => setOpenEditor(!openEditor)}
                  >
                    + Nova mensagem
                  </button>
                </div>
              )}
            </div>

            {openEditor && (
              <TextEditor
                handleSendMessage={sendMessageRh}
                editorState={editorState}
                setEditorState={setEditorState}
                files={files}
                setFiles={setFiles}
              />
            )}
          </section>
        </>
      ) : (
        <div className="loading">
          <CircularProgress /> <p>Carregando...</p>
        </div>
      )}
    </Wrapper>
  );
};

export default DrawerTicketMovimentacao;
