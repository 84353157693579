import styled from 'styled-components';
import colors from 'apps/broker/styles/colors';

export const Div = styled.div`
  .form-beneficiario-container {
    color: ${colors.genoaGrey};
    padding-top: 40px;
    display: flex;
    justify-content: center;
    font-size: 1.1rem;

    .beneficiario-option-container {
      width: 250px;
      display: flex;
      justify-content: space-between;
    }

    .checkbox-label {
      display: flex;
      align-items: center;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      span {
        margin-left: 5px;
      }
      :hover {
        cursor: pointer;
      }
    }

    .checkbox-label-selected {
      display: flex;
      align-items: center;
      color: ${colors.genoaBlue};
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      span {
        margin-left: 5px;
      }
      :hover {
        cursor: pointer;
      }
    }
  }

  .form-beneficiario {
    width: 600px;

    .form-first-line {
      display: grid;
      grid-template-columns: 2fr 1fr;
      column-gap: 20px;
    }

    .titular-dependente-form-container {
      height: 0px;
      transition: height 0.5s ease-in-out;
      overflow: hidden;
    }

    .send-btn-container {
      display: flex;
      justify-content: center;
      padding-top: 15px;
    }

    .send-btn {
      color: white;
      margin-top: 5px;
      margin-bottom: 30px;
      padding: 4px 10px;
      background-color: ${colors.genoaBlue};
      :hover {
        filter: contrast(120%);
      }
    }

    .confirmation-message {
      min-height: 40px;
      margin-bottom: 200px;
      color: ${colors.genoaBlue};
      font-family: 'Avenir Next GEO W05 Demi';
      display: flex;
      font-size: 1.2rem;
      justify-content: center;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;

      .error-message {
        font-size: 1rem;
        color: red;
      }
    }

    .react-datepicker__day--selected:hover {
      color: white;
      background-color: #1c76d1;
      font-weight: bold;
    }

    .react-datepicker__day--selected {
      color: white;
      background-color: #1e88f2;
      font-weight: bold;
    }

    .react-datepicker__day--keyboard-selected:hover {
      color: #1e88f2;
      background-color: #f0f0f0;
    }

    .react-datepicker__day--keyboard-selected {
      color: #1e88f2;
      background-color: white;
      font-weight: bold;
    }

    .error-message-container {
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      font-size: 0.9rem;
      color: ${colors.genoaBlue};
      padding-top: 15px;
      height: 27px;  
      display: flex;
      align-items: center;
      justify-content: end;
      font-family: 'Avenir Next GEO W05 Demi';

      div {
        height: 21.2px;
        span {
          margin-left: 4px;
          :hover {
            cursor: pointer;
            border-bottom: 1px solid;
          }
        }
      } 
    }
  }

`;

export const ContainerOptions = styled.div`
  height: 0;
  overflow: hidden;
  transition: height 0.8s ease-in-out;

  .planos-options-container {
    height: 26px;
    padding-top: 20px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    width: max-content;
    color: ${colors.genoaGrey};
    display: grid;
    grid-template-columns: 25px 400px 80px 90px 100px;
    align-items: center;
    font-size: 18px;
    margin-left: 15px;
    svg {
      fill: currentColor;
      width: 20px;
    }

    :hover {
      cursor: pointer;
    }

    .plano-option-icon {
      margin-right: 5px;
    }

    .plano-option-select {
      width: 0px;
      overflow: hidden;
      transition: width 0.4s ease-out;
      select {
        border: 0.5px solid rgb(69, 106, 237);
        border-radius: 5px;
        font-size: 0.9rem;
        width: 240px;
        margin-left: 60px;
        height: 24.8px;
        padding: 0px;
      }
    }
  }

  .planos-options-container-selected {
    height: 26px;
    padding-top: 20px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    width: max-content;
    color: ${colors.genoaBlue};
    display: grid;
    grid-template-columns: 25px 400px 80px 90px 100px;
    align-items: center;
    font-size: 18px;
    margin-left: 15px;
    svg {
      fill: currentColor;
      width: 20px;
    }

    :hover {
      cursor: pointer;
    }

    .plano-option-icon {
      margin-right: 5px;
    }

    .plano-option-select {
      width: 300px;
      overflow: hidden;
      transition: width 0.4s ease-in;
      select {
        border: 0.5px solid rgb(69, 106, 237);
        border-radius: 5px;
        font-size: 0.9rem;
        width: 240px;
        margin-left: 60px;
        height: 24.8px;
        padding: 0px;
      }
    }
  }
`;
