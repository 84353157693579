import styled from 'styled-components';
import colors from 'styles/colors';

export const Wrapper = styled.div`
  max-width: 700px;
  width: 700px;
  box-sizing: content-box;
  min-height: 100.1vh;

  header {
    background-color: ${colors.genoaBlue};
    padding: 15px;
    padding-left: 60px;
    height: 110px;

    .headerUser {
      margin-top: 10px;
    }

    .nameUser {
      display: flex;
      flex-direction: column;
      color: #fff;
      margin-top: 45px;

      svg {
        font-size: 2.5rem;
        margin-left: -5px;
      }
      .user-company {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        span {
          color: #fff;
          font-size: 18px;
          font-family: 'Avenir Next GEO W05 Bold';
        }

        .header-title-second-row {
          line-height: 12px;
        }

        .header-title-name {
          font-family: 'Avenir Next GEO W05 Heavy';
          font-size: 20px;
        }

        .header-title-estipulante {
          font-size: 12px;
          font-family: 'Avenir Next GEO W05 Regular';
        }

        .header-title-hifen-space {
          margin-right: 9px;
          margin-left: 9px;
        }
      }
    }

    h2 {
      font-family: 'Avenir Next GEO W05 Bold';
      color: #fff;
      font-size: 35px;
      margin: 0px 0 2px 0;
    }
    span.tipo {
      color: #fff;
    }
  }
  section {
    padding: 20px;
  }
  section.infos {
    padding: 20px;
    padding-left: 60px;

    h3 {
      font-family: 'Avenir Next GEO W05 bold';
      font-size: 20px;
      color: #3b3838;
    }

    .info-section {
      margin-top: 25px;
      margin-bottom: 45px;

      .title-info {
        font-size: 15px;
        margin-bottom: 10px;
      }
    }

    .data-info {
      margin-bottom: 10px;
      font-size: 15px;
      color: ${colors.genoaDarkBlue};

      .info-plano {
        margin-left: 10px;
      }

      > div {
        display: flex;
        align-items: center;

        span {
          color: ${colors.genoaDarkBlue};
        }

        svg {
          margin-right: 5px;
          font-size: 1.1rem;
          color: ${colors.genoaDarkBlue};
        }
      }
    }

    .list-infos {
      margin-top: 15px;
      list-style: none;
      li {
        margin-bottom: 8px;
        color: ${colors.genoaGrey};
      }

      .list-info-title {
        font-family: 'Avenir Next GEO W05 Bold';
        font-size: 15px;
      }

      .list-info-value {
        margin-left: 10px;
        font-family: 'Avenir Next GEO W05 Regular';
        font-size: 15px;
      }
    }

    .data-bank {
      span {
        margin-right: 25px;
      }
    }
    .input-docs {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      .file {
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        transition: all 0.4s;

        a {
          text-decoration: none;
          color: ${colors.genoaBlue};
          transition: all 0.4s;
        }

        svg {
          color: ${colors.genoaBlue};
        }

        &:hover {
          filter: brightness(0.8);
          a {
            text-decoration: underline;
          }
        }
      }
    }

    .div-input-file {
      margin-top: 30px;
      form {
        display: flex;
        justify-content: space-between;
      }

      .label-file {
        font-size: 16px;
        background-color: #f3ecff;
        border-radius: 5px;
        color: #fff;
        cursor: pointer;

        padding: 5px 5px;
        border: 1px ${colors.genoaBlue} solid;

        input {
          display: none;
        }
        span {
          display: flex;
          align-items: center;
          color: ${colors.genoaBlue};

          svg {
            font-size: 1.3rem;
          }
        }
      }
      span.errorsMsg {
        font-size: 12px;
        color: #f72757;
      }

      button.btn {
        font-family: 'Avenir Next GEO W05 bold';
        padding: 7px 15px;
        background: #f3ecff;
        border: 1px ${colors.genoaBlue} solid;
        border-radius: 5px;
        color: ${colors.genoaBlue};
        cursor: pointer;

        :hover {
          background: #e3d2ff;
        }
        :disabled {
          opacity: 0.5;
        }
      }
    }

    .infos-section-title-container {
      margin-top: 10px;
    }

    .infos-section-title {
      color: ${colors.genoaBlue};
      font-size: 15px;
      font-family: 'Avenir Next GEO W05 Bold';
    }
  }
`;
