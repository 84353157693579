import styled from 'styled-components';
import colors from 'styles/colors';

export const DarkBackground = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;

  -webkit-transition: background-color 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -ms-transition: background-color 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  animation: fade-in 0.225s forwards;

  ::after {
    animation: fade-out 0.3s forwards;
  }

  @keyframes fade-in {
    from {
      background-color: ${colors.transparent};
    }
    to {
      background-color: rgba(0, 0, 0, 0.7);
    }
  }

  @keyframes fade-out {
    from {
      background-color: rgba(0, 0, 0, 0.7);
    }
    to {
      background-color: ${colors.transparent};
    }
  }

  .leave {
    animation: down-up 0.2s forwards;
  }

  @keyframes down-up {
    from {
      transform: calc(50% - 200px);
    }
    to {
      transform: translateY(-100%);
    }
  }
`;
