import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div`
  @media (max-height: 580px) {
    display: flex;
    justify-content: space-between;
    padding: 16.75px;
    padding-left: 30.15px;
    padding-right: 30.15px;
    background: ${colors.white};
    .search-input {
      width: 80%;
      max-width: 268px;
      display: flex;
      text-align: center;
      border: none;
      border: 2px solid #bfbfbf;
      border-radius: 20px;
    }
    /* .new-ticket {
            border: none;
            background: #62A0E2;
            max-width: 134px;
            width: 100%;
            font-size: 0.75375rem;
            color: #fff;
            border-radius: 20px;
            font-weight: 600;
        } */

    .menu-top {
      display: flex;
      align-items: center;
      margin-top: 6.7px;
      height: 33.23px;
    }

    .iniciais-empresa {
      /* background: #62A0E2;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            box-sizing: border-box;
            width: 29.48px;
            height: 29.48px; */
      span {
        color: ${colors.genoaDarkBlue};
        font-family: 'Avenir Next GEO W05 Demi';
        font-size: 0.67em;
      }
    }

    .logo {
      max-width: 113.9px;
      max-height: 40px;
      img {
        max-width: 113.9px;
        max-height: 40px;
      }
    }

    .welcome-name-title-container {
      margin-top: 6.7px;
      display: flex;
      align-items: center;
    }

    .heavy-title {
      font-family: 'Avenir Next GEO W05 Heavy';
    }

    .vertical-points-button {
      margin-right: 10px;
      margin-left: 3.35px;
      height: 16px;
      width: 16px;
      padding: 3.35px;
      color: #a1a1a1;

      :hover {
        cursor: pointer;
        padding: 3.35px;
        background-color: ${colors.genoaLightBackgroundGrey};
        border-radius: 15.3px;
      }
    }

    .vertical-points-button-disabled {
      margin-right: 10px;
      margin-left: 3.35px;
      height: 16px;
      width: 16px;
      padding: 3.35px;
      color: #a1a1a1;
    }

    .welcome-name-title {
      font-size: 18.76px;
      font-family: 'Avenir Next GEO W05 Regular';
      color: ${colors.genoaDarkBlue};
    }
  }

  @media (min-height: 580px) and (max-height: 650px) {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    padding-left: 36px;
    padding-right: 36px;
    background: ${colors.white};
    .search-input {
      width: 80%;
      max-width: 320px;
      display: flex;
      text-align: center;
      border: none;
      border: 2.4px solid #bfbfbf;
      border-radius: 24px;
    }
    /* .new-ticket {
            border: none;
            background: #62A0E2;
            max-width: 160px;
            width: 100%;
            font-size: 0.9rem;
            color: #fff;
            border-radius: 24px;
            font-weight: 600;
        } */

    .menu-top {
      display: flex;
      align-items: center;
      margin-top: 8px;
      height: 39.7px;
    }

    .iniciais-empresa {
      /* background: #62A0E2;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            box-sizing: border-box;
            width: 35.2px;
            height: 35.2px; */
      span {
        color: ${colors.genoaDarkBlue};
        font-family: 'Avenir Next GEO W05 Demi';
        font-size: 0.8em;
      }
    }

    .logo {
      max-width: 136px;
      max-height: 48px;
      img {
        max-width: 136px;
        max-height: 48px;
      }
    }

    .welcome-name-title-container {
      margin-top: 8px;
      display: flex;
      align-items: center;
    }

    .heavy-title {
      font-family: 'Avenir Next GEO W05 Heavy';
    }

    .vertical-points-button {
      margin-right: 12px;
      margin-left: 4px;
      height: 19.2px;
      width: 19.2px;
      padding: 4px;
      color: #a1a1a1;

      :hover {
        cursor: pointer;
        padding: 4px;
        background-color: ${colors.genoaLightBackgroundGrey};
        border-radius: 15.3px;
      }
    }

    .vertical-points-button-disabled {
      margin-right: 12px;
      margin-left: 4px;
      height: 19.2px;
      width: 19.2px;
      padding: 4px;
      color: #a1a1a1;
    }

    .welcome-name-title {
      font-size: 22.4px;
      font-family: 'Avenir Next GEO W05 Regular';
      color: ${colors.genoaDarkBlue};
    }
  }

  @media (min-height: 650px) and (max-height: 720px) {
    display: flex;
    justify-content: space-between;
    padding: 22.5px;
    padding-left: 40px;
    padding-right: 40px;
    background: ${colors.white};
    .search-input {
      width: 80%;
      max-width: 360px;
      display: flex;
      text-align: center;
      border: none;
      border: 2.7px solid #bfbfbf;
      border-radius: 27px;
    }
    /* .new-ticket {
            border: none;
            background: #62A0E2;
            max-width: 180px;
            width: 100%;
            font-size: 1.0125rem;
            color: #fff;
            border-radius: 27px;
            font-weight: 600;
        } */

    .menu-top {
      display: flex;
      align-items: center;
      margin-top: 7px;
      height: 44.64px;
    }

    .iniciais-empresa {
      /* background: #62A0E2;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            box-sizing: border-box;
            width: 39.6px;
            height: 39.6Px; */
      span {
        color: ${colors.genoaDarkBlue};
        font-family: 'Avenir Next GEO W05 Demi';
        font-size: 0.91em;
      }
    }

    .logo {
      max-width: 153px;
      max-height: 54px;
      img {
        max-width: 153px;
        max-height: 54px;
      }
    }

    .welcome-name-title-container {
      margin-top: 9px;
      display: flex;
      align-items: center;
    }

    .heavy-title {
      font-family: 'Avenir Next GEO W05 Heavy';
    }

    .vertical-points-button {
      margin-right: 13.5px;
      margin-left: 4.5px;
      height: 21.6px;
      width: 21.6px;
      padding: 4.5px;
      color: #a1a1a1;

      :hover {
        cursor: pointer;
        padding: 4.5px;
        background-color: ${colors.genoaLightBackgroundGrey};
        border-radius: 15.3px;
      }
    }

    .vertical-points-button-disabled {
      margin-right: 13.5px;
      margin-left: 4.5px;
      height: 21.6px;
      width: 21.6px;
      padding: 4.5px;
      color: #a1a1a1;
    }

    .welcome-name-title {
      font-size: 25.2px;
      font-family: 'Avenir Next GEO W05 Regular';
      color: ${colors.genoaDarkBlue};
    }
  }

  @media (min-height: 720px) {
    display: flex;
    justify-content: space-between;
    padding: 25px;
    padding-left: 45px;
    padding-right: 45px;
    background: ${colors.white};
    .search-input {
      width: 80%;
      max-width: 400px;
      display: flex;
      text-align: center;
      border: none;
      border: 3px solid #bfbfbf;
      border-radius: 30px;
    }
    /* .new-ticket {
            border: none;
            background: #62A0E2;
            max-width: 200px;
            width: 100%;
            font-size: 1.125rem;
            color: #fff;
            border-radius: 30px;
            font-weight: 600;
        } */

    .menu-top {
      display: flex;
      align-items: center;
      margin-top: 10px;
      height: 49.6px;
    }

    .iniciais-empresa {
      /* background: #62A0E2;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            box-sizing: border-box;
            width: 44px;
            height: 44px; */
      span {
        color: ${colors.genoaDarkBlue};
        font-family: 'Avenir Next GEO W05 Demi';
        font-size: 1em;
      }
    }

    .logo {
      max-width: 170px;
      max-height: 60px;
      img {
        max-width: 170px;
        max-height: 60px;
      }
    }

    .welcome-name-title-container {
      margin-top: 10px;
      display: flex;
      align-items: center;
    }

    .heavy-title {
      font-family: 'Avenir Next GEO W05 Heavy';
    }

    .vertical-points-button {
      margin-right: 15px;
      margin-left: 5px;
      height: 24px;
      width: 24px;
      padding: 5px;
      color: #a1a1a1;

      :hover {
        cursor: pointer;
        padding: 5px;
        background-color: ${colors.genoaLightBackgroundGrey};
        border-radius: 17px;
      }
    }

    .vertical-points-button-disabled {
      margin-right: 15px;
      margin-left: 5px;
      height: 24px;
      width: 24px;
      padding: 5px;
      color: #a1a1a1;
    }

    .welcome-name-title {
      font-size: 28px;
      font-family: 'Avenir Next GEO W05 Regular';
      color: ${colors.genoaDarkBlue};
    }
  }
`;
