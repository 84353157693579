import { useState, useEffect } from 'react';

import { BrowserRouter, Redirect } from 'react-router-dom';
import Routes from '../routes';
import '../index.css';

const Main = (props) => {
  const [redirect, setRedirect] = useState(false);

  const now = Math.floor(Date.now() / 1000);

  const json = localStorage.getItem('jwt_token_user');
  const userData = JSON.parse(json);

  const home = userData?.corretora ? '/corretor/home' : '/rh/home';

  const validateToken = async () => {
	if (!userData) {
        return;
      }
      if (userData.exp !== undefined && userData.exp < now) {
        localStorage.clear();

        setRedirect(true);
      } else {
        setRedirect(false);
      }
  }

  useEffect(() => {
    validateToken();
  }, [now, userData]);

  return (
    <BrowserRouter>
      <Routes>
        {!redirect ? (
          <Redirect push to="/login" />
        ) : (
          <Redirect push to={home} />
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default Main;
