import React from "react";

import { AbaScanDocumentosWrapper } from "./styles-abaScanDocumentos";
import { Checkbox, ThemeProvider, createTheme } from "@mui/material";
import colors from "styles/colors";
import { AiOutlineUpload } from "react-icons/ai";
import CloseIcon from "@material-ui/icons/Close";

const theme = createTheme({
  palette: {
    genoaBlue: {
      main: colors.genoaBlue,
    },
    darkGenoaBlue: {
      main: colors.genoaDarkBlue,
    },
    action: {
      disabled: colors.genoaDisabledGrey
    }
  },
});

const AbaScanDocumentos = (
  { values,
    setInputValue,
    setInputFile,
    setSelectValue,
    camposInclusao,
    camposInclusaoPersonalizados,
    setCheckboxValue,
    files,
    handleFile,
    deleteFile
  }) => {
  // console.log("files", files)
  // console.log("camposInclusao - AbaScanDocumentos", camposInclusao)
  // console.log("camposInclusaoPersonalizados - AbaScanDocumentos", camposInclusaoPersonalizados)

  // console.log("values", values)

  return (
    <AbaScanDocumentosWrapper>
      <div className="aba-container">
        <div className="aba-title-container">
          <span>Cópia de Documentos</span>
        </div>

      </div>
      <div className="documentos_content_container">
        <div className="coluna_lista">
          <div className="lista_container">
            {
              values && values['tipoBeneficiario'] === "Titular" ? (
                <>
                  {
                    camposInclusao['documento_scan_rg'] ? (
                      <ThemeProvider theme={theme}>
                        <div className="documento_option">
                          <Checkbox
                            size="small"
                            checked={values['documento_scan_rg']}
                            onChange={setCheckboxValue}
                            name="documento_scan_rg"
                            sx={{
                              p: 0
                            }}
                            color={"darkGenoaBlue"}
                          />
                          <label>
                            RG
                          </label>
                        </div>
                      </ThemeProvider>
                    ) : ""
                  }
                  {
                    camposInclusao['scan_carteira_trabalho'] ? (
                      <ThemeProvider theme={theme}>
                        <div className="documento_option">
                          <Checkbox
                            size="small"
                            checked={values['scan_carteira_trabalho']}
                            onChange={setCheckboxValue}
                            name="scan_carteira_trabalho"
                            sx={{
                              p: 0
                            }}
                            color={"darkGenoaBlue"}
                          />
                          <label>
                            Carteira de trabalho
                          </label>
                        </div>
                      </ThemeProvider>
                    ) : ""
                  }
                  {
                    camposInclusao['scan_comprovante_residencia'] ? (
                      <ThemeProvider theme={theme}>
                        <div className="documento_option">
                          <Checkbox
                            size="small"
                            checked={values['scan_comprovante_residencia']}
                            onChange={setCheckboxValue}
                            name="scan_comprovante_residencia"
                            sx={{
                              p: 0
                            }}
                            color={"darkGenoaBlue"}
                          />
                          <label>
                            Comprovante de residência
                          </label>
                        </div>
                      </ThemeProvider>
                    ) : ""
                  }
                  {
                    camposInclusao['scan_carta_inclusao'] ? (
                      <ThemeProvider theme={theme}>
                        <div className="documento_option">
                          <Checkbox
                            size="small"
                            checked={values['scan_carta_inclusao']}
                            onChange={setCheckboxValue}
                            name="scan_carta_inclusao"
                            sx={{
                              p: 0
                            }}
                            color={"darkGenoaBlue"}
                          />
                          <label>
                            Carta de inclusão
                          </label>
                        </div>
                      </ThemeProvider>
                    ) : ""
                  }
                  {
                    camposInclusao['scan_carta_permanencia'] ? (
                      <ThemeProvider theme={theme}>
                        <div className="documento_option">
                          <Checkbox
                            size="small"
                            checked={values['scan_carta_permanencia']}
                            onChange={setCheckboxValue}
                            name="scan_carta_permanencia"
                            sx={{
                              p: 0
                            }}
                            color={"darkGenoaBlue"}
                          />
                          <label>
                            Carta de permanência
                          </label>
                        </div>
                      </ThemeProvider>
                    ) : ""
                  }
                  {
                    camposInclusao['scan_passaporte'] ? (
                      <ThemeProvider theme={theme}>
                        <div className="documento_option">
                          <Checkbox
                            size="small"
                            checked={values['scan_passaporte']}
                            onChange={setCheckboxValue}
                            name="scan_passaporte"
                            sx={{
                              p: 0
                            }}
                            color={"darkGenoaBlue"}
                          />
                          <label>
                            Passaporte
                          </label>
                        </div>
                      </ThemeProvider>
                    ) : ""
                  }
                  {
                    Object.keys(camposInclusaoPersonalizados)?.map((item) => (
                      <ThemeProvider theme={theme}>
                        <div className="documento_option">
                          <Checkbox
                            size="small"
                            checked={values[item]}
                            onChange={setCheckboxValue}
                            name={item}
                            sx={{
                              p: 0
                            }}
                            color={"darkGenoaBlue"}
                          />
                          <label>
                            {item}
                          </label>
                        </div>
                      </ThemeProvider>
                    ))
                  }
                </>
              ) : (
                <>
                  <ThemeProvider theme={theme}>
                    <div className="documento_option">
                      <Checkbox
                        size="small"
                        checked={values["documento_comprovacao_vinculo"]}
                        onChange={setCheckboxValue}
                        name="documento_comprovacao_vinculo"
                        sx={{
                          p: 0
                        }}
                        color={"darkGenoaBlue"}
                      />
                      <label>
                        Comprovante de vínculo
                      </label>
                    </div>
                  </ThemeProvider>

                  <ThemeProvider theme={theme}>
                    <div className="documento_option">
                      <Checkbox
                        size="small"
                        checked={values["documento_scan_rg"]}
                        onChange={setCheckboxValue}
                        name="documento_scan_rg"
                        sx={{
                          p: 0
                        }}
                        color={"darkGenoaBlue"}
                      />
                      <label>
                        RG
                      </label>
                    </div>
                  </ThemeProvider>

                  <ThemeProvider theme={theme}>
                    <div className="documento_option">
                      <Checkbox
                        size="small"
                        checked={values["documento_scan_cpf"]}
                        onChange={setCheckboxValue}
                        name="documento_scan_cpf"
                        sx={{
                          p: 0
                        }}
                        color={"darkGenoaBlue"}
                      />
                      <label>
                        CPF
                      </label>
                    </div>
                  </ThemeProvider>
                </>
              )
            }
          </div>

          <label className="input-label">
            <span>
              Adicionar documento
              <AiOutlineUpload />
            </span>
            <input
              className="input-file"
              type="file"
              onChange={({ target }) => handleFile(target)}
              // onClick={() => posthog?.capture('Clicou em "+ Documentos"')}
              multiple
            />
          </label>
        </div>
        <div className="coluna_documentos">
          <div className="coluna_documentos__title">
            <span>Documentos anexados:</span>
          </div>
          {
            files?.map((item, index) => (
              <div className="documento_file_option" key={index}>
                <span>{item?.name}</span>
                <button>
                  <CloseIcon
                    onClick={() => deleteFile(index)}
                    className="remove_icon"
                  />
                </button>
              </div>
            ))
          }
        </div>
      </div>
    </AbaScanDocumentosWrapper>
  )
}

export default AbaScanDocumentos;
