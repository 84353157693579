import { saveAs } from 'file-saver';

export const saveFile = async (fileName, workbook) => {
  const xls64 = await workbook.xlsx.writeBuffer({ base64: true });
  saveAs(
    new Blob([xls64], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }),
    fileName
  );
}

export default saveFile;
