import DeleteButtonWithPopUp from 'apps/broker/components/DeleteButtonWithPopUp';

const DeleteMoviments = ({
  anchorEl,
  setAnchorEl,
  selectedCheckboxes,
  editModeClickHandler,
  popupPositiveClickHandler
}) => {
  const handleDisplayMessage = () => {
    if (selectedCheckboxes.length > 0) {
      if (selectedCheckboxes.length === 1) {
        return 'Você quer realmente excluir o item selecionado?';
      }

      return 'Você quer realmente excluir os itens selecionados?';
    }
    return 'Selecione pelo menos um item para excluir';
  };

  const popupNegativeClickHandler = () => {
    setAnchorEl(null);
  };

  const handleClose = (event) => {
    if (event.target && anchorEl !== event.target) {
      setAnchorEl(null);
    }
  };

  const deleteDrawerToggleHandler = (target) => {
    setAnchorEl((prevState) => (prevState ? null : target));
  };

  const clickDeleteMovimentacoesHandler = ({ target }) => {
    deleteDrawerToggleHandler(target);
  };

  return (
    <DeleteButtonWithPopUp
      anchorEl={anchorEl}
      selectedCheckboxes={selectedCheckboxes}
      editModeClickHandler={editModeClickHandler}
      clickDeleteHandler={clickDeleteMovimentacoesHandler}
      popupPositiveClickHandler={popupPositiveClickHandler}
      popupNegativeClickHandler={popupNegativeClickHandler}
      handleDisplayMessage={handleDisplayMessage}
      handleClose={handleClose}
    />
  );
};

export default DeleteMoviments;
