import { useState, useEffect } from 'react';
import { PieChart, Pie, ResponsiveContainer, Sector } from 'recharts';
import { GraphDonutWrapper } from './styles-GraficoDonut';

const GraficoDonut = (props) => {
  const { data, keyName, label } = props;

  const [activeIndex, setActiveIndex] = useState(undefined);
  const [percentualKeyValue, setPercentualKeyValue] = useState(undefined);

  const [dataToPlot, setDataToPlot] = useState([]);
  const [checked, setChecked] = useState(false);

  const setEndPositionLabelX = (cos) => {
    if (cos >= 0.95) {
      return 0;
    } else if (cos <= -0.95) {
      return 0;
    } else if (cos >= 0) {
      return 1;
    } else if (cos < 0) {
      return -1;
    }
  };

  const setEndPositionLabelY = (cos) => {
    if (cos >= 0.95) {
      return -1;
    } else if (cos <= -0.95) {
      return 1;
    } else if (cos >= 0) {
      return 0;
    } else if (cos < 0) {
      return 0;
    }
  };

  const setTextPositionX = (cos) => {
    if (cos >= 0.95) {
      return false;
    } else if (cos <= -0.95) {
      return true;
    } else if (cos >= 0) {
      return true;
    } else if (cos < 0) {
      return false;
    }
  };

  const setTextEndPositionX = (cos) => {
    if (cos >= 0.95) {
      return 1;
    } else if (cos <= -0.95) {
      return -2;
    } else if (cos >= 0) {
      return 1;
    } else if (cos < 0) {
      return -1;
    }
  };

  const setTextEndPositionY = (cos, sin) => {
    const cosRounded = Number(cos.toFixed(2));
    if (cosRounded >= 0.95) {
      return -1;
    } else if (cosRounded <= -0.95) {
      return 3;
    } else if (cosRounded > 0) {
      return 0;
    } else if (cosRounded < 0) {
      return 0;
    } else if (cosRounded === 0) {
      if (sin > 0) {
        return 0.2;
      } else {
        return 1;
      }
    }
  };

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      value
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);

    const sx = cx + outerRadius * cos;
    const sy = cy + outerRadius * sin;
    const mx = cx + (outerRadius + 8) * cos;
    const my = cy + (outerRadius + 8) * sin;
    const ex = mx + setEndPositionLabelX(cos) * 10;

    const ey = my + setEndPositionLabelY(cos) * 10;
    const textAnchor = setTextPositionX(cos) ? 'start' : 'end';

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + setTextEndPositionX(cos) * 5}
          y={ey + setTextEndPositionY(cos, sin) * 5}
          textAnchor={textAnchor}
          fill="#333"
          style={{ fontSize: '0.75em' }}
        >{`${checked ? value : 0}`}</text>
      </g>
    );
  };

  const onPieEnterHandler = (_, index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    const dataReduce = data?.reduce(
      (acc, item, index) => {
        if (index === 0 && !keyName) {
          acc.keyCount = item.value;
        }

        if (keyName === item?.name) {
          acc.keyCount = item.value;
        }
        acc.count += item.value;
        return acc;
      },
      {
        count: 0,
        keyCount: 0
      }
    );

    const value = !dataReduce?.keyCount
      ? 0
      : (dataReduce?.keyCount / dataReduce?.count) * 100;
    setPercentualKeyValue(Number(value.toFixed(0)));

    const dataCheck = !data?.every((item) => {
      if (!item?.value) {
        return true;
      }
      return false;
    });

    setChecked(dataCheck);

    if (dataCheck) {
      setDataToPlot(data);
    } else {
      const newData = data?.map((item) => {
        return {
          ...item,
          value: 1
        };
      });

      setDataToPlot(newData);
    }
  }, [data]);

  return (
    <GraphDonutWrapper>
      <div className="graphBox">
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={dataToPlot}
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              dataKey="value"
              outerRadius={70}
              innerRadius={60}
              paddingAngle={0}
              cx="50%"
              cy="50%"
              onMouseEnter={onPieEnterHandler}
              onMouseLeave={() => {
                setActiveIndex(undefined);
              }}
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className="label_tipo">
        <span className="value">{percentualKeyValue}%</span>
        <span className="tipo">{label ? label : ''}</span>
      </div>
    </GraphDonutWrapper>
  );
};

export default GraficoDonut;
