import { IoDocumentTextOutline } from 'react-icons/io5';
import { Div } from './styles';

const TopBar = ({ title }) => {
  return (
    <Div>
      <div className='icon-bar'>
        <IoDocumentTextOutline />
      </div>
      <div className='container-title'>
        <span className="title-bar">{title}</span>
      </div>
      <div className="top-bar"></div>
    </Div>
  );
};

export default TopBar;
