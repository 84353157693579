const modules = [
  {
    option: 'Solteiro(a)',
    value: 'Solteiro(a)'
  },
  {
    option: 'Casado(a)',
    value: 'Casado(a)'
  },
  {
    option: 'Divorciado(a)',
    value: 'Divorciado(a)'
  },
  {
    option: 'Viúvo(a)',
    value: 'Viúvo(a)'
  },
  {
    option: 'União Estável',
    value: 'União Estável'
  }
];

export default modules;
