import styled from 'styled-components';
import colors from 'apps/broker/styles/colors';

export const Wrapper = styled.div`
  width: 1065px;
  box-sizing: content-box;
  min-height: 101vh;

  header {
    background-color: ${colors.genoaBlue};
    padding: 20px;
    padding-left: 65px;

    h2 {
      font-family: 'Avenir Next GEO W05 Bold';
      color: #fff;
      font-size: 35px;
      margin: 10px 0 20px 0;
    }
    span.date {
      color: #fff;
    }
    .back {
      .icon-back {
        color: #fff;
        cursor: pointer;
        font-size: 2rem;
      }
    }

    .detalhes-second-row {
      display: flex;
      justify-content: space-between;
      color: white;
    }

    .status-change-handler-container {
      color: white;
      display: flex;
      margin-right: 10px;

      .mudar-status-title {
        margin-right: 10px;

        :hover {
          cursor: pointer;
        }
      }

      .arrow-icon {
        width: 13px;
        text-align: center;
        :hover {
          cursor: pointer;
          background-color: #1c2a3929;
        }
      }

      .width-transition {
        overflow: hidden;
        height: 22px;
        transition: width 0.4s ease-in-out;
        span {
          white-space: nowrap;
        }
      }
    }

    .status-change-options-container {
      span {
        margin-left: 10px;

        :hover {
          cursor: pointer;
          border-bottom: solid 1px white;
        }
      }
    }
  }

  section {
    padding: 20px;
    padding-left: 65px;
  }

  .third-row-container {
    display: flex;
    justify-content: end;
    min-height: 22px;
  }

  .status-change-handler-container {
    color: white;
    display: flex;
    margin-right: 10px;

    .mudar-status-title {
      margin-right: 10px;

      :hover {
        cursor: pointer;
      }
    }

    .arrow-icon {
      width: 13px;
      text-align: center;
      :hover {
        cursor: pointer;
        background-color: #1c2a3929;
      }
    }

    .width-transition {
      overflow: hidden;
      height: 22px;
      transition: width 0.4s ease-in-out;
      span {
        white-space: nowrap;
      }
    }
  }

  .inner-abas-movimentacoes {
    width: 100%;
    height: 67px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    font-family: 'Avenir Next GEO W05 bold';
    font-size: 20px;
    color: #3b3838;

    .inner-aba-option {
      background-color: #ccc;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px #a0a0a0 solid;
      :hover {
        cursor: pointer;
      }

      .aba-option-title {
        margin-left: 5px;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
      }
    }

    .inner-aba-option-selected {
      background-color: #ececec;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px #a0a0a0 solid;
      :hover {
        cursor: pointer;
      }

      .aba-option-title {
        margin-left: 5px;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
      }
    }
  }

  .estipulante-select-container {
    min-height: 75.6px;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    color: ${colors.genoaGrey};

    .estipulante-select-row {
      display: flex;
      align-items: center;
      .circular-spinner-container {
        margin-top: 8px;
        padding-left: 20px;
        display: flex;
        align-items: center;

        .circular-spinner {
          color: ${colors.genoaBlue};
        }
      }
    }
    .estipulante-title {
      font-size: 1.1rem;
      font-weight: bold;
    }

    .estipulante-select {
      margin-top: 10px;
      border: 0.5px solid rgb(69, 106, 237);
      border-radius: 10px;
      padding: 10px;
      font-size: 0.9rem;
      width: 500px;
    }
  }

  .sub-estipulante-select-container {
    height: 0px;
    overflow: hidden;
    transition: height 0.8s ease-in-out;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    color: ${colors.genoaGrey};

    .estipulante-title {
      font-size: 1.1rem;
      font-weight: bold;
    }

    .estipulante-select {
      margin-top: 10px;
      border: 0.5px solid rgb(69, 106, 237);
      border-radius: 10px;
      padding: 10px;
      font-size: 0.9rem;
      width: 500px;
    }
  }
`;
