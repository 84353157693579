import styled from 'styled-components';
import colors from 'apps/broker/styles/colors';

export const Div = styled.div`
  .infos {
    padding: 20px;
    padding-left: 65px;

    .open {
      font-family: 'Avenir Next GEO W05 Demi';
      text-decoration: underline;
      margin-bottom: 20px;
    }
    .status {
      > div {
        margin-bottom: 10px;
      }
      .text-icon {
        display: flex;
        align-items: center;
        svg {
          margin-right: 5px;
          font-size: 1.2rem;
        }
        span {
          margin-left: 4px;
          font-family: 'Avenir Next GEO W05 Bold';
        }
        .red {
          color: red;
        }
      }
    }
  }

  .btn-edit {
    display: flex;
    svg {
      margin-left: 10px;
      max-width: 15px;
      max-height: 15px;
    }
  }

  .titleTicket{
    font-family: 'Avenir Next GEO W05 Bold';
    margin-bottom: 20px;
  }

  .messageUser{
    margin-bottom: 25px;;
    .messageText{
      border-radius: 10px;
      box-sizing: border-box;
      padding: 20px;
      max-height: 450px;
      overflow: auto;
    }
    .open-msg{
      background: #F5F5F5;
    }
    .resp-msg{
      background: #DCEEFF;
    }
    .user-date {
      margin-bottom: 6px;
    }
  }

  .actions{
    .buttons{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    

    button.btn-toogle{
      font-size: 1rem;
      font-family: 'Avenir Next GEO W05 Demi';
      background: none;
      border: none;
      cursor: pointer;
      text-decoration: underline;
      color: ${colors.genoaBlue};

      :disabled{
        opacity: 0.6;

      }
    }
    .buttons2{
      display: flex;
      flex-direction: row-reverse;
      margin-right: 20px;
      gap: 10px;
    }

    button.btn-close {
      display: flex;
      padding: 5px 7px;
      margin-right: 20px;
      color: #ffffff;
      border: ${colors.genoaBlue} 1px solid;
      border-radius: 5px;
      background: ${colors.genoaBlue};
      cursor: pointer;
      font-family: 'Avenir Next GEO W05 Demi';
      :hover{
        color: ${colors.genoaBlue};
        background-color: ${colors.white};
      }
    }

    button.btn-archived {
      display: flex;
      padding: 5px 7px;
      margin-right: 20px;
      color: #ffffff;
      border: ${colors.genoaBlue} 1px solid;
      border-radius: 5px;
      background: ${colors.genoaBlue};
      cursor: pointer;
      font-family: 'Avenir Next GEO W05 Demi';
      :hover{
        color: ${colors.genoaBlue};
        background-color: ${colors.white};
      }
    }

    button.btn-reopen {
      display: flex;
      padding: 5px 7px;
      color: #ffffff;
      border: ${colors.genoaBlue} 1px solid;
      border-radius: 5px;
      background: ${colors.genoaBlue};
      cursor: pointer;
      font-family: 'Avenir Next GEO W05 Demi';
      :hover{
        color: ${colors.genoaBlue};
        background-color: ${colors.white};
      }
    }
  }
`;
