import React, { useState, useEffect } from 'react'
import HeaderRh from 'apps/hr/components/Header-RH-Novo'
import SidemenuRh from 'apps/hr/components/Sidebar-RH'
import MainRh from 'apps/hr/components/MainContentRh'
import { Wrapper, Content } from './styles'
import api from 'services/api'
import { useSelector } from 'react-redux'

const DadosEstipulanteRH = () => {
  const user = useSelector(state => state.userReducers.user)

  const [estipulante, setEstipulante] = useState({
    cnpj: "",
    razaoSocial: "",
    endereco: ""
  })
  const [subestipulantes, setSubestipulantes] = useState([])

  function getData() {
    api.get('/rh/estipulante',
      {
        headers: {
          id: user.estipulante,
          authorization: `Bearer ${user.token}`
        }
      }
    ).then(resp => {
      const dataEstipulante = resp.data.estipulante
      setEstipulante({
        cnpj: dataEstipulante.cnpj,
        razaoSocial: dataEstipulante.razaoSocial,
        endereco: dataEstipulante.endereco
      })
      setSubestipulantes(resp.data.subestipulantes)
    }).catch(err => {
      console.log(err)
    })
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <Wrapper>
      <SidemenuRh />
      <Content>
        <HeaderRh />
        <MainRh>
          <div className="main-painel">
            <div className="box">
              <div className="title">
                Informações do Estipulante
              </div>
              <div className="data-estipulante">
                <div className="data">
                  <span className="data-title">Razão Social</span>

                  <span className="data-info">
                    {estipulante.razaoSocial}
                  </span>
                </div>

                <div className="data">
                  <span className="data-title">CNPJ</span>

                  <span className="data-info">
                    {estipulante.cnpj}
                  </span>
                </div>

                <div className="data">
                  <span className="data-title">Endereço</span>
                  <span className="data-info">
                    {estipulante.endereco}
                  </span>
                </div>
              </div>
            </div>

            <div className="box">
              <div className="title">
                Sub estipulantes
              </div>
              {
                subestipulantes.map((e, i) => {
                  return (
                    <div key={i} className="data-estipulante">
                      <div className="data">
                        <span className="data-title">Razão Social</span>
                        <span className="data-info">
                          {e.razaoSocial}
                        </span>
                      </div>

                      <div className="data">
                        <span className="data-title">CNPJ</span>
                        <span className="data-info">{e.cnpj}</span>
                      </div>
                    </div>
                  )
                })
              }
            </div>

          </div>
        </MainRh>
      </Content>
    </Wrapper>
  )
}

export default DadosEstipulanteRH