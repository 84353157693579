import { useState, useEffect } from 'react';
import { Container } from './styles';
import Menu from 'apps/broker/components/Header/HeaderMenuCorretor';
import { useSelector } from 'react-redux';
import api from 'services/api';
import { makeStyles, SwipeableDrawer } from '@material-ui/core';
import DrawerAdicionarBeneficiarioRh from 'apps/hr/pages/HomeRH/DrawerAdicionarBeneficiarioRh';
import { usePostHog } from 'posthog-js/react';
import DrawerAdicionarPlanilhaRh from 'apps/hr/pages/HomeRH/DrawerAdicionarPlanilhaRh';
import DrawerRequestInclusion from 'apps/hr/pages/HomeRH/RequestInclusion/DrawerRequestInclusion';

const useStyles = makeStyles({
  paper: {
    background: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh'
  }
});

const HeaderCorretor = ({ getMovimentacoes }) => {
  const posthog = usePostHog();

  const user = useSelector((state) => state.userReducers.user);

  const [openAdicionarBeneficiario, setOpenAdicionarBeneficiario] =
    useState(false);

  const [openAdicionarPlanilha, setOpenAdicionarPlanilha] = useState(false);

  const [openRequestInclusion, setOpenRequestInclusion] = useState(false);

  const [estipulanteAndSubs, setEstipulantesAndSubs] = useState([]);

  const [contratosEstipulante, setContratosEstipulante] = useState({
    saude: [],
    odonto: []
  });

  const classes = useStyles();

  const toggleDrawerAdicionarBeneficiario = () => {
    posthog?.capture('Fechou "DrawerAdicionarBeneficiario"');
    setOpenAdicionarBeneficiario((prevState) => !prevState);
  };

  const toggleDrawerAdicionarPlanilha = () => {
    posthog?.capture('Fechou "DrawerAdicionarPlanilha"');
    setOpenAdicionarPlanilha((prevState) => !prevState);
  };

  const toggleDrawerRequestInclusion = () => {
    posthog?.capture('Fechou "DrawerRequestInclusion"');
    setOpenRequestInclusion(false);
  };

  const getContratosAtivosEstipulante = () => {
    api
      .get(`/rh/estipulante-sub/contratos/info/${user.mainEstipulante}`, {
        headers: {
          authorization: `Bearer ${user.token}`
        }
      })
      .then((resp) => {
        // console.log(resp.data)
        setContratosEstipulante(resp.data);
      });
  };

  const getEstipulanteAndSubs = () => {
    api
      .get(`rh/estipulante-sub/info/${user.mainEstipulante}`, {
        headers: {
          authorization: `Bearer ${user.token}`
        }
      })
      .then((resp) => {
        setEstipulantesAndSubs(resp?.data);
      });
  };

  const contentAdicionarBeneficiario = () => {
    return (
      <DrawerAdicionarBeneficiarioRh
        estipulanteAndSubs={estipulanteAndSubs}
        contratosEstipulante={contratosEstipulante}
        closeDrawer={toggleDrawerAdicionarBeneficiario}
        getMovimentacoes={getMovimentacoes}
      />
    );
  };

  const contentAdicionarPlanilha = () => {
    return (
      <DrawerAdicionarPlanilhaRh
        estipulanteAndSubs={estipulanteAndSubs}
        contratosEstipulante={contratosEstipulante}
        closeDrawer={toggleDrawerAdicionarPlanilha}
        getMovimentacoes={getMovimentacoes}
      />
    );
  };

  const contentRequestInclusion = () => {
    return (
      <DrawerRequestInclusion
        closeDrawer={toggleDrawerRequestInclusion}
      />
    );
  };

  const getEstipulanteName = async () => {
    const idref = user.estipulante;

    api
      .get('/rh/estipulante', {
        headers: {
          id: idref,
          authorization: `Bearer ${user.token}`
        }
      })
      .then((resp) => {
        // console.log('RESP.DATA >>>>>>>>>>>>', resp.data)
      });
  };

  const getInitials = (name) => {
    if (name) {
      const fullName = name.split(' ');
      // if (fullName.length >= 2)
      //     return fullName.shift().charAt(0) + fullName.pop().charAt(0);
      return fullName[0];
    }
  };

  useEffect(() => {
    getEstipulanteName();
    getContratosAtivosEstipulante();
    getEstipulanteAndSubs();
  }, []);

  return (
    <Container>
      {user.nome && (
        <div className="welcome-name-title-container">
          <span className="welcome-name-title">
            <span className="heavy-title">{`${getInitials(user.nome)}`}</span>,
            boas vindas ao
            <span className="heavy-title"> Zeca</span>
          </span>
        </div>
      )}
      <div></div>

      <Menu
        setOpenAdicionarBeneficiario={setOpenAdicionarBeneficiario}
        setOpenAdicionarPlanilha={setOpenAdicionarPlanilha}
        setOpenRequestInclusion={setOpenRequestInclusion}
        user={user}
      >
        <div className="iniciais-empresa">
          {user.nome && <span>{`Olá, Genoa`}</span>}
        </div>
      </Menu>

      <SwipeableDrawer
        classes={{ paper: classes.paper }}
        anchor="top"
        transitionDuration={0}
        open={openAdicionarBeneficiario}
        onOpen={() => posthog?.capture('Abriu "DrawerAdicionarBeneficiario"')}
        onClose={toggleDrawerAdicionarBeneficiario}
      >
        {contentAdicionarBeneficiario()}
      </SwipeableDrawer>

      <SwipeableDrawer
        classes={{ paper: classes.paper }}
        anchor="top"
        transitionDuration={0}
        open={openAdicionarPlanilha}
        onOpen={() => posthog?.capture('Abriu "DrawerAdicionarPlanilha"')}
        onClose={toggleDrawerAdicionarPlanilha}
      >
        {contentAdicionarPlanilha()}
      </SwipeableDrawer>

      <SwipeableDrawer
        anchor="right"
        transitionDuration={500}
        open={openRequestInclusion}
        onOpen={() => {
          posthog?.capture('Abriu "DrawerRequestInclusion"');
          setOpenRequestInclusion(true);
        }}
        onClose={toggleDrawerRequestInclusion}
      >
        {contentRequestInclusion()}
      </SwipeableDrawer>
    </Container>
  );
};

export default HeaderCorretor;
