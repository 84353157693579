import styled from 'styled-components';
import colors from 'styles/colors';

export const DrawerContent = styled.div`
  border-left: 10px solid ${colors.genoaBlue};
  border-radius: 10px;
  background-color: ${colors.white};
  width: 450px;
  position: fixed;
  // top: calc(50% - 200px);
  left: calc(60% - 305px);
  // transform: translateX(calc(50%));
  padding: 25px;
  z-index: 10000;

  animation: up-down 0.2s forwards;


  @keyframes up-down {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: calc(50% - 200px);
    }
  }

  display: flex;
  flex-direction: column;
  row-gap: 30px;

  .title {
    font-family: Avenir Next Geo W05 Heavy;
    font-size: 1.2em;
  }

  .form_container {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
  }

  .form_option {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .form_option_title {
    font-family: Avenir Next Geo W05 Demi;
    font-size: 0.85em;
  }

  .custom_select {
    // transform: translateY(-22px);
    font-family: Avenir Next Geo W05 Regular;
    width: 350px;
    height: 45px;

    .select__trigger {
      border-bottom: none;
      background-position-y: 3px;
      height: 24px;

      :hover{
        border-bottom: none;
      }
    }

    div.focused {
      border-color: ${colors.genoaBlue};
      border-bottom: 1px solid ${colors.genoaBlue};

      :hover{
        border-bottom: 1px ${colors.genoaBlue} solid;
      }
    }

    span {
      font-size: 0.85em;
      line-height: 1.5em;

    }

    .select__trigger {
      padding-bottom: 0px;
      background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 9L0.73686 0.75L10.2631 0.75L5.5 9Z' fill='%23456AED'/%3E%3C/svg%3E%0A");
    }

    .options__wrapper {
      font-size: 0.95em;
      row-gap: 2px;
      border: none;

      span:hover {
        background-color: ${colors.genoaBlue};
      }

      ::-webkit-scrollbar-thumb {
        background: ${colors.genoaBlue};
      }
    }
  }

  .custom_datepicker {
    border: none;
    border-bottom: 1px solid ${colors.genoaBlue};
    display: inline-block;
    overflow: hidden;
    text-align: center;
    font-family: 'Avenir Next GEO W05 Regular';
    height: 22px;
    padding-bottom: 6px;
    width: 90px;
    margin-top: 15px;
  }

  .active {
    border-bottom: 2px solid ${colors.genoaBlue};
  }

  .react-datepicker__day--selected:hover {
    color: white;
    background-color: ${colors.genoaBlueHover};
    font-weight: bold;
  }

  .react-datepicker__day--selected {
    color: white;
    background-color: ${colors.genoaBlue};
    font-weight: bold;
  }
  .react-datepicker__day--keyboard-selected:hover {
    color: ${colors.genoaBlue};
    background-color: #f0f0f0;
  }

  .react-datepicker__day--keyboard-selected {
    color: ${colors.genoaBlue};
    background-color: white;
    font-weight: bold;
  }


  .react-datepicker__triangle::after{
    // background-color: ${colors.darkGray};
    // transform: translateY(-242px) rotate(180deg);
    // border: 1px solid ${colors.darkGray};
    padding: 0px;
  }

  .react-datepicker__triangle::before{
    // background-color: red;
    // transform: translateY(-242px) rotate(180deg);
    // border: 20px solid ${colors.darkGray};
    // padding: 1px;
  }

  .react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: min-content;
  }

  .span-date-container {
    padding-top: 20px;
    width: 200px;
    height: 33px;
    display: flex;
    justify-content: space-between;
    transition: all .4s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .span-date-title {
    padding-top: 10px;
  }

  .datepicker-selector-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
  }

  .clear-date-option {
    font-size: 0.8rem;
    color: ${colors.genoaBlue};
    height: 22.8px;
    font-weight: bold;
    padding-top: 5px;
    :hover {
      cursor: pointer;
      border-bottom: 1px ${colors.genoaBlue} solid;
    }
  }

  .clear-date-option-container {
    min-height: 23px;
  }

  .textarea_container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  textarea {
    resize: none;
    padding: 10px;
    border: 1px solid ${colors.genoaBlue};
    border-radius: 12px;
    font-family: Avenir Next Geo W05 Regular;
    width: 300px;

    :focus-visible {
      outline: 1px ${colors.genoaBlue}f5 auto;
    }

    ::-webkit-scrollbar {
      width: 3px;
      padding: 16px 0px;
      background: rgba(0, 0, 0, 0);
    }
    ::-webkit-scrollbar-thumb {
      background: ${colors.rhLightGrey};
      padding: 16px 0px;
      box-shadow: none;
      
    }
    ::-webkit-scrollbar-track {
      margin-top: 16px;
      margin-bottom: 16px;
      box-shadow: inset 0 0 2px rgb(0, 0 , 0, 0);
      
    }
  }

  .button_container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .criar_btn {
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    background-color: ${colors.genoaBlue};
    color: white;
    padding: 4px 8px;
    font-family: Avenir Next Geo W05 Demi;
    text-transform: uppercase;
    font-size: 0.9em;
    border-radius: 5px;
    top: 0;
    right: 0;
  }

  .disabled {
    background-color: ${colors.genoaDisabledGrey};
  }

`