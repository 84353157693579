import styled from 'styled-components';
import colors from 'apps/broker/styles/colors';

export const Content = styled.div`
  padding: 15px 20px;

  .title {
    color: #3b3838;
    font-family: 'Avenir Next GEO W05 Bold';
    margin-bottom: 20px;
  }

  .form-edit {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    label {
      margin-bottom: 10px;
    }
    select {
      font-family: 'Avenir Next GEO W05 Regular';
      padding: 10px;
      width: 307px;
      border-radius: 8px;
      border: 1px ${colors.genoaBlue} solid;
      margin-top: 10px;

      :focus-visible {
        outline: 1px ${colors.genoaBlue}f5 auto;
      }
    }
  }

  .actions {
    padding: 10px 0;
    .buttons2 {
      display: flex;
      justify-content: center;

      .btn {
        font-family: 'Avenir Next GEO W05 Bold';
        cursor: pointer;
        padding: 6px 8px;
        border-radius: 5px;
        min-width: 120px;

        :last-child {
          margin-left: 20px;
        }
      }

      .btn-confirm2 {
        color: #ffffff;
        border: ${colors.genoaBlue} 1px solid;
        background: ${colors.genoaBlue};

        :hover {
          color: ${colors.genoaBlue};
          background-color: white;
        }
      }
      .btn-canlcel2 {
        color: #eb3535;
        border: #eb3535 1px solid;
        background-color: white;

        :hover {
          color: white;
          background-color: #eb3535;
        }
      }
    }
  }
`;
