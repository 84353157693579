import React, { useEffect, useState } from "react";
import { Container } from "./styles";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
// import IconButton from "@material-ui/core/IconButton";
// import MoreVertIcon from "@material-ui/icons/MoreVert";

import { BiLogOut } from "react-icons/bi";

import { makeStyles } from "@material-ui/core/styles";

import NewTicket from "./newTicket";
import { useDispatch, useSelector } from "react-redux";

import logo_zeca_branco from "assets/imgs/svg/logo-zeca-branco.svg";
import SlideModal from "components/Modal";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import NewCollaborator from "./newCollaborator";
import { Link } from "react-router-dom";
import api from "services/api";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  dropdown: {
    left: "-20px !important",
  },
  icon: {
    minWidth: "35px",
  },
}));

const HeaderRh = (props) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const dispatch = useDispatch();

  function logOut() {
    localStorage.removeItem("jwt_token_user");
    localStorage.removeItem("persist:root");
    dispatch({ type: "SET_USER", user: null });
  }

  /* Code Tunnes */ /* Code Tunnes */ /* Code Tunnes */

  const [openSlideModal, setOpenSlideModal] = useState(false);
  const [openSlideModalCollaborator, setOpenSlideModalCollaborator] =
    useState(false);
  const [estipulante, setEstipulante] = useState({
    cnpj: "",
    razaoSocial: "",
    endereco: "",
  });
  const user = useSelector((state) => state.userReducers.user);

  const handleModalCollaborator = (collaborator) => {
    setOpenSlideModalCollaborator(true);
  };

  const handleModalTicket = (ticket) => {
    setOpenSlideModal(true);
  };

  const handleCloseModal = () => {
    setOpenSlideModal(false);
    setOpenSlideModalCollaborator(false);
  };

  function getData() {
    api
      .get("/rh/estipulante", {
        headers: {
          id: user.estipulante,
          authorization: `Bearer ${user.token}`,
        },
      })
      .then((resp) => {
        const dataEstipulante = resp.data.estipulante;
        setEstipulante({
          cnpj: dataEstipulante.cnpj,
          razaoSocial: dataEstipulante.razaoSocial,
          endereco: dataEstipulante.endereco,
        });
      });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container>
      <div className="logo">
        <img src={logo_zeca_branco} alt="logo_zeca_branco" />
      </div>

      <div className="nome-rh">
        <p>{estipulante.razaoSocial}</p>
      </div>

      <div className="header-menu">
        <div className="novo-ticket">
          <button onClick={() => handleModalTicket()}>Novo Ticket</button>

          <SlideModal
            className="modal-box"
            openModal={openSlideModal}
            setOpenModal={handleCloseModal}
          >
            <NewTicket closeModal={handleCloseModal} />
          </SlideModal>
        </div>

        <div className="novo-colaborador">
          <button onClick={() => handleModalCollaborator()}>
            Novo Colaborador
          </button>

          <SlideModal
            className="modal-box"
            openModal={openSlideModalCollaborator}
            setOpenModal={setOpenSlideModalCollaborator}
          >
            <NewCollaborator />
          </SlideModal>
        </div>

        <div className="iniciais">
          <div className="name" ref={anchorRef} onClick={handleToggle}>
            {user.nome && user.nome.substring(0, 1)}
            {user.sobrenome && user.sobrenome.substring(0, 1)}
          </div>
          <Popper
            className={classes.dropdown}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      <Link to="/login">
                        <MenuItem onClick={logOut}>
                          <ListItemIcon className={classes.icon}>
                            <BiLogOut />
                          </ListItemIcon>
                          <Typography variant="inherit" noWrap>
                            Sair
                          </Typography>
                        </MenuItem>
                      </Link>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    </Container>
  );
};

export default HeaderRh;
