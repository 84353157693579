import styled from 'styled-components';
import colors from 'styles/colors';

export const CardWrapper = styled.li`

  display: flex;
  flex-direction: column;
  margin-top: 10px;
  background-color: white;

  box-shadow: 2px 3px ${colors.genoaMediumBackgroundGrey};
  border-radius: 12px;
  padding: 10px 15px;
  height: 150px;
  width: 300px;
  cursor: pointer;

  .pipe_body_container {
    display: flex;
    flex-direction: column;
    row-gap: 2px;

    span {
      font-size: 0.8em;
      font-family: Avenir Next Geo W05 Regular;
    }

    .content_card {
      width: 100%;

      .estipulante {
        font-size: 0.78em;
        font-family: Avenir Next Geo W05 Demi;
      }
    }

    .tipo_container {
      display: flex;
      flex-direction: row;
      column-gap: 10px;
    }

    .negocio_novo {
      font-family: Avenir Next Geo W05 Demi;
      border-radius: 4px;
      padding: 2px 3px;
    }

    .icon_novo {
      color: ${colors.genoaBlue};
      font-size: 1rem;
    }

    .negocio_renovacao {
      font-family: Avenir Next Geo W05 Demi;
      border-radius: 4px;
      padding: 2px 3px;
    }

    .icon_renovacao {
      color: ${colors.darkGray};
      font-size: 1rem;
    }

    .atividade_icon {
      display: flex;
      justify-content: right;
    }

  }

  .container_btn_activity {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 6px;


    .icon-alert {
      font-size: 1.5em;
    }
  
    .plus {
      color: ${colors.darkGray};
      font-size: 1em;
  
      :hover {
        cursor: pointer;
      }
    }
  
    .status-grey-circle-icon {
      color: #999999;
    }
  
    .status-green-circle-icon>circle {
      color: #008631;
    }
  
    .status-yellow-circle-icon {
      color: #FFF266;
    }
  
    .status-red-circle-icon {
      color: #FF1919; 
    }
  }

  button {
    border: none;
    background: none;
    cursor: pointer;
  }

  .card_menu_tipo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .container_btn {
      display: flex;
      flex-direction: row;
      column-gap: 5px;
      
      .btn_glasses {
        display: flex;
        align-items: center;
        padding: 4px;
        font-size: 1rem;
      }
    
      .btn_remove {
        display: flex;
        align-items: center;
        padding: 4px;
        border: none;
        font-size: 1rem;
        cursor: pointer;
        background: none;
        transition: 0.1s ease-in;
        border-radius: 50%;
    
        color: #FF1919;;
      }
    }
  }
  
`;

export const IsDraggingCardWrapper = styled.li`

  display: flex;
  flex-direction: column;
  margin-top: 10px;
  background-color: white;

  // box-shadow: 2px 3px ${colors.genoaMediumBackgroundGrey};
  border: solid 2px ${colors.genoaRed};
  border-radius: 12px;
  padding: 10px 15px;
  height: 150px;
  width: 300px;
  cursor: pointer;

  .pipe_body_container {
    display: flex;
    flex-direction: column;
    row-gap: 2px;

    span {
      font-size: 0.8em;
    }

    .content_card {
      width: 100%;

      .estipulante {
        font-size: 0.78em;
        font-family: Avenir Next Geo W05 Demi;
      }
    }

    .tipo_container {
      display: flex;
      flex-direction: row;
      column-gap: 10px;
    }

    .negocio_novo {
      font-family: Avenir Next Geo W05 Demi;
      border-radius: 4px;
      padding: 2px 3px;
    }

    .icon_novo {
      color: ${colors.genoaBlue};
      font-size: 1rem;
    }

    .negocio_renovacao {
      font-family: Avenir Next Geo W05 Demi;
      border-radius: 4px;
      padding: 2px 3px;
    }

    .icon_renovacao {
      color: ${colors.darkGray};
      font-size: 1rem;
    }

    .atividade_icon {
      display: flex;
      justify-content: right;
    }

  }

  .container_btn_activity {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 6px;


    .icon-alert {
      font-size: 1.5em;
    }
  
    .plus {
      color: ${colors.darkGray};
      font-size: 1em;
  
      :hover {
        cursor: pointer;
      }
    }
  
    .status-grey-circle-icon {
      color: #999999;
    }
  
    .status-green-circle-icon>circle {
      color: #008631;
    }
  
    .status-yellow-circle-icon {
      color: #FFF266;
    }
  
    .status-red-circle-icon {
      color: #FF1919; 
    }
  }

  button {
    border: none;
    background: none;
    cursor: pointer;
  }

  .card_menu_tipo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .container_btn {
      display: flex;
      flex-direction: row;
      column-gap: 5px;
      
      .btn_glasses {
        display: flex;
        align-items: center;
        padding: 4px;
        font-size: 1rem;
      }
    
      .btn_remove {
        display: flex;
        align-items: center;
        padding: 4px;
        border: none;
        font-size: 1rem;
        cursor: pointer;
        background: none;
        transition: 0.1s ease-in;
        border-radius: 50%;
    
        color: #FF1919;;
      }
    }
  }
  
`;
