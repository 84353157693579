import { 
  keysAbaDadosBasicos,
  keysAbaDadosImc, 
  keysAbaFichaProposta, 
  keysAbaScanDocumentos 
} from "./camposInclusaoPorAbaAdicionais";

  
  const determinaAbasDisponiveisDepAdicionais = (
    {
      camposInclusao,
      setAbasDisponiveis,
      state,
      beneficiariosAdicionais,
      beneficiarioPage
    }
  ) => {
    // console.log('camposInclusao >>>>>', camposInclusao)
    const beneficiarioAdicional = beneficiariosAdicionais[Number(beneficiarioPage)];

    const planoSelecionado = beneficiarioAdicional?.incluirSaude || beneficiarioAdicional?.incluirOdonto

    const showDadosPlanos = true;
    const showDadosBasicos = planoSelecionado && keysAbaDadosBasicos.some((item) => camposInclusao && camposInclusao[item]);
    const showDadosImc = planoSelecionado && keysAbaDadosImc.some((item) => camposInclusao && camposInclusao[item]);
    const showScanDocumentos = planoSelecionado && keysAbaScanDocumentos.some((item) => camposInclusao && camposInclusao[item]);
    const showFichaProposta = planoSelecionado && keysAbaFichaProposta.some((item) => camposInclusao && camposInclusao[item])
  
    setAbasDisponiveis({

      showDadosPlanos,
      showDadosBasicos,
      showDadosImc,
      showScanDocumentos,
      showFichaProposta
    })
  
  };
  
  export default determinaAbasDisponiveisDepAdicionais;
  