import styled from "styled-components";
import colors from "styles/colors";

export const InputFileComponentWrapper = styled.div`
  display: grid;
  grid-template-columns: 150px 20px;
  column-gap: 8px;
  align-items: end;
  padding-top: 10px;

  .remove-file-icon {
    color: ${colors.genoaPink};
    padding-bottom: 8px;
    
    :hover {
      cursor: pointer;
    }
  }
`

export const InputFileComponent = styled.div`
  display: flex;
  flex-direction: column;
  height: 30px;

  label {
    color: ${colors.genoaGrey};
    white-space: nowrap;
    position: relative;
    font-size: 12px;
    transform-origin: top left;
    top: 23px;
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms
  }

  label.translate {
    transform: translate(0, -25px) scale(0.75);
    font-family: Avenir Next GEO W05 Demi;
    letter-spacing: 0.3px;
    transform-origin: top left;
    color: ${colors.genoaBlue};
    transition: color 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms
  }


  input {
    border: none;
    z-index: 1000;
    
    font-size: 12px;
    background-color: transparent !important;
    font-family: Avenir Next GEO W05 Regular;
    padding-bottom: 5px;  
    border-bottom: 3px ${colors.rhTabLightGrey} solid;

    :hover{
      border-bottom: 3px ${colors.rhTabDarkGrey} solid;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
  
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  input.focused {
    transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid ${colors.genoaBlue};
  }

  input::-webkit-file-upload-button {
    display: none;
  }

  input::file-selector-button{
    visibility: hidden;
    color: transparent;
  }

  input[type='file'].empty {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    color: rgba(0, 0, 0, 0)
  }
`