import { useState } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import { Wrapper } from './styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputMask from 'react-input-mask';
import Loading from 'components/Loading';
import services from 'apps/broker/services';

registerLocale('ptBR', ptBR);

const AddSubestipulante = ({
  estipulante,
  subs,
  getDataDrawer,
  setOpenModal
}) => {
  const [successSubEst, setSuccessSubEst] = useState(false);
  const [load, setLoad] = useState(false);

  const { handleSubmit, control, watch, getValues, setValue } = useForm();

  const {
    fields: subEstipulanteFields,
    append: subEstipulanteAppend,
    remove: subEstipulanteRemove
  } = useFieldArray({ control, name: 'subEstipulante' });

  const addSub = (data) => {
    setLoad(true);
    if (!data.subEstipulante[0]?.cnpj) {
      return;
    } else {
      services.policyOwner
      .createSubByPolicyOwnerUUID(estipulante, data)
      .then(() => {
        setSuccessSubEst(true);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        getDataDrawer();
        setLoad(false);
        setTimeout(() => {
          setSuccessSubEst(false);
          setOpenModal(false);
        }, 1000);
      });
    }
  };

  const handleSubsCnpjChange = (field, target, index) => {
    field.onChange(target.value);
    if (target.value.length > 17) {
      subsCnpjSend(target.value, index);
    }
  };

  async function subsCnpjSend(value, index) {
    try {
      if (value.length > 17) {
        const cnpj = value.replace(/[^\d]+/g, '');

        await services.policyOwner.getRevenueService(cnpj).then((response) => {
          const {
            nome,
            abertura,
            logradouro,
            numero,
            complemento,
            cep,
            uf,
            municipio
          } = response.data;
          const complementoTratado = complemento.replace(';', '');

          setValue(`subEstipulante.${index}.dados`, {
            nome,
            abertura,
            logradouro,
            numero,
            complemento: complementoTratado,
            cep,
            uf,
            municipio
          });
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <>
      <Loading open={load} setOpen={() => setLoad(false)} />
      <Wrapper>
        <section>
          <div className="rows">
            <div className="cols">
              <div className="card">
                <br />
                <h4>Para adicionar um novo sub clique no botão abaixo: </h4>
                <div className="addGestor">
                  <form onSubmit={handleSubmit(addSub)}>
                    {successSubEst && (
                      <span className="message animeLeft">
                        Subestipulante cadastrado com sucesso.
                      </span>
                    )}
                    {subEstipulanteFields?.map((item, index) => {
                      return (
                        <div key={index} className="input-group">
                          <div className="label-fields">
                            <label className="default">Insira o CNPJ</label>
                            <button
                              type="button"
                              className="remove-field"
                              onClick={() => subEstipulanteRemove(index)}
                            >
                              remover
                            </button>
                          </div>

                          <Controller
                            name={`subEstipulante.${index}.cnpj`}
                            control={control}
                            rules={{ required: false }}
                            defaultValue={`${item.cnpj}`}
                            render={({ field }) => (
                              <InputMask
                                maskChar={null}
                                mask="99.999.999/9999-99"
                                type="text"
                                autoFocus={false}
                                onChange={({ target }) =>
                                  handleSubsCnpjChange(field, target, index)
                                }
                              />
                            )}
                          />

                          {watch(`subEstipulante.${index}.cnpj`).length ===
                            18 &&
                            !watch(`subEstipulante.${index}.dados.nome`) && (
                              <CircularProgress size={20} />
                            )}

                          {watch(`subEstipulante.${index}.dados`) && (
                            <div className="dados-receita">
                              {getValues(`subEstipulante.${index}.dados.nome`)}{' '}
                              <br />
                            </div>
                          )}
                        </div>
                      );
                    })}

                    <button
                      type="button"
                      className="add"
                      onClick={() => subEstipulanteAppend({ cnpj: '' })}
                    >
                      + SUB ESTIPULANTE
                    </button>

                    <div className="row">
                      <button className="send-btn" type="submit">
                        Salvar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Wrapper>
    </>
  );
};

export default AddSubestipulante;
