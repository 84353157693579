const DialogRemoval = ({
  contato,
  removeGestor,
  setRemoveGest
}) => {
  return (
    <div className="dialog-remove-gestor">
      <div className="txt-jt">
        {`Você tem certeza que deseja excluir ${contato.nome} ${contato.sobrenome}?`}
      </div>
      <div className="btn-remove">
        <button
          className="send-btnRemove"
          type="button"
          onClick={(e) => removeGestor(e, contato.usuario_rh_id)}
        >
          Sim
        </button>
        <button
          className="send-btnRemove"
          type="button"
          onClick={(e) => setRemoveGest(false)}
        >
          Não
        </button>
      </div>
    </div>
  );
};

export default DialogRemoval;
