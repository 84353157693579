import styled from 'styled-components';
import colors from 'styles/colors';

export const Div = styled.div`
  display: grid;
  grid-template-columns: 5% 30% 70%;
  align-items: center;
  margin-bottom: 15px;

  .icon-bar {
    display: flex;
    align-items: center;
    font-size: 20px;
  }

  .container-title {
    display: flex;
    align-items: center;
  }

  .title-bar {
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 17px;
    color: ${colors.genoaBlue};
  }

  .top-bar {
    background-color: ${colors.rhBlue};
    height: 10px;
    width: 680px;
  }
`;

export const Bar = styled.div`
  background-color: ${colors.rhBlue};
  height: 10px;
  width: 100%;
`;
