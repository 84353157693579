import styled from 'styled-components';
import media from 'styled-media-query'

export const Wrapper = styled.div `
    display: flex;

    .animeLeft {
        opacity: 0;
        transform: translateX(-20px);
        animation: animeLeft 0.3s forwards;
      }
      @keyframes animeLeft {
        to {
          opacity: 1;
          transform: initial;
        }
      }
`

export const Content = styled.div `

    display: flex;
    flex-direction: column;
    flex: 1;

    .main-painel {
        padding: 20px 100px;
    }

    .box {
        margin-bottom: 40px;
    }

    .title {
        font-family: 'Avenir Next GEO W05 Bold';
        font-size: 1.4rem;
        margin-bottom: 20px;
        color: #3B3838;
    }

    .data-estipulante {
        display: grid;
        grid-template-columns: 30% 30%;
        grid-gap: 20px;
    }

    .data {
        display: flex;
        flex-direction: column;
    }

    .data-title {
        margin-bottom: 10px;   
    }
    .data-info {
        font-family: 'Avenir Next GEO W05 Bold';
        margin-bottom: 10px;   
    }

    .input-read {
        max-width: 600px;
        padding: 10px;
        border: 1px solid #62A0E2;
        border-radius: 10px;
        font-size: 1.25rem;
    }

    ${media.lessThan("1280px")`
        .data-estipulante {
            grid-template-columns: 1fr;
        }
    `}
`