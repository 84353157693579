import http, { BROKERAGE_UUID } from 'services/http';

// index.js
const getAll = async () => {
  const res = await http.get('/corretor/estipulantes');

  return res;
};

const getAllSubs = async (companyId) => {
  const res = await http.get('/corretor/estipulante', {
    headers: {
      estipulante: companyId
    }
  });

  return res;
};

const getPolicyOwnerAndSub = async () => {
  const res = await http.get('/corretor/estipulante-sub/');

  return res;
};

const getAllPolicyOwnersAndSubs = async () => {
  const res = await http.get('/corretor/estipulantes-subs');

  return res;
};

const getPolicyOwnersList = async () => {
  const res = await http.get('/corretor/lista-estipulantes');

  return res;
};

// Essa requisição retorna somente a razão social
const getPolicyOwners = async () => {
  const res = await http.get('/corretor/estipulantes/onlyRazaoSocial');

  return res;
};

// Precisa refatorar o backend
const getPolicyOwnerSelected = async (estipulanteSelecionado) => {
  const res = await http.get('/corretor/estipulante/atualizado', {
    headers: {
      id: estipulanteSelecionado
    }
  });

  return res;
};

const getPotencialLeads = async () => {
  const res = await http.get('/corretor/lista-leads');

  return res;
};

const createPolicyOwner = async (data) => {
  const res = await http.post('/corretor/add/estipulante', data);

  return res;
};

const deletePolicyOwnerSelected = async (selectedCheckboxes) => {
  const res = await http.delete('/corretor/estipulantes', {
    data: {
      estipulantes: selectedCheckboxes
    }
  });

  return res;
};

// components/ DrawerEstipulante
const getById = async (companyId) => {
  const res = await http.get('/corretor/estipulante', {
    headers: {
      estipulante: companyId
    }
  });

  const contracts = res.data.contratos || {};
  const newContracts = Object.keys(contracts).reduce((acc, type) => {
    const list = contracts[type] || [];
    const newList = list.map((item) => {
      const lifesInsuranceTotal = item.estipulantes.reduce((total, item) => {
        return total + item.vidas?.qnt;
      }, 0);

      return {
        ...item,
        lifesInsuranceTotal,
        type
      };
    });

    return [...acc, ...newList];
  }, []);

  return {
    ...res.data,
    businessName: res.data.razao_social,
    contracts: newContracts
  };
};

const createManagersByUUID = async (data, companyUUID) => {
  const res = await http.post('/corretor/gestores', data, {
    headers: {
      estipulante: companyUUID,
      corretora: BROKERAGE_UUID
    }
  });

  return res;
};

const deleteManagerByUUID = async (userRhId, companyUUID) => {
  const res = await http.post(
    '/corretor/gestor-remove',
    {},
    {
      headers: {
        corretora: BROKERAGE_UUID,
        estipulante: companyUUID,
        rhuser: userRhId
      }
    }
  );

  return res;
};

const updateManagerByUUID = async (data, companyUUID) => {
  const res = await http.post('/corretor/gestor-update', data, {
    headers: {
      rhuser: data.usuario_rh_id,
      estipulante: companyUUID,
      corretora: BROKERAGE_UUID
    }
  });

  return res;
};

// Não consegui testar porque a função handleImgChange está dando erro de Blob
const createLogoByUUID = async (formData, companyUUID) => {
  const res = await http.post('/corretor/upload-logo-estipulante', formData, {
    headers: {
      estipulante: companyUUID
    }
  });

  return res;
};

const updateById = async (data) => {
  const res = await http.post('/corretor/update-estipulante', data, {
    headers: {
      estipulante: data.estipulante,
      corretora: BROKERAGE_UUID
    }
  });

  return res;
};

const deleteEmailById = async (data) => {
  const res = await http.post('/corretor/remove-email-estipulante', data, {
    headers: {
      estipulante: data.id_estipulante,
      corretora: BROKERAGE_UUID
    }
  });

  return res;
};

// AddSubestipulante-atualizacao
const createSubByPolicyOwnerUUID = async (companyUUID, data) => {
  const res = await http.post('/corretor/add-subestipulante', data, {
    headers: {
      estipulante: companyUUID,
      corretora: BROKERAGE_UUID
    }
  });

  return res;
};

const getRevenueService = async (cnpj) => {
  const res = await http.get('/receita', {
    headers: {
      cnpj: cnpj
    }
  });

  return res;
};

const getRevenueServiceCNPJ = async (cnpjNumbers) => {
  const res = await http.get('/receita/cnpj', {
    headers: {
      cnpj: cnpjNumbers
    }
  });

  return res;
};

const updateInformationSettings = async (data) => {
  const res = await http.put('/corretor/estipulantes/campos-inclusao', data);

  return res;
};

const updateProposalInformationSetting = async (data) => {
  const res = await http.put(
    '/corretor/estipulantes/campos-inclusao/ficha-proposta',
    data
  );

  return res;
};

const updateCustomInformationSetting = async (data) => {
  const res = await http.put(
    '/corretor/estipulantes/campos-inclusao/campos-personalizados',
    data
  );

  return res;
};

const deleteContractProposal = async (contractCompanyId) => {
  const res = await http.delete(
    `/corretor/estipulantes/campos-inclusao/ficha-proposta/${contractCompanyId}`
  );

  return res;
};

const modules = {
  getAll,
  getAllSubs,
  getPolicyOwnerAndSub,
  getAllPolicyOwnersAndSubs,
  getPolicyOwnersList,
  getPolicyOwners,
  getPolicyOwnerSelected,
  getPotencialLeads,
  createPolicyOwner,
  deletePolicyOwnerSelected,
  getById,
  createManagersByUUID,
  deleteManagerByUUID,
  updateManagerByUUID,
  createLogoByUUID,
  updateById,
  deleteEmailById,
  createSubByPolicyOwnerUUID,
  getRevenueService,
  getRevenueServiceCNPJ,
  updateInformationSettings,
  updateProposalInformationSetting,
  updateCustomInformationSetting,
  deleteContractProposal
};

export default modules;
