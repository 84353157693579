import styled from "styled-components";
import colors from "apps/broker/styles/colors";

export const Wrapper = styled.div`
  display: flex;

  .animeLeft {
    opacity: 0;
    transform: translateX(-20px);
    animation: animeLeft 0.3s forwards;
  }
  @keyframes animeLeft {
    to {
      opacity: 1;
      transform: initial;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100.06vh;
`;

export const Main = styled.main`
  display: flex;
  justify-content: center;
  width: calc(100% - 90px);
  height: 100%;
  background: ${colors.genoaLightBackgroundGrey};
  padding: 45px 45px 25px 45px;
`;
