import styled from "styled-components";
import media from "styled-media-query";
import colors from "apps/broker/styles/colors";

export const Wrapper = styled.div`
  width: 700px;
  box-sizing: content-box;
  min-height: 100.1vh;
  padding-bottom: 200px;

  header {
    background-color: ${colors.genoaBlue};
    padding: 15px;
    padding-left: 60px;
    padding-right: 60px;
    height: 120px;

    .header-grid {
      display: grid;
      grid-template-columns: 410px 1fr;
    }

    .title-container {
      margin-top: 18px;
      font-family: "Avenir Next GEO W05 Regular";
      color: ${colors.white};
      font-size: 18px;

      display: flex;
      flex-direction: column;
      row-gap: 10px;

      .title-info {
        font-size: 20px;
        font-family: "Avenir Next GEO W05 Heavy";
        line-height: 25px;
      }
    }

    .icon-container {
      svg {
        scale: 0.75;
        position: absolute;
        transform: translateY(-25px) translateX(25px);
      }
    }
  }

  section {
    padding: 20px;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 35px;
    display: flex;
    flex-direction: column;
    row-gap: 15px;

    .section-title {
      font-size: 13px;
      font-family: "Avenir Next GEO W05 Bold";
    }

    .demandas-lista {
      display: flex;
      flex-direction: column;
      padding-top: 15px;
      row-gap: 12px;
    }

    .movimentacao-container {
    }
  }
`;
