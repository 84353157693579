import React from "react";
import SelectStandard from "apps/broker/components/Select";
import { AbaTipoBeneficiarioWrapper } from "./styles-abaTipoBeneficiario";

const AbaTipoBeneficiario = (
  { values,
    setState,
    setInputValue,
    setInputFile,
    setSelectValue,
    setNameFilter,
    setIncluirSaude,
    setIncluirOdonto,
    camposInclusao
  }) => {
  const optionsBeneficiario = [
    { option: "Titular", value: "Titular" },
    { option: "Dependente(s)", value: "Dependentes" },
  ]

  return (
    <AbaTipoBeneficiarioWrapper>
      <div className="aba-container">
        <div className="aba-input-container">
          <div className="aba-title-container">
            <span>Tipo de Beneficiário</span>
          </div>
        </div>
        <div className="aba-input-container">
          <div className="aba-input-row-select">
            <SelectStandard
              value={values['tipoBeneficiario']}
              setValue={setSelectValue}
              label={"Deseja fazer a inclusão de:"}
              name="tipoBeneficiario"
              options={optionsBeneficiario}
              posthogSelectClickHandlerEvent={'Clicou em Select p/ mudar "Tipo de Beneficiário"'}
              posthogOptionClickHandlerEvent={'Clicou na opção de "Tipo de Beneficiário"'}
            />
          </div>
        </div>
      </div>
    </AbaTipoBeneficiarioWrapper>
  )
}

export default AbaTipoBeneficiario;
