import { useState } from 'react';
import { HiTrash } from 'react-icons/hi';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './style.scss';
import Button from 'components/DS/Button';
import InputUploadFile from 'components/DS/InputUploadFile';

const toolbar = {
  options: ["inline", "blockType", "fontSize", "list", "textAlign", "link"],
  inline: { inDropdown: true },
  list: { inDropdown: true },
  textAlign: { inDropdown: true },
  link: { inDropdown: true },
  history: { inDropdown: true },
};

const TextEditor = ({ handleSendMessage, editorState, setEditorState, files, setFiles }) => {
  const [openEdit, setOpenEdit] = useState(false);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setOpenEdit(!openEdit);
  };

  const handleFile = (target) => {
    if (target.files?.length === 0 || target.files === null) {
      return;
    }
    if (target.files.length > 1) {
      const newFiles = [];
      Array.from(target.files).forEach((file) => {
        newFiles.push(file);
      });
      const oldFiles = files;
      setFiles([...oldFiles, ...newFiles]);
    } else {
      const oldFiles = files;
      setFiles([...oldFiles, target.files[0]]);
    }
  };

  const deleteFile = (index) => {
    const newArray = files;
    newArray.splice(index, 1);
    if (newArray.length === 0) setFiles([]);
    setFiles([...newArray]);
  };

  return (
    <div className="c-text-editor">
      <Editor
        toolbar={toolbar}
        editorState={editorState}
        wrapperClassName="c-editor"
        editorClassName="c-editor__textarea"
        onEditorStateChange={onEditorStateChange}
      />
      {files.length > 0
        ? files.map((file, index) => {
            return (
              <div className="c-text-editor-file" key={index}>
                <span>{file?.name}</span>
                <Button
                  className="c-text-editor-remove__button"
                  variant="transparent"
                  onClick={() => deleteFile(index)}
                >
                  <HiTrash />
                </Button>
              </div>
            );
          })
        : null}

      <div className="c-text-editor__button">
        <Button
          onClick={(e) => {
            handleSendMessage();
            handleEdit(e);
          }}
        >
          Enviar
        </Button>

        <InputUploadFile
          label={'+ Documentos'}
          onChange={({ target }) => handleFile(target)}
          multiple
        />
      </div>
    </div>
  );
};

export default TextEditor;
