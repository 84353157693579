import React from "react";
import { useMemo } from "react";
import InputFileCustom from "components/Input/InputFileCustom";
import InputFileStandard from "components/Input/InputFileStandard";
import { ScanDocumentosAdicionaisWrapper } from "./styles-ScanDocumentosAdicionais";
import colors from "styles/colors";
import { Checkbox, ThemeProvider, createTheme } from "@mui/material";
import { AiOutlineUpload } from "react-icons/ai";
import CloseIcon from "@material-ui/icons/Close";
import { ImCross } from "react-icons/im";

const theme = createTheme({
  palette: {
    genoaBlue: {
      main: colors.genoaBlue,
    },
    darkGenoaBlue: {
      main: colors.genoaDarkBlue,
    },
    action: {
      disabled: colors.genoaDisabledGrey
    }
  },
});

const ScanDocumentosAdicionais = (
  {
    beneficiarioPage,
    values,
    beneficiariosAdicionais,
    setInputValue,
    setInputFile,
    setSelectValue,
    camposInclusao,
    setCheckboxValue,
    filesAdicionais,
    handleFileAdicionais,
    deleteFileAdicionais
  }) => {

  const beneficiarioAdicional = useMemo(() => {
    return beneficiariosAdicionais[beneficiarioPage]
  }, [beneficiariosAdicionais, beneficiarioPage])

  return (
    <ScanDocumentosAdicionaisWrapper>
      <div className="aba-container">
        <div className="aba-title-container">
          <span>Cópia de Documentos</span>
        </div>
        <div className="documentos_content_container">
          <div className="coluna_lista">
            <div className="lista_container">

              <ThemeProvider theme={theme}>
                <div className="documento_option">
                  <Checkbox
                    size="small"
                    checked={beneficiarioAdicional["documento_comprovacao_vinculo"]}
                    onChange={setCheckboxValue}
                    name="documento_comprovacao_vinculo"
                    sx={{
                      p: 0
                    }}
                    color={"darkGenoaBlue"}
                  />
                  <label>
                    Comprovante de vínculo
                  </label>
                </div>
              </ThemeProvider>

              <ThemeProvider theme={theme}>
                <div className="documento_option">
                  <Checkbox
                    size="small"
                    checked={beneficiarioAdicional["documento_scan_rg"]}
                    onChange={setCheckboxValue}
                    name="documento_scan_rg"
                    sx={{
                      p: 0
                    }}
                    color={"darkGenoaBlue"}
                  />
                  <label>
                    RG
                  </label>
                </div>
              </ThemeProvider>

              <ThemeProvider theme={theme}>
                <div className="documento_option">
                  <Checkbox
                    size="small"
                    checked={beneficiarioAdicional["documento_scan_cpf"]}
                    onChange={setCheckboxValue}
                    name="documento_scan_cpf"
                    sx={{
                      p: 0
                    }}
                    color={"darkGenoaBlue"}
                  />
                  <label>
                    CPF
                  </label>
                </div>
              </ThemeProvider>
            </div>
            <label className="input-label">
              <span>
                Adicionar documento
                <AiOutlineUpload />
              </span>
              <input
                className="input-file"
                type="file"
                onChange={({ target }) => handleFileAdicionais(target)}
                // onClick={() => posthog?.capture('Clicou em "+ Documentos"')}
                multiple
              />
            </label>
          </div>
          <div className="coluna_documentos">
            <div className="coluna_documentos__title">
              <span>Documentos anexados:</span>

            </div>
            {
              filesAdicionais[beneficiarioPage]?.map((item, index) => (
                <div className="documento_file_option" key={index}>
                  <span>{item?.name}</span>
                  <button>
                    <CloseIcon
                      onClick={() => deleteFileAdicionais(index)}
                      className="remove_icon"
                    />
                  </button>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </ScanDocumentosAdicionaisWrapper>
  )
}

export default ScanDocumentosAdicionais;
