import React from "react";
import { AiOutlineDownload } from "react-icons/ai";
import InputFileCustom from "components/Input/InputFileCustom";
import InputFileStandard from "components/Input/InputFileStandard";
import { AbaFichaPropostaWrapper } from "./styles-abaFichaProposta";
import { usePostHog } from 'posthog-js/react';

const AbaFichaProposta = (
  { values,
    setInputValue,
    setInputFile,
    setSelectValue,
    camposInclusao,
    incluirSaude,
    incluirOdonto,
    contratoEstipulanteSaudeInfo,
    contratoEstipulanteOdontoInfo

  }) => {
  const posthog = usePostHog();

  const downloadFichaPropostaSaudeClickHandler = () => {
    posthog?.capture('Clicou em "Download Ficha Proposta Saúde"')
    const { ficha_proposta } = contratoEstipulanteSaudeInfo
    window.open(ficha_proposta.file_link, '_blank')
  }

  const downloadFichaPropostaOdontoClickHandler = () => {
    posthog?.capture('Clicou em "Download Ficha Proposta Odonto"')
    const { ficha_proposta } = contratoEstipulanteOdontoInfo
    window.open(ficha_proposta.file_link, '_blank')
  }

  // console.log(incluirSaude, incluirOdonto)

  return (
    <AbaFichaPropostaWrapper>
      <div className="aba-container">
        <div className="aba-title-container">
          <span>8. Ficha Proposta</span>
        </div>
        <div className="aba-input-container">
          {
            incluirSaude && contratoEstipulanteSaudeInfo?.ficha_proposta?.file_link ? (
              <div className="aba-input-row-cargo">
                {
                  <div className="title-container">
                    <button
                      className="title"
                      onClick={downloadFichaPropostaSaudeClickHandler}
                    >
                      <span>Download de Ficha Proposta Saúde</span>
                      <AiOutlineDownload size={18} />
                    </button>
                  </div>
                }

                {
                  camposInclusao['ficha_proposta'] ? (
                    <InputFileCustom
                      value={values['ficha_proposta_saude']}
                      setValue={setInputFile}
                      label={"Inserir Ficha Proposta Saúde"}
                      name="ficha_proposta_saude"
                      posthogInputFileClickHandlerEvent={'Clicou em Input de "Ficha Proposta Saúde"'}
                      posthogInputFileResetHandlerEvent={'Clicou p/ resetar "Ficha Proposta Saúde"'}
                    />
                  ) : ""
                }
              </div>
            ) : ""
          }
          {
            incluirOdonto && contratoEstipulanteOdontoInfo?.ficha_proposta?.file_link ? (
              <div className="aba-input-row-cargo">
                {
                  <div className="title-container">
                    <button
                      className="title"
                      onClick={downloadFichaPropostaOdontoClickHandler}
                    >
                      <span>Download de Ficha Proposta Odonto</span>
                      <AiOutlineDownload size={18} />
                    </button>
                  </div>
                }

                {
                  camposInclusao['ficha_proposta'] ? (
                    <InputFileCustom
                      value={values['ficha_proposta_odonto']}
                      setValue={setInputFile}
                      label={"Inserir Ficha Proposta Odonto"}
                      name="ficha_proposta_odonto"
                      posthogInputFileClickHandlerEvent={'Clicou em Input de "Ficha Proposta Odonto"'}
                      posthogInputFileResetHandlerEvent={'Clicou p/ resetar "Ficha Proposta Odonto"'}
                    />
                  ) : ""
                }
              </div>
            ) : ""
          }

        </div>
      </div>
    </AbaFichaPropostaWrapper>
  )
}

export default AbaFichaProposta;
