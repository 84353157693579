import http, { BROKERAGE_UUID } from 'services/http';

// components/ DrawerEstipulante
const createByContractIdref = async (contratoIdref, formData) => {
  const res = await http.post('/corretor/add-beneficiarios', formData, {
    headers: {
      contrato: contratoIdref,
      corretora: BROKERAGE_UUID
    }
  });

  return res;
};

// Beneficiarios/ DrawerAddBeneficiaries
const createByCompanyInfo = async (data) => {
  const res = await http.post('/corretor/add-beneficiario', data);

  return res;
};

const createContractExclusionByMovInfo = async (data) => {
  const res = await http.post(
    '/corretor/movimentacao-beneficiario/excluir-contrato',
    data
  );

  return res;
};

const updatePersonalInformation = async (data) => {
  const res = await http.put('/corretor/estipulante-sub/beneficiarios', data);

  return res;
};

const updatePlanByBeneficiaryInfo = async (data) => {
  const res = await http.put('/corretor/beneficiario/inclusao', data);

  return res;
};

const getTitularByCompanyId = async (params) => {
  const { estipulante_id, contrato_id, plano } = params;
  const res = await http.get(
    `/corretor/beneficiarios-estipulante/${estipulante_id}/${contrato_id}/${plano}`
  );

  return res;
};

const getSubCompaniesAndBeneficiariesByCompanyId = async (companyId) => {
  const res = await http.get(
    `/corretor/estipulante-sub/beneficiarios/${companyId}`
  );

  return res;
};

const getBeneficiaryFamilyGroupByBeneficiaryId = async (beneficiaryId) => {
  const res = await http.get(`/corretor/beneficiario/${beneficiaryId}`);

  return res;
};

const getPersonalInformationByBeneficiaryId = async (
  beneficiaryType,
  beneficiaryId
) => {
  const res = await http.get(
    `/corretor/beneficiario/personalInformation/${beneficiaryType}/${beneficiaryId}`
  );

  return res;
};

const getBeneficiaryAndDependentsContracts = async (beneficiario_id, tipo) => {
  const res = await http.get(
    `/corretor/beneficiarios-estipulante/${beneficiario_id}/${tipo}`
  );

  return res;
};

const deleteBeneficiariesSelected = async (beneficiariesId) => {
  const res = await http.delete('/corretor/beneficiarios', {
    data: {
      beneficiarios: beneficiariesId
    }
  });

  return res;
};

const modules = {
  createByContractIdref,
  createByCompanyInfo,
  createContractExclusionByMovInfo,
  updatePersonalInformation,
  updatePlanByBeneficiaryInfo,
  getTitularByCompanyId,
  getSubCompaniesAndBeneficiariesByCompanyId,
  getBeneficiaryFamilyGroupByBeneficiaryId,
  getPersonalInformationByBeneficiaryId,
  getBeneficiaryAndDependentsContracts,
  deleteBeneficiariesSelected
};

export default modules;
