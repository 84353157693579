import { useState, useEffect } from 'react';
import { RelatorioMovimentacoesAsideMenu } from './styles-AsideMenu';
import AsideUserIcon from './components/Icons/AsideUserIcon';

const AsideMenuMovimentacoes = ({
  relatorioSelecionado,
  setRelatorioSelecionado,
  servicesOpenedCount,
  servicesClosedCount,
  estipulanteSelected,
  servicesOpenedCountEstipulante,
  servicesClosedCountEstipulante,
  tableEstipulanteValue,
  tableSeguradora,
  movimentacoesConcluidas,
  movimentacoesSeguradora,
  tempoMedio,
  tempoMedioSeguradora,
  hasInclusao,
  hasExclusao,
  hasRh,
  hasCorretora
}) => {
  const [inclusaoAbertas, setInclusaoAbertas] = useState(0);
  const [exclusaoAbertas, setExclusaoAbertas] = useState(0);
  const [totalAbertas, setTotalAbertas] = useState(0);

  const [inclusaoConcluidas, setInclusaoConcluidas] = useState(0);
  const [exclusaoConcluidas, setExclusaoConcluidas] = useState(0);
  const [totalConcluidas, setTotalConcluidas] = useState(0);

  const [tempoInclusao, setTempoInclusao] = useState(0);
  const [tempoExclusao, setTempoExclusao] = useState(0);
  const [tempoTotal, setTempoTotal] = useState(0);

  // console.log({
  //   inclusaoAbertas,
  //   exclusaoAbertas,
  //   totalAbertas,
  //   inclusaoConcluidas,
  //   exclusaoConcluidas,
  //   totalConcluidas,
  //   tempoInclusao,
  //   tempoExclusao,
  //   tempoTotal
  // })

  const reduceMovimentacoesAbertas = () => {
    let inclusao = 0;
    let exclusao = 0;
    let total = 0;

    for (let index in tableEstipulanteValue) {
      if (hasCorretora === 'active') {
        if (hasInclusao === 'active') {
          inclusao += tableEstipulanteValue[index]['Corretora']['Inclusão'];
        }
        if (hasExclusao === 'active') {
          exclusao += tableEstipulanteValue[index]['Corretora']['Exclusão'];
        }
      }
      if (hasRh === 'active') {
        if (hasInclusao === 'active') {
          inclusao += tableEstipulanteValue[index]['Rh']['Inclusão'];
        }
        if (hasExclusao === 'active') {
          exclusao += tableEstipulanteValue[index]['Rh']['Exclusão'];
        }
      }
    }

    total = inclusao + exclusao;

    setInclusaoAbertas(inclusao);
    setExclusaoAbertas(exclusao);
    setTotalAbertas(total);
  };

  const reduceMovimentacoesConcluidas = () => {
    let inclusao = 0;
    let exclusao = 0;
    let total = 0;

    for (let index in movimentacoesConcluidas) {
      if (hasCorretora === 'active') {
        if (hasInclusao === 'active') {
          inclusao += movimentacoesConcluidas[index]['Corretora']['Inclusão'];
        }
        if (hasExclusao === 'active') {
          exclusao += movimentacoesConcluidas[index]['Corretora']['Exclusão'];
        }
      }
      if (hasRh === 'active') {
        if (hasInclusao === 'active') {
          inclusao += movimentacoesConcluidas[index]['Rh']['Inclusão'];
        }
        if (hasExclusao === 'active') {
          exclusao += movimentacoesConcluidas[index]['Rh']['Exclusão'];
        }
      }
    }

    total = inclusao + exclusao;

    setInclusaoConcluidas(inclusao);
    setExclusaoConcluidas(exclusao);
    setTotalConcluidas(total);
  };

  const reduceTempoMovimentacoes = () => {
    let inclusao = 0;
    let exclusao = 0;
    let total = 0;

    let tempoTotalInclusao = 0;
    let tempoTotalExclusao = 0;
    let countTotalInclusao = 0;
    let countTotalExclusao = 0;

    for (let index in tempoMedio) {
      if (hasCorretora === 'active') {
        if (hasInclusao === 'active') {
          if (tempoMedio[index]['Corretora']['Inclusão']['count'] !== 0) {
            const tempoInclusaoCorretora =
              tempoMedio[index]['Corretora']['Inclusão']['tempo_total'] /
              1000 /
              60 /
              60 /
              24;
            tempoTotalInclusao += tempoInclusaoCorretora;
            countTotalInclusao +=
              tempoMedio[index]['Corretora']['Inclusão']['count'];
          }
        }
        if (hasExclusao === 'active') {
          if (tempoMedio[index]['Corretora']['Exclusão']['count'] !== 0) {
            const tempoExclusaoCorretora =
              tempoMedio[index]['Corretora']['Exclusão']['tempo_total'] /
              1000 /
              60 /
              60 /
              24;
            tempoTotalExclusao += tempoExclusaoCorretora;
            countTotalExclusao +=
              tempoMedio[index]['Corretora']['Exclusão']['count'];
          }
        }
      }
      if (hasRh === 'active') {
        if (hasInclusao === 'active') {
          if (tempoMedio[index]['Rh']['Inclusão']['count'] !== 0) {
            const tempoInclusaoRh =
              tempoMedio[index]['Rh']['Inclusão']['tempo_total'] /
              1000 /
              60 /
              60 /
              24;
            tempoTotalInclusao += tempoInclusaoRh;
            countTotalInclusao += tempoMedio[index]['Rh']['Inclusão']['count'];
          }
        }
        if (hasExclusao === 'active') {
          if (tempoMedio[index]['Rh']['Exclusão']['count'] !== 0) {
            const tempoExclusaoRh =
              tempoMedio[index]['Rh']['Exclusão']['tempo_total'] /
              1000 /
              60 /
              60 /
              24;
            tempoTotalExclusao += tempoExclusaoRh;
            countTotalExclusao += tempoMedio[index]['Rh']['Exclusão']['count'];
          }
        }
      }
    }

    let mediaTempoTotalInclusao = 0;

    if (countTotalInclusao !== 0) {
      mediaTempoTotalInclusao = tempoTotalInclusao / countTotalInclusao;
    }

    let mediaTempoExclusao = 0;

    if (countTotalExclusao !== 0) {
      mediaTempoExclusao = tempoTotalExclusao / countTotalExclusao;
    }

    inclusao = Math.ceil(mediaTempoTotalInclusao, 0);
    exclusao = Math.ceil(mediaTempoExclusao, 0);

    let mediaTotal = 0;
    if (countTotalInclusao !== 0 || countTotalExclusao !== 0) {
      mediaTotal =
        (tempoTotalInclusao + tempoTotalExclusao) /
        (countTotalInclusao + countTotalExclusao);
    }

    total = Math.ceil(mediaTotal, 0);

    setTempoInclusao(inclusao);
    setTempoExclusao(exclusao);
    setTempoTotal(total);
  };

  useEffect(() => {
    reduceMovimentacoesAbertas();
    reduceMovimentacoesConcluidas();
    reduceTempoMovimentacoes();
  }, [
    hasInclusao,
    hasExclusao,
    hasRh,
    hasCorretora,
    tableEstipulanteValue,
    movimentacoesConcluidas,
    tempoMedio
  ]);

  return (
    <RelatorioMovimentacoesAsideMenu>
      <div
        className={`menu_aside__option ${
          relatorioSelecionado === 'movimentacoes abertas' ? 'selected' : ''
        }`}
        onClick={() => setRelatorioSelecionado('movimentacoes abertas')}
      >
        <div className="option__info">
          <div className="option__quantity">
            <span>{totalAbertas}</span>
            <div className="misc_container">
              {hasInclusao === 'active' && hasExclusao === 'active' ? (
                <div className="misc_value">
                  <AsideUserIcon
                    tipo={'Inclusão'}
                    selected={relatorioSelecionado === 'movimentacoes abertas'}
                  />
                  <span>{inclusaoAbertas}</span>
                </div>
              ) : (
                ''
              )}
              {hasInclusao === 'active' && hasExclusao === 'active' ? (
                <div className="misc_value">
                  <AsideUserIcon
                    tipo={'Exclusão'}
                    selected={relatorioSelecionado === 'movimentacoes abertas'}
                  />
                  <span>{exclusaoAbertas}</span>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="option__unity">
            <span></span>
          </div>
        </div>
        <div className="option__title">Movimentações Abertas</div>
      </div>

      <div
        className={`menu_aside__option ${
          relatorioSelecionado === 'movimentacoes concluidas' ? 'selected' : ''
        }`}
        onClick={() => setRelatorioSelecionado('movimentacoes concluidas')}
      >
        <div className="option__info">
          <div className="option__quantity">
            <span>{totalConcluidas}</span>
            <div className="misc_container">
              {hasInclusao === 'active' && hasExclusao === 'active' ? (
                <div className="misc_value">
                  <AsideUserIcon
                    tipo={'Inclusão'}
                    selected={
                      relatorioSelecionado === 'movimentacoes concluidas'
                    }
                  />
                  <span>{inclusaoConcluidas}</span>
                </div>
              ) : (
                ''
              )}
              {hasInclusao === 'active' && hasExclusao === 'active' ? (
                <div className="misc_value">
                  <AsideUserIcon
                    tipo={'Exclusão'}
                    selected={
                      relatorioSelecionado === 'movimentacoes concluidas'
                    }
                  />
                  <span>{exclusaoConcluidas}</span>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="option__unity">
            <span></span>
          </div>
        </div>
        <div className="option__title">Movimentações concluídas</div>
      </div>

      <div
        className={`menu_aside__option ${
          relatorioSelecionado === 'tempo de resolucao' ? 'selected' : ''
        }`}
        onClick={() => setRelatorioSelecionado('tempo de resolucao')}
      >
        <div className="option__info">
          <div className="option__quantity">
            <div>
              <span>{tempoTotal}</span>
              <span className="total_dias">
                {tempoTotal > 1 ? 'dias' : 'dia'}
              </span>
            </div>
            <div className="misc_container">
              {hasInclusao === 'active' && hasExclusao === 'active' ? (
                <div className="misc_value">
                  <AsideUserIcon
                    tipo={'Inclusão'}
                    selected={relatorioSelecionado === 'tempo de resolucao'}
                  />
                  <span>{tempoInclusao}</span>
                </div>
              ) : (
                ''
              )}
              {hasInclusao === 'active' && hasExclusao === 'active' ? (
                <div className="misc_value">
                  <AsideUserIcon
                    tipo={'Exclusão'}
                    selected={relatorioSelecionado === 'tempo de resolucao'}
                  />
                  <span>{tempoExclusao}</span>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="option__title">Média de tempo de resolução</div>
      </div>
    </RelatorioMovimentacoesAsideMenu>
  );
};

export default AsideMenuMovimentacoes;
