export const typeColumns = {
  'Saúde': [
    { key: 'cnpj', style: { alignment: { vertical: 'middle', horizontal: 'center', height: 25 } }, width: 30 },
    { key: 'nome', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'email', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'telefone_celular', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'data_nascimento', style: { numFmt: 'dd"/"mm"/"yyyy', alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'rg', style: { numFmt: '00"."000"."000"-"00', alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'orgao_emissor_rg', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'data_expedicao_rg', style: { numFmt: 'dd"/"mm"/"yyyy', alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'sexo', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'cpf', style: { numFmt: '000"."000"."000"-"00', alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'cpf_titular', style: { numFmt: '000"."000"."000"-"00', alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 40 },
    { key: 'grau_parentesco', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'estado_civil', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'nacionalidade', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'pis_pasep', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'nome_mae', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'cep', style: { numFmt: '00000"-"000', alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'endereco', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'numero_endereco', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'complemento_endereco', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'bairro', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'uf', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'cidade', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'cargo', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'data_admissao', style: { numFmt: 'dd"/"mm"/"yyyy', alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'tipo_contrato', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'matricula_funcional', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'plano', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'data_contratacao', style: { numFmt: 'dd"/"mm"/"yyyy', alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 60 },
    { key: 'agencia', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'conta', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'digito_conta', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'banco', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'altura', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'peso', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 }
  ],
  'Odonto': [
    { key: 'cnpj', style: { alignment: { vertical: 'middle', horizontal: 'center', height: 25 } }, width: 30 },
    { key: 'nome', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'email', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'telefone_celular', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'data_nascimento', style: { numFmt: 'dd"/"mm"/"yyyy', alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'rg', style: { numFmt: '00"."000"."000"-"00', alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'orgao_emissor_rg', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'data_expedicao_rg', style: { numFmt: 'dd"/"mm"/"yyyy', alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'sexo', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'cpf', style: { numFmt: '000"."000"."000"-"00', alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'cpf_titular', style: { numFmt: '000"."000"."000"-"00', alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 40 },
    { key: 'grau_parentesco', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'estado_civil', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'nacionalidade', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'pis_pasep', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'nome_mae', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'cep', style: { numFmt: '00000"-"000', alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'endereco', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'numero_endereco', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'complemento_endereco', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'bairro', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'uf', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'cidade', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'cargo', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'data_admissao', style: { numFmt: 'dd"/"mm"/"yyyy', alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'tipo_contrato', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'matricula_funcional', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'plano', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'data_contratacao', style: { numFmt: 'dd"/"mm"/"yyyy', alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 60 },
    { key: 'agencia', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'conta', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'digito_conta', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'banco', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'altura', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 },
    { key: 'peso', style: { alignment: { vertical: 'middle', horizontal: 'center' } }, height: 25, width: 30 }
  ]
};

export const arrayColumns = {
  'saude': [
    { key: 'cnpj', column: 'CNPJ do estipulante' },
    { key: 'nome', column: 'Nome' },
    { key: 'email', column: 'Email' },
    { key: 'telefone_celular', column: 'Telefone' },
    { key: 'data_nascimento', column: 'Data de nascimento' },
    { key: 'rg', column: 'RG' },
    { key: 'orgao_emissor_rg', column: 'Orgão emissor do RG' },
    { key: 'data_expedicao_rg', column: 'Data de expedição do RG' },
    { key: 'sexo', column: 'Sexo'},
    { key: 'cpf', column: 'CPF' },
    { key: 'cpf_titular', column: 'Se dependente CPF do titular' },
    { key: 'grau_parentesco', column: 'Grau parentesco' },
    { key: 'estado_civil', column: 'Estado civil' },
    { key: 'nacionalidade', column: 'Nacionalidade' },
    { key: 'pis_pasep', column: 'PIS/Pasep' },
    { key: 'nome_mae', column: 'Nome da mãe' },
    { key: 'cep', column: 'CEP' },
    { key: 'endereco', column: 'Endereço' },
    { key: 'numero_endereco', column: 'Número' },
    { key: 'complemento_endereco', column: 'Complemento' },
    { key: 'bairro', column: 'Bairro' },
    { key: 'uf', column: 'UF' },
    { key: 'cidade', column: 'Cidade' },
    { key: 'cargo', column: 'Cargo' },
    { key: 'data_admissao', column: 'Data de admissão' },
    { key: 'tipo_contrato', column: 'Tipo de contrato' },
    { key: 'matricula_funcional', column: 'Matrícula' },
    { key: 'plano', column: 'Plano Saúde' },
    { key: 'data_contratacao', column: 'Data de contratação do plano' },
    { key: 'agencia', column: 'Agência'},
    { key: 'conta', column: 'Conta' },
    { key: 'digito_conta', column: 'Digito' },
    { key: 'banco', column: 'Banco' },
    { key: 'altura', column: 'Altura(m)' },
    { key: 'peso', column: 'Peso(Kg)' }
  ],
  'odonto': [
    { key: 'cnpj', column: 'CNPJ do estipulante' },
    { key: 'nome', column: 'Nome' },
    { key: 'email', column: 'Email' },
    { key: 'telefone_celular', column: 'Telefone' },
    { key: 'data_nascimento', column: 'Data de nascimento' },
    { key: 'rg', column: 'RG' },
    { key: 'orgao_emissor_rg', column: 'Orgão emissor do RG' },
    { key: 'data_expedicao_rg', column: 'Data de expedição do RG' },
    { key: 'sexo', column: 'Sexo' },
    { key: 'cpf', column: 'CPF' },
    { key: 'cpf_titular', column: 'Se dependente CPF do titular' },
    { key: 'grau_parentesco', column: 'Grau parentesco' },
    { key: 'estado_civil', column: 'Estado civil' },
    { key: 'nacionalidade', column: 'Nacionalidade' },
    { key: 'pis_pasep', column: 'PIS/Pasep' },
    { key: 'nome_mae', column: 'Nome da mãe' },
    { key: 'cep', column: 'CEP' },
    { key: 'endereco', column: 'Endereço' },
    { key: 'numero_endereco', column: 'Número' },
    { key: 'complemento_endereco', column: 'Complemento' },
    { key: 'bairro', column: 'Bairro' },
    { key: 'uf', column: 'UF' },
    { key: 'cidade', column: 'Cidade' },
    { key: 'cargo', column: 'Cargo' },
    { key: 'data_admissao', column: 'Data de admissão' },
    { key: 'tipo_contrato', column: 'Tipo de contrato' },
    { key: 'matricula_funcional', column: 'Matrícula' },
    { key: 'plano', column: 'Plano Odonto' },
    { key: 'data_contratacao', column: 'Data de contratação do plano' },
    { key: 'agencia', column: 'Agência' },
    { key: 'conta', column: 'Conta' },
    { key: 'digito_conta', column: 'Digito' },
    { key: 'banco', column: 'Banco' },
    { key: 'altura', column: 'Altura(m)' },
    { key: 'peso', column: 'Peso(Kg)' }
  ]
};
