import React from "react";
import { Wrapper } from "./styles-drawerAdicionarUsuario";

// import { useForm } from "react-hook-form";

import api from "services/api";
import { useSelector } from "react-redux";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { RiSendPlaneFill } from "react-icons/ri";
import ReactInputMask from "react-input-mask";

const DrawerAdicionarUsuario = (props) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [nome, setNome] = React.useState("");
  const [sobrenome, setSobrenome] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [telefone, setTelefone] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [cargo, setCargo] = React.useState("");
  const [showResponseMessage, setShowResponseMessage] = React.useState(false);
  const [showErrorMessage, setShowErrorMessage] = React.useState(false);
  const [responseMessage, setResponseMessage] = React.useState("");

  // const {
  //   register,
  //   handleSubmit,
  //   watch,
  //   formState: { errors },
  // } = useForm();


  const user = useSelector((state) => state.userReducers.user);

  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  }

  const requestNewUser = () => {
    const headers = {
      authorization: `Bearer ${user.token}`
    }
    const body = {
      masterUser: user,
      newUser: {
        nome,
        sobrenome,
        email,
        telefone,
        password,
        cargo
      }
    }


    if (!nome || !sobrenome || !email || !password || !cargo) {
      setShowErrorMessage(true);
      setResponseMessage("Por favor preencher todos os campos")
      setShowResponseMessage(true);
      setTimeout(() => {
        setShowResponseMessage(false)
        setShowErrorMessage(false);
        setResponseMessage("")
      }, 3500)

      return
    }

    api.post('/rh/gestao-usuarios/novo-usuario',
      body,
      { headers }
    ).then((resp) => {
      if (resp.data.status === 200) {
        props.getData()
      }
      if (resp.data.message) {
        setResponseMessage(resp.data.message);
        setShowErrorMessage(resp.data.status !== 200 ? true : false);
        setShowResponseMessage(true);
        setTimeout(() => {
          setShowResponseMessage(false)
          setShowErrorMessage(false);
          setResponseMessage("")
        }, 3500)
      }
    })
      .catch((e) => console.log(e))
  }

  const phoneMask = () => {
    // console.log(telefone.length)
    return telefone[5] !== "9" ?
      "(99) 9999 - 9999" : "(99) 99999 - 9999"
  }

  return (
    <Wrapper>
      <header>
        <div className="header-title-container">
          <span>Adicionar Usuário</span>
        </div>
      </header>
      <section className="infos">
        <form>
          <div className="input-container">
            <label>Nome</label>
            <input
              type={"text"}
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
          </div>
          <div className="input-container">
            <label>Sobrenome</label>
            <input
              type={"text"}
              value={sobrenome}
              onChange={(e) => setSobrenome(e.target.value)}
            />
          </div>
          <div className="input-container">
            <label>Email</label>
            <input
              type={"email"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="new-email"
            />
          </div>
          <div className="input-container">
            <label>Telefone</label>
            <ReactInputMask
              type={"text"}
              value={telefone}
              mask={phoneMask()}
              maskChar={null}
              onChange={(e) => setTelefone(e.target.value)}
              autoComplete="new-email"
            />
          </div>
          <div className="input-container">
            <label>Senha</label>
            <div className="input-password-container">
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="new-password"
              />
              {
                showPassword ? (
                  <AiFillEyeInvisible
                    className="show-icon"
                    onClick={toggleShowPassword}

                    size="19"
                  />
                ) : (
                  <AiFillEye
                    className="show-icon"
                    onClick={toggleShowPassword}

                    size="19"
                  />
                )
              }
            </div>
          </div>
          <div className="input-container">
            <label>Cargo</label>
            <select
              value={cargo}
              onChange={(e) => setCargo(e.target.value)}
            >
              <option disabled value="" style={{ fontWeight: "bold" }}>Selecione</option>
              <option value="Master" >Master</option>
              <option value="Usuário" >Usuário</option>
            </select>
          </div>
          <div
            className="send-btn"
            onClick={requestNewUser}
          >
            <span>Criar usuário</span>
            <RiSendPlaneFill
              className="send-icon"
              size={11}
            />
          </div>
          <div>
            {
              showResponseMessage ? (
                <span
                  className={showErrorMessage ? "error-message-span" : "confirmation-message-span"}
                >
                  {responseMessage}
                </span>
              ) : ""
            }
          </div>
        </form>
      </section>
    </Wrapper>
  );
};

export default DrawerAdicionarUsuario;
