import { Box, Popper } from "@material-ui/core";
import { ListComponent } from "./styles-dropDownListaBeneficiarios";
import PopUpListDocumentos from "./PopUpListaDocs";
import React from "react";

const ListaBeneficiariosOdonto = ({
  abaSelected,
  setNomeId,
  arrayJson,
  nameToButton,
  insertConcluido,
  buttonDisabled,
  handleSubmit,
  planilhaOdonto,
  campoInclusaoObj,
  files,
  setCheckboxValue,
  values,
  handleFile,
  deleteFile,
  saveChecklist
}) => {
  const [openAdicionarDoc, setOpenAdicionarDoc] = React.useState(undefined);
  const [selected, setSelected] = React.useState(false);
  const [infoBeneficiario, setInfoBeneficiario] = React.useState(undefined);
  // console.log('arrayJson', arrayJson)
  // console.log('insertConcluido odonto', insertConcluido)
  // console.log('values', values)
  // console.log('buttonDisabled', buttonDisabled)

  React.useMemo(() => {
    if (Boolean(openAdicionarDoc)) {
      const newSelected = arrayJson?.find((item) => openAdicionarDoc?.["CPF"] === item["CPF"]);

      if (newSelected) {
        setSelected(true);
        setInfoBeneficiario(newSelected);
      }
    } else {
      setOpenAdicionarDoc(undefined);
    }

  }, [openAdicionarDoc])

  return (
    <ListComponent>
      <Popper
        open={Boolean(arrayJson)}
        placement="bottom-start"
        disablePortal
        className="popper-container"
      >
        <Box>
          <ul className={arrayJson?.length > 0 ? "lista" : ""}>
            {
              planilhaOdonto?.[0]?.name ? (
                arrayJson?.map((beneficiario, index) => (
                  <li className="item_lista" key={index}>
                    <span style={{ fontWeight: 'bold' }}>{beneficiario["Se dependente CPF do titular"] ? "Dependente: " : "Titular: "}</span>
                    <span>{beneficiario["Nome"]}</span>
                    {/* Depois de concluída a inclusão deve aparecer Concluído! */}
                    {insertConcluido['odonto']?.[beneficiario["CPF"]] === beneficiario["CPF"] ? (
                      <span className="alerta_concluido">Concluído!</span>
                    ) : (
                      <>
                        <span className="btn_lista" onClick={() => {
                          setOpenAdicionarDoc(beneficiario)
                          setNomeId(beneficiario["Nome"])
                        }}>Adicionar documentos</span>
                        <button
                          className={
                            `btn_enviar ${nameToButton?.odonto?.[beneficiario["CPF"]] === beneficiario["CPF"]
                              && buttonDisabled ? '' : 'disabled'}`
                          }
                          type="button"
                          onClick={
                            nameToButton?.odonto?.[beneficiario["CPF"]] === beneficiario["CPF"]
                              && buttonDisabled ? () => {
                                handleSubmit(beneficiario)
                              } : () => { }
                          }
                        >
                          Enviar
                        </button>
                      </>
                    )}
                  </li>
                ))
              ) : null
            }
          </ul>
        </Box>

      </Popper>

      {
        abaSelected === 'odonto' ?
          selected ?
            <PopUpListDocumentos
              isSelected={infoBeneficiario}
              onClose={setSelected}
              setIsSelected={setOpenAdicionarDoc}
              campoInclusaoObj={campoInclusaoObj}
              files={files}
              setCheckboxValue={setCheckboxValue}
              values={values}
              handleFile={handleFile}
              deleteFile={deleteFile}
              saveChecklist={saveChecklist}
            />
            : null
          : null
      }
    </ListComponent>
  )
}

export default ListaBeneficiariosOdonto;
