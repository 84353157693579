import styled from 'styled-components';
import colors from 'styles/colors';

export const Wrapper = styled.div`
  min-width: 700px;
  max-width: 900px;
  box-sizing: content-box;

  header {
    background-color: ${colors.genoaBlue};
    padding: 20px;
    padding-left: 65px;
    padding-right: 65px;

    h2 {
      font-family: 'Avenir Next GEO W05 Bold';
      color: #fff;
      font-size: 35px;
      margin: 10px 0 50px 0;
    }

    .back {
      .icon-back {
        color: #fff;
        cursor: pointer;
        font-size: 2rem;
      }
    }
  }
`;

export const Form = styled.form`
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 65px;
  padding-right: 65px;
  min-height: 100vh;
  background-color: #f9f9f9;

  .form-container {
    height: 500px;

    .input-span-title {
      font-size: 0.9rem;
      padding-left: 10px;
      font-family: 'Avenir Next GEO W05 Demi';
      color: ${colors.genoaGrey};
    }

    .input-seguradoras-texto {
      font-family: 'Avenir Next GEO W05 Regular';
      margin-top: 10px;
      width: 75%;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 7px;
      padding-bottom: 7px;
      border: 1px solid ${colors.genoaBlue};
      border-radius: 20px;
      font-size: 0.8rem;
    }

    .seguradora-select-container {
      display: flex;
      flex-direction: column;

      .select-seguradora {
        margin-top: 10px;
        width: 75%;
        padding-left: 15px;
        padding-right: 15px;
        appearance: none;
        background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 9L0.73686 0.75L10.2631 0.75L5.5 9Z' fill='%23456AED'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position-x: 96%;
        background-position-y: 12px;
        padding-top: 7px;
        padding-bottom: 7px;
        border: 1px solid ${colors.genoaBlue};
        border-radius: 20px;
        font-size: 0.8rem;
        font-family: 'Avenir Next GEO W05 Regular';

        :focus-visible {
          outline: 1px ${colors.genoaBlue}f5 auto;
        }
        option {
          font-size: 0.8rem;
        }
      }
    }

    .seguradora-nome-contato-container {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
    }

    .seguradora-telefone-contato-container {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
    }

    .seguradora-email-contato-container {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
    }

    .seguradora-funcao-contato-container {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
    }

    .botao-adicionar-contato-container {
      display: grid;
      grid-template-columns: 200px 1fr;
      margin-top: 30px;

      .botao-adicionar-contato {
        width: 150px;
        font-size: 0.9rem;
        font-family: 'Avenir Next GEO W05 Demi';
        border-radius: 15px;
        padding: 0;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 3px;
        padding-bottom: 3px;
        text-transform: none;
        color: white;
        background-color: ${colors.genoaBlue};

        :hover {
          background-color: #2a52dd;
        }

        .loading-spinner {
          margin-top: 3px;
          margin-bottom: 3px;
          color: white;
        }

        .icone-caret-direita {
          margin-left: 5px;
        }
      }

      .mensagem-confirmacao-container {
        color: ${colors.genoaBlue};
        font-family: 'Avenir Next GEO W05 Demi';
        padding-top: 3px;
      }
    }

    .mensagem-erro-container {
      font-family: 'Avenir Next GEO W05 Demi';
      color: red;
      font-size: 0.8rem;
      margin-top: 20px;
      height: 16.8px;
    }
  }
`;
