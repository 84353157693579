import React from "react";
import { SideStripe } from "./styles-drawerAdicionarBeneficiarioRh";
import { AbaPlanos, Content, DrawerAdicionarPlanilhaWrapper } from "./styles-drawerAdicionarPlanilhaRh";
import { BiFolderOpen, BiX } from "react-icons/bi";
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import SelectStandard from "apps/broker/components/Select";
import { arrayColumns, typeColumns } from "./PlanilhaDeInclusaoDados";
import { useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import api from "services/api";
import * as xlsx from 'xlsx';
import ListaBeneficiariosSaude from "./DropDownListaBeneficiariosSaude";
import ListaBeneficiariosOdonto from "./DropDownListaBeneficiariosOdonto";
import toast, { Toaster } from "react-hot-toast";
import colors from "styles/colors";
import { AiFillWarning } from "react-icons/ai";
import Loading from "components/Loading";

const DrawerAdicionarPlanilhaRh = ({
  closeDrawer,
  contratosEstipulante,
  estipulanteAndSubs,
}) => {
  const user = useSelector(state => state.userReducers.user);
  // console.log('user rh', user)
  const { getValues, setValue, control } = useForm();

  const planilhaSaude = useWatch({
    control,
    name: 'planilha_saude',
  });

  const planilhaOdonto = useWatch({
    control,
    name: 'planilha_odonto',
  });

  const [abaSelected, setAbaSelected] = React.useState('saude');
  const [seguradoraNome, setSeguradoraNome] = React.useState('');
  const [optionsSeguradoras, setOptionsSeguradoras] = React.useState([]);
  const [typeColumnsFormatter, setTypeColumnsFormatter] = React.useState(undefined);
  const [arrayColumnsFormatter, setArrayColumnsFormatter] = React.useState(undefined);

  const [arrayJson, setArrayJson] = React.useState([{
    saude: [],
    odonto: [],
  }]);
  const [campoInclusaoObj, setCampoInclusaoObj] = React.useState({});
  const [files, setFiles] = React.useState([]);
  const [stateObj, setStateObj] = React.useState({});

  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const [nameToButton, setNameToButton] = React.useState({
    saude: {},
    odonto: {}
  });
  const [insertConcluido, setInsertConcluido] = React.useState({
    'saude': {},
    'odonto': {},
  });

  const [nomeId, setNomeId] = React.useState('');
  const [updateStatus, setUpdateStatus] = React.useState(null);
  const [loading, setLoading] = React.useState(false);


  // console.log('files', files)

  React.useEffect(() => {
    if (abaSelected === 'saude') {
      const options = contratosEstipulante?.saude?.map((item) => ({
        value: item.seguradora_nome,
        option: `${item.seguradora_nome} (Apólice: ${item.numero_apolice})`
      }))

      setOptionsSeguradoras(options)
      toast.dismiss();
      toast.error('Por favor, selecionar a seguradora.', {
        duration: 3000,
        icon: <AiFillWarning style={{ color: "yellow", fontSize: '18px' }} />
      });
    } else if (abaSelected === 'odonto') {
      const options = contratosEstipulante?.odonto?.map((item) => ({
        value: item.seguradora_nome,
        option: `${item.seguradora_nome} (Apólice: ${item.numero_apolice})`
      }))

      setOptionsSeguradoras(options)
      toast.dismiss();
      toast.error('Por favor, selecionar a seguradora.', {
        duration: 3000,
        icon: <AiFillWarning style={{ color: "yellow", fontSize: '18px' }} />
      });
    }
  }, [abaSelected]);

  const handleFile = (target, cpf) => {
    if (target.files?.length === 0 || target.files === null) {
      return;
    }

    if (target.files.length > 1) {
      const file = [];
      Array.from(target.files).forEach((file) => {
        file.push(file);
      });

      setFiles((prevState) => ([...prevState, { cpf, ...file }]));
    } else {
      const { files } = target;
      const file = files[0];
      setFiles((prevState) => ([...prevState, { cpf, file }]));
    }
  };

  const deleteFile = (index) => {
    const newArray = files;
    newArray.splice(index, 1);
    if (newArray.length === 0) {
      setFiles([]);
    } else {
      setFiles([...newArray]);
    }
  };


  const checkboxChangeHandler = ({ target }) => {
    const { name, checked } = target;

    setStateObj((prevState) => ({
      ...prevState,
      [nomeId]: {
        ...prevState[nomeId],
        [name]: checked,
      },
    }));
  };

  const saveChecklistDocumentosScanHandler = (infoBeneficiario, values) => {
    const contrato = contratosEstipulante[abaSelected]?.filter(item => item.seguradora_nome === seguradoraNome);

    if (contrato?.[0].contrato_estipulante_id) {
      const nome = infoBeneficiario["Nome"];
      const checklistDocs = values[nome];
      const isTitular = infoBeneficiario["Se dependente CPF do titular"] ? 0 : 1;
      const cpf = infoBeneficiario["CPF"];
      // console.log('contrato >>', contrato)
      // console.log('nome_beneficiario >>', infoBeneficiario, 'campos_scan >>', values, 'checklistDocs', checklistDocs)

      const body = {
        contrato_estipulante_id: contrato?.[0].contrato_estipulante_id,
        nome_beneficiario: nome,
        titular: isTitular,
        cpf: cpf,
        campos_scan_doc: checklistDocs,
        plano: abaSelected,
      };

      api.post('/rh/estipulante-sub/movimentacoes/planilha-inclusao/checklist', body)
        .then((resp) => {
          // console.log('resp >> checklist', resp.data)
          if (resp?.data.length >= 0) {
            verifyChecklistDocs(resp.data.contrato_estipulante_id);
          } else {
            verifyChecklistDocs(contrato?.[0].contrato_estipulante_id);
          }
        })
        .catch((err) => {
          console.log('err', err)
        })
    }
  }

  const verifyChecklistDocs = (contrato_estipulante_id) => {
    // console.log('data >> checklists by contrato', contrato_estipulante_id)

    api.get(`/rh/estipulante-sub/movimentacoes/planilha-inclusao/checklist/${contrato_estipulante_id}`)
      .then((resp) => {
        // console.log('resp get checklist', resp.data)

        if (abaSelected === 'saude') {
          verifyDocsSaude(resp.data);
        }

        if (abaSelected === 'odonto') {
          verifyDocsOdonto(resp.data);
        }
      })
      .catch((err) => {
        throw err;
      })
  }

  const camposVerificacao = (titular, camposInclusao) => {
    let allKeys = [];

    if (titular === 1) {
      camposInclusao?.forEach(value => {
        if (value === 'ficha_proposta') {
          allKeys.push(`ficha_proposta_${abaSelected}`)
        }
        if (value === 'scan_carta_inclusao') {
          allKeys.push('scan_carta_inclusao')
        }
        if (value === 'scan_carteira_trabalho') {
          allKeys.push('scan_carteira_trabalho')
        }
        if (value === 'documento_scan_rg') {
          allKeys.push('documento_scan_rg')
        }
        if (value === 'scan_comprovante_residencia') {
          allKeys.push('scan_comprovante_residencia')
        }
        if (value === 'scan_passaporte') {
          allKeys.push('scan_passaporte')
        }
        if (value === 'scan_carta_permanencia') {
          allKeys.push('scan_carta_permanencia')
        }
      });

      return allKeys;

    } else if (titular === 0) {
      camposInclusao?.forEach(value => {
        if (value === 'ficha_proposta') {
          allKeys.push(`ficha_proposta_${abaSelected}`)
        }
        if (value === 'scan_carta_inclusao') {
          allKeys.push('scan_carta_inclusao')
        }
        if (value === 'documento_scan_rg') {
          allKeys.push('documento_scan_rg')
        }
        if (value === 'scan_comprovante_residencia') {
          allKeys.push('scan_comprovante_residencia')
        }
        if (value === 'scan_passaporte') {
          allKeys.push('scan_passaporte')
        }
        if (value === 'scan_carta_permanencia') {
          allKeys.push('scan_carta_permanencia')
        }

        allKeys.push('documento_scan_cpf')
        allKeys.push('documento_comprovacao_vinculo')
      });

      return [...new Set(allKeys)];
    }
  };

  const verifyDocsSaude = (data) => {
    const camposInclusao = Object.entries(campoInclusaoObj).filter(([key, value]) => value === 1)
      .map(([key, value]) => key);
    const hasName = data?.filter((item, index) => item.nome_beneficiario === nomeId);

    const allKeys = camposVerificacao(hasName[0].titular, camposInclusao);

    if (hasName[0]?.titular === 1) {
      const cpf = hasName[0]?.cpf;

      const objDocs = JSON.parse(hasName[0]?.campos_scan_doc);
      const verifyCamposInseridos = Object.entries(objDocs).filter(([key, value]) => value === true)
        .map(([key, value]) => key);

      const verifyKeys = allKeys.length === verifyCamposInseridos.length
        && allKeys.every(key => verifyCamposInseridos.includes(key));
      console.log('verifyCamposInseridos saude', verifyCamposInseridos)
      console.log('allKeys saude', allKeys)
      console.log('verifyKeys saude', verifyKeys)
      setNameToButton((prevState) => {
        return {
          ...prevState,
          saude: {
            ...prevState.saude,
            [cpf]: cpf,
          },
        }
      });
      if (verifyKeys) {
        setButtonDisabled(true);
      } else {
        setButtonDisabled(false);
      }

    } else if (hasName[0]?.titular === 0) {
      const cpf = hasName[0]?.cpf;

      const objDocs = JSON.parse(hasName[0]?.campos_scan_doc);
      const verifyCamposInseridos = Object.entries(objDocs).filter(([key, value]) => value === true)
        .map(([key, value]) => key);

      const verifyKeys = allKeys.length === verifyCamposInseridos.length
        && allKeys.every(key => verifyCamposInseridos.includes(key));

      setNameToButton((prevState) => {
        return {
          ...prevState,
          saude: {
            ...prevState.saude,
            [cpf]: cpf,
          },
        }
      });
      if (verifyKeys) {
        setButtonDisabled(true);
      } else {
        setButtonDisabled(false);
      }
    }
  }

  const verifyDocsOdonto = (data) => {
    const camposInclusao = Object.entries(campoInclusaoObj).filter(([key, value]) => value === 1)
      .map(([key, value]) => key);

    const hasName = data?.filter((item, index) => item.nome_beneficiario === nomeId);

    const allKeys = camposVerificacao(hasName[0].titular, camposInclusao);

    if (hasName[0]?.titular === 1) {
      const cpf = hasName[0]?.cpf;

      const objDocs = JSON.parse(hasName[0]?.campos_scan_doc);
      const verifyCamposInseridos = Object.entries(objDocs).filter(([key, value]) => value === true)
        .map(([key, value]) => key);

      const verifyKeys = allKeys.length === verifyCamposInseridos.length
        && allKeys.every(key => verifyCamposInseridos.includes(key));

      setNameToButton((prevState) => {
        return {
          ...prevState,
          odonto: {
            ...prevState.odonto,
            [cpf]: cpf
          },
        }
      });
      // console.log('verifyCamposInseridos odonto', verifyCamposInseridos)
      // console.log('allKeys odonto', allKeys)
      // console.log('verifyKeys odonto', verifyKeys)
      if (verifyKeys) {
        setButtonDisabled(true);
      } else {
        setButtonDisabled(false);
      }

    } else if (hasName[0]?.titular === 0) {
      const cpf = hasName[0]?.cpf;

      const objDocs = JSON.parse(hasName[0]?.campos_scan_doc);
      const verifyCamposInseridos = Object.entries(objDocs).filter(([key, value]) => value === true)
        .map(([key, value]) => key);

      const verifyKeys = allKeys.length === verifyCamposInseridos.length
        && allKeys.every(key => verifyCamposInseridos.includes(key));

      setNameToButton((prevState) => {
        return {
          ...prevState,
          odonto: {
            ...prevState.odonto,
            [cpf]: cpf
          },
        }
      });
      if (verifyKeys) {
        setButtonDisabled(true);
      } else {
        setButtonDisabled(false);
      }
    }
  }


  const appendDocumentos = (formData) => {
    const nomeSnackCase = Object.entries(stateObj[nomeId])
      ?.map(([key, value]) => key.replaceAll(" ", "_"));

    files?.forEach((item, index) => {
      const snackCaseFileName = item?.file.name.replaceAll(" ", "_");
      formData.append(`${nomeSnackCase[index]}_${snackCaseFileName}`, item.file);
      // formData.get(item.file.name);
    });

    // código para apenas verificar e ler os documentos
    // for (let pair of formData.entries()) {
    //   console.log(pair);
    // }
  }

  // console.log('contratosEstipulante', contratosEstipulante)
  // console.log('estipulanteAndSubs', estipulanteAndSubs)

  const renderListaBeneficiarios = () => {
    // Leitura de arquivo para renderizar o nome dos beneficiário
    const file = getValues();
    if (abaSelected === 'saude' && seguradoraNome !== '') {
      if (file?.planilha_saude?.[0]?.name) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;

          // Converte o ArrayBuffer para um string binária
          const binaryString = String.fromCharCode.apply(null, new Uint8Array(data));

          // Usa a biblioteca xlsx para converter a string binária em um objeto JSON
          const workbook = xlsx.read(binaryString, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = xlsx.utils.sheet_to_json(worksheet, {
            dateNF: 'DD-MM-YYYY',
            raw: false,
          });

          setArrayJson((prevState) => [{
            ...prevState,
            saude: json,
          }]);
        }
        if (file?.planilha_saude[0]) {
          reader.readAsArrayBuffer(file?.planilha_saude[0]);
        }
      }
    }

    if (abaSelected === 'odonto' && seguradoraNome !== '') {
      if (file?.planilha_odonto?.[0]?.name) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;

          // Converte o ArrayBuffer para um string binária
          const binaryString = String.fromCharCode.apply(null, new Uint8Array(data));

          // Usa a biblioteca xlsx para converter a string binária em um objeto JSON
          const workbook = xlsx.read(binaryString, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = xlsx.utils.sheet_to_json(worksheet, {
            dateNF: 'DD-MM-YYYY',
            raw: false,
          });

          setArrayJson((prevState) => [{
            ...prevState,
            odonto: json,
          }]);
        }
        if (file?.planilha_odonto[0]) {
          reader.readAsArrayBuffer(file?.planilha_odonto[0]);
        }
      }
    }
  }

  const handleSubmit = async beneficiario => {
    // console.log('beneficiario', beneficiario);
    if (!beneficiario) {
      return;
    }

    if (abaSelected === 'saude') {
      const formData = new FormData();
      appendDocumentos(formData);

      const contrato = contratosEstipulante[abaSelected]?.filter(item => item.seguradora_nome === seguradoraNome);
      const contrato_id = contrato[0].contrato_id;

      const headers = {
        usuario_rh: user.id,
        beneficiario: JSON.stringify(beneficiario)
      };

      setLoading(true);

      api.post(`/rh/estipulante-sub/movimentacoes/planilha-inclusao/${contrato_id}`, formData, {
        headers,
      })
        .then(resp => {
          // console.log('resp', resp)
          // console.log(resp.data.resp.beneficiarios);
          if (resp.data) {
            setButtonDisabled(false);
            setLoading(false);
            setStateObj((prevState) => ({
              ...prevState,
              [beneficiario["Nome"]]: {},
            }));
            setFiles([]);
            setUpdateStatus(beneficiario);
            toast.dismiss();
            toast.success('Movimentação concluída com sucesso.', {
              duration: 2000,
            });
          }
        })
        .catch(err => {
          setLoading(false);
          toast.dismiss();
          toast.error('Algo deu errado, tente novamente.', {
            duration: 2000,
          });
        })
    }
    if (abaSelected === 'odonto') {
      const formData = new FormData();
      appendDocumentos(formData);

      const contrato = contratosEstipulante[abaSelected]?.filter(item => item.seguradora_nome === seguradoraNome);
      const contrato_id = contrato[0].contrato_id;

      const headers = {
        usuario_rh: user.id,
        beneficiario: JSON.stringify(beneficiario)
      };

      setLoading(true);

      api.post(`/rh/estipulante-sub/movimentacoes/planilha-inclusao/${contrato_id}`, formData, {
        headers,
      })
        .then(resp => {
          // console.log(resp.data);
          if (resp.data) {
            setButtonDisabled(false);
            setLoading(false);
            setStateObj((prevState) => ({
              ...prevState,
              [beneficiario["Nome"]]: {},
            }));
            setFiles([]);
            setUpdateStatus(beneficiario);
            toast.dismiss();
            toast.success('Movimentação concluída com sucesso.', {
              duration: 2000,
            });
          }
        })
        .catch(err => {
          setLoading(false);
          toast.dismiss();
          toast.error('Algo deu errado, tente novamente.', {
            duration: 2000,
          });
        })
    }
  };

  React.useEffect(() => {
    if (abaSelected === 'saude' && seguradoraNome !== '') {
      const contrato = contratosEstipulante[abaSelected]?.filter(item => item.seguradora_nome === seguradoraNome);
      const contrato_id = contrato[0].contrato_id;

      api.get(`/rh/estipulante-sub/movimentacoes/planilha-inclusao/${contrato_id}`)
        .then(resp => {
          // console.log('resp get bene', resp.data)
          let beneficiarioFiltro = [];

          arrayJson[0].saude?.forEach((beneficiario) => {
            resp?.data.forEach((beneficiarioInserido) => {
              if (beneficiario["CPF"] === beneficiarioInserido.cpf) {
                beneficiarioFiltro.push(beneficiarioInserido);
              }
            });
          });

          // console.log('beneficiarioFiltro saude', beneficiarioFiltro)
          if (beneficiarioFiltro) {
            beneficiarioFiltro.map(beneficiario => {
              const { cpf } = beneficiario;
              setInsertConcluido((prevState) => {
                return {
                  ...prevState,
                  'saude': {
                    ...prevState['saude'],
                    [cpf]: cpf,
                  },
                }
              });
            });
          }
        })
        .catch(err => {
          // console.log(err)
        })
    } else if (abaSelected === 'odonto' && seguradoraNome !== '') {
      const contrato = contratosEstipulante[abaSelected]?.filter(item => item.seguradora_nome === seguradoraNome);
      const contrato_id = contrato[0].contrato_id;

      api.get(`/rh/estipulante-sub/movimentacoes/planilha-inclusao/${contrato_id}`)
        .then(resp => {
          // console.log('resp get bene odonto', resp.data)
          let beneficiarioFiltro = [];
          arrayJson[0].odonto?.forEach((beneficiario) => {
            resp?.data.forEach((beneficiarioInserido) => {
              if (beneficiario["CPF"] === beneficiarioInserido.cpf) {
                beneficiarioFiltro.push(beneficiarioInserido);
              }
            });
          });

          // console.log('beneficiarioFiltro odonto', beneficiarioFiltro)
          if (beneficiarioFiltro) {
            beneficiarioFiltro.map(beneficiario => {
              const { cpf } = beneficiario;
              setInsertConcluido((prevState) => {
                return {
                  ...prevState,
                  'odonto': {
                    ...prevState['odonto'],
                    [cpf]: cpf,
                  },
                }
              });
            });
          }
        })
        .catch(err => {
          // console.log(err)
        })
    }
  }, [
    updateStatus,
    arrayJson,
  ]);

  React.useEffect(() => {
    if (abaSelected === 'saude' && seguradoraNome !== '') {
      // console.log('useEffect saude')
      const filtroTypesArr = contratosEstipulante?.saude?.filter(item => item.seguradora_nome === seguradoraNome)
        .map(item => item.campos_inclusao);

      const filtroTypesObj = filtroTypesArr[0];

      // Este estado será utilizado no pop up de documentos
      setCampoInclusaoObj(filtroTypesObj);

      const filtroTypes = Object.keys(filtroTypesObj)?.filter(key => filtroTypesObj[key] === 1);

      // Filtro para a variável typeColumns
      const types = typeColumns['Saúde'].filter(item => filtroTypes.includes(item.key));
      // console.log('types', types);

      // filtro para a variável arrayColumns
      const columns = arrayColumns['saude'].filter(item => filtroTypes.includes(item.key));
      const onlyColumn = columns?.map(({ column }) => column);

      setTypeColumnsFormatter(types);
      setArrayColumnsFormatter(onlyColumn);

    } else if (abaSelected === 'odonto' && seguradoraNome !== '') {
      // console.log('useEffect odonto')
      const filtroTypesArr = contratosEstipulante?.odonto?.filter(item => item.seguradora_nome === seguradoraNome)
        .map(item => item.campos_inclusao);

      const filtroTypesObj = filtroTypesArr[0];

      // Este estado será utilizado no pop up de documentos
      setCampoInclusaoObj(filtroTypesObj);

      const filtroTypes = Object.keys(filtroTypesObj)?.filter(key => filtroTypesObj[key] === 1);

      // Filtro para a variável typeColumns
      const types = typeColumns['Odonto'].filter(item => filtroTypes.includes(item.key));

      // filtro para a variável arrayColumns
      const columns = arrayColumns['odonto'].filter(item => filtroTypes.includes(item.key));
      const onlyColumn = columns?.map(({ column }) => column);

      setTypeColumnsFormatter(types);
      setArrayColumnsFormatter(onlyColumn);
    }

  }, [seguradoraNome, abaSelected]);

  const handleSeguradora = (object) => {
    setSeguradoraNome(object.value)
  }

  const rows = 50;


  const applyValidationToColumn = (worksheet, columnIndex, columnName, validation) => {
    // console.log(`Aplicou validação na coluna: ${columnName}, columnIndex: ${columnIndex}`);
    for (let rowIndex = 0; rowIndex < rows; rowIndex += 1) {
      worksheet.getRow(rowIndex + 2).getCell(columnIndex + 1).dataValidation = validation;
    }
  };

  const downloadExcel = (e) => {
    e.preventDefault();
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Planilha de Inclusão', { properties: { tabColor: { argb: 'FF00FF00' } } });
    const worksheetPlanos = workbook.addWorksheet('Planos');
    worksheetPlanos.columns = [{
      header: "planos", key: "planos"
    }];

    const headerRow = worksheet.getRow(1);
    headerRow.height = 30;

    if (abaSelected === 'saude') {

      worksheet.getRow(1).values = arrayColumnsFormatter;
      worksheet.columns = typeColumnsFormatter;
    } else if (abaSelected === 'odonto') {

      worksheet.getRow(1).values = arrayColumnsFormatter;
      worksheet.columns = typeColumnsFormatter;
    }

    let formStringEstipulantes = "";
    estipulanteAndSubs?.map((element, index) => {
      if (index === estipulanteAndSubs.length - 1) return formStringEstipulantes += `${element.cnpj}`
      return formStringEstipulantes += `${element.cnpj},`
    })

    const estipulanteValidation = {
      type: 'list',
      allowBlank: true,
      formulae: [`"${formStringEstipulantes}"`],
      showErrorMessage: true,
      errorStyle: 'Erro',
      errorTitle: 'Selecione uma opção',
      error: 'Selecione uma das opções entre os Estipulantes'
    };

    const dateValidation = {
      type: 'date',
      allowBlank: true,
      showErrorMessage: true,
      errorStyle: 'Erro',
      errorTitle: 'Insira o dado no formato correto',
      error: 'O dado de entrada deve ser no formato "DD/MM/YYYY"',
      formulae: [new Date(1900, 0, 1), new Date()]
    };

    // worksheet.dataValidations.add("A1:A9999", {
    //   type: 'list',
    //   allowBlank: true,
    //   formulae: [`"${formStringEstipulantes}"`],
    //   showErrorMessage: true,
    //   errorStyle: 'Erro',
    //   errorTitle: 'Selecione uma opção',
    //   error: 'Selecione uma das opções entre os Estipulantes'
    // });

    const generoValidation = {
      type: 'list',
      allowBlank: true,
      formulae: ['"Masculino, Feminino, Outros"'],
      showErrorMessage: true,
      errorStyle: 'Erro',
      errorTitle: 'Selecione uma opção',
      error: 'Selecione uma das opções'
    };

    const grauParentescoValidation = {
      type: 'list',
      allowBlank: true,
      formulae: ['"Filho(a),Cônjuge,Pai,Mãe,Irmão,Irmã,Tio(a),Cunhado(a),Sogro(a),Genro,Nora,Primo(a)"']
    };

    const estadoCivilValidation = {
      type: 'list',
      allowBlank: true,
      formulae: ['"Casado(a),Solteiro(a),Viúvo(a),Divorciado(a),Outros"']
    };

    const tipoContratoValidation = {
      type: 'list',
      allowBlank: true,
      formulae: ['"CLT,PJ,Sócio,Estagiário"']
    };

    let planosSaudeValidation = {};
    let planosOdontoValidation = {};

    let formStringPlanos = "";

    if (abaSelected === 'saude') {
      const planos = contratosEstipulante?.saude?.filter((element) =>
        element.seguradora_nome === seguradoraNome)[0].tipo_planos?.map(item => item.tipo);
      const planosToString = planos.toString();
      const planosFormatado = '"' + planosToString + '"';

      planosSaudeValidation = {
        type: 'list',
        allowBlank: true,
        formulae: [planosFormatado]
      };

      // Adiciona aba planos
      contratosEstipulante?.saude?.map(element => {
        return element.tipo_planos?.map(item => {
          return worksheetPlanos.addRow({ planos: item.tipo })
        })
      })

      contratosEstipulante?.saude?.map(element => {
        return element.tipo_planos?.map((item, index) => {
          if (index === element.length - 1) return formStringPlanos += `${item.tipo}`
          return formStringPlanos += `${item.tipo},`
        })
      })

    } else if (abaSelected === 'odonto') {
      const planos = contratosEstipulante?.odonto?.filter((element) =>
        element.seguradora_nome === seguradoraNome)[0].tipo_planos?.map(item => item.tipo);
      const planosToString = planos.toString();
      const planosFormatado = '"' + planosToString + '"';

      planosOdontoValidation = {
        type: 'list',
        allowBlank: true,
        formulae: [planosFormatado]
      };


      // Adiciona aba planos
      contratosEstipulante?.odonto?.map(element => {
        return element.tipo_planos?.map(item => {
          return worksheetPlanos.addRow({ planos: item.tipo })
        })
      })

      contratosEstipulante?.odonto?.map(element => {
        return element.tipo_planos?.map((item, index) => {
          if (index === element.length - 1) return formStringPlanos += `${item.tipo}`
          return formStringPlanos += `${item.tipo},`
        })
      })
    }

    arrayColumnsFormatter?.forEach((column, columnIndex) => {
      switch (column) {
        case 'CNPJ do estipulante':
          applyValidationToColumn(worksheet, columnIndex, column, estipulanteValidation);
          break;
        case 'Data de nascimento':
        case 'Data de expedição do RG':
        case 'Data de admissão':
        case 'Data de contratação do plano':
          applyValidationToColumn(worksheet, columnIndex, column, dateValidation);
          break;
        case 'Sexo':
          applyValidationToColumn(worksheet, columnIndex, column, generoValidation);
          break;
        case 'Grau parentesco':
          applyValidationToColumn(worksheet, columnIndex, column, grauParentescoValidation);
          break;
        case 'Estado civil':
          applyValidationToColumn(worksheet, columnIndex, column, estadoCivilValidation);
          break;
        case 'Tipo de contrato':
          applyValidationToColumn(worksheet, columnIndex, column, tipoContratoValidation);
          break;
        case 'Plano Saúde':
          if (abaSelected === 'saude') {
            applyValidationToColumn(worksheet, columnIndex, column, planosSaudeValidation);
          }
          break;
        case 'Plano Odonto':
          if (abaSelected === 'odonto') {
            // console.log('Plano odonto Valid', planosOdontoValidation)
            applyValidationToColumn(worksheet, columnIndex, column, planosOdontoValidation);
          }
          break;
        default:
          // console.log(`Não aplicou validação na coluna: ${column}`)
          break;
      }
    });

    // console.log('worksheet depois da validação:', worksheet)

    worksheet.eachRow({ includeEmpty: true }, function (Row, rowNum) {
      Row.eachCell({ includeEmpty: true }, function (Cell, colNumber) {
        if (rowNum === 1) {
          Cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '62A0E2' }
          }

          Cell.font = {
            bold: true,
            size: 14,
            color: { argb: 'FFFFFF' }
          }

          Cell.alignment = {
            vertical: 'middle',
            horizontal: 'center'
          }
        }
      })
    })

    worksheet.properties.defaultRowHeight = 20;
    worksheet.properties.defaultColWidth = 30;

    if (abaSelected === 'saude') {
      saveFile('Planilha de Inclusão Saude', workbook);
    } else if (abaSelected === 'odonto') {
      saveFile('Planilha de Inclusão Odonto', workbook);
    }

  }

  const saveFile = async (fileName, workbook) => {
    const xls64 = await workbook.xlsx.writeBuffer({ base64: true });
    saveAs(
      new Blob([xls64], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
      fileName
    )
  }

  const renderAbaSelecionada = () => {
    switch (abaSelected) {
      case 'saude':
        return (
          <div>
            <div className="container_select">
              <SelectStandard
                name={"Seguradora"}
                label={"Seguradora"}
                options={optionsSeguradoras}
                value={seguradoraNome}
                setValue={handleSeguradora}
              />
            </div>
            <div className="container_btn_doc">
              <button
                className="btn"
                onClick={(e) => {
                  if (seguradoraNome !== '') {
                    downloadExcel(e);
                  }
                }}
              >
                Baixar planilha
              </button>
              <label className="btn label-file">
                Inserir planilha
                <input
                  onChange={({ target }) => setValue('planilha_saude', target?.files)}
                  type="file"
                  name="planilha_saude"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
              </label>
              <div className="doc_container">
                {
                  planilhaSaude?.[0]?.name ?
                    (
                      <>
                        <span><BiFolderOpen /> {planilhaSaude?.[0]?.name} </span>
                        <BiX
                          className="remove_icon"
                          size={20}
                          onClick={() => {
                            setValue('planilha_saude', '')
                            setArrayJson((prevState) => [{
                              ...prevState,
                              saude: [],
                            }]);
                            setFiles([]);
                            setStateObj({});
                          }}
                        />
                      </>
                    ) : null
                }
              </div>
            </div>

            <div className="lista_bene_container">
              <div
                className="lista_titulo"
                onClick={() => renderListaBeneficiarios()}
              >
                Mostrar Lista de Beneficiários
              </div>

              <ListaBeneficiariosSaude
                abaSelected={abaSelected}
                setNomeId={setNomeId}
                arrayJson={arrayJson[0].saude}
                nameToButton={nameToButton}
                insertConcluido={insertConcluido}
                buttonDisabled={buttonDisabled}
                handleSubmit={handleSubmit}
                planilhaSaude={planilhaSaude}
                campoInclusaoObj={campoInclusaoObj}
                files={files}
                setCheckboxValue={checkboxChangeHandler}
                values={stateObj}
                handleFile={handleFile}
                deleteFile={deleteFile}
                saveChecklist={saveChecklistDocumentosScanHandler}
              />
            </div>
            <Toaster
              position={"top-center"}
              toastOptions={{
                style: {
                  background: colors.rhBlue,
                  padding: '0 12px',
                  color: '#fff',
                  maxWidth: '450px',
                  height: '60px',
                  marginTop: '80px',
                  fontSize: '1em',
                  fontWeight: 'bold',
                },
              }} />
          </div>
        )
      case 'odonto':
        return (
          <div>
            <div className="container_select">
              <SelectStandard
                name={"Seguradora"}
                label={"Seguradora"}
                options={optionsSeguradoras}
                value={seguradoraNome}
                setValue={handleSeguradora}
              />
            </div>
            <div className="container_btn_doc">
              <button
                className="btn"
                onClick={(e) => {
                  if (seguradoraNome !== '') {
                    downloadExcel(e);
                  }
                }}>
                Baixar planilha
              </button>
              <label className="btn label-file">
                Inserir planilha
                <input
                  onChange={({ target }) => setValue('planilha_odonto', target?.files)}
                  type="file"
                  name="planilha_odonto"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
              </label>
              <div className="doc_container">
                {
                  planilhaOdonto?.[0]?.name ?
                    (
                      <>
                        <span><BiFolderOpen /> {planilhaOdonto?.[0]?.name} </span>
                        <BiX
                          className="remove_icon"
                          size={20}
                          onClick={() => {
                            setValue('planilha_odonto', '')
                            setArrayJson((prevState) => [{
                              ...prevState,
                              odonto: [],
                            }]);
                            setFiles([]);
                            setStateObj({});
                          }}
                        />
                      </>
                    ) : null
                }
              </div>
            </div>

            <div className="lista_bene_container">
              <div
                className="lista_titulo"
                onClick={() => renderListaBeneficiarios()}
              >
                Mostrar Lista de Beneficiários
              </div>
              <ListaBeneficiariosOdonto
                abaSelected={abaSelected}
                setNomeId={setNomeId}
                arrayJson={arrayJson[0].odonto}
                nameToButton={nameToButton}
                insertConcluido={insertConcluido}
                buttonDisabled={buttonDisabled}
                handleSubmit={handleSubmit}
                planilhaOdonto={planilhaOdonto}
                campoInclusaoObj={campoInclusaoObj}
                files={files}
                setCheckboxValue={checkboxChangeHandler}
                values={stateObj}
                handleFile={handleFile}
                deleteFile={deleteFile}
                saveChecklist={saveChecklistDocumentosScanHandler}
              />
            </div>
            <Toaster
              position={"top-center"}
              toastOptions={{
                style: {
                  background: colors.rhBlue,
                  padding: '0 12px',
                  color: '#fff',
                  maxWidth: '450px',
                  height: '60px',
                  marginTop: '80px',
                  fontSize: '1em',
                  fontWeight: 'bold',
                },
              }} />
          </div>
        )
      default:
        return (
          <div></div>
        )
    }
  }

  return (
    <DrawerAdicionarPlanilhaWrapper>
      <SideStripe />
      <Content>
        <Loading open={loading} setOpen={() => setLoading(false)} />
        <div>
          <h3>Adicionar Planilha de Beneficiários</h3>
          <div className="close-icon-container">
            <BiX
              className="close-icon"
              size={30}
              onClick={closeDrawer}
            />
          </div>
        </div>

        <AbaPlanos>
          <div
            className={`aba_btn ${abaSelected === 'saude' && 'btn_aba_selected'}`}
            onClick={() => {
              setAbaSelected('saude')
              setSeguradoraNome('')
            }}
          >Plano Saúde</div>
          <div
            className={`aba_btn ${abaSelected === 'odonto' && 'btn_aba_selected'}`}
            onClick={() => {
              setAbaSelected('odonto')
              setSeguradoraNome('')
            }}
          >Plano Odontológico</div>
        </AbaPlanos>

        <div>
          {renderAbaSelecionada()}
        </div>

      </Content>
    </DrawerAdicionarPlanilhaWrapper>
  )
}

export default DrawerAdicionarPlanilhaRh;
