import React from 'react';
import moment from 'moment';
import { BsCircleFill } from 'react-icons/bs';
import './styles.scss';

const StatusCircle = ({ vigencia_final }) => {
  const isActive =
    vigencia_final && moment(vigencia_final) > moment(new Date());

  const circleClassName = isActive
    ? 'status-in-cancel-process-circle-icon'
    : vigencia_final
    ? 'status-canceled-active-circle-icon'
    : 'status-active-circle-icon';

  return (
    <span className="status-circle">
      <BsCircleFill className={circleClassName} size={15} />
    </span>
  );
};

export default StatusCircle;
