import styled from 'styled-components';
// import media from 'styled-media-query'
import colors from 'styles/colors';

export const Wrapper = styled.div `
  display: flex;

  .animeLeft {
    opacity: 0;
    transform: translateX(-20px);
    animation: animeLeft 0.3s forwards;
  }
  @keyframes animeLeft {
    to {
      opacity: 1;
      transform: initial;
    }
  }

  .password-inputs-container {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    padding-left: 5px;

    .input-container {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      height: 62px;

      label {
        color: ${colors.rhBlue};
        font-size: 15px;  
        font-family: "Avenir Next GEO W05 Demi";
        padding-left: 15px;
      }
  
      input {
        padding: 0 15px;
        font-family: "Avenir Next GEO W05 Regular";
        border: none;
        background-color: ${colors.white};
        font-size: 15px;
        height: 32px;
        border-radius: 9px;
        width: 420px;
      }

      .input-password-container {
        display: flex;
        align-items: center;

        -moz-user-select: none;
        -webkit-user-select: none;
        user-select: none;
        
        input {
          ::-ms-reveal {
            display: none;
          }
        }
        
        .show-icon {
          position: relative;
          left: -30px;
          color: ${colors.genoaDarkBlue};

          :hover {
            cursor: pointer;
          }
        }
      }
    }

  }

  .send-btn {
    margin-top: 15px;
    padding-left: 15px;
    width: max-content;
    color: ${colors.genoaDarkBlue};
    font-family: "Avenir Next GEO W05 Demi";
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    display: flex;
    align-items: center;

    .send-icon {
      margin-left: 5px;
    }

    :hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .error-message-span {
    font-family: "Avenir Next GEO W05 Demi";
    padding-left: 15px;
    color: ${colors.red};
    font-size: 15px;
  }

  .confirmation-message-span {
    font-family: "Avenir Next GEO W05 Demi";
    padding-left: 15px;
    color: ${colors.rhBlue};
    font-size: 15px;
  }
`
export const Content = styled.div `
  width: calc(100% - 80px);

`

export const Consult = styled.section`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  width: 100%;

  .title {
    width: 100%;
    margin-right: 10px;
    color: #3b3838;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

    h1 {
      font-family: "Avenir Next GEO W05 Heavy";
      font-size: 1.2rem;
    }

  }
`;
