import styled from 'styled-components';

export const Footer = styled.footer `
  display: flex;
  justify-content: center;
  padding: 20px 0;

  .image{
    width: 120px;

    img{width:100%}
  }

`