import { createContext, useContext, useState, useEffect } from 'react';

export const UserContext = createContext();

// esses são os valores armazenados no context user.
// const USER_BROKER_PERMISSION_DEFAULT = {
//   user: {
//     id: '',
//     token: '',
//     exp: '',
//     iat: '',
//     nome: '',
//     email: '',
//     estipulante: '',
//     corretora: '',
//     user_id: '',
//     usuario_id: undefined
//   },
//   acessedPages: {
//     tickets: false,
//     movimentacoes: false
//   }
// };

// const USER_HR_DEFAULT = {
//   email: '',
//   estipulante: '',
//   estipulanteRazaoSocial: '',
//   exp: 0,
//   iat: 0,
//   id: 0,
//   logoCorretora: '',
//   logoEstipulante: '',
//   mainEstipulante: 0,
//   nome: '',
//   sobrenome: '',
//   token: '',
//   uuid: ''
// };

export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState({});

  useEffect(() => {
    const userLS = localStorage.getItem('context-user');

    if (userLS) {
      setUser(JSON.parse(userLS));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('context-user', JSON.stringify(user));
  }, [user]);

  const setAcessedPages = (page) => {
    setUser({
      ...user,
      acessedPages: {
        ...user.acessedPages,
        [page]: true
      }
    });
  };

  const logOut = () => {
    setUser({});
    localStorage.clear();
    sessionStorage.clear();
  };

  const userMapped = {
    ...user,
    usuario_id: user.nome === 'Rodrigo Pedroni' ? 17 : user.usuario_id
  };

  return (
    <UserContext.Provider
      value={{ user: userMapped, setUser, setAcessedPages, logOut }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
