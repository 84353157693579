import http from 'services/http';

const removeSpecialCharacters = (value) => {
  return value.replace(/[^0-9]/g, '');
};

const modules = {
  async getConfiguration(idEstipulante) {
    try {
      const res = await http.get(
        `/inclusion-request/configuration?id_estipulante=${idEstipulante}`
      );

      return res.data;
    } catch (error) {
      throw error;
    }
  },

  async createInclusionRequest(payload) {
    const newPayload = {
      ...payload,
      beneficiarios: payload.beneficiarios.map((beneficiario) => {
        return {
          ...beneficiario,
          cpf: removeSpecialCharacters(beneficiario.cpf)
        };
      }),
      rh_user_id: String(payload.rh_user_id)
    };

    try {
      const res = await http.post('/inclusion-request', newPayload);

      return res.data;
    } catch (error) {
      throw error;
    }
  },

  async getAllInclusionRequests(estipulanteId) {
    try {
      const res = await http.get(
        `/inclusion-request/all-requests?estipulante_id=${estipulanteId}`
      );

      return res.data;
    } catch (error) {
      throw error;
    }
  },

  async generateReviewLink({ titularId, batchId, rhUserId }) {
    try {
      const res = await http.get(
        `/inclusion-request/generate-review-link?titular_id=${titularId}&batch_id=${batchId}&rh_user_id=${rhUserId}`
      );

      return res.data;
    } catch (error) {
      throw error;
    }
  },

  async getPersonalInformation(id_estipulante) {
    try {
      const res = await http.get(
        `/inclusion-request/holders-personal-information/${id_estipulante}`
      );

      return res.data;
    } catch (err) {
      throw err;
    }
  }
};

export default modules;
