import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { AiOutlineCheck } from 'react-icons/ai';
import { CancelarContratoContainer } from './styles';
import services from 'apps/broker/services';
import TopBar from 'apps/broker/components/HorizontalBar/topBar';
import BottomBar from 'apps/broker/components/HorizontalBar/bottomBar';

const CancelarContratoInner = ({ contrato, getData }) => {
  const [submitDelete, setSubmitDelete] = React.useState({});
  const { handleSubmit, control, setValue } = useForm({
    defaultValues: {
      vigenciaFinal: new Date()
    }
  });

  async function onSubmit(dataForm, e) {
    // console.log(getValues())
    setValue('vigenciaFinal', dataForm.vigenciaFinal);

    const idref_contrato = e.target[1].classList[1];
    const body = {
      vigenciaFinal: dataForm.vigenciaFinal,
      idref_contrato
    };

    const response = await services.insuranceCompanies.createContractExpireDate(
      body
    );

    if (response.status === 200) {
      getData();
    }

    // getData vem do componente pai para atualizar os dados do componente filho
    setSubmitDelete((prevState) => ({
      ...prevState,
      [idref_contrato]: true
    }));
  }

  return (
    <CancelarContratoContainer>
      <TopBar title={'Cancelamento de contrato'} />
      <div className="cancel-form-container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-first-row">Data de vigência final: </div>
          <div className="form-second-row">
            <Controller
              name="vigenciaFinal"
              control={control}
              rules={{ required: false }}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  className="delete-input-visible"
                  selected={value}
                  locale="ptBR"
                  onChange={(date) => {
                    setValue('vigenciaFinal', date);
                    // console.log(control._formValues)
                  }}
                  placeholderText="Selecione uma data"
                  dateFormat="dd/MM/yyyy"
                />
              )}
            />
            <button
              className={`delete-btn ${contrato.idref_contrato}`}
              type="submit"
            >
              Definir data de vigência final
            </button>
            {submitDelete[contrato.idref_contrato] ? (
              <span className="delete-confirmation-icon">
                <AiOutlineCheck size={20} />
              </span>
            ) : (
              ''
            )}
          </div>
        </form>
      </div>
      <BottomBar />
    </CancelarContratoContainer>
  );
};

export default CancelarContratoInner;
