const todosCamposPreenchidosAdicionais = (
  {
    abasCompletasBeneficiariosAdicionais,
    beneficiariosAdicionaisKeys
  }
) => {

  const response = beneficiariosAdicionaisKeys.every((item) => {
    const abasCompletasBeneficiario = abasCompletasBeneficiariosAdicionais[Number(item)]
    if (abasCompletasBeneficiario) {
      const abasBeneficiario = Object.keys(abasCompletasBeneficiario)
      // if (abasBeneficiario.length < 1) {
      //   return false
      // }
      const todasCompletas = abasBeneficiario.every((aba) => {
        return abasCompletasBeneficiario[aba]
      })


      return todasCompletas
    }
    return false
  });

  return response
}

export default todosCamposPreenchidosAdicionais