import http from 'services/http';
import { jwtDecode } from 'jwt-decode';

const createDemonstrationRequest = async (data) => {
  const res = await http.post('/solicitacao-demonstracao', data);

  return res;
};

const getUserInfo = async () => {
  const tokenQueryString = new URLSearchParams(window.location.search).get(
    'token'
  );

  if (tokenQueryString) {
    const decoded = jwtDecode(tokenQueryString);
    const expiredDate = decoded.exp;
    const today = Math.floor(new Date().getTime() / 1000);
    if (expiredDate <= today) {
      return "Token expired";
    } else {
      const newToken = await http
      .get(`/inclusion-request/employee-data?token=${tokenQueryString}`)
      .then((res) => res.data.token);

    localStorage.setItem('token', newToken);
    const decoded = jwtDecode(newToken);

    console.log('decoded');
    return decoded;
    }
  } else {
    const token = localStorage.getItem('token');

    if (token) {
      const decoded = jwtDecode(token);
      console.log('-> decoded', decoded);

      return decoded;
    }
  }

  return null;
};

const sendEmployeeData = async (data) => {
  const token = localStorage.getItem('token');

  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (key === 'dependentes') {
      const dependentes = data['dependentes'].map((dependente) => {
        const { files, ...dependenteData } = dependente;
        return dependenteData;
      });

      formData.append(key, JSON.stringify(dependentes));

      data['dependentes'].forEach((dependente, index) => {
        Object.keys(dependente.files).forEach((fileKey) => {
          const dependenteValue = dependente.files[fileKey];

          if (typeof dependenteValue === 'string') return;

          formData.append(
            `dependente_${index}_${fileKey}`,
            dependente.files[fileKey]
          );
        });
      });
    } else if (key === 'files') {
      const files = data[key]?.[0];
      Object.keys(files).forEach((fileKey) => {
        const file = files[fileKey];
        if (typeof file === 'string') return;

        formData.append(fileKey, files[fileKey]);
      });
    } else {
      formData.append(key, data[key]);
    }
  });

  formData.append('token', token);

  const response = await http.put(
    `/inclusion-request/employee-data/`,
    formData
  );

  return response;
};

const approveRequest = async () => {
  const token = localStorage.getItem('token');

  const response = await http.post(`/inclusion-request/approve-request/`, {
    token
  });

  return response;
};

const sendCorrectionRequest = async (
  titularId,
  batchId,
  userRhId,
  comments
) => {
  const data = {
    titular_id: titularId,
    batch_id: batchId,
    rh_user_id: userRhId,
    message: comments
  };

  const response = await http.post(
    '/inclusion-request/generate-correction-link',
    data
  );

  return response;
};

const fetchAddress = async (unmaskedCep) => {
  const url = `https://viacep.com.br/ws/${unmaskedCep}/json/`;

  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Erro ao buscar dados:', error);
    throw error;
  }
};

const modules = {
  createDemonstrationRequest,
  getUserInfo,
  sendEmployeeData,
  approveRequest,
  sendCorrectionRequest,
  fetchAddress
};

export default modules;
