import http from 'services/http';

const getAll = async () => {
  const res = await http.get('/corretor/movimentacaobeneficiario');

  return res;
};

const createExclusionByBeneficiaryId = async (data) => {
  const res = await http.post('/corretor/movimentacaobeneficiario', data);

  return res;
};

const createExclusionByContractIdAndBeneficiaryId = async (data) => {
  const res = await http.post(
    '/corretor/movimentacao-beneficiario/excluir-contrato',
    data
  );

  return res;
};

const createInclusionByCompanyId = async (companyId, data) => {
  const res = await http.post('/corretor/beneficiario/inclusao', data, {
    headers: {
      estipulante: companyId
    }
  });

  return res;
};

const updateInclusionByBeneficiaryId = async (data) => {
  const res = await http.put('/corretor/movimentacaobeneficiario', data);

  return res;
};

const updateExclusionByBeneficiaryId = async (data) => {
  const res = await http.put('/corretor/exclusaobeneficiario', data);

  return res;
};

const deleteMoviment = async (data) => {
  const res = await http.delete('/corretor/movimentacaobeneficiario', {
    data
  });

  return res;
};

const modules = {
  getAll,
  createExclusionByBeneficiaryId,
  createExclusionByContractIdAndBeneficiaryId,
  createInclusionByCompanyId,
  updateInclusionByBeneficiaryId,
  updateExclusionByBeneficiaryId,
  deleteMoviment
};

export default modules;
