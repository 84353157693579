import React from 'react';
import { Checkbox, createTheme, ThemeProvider } from '@mui/material';
import { useMemo } from 'react';
import {
  RiCheckboxBlankCircleLine,
  RiCheckboxCircleFill
} from 'react-icons/ri';
import SelectStandard from 'apps/broker/components/Select/index.js';
import colors from 'styles/colors';
import { AbaPlanosWrapper } from './styles-abaPlanos.js';
import { usePostHog } from 'posthog-js/react';

const theme = createTheme({
  palette: {
    rhBlue: {
      main: colors.rhBlue
    },
    darkGenoaBlue: {
      main: colors.genoaDarkBlue
    },
    action: {
      disabled: colors.genoaDisabledGrey
    }
  }
});

const AbaPlanos = ({
  beneficiariosAdicionais,
  values,
  setValues,
  setBeneficiariosAdicionais,
  setSelectValue,
  contratosEstipulante,
  incluirSaude,
  setIncluirSaude,
  incluirOdonto,
  setIncluirOdonto
}) => {
  const posthog = usePostHog();
  const [height, setHeight] = React.useState(window.innerHeight);

  const tipoBeneficiario = values['tipoBeneficiario'];
  const titular = values['titular'];

  const contratosTitular = useMemo(() => {
    const contratoSaude = titular?.contratos?.filter(
      (item) => item.produto_id === 1 && item?.status === 'Ativo'
    );
    const contratoOdonto = titular?.contratos?.filter(
      (item) => item.produto_id === 2 && item?.status === 'Ativo'
    );

    return {
      saude: contratoSaude ? contratoSaude : [],
      odonto: contratoOdonto ? contratoOdonto : []
    };
  }, [titular]);

  const contratosSaude = useMemo(() => {
    return tipoBeneficiario === 'Titular'
      ? // Se for Titular, renderizar contratos de estipulante
        contratosEstipulante?.saude
      : // Se for Dependente, renderizar apenas contrato do Titular (se possuir)
        contratosTitular?.saude;
  }, [tipoBeneficiario, contratosEstipulante?.saude, contratosTitular]);

  const [optionsContratosSaude, setOptionsContratosSaude] = React.useState([]);
  const [optionsTipoPlanoSaude, setOptionsTipoPlanoSaude] = React.useState([]);

  const contratosOdonto = useMemo(() => {
    return tipoBeneficiario === 'Titular'
      ? // Se for Titular, renderizar contratos de estipulante
        contratosEstipulante?.odonto
      : // Se for Dependente, renderizar apenas contrato do Titular (se possuir)
        contratosTitular?.odonto;
  }, [tipoBeneficiario, contratosEstipulante?.odonto]);

  const [optionsContratosOdonto, setOptionsContratosOdonto] = React.useState(
    []
  );
  const [optionsTipoPlanoOdonto, setOptionsTipoPlanoOdonto] = React.useState(
    []
  );

  React.useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
  });

  React.useEffect(() => {
    const optionsSaude = contratosSaude.map((item) => ({
      option: `${item.seguradora_nome} (Apólice: ${item.numero_apolice})`,
      value: item.contrato_estipulante_id
    }));

    setOptionsContratosSaude(optionsSaude);
  }, [contratosSaude]);

  React.useEffect(() => {
    if (tipoBeneficiario === 'Titular') {
      const tipoPlanosSaude = contratosSaude.find(
        (item) =>
          item.contrato_estipulante_id === values['contrato_estipulante_saude']
      );
      console.log('tipoPlanosSaude', tipoPlanosSaude)
      const optionsPlanoSaude = tipoPlanosSaude?.tipo_planos?.map((item) => ({
        option: item.tipo,
        value: item.tipo
      }));

      setOptionsTipoPlanoSaude(optionsPlanoSaude);
    } else if (tipoBeneficiario === 'Dependentes') {
      // const tipoPlanosSaude = contratosEstipulante?.saude
      //   .find((item) => item.contrato_estipulante_id === values['contrato_estipulante_saude']);

      // const optionsPlanoSaude = tipoPlanosSaude?.tipo_planos
      //   ?.map((item) => ({
      //     option: item.tipo, value: item.tipo
      //   }));

      const contratoSaudeTitular = contratosSaude?.find(
        (item) => item.produto_id === 1
      );

      // const optionsFiltradas = optionsPlanoSaude?.filter((item) => item?.option === contratoSaudeTitular?.tipo_plano);

      const optionsFiltradas = [
        {
          option: contratoSaudeTitular?.tipo_plano,
          value: contratoSaudeTitular?.tipo_plano
        }
      ];
      setOptionsTipoPlanoSaude(optionsFiltradas);
    }
  }, [values['contrato_estipulante_saude']]);

  React.useEffect(() => {
    const optionsOdonto = contratosOdonto.map((item) => ({
      option: `${item.seguradora_nome} (Apólice: ${item.numero_apolice})`,
      value: item.contrato_estipulante_id
    }));

    setOptionsContratosOdonto(optionsOdonto);
  }, [contratosOdonto]);

  React.useEffect(() => {
    if (tipoBeneficiario === 'Titular') {
      const tipoPlanosOdonto = contratosOdonto.find(
        (item) =>
          item.contrato_estipulante_id === values['contrato_estipulante_odonto']
      );

      const optionsPlanoOdonto = tipoPlanosOdonto?.tipo_planos?.map((item) => ({
        option: item.tipo,
        value: item.tipo
      }));

      setOptionsTipoPlanoOdonto(optionsPlanoOdonto);
    } else if (tipoBeneficiario === 'Dependentes') {
      const tipoPlanosOdonto = contratosEstipulante?.odonto.find(
        (item) =>
          item.contrato_estipulante_id === values['contrato_estipulante_odonto']
      );

      const optionsPlanosOdonto = tipoPlanosOdonto?.tipo_planos?.map(
        (item) => ({
          option: item.tipo,
          value: item.tipo
        })
      );

      const contratoOdontoTitular = contratosOdonto?.find(
        (item) => item.produto_id === 2
      );
      const optionsFiltradas = optionsPlanosOdonto?.filter(
        (item) => item?.option === contratoOdontoTitular?.tipo_plano
      );

      setOptionsTipoPlanoOdonto(optionsFiltradas);
    }
  }, [values['contrato_estipulante_odonto']]);

  const checkboxSaudeClickHandler = () => {
    posthog?.capture('Clicou em botão radial "Incluir Saúde"');
    setIncluirSaude((prevState) => {
      if (prevState === true) {
        if (values?.tipoBeneficiario === 'Titular') {
          setBeneficiariosAdicionais((prevState) => {
            for (let item in prevState) {
              const beneficiarioAdicional = prevState[item];
              beneficiarioAdicional.incluirSaude = false;
              prevState[item] = beneficiarioAdicional;
            }
            return prevState;
          });
        }
      }

      if (!prevState) {
        if (tipoBeneficiario === 'Dependentes') {
          const titular_contrato_saude = values?.titular?.contratos.find(
            (item) => item.produto_id === 1
          );

          setValues((prevState) => ({
            ...prevState,
            contrato_estipulante_saude:
              titular_contrato_saude?.contrato_estipulante_id,
            tipo_plano_saude: titular_contrato_saude?.tipo_plano
          }));
        }
      }
      return !prevState;
    });
  };

  const checkboxOdontoClickHandler = () => {
    posthog?.capture('Clicou em botão radial "Incluir Odonto"');
    setIncluirOdonto((prevState) => {
      if (prevState === true) {
        if (values?.tipoBeneficiario === 'Titular') {
          setBeneficiariosAdicionais((prevState) => {
            for (let item in prevState) {
              const beneficiarioAdicional = prevState[item];
              beneficiarioAdicional.incluirOdonto = false;
              prevState[item] = beneficiarioAdicional;
            }
            return prevState;
          });
        }
      }
      if (!prevState) {
        if (tipoBeneficiario === 'Dependentes') {
          const titular_contrato_odonto = values?.titular?.contratos.find(
            (item) => item.produto_id === 2
          );

          setValues((prevState) => ({
            ...prevState,
            contrato_estipulante_odonto:
              titular_contrato_odonto?.contrato_estipulante_id,
            tipo_plano_odonto: titular_contrato_odonto?.tipo_plano
          }));
        }
      }
      return !prevState;
    });
  };

  return (
    <AbaPlanosWrapper>
      <div className="aba-container">
        <div className="aba-title-container">
          <span>Seleção de plano(s)</span>
        </div>
        {contratosSaude?.length ? (
          <div className="aba-input-container">
            <div className="title-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  name="saude"
                  checked={incluirSaude}
                  onChange={checkboxSaudeClickHandler}
                  icon={
                    <RiCheckboxBlankCircleLine
                      className="uncheckedIcon"
                      size={(20 * height) / 745}
                    />
                  }
                  checkedIcon={
                    <RiCheckboxCircleFill size={(20 * height) / 745} />
                  }
                  sx={{ p: 0 }}
                  color={'rhBlue'}
                />
              </ThemeProvider>
              <span className="title">Incluir plano de Saúde?</span>
            </div>
            <div className="aba-contrato-planos">
              <SelectStandard
                value={values['contrato_estipulante_saude']}
                setValue={setSelectValue}
                label={'Seguradora'}
                name="contrato_estipulante_saude"
                options={optionsContratosSaude}
                disabled={!incluirSaude}
                posthogSelectClickHandlerEvent={
                  'Clicou em Select p/ mudar "Contrato Saúde"'
                }
                posthogOptionClickHandlerEvent={
                  'Clicou na opção de "Contrato Saúde"'
                }
              />

              <SelectStandard
                value={values['tipo_plano_saude']}
                setValue={setSelectValue}
                label={'Plano'}
                name="tipo_plano_saude"
                options={optionsTipoPlanoSaude}
                selectPlaceholder={
                  values['contrato_estipulante_saude']
                    ? 'Selecione um Plano'
                    : 'Selecione uma Seguradora'
                }
                disabled={!incluirSaude}
                posthogSelectClickHandlerEvent={
                  'Clicou em Select p/ mudar "Plano de Saúde"'
                }
                posthogOptionClickHandlerEvent={
                  'Clicou na opção de "Plano de Saúde"'
                }
              />
            </div>
          </div>
        ) : (
          ''
        )}
        {contratosOdonto?.length ? (
          <div className="aba-input-container">
            <div className="title-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  name="odonto"
                  checked={incluirOdonto}
                  onChange={checkboxOdontoClickHandler}
                  icon={
                    <RiCheckboxBlankCircleLine
                      className="uncheckedIcon"
                      size={(20 * height) / 745}
                    />
                  }
                  checkedIcon={
                    <RiCheckboxCircleFill size={(20 * height) / 745} />
                  }
                  sx={{ p: 0 }}
                  color={'rhBlue'}
                />
              </ThemeProvider>
              <span className="title">Incluir plano Odontológico?</span>
            </div>
            <div className="aba-contrato-planos">
              <SelectStandard
                value={values['contrato_estipulante_odonto']}
                setValue={setSelectValue}
                label={'Seguradora'}
                name="contrato_estipulante_odonto"
                options={optionsContratosOdonto}
                disabled={!incluirOdonto}
                posthogSelectClickHandlerEvent={
                  'Clicou em Select p/ mudar "Contrato Saúde"'
                }
                posthogOptionClickHandlerEvent={
                  'Clicou na opção de "Contrato Saúde"'
                }
              />

              <SelectStandard
                value={values['tipo_plano_odonto']}
                setValue={setSelectValue}
                label={'Plano'}
                name="tipo_plano_odonto"
                options={optionsTipoPlanoOdonto}
                selectPlaceholder={
                  values['contrato_estipulante_odonto']
                    ? 'Selecione um Plano'
                    : 'Selecione uma Seguradora'
                }
                disabled={!incluirOdonto}
                posthogSelectClickHandlerEvent={
                  'Clicou em Select p/ mudar "Plano Odontológico"'
                }
                posthogOptionClickHandlerEvent={
                  'Clicou na opção de "Plano de Odontológico"'
                }
              />
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </AbaPlanosWrapper>
  );
};

export default AbaPlanos;
