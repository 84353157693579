import { useState, useEffect } from 'react';
import { DarkBackground, DrawerContent } from './styles';
import {
  ClickAwayListener,
  Radio,
  ThemeProvider,
  createTheme
} from '@mui/material';
import ReactDatePicker from 'react-datepicker';
import { toast } from 'react-hot-toast';
import services from 'apps/broker/services';

const theme = createTheme({
  palette: {
    genoaBlue: {
      main: '#456AED'
    },
    darkGenoaBlue: {
      main: '#1E2552'
    }
  }
});

const DrawerNovaTask = ({
  setOpenDrawer,
  corretores,
  estipulantes,
  leads,
  setSelectEstipulante,
  selectEstipulante,
  setSelectLead,
  selectLead,
  estipulanteContratos,
  getTasksData
}) => {
  const [transitionLeave, setTransitionLeave] = useState(false);

  const [responsavel, setResponsavel] = useState(undefined);
  const [tituloTask, setTituloTask] = useState('');
  const [descricao, setDescricao] = useState('');
  const [produto, setProduto] = useState('saude');
  const [tipoCliente, setTipoCliente] = useState('estipulante');
  const [contrato, setContrato] = useState(undefined);
  const [dataTask, setDataTask] = useState(new Date());
  const [isDisabled, setDisabled] = useState(true);

  const renderCorretoresOptions = () => {
    return corretores?.map((item, index) => (
      <option key={`${item.id}-${index}`} value={item.id}>
        {item.nome}
      </option>
    ));
  };

  const renderContratosOptions = () => {
    return estipulanteContratos?.map((item, index) => (
      <option key={`${item.value}-${index}`} value={item.value}>
        {item.option}
      </option>
    ));
  };

  const selectResponsavelChangeHandler = (e) => {
    setResponsavel(e.target.value);
  };

  const setTituloTaskChangeHandler = (e) => {
    setTituloTask(e.target.value);
  };

  const setDescricaoChangeHandler = (e) => {
    setDescricao(e.target.value);
  };

  const radioTipoProdutoClickHandler = (e) => {
    setProduto(e.target.value);
  };

  const getProdutoId = () => {
    switch (produto) {
      case 'dental':
        return 2;
      case 'vida':
        return 3;
      default:
        return 1;
    }
  };

  const radioTipoClienteClickHandler = (e) => {
    setTipoCliente(e.target.value);
  };

  const renderEstipulantesOptions = () => {
    return estipulantes?.map((item, index) => (
      <option key={`${item.value}-${index}`} value={item.value}>
        {item.option}
      </option>
    ));
  };

  const renderLeadsOptions = () => {
    return leads?.map((item, index) => (
      <option key={`${item.value}-${index}`} value={item.value}>
        {item.option}
      </option>
    ));
  };

  const selectEstipulanteChangeHandler = (e) => {
    setSelectEstipulante(e.target.value);
  };

  const selectLeadChangeHandler = (e) => {
    setSelectLead(e.target.value);
  };

  const selectContratoChangeHandler = (e) => {
    setContrato(e.target.value);
  };

  const createNewTaskHandler = async () => {
    const produto_id = getProdutoId();
    let body = {};
    if (selectEstipulante && produto) {
      body = {
        usuario_id: responsavel,
        titulo_task: tituloTask,
        descricao,
        estipulante_id: selectEstipulante,
        lead_potencial_id: null,
        produto_id,
        contrato_id: contrato ? contrato : null,
        data_task: dataTask
      };
    } else if (selectLead && produto) {
      body = {
        usuario_id: responsavel,
        titulo_task: tituloTask,
        descricao,
        estipulante_id: null,
        lead_potencial_id: selectLead,
        produto_id,
        contrato_id: null,
        data_task: dataTask
      };
    } else if (!selectEstipulante && !selectLead) {
      body = {
        usuario_id: responsavel,
        titulo_task: tituloTask,
        descricao,
        estipulante_id: null,
        lead_potencial_id: null,
        produto_id: null,
        contrato_id: null,
        data_task: dataTask
      };
    }

    const response = await services.tasks.createNewTask(body);
    if (response.status === 201) {
      setResponsavel(false);
      setTituloTask('');
      setDescricao('');
      setProduto('saude');
      setContrato(undefined);
      setDataTask(new Date());
      setSelectEstipulante(0);
      setSelectLead(0);
      toast.dismiss();
      toast.success('Nova task criada com sucesso!', {
        duration: 2500
      });
      setOpenDrawer(false);
      getTasksData();
    } else {
      toast.dismiss();
      toast.error('Algo deu errado, tente novamente!', {
        duration: 2500
      });
    }
  };

  useEffect(() => {
    if (responsavel && tituloTask !== '' && dataTask) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [responsavel, tituloTask, dataTask]);

  return (
    <DarkBackground
      style={{
        animation: transitionLeave ? 'fade-out 0.225s forwards' : ''
      }}
    >
      <ClickAwayListener
        onClickAway={() => {
          setTransitionLeave(true);
          setTimeout(() => {
            setOpenDrawer(false);
          }, 200);
        }}
      >
        <DrawerContent className={transitionLeave ? 'leave' : ''}>
          <div className="popper_title">
            <span>Nova task</span>
          </div>
          <div className="form_container">
            <div className="form_option">
              <div className="form_label">Responsável:</div>
              <div>
                <ThemeProvider theme={theme}>
                  <div>
                    <select
                      className="select_resp select-arrow"
                      value={responsavel}
                      onChange={selectResponsavelChangeHandler}
                    >
                      <option value={undefined}>Selecione</option>
                      {renderCorretoresOptions()}
                    </select>
                  </div>
                </ThemeProvider>
              </div>
            </div>
            <div className="form_option">
              <div className="form_label">Título da task:</div>
              <div className="input_container">
                <input
                  className="input_titulo"
                  type="text"
                  onChange={setTituloTaskChangeHandler}
                />
              </div>
            </div>
            <div className="form_option">
              <div className="textarea_container">
                <div className="form_label">Descrição da task</div>

                <textarea
                  rows={4}
                  maxLength={255}
                  onChange={setDescricaoChangeHandler}
                />
              </div>
            </div>
            <div className="form_option">
              <ThemeProvider theme={theme}>
                <div className="options">
                  <div className="radio_container">
                    <Radio
                      value="estipulante"
                      onClick={radioTipoClienteClickHandler}
                      name="cliente"
                      className="radio-input dont-close"
                      color={'darkGenoaBlue'}
                      checked={tipoCliente === 'estipulante'}
                    />
                    <label>Estipulante</label>
                  </div>
                  <div className="radio_container">
                    <Radio
                      value="lead"
                      onClick={radioTipoClienteClickHandler}
                      name="cliente"
                      className="radio-input dont-close"
                      color={'darkGenoaBlue'}
                      checked={tipoCliente === 'lead'}
                    />
                    <label>Lead</label>
                  </div>
                </div>
                {tipoCliente === 'estipulante' ? (
                  <div>
                    <div>
                      <select
                        className="select_estipulante select-arrow"
                        value={selectEstipulante}
                        onChange={selectEstipulanteChangeHandler}
                      >
                        <option value={0}>Selecione</option>
                        {renderEstipulantesOptions()}
                      </select>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div>
                      <select
                        className="select_estipulante select-arrow"
                        value={selectLead}
                        onChange={selectLeadChangeHandler}
                      >
                        <option value={0}>Selecione</option>
                        {renderLeadsOptions()}
                      </select>
                    </div>
                  </div>
                )}
              </ThemeProvider>
            </div>
            <div className="form_option">
              <div className="form_label">Produto:</div>
              <div className="options">
                <ThemeProvider theme={theme}>
                  <div className="radio_container">
                    <Radio
                      value="saude"
                      onClick={radioTipoProdutoClickHandler}
                      name="produto"
                      className="radio-input dont-close"
                      color={'darkGenoaBlue'}
                      checked={produto === 'saude'}
                    />
                    <label>Saúde</label>
                  </div>
                  <div className="radio_container">
                    <Radio
                      value="dental"
                      onClick={radioTipoProdutoClickHandler}
                      name="produto"
                      className="radio-input dont-close"
                      color={'darkGenoaBlue'}
                      checked={produto === 'dental'}
                    />
                    <label>Dental</label>
                  </div>
                  <div className="radio_container">
                    <Radio
                      value="vida"
                      onClick={radioTipoProdutoClickHandler}
                      name="produto"
                      className="radio-input dont-close"
                      color={'darkGenoaBlue'}
                      checked={produto === 'vida'}
                    />
                    <label>Vida</label>
                  </div>
                </ThemeProvider>
              </div>
            </div>
            {tipoCliente === 'estipulante' ? (
              <div className="form_option">
                <div className="form_label">Contrato:</div>
                <div>
                  <ThemeProvider theme={theme}>
                    <div>
                      <select
                        className="select_estipulante select-arrow"
                        value={contrato}
                        onChange={selectContratoChangeHandler}
                      >
                        <option value={undefined}>Selecione</option>
                        {renderContratosOptions()}
                      </select>
                    </div>
                  </ThemeProvider>
                </div>
              </div>
            ) : (
              <div className="gap_size"></div>
            )}

            <div className="form_option">
              <div className="form_label">Data da task</div>
              <ThemeProvider theme={theme}>
                <ReactDatePicker
                  className="input-date"
                  selected={dataTask}
                  locale="ptBR"
                  minDate={new Date()}
                  onChange={(data) => setDataTask(data)}
                  dateFormat="dd/MM/yyyy"
                />
              </ThemeProvider>
            </div>
          </div>

          <div>
            <button
              className={`criar_task_btn ${isDisabled ? 'disabled' : ''}`}
              onClick={isDisabled ? () => {} : createNewTaskHandler}
            >
              criar task
            </button>
          </div>
        </DrawerContent>
      </ClickAwayListener>
    </DarkBackground>
  );
};

export default DrawerNovaTask;
