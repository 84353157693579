import {
  keysAbaDadosBancarios,
  keysAbaDadosBasicos,
  keysAbaDadosContato,
  keysAbaDadosImc,
  keysAbaDadosVinculo,
  keysAbaEndereco,
  keysAbaScanDocumentos
} from "./camposInclusaoPorAba";

const determinaPlanoSelecionado = ({
  state,
  incluirSaude,
  incluirOdonto
}) => {
  if (incluirSaude && !state['tipo_plano_saude']) {
    return false
  }

  if (incluirOdonto && !state['tipo_plano_odonto']) {
    return false
  }

  if (!incluirSaude && !incluirOdonto) {
    return false
  }

  return true
}

const determinaTitularSelecionado = ({ state }) => {
  // console.log(state)
  if (state["tipoBeneficiario"] === "Dependentes") {
    if (state["titular"]) {
      return true
    }
    return false
  }

  return true;
}

const determinaDadosBasicosCompleto = ({
  camposInclusao,
  state
}) => {
  if (state['tipoBeneficiario'] === "Titular") {
    const response = keysAbaDadosBasicos.every((item) => {
      // console.log(camposInclusao[item], state[item], item)
      if (camposInclusao[item]) {
        return state[item]
      }
      return true;
    })

    return response
  } else {
    // console.log(state);
    const response = [
      'nome',
      'data_nascimento',
      'sexo',
      'cpf',
      'estado_civil',
      'nacionalidade',
      'nome_mae',
      'vinculo',
      'dataVinculo'
    ].every((item) => {
      // console.log(camposInclusao[item], state[item], item)
      if (state[item]) {
        return true;
      }
  
      if (!camposInclusao[item]) {
        if (item === 'vinculo' || item === 'dataVinculo') {
          return false
        }
        return true
      }
      
      return true
    })

    return response
  }
}

const determinaEnderecoCompleto = ({
  camposInclusao,
  state
}) => {
  const response = keysAbaEndereco.every((item) => {
    // console.log(camposInclusao[item], state[item], item)
    if (camposInclusao[item] && item !== "complemento_endereco") {
      return state[item]
    }
    return true;
  })

  return response;
}

const determinaContatoCompleto = ({
  camposInclusao,
  state
}) => {
  const response = keysAbaDadosContato.every((item) => {
    // console.log(camposInclusao[item], state[item], item)
    if (camposInclusao[item]) {
      return state[item]
    }
    return true;
  })

  return response
}

const determinaVinculoCompleto = ({
  camposInclusao,
  state
}) => {
  const response = keysAbaDadosVinculo.every((item) => {
    // console.log(camposInclusao[item], state[item], item)
    if (camposInclusao[item]) {
      return state[item]
    }
    return true;
  })

  return response;
}

const determinaImcCompleto = ({
  camposInclusao,
  state
}) => {
  const response = keysAbaDadosImc.every((item) => {
    // console.log(camposInclusao[item], state[item], item)
    if (camposInclusao[item]) {
      return state[item]
    }
    return true;
  })

  return response;
}

const determinaDadosBancariosCompleto = ({
  camposInclusao,
  state
}) => {
  const response = keysAbaDadosBancarios.every((item) => {
    // console.log(camposInclusao[item], state[item], item)
    if (camposInclusao[item]) {
      return state[item]
    }
    return true;
  })

  return response;
}

const determinaScanCompleto = ({
  camposInclusao,
  state,
  camposInclusaoPersonalizados
}) => {
  if (state['tipoBeneficiario'] === "Titular") {
    const keysDocumentosPersonalizados = Object.keys(camposInclusaoPersonalizados)
    const keysDocumentosGeral = [
      ...keysAbaScanDocumentos, 
      ...keysDocumentosPersonalizados
    ]
    // console.log("keysDocumentosPersonalizados", keysDocumentosPersonalizados)
    const response = keysDocumentosGeral.every((item) => {
      // console.log(camposInclusao[item] && item === "documento_scan_rg", state[item], item)
      // console.log("camposInclusao[item]", camposInclusao[item])
      // console.log("camposInclusaoPersonalizados[item]", camposInclusaoPersonalizados[item])
      // console.log("state[item]", item, state[item])
      if (camposInclusao[item] || camposInclusaoPersonalizados[item]) {
        return state[item]
      }
      return true;
    })

    return response;
  } else {
    const response = [
      "documento_scan_rg",
      "documento_comprovacao_vinculo",
      "documento_scan_cpf"
    ].every((item) => {
      if (item === "documento_comprovacao_vinculo") {
        return state[item]
      }
      return true;
    })

    return response;
  }

}

const determinaFichaPropostaCompleto = ({
  camposInclusao,
  state,
  contratoEstipulanteSaudeInfo,
  contratoEstipulanteOdontoInfo,
  incluirSaude,
  incluirOdonto
}) => {
  const camposInclusaoSaude = contratoEstipulanteSaudeInfo?.campos_inclusao?.ficha_proposta;
  const camposInclusaoOdonto = contratoEstipulanteOdontoInfo?.campos_inclusao?.ficha_proposta;

  let fichasPropostasPreenchidas = false;

  if (camposInclusao["ficha_proposta"]) {
    if (camposInclusaoSaude) {
      if (!incluirSaude || state['ficha_proposta_saude']) {
        fichasPropostasPreenchidas = true;
      } else {
        fichasPropostasPreenchidas = false;
        return fichasPropostasPreenchidas;
      }
    }

    if (camposInclusaoOdonto) {
      if (!incluirOdonto || state['ficha_proposta_odonto']) {
        fichasPropostasPreenchidas = true;
      } else {
        fichasPropostasPreenchidas = false;
        return fichasPropostasPreenchidas;
      }
    }
  } else {
    fichasPropostasPreenchidas = true
  }

  return fichasPropostasPreenchidas;
}

const determinaAbasCompletas = ({
  camposInclusao,
  state,
  incluirSaude,
  incluirOdonto,
  contratoEstipulanteSaudeInfo,
  contratoEstipulanteOdontoInfo,
  setAbasCompletas,
  camposInclusaoPersonalizados
}) => {
  // console.log(camposInclusaoPersonalizados)
  const estipulanteSelecionado = state['estipulante_id'] ? true : false;
  const tipoBeneficiarioSelecionado = state['tipoBeneficiario'] ? true : false;
  const planoSelecionado = determinaPlanoSelecionado({
    state,
    incluirSaude,
    incluirOdonto
  });
  const titular = determinaTitularSelecionado({ state });
  const dadosBasicos = determinaDadosBasicosCompleto({ camposInclusao, state });
  const endereco = determinaEnderecoCompleto({ camposInclusao, state });
  const contato = determinaContatoCompleto({ camposInclusao, state });
  const vinculo = determinaVinculoCompleto({ camposInclusao, state });
  const imc = determinaImcCompleto({ camposInclusao, state });
  const dadosBancarios = determinaDadosBancariosCompleto({ camposInclusao, state });
  const scanDocumentos = determinaScanCompleto({ camposInclusao, state, camposInclusaoPersonalizados })
  const fichaProposta = determinaFichaPropostaCompleto({
    camposInclusao,
    state,
    contratoEstipulanteSaudeInfo,
    contratoEstipulanteOdontoInfo,
    incluirSaude,
    incluirOdonto
  })

  const response = {
    estipulanteSelecionado,
    tipoBeneficiarioSelecionado,
    titular,
    planoSelecionado,
    dadosBasicos,
    endereco,
    contato,
    vinculo,
    imc,
    dadosBancarios,
    scanDocumentos,
    fichaProposta
  }

  setAbasCompletas(response)
}

export default determinaAbasCompletas;