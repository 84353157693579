const optionsMotivoExclusao = {
  // Os números das chaves correspondem ao ID da tabela `motivo_exclusao_contrato`
  0: 'Selecione',
  1: 'Demissão sem Justa Causa',
  2: 'Demissão com Justa Causa',
  3: 'Saída do Contrato Social',
  4: 'Recisão de Contrato de Prestação de Serviços',
  5: 'A Pedido do Segurado',
  6: 'Falecimento'
};

export default optionsMotivoExclusao;
