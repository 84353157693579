import { useState } from 'react';
import { isLocalhost } from 'utils/browser';
import Button from 'components/DS/Button';
import Select from 'apps/lp/components/Form/Select';
import Input from 'apps/lp/components/Form/Input';
import { useFormContext } from 'react-hook-form';
import { FaRegQuestionCircle } from 'react-icons/fa';
import InformationModal from './InformationModal';
import './styles.scss';

const FormThirdStep = ({
  prevStep,
  nextStep,
  configFields,
  isHrUser,
  isFixed,
  setIsFixed,
  successMessage
}) => {
  const { formState, setValue, watch } = useFormContext();
  const cargo = watch('cargo');
  const hasValue = watch('data_admissao') ? true : false;

  const [openModalInfo, setOpenModalInfo] = useState(false);

  const onFill = () => {
    const data = {
      cargo: 'CLT',
      pis_pasep: '123.45678.90-0',
      data_admissao: '01/01/2021'
      // matricula_funcional: '123456'
    };

    Object.keys(data).forEach((key) => {
      setValue(key, data[key]);
    });
  };

  return (
    <div className="third-step">
      <div className="third-step__form">
        <div className="third-step__container-title">
          <span className="third-step__title">Vínculo com a empresa</span>
        </div>
        {configFields?.cargo ? (
          <div>
            <Select
              isRequired
              name="cargo"
              label={'Qual o seu vínculo com a empresa?'}
              options={[
                {
                  value: 'CLT',
                  label: 'CLT'
                },
                {
                  value: 'PJ',
                  label: 'PJ'
                },
                {
                  value: 'Estagiário',
                  label: 'Estagiário'
                },
                {
                  value: 'Aprendiz',
                  label: 'Aprendiz'
                },
                {
                  value: 'Sócio',
                  label: 'Sócio'
                }
              ]}
              classNameLabel={'third-step__label'}
              setIsFixed={setIsFixed}
            ></Select>
          </div>
        ) : null}
        {configFields?.pis_pasep && cargo === 'CLT' ? (
          <div>
            <Input
              isRequired
              name="pis_pasep"
              label={'Pis/Pasep'}
              classNameLabel={'third-step__label'}
              mask="pis"
              setIsFixed={setIsFixed}
            />
          </div>
        ) : null}
        {configFields?.data_admissao ? (
          <div className="third-step-binding-date">
            <Input
              isRequired
              name="data_admissao"
              label={'Qual a data de início do vínculo com a empresa?'}
              placeholder="DD/MM/AAAA"
              mask="date"
              validation="date"
              setIsFixed={setIsFixed}
            />
          </div>
        ) : null}
        {configFields?.matricula_funcional && isHrUser && (
          <div className="third-step-register-number">
            <Input
              label={
                <span
                  className="third-step-container-input__span"
                  onMouseEnter={() => setOpenModalInfo(true)}
                  onMouseLeave={() => setOpenModalInfo(false)}
                >
                  Por favor, incluir a matrícula funcional do titular{' '}
                  <FaRegQuestionCircle size={15} color="red" />
                  {openModalInfo && (
                    <InformationModal text="Digite a matrícula funcional do titular e clique em salvar." />
                  )}
                </span>
              }
              name="matricula_funcional"
              classNameLabel={'third-step-register-number__label'}
              classNameInput={'third-step-register-number__input'}
              setIsFixed={setIsFixed}
            />
          </div>
        )}
        {isHrUser && isFixed ? (
          <div className="third-step-save">
            <span className="third-step-save__span">
              Caso tenha feito alguma alteração clique em salvar:
            </span>
            <div>
              <Button
                isDisabled={!formState.isValid}
                type="submit"
                variant="save"
              >
                Salvar
              </Button>
              {successMessage && (
                <span className="third-step-save__span-success">
                  {successMessage}
                </span>
              )}
            </div>
          </div>
        ) : null}

        <div className="third-step__buttons-container">
          <div>
            <Button type="button" variant="back" onClick={prevStep}>
              Voltar
            </Button>
          </div>
          <div>
            {isHrUser ? (
              <Button type="button" isDisabled={!hasValue} onClick={nextStep}>
                Avançar
              </Button>
            ) : (
              <Button
                type="button"
                isDisabled={!formState.isValid}
                onClick={nextStep}
              >
                Avançar
              </Button>
            )}

            {isLocalhost() && (
              <Button
                className="form-fill__button"
                type="button"
                onClick={onFill}
              >
                Preencher form
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormThirdStep;
