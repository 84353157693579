import { ClickAwayListener } from "@material-ui/core";
import React from "react";
import { AiOutlineCloseCircle, AiOutlineUpload } from "react-icons/ai";
import { InputFileComponent, InputFileComponentWrapper } from "./styles-inputFileCustom";
import { usePostHog } from 'posthog-js/react'

const InputFileCustom = (
  {
    value,
    setValue,
    label,
    name,
    posthogInputFileClickHandlerEvent,
    posthogInputFileResetHandlerEvent
  }) => {
  const posthog = usePostHog();
  const [height, setHeight] = React.useState(window.innerHeight);
  const [focused, setFocused] = React.useState(false);

  React.useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight)

    }
    window.addEventListener('resize', handleResize)
  });

  const renderInputFileClassname = () => {
    if (value) {
      return "focused"
    }
    if (!value) {
      if (focused) {
        return "focused"
      }
      return "empty"
    }
  }

  const resetInputFile = () => {
    if (posthogInputFileResetHandlerEvent) {
      posthog?.capture(posthogInputFileResetHandlerEvent)
    }

    const target = {
      name,
      files: undefined
    }
    const event = {
      target
    }
    setValue(event)
  }

  return (
    <ClickAwayListener onClickAway={() => setFocused(false)}>
      <InputFileComponentWrapper>
        <InputFileComponent>
          <label className={value || focused ? "translate" : ""}>
            {label}
            {
              value || focused ?
                "" : (
                  <AiOutlineUpload
                    size={16 * height / 745}
                    className="upload_icon"
                  />
                )
            }
          </label>
          {
            value ? (
              <span className="focused">
                {value?.name}
              </span>
            ) : (
              <>
                {
                  focused && (
                    <span>
                      Nenhum Arquivo Selecionado
                    </span>
                  )
                }
                <input
                  type="file"
                  fileName="Teste"
                  className={renderInputFileClassname()}
                  onChange={setValue}
                  onClick={() => {
                    if (posthogInputFileClickHandlerEvent) {
                      posthog?.capture(posthogInputFileClickHandlerEvent)
                    }
                    setFocused(true)
                  }}
                  name={name}
                  autoComplete="new-telephone"
                />
              </>
            )
          }
        </InputFileComponent>
        {
          value ? (
            <AiOutlineCloseCircle
              className="remove-file-icon"
              size={18 * height / 745}
              onClick={resetInputFile}
            />
          ) : <></>
        }
      </InputFileComponentWrapper>
    </ClickAwayListener>

  )
}

export default InputFileCustom;
