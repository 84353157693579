import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  ThemeProvider
} from '@mui/material';
import { ClickAwayListener, FormControl, Paper, Popper } from '@material-ui/core';
import { BsFilter } from 'react-icons/bs';
import DatePicker from 'react-datepicker';
import colors from 'apps/broker/styles/colors';
import DownloadBeneficiariesData from '../DownloadBeneficiariesData';
import { FiltersInfos } from './styles';

const Filters = ({
  theme,
  setFilterStatus,
  setFilterTipo,
  anchorElDataFilter,
  setAnchorElDataFilter,
  dateFilterValue,
  setDateFilterValue,
  dataTipoFilter,
  setDataTipoFilter,
  allEstipulantes,
  filterTipo,
  filterStatus,
  initialDate,
  setInitialDate,
  finalDate,
  setFinalDate,
  beneficiariesFilter,
  filterByEstipulante
}) => {
  const filterByStatusMovimentacao = (status) => {
    setFilterStatus(status);
  };

  const filterByTipo = (tipo) => {
    setFilterTipo(tipo);
  };

  const handleCloseDataFilterPopper = (event) => {
    const classList = event.target.className ? event.target.className : '';
    const classListParentNode = event.target.parentNode
      ? event.target.parentNode.className
      : 'close';
    const classListString =
      typeof classList === 'object' ? 'dont-close' : classList;
    const dontClose =
      classListString.includes('dont-close') ||
      classListParentNode.includes('dont-close');

    if (dontClose) return;

    if (event.target && anchorElDataFilter !== event.target) {
      setAnchorElDataFilter(null);
    }
  };

  const dataFilterClickHandler = ({ target }) => {
    setAnchorElDataFilter((prevState) => (prevState ? null : target));
  };

  const dateFilterChangeHandler = ({ target }) => {
    setDateFilterValue((prevState) => {
      if (prevState === target.value) {
        return '';
      }
      return target.value;
    });
  };

  const aberturaClickHandler = () => {
    setDataTipoFilter((prevState) => ({
      ...prevState,
      abertura: !prevState['abertura']
    }));
  };

  const conclusaoClickHandler = () => {
    setDataTipoFilter((prevState) => ({
      ...prevState,
      conclusao: !prevState['conclusao']
    }));
  };


  return (
    <FiltersInfos>
      <div className="box-input">
        <label>Consultar por empresa</label>
        <select
          name="cars"
          id="cars"
          defaultValue=""
          className="select-estipulante"
          onChange={({ target }) => filterByEstipulante(target.value)}
        >
          <option value="" disabled>
            Selecione
          </option>
          <option value="mostrarTodos" style={{ fontWeight: 'bold' }}>
            Todas
          </option>
          {allEstipulantes &&
            allEstipulantes.map((empresa, index) => {
              return (
                <option value={empresa.razao_social}>
                  {empresa.razao_social}
                </option>
              );
            })}
        </select>
      </div>

      <div>
        <label className="label-title">Consultar por tipo</label>
        <ThemeProvider theme={theme}>
          <div className="status-options-container dont-close">
            <div className="checkbox-container dont-close">
              <Radio
                value="Todos"
                size="small"
                onClick={({ target }) => filterByTipo(target.value)}
                className="radio-input dont-close"
                color={'darkGenoaBlue'}
                checked={filterTipo === 'Todos'}
              />
              <label>Todos</label>
            </div>
            <div className="checkbox-container dont-close">
              <Radio
                value="Exclusão"
                onClick={({ target }) => filterByTipo(target.value)}
                size="small"
                className="radio-input dont-close"
                color={'darkGenoaBlue'}
                checked={filterTipo === 'Exclusão'}
              />
              <label>Exclusão</label>
            </div>
            <div className="checkbox-container dont-close">
              <Radio
                value="Inclusão"
                size="small"
                onClick={({ target }) => filterByTipo(target.value)}
                className="radio-input dont-close"
                color={'darkGenoaBlue'}
                checked={filterTipo === 'Inclusão'}
              />
              <label>Inclusão</label>
            </div>
          </div>
        </ThemeProvider>
      </div>

      <div>
        <label className="label-title">Consultar por Status</label>
        <ThemeProvider theme={theme}>
          <div className="status-options-container dont-close">
            <div className="checkbox-container dont-close">
              <Radio
                value="Todos"
                size="small"
                onClick={({ target }) =>
                  filterByStatusMovimentacao(target.value)
                }
                className="radio-input dont-close"
                color={'darkGenoaBlue'}
                checked={filterStatus === 'Todos'}
              />
              <label>Todos</label>
            </div>
            <div className="checkbox-container dont-close">
              <Radio
                value="Aberto"
                size="small"
                onClick={({ target }) =>
                  filterByStatusMovimentacao(target.value)
                }
                className="radio-input dont-close"
                color={'darkGenoaBlue'}
                checked={filterStatus === 'Aberto'}
              />
              <label>Em Aberto</label>
            </div>
            <div className="checkbox-container dont-close">
              <Radio
                value="Concluído"
                size="small"
                onClick={({ target }) =>
                  filterByStatusMovimentacao(target.value)
                }
                className="radio-input dont-close"
                color={'darkGenoaBlue'}
                checked={filterStatus === 'Concluído'}
              />
              <label>Concluído</label>
            </div>
          </div>
        </ThemeProvider>
      </div>

      <div className="filtro-data-container">
        <BsFilter size={20} onClick={dataFilterClickHandler} />
      </div>
      <Popper
        id={'id-data'}
        open={Boolean(anchorElDataFilter)}
        anchorEl={anchorElDataFilter}
        placement="bottom-end"
        transition
        disablePortal
      >
        <Box>
          <ClickAwayListener onClickAway={handleCloseDataFilterPopper}>
            <Paper className="data-filter-options dont-close" elevation={3}>
              <div className="filters-infos-second-row">
                <div className="data-checkbox-container">
                  <FormControl>
                    <RadioGroup
                      className="checkbox-data-group-container"
                      onClick={dateFilterChangeHandler}
                      value={dateFilterValue}
                    >
                      <FormControlLabel
                        value="desde inicio"
                        control={
                          <Radio
                            sx={{
                              padding: 0
                            }}
                            size="small"
                            className="radio-checkbox"
                          />
                        }
                        label="Desde o Início"
                      />
                      <FormControlLabel
                        value="ultima semana"
                        control={
                          <Radio
                            sx={{
                              padding: 0
                            }}
                            size="small"
                            className="radio-checkbox"
                          />
                        }
                        label="Última Semana"
                      />
                      <FormControlLabel
                        value="ultimo mes"
                        control={
                          <Radio
                            sx={{
                              padding: 0
                            }}
                            size="small"
                            className="radio-checkbox"
                          />
                        }
                        label="Último Mês"
                      />
                      <FormControlLabel
                        value="data personalizada"
                        control={
                          <Radio
                            sx={{
                              padding: 0
                            }}
                            size="small"
                            className="radio-checkbox"
                          />
                        }
                        label="Data Personalizada:"
                      />
                    </RadioGroup>
                    <div className="datepicker-box-space">
                      <div className="datepicker-group-container">
                        <div className="datepicker-selector-container">
                          <div className="span-date-container">
                            <span>Data Inicial:</span>
                          </div>

                          <DatePicker
                            className={
                              dateFilterValue === 'data personalizada'
                                ? 'input-data'
                                : 'input-data-disabled'
                            }
                            disabled={dateFilterValue !== 'data personalizada'}
                            selected={
                              dateFilterValue === 'data personalizada'
                                ? initialDate
                                : ''
                            }
                            popperPlacement="left-start"
                            locale="ptBR"
                            onChange={(value) => setInitialDate(value)}
                            placeholderText={
                              dateFilterValue === 'data personalizada'
                                ? '...'
                                : '...'
                            }
                            maxDate={new Date()}
                            dateFormat="dd/MM/yyyy"
                          />
                        </div>
                        <div className="datepicker-selector-container">
                          <div className="span-date-container">
                            <span>Data Final:</span>
                          </div>

                          <DatePicker
                            className={
                              dateFilterValue === 'data personalizada'
                                ? 'input-data'
                                : 'input-data-disabled'
                            }
                            disabled={dateFilterValue !== 'data personalizada'}
                            popperPlacement="right-start"
                            selected={
                              dateFilterValue === 'data personalizada'
                                ? finalDate
                                : ''
                            }
                            locale="ptBR"
                            onChange={(value) => setFinalDate(value)}
                            placeholderText={
                              dateFilterValue === 'data personalizada'
                                ? '...'
                                : '...'
                            }
                            maxDate={new Date()}
                            dateFormat="dd/MM/yyyy"
                          />
                        </div>
                      </div>
                    </div>
                  </FormControl>
                </div>
              </div>
            </Paper>
          </ClickAwayListener>
        </Box>
      </Popper>
      <Popper
        id={'id-data'}
        open={Boolean(anchorElDataFilter && dateFilterValue !== '')}
        anchorEl={anchorElDataFilter}
        placement="bottom-start"
        transition
        disablePortal
      >
        <Box>
          <ClickAwayListener onClickAway={(e) => console.log(e.target)}>
            <Paper className="data-filter-selection dont-close" elevation={6}>
              <div className="box-input dont-close">
                <div className="data-filters-container dont-close">
                  <div className="dont-close">
                    <Radio
                      className="dont-close"
                      size={'small'}
                      sx={{
                        padding: 0,
                        marginRight: '10px',
                        '&.Mui-checked': {
                          color: colors.genoaBlue
                        }
                      }}
                      checked={dataTipoFilter['abertura']}
                      onClick={aberturaClickHandler}
                    />
                    <span className="dont-close">Abertura</span>
                  </div>
                  <div className="dont-close">
                    <Radio
                      className="dont-close"
                      size={'small'}
                      sx={{
                        padding: 0,
                        marginRight: '10px',
                        '&.Mui-checked': {
                          color: colors.genoaBlue
                        }
                      }}
                      checked={dataTipoFilter['conclusao']}
                      onClick={conclusaoClickHandler}
                    />
                    <span className="dont-close">Conclusão</span>
                  </div>
                </div>
              </div>
            </Paper>
          </ClickAwayListener>
        </Box>
      </Popper>

      <DownloadBeneficiariesData beneficiariesFilter={beneficiariesFilter} />
    </FiltersInfos>
  );
};

export default Filters;
