import React from "react";
import { Wrapper } from "./styles-draweBeneficiarios";
import {XAxis, YAxis, Tooltip, ScatterChart, Scatter } from 'recharts';


import { useForm } from "react-hook-form";

import {
  BiPlus,
} from "react-icons/bi";


import { BiFolderOpen } from "react-icons/bi";

import api from "services/api";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import { FaBuilding } from "react-icons/fa";
import { BsCircleFill, BsFileEarmarkText, BsFillFileEarmarkTextFill } from "react-icons/bs";
import { useState } from "react";
import { Card } from "@material-ui/core";
import colors from "styles/colors";
import { FiEdit, FiX } from "react-icons/fi";
import ModalEditDocument from "./ModalEditDocument";
import ModalDeleteDocument from "./ModalDeleteDocument";
import Loading from "components/Loading";

const DrawerContratoDetalhes = (props) => {
  const contrato = props.contrato;
  const estipulante = props.estipulante;
  const [estipulantesInfo, setEstipulantesInfo] = useState([]);
  const [draggable, setDraggable] = useState(false);
  const [positionStart, setPositionStart] = useState(undefined);
  const [anosArrastados, setAnosArrastados] = useState(0);
  const [storedDelta, setStoredDelta] = useState(0);
  const [abaSelected, setAbaSelected] = React.useState("dados gerais")
  const { register, handleSubmit, setValue, setError, watch, formState: { errors } } = useForm();
  const [openModal, setOpenModal] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [selectedDocument, setSelectedDocument] = React.useState();
  const [message, setMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const user = useSelector((state) => state.userReducers.user);

  const onSubmit = data => {
    // console.log(data)
    if (data.arquivo?.length === 0 || data.arquivo === null) {
      setError(`${data.contrato}.arquivo`, {
        type: "manual"
      });
      return
    } else {


      const formData = new FormData()
      formData.append('file', data.arquivo[0])
      formData.append('nome_documento', data.nome_documento)

      const headers = {
        contrato: data.contrato,
        authorization: `Bearer ${user.token}`,
        type: 'Documento'
      }
      setLoading(true)
      api.post('/rh/file-contrato', formData, { headers: headers })
        .then(resp => {
          setMessage(resp.data)
          props.getData();
          setTimeout(() => {
            setMessage("")
          }, 3500)
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          setLoading(false)
        })
    }
  };

  const renderContratoIconStatus = () => {
    return (
      <>
        {
          contrato?.vigencia_final ? (
            <span>
              {(moment(contrato.vigencia_final) > moment(new Date())) ?
                (
                  <BsCircleFill className='status-in-cancel-process-circle-icon icon-status' size={15} />
                ) : (
                  <BsCircleFill className='status-canceled-active-circle-icon icon-status' size={15} />
                )}
            </span>
          ) : (
            <span>
              <BsCircleFill className='status-active-circle-icon icon-status' size={15} />
            </span>
          )
        }
      </>
    )
  }

  const renderContratoStatus = () => {
    return (
      <>
        {
          contrato?.vigencia_final ? (
            <>
              {(moment(contrato.vigencia_final) > moment(new Date())) ?
                (
                  "(Em Cancelamento)"
                ) : (
                  "(Inativo)"
                )}
            </>
          ) : (
            <>
              {"(Ativo)"}
            </>
          )
        }
      </>
    )
  }

  const renderContratoVidasAtivas = (contrato) => {
    const vidasAtivas = contrato?.beneficiarios.filter((item) => item.status === "Ativo");
    // console.log(vidasAtivas)
    return (
      <>
        {
          contrato && contrato.beneficiarios &&
            vidasAtivas.length > 0 ? (
            vidasAtivas.length > 1 ? (
              `${vidasAtivas.length} vidas`
            ) : `1 vida`
          ) : 'Sem vidas'
        }
      </>
    )
  }

  const renderEstipulantesVidasInfo = () => {
    // console.log(estipulantesInfo)
    const caracteristicas = estipulantesInfo[0]?.caracteristicas;
    if (estipulantesInfo.length && caracteristicas) {
      return (
        <>
          <div className="dados-gerais-title">
            <span >Estipulantes e distribuição de vidas</span>
          </div>
          <div className="estipulantes-info">
            <div className="cols-estipulantes-info">

              <div className="column-one-container">
                {
                  estipulantesInfo?.map((item, index) => {
                    return (
                      <div className="estipulante-name" key={index}>
                        <div>
                          <FaBuilding size={20} />
                        </div>
                        <span>{item.razao_social}</span>
                      </div>
                    )
                  })
                }
              </div>
              <div className="column-one-container">
                {
                  estipulantesInfo?.map((item, index) => {
                    return (
                      <div className="estipulante-vidas" key={index}>
                        {
                          renderContratoVidasAtivas(item)
                        }
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
          <div className="dados-gerais-contratos">
            <div className="dados-gerais-title">
              <span >Características contratuais</span>
              <BsFillFileEarmarkTextFill
                size={15}
                className="icon-contrato"
              />
            </div>
            <div className="contrato-caracteristicas">
              <div>
                {
                  contrato.produto_id === 1 ? (
                    <>
                      <div className="caracteristica-container">
                        <span className="caracteristica-title">Precificação:</span>
                        <span>{caracteristicas?.caracteristica}</span>
                      </div>
                      <div className="caracteristica-container">
                        <span className="caracteristica-title">Coparticipação:</span>
                        {/* {console.log(contrato)} */}
                        <span>
                          {
                            caracteristicas ?
                              caracteristicas.coparticipacao_details : "Sem Coparticipação"
                          }
                        </span>
                      </div>
                      <div className="caracteristica-container">
                        <span className="caracteristica-title">Forma de custeio:</span>
                        <span>
                          {
                            !caracteristicas ?
                              ' ' : caracteristicas.custeio_details
                          }
                        </span>
                      </div>
                    </>
                  ) : ""
                }
                {
                  contrato.produto_id === 2 ? (
                    <>
                      <div className="caracteristica-container">
                        <span className="caracteristica-title">Forma de custeio:</span>
                        <span>
                          {
                            !caracteristicas ?
                              ' ' : caracteristicas.custeio_details
                          }
                        </span>
                      </div>
                    </>
                  ) : ""
                }
              </div>
              <div>
                <div className="caracteristica-container">
                  <span className="caracteristica-title">Multa:</span>
                  <span>
                    {
                      caracteristicas ? (
                        caracteristicas.multa_details ?? caracteristicas.multa_details
                      ) : ''
                    }
                  </span>
                </div>
                <div className="caracteristica-container">
                  <span className="caracteristica-title">Mês de reajuste:</span>
                  <span>{contrato?.mes_aniversario}</span>
                </div>
              </div>
              <div>
                <div className="caracteristica-datas-container">
                  <span className="caracteristica-title">Dia de corte:</span>
                  <span>{contrato?.dia_corte}</span>
                </div>
                <div className="caracteristica-datas-container">
                  <span className="caracteristica-title">Dia da Fatura:</span>
                  <span>{contrato?.vencimento_fatura}</span>
                </div>
              </div>
            </div>
          </div>
        </>
      )
    }
    return ""
  }

  const renderContratoProdutoTipo = () => {
    switch (contrato.produto_id) {
      case 2:

        return "Odontológico"
      case 3:

        return "Vida"
      default:

        return "Saúde"
    }
  }

  const formatPercentageData = (value) => {
    const valueWithComma = value.slice(0, -1).replace(".", ",")

    return valueWithComma + '%'
  }

  const CustomTooltip = ({ active, payload, label }) => {
    // console.log("payload", payload);  //you check payload
    if (active) {
      return (
        <div className="custom-tooltip">
          <Card className="tooltip-card">
            <div className="tooltip-info-container">
              <span className="tooltip-info-title">Ano:</span>
              <span className="tooltip-info-value-important">{payload[0]?.value}</span>
            </div>
            <div className="tooltip-info-container">
              <span className="tooltip-info-title">Total:</span>
              <span className="tooltip-info-value-important">{formatPercentageData(payload[1]?.value)}</span>
            </div>
            <div className="tooltip-info-container">
              <span className="tooltip-info-title">Sinistralidade:</span>
              <span className="tooltip-info-value">{formatPercentageData(payload[0]?.payload?.sinistralidade)}</span>
            </div>
            <div className="tooltip-info-container">
              <span className="tooltip-info-title">VCMH:</span>
              <span className="tooltip-info-value">{formatPercentageData(payload[0]?.payload?.vcmh)}</span>
            </div>
            <div className="tooltip-info-container">
              <span className="tooltip-info-title">Negociado:</span>
              <span className="tooltip-info-value">{`${String((Number(payload[1]?.value) - (
                Number(payload[0]?.payload?.sinistralidade)
                + Number(payload[0]?.payload?.vcmh)
              )).toFixed(1)).replace('.', ',')
                }%`}</span>
            </div>
          </Card>
        </div>
      );
    }

    return (
      <div className="custom-tooltip">
        <Card className="tooltip-card">
          <div>5</div>
        </Card>
      </div>
    );
  };

  const draggableMovementHandler = (e) => {
    if (draggable) {
      if (!e || !e?.xValue) {
        setStoredDelta((prevState) => prevState + anosArrastados)
        setAnosArrastados(0)
        setPositionStart(undefined)
        return setDraggable(false)
      }
      if (e && e?.xValue) {
        const delta = window.event.x - positionStart

        // 1 ano a cada 65 pixels arrastado
        const deltaAnos = delta / 65;
        setAnosArrastados(deltaAnos)
      }

    }

  }

  const renderContratoYearDomain = () => {
    const reajustes = estipulantesInfo[0]?.reajustes;
    const yearPoints = reajustes?.map((item) => item.ano);
    const yearPointsOrdered = yearPoints?.sort((a, b) => a - b);
    const oldestReajuste = yearPointsOrdered[0];
    const latestReajuste = yearPointsOrdered[yearPointsOrdered.length - 1];
    let oldestDomain;
    let latestDomain;

    const yearInterval = latestReajuste - oldestReajuste
    // constante 0.3 é referente ao espaçamento do ponto nas laterais do gráfico 
    // para não cortar os pontos
    if (yearInterval > 4) {
      if (oldestReajuste - anosArrastados - storedDelta < oldestReajuste) {
        setAnosArrastados(0)
        setStoredDelta(0)
      }

      // 0.6 considerando 0.3 de margem do lado esquerdo e 0.3 p/ lado direito
      else if (oldestReajuste - 0.6 + 4 - anosArrastados - storedDelta > latestReajuste) {
        // console.log(oldestReajuste + 4 - 0.3 - anosArrastados - storedDelta)
        setAnosArrastados(0);
        setStoredDelta((oldestReajuste + 4) - latestReajuste - 0.6)
      }

      oldestDomain = oldestReajuste - 0.3 - anosArrastados - storedDelta;
      latestDomain = oldestReajuste + 4 - 0.3 - anosArrastados - storedDelta;

      return [oldestDomain, latestDomain]
    }

    return [oldestReajuste - 0.3, latestReajuste + 0.3]

  }

  const renderXDomain = () => {
    const xDomain = renderContratoYearDomain()
    const oldestDomain = xDomain[0];
    const latestDomain = xDomain[1];
    const yearInterval = latestDomain - oldestDomain;
    const ticks = [];

    for (let index = 0; index <= yearInterval; index += 1) {
      const indexDomain = Number(oldestDomain.toFixed(0)) + index;
      ticks.push(indexDomain);
    }
    return ticks
  }

  const renderReajustesChart = () => {
    const reajustes = estipulantesInfo[0]?.reajustes;

    if (reajustes && reajustes.length) {
      const chartPoints = reajustes?.map((reajuste) => {
        return {
          ano: reajuste.ano,
          total: reajuste.total,
          sinistralidade: reajuste.sinistralidade,
          vcmh: reajuste.vcmh
        }
      })
      const chartPointsOrdered = chartPoints?.sort((a, b) => a.ano - b.ano);
      let latestReajuste;
      let oldestReajuste;
      let xDomain = [];
      let yDomain = [];
      if (chartPointsOrdered) {
        // console.log('Anos Arrastados >>>', anosArrastados)

        latestReajuste = chartPointsOrdered[chartPointsOrdered.length - 1].ano
        oldestReajuste = chartPointsOrdered[0].ano;

        const yearInterval = latestReajuste - oldestReajuste;

        for (let index = yearInterval; index >= 0; index -= 1) {
          const anoIndex = latestReajuste - index
          xDomain.push(anoIndex);
        }

        const minMaxValues = chartPointsOrdered.reduce((previous, current, index) => {
          if (index === 1) {
            const reduceInfo = {
              maxValue: Number(previous.total) > Number(current.total) ? Number(previous.total) : Number(current.total),
              minValue: Number(previous.total) < Number(current.total) ? Number(previous.total) : Number(current.total)
            }
            return reduceInfo
          }
          if (Number(previous.minValue) > Number(current.total)) {
            previous.minValue = Number(current.total);
          }
          if (Number(previous.maxValue) < Number(current.total)) {

            previous.maxValue = Number(current.total);
          }
          return previous;
        });

        minMaxValues.maxValue = Number((Number(minMaxValues.maxValue) + 5).toFixed(1));

        if (Number(minMaxValues.minValue) - 5 < 0) {
          minMaxValues.minValue = 0;
        } else {
          minMaxValues.minValue = Number((Number(minMaxValues.minValue) - 5).toFixed(1));
        }

        yDomain = [minMaxValues.minValue, minMaxValues.maxValue]

      }
      return (
        <>
          <ScatterChart
            width={350}
            height={310}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: -20,
            }}
            onMouseMove={draggableMovementHandler}
            onMouseDown={() => {
              setDraggable(true);
              setPositionStart(window.event.x)
            }}
            onMouseUp={() => {
              setStoredDelta((prevState) => prevState + anosArrastados)
              setAnosArrastados(0)
              setPositionStart(undefined)
              setDraggable(false)
            }}
            onMouseLeave={() => {
              setStoredDelta((prevState) => prevState + anosArrastados)
              setAnosArrastados(0)
              setPositionStart(undefined)
              setDraggable(false)
            }}
          >

            <XAxis className="eixo-x" type="number" tick={{ fontSize: 12 }} fill={colors.genoaDarkBlue} dataKey="ano" name="Ano" scale="linear" domain={renderContratoYearDomain()} ticks={renderXDomain()} allowDataOverflow={true} />
            <YAxis type="number" tick={{ fontSize: 12 }} fill={colors.genoaDarkBlue} dataKey="total" name="Total" unit="%" domain={yDomain}>

            </YAxis>

            <Tooltip
              className="recharts-tooltip-wrapper"
              cursor={{ strokeDasharray: '3 3' }}
              content={<CustomTooltip />}
              contentStyle={{ color: 'red' }}
            />
            <Scatter name="Reajuste" data={chartPointsOrdered} lineJointType="natural" fill={colors.rhBlue} line shape="circle" />
          </ScatterChart>
        </>
      )
    }

  }

  const getContratoInfo = () => {

    api.get('/rh/estipulante-sub/contrato/details',
      {
        headers: {
          id: user.mainEstipulante,
          contrato: contrato.contrato_id,
          authorization: `Bearer ${user.token}`,
        }
      }).then((resp) => {
        // console.log(resp.data)
        setEstipulantesInfo(resp.data)
      })
  }

  React.useEffect(() => {
    getContratoInfo()
  }, [])

  return (
    <Wrapper>
      <header>
        <div className="estipulante-info-container">
          <span className="estipulante-razao-social">{estipulante?.razaoSocial}</span>
          <span className="estipulante-sub-title">{estipulante?.cnpj}</span>
        </div>
      </header>

      <section className="contrato-info-container">
        <div className="title-container">
          <span className="contrato-nome">{contrato?.nome_completo}</span>
          <span className="icon-status-container">{renderContratoIconStatus()}</span>
          <span className="status-title">{renderContratoStatus()}</span>
        </div>
        <div className="sub-title-container">
          <div className="info-container">
            <span className="info-title">Apólice:</span>
            <span className="info-content">{contrato?.numero_apolice}</span>
          </div>
          <div className="info-container">
            <span className="info-title">Vigência Inicial:</span>
            <span className="info-content">{moment(contrato.vigencia_inicial).format('DD/MM/YYYY')}</span>
          </div>
          <div className="info-container">
            <span className="info-title">Produto:</span>
            <span className="info-content">{renderContratoProdutoTipo()}</span>
          </div>
        </div>
      </section>
      <nav className="nav-abas">
        <div>
          <span
            className={abaSelected === "dados gerais" ? "selected" : ""}
            onClick={() => setAbaSelected("dados gerais")}
          >
            Dados Gerais
          </span>
        </div>
        <div>
          <span
            className={abaSelected === "documentos" ? "selected" : ""}
            onClick={() => setAbaSelected("documentos")}
          >
            Documentos
          </span>
        </div>
      </nav>
      {
        abaSelected === "dados gerais" ? (
          <>
            <section className="dados-container">
              <div className="dados-gerais-estipulantes-info">
                {renderEstipulantesVidasInfo()}
              </div>
            </section>
            {
              estipulantesInfo[0]?.reajustes?.length ? (
                <section className="reajustes-dados-container">
                  <div className="reajustes-title">
                    <span >Reajustes anuais e sinistralidade</span>
                  </div>
                  <div className="chart-info-container">
                    {
                      renderReajustesChart()
                    }
                  </div>
                </section>
              ) : ''
            }
          </>
        ) : ""
      }
      {
        abaSelected === "documentos" ? (
          <>
            <Loading open={loading} setOpen={() => setLoading(false)} />
            <section className="documentos-container">
              <div>
                <span className="documentos-title">Documentos</span>
              </div>
              <div className="listFile">
                <ul>
                  {
                    contrato?.documentos?.map(x => {
                      return (
                        <>
                          {/* {console.log(x)} */}
                          <li className="file-document" key={x.file_id}>
                            <BsFileEarmarkText
                              size={17}
                              className="icon-documento"
                            />
                            <a
                              href={x.file_link}
                              className="link-text"
                            >
                              {x.file_type}
                            </a>
                            {
                              Boolean(x.inserido_por_rh) ? (
                                <>
                                  <FiX className="delete-file" onClick={() => {
                                    setDeleteModal(true)
                                    setSelectedDocument(x)
                                  }} />
                                  <FiEdit className="edit-file" onClick={() => {
                                    setOpenModal(true)
                                    setSelectedDocument(x)
                                  }} />
                                </>
                              ) : ""
                            }
                          </li>
                        </>
                      )
                    })
                  }
                </ul>
                <div className="div-input-file">
                  <form onSubmit={handleSubmit((data) => onSubmit(data[contrato.contrato_id]))}>

                    <label className="label-file">
                      {watch(`${contrato.contrato_id}.arquivo`)?.[0]?.name ?
                        <span>
                          <BiFolderOpen size={19} className="icon-folder" />
                          {`${watch(`${contrato.contrato_id}.arquivo`)[0]?.name.slice(0, 45)}`}
                        </span> : <span><BiPlus /> Documento </span>
                      }
                      <input
                        {...register(`${contrato.contrato_id}.arquivo`, { required: false })}
                        className="input-file" type="file" />

                      <input
                        {...register(`${contrato.contrato_id}.contrato`, { required: false })}
                        value={contrato.contrato_id}
                        className="input" type="hidden" />
                    </label>

                    <div className="div-input-files">
                      <input
                        {...register(`${contrato.contrato_id}.nome_documento`, { required: false })}
                        className="document-name" type="text" placeholder="Nome do documento" />

                      <div className="buttons">
                        {watch(`${contrato.contrato_id}.arquivo`)?.[0]?.name &&
                          <button type="button" onClick={() => { setValue(`${contrato.contrato_id}.arquivo`, null); }} className="btn-clear"><FiX size={16} /></button>
                        }

                        <button type="submit" className="btn">Salvar</button>
                      </div>
                    </div>
                    {errors[contrato.contrato_id]?.arquivo && <span className="error-msg">Selecione um documento para enviar</span>}
                    {message ? <span className="confirmation-msg">{message}</span> : ""}
                  </form>
                </div>
              </div>
            </section>
            <ModalEditDocument
              openModal={openModal}
              setOpenModal={setOpenModal}
              document={selectedDocument}
              getData={props.getData}
            />
            <ModalDeleteDocument
              openModal={deleteModal}
              setOpenModal={setDeleteModal}
              document={selectedDocument}
              getData={props.getData}
            />
          </>
        ) : ""
      }
    </Wrapper>
  );
};

export default DrawerContratoDetalhes;
