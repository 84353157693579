import React from 'react';
import { TabelaConciergeContainer } from './styles';

const TabelaConcierge = ({
  data,
  dataType,
  keyDescription,
  valueDescription,
  dataKey,
  dataValue,
  formatTimeValue,
  estipulanteSelected,
  setEstipulanteSelected
}) => {
  const renderTableDataValue = (value) => {
    switch (dataType) {
      case 'minutes':
        return (
          <>
            {formatTimeValue(value)?.hours ? (
              <>
                <span>{`${formatTimeValue(value)?.hours} h `}</span>
              </>
            ) : (
              ''
            )}
            {formatTimeValue(value)?.minutes ? (
              <>
                <span> {formatTimeValue(value)?.minutes} min</span>
              </>
            ) : (
              ''
            )}
          </>
        );

      case 'nps':
        return `${Math.round(value)}%`;

      default:
        return value;
    }
  };

  const renderTableValues = () => {
    const orderedData = data?.sort(
      (a, b) => Number(b[dataValue]) - Number(a[dataValue])
    );
    return orderedData?.map((item) => {
      return (
        <tr
          className={`
            tbody_row ${
              item?.estipulante_id === estipulanteSelected ? 'selected' : ''
            }        
          `}
          onClick={() => {
            setEstipulanteSelected((prevState) =>
              prevState !== item?.estipulante_id
                ? item?.estipulante_id
                : undefined
            );
          }}
        >
          <td>{item[dataKey]}</td>
          <td>{renderTableDataValue(item[dataValue])}</td>
        </tr>
      );
    });
  };

  return (
    <TabelaConciergeContainer>
      <table>
        <thead>
          <tr className="thead_row">
            <th>{keyDescription}</th>
            <th>{valueDescription}</th>
          </tr>
        </thead>
        <tbody>{renderTableValues()}</tbody>
      </table>
    </TabelaConciergeContainer>
  );
};

export default TabelaConcierge;
