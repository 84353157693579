import React from 'react';
import { FilterTasksWrapper } from './styles';
import { Radio, ThemeProvider, createTheme } from '@mui/material';
import ReactDatePicker from 'react-datepicker';

const theme = createTheme({
  palette: {
    genoaBlue: {
      main: '#456AED'
    },
    darkGenoaBlue: {
      main: '#1E2552'
    }
  }
});

const FilterTasks = ({
  corretores,
  setVencimento,
  vencimento,
  setResponsavel,
  responsavel,
  setDataInicial,
  dataInicial,
  setDataFinal,
  dataFinal,
  setStatus,
  status,
  resetTasksFilters
}) => {
  const renderCorretoresOptions = () => {
    return corretores?.map((item) => (
      <option key={`corretor-${item?.id}`} value={item.id}>
        {item.nome}
      </option>
    ));
  };

  const selectResponsavelChangeHandler = (e) => {
    setResponsavel(e.target.value);
    resetTasksFilters();
  };

  return (
    <FilterTasksWrapper>
      <div className="filter__container">
        <div className="filter_title__task">
          <span>Vencimento</span>
        </div>
        <div className="filter_options">
          <ThemeProvider theme={theme}>
            <div className="radio__container">
              <Radio
                onClick={() => setVencimento('a_fazer')}
                color={'darkGenoaBlue'}
                checked={vencimento === 'a_fazer'}
              />
              <label>À fazer</label>
            </div>
            <div className="radio__container">
              <Radio
                onClick={() => setVencimento('vencidas')}
                color={'darkGenoaBlue'}
                checked={vencimento === 'vencidas'}
              />
              <label>Vencidas</label>
            </div>
            <div className="radio__container">
              <Radio
                onClick={() => setVencimento('hoje')}
                color={'darkGenoaBlue'}
                checked={vencimento === 'hoje'}
              />
              <label>Hoje</label>
            </div>
            <div className="radio__container">
              <Radio
                onClick={() => setVencimento('amanha')}
                color={'darkGenoaBlue'}
                checked={vencimento === 'amanha'}
              />
              <label>Amanhã</label>
            </div>
            <div className="radio__container">
              <Radio
                onClick={() => setVencimento('esta_semana')}
                color={'darkGenoaBlue'}
                checked={vencimento === 'esta_semana'}
              />
              <label>Esta semana</label>
            </div>
            <div className="radio__container">
              <Radio
                onClick={() => setVencimento('periodo')}
                color={'darkGenoaBlue'}
                checked={vencimento === 'periodo'}
              />
              <label>Período</label>
              <div>
                {vencimento === 'periodo' ? (
                  <div className="datepicker-box-space">
                    <div className="datepicker-container">
                      <span>Data Inicial:</span>
                      <ReactDatePicker
                        className="input-date"
                        locale="ptBR"
                        selected={dataInicial}
                        onChange={(date) => {
                          setDataInicial(date);
                          if (!dataFinal) {
                            setDataFinal(date);
                          }
                        }}
                        placeholderText="..."
                        dateFormat="dd/MM/yyyy"
                      />
                    </div>
                    <div className="datepicker-container">
                      <span>Data Final:</span>
                      <ReactDatePicker
                        className="input-date"
                        disabled={dataInicial === ''}
                        locale="ptBR"
                        selected={dataFinal}
                        onChange={(date) => setDataFinal(date)}
                        placeholderText="..."
                        dateFormat="dd/MM/yyyy"
                      />
                    </div>
                  </div>
                ) : (
                  <div className="datepicker-box-space"></div>
                )}
              </div>
            </div>
          </ThemeProvider>
        </div>
      </div>

      <div className="filter__container">
        <div className="filter_title__task">
          <span>Status</span>
        </div>
        <div className="filter_options">
          <ThemeProvider theme={theme}>
            <div className="radio__container">
              <Radio
                onClick={() => setStatus('Aberta')}
                color={'darkGenoaBlue'}
                checked={status === 'Aberta'}
              />
              <label>Aberta</label>
            </div>
            <div className="radio__container">
              <Radio
                onClick={() => setStatus('Concluída')}
                color={'darkGenoaBlue'}
                checked={status === 'Concluída'}
              />
              <label>Concluída</label>
            </div>
          </ThemeProvider>
        </div>
      </div>

      <div className="filter__container">
        <div className="filter_title__task">
          <span>Responsável</span>
        </div>
        <div className="filter_options">
          <ThemeProvider theme={theme}>
            <div>
              <select
                className="select-arrow"
                value={responsavel}
                onChange={selectResponsavelChangeHandler}
              >
                <option value={undefined}>Selecione</option>
                {renderCorretoresOptions()}
              </select>
            </div>
          </ThemeProvider>
        </div>
      </div>
    </FilterTasksWrapper>
  );
};

export default FilterTasks;
