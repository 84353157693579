import styled from 'styled-components';
import colors from 'styles/colors';

export const DrawerNovoContratoWrapper = styled.div`
  border-left: 10px solid ${colors.genoaBlue};
  border-radius: 10px;
  background-color: ${colors.white};
  height: 550px;
  width: 800px;
  position: fixed;
  left: calc(65% - 425px);
  padding: 25px;

  display: flex;
  flex-direction: column;
  row-gap: 20px;

  animation: up-down 0.2s forwards;


  @keyframes up-down {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: calc(50% - 200px);
    }
  }

  .title {
    font-family: Avenir Next Geo W05 Heavy;
    font-size: 1.2em;

    display: flex;
    flex-direction: column;
    row-gap: 25px;
  }

  .container {
    display: grid;
    grid-template-columns: 1fr 1.2fr;
    column-gap: 50px;
  }

  .primeira_coluna {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 15px;
  }

  .segunda_coluna {
    display: grid !important;
    grid-template-rows: 1fr 1fr 50px 1fr;
    row-gap: 15px;
  }

  .div_default {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  .div_input {
    height: 47px;
  }

  .input_title {
    font-family: Avenir Next Geo W05 Demi;
    font-size: 0.8em;
  }

  label {
    font-family: Avenir Next Geo W05 Regular;
    font-size: 0.8em;
  }

  .MuiRadio-root {
    padding: 0;
  }

  .radio_div {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
  }

  .radio_container {
    display: flex;
    column-gap: 5px;
    align-items: center;

  }

  .MuiSvgIcon-root {
    font-size: 0.8em;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-bottom: 0px;
    text-transform: uppercase;
    background-repeat: no-repeat;
    background-position: 97% 50%;
    background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 9L0.73686 0.75L10.2631 0.75L5.5 9Z' fill='%23456AED'/%3E%3C/svg%3E%0A");
    padding: 4px;
    padding-left: 8px;
    border-radius: 10px;
    text-transform: none;

    :focus-visible {
      outline: 1px ${colors.genoaBlue}f5 auto;
    }
  }

  input {
    border: none;
    padding-bottom: 3px;
    width: 170px;
    border-bottom: 1px solid ${colors.rhLightGrey};

    :focus-visible {
      padding-bottom: 2px;
      border-bottom: 2px solid ${colors.genoaBlue};
    }
  }

  textarea {
    height: 100%;
    font-size: 0.8em;
    padding: 10px;

    :focus-visible {
      outline: 1px ${colors.genoaBlue} auto !important;
    }
  }

  .btn_container {
    position: absolute;
    right: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    column-gap: 30px;
  }

  .aba_btn {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font-size: 0.8em;
    cursor: pointer;
    outline: inherit;
    font-family: Avenir Next Geo W05 Demi;
    color: ${colors.genoaDarkBlue};

    svg {
      font-size: 1.3em;
      transform: translateY(1px);
    }

    :hover {
      cursor: pointer;
    }
  }

  .selected {
    color: ${colors.genoaBlue};

    border-bottom: 2px solid ${colors.genoaBlue};
  }

  .planos_wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  .planos_container {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    row-gap: 30px;
    max-height: 410px;
    flex-wrap: wrap;
  }

  .div_planos {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
  }

  .btn_trash {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font-size: 0.9em;
    cursor: pointer;
    outline: inherit;
    font-family: Avenir Next Geo W05 Demi;
    color: ${colors.genoaPink};
  }

  .doc_wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  .doc_container {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    row-gap: 20px;
    max-height: 410px;
    flex-wrap: wrap;
  }

  .input-label {
        
    input {
      display: none;
    }

    span {
      display: flex;
      font-size: 12.8px;
      align-items: center;
      background: transparent;
      color: ${colors.genoaDarkBlue} ;
      font-family: "Avenir Next GEO W05 Demi";           
      cursor: pointer;           
    }
  }

  .file {
    max-width: 250px;
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    align-items: center;
    margin-top: 4px;

    span {
      font-size: 0.80em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: ${colors.genoaGrey};
    }

    .btn-clear {
      background: transparent;
      border: none;
      cursor: pointer;
      font-size: .8rem;
      display: flex;
      align-items: center;

      svg {
   
        font-size: 13.6px;
        /* font-size: 1rem; */
        color: ${colors.genoaBlue};
      }

      &:hover {
        svg {
          color: #fb2916;
        }
      }
    }

  }

  .container_btn_enviar {
    position: absolute;
    bottom: 25px;
  }

  .btn_enviar {
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    background-color: ${colors.genoaBlue};
    color: white;
    padding: 4px 8px;
    font-family: Avenir Next Geo W05 Demi;
    text-transform: uppercase;
    font-size: 0.9em;
    border-radius: 5px;
    top: 0;
    right: 0;
  }

  .disabled {
    background-color: ${colors.genoaDisabledGrey};
  }

  .button_container {
    display: flex;
    flex-direction: row;
    column-gap: 15px;

    button {
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      background-color: inherit;
      color: ${colors.genoaBlue};
  
      font-family: Avenir Next Geo W05 Demi;
      text-transform: uppercase;
      font-size: 0.75em;
      top: 0;
      right: 0;

      :hover {
        text-decoration: underline;
      }
    }
  }

  .check_confirm {
    color: green;
    font-size: 0.80em;
  }

  .cancel_wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  .cancelamento_container {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }

  .date_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
  }
  
  .custom_datepicker {
    width: 195px;
  }

  .datepicker {
    border: none;
    border-bottom: 1px solid ${colors.genoaBlue};
    display: inline-block;
    overflow: hidden;
    text-align: inherit;
    font-family: 'Avenir Next GEO W05 Regular';
    height: 22px;
    padding-bottom: 6px;
  }

`