import styled from "styled-components";
import colors from "styles/colors";

export const TabOptionsWrapper = styled.div`
  @media (max-height: 580px) {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    border-top-right-radius: 13.34px;
    border-bottom-right-radius: 13.34px;
    background-color: ${colors.rhTabLightGrey};

    padding-top: 26.68px;

    .close-icon-container {
      display: flex;
      flex-direction: row-reverse;
      padding-right: 30px;
      padding-left: 23.345px;
      
  
      .close-icon {
        color: ${colors.genoaPink};
  
        :hover {
          cursor: pointer;
        }
      }
    }
    
    .scrollable {
      height: calc(100vh - 120px);
      max-height: 360px;
      overflow-y: auto;
  
      ::-webkit-scrollbar {
        width: 0px;
        background: transparent;
      }
    }
  
    .aba-options-container {
      display: flex;
      flex-direction: column;
      
      
      
      .aba-option {
        height: 26.67px;
        display: grid;
        grid-template-columns: 16.75px 1fr 11.339px;
        column-gap: 8px;
        align-items: center;
        padding-right: 18.676px;
        padding-left: 23.345px;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
  
        span {
          font-family: Avenir Next GEO W05 Medium;
          font-size: 10.672px;
        }
  
        .icon-container {
          display: flex;
          align-items: center;
          justify-content: center;
  
          color: ${colors.genoaDarkBlue};
        }
  
        .check_icon {
          color: ${colors.green};
        }
  
        :hover {
          cursor: pointer;
          
          background-color: ${colors.rhTabDarkGrey};
        }
  
      }
  
      .informacoes {
        padding-left: 33.35px;
        padding-right: 20px;
      }
  
      .selected {
        background: linear-gradient(45deg, ${colors.rhTabDarkGrey}, rgba(255, 255, 255, 0));
      }
  
      .abas-options-scrollable {
  
        ::-webkit-scrollbar {
          width: 4px;
          background: transparent;
        }
    
        ::-webkit-scrollbar-thumb {
          background: ${colors.rhLightGrey};
          box-shadow: none;
          border-radius: 14px;
        }
    
        ::-webkit-scrollbar-track {
          box-shadow: inset 0 0 2px ${colors.rhLightGrey};
          border-radius: 14px;
        }
      }
  
      .abas-options-expandable {
        transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        overflow: hidden;
      }
  
      .arrow-icon {
        transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
   
      }
  
      .aba-options-expandable-title {
        height: 26.68px;
        display: grid;
        grid-template-columns: 1fr 16.75px;
        column-gap: 8px;
        align-items: center;
        padding-right: 30px;
        padding-left: 26.68px;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
  
        span {
          font-family: Avenir Next GEO W05 Medium;
        }
  
        .icon-container {
          display: flex;
          align-items: center;
          justify-content: center;
  
          color: ${colors.genoaDarkBlue};
        }
  
        :hover {
          cursor: pointer;
          
          // background-color: ${colors.rhTabDarkGrey};
        }
  
      }
    }
  }

  @media (min-height: 580px) and (max-height: 650px) {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    background-color: ${colors.rhTabLightGrey};
  
    padding-top: 32px;
  
  
    .close-icon-container {
      display: flex;
      flex-direction: row-reverse;
      padding-right: 36px;
      padding-left: 28px;
      
  
      .close-icon {
        color: ${colors.genoaPink};
  
        :hover {
          cursor: pointer;
        }
      }
    }
    
    .scrollable {
      height: calc(100vh - 144px);
      max-height: 432px;
      overflow-y: auto;
  
      ::-webkit-scrollbar {
        width: 0px;
        background: transparent;
      }
    }
  
    .aba-options-container {
      display: flex;
      flex-direction: column;
      
      
      
      .aba-option {
        height: 32px;
        display: grid;
        grid-template-columns: 20px 1fr 13.6px;
        column-gap: 9.6px;
        align-items: center;
        padding-right: 22.4px;
        padding-left: 28px;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
  
        span {
          font-family: Avenir Next GEO W05 Medium;
          font-size: 12.8px;
        }
  
        .icon-container {
          display: flex;
          align-items: center;
          justify-content: center;
  
          color: ${colors.genoaDarkBlue};
        }
  
        .check_icon {
          color: ${colors.green};
        }
  
        :hover {
          cursor: pointer;
          
          background-color: ${colors.rhTabDarkGrey};
        }
  
      }
  
      .informacoes {
        padding-left: 40px;
        padding-right: 24px;
      }
  
      .selected {
        background: linear-gradient(45deg, ${colors.rhTabDarkGrey}, rgba(255, 255, 255, 0));
      }
  
      .abas-options-scrollable {
  
        ::-webkit-scrollbar {
          width: 4.8px;
          background: transparent;
        }
    
        ::-webkit-scrollbar-thumb {
          background: ${colors.rhLightGrey};
          box-shadow: none;
          border-radius: 11.2px;
        }
    
        ::-webkit-scrollbar-track {
          box-shadow: inset 0 0 2px ${colors.rhLightGrey};
          border-radius: 11.2px;
        }
      }
  
      .abas-options-expandable {
        transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        overflow: hidden;
      }
  
      .arrow-icon {
        transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
   
      }
  
      .aba-options-expandable-title {
        height: 32px;
        display: grid;
        grid-template-columns: 1fr 20px;
        column-gap: 9.6px;
        align-items: center;
        padding-right: 36px;
        padding-left: 32px;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
  
        span {
          font-family: Avenir Next GEO W05 Medium;
        }
  
        .icon-container {
          display: flex;
          align-items: center;
          justify-content: center;
  
          color: ${colors.genoaDarkBlue};
        }
  
        :hover {
          cursor: pointer;
          
          // background-color: ${colors.rhTabDarkGrey};
        }
  
      }
    }
  }

  @media (min-height: 650px) and (max-height: 720px) {
    display: flex;
    flex-direction: column;
    row-gap: 27px;
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;
    background-color: ${colors.rhTabLightGrey};
  
    padding-top: 36px;
  
  
    .close-icon-container {
      display: flex;
      flex-direction: row-reverse;
      padding-right: 40.5px;
      padding-left: 31.5px;
      
  
      .close-icon {
        color: ${colors.genoaPink};
  
        :hover {
          cursor: pointer;
        }
      }
    }
    
    .scrollable {
      height: calc(100vh - 162px);
      max-height: 486px;
      overflow-y: auto;
  
      ::-webkit-scrollbar {
        width: 0px;
        background: transparent;
      }
    }
  
    .aba-options-container {
      display: flex;
      flex-direction: column;
      
      
      
      .aba-option {
        height: 36px;
        display: grid;
        grid-template-columns: 22.5px 1fr 15.3px;
        column-gap: 10.8px;
        align-items: center;
        padding-right: 25.2px;
        padding-left: 31.5px;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
  
        span {
          font-family: Avenir Next GEO W05 Medium;
          font-size: 14.4px;
        }
  
        .icon-container {
          display: flex;
          align-items: center;
          justify-content: center;
  
          color: ${colors.genoaDarkBlue};
        }
  
        .check_icon {
          color: ${colors.green};
        }
  
        :hover {
          cursor: pointer;
          
          background-color: ${colors.rhTabDarkGrey};
        }
  
      }
  
      .informacoes {
        padding-left: 45px;
        padding-right: 27px;
      }
  
      .selected {
        background: linear-gradient(45deg, ${colors.rhTabDarkGrey}, rgba(255, 255, 255, 0));
      }
  
      .abas-options-scrollable {
  
        ::-webkit-scrollbar {
          width: 5.4px;
          background: transparent;
        }
    
        ::-webkit-scrollbar-thumb {
          background: ${colors.rhLightGrey};
          box-shadow: none;
          border-radius: 12.6px;
        }
    
        ::-webkit-scrollbar-track {
          box-shadow: inset 0 0 1.8px ${colors.rhLightGrey};
          border-radius: 12.6px;
        }
      }
  
      .abas-options-expandable {
        transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        overflow: hidden;
      }
  
      .arrow-icon {
        transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
   
      }
  
      .aba-options-expandable-title {
        height: 36px;
        display: grid;
        grid-template-columns: 1fr 22.5px;
        column-gap: 10.8px;
        align-items: center;
        padding-right: 40.5px;
        padding-left: 36px;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
  
        span {
          font-family: Avenir Next GEO W05 Medium;
        }
  
        .icon-container {
          display: flex;
          align-items: center;
          justify-content: center;
  
          color: ${colors.genoaDarkBlue};
        }
  
        :hover {
          cursor: pointer;
          
          // background-color: ${colors.rhTabDarkGrey};
        }
  
      }
    }
  }

  @media (min-height: 720px) {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: ${colors.rhTabLightGrey};
  
    padding-top: 40px;
  
  
    .close-icon-container {
      display: flex;
      flex-direction: row-reverse;
      padding-right: 45px;
      padding-left: 35px;
      
  
      .close-icon {
        color: ${colors.genoaPink};
  
        :hover {
          cursor: pointer;
        }
      }
    }
    
    .scrollable {
      height: calc(100vh - 180px);
      max-height: 540px;
      overflow-y: auto;
  
      ::-webkit-scrollbar {
        width: 0px;
        background: transparent;
      }
    }
  
    .aba-options-container {
      display: flex;
      flex-direction: column;
      
      
      
      .aba-option {
        height: 40px;
        display: grid;
        grid-template-columns: 25px 1fr 17px;
        column-gap: 12px;
        align-items: center;
        padding-right: 28px;
        padding-left: 35px;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
  
        span {
          font-family: Avenir Next GEO W05 Medium;
          font-size: 16px;
        }
  
        .icon-container {
          display: flex;
          align-items: center;
          justify-content: center;
  
          color: ${colors.genoaDarkBlue};
        }
  
        .check_icon {
          color: ${colors.green};
        }
  
        :hover {
          cursor: pointer;
          
          background-color: ${colors.rhTabDarkGrey};
        }
  
      }
  
      .informacoes {
        padding-left: 50px;
        padding-right: 30px;
      }
  
      .selected {
        background: linear-gradient(45deg, ${colors.rhTabDarkGrey}, rgba(255, 255, 255, 0));
      }
  
      .abas-options-scrollable {
  
        ::-webkit-scrollbar {
          width: 6px;
          background: transparent;
        }
    
        ::-webkit-scrollbar-thumb {
          background: ${colors.rhLightGrey};
          box-shadow: none;
          border-radius: 14px;
        }
    
        ::-webkit-scrollbar-track {
          box-shadow: inset 0 0 2px ${colors.rhLightGrey};
          border-radius: 14px;
        }
      }
  
      .abas-options-expandable {
        transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        overflow: hidden;
      }
  
      .arrow-icon {
        transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
   
      }
  
      .aba-options-expandable-title {
        height: 40px;
        display: grid;
        grid-template-columns: 1fr 25px;
        column-gap: 12px;
        align-items: center;
        padding-right: 45px;
        padding-left: 40px;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
  
        span {
          font-family: Avenir Next GEO W05 Medium;
        }
  
        .icon-container {
          display: flex;
          align-items: center;
          justify-content: center;
  
          color: ${colors.genoaDarkBlue};
        }
  
        :hover {
          cursor: pointer;
          
          // background-color: ${colors.rhTabDarkGrey};
        }
  
      }
    }
  }
`