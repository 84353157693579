import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Button, CircularProgress } from '@material-ui/core';
import { AiFillCaretRight } from 'react-icons/ai';
import { useState } from 'react';
import ReactInputMask from 'react-input-mask';
import { Form, Wrapper } from './styles';
import services from 'apps/broker/services';

const DrawerUpdateContatoSeguradora = ({
  contato,
  seguradorasData,
  setSeguradorasData,
  onClose
}) => {
  const [nomeContato, setNomeContato] = useState(contato.nome);
  const [telefoneContato, setTelefoneContato] = useState(contato.telefone);
  const [emailContato, setEmailContato] = useState(contato.email);
  const [funcaoContato, setFuncaoContato] = useState(contato.funcao);

  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
  const [displayConfirmationMessage, setDisplayConfirmationMessage] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const adicionarContatoClickHandler = async () => {
    if (
      !(
        nomeContato.length &&
        telefoneContato.length &&
        emailContato.length &&
        funcaoContato.length
      )
    ) {
      setDisplayErrorMessage(true);
      setTimeout(() => {
        setDisplayErrorMessage(false);
      }, 4000);

      return;
    }
    setDisplayErrorMessage(false);
    const contatoAtualizado = {
      id: contato.id,
      nome: nomeContato,
      telefone: telefoneContato,
      email: emailContato,
      funcao: funcaoContato
    };

    await updateContact(contatoAtualizado);

    return;
  };

  const updateContact = async (updatedContact) => {
    const body = {
      contato: updatedContact
    };

    setLoading(true);

    const response = await services.insuranceCompanies.updateContactInformation(
      body
    );
    if (response.status === 200) {
      setDisplayConfirmationMessage(true);
      setTimeout(() => {
        setDisplayConfirmationMessage(false);
      }, 4000);
      if (response.data) {
        setSeguradorasData((prevState) => {
          const newState = prevState.map((seguradora) => {
            if (seguradora.id === contato.seguradora_id) {
              const contatosDepoisAtualizar = seguradora.contatos.map(
                (item) => {
                  if (item.id === contato.id) {
                    return updatedContact;
                  }
                  return item;
                }
              );
              seguradora.contatos = contatosDepoisAtualizar;
            }
            return seguradora;
          });
          return newState;
        });
      }
    }
    setLoading(false);
  };

  const phoneMask = () => {
    return telefoneContato[5] !== '9'
      ? '(99) 9999 - 9999'
      : '(99) 99999 - 9999';
  };

  const telefoneInputChangeHandler = ({ target }) => {
    setTelefoneContato(target.value);
  };

  const renderMensagemDeErro = () => {
    if (displayErrorMessage) {
      return (
        <div className="mensagem-erro-container">
          <span>* Por favor, preencher todos os campos do formulário</span>
        </div>
      );
    }

    return <div className="mensagem-erro-container"></div>;
  };

  return (
    <Wrapper>
      <header>
        <div className="back">
          <span onClick={onClose} className="icon-back">
            <ArrowBackIcon fontSize="inherit" />
          </span>
        </div>
        <h2>Atualizar Contato de Seguradora</h2>
      </header>
      <Form>
        <div className="form-container">
          <div className="seguradora-nome-contato-container">
            <span className="input-span-title">Nome do Contato</span>
            <input
              type={'text'}
              value={nomeContato}
              onChange={(e) => setNomeContato(e.target.value)}
              className="input-seguradoras-texto"
            />
          </div>
          <div className="seguradora-telefone-contato-container">
            <span className="input-span-title">Telefone</span>
            <ReactInputMask
              type={'text'}
              value={telefoneContato}
              mask={phoneMask()}
              maskChar={null}
              onChange={telefoneInputChangeHandler}
              className="input-seguradoras-texto"
            />
          </div>
          <div className="seguradora-email-contato-container">
            <span className="input-span-title">Email</span>
            <input
              type={'text'}
              value={emailContato}
              onChange={(e) => setEmailContato(e.target.value)}
              className="input-seguradoras-texto"
            />
          </div>
          <div className="seguradora-funcao-contato-container">
            <span className="input-span-title">Função</span>
            <input
              type={'text'}
              value={funcaoContato}
              onChange={(e) => setFuncaoContato(e.target.value)}
              className="input-seguradoras-texto"
            />
          </div>
          {renderMensagemDeErro()}
          <div className="botao-adicionar-contato-container">
            <Button
              variant="contained"
              className="botao-adicionar-contato"
              onClick={adicionarContatoClickHandler}
            >
              {loading ? (
                <>
                  <CircularProgress size={19.2} className="loading-spinner" />
                </>
              ) : (
                <>
                  Atualizar
                  <AiFillCaretRight className="icone-caret-direita" size={15} />
                </>
              )}
            </Button>
            {displayConfirmationMessage ? (
              <div className="mensagem-confirmacao-container">
                <span>Contato Atualizado com Sucesso!</span>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </Form>
    </Wrapper>
  );
};

export default DrawerUpdateContatoSeguradora;
