import React from "react";
import { Wraper, Footer } from './styles'
import HeaderQuestionario from 'apps/lp/components/Header-Questionario';
// import LogoCoinsure from 'assets/imgs/logo-coinsure.png'
import logo_zeca_colorido from "assets/imgs/svg/logo_zeca_colorido.svg";
import PerguntasRespostas from 'apps/lp/components/PerguntasRespostas';
import { HiOutlineLightBulb, HiOutlineMail } from "react-icons/hi";
import { BiHomeSmile, BiCar, BiHeart, BiTab, BiMinus, BiInfoCircle } from "react-icons/bi";
import { MdAirplanemodeActive } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { RiFileEditLine } from "react-icons/ri";
import api from 'services/api'
import { useParams } from 'react-router-dom';
import { useEmitTicket } from "contexts/emitTicket";

const seguros = {
  seguroViagem: {
    name: 'Seguro Viagem',
    icon: <MdAirplanemodeActive />
  },
  seguroTablet: {
    name: 'Seguro de Tablets',
    icon: <BiTab />
  },
  outros: {
    name: 'Outros',
    icon: <BiMinus />
  },
  seguroAuto: {
    name: 'Seguro Auto',
    icon: <BiCar />
  },
  seguroResidencial: {
    name: 'Seguro Residencial',
    icon: <BiHomeSmile />
  },
  seguroVida: {
    name: 'Seguro de Vida',
    icon: <BiHeart />
  }
}
const ResultFormTicket = () => {
  const { emitTicket } = useEmitTicket();
  const { ticket } = emitTicket;
  const [onTickect,setOnTicke] = React.useState('');
  const { corretoraId, estipulanteId, contratoId } = useParams();

  const [data, setData] = React.useState({})
  const [produtos, setProdutos] = React.useState()
  const [perguntas, setPerguntas] = React.useState()

  function getData() {
    api.get(`/beneficiario/ticket/${corretoraId}/${estipulanteId}/${contratoId}`
    ).then(resp => {
    
      setData(resp.data)
      setProdutos(resp.data.produtosCorretora)
    }).catch(err => {
      console.log(err)
    })
  }
  const getPergunta = () => {
    const headers = {
      contrato: contratoId,
    }
    api.get('/corretor/perguntas', { headers: headers })
      .then(resp => {
        setPerguntas(resp.data)
      }).catch(err => {
        console.log(err)
      });
  }

  const renderProduto = (produto) => {
    return produto === "Plano de Saúde" ? "Seguro Saúde" : "Seguro Odontológico"
  }

  React.useEffect(() => {
    getData()
    getPergunta()
  }, [])

  React.useEffect(() => {
    if(ticket) setOnTicke(ticket);
    else  setOnTicke(sessionStorage.getItem('ticket'))
  }, [ticket])

  return (
    <>
      <HeaderQuestionario  estipulante={data?.estipulante}/>
      <Wraper>

        <main>
          <section className="forms">
            <div className="title">
              <h1>
                <RiFileEditLine />
                Formulário de  {data.produto && renderProduto(data.produto)} da {data.estipulante?.razao_social}
              </h1>
            </div>

            <div className="complete">
              <div className="content-center">
                <div className="text-bold">Pronto!</div>
                <div>O seu número de ticket para acompanhar esse processo é: {onTickect} </div>
              </div>
            </div>

          </section>
          <section className="infos border">
            <div className="title">
              <h2>
                <HiOutlineLightBulb />  Perguntas e Respostas
              </h2>
              <PerguntasRespostas perguntas={perguntas} />
            </div>
          </section>
          <section className="infos">
            <div className="title-logo">
              <div className="title">
                <h2>
                  <BiInfoCircle />
                  Você sabia...
                </h2>
              </div>
              <div className="logo">
                <img src={data.corretora?.logo} alt="logo corretora" />
              </div>
            </div>

            <div className="content">
              <p>A {data.corretora?.razao_social} é a corretora que atende a {data.estipulante?.razao_social} no seguro saúde. Ela trabalha com outros tipos de cobertura e  caso precise é só entrar em contato conosco.
              </p>
              <ul className="listSeguros">
                {produtos && produtos.map((item, index) => {
                  return (<li key={index}>{seguros[item.produto].icon} {seguros[item.produto].name}</li>)
                })}
              </ul>
              <div className="contatos">
                {data.contato?.whatsapp ?
                  <div> <FaWhatsapp />  <p>{data.contato.whatsapp} (<a href={`https://web.whatsapp.com/send?phone=55${data.contato?.whatsapp.replace(' ', '').replace('-', '')}`}
                    target="_blank"
                    rel="noopener noreferrer">Whatsapp</a>)</p></div> : null}
                {data.contato?.email ?
                  <div> <HiOutlineMail /> <p>{data.contato.email}</p> </div> : null}
              </div>
            </div>
          </section>

        </main>



        {/* <section className="title">
          <h1>Programa de Benefícios da Genoa Seguros</h1>
          <span>Preencha as informações abaixo para entrar no programa de benefícios da Genoa Seguros.</span>
        </section>

        <section className="complete">
          <div className="content-center">
            <div className="text-bold">Pronto!</div>
            <div>O seu número de ticket para acompanhar esse processo é: {ticket} </div>
          </div>
        </section> */}

      </Wraper>
      <Footer>
        <div className="logo"><img src={logo_zeca_colorido} alt="Logo Zeca" /></div>
      </Footer>
    </>
  )
}

export default ResultFormTicket
