import styled from 'styled-components';
import colors from 'styles/colors';

export const ToastWrapper = styled.div`
  background-color: ${colors.genoaOrange};
  width: 900px;
  height: 45px;
  padding: 0px 20px;
  color: ${colors.white};
  font-family: 'Avenir Next GEO W05 Regular';
  font-size: 0.9em;
  border-radius: 12px;


  display: flex;
  // flex-direction: column;
  row-gap: 15px;
  // column-gap: 25px;
  justify-content: space-around;

  .toast-header {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .info-card-container {
    display: flex;
    // justify-content: space-around;
    min-width: 80px;
    column-gap: 45px;
    
  }

  .info-card {
    display: flex;
    justify-content: center;
    align-items: center;
    
    // border: 2px solid ${colors.white};
    // border-radius: 12px;
    // padding: 10px;
    // width: 40px;
    // height: 40px;
    color: ${colors.white};
    font-size: 1.2em;
    font-family: 'Avenir Next GEO W05 Bold';
  }

  .movimentacao-icon {
    font-size: 1.35em;
  }

  .ticket-icon {
    font-size: 1.1em;
    color: ${colors.white};
    transform: scaleX(-1) translateY(2px);

    path {
      stroke: ${colors.white};
    }
  }

  .bell-card {
    display: flex;
    justify-content: center;
    align-items: center;  
    border: 2px solid ${colors.genoaBlue};
    outline: 2px solid ${colors.white};
    border-radius: 12px;
    padding: 10px;
    width: 38px;
    height: 38px;
    background-color: ${colors.white};

    :hover {
      cursor: pointer;
    }
  }

  .bell-icon {
    font-size: 2em;
    color: ${colors.genoaBlue};
  }

  .mov {
    column-gap: 3px;
  }

  .ticket {
    column-gap: 6px;
    
  }

  .toast-footer {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      padding: 0px 4px;
      font-family: 'Avenir Next GEO W05 Bold';
      text-decoration: underline;
      font-size: 0.9em;
    }
  }
`