import { useEffect, useState } from 'react';
import { GiHealthNormal } from 'react-icons/gi';
import { FaTooth } from 'react-icons/fa';
import { IoIosEye } from 'react-icons/io';
import colors from 'styles/colors';
import ModalLogs from './modalLogs';
import Button from 'components/DS/Button';
import CheckDoc from 'assets/imgs/verification.png';
import services from 'apps/hr/services';
import { useUser } from 'contexts/user';
import './style.scss';
import { BsCheck2Circle } from 'react-icons/bs';

const TableResult = ({ key, data }) => {
  const [showLogs, setShowLogs] = useState(false);
  const { user } = useUser();

  const onReview = async () => {
    const response = await services.inclusionRequest.generateReviewLink({
      titularId: data.beneficiario_id,
      batchId: data.batch_id,
      rhUserId: user.id
    });

    const formUrl = `/formulario-inclusao-plano-saude?token=${response.token}&reset=true`;
    window.open(formUrl, '_blank');
  };

  useEffect(() => {
    data.status_historico.sort(
      (a, b) => new Date(a.created_at) - new Date(b.created_at)
    );
  }, [showLogs]);

  return (
    <div className="manager-request-table-result" key={key}>
      <div></div>
      <div>
        <span className="manager-request-table-result__span">
          {data.titular_nome}
        </span>
      </div>
      <div>
        {data.tipo_solicitacao === 'saude' && (
          <GiHealthNormal color={colors.genoaDarkBlue} />
        )}
        {data.tipo_solicitacao === 'odonto' && (
          <FaTooth color={colors.genoaDarkBlue} />
        )}
        {data.tipo_solicitacao === 'saude_odonto' && (
          <div className="manager-request-table-result__icons-container">
            <GiHealthNormal color={colors.genoaDarkBlue} />
            <FaTooth color={colors.genoaDarkBlue} />
          </div>
        )}
      </div>
      <div className="manager-request-table-result__status-container">
        <div>
          <span className="manager-request-table-result__span">
            {data.status_atual}
          </span>
        </div>
        <div>
          <Button variant="transparent" onClick={() => setShowLogs(true)}>
            <IoIosEye size={20} />
          </Button>
        </div>
        {showLogs ? (
          <ModalLogs
            setShowLogs={setShowLogs}
            logs={data.status_historico?.reverse()}
          />
        ) : null}
      </div>
      <div>
        {/* acessa o formulário externo já preenchido*/}
        {data.status_atual === 'Movimentação de Inclusão Enviada' ? (
          <BsCheck2Circle className="manager-request-table-result__icon" />
        ) : (
          <Button variant="transparent" onClick={onReview}>
            <img
              className="manager-request-table-result__img"
              src={CheckDoc}
              alt="ícone de documento"
            />
          </Button>
        )}
      </div>
      <div></div>
    </div>
  );
};

export default TableResult;
