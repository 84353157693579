import { useState, useMemo, useEffect } from 'react';
import DrawerNovaAtividade from '../CreateNewActivity';
import DrawerCancelamentoContrato from '../CancelContract';
import DrawerRenMesmaCia from '../ReadjustmentInsurance';
import DrawerNovoContrato from '../CreateNewContract';
import CardAtividadeDrawer from '../EditActivityInBusinessDetails';
import DrawerNovaSeguradora from '../InsertNewInsurance';
import { DrawerNegociosWrapper } from './styles';
import { HiTrash } from 'react-icons/hi';
import { BiPlus } from 'react-icons/bi';
import { AiFillCheckCircle } from 'react-icons/ai';
import { toast } from 'react-hot-toast';
import services from 'apps/broker/services';
import Button from 'components/DS/Button';

const DrawerNegocios = ({
  formatDate,
  isSelected,
  getNegociosData,
  iconAtividadeHandler,
  corretores,
  setIsNewResp
}) => {
  const [seguradoras, setSeguradoras] = useState([]);
  const [defaultObservacoes, setDefaultObservacoes] = useState('');

  const [editedObservacoes, setEditObservacoes] = useState('');
  const [showSaveButton, setShowSaveButton] = useState(false);

  const [isNewActivityDrawer, setIsNewActivityDrawer] = useState(undefined);
  const [isNewSeguradoraDrawer, setIsNewSeguradoraDrawer] = useState(undefined);

  const [cancelamentoDrawer, setCancelamentoDrawer] = useState(false);
  const [renMesmaCia, setRenMesmaCiaDrawer] = useState(false);
  const [novoContratoDrawer, setNovoContratoDrawer] = useState(false);

  const [editAtividade, setEditAtividade] = useState(0);

  const getInsuranceCompanies = async () => {
    const response = await services.insuranceCompanies.getAll();
    setSeguradoras(response.data);
  };

  const optionsCorretores = () => {
    const naoAlocado = {
      value: '0',
      option: 'Selecione'
    };
    const responsaveis = corretores?.map((item) => ({
      value: item.usuario_id,
      option: `${item.nome} ${item.sobrenome}`
    }));

    const optionsCorretores = [naoAlocado, ...responsaveis];
    return optionsCorretores?.map((item) => (
      <option
        className="options"
        key={`corretor-${item.value}`}
        value={item.value}
      >
        {`${item.option}`}
      </option>
    ));
  };

  const orderSeguradorasInfo = () => {
    const seguradoras = isSelected?.seguradoras?.sort((a, b) => {
      if (b.seguradora_nome < a.seguradora_nome) {
        return 1;
      }
      return -1;
    });

    return seguradoras;
  };

  const orderAtividades = () => {
    const atividades = isSelected?.atividades?.sort((a, b) => {
      const dataA = new Date(a.data_agendada);
      const dataB = new Date(b.data_agendada);

      if (dataA < dataB) {
        return -1;
      }

      return 1;
    });

    return atividades;
  };

  const renderOptionSeguradora = () => {
    const status = [
      { status: '  ', value: null },
      { status: 'Em cotação', value: 1 },
      { status: 'Cotação apresentada', value: 2 },
      { status: 'Risco negado', value: 3 }
    ];

    return status;
  };

  const renderOptionEstagio = () => {
    const estagio = [
      { estagio: 'Estudo', value: 1 },
      { estagio: 'Negociação', value: 2 },
      { estagio: 'Proposta em análise da seguradora', value: 3 },
      { estagio: 'Implantação', value: 4 }
    ];

    return estagio?.map((item) => (
      <option key={`estagio-${item.value}`} value={item.value}>
        {item.estagio}
      </option>
    ));
  };

  const renderColorAtivity = (item) => {
    let color;

    if (item.data_fechamento === null) {
      color = iconAtividadeHandler(item);
    } else {
      color = 'yellow';
    }

    return color;
  };

  const updateResponsavelNegocio = async (e, negocio) => {
    const integerId = Number(e.target.value);
    const body = {
      negocio_id: negocio.id,
      newData: {
        usuario_id: integerId ? integerId : null
      }
    };

    const response = await services.business.updateResponsableByBusinessId(
      body
    );
    if (response.status === 200) {
      getNegociosData();
      setIsNewResp({ usuario_id: integerId, negocio });
      toast.remove();
      toast.success(`O usuário responsável pelo negócio foi atualizado!`, {
        duration: 2500
      });
    } else {
      toast.remove();
      toast.error('Algo deu errado, tente novamente mais tarde!', {
        duration: 2500
      });
    }
  };

  const changeSeguradoraStatusHandler = async (item, e) => {
    const body = {
      seguradoraItem: item,
      newData: {
        status_id: e.target.value ? e.target.value : null
      }
    };
    await services.business.updateInsuranceStatusByInsuranceId(body);

    getNegociosData();
  };

  const atividadeStatusHandler = async (atividade_id) => {
    const body = {
      id: atividade_id
    };
    await services.activities.updateStatusByActivityId(body);
    getNegociosData();
  };

  const deleteAtividadeClickHandler = async (item) => {
    const response = await services.activities.deleteActivity(item);
    if (response.status === 200) {
      getNegociosData();

      toast.dismiss();
      toast.success('Atividade removida com sucesso');
    }
  };

  const updateObservacaoClickHandler = async () => {
    const body = {
      negocio_id: isSelected?.id,
      newData: {
        observacoes: editedObservacoes
      }
    };

    const response = await services.business.updateDescriptionByBusinessId(
      body
    );
    if (response.status === 200) {
      toast.dismiss();
      toast.success('Observação atualizada com sucesso');
      getNegociosData();
    }
  };

  const changeTextAreaHandler = (e) => {
    setEditObservacoes(e.target.value);
  };

  const updateEstagioOnChange = async (e) => {
    const body = {
      negocio_id: isSelected?.id,
      estagio_id: e.target.value
    };

    const response = await services.business.updateStageByBusinessId(body);
    if (response.status === 200) {
      toast.dismiss();
      toast.success('Estágio atualizado com sucesso');
      getNegociosData();
    } else {
      toast.dismiss();
      toast.error('Algo deu errado, tente novamente mais tarde!');
    }
  };

  const updateResponsavelAtividade = async (atividade_id, usuario_id) => {
    const body = {
      atividade_id: atividade_id,
      newData: {
        usuario_id: usuario_id !== '0' ? usuario_id : null
      }
    };

    const response = await services.activities.updateResponsableByActivityId(
      body
    );
    if (response.status === 200) {
      toast.dismiss();
      toast.success(`O usuário responsável pela atividade foi atualizado!`, {
        duration: 2500
      });
      getNegociosData();
    } else {
      toast.dismiss();
      toast.error('Algo deu errado, tente novamente mais tarde!', {
        duration: 2500
      });
    }
  };

  const atividadeResponsavelChangeHandler = (e, item) => {
    const responsavelId = e.target.value;
    const atividadeId = item.atividade_id;

    updateResponsavelAtividade(atividadeId, responsavelId);
  };

  const changeAtividadesInfo = (atividade_id) => {
    setEditAtividade(atividade_id);
  };

  const closeAtividadesInfo = () => {
    setEditAtividade(0);
  };

  const renderRazaoSocial = () => {
    if (isSelected?.estipulante_razao_social) {
      return isSelected?.estipulante_razao_social;
    } else {
      return isSelected?.lead_razao_social;
    }
  };

  const renderCnpj = () => {
    if (isSelected?.estipulante_id) {
      return isSelected?.estipulante_cnpj;
    } else {
      return isSelected?.lead_potencial_cnpj;
    }
  };

  const renderNomeFantasia = () => {
    if (isSelected?.estipulante_id) {
      if (isSelected?.estipulante_nome_fantasia !== null) {
        if (isSelected?.estipulante_nome_fantasia?.nome_fantasia !== null) {
          return isSelected?.estipulante_nome_fantasia?.nome_fantasia;
        } else {
          return '';
        }
      } else {
        return '';
      }
    } else if (isSelected?.lead_potencial_id) {
      if (isSelected?.lead_potencial_nome_fantasia !== null) {
        if (isSelected?.lead_potencial_nome_fantasia?.nome_fantasia !== null) {
          return isSelected?.lead_potencial_nome_fantasia?.nome_fantasia;
        } else {
          return '';
        }
      } else {
        return '';
      }
    }
  };

  useMemo(() => {
    setEditObservacoes(defaultObservacoes);
  }, [defaultObservacoes]);

  useMemo(() => {
    if (Boolean(isNewActivityDrawer)) {
      setIsNewActivityDrawer(isSelected);
    }

    // Esta condição abrirá um drawer de nova atividade
    if (Boolean(isNewSeguradoraDrawer)) {
      setIsNewSeguradoraDrawer(isSelected);
    }

    setDefaultObservacoes(isSelected?.observacoes);
  }, [isSelected]);

  useEffect(() => {
    getInsuranceCompanies();
  }, []);

  useEffect(() => {
    if (editedObservacoes !== defaultObservacoes) {
      setShowSaveButton(true);
    } else {
      setShowSaveButton(false);
    }
  }, [editedObservacoes, defaultObservacoes, cancelamentoDrawer]);

  return (
    <DrawerNegociosWrapper>
      <div className="drawer_header">
        <div className="header_title">{`${renderRazaoSocial()} - ${renderCnpj()}`}</div>
        <div className="header_title">{`Nome fantasia: ${renderNomeFantasia()}`}</div>
        <div className="header_subtitle">
          {isSelected?.tipo === 'Novo' ? (
            <span>Novo</span>
          ) : (
            <span>Renovação</span>
          )}
        </div>
        {isSelected?.tipo === 'Renovação' && isSelected?.seguradora_nome ? (
          isSelected?.numero_apolice ? (
            <div className="header_subtitle seguradora_nome">{`${isSelected?.produto} - ${isSelected?.seguradora_nome} - Apólice ${isSelected?.numero_apolice}`}</div>
          ) : (
            <div className="header_subtitle seguradora_nome">{`${isSelected?.produto} - ${isSelected?.seguradora_nome}`}</div>
          )
        ) : (
          ''
        )}
      </div>
      <div className="drawer_content">
        <div className="primeira_linha">
          <div className="primeira_coluna">
            <div className="column-info">
              <span className="span_header">Estágio</span>
              <div className="estagio_select">
                <select
                  defaultValue={isSelected?.estagio_id}
                  onChange={(e) => updateEstagioOnChange(e)}
                >
                  <option disabled>Selecione</option>
                  {renderOptionEstagio()}
                </select>
              </div>
            </div>
            <div className="column-info">
              <span className="span_header">Vidas</span>
              <span>{isSelected?.vidas}</span>
            </div>
            <div className="column-info">
              <span className="span_header">Produto</span>
              <span>{isSelected?.produto}</span>
            </div>
            <div className="column-info">
              <span className="span_header">Responsável</span>
              <div className="estagio_select">
                <select
                  value={isSelected?.usuario_id}
                  onChange={(e) => updateResponsavelNegocio(e, isSelected)}
                >
                  {optionsCorretores()}
                </select>
              </div>
            </div>
          </div>
          <div className="segunda_coluna">
            {isSelected?.tipo === 'Novo' ? (
              <>
                <button
                  className="verde"
                  onClick={() => setNovoContratoDrawer(true)}
                >
                  Negócio Concluído
                </button>
                <button
                  className="vermelho"
                  onClick={() => setCancelamentoDrawer(true)}
                >
                  Perdemos o cliente
                </button>
              </>
            ) : (
              <>
                <button
                  className="verde"
                  onClick={() => setRenMesmaCiaDrawer(true)}
                >
                  Renovou na mesma cia.
                </button>
                <button
                  className="cinza"
                  onClick={() => setNovoContratoDrawer(true)}
                >
                  Renovou em outra cia.
                </button>
                <button
                  className="vermelho"
                  onClick={() => setCancelamentoDrawer(true)}
                >
                  Perdemos o cliente
                </button>
              </>
            )}
          </div>
        </div>
        <div className="segunda_linha">
          <div
            className={`header_atividades ${
              isSelected?.tipo === 'Novo' ? 'p-top' : ''
            }`}
          >
            Atividades relacionadas
          </div>
          <div className="lista_atividades">
            {orderAtividades()?.map((item) =>
              item.data_fechamento ? (
                ''
              ) : (
                <CardAtividadeDrawer
                  key={`atividade-relacionada-${item.atividade_id}`}
                  item={item}
                  changeAtividadesInfo={changeAtividadesInfo}
                  editAtividade={editAtividade}
                  closeAtividadesInfo={closeAtividadesInfo}
                  renderColorAtivity={renderColorAtivity}
                  formatDate={formatDate}
                  atividadeResponsavelChangeHandler={
                    atividadeResponsavelChangeHandler
                  }
                  optionsCorretores={optionsCorretores}
                  atividadeStatusHandler={atividadeStatusHandler}
                  deleteAtividadeClickHandler={deleteAtividadeClickHandler}
                  getNegociosData={getNegociosData}
                />
              )
            )}
          </div>
          <div>
            <Button
              // className="button_atividade"
              variant="transparent"
              onClick={() => setIsNewActivityDrawer(isSelected)}
            >
              + atividade
            </Button>
          </div>

          <div className="header_atividades">Atividades Concluídas</div>
          <div className="lista_atividades">
            {isSelected?.atividades?.map((item) =>
              item.data_fechamento ? (
                <div
                  className="segunda_coluna_atividades"
                  key={`${item.negocio_id}-${item.atividade_id}`}
                >
                  <div className="check_concluidas">
                    <AiFillCheckCircle />
                  </div>
                  <span>{item.descricao}</span>
                  <span>{formatDate(item.data_agendada)}</span>
                  <span>
                    {item.usuario_id
                      ? `${item.usuario_nome} ${item.usuario_sobrenome}`
                      : ''}
                  </span>
                  <div>
                    <button
                      className="trash"
                      onClick={() => deleteAtividadeClickHandler(item)}
                    >
                      <HiTrash />
                    </button>
                  </div>
                </div>
              ) : (
                ''
              )
            )}
          </div>
        </div>
        <div className="terceira_linha">
          <div className="header_atividades">Seguradoras</div>
          <div className="lista_seguradoras">
            {orderSeguradorasInfo()?.map((item) => (
              <div className="seguradora_option" key={`seguradora-${item?.id}`}>
                <div>{item?.seguradora_nome}</div>
                <div>
                  <select
                    defaultValue={item?.status_id}
                    onChange={(e) => changeSeguradoraStatusHandler(item, e)}
                  >
                    {renderOptionSeguradora().map((opt) => (
                      <option
                        key={`opt-seguradora-${opt.value}`}
                        value={opt?.value}
                      >
                        {opt?.status}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ))}
          </div>
          <div>
            <Button
              variant="transparent"
              onClick={() => setIsNewSeguradoraDrawer(isSelected)}
            >
              + seguradora
            </Button>
          </div>
        </div>
        <div className="quarta_linha">
          <div className="header_atividades">Observação</div>
          <textarea
            value={editedObservacoes}
            onChange={changeTextAreaHandler}
            rows={10}
            maxLength={1200}
          />
          <div className="container_button">
            {showSaveButton ? (
              <button
                className="save_observacoes_btn"
                onClick={updateObservacaoClickHandler}
              >
                Salvar
              </button>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      {isNewActivityDrawer && (
        <DrawerNovaAtividade
          setNewActivity={setIsNewActivityDrawer}
          isNewActivity={isNewActivityDrawer}
          corretores={corretores}
          getNegociosData={getNegociosData}
        />
      )}
      {isNewSeguradoraDrawer && (
        <DrawerNovaSeguradora
          setNewActivity={setIsNewSeguradoraDrawer}
          isNewActivity={isNewSeguradoraDrawer}
          seguradoras={seguradoras}
          getNegociosData={getNegociosData}
        />
      )}
      {cancelamentoDrawer && (
        <DrawerCancelamentoContrato
          setCancelamentoDrawer={setCancelamentoDrawer}
          isSelected={isSelected}
          getNegociosData={getNegociosData}
        />
      )}
      {novoContratoDrawer && (
        <DrawerNovoContrato
          setNovoContratoDrawer={setNovoContratoDrawer}
          isSelected={isSelected}
          getNegociosData={getNegociosData}
          seguradoras={seguradoras}
        />
      )}
      {renMesmaCia && (
        <DrawerRenMesmaCia
          setRenMesmaCiaDrawer={setRenMesmaCiaDrawer}
          isSelected={isSelected}
          getNegociosData={getNegociosData}
        />
      )}
    </DrawerNegociosWrapper>
  );
};

export default DrawerNegocios;
