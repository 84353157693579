import { useState, useEffect } from 'react';
import { RelatorioContentContainer } from './styles-RelatorioContent';
import TabelaConcierge from './components/TableResult';
import colors from 'styles/colors';
import GraficoConcierge from './components/ConciergeGraphic';
import monthsObject from 'utils/objects/optionsMonth';

const RelatorioContent = ({
  relatorioSelecionado,
  servicesByPeriod,
  averageFirstResponseTime,
  averageConclusionTime,
  usersByPeriod,
  newUsersByPeriod,
  evaluationsByPeriod,
  formatTimeValue,
  servicesCountByMonths,
  servicesCountOfNewUsersByMonths,
  estipulanteSelected,
  setEstipulanteSelected
}) => {
  const [servicesByMonthsToPlot, setServicesByMonthsToPlot] = useState([]);
  const [
    servicesByNewUsersByMonthsToPlot,
    setServicesByNewUsersByMonthsToPlot
  ] = useState([]);

  // Estados para gráficos
  const [graphsDomainY, setGraphsDomainY] = useState([]);

  // Estado para funcionalidade de arrasto
  const [mesesArrastadosAtendimento, setMesesArrastadosAtendimento] =
    useState(0);
  const [storedDeltaAtendimento, setStoredDeltaAtendimento] = useState(0);
  const [draggable, setDraggable] = useState(false);
  const [positionStart, setPositionStart] = useState(undefined);

  const prepareDataToPlot = (array) => {
    const orderedArray = array.sort((a, b) => {
      const { concierge_month: date_a } = a;
      const { concierge_month: date_b } = b;

      const monthA = Number(date_a.slice(0, 2));
      const monthB = Number(date_b.slice(0, 2));

      const yearA = Number(date_a.slice(3));
      const yearB = Number(date_b.slice(3));
      // Se anoA menor que anoB, item A virá antes no Array
      if (yearA < yearB) {
        return -1;
      }
      // Se anoA maior que anoB, item A virá depois no Array
      if (yearA > yearB) {
        return 1;
      }
      // Se anoA igual a anoB, item A virá antes no Array se mesA for menor
      if (monthA < monthB) {
        return -1;
      }
      // Se anoA igual a anoB, item A virá depois no Array se mesA for maior
      return 1;
    });

    const formattedArray = orderedArray.map((item, index) => {
      const { concierge_month } = item;
      const monthNumber = concierge_month.slice(0, 2);
      item.month = monthsObject[monthNumber];
      item.index = index;
      return item;
    });

    return formattedArray;
  };

  const renderAtendimentoMonthsDomain = () => {
    const data = prepareDataToPlot(servicesCountByMonths);
    const oldestReajuste = data[0]?.index;
    const latestReajuste = data[data.length - 1]?.index;
    let oldestDomain;
    let latestDomain;

    const interval = latestReajuste - oldestReajuste;

    // constante 0.3 é referente ao espaçamento do ponto nas laterais do gráfico
    // para não cortar os pontos
    if (interval > 7) {
      if (
        latestReajuste - mesesArrastadosAtendimento - storedDeltaAtendimento >
        interval
      ) {
        setMesesArrastadosAtendimento(0);
        setStoredDeltaAtendimento(0);

        return [latestReajuste - 7 - 0.25, latestReajuste + 0.25];
      }

      // 0.6 considerando 0.3 de margem do lado esquerdo e 0.3 p/ lado direito
      else if (
        interval - 7 - mesesArrastadosAtendimento - storedDeltaAtendimento <
        0
      ) {
        setMesesArrastadosAtendimento(0);
        setStoredDeltaAtendimento(interval - 7);

        return [oldestReajuste - 0.25, oldestReajuste + 7 + 0.25];
      }

      oldestDomain =
        latestReajuste -
        7 -
        0.25 -
        mesesArrastadosAtendimento -
        storedDeltaAtendimento;
      latestDomain =
        latestReajuste +
        0.25 -
        mesesArrastadosAtendimento -
        storedDeltaAtendimento;

      return [oldestDomain, latestDomain];
    }

    return [oldestReajuste - 0.25, latestReajuste + 0.25];
  };

  const renderXDomain = () => {
    const xDomain = renderAtendimentoMonthsDomain();
    const oldestDomain = xDomain[0];
    const latestDomain = xDomain[1];
    const yearInterval = latestDomain - oldestDomain;
    const ticks = [];

    for (let index = 0; index <= yearInterval; index += 1) {
      const indexDomain = Number(oldestDomain.toFixed(0)) + index;
      ticks.push(indexDomain);
    }
    return ticks;
  };

  const draggableMovementHandler = (e) => {
    const ticks = document.querySelectorAll(
      '.recharts-cartesian-axis-tick-line'
    );

    const firstTick = ticks[0]?.getAttribute('x1');
    const secondTick = ticks[1]?.getAttribute('x1');
    const tickScale = Math.round(secondTick - firstTick);

    if (draggable) {
      if (!e || !e?.xValue) {
        setStoredDeltaAtendimento(
          (prevState) => prevState + mesesArrastadosAtendimento
        );
        setMesesArrastadosAtendimento(0);
        setPositionStart(undefined);
        return setDraggable(false);
      }
      if (e && e?.xValue) {
        const delta = window.event.x - positionStart;

        // 1 ano a cada tickScale pixels arrastado
        const deltaAnos = delta / tickScale;

        setMesesArrastadosAtendimento(deltaAnos);
      }
    }
  };

  const renderTabelaAbaConcierge = () => {
    switch (relatorioSelecionado) {
      case 'atendimentos resolvidos':
        return (
          <TabelaConcierge
            data={servicesByPeriod}
            dataType={undefined}
            keyDescription={'Estipulante'}
            valueDescription={'atendimentos'}
            dataKey={'empresa'}
            dataValue={'count_finalizados'}
            formatTimeValue={formatTimeValue}
            estipulanteSelected={estipulanteSelected}
            setEstipulanteSelected={setEstipulanteSelected}
          />
        );

      case 'tempo de resposta':
        return (
          <TabelaConcierge
            data={averageFirstResponseTime}
            dataType={'minutes'}
            keyDescription={'Estipulante'}
            valueDescription={'tempo'}
            dataKey={'empresa'}
            dataValue={'average_time'}
            formatTimeValue={formatTimeValue}
            estipulanteSelected={estipulanteSelected}
            setEstipulanteSelected={setEstipulanteSelected}
          />
        );

      case 'tempo de resolucao':
        return (
          <TabelaConcierge
            data={averageConclusionTime}
            dataType={'minutes'}
            keyDescription={'Estipulante'}
            valueDescription={'tempo'}
            dataKey={'empresa'}
            dataValue={'average_time'}
            formatTimeValue={formatTimeValue}
            estipulanteSelected={estipulanteSelected}
            setEstipulanteSelected={setEstipulanteSelected}
          />
        );

      case 'usuarios':
        return (
          <TabelaConcierge
            data={usersByPeriod}
            dataType={undefined}
            keyDescription={'Estipulante'}
            valueDescription={'usuarios'}
            dataKey={'empresa'}
            dataValue={'count_usuarios'}
            formatTimeValue={formatTimeValue}
            estipulanteSelected={estipulanteSelected}
            setEstipulanteSelected={setEstipulanteSelected}
          />
        );

      case 'novos usuarios':
        return (
          <TabelaConcierge
            data={newUsersByPeriod}
            dataType={undefined}
            keyDescription={'Estipulante'}
            valueDescription={'usuarios'}
            dataKey={'empresa'}
            dataValue={'count_usuarios'}
            formatTimeValue={formatTimeValue}
            estipulanteSelected={estipulanteSelected}
            setEstipulanteSelected={setEstipulanteSelected}
          />
        );

      case 'nps':
        return (
          <TabelaConcierge
            data={evaluationsByPeriod}
            dataType={'nps'}
            keyDescription={'Estipulante'}
            valueDescription={'net promoter score'}
            dataKey={'empresa'}
            dataValue={'nps'}
            formatTimeValue={formatTimeValue}
            estipulanteSelected={estipulanteSelected}
            setEstipulanteSelected={setEstipulanteSelected}
          />
        );

      default:
        return (
          <TabelaConcierge
            data={servicesByPeriod}
            dataType={undefined}
            keyDescription={'Estipulante'}
            valueDescription={'atendimentos'}
            dataKey={'empresa'}
            dataValue={'count_abertos'}
            formatTimeValue={formatTimeValue}
            estipulanteSelected={estipulanteSelected}
            setEstipulanteSelected={setEstipulanteSelected}
          />
        );
    }
  };

  useEffect(() => {
    const servicesCountToPlot = prepareDataToPlot(servicesCountByMonths);

    if (!estipulanteSelected) {
      const maxCountValue = servicesCountToPlot.reduce((acc, currentValue) => {
        const currentCount = currentValue?.count;

        return currentCount > acc ? currentCount : acc;
      }, 0);

      setGraphsDomainY([0, maxCountValue + (10 - (maxCountValue % 5))]);

      setServicesByMonthsToPlot(servicesCountToPlot);
    } else {
      const estipulanteServicesCountToPlot = servicesCountToPlot?.map(
        (item) => {
          return {
            concierge_month: item?.concierge_month,
            index: item?.index,
            month: item?.month,
            count: item?.ticket_empresas[estipulanteSelected]
              ? item?.ticket_empresas[estipulanteSelected]?.count
              : 0
          };
        }
      );
      const maxCountValue = estipulanteServicesCountToPlot.reduce(
        (acc, currentValue) => {
          const currentCount = currentValue?.count;

          return currentCount > acc ? currentCount : acc;
        },
        0
      );

      setGraphsDomainY([0 - 0.5, maxCountValue + 5]);

      setServicesByMonthsToPlot(estipulanteServicesCountToPlot);
    }
  }, [servicesCountByMonths, estipulanteSelected]);

  useEffect(() => {
    const servicesCountByNewUsersToPlot = prepareDataToPlot(
      servicesCountOfNewUsersByMonths
    );

    if (!estipulanteSelected) {
      setServicesByNewUsersByMonthsToPlot(servicesCountByNewUsersToPlot);
    } else {
      const estipulanteNewUsersServicesCountToPlot =
        servicesCountByNewUsersToPlot?.map((item) => {
          return {
            concierge_month: item?.concierge_month,
            index: item?.index,
            month: item?.month,
            count: item?.ticket_empresas[estipulanteSelected]
              ? item?.ticket_empresas[estipulanteSelected]?.count
              : 0
          };
        });

      setServicesByNewUsersByMonthsToPlot(
        estipulanteNewUsersServicesCountToPlot
      );
    }
  }, [servicesCountOfNewUsersByMonths, estipulanteSelected]);

  return (
    <RelatorioContentContainer>
      <div className="graphs_column">
        <div className="graphs_container">
          <GraficoConcierge
            draggableMovementHandler={draggableMovementHandler}
            setDraggable={setDraggable}
            setPositionStart={setPositionStart}
            setStoredDeltaAtendimento={setStoredDeltaAtendimento}
            mesesArrastadosAtendimento={mesesArrastadosAtendimento}
            setMesesArrastadosAtendimento={setMesesArrastadosAtendimento}
            servicesByMonthsToPlot={servicesByMonthsToPlot}
            renderXDomain={renderXDomain}
            renderAtendimentoMonthsDomain={renderAtendimentoMonthsDomain}
            graphsDomainY={graphsDomainY}
            graphData={servicesByMonthsToPlot}
            lineColor={colors.genoaBlue}
            dotColor={colors.genoaBlue}
            graphTitle={'Atendimentos criados x mês'}
          />

          <GraficoConcierge
            draggableMovementHandler={draggableMovementHandler}
            setDraggable={setDraggable}
            setPositionStart={setPositionStart}
            setStoredDeltaAtendimento={setStoredDeltaAtendimento}
            mesesArrastadosAtendimento={mesesArrastadosAtendimento}
            setMesesArrastadosAtendimento={setMesesArrastadosAtendimento}
            servicesByMonthsToPlot={servicesByMonthsToPlot}
            renderXDomain={renderXDomain}
            renderAtendimentoMonthsDomain={renderAtendimentoMonthsDomain}
            graphsDomainY={graphsDomainY}
            graphData={servicesByNewUsersByMonthsToPlot}
            lineColor={colors.genoaBlue}
            dotColor={colors.genoaBlue}
            graphTitle={'Atendimentos criados de novos usuários x mês'}
            lineDash="4 2"
          />
        </div>
      </div>
      {renderTabelaAbaConcierge()}
    </RelatorioContentContainer>
  );
};

export default RelatorioContent;
