import styled from 'styled-components';
import colors from 'styles/colors';

export const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 20px 20px;

  .estipulante-paragraph {
    font-family: 'Avenir Next GEO W05 Demi';
    margin-top: 5px;
    margin-bottom: 5px;
    color: ${colors.genoaBlue};
    min-height: 21.6px;
  }

  .content {
    margin-top: 15px;
  }

  .edit-estipulante-confirmation {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    .check-icon {
      margin-right: 50px;
      color: #00b346;
      :hover {
        cursor: pointer;
      }
    }

    .cancel-icon {
      color: red;
      :hover {
        cursor: pointer;
      }
    }
  }
`;
