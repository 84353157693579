const relatives = [
  {
    option: 'Filho(a)',
    value: 'Filho(a)'
  },
  {
    option: 'Cônjuge',
    value: 'Cônjuge'
  },
  {
    option: 'Pai',
    value: 'Pai'
  },
  {
    option: 'Mãe',
    value: 'Mãe'
  },
  {
    option: 'Irmão',
    value: 'Irmão'
  },
  {
    option: 'Irmã',
    value: 'Irmã'
  },
  {
    option: 'Neto(a)',
    value: 'Neto(a)'
  },
  {
    option: 'Tio(a)',
    value: 'Tio(a)'
  },
  {
    option: 'Cunhado(a)',
    value: 'Cunhado(a)'
  },
  {
    option: 'Sogro(a)',
    value: 'Sogro(a)'
  },
  {
    option: 'Genro',
    value: 'Genro'
  },
  {
    option: 'Nora',
    value: 'Nora'
  },
  {
    option: 'Primo(a)',
    value: 'Primo(a)'
  }
];

export default relatives;
