import { useEffect, useState } from 'react';
import moment from 'moment';
import services from 'apps/lp/services';

const useUserInfo = (methods) => {
  const [isAllowed, setIsAllowed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [configFields, setConfigFields] = useState({});
  const [userData, setUserData] = useState({});
  const [isHrUser, setIsHrUser] = useState(false);
  const [logoCompany, setLogoCompany] = useState('');

  useEffect(() => {
    const loadUserInfo = async () => {
      const user = await services.client.getUserInfo();
      console.log('user', user);

      if (!user) {
        setIsAllowed(false);
        setErrorMessage(
          'A data de preenchimento expirou! Por favor, entre em contato com o seu RH.'
        );
        return;
      }

      if (user === 'Token expired') {
        setIsAllowed(false);
        setErrorMessage(
          'A data de preenchimento expirou! Por favor, entre em contato com o seu RH.'
        );
      } else {
        const { logo } = user.logo || '';
        logo ? setLogoCompany(logo) : setLogoCompany('');
        setUserData(user);
        setIsAllowed(true);
        setConfigFields(user.formsFields);
        setIsHrUser(user?.rhUserId);

        if (user.titular) {
          methods.reset();
          let formData = {
            ...user.titular,
            dependentes: user.dependentes.map((dep) => {
              return {
                ...dep,
                data_expedicao_rg: moment(dep.data_expedicao_rg).format(
                  'DD/MM/YYYY'
                ),
                data_nascimento: moment(dep.data_nascimento).format(
                  'DD/MM/YYYY'
                ),

                files: {
                  documento_scan_rg: dep.documento_scan_rg,
                  documento_scan_cpf: dep.documento_scan_cpf,
                  documento_comprovacao_vinculo:
                    dep.documento_comprovacao_vinculo
                }
              };
            })
          };
          if (user?.documents) {
            formData.files = [user.documents];
          }

          Object.keys(formData).forEach((key) => {
            if (
              key === 'data_nascimento' ||
              key === 'data_admissao' ||
              key === 'data_expedicao_rg'
            ) {
              methods.setValue(key, moment(formData[key]).format('DD/MM/YYYY'));
              return;
            }

            if (key === 'files') {
              setTimeout(() => {
                methods.setValue(key, formData[key]);
              }, 1000);
            }

            methods.setValue(key, formData[key]);
          });
        } else {
          methods.setValue('nome', user?.nome);
          methods.setValue('cpf', user?.cpf);
          methods.setValue('email', user?.email);
        }
      }
    };

    loadUserInfo();
  }, [methods]);

  return {
    isAllowed,
    errorMessage,
    configFields,
    userData,
    isHrUser,
    setIsHrUser,
    logoCompany
  };
};

export default useUserInfo;
