import { FaRegCheckCircle } from 'react-icons/fa';
import './styles.scss';

const CompletedInclusionForm = ({ text }) => {
  return (
    <div className="completed-inclusion">
      <span>{text}</span>
      <FaRegCheckCircle className="completed-inclusion__icon" />
    </div>
  );
};

export default CompletedInclusionForm;
