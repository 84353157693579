import { useEffect, useState } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import moment from 'moment-timezone';
import { CircularProgress } from '@material-ui/core';
import { ImUserMinus, ImUserPlus } from 'react-icons/im';
import { BiMessageSquareDetail } from 'react-icons/bi';
import { Wrapper } from './styles';
import MovimentInclusion from './components/MovimentInclusion';
import MovimentExclusion from './components/MovimentExclusion';
import MovimentDetails from './components/MovimentDetails';
import io from 'socket.io-client';
import { useUser } from 'contexts/user';
import api from 'services/api';
import services from 'apps/broker/services';

const DrawerMovimentacaoBeneficiario = ({ data, onClose }) => {
  const { user } = useUser();

  const [abaOption, setAbaOption] = useState('Inclusão');
  const [beneficiarioOption, setBeneficiarioOption] = useState('Titular');
  const [estipulantes, setEstipulantes] = useState([]);
  const [selectedEstipulante, setSelectedEstipulante] = useState();
  const [selectedSubEstipulante, setSelectedSubEstipulante] = useState();
  const [triggerGetSubs, setTriggerGetSubs] = useState(false);
  const [allSubEstipulantes, setAllSubEstipulantes] = useState([]);
  const [planoSaudeSelected, setPlanoSaudeSelected] = useState();
  const [planoOdontoSelected, setPlanoOdontoSelected] = useState();
  const [tipoPlanoSaudeSelected, setTipoPlanoSaudeSelected] = useState('');
  const [tipoPlanoOdontoSelected, setTipoPlanoOdontoSelected] = useState('');
  const [selectedBeneficiario, setSelectedBeneficiario] = useState();
  const [estipulanteSelected, setEstipulanteSelected] = useState('');
  const [loading, setLoading] = useState(false);
  const [titularDependente, setTitularDependente] = useState('');
  const [formIncompleto, setFormIncompleto] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState(false);
  const [beneficiarioJaExistente, setBeneficiarioJaExistente] = useState(false);
  const [planosToRemove, setPlanosToRemove] = useState({});
  const [motivoExclusao, setMotivoExclusao] = useState(1);
  const [date, setDate] = useState('');
  const [beneficiarioContratos, setBeneficiariosContratos] = useState([]);
  const [details, setDetails] = useState([]);
  const [messages, setMessages] = useState([]);
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [reopen, setReopen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [status, setStatus] = useState();
  const [loadingDetalhes, setLoadingDetalhes] = useState(false);
  const [changeStatus, setChangeStatus] = useState(false);

  const getSegurosEstipulante = (selectedEstipulanteId) => {
    const choosenEstipulante = estipulantes.filter(
      (item) => item.id === Number(selectedEstipulanteId)
    );

    setSelectedEstipulante(choosenEstipulante[0]);

    setTriggerGetSubs(true);
  };

  const updateContratoOptionsAfterDelete = () => {
    const keys = Object.keys(planosToRemove);
    const keysToDelete = keys.filter((key) => planosToRemove[key]);

    const contratosAfterDelete = beneficiarioContratos.map((contrato) => {
      const contratoDeleted = keysToDelete.some(
        (key) => contrato.contrato_id === Number([key])
      );

      if (contratoDeleted) {
        const contratoUpdated = contrato;
        contratoUpdated.movimentacao_remocao = true;
        return contratoUpdated;
      }
      return contrato;
    });

    setBeneficiariosContratos(contratosAfterDelete);
  };

  const socket = io(process.env.REACT_APP_BASE_URL_API);

  const reduceEstipulantes2 = (data) => {
    const arr2 = data.sort((a, b) =>
      a.razao_social?.localeCompare(b.razao_social)
    );
    return arr2;
  };

  const getPoliceOwners = async () => {
    const response = await services.policyOwner.getAll();
    const policeOrdered = reduceEstipulantes2(response.data);
    setEstipulantes(policeOrdered);
  };

  const getAllPoliceOwnersAndSubs = async () => {
    setLoading(true);
    const { id_estipulante } = selectedEstipulante;

    const response = await services.policyOwner.getAllSubs(id_estipulante);
    setSelectedEstipulante(response.data);
    setAllSubEstipulantes(response.data.subestipulantes);

    setLoading(false);
  };

  const postNewBeneficiario = async (body) => {
    const { id_estipulante } = selectedEstipulante;
    const response = await services.moviment.createInclusionByCompanyId(
      id_estipulante,
      body
    );

    if (response.status === 200) {
      setConfirmationMessage(true);
      await confirmationMessageTimeout();
      clearConfirmationMessageTimeout();
    } else if (response.status === 409) {
      setBeneficiarioJaExistente(true);
      setConfirmationMessage(false);
      await beneficiarioJaExistenteMessageTimeout();
      clearBeneficiarioJaExistenteMessageTimeout();
    }
  };

  const postExclusionMoviment = async () => {
    const keys = Object.keys(planosToRemove);
    const keysToBeSent = keys.filter((key) => planosToRemove[key]);
    const planosToRemoveInfo = beneficiarioContratos.filter((contrato) => {
      if (keysToBeSent.some((key) => Number(key) === contrato.contrato_id)) {
        return true;
      }
      return false;
    });

    const movimentacaoExclusaoInfo = planosToRemoveInfo.map((contrato) => ({
      contrato_id: contrato.contrato_id,
      beneficiario_id: contrato.beneficiario_id,
      motivo_id: motivoExclusao
    }));

    const body = {
      movimentacaoExclusaoInfo,
      removalDate: date !== '' ? moment(date).format() : undefined,
      dependentes: selectedBeneficiario.dependentes
    };

    await services.moviment.createExclusionByContractIdAndBeneficiaryId(body);
    updateContratoOptionsAfterDelete();
    setPlanosToRemove({});
    setDate('');
  };

  const formatNomesTitulares = (titulares) => {
    const nomesTitularesFormatados = titulares.map((titular) => {
      const nome = titular.nome;
      const nomeArr = nome.split(' ');
      const nomeArrFormatado = nomeArr.map((palavraDoNome) => {
        if (palavraDoNome.length > 2 && palavraDoNome.toLowerCase() !== 'dos') {
          const nomeLowered = palavraDoNome
            .slice(1, palavraDoNome.length)
            .toLowerCase();
          const nomeFormatado = palavraDoNome[0].toUpperCase();
          return nomeFormatado + nomeLowered;
        } else {
          const nomeLowered = palavraDoNome.toLowerCase();
          return nomeLowered;
        }
      });
      const nomeTotalFormatado = nomeArrFormatado.join(' ');
      return {
        ...titular,
        nome: nomeTotalFormatado,
        cpf: titular.cpf
      };
    });
    return nomesTitularesFormatados;
  };

  const errorMessageTimeout = async () =>
    setTimeout(() => setFormIncompleto(false), 6000);

  const clearErrorMessageTimeout = () => {
    clearTimeout(errorMessageTimeout);
  };

  const confirmationMessageTimeout = async () =>
    setTimeout(() => setConfirmationMessage(false), 6000);

  const beneficiarioJaExistenteMessageTimeout = async () =>
    setTimeout(() => setBeneficiarioJaExistente(false), 6000);

  const clearConfirmationMessageTimeout = () => {
    clearTimeout(errorMessageTimeout);
  };

  const clearBeneficiarioJaExistenteMessageTimeout = () => {
    clearTimeout(beneficiarioJaExistenteMessageTimeout);
  };

  const handleCloseDetalhes = () => {
    setOpen(false);
  };

  const handleClickConfirm = () => {
    setConfirm(true);
  };

  const handleCloseConfirm = () => {
    setConfirm(false);
  };

  const handleReopen = () => {
    setReopen(true);
  };

  const handleCloseReopen = () => {
    setReopen(false);
  };

  const handleChangeStatus = async () => {
    const body = {
      ticket: details.id_ticket,
      status: status
    };

    await services.tickets.updateStatusByIdTicket(body);
    getData();
    setOpenEdit(false);
  };

  const getData = async () => {
    setLoadingDetalhes(true);

    // const response = await services.tickets.getByTicketId(data.id_ticket);
    api
      .get('/corretor/ticket', {
        headers: {
          id: data.id_ticket,
          authorization: `Bearer ${user.token}`
        }
      })
      .then((resp) => {
        setDetails(resp.data);
        setMessages(resp.data.messages);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingDetalhes(false);
      });
  };

  const closeTicket = async () => {
    const { id_ticket } = data;
    const response = await services.tickets.updateStatusToCloseByIdTicket(
      id_ticket
    );
    if (response.status === 200) {
      setChangeStatus(false);
      getData();
      handleCloseDetalhes();
    }
  };

  const archiveTicket = async () => {
    const { id_ticket } = data;
    await services.tickets.updateShelveByIdTicket(id_ticket);
    getData();
    handleCloseConfirm();
  };

  const reopenTicket = async () => {
    const body = {
      ticket: details.id_ticket,
      status: status
    };

    await services.tickets.updateReopenIdTicket(body);
    getData();
    handleCloseReopen();
  };

  const renderSpinnerOnloading = () => {
    if (loading) {
      return (
        <div className="circular-spinner-container">
          <CircularProgress className="circular-spinner" size={35} />
        </div>
      );
    }
  };

  const subEstipulanteSelectClickHandler = (target) => {
    setSelectedSubEstipulante(target.value);
    if (beneficiarioOption === 'Dependente') {
      setTitularDependente('');
      setPlanoSaudeSelected(undefined);
      setPlanoOdontoSelected(undefined);
      setTipoPlanoSaudeSelected(undefined);
      setTipoPlanoOdontoSelected(undefined);
    }

    if (abaOption === 'Remoção') {
      setSelectedBeneficiario(undefined);
      setBeneficiariosContratos([]);
    }
  };

  const defaultEstipulanteValue = () => {
    const selectedEstipulanteFilter = estipulantes.filter(
      (item) => item.razao_social === data.razaoSocialEstipulante
    );
    if (selectedEstipulanteFilter && selectedEstipulanteFilter.length) {
      setEstipulanteSelected(String(selectedEstipulanteFilter[0].id));
      getSegurosEstipulante(String(selectedEstipulanteFilter[0].id));
    }
  };

  const mudarStatusClickHandler = () => {
    setChangeStatus((prevState) => !prevState);
  };

  useEffect(() => defaultEstipulanteValue(), [estipulantes]);

  useEffect(() => {
    if (triggerGetSubs) {
      getAllPoliceOwnersAndSubs();
      setTriggerGetSubs(false);
    }
  }, [triggerGetSubs]);

  useEffect(() => {
    getData();
    getPoliceOwners();
  }, []);

  socket.on('updateFront', () => {
    setOpenEdit(!openEdit);
    getData();
  });

  return (
    <Wrapper>
      <header>
        <div className="back">
          <span onClick={() => onClose(false)} className="icon-back">
            <ArrowBackIcon fontSize="inherit" />
          </span>
        </div>
        <h2>Movimentação de Tickets</h2>
        <div className="third-row-container">
          {abaOption === 'Detalhes' ? (
            details.descricao === 'Resolvido' ? (
              <div className="status-change-handler-container">
                <div
                  className="width-transition mudar-status-title"
                  onClick={mudarStatusClickHandler}
                  style={{
                    width: changeStatus ? '0px' : '96px'
                  }}
                >
                  <span>{!changeStatus ? 'Mudar Status' : ''}</span>
                </div>

                <span onClick={mudarStatusClickHandler} className="arrow-icon">
                  {changeStatus ? '>' : '<'}
                </span>
                <div
                  className="status-change-options-container width-transition"
                  style={{
                    width: !changeStatus ? '0px' : '200px'
                  }}
                >
                  {!changeStatus ? (
                    ''
                  ) : (
                    <>
                      <span onClick={handleReopen}>Reabrir Ticket</span>
                      <span onClick={handleClickConfirm}>Arquivar</span>
                    </>
                  )}
                </div>
              </div>
            ) : (
              ''
            )
          ) : (
            ''
          )}
        </div>
      </header>
      <div className="inner-abas-movimentacoes">
        <div
          className={
            abaOption === 'Inclusão'
              ? 'inner-aba-option-selected'
              : 'inner-aba-option'
          }
          onClick={() => setAbaOption('Inclusão')}
        >
          <ImUserPlus />
          <span className="aba-option-title">Inclusão</span>
        </div>
        <div
          className={
            abaOption === 'Remoção'
              ? 'inner-aba-option-selected'
              : 'inner-aba-option'
          }
          onClick={() => setAbaOption('Remoção')}
        >
          <ImUserMinus />
          <span className="aba-option-title">Remoção</span>
        </div>
        <div
          className={
            abaOption === 'Detalhes'
              ? 'inner-aba-option-selected'
              : 'inner-aba-option'
          }
          onClick={() => setAbaOption('Detalhes')}
        >
          <BiMessageSquareDetail />
          <span className="aba-option-title">Detalhes</span>
        </div>
      </div>
      <section className="content-container">
        {abaOption === 'Inclusão' || abaOption === 'Remoção' ? (
          <>
            <div className="estipulante-select-container">
              <span className="estipulante-title">Estipulante:</span>
              <div className="estipulante-select-row">
                <select
                  className="estipulante-select"
                  defaultValue={''}
                  value={estipulanteSelected}
                  onChange={({ target }) => {
                    setLoading(false);
                    setTitularDependente('');
                    setEstipulanteSelected(target.value);
                    setTipoPlanoSaudeSelected(undefined);
                    setTipoPlanoOdontoSelected(undefined);
                    getSegurosEstipulante(target.value);
                    setSelectedBeneficiario(undefined);
                    setBeneficiariosContratos([]);
                  }}
                >
                  <option value="" disabled style={{ fontWeight: 'bold' }}>
                    Selecione um Estipulante
                  </option>
                  {estipulantes &&
                    estipulantes.map((item, index) => (
                      <option value={item.id} key={index}>
                        {item.razao_social}
                      </option>
                    ))}
                </select>
                {renderSpinnerOnloading()}
              </div>
            </div>
            <div
              className="sub-estipulante-select-container"
              style={{ height: `${allSubEstipulantes.length ? 75.6 : 0}px` }}
            >
              {allSubEstipulantes.length ? (
                <>
                  <span className="estipulante-title">Sub estipulante:</span>
                  <select
                    className="estipulante-select"
                    defaultValue=""
                    value={selectedSubEstipulante}
                    onChange={({ target }) =>
                      subEstipulanteSelectClickHandler(target)
                    }
                  >
                    <option value="" disabled style={{ fontWeight: 'bold' }}>
                      Selecione um Subestipulante
                    </option>
                    <option value={''}></option>
                    {allSubEstipulantes?.map((item, index) => (
                      <option value={item.id} key={index}>
                        {item.razao_social}
                      </option>
                    ))}
                  </select>
                </>
              ) : null}
            </div>
          </>
        ) : (
          ''
        )}
        {abaOption === 'Inclusão' && (
          <MovimentInclusion
            planoSaudeSelected={planoSaudeSelected}
            setPlanoSaudeSelected={setPlanoSaudeSelected}
            titularDependente={titularDependente}
            setTitularDependente={setTitularDependente}
            tipoPlanoSaudeSelected={tipoPlanoSaudeSelected}
            setTipoPlanoSaudeSelected={setTipoPlanoSaudeSelected}
            planoOdontoSelected={planoOdontoSelected}
            setPlanoOdontoSelected={setPlanoOdontoSelected}
            tipoPlanoOdontoSelected={tipoPlanoOdontoSelected}
            setTipoPlanoOdontoSelected={setTipoPlanoOdontoSelected}
            selectedSubEstipulante={selectedSubEstipulante}
            allSubEstipulantes={allSubEstipulantes}
            beneficiarioOption={beneficiarioOption}
            setBeneficiarioOption={setBeneficiarioOption}
            selectedEstipulante={selectedEstipulante}
            confirmationMessage={confirmationMessage}
            beneficiarioJaExistente={beneficiarioJaExistente}
            formIncompleto={formIncompleto}
            setFormIncompleto={setFormIncompleto}
            errorMessageTimeout={errorMessageTimeout}
            clearErrorMessageTimeout={clearErrorMessageTimeout}
            formatNomesTitulares={formatNomesTitulares}
            postNewBeneficiario={postNewBeneficiario}
          />
        )}
        {abaOption === 'Remoção' && (
          <MovimentExclusion
            selectedEstipulante={selectedEstipulante}
            selectedSubEstipulante={selectedSubEstipulante}
            formatNomesTitulares={formatNomesTitulares}
            selectedBeneficiario={selectedBeneficiario}
            setSelectedBeneficiario={setSelectedBeneficiario}
            beneficiarioContratos={beneficiarioContratos}
            setBeneficiariosContratos={setBeneficiariosContratos}
            planosToRemove={planosToRemove}
            setPlanosToRemove={setPlanosToRemove}
            motivoExclusao={motivoExclusao}
            setMotivoExclusao={setMotivoExclusao}
            date={date}
            setDate={setDate}
            postExclusionMoviment={postExclusionMoviment}
          />
        )}
        {abaOption === 'Detalhes' && (
          <MovimentDetails
            open={open}
            setOpen={setOpen}
            setOpenEdit={setOpenEdit}
            loadingDetalhes={loadingDetalhes}
            confirm={confirm}
            reopen={reopen}
            status={status}
            setStatus={setStatus}
            openEdit={openEdit}
            data={data}
            getData={getData}
            details={details}
            messages={messages}
            setMessages={setMessages}
            handleReopen={handleReopen}
            handleCloseDetalhes={handleCloseDetalhes}
            closeTicket={closeTicket}
            handleClickConfirm={handleClickConfirm}
            handleCloseConfirm={handleCloseConfirm}
            archiveTicket={archiveTicket}
            handleCloseReopen={handleCloseReopen}
            reopenTicket={reopenTicket}
            handleChangeStatus={handleChangeStatus}
          />
        )}
      </section>
    </Wrapper>
  );
};

export default DrawerMovimentacaoBeneficiario;
