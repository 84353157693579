import { FilterAtividadesWrapper } from './styles';
import { Radio, ThemeProvider, createTheme } from '@mui/material';
import DatePicker from 'react-datepicker';

const theme = createTheme({
  palette: {
    genoaBlue: {
      main: '#456AED'
    },
    darkGenoaBlue: {
      main: '#1E2552'
    }
  }
});

const FilterAtividades = ({
  corretores,
  vencimento,
  responsavel,
  setVencimento,
  setResponsavel,
  setDataInicial,
  dataInicial,
  setDataFinal,
  dataFinal,
  resetActivitiesFilters
}) => {

  const selectResponsavelChangeHandler = (e) => {
    setResponsavel(e.target.value);
    resetActivitiesFilters();
  };

  return (
    <FilterAtividadesWrapper>
      <div className="filter_type__container">
        <div className="filter_type__title">
          <span>Vencimento</span>
        </div>
        <div className="filter_type__options">
          <ThemeProvider theme={theme}>
            <div className="radio_container">
              <Radio
                onClick={() => setVencimento('vencidas')}
                // className="radio-input dont-close"
                color={'darkGenoaBlue'}
                checked={vencimento === 'vencidas'}
              />
              <label>Vencidas</label>
            </div>
            <div className="radio_container">
              <Radio
                // value="Exclusão"
                onClick={() => setVencimento('hoje')}
                // className="radio-input dont-close"
                color={'darkGenoaBlue'}
                checked={vencimento === 'hoje'}
              />
              <label>Hoje</label>
            </div>

            <div className="radio_container">
              <Radio
                onClick={() => setVencimento('amanha')}
                // className="radio-input dont-close"
                color={'darkGenoaBlue'}
                checked={vencimento === 'amanha'}
              />
              <label>Amanhã</label>
            </div>
            <div className="radio_container">
              <Radio
                // value="Exclusão"
                onClick={() => setVencimento('periodo')}
                // className="radio-input dont-close"
                color={'darkGenoaBlue'}
                checked={vencimento === 'periodo'}
              />
              <label>Período</label>
              <div>
                {vencimento === 'periodo' ? (
                  <div className="datepicker-box-space">
                    <div className="datepicker-container">
                      <span>Data Inicial:</span>
                      <DatePicker
                        className="input-date"
                        locale="ptBR"
                        selected={dataInicial}
                        onChange={(date) => {
                          setDataInicial(date);
                          if (!dataFinal) {
                            setDataFinal(date);
                          }
                        }}
                        placeholderText="..."
                        dateFormat="dd/MM/yyyy"
                      />
                    </div>
                    <div className="datepicker-container">
                      <span>Data Final:</span>
                      <DatePicker
                        className="input-date"
                        disabled={dataInicial === ''}
                        locale="ptBR"
                        selected={dataFinal}
                        onChange={(date) => setDataFinal(date)}
                        placeholderText="..."
                        dateFormat="dd/MM/yyyy"
                      />
                    </div>
                  </div>
                ) : (
                  <div className="datepicker-box-space"></div>
                )}
              </div>
            </div>
          </ThemeProvider>
        </div>
      </div>

      <div className="filter_type__container">
        <div className="filter_type__title">
          <label>Responsável</label>
        </div>
        <div className="filter_type__options">
          <ThemeProvider theme={theme}>
            <div>
              <select
                className="select-arrow"
                value={responsavel}
                onChange={selectResponsavelChangeHandler}
              >
                <option value={undefined}>Selecione</option>
                {corretores?.map((item) => {
                  return (
                    <option
                      key={`corretor-${item?.usuario_id}`}
                      value={item?.usuario_id}
                    >
                      {item.nome} {item.sobrenome}
                    </option>
                  );
                })}
              </select>
            </div>
          </ThemeProvider>
        </div>
      </div>
    </FilterAtividadesWrapper>
  );
};

export default FilterAtividades;
