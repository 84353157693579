import { createContext, useContext, useState } from "react";

export const EmitTicketContext = createContext();

const FORM_DEFAULT_TICKET = {
  aderente: '',
  infosTitular: {},
  vinculo: {},
  dependentes: [],
  planos: {}
}

const FORM_STAGE_DEFAULT_TICKET = {
  stage: 'programaBeneficios',
  downloadedForm: false
}

const EMIT_TICKET_DEFAULT = {
  ticket: ''
}

export const EmitTicketContextProvider = ({ children }) => {
  const [externalForm, setExternalForm] = useState(FORM_DEFAULT_TICKET);
  const [externalFormStage, setExternalFormStage] = useState(FORM_STAGE_DEFAULT_TICKET);
  const [emitTicket, setEmitTicket] = useState(EMIT_TICKET_DEFAULT);

  const updateExternalForm = (data) => {
    setExternalForm({
      ...externalForm,
      ...data
    });
  }

  const updateExternalFormStage = (data) => {
    setExternalFormStage({
      ...externalFormStage,
      stage: data
    });
  }

  const resetExternalForm = () => {
    setExternalForm(FORM_DEFAULT_TICKET);
  }

  const resetExternalFormStage = () => {
    setExternalFormStage(FORM_STAGE_DEFAULT_TICKET);
  }

  return (
    <EmitTicketContext.Provider value={{
      externalForm,
      updateExternalForm,
      resetExternalForm,
      externalFormStage,
      updateExternalFormStage,
      resetExternalFormStage,
      emitTicket,
      setEmitTicket,
    }}>
      {children}
    </EmitTicketContext.Provider>
  );
};

export const useEmitTicket = () => useContext(EmitTicketContext);
