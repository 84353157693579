import { useState } from 'react';
import { Wrapper } from './styles';
import { AiOutlineMinus } from 'react-icons/ai';
import { createTheme } from '@mui/material';
import colors from 'styles/colors';
import services from 'apps/broker/services';
import FormEditPersonalInfo from './FormEditPersonalInfo';
import FormAddNewPlanInfo from './FormAddNewPlanInfo';
import { BiPlusCircle } from 'react-icons/bi';
import { ReactComponent as IconTooth } from 'assets/imgs/svg/teeth.svg';

const theme = createTheme({
  palette: {
    genoaBlue: {
      main: colors.genoaBlue
    },
    darkGenoaBlue: {
      main: colors.genoaDarkBlue
    },
    action: {
      disabled: colors.genoaDisabledGrey
    }
  }
});

const DrawerEditBeneficiary = ({
  data,
  beneficiario,
  estipulanteSelected,
  contratos,
  getBeneficiarios,
  getData
}) => {

  const [abaEditarBeneficiario, setAbaEditarBeneficiario] =
    useState('dados gerais');
  const [initialContractsOptions, setInitialContractsOptions] =
    useState('initial');
  const [planToEdit, setPlanToEdit] = useState('');
  const [nome, setNome] = useState(data?.nome);
  const [cpf, setCpf] = useState(data?.cpf);
  const [email, setEmail] = useState(data?.email);
  const [dataNascimento, setDataNascimento] = useState(
    data?.data_nascimento ? new Date(data?.data_nascimento) : ''
  );
  const [dataVinculo, setDataVinculo] = useState(
    data?.data_vinculo ? new Date(data?.data_vinculo) : ''
  );
  const [telefone, setTelefone] = useState(data?.telefone ? data.telefone : '');
  // Numero de carteirinha e data de entrada são "settadas" no useEffect ao carregar a página
  const [numeroCarteirinhaSaude, setNumeroCarteirinhaSaude] = useState('');
  const [dataEntradaSaude, setDataEntradaSaude] = useState('');
  const [numeroCarteirinhaOdonto, setNumeroCarteirinhaOdonto] = useState('');
  const [dataEntradaOdonto, setDataEntradaOdonto] = useState('');
  const [sexo, setSexo] = useState(data?.sexo ? data.sexo : '');
  const [grauParentesco, setGrauParentesco] = useState(data?.grau_parentesco);
  const [seguradoraSaude, setSeguradoraSaude] = useState('');
  const [planoSaude, setPlanoSaude] = useState('');
  const [seguradoraOdonto, setSeguradoraOdonto] = useState('');
  const [planoOdonto, setPlanoOdonto] = useState('');
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);
  const [adicionarPlano, setAdicionarPlano] = useState(undefined);
  const [contratoOption, setContratoOption] = useState('');
  const [contratoPlanoOption, setContratoPlanoOption] = useState('');
  const [numeroCarteirinhaNovoPlano, setNumeroCarteirinhaNovoPlano] =
    useState('');
  const [dataEntradaNovoPlano, setDataEntradaNovoPlano] = useState('');
  const [mensagemErro, setMensagemErro] = useState('');
  const [mensagemSucesso, setMensagemSucesso] = useState('');

  const getBeneficiariesAfterUpdate = async () => {
    const beneficiaries = await getBeneficiarios(estipulanteSelected?.id);
    return beneficiaries;
  };

  const onSubmit = async () => {
    const beneficiarioInfo = {
      nome,
      email
    };

    const contrato_beneficiario_saude = {
      contrato_id: Number(seguradoraSaude),
      tipo_plano: planoSaude,
      numero_carteirinha: numeroCarteirinhaSaude,
      data_entrada: dataEntradaSaude
    };

    const contrato_beneficiario_odonto = {
      contrato_id: Number(seguradoraOdonto),
      tipo_plano: planoOdonto,
      numero_carteirinha: numeroCarteirinhaOdonto,
      data_entrada: dataEntradaOdonto
    };

    const dados = {
      data_contratacao: dataVinculo,
      cpf,
      data_nascimento: dataNascimento,
      telefone,
      sexo
    };

    if (data.tipo_beneficiario === 'Dependente') {
      dados.grau_parentesco = grauParentesco;
    }

    const body = {
      data,
      contratos,
      newInfo: {
        beneficiario: beneficiarioInfo,
        dados
      }
    };

    if (contratos.some((contrato) => contrato.produto_id === 1)) {
      body.newInfo.contrato_beneficiario_saude = contrato_beneficiario_saude;
    }

    if (contratos.some((contrato) => contrato.produto_id === 2)) {
      body.newInfo.contrato_beneficiario_odonto = contrato_beneficiario_odonto;
    }

    const response = await services.beneficiaries.updatePersonalInformation(
      body
    );

    if (response.data.status === 200) {
      setShowErrorMessage(false);
      setErrorMessage('');
      setShowConfirmationMessage(true);
      setConfirmationMessage(response.data.message);

      setTimeout(() => {
        setShowConfirmationMessage(false);
        setConfirmationMessage('');
        getData();
        getBeneficiariesAfterUpdate();
      }, 1500);
    }

    if (response.data.status === 400) {
      setShowConfirmationMessage(false);
      setConfirmationMessage('');
      setShowErrorMessage(true);
      setErrorMessage(response.data.message);

      setTimeout(() => {
        setShowErrorMessage(false);
        setErrorMessage('');
        getData();
        getBeneficiariesAfterUpdate();
      }, 1500);
    }
  };

  const renderHealthContractsOptionsToEdit = () => {
    return data?.contratos
      ?.filter(
        (contrato) =>
          contrato.movimentacao_remocao === null &&
          contrato.contrato_beneficiario_data_exclusao === null
      )
      .map((contrato) => {
        if (contrato.produto_id === 1) {
          return (
            <div>
              <button
                className="plans-options__button"
                onClick={() => {
                  setInitialContractsOptions('edit');
                  setPlanToEdit(contrato.contrato_id);
                  setSeguradoraSaude(contrato.contrato_id);
                  setPlanoSaude(contrato.tipo_plano);
                  setNumeroCarteirinhaSaude(contrato?.numero_carteirinha);
                  setDataEntradaSaude(contrato?.data_entrada_edit);
                }}
              >
                <BiPlusCircle className="health__icon" />
                <span id={contrato.id}>
                  {`${contrato.seguradora_nome} (${contrato.numero_apolice}) - ${contrato.tipo_plano}` ??
                    `Seguro saúde`}
                </span>
              </button>
            </div>
          );
        } else {
          return <div></div>;
        }
      });
  };

  const renderDentalContractsOptionsToEdit = () => {
    return data?.contratos
      ?.filter(
        (contrato) =>
          contrato.movimentacao_remocao === null &&
          contrato.contrato_beneficiario_data_exclusao === null
      )
      .map((contrato) => {
        if (contrato.produto_id === 2) {
          return (
            <div>
              <button
                className="plans-options__button"
                onClick={() => {
                  setInitialContractsOptions('edit');
                  setPlanToEdit(contrato.contrato_id);
                  setSeguradoraOdonto(contrato.contrato_id);
                  setPlanoOdonto(contrato.tipo_plano);
                  setNumeroCarteirinhaOdonto(contrato?.numero_carteirinha);
                  setDataEntradaOdonto(contrato?.data_entrada_edit);
                }}
              >
                <IconTooth className="dental__icon" />
                <span id={contrato.id}>
                  {`${contrato.seguradora_nome} (${contrato.numero_apolice}) - ${contrato.tipo_plano}` ??
                    `Seguro odontológico`}
                </span>
              </button>
            </div>
          );
        } else {
          return <div></div>;
        }
      });
  };

  // useEffect(() => {
  //   data?.contratos?.forEach((contrato) => {
  //     if (
  //       contrato.produto_id === 1 &&
  //       contrato.movimentacao_remocao === null &&
  //       contrato.contrato_beneficiario_data_exclusao === null
  //     ) {
  //       setSeguradoraSaude(contrato.contrato_id);
  //       setPlanoSaude(contrato.tipo_plano);
  //       setNumeroCarteirinhaSaude(contrato?.numero_carteirinha);

  //       setDataEntradaSaude(contrato?.data_entrada_edit);
  //     }

  //     if (
  //       contrato.produto_id === 2 &&
  //       contrato.movimentacao_remocao === null &&
  //       contrato.contrato_beneficiario_data_exclusao === null
  //     ) {
  //       setSeguradoraOdonto(contrato.contrato_id);
  //       setPlanoOdonto(contrato.tipo_plano);
  //       setNumeroCarteirinhaOdonto(contrato?.numero_carteirinha);

  //       setDataEntradaOdonto(contrato?.data_entrada_edit);
  //     }
  //   });
  // }, []);

  const checkErros = (planoAdicionado) => {
    if (!planoAdicionado.contrato_id && !planoAdicionado.tipo) {
      setMensagemSucesso('');
      setMensagemErro(
        'Por favor selecione ao menos um Contrato e um Plano para criar Movimentação'
      );
      setTimeout(() => {
        setMensagemErro('');
      }, 3500);

      return true;
    }

    if (!planoAdicionado.contrato_id) {
      setMensagemSucesso('');
      setMensagemErro(
        'Por favor selecione ao menos um Contrato para criar Movimentação'
      );
      setTimeout(() => {
        setMensagemErro('');
      }, 3500);

      return true;
    }

    if (!planoAdicionado.tipo) {
      setMensagemSucesso('');
      setMensagemErro(
        'Por favor selecione ao menos um Plano para criar Movimentação'
      );
      setTimeout(() => {
        setMensagemErro('');
      }, 3500);

      return true;
    }

    setMensagemErro('');

    return false;
  };

  const enviarPlanoAdicionadoClickHandler = async () => {
    const planoAdicionado = {
      produto_id: Number(adicionarPlano),
      contrato_id: Number(contratoOption),
      tipo: contratoPlanoOption
    };

    const erros = checkErros(planoAdicionado);

    if (erros) {
      return;
    }

    const novoPlanoInfo = {
      numeroCarteirinhaNovoPlano,
      dataEntradaNovoPlano
    };

    const body = {
      beneficiario: data,
      planoAdicionado,
      novoPlanoInfo
    };

    const response = await services.beneficiaries.updatePlanByBeneficiaryInfo(
      body
    );

    if (response?.data?.status === 400) {
      setMensagemSucesso('');
      setMensagemErro(response?.data?.message);
      setTimeout(() => {
        setMensagemErro('');
      }, 3500);
    }
    if (response?.data?.status === 201) {
      setMensagemErro('');
      if (
        novoPlanoInfo.dataEntradaNovoPlano &&
        novoPlanoInfo.numeroCarteirinhaNovoPlano
      ) {
        setMensagemSucesso('Beneficiário adicionado com sucesso ao plano!');
        setTimeout(() => {
          setMensagemSucesso('');
          getData();
          getBeneficiariesAfterUpdate();
        }, 1500);
      } else {
        setMensagemSucesso(
          'Pedido de inclusão de Beneficiário realizado com sucesso!'
        );
        setTimeout(() => {
          setMensagemSucesso('');
          getData();
          getBeneficiariesAfterUpdate();
        }, 1500);
      }
    }

    setAdicionarPlano(undefined);
    setContratoOption('');
    setContratoPlanoOption('');
    setNumeroCarteirinhaNovoPlano('');
    setDataEntradaNovoPlano('');
  };

  return (
    <Wrapper>
      <header>
        <div className="headerUser">
          <div className="nameUser">
            <div className="user-company">
              <span className="header-title-name">{data.nome}</span>
              <div className="header-title-second-row">
                <div className="subtitle-container">
                  <span className="header-title-estipulante">
                    {beneficiario.razao_social}
                  </span>
                  <span className="header-title-hifen-space">
                    <AiOutlineMinus size={10} />
                  </span>
                  <span className="header-title-estipulante">
                    {data?.tipo_beneficiario}
                  </span>
                  {data?.idade ? (
                    <>
                      <span className="header-title-hifen-space">
                        {' '}
                        <AiOutlineMinus size={10} />
                      </span>
                      <span className="header-title-estipulante">
                        {data?.idade} anos
                      </span>
                    </>
                  ) : null}
                </div>
                <div className="movimentacao-button-container">
                  <span
                    onClick={() => setAbaEditarBeneficiario('adicionar plano')}
                  >
                    + Adicionar plano
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {abaEditarBeneficiario === 'dados gerais' ? (
        <FormEditPersonalInfo
          data={data}
          contratos={contratos}
          nome={nome}
          setNome={setNome}
          cpf={cpf}
          setCpf={setCpf}
          email={email}
          setEmail={setEmail}
          telefone={telefone}
          setTelefone={setTelefone}
          dataNascimento={dataNascimento}
          setDataNascimento={setDataNascimento}
          dataVinculo={dataVinculo}
          setDataVinculo={setDataVinculo}
          sexo={sexo}
          setSexo={setSexo}
          grauParentesco={grauParentesco}
          setGrauParentesco={setGrauParentesco}
          showConfirmationMessage={showConfirmationMessage}
          confirmationMessage={confirmationMessage}
          showErrorMessage={showErrorMessage}
          errorMessage={errorMessage}
          planoSaude={planoSaude}
          setPlanoSaude={setPlanoSaude}
          planoOdonto={planoOdonto}
          setPlanoOdonto={setPlanoOdonto}
          seguradoraSaude={seguradoraSaude}
          setSeguradoraSaude={setSeguradoraSaude}
          seguradoraOdonto={seguradoraOdonto}
          setSeguradoraOdonto={setSeguradoraOdonto}
          numeroCarteirinhaSaude={numeroCarteirinhaSaude}
          setNumeroCarteirinhaSaude={setNumeroCarteirinhaSaude}
          numeroCarteirinhaOdonto={numeroCarteirinhaOdonto}
          setNumeroCarteirinhaOdonto={setNumeroCarteirinhaOdonto}
          dataEntradaSaude={dataEntradaSaude}
          setDataEntradaSaude={setDataEntradaSaude}
          dataEntradaOdonto={dataEntradaOdonto}
          setDataEntradaOdonto={setDataEntradaOdonto}
          onSubmit={onSubmit}
          initialContractsOptions={initialContractsOptions}
          renderHealthContractsOptionsToEdit={
            renderHealthContractsOptionsToEdit
          }
          renderDentalContractsOptionsToEdit={
            renderDentalContractsOptionsToEdit
          }
          setInitialContractsOptions={setInitialContractsOptions}
          planToEdit={planToEdit}
          setPlanToEdit={setPlanToEdit}
        />
      ) : (
        ''
      )}
      {abaEditarBeneficiario === 'adicionar plano' ? (
        <FormAddNewPlanInfo
          theme={theme}
          data={data}
          contratos={contratos}
          setContratoOption={setContratoOption}
          contratoOption={contratoOption}
          setContratoPlanoOption={setContratoPlanoOption}
          contratoPlanoOption={contratoPlanoOption}
          setAdicionarPlano={setAdicionarPlano}
          adicionarPlano={adicionarPlano}
          setNumeroCarteirinhaNovoPlano={setNumeroCarteirinhaNovoPlano}
          numeroCarteirinhaNovoPlano={numeroCarteirinhaNovoPlano}
          setDataEntradaNovoPlano={setDataEntradaNovoPlano}
          dataEntradaNovoPlano={dataEntradaNovoPlano}
          setAbaEditarBeneficiario={setAbaEditarBeneficiario}
          enviarPlanoAdicionadoClickHandler={enviarPlanoAdicionadoClickHandler}
        />
      ) : (
        ''
      )}
      <div className="mensagens-container">
        <span className="error">{mensagemErro}</span>
        <span className="success">{mensagemSucesso}</span>
      </div>
    </Wrapper>
  );
};

export default DrawerEditBeneficiary;
