import styled from 'styled-components';
import colors from 'styles/colors';

export const AbaSelecioneTitularWrapper = styled.div`

  @media (max-height: 580px) {
    display: grid;
    grid-template-rows: 17.342px 1fr 26.68px;
    row-gap: 30px;
  
    .aba-container {
      display: grid;
      grid-template-rows: 17.342px 1fr;
      row-gap: 26.68px;
  
      .aba-title-container {
        span {
          font-size: 12px;
          font-family: Avenir Next GEO W05 Bold;
          color: ${colors.genoaDarkBlue};
        }
      }
  
      .table-container {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
  
        .input-container {
          display: grid;
          grid-template-columns: 1fr 13.34px;
          align-items: end;
          column-gap: 10px;
          width: 40%;
          min-width: 244px;
  
          .remove-file-icon {
            color: ${colors.genoaPink};
            padding-bottom: 5.336px;
            
            :hover {
              cursor: pointer;
            }
          }
        }
      }
  
      .aba-input-row-table {
        width: 100%;
        table {
          
          display: flex;
          flex-direction: column;
          width: 100%;
          thead {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            background-color: ${colors.rhBlue};
            padding: 5.336px 10px;
            color: white;
            padding-right: 12px;
  
            tr {
              font-size: 9.338px;
              display: grid;
              grid-template-columns: 1.2fr 2fr 0.7fr;
               
            }
  
            tr > th {
              font-size: 9.338px;
              font-family: Avenir Next GEO W05 Demi;
            }
  
            th:first-child {
              text-align: left;
            }
  
          }
  
          tbody {
            background-color: white;
            display: flex;
            padding-top: 2px;
            flex-direction: column;
            max-height: 133.34px;
            overflow-y: auto;
  
            tr {
              font-family: Avenir Next GEO W05 Regular;
              font-size: 8px;
              display: grid;
              height: 21.60px;
              line-height: 12.27px;
              grid-template-columns: 1.2fr 2fr 0.7fr;
              padding: 4.669px 10px;
              background-color: #89a9cc1c;
              table-layout: fixed;
              color: ${colors.genoaGrey};
              user-select: none;
              -moz-user-select: none;
              -webkit-user-select: none;
      
              :hover {
                color: ${colors.genoaGrey};
                font-family: Avenir Next GEO W05 Bold;
                cursor: pointer;
                text-shadow: 0.335px 0.335px 0.335px ${colors.rhLightGrey};
  
                .contrato-icones-container {
                  font-family: Avenir Next GEO W05 Regular;
                  text-shadow: none;
                }
              }
            }
  
            tr.selected {
              height: 21.6px;
              color: ${colors.rhBlue};
              font-family: Avenir Next GEO W05 Bold;
              text-shadow: 0.335px 0.335px 0.335px ${colors.rhLightGrey};
  
              .contrato-icones-container {
                font-family: Avenir Next GEO W05 Regular;
                text-shadow: none;
                color: ${colors.rhBlue};
              }
            }
  
            tr:nth-child(2n) {
              background-color: white;
            }
  
            td:nth-child(2), td:last-child {
              display: flex;
              justify-content: center;
            }
  
            ::-webkit-scrollbar {
              border-radius: .667px;
              width: 4px;
              background: transparent;
            }
            ::-webkit-scrollbar-thumb {
              background: ${colors.rhBlue};
              box-shadow: none;
              border-radius: .667px;
            }
            ::-webkit-scrollbar-track {
              box-shadow: inset 0 0 1.334px grey;
              border-radius: .667px;
            }
          }
  
          .contrato-icones-container {
            display: flex;
            column-gap: 6.67px;
            color: ${colors.genoaGrey};
          }
        }
      }
    }
  }

  @media (min-height: 580px) and (max-height: 650px) {
    display: grid;
    grid-template-rows: 20.8px 1fr 32px;
    row-gap: 36px;
  
    .aba-container {
      display: grid;
      grid-template-rows: 20.8px 1fr;
      row-gap: 32px;
  
      .aba-title-container {
        span {
          font-size: 14.4px;
          font-family: Avenir Next GEO W05 Bold;
          color: ${colors.genoaDarkBlue};
        }
      }
  
      .table-container {
        display: flex;
        flex-direction: column;
        row-gap: 24px;
  
        .input-container {
          display: grid;
          grid-template-columns: 1fr 16px;
          align-items: end;
          column-gap: 12px;
          width: 40%;
          min-width: 292.8px;
  
          .remove-file-icon {
            color: ${colors.genoaPink};
            padding-bottom: 6.4px;
            
            :hover {
              cursor: pointer;
            }
          }
        }
      }
  
      .aba-input-row-table {
        width: 100%;
        table {
          
          display: flex;
          flex-direction: column;
          width: 100%;
          thead {
            border-top-left-radius: 4.8px;
            border-top-right-radius: 4.8px;
            background-color: ${colors.rhBlue};
            padding: 6.4px 12px;
            color: white;
            padding-right: 14.4px;
  
            tr {
              font-size: 11.2px;
              display: grid;
              grid-template-columns: 1.2fr 2fr 0.7fr;
               
            }
  
            tr > th {
              font-size: 11.2px;
              font-family: Avenir Next GEO W05 Demi;
            }
  
            th:first-child {
              text-align: left;
            }
  
          }
  
          tbody {
            background-color: white;
            display: flex;
            padding-top: 2.4px;
            flex-direction: column;
            max-height: 160px;
            overflow-y: auto;
  
            tr {
              font-family: Avenir Next GEO W05 Regular;
              font-size: 9.6px;
              display: grid;
              height: 25.92px;
              line-height: 14.72px;
              grid-template-columns: 1.2fr 2fr 0.7fr;
              padding: 5.6px 12px;
              background-color: #89a9cc1c;
              table-layout: fixed;
              color: ${colors.genoaGrey};
              user-select: none;
              -moz-user-select: none;
              -webkit-user-select: none;
      
              :hover {
                color: ${colors.genoaGrey};
                font-family: Avenir Next GEO W05 Bold;
                cursor: pointer;
                text-shadow: 0.4px 0.4px 0.4px ${colors.rhLightGrey};
  
                .contrato-icones-container {
                  font-family: Avenir Next GEO W05 Regular;
                  text-shadow: none;
                }
              }
            }
  
            tr.selected {
              height: 25.92px;
              color: ${colors.rhBlue};
              font-family: Avenir Next GEO W05 Bold;
              text-shadow: 0.4px 0.4px 0.4px ${colors.rhLightGrey};
  
              .contrato-icones-container {
                font-family: Avenir Next GEO W05 Regular;
                text-shadow: none;
                color: ${colors.rhBlue};
              }
            }
  
            tr:nth-child(2n) {
              background-color: white;
            }
  
            td:nth-child(2), td:last-child {
              display: flex;
              justify-content: center;
            }
  
            ::-webkit-scrollbar {
              border-radius: .8px;
              width: 4.8px;
              background: transparent;
            }
            ::-webkit-scrollbar-thumb {
              background: ${colors.rhBlue};
              box-shadow: none;
              border-radius: .8px;
            }
            ::-webkit-scrollbar-track {
              box-shadow: inset 0 0 1.6px grey;
              border-radius: .8px;
            }
          }
  
          .contrato-icones-container {
            display: flex;
            column-gap: 8px;
            color: ${colors.genoaGrey};
          }
        }
      }
    }
  }

  @media (min-height: 650px) and (max-height: 720px) {
    display: grid;
    grid-template-rows: 23.4px 1fr 36px;
    row-gap: 40.5px;
  
    .aba-container {
      display: grid;
      grid-template-rows: 23.4px 1fr;
      row-gap: 36px;
  
      .aba-title-container {
        span {
          font-size: 16.2px;
          font-family: Avenir Next GEO W05 Bold;
          color: ${colors.genoaDarkBlue};
        }
      }
  
      .table-container {
        display: flex;
        flex-direction: column;
        row-gap: 27px;
  
        .input-container {
          display: grid;
          grid-template-columns: 1fr 18px;
          align-items: end;
          column-gap: 13.5px;
          width: 40%;
          min-width: 329.4px;
  
          .remove-file-icon {
            color: ${colors.genoaPink};
            padding-bottom: 7.2px;
            
            :hover {
              cursor: pointer;
            }
          }
        }
      }
  
      .aba-input-row-table {
        width: 100%;
        table {
          
          display: flex;
          flex-direction: column;
          width: 100%;
          thead {
            border-top-left-radius: 5.4px;
            border-top-right-radius: 5.4px;
            background-color: ${colors.rhBlue};
            padding: 7.2px 13.5px;
            color: white;
            padding-right: 16.2px;
  
            tr {
              font-size: 12.6px;
              display: grid;
              grid-template-columns: 1.08fr 1.8fr 0.63fr;
               
            }
  
            tr > th {
              font-size: 12.6px;
              font-family: Avenir Next GEO W05 Demi;
            }
  
            th:first-child {
              text-align: left;
            }
  
          }
  
          tbody {
            background-color: white;
            display: flex;
            padding-top: 2.7px;
            flex-direction: column;
            max-height: 180px;
            overflow-y: auto;
  
            tr {
              font-family: Avenir Next GEO W05 Regular;
              font-size: 10.8px;
              display: grid;
              height: 29.16px;
              line-height: 16.56px;
              grid-template-columns: 1.08fr 1.8fr 0.63fr;
              padding: 6.3px 13.5px;
              background-color: #89a9cc1c;
              table-layout: fixed;
              color: ${colors.genoaGrey};
              user-select: none;
              -moz-user-select: none;
              -webkit-user-select: none;
      
              :hover {
                color: ${colors.genoaGrey};
                font-family: Avenir Next GEO W05 Bold;
                cursor: pointer;
                text-shadow: 0.35px 0.35px 0.35px ${colors.rhLightGrey};
  
                .contrato-icones-container {
                  font-family: Avenir Next GEO W05 Regular;
                  text-shadow: none;
                }
              }
            }
  
            tr.selected {
              height: 29.16px;
              color: ${colors.rhBlue};
              font-family: Avenir Next GEO W05 Bold;
              text-shadow: 0.35px 0.35px 0.35px ${colors.rhLightGrey};
  
              .contrato-icones-container {
                font-family: Avenir Next GEO W05 Regular;
                text-shadow: none;
                color: ${colors.rhBlue};
              }
            }
  
            tr:nth-child(2n) {
              background-color: white;
            }
  
            td:nth-child(2), td:last-child {
              display: flex;
              justify-content: center;
            }
  
            ::-webkit-scrollbar {
              border-radius: .9px;
              width: 5.4px;
              background: transparent;
            }
            ::-webkit-scrollbar-thumb {
              background: ${colors.rhBlue};
              box-shadow: none;
              border-radius: .9px;
            }
            ::-webkit-scrollbar-track {
              box-shadow: inset 0 0 1.8px grey;
              border-radius: .9px;
            }
          }
  
          .contrato-icones-container {
            display: flex;
            column-gap: 9px;
            color: ${colors.genoaGrey};
          }
        }
      }
    }
  }

  @media (min-height: 720px) {
    display: grid;
    grid-template-rows: 26px 1fr 40px;
    row-gap: 45px;
  
    .aba-container {
      display: grid;
      grid-template-rows: 26px 1fr;
      row-gap: 40px;
  
      .aba-title-container {
        span {
          font-size: 18px;
          font-family: Avenir Next GEO W05 Bold;
          color: ${colors.genoaDarkBlue};
        }
      }
  
      .table-container {
        display: flex;
        flex-direction: column;
        row-gap: 30px;
  
        .input-container {
          display: grid;
          grid-template-columns: 1fr 20px;
          align-items: end;
          column-gap: 15px;
          width: 40%;
          min-width: 366px;
  
          .remove-file-icon {
            color: ${colors.genoaPink};
            padding-bottom: 8px;
            
            :hover {
              cursor: pointer;
            }
          }
        }
      }
  
      .aba-input-row-table {
        width: 100%;
        table {
          
          display: flex;
          flex-direction: column;
          width: 100%;
          thead {
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            background-color: ${colors.rhBlue};
            padding: 8px 15px;
            color: white;
            padding-right: 18px;
  
            tr {
              font-size: 14px;
              display: grid;
              grid-template-columns: 1.2fr 2fr 0.7fr;
               
            }
  
            tr > th {
              font-size: 14px;
              font-family: Avenir Next GEO W05 Demi;
            }
  
            th:first-child {
              text-align: left;
            }
  
          }
  
          tbody {
            background-color: white;
            display: flex;
            padding-top: 3px;
            flex-direction: column;
            max-height: 200px;
            overflow-y: auto;
  
            tr {
              font-family: Avenir Next GEO W05 Regular;
              font-size: 12px;
              display: grid;
              height: 32.4px;
              line-height: 18.4px;
              grid-template-columns: 1.2fr 2fr 0.7fr;
              padding: 7px 15px;
              background-color: #89a9cc1c;
              table-layout: fixed;
              color: ${colors.genoaGrey};
              user-select: none;
              -moz-user-select: none;
              -webkit-user-select: none;
      
              :hover {
                color: ${colors.genoaGrey};
                font-family: Avenir Next GEO W05 Bold;
                cursor: pointer;
                text-shadow: 0.5px 0.5px 0.5px ${colors.rhLightGrey};
  
                .contrato-icones-container {
                  font-family: Avenir Next GEO W05 Regular;
                  text-shadow: none;
                }
              }
            }
  
            tr.selected {
              height: 32.4px;
              color: ${colors.rhBlue};
              font-family: Avenir Next GEO W05 Bold;
              text-shadow: 0.5px 0.5px 0.5px ${colors.rhLightGrey};
  
              .contrato-icones-container {
                font-family: Avenir Next GEO W05 Regular;
                text-shadow: none;
                color: ${colors.rhBlue};
              }
            }
  
            tr:nth-child(2n) {
              background-color: white;
            }
  
            td:nth-child(2), td:last-child {
              display: flex;
              justify-content: center;
            }
  
            ::-webkit-scrollbar {
              border-radius: 1px;
              width: 6px;
              background: transparent;
            }
            ::-webkit-scrollbar-thumb {
              background: ${colors.rhBlue};
              box-shadow: none;
              border-radius: 1px;
            }
            ::-webkit-scrollbar-track {
              box-shadow: inset 0 0 2px grey;
              border-radius: 1px;
            }
          }
  
          .contrato-icones-container {
            display: flex;
            column-gap: 10px;
            color: ${colors.genoaGrey};
          }
        }
      }
    }
  }
`