import React from 'react';
import Header from 'apps/hr/components/Header-RH-Novo';
import SidemenuRh from 'apps/hr/components/Sidebar-RH';
import MainRh from 'apps/hr/components/MainContentRh';
import { Wrapper, Content } from './styles';
import api from 'services/api';
import { useSelector } from 'react-redux';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, EditorState } from 'draft-js';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import colors from 'styles/colors';
import { BiPlus } from 'react-icons/bi';
import moment from 'moment';
import { GiHealthNormal } from 'react-icons/gi';
import { FaTooth } from 'react-icons/fa';
import CloseIcon from '@material-ui/icons/Close';
import { RiFileUploadFill } from 'react-icons/ri';
import {
  Box,
  CircularProgress,
  makeStyles,
  Popper,
  SwipeableDrawer
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import draftToHtml from 'draftjs-to-html';
import { AiOutlineCheck } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';

const editorStyle = {
  border: `1.5px solid ${colors.rhBlue}`,
  borderTop: 'none',
  borderBottomLeftRadius: '15px',
  borderBottomRightRadius: '15px',
  backgroundColor: 'white',
  fontSize: '15px'
};

const toolbar = {
  options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'link'],
  inline: { inDropdown: true },
  list: { inDropdown: true },
  textAlign: { inDropdown: true },
  link: { inDropdown: true },
  history: { inDropdown: true }
};

const useStyles = makeStyles({
  paper: {
    background: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh'
  }
});

const HomeRH = () => {
  const posthog = usePostHog();
  const [tickets, setTickets] = React.useState({});
  const [movimentacoes, setMovimentacoes] = React.useState([]);
  const [contratos, setContratos] = React.useState([]);
  const user = useSelector((state) => state.userReducers.user);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const { handleSubmit } = useForm();
  const [files, setFiles] = useState([]);
  const [height, setHeight] = React.useState(window.innerHeight);
  const [openDocumentsPopper, setOpenDocumentsPopper] = React.useState(false);
  const [docPopperAnchor, setDocPopperAnchor] = React.useState(null);
  const [subject, setSubject] = React.useState('');
  const [submitSucess, setSubmitSucess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();

  const classes = useStyles();
  const [openAdicionarBeneficiario, setOpenAdicionarBeneficiario] =
    React.useState(false);

  const [contratosEstipulante, setContratosEstipulante] = React.useState({
    saude: [],
    odonto: []
  });

  const [estipulanteAndSubs, setEstipulantesAndSubs] = React.useState([]);

  const onEditorStateChange = (editorState) => {
    // console.log(editorState)
    setEditorState(editorState);
  };

  const handleFile = (target) => {
    if (target.files?.length === 0 || target.files === null) {
      return;
    }
    if (target.files.length > 1) {
      posthog?.capture('Adicionou um documento à criação do Ticket');
      const newFiles = [];
      Array.from(target.files).forEach((file) => {
        newFiles.push(file);
      });
      const oldFiles = files;
      setFiles([...oldFiles, ...newFiles]);
    } else {
      const oldFiles = files;
      setFiles([...oldFiles, target.files[0]]);
    }
  };

  const deleteFile = (index) => {
    const newArray = files;
    newArray.splice(index, 1);
    if (newArray.length === 0) {
      setFiles([]);
      setOpenDocumentsPopper(false);
      setDocPopperAnchor(null);
    }
    setFiles([...newArray]);
  };

  const toggleOpenDocumentsPopper = ({ target }) => {
    if (files?.length) {
      posthog?.capture('Hover p/ mostrar documentos em anexo');
      setOpenDocumentsPopper(true);
      setDocPopperAnchor(target);
    }
  };

  const toggleCloseDocumentsPopper = ({ target }) => {
    // console.log(target.classList)
    setOpenDocumentsPopper(false);
    setDocPopperAnchor(null);
  };

  const meses = {
    Janeiro: 0,
    Fevereiro: 1,
    Março: 2,
    Abril: 3,
    Maio: 4,
    Junho: 5,
    Julho: 6,
    Agosto: 7,
    Setembro: 8,
    Outubro: 9,
    Novembro: 10,
    Dezembro: 11
  };

  const renderProximoReajuste = (contrato) => {
    const mesAniversario = meses[contrato?.mes_aniversario];
    const mesAtual = moment().locale('pt-br').month();
    const anoAtual = moment().locale('pt-br').year();
    const anoAtualResumido = Number(String(anoAtual).slice(2, 4));

    if (Number(mesAtual) > Number(mesAniversario)) {
      return `${contrato.mes_aniversario} / ${anoAtualResumido + 1}`;
    }

    if (Number(mesAtual) <= Number(mesAniversario)) {
      return `${contrato.mes_aniversario} / ${anoAtualResumido}`;
    }
  };

  const renderIconesProdutos = (produtos) => {
    return (
      <>
        {produtos.some((item) => item === 1) ? (
          <span>
            <GiHealthNormal />
          </span>
        ) : (
          ''
        )}

        {produtos.some((item) => item === 2) ? (
          <span>
            <FaTooth />
          </span>
        ) : (
          ''
        )}
      </>
    );
  };

  const resetTicketInputs = () => {
    setEditorState(EditorState.createEmpty());
    setSubject('');
    setFiles([]);
  };

  const onSubmit = () => {
    posthog?.capture('Clicou em "Enviar" ticket');
    const text = convertToRaw(editorState.getCurrentContent()).blocks[0].text;
    // console.log(convertToRaw(editorState.getCurrentContent()))
    // console.log(text)
    // console.log(files)
    // if (text.length === 0) {
    //   setErrorEmptyMsg(true);
    //   return;
    // }
    const htmlMessage = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );

    const formData = new FormData();

    formData.append('assunto', subject);
    formData.append('message', htmlMessage);
    files.forEach((file, index) => {
      formData.append(index, file);
    });

    if (htmlMessage.length > 8 && subject.length > 0) {
      // corretora: 6 => id da corretora Genoa
      // Inicialmente apenas para Genoa, futuramente irá expandir para demais

      const headers = {
        corretora: 6,
        estipulante: user.mainEstipulante,
        uuid: user.uuid,
        authorization: `Bearer ${user.token}`
      };
      setLoading(true);
      api
        .post('/rh/create-ticket', formData, { headers: headers })
        .then((resp) => {
          if (resp.status === 200) {
            posthog?.capture('Enviou Ticket com sucesso');
            setSubmitSucess(true);
            setLoading(false);
            setTimeout(() => {
              setSubmitSucess(false);
            }, 4000);
            resetTicketInputs();
          }
        });
      //   .then((response) => {
      //     if (props.getTickets) {
      //       props.getTickets();
      //     }

      //     setSuccess(true);
      //     setSubmittedData({ assunto });
      //     setEditorState(EditorState.createEmpty());
      //     history.push("/rh/tickets");
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    } else {
      console.log('Não enviou');
    }
  };

  const getContratos = async () => {
    api
      .get('/rh/estipulante-sub/contratos/more-info', {
        headers: {
          id: user.mainEstipulante,
          authorization: `Bearer ${user.token}`
        }
      })
      .then((resp) => {
        const contratosAtivos = resp.data.contratos.filter(
          (contrato) => contrato.status === 'Ativo'
        );

        // console.log(contratosAtivos)
        setContratos(contratosAtivos);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getMovimentacoes = () => {
    api
      .get('/rh/count/movimentacao', {
        headers: {
          id: user.mainEstipulante,
          authorization: `Bearer ${user.token}`
        }
      })
      .then((resp) => {
        // console.log(resp.data)
        setMovimentacoes(resp.data);
      });
  };

  function getTicketsCount() {
    api
      .get('/rh/count', {
        headers: {
          id: user.estipulante,
          authorization: `Bearer ${user.token}`
        }
      })
      .then((resp) => {
        setTickets(resp.data);
        // console.log(resp.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getContratosAtivosEstipulante = () => {
    api
      .get(`/rh/estipulante-sub/contratos/info/${user.mainEstipulante}`, {
        headers: {
          authorization: `Bearer ${user.token}`
        }
      })
      .then((resp) => {
        // console.log(resp.data)
        setContratosEstipulante(resp.data);
      });
  };

  const getEstipulanteAndSubs = () => {
    api
      .get(`rh/estipulante-sub/info/${user.mainEstipulante}`, {
        headers: {
          authorization: `Bearer ${user.token}`
        }
      })
      .then((resp) => {
        setEstipulantesAndSubs(resp?.data);
      });
  };

  React.useEffect(() => {
    posthog?.capture('Acessou "/rh/home"');
    getTicketsCount();
    getMovimentacoes();
    getContratos();
    getContratosAtivosEstipulante();
    getEstipulanteAndSubs();
    // console.log(data)
  }, []);

  const setMovimentacaoTbodyHeight = () => {
    if (height <= 470) {
      const maxHeight = ((height - 149.59) / 2 - 110 - 15) / 0.3;
      const itemsHeight = (contratos.length * 37.6) / 0.3;

      if (itemsHeight > maxHeight) {
        return `${maxHeight}px`;
      }
      return `${itemsHeight}px`;
    }

    if (height <= 500) {
      const maxHeight = ((height - 149.59) / 2 - 110 - 15) / 0.37;
      const itemsHeight = (contratos.length * 37.6) / 0.37;

      if (itemsHeight > maxHeight) {
        return `${maxHeight}px`;
      }
      return `${itemsHeight}px`;
    }

    if (height <= 573) {
      const maxHeight = ((height - 149.59) / 2 - 110 - 15) / 0.5;
      const itemsHeight = (contratos.length * 37.6) / 0.5;

      if (itemsHeight > maxHeight) {
        return `${maxHeight}px`;
      }
      return `${itemsHeight}px`;
    }

    if (height <= 620) {
      const maxHeight = ((height - 149.59) / 2 - 110 - 15) / 0.67;
      const itemsHeight = (contratos.length * 37.6) / 0.67;

      if (itemsHeight > maxHeight) {
        return `${maxHeight}px`;
      }
      return `${itemsHeight}px`;
    }

    if (height <= 710) {
      const maxHeight = ((height - 149.59) / 2 - 110 - 15) / 0.8;
      const itemsHeight = (contratos.length * 37.6) / 0.8;

      if (itemsHeight > maxHeight) {
        return `${maxHeight}px`;
      }
      return `${itemsHeight}px`;
    }

    if (height <= 850) {
      const maxHeight = ((height - 149.59) / 2 - 110 - 15) / 0.9;
      const itemsHeight = (movimentacoes.length * 37.6) / 0.9;

      if (itemsHeight > maxHeight) {
        return `${maxHeight}px`;
      }
      return `${itemsHeight}px`;
    }

    if (height > 850) {
      const maxHeight = height - 149.59 - 359.8 - 110;
      const itemsHeight = movimentacoes.length * 37;

      if (itemsHeight > maxHeight) {
        return `${maxHeight}px`;
      }
      return `${itemsHeight}px`;
    }
  };

  const setMovimentacaoHeaderMarginRight = () => {
    if (height <= 470) {
      const maxHeight = ((height - 149.59) / 2 - 110 - 15) / 0.3;
      const itemsHeight = (contratos.length * 37.6) / 0.3;

      if (itemsHeight > maxHeight) {
        return `${maxHeight}px`;
      }
      return `${itemsHeight}px`;
    }

    if (height <= 500) {
      const maxHeight = ((height - 149.59) / 2 - 110 - 15) / 0.37;
      const itemsHeight = (contratos.length * 37.6) / 0.37;

      if (itemsHeight >= maxHeight) {
        return '6px';
      }
      return '0px';
    }

    if (height <= 573) {
      const maxHeight = ((height - 149.59) / 2 - 110 - 15) / 0.5;
      const itemsHeight = (contratos.length * 37.6) / 0.5;

      if (itemsHeight >= maxHeight) {
        return '6px';
      }
      return '0px';
    }

    if (height <= 620) {
      const maxHeight = ((height - 149.59) / 2 - 110 - 15) / 0.67;
      const itemsHeight = (contratos.length * 37.6) / 0.67;

      if (itemsHeight >= maxHeight) {
        return '6px';
      }
      return '0px';
    }

    if (height <= 710) {
      const maxHeight = ((height - 149.59) / 2 - 110 - 15) / 0.7;
      const itemsHeight = (contratos.length * 37.6) / 0.7;

      if (itemsHeight >= maxHeight) {
        return '6px';
      }
      return '0px';
    }

    if (height <= 850) {
      const maxHeight = (height - 149.59) / 2 - 110 - 15;
      const itemsHeight = movimentacoes.length * 37.6;
      // console.log(maxHeight, itemsHeight)
      if (itemsHeight >= maxHeight) {
        return '6px';
      }
      return '0px';
    }

    if (height > 850) {
      const maxHeight = height - 149.59 - 359.8 - 110;
      const itemsHeight = movimentacoes.length * 37;

      if (itemsHeight > maxHeight) {
        return '6px';
      }
      return '0px';
    }
  };

  const setContratosTbodyHeight = () => {
    if (height <= 470) {
      const maxHeight = ((height - 149.59) / 2 - 110 - 15) / 0.3;
      const itemsHeight = (contratos.length * 37.6) / 0.3;

      if (itemsHeight > maxHeight) {
        return `${maxHeight}px`;
      }
      return `${itemsHeight}px`;
    }

    if (height <= 500) {
      const maxHeight = ((height - 149.59) / 2 - 110 - 15) / 0.37;
      const itemsHeight = (contratos.length * 37.6) / 0.37;

      if (itemsHeight > maxHeight) {
        return `${maxHeight}px`;
      }
      return `${itemsHeight}px`;
    }

    if (height <= 573) {
      const maxHeight = ((height - 149.59) / 2 - 110 - 15) / 0.5;
      const itemsHeight = (contratos.length * 37.6) / 0.5;

      if (itemsHeight > maxHeight) {
        return `${maxHeight}px`;
      }
      return `${itemsHeight}px`;
    }

    if (height <= 620) {
      const maxHeight = ((height - 149.59) / 2 - 110 - 15) / 0.67;
      const itemsHeight = (contratos.length * 37.6) / 0.67;

      if (itemsHeight > maxHeight) {
        return `${maxHeight}px`;
      }
      return `${itemsHeight}px`;
    }

    if (height <= 710) {
      const maxHeight = ((height - 149.59) / 2 - 110 - 15) / 0.8;
      const itemsHeight = (contratos.length * 37.6) / 0.8;

      if (itemsHeight > maxHeight) {
        return `${maxHeight}px`;
      }
      return `${itemsHeight}px`;
    }

    if (height <= 850) {
      const maxHeight = ((height - 149.59) / 2 - 110 - 15) / 0.9;
      const itemsHeight = (movimentacoes.length * 37.6) / 0.9;

      if (itemsHeight > maxHeight) {
        return `${maxHeight}px`;
      }
      return `${itemsHeight}px`;
    }

    if (height > 850) {
      const maxHeight = height - 149.59 - 359.8 - 110;
      const itemsHeight = movimentacoes.length * 37;

      if (itemsHeight > maxHeight) {
        return `${maxHeight}px`;
      }
      return `${itemsHeight}px`;
    }
  };

  const setContratosHeaderMarginRight = () => {
    if (height <= 470) {
      const maxHeight = ((height - 149.59) / 2 - 110 - 15) / 0.3;
      const itemsHeight = (contratos.length * 37.6) / 0.3;

      if (itemsHeight > maxHeight) {
        return `${maxHeight}px`;
      }
      return `${itemsHeight}px`;
    }

    if (height <= 500) {
      const maxHeight = ((height - 149.59) / 2 - 110 - 15) / 0.37;
      const itemsHeight = (contratos.length * 37.6) / 0.37;

      if (itemsHeight >= maxHeight) {
        return '6px';
      }
      return '0px';
    }
    if (height <= 573) {
      const maxHeight = ((height - 149.59) / 2 - 110 - 15) / 0.5;
      const itemsHeight = (contratos.length * 37.6) / 0.5;

      if (itemsHeight >= maxHeight) {
        return '6px';
      }
      return '0px';
    }

    if (height <= 620) {
      const maxHeight = ((height - 149.59) / 2 - 110 - 15) / 0.67;
      const itemsHeight = (contratos.length * 37.6) / 0.67;

      if (itemsHeight >= maxHeight) {
        return '6px';
      }
      return '0px';
    }

    if (height <= 710) {
      const maxHeight = ((height - 149.59) / 2 - 110 - 15) / 0.7;
      const itemsHeight = (contratos.length * 37.6) / 0.7;

      if (itemsHeight >= maxHeight) {
        return '6px';
      }
      return '0px';
    }

    if (height <= 850) {
      const maxHeight = (height - 149.59) / 2 - 110 - 15;
      const itemsHeight = movimentacoes.length * 37.6;
      // console.log(maxHeight, itemsHeight)
      if (itemsHeight >= maxHeight) {
        return '6px';
      }
      return '0px';
    }

    if (height > 850) {
      const maxHeight = height - 149.59 - 359.8 - 110;
      const itemsHeight = movimentacoes.length * 37;

      if (itemsHeight > maxHeight) {
        return '6px';
      }
      return '0px';
    }
  };

  const renderCircularSpinner = () => {
    if (loading) {
      return <CircularProgress size={13} className="circular-spinner" />;
    }

    if (!loading && submitSucess) {
      return <AiOutlineCheck size={13} className="check-icon" />;
    }
  };

  const navigateToMovimentacoes = () => {
    posthog?.capture('Clicou em "ver todas" movimentações');
    return history.push('/rh/movimentacao-beneficiarios');
  };

  const navigateToContratos = () => {
    posthog?.capture('Clicou em "ver todos" contratos');
    return history.push('/rh/contratos');
  };

  React.useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
  });

  const renderVidasAtivas = (contrato) => {
    const vidasAtivasFiltradas = contrato?.vidas?.filter(
      (contrato) => contrato.status === 'Ativo'
    );
    const vidasAtivas = vidasAtivasFiltradas?.length;

    if (vidasAtivas === 1) {
      if (contrato?.vidas[0]) {
        return 1;
      }
      return 0;
    }

    return vidasAtivas;
  };

  return (
    <Wrapper>
      <SidemenuRh />
      <Content>
        <Header getMovimentacoes={getMovimentacoes} />
        <MainRh>
          <div className="main-content">
            <div className="column-one">
              <div className="row-one">
                <div className="title">
                  <span>Atendimentos em aberto</span>
                </div>
                <div className="tickets-painel-container">
                  <div className="tickets-painel">
                    <div className="info-painel">
                      <div className="info">
                        <span className="data-value">
                          {tickets?.movimentacao}
                        </span>
                        <span className="data-info-title">Movimentações</span>
                      </div>
                    </div>
                    <div className="info-painel">
                      <div className="info">
                        <span className="data-value">{tickets?.tickets}</span>
                        <span className="data-info-title">Tickets</span>
                      </div>
                    </div>
                    <div className="info-painel">
                      <div className="info">
                        <span className="data-value">{tickets?.concierge}</span>
                        <span className="data-info-title">Concierge</span>
                      </div>
                    </div>
                    <div className="info-painel">
                      <div className="info">
                        <span className="data-value">
                          {tickets?.movimentacao +
                            tickets?.tickets +
                            tickets?.concierge}
                        </span>
                        <span className="data-info-title">Total</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="row-two" className="row-two">
                <div className="title-row-two">
                  <span>Movimentações em aberto</span>
                </div>

                <table>
                  <thead
                    style={{
                      marginRight: setMovimentacaoHeaderMarginRight()
                    }}
                  >
                    <th>Beneficiário</th>
                    <th>Tipo</th>
                    <th>Produto</th>
                    <th>Data do pedido</th>
                  </thead>
                  <tbody
                    style={{
                      maxHeight: setMovimentacaoTbodyHeight()
                    }}
                  >
                    {movimentacoes?.map((item) => (
                      <tr>
                        <td>{item.nome}</td>
                        <td className="data">{item.tipo_movimentacao}</td>
                        <td className="icons-container data">
                          {renderIconesProdutos(item.produtos)}
                        </td>
                        <td className="data">
                          {moment(item.created_at).format('DD /MM/YYYY')}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="show-all">
                  <span onClick={navigateToMovimentacoes}>ver todos</span>
                </div>
              </div>
            </div>
            <div className="column-two">
              <div className="row-one">
                <div className="title">
                  <span>Novo Ticket</span>
                </div>
                <div className="ticket-editor-container">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="ticket-subject-input-container">
                      <span className="ticket-subject-title">Título:</span>
                      <input
                        type="text"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        className="ticket-subject-input"
                        placeholder="Clique para trocar o título"
                      />
                    </div>
                    <Editor
                      toolbar={toolbar}
                      // editorStyle={editorStyle}
                      editorState={editorState}
                      placeholder={
                        'Precisa da nossa ajuda? É só escrever aqui e enviar =) \nVocê também pode criar um ticket enviando um e-mail para:\nbeneficiosgenoa@coinsure.freshdesk.com'
                      }
                      onEditorStateChange={onEditorStateChange}
                      toolbarOnFocus
                    />
                    <div className="buttons-container">
                      <label className="input-label">
                        <span>
                          <BiPlus /> Documentos
                        </span>
                        <input
                          className="input-file"
                          type="file"
                          onChange={({ target }) => handleFile(target)}
                          onClick={() =>
                            posthog?.capture('Clicou em "+ Documentos"')
                          }
                          multiple
                        />
                      </label>
                      <div
                        className="upload-icon-container"
                        onMouseLeave={toggleCloseDocumentsPopper}
                      >
                        <RiFileUploadFill
                          className={
                            files?.length
                              ? 'upload-icon'
                              : 'upload-icon-disabled'
                          }
                          onMouseEnter={toggleOpenDocumentsPopper}
                        />

                        {files?.length ? (
                          <Popper
                            open={openDocumentsPopper}
                            anchorEl={docPopperAnchor}
                            placement="bottom-start"
                            transition
                            disablePortal
                            className="documents-popper"
                          >
                            <Box>
                              <Paper
                                elevation={2}
                                className="documents-attached-info-container"
                              >
                                <span className="documents-attached-title">
                                  Documentos anexados:
                                </span>
                                <div className="documents-list-container">
                                  {files?.length > 0
                                    ? files.map((file, index) => {
                                        return (
                                          <div className="file" key={index}>
                                            <span>{file?.name}</span>
                                            <button
                                              className="btn-clear"
                                              onClick={() => deleteFile(index)}
                                            >
                                              <CloseIcon size={13} />
                                            </button>
                                          </div>
                                        );
                                      })
                                    : null}
                                </div>
                              </Paper>
                            </Box>
                          </Popper>
                        ) : (
                          ''
                        )}
                      </div>

                      <button type="submit">
                        <span>Enviar</span>
                        {renderCircularSpinner()}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="row-two">
                <div className="title-row-two">
                  <span>Contratos Ativos</span>
                </div>
                <table>
                  <thead
                    style={{
                      marginRight: setContratosHeaderMarginRight()
                    }}
                  >
                    <th>Seguradora</th>
                    <th>Produto</th>
                    <th>Próximo Reajuste</th>
                    <th>Vidas Ativas</th>
                  </thead>
                  <tbody
                    style={{
                      maxHeight: setContratosTbodyHeight()
                    }}
                  >
                    {contratos?.map((item) => (
                      <tr>
                        <td>{item?.nome}</td>
                        <td className="icons-container data">
                          {renderIconesProdutos([item?.produto_id])}
                        </td>
                        <td>{renderProximoReajuste(item)}</td>
                        <td>
                          <span className="vidas-span">
                            {renderVidasAtivas(item)}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="show-all">
                  <span onClick={navigateToContratos}>ver todos</span>
                </div>
              </div>
            </div>
          </div>
        </MainRh>
        {/* <SwipeableDrawer
          classes={{ paper: classes.paper }}
          anchor="top"
          transitionDuration={0}
          open={openAdicionarBeneficiario}
          onClose={toggleDrawerAdicionarBeneficiario}
        >
          {contentAdicionarBeneficiario()}
        </SwipeableDrawer> */}
      </Content>
    </Wrapper>
  );
};

export default HomeRH;
