//import apps/broker/services
import http, { TOKEN_LABEL, BROKERAGE_ID, BROKERAGE_UUID } from 'services/http';

const login = async ({ credential, password }) => {
  const regexCNPJ = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/;

  const payload = {
    cnpj: credential.replace(regexCNPJ, '$1.$2.$3/$4-$5'),
    password: password
  };

  const res = await http.post('/login/corretor', payload);
  sessionStorage.setItem(TOKEN_LABEL, JSON.stringify(res.data));

  return res;
};

const createResetPassword = async (data) => {
  const res = await http.post('/recuperar-senha/corretora', data);

  return res;
};

const getRequestsCount = async () => {
  const res = await http.get('/corretor/count');

  return res;
};

const getRequestsCompaniesToEachUser = async () => {
  const res = await http.get('/corretor/demandas-usuario', {
    headers: {
      id: BROKERAGE_ID
    }
  });

  return res;
};

const getRequestsTologinUser = async (userId) => {
  const res = await http.get('/corretor/demandas/usuarioId', {
    headers: {
      id: userId
    }
  });

  return res;
};

const getUrgentRequestsMovsByUserId = async (userId) => {
  const res = await http.get('/corretor/demandas-urgentes-corretor', {
    headers: {
      userid: userId,
      corretoraid: BROKERAGE_UUID
    }
  });

  return res;
};

const getBrokerUsers = async () => {
  const res = await http.get('/corretor/corretores-ativos');

  return res;
};

const getBrokerUsersList = async () => {
  const res = await http.get('/corretor/lista-corretores');

  return res;
};

const createEmailToNewUserByManager = async (token, data) => {
  const res = await http.post(
    `/corretor/cadastro-user-corretor/${token}`,
    data
  );

  return res;
};

const sendEmailToRegisterUser = async (data) => {
  const res = await http.post('/corretor/send-mail-create-corretor', data);

  return res;
};

const createUploadLogo = async (formData) => {
  const res = await http.post('/corretor/upload-logo', formData);

  return res;
};

const createEmailToReceiveTickets = async (data) => {
  const res = await http.post('/corretor/add-email', data, {
    headers: {
      corretora: BROKERAGE_UUID
    }
  });

  return res;
};

const getActualEmailtoReceiveTickets = async () => {
  const res = await http.get('/corretor/add-email', {
    headers: {
      corretora: BROKERAGE_UUID
    }
  });

  return res;
};

const modules = {
  login,
  createResetPassword,
  getRequestsCount,
  getRequestsCompaniesToEachUser,
  getRequestsTologinUser,
  getUrgentRequestsMovsByUserId,
  getBrokerUsers,
  getBrokerUsersList,
  createEmailToNewUserByManager,
  sendEmailToRegisterUser,
  createUploadLogo,
  createEmailToReceiveTickets,
  getActualEmailtoReceiveTickets
};

export default modules;
