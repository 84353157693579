import React from 'react';
import moment from 'moment';
import './styles.scss';

const StatusContract = ({ vigencia_final }) => {
  const getStatusText = () => {
    if (moment(vigencia_final) > moment(new Date())) {
      return '(Em Cancelamento)';
    } else if (vigencia_final) {
      return '(Inativo)';
    } else {
      return '(Ativo)';
    }
  };

  return (
    <span className="status-contract">
      {vigencia_final ? getStatusText() : '(Ativo)'}
    </span>
  );
};

export default StatusContract;
