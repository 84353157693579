import styled from "styled-components";
import colors from "styles/colors";

export const CardContainer = styled.tr`

  height: 40px;
  display: flex;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 1fr 3fr 2fr 4fr 2fr 3fr 3fr 2fr 1fr;
  column-gap: 10px;
  padding: 7px 0px;
  font-size: 0.72em;
  text-align: center;

  .checkbox {
    padding: 0;
    color: ${colors.genoaDarkBlue};

    &.Mui-checked {
      color: ${colors.genoaBlue};
    }

    svg {
      height: 15.9px;
      width: 16px;
    }
  }

  .column_status {
    margin-left: 5px;
    margin-right: 5px;
    padding: 5px 0px 6px 0px;
    border-radius: 7px;
    color: white;

    :hover {
      cursor: default;
    }
  }

  .aberto {
    background-color: ${colors.green};
  }

  .finalizado {
    background-color: ${colors.darkGray};
  }

  // td:nth-child(4), td:nth-child(5) { 
  //   text-align: left;
  // }

  .empresa_name {
    display: flex;
    text-align: start;
    padding-right: 20px;

    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    
    font-size: 0.90em;

    :hover {
      cursor: pointer;
    }
  } 

  .closed {
    background-image: url("data:image/svg+xml,%3Csvg width='6.11' height='5' viewBox='0 0 6.11 5' fill='none' xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath d='M3.055 5 0.409 0.415H5.7L3.055 5Z' fill='%233B3838'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position-x: calc(100% - 6px);
    background-position-y: 5px;
    color: inherit;
  }

  .open {
    background-image: url("data:image/svg+xml,%3Csvg width='6.11' height='5' viewBox='0 0 6.11 5' fill='none' xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath d='M3.055 5 0.409 0.415H5.7L3.055 5Z' fill='%23456AED'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position-x: calc(100% - 6px);
    background-position-y: 5px;
    color: ${colors.genoaBlue};
    font-family: 'Avenir Next GEO W05 Regular';
  }

  .more_info_icon {
    color: ${colors.lightGrey};
    padding: 2px;
    transition: box-shadow .5s 0s, color .25s 0s;
    box-shadow: inset 0 0 0 0 rgba(0, 0 , 0, 0);
    border-radius: 50%;

    :hover {
      color: ${colors.genoaGrey};
      cursor: pointer;
      box-shadow: inset 0 0 0 150px rgb(45 45 45 / 6%);
    }
    
    
      
    }
  }

`;

export const InfoWrapper = styled.div`
  font-size: 0.75em;
  text-align: center;
  font-family: "Avenir Next GEO W05 Medium";
`;

export const MoreInfoWrapper = styled.div`
  font-size: 0.75em;
  background-color: white;
  font-family: "Avenir Next GEO W05 regular";

  .more_info_menu__option {
    transition: background-color 0.25s;
    padding: 10px 18px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;

    :hover {
      cursor: pointer;
      background-color: ${colors.rhTabDarkGrey};
    }
  }
`;

export const SelectPopper = styled.div`
  font-size: 0.8em;
  background-color: white;
  font-family: "Avenir Next GEO W05 regular";
  min-height: 80px;

  .select_options_container {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    max-height: 300px;

    ::-webkit-scrollbar {
      width: 4px;
      background: rgba(0, 0, 0, 0);
    }
    ::-webkit-scrollbar-thumb {
      background: ${colors.lightGrey};
      box-shadow: none;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 2px rgb(0, 0, 0, 0);
    }
  }

  .select_popper_option {
    padding: 5px 5px;
    :hover {
      cursor: pointer;
      background-color: ${colors.rhTabDarkGrey};
    }
  }
`;
