import { useState } from 'react';
import { Box, ClickAwayListener, Paper } from '@material-ui/core';
import { Popper } from '@mui/material';
import { SelectComponent } from './styles';
import { usePostHog } from 'posthog-js/react';

const SelectStandard = ({
  value,
  setValue,
  label,
  name,
  options,
  dropDownHeight,
  selectPlaceholder,
  disabled,
  posthogSelectClickHandlerEvent,
  posthogOptionClickHandlerEvent
}) => {
  const posthog = usePostHog();
  const [focused, setFocused] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const optionClickHandler = (value, option) => {
    if (posthogOptionClickHandlerEvent) {
      posthog?.capture(posthogOptionClickHandlerEvent);
    }
    setValue({ name, value });
    setFocused(false);
  };

  const renderMaxDropdownheight = () => {
    if (dropDownHeight?.length) {
      return dropDownHeight;
    }
    return '200px';
  };

  const renderOptions = () => {
    return options?.map((item) => (
      <span
        key={item.value}
        onClick={() => optionClickHandler(item.value, item.option)}
      >
        {item.option}
      </span>
    ));
  };

  const renderOptionSelected = () => {
    const optionSelected = options?.find((item) => item.value === value);
    return optionSelected?.option;
  };

  const renderSelectPlaceholder = () => {
    if (selectPlaceholder?.length) {
      return selectPlaceholder;
    }
    return 'Selecione';
  };

  return (
    <ClickAwayListener onClickAway={() => setFocused(false)}>
      <SelectComponent>
        <label
          className={
            disabled ? 'disabled' : value || focused ? 'translate' : ''
          }
        >
          {label}
        </label>
        <div
          className={
            disabled
              ? `select__trigger ${name} disabled`
              : value || focused
              ? `select__trigger focused ${name}`
              : `select__trigger ${name}`
          }
          onClick={(e) => {
            if (!disabled) {
              if (posthogSelectClickHandlerEvent) {
                posthog?.capture(posthogSelectClickHandlerEvent);
              }
              setFocused(true);
              setAnchorEl(e.currentTarget.parentNode);
            }
          }}
          tabIndex="0"
        >
          <span className={disabled ? 'disabled' : ''}>
            {disabled
              ? ''
              : focused
              ? value
                ? renderOptionSelected()
                : renderSelectPlaceholder()
              : value
              ? renderOptionSelected()
              : ''}
          </span>
        </div>
        {!disabled && options?.length ? (
          <Popper
            open={focused}
            anchorEl={anchorEl}
            placement="bottom-start"
            disablePortal
            className="popper-container"
          >
            <Box>
              <Paper elevation={8}>
                <div
                  className="options__wrapper"
                  style={{
                    width: anchorEl?.offsetWidth,
                    maxHeight: renderMaxDropdownheight(),
                    overflowY: 'auto'
                  }}
                >
                  {renderOptions()}
                </div>
              </Paper>
            </Box>
          </Popper>
        ) : (
          ''
        )}
      </SelectComponent>
    </ClickAwayListener>
  );
};

export default SelectStandard;
