import styled from 'styled-components';
import colors from 'styles/colors';

export const TableAtividadesWrapper = styled.table`
  display: flex;
  flex-direction: column;
  padding-top: 35px;
  width: 100%;
  row-gap: 10px;

  tr {
    display: grid;
    grid-template-columns: 40px 2.5fr 80px 2.5fr 80px 80px 2fr 80px 70px 32px;
    column-gap: 20px;
    padding-left: 25px;
    padding-right: 25px;
  }
  
  thead {
    tr {
      text-align: left;
      font-size: 0.85em;
    }
    
  }

  tbody {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    tr {
      font-size: 0.8em;
      padding-top: 10px;
      padding-bottom: 10px;
      border: 3px solid ${colors.genoaBlue};
      border-radius: 42px;
      height: 38px;
      background-color: ${colors.white};
    }
    
    td {
      display: flex;
      align-items: center;
      line-height: 14px;


      .details_button {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        padding: 4px;
        font-size: 1.25rem;
        color: ${colors.genoaDarkBlue};
        transition: 0.1s ease-in;
        border-radius: 50%;

        :hover {
          background: rgb(204 204 204 / 25%);
        }
      }
    }

    .atividade {
      display: flex;
      align-items: center;
      column-gap: 8px;
      justify-content: center;
    }

    .select {
      appearance: none; 
      border: none;
      font-family: Avenir Next Geo W05 Regular;
      width: 102px;
      height: 20px;
      text-overflow: ellipsis;

    }


    .select__trigger {
      padding-bottom: 0px;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 9L0.73686 0.75L10.2631 0.75L5.5 9Z' fill='%23456AED'/%3E%3C/svg%3E%0A");
      background-position-x: 100%;
      background-position-y: 50%; 

      :focus {
        outline: none;
      }
    }

    .options {
      font-size: 0.95em;
      row-gap: 2px;
      border: none;

      :hover {
        background-color: ${colors.genoaBlue};
      }

      ::-webkit-scrollbar-thumb {
        background: ${colors.genoaBlue};
      }
    }

    .icon-alert {
      font-size: 1.5em;
    }

    .plus {
      color: ${colors.darkGray};
      font-size: 1em;

      :hover {
        cursor: pointer;
      }
    }

    .status-grey-circle-icon {
      // animation: blinking-grey-icon 1s infinite;
      color: #999999;
    }

    .status-green-circle-icon>circle {
      // animation: blinking-green-icon 1s infinite;
      color: #008631;
    }

    .status-yellow-circle-icon {
      // animation: blinking-yellow-icon 1s infinite;
      color: #FFF266;
    }

    .status-red-circle-icon {
      // animation: blinking-red-icon 1s infinite;
      color: #FF1919; 
    }

    .MuiCheckbox-root {
      padding: 7px;
      svg {
        font-size: 14px;
      }
    }

    .Mui-checked {
      color: ${colors.luminousGreen};
    }

    
  }
`
