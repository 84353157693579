export const typeColumns = [
  {
    header: 'Nome',
    key: 'nome',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'Email',
    key: 'email',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'Telefone',
    key: 'telefone_celular',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'Data de nascimento',
    key: 'data_nascimento',
    style: {
      numFmt: 'dd"/"mm"/"yyyy',
      alignment: { vertical: 'middle', horizontal: 'center' }
    },
    height: 25,
    width: 30
  },
  {
    header: 'RG',
    key: 'rg',
    style: {
      numFmt: '00"."000"."000"-"00',
      alignment: { vertical: 'middle', horizontal: 'center' }
    },
    height: 25,
    width: 30
  },
  {
    header: 'Orgão emissor do RG',
    key: 'orgao_emissor_rg',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'Data de expedição do RG',
    key: 'data_expedicao_rg',
    style: {
      numFmt: 'dd"/"mm"/"yyyy',
      alignment: { vertical: 'middle', horizontal: 'center' }
    },
    height: 25,
    width: 30
  },
  {
    header: 'Sexo',
    key: 'sexo',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'CPF',
    key: 'cpf',
    style: {
      numFmt: '000"."000"."000"-"00',
      alignment: { vertical: 'middle', horizontal: 'center' }
    },
    height: 25,
    width: 30
  },
  {
    header: 'Se dependente CPF do titular',
    key: 'cpf_titular',
    style: {
      numFmt: '000"."000"."000"-"00',
      alignment: { vertical: 'middle', horizontal: 'center' }
    },
    height: 25,
    width: 40
  },
  {
    header: 'Grau parentesco',
    key: 'grau_parentesco',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'Estado civil',
    key: 'estado_civil',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'Nacionalidade',
    key: 'nacionalidade',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'PIS/Pasep',
    key: 'pis_pasep',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'Nome da mãe',
    key: 'nome_mae',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'CEP',
    key: 'cep',
    style: {
      numFmt: '00000"-"000',
      alignment: { vertical: 'middle', horizontal: 'center' }
    },
    height: 25,
    width: 30
  },
  {
    header: 'Endereço',
    key: 'endereco',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'Número',
    key: 'numero_endereco',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'Complemento',
    key: 'complemento_endereco',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'Bairro',
    key: 'bairro',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'UF',
    key: 'uf',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'Cidade',
    key: 'cidade',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'Cargo',
    key: 'cargo',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'Data de admissão',
    key: 'data_admissao',
    style: {
      numFmt: 'dd"/"mm"/"yyyy',
      alignment: { vertical: 'middle', horizontal: 'center' }
    },
    height: 25,
    width: 30
  },
  {
    header: 'Matrícula',
    key: 'matricula_funcional',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'Agência',
    key: 'agencia',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'Conta',
    key: 'conta',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'Banco',
    key: 'banco',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'Altura(m)',
    key: 'altura',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'Peso(Kg)',
    key: 'peso',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  }
];

export const columnName = [
  'nome',
  'email',
  'telefone_celular',
  'data_nascimento',
  'rg',
  'orgao_emissor_rg',
  'data_expedicao_rg',
  'sexo',
  'cpf',
  'cpf_titular',
  'grau_parentesco',
  'estado_civil',
  'nacionalidade',
  'pis_pasep',
  'nome_mae',
  'cep',
  'endereco',
  'numero_endereco',
  'complemento_endereco',
  'bairro',
  'uf',
  'cidade',
  'cargo',
  'data_admissao',
  'matricula_funcional',
  'agencia',
  'conta',
  'banco',
  'altura',
  'peso'
];
