import React from 'react';
import { RelatorioConciergeAsideMenu } from './styles-AsideMenu';

const AsideMenuConcierge = ({
  relatorioSelecionado,
  setRelatorioSelecionado,
  servicesOpenedCount,
  servicesClosedCount,
  firstResponseTime,
  conclusionTime,
  usersCount,
  newUsersCount,
  netPromoterScore,
  formatTimeValue,
  estipulanteSelected,
  servicesOpenedCountEstipulante,
  servicesClosedCountEstipulante,
  firstResponseTimeEstipulante,
  conclusionTimeEstipulante,
  usersCountEstipulante,
  newUsersCountEstipulante,
  netPromoterScoreEstipulante
}) => {
  return (
    <RelatorioConciergeAsideMenu>
      <div
        className={`menu_aside__option ${
          relatorioSelecionado === 'atendimentos criados' ? 'selected' : ''
        }`}
        onClick={() => setRelatorioSelecionado('atendimentos criados')}
      >
        <div className="option__info">
          <div className="option__quantity">
            <span>
              {estipulanteSelected
                ? servicesOpenedCountEstipulante
                : servicesOpenedCount}
            </span>
          </div>
          <div className="option__unity">
            <span></span>
          </div>
        </div>
        <div className="option__title">Atendimentos Criados</div>
      </div>

      <div
        className={`menu_aside__option ${
          relatorioSelecionado === 'atendimentos resolvidos' ? 'selected' : ''
        }`}
        onClick={() => setRelatorioSelecionado('atendimentos resolvidos')}
      >
        <div className="option__info">
          <div className="option__quantity">
            <span>
              {estipulanteSelected
                ? servicesClosedCountEstipulante
                : servicesClosedCount}
            </span>
          </div>
          <div className="option__unity">
            <span></span>
          </div>
        </div>
        <div className="option__title">Atendimentos Resolvidos</div>
      </div>

      <div
        className={`menu_aside__option ${
          relatorioSelecionado === 'tempo de resposta' ? 'selected' : ''
        }`}
        onClick={() => setRelatorioSelecionado('tempo de resposta')}
      >
        <div className="option__info">
          {estipulanteSelected ? (
            <>
              {formatTimeValue(firstResponseTimeEstipulante)?.hours ? (
                <>
                  <div className="option__quantity">
                    <span>
                      {formatTimeValue(firstResponseTimeEstipulante).hours}
                    </span>
                  </div>
                  <div className="option__unity">
                    <span>h</span>
                  </div>
                </>
              ) : (
                ''
              )}
              {formatTimeValue(firstResponseTimeEstipulante)?.minutes ? (
                <>
                  <div className="option__quantity">
                    <span>
                      {formatTimeValue(firstResponseTimeEstipulante).minutes}
                    </span>
                  </div>
                  <div className="option__unity">
                    <span>min</span>
                  </div>
                </>
              ) : (
                <div className="option_null">
                  <span>--</span>
                </div>
              )}
            </>
          ) : (
            <>
              {formatTimeValue(firstResponseTime)?.hours ? (
                <>
                  <div className="option__quantity">
                    <span>{formatTimeValue(firstResponseTime).hours}</span>
                  </div>
                  <div className="option__unity">
                    <span>h</span>
                  </div>
                </>
              ) : (
                ''
              )}
              {formatTimeValue(firstResponseTime)?.minutes ? (
                <>
                  <div className="option__quantity">
                    <span>{formatTimeValue(firstResponseTime).minutes}</span>
                  </div>
                  <div className="option__unity">
                    <span>min</span>
                  </div>
                </>
              ) : (
                <div className="option_null">
                  <span>--</span>
                </div>
              )}
            </>
          )}
        </div>
        <div className="option__title">
          Média de tempo para primeira resposta
        </div>
      </div>

      <div
        className={`menu_aside__option ${
          relatorioSelecionado === 'tempo de resolucao' ? 'selected' : ''
        }`}
        onClick={() => setRelatorioSelecionado('tempo de resolucao')}
      >
        <div className="option__info">
          {estipulanteSelected ? (
            <>
              {formatTimeValue(conclusionTimeEstipulante)?.hours ? (
                <>
                  <div className="option__quantity">
                    <span>
                      {formatTimeValue(conclusionTimeEstipulante).hours}
                    </span>
                  </div>
                  <div className="option__unity">
                    <span>h</span>
                  </div>
                </>
              ) : (
                ''
              )}
              {formatTimeValue(conclusionTimeEstipulante)?.minutes ? (
                <>
                  <div className="option__quantity">
                    <span>
                      {formatTimeValue(conclusionTimeEstipulante).minutes}
                    </span>
                  </div>
                  <div className="option__unity">
                    <span>min</span>
                  </div>
                </>
              ) : (
                <div className="option_null">
                  <span>--</span>
                </div>
              )}
            </>
          ) : (
            <>
              {formatTimeValue(conclusionTime)?.hours ? (
                <>
                  <div className="option__quantity">
                    <span>{formatTimeValue(conclusionTime).hours}</span>
                  </div>
                  <div className="option__unity">
                    <span>h</span>
                  </div>
                </>
              ) : (
                ''
              )}
              {formatTimeValue(conclusionTime)?.minutes ? (
                <>
                  <div className="option__quantity">
                    <span>{formatTimeValue(conclusionTime).minutes}</span>
                  </div>
                  <div className="option__unity">
                    <span>min</span>
                  </div>
                </>
              ) : (
                <div className="option_null">
                  <span>
                    {formatTimeValue(conclusionTime)?.hours ? '' : '--'}
                  </span>
                </div>
              )}
            </>
          )}
        </div>
        <div className="option__title">Média de tempo de resolução</div>
      </div>

      <div
        className={`menu_aside__option ${
          relatorioSelecionado === 'usuarios' ? 'selected' : ''
        }`}
        onClick={() => setRelatorioSelecionado('usuarios')}
      >
        <div className="option__info">
          <div className="option__quantity">
            <span>
              {estipulanteSelected ? usersCountEstipulante : usersCount}
            </span>
          </div>
          <div className="option__unity">
            <span></span>
          </div>
        </div>
        <div className="option__title">Usuários</div>
      </div>

      <div
        className={`menu_aside__option ${
          relatorioSelecionado === 'novos usuarios' ? 'selected' : ''
        }`}
        onClick={() => setRelatorioSelecionado('novos usuarios')}
      >
        <div className="option__info">
          <div className="option__quantity">
            <span>
              {estipulanteSelected ? newUsersCountEstipulante : newUsersCount}
            </span>
          </div>
          <div className="option__unity">
            <span></span>
          </div>
        </div>
        <div className="option__title">Novos usuários</div>
      </div>
      <div
        className={`menu_aside__option ${
          relatorioSelecionado === 'nps' ? 'selected' : ''
        }`}
        onClick={() => setRelatorioSelecionado('nps')}
      >
        <div className="option__info">
          <div className="option__quantity">
            <span>
              {estipulanteSelected
                ? Number.isNaN(netPromoterScoreEstipulante)
                  ? '--'
                  : netPromoterScoreEstipulante
                : Number.isNaN(netPromoterScore)
                ? '--'
                : netPromoterScore}
            </span>
          </div>
          <div className="option__unity">
            <span>
              {estipulanteSelected
                ? Number.isNaN(netPromoterScoreEstipulante)
                  ? ''
                  : '%'
                : Number.isNaN(netPromoterScore)
                ? ''
                : '%'}
            </span>
          </div>
        </div>
        <div className="option__title">Net promotor score (NPS)</div>
      </div>
    </RelatorioConciergeAsideMenu>
  );
};

export default AsideMenuConcierge;
