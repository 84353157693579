import styled from 'styled-components';
import colors from 'apps/broker/styles/colors';

export const Wrapper = styled.div`
  width: 1065px;
  box-sizing: content-box;
  min-height: 101vh;

  .loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin: 25% auto;

    .circular-spinner {
      color: ${colors.genoaBlue};
    }

    p {
      margin-top: 5px;
      color: ${colors.genoaGrey};
      font-size: 1.2rem;
    }
  }
    
  header{
    background-color: ${colors.genoaBlue};
    padding: 20px;
    padding-left: 65px;
   
    h2{
      font-family: 'Avenir Next GEO W05 Bold';
      color:#fff;
      font-size: 35px;
      margin: 10px 0 20px 0;
    }
    span.date{
      color:#fff
    }
    .back{
      .icon-back{
        color: #fff;
        cursor: pointer;
        font-size: 2rem;
      }
    }

    .detalhes-second-row {
      display: flex;
      justify-content: space-between;
      color: white;
    }

    .status-change-handler-container {
      color: white;
      display: flex;
      margin-right: 10px;

      .mudar-status-title {
        margin-right: 10px;

        :hover {
          cursor: pointer;
        }
      }

      .arrow-icon {
        width: 13px;
        text-align: center;
        :hover {
          cursor: pointer;
          background-color: #1c2a3929;
        }
      }

      .width-transition {
        overflow: hidden;
        height: 22px;
        transition: width 0.4s ease-in-out;
        span {
          white-space: nowrap;
        }
      }
    }

    .status-change-options-container {
      span {
        margin-left: 10px;

        :hover {
          cursor: pointer;
          border-bottom: solid 1px white;
        }
      }
    }

  }
  section{
    padding: 20px;
    padding-left: 65px;
  }
  .linkFile{
    color: blue;
  }

  .dependentes {
    margin-top: 30px;
  }

  .details{
    margin-top: 10px;
  }

  .plano{
    font-family: 'Avenir Next GEO W05 Demi';
    margin-bottom: 5px;
  }

  .inputs{
    margin-top: 20px;
    max-width: 300px;
    .input-group{
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      label{
        font-family: 'Avenir Next GEO W05 Demi';
        color: #3B3838;
        font-size: 14px;
      }
      input{
        border: 1px solid ${colors.genoaBlue};
        padding: 10px 10px;
        border-radius: 10px;
        margin: 5px 0 10px 0;
        font-size: 1rem;
      
      }
    }

    .errorsMsg{
      color: #ff1b5a;
      font-size: 13px;
      margin-top: 10px;
    }
    .react-datepicker-wrapper {
      width: 100%;
      .input-data{
        border-radius: 10px;
        background: #FFFFFF;
        border: 0.5px solid ${colors.genoaBlue};
       // text-align: center;
        margin: 10px 0 10px 0;
        font-size: 1rem;
        padding: 10px 10px;
    }
  }
  .input-date-area{
    display: flex;
    flex-direction: column;    

  }

  }

  .btn-area{
    margin:1rem 0 2rem 0
  }

  button.send-btn{
    background: #eadeff;
    border: none;
    cursor: pointer;
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 1rem;
    color: ${colors.genoaBlue};
    padding: 4px 15px;
    border-radius: 6px;
  }

  .files{
    padding: 20px 0px;

    .file-item{
      a{
        text-decoration: none;
        color: ${colors.genoaBlue};
        display: flex;
        align-items: center;
        gap: 5px;

        span{
          color: inherit;
        }
        svg{
          color: inherit;
        }
      }
    }
  }

  .messageUserAlt {
    margin-top: 20px;
    .messageText{
      border-radius: 10px;
      box-sizing: border-box;
      padding: 20px;
      max-height: 230px;
      overflow: auto;
    }
    .open-msg{
      background: #F5F5F5;
    }
    .resp-msg{
      background: #DCEEFF;
    }
    .user-date {
      margin-bottom: 6px;

    }
  }

  button.open-btn{
    background: #eadeff;
    border: none;
    cursor: pointer;
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 1rem;
    color: ${colors.genoaBlue};
    padding: 4px 15px;
    border-radius: 6px;
    :hover{
      background: #eadeff;
    }
  } 

  .btn-movimentacao {
    margin-left: 20px;
  }

  button.btn-archived-mov {
    background: #eadeff;
    border: none;
    cursor: pointer;
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 1rem;
    color: ${colors.genoaBlue};
    padding: 4px 15px;
    border-radius: 6px;
    margin-left: 20px;
    :hover{
      background: #eadeff;
    }
  }
  
  button.btn-reopen-mov {
    background: #eadeff;
    border: none;
    cursor: pointer;
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 1rem;
    color: ${colors.genoaBlue};
    padding: 4px 15px;
    border-radius: 6px;
    margin-left: 20px;
    :hover{
      background: #eadeff;
    }
  }

  select{
    font-family: 'Avenir Next GEO W05 Regular';
    font-size: 1rem;
    padding: 10px 10px;
    border: 0.5px solid ${colors.genoaBlue};
    border-radius: 5px;
  }

  .options-container {
    height: 0;
    overflow: hidden;
    transition: height 0.8s ease-in-out;
  }
`
