import http from 'services/http';

const createConfirmation = async (id, token) => {
  const res = await http.post(`/confirma-corretor/${id}/${token}`);

  return res;
};

const modules = {
  createConfirmation
};

export default modules;
