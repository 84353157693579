import React from "react";
import HeaderRh from "apps/hr/components/HeaderRH";
import MenuRh from "apps/hr/components/MenuRh";
// import SidemenuRh from "apps/hr/components/Sidebar-RH";
// import MainRh from "apps/hr/components/MainContentRh";
import MainContainer from "apps/hr/components/MainContainer";
import { GridSection, Card } from "./styles";
import api from "services/api";
import { useSelector } from "react-redux";
// import { Link } from "react-router-dom";

import cardTickets from "assets/imgs/pages/home-rh/ticket-card.png";
import cardMovimentacao from "assets/imgs/pages/home-rh/card-movimentacao.png";
import FooterPainel from "apps/hr/components/FooterPainel";
const Home = () => {
  const [ticketsRH, setTicketsRH] = React.useState([]);
  const [ticketsMovimentacao, setTicketsMovimentacao] = React.useState([]);
  // const [loading, setLoading] = React.useState(false);

  const user = useSelector((state) => state.userReducers.user);

  function getData() {
    api
      .get("/rh/last-tickets", {
        headers: {
          id: user.estipulante,
          authorization: `Bearer ${user.token}`,
        },
      })
      .then((resp) => {
        setTicketsRH(resp.data.rh);
        setTicketsMovimentacao(resp.data.movimentacao);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  React.useEffect(() => {
    getData();
    // console.log(data)
  }, []);

  return (
    <>
      <HeaderRh />
      <MenuRh />
      <MainContainer>
        <GridSection>
          <Card>
            <div className="title">
              <h3>Tickets que estão aguardando você</h3>
            </div>
            <div className="content">
              <div className="list-tickets">
                <ul>
                  {ticketsRH?.length >= 1 ? (
                    ticketsRH.map((ticket, index) => (
                      <li key={index}>{ticket.assunto}</li>
                    ))
                  ) : (
                    <li>Não há tickets do tipo RH aberto no momento.</li>
                  )}
                </ul>
              </div>
              <div className="image">
                <img src={cardTickets} alt="tickets-rh" />
              </div>
            </div>
            <div className="footer">
              <a href="/rh/tickets"> Responder</a>
            </div>
          </Card>
          <Card>
            <div className="title">
              <h3>Movimentações em aberto</h3>
            </div>
            <div className="content">
              <div className="list-tickets">
                <ul>
                  {ticketsMovimentacao?.length >= 1 ? (
                    ticketsMovimentacao
                      .filter((item) => item.status_id !== 7)
                      .map((ticket, index) => (
                        <li key={index}>{ticket.assunto}</li>
                      ))
                  ) : (
                    <li>Não há movimentações em aberto no momento.</li>
                  )}
                </ul>
              </div>
              <div className="image">
                <img src={cardMovimentacao} alt="tickets-rh" />
              </div>
            </div>
            <div className="footer"></div>
          </Card>
        </GridSection>
      </MainContainer>
      <FooterPainel />
    </>
  );
};

export default Home;
