import styled from "styled-components";
import colors from "styles/colors";

export const DarkBackground = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;

  -webkit-transition: background-color 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -ms-transition: background-color 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  animation: fade-in 0.225s forwards;
  
  ::after {
    animation: fade-out 0.3s forwards;
  }

  @keyframes fade-in {
    from {
      background-color: ${colors.transparent};
    }
    to {
      background-color: rgba(0, 0, 0, 0.7)
    }
  }

  @keyframes fade-out {
    from {
      background-color: rgba(0, 0, 0, 0.7)
    }
    to {
      background-color: ${colors.transparent};
    }
  }

  .leave {
    animation: down-up 0.2s forwards;
  }

  @keyframes down-up {
    from {
      transform: calc(50% - 200px);
    }
    to {
      transform: translateY(-100%);
    }
  }
`

export const DrawerContent = styled.div`
  border-left: 10px solid ${colors.genoaBlue};
  border-radius: 10px;
  background-color: ${colors.white};
  height: 450px;
  width: 550px;
  position: fixed;
  left: calc(50% - 305px);
  padding: 25px;

  display: flex;
  flex-direction: column;
  row-gap: 30px;

  animation: up-down 0.2s forwards;


  @keyframes up-down {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: calc(50% - 200px);
    }
  }

  .popper_title {
    font-family: Avenir Next Geo W05 Heavy;
    font-size: 1.2em;
  }


  .form_container {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
  }

  .form_option {
    display: flex;
    column-gap: 30px;
    height: 28px;

    .custom_input__container {
      width: 80px;

      input {
        font-size: 0.9em;
        padding-bottom: 2px;
      }

      input.focused {
        border-color: ${colors.genoaBlue};
      }

    }

    .custom_input_nome_fantasia_container {
      width: 280px;

      input {
        font-size: 0.9em;
        padding-bottom: 2px;
      }

      input.focused {
        border-color: ${colors.genoaBlue};
      }
    }

    .custom_input__cnpj {
      width: 160px;

      input {
        font-size: 0.9em;
        padding-bottom: 2px;
      }

      input.focused {
        border-color: ${colors.genoaBlue};
      }
    }

    .input__select {

      select {
        font-family: 'Avenir Next GEO W05 Regular';
        font-size: 0.9em;
        appearance: none;
        border: none;
        border-bottom: 2px solid ${colors.genoaBlue};
        padding: 2px;
        padding-left: 8px;
        height: 25px;
        width: 300px;
        background-color: transparent !important;
    
        :focus {
          box-shadow: 0 0 0 0;
          border: 0 none;
          outline: 0;
          border-bottom: 2px solid ${colors.genoaBlue};
        }

        option {
          font-size: 0.85em;
          row-gap: 2px;
          border: none;
          text-transform: uppercase;
  
          ::-webkit-scrollbar-thumb {
            background: ${colors.genoaBlue};
            appearance: none;
          }

          ::-webkit-scrollbar-thumb:hover {
            background: ${colors.genoaBlue};
          }
        }
      }

      .select-arrow {
        background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 9L0.73686 0.75L10.2631 0.75L5.5 9Z' fill='%23456AED'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        
        background-position-x: 96%;
        background-position-y: center;
        background-size: 8px;
      }
    }

    .custom_input__select {
      transform: translateY(-22px);
      width: 300px;
      font-size: 0.85em;
      
      div.focused {
        border-color: ${colors.genoaBlue};
      }

      .select__trigger {
        appearance: none;
        background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 9L0.73686 0.75L10.2631 0.75L5.5 9Z' fill='%23456AED'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        
        background-position-x: 96%;
        background-position-y: center;
        background-size: 8px;
      }

      .options__wrapper {
        font-size: 0.9em;
        row-gap: 2px;
        border: none;
        text-transform: uppercase;

        span:hover {
          background-color: ${colors.genoaBlue};
        }

        ::-webkit-scrollbar-thumb {
          background: ${colors.genoaBlue};
        }
      }
    }

    .form_option__title {
      font-family: Avenir Next Geo W05 Demi;
    }

    .input-standard {
      font-size: 0.8em;
    }

    .filter_type__options {
      display: flex;
      font-family: "Avenir Next GEO W05 Regular";
      column-gap: 20px;

      .MuiRadio-root {
        padding: 0;
      }

      .radio_container {
        display: flex;
        column-gap: 5px;
        align-items: center;
      }

      .MuiSvgIcon-root {
        font-size: 0.8em;
      }
    }

    .checkbox_container {
      display: flex;
      column-gap: 5px;
    }

    .checkbox {
      padding: 0;
      color: ${colors.genoaDarkBlue};
      transform: translateY(-2px);
      
      &.Mui-checked {
        color: ${colors.genoaBlue};
      }
  
      svg {
        height: 15.9px;
        width: 16px;
      }
    }
  }

  .z_index_top {
    position: relative;
    z-index: 5;
  }

  .z_index_middle_first {
    position: relative;
    z-index: 4;
  }

  .z_index_middle {
    position: relative;
    z-index: 3;
  }

  .empresa_info_container {
    display: flex;
    height: 40px;
  }

  .button_container {
    position: absolute;
    top: 450px;
    z-index: -1;
  }

  .criar_negocio_btn {
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    background-color: ${colors.genoaBlue};
    color: white;
    padding: 4px 8px;
    font-family: Avenir Next Geo W05 Demi;
    text-transform: uppercase;
    font-size: 0.9em;
    border-radius: 6px;
    top: 0;
    right: 0;
  }

  .circular_spinner {
    color: ${colors.genoaBlue};
  }

  .cnpj_error {
    font-size: 0.9em;
    font-family: 'Avenir Next GEO W05 Demi';
    color: ${colors.genoaRed};
  }

  .message_success {
    font-size: 0.9em;
    font-family: 'Avenir Next GEO W05 Demi';
    color: ${colors.green};
    padding-left: 10px;
  }

  .disabled {
    background-color: ${colors.genoaDisabledGrey};
  }
`