import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 1400px;
  box-sizing: content-box;

`

export const Header = styled.div`
    background-color: #62A0E2;
    padding: 20px 30px;

    .back{
      .icon-back{
        color: #fff;
        cursor: pointer;
        font-size: 2rem;
      }
    }

    .nameUser {
      display: flex;
      justify-content: space-between;
      color:#fff;
      margin-top: 15px;
     
      svg{
        font-size: 2.5rem;
        margin-left: -5px;
      }
      .user-company{
        display: flex;
        align-items: baseline;
        span{
          color: #fff;
          margin-left: 6px;
          font-size: 18px;
          font-family: 'Avenir Next GEO W05 Bold';
        }
      }
      .user-contact > div{
        font-family: 'Avenir Next GEO W05 Demi';
        color:#fff;
        font-size: 20px;
        margin-bottom: 10px;
      }
    }
    .header-menu{
      margin-top: 40px;
      span{
        color: #fff;
        margin-right: 40px;
        text-decoration: underline;
        cursor: pointer;
      }
      .active{
        font-family: 'Avenir Next GEO W05 Demi';
        text-shadow: 1px 1px 1px rgb(2 2 2 / 46%);
      }
    }
    h2{
      font-family: 'Avenir Next GEO W05 Bold';
      color:#fff;
      font-size: 35px;
      margin: 0px 0 2px 0;
    }
    span.tipo{
      color:#fff;
    }
`

export const Body = styled.div `
  padding: 20px 30px;
  .checkbox {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .input-contato {
    margin: 20px 0px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    .contato {
      display: flex;
      flex-direction: column;
      input {
        margin-top: 10px;
        max-width: 200px;
        padding: 5px;
        border: none;
        border: 1px #62A0E2 solid;
        border-radius: 5px;
      }
      input:focus {
        border: 2px #62A0E2 solid;
      }
    }
  }

  button.btn {
    font-family: 'Avenir Next GEO W05 bold';
    padding: 7px 15px;
    background: #f3ecff;
    border: 1px #62A0E2 solid;
    border-radius: 5px;
    color: #62A0E2;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-top: 20px;
    :hover{
      background: #e3d2ff;
    }
    :disabled{
      opacity: 0.5;
    }
    span {color:inherit}
    .MuiCircularProgress-root{
      margin-left: 10px;
    }
  }

  button.btn-clear {
    background: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f76767;
    margin-right: 1rem;
    cursor: pointer;
  }

  .text-sobre {
    label {
      display: flex;
      flex-direction: column;
      textarea {
        resize: none;
        max-width: 400px;
        height: 200px;
        padding: 5px;
        border: none;
        border: 1px #62A0E2 solid;
        border-radius: 5px;
      }
      input:focus {
        border: 2px #62A0E2 solid;
      }
    }
  }
  
  .contatos{
      margin-top: 30px;

      div{
        display: flex;
        align-items: center;

        svg{margin-top:3px;
        margin-right: 3px;
        }
      }
    }

  .text-tip {
    margin-top: 20px;
    font-family: 'Avenir Next GEO W05 Heavy';
    color: #3B3838;
    font-size: 1.17em;
  }

  .text-resp {
    color: green;
    font-size: 1em;
    margin-top: 10px;
  }
`

export const Preview = styled.div`
  padding: 20px;
  margin-top: 20px;
  max-width: 500px;
  border: none;
  padding: 20px;
  border: 1px #62A0E2 solid;
  border-radius: 5px;
  .headers {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      max-width: 170px;
      max-height: 60px;
    }
    h2 {
      font-family: 'Avenir Next GEO W05 Heavy';
      color: #3B3838;
      font-size: 1.17em;
      padding: 30px 10px;
    }
  }
  .text {
    margin-top: 30px;
    ul.listSeguros{
      align-self: flex-start;
      margin-top: 30px;
      list-style: none;
      li{
        margin-top: 10px;
        display: flex;
        align-items: center;
        svg {
          margin-right: 5px;
          margin-top: 3px;
        }
      }
    }
  }
`