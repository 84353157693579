const validations = {
  required: (value) => {
    if (!value) {
      return 'Campo obrigatório';
    }
    return true;
  },

  // cpf: (input) => {
  //   const isRepeatingNumber = (str) => /^(\d)(\1){10}$/.test(str);

  //   const cpf = input.replace(/\D/g, '');

  //   if (
  //     cpf === '' ||
  //     cpf.length !== 11 ||
  //     !/^\d{11}$/.test(cpf) ||
  //     isRepeatingNumber(cpf)
  //   ) {
  //     return 'CPF Inválido';
  //   }

  //   const digits = cpf.split('').map((x) => parseInt(x));

  //   for (let j = 0; j < 2; j++) {
  //     let sum = 0;

  //     for (let i = 0; i < 9 + j; i++) {
  //       sum += digits[i] * (10 + j - i);
  //     }

  //     let checkDigit = 11 - (sum % 11);

  //     if (checkDigit === 10 || checkDigit === 11) {
  //       checkDigit = 0;
  //     }

  //     if (checkDigit !== digits[9 + j]) {
  //       return 'CPF Inválido';
  //     }
  //   }
  //   return true;
  // },

  name: (value) => {
    if (value && !/^[A-zÀ-ú]([-']?[A-zÀ-ú]+)*( [A-zÀ-ú]([-']?[A-zÀ-ú]+)*)+$/.test(value)) {
      return 'Informe seu nome completo sem abreviações.';
    }
    
    return true;
  },

  email: (value) => {
    if (
      value &&
      !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value)
    ) {
      return 'Email inválido';
    }
    return true;
  },

  phone: (value) => {
    const isEmpty = value.split('_').length === 12;

    if (isEmpty) {
      return 'Campo obrigatório';
    }

    if (value && !/^\(\d{2}\) \d{5}-\d{4}$/.test(value)) {
      return 'Telefone inválido';
    }
    return true;
  },

  date: (value) => {
    const isEmpty = value.split('_').length === 10;

    if (isEmpty) {
      return 'Campo obrigatório';
    }

    if (value && !/^\d{2}\/\d{2}\/\d{4}$/.test(value)) {
      return 'Data inválida';
    }

    const date = value.split('/');
    const day = date[0];
    const month = date[1];
    const year = date[2];

    if (day < 1 || day > 31) {
      return 'Dia inválido';
    }

    if (month < 1 || month > 12) {
      return 'Mês inválido';
    }

    if (year < 1900 || year > new Date().getFullYear()) {
      return 'Ano inválido';
    }

    return true;
  }
};

export default validations;
