import styled from "styled-components";
import colors from "styles/colors";

export const RelatorioMovimentacoesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 13px;

  .title_container {
    display: flex;
    // background-color: springgreen;
    column-gap: 15px;
    align-items: end;
    span {
      font-size: 0.90em;
    }

    .return_link {
      color: ${colors.genoaBlue};
      
      :hover {
        cursor: pointer;
      }
    }

    .slash_icon {
      color: ${colors.genoaGrey};
    }
  }

  .relatorio_concierge__wrapper {
    display: flex;
    flex-direction: column;
  }

  .relatorio_concierge__header {
    width: 100%;
    height: 50px;
    display: flex;
    border: 1px solid ${colors.rhTabDarkGrey};
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border-bottom: none;
    column-gap: 100px;

    .header_info_wrapper {
      display: flex;
      align-items: center;
      padding-left: 15px;
      column-gap: 20px;

      .info_filter__container {
        display: flex;
        column-gap: 7px;

        .info_filter__title {
          font-family: 'Avenir Next GEO W05 Bold';
          color: ${colors.genoaGrey};
          font-size: 0.73em;
        }

        .info_filter__period {
          font-family: 'Avenir Next GEO W05 Regular';
          color: ${colors.genoaGrey};
          font-size: 0.73em;
          display: flex;
          column-gap: 5px;
        }
      }

      .filter_icon_btn {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
      }

      .filter_icon {
        margin-top: -2px;

        :hover {
          cursor: pointer;
        }
      }
    }

    .header_tipo_movimentacao__container {
      display: flex;
      align-items: center;
      
      .button__container {
        display: flex;
        column-gap: 1px;
        
        button {
          /*Reset's every elements apperance*/
          all: unset;
          padding: 4px 0px;
          width: 80px;
          font-size: 0.85em;
          font-family: 'Avenir Next GEO W05 Regular';
          text-align: center;
        }

        button:first-child {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }

        button:last-child {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }

        .active {
          color: ${colors.white};
          background-color: ${colors.genoaBlue};
          
        }

        .disabled {
          color: ${colors.lightGrey};
          background-color: ${colors.rhTabDarkGrey};
        }
      }
    }

    .header_origem_movimentacao__container {
      display: flex;
      align-items: center;
      

      .button__container {
        display: flex;
        column-gap: 1px;
        
        button {
          /*Reset's every elements apperance*/
          all: unset;
          padding: 4px 0px;
          width: 80px;
          font-size: 0.85em;
          font-family: 'Avenir Next GEO W05 Regular';
          text-align: center;
        }

        button:first-child {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }

        button:last-child {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }

        .active {
          color: ${colors.white};
          background-color: ${colors.genoaBlue};
        }

        .disabled {
          color: ${colors.lightGrey};
          background-color: ${colors.rhTabDarkGrey};
        }
      }
    }
  }

  .relatorio_concierge__content {
    width: 100%;
    // min-height: 500px;
    // background-color: red;
    border: 1px solid ${colors.rhTabDarkGrey};
    border-top: 1px solid ${colors.rhTabLightGrey};
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    overflow: hidden;

    display: grid;
    grid-template-columns: 1fr 5fr;

    .relatorio_concierge__menu_aside {
      
      
    }
  }

  .popper_filter {
    position: fixed;
    top: 50px;
    background-color: red;
    z-index: 10000;
  }

  .circular-spinner-container {
    width: 100%;
    padding-top: 25vh;
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    justify-content: center;
    align-items: center;

    span {
      font-size: 1.1em;
    }
  }

  .circular-spinner {
    color: ${colors.genoaBlue};
  }

`
