import styled from 'styled-components'
import colors from 'apps/broker/styles/colors'

export const TableUsuario = styled.table`
  max-width: 100%;
  height: 188px;
  max-height: 100%;
  margin: 0;
  border-collapse: collapse;

  thead {
    text-align: left;
    width: 100%;
    position: sticky;
    top: 0;
    background: ${colors.genoaLightBackgroundGrey};

    .title_filter_usuario {
      display: flex;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;
    }

    th {
      font-family: 'Avenir Next GEO W05 Regular';
      color: ${colors.genoaBlue};
      font-size: 0.9em;
      padding-bottom: 5px;
    }
  }

  tbody {
    text-align: left;
    width: 100%;

    .title_tbody {
      font-size: 0.9em;
      padding: 5px;
    }

    td {
      font-family: 'Avenir Next Geo W05 Demi';
      font-size: 0.85em;
      padding: 5px;
    }
  }

  tr {
    display: grid;
    grid-template-columns: 140px 90px 90px 90px;
  }

  .ticket_corretor {
    cursor: pointer;

    :hover {
      font-family: 'Avenir Next GEO W05 Demi';
      color: ${colors.genoaBlue};
      cursor: pointer;
    }
  }
`
