import ReactDatePicker from 'react-datepicker';
import ReactInputMask from 'react-input-mask';
import { Div } from './styles';

const TitularForm = ({
  beneficiarioNome,
  setBeneficiarioNome,
  cpf,
  setCpf,
  beneficiaryBirthDate,
  setBeneficiaryBirthDate,
  dataVinculo,
  setDataVinculo,
  numeroDeCarteirinha,
  setNumeroDeCarteirinha,
  dataInicio,
  setDataInicio
}) => {
  return (
    <Div>
      <div className="form-line-row">
        <input
          className="input"
          value={beneficiarioNome}
          onChange={(e) => setBeneficiarioNome(e.target.value)}
          placeholder="* Nome completo do Beneficiário"
        />
      </div>

      <div className="form-line-row">
        <ReactInputMask
          className="input"
          mask="999.999.999-99"
          placeholder="* CPF do Beneficiário"
          value={cpf}
          onChange={(e) => setCpf(e.target.value)}
        />
      </div>

      <div className="datepicker-row">
        <div>
          <ReactDatePicker
            className="input-data"
            selected={beneficiaryBirthDate}
            locale="ptBR"
            onChange={(date) => setBeneficiaryBirthDate(date)}
            placeholderText="* Selecione a data de nascimento"
            dateFormat="dd/MM/yyyy"
          />
        </div>
        <div>
          <ReactDatePicker
            className="input-data"
            selected={dataVinculo}
            locale="ptBR"
            onChange={(date) => setDataVinculo(date)}
            placeholderText="* Selecione a data de vínculo"
            dateFormat="dd/MM/yyyy"
          />
        </div>
      </div>

      <div className="form-line-row">
        <input
          className="input"
          value={numeroDeCarteirinha}
          onChange={(e) => setNumeroDeCarteirinha(e.target.value)}
          placeholder="Número da carteirinha"
        />
      </div>

      <div className="datepicker-start-date-row-container">
        <div className="datepicker-start-date-row">
          <ReactDatePicker
            className="input-data"
            selected={dataInicio}
            locale="ptBR"
            onChange={(date) => setDataInicio(date)}
            placeholderText="Selecione a data de início do plano"
            dateFormat="dd/MM/yyyy"
          />
        </div>
      </div>
    </Div>
  );
};

export default TitularForm;
