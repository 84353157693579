import styled from 'styled-components';
import colors from 'styles/colors';

export const GraphBox = styled.div`
  height: 215px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .legend_text_x {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
  }

  .recharts-cartesian-axis-tick {
    ser-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
  }

  .graph_title {
    text-align: center;
    padding-left: 20px;
    font-size: 0.8em;
    font-family: 'Avenir Next GEO W05 Bold';
  }
`;

export const ConciergeTooltip = styled.div`
  border: none;
  outline: none;

  .tooltip-card {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    padding: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

    .tooltip-info-container {
      display: flex;
      column-gap: 10px;
      font-size: 11px;

      .tooltip-info-title {
        font-family: 'Avenir Next GEO W05 Bold';
        color: ${colors.genoaGrey};
      }

      .tooltip-info-value-important {
        font-family: 'Avenir Next GEO W05 Bold';
        color: ${colors.genoaBlue};
      }

      .tooltip-info-value {
        font-family: 'Avenir Next GEO W05 Regular';
        color: ${colors.genoaGrey};
      }
    }
  }
`;
