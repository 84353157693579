import React, { useState, useEffect, useMemo } from 'react';
import ConciergeDrawerMessage from '../ContactMessages';
import ConciergeTicketCard from '../TableRow';
import { CheckboxHeader, ListWrapper } from './styles';
import { BiEdit } from 'react-icons/bi';
import { Checkbox } from '@mui/material';
import { MdEditOff } from 'react-icons/md';
import services from 'apps/broker/services';

const ConciergeTicketsList = () => {
  const [openMessageDrawer, setOpenMessageDrawer] = useState(false);
  const [conciergeTicketSelected, setConciergeTicketSelected] =
    useState(undefined);
  const [conciergeTickets, setConciergeTickets] = useState([]);
  const [conciergeTicketsFilter, setConciergeTicketsFilter] = useState(
    []
  );
  const [estipulantes, setEstipulantes] = useState([]);
  const [isEditMode, setEditMode] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [dispatchInfo, setDispatchInfo] = useState({
    state: false,
    item: undefined
  });

  const editModeHandler = () => {
    if (!isEditMode) {
      setEditMode(true);
    } else {
      setEditMode(false);
      setIsChecked(false);
    }
  };

  const isChekedHandler = () => {
    setIsChecked((prevState) => !prevState);
  };

  const getConciergeTickets = async () => {
    const response = await services.concierge.getAll();
    setConciergeTickets(response.data);
  };

  const getAllCompanies = async () => {
    const response = await services.policyOwner.getAll();

    const estipulantes = response.data.filter((item) => item.razao_social);
    const estipulantesOrdenados = estipulantes.sort((a, b) =>
      a.razao_social.localeCompare(b.razao_social)
    );
    setEstipulantes(estipulantesOrdenados);
  };

  useEffect(() => {
    getConciergeTickets();
    getAllCompanies();
  }, []);

  useMemo(() => {
    const conciergeTicketsDefault = conciergeTickets;
    setConciergeTicketsFilter(conciergeTicketsDefault);
  }, [conciergeTickets, setConciergeTicketsFilter]);

  return (
    <ListWrapper>
      <thead>
        <tr>
          <th>
            <CheckboxHeader>
              {isEditMode === false ? (
                <BiEdit className="edit_box" onClick={editModeHandler} />
              ) : (
                <div className="div_checkbox">
                  <Checkbox
                    className="checkbox"
                    checked={isChecked}
                    onChange={isChekedHandler}
                  />
                  <MdEditOff
                    className="close_checkbox"
                    onClick={editModeHandler}
                  />
                </div>
              )}
            </CheckboxHeader>
          </th>
          <th>Status</th>
          <th>Protocolo</th>
          <th>Categoria</th>
          <th>Número</th>
          <th>Nome</th>
          <th>Empresa</th>
          <th>Atendente</th>
          <th>Data de Abertura</th>
          <th>Data de Finalização</th>
          <th>Avaliação</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {conciergeTicketsFilter.map((ticket) => {
          return (
            <ConciergeTicketCard
              key={ticket?.protocolo}
              id={ticket?.protocolo}
              ticketInfo={ticket}
              setOpenMessageDrawer={setOpenMessageDrawer}
              setConciergeTicketSelected={setConciergeTicketSelected}
              estipulantes={estipulantes}
              getConciergeTickets={getConciergeTickets}
              isEditMode={isEditMode}
              setIsChecked={setIsChecked}
              isChecked={isChecked}
              setDispatchInfo={setDispatchInfo}
              dispatchInfo={dispatchInfo}
            />
          );
        })}
      </tbody>
      {openMessageDrawer && (
        <ConciergeDrawerMessage
          ticketInfo={conciergeTicketSelected}
          setOpenMessageDrawer={setOpenMessageDrawer}
          setConciergeTicketSelected={setConciergeTicketSelected}
        />
      )}
    </ListWrapper>
  );
};

export default ConciergeTicketsList;
