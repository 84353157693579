import React from 'react';
import { ContentTab } from './styles';
import { FiEdit } from 'react-icons/fi';
import { AiFillCaretDown, AiOutlineCheck } from 'react-icons/ai';
import { BsCircleFill, BsFillFileEarmarkTextFill } from 'react-icons/bs';
import { FaBuilding } from 'react-icons/fa';
import { useForm, Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { BsChevronCompactUp } from 'react-icons/bs';
import DocumentosInner from './DocumentosInner';
import ReajusteInner from './ReajusteInner';
import PerguntasRespostasInner from './PerguntasRespostasInner';
import VidasInner from './VidasInner';
import ComissionamentoInner from './ComissionamentoInner';
import CancelarContratoInner from './CancelarContratoInner';
import CamposInclusaoContrato from './CamposInclusaoContrato';
import services from 'apps/broker/services';
import Subs from './Subs';
import TopBar from 'apps/broker/components/HorizontalBar/topBar';
import BottomBar from 'apps/broker/components/HorizontalBar/bottomBar';

const DadosGerais = ({
  title,
  data,
  setData,
  selectVidas,
  selectUpdate,
  getData
}) => {
  const [deleteMode, setDeleteMode] = React.useState({});
  const [contratoInnerTab, setContratoInnerTab] = React.useState({});
  const [submitDelete, setSubmitDelete] = React.useState({});


  function formatEstipulante(caracteristicas, dados) {
    return (
      <div className="dados-gerais-container">
        <div className="dados-gerais-contratos">
          <TopBar title={'Características contratuais'} />
          <div className="contrato-caracteristicas">
            <div>
              {dados.produto_id === 1 ? (
                <>
                  <div className="caracteristica-container">
                    <span className="caracteristica-title">Precificação:</span>
                    <span>{caracteristicas[0]?.caracteristica}</span>
                  </div>
                  <div className="caracteristica-container">
                    <span className="caracteristica-title">
                      Coparticipação:
                    </span>
                    <span>
                      {caracteristicas[1]
                        ? caracteristicas[1].coparticipacao_details
                        : 'Sem Coparticipação'}
                    </span>
                  </div>
                  <div className="caracteristica-container">
                    <span className="caracteristica-title">
                      Forma de custeio:
                    </span>
                    <span>
                      {!caracteristicas[0]
                        ? ' '
                        : caracteristicas[0].custeio_details}
                    </span>
                  </div>
                </>
              ) : (
                ''
              )}
              {dados.produto_id === 2 ? (
                <>
                  <div className="caracteristica-container">
                    <span className="caracteristica-title">
                      Forma de custeio:
                    </span>
                    <span>
                      {!caracteristicas[0]
                        ? ' '
                        : caracteristicas[0].custeio_details}
                    </span>
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
            <div>
              <div className="caracteristica-container">
                <span className="caracteristica-title">Multa:</span>
                <span>
                  {caracteristicas[0]
                    ? caracteristicas[0].multa_details ??
                      caracteristicas[0].multa_details
                    : ''}
                </span>
              </div>
              <div className="caracteristica-container">
                <span className="caracteristica-title">Mês de reajuste:</span>
                <span>{dados.mes_aniversario}</span>
              </div>
            </div>
            <div>
              <div className="caracteristica-datas-container">
                <span className="caracteristica-title">Dia de corte:</span>
                <span>{dados.dia_corte}</span>
              </div>
              <div className="caracteristica-datas-container">
                <span className="caracteristica-title">Dia da Fatura:</span>
                <span>{dados.vencimento_fatura}</span>
              </div>
            </div>
          </div>
        </div>
        <BottomBar />
      </div>
    );
  }


  function renderDadosGerais(contrato) {
    return (
      <div className="dados-container">
        <div className="teste-transition">
          <div
            className={
              deleteMode[contrato.idref_contrato]
                ? 'close-delete-mode'
                : 'container-cancelar-hidden'
            }
          >
            <BsChevronCompactUp
              onClick={() => {
                setDeleteMode((prevState) => ({
                  ...prevState,
                  [contrato.idref_contrato]: !prevState[contrato.idref_contrato]
                }));
                setSubmitDelete((prevState) => ({
                  ...prevState,
                  [contrato.idref_contrato]: false
                }));
              }}
            />
          </div>
          <hr
            className={
              deleteMode[contrato.idref_contrato]
                ? 'divider-delete'
                : 'container-cancelar-hidden'
            }
          />
        </div>
        {formatEstipulante(
          contrato.caracteristicas,
          contrato
        )}
      </div>
    );
  }

  function renderDocumentos(contrato) {
    return (
      <DocumentosInner
        contrato={contrato}
        title={'Sáude'}
        getDataDrawer={getData}
      />
    );
  }

  function renderReajustes(contrato) {
    // Lógica apenas para "enganar" o componente de que é um array
    const contratoArray = [contrato];
    return <ReajusteInner data={contratoArray} />;
  }

  function renderAdicionarVidas(contrato) {
    return <VidasInner idref={contrato.idref_contrato} />;
  }

  function renderComissionamento(contrato) {
    // Lógica apenas para "enganar" o componente de que é um array
    const contratoArray = [contrato];
    return (
      <ComissionamentoInner
        data={contratoArray}
        title={'Saúde'}
        getData={getData}
      />
    );
  }

  // function renderPerguntasRespostas(contrato) {
  //   return <PerguntasRespostasInner contrato={contrato} />;
  // }

  function renderSubs(contrato) {
    const { estipulantes } = contrato;
    return <Subs estipulantes={estipulantes} />
  }

  function renderCancelarContrato(contrato) {
    return <CancelarContratoInner contrato={contrato} getData={getData} />;
  }

  function renderCamposInclusao(contrato) {
    return (
      <CamposInclusaoContrato
        contrato={contrato}
        getData={getData}
        setData={setData}
      />
    );
  }

  const renderContratosSorted = () => {
    const dataSorted = data.sort((a, b) => {
      if (a.status === 'Ativo' && b.status === 'Ativo') {
        return -1;
      } else if (a.status === 'Inativo' && b.status === 'Inativo') {
        return -1;
      } else if (a.status === 'Ativo' && b.status === 'Inativo') {
        return -1;
      } else {
        return 1;
      }
    });
    // console.log('dataSorted', dataSorted);
    return dataSorted;
  };

  return (
    <ContentTab>
      {renderContratosSorted().map((contrato, documentos, index) => {
        return (
          <div className="sectionContent">
            <div className="header-abas-container">
              <span
                className={
                  contratoInnerTab[contrato.id]
                    ? contratoInnerTab[contrato.id] === 'dados gerais'
                      ? 'aba-title-active'
                      : 'aba-title'
                    : 'aba-title-active'
                }
                onClick={() => {
                  setDeleteMode((prevState) => ({
                    ...prevState,
                    [contrato.idref_contrato]: false
                  }));
                  setContratoInnerTab((prevState) => ({
                    ...prevState,
                    [contrato.id]: 'dados gerais'
                  }));
                }}
              >
                {/* {console.log(contratoInnerTab)} */}
                Cláusulas
              </span>
              <span
                className={
                  contratoInnerTab[contrato.id] === 'documentos'
                    ? 'aba-title-active'
                    : 'aba-title'
                }
                onClick={() =>
                  setContratoInnerTab((prevState) => ({
                    ...prevState,
                    [contrato.id]: 'documentos'
                  }))
                }
              >
                Documentos
              </span>
              <span
                className={
                  contratoInnerTab[contrato.id] === 'reajuste'
                    ? 'aba-title-active'
                    : 'aba-title'
                }
                onClick={() =>
                  setContratoInnerTab((prevState) => ({
                    ...prevState,
                    [contrato.id]: 'reajuste'
                  }))
                }
              >
                Reajuste
              </span>
              <span
                className={
                  contratoInnerTab[contrato.id] === 'adicionar vidas'
                    ? 'aba-title-active'
                    : 'aba-title'
                }
                onClick={() =>
                  setContratoInnerTab((prevState) => ({
                    ...prevState,
                    [contrato.id]: 'adicionar vidas'
                  }))
                }
              >
                Adicionar Vidas
              </span>
              <span
                className={
                  contratoInnerTab[contrato.id] === 'subs'
                    ? 'aba-title-active'
                    : 'aba-title'
                }
                onClick={() =>
                  setContratoInnerTab((prevState) => ({
                    ...prevState,
                    [contrato.id]: 'subs'
                  }))
                }
              >
                Subs
              </span>
              <span
                className={
                  contratoInnerTab[contrato.id] === 'comissionamento'
                    ? 'aba-title-active'
                    : 'aba-title'
                }
                onClick={() =>
                  setContratoInnerTab((prevState) => ({
                    ...prevState,
                    [contrato.id]: 'comissionamento'
                  }))
                }
              >
                Comissionamento
              </span>
              <span
                className={
                  contratoInnerTab[contrato.id] === 'cancelar contrato'
                    ? 'aba-title-active'
                    : 'aba-title'
                }
                onClick={() =>
                  setContratoInnerTab((prevState) => ({
                    ...prevState,
                    [contrato.id]: 'cancelar contrato'
                  }))
                }
              >
                Cancelar contrato
              </span>
              <span
                className={
                  contratoInnerTab[contrato.id] === 'campos de inclusão'
                    ? 'aba-title-active'
                    : 'aba-title'
                }
                onClick={() =>
                  setContratoInnerTab((prevState) => ({
                    ...prevState,
                    [contrato.id]: 'campos de inclusão'
                  }))
                }
              >
                Campos de Inclusão
              </span>
            </div>
            <div className="contrato-content">
              {contratoInnerTab[contrato.id]
                ? contratoInnerTab[contrato.id] === 'dados gerais'
                  ? renderDadosGerais(contrato)
                  : ''
                : renderDadosGerais(contrato)}
              {contratoInnerTab[contrato.id] === 'documentos'
                ? renderDocumentos(contrato)
                : ''}
              {contratoInnerTab[contrato.id] === 'reajuste'
                ? renderReajustes(contrato)
                : ''}
              {contratoInnerTab[contrato.id] === 'adicionar vidas'
                ? renderAdicionarVidas(contrato)
                : ''}
              {contratoInnerTab[contrato.id] === 'comissionamento'
                ? renderComissionamento(contrato)
                : ''}
              {contratoInnerTab[contrato.id] === 'subs'
                ? renderSubs(contrato)
                : ''}
              {contratoInnerTab[contrato.id] === 'cancelar contrato'
                ? renderCancelarContrato(contrato)
                : ''}
              {contratoInnerTab[contrato.id] === 'campos de inclusão'
                ? renderCamposInclusao(contrato)
                : ''}
            </div>
            {/* <hr className="divider" /> */}
          </div>
        );
      })}
    </ContentTab>
  );
};

export default DadosGerais;
