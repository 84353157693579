import { useState } from 'react';
import { BiEdit, BiX } from 'react-icons/bi';
import { BsCaretDownFill, BsCaretUpFill } from 'react-icons/bs';
import { SeguradoraCard, SeguradorasListContainer } from './styles';

const CardContactInformation = ({
  seguradorasData,
  deleteContatoSeguradora,
  toggleDrawerUpdateContatoSeguradora
}) => {
  const [showSeguradorasData, setShowSeguradorasData] = useState({});

  const showSeguradoraClickHandler = (seguradora) => {
    setShowSeguradorasData((prevState) => ({
      ...prevState,
      [seguradora.id]: !prevState[seguradora.id]
    }));
  };

  return (
    <SeguradorasListContainer>
      {seguradorasData.map((seguradora) => {
        if (seguradora.contatos.length) {
          return (
            <SeguradoraCard key={seguradora.id}>
              <div
                className={
                  showSeguradorasData[seguradora.id]
                    ? 'card-content'
                    : 'card-content-closed'
                }
                style={{
                  height: showSeguradorasData[seguradora.id]
                    ? `${75 + seguradora.contatos.length * 34}px`
                    : '20px',
                  transition: `height ${
                    seguradora.contatos.length * 0.034 + 0.2
                  }s ease-in`
                }}
              >
                <div className="first-row">
                  <span className="seguradora-name">
                    {seguradora.nome_completo}
                  </span>
                  {showSeguradorasData[seguradora.id] ? (
                    <BsCaretUpFill
                      size={20}
                      className="edit-icon-open"
                      onClick={() => showSeguradoraClickHandler(seguradora)}
                    />
                  ) : (
                    <BsCaretDownFill
                      size={20}
                      className="edit-icon-open"
                      onClick={() => showSeguradoraClickHandler(seguradora)}
                    />
                  )}
                </div>
                <div className="second-row">
                  <table className="table">
                    <thead>
                      <tr className="table-header-row">
                        <th className="first-column-title">
                          <span>Nome</span>
                        </th>
                        <th className="second-column-title">
                          <span>Telefone</span>
                        </th>
                        <th className="third-column-title">
                          <span>Email</span>
                        </th>
                        <th className="fourth-column-title">
                          <span>Função</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {seguradora.contatos.map((contato) => (
                        <tr className="table-body-row" key={contato.id}>
                          <td>{contato.nome}</td>
                          <td>{contato.telefone}</td>
                          <td>{contato.email}</td>
                          <td>{contato.funcao}</td>
                          <td>
                            <div className="icons-container">
                              <div className="delete-icon">
                                <BiX
                                  size={18}
                                  onClick={() =>
                                    deleteContatoSeguradora(contato)
                                  }
                                />
                              </div>
                              <div className="edit-icon">
                                <BiEdit
                                  size={18}
                                  onClick={() =>
                                    toggleDrawerUpdateContatoSeguradora(contato)
                                  }
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </SeguradoraCard>
          );
        }
        return '';
      })}
    </SeguradorasListContainer>
  );
};

export default CardContactInformation;
