import React, { useState } from 'react';
import { Wraper } from './styles';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import api from 'services/api';
import { useParams } from 'react-router-dom';
import { serialize } from 'object-to-formdata';
import Loading from 'components/Loading';
import { BiUpload, BiFolderOpen } from "react-icons/bi";
import { useEmitTicket } from "contexts/emitTicket";

const EscolhaPlano = ({ formExt, planosBloqueados, fileDependents }) => {
  const history = useHistory();

  const {
    externalForm,
    updateExternalForm,
    resetExternalForm,
    updateExternalFormStage,
    resetExternalFormStage,
    setEmitTicket
  } = useEmitTicket();

  const formData = externalForm;
  const { register, handleSubmit, formState: { errors }, watch } = useForm();

  const [erroCampos, setErrorCampos] = useState(false);
  const [planos, setPlanos] = useState([]);
  const [details, setDetails] = useState({});
  const [fileNotExists, setFileNotExists] = useState(false);

  const { corretoraId, estipulanteId, contratoId } = useParams();
  const [loading, setLoading] = useState(false);
  const [errorsApi, setErrorsApi] = useState(false);

  const options = {
    indices: true,
    nullsAsUndefineds: false,
    booleansAsIntegers: false,
    allowEmptyArrays: false,
  };

  const sendData = (data) => {
    if (formData.vinculo.tipoContrato === 'Prestador de Serviço'
      && !formData.vinculo.contrato_prest_servico[0].name
      && !formData.vinculo.contrato_social[0].name) {
      setFileNotExists(true);
      return;
    }

    if (formData.vinculo.tipoContrato === 'Sócio'
      && !formData.vinculo.contrato_social[0].name) {
      setFileNotExists(true);
      return;
    }

    if (formData.vinculo.tipoContrato === 'Estagiário'
      && !formData.vinculo.termo_contrat_estag[0].name) {
      setFileNotExists(true);
      return;
    }

    if (fileDependents.length) {
      setFileNotExists(true);
      return;
    }

    setLoading(true)
    if (!data.plano && planosBloqueados === false) {
      setErrorCampos(true)
      return
    }

    const body = serialize(
      { ...fileDependents, ...data, ...formData },
      options,
    );

    api.post(`/beneficiario/ticket/${corretoraId}/${estipulanteId}/${contratoId}`,
      body,
    ).then(resp => {
      if (data.formExt && data.formExt[0]) {

        const formData = new FormData()
        formData.append('file', data.formExt[0])

        const headersFormExt = {
          ticket: resp.data,
        }

        api.post('/ticket/formext', formData, { headers: headersFormExt })
      }

      sessionStorage.setItem('ticket', resp.data);

      setEmitTicket({ ticket: resp.data });
      updateExternalForm({ planos: data });
      resetExternalForm();
      resetExternalFormStage();

      history.push(`/questionario-inclusao-ticket/${corretoraId}/${estipulanteId}/${contratoId}`)
    }).catch(err => {
      console.log(err)
      setErrorsApi(err.response.data)
    }).finally(() => {
      setLoading(false)
    })
  }

  const getData = () => {
    const estipulante_id = formData.vinculo.vinculo
    const headers = {
      estipulante: estipulante_id,
      corretora: corretoraId,
      contrato: contratoId,
    }
    api.get('/ticket/values', { headers: headers })
      .then(resp => {
        setDetails(resp.data.contrato)
        setPlanos(resp.data.planos)
      }).catch(err => {
        console.log(err)
      })
  }


  React.useEffect(() => {
    getData()
  }, [])

  React.useEffect(() => {
    if (planosBloqueados === true && !formExt) {
      const data = {
        plano: ""
      }
      sendData(data)

    }
  }, [planosBloqueados])

  const typeProduct = {
    'Saúde': (planos) => {
      return (
        <div className="select-group">
          {errors.plano && <div className="erroPlanos">
            <span className="erroPlanos">Campo obrigatório</span>
          </div>}
          <label >Qual plano  de saúde você deseja adquirir?</label>
          <select name="plano" {...register("plano", { required: true })}>
            <option value="">Selecione</option>
            {
              planos.map((plano) => {
                return (
                  <option key={plano.id} value={plano.id}>{plano.tipo}</option>
                )
              })
            }
          </select>
        </div>
      )
    },
    'Odonto': (planos) => {
      return (
        <div className="select-group">
          {errors.plano && <div className="erroPlanos">
            <span className="erroPlanos">Campo obrigatório</span>
          </div>}
          <label>Qual plano odontológico você deseja adquirir?</label>
          <select name="plano" {...register("plano", { required: true })}>
            <option value="">Selecione</option>
            {
              planos.map((plano, index) => {
                return (
                  <option value={plano.id}>{plano.tipo}</option>
                )
              })

            }
          </select>
        </div>
      )
    },
    'undefined': () => {
      return
    }
  }

  const changeStage = (stage) => {
    if (formData.aderente === 'solicitante') {
      updateExternalFormStage('VinculoEmpresa');
    } else {
      updateExternalFormStage(stage);
    }
  }

  return (
    <Wraper>
      <Loading open={loading} setOpen={() => setLoading(false)} />
      <fieldset>
        {planosBloqueados ? null : <legend>Escolha o seu plano</legend>}
        {planosBloqueados ? null : typeProduct[details?.search](planos)}
      </fieldset>

      {erroCampos && <div className="erroPlanos">
        <span className="erroPlanos">Escolha pelo menos um plano</span>
      </div>}

      {errorsApi && <div className="erroPlanos">
        <span className="erroPlanos">{errorsApi}</span>
      </div>}

      <div className="text-desc" style={{ paddingTop: '1px' }}>
        <p> Caso haja, os seus dependentes irão aderir ao mesmo plano que você.</p>
      </div>

      {/* <div className="select-group">
            <label >Qual plano  de saúde você deseja adquirir?</label>
            <select name="planoSaude" onChange={(e) => {
              planoSaude.onChange(e);
              console.log(e)
              setErrorCampos(false)
            }}
            >
              <option value="">Selecione</option>
              {
                saude.map((plano, index) => {
                  return (
                    <option value={plano.id}>{plano.tipo}</option>
                  )
                })
              }
              <option value={false}>Não quero plano de saúde</option>
            </select>
          </div> */}

      {/* <div className="select-group">
            <label>Qual plano odontológico você deseja adquirir?</label>
            <select name="planoOdonto" onChange={(e) => {
              planoOdonto.onChange(e);
              setErrorCampos(false)
            }}>
              <option value="">Selecione</option>
              {
                odonto.map((plano, index) => {
                  return (
                    <option value={plano.id}>{plano.tipo} - {plano.preco}</option>
                  )
                })
                
              }
              <option value={false}>Não quero plano dental</option>
            </select>
          </div> */}

      <div className="input-files-group " style={{ marginBottom: '25px' }}>
        <legend>Documento de Identidade
        </legend>
        {errors.documentoIdentidade && <div className="erroPlanos">
          <span className="erroPlanos">Campo obrigatório</span>
        </div>}
        <label className="label-file">
          {watch('documentoIdentidade')?.[0]?.name ?
            <span><BiFolderOpen /> {watch('documentoIdentidade')[0]?.name} </span> :
            <span>Selecione um arquivo <BiUpload /></span>
          }
          <input
            accept="image/jpeg,
          image/jpg,
          image/png,
          image/pjpeg,
          application/pdf,
          application/msword,
          application/vnd.openxmlformats-officedocument.wordprocessingml.document,
          text/plain,
          application/vnd.ms-excel"
            className="input-file"
            {...register('documentoIdentidade', { required: true })}
            type="file" />
        </label>
      </div>

      {
        formExt
          ?
          <div className="input-files-group formExt">
            <legend>Faça o upload do formulário baixado anteriormente
            </legend>
            {errors.formExt && <div className="erroPlanos">
              <span className="erroPlanos">Campo obrigatório</span>
            </div>}
            <label className="label-file">
              {watch('formExt')?.[0]?.name ?
                <span><BiFolderOpen /> {watch('formExt')[0]?.name} </span> :
                <span>Formulário externo <BiUpload /></span>
              }
              <input
                accept="image/jpeg,
                image/jpg,
                image/png,
                image/pjpeg,
                application/pdf,
                application/msword,
                application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                text/plain,
                application/vnd.ms-excel"
                className="input-file"
                {...register('formExt', { required: true })}
                type="file" />
            </label>
          </div>
          :
          null
      }
      <div className="button-areas">
        <button type="button" onClick={() => changeStage('Dependentes')}  >Voltar</button>
        <button type="button" onClick={handleSubmit(sendData)}>Finalizar</button>
      </div>
      {fileNotExists && <span className="erroPlanos">Atenção. Esta faltando arquivo(s)!</span>}
    </Wraper>
  )
}

export default EscolhaPlano
