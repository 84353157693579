import React, { useState } from 'react';
import { usePostHog } from 'posthog-js/react';
import { Link, NavLink } from 'react-router-dom';
import { Aside } from './styles';
import { BiLogOut, BiHome, BiFile, BiUser, BiCalendar } from 'react-icons/bi';
import coinsure_logo_icon from 'assets/imgs/logo-coinsure-icon.svg';
import { AiOutlineUsergroupAdd } from 'react-icons/ai';
import { BsCardChecklist } from 'react-icons/bs';
import { RiLockPasswordLine, RiUserSettingsLine } from 'react-icons/ri';
import { useUser } from 'contexts/user';

const Navigation = () => {
  const posthog = usePostHog();
  const { logOut } = useUser();
  const [open, setOpen] = useState();

  function selectedType(idType) {
    setOpen(idType);
  }

  return (
    <>
      <Aside>
        <div className="navFixed">
          <nav className="menu animeLeft">
            <ul>
              <li className="li-logo">
                <Link to="/rh/home">
                  <img src={coinsure_logo_icon} alt="Logo_Coinsure"></img>
                </Link>
              </li>
              <li>
                <NavLink to="/rh/home" activeClassName="selected">
                  <BiHome
                    onMouseEnter={() => selectedType(1)}
                    onMouseLeave={() => {
                      selectedType(0);
                    }}
                    onClick={() =>
                      posthog?.capture('Clicou em "Home" (Menu Lateral)')
                    }
                  />
                  <div className={`description ${open !== 1 ? 'off' : ''}`}>
                    Home
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink to="/rh/gestao-tickets" activeClassName="selected">
                  <BiCalendar
                    onMouseEnter={() => selectedType(6)}
                    onMouseLeave={() => {
                      selectedType(0);
                    }}
                    onClick={() =>
                      posthog?.capture('Clicou em "Tickets" (Menu Lateral)')
                    }
                  />
                  <div className={`description ${open !== 6 ? 'off' : ''}`}>
                    Gestão de Tickets
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/rh/movimentacao-beneficiarios"
                  activeClassName="selected"
                >
                  <AiOutlineUsergroupAdd
                    onMouseEnter={() => selectedType(5)}
                    onMouseLeave={() => {
                      selectedType(0);
                    }}
                    onClick={() =>
                      posthog?.capture(
                        'Clicou em "Movimentações" (Menu Lateral)'
                      )
                    }
                  />
                  <div className={`description ${open !== 5 ? 'off' : ''}`}>
                    Movimentações
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink to="/rh/beneficiarios" activeClassName="selected">
                  <BiUser
                    onMouseEnter={() => selectedType(4)}
                    onMouseLeave={() => {
                      selectedType(0);
                    }}
                    onClick={() =>
                      posthog?.capture(
                        'Clicou em "Beneficiários" (Menu Lateral)'
                      )
                    }
                  />
                  <div className={`description ${open !== 4 ? 'off' : ''}`}>
                    Beneficiários
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink to="/rh/contratos" activeClassName="selected">
                  <BiFile
                    onMouseEnter={() => selectedType(3)}
                    onMouseLeave={() => {
                      selectedType(0);
                    }}
                    onClick={() =>
                      posthog?.capture('Clicou em "Contratos" (Menu Lateral)')
                    }
                  />
                  <div className={`description ${open !== 3 ? 'off' : ''}`}>
                    Contratos
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/rh/gestao-solicitacao-inclusao"
                  activeClassName="selected"
                >
                  <BsCardChecklist
                    onMouseEnter={() => selectedType(10)}
                    onMouseLeave={() => {
                      selectedType(0);
                    }}
                    onClick={() =>
                      posthog?.capture('Clicou em "Contratos" (Menu Lateral)')
                    }
                  />
                  <div className={`description ${open !== 10 ? 'off' : ''}`}>
                    Gestão de link inclusão
                  </div>
                </NavLink>
              </li>
              {/* <li>
                <NavLink to="/rh/estipulante" activeClassName="selected">
                  <BiBuildings onMouseEnter={() => selectedType(2)} onMouseLeave={() => { selectedType(0) }} />
                  <div className={`description ${open !== 2 ? 'off' : ''}`}>
                    Estipulante
                  </div>
                </NavLink>
              </li> */}

              <li>
                <NavLink to="/rh/usuarios" activeClassName="selected">
                  <RiUserSettingsLine
                    onMouseEnter={() => selectedType(7)}
                    onMouseLeave={() => {
                      selectedType(0);
                    }}
                    onClick={() =>
                      posthog?.capture('Clicou em "Usuários" (Menu Lateral)')
                    }
                  />
                  <div className={`description ${open !== 7 ? 'off' : ''}`}>
                    Gestão de Usuários
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink to="/rh/trocar-senha" activeClassName="selected">
                  <RiLockPasswordLine
                    onMouseEnter={() => selectedType(8)}
                    onMouseLeave={() => {
                      selectedType(0);
                    }}
                    onClick={() =>
                      posthog?.capture(
                        'Clicou em "Trocar Senha" (Menu Lateral)'
                      )
                    }
                  />
                  <div className={`description ${open !== 8 ? 'off' : ''}`}>
                    Trocar Senha
                  </div>
                </NavLink>
              </li>
              <li>
                <Link to="/rh/login" onClick={logOut}>
                  <BiLogOut
                    onMouseEnter={() => selectedType(9)}
                    onMouseLeave={() => {
                      selectedType(0);
                    }}
                    onClick={() =>
                      posthog?.capture('Clicou em "Sair" (Menu Lateral)')
                    }
                  />
                  <div className={`description ${open !== 9 ? 'off' : ''}`}>
                    Sair
                  </div>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </Aside>
    </>
  );
};

export default Navigation;
