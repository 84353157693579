import { useState } from 'react';
import { useForm } from 'react-hook-form';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from 'components/DS/Button';
import RegistrationData from '../PolicyOwnerDetails/components/TabsHeader/RegistrationData';
import AddSubestipulante from '../PolicyOwnerDetails/components/Atualizacao-Estipulantes/AddSubestipulante-atualizacao';
import services from 'apps/broker/services';

import './styles.scss';

const RegistrationInformation = ({ brokerInformation, onFetchPolicyOwners }) => {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [selectedView, setSelectedView] = useState('cadastro');
  const [openModal, setOpenModal] = useState(false);
  const [loadGestor, setLoadGestor] = useState(false);

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState,
    reset,
    formState: { errors }
  } = useForm();

  return (
    <div className="registration-information">
      <Button
        variant="transparent"
        onClick={() => setIsOpenDrawer(!isOpenDrawer)}
      >
        Dados Cadastrais
      </Button>

      <SwipeableDrawer
        anchor="right"
        open={isOpenDrawer}
        onClose={() => setIsOpenDrawer(!isOpenDrawer)}
        onOpen={() => setIsOpenDrawer(!isOpenDrawer)}
      >
        <div className="registration-information__drawer">
          {selectedView === 'cadastro' && (
            <RegistrationData
              details={brokerInformation}
              handleSubmit={handleSubmit}
              control={control}
              register={register}
              setValue={setValue}
              emailsEmpresa={brokerInformation.emailsEmpresa}
              openModal={openModal}
              setOpenModal={setOpenModal}
              estipulante={brokerInformation.id_estipulante}
              setLoadGestor={setLoadGestor}
              getData={onFetchPolicyOwners}
              errors={errors}
            />
          )}
        </div>
      </SwipeableDrawer>
    </div>
  );
};

export default RegistrationInformation;
