import React from 'react';
import Menu from './HeaderMenuCorretor';
import { Container } from './styles';
import { useUser } from 'contexts/user';

const HeaderCorretor = () => {
  const { user } = useUser();

  const getInitials = (name) => {
    if (name) {
      const fullName = name.split(' ');
      return fullName[0];
    }
  };

  return (
    <Container>
      {user.nome && (
        <div className="welcome-name-title-container">
          <span className="welcome-name-title">
            <span className="heavy-title">{`${getInitials(user.nome)}`}</span>,
            bem vinda ao
            <span className="heavy-title"> Zeca</span>
          </span>
        </div>
      )}
      <div></div>
      <Menu user={user}>
        <div className="iniciais-empresa">
          {user.nome && <span>{`Olá, Genoa`}</span>}
        </div>
      </Menu>
    </Container>
  );
};

export default HeaderCorretor;
