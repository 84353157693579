import { useEffect, useState } from 'react';
import { Wrapper } from './styles-draweBeneficiarios';
import { BiMale } from 'react-icons/bi';
import { idade } from 'utils/calculateFunctions';
import moment from 'moment-timezone';
import { AiOutlineCreditCard, AiOutlineMinus } from 'react-icons/ai';
import { GiHealthNormal } from 'react-icons/gi';
import { FaTooth } from 'react-icons/fa';
import { BsFillCalendarEventFill } from 'react-icons/bs';
import services from 'apps/hr/services';

const DrawerBeneficiariosContent = (props) => {
  const data = props.data;

  const [familyGroup, setFamilyGroup] = useState({});

  // console.log(data);

  const seguros = {
    1: <GiHealthNormal size={16} />,
    2: <FaTooth size={16} />,
    3: <BiMale size={16} />
  };

  const renderHealthPlanInfo = (data) => {
    const contratosSaude = data?.contratos?.filter(
      (item) => item.produto_id === 1
    );

    return (
      <>
        {contratosSaude?.map((contrato, index) => {
          if (
            !contrato ||
            (contrato?.statusContratoBeneficiario === 'Inativo' &&
              contrato?.movimentacao_remocao &&
              contrato?.dataExclusao)
          ) {
            return '';
          }
          return (
            <div className="info-section" key={index}>
              <div className="data-info">
                <div>
                  {seguros[contrato.produto_id]}
                  <span className="info-plano">{contrato.nome}</span>
                  <span className="info-plano">
                    <AiOutlineMinus size={10} />
                  </span>
                  <span className="info-plano">{contrato.tipo_plano}</span>
                </div>
              </div>
              <div className="data-info">
                {seguros[contrato.produto_id]}
                <span className="info-plano">Apólice: </span>
                <span className="info-plano">{contrato.numero_apolice}</span>
              </div>
              <div className="data-info">
                <div>
                  <AiOutlineCreditCard size={18} />
                  <span className="info-plano">
                    {contrato?.numero_carteirinha}
                  </span>
                </div>
              </div>
              <div className="data-info">
                <div>
                  <BsFillCalendarEventFill />
                  <span className="info-plano">
                    {contrato.data_entrada
                      ? moment(contrato.data_entrada).utc().format('DD/MM/YYYY')
                      : ''}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const renderDentalPlanInfo = (data) => {
    const contratosOdonto = data?.contratos?.filter(
      (item) => item.produto_id === 2
    );

    return (
      <>
        {contratosOdonto?.map((contrato, index) => {
          if (
            !contrato ||
            (contrato?.statusContratoBeneficiario === 'Inativo' &&
              contrato?.movimentacao_remocao &&
              contrato?.dataExclusao)
          ) {
            return '';
          }
          return (
            <div className="info-section" key={index}>
              <div className="data-info">
                <div>
                  {seguros[contrato.produto_id]}
                  <span className="info-plano">{contrato.nome}</span>
                  <span className="info-plano">
                    <AiOutlineMinus size={10} />
                  </span>
                  <span className="info-plano">{contrato.tipo_plano}</span>
                </div>
              </div>
              <div className="data-info">
                {seguros[contrato.produto_id]}
                <span className="info-plano">Apólice: </span>
                <span className="info-plano">{contrato.numero_apolice}</span>
              </div>
              <div className="data-info">
                <div>
                  <AiOutlineCreditCard size={18} />
                  <span className="info-plano">
                    {contrato?.numero_carteirinha}
                  </span>
                </div>
              </div>
              <div className="data-info">
                <div>
                  <BsFillCalendarEventFill />
                  <span className="info-plano">
                    {contrato.data_entrada
                      ? moment(contrato.data_entrada).utc().format('DD/MM/YYYY')
                      : ''}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  // const renderPlanosInfos = (data) => {
  //   const contratosSaude = data?.contratos?.filter(
  //     (item) => item.produto_id === 1
  //   );
  //   // console.log('contratosSaude', contratosSaude)
  //   const maisRecenteSaude = contratosSaude
  //     ?.sort(
  //       (a, b) =>
  //         Number(new Date(b.contrato_beneficiario_created_at)) -
  //         Number(new Date(a.contrato_beneficiario_created_at))
  //     )
  //     .shift();

  //   const contratosOdonto = data?.contratos?.filter(
  //     (item) => item.produto_id === 2
  //   );
  //   const maisRecenteOdonto = contratosOdonto
  //     ?.sort(
  //       (a, b) =>
  //         Number(new Date(b.contrato_beneficiario_created_at)) -
  //         Number(new Date(a.contrato_beneficiario_created_at))
  //     )
  //     .shift();

  //   const contratosMaisRecentes = [maisRecenteSaude, maisRecenteOdonto];

  //   // console.log('contratosMaisRecentes', contratosMaisRecentes)

  //   return (
  //     <>
  //       {contratosMaisRecentes?.map((contrato, index) => {
  //         if (
  //           !contrato ||
  //           (contrato?.statusContratoBeneficiario === 'Inativo' &&
  //             contrato?.movimentacao_remocao &&
  //             contrato?.dataExclusao)
  //         ) {
  //           return '';
  //         }
  //         return (
  //           <div className="info-section" key={index}>
  //             <div className="data-info">
  //               <div>
  //                 {seguros[contrato.produto_id]}
  //                 <span className="info-plano">{contrato.nome}</span>
  //                 <span className="info-plano">
  //                   <AiOutlineMinus size={10} />
  //                 </span>
  //                 <span className="info-plano">{contrato.tipo_plano}</span>
  //               </div>
  //             </div>
  //             <div className="data-info">
  //               {seguros[contrato.produto_id]}
  //               <span className="info-plano">Apólice: </span>
  //               <span className="info-plano">{contrato.numero_apolice}</span>
  //             </div>
  //             <div className="data-info">
  //               <div>
  //                 <AiOutlineCreditCard size={18} />
  //                 <span className="info-plano">
  //                   {contrato?.numero_carteirinha}
  //                 </span>
  //               </div>
  //             </div>
  //             <div className="data-info">
  //               <div>
  //                 <BsFillCalendarEventFill />
  //                 <span className="info-plano">
  //                   {contrato.data_entrada
  //                     ? moment(contrato.data_entrada).utc().format('DD/MM/YYYY')
  //                     : ''}
  //                 </span>
  //               </div>
  //             </div>
  //           </div>
  //         );
  //       })}
  //     </>
  //   );
  // };

  const getBeneficiariesFamilyGroupContracts = async () => {
    const response = await services.beneficiaries.getBeneficiaryFamilyGroup(data.id);

    setFamilyGroup(response.data);
  }

  useEffect(() => {
    getBeneficiariesFamilyGroupContracts();
  }, []);

  return (
    <Wrapper>
      <header>
        <div className="headerUser">
          <div className="nameUser">
            <div className="user-company">
              <span className="header-title-name">{data.nome}</span>
              <div className="header-title-second-row">
                <span className="header-title-estipulante">
                  {data.estipulante}
                </span>
                <span className="header-title-hifen-space">
                  {' '}
                  <AiOutlineMinus size={10} />
                </span>
                <span className="header-title-estipulante">{data.tipo}</span>
                <span className="header-title-hifen-space">
                  {' '}
                  <AiOutlineMinus size={10} />
                </span>
                <span className="header-title-estipulante">
                  {idade(new Date(data.dados.data_nascimento))} anos
                </span>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className="infos">
        <div className="infos-section-title-container">
          <span className="infos-section-title">Benefícios</span>
        </div>
        {renderHealthPlanInfo(data)}
        {renderDentalPlanInfo(data)}
        <div className="info-section">
          <div className="infos-section-title-container">
            <span className="infos-section-title">Outras Informações</span>
          </div>
          <ul className="list-infos">
            <li>
              <span className="list-info-title">Data de Vínculo:</span>
              <span className="list-info-value">
                {data.dados.data_contratacao
                  ? moment(data.dados.data_contratacao)
                      .utc()
                      .format('DD/MM/YYYY')
                  : ''}
              </span>
            </li>
            <li>
              <span className="list-info-title">Data de Nascimento:</span>
              <span className="list-info-value">
                {data.dados.data_nascimento
                  ? moment(data.dados.data_nascimento)
                      .utc()
                      .format('DD/MM/YYYY')
                  : ''}
              </span>
            </li>
            <li>
              <span className="list-info-title">E-mail:</span>
              <span className="list-info-value">{data?.email}</span>
            </li>
            <li>
              <span className="list-info-title">Telefone:</span>
              <span className="list-info-value">{data?.dados?.telefone}</span>
            </li>
          </ul>
        </div>

        {familyGroup.tipo === 'Titular' ? (
          familyGroup.dependentes?.length > 0 ? (
            <div className="info-section">
              <div className="infos-section-title-container">
                <span className="infos-section-title">Grupo Familiar</span>
              </div>
              <ul className="list-infos">
                {familyGroup?.dependentes?.map((dependente) => (
                  <>
                    <li>
                      <span className="list-info-title">Dependente:</span>
                      <span className="list-info-value">{dependente.nome}</span>
                    </li>
                    <li>{renderHealthPlanInfo(dependente)}</li>
                    <li>{renderDentalPlanInfo(dependente)}</li>
                  </>
                ))}
              </ul>
            </div>
          ) : null
        ) : (
          <div className="info-section">
            <div className="infos-section-title-container">
              <span className="infos-section-title">Grupo Familiar</span>
            </div>
            <ul className="list-infos">
              <li>
                <span className="list-info-title">
                  {familyGroup.titular_id !== null
                    ? `${familyGroup?.titular?.tipo}:`
                    : null}
                </span>
                <span className="list-info-value">
                  {familyGroup.titular_id !== null
                    ? `${familyGroup?.titular?.nome}`
                    : null}
                </span>
              </li>
              <li>
                {familyGroup.titular_id !== null
                  ? renderHealthPlanInfo(familyGroup.titular)
                  : null}
              </li>
              <li>
                {familyGroup.titular_id !== null
                  ? renderDentalPlanInfo(familyGroup.titular)
                  : null}
              </li>
              {familyGroup?.dependentes?.length > 0
                ? familyGroup.dependentes.map((dependente) => (
                      <>
                        <li>
                          <span className="list-info-title">{`${dependente.tipo}:`}</span>
                          <span className="list-info-value">
                            {dependente.nome}
                          </span>
                        </li>
                        <li>{renderHealthPlanInfo(dependente)}</li>
                        <li>{renderDentalPlanInfo(dependente)}</li>
                      </>
                    ))
                : null}
            </ul>
          </div>
        )}
      </section>
    </Wrapper>
  );
};

export default DrawerBeneficiariosContent;
