import Button from 'components/DS/Button';
import ReactDatePicker from 'react-datepicker';
import { formatUTC } from 'utils/calculateFunctions';
import { FaArrowLeftLong } from 'react-icons/fa6';

const HealthEditForm = ({
  contrato,
  renderContratoTitle,
  seguradoraSaude,
  setSeguradoraSaude,
  updatePlanoSaudeInitialValue,
  renderSeguradoraOptions,
  planoSaude,
  setPlanoSaude,
  renderPlanosOptions,
  numeroCarteirinhaSaude,
  setNumeroCarteirinhaSaude,
  dataEntradaSaude,
  setDataEntradaSaude,
  setInitialContractsOptions,
  planToEdit,
  setPlanToEdit
}) => {
  return (
    <div>
      {planToEdit === contrato.contrato_id ? (
        <>
          <div className="title-container">
            <Button variant={'transparent'} onClick={() => {
              setPlanToEdit('');
              setInitialContractsOptions('initial');
            }}>
              <FaArrowLeftLong />
            </Button>
            <span>{renderContratoTitle(contrato)}</span>
          </div>
          <div className="row">
            <div className="column">
              <label>Seguradora</label>
              <select
                value={seguradoraSaude}
                onChange={(e) => {
                  setSeguradoraSaude(e.target.value);
                  updatePlanoSaudeInitialValue(e.target.value);
                }}
              >
                <option disabled value="" style={{ fontWeight: 'bold' }}>
                  Selecione
                </option>
                {renderSeguradoraOptions(contrato)}
              </select>
            </div>
            <div className="column">
              <label>Plano</label>
              <select
                value={planoSaude}
                onChange={(e) => {
                  setPlanoSaude(e.target.value);
                }}
              >
                {renderPlanosOptions(contrato)}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="column">
              <label>Número de carteirinha</label>
              <input
                type={'text'}
                value={numeroCarteirinhaSaude}
                onChange={(e) => {
                  setNumeroCarteirinhaSaude(e.target.value);
                }}
              />
            </div>
            <div className="column">
              <label>Data de entrada</label>
              <ReactDatePicker
                className="input-data"
                selected={
                  dataEntradaSaude ? formatUTC(dataEntradaSaude, true) : null
                }
                onChange={(date) => {
                  setDataEntradaSaude(formatUTC(date, true));
                }}
                dateFormat="dd/MM/yyyy"
                locale="ptBR"
              />
            </div>
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export default HealthEditForm;
