const InformationModal = ({ text }) => {
  return (
    <div className="informations-modal">
      <span>
        {text}
      </span>
    </div>
  );
}

export default InformationModal;
