import styled from 'styled-components';
import colors from 'styles/colors';

export const FilterTasksWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, max-content) 1fr 1fr;
  padding-left: 25px;
  padding-right: 25px;
  margin: 0px 70px;
  column-gap: 70px;

  .filter__container {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    .filter_title__task {
      font-family: Avenir Next Geo W05 Demi;
      font-size: 0.9em;
    }

    .filter_options {
      display: flex;
      column-gap: 15px;
  
      font-size: 0.85em;
  
        .MuiRadio-root {
          padding: 0;
        }
  
        .radio__container {
          display: flex;
          column-gap: 5px;
          align-items: center;

          label {
            width: 100%;
          }
        }
  
        .MuiSvgIcon-root {
          font-size: 1em;
        }
  
        .datepicker-box-space {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 370px;
          height: 25px;
          column-gap: 15px;
        }
  
        .datepicker-container {
          display: flex;
          align-items: center;
          column-gap: 5px;
  
          span {
            width: 90px;
          }
        }
  
        .input-date {
          width: 100px;
          height: 25px;
          border: 2px solid ${colors.lightGrey};
          display: inline-block;
          text-align: center;
          font-size: 0.75rem;
          font-family: 'Avenir Next GEO W05 Regular';
          border-radius: 7px;
  
          :focus-visible {
            outline: 1px ${colors.genoaBlue} auto;
          }
        }
    }
  
    .react-datepicker-wrapper {
      display: inline-block;
      padding: 0;
      border: 0;
      width: min-content;
      height: 25px;
      
    }
  
    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
      background-color: white;
      color: ${colors.genoaBlue};
      font-weight: bold;
    }
  
    .react-datepicker__day--selected, .react-datepicker__month-text--selected,  .react-datepicker__quarter-text--selected, .react-datepicker__year-text--selected {
      border-radius: 0.3rem;
      background-color: ${colors.genoaBlue};
      color: #fff;
    }
  
    .datepicker-selector-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      font-size: 0.8rem;
  
      .span-date-container {
        padding-bottom: 2px;
        font-family: "Avenir Next GEO W05 bold";
        color: ${colors.genoaGrey};
      }
    }
  }

  select {
    font-family: 'Avenir Next GEO W05 Regular';
    appearance: none;
    border-radius: 10px;
    border: none;
    padding: 2px;
    padding-left: 8px;
    cursor: pointer;
    width: 160px;

    :focus-visible {
      outline: 1px ${colors.genoaBlue}f5 auto;
      cursor: pointer;
    }
  }

  .select-arrow {
    background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 9L0.73686 0.75L10.2631 0.75L5.5 9Z' fill='%23456AED'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    
    background-position-x: 96%;
    background-position-y: center;
    background-size: 8px;
  }
  
`