import { useState, useEffect } from 'react';
import { ContentTab } from './styles';
import { TiEquals } from 'react-icons/ti';
import { useForm } from "react-hook-form";
import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";
import { ImCross } from "react-icons/im";
import "react-datetime/css/react-datetime.css";
import { BiPlus } from 'react-icons/bi';
import services from 'apps/broker/services';
import TopBar from 'apps/broker/components/HorizontalBar/topBar';
import BottomBar from 'apps/broker/components/HorizontalBar/bottomBar';

const ReajusteInner = ({ data }) => {
  // Estado do Array renderizado na tela
  const [reajustes, setReajustes] = useState([]);

  // Estado do modo de exclusão
  const [deleteMode, setDeleteMode] = useState({});

  const [openTable, setOpenTable] = useState(false)
  const { register, handleSubmit, control, getValues, setValue } = useForm({
    defaultValues: {
      'ano': new Date().getFullYear(),
      'contrato_id': data.length > 0 ? data[0]['id'] : '',
      'estipulante_id': data.length > 0 ? data[0]['estipulante_id'] : '',
      'observacoes': ''
    }
  });

  const toggleOpenTable = () => {
    setOpenTable((prevState) => !prevState);
  }

  const getReajustes = async () => {
    const { estipulante_id } = getValues();

    await services.insuranceCompanies
      .getAdjustmentsById(estipulante_id)
      .then((resp) => {
        if (resp.data.length > 0) {
          setReajustes(resp.data);
        } else {
          setReajustes([]);
        }
      });
  }

  const deleteReajuste = async (reajuste) => {
    await services.insuranceCompanies
      .deleteAdjustmentsByContractId(reajuste)
      .then(() => {
        getReajustes();
      });
  }

  // UseEffect do primeiro carregamento de tela
  useEffect(() => getReajustes(), [])

  async function onSubmit(dataForm, e) {
    const data = {
      reajuste: dataForm,
    };

    await services.insuranceCompanies
      .createAdjustments(data)
      .then(() => {
        getReajustes();
        setOpenTable(false);
      });
  }

  return (
    <ContentTab>

      <div className="section-reajuste">
        <TopBar title={'Reajuste'} />
        <div className={openTable ? "cols-data-reajuste-form-aberto" : "cols-data-reajuste-form-fechado"}>
          {
            data?.map((contrato) => {
              return (
                <div className="contrato-data-table-inner">
                  <div className="cols-header-container">
                    <div className="cols-header-reajuste-inner">
                      <p className="cols-header-contrato">Ano</p>
                      <p className="cols-header-contrato">VCMH</p>
                      <p className="cols-header-contrato">Sinistralidade</p>
                      <p className="cols-header-contrato">Total</p>
                      <p className="cols-header-contrato">Observações</p>
                      <div className="ano-icon-container">
                        {deleteMode[contrato.id] ? (
                          <BsCaretUpFill
                            size={25}
                            className="open-checkboxes-icon start-delete-mode"
                            id={contrato.id}
                            onClick={() => {
                              setDeleteMode((prevState) => ({
                                ...prevState,
                                [contrato.id]: !prevState[contrato.id]
                              }))
                            }
                            }
                          />
                        ) : (
                          <BsCaretDownFill
                            size={25}
                            className="open-checkboxes-icon start-delete-mode"
                            id={contrato.id}
                            onClick={() => {
                              setDeleteMode((prevState) => ({
                                ...prevState,
                                [contrato.id]: !prevState[contrato.id]
                              }))
                            }
                            }
                          />
                        )}

                      </div>
                    </div>
                  </div>
                  <div className="reajustes-table-container">
                    {
                      reajustes.map((reajuste, index) => {
                        if (contrato.id === reajuste.contrato_id) {
                          return (
                            <div className="cols-data-container">
                              <div className="cols-data-reajuste-inner">
                                <p>{reajuste['ano']}</p>
                                <p>{reajuste['vcmh'].replace('.', ',')}%</p>
                                <p>{reajuste['sinistralidade'].replace('.', ',')}%</p>
                                <div>
                                  <p>{`${reajuste['total'].replace('.', ',')}%`}</p>
                                </div>
                                <div>
                                  <p>{reajuste['observacoes']}</p>
                                </div>
                                <div className="ano-icon-container">
                                  <div>
                                    {deleteMode[reajuste.contrato_id] ? (
                                      <ImCross
                                        size={12}
                                        onClick={() => deleteReajuste(reajuste)}
                                        className="delete-icon"
                                      />
                                    ) : ''
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        }
                        return '';
                      })
                    }
                  </div>
                </div>
              )
            })
          }
        </div>

        <div className='reajuste-container'>
          <div className="adicionar-reajuste-container">
            <span onClick={toggleOpenTable}>
              <BiPlus className={openTable ? "fechar-reajuste" : "adicionar-reajuste"} size={16} />
              Reajuste
            </span>
          </div>
          <form
            className={openTable ?
              "form-table-visible" : "form-table-hidden"
            }
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="sectionContentReajuste">
              <div className="cols-reajuste-inner">
                <div>
                  <div className="title-col-reajuste">Ano</div>
                  <input
                    className="input-reajuste"
                    timeFormat={false}
                    dateFormat="YYYY"
                    {...register('ano', { required: true })}
                    onChange={() => console.log(control._formValues)}
                  />
                </div>
                <div>
                  <div className="title-col-reajuste">VCMH (%)</div>
                  <input
                    type="text"
                    placeholder='VCMH'
                    className="input-reajuste"
                    {...register('vcmh', { required: true })}
                    onChange={() => console.log(control._formValues)}
                  />
                </div>
                <div>
                  <div className="title-col-reajuste">Sinistralidade (%)</div>
                  <input
                    type="text"
                    placeholder='Sinistralidade'
                    className="input-reajuste"
                    {...register('sinistralidade', { required: true })}
                    onChange={() => console.log(control._formValues)}
                  />
                </div>
                <div>
                  <div className="title-col-reajuste"></div>
                  <TiEquals className="equal-icon" />
                </div>

                <div>
                  <div className="title-col-reajuste">Total (%)</div>
                  <input
                    type="text"
                    placeholder='Total'
                    className="input-reajuste"
                    {...register('total', { required: true })}
                    onChange={() => console.log(control._formValues)}
                  />
                </div>

              </div>
              <div className='container_observacoes'>
                <div className="title-col-reajuste">Observações</div>
                <textarea
                  {...register('observacoes')}
                  onChange={() => console.log(control._formValues)}
                />
              </div>
              <div className='btn_container'>
                <div className="title-col-reajuste"></div>
                <button type="submit" className="reajuste-submit-btn">
                  Enviar
                </button>
              </div>
            </div>

          </form>

        </div>
        <BottomBar />
      </div>
    </ContentTab>
  )
}

export default ReajusteInner
