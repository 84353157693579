import { Checkbox } from "@mui/material";
import InputStandard from "components/Input/InputStandard";
import maskCnpj from 'utils/inputMasks/maskCnpj';

const FormNewBusiness = ({
  buscaCnpj,
  setBuscaCnpj,
  cnpj,
  setCnpj,
  setEstipulanteSelecionado,
  cadastrado,
  setCadastrado,
  estipulantes,
  nomeFantasia,
  estipulanteSelecionado,
  selectValueChangeHandler,
  keyPressInputNomeFantasiaHandler
}) => {
  const checkboxCadastradoClickHandler = () => {
    setBuscaCnpj(undefined);
    setCnpj('');
    setEstipulanteSelecionado(undefined);
    setCadastrado((prevState) => !prevState);
  };

  const keyPressInputCnpjHandler = (e) => {
    const cnpjNumbers = e.target.value.replace(/[^0-9]/g, '');
    if (cnpjNumbers.length <= 14) {
      const oldValue = e.target.value;
      const newValue = maskCnpj(oldValue);
      setCnpj(newValue);
    }
  };

  return (
    <>
      <div className="form_option">
        <div className="checkbox_container">
          <Checkbox
            className="checkbox"
            checked={cadastrado}
            onChange={checkboxCadastradoClickHandler}
          />
          <label>Já é cadastrado?</label>
        </div>
        {!cadastrado ? (
          <div className="form_option">
            <div className="form_option__title">CNPJ:</div>
            <div className="custom_input__cnpj z_index_middle">
              <InputStandard
                name={'cnpj'}
                value={cnpj}
                setValue={keyPressInputCnpjHandler}
              />
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="empresa_info_container">
        {!cadastrado ? (
          buscaCnpj && buscaCnpj.status === 'OK' ? (
            <div className="form_container">
              <div className="form_option">
                <div className="form_option__title">Empresa:</div>
                <div>{buscaCnpj.nome}</div>
              </div>
              <div className="form_option">
                <div className="form_option__title">Nome fantasia:</div>
                <div className="custom_input_nome_fantasia_container">
                  <InputStandard
                    name={'nomeFantasia'}
                    value={nomeFantasia}
                    setValue={keyPressInputNomeFantasiaHandler}
                  />
                </div>
              </div>
            </div>
          ) : buscaCnpj && buscaCnpj.status === 'ERROR' ? (
            <div className="form_container">
              <div className="form_option">
                <div className="cnpj_error">CNPJ {cnpj} não foi encontrado</div>
              </div>
            </div>
          ) : (
            ''
          )
        ) : (
          <div className="form_container">
            <div className="form_option z_index_middle_first">
              <div className="form_option__title">Estipulante:</div>
              <div className="input__select">
                <select
                  className="select-arrow"
                  value={estipulanteSelecionado}
                  onChange={({ target }) => selectValueChangeHandler(target)}
                >
                  <option value={0}>Selecione</option>
                  {estipulantes?.map(({ option, value }) => (
                    <option value={value}>{option}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form_option z_index_middle">
              <div className="form_option__title">Nome fantasia:</div>
              <div className="custom_input_nome_fantasia_container">
                <InputStandard
                  name={'nomeFantasia'}
                  value={nomeFantasia}
                  setValue={keyPressInputNomeFantasiaHandler}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FormNewBusiness;
