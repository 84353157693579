import React, { useState, useEffect } from 'react';
import SidemenuRh from 'apps/broker/components/Sidebar';
import Header from 'apps/broker/components/Header';
import { Consult, Content, Main, Wrapper } from './styles';
import { AiOutlinePlus } from 'react-icons/ai';
import { SwipeableDrawer } from '@material-ui/core';
import DrawerAddContatoSeguradora from './components/AddInsuranceContact/DrawerAddContatoSeguradora';
import DrawerUpdateContatoSeguradora from './components/UpdateInsuranceContact/DrawerUpdateContatoSeguradora';
import CardContactInformation from './components/InsuranceContactDetails/CardContactInfo';
import services from 'apps/broker/services';

const ContatosSeguradoras = () => {
  const [drawerAdicionarContato, setDrawerAdicionarContato] = useState(false);
  const [drawerAtualizarContato, setDrawerAtualizarContato] = useState(false);
  const [seguradorasData, setSeguradorasData] = useState([]);
  const [contatoParaAtualizar, setContatoParaAtualizar] = useState();

  const toggleDrawerAddContatoSeguradora = () => {
    setDrawerAdicionarContato((prevState) => !prevState);
  };

  const toggleDrawerUpdateContatoSeguradora = (contato) => {
    setContatoParaAtualizar(contato);
    setDrawerAtualizarContato((prevState) => !prevState);
  };

  const getSeguradoras = async () => {
    const response = await services.insuranceCompanies.getAllInsurance();
    setSeguradorasData(response.data);
  };

  const deleteContatoSeguradora = async (contato) => {
    const response = await services.insuranceCompanies.deleteContactInformation(
      contato
    );

    if (response.status === 200 && response.data) {
      setSeguradorasData((prevState) => {
        const newState = prevState.map((seguradora) => {
          if (seguradora.id === contato.seguradora_id) {
            const contatosFiltered = seguradora.contatos.filter(
              (item) => item.id !== contato.id
            );
            seguradora.contatos = contatosFiltered;
          }
          return seguradora;
        });
        return newState;
      });
    }
  };

  useEffect(() => {
    getSeguradoras();
  }, []);

  return (
    <Wrapper>
      <SidemenuRh />
      <Content>
        <Header />
        <Main>
          <div className="main-painel">
            <Consult>
              <div className="title">
                <h1>Contatos Seguradoras</h1>
                <div
                  className="adicionar-contato-container"
                  onClick={() => toggleDrawerAddContatoSeguradora()}
                >
                  <AiOutlinePlus size={14} />
                  <span>Adicionar um Contato</span>
                </div>
              </div>
            </Consult>
            <CardContactInformation
              seguradorasData={seguradorasData}
              deleteContatoSeguradora={deleteContatoSeguradora}
              toggleDrawerUpdateContatoSeguradora={
                toggleDrawerUpdateContatoSeguradora
              }
            />
          </div>
        </Main>
      </Content>
      <SwipeableDrawer
        anchor="right"
        open={drawerAdicionarContato}
        onClose={toggleDrawerAddContatoSeguradora}
        onOpen={toggleDrawerAddContatoSeguradora}
      >
        <DrawerAddContatoSeguradora
          seguradorasData={seguradorasData}
          setSeguradorasData={setSeguradorasData}
          onClose={toggleDrawerAddContatoSeguradora}
        />
      </SwipeableDrawer>
      <SwipeableDrawer
        anchor="right"
        open={drawerAtualizarContato}
        onClose={toggleDrawerUpdateContatoSeguradora}
        onOpen={toggleDrawerUpdateContatoSeguradora}
      >
        <DrawerUpdateContatoSeguradora
          contato={contatoParaAtualizar}
          seguradorasData={seguradorasData}
          setSeguradorasData={setSeguradorasData}
          onClose={toggleDrawerUpdateContatoSeguradora}
        />
      </SwipeableDrawer>
    </Wrapper>
  );
};

export default ContatosSeguradoras;
