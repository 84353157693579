import { useState, useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import { AiOutlineClose } from 'react-icons/ai';
import { BiEditAlt } from 'react-icons/bi';
import { BsCheckLg } from 'react-icons/bs';
import { HiTrash } from 'react-icons/hi';
import services from 'apps/broker/services';

const CardAtividadeDrawer = ({
  item,
  changeAtividadesInfo,
  editAtividade,
  closeAtividadesInfo,
  renderColorAtivity,
  formatDate,
  atividadeResponsavelChangeHandler,
  optionsCorretores,
  atividadeStatusHandler,
  deleteAtividadeClickHandler,
  getNegociosData
}) => {
  const [defaultDescricao, setDefaultDescricao] = useState('');
  const [editedDescricao, setEditDescricao] = useState('');
  const [defaultDate, setDefaultDate] = useState(new Date());
  const [editedDate, setEditedDate] = useState(new Date());
  const [showSaveButton, setShowSaveButton] = useState(false);

  const updateDescricaoAtividade = async () => {
    const body = {
      descricao: editedDescricao,
      atividade_id: item.atividade_id
    };

    await services.activities.updateDescriptionByActivityId(body);
    getNegociosData();
    setShowSaveButton(false);
  };

  const updateDataAtividade = async () => {
    const body = {
      data_agendada: new Date(editedDate),
      atividade_id: item.atividade_id
    };

    await services.activities.updateDateByActivityId(body);
    getNegociosData();
    setShowSaveButton(false);
  };

  useEffect(() => {
    setDefaultDescricao(item?.descricao);
    setEditDescricao(item?.descricao);
    setDefaultDate(new Date(item.data_agendada).setHours(0, 0, 0, 0));
    setEditedDate(new Date(item.data_agendada).setHours(0, 0, 0, 0));
  }, [item]);

  useEffect(() => {
    if (editedDescricao !== defaultDescricao) {
      setShowSaveButton(true);
    } else if (editedDate !== defaultDate) {
      setShowSaveButton(true);
    } else {
      setShowSaveButton(false);
    }
  }, [editedDescricao, editedDate]);

  return (
    <div
      className="primeira_coluna_atividades"
      key={`${item.negocio_id}-${item.atividade_id}`}
    >
      <div className="buttons_container">
        <button onClick={() => changeAtividadesInfo(item.atividade_id)}>
          <BiEditAlt />
        </button>
        {editAtividade === item.atividade_id ? (
          <button
            className="close_edit_btn"
            onClick={() => {
              closeAtividadesInfo();
            }}
          >
            <AiOutlineClose />
          </button>
        ) : (
          ''
        )}
      </div>
      {editAtividade === item.atividade_id ? (
        <div className="conteiner_descricao_atividade">
          <input
            className="input_descricao_atividade"
            type="text"
            value={editedDescricao}
            onChange={({ target }) => setEditDescricao(target.value)}
          />
        </div>
      ) : (
        <div>
          <span className={`atividade_${renderColorAtivity(item)}`}>
            {item.descricao}
          </span>
        </div>
      )}
      {editAtividade === item.atividade_id ? (
        <div className="datepicker_containers">
          <ReactDatePicker
            className={`atividade_${renderColorAtivity(
              item
            )} custom_datepicker ${editedDate ? 'active' : ''}`}
            selected={new Date(editedDate)}
            locale="ptBR"
            minDate={new Date()}
            onChange={(date) =>
              setEditedDate(new Date(date).setHours(0, 0, 0, 0))
            }
            dateFormat="dd/MM/yyyy"
          />
        </div>
      ) : (
        <span className={`atividade_${renderColorAtivity(item)}`}>
          {formatDate(item.data_agendada)}
        </span>
      )}
      {editAtividade === item.atividade_id ? (
        <select
          className={`atividade_${renderColorAtivity(
            item
          )} select select__trigger`}
          value={item.usuario_id ? item.usuario_id : '0'}
          onChange={(e) => atividadeResponsavelChangeHandler(e, item)}
        >
          {optionsCorretores()}
        </select>
      ) : (
        <span
          className={`atividade_${renderColorAtivity(item)}`}
        >{`${item.usuario_nome} ${item.usuario_sobrenome}`}</span>
      )}
      <div className="buttons_container">
        {editAtividade === item.atividade_id ? (
          <div className="container_button">
            <button
              className={`save_observacoes_btn ${
                showSaveButton ? '' : 'disabled'
              }`}
              onClick={() => {
                if (
                  editedDescricao !== '' &&
                  editedDescricao !== defaultDescricao
                ) {
                  updateDescricaoAtividade();
                }
                if (editedDate !== '' && editedDate !== defaultDate) {
                  updateDataAtividade();
                }
                closeAtividadesInfo();
              }}
            >
              Salvar
            </button>
          </div>
        ) : (
          <>
            <button
              className="check"
              onClick={() => atividadeStatusHandler(item.atividade_id)}
            >
              <BsCheckLg />
            </button>
            <button
              className="trash"
              onClick={() => deleteAtividadeClickHandler(item)}
            >
              <HiTrash />
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default CardAtividadeDrawer;
