import { useState, useEffect } from "react";
import { PainelWrapper } from "./styles-PainelAdesaoRh";
import { BsArrowsAngleExpand } from "react-icons/bs";
import colors from "styles/colors";
import GraficoDonut from "../MovimentGraphic/GraficoDonut";

const PainelTitularesDependentes = (props) => {
  const { tableEstipulanteValue } = props;

  const [inclusaoCorretora, setInclusaoCorretora] = useState(0);
  const [exclusaoCorretora, setExclusaoCorretora] = useState(0);
  const [inclusaoRh, setInclusaoRh] = useState(0);
  const [exclusaoRh, setExclusaoRh] = useState(0);

  const getDataInclusao = () => {
    return [
      { name: "Corretora", value: inclusaoCorretora, fill: colors.genoaBlue },
      { name: "Rh", value: inclusaoRh, fill: `${colors.rhBlue}75` },
    ]
  }

  const getDataExclusao = () => {
    return [
      { name: "Corretora", value: exclusaoCorretora, fill: colors.genoaBlue },
      { name: "Rh", value: exclusaoRh, fill: `${colors.rhBlue}75` },
    ]
  }

  useEffect(() => {
    let totalInclusaoCorretora = 0;
    let totalExclusaoCorretora = 0;
    let totalInclusaoRh = 0;
    let totalExclusaoRh = 0;

    for (let index in tableEstipulanteValue) {
      totalInclusaoCorretora += tableEstipulanteValue[index]["Corretora"]["Inclusão"]
      totalExclusaoCorretora += tableEstipulanteValue[index]["Corretora"]["Exclusão"]
      totalInclusaoRh += tableEstipulanteValue[index]["Rh"]["Inclusão"]
      totalExclusaoRh += tableEstipulanteValue[index]["Rh"]["Exclusão"]
    }

    setInclusaoCorretora(totalInclusaoCorretora)
    setExclusaoCorretora(totalExclusaoCorretora)
    setInclusaoRh(totalInclusaoRh)
    setExclusaoRh(totalExclusaoRh)
  }, [tableEstipulanteValue])

  return (
    <PainelWrapper>
      <div className="title">
        <span>Adesão Módulo Rh</span>
      </div>
      <div className="graph__row">
        <GraficoDonut 
          data={getDataInclusao()}
          keyName={"Rh"}
          label={"Inclusão"}
        />
        <GraficoDonut
          data={getDataExclusao()}
          keyName={"Rh"}
          label={"Exclusão"}
        />
        <div className="expand_icon">
          <BsArrowsAngleExpand />
        </div>
      </div>
      <div className="legenda__container">
        <div className="legenda">
          <div className="legenda_col">
            <div className="hifen rh"></div>
            <div>
              <span>Rh</span>
            </div>
          </div>

          <div className="legenda_col">
            <div className="hifen corretora"></div>
            <div>
              <span>Corretora</span>
            </div>
          </div>
        </div>
      </div>
    </PainelWrapper>
  );
};

export default PainelTitularesDependentes;
