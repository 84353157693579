import styled from 'styled-components';

export const Button = styled.button`
  cursor: pointer;
  background: none;
  border: none;
`;

export const ContentWrapper = styled.div`
  @media (max-height: 580px) {
    .MuiListItem-gutters {
      padding-left: 10.72px;
      padding-right: 10.72px;
    }

    .MuiMenuItem-root {
      font-size: 0.67rem;
      min-height: 32.16px;
      line-height: 1;
      padding-top: 4px;
      letter-spacing: 0.0062846em;
      padding-bottom: 4px;
    }

    .MuiList-padding {
      padding-top: 5.36px;
      padding-bottom: 5.36px;
    }
  }

  @media (min-height: 580px) and (max-height: 650px) {
    .MuiListItem-gutters {
      padding-left: 12.8px;
      padding-right: 12.8px;
    }

    .MuiMenuItem-root {
      font-size: 0.8rem;
      min-height: 38.4px;
      line-height: 1.2;
      padding-top: 4.8px;
      letter-spacing: 0.007504em;
      padding-bottom: 4.8px;
    }

    .MuiList-padding {
      padding-top: 6.4px;
      padding-bottom: 6.4px;
    }
  }

  @media (min-height: 650px) and (max-height: 720px) {
    .MuiListItem-gutters {
      padding-left: 14.4px;
      padding-right: 14.4px;
    }

    .MuiMenuItem-root {
      font-size: 0.91rem;
      min-height: 43.2px;
      line-height: 1.365;
      padding-top: 5.4px;
      letter-spacing: 0.008442em;
      padding-bottom: 5.4px;
    }

    .MuiList-padding {
      padding-top: 7.2px;
      padding-bottom: 7.2px;
    }
  }

  @media (min-height: 720px) {
    .MuiListItem-gutters {
      padding-left: 16px;
      padding-right: 16px;
    }

    .MuiMenuItem-root {
      font-size: 1rem;
      min-height: 48px;
      line-height: 1.5;
      padding-top: 6px;
      letter-spacing: 0.00938em;
      padding-bottom: 6px;
    }

    .MuiList-padding {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
`;

export const DropdownHeaderMenu = styled.div`
  @media (max-height: 580px) {
    .list-icon {
      min-width: 23.45px;

      svg {
        font-size: 0.8375rem;
      }

      img {
        height: 18px;
        width: 18px;
      }
    }

    .request-icon {
      padding-left: 4px;
    }

    span.MuiTypography-root {
      font-size: 0.6rem;
    }
  }

  @media (min-height: 580px) and (max-height: 650px) {
    .list-icon {
      min-width: 25.2px;

      svg {
        font-size: 1rem;
      }

      img {
        height: 18px;
        width: 18px;
      }
    }

    .request-icon {
      padding-left: 4.3px;
    }

    span.MuiTypography-root {
      font-size: 0.72rem;
    }
  }

  @media (min-height: 650px) and (max-height: 720px) {
    .list-icon {
      min-width: 31.5px;

      svg {
        font-size: 1.1375rem;
      }

      img {
        height: 25px;
        width: 25px;
      }
    }

    .request-icon {
      padding-left: 4.6px;
    }

    span.MuiTypography-root {
      font-size: 0.819rem;
    }
  }

  @media (min-height: 720px) {
    .list-icon {
      min-width: 35px;

      svg {
        width: 1em;
        height: 1em;
        font-size: 1.25rem;
      }

      img {
        height: 28px;
        width: 28px;
      }
    }

    .request-icon {
      padding-left: 5px;
    }

    span.MuiTypography-root {
      font-size: 0.9rem;
    }
  }
`;
