import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';


posthog.init(
  process.env.REACT_APP_PUBLIC_POSTHOG_KEY,
  {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
    autocapture: false,
  }
);

posthog.capture('Carregou o Zeca', { property: 'value' })

ReactDOM.render(
  <PostHogProvider client={posthog}>
    <App />
  </PostHogProvider>
  , document.getElementById('root')
);

