import http from 'services/http';

const getConciergeByFilterDate = async (data) => {
  const res = await http.post('/corretor/concierge/relatorio', data);

  return res;
};

const getMovimentByFilterDate = async (data) => {
  const res = await http.post('/corretor/movimentacoes/relatorio', data);

  return res;
};

const getMovimentStatusOpen = async () => {
  const res = await http.get(
    '/corretor/movimentacoes/relatorio/grafico-linear'
  );

  return res;
};

const modules = {
  getConciergeByFilterDate,
  getMovimentByFilterDate,
  getMovimentStatusOpen
};

export default modules;
