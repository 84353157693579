import { useEffect, useState } from 'react';
import SidemenuRh from 'apps/hr/components/Sidebar-RH';
import Header from 'apps/hr/components/Header-RH-Novo';
import CheckmarkStandard from 'apps/broker/components/Select/Checkmark';
import TableHeader from './TableHeader';
import TableResult from './TableResult';
import { useInclusionRequests } from 'contexts/inclusionRequests';
import { useUser } from 'contexts/user';
import DownloadPersonalInformation from './DownloadPersonalInformation';
import service from 'apps/hr/services';
import './style.scss';

const ManagerRequestInclusion = () => {
  const { user } = useUser();
  const {
    holdersInformation,
    statusRequests,
    status,
    setStatus,
    getInclusionRequests
  } = useInclusionRequests();

  const [personalInformation, setPersonalInformation] = useState([]);

  const getPesonalInformationToDownload = async () => {
    const titulares = await service.inclusionRequest.getPersonalInformation(
      user.estipulante
    );

    if (titulares.length > 0) {
      return;
    }

    setPersonalInformation(titulares);
  };

  useEffect(() => {
    getInclusionRequests();
    getPesonalInformationToDownload();
  }, []);

  return (
    <div className="manager-request">
      <SidemenuRh />
      <main className="manager-request__main">
        <Header />
        <div className="manager-request-container">
          <div
            className="manager-request-content-title"
            style={{ marginBottom: 30 }}
          >
            <h1 className="manager-request-content-title__title">
              Todas as solicitações de inclusão
            </h1>
          </div>
          <div className="manager-request-content">
            <div className="manager-request-content__checkbox-content">
              <CheckmarkStandard
                value={status}
                setValue={setStatus}
                options={statusRequests}
                label="Status de solicitação"
                variant="hr"
              />
            </div>
            <div className="manager-request-content__button-content">
              <DownloadPersonalInformation personalInformation={personalInformation} />
            </div>
          </div>
          <div>
            <TableHeader />
            {holdersInformation?.map((item, index) => (
              <TableResult key={index} data={item} />
            ))}
          </div>
        </div>
      </main>
    </div>
  );
};

export default ManagerRequestInclusion;
