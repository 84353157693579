import styled from "styled-components";
import colors from "styles/colors";

export const Container = styled.div`
  /* 
  .logo, .nome-rh, .header-menu {
    background: red;
  } */

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  padding: 25px 25px 125px 25px;
  background: ${colors.purple};
  background: -moz-linear-gradient(
    90deg,
    ${colors.purple} 0%,
    ${colors.cyan} 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    ${colors.purple} 0%,
    ${colors.cyan} 100%
  );
  background: linear-gradient(90deg, ${colors.purple} 0%, ${colors.cyan} 100%);

  /* @media (max-width: 720px) {

        align-items: flex-start;

        .nome-rh {
            color: #F00;
            
        }
    } */

  .header-menu {
    display: grid;
    grid-template-columns: 1fr 1fr 80px;
    grid-gap: 20px;
    align-items: center;

    .novo-colaborador button,
    .novo-ticket button {
      background: transparent;
      border: none;
      font-family: "Avenir Next GEO W05 Bold";
      font-size: 1rem;
      color: #fff;
      text-align: center;
      cursor: pointer;
    }

    .iniciais {
      cursor: pointer;
      .name {
        margin: 0px auto;
        background: #fff;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Avenir Next GEO W05 Demi";
        color: #006ffd;
        font-size: 18px;

        transition: all 0.4s;
        &:hover {
          background: #eef;
        }
      }
    }
  }

  .search-input {
    width: 80%;
    max-width: 400px;
    display: flex;
    text-align: center;
    border: none;
    border: 3px solid #bfbfbf;
    border-radius: 30px;
  }

  .nome-rh {
    p {
      color: #fff;
      text-align: center;
      font-family: "Avenir Next GEO W05 Bold";
    }
  }
  /* .new-ticket {
        border: none;
        background: #62A0E2;
        max-width: 200px;
        width: 100%;
        font-size: 1.125rem;
        color: #fff;
        border-radius: 30px;
        font-weight: 600;
    } */

  .logo {
    max-width: 170px;
    max-height: 60px;
    img {
      max-width: 170px;
      max-height: 60px;
    }
  }
`;

export const ContainerModal = styled.div`
  padding: 1rem 2rem;
  background: #fff;
  border: none;

  h1 {
    color: ${colors.darkGray};
    font-family: "Avenir Next GEO W05 Bold";
    margin-bottom: 1rem;
  }

  .msgSuccess {

    .newTicket {
        padding: 0.25rem 0;
        color: ${colors.purple};
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    .buttons {

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      


      .btn-close {
        background: transparent;
        border: 1px solid ${colors.purple};
        border-radius: 0.75rem;
        color: ${colors.darkGray};
        padding: 0.25rem 0.5rem;
        cursor: pointer;

        transition: all 0.4s;

        &:hover {
          background: ${colors.purple};
          color: #FFF;

        }
      }
    }
  }

  form {
    display: flex;
    flex-direction: column;

    .input-group {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      label {
        font-size: 1.2rem;
        font-weight: bold;
      }

      input,
      select {
        width: 100%;
        border: 1.5px solid ${colors.purple};
        border-radius: 8px;
        font-size: 1rem;
        font-weight: normal;
        color: ${colors.darkGray};

        padding: 0.375rem 0.25rem;
      }



      .input-label {
        max-width: 9.5rem;

        input {
          display: none;
        }

        span {
          display: flex;
          align-items: center;
          background: transparent;
          color: ${colors.purple} ;
          font-weight: normal;

          border: 1px solid ${colors.purple};
          border-radius: 0.5rem;
          padding: 0.25rem 0.5rem;
          cursor: pointer;

          transition: all 0.4s;

          &:hover {
            background: ${colors.purple};
            color: #fff;
          }
        }
      }

      .file {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        span {
          font-size: 0.75rem;
        }

        .btn-clear {
          background: transparent;
          border: none;
          cursor: pointer;
          font-size: 1rem;
          display: flex;
          align-items: center;

          svg {
            width: 100%;

            /* font-size: 1rem; */
            color: ${colors.cyan};
          }

          &:hover {
            svg {
              color: #fb2916;
            }
          }
        }
      }

      & + .input-group {
        margin-top: 0.75rem;
      }
    }

    button[type="submit"] {
      width: 30%;
      background: ${colors.purple};
      margin: 0 auto;
      margin-top: 0.75rem;
      padding: 0.5rem;
      border: none;
      border-radius: 0.5rem;

      font-size: 1.25rem;
      color: #fff;

      cursor: pointer;
      transition: filter 0.2s;
      &:hover {
        filter: brightness(0.9);
      }
    }

    .text-area-editor {
      padding: 0 0.25rem;
    }

    .checkbox {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 1.25rem 0;
    }

    .errorMsg {
      font-size: 1rem;
      color: #fb2916;
    }
  }
`;
