import styled from 'styled-components';
import colors from 'styles/colors';

export const AbaFichaPropostaWrapper = styled.div`
 
  @media (max-height: 580px) {
    display: grid;
    grid-template-rows: 17.342px 1fr 26.68px;
    row-gap: 30px;
  
    .content-title {
      span {
        font-size: 12px;
        color: ${colors.rhBlue};
        font-family: Avenir Next GEO W05 Heavy;
      }
    }
  
    .aba-container {
      display: grid;
      grid-template-rows: 17.342px 1fr;
      row-gap: 40px;
  
      .aba-title-container {
        span {
          font-size: 12px;
          font-family: Avenir Next GEO W05 Bold;
          color: ${colors.genoaDarkBlue};
        }
      }
  
      .aba-input-container {
        width: 100%;
        height: 100%;
  
        display: flex;
        flex-direction: column;
        row-gap: 23.345px;
  
        .aba-input-row-cargo {
          display: grid;
          width: max-content;
          grid-template-columns: 233.45px 233.45px;
          column-gap: 33.35px;
          align-items: end;
  
          .title-container {
            display: flex;
            align-items: end;
            padding-bottom: 2px;
            border-bottom: .667px solid transparent;
            width: max-content;
            height: max-content;
            margin-bottom: .667px;
  
            :hover {
              border-bottom: .667px solid ${colors.rhBlue};
            }
          }
  
          .title {
            display: flex;
            border: none;
            background-color: inherit;
            color: ${colors.rhBlue};
            font-family: Avenir Next GEO W05 Bold;
            font-size: 10.672px;
            padding: 0;
            margin: 0;
            width: max-content;
            height: max-content;
            column-gap: 6.67px;
            align-items: center;
  
            :hover {
              cursor: pointer;
            }
          }
  
          .send-btn-container {
            display: flex;
            align-items: end;
            margin-top: 20px;
  
            .send-btn {
              display: flex;
              border: none;
              background-color: inherit;
              color: ${colors.genoaDarkBlue};
              font-family: Avenir Next GEO W05 Bold;
              font-size: 10.672px;
              padding: 0;
              margin: 0;
              width: max-content;
              height: max-content;
              column-gap: 6.67px;
              align-items: center;
              padding-bottom: 2px;
              width: max-content;
              height: max-content;
              margin-bottom: .667px;
  
              :hover {
                border-bottom: .667px solid ${colors.genoaDarkBlue};
              }
    
              :hover {
                cursor: pointer;
              }
            }
          }
        }
  
  
        .aba-input-last-row {
          display: grid;
          width: max-content;
          grid-template-columns: 200px 200px;
          column-gap: 33.35px;
          
        }
  
      }
    }
  }

  @media (min-height: 580px) and (max-height: 650px) {
    display: grid;
    grid-template-rows: 20.8px 1fr 32px;
    row-gap: 36px;
  
    .content-title {
      span {
        font-size: 14.4px;
        color: ${colors.rhBlue};
        font-family: Avenir Next GEO W05 Heavy;
      }
    }
  
    .aba-container {
      display: grid;
      grid-template-rows: 20.8px 1fr;
      row-gap: 48px;
  
      .aba-title-container {
        span {
          font-size: 14.4px;
          font-family: Avenir Next GEO W05 Bold;
          color: ${colors.genoaDarkBlue};
        }
      }
  
      .aba-input-container {
        width: 100%;
        height: 100%;
  
        display: flex;
        flex-direction: column;
        row-gap: 28px;
  
        .aba-input-row-cargo {
          display: grid;
          width: max-content;
          grid-template-columns: 280px 160px;
          column-gap: 40px;
          align-items: end;
  
          .title-container {
            display: flex;
            align-items: end;
            padding-bottom: 2.4px;
            border-bottom: .8px solid transparent;
            width: max-content;
            height: max-content;
            margin-bottom: .8px;
  
            :hover {
              border-bottom: .8px solid ${colors.rhBlue};
            }
          }
  
          .title {
            display: flex;
            border: none;
            background-color: inherit;
            color: ${colors.rhBlue};
            font-family: Avenir Next GEO W05 Bold;
            font-size: 12.8px;
            padding: 0;
            margin: 0;
            width: max-content;
            height: max-content;
            column-gap: 8px;
            align-items: center;
  
            :hover {
              cursor: pointer;
            }
          }
  
          .send-btn-container {
            display: flex;
            align-items: end;
            margin-top: 24px;
  
            .send-btn {
              display: flex;
              border: none;
              background-color: inherit;
              color: ${colors.genoaDarkBlue};
              font-family: Avenir Next GEO W05 Bold;
              font-size: 12.8px;
              padding: 0;
              margin: 0;
              width: max-content;
              height: max-content;
              column-gap: 8px;
              align-items: center;
              padding-bottom: 2.4px;
              width: max-content;
              height: max-content;
              margin-bottom: .8px;
  
              :hover {
                border-bottom: .8px solid ${colors.genoaDarkBlue};
              }
    
              :hover {
                cursor: pointer;
              }
            }
          }
        }
  
  
        .aba-input-last-row {
          display: grid;
          width: max-content;
          grid-template-columns: 240px 240px;
          column-gap: 40px;
          
        }
  
      }
    }
  }

  @media (min-height: 650px) and (max-height: 720px) {
    display: grid;
    grid-template-rows: 23.4px 1fr 36px;
    row-gap: 40.5px;
  
    .content-title {
      span {
        font-size: 16.2px;
        color: ${colors.rhBlue};
        font-family: Avenir Next GEO W05 Heavy;
      }
    }
  
    .aba-container {
      display: grid;
      grid-template-rows: 23.4px 1fr;
      row-gap: 54px;
  
      .aba-title-container {
        span {
          font-size: 16.2px;
          font-family: Avenir Next GEO W05 Bold;
          color: ${colors.genoaDarkBlue};
        }
      }
  
      .aba-input-container {
        width: 100%;
        height: 100%;
  
        display: flex;
        flex-direction: column;
        row-gap: 31.5px;
  
        .aba-input-row-cargo {
          display: grid;
          width: max-content;
          grid-template-columns: 315px 180px;
          column-gap: 45px;
          align-items: end;
  
          .title-container {
            display: flex;
            align-items: end;
            padding-bottom: 2.7px;
            border-bottom: 0.9px solid transparent;
            width: max-content;
            height: max-content;
            margin-bottom: 0.9px;
  
            :hover {
              border-bottom: 0.9px solid ${colors.rhBlue};
            }
          }
  
          .title {
            display: flex;
            border: none;
            background-color: inherit;
            color: ${colors.rhBlue};
            font-family: Avenir Next GEO W05 Bold;
            font-size: 14.4px;
            padding: 0;
            margin: 0;
            width: max-content;
            height: max-content;
            column-gap: 9px;
            align-items: center;
  
            :hover {
              cursor: pointer;
            }
          }
  
          .send-btn-container {
            display: flex;
            align-items: end;
            margin-top: 27px;
  
            .send-btn {
              display: flex;
              border: none;
              background-color: inherit;
              color: ${colors.genoaDarkBlue};
              font-family: Avenir Next GEO W05 Bold;
              font-size: 14.4px;
              padding: 0;
              margin: 0;
              width: max-content;
              height: max-content;
              column-gap: 9px;
              align-items: center;
              padding-bottom: 2.7px;
              width: max-content;
              height: max-content;
              margin-bottom: 0.9px;
  
              :hover {
                border-bottom: 0.9px solid ${colors.genoaDarkBlue};
              }
    
              :hover {
                cursor: pointer;
              }
            }
          }
        }
  
  
        .aba-input-last-row {
          display: grid;
          width: max-content;
          grid-template-columns: 270px 270px;
          column-gap: 45px;
          
        }
  
      }
    }
  }

  @media (min-height: 720px) {
    display: grid;
    grid-template-rows: 26px 1fr 40px;
    row-gap: 45px;
  
    .content-title {
      span {
        font-size: 18px;
        color: ${colors.rhBlue};
        font-family: Avenir Next GEO W05 Heavy;
      }
    }
  
    .aba-container {
      display: grid;
      grid-template-rows: 26px 1fr;
      row-gap: 60px;
  
      .aba-title-container {
        span {
          font-size: 18px;
          font-family: Avenir Next GEO W05 Bold;
          color: ${colors.genoaDarkBlue};
        }
      }
  
      .aba-input-container {
        width: 100%;
        height: 100%;
  
        display: flex;
        flex-direction: column;
        row-gap: 35px;
  
        .aba-input-row-cargo {
          display: grid;
          width: max-content;
          grid-template-columns: 350px 200px;
          column-gap: 50px;
          align-items: end;
  
          .title-container {
            display: flex;
            align-items: end;
            padding-bottom: 3px;
            border-bottom: 1px solid transparent;
            width: max-content;
            height: max-content;
            margin-bottom: 1px;
  
            :hover {
              border-bottom: 1px solid ${colors.rhBlue};
            }
          }
  
          .title {
            display: flex;
            border: none;
            background-color: inherit;
            color: ${colors.rhBlue};
            font-family: Avenir Next GEO W05 Bold;
            font-size: 16px;
            padding: 0;
            margin: 0;
            width: max-content;
            height: max-content;
            column-gap: 10px;
            align-items: center;
  
            :hover {
              cursor: pointer;
            }
          }
  
          .send-btn-container {
            display: flex;
            align-items: end;
            margin-top: 30px;
  
            .send-btn {
              display: flex;
              border: none;
              background-color: inherit;
              color: ${colors.genoaDarkBlue};
              font-family: Avenir Next GEO W05 Bold;
              font-size: 16px;
              padding: 0;
              margin: 0;
              width: max-content;
              height: max-content;
              column-gap: 10px;
              align-items: center;
              padding-bottom: 3px;
              width: max-content;
              height: max-content;
              margin-bottom: 1px;
  
              :hover {
                border-bottom: 1px solid ${colors.genoaDarkBlue};
              }
    
              :hover {
                cursor: pointer;
              }
            }
          }
        }
  
  
        .aba-input-last-row {
          display: grid;
          width: max-content;
          grid-template-columns: 300px 300px;
          column-gap: 50px;
          
        }
  
      }
    }
  }
`