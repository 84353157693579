import { useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import { useParams } from 'react-router-dom';
import { FiLogIn } from 'react-icons/fi';
import { FiCheckCircle } from 'react-icons/fi';
import { Wraper } from './styles';
import LogoCoinsure from 'assets/imgs/logo-coinsure.png';
import { makeStyles } from '@material-ui/core/styles';
import InputMask from 'react-input-mask';
import { useForm, Controller } from 'react-hook-form';
import services from 'apps/broker/services';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  header: {
    boxShadow: 'none',
    backgroundColor: '#f0e7ff'
  },
  tabs: {
    fontFamily: 'Avenir Next GEO W05 Demi'
  }
}));

const RegisterCorretor = () => {
  const classes = useStyles();
  const { token } = useParams();
  const { control, handleSubmit } = useForm();
  const [stateSucess, setStateSucess] = useState(false);
  const [errorApi, setErrorApi] = useState(false);

  const onSubmit = async (data) => {
    const response = await services.user.createEmailToNewUserByManager(
      token,
      data
    );
    if (response.status === 201) {
      setStateSucess(true);
    } else {
      setErrorApi(true);
    }
  };

  return (
    <Wraper>
      <header>
        <Container maxWidth="xl">
          <div className="top-area">
            <a className="nav-logo" href="/">
              <img src={LogoCoinsure} width="200" alt="" loading="lazy" />
            </a>
          </div>
        </Container>
      </header>
      <main>
        <Container>
          <Grid container justify="center" spacing={3}>
            <Grid item xs={12} md={6}>
              <Card>
                <div className={classes.root}>
                  {stateSucess ? (
                    <CardContent>
                      <div className="largeIcon">
                        <FiCheckCircle />
                      </div>
                      <div className="title">
                        <h1>Cadastro realizado</h1>
                      </div>
                    </CardContent>
                  ) : (
                    <CardContent>
                      <div className="title">
                        <h1>Cadastro Corretor(a)</h1>
                      </div>
                      <div className="form">
                        <form
                          validate="true"
                          autoComplete="off"
                          onSubmit={handleSubmit(onSubmit)}
                        >
                          <div className="flex-center"></div>
                          <label className="default">Nome</label>
                          <FormControl fullWidth className="form-controll">
                            <Controller
                              name="name"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                                <InputMask
                                  {...field}
                                  type="text"
                                  label="razaoSocial"
                                  required
                                />
                              )}
                            />
                          </FormControl>
                          <label className="default">Sobrenome</label>
                          <FormControl fullWidth className="form-controll">
                            <Controller
                              name="lastname"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                                <InputMask
                                  {...field}
                                  type="text"
                                  label="lastname"
                                  required
                                />
                              )}
                            />
                          </FormControl>
                          <label className="default">Email</label>
                          <FormControl fullWidth className="form-controll">
                            <Controller
                              name="email"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                                <InputMask
                                  {...field}
                                  type="email"
                                  label="E-mail"
                                  required
                                />
                              )}
                            />
                          </FormControl>
                          <label className="default">Senha</label>
                          <FormControl fullWidth className="form-controll">
                            <Controller
                              name="password"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                                <InputMask
                                  {...field}
                                  type="password"
                                  label="Senha"
                                  required
                                />
                              )}
                            />
                          </FormControl>
                          {errorApi && (
                            <span className="error-msg">
                              E-mail já cadastrado. Ou e-mail incorreto.
                            </span>
                          )}
                          <div className="flex-center mb-1">
                            <button type="submit">
                              Registrar{' '}
                              <span className="icon">
                                <FiLogIn />
                              </span>{' '}
                            </button>
                          </div>
                        </form>
                      </div>
                    </CardContent>
                  )}
                </div>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </main>
    </Wraper>
  );
};

export default RegisterCorretor;
