import styled from 'styled-components';
import colors from 'apps/broker/styles/colors';

export const Div = styled.div`
  display: grid;
  grid-template-columns: 26px 60px 1.5fr 0.75fr 0.5fr 1.5fr 0.5fr 0.5fr 80px;
  grid-gap: 10px;
  align-items: center;
  min-height: 42px;
  font-size: 0.8rem;

  .margin-auto {
    margin: 0 auto;
  }

  p {
    > svg {
      color: ${colors.genoaDarkBlue};
      font-size: 1.3rem;
    }
  }

  .more-infos {
    cursor: pointer;
    svg {
      margin-left: 15px;
    }
  }

  .flex-products {
    width: 55%;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    span {
      width: 45%;
      color: ${colors.genoaDarkBlue};
      display: flex;
      justify-content: center;

      > svg {
        font-size: 1.5rem;
      }
    }
  }

  .checkbox {
    margin-left: 7px;
    svg {
      height: 18px;
      width: 18px;
      color: ${colors.genoaDarkBlue};
    }
  }

  .fire-icon {
    width: 18px;
    height: 18px;

    position: absolute;
    // position 1
    transform: translate(60px, -23px);

    // position 2
    // transform: translate(295px, -17px);
  }
`;

export const TypeMovement = styled.p`
  text-align: center;

  position: relative;
  z-index: 0;

  svg {
    color: ${(props) =>
      props.type === 'Inclusão' ? '#43a047' : '#e53935'} !important;
  }

  span {
    visibility: hidden;
    width: 80px;
    background-color: ${colors.genoaBlue};
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    position: absolute;
    top: 100%;
    margin-left: -40px;
    z-index: 999;

    opacity: 0.8;
  }

  &:hover {
    span {
      visibility: visible;
    }
  }
`;
