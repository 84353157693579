import React, { useState } from 'react';
import DrawerAddBeneficiaries from './DrawerAddBeneficiaries';
import { FiUserPlus } from 'react-icons/fi';
import { SwipeableDrawer } from '@material-ui/core';

const AddBeneficiary = ({ allEstipulantes }) => {
  const [drawerAddBeneficiaryIsOpen, setDrawerAddBeneficiaryIsOpen] =
    useState(false);

  function toggleDrawerAddBeneficiary() {
    setDrawerAddBeneficiaryIsOpen(!drawerAddBeneficiaryIsOpen);
  }

  const contentAddBeneficiary = () => (
    <div
      className="teste"
      role="presentation"
    >
      <DrawerAddBeneficiaries estipulantes={allEstipulantes} />
    </div>
  );

  return (
    <>
      <button
        className="btn-add-beneficiary"
        onClick={() => toggleDrawerAddBeneficiary()}
      >
        <FiUserPlus size={19} />
        <span>Adicionar Beneficiário</span>
      </button>

      <SwipeableDrawer
        anchor="right"
        open={drawerAddBeneficiaryIsOpen}
        onClose={toggleDrawerAddBeneficiary}
        onOpen={toggleDrawerAddBeneficiary}
      >
        {contentAddBeneficiary()}
      </SwipeableDrawer>
    </>
  );
};

export default AddBeneficiary;
