import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { FiCheckCircle } from "react-icons/fi";

import { FormSection, MsgSuccess } from './styles'
import { useForm } from "react-hook-form";

import { useSelector } from 'react-redux'
import api from 'services/api';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  title: {
    fontFamily: 'Avenir Next GEO W05 Demi',
    color:'#3B3838'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography className={classes.title} variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function CustomizedDialogs({ openModal, setOpenModal, document}) {
  // const [openModal, setOpenModal] = React.useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [success, setSuccess] = React.useState(false)
  const [load, setLoad] = React.useState(false)
  const [errorApi, setErrorApi] = React.useState(false)
  const user = useSelector(state => state.userReducers.user)

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const onSubmit = (data) => {
    const headers = {
      corretora: user.corretora,
      authorization: `Bearer ${user.token}`
    }
    const body = {
      document: document,
      nome: data.nome
    }
    api.post('/corretor/update-file', body, { headers: headers })
    .then(() => {
      setSuccess(true)
    })
    .catch(err => setErrorApi(err.response.data))
    .finally(()=>{
      setLoad(false)
    })
  }

  React.useEffect(() => {
    setSuccess(false)
  }, [openModal])

  return (
    <div>
      
      <Dialog onClose={handleCloseModal} aria-labelledby="customized-dialog-title" open={openModal}>
        <DialogTitle id="customized-dialog-title" onClose={handleCloseModal}>
          Novo nome para este documento 
        </DialogTitle>
        <DialogContent dividers>

          {success ? <MsgSuccess>
            <div className="icon-area">
              <FiCheckCircle />
            </div>
            <div className="message">Documento salvo com sucesso</div>
          </MsgSuccess>
            : <FormSection>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="div-input">
                  <div>
                    <div className="actual-email">
                      Nome do documento atual: <a href={document?.file_link}>{document?.file_type}</a>
                    </div>
                    <input className="input-default"{...register('nome', { required: true, pattern: "[A-Za-z]+"})} />
                    {errors.email && <span className="error-msg">Digite o novo nome para este documento</span>}
                    {errorApi && <span className="error-msg">{errorApi}</span>}
                  </div>
                </div>
                <button type="submit" className="btn"><span>Salvar</span> {load &&  <CircularProgress size={15} />} </button>
              </form>
            </FormSection>

          }



        </DialogContent>

      </Dialog>
    
    </div>
  );
}
