
import styled from 'styled-components';
import colors from 'styles/colors';

export const Wraper = styled.div`
  background: ${colors.genoaLightBackgroundGrey};
  min-height: calc(100vh - 250px);

  main{
    display: flex;
    padding: 20px 30px;

    section{
     box-sizing:border-box
    }
    .border{
      border-right: 2px #fff solid;
      border-left: 2px #fff solid;
    }
  }

 
  .title {
    display: flex;
    flex-direction: column;
   // align-items: center;
    padding: 30px 0px;

    svg{
      font-size: 2rem;
      margin-right: 1rem;
    }
    h1{
      font-family: 'Avenir Next GEO W05 Heavy';
      color: #3B3838;
      margin-bottom: 10px;
      font-size: 1.17em;
      display: flex;
      align-items: center;
    }
    h2{
      font-family: 'Avenir Next GEO W05 Heavy';
      color: #3B3838;
      margin-bottom: 10px;
      font-size: 1.17em;
      display: flex;
      align-items: center;
    }
    span{
      font-size: 20px;
    }
  }
  .infos {
    display: flex;
    flex-direction: column;
    //align-items: center;
    padding: 20px 30px;
    flex: 1;
    opacity: 0.5;
    transition: ease 100ms ;

    :hover{
      opacity: 1;
    }

    ul.listSeguros{
      align-self: flex-start;
      margin-top: 30px;
      list-style: none;

      li{
        margin-top: 6px;
        display: flex;
        align-items: center;

        svg{
          margin-right: 5px;
          margin-top: 3px;
        }
      }
    }

    .contatos{
      margin-top: 30px;

      div{
        display: flex;
        align-items: center;

        svg{margin-top:3px;
        margin-right: 3px;
        }
      }
    }
  }

  .title-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;

    .logo {
    background: none;
  
    max-width: 110px;
    max-height: 80px;

   img{
       width:100%;
       max-height: 80px;
    }
  }
 
  }
  section.forms {
    display: flex;
    flex-direction: column;
   // align-items: center;
    padding: 20px 30px;
    flex: 1;

    .stages{
      box-sizing: border-box;
      //width: 600px;
      display: flex;
      flex-direction: column;
    //  align-items: center;
     // padding: 10px 30px;
    }

  }
  section.complete {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 10px;

    .content-center{
      display: inherit;
      flex-direction: column;
      align-items: center;

      .text-bold{  
        font-family: 'Avenir Next GEO W05 Demi';
      padding: 20px 0;
      }
    }

  }



 

  @media (max-width: 1024px) {
    main{
      padding: 20px 10px;
    }
  }

  @media (max-width: 900px){
    width: 100%;
    section.forms {
      .stages{
        width: 100%;
      }
    }

    main{
      flex-direction: column;
    }
  }

`

export const Footer = styled.footer` 
  display: flex;
    justify-content: center;
    background: ${colors.genoaLightBackgroundGrey};
    padding: 30px 0;
    .logo{
      margin-top: 50px;

      img{
        width: 140px;
      }
    }


`



