import { HiOutlineTrophy } from 'react-icons/hi2'

const CareOverview = ({ data, companiesCount }) => {
  return (
    <div className="column-one-row-two">
      <div className="trofeu_pessoas_cuidadas">
        <HiOutlineTrophy />
      </div>
      <div className="pessoas_cuidadas">
        <div className="title_fluxos_demandas">
          <span>Pessoas cuidadas pela Genoa</span>
        </div>
        <div>
          <section className="vidas">
            <div className="vidas-boxes">
              <div className="box-vidas">
                <div className="vidas-count">{data.saude}</div>
                <div className="vidas-title">Saúde</div>
              </div>

              <div className="box-vidas">
                <div className="vidas-count">{companiesCount}</div>
                <div className="vidas-title">Estipulantes</div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default CareOverview
