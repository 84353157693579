import styled from "styled-components";
import media from "styled-media-query";

export const Wrapper = styled.div`
  display: flex;
`;

export const GridSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 0.5fr;
  grid-gap: 2rem;

  ${media.lessThan("1440px")` `}

  ${media.lessThan("1280px")` `}

    ${media.lessThan("900px")` `}
`;

export const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 30px 20px;

  .title {
    padding-bottom: 20px;
    font-family: "Avenir Next GEO W05 Heavy";
    font-size: 18px;
    color: #3b3838;
  }

  .content {
    padding: 20px 0px;
    display: flex;
    justify-content: space-between;

    .image {
      img {
        width: 100%;
      }
    }

    .list-tickets {
      ul {
        list-style: none;
        li {
          margin-bottom: 15px;
        }
      }
    }
  }

  .footer {
    margin-top: 10px;
    a {
      font-family: "Avenir Next GEO W05 Demi";
      color: #6da2eb;
    }
  }
`;
