import { BiX } from "react-icons/bi";
import React from "react";
import { PopUpContent } from "./styles-PopUpListDocs";
import { ClickAwayListener, createTheme } from "@material-ui/core";
import { DarkBackground } from "apps/broker/pages/Business/components/CreateNewBusiness/styles";
import colors from "styles/colors";
import { Checkbox, ThemeProvider } from "@mui/material";
import { SideStripe } from "./styles-drawerAdicionarBeneficiarioRh";

const theme = createTheme({
  palette: {
    genoaBlue: {
      main: colors.genoaBlue,
    },
    darkGenoaBlue: {
      main: colors.genoaDarkBlue,
    },
    action: {
      disabled: colors.genoaDisabledGrey
    }
  },
});

const PopUpListDocumentos = ({
  isSelected,
  onClose,
  setIsSelected,
  campoInclusaoObj,
  files,
  setCheckboxValue,
  values,
  handleFile,
  deleteFile,
  saveChecklist,
}) => {
  const [transitionLeave, setTransitionLeave] = React.useState(false);

  // console.log('campoInclusaoObj', campoInclusaoObj)
  // console.log('isSelected', isSelected)
  // console.log('values', values)
  // console.log('files', files)

  return (
    <DarkBackground
      style={{ animation: transitionLeave ? "fade-out 0.225s forwards" : "" }}
    >
      <ClickAwayListener
        onClickAway={() => {
          setTransitionLeave(true)
          setTimeout(() => {
            onClose(false);
            setIsSelected(undefined);
          }, 200)
        }}
      >
        <PopUpContent>
          <SideStripe />
          <div className="content">
            <div className="container_header">
              <span
                className="title_name"
              >
                {`Inserir cópia de documentos de ${isSelected?.["Nome"]}`}
              </span>
              <div className="close-container">
                <BiX
                  className="close-icon"
                  size={28}
                  onClick={() => {
                    onClose(false);
                    setIsSelected(undefined);
                  }}
                />
              </div>
            </div>

            <div className="coluna_documentos">
              <div className="container_btn_doc">
                <label className="btn label-file">
                  Inserir documentos
                  <input
                    onChange={({ target }) => handleFile(target, isSelected?.["CPF"])}
                    type="file"
                    multiple
                  />
                </label>
              </div>

              <div className="checklist_container">
                {
                  isSelected["Se dependente CPF do titular"] ? (
                    <>
                      <ThemeProvider theme={theme}>
                        <div>
                          <Checkbox
                            size="small"
                            checked={values['documento_comprovacao_vinculo']}
                            onChange={setCheckboxValue}
                            name="documento_comprovacao_vinculo"
                            sx={{ p: 0 }}
                            color={"darkGenoaBlue"}
                          />
                          <label>
                            Comprovante de vínculo
                          </label>
                        </div>

                        <div className="documento_option">
                          <Checkbox
                            size="small"
                            checked={values["documento_scan_cpf"]}
                            onChange={setCheckboxValue}
                            name="documento_scan_cpf"
                            sx={{ p: 0 }}
                            color={"darkGenoaBlue"}
                          />
                          <label>
                            CPF
                          </label>
                        </div>

                        {
                          campoInclusaoObj['documento_scan_rg'] ? (
                            <div className="documento_option">
                              <Checkbox
                                size="small"
                                checked={values['documento_scan_rg']}
                                onChange={setCheckboxValue}
                                name="documento_scan_rg"
                                sx={{ p: 0 }}
                                color={"darkGenoaBlue"}
                              />
                              <label>
                                RG
                              </label>
                            </div>
                          ) : ""
                        }
                        {
                          campoInclusaoObj['scan_comprovante_residencia'] ? (
                            <div className="documento_option">
                              <Checkbox
                                size="small"
                                checked={values['scan_comprovante_residencia']}
                                onChange={setCheckboxValue}
                                name="scan_comprovante_residencia"
                                sx={{ p: 0 }}
                                color={"darkGenoaBlue"}
                              />
                              Comprovante de residência
                            </div>
                          ) : ""
                        }
                        {
                          campoInclusaoObj['scan_carta_inclusao'] ? (
                            <div className="documento_option">
                              <Checkbox
                                size="small"
                                checked={values['scan_carta_inclusao']}
                                onChange={setCheckboxValue}
                                name="scan_carta_inclusao"
                                sx={{ p: 0 }}
                                color={"darkGenoaBlue"}
                              />
                              <label>
                                Carta de inclusão
                              </label>
                            </div>
                          ) : ""
                        }
                        {
                          campoInclusaoObj['ficha_proposta'] ?
                            isSelected['Plano Saúde'] ?
                              (
                                <div className="documento_option">
                                  <Checkbox
                                    size="small"
                                    checked={values['ficha_proposta_saude']}
                                    onChange={setCheckboxValue}
                                    name="ficha_proposta_saude"
                                    sx={{ p: 0 }}
                                    color={"darkGenoaBlue"}
                                  />
                                  <label>
                                    Ficha proposta plano saúde
                                  </label>
                                </div>
                              ) : (
                                <div className="documento_option">
                                  <Checkbox
                                    size="small"
                                    checked={values['ficha_proposta_odonto']}
                                    onChange={setCheckboxValue}
                                    name="ficha_proposta_odonto"
                                    sx={{ p: 0 }}
                                    color={"darkGenoaBlue"}
                                  />
                                  <label>
                                    Ficha proposta plano Odontológico
                                  </label>
                                </div>
                              )
                            : null
                        }
                        {
                          campoInclusaoObj['scan_passaporte'] ? (
                            <div className="documento_option">
                              <Checkbox
                                size="small"
                                checked={values['scan_passaporte']}
                                onChange={setCheckboxValue}
                                name="scan_passaporte"
                                sx={{ p: 0 }}
                                color={"darkGenoaBlue"}
                              />
                              <label>
                                Passaporte
                              </label>
                            </div>
                          ) : ""
                        }
                        {
                          campoInclusaoObj['campos_personalizados']?.length === 0 ?
                            campoInclusaoObj['campos_personalizados']?.map((item) => (
                              <div className="documento_option">
                                <Checkbox
                                  size="small"
                                  checked={values[item]}
                                  onChange={setCheckboxValue}
                                  name={item}
                                  sx={{ p: 0 }}
                                  color={"darkGenoaBlue"}
                                />
                                <label>
                                  {item}
                                </label>
                              </div>
                            ))
                            : null
                        }
                      </ThemeProvider>
                    </>
                  ) : (
                    <>
                      <ThemeProvider theme={theme}>
                        {
                          campoInclusaoObj['documento_scan_rg'] ? (
                            <div className="documento_option">
                              <Checkbox
                                size="small"
                                checked={values['documento_scan_rg']}
                                onChange={setCheckboxValue}
                                name="documento_scan_rg"
                                sx={{ p: 0 }}
                                color={"darkGenoaBlue"}
                              />
                              <label>
                                RG
                              </label>
                            </div>
                          ) : ""
                        }
                        {
                          campoInclusaoObj['scan_carteira_trabalho'] ? (
                            <div className="documento_option">
                              <Checkbox
                                size="small"
                                checked={values['scan_carteira_trabalho']}
                                onChange={setCheckboxValue}
                                name="scan_carteira_trabalho"
                                sx={{ p: 0 }}
                                color={"darkGenoaBlue"}
                              />
                              <label>
                                Carteira de trabalho
                              </label>
                            </div>
                          ) : ""
                        }
                        {
                          campoInclusaoObj['scan_comprovante_residencia'] ? (
                            <div className="documento_option">
                              <Checkbox
                                size="small"
                                checked={values['scan_comprovante_residencia']}
                                onChange={setCheckboxValue}
                                name="scan_comprovante_residencia"
                                sx={{ p: 0 }}
                                color={"darkGenoaBlue"}
                              />
                              <label>
                                Comprovante de residência
                              </label>
                            </div>
                          ) : ""
                        }
                        {
                          campoInclusaoObj['scan_carta_inclusao'] ? (
                            <div className="documento_option">
                              <Checkbox
                                size="small"
                                checked={values['scan_carta_inclusao']}
                                onChange={setCheckboxValue}
                                name="scan_carta_inclusao"
                                sx={{ p: 0 }}
                                color={"darkGenoaBlue"}
                              />
                              <label>
                                Carta de inclusão
                              </label>
                            </div>
                          ) : ""
                        }
                        {
                          campoInclusaoObj['ficha_proposta'] ?
                            isSelected['Plano Saúde'] ?
                              (
                                <div className="documento_option">
                                  <Checkbox
                                    size="small"
                                    checked={values['ficha_proposta_saude']}
                                    onChange={setCheckboxValue}
                                    name="ficha_proposta_saude"
                                    sx={{ p: 0 }}
                                    color={"darkGenoaBlue"}
                                  />
                                  <label>
                                    Ficha proposta plano saúde
                                  </label>
                                </div>
                              ) : (
                                <div className="documento_option">
                                  <Checkbox
                                    size="small"
                                    checked={values['ficha_proposta_odonto']}
                                    onChange={setCheckboxValue}
                                    name="ficha_proposta_odonto"
                                    sx={{ p: 0 }}
                                    color={"darkGenoaBlue"}
                                  />
                                  <label>
                                    Ficha proposta plano Odontológico
                                  </label>
                                </div>
                              )
                            : null
                        }
                        {
                          campoInclusaoObj['scan_carta_permanencia'] ? (
                            <div className="documento_option">
                              <Checkbox
                                size="small"
                                checked={values['scan_carta_permanencia']}
                                onChange={setCheckboxValue}
                                name="scan_carta_permanencia"
                                sx={{ p: 0 }}
                                color={"darkGenoaBlue"}
                              />
                              <label>
                                Carta de permanência
                              </label>
                            </div>
                          ) : ""
                        }
                        {
                          campoInclusaoObj['scan_passaporte'] ? (
                            <div className="documento_option">
                              <Checkbox
                                size="small"
                                checked={values['scan_passaporte']}
                                onChange={setCheckboxValue}
                                name="scan_passaporte"
                                sx={{ p: 0 }}
                                color={"darkGenoaBlue"}
                              />
                              <label>
                                Passaporte
                              </label>
                            </div>
                          ) : ""
                        }
                        {
                          campoInclusaoObj['campos_personalizados']?.length === 0 ?
                            campoInclusaoObj['campos_personalizados']?.map((item) => (
                              <div className="documento_option">
                                <Checkbox
                                  size="small"
                                  checked={values[item]}
                                  onChange={setCheckboxValue}
                                  name={item}
                                  sx={{ p: 0 }}
                                  color={"darkGenoaBlue"}
                                />
                                <label>
                                  {item}
                                </label>
                              </div>
                            ))
                            : null
                        }
                      </ThemeProvider>
                    </>
                  )
                }
              </div>

              <div className="container_doc">
                {
                  files?.filter((item) => item.cpf === isSelected?.["CPF"])
                    ?.map((item, index) => (
                      <div className="content_doc" key={index}>
                        <span>{item?.file?.name}</span>
                        <div className="close_container_doc">
                          <BiX
                            className="close-icon"
                            size={20}
                            onClick={() => deleteFile(index)}
                          />
                        </div>
                      </div>
                    ))
                }
              </div>
            </div>

            <div className="btn_salvar_container">
              <button
                className="btn_salvar"
                type="button"
                onClick={() => {
                  saveChecklist(isSelected, values);
                  setIsSelected(undefined);
                  onClose(false);

                }}
              >
                Salvar
              </button>
            </div>

          </div>
        </PopUpContent>
      </ClickAwayListener>

    </DarkBackground>
  )
}

export default PopUpListDocumentos;
