import styled from 'styled-components';
import media from 'styled-media-query'
import colors from 'styles/colors';

export const Wrapper = styled.div`
  display: flex;

  .animeLeft {
    opacity: 0;
    transform: translateX(-20px);
    animation: animeLeft 0.3s forwards;
  }
  @keyframes animeLeft {
    to {
      opacity: 1;
      transform: initial;
    }
  }
`

export const Content = styled.div`
  @media (max-height: 580px) {
    display: flex;
    flex-direction: column;
    flex: 1;
  
    .main-content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding-left: 10px;
      padding-right: 10px;
      
      column-gap: 2.68vw;
      height: calc(100vh - 100.22px);
  
      .title {
        font-size: 12px;
        margin-top: 10px;
        span {
          font-family: 'Avenir Next GEO W05 Bold';
          color: ${colors.genoaGrey};
        }
      }
  
      .title-row-two {
        font-size: 12px;
        margin-top: 3.35px;
        span {
          font-family: 'Avenir Next GEO W05 Bold';
          color: ${colors.genoaGrey};
        }
      }
  
      .column-one {
        display: grid;
        @media (max-height: 850px) {
  
          grid-template-rows: 50% 50%;
        }
    
        @media (min-height: 850px) {
          grid-template-rows: 359.8px 1fr;
        }
        
  
        .row-one {
  
          .tickets-painel-container {
            height: calc(100% - 43.42px);
            margin-top: 13.4px;
            display: flex;
            // align-items: center;
  
            .tickets-painel {
              display: grid;
              grid-template-columns: 1fr 1fr 1fr 1fr;
              // border: 0.50px solid ${colors.rhBlue};
              border-radius: 16.75px;
              margin-left: -6.67px;
              background-color: ${colors.rhBlue};
              height: calc(50vh - 130.65px);
              width: calc(100% + 10px);
              max-height: 160px;
              // margin-bottom: 40px;
              padding: 0px 10.5px;
  
              .info-painel {
                display: flex;
                justify-content: center;
                align-items: center;
  
                .info {
                  display: flex;
                  flex-direction: column;
            
                  text-align: center;
  
                  .data-value {
                    color: ${colors.white};
                    font-family: 'Avenir Next GEO W05 Heavy';
                    font-size: 7vh;
                  }
  
                  .data-info-title {
                    margin-top: 3.35px;
                    color: ${colors.white};
                    font-family: 'Avenir Next GEO W05 Heavy';
  
                    @media (max-width: 1310px) {
  
                      font-size: 11.67px;
                    }
                    
  
                    @media (max-width: 1400px && min-width: 1310px) {
  
                      font-size: 15px;
                    }
                
                    @media (max-width: 1630px && min-width: 1400px) {
                      font-size: 17px;
                    }
  
                    @media (min-width: 1630px) {
                      font-size: 20px;
                    }
                  }
                }
              }
            }
          }
        }
        .row-two {
  
          table {
            margin-top: 13.4px;
            width: calc(100%);
            thead {
              display: grid;
              padding: 0 5.36px;
              max-height: 14px;
              grid-template-columns: 1fr 60px 33.5px 80px;
              column-gap: 26.8px;
              text-align: left;
              font-size: 8.71px;
              color: ${colors.genoaDarkBlue};
            }
  
            tbody {
              margin-top: 3.35px;   
              overflow-y: auto;
              display: flex;
              flex-direction: column;
              row-gap: 6.67px;
  
              tr {
                display: grid;
                grid-template-columns: 1fr 60px 33.5px 80px;
                column-gap: 26.8px;
                padding: 3.35px 5.36px;
                max-width: calc(100% - 10.72px);
                border-radius: 6.7px;
                font-size: 8px;
                background-color: ${colors.white};

                td {
                  align-self: center;
                }
  
                .icons-container {
                  display: flex;
                  justify-content: center;
                  column-gap: 3.35px;
                  margin-right: 3.35px;
                  color: ${colors.genoaDarkBlue};

                  svg {
                    font-size: 8.71px;
                  }
                }
  
                .data{
                  // margin-left: -6px;
                }
              }
  
              ::-webkit-scrollbar {
                position: relative;
                display: flex;
                left: 4px;
                width: 4px;
                background: transparent;
              }
              ::-webkit-scrollbar-thumb {
                background: ${colors.rhBlue};
                box-shadow: none;
                border-radius: 9px;
              }
              ::-webkit-scrollbar-track {
                box-shadow: inset 0 0 1.34px grey;
                border-radius: 9px;
              }
            }
          }
  
          .show-all {
            margin-top: 6.7px;
            display: flex;
            justify-content: end;
            color: ${colors.genoaDarkBlue};
            font-size: 9px;
            font-family: 'Avenir Next GEO W05 Demi';
  
            span {
              
              :hover {
                cursor: pointer;
                text-decoration: underline;
              }
  
            }  
            
          }
        }
      }
  
      .column-two {
        display: grid;
  
        @media (max-height: 850px) {
  
          grid-template-rows: 50% 50%;
        }
    
        @media (min-height: 850px) {
          grid-template-rows: 359.8px 1fr;
        }
  
        .row-one {
  
          .ticket-editor-container {
            height: calc(100% - 43.42px);
  
  
            form {
              margin-top: 13.4px;
  
              .buttons-container {
                margin-top: 10px;
                margin-left: 5.36px;
                display: flex;
                align-items: flex-start;
                column-gap: 26.8px;
  
                button {
                  border: none;
                  background-color: transparent;
                  font-size: 10.72px;
                  color: ${colors.rhBlue} ;
                  font-family: "Avenir Next GEO W05 Demi";
  
                  :hover {
                    cursor: pointer;
                  }
                }
  
                .upload-icon-container {
                  display: flex;
                  align-items: flex-start;

                  svg {
                    font-size: 12.73px;
                    width: 12.73px;
                    height: 12.73px;
                    padding-top: 2px;
                  }

                  .upload-icon {
                    color: ${colors.rhBlue};
  
                    :hover {
                      cursor: pointer;
                    }
                  }
  
                  .upload-icon-disabled {
                    color: ${colors.genoaDisabledGrey};
                  }
                }
  
                .documents-popper {
                  z-index: 1500;
                }
  
                .documents-attached-info-container {
                  margin-top: 3.35px;
                  width: calc(25vw);
                  min-height: 33.5px;
                  height: max-content;
                  padding: 6.67px 6.67px;
                  padding-bottom: 10px;
                  border-radius: 6.67px;
                  
  
                  .documents-attached-title {
                    font-family: "Avenir Next GEO W05 Bold";
                    color: ${colors.rhBlue};
                    font-size: 9px;
                    
                  }
  
                  .documents-list-container {
                    margin-top: 6.67px;
                  }
  
                  .file {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    column-gap: 6.67px;
                    align-items: center;
                    margin-top: 3.35px;
            
                    span {
                      font-size: 8.71px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      color: ${colors.genoaGrey};
                    }
            
                    .btn-clear {
                      background: transparent;
                      border: none;
                      cursor: pointer;
                      font-size: 0.67rem;
                      display: flex;
                      align-items: center;
            
                      svg {
                   
                        font-size: 11.39px;
                        /* font-size: 0.67rem; */
                        color: ${colors.rhBlue};
                      }
            
                      &:hover {
                        svg {
                          color: #fb2916;
                        }
                      }
                    }
  
                  }
  
                }
              }
  
              .input-label {
        
                input {
                  display: none;
                }
        
                span {
                  display: flex;
                  font-size: 10.72px;
                  align-items: center;
                  background: transparent;
                  color: ${colors.rhBlue} ;
                  font-family: "Avenir Next GEO W05 Demi";           
                  cursor: pointer;           
                }
              }
  
              .circular-spinner {
                margin-left: 6.67px;
                color: ${colors.genoaDarkBlue};
              }
  
              .check-icon {
                margin-left: 6.67px;
                color: #4caf50;
              }
            }
  
            .rdw-editor-toolbar {
              margin-bottom: -25.46px;
              border-top-left-radius: 10px;
              border-top-right-radius: 10px;
              font-size: 8px;
              padding: 0px;
  
              img {
                height: 5.36px;
              }
            }
  
            .ticket-subject-input-container {
              border-top-left-radius: 10px;
              border-top-right-radius: 10px;
              margin-left: -6.67px;
              padding-left: 20px;
              padding-right: 20px;
              padding-top: 5.36px;
              padding-bottom: 6.67px;
              height: 10.72px;
              background-color: ${colors.white};
              border: 1px solid ${colors.rhBlue};
              display: flex;
              align-items: center;
  
              .ticket-subject-title {
                color: ${colors.genoaGrey};
                font-size: 9px;
                font-family: 'Avenir Next GEO W05 Demi';
              }
  
              .ticket-subject-input {
                margin-left: 6.67px;
                width: 100%;
                border: none;
                height: 14px;
                font-size: 9px;
                color: #969ba7;
                font-family: 'Avenir Next GEO W05 Regular';
  
                ::placeholder {
                  font-size: 9px;
                  font-family: 'Avenir Next GEO W05 Regular';
                  color: ${colors.genoaDisabledGrey};
                }
              }
            }
  
            .rdw-editor-main {
              padding-top: 13.4px;
              padding-left: 20px;
              padding-right: 20px;
              margin-left: -6.67px;
              border-right: 1px solid ${colors.rhBlue};
              border-bottom: 1px solid ${colors.rhBlue};
              border-left: 1px solid ${colors.rhBlue};
              border-image: initial;
              border-bottom-left-radius: 10px;
              border-bottom-right-radius: 10px;
              background-color: white;
              font-size: 10px;
              
              @media (max-height: 850px) {
                height: calc(50vh - 150.75px);
              }
          
              @media (min-height: 850px) {
                height: 210px;
              }
              ::-webkit-scrollbar {
                width: 0px;
                background: transparent;
              }
              ::-webkit-scrollbar-thumb {
                background: ${colors.rhBlue};
                box-shadow: none;
                border-radius: 14px;
              }
              ::-webkit-scrollbar-track {
                box-shadow: inset 0 0 2px grey;
                border-radius: 14px;
              }
            }
  
            .rdw-dropdown-wrapper {
              height: 13.4px;
            }
  
          }
        }
  
        .row-two {
  
          table {
            margin-top: 13.4px;
            width: 100%;
  
            thead {
              display: grid;
              padding: 0 5.36px;
              max-height: 14px;
              grid-template-columns: 0.737fr 33.5px 0.667fr 60px;
              column-gap: 20px;
              text-align: left;
              font-size: 8.71px;
              color: ${colors.genoaDarkBlue};
            }
  
            tbody {
              margin-top: 3.35px;
              overflow-y: auto;
              display: flex;
              flex-direction: column;
              row-gap: 6.67px;
  
              tr {
                display: grid;
                grid-template-columns: 0.737fr 33.5px 0.67fr 60px;
                column-gap: 20px;
                padding: 3.35px 5.36px;
                border-radius: 6.67px;
                width: calc(100% - 10.72px);
                font-size: 8px;
                background-color: ${colors.white};

                td {
                  align-self: center;
                }
  
                .vidas-span {
                  padding-left: 14.33px;
                }
  
                .icons-container {
                  color: ${colors.genoaDarkBlue};

                  svg {
                    font-size: 8.71px;
                  }
                }
  
                .data {
                  display: flex;
                  justify-content: center;
                }
              }
  
              ::-webkit-scrollbar {
                position: relative;
                display: flex;
                left: 4px;
                width: 4px;
                background: transparent;
              }
              ::-webkit-scrollbar-thumb {
                background: ${colors.rhBlue};
                box-shadow: none;
                border-radius: 9px;
              }
              ::-webkit-scrollbar-track {
                box-shadow: inset 0 0 1.34px grey;
                border-radius: 9px;
              }
            }
          }
  
          .show-all {
            margin-top: 6.67px;
            display: flex;
            justify-content: end;
            color: ${colors.genoaDarkBlue};
            font-size: 9px;
            font-family: 'Avenir Next GEO W05 Demi';
  
            span {
  
              :hover {
                cursor: pointer;
                text-decoration: underline;
              }
  
            }  
  
          }
        }
        
      }
    }
  
    .largeText{
      padding-top: 20px;
      color: #62A0E2;
      text-align: center;
      font-family: 'Avenir Next GEO W05 Heavy';
      font-size: 20px;
      margin-bottom: 13.4px;
      
    }
    .tickets {
      display: grid;
      grid-template-columns: 200px 200px 200px 200px;
      grid-gap: 26.8px;
      text-align: center;
      padding-top: 26.8px;
      max-width: 1000px;
      margin-inline: auto;
      justify-content: center;
      //place-content: center;
    }
    .box-ticket {
      max-width: 200px;
      height: 268px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      padding: 0px 26.8px;
    }
    .ticket-title {
      color: #62A0E2;
      font-size: 0.8375rem;
      margin-bottom: 26.8px;
    }
    .ticket-count {
      height: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      font-size: 3.35rem;
      color: #7D7D7D;
      border-radius: 6.67px;
    }
    .seeAll{
      margin-top: 13.4px;
      padding: 0px 1.34px;
      box-sizing: border-box; 
      display: flex;
      justify-content: center;
  
      a {
      font-family: 'Avenir Next GEO W05 Bold';
      color: #62A0E2;
      cursor: pointer;
      }
  
    }
    ${media.lessThan("1440px")`
      .box-ticket {
        padding: 0px 10px;
      }
      .tickets {
        grid-gap: 0px;
        grid-template-columns: 250px 250px 250px 250px;
      }
      .ticket-title {
        font-size: 1rem;
      }
      .ticket-count {
        font-size: 4rem;
      }
    `}
  
    ${media.lessThan("1280px")`
      .tickets {
        grid-gap: 0px;
        grid-template-columns: 200px 200px 200px 200px;
      }
    `}
  
    ${media.lessThan("900px")`
      .tickets {
        grid-gap: 0px;
        grid-template-columns: 200px 200px;
      }
    `}
  }

  @media (min-height: 580px) and (max-height: 650px) {
    display: flex;
    flex-direction: column;
    flex: 1;
  
    .main-content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding-left: 12px;
      padding-right: 12px;
      
      column-gap: 3.2vw;
      height: calc(100vh - 119.67px);
  
      .title {
        font-size: 14.4px;
        margin-top: 12px;
        span {
          font-family: 'Avenir Next GEO W05 Bold';
          color: ${colors.genoaGrey};
        }
      }
  
      .title-row-two {
        font-size: 14.4px;
        margin-top: 4px;
        span {
          font-family: 'Avenir Next GEO W05 Bold';
          color: ${colors.genoaGrey};
        }
      }
  
      .column-one {
        display: grid;
        @media (max-height: 850px) {
  
          grid-template-rows: 50% 50%;
        }
    
        @media (min-height: 850px) {
          grid-template-rows: 359.8px 1fr;
        }
        
  
        .row-one {
  
          .tickets-painel-container {
            height: calc(100% - 51.84px);
            margin-top: 16px;
            display: flex;
            // align-items: center;
  
            .tickets-painel {
              display: grid;
              grid-template-columns: 1fr 1fr 1fr 1fr;
              // border: 0.75px solid ${colors.rhBlue};
              border-radius: 20px;
              margin-left: -12px;
              background-color: ${colors.rhBlue};
              height: calc(50vh - 156px);
              width: calc(100% + 12px);
              max-height: 192px;
              // margin-bottom: 40px;
              padding: 0px 11px;
  
              .info-painel {
                display: flex;
                justify-content: center;
                align-items: center;
  
                .info {
                  display: flex;
                  flex-direction: column;
   
                  text-align: center;
  
                  .data-value {
                    color: ${colors.white};
                    font-family: 'Avenir Next GEO W05 Heavy';
                    font-size: 7.33vh;
                  }
  
                  .data-info-title {
                    margin-top: 4px;
                    color: ${colors.white};
                    font-family: 'Avenir Next GEO W05 Heavy';
  
                    @media (max-width: 1310px) {
  
                      font-size: 13px;
                    }
                    
  
                    @media (max-width: 1400px && min-width: 1310px) {
  
                      font-size: 15px;
                    }
                
                    @media (max-width: 1630px && min-width: 1400px) {
                      font-size: 17px;
                    }
  
                    @media (min-width: 1630px) {
                      font-size: 20px;
                    }
                  }
                }
              }
            }
          }
        }
        .row-two {
  
          table {
            margin-top: 16px;
            width: calc(100%);
            thead {
              display: grid;
              padding: 0 6.4px;
              max-height: 16.64px;
              grid-template-columns: 1fr 72px 40px 96px;
              column-gap: 32px;
              text-align: left;
              font-size: 10.4px;
              color: ${colors.genoaDarkBlue};
            }
  
            tbody {
              margin-top: 4px;   
              overflow-y: auto;
              display: flex;
              flex-direction: column;
              row-gap: 8px;
  
              tr {
                display: grid;
                grid-template-columns: 1fr 72px 40px 96px;
                column-gap: 32px;
                padding: 4px 6.4px;
                max-width: calc(100% - 12.8px);
                border-radius: 8px;
                font-size: 9.6px;
                background-color: ${colors.white};

                td {
                  align-self: center;
                }
  
                .icons-container {
                  display: flex;
                  justify-content: center;
                  column-gap: 4px;
                  margin-right: 4px;
                  color: ${colors.genoaDarkBlue};

                  svg {
                    font-size: 10.4px;
                  }
                }
  
                .data{
                  // margin-left: -6px;
                }
              }
  
              ::-webkit-scrollbar {
                position: relative;
                display: flex;
                left: 4.8px;
                width: 4.8px;
                background: transparent;
              }
              ::-webkit-scrollbar-thumb {
                background: ${colors.rhBlue};
                box-shadow: none;
                border-radius: 11.2px;
              }
              ::-webkit-scrollbar-track {
                box-shadow: inset 0 0 1.6px grey;
                border-radius: 11.2px;
              }
            }
          }
  
          .show-all {
            margin-top: 8px;
            display: flex;
            justify-content: end;
            color: ${colors.genoaDarkBlue};
            font-size: 11.2px;
            font-family: 'Avenir Next GEO W05 Demi';
  
            span {
              
              :hover {
                cursor: pointer;
                text-decoration: underline;
              }
  
            }  
            
          }
        }
      }
  
      .column-two {
        display: grid;
  
        @media (max-height: 850px) {
  
          grid-template-rows: 50% 50%;
        }
    
        @media (min-height: 850px) {
          grid-template-rows: 359.8px 1fr;
        }
  
        .row-one {
  
          .ticket-editor-container {
            height: calc(100% - 51.84px);
  
  
            form {
              margin-top: 16px;
  
              .buttons-container {
                margin-top: 12px;
                margin-left: 6.4px;
                display: flex;
                align-items: flex-start;
                column-gap: 32px;
  
                button {
                  border: none;
                  background-color: transparent;
                  font-size: 12.8px;
                  color: ${colors.rhBlue} ;
                  font-family: "Avenir Next GEO W05 Demi";
  
                  :hover {
                    cursor: pointer;
                  }
                }
  
                .upload-icon-container {
                  display: flex;
                  align-items: flex-start;

                  svg {
                    font-size: 15.2px;
                    width: 15.2px;
                    height: 15.2px;
                    padding: 2.3px;
                  }

                  .upload-icon {
                    color: ${colors.rhBlue};
  
                    :hover {
                      cursor: pointer;
                    }
                  }
  
                  .upload-icon-disabled {
                    color: ${colors.genoaDisabledGrey};
                  }
                }
  
                .documents-popper {
                  z-index: 1500;
                }
  
                .documents-attached-info-container {
                  margin-top: 4px;
                  width: calc(25vw);
                  min-height: 40px;
                  height: max-content;
                  padding: 8px 8px;
                  padding-bottom: 12px;
                  border-radius: 8px;
                  
  
                  .documents-attached-title {
                    font-family: "Avenir Next GEO W05 Bold";
                    color: ${colors.rhBlue};
                    font-size: 11.2px;
                    
                  }
  
                  .documents-list-container {
                    margin-top: 8px;
                  }
  
                  .file {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    column-gap: 8px;
                    align-items: center;
                    margin-top: 4px;
            
                    span {
                      font-size: 10.4px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      color: ${colors.genoaGrey};
                    }
            
                    .btn-clear {
                      background: transparent;
                      border: none;
                      cursor: pointer;
                      font-size: .8rem;
                      display: flex;
                      align-items: center;
            
                      svg {
                   
                        font-size: 13.6px;
                        /* font-size: 1rem; */
                        color: ${colors.rhBlue};
                      }
            
                      &:hover {
                        svg {
                          color: #fb2916;
                        }
                      }
                    }
  
                  }
  
                }
              }
  
              .input-label {
        
                input {
                  display: none;
                }
        
                span {
                  display: flex;
                  font-size: 12.8px;
                  align-items: center;
                  background: transparent;
                  color: ${colors.rhBlue} ;
                  font-family: "Avenir Next GEO W05 Demi";           
                  cursor: pointer;           
                }
              }
  
              .circular-spinner {
                margin-left: 8px;
                color: ${colors.genoaDarkBlue};
              }
  
              .check-icon {
                margin-left: 8px;
                color: #4caf50;
              }
            }
  
            .rdw-editor-toolbar {
              margin-bottom: -30.4px;
              border-top-left-radius: 12px;
              border-top-right-radius: 12px;
              font-size: 9.6px;
              padding: 0px;
  
              img {
                height: 6.4px;
              }
            }
  
            .ticket-subject-input-container {
              border-top-left-radius: 12px;
              border-top-right-radius: 12px;
              margin-left: -8px;
              padding-left: 24px;
              padding-right: 24px;
              padding-top: 6.4px;
              padding-bottom: 6.4px;
              height: 12.8px;
              background-color: ${colors.white};
              border: 1.2px solid ${colors.rhBlue};
              display: flex;
              align-items: center;
  
              .ticket-subject-title {
                color: ${colors.genoaGrey};
                font-size: 11.2px;
                font-family: 'Avenir Next GEO W05 Demi';
              }
  
              .ticket-subject-input {
                margin-left: 6.4px;
                width: 100%;
                border: none;
                height: 16.8px;
                font-size: 11.2px;
                color: #969ba7;
                font-family: 'Avenir Next GEO W05 Regular';
  
                ::placeholder {
                  font-size: 11.2px;
                  font-family: 'Avenir Next GEO W05 Regular';
                  color: ${colors.genoaDisabledGrey};
                }
              }
            }
  
            .rdw-editor-main {
              padding-top: 16px;
              padding-left: 24px;
              padding-right: 24px;
              margin-left: -8px;
              border-top: none;
              border-right: 1.2px solid ${colors.rhBlue};
              border-bottom: 1.2px solid ${colors.rhBlue};
              border-left: 1.2px solid ${colors.rhBlue};
              border-image: initial;
              border-bottom-left-radius: 12px;
              border-bottom-right-radius: 12px;
              background-color: white;
              font-size: 12px;

              @media (max-height: 850px) {
                height: calc(50vh - 180px);
              }
          
              @media (min-height: 850px) {
                height: 210px;
              }
              ::-webkit-scrollbar {
                width: 0px;
                background: transparent;
              }
              ::-webkit-scrollbar-thumb {
                background: ${colors.rhBlue};
                box-shadow: none;
                border-radius: 14px;
              }
              ::-webkit-scrollbar-track {
                box-shadow: inset 0 0 2px grey;
                border-radius: 14px;
              }
            }
  
            .rdw-dropdown-wrapper {
              height: 20px;
            }
  
          }
        }
  
        .row-two {
  
          table {
            margin-top: 20px;
            width: 100%;
  
            thead {
              display: grid;
              padding: 0 8px;
              max-height: 20.8px;
              grid-template-columns: 0.88fr 40px .8fr 72px;
              column-gap: 24px;
              text-align: left;
              font-size: 10.4px;
              color: ${colors.genoaDarkBlue};
            }
  
            tbody {
              margin-top: 4px;
              overflow-y: auto;
              display: flex;
              flex-direction: column;
              row-gap: 10px;
  
              tr {
                display: grid;
                grid-template-columns: 0.88fr 40px .8fr 72px;;
                column-gap: 24px;
                padding: 4px 6.4px;
                border-radius: 8px;
                width: calc(100% - 12.8px);
                font-size: 9.6px;
                background-color: ${colors.white};

                td {
                  align-self: center;
                }
  
                .vidas-span {
                  padding-left: 17.6px;
                }
  
                .icons-container {
                  color: ${colors.genoaDarkBlue};

                  svg {
                    font-size: 10.4px;
                  }
                }
  
                .data {
                  display: flex;
                  justify-content: center;
                }
              }
  
              ::-webkit-scrollbar {
                width: 4.8px;
                background: transparent;
              }
              ::-webkit-scrollbar-thumb {
                background: ${colors.rhBlue};
                box-shadow: none;
                border-radius: 11.2px;
              }
              ::-webkit-scrollbar-track {
                box-shadow: inset 0 0 1.6px grey;
                border-radius: 11.2px;
              }
            }
          }
  
          .show-all {
            margin-top: 8px;
            display: flex;
            justify-content: end;
            color: ${colors.genoaDarkBlue};
            font-size: 11.2px;
            font-family: 'Avenir Next GEO W05 Demi';
  
            span {
  
              :hover {
                cursor: pointer;
                text-decoration: underline;
              }
  
            }  
  
          }
        }
        
      }
    }
  
    .largeText{
      padding-top: 24px;
      color: #62A0E2;
      text-align: center;
      font-family: 'Avenir Next GEO W05 Heavy';
      font-size: 24px;
      margin-bottom: 16px;
      
    }
    .tickets {
      display: grid;
      grid-template-columns: 240px 240px 240px 240px;
      grid-gap: 32px;
      text-align: center;
      padding-top: 32px;
      max-width: 1200px;
      margin-inline: auto;
      justify-content: center;
      //place-content: center;
    }
    .box-ticket {
      max-width: 300px;
      height: 320px;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      padding: 0px 32px;
    }
    .ticket-title {
      color: #62A0E2;
      font-size: 1rem;
      margin-bottom: 32px;
    }
    .ticket-count {
      height: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      font-size: 4rem;
      color: #7D7D7D;
      border-radius: 12px;
    }
    .seeAll{
      margin-top: 16px;
      padding: 0px 1.6px;
      box-sizing: border-box; 
      display: flex;
      justify-content: center;
  
      a {
      font-family: 'Avenir Next GEO W05 Bold';
      color: #62A0E2;
      cursor: pointer;
      }
  
    }
    ${media.lessThan("1440px")`
      .box-ticket {
        padding: 0px 10px;
      }
      .tickets {
        grid-gap: 0px;
        grid-template-columns: 250px 250px 250px 250px;
      }
      .ticket-title {
        font-size: 1rem;
      }
      .ticket-count {
        font-size: 4rem;
      }
    `}
  
    ${media.lessThan("1280px")`
      .tickets {
        grid-gap: 0px;
        grid-template-columns: 200px 200px 200px 200px;
      }
    `}
  
    ${media.lessThan("900px")`
      .tickets {
        grid-gap: 0px;
        grid-template-columns: 200px 200px;
      }
    `}
  }

  @media (min-height: 650px) and (max-height: 720px) {
    display: flex;
    flex-direction: column;
    flex: 1;
  
    .main-content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding-left: 13.5px;
      padding-right: 13.5px;
      
      column-gap: 3.6vw;
      height: calc(100vh - 134.631px);
  
      .title {
        font-size: 16.2px;
        margin-top: 13.5px;
        span {
          font-family: 'Avenir Next GEO W05 Bold';
          color: ${colors.genoaGrey};
        }
      }
  
      .title-row-two {
        font-size: 16.2px;
        margin-top: 4.5px;
        span {
          font-family: 'Avenir Next GEO W05 Bold';
          color: ${colors.genoaGrey};
        }
      }
  
      .column-one {
        display: grid;
        @media (max-height: 850px) {
  
          grid-template-rows: 50% 50%;
        }
    
        @media (min-height: 850px) {
          grid-template-rows: 323.82px 1fr;
        }
        
  
        .row-one {
  
          .tickets-painel-container {
            height: calc(100% - 58.32px);
            margin-top: 18px;
            display: flex;
            // align-items: center;
  
            .tickets-painel {
              display: grid;
              grid-template-columns: 1fr 1fr 1fr 1fr;
              // border: 0.675px solid ${colors.rhBlue};
              border-radius: 22.5px;
              margin-left: -13.5px;
              background-color: ${colors.rhBlue};
              height: calc(50vh - 175.5px);
              width: calc(100% + 13.5px);
              max-height: 216px;
              // margin-bottom: 36px;
              padding: 0px 13px;
  
              .info-painel {
                display: flex;
                justify-content: center;
                align-items: center;
  
                .info {
                  display: flex;
                  flex-direction: column;

                  text-align: center;
  
                  .data-value {
                    color: ${colors.white};
                    font-family: 'Avenir Next GEO W05 Heavy';
                    font-size: 7.67vh;
                  }
  
                  .data-info-title {
                    margin-top: 4.5px;
                    color: ${colors.white};
                    font-family: 'Avenir Next GEO W05 Heavy';
  
                    @media (max-width: 1310px) {
  
                      font-size: 13px;
                    }
                    
  
                    @media (max-width: 1400px && min-width: 1310px) {
  
                      font-size: 13.5px;
                    }
                
                    @media (max-width: 1630px && min-width: 1400px) {
                      font-size: 17px;
                    }
  
                    @media (min-width: 1630px) {
                      font-size: 20px;
                    }
                  }
                }
              }
            }
          }
        }
        .row-two {
  
          table {
            margin-top: 18px;
            width: calc(100%);
            thead {
              display: grid;
              padding: 0 7.2px;
              max-height: 18.72px;
              grid-template-columns: 1fr 81px 45px 108px;
              column-gap: 36px;
              text-align: left;
              font-size: 11.7px;
              color: ${colors.genoaDarkBlue};
            }
  
            tbody {
              margin-top: 4.5px;   
              overflow-y: auto;
              display: flex;
              flex-direction: column;
              row-gap: 9px;
  
              tr {
                display: grid;
                grid-template-columns: 1fr 81px 45px 108px;
                column-gap: 36px;
                padding: 4.5px 7.2px;
                max-width: calc(100% - 14.4px);
                border-radius: 9px;
                font-size: 10.8px;
                background-color: ${colors.white};

                td {
                  align-self: center;
                }
  
                .icons-container {
                  display: flex;
                  justify-content: center;
                  column-gap: 4.5px;
                  margin-right: 4.5px;
                  color: ${colors.genoaDarkBlue};
                  
                  svg {
                    font-size: 11.7px;
                  }
                }
  
                .data{
                  // margin-left: -5.4px;
                }
              }
  
              ::-webkit-scrollbar {
                position: relative;
                display: flex;
                left: 5.4px;
                width: 5.4px;
                background: transparent;
              }
              ::-webkit-scrollbar-thumb {
                background: ${colors.rhBlue};
                box-shadow: none;
                border-radius: 12.6px;
              }
              ::-webkit-scrollbar-track {
                box-shadow: inset 0 0 1.8px grey;
                border-radius: 12.6px;
              }
            }
          }
  
          .show-all {
            margin-top: 10px;
            display: flex;
            justify-content: end;
            color: ${colors.genoaDarkBlue};
            font-size: 12.6px;
            font-family: 'Avenir Next GEO W05 Demi';
  
            span {
              
              :hover {
                cursor: pointer;
                text-decoration: underline;
              }
  
            }  
            
          }
        }
      }
  
      .column-two {
        display: grid;
  
        @media (max-height: 850px) {
  
          grid-template-rows: 50% 50%;
        }
    
        @media (min-height: 850px) {
          grid-template-rows: 359.8px 1fr;
        }
  
        .row-one {
  
          .ticket-editor-container {
            height: calc(100% - 58.32px);
  
  
            form {
              margin-top: 18px;
  
              .buttons-container {
                margin-top: 13.5px;
                margin-left: 7.2px;
                display: flex;
                align-items: flex-start;
                column-gap: 36px;
  
                button {
                  border: none;
                  background-color: transparent;
                  font-size: 14.4px;
                  color: ${colors.rhBlue} ;
                  font-family: "Avenir Next GEO W05 Demi";
  
                  :hover {
                    cursor: pointer;
                  }
                }
  
                .upload-icon-container {
                  display: flex;
                  align-items: flex-start;

                  svg {
                    font-size: 17.5px;
                    width: 17.5px;
                    height: 17.5px;
                    padding: 2.5px;
                  }

                  .upload-icon {
                    color: ${colors.rhBlue};
  
                    :hover {
                      cursor: pointer;
                    }
                  }
  
                  .upload-icon-disabled {
                    color: ${colors.genoaDisabledGrey};
                  }
                }
  
                .documents-popper {
                  z-index: 1500;
                }
  
                .documents-attached-info-container {
                  margin-top: 4.5px;
                  width: calc(22.5vw);
                  min-height: 45px;
                  height: max-content;
                  padding: 9px 9px;
                  padding-bottom: 15px;
                  border-radius: 9px;
                  
  
                  .documents-attached-title {
                    font-family: "Avenir Next GEO W05 Bold";
                    color: ${colors.rhBlue};
                    font-size: 12.6px;
                    
                  }
  
                  .documents-list-container {
                    margin-top: 9px;
                  }
  
                  .file {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    column-gap: 9px;
                    align-items: center;
                    margin-top: 4.5px;
            
                    span {
                      font-size: 11.7px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      color: ${colors.genoaGrey};
                    }
            
                    .btn-clear {
                      background: transparent;
                      border: none;
                      cursor: pointer;
                      font-size: 0.9rem;
                      display: flex;
                      align-items: center;
            
                      svg {
                   
                        font-size: 15.3px;
                        /* font-size: 1rem; */
                        color: ${colors.rhBlue};
                      }
            
                      &:hover {
                        svg {
                          color: #fb2916;
                        }
                      }
                    }
  
                  }
  
                }
              }
  
              .input-label {
        
                input {
                  display: none;
                }
        
                span {
                  display: flex;
                  font-size: 14.4px;
                  align-items: center;
                  background: transparent;
                  color: ${colors.rhBlue} ;
                  font-family: "Avenir Next GEO W05 Demi";           
                  cursor: pointer;           
                }
              }
  
              .circular-spinner {
                margin-left: 9px;
                color: ${colors.genoaDarkBlue};
              }
  
              .check-icon {
                margin-left: 9px;
                color: #4caf50;
              }
            }
  
            .rdw-editor-toolbar {
              margin-bottom: -34.2px;
              border-top-left-radius: 13.5px;
              border-top-right-radius: 13.5px;
              font-size: 10.8px;
              padding: 0px;
  
              img {
                height: 7.2px;
              }
            }
  
            .ticket-subject-input-container {
              border-top-left-radius: 13.5px;
              border-top-right-radius: 13.5px;
              margin-left: -9px;
              padding-left: 27px;
              padding-right: 27px;
              padding-top: 7.2px;
              padding-bottom: 9px;
              height: 14.4px;
              background-color: ${colors.white};
              border: 1.35px solid ${colors.rhBlue};
              display: flex;
              align-items: center;
  
              .ticket-subject-title {
                color: ${colors.genoaGrey};
                font-size: 12.6px;
                font-family: 'Avenir Next GEO W05 Demi';
              }
  
              .ticket-subject-input {
                margin-left: 9px;
                width: 100%;
                border: none;
                height: 18.9px;
                font-size: 12.6px;
                color: #969ba7;
                font-family: 'Avenir Next GEO W05 Regular';
  
                ::placeholder {
                  font-size: 12.6px;
                  font-family: 'Avenir Next GEO W05 Regular';
                  color: ${colors.genoaDisabledGrey};
                }
              }
            }
  
            .rdw-editor-main {
              padding-top: 18px;
              padding-left: 27px;
              padding-right: 27px;
              margin-left: -9px;

              border-top: none;
              border-right: 1.35px solid ${colors.rhBlue};
              border-bottom: 1.35px solid ${colors.rhBlue};
              border-left: 1.35px solid ${colors.rhBlue};
              border-image: initial;
              border-bottom-left-radius: 13.5px;
              border-bottom-right-radius: 13.5px;
              background-color: white;
              font-size: 13.5px;
              @media (max-height: 850px) {
                height: calc(50vh - 202.5px);
              }
          
              @media (min-height: 850px) {
                height: 210px;
              }
              ::-webkit-scrollbar {
                width: 0px;
                background: transparent;
              }
              ::-webkit-scrollbar-thumb {
                background: ${colors.rhBlue};
                box-shadow: none;
                border-radius: 14px;
              }
              ::-webkit-scrollbar-track {
                box-shadow: inset 0 0 2px grey;
                border-radius: 14px;
              }
            }
  
            .rdw-dropdown-wrapper {
              height: 18px;
            }
  
          }
        }
  
        .row-two {
  
          table {
            margin-top: 18px;
            width: 100%;
  
            thead {
              display: grid;
              padding: 0 7.2px;
              max-height: 18.72px;
              grid-template-columns: 1.1fr 45px 1fr 81px;
              column-gap: 27px;
              text-align: left;
              font-size: 11.7px;
              color: ${colors.genoaDarkBlue};
            }
  
            tbody {
              margin-top: 4.5px;
              overflow-y: auto;
              display: flex;
              flex-direction: column;
              row-gap: 9px;
  
              tr {
                display: grid;
                grid-template-columns: 1.1fr 45px 1fr 81px;
                column-gap: 27px;
                padding: 4.5px 7.2px;
                border-radius: 9px;
                width: calc(100% - 14.4px);
                font-size: 10.8px;
                background-color: ${colors.white};

                td {
                  align-self: center;
                }
  
                .vidas-span {
                  padding-left: 19.8px;
                }
  
                .icons-container {
                  color: ${colors.genoaDarkBlue};

                  svg {
                    font-size: 11.7px;
                  }
                }
  
                .data {
                  display: flex;
                  justify-content: center;
                }
              }
  
              ::-webkit-scrollbar {
                width: 5.4px;
                background: transparent;
              }
              ::-webkit-scrollbar-thumb {
                background: ${colors.rhBlue};
                box-shadow: none;
                border-radius: 12.6px;
              }
              ::-webkit-scrollbar-track {
                box-shadow: inset 0 0 2px grey;
                border-radius: 12.6px;
              }
            }
          }
  
          .show-all {
            margin-top: 9px;
            display: flex;
            justify-content: end;
            color: ${colors.genoaDarkBlue};
            font-size: 12.6px;
            font-family: 'Avenir Next GEO W05 Demi';
  
            span {
  
              :hover {
                cursor: pointer;
                text-decoration: underline;
              }
  
            }  
  
          }
        }
        
      }
    }
  
    .largeText{
      padding-top: 27px;
      color: #62A0E2;
      text-align: center;
      font-family: 'Avenir Next GEO W05 Heavy';
      font-size: 27px;
      margin-bottom: 18px;
      
    }
    .tickets {
      display: grid;
      grid-template-columns: 270px 270px 270px 270px;
      grid-gap: 36px;
      text-align: center;
      padding-top: 36px;
      max-width: 1365px;
      margin-inline: auto;
      justify-content: center;
      //place-content: center;
    }
    .box-ticket {
      max-width: 270px;
      height: 324px;
      border-radius: 13.5px;
      display: flex;
      flex-direction: column;
      padding: 0px 40px;
    }
    .ticket-title {
      color: #62A0E2;
      font-size: 1.125rem;
      margin-bottom: 36px;
    }
    .ticket-count {
      height: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      font-size: 4.5rem;
      color: #7D7D7D;
      border-radius: 13.5px;
    }
    .seeAll{
      margin-top: 18px;
      padding: 0px 1.8px;
      box-sizing: border-box; 
      display: flex;
      justify-content: center;
  
      a {
      font-family: 'Avenir Next GEO W05 Bold';
      color: #62A0E2;
      cursor: pointer;
      }
  
    }
    ${media.lessThan("1440px")`
      .box-ticket {
        padding: 0px 10px;
      }
      .tickets {
        grid-gap: 0px;
        grid-template-columns: 250px 250px 250px 250px;
      }
      .ticket-title {
        font-size: 1rem;
      }
      .ticket-count {
        font-size: 4rem;
      }
    `}
  
    ${media.lessThan("1280px")`
      .tickets {
        grid-gap: 0px;
        grid-template-columns: 200px 200px 200px 200px;
      }
    `}
  
    ${media.lessThan("900px")`
      .tickets {
        grid-gap: 0px;
        grid-template-columns: 200px 200px;
      }
    `}
  }

  @media (min-height: 720px) {
    display: flex;
    flex-direction: column;
    flex: 1;
  
    .main-content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding-left: 15px;
      padding-right: 15px;
      
      column-gap: 4vw;
      height: calc(100vh - 149.59px);
  
      .title {
        font-size: 18px;
        margin-top: 15px;
        span {
          font-family: 'Avenir Next GEO W05 Bold';
          color: ${colors.genoaGrey};
        }
      }
  
      .title-row-two {
        font-size: 18px;
        margin-top: 5px;
        span {
          font-family: 'Avenir Next GEO W05 Bold';
          color: ${colors.genoaGrey};
        }
      }
  
      .column-one {
        display: grid;
        @media (max-height: 850px) {
  
          grid-template-rows: 50% 50%;
        }
    
        @media (min-height: 850px) {
          grid-template-rows: 359.8px 1fr;
        }
        
  
        .row-one {
  
          .tickets-painel-container {
            height: calc(100% - 64.8px);
            margin-top: 20px;
            display: flex;
            // align-items: center;
  
            .tickets-painel {
              display: grid;
              grid-template-columns: 1fr 1fr 1fr 1fr;
              // border: 0.75px solid ${colors.rhBlue};
              border-radius: 25px;
              margin-left: -15px;
              background-color: ${colors.rhBlue};
              height: calc(50vh - 195px);
              width: calc(100% + 15px);
              max-height: 240px;
              // margin-bottom: 40px;
              padding: 0px 15px;
  
              .info-painel {
                display: flex;
                justify-content: center;
                align-items: center;
  
                .info {
                  display: flex;
                  flex-direction: column;
         
                  text-align: center;
  
                  .data-value {
                    color: ${colors.white};
                    font-family: 'Avenir Next GEO W05 Heavy';
                    font-size: 8vh;
                  }
  
                  .data-info-title {
                    margin-top: 5px;
                    color: ${colors.white};
                    font-family: 'Avenir Next GEO W05 Heavy';
  
                    @media (max-width: 1310px) {
  
                      font-size: 11px;
                    }
                    
  
                    @media (max-width: 1400px && min-width: 1310px) {
  
                      font-size: 13px;
                    }
                
                    @media (max-width: 1630px && min-width: 1400px) {
                      font-size: 16px;
                    }
  
                    @media (min-width: 1630px) {
                      font-size: 18px;
                    }
                  }
                }
              }
            }
          }
        }
        .row-two {
  
          table {
            margin-top: 20px;
            width: calc(100%);
            thead {
              display: grid;
              padding: 0 8px;
              max-height: 20.8px;
              grid-template-columns: 1fr 90px 50px 120px;
              column-gap: 40px;
              text-align: left;
              font-size: 13px;
              color: ${colors.genoaDarkBlue};
            }
  
            tbody {
              margin-top: 5px;   
              overflow-y: auto;
              display: flex;
              flex-direction: column;
              row-gap: 10px;
  
              tr {
                display: grid;
                grid-template-columns: 1fr 90px 50px 120px;
                column-gap: 40px;
                padding: 5px 8px;
                max-width: calc(100% - 16px);
                border-radius: 10px;
                font-size: 12px;
                background-color: ${colors.white};

                td {
                  align-self: center;
                }
  
                .icons-container {
                  display: flex;
                  justify-content: center;
                  column-gap: 5px;
                  margin-right: 5px;
                  color: ${colors.genoaDarkBlue};

                  svg {
                    font-size: 13px;
                  }
                }
  
                .data{
                  // margin-left: -6px;
                }
              }
  
              ::-webkit-scrollbar {
                position: relative;
                display: flex;
                left: 6px;
                width: 6px;
                background: transparent;
              }
              ::-webkit-scrollbar-thumb {
                background: ${colors.rhBlue};
                box-shadow: none;
                border-radius: 14px;
              }
              ::-webkit-scrollbar-track {
                box-shadow: inset 0 0 2px grey;
                border-radius: 14px;
              }
            }
          }
  
          .show-all {
            margin-top: 10px;
            display: flex;
            justify-content: end;
            color: ${colors.genoaDarkBlue};
            font-size: 14px;
            font-family: 'Avenir Next GEO W05 Demi';
  
            span {
              
              :hover {
                cursor: pointer;
                text-decoration: underline;
              }
  
            }  
            
          }
        }
      }
  
      .column-two {
        display: grid;
  
        @media (max-height: 850px) {
  
          grid-template-rows: 50% 50%;
        }
    
        @media (min-height: 850px) {
          grid-template-rows: 359.8px 1fr;
        }
  
        .row-one {
  
          .ticket-editor-container {
            height: calc(100% - 64.8px);
  
  
            form {
              margin-top: 20px;
  
              .buttons-container {
                margin-top: 15px;
                margin-left: 8px;
                display: flex;
                align-items: flex-start;
                column-gap: 40px;
  
                button {
                  border: none;
                  background-color: transparent;
                  font-size: 16px;
                  color: ${colors.rhBlue} ;
                  font-family: "Avenir Next GEO W05 Demi";
  
                  :hover {
                    cursor: pointer;
                  }
                }
  
                .upload-icon-container {
                  display: flex;
                  align-items: flex-start;

                  svg {
                    font-size: 19px;
                    width: 19px;
                    height: 19px;
                    padding-top: 3px;
                  }

                  .upload-icon {
                    color: ${colors.rhBlue};
  
                    :hover {
                      cursor: pointer;
                    }
                  }
  
                  .upload-icon-disabled {
                    color: ${colors.genoaDisabledGrey};
                  }
                }
  
                .documents-popper {
                  z-index: 1500;
                }
  
                .documents-attached-info-container {
                  margin-top: 5px;
                  width: calc(25vw);
                  min-height: 50px;
                  height: max-content;
                  padding: 10px 10px;
                  padding-bottom: 15px;
                  border-radius: 10px;
                  
  
                  .documents-attached-title {
                    font-family: "Avenir Next GEO W05 Bold";
                    color: ${colors.rhBlue};
                    font-size: 14px;
                    
                  }
  
                  .documents-list-container {
                    margin-top: 10px;
                  }
  
                  .file {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    column-gap: 10px;
                    align-items: center;
                    margin-top: 5px;
            
                    span {
                      font-size: 13px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      color: ${colors.genoaGrey};
                    }
            
                    .btn-clear {
                      background: transparent;
                      border: none;
                      cursor: pointer;
                      font-size: 1rem;
                      display: flex;
                      align-items: center;
            
                      svg {
                   
                        font-size: 17px;
                        /* font-size: 1rem; */
                        color: ${colors.rhBlue};
                      }
            
                      &:hover {
                        svg {
                          color: #fb2916;
                        }
                      }
                    }
  
                  }
  
                }
              }
  
              .input-label {
        
                input {
                  display: none;
                }
        
                span {
                  display: flex;
                  font-size: 16px;
                  align-items: center;
                  background: transparent;
                  color: ${colors.rhBlue} ;
                  font-family: "Avenir Next GEO W05 Demi";           
                  cursor: pointer;           
                }
              }
  
              .circular-spinner {
                margin-left: 10px;
                color: ${colors.genoaDarkBlue};
              }
  
              .check-icon {
                margin-left: 10px;
                color: #4caf50;
              }
            }
  
            .rdw-editor-toolbar {
              margin-bottom: -38px;
              border-top-left-radius: 15px;
              border-top-right-radius: 15px;
              font-size: 12px;
              padding: 0px;
  
              img {
                height: 8px;
              }
            }
  
            .ticket-subject-input-container {
              border-top-left-radius: 15px;
              border-top-right-radius: 15px;
              margin-left: -10px;
              padding-left: 30px;
              padding-right: 30px;
              padding-top: 8px;
              padding-bottom: 10px;
              height: 16px;
              background-color: ${colors.white};
              border: 1.5px solid ${colors.rhBlue};
              display: flex;
              align-items: center;
  
              .ticket-subject-title {
                color: ${colors.genoaGrey};
                font-size: 14px;
                font-family: 'Avenir Next GEO W05 Demi';
              }
  
              .ticket-subject-input {
                margin-left: 10px;
                width: 100%;
                border: none;
                height: 21px;
                font-size: 14px;
                color: #969ba7;
                font-family: 'Avenir Next GEO W05 Regular';
  
                ::placeholder {
                  font-size: 14px;
                  font-family: 'Avenir Next GEO W05 Regular';
                  color: ${colors.genoaDisabledGrey};
                }
              }
            }
  
            .rdw-editor-main {
              padding-top: 20px;
              padding-left: 30px;
              padding-right: 30px;
              margin-left: -10px;
              border-top: none;
              border-right: 1.5px solid ${colors.rhBlue};
              border-bottom: 1.5px solid ${colors.rhBlue};
              border-left: 1.5px solid ${colors.rhBlue};
              border-image: initial;
              border-bottom-left-radius: 15px;
              border-bottom-right-radius: 15px;
              background-color: white;
              font-size: 15px;
              @media (max-height: 850px) {
                height: calc(50vh - 225px);
              }
          
              @media (min-height: 850px) {
                height: 210px;
              }
              ::-webkit-scrollbar {
                width: 0px;
                background: transparent;
              }
              ::-webkit-scrollbar-thumb {
                background: ${colors.rhBlue};
                box-shadow: none;
                border-radius: 14px;
              }
              ::-webkit-scrollbar-track {
                box-shadow: inset 0 0 2px grey;
                border-radius: 14px;
              }
            }
  
            .rdw-dropdown-wrapper {
              height: 20px;
            }
  
          }
        }
  
        .row-two {
  
          table {
            margin-top: 20px;
            width: 100%;
  
            thead {
              display: grid;
              padding: 0 8px;
              max-height: 20.8px;
              grid-template-columns: 1.1fr 50px 1fr 90px;
              column-gap: 30px;
              text-align: left;
              font-size: 13px;
              color: ${colors.genoaDarkBlue};
            }
  
            tbody {
              margin-top: 5px;
              overflow-y: auto;
              display: flex;
              flex-direction: column;
              row-gap: 10px;
  
              tr {
                display: grid;
                grid-template-columns: 1.1fr 50px 1fr 90px;
                column-gap: 30px;
                padding: 5px 8px;
                border-radius: 10px;
                width: calc(100% - 16px);
                font-size: 12px;
                background-color: ${colors.white};

                td {
                  align-self: center;
                }
  
                .vidas-span {
                  padding-left: 22px;
                }
  
                .icons-container {
                  color: ${colors.genoaDarkBlue};

                  svg {
                    font-size: 13px;
                  }
                }
  
                .data {
                  display: flex;
                  justify-content: center;
                }
              }
  
              ::-webkit-scrollbar {
                width: 6px;
                background: transparent;
              }
              ::-webkit-scrollbar-thumb {
                background: ${colors.rhBlue};
                box-shadow: none;
                border-radius: 14px;
              }
              ::-webkit-scrollbar-track {
                box-shadow: inset 0 0 2px grey;
                border-radius: 14px;
              }
            }
          }
  
          .show-all {
            margin-top: 10px;
            display: flex;
            justify-content: end;
            color: ${colors.genoaDarkBlue};
            font-size: 14px;
            font-family: 'Avenir Next GEO W05 Demi';
  
            span {
  
              :hover {
                cursor: pointer;
                text-decoration: underline;
              }
  
            }  
  
          }
        }
        
      }
    }
  
    .largeText{
        padding-top: 30px;
      color: #62A0E2;
      text-align: center;
      font-family: 'Avenir Next GEO W05 Heavy';
      font-size: 30px;
      margin-bottom: 20px;
      
    }
    .tickets {
      display: grid;
      grid-template-columns: 300px 300px 300px 300px;
      grid-gap: 40px;
      text-align: center;
      padding-top: 40px;
      max-width: 1500px;
      margin-inline: auto;
      justify-content: center;
      //place-content: center;
    }
    .box-ticket {
      max-width: 300px;
      height: 400px;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      padding: 0px 40px;
    }
    .ticket-title {
      color: #62A0E2;
      font-size: 1.25rem;
      margin-bottom: 40px;
    }
    .ticket-count {
      height: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      font-size: 5rem;
      color: #7D7D7D;
      border-radius: 15px;
    }
    .seeAll{
      margin-top: 20px;
      padding: 0px 2px;
      box-sizing: border-box; 
      display: flex;
      justify-content: center;
  
      a {
      font-family: 'Avenir Next GEO W05 Bold';
      color: #62A0E2;
      cursor: pointer;
      }
  
    }
    ${media.lessThan("1440px")`
      .box-ticket {
        padding: 0px 10px;
      }
      .tickets {
        grid-gap: 0px;
        grid-template-columns: 250px 250px 250px 250px;
      }
      .ticket-title {
        font-size: 1rem;
      }
      .ticket-count {
        font-size: 4rem;
      }
    `}
  
    ${media.lessThan("1280px")`
      .tickets {
        grid-gap: 0px;
        grid-template-columns: 200px 200px 200px 200px;
      }
    `}
  
    ${media.lessThan("900px")`
      .tickets {
        grid-gap: 0px;
        grid-template-columns: 200px 200px;
      }
    `}
  }
`