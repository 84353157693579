import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { Wraper } from './styles'
import LogoCoinsure from 'assets/imgs/logo-coinsure.png'
import api from 'services/api.js'

import { makeStyles } from '@material-ui/core/styles';

import { useParams } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  header: {
    boxShadow: 'none',
    backgroundColor: '#f0e7ff'
  },
  tabs: {
    fontFamily: 'Avenir Next GEO W05 Demi'
  }
}));

export default function ConfirmationAccountRh(props) {

  const classes = useStyles();
  const history = useHistory()
  const [messageResp , setMessageResp] = useState()

  const {id, token} = useParams()

  const postConfirm = () => {
      api.post(`/confirma-rh/${id}/${token}`)
      .then(resp => {
          setMessageResp(resp.data)
          history.push('/login')
      }).catch(err => {
        // console.log(err.response.data)
        setMessageResp(err.response.data)
      })
  }

  React.useEffect(() => {
      postConfirm()
  }, [])
  
  return (
    <Wraper>
      <header>
        <Container maxWidth="xl">
          <div className="top-area">
            <a className="nav-logo" href="/">
              <img src={LogoCoinsure} width="200" alt="" loading="lazy" />
            </a>
          </div>
        </Container>

      </header>
      <main>
        <Container>
          <Grid container justify="center" spacing={3}>
            <Grid item xs={12} md={6}>
              <Card>
                <div className={classes.root}>
                    <CardContent>
                        <div className="title"><h1>{messageResp}</h1></div>
                    </CardContent>
                </div>
              </Card>
            </Grid>

          </Grid>
        </Container>



      </main>

    </Wraper>
  )
}
