import React, { useState, useEffect } from 'react';
import {
  FaCar,
  FaFileMedicalAlt,
  FaNotesMedical
} from 'react-icons/fa';
import {
  RiCustomerService2Fill,
  RiRefund2Line
} from 'react-icons/ri';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import {
  CardContainer,
  InfoWrapper,
  MoreInfoWrapper,
  SelectPopper
} from './styles';
import { BsJournalMedical } from 'react-icons/bs';
import { Box, ClickAwayListener, Popper } from '@material-ui/core';
import Fade from '@mui/material/Fade';
import { Checkbox } from '@mui/material';
import services from 'apps/broker/services';

const ConciergeTicketCard = ({
  ticketInfo,
  setOpenMessageDrawer,
  setConciergeTicketSelected,
  estipulantes,
  getConciergeTickets,
  isEditMode,
  isChecked,
  setDispatchInfo,
  dispatchInfo
}) => {
  const [showCategoriaInfo, setShowCategoriaInfo] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMoreInfo, setOpenMoreInfo] = useState(false);
  const [anchorElMoreInfo, setAnchorElMoreInfo] = useState(null);
  const [openSelectPopper, setOpenSelectPopper] = useState(false);
  const [selectPopperAnchorEl, setSelectPopperAnchorEl] = useState(null);
  const [width, setWidth] = useState(undefined);
  const [popperSelectWidth, setPopperSelectWidth] = useState(0);
  const [isSelected, setIsSelected] = useState(false);

  const createdDate = new Date(ticketInfo?.created_at);

  const createdDateSring =
    ('0' + createdDate.getDate()).slice(-2) +
    '/' +
    ('0' + (createdDate.getMonth() + 1)).slice(-2) +
    '/' +
    createdDate.getFullYear() +
    ' ' +
    ('0' + createdDate.getHours()).slice(-2) +
    ':' +
    ('0' + createdDate.getMinutes()).slice(-2);

  const closedDate = new Date(ticketInfo?.closed_at);

  const closedDateSring =
    ('0' + closedDate.getDate()).slice(-2) +
    '/' +
    ('0' + (closedDate.getMonth() + 1)).slice(-2) +
    '/' +
    closedDate.getFullYear() +
    ' ' +
    ('0' + closedDate.getHours()).slice(-2) +
    ':' +
    ('0' + closedDate.getMinutes()).slice(-2);

  const refundIcon = () => {
    return (
      <RiRefund2Line
        size={18}
        onMouseEnter={(e) => {
          setShowCategoriaInfo(true);
          setAnchorEl(e.currentTarget);
        }}
        onMouseLeave={() => {
          setShowCategoriaInfo(false);
          setAnchorEl(null);
        }}
      />
    );
  };

  const surgeryAndHospitalizationIcon = () => {
    return (
      <FaNotesMedical
        size={18}
        onMouseEnter={(e) => {
          setShowCategoriaInfo(true);
          setAnchorEl(e.currentTarget);
        }}
        onMouseLeave={() => {
          setShowCategoriaInfo(false);
          setAnchorEl(null);
        }}
      />
    );
  };

  const laboratorialExamIcon = () => {
    return (
      <FaFileMedicalAlt
        size={18}
        onMouseEnter={(e) => {
          setShowCategoriaInfo(true);
          setAnchorEl(e.currentTarget);
        }}
        onMouseLeave={() => {
          setShowCategoriaInfo(false);
          setAnchorEl(null);
        }}
      />
    );
  };

  const hospitalAndLabAvailableIcon = () => {
    return (
      <BsJournalMedical
        size={18}
        onMouseEnter={(e) => {
          setShowCategoriaInfo(true);
          setAnchorEl(e.currentTarget);
        }}
        onMouseLeave={() => {
          setShowCategoriaInfo(false);
          setAnchorEl(null);
        }}
      />
    );
  };

  const carServiceIcon = () => {
    return (
      <FaCar
        size={18}
        onMouseEnter={(e) => {
          setShowCategoriaInfo(true);
          setAnchorEl(e.currentTarget);
        }}
        onMouseLeave={() => {
          setShowCategoriaInfo(false);
          setAnchorEl(null);
        }}
      />
    );
  };

  const otherServices = () => {
    return (
      <RiCustomerService2Fill
        size={16}
        onMouseEnter={(e) => {
          setShowCategoriaInfo(true);
          setAnchorEl(e.currentTarget);
        }}
        onMouseLeave={() => {
          setShowCategoriaInfo(false);
          setAnchorEl(null);
        }}
      />
    );
  };

  const renderCategoriaIcon = (categoria_id) => {
    switch (Number(categoria_id)) {
      case 1:
        return refundIcon();

      case 2:
        return surgeryAndHospitalizationIcon();

      case 3:
        return laboratorialExamIcon();

      case 4:
        return hospitalAndLabAvailableIcon();

      case 5:
        return carServiceIcon();

      default:
        return otherServices();
    }
  };

  const renderCategoriaInfo = (categoria_id) => {
    switch (Number(categoria_id)) {
      case 1:
        return 'Reembolsos';

      case 2:
        return 'Cirurgias e internações';

      case 3:
        return 'Exames laboratoriais e consultas médicas';

      case 4:
        return 'Hospitais e laboratórios disponíveis no plano';

      case 5:
        return 'Seguros pessoais';

      default:
        return 'Outros assuntos';
    }
  };

  const renderMoreInfoMenu = () => {
    return (
      <>
        <div className="more_info_menu__option">Editar</div>
        <div
          className="more_info_menu__option"
          onClick={() => {
            setOpenMessageDrawer(true);
            setConciergeTicketSelected(ticketInfo);
            setAnchorElMoreInfo(null);
            setOpenMoreInfo(false);
          }}
        >
          Mensagens
        </div>
      </>
    );
  };

  const openSelectPopperClickHandler = (e) => {
    setOpenSelectPopper(true);
    setSelectPopperAnchorEl(e.currentTarget);
  };

  const isSelectedHandler = () => {
    setIsSelected((prevState) => !prevState);
  };

  const updateContactInfo = async () => {
    const body = {
      estipulante: dispatchInfo?.item,
      ticketInfo
    };

    await services.concierge.updateContactInformation(body);

    getConciergeTickets();
    setOpenSelectPopper(false);
    setSelectPopperAnchorEl(null);
  };

  const optionClickHandler = async (item) => {
    if (isSelected) {
      setDispatchInfo({ state: true, item });
    }

    const body = {
      estipulante: item,
      ticketInfo
    };

    await services.concierge.updateContactInformation(body);

    getConciergeTickets();
    setOpenSelectPopper(false);
    setSelectPopperAnchorEl(null);
    setDispatchInfo({ state: false, item: undefined });
  };

  const renderTelefoneContato = (ticketInfo) => {
    const { numero_telefone } = ticketInfo;

    const semRegiaoBrasil = numero_telefone.slice(2);
    const dddRegiao = semRegiaoBrasil.slice(0, 2);
    const numeroTelefone = semRegiaoBrasil.slice(2);

    let numeroTelefoneFormatado;

    if (numeroTelefone.length > 8) {
      const primeiraParte = numeroTelefone.slice(0, 5);
      const segundaParte = numeroTelefone.slice(5, 9);
      numeroTelefoneFormatado = `${primeiraParte} - ${segundaParte}`;
    } else {
      const primeiraParte = numeroTelefone.slice(0, 4);
      const segundaParte = numeroTelefone.slice(4, 8);
      numeroTelefoneFormatado = `${primeiraParte} - ${segundaParte}`;
    }

    return `(${dddRegiao}) ${numeroTelefoneFormatado}`;
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const columnEmpresa = document.querySelector('.empresa_column');
    if (columnEmpresa) {
      setPopperSelectWidth(columnEmpresa.offsetWidth);
    }
  }, [width]);

  useEffect(() => {
    setIsSelected(isChecked);
  }, [isChecked]);

  useEffect(() => {
    if (dispatchInfo?.state && isSelected) {
      updateContactInfo();
    }
  }, [dispatchInfo?.state]);

  return (
    <CardContainer>
      <td>
        {isEditMode ? (
          <Checkbox
            className="checkbox"
            checked={isSelected}
            onChange={isSelectedHandler}
          />
        ) : (
          <div></div>
        )}
      </td>
      <td
        className={`column_status ${
          ticketInfo?.finalizado ? 'finalizado' : 'aberto'
        }`}
      >
        {ticketInfo?.finalizado ? 'Finalizado' : 'Aberto'}
      </td>
      <td>{ticketInfo?.protocolo}</td>
      <td>
        {renderCategoriaIcon(ticketInfo?.categoria_id)}
        <Popper
          open={showCategoriaInfo}
          anchorEl={anchorEl}
          transition
          placement="right-start"
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Box
                sx={{
                  border: 1,
                  p: 1,
                  bgcolor: 'background.paper',
                  width: 170,
                  padding: '10px 8px',
                  borderTopRightRadius: 10,
                  borderBottomRightRadius: 10,
                  borderBottomLeftRadius: 10,
                  boxShadow:
                    '0 3px 6px 0 rgba(0,0,0,0.16), 0 3px 6px 0 rgba(0,0,0,0.23)',
                  marginLeft: 5,
                  marginTop: 3
                }}
              >
                <InfoWrapper>
                  <span>{renderCategoriaInfo(ticketInfo?.categoria_id)}</span>
                </InfoWrapper>
              </Box>
            </Fade>
          )}
        </Popper>
      </td>
      <td>{renderTelefoneContato(ticketInfo)}</td>
      <td>{ticketInfo?.nome_contato?.slice(0, 35)}</td>
      <td className="empresa_column">
        <span
          className={`empresa_name ${openSelectPopper ? 'open' : 'closed'}`}
          onClick={openSelectPopperClickHandler}
        >
          {ticketInfo?.empresa}
        </span>
        <Popper
          open={openSelectPopper}
          anchorEl={selectPopperAnchorEl}
          transition
          placement="bottom"
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={200}>
              <Box
                sx={{
                  border: 1,
                  p: 1,
                  bgcolor: 'background.paper',
                  width: popperSelectWidth + 120,
                  minWidth: 360,
                  padding: '8px 0',
                  borderBottomRightRadius: 4,
                  borderBottomLeftRadius: 4,
                  boxShadow:
                    '0 4px 15px rgba(0,0,0,0.19), 0 8px 12px rgba(0,0,0,0.23)',
                  marginRight: -4,
                  marginTop: 7
                }}
              >
                <SelectPopper>
                  <ClickAwayListener
                    onClickAway={() => {
                      setOpenSelectPopper(false);
                      setSelectPopperAnchorEl(null);
                    }}
                  >
                    <SelectPopper>
                      <div className="select_options_container">
                        {estipulantes.map((item) => {
                          if (item?.razao_social) {
                            return (
                              <span
                                className="select_popper_option"
                                onClick={() => {
                                  optionClickHandler(item);
                                }}
                              >
                                {item.razao_social}
                              </span>
                            );
                          }
                          return '';
                        })}
                      </div>
                    </SelectPopper>
                  </ClickAwayListener>
                </SelectPopper>
              </Box>
            </Fade>
          )}
        </Popper>
      </td>
      <td>{ticketInfo?.nome_atendente}</td>
      <td>{ticketInfo?.created_at ? createdDateSring : ''}</td>
      <td>{ticketInfo?.closed_at ? closedDateSring : ''}</td>
      <td>{ticketInfo?.nota_atendimento}</td>
      <td>
        <BiDotsVerticalRounded
          size={20}
          className="more_info_icon"
          onClick={(e) => {
            setOpenMoreInfo(true);
            setAnchorElMoreInfo(e.currentTarget);
          }}
        />
        <Popper
          open={openMoreInfo}
          anchorEl={anchorElMoreInfo}
          transition
          placement="left-start"
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Box
                sx={{
                  border: 1,
                  p: 1,
                  bgcolor: 'background.paper',
                  width: 150,
                  padding: '8px 0',
                  borderTopLeftRadius: 8,
                  borderBottomRightRadius: 8,
                  borderBottomLeftRadius: 8,
                  boxShadow:
                    '0 4px 15px rgba(0,0,0,0.19), 0 8px 12px rgba(0,0,0,0.23)',
                  marginRight: -4,
                  marginTop: 7
                }}
              >
                <MoreInfoWrapper>
                  <ClickAwayListener
                    onClickAway={() => {
                      setOpenMoreInfo(false);
                      setAnchorElMoreInfo(null);
                    }}
                  >
                    <span>{renderMoreInfoMenu(ticketInfo?.categoria_id)}</span>
                  </ClickAwayListener>
                </MoreInfoWrapper>
              </Box>
            </Fade>
          )}
        </Popper>
      </td>
    </CardContainer>
  );
};

export default ConciergeTicketCard;
