import React from "react";
import InputStandard from "components/Input/InputStandard";
import { AbaDadosVinculoWrapper } from "./styles-abaDadosImc.js";

const AbaDadosImc = (
  { values,
    setInputValue,
    setSelectValue,
    camposInclusao
  }) => {

  return (
    <AbaDadosVinculoWrapper>
      <div className="aba-container">
        <div className="aba-title-container">
          <span>5. Dados de IMC</span>
        </div>
        <div className="aba-input-container">
          <div className="aba-input-row-imc">
            {
              camposInclusao['peso'] ? (
                <InputStandard
                  value={values['peso']}
                  setValue={setInputValue}
                  label={"Peso (kg)"}
                  name="peso"
                />
              ) : ""
            }

            {
              camposInclusao['altura'] ? (
                <InputStandard
                  value={values['altura']}
                  setValue={setInputValue}
                  label={"Altura (m)"}
                  name="altura"
                />
              ) : ""
            }

          </div>
        </div>
      </div>
    </AbaDadosVinculoWrapper>
  )
}

export default AbaDadosImc;
