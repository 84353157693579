import { useState, useEffect } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { Wrapper, Header, Body, Preview } from './drawer-styles';
import InputMask from 'react-input-mask';
import { BiHomeSmile, BiCar, BiHeart, BiTab, BiMinus } from 'react-icons/bi';
import { MdAirplanemodeActive } from 'react-icons/md';
import { FaWhatsapp } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import services from 'apps/broker/services';
import { useUser } from 'contexts/user';

const GreenCheckbox = withStyles({
  root: {
    color: '#6e3ec1',
    '&$checked': {
      color: '#6e3ec1'
    }
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />);

const DrawerProdutos = (props) => {
  const { user } = useUser();

  const [seguroAuto, setSeguroAuto] = useState(false);
  const [seguroResidencial, setSeguroResidencial] = useState(false);
  const [seguroViagem, setSeguroViagem] = useState(false);
  const [seguroVida, setSeguroVida] = useState(false);
  const [seguroTablet, setSeguroTablet] = useState(false);
  const [outros, setOutros] = useState(false);
  const [resp, setResp] = useState();

  const [emailContato, setEmailContato] = useState();
  const [whatsappContato, setWhatsappContato] = useState();

  const setStates = {
    seguroAuto: (habilitado) => setSeguroAuto(habilitado),
    seguroResidencial: (habilitado) => setSeguroResidencial(habilitado),
    seguroViagem: (habilitado) => setSeguroViagem(habilitado),
    seguroVida: (habilitado) => setSeguroVida(habilitado),
    seguroTablet: (habilitado) => setSeguroTablet(habilitado),
    outros: (habilitado) => setOutros(habilitado)
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const body = {
      produtos: {
        seguroAuto: seguroAuto,
        seguroResidencial: seguroResidencial,
        seguroViagem: seguroViagem,
        seguroVida: seguroVida,
        seguroTablet: seguroTablet,
        outros: outros
      },
      contato: {
        emailContato: emailContato,
        whatsappContato: whatsappContato
      }
    };

    const response = await services.brokerProducts.createBrokerProducts(body);
    if (response.status === 200) {
      setResp('Suas informações foram atualizadas');
      setTimeout(() => {
        setResp();
      }, 5000);
      getData();
    }
  };

  const getData = async () => {
    const response = await services.brokerProducts.getAll();
    if (response.status === 200) {
      const dataResp = response.data;
      if (dataResp.produto.length > 0) {
        dataResp.produto.forEach((element) => {
          setStates[element.produto](element.habilitado);
        });
      }
      setEmailContato(response.data.contato.email);
      setWhatsappContato(response.data.contato.whatsapp);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const preview = () => {
    return (
      <>
        <Preview>
          <div className="headers">
            <h2>Você sabia...</h2>
            <img src={user.logoCorretora} alt="logo corretora" />
          </div>
          <div className="text">
            <p className="text-intro">
              A {user.nome} é a corretora que atende a {'<Estipulante>'} no
              seguro saúde. Ela trabalha com outros tipos de cobertura e caso
              precise é só entrar em contato conosco.
            </p>
            <ul className="listSeguros">
              {seguroAuto === 1 ? (
                <li>
                  <BiCar />
                  Seguro Auto
                </li>
              ) : null}
              {seguroResidencial === 1 ? (
                <li>
                  <BiHomeSmile />
                  Seguro Residencial
                </li>
              ) : null}
              {seguroViagem === 1 ? (
                <li>
                  <MdAirplanemodeActive />
                  Seguro Viagem
                </li>
              ) : null}
              {seguroVida === 1 ? (
                <li>
                  <BiHeart />
                  Seguro de Vida
                </li>
              ) : null}
              {seguroTablet === 1 ? (
                <li>
                  <BiTab />
                  Seguro de Tablets
                </li>
              ) : null}
              {outros === 1 ? (
                <li>
                  <BiMinus />
                  Outros
                </li>
              ) : null}
            </ul>

            <div className="contatos">
              {whatsappContato ? (
                <div>
                  {' '}
                  <FaWhatsapp /> <p>{whatsappContato} </p>
                </div>
              ) : null}
              {emailContato ? (
                <div>
                  {' '}
                  <HiOutlineMail /> <p>{emailContato}</p>{' '}
                </div>
              ) : null}
            </div>
          </div>
        </Preview>
      </>
    );
  };

  return (
    <Wrapper>
      <Header>
        <div className="back">
          <span onClick={() => props.onClose(false)} className="icon-back">
            <ArrowBackIcon fontSize="inherit" />
          </span>
        </div>
        <div className="headerUser">
          <div className="nameUser">
            <div>
              <div className="user-company">
                <h2>
                  Selecione os seguros que sua corretora também comercializa!
                </h2>
              </div>
              <span className="tipo">
                Seu contato e seguros oferecidos irão aparecer no formulário de
                inclusão de beneficiario
              </span>
            </div>
          </div>
        </div>
      </Header>
      <Body>
        <div className="form-infos">
          Selecione os seguros que a sua corretora oferece suporte
          <form onSubmit={(e) => onSubmit(e)}>
            <div className="checkbox">
              <label>
                <GreenCheckbox
                  checked={seguroAuto}
                  onChange={(e) => setSeguroAuto(e.target.checked)}
                  name="Seguro Auto"
                />
                <span>Seguro Auto</span>
              </label>

              <label>
                <GreenCheckbox
                  checked={seguroResidencial}
                  onChange={(e) => setSeguroResidencial(e.target.checked)}
                  name="Seguro Auto"
                />
                <span>Seguro Residencial</span>
              </label>

              <label>
                <GreenCheckbox
                  checked={seguroViagem}
                  onChange={(e) => setSeguroViagem(e.target.checked)}
                  name="Seguro Auto"
                />
                <span>Seguro Viagem</span>
              </label>

              <label>
                <GreenCheckbox
                  checked={seguroVida}
                  onChange={(e) => setSeguroVida(e.target.checked)}
                  name="Seguro Auto"
                />
                <span>Seguro de Vida</span>
              </label>

              <label>
                <GreenCheckbox
                  checked={seguroTablet}
                  onChange={(e) => setSeguroTablet(e.target.checked)}
                  name="Seguro Auto"
                />
                <span>Seguro de Tablets</span>
              </label>

              <label>
                <GreenCheckbox
                  checked={outros}
                  onChange={(e) => setOutros(e.target.checked)}
                  name="Seguro Auto"
                />
                <span>Outros</span>
              </label>
            </div>

            <div className="input-contato">
              <label className="contato">
                <span>Email para contato</span>
                <input
                  type="email"
                  value={emailContato}
                  onChange={(e) => setEmailContato(e.target.value)}
                />
              </label>

              <label className="contato">
                <span>Whatsapp para contato</span>
                <InputMask
                  mask="99 99999-9999"
                  value={whatsappContato}
                  onChange={(e) => setWhatsappContato(e.target.value)}
                />
              </label>
            </div>

            <button type="submit" className="btn">
              Salvar
            </button>
            {resp && <div className="text-resp">{resp}</div>}
          </form>
          <p className="text-tip">
            Sua apresentação ficará desse jeito para seus clientes
          </p>
          {preview()}
        </div>
      </Body>
    </Wrapper>
  );
};

export default DrawerProdutos;
