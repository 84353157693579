import styled from 'styled-components';
import colors from 'apps/broker/styles/colors';

export const Div = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  margin-bottom: 5px;

  button {
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    background-color: ${colors.genoaBlue};
    color: white;
    padding: 6px 10px;
    font-family: Avenir Next Geo W05 Demi;
    text-transform: uppercase;
    font-size: 0.85em;
    border-radius: 5px;
    top: 0;
    right: 0;
  }
`;
