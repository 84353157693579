import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import './styles.scss';

const Select = ({
  label,
  options = [],
  isRequired,
  name,
  setIsFixed,
  ...props
}) => {
  const {
    control,
    formState: { errors, touchedFields }
  } = useFormContext();
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFixed(true);
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const isInvalid = touchedFields[name] && errors[name];

  return (
    <div
      className={`select-field__group ${
        isInvalid ? 'field__group--invalid' : ''
      }`}
    >
      <label htmlFor={props.id || name} className={`field__label`}>
        {label}
      </label>
      <Controller
        name={name}
        control={control}
        rules={{
          required: { value: isRequired, message: 'Campo obrigatório' }
        }}
        render={({ field }) => (
          <select
            {...field}
            {...props}
            id={props.id || name}
            defaultValue="Selecione"
            onFocus={handleFocus}
            onBlur={(e) => {
              field.onBlur();
              handleBlur();
            }}
            className={`select-field__input ${
              isInvalid ? 'select-field__input--invalid' : ''
            }`}
          >
            <option value="Selecione" disabled>
              Selecione...
            </option>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        )}
      />
      {isInvalid && (
        <div className="select-field__input--error-message">
          {errors[name]?.message}
        </div>
      )}
    </div>
  );
};

export default Select;
