import React, { useState } from 'react';
import { SwipeableDrawer } from '@material-ui/core';
import DrawerBeneficiariesContent from './DrawerBeneficiariesContent';
import { makeStyles } from '@material-ui/core/styles';
import { TbEyeglass } from 'react-icons/tb';
import services from 'apps/broker/services';

const useStyles = makeStyles({
  BackdropProps: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  content: {
    maxWidth: 600
  },
  fullList: {
    width: 'auto'
  },
  itemMenu: {
    padding: '10px',
    fontFamily: 'Avenir Next GEO W05 Demi'
  },
  label: {
    padding: '10px',
    fontFamily: 'Avenir Next GEO W05 Demi'
  }
});

const BeneficiaryDetails = ({ beneficiario }) => {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [beneficiarioSelected, setBeneficiarioSelected] = useState([]);
  const [groupFamily, setGroupFamily] = useState([]);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpenDrawer(open);
  };

  const content = (beneficiarioSelected, groupFamily) => (
    <div role="presentation" onKeyDown={toggleDrawer(false)}>
      <DrawerBeneficiariesContent
        beneficiario={beneficiarioSelected}
        groupFamily={groupFamily}
      />
    </div>
  );

  const handleData = async (beneficiario) => {
    setBeneficiarioSelected(beneficiario);
    toggleDrawer(true)();

    const response =
      await services.beneficiaries.getBeneficiaryFamilyGroupByBeneficiaryId(
        beneficiario.beneficiario_id
      );

    const group = response?.data;

    if (group) {
      setGroupFamily(group);
    }
  };

  return (
    <>
      <button className="btn detalhes" onClick={() => handleData(beneficiario)}>
        <TbEyeglass />
      </button>
      <SwipeableDrawer
        ModalProps={{
          BackdropProps: {
            classes: {
              root: classes.BackdropProps
            }
          }
        }}
        anchor="right"
        open={openDrawer}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {content(beneficiarioSelected, groupFamily)}
      </SwipeableDrawer>
    </>
  );
};

export default BeneficiaryDetails;
