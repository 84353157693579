import React, { useEffect, useState } from 'react';
import Button from 'components/DS/Button';
import Input from 'apps/lp/components/Form/Input';
import Select from 'apps/lp/components/Form/Select';
import InputUploadFile from 'components/DS/InputUploadFile';
import relatives from 'utils/arrays/relatives';
import { useFieldArray, useFormContext } from 'react-hook-form';
import './styles.scss';

const createObjectURL = (file) => {
  if (typeof file === 'string') {
    return file;
  }

  if (Object.prototype.toString.call(file) !== '[object File]') {
    return '';
  }

  return URL.createObjectURL(file);
};

const FormFourthStep = ({
  prevStep,
  nextStep,
  isHrUser,
  isFixed,
  setIsFixed,
  successMessage
}) => {
  const { control, formState, setValue, watch, clearErrors } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'dependentes'
  });
  const dependentes = watch('dependentes') || [{}];
  const isFormValid = formState.isValid;
  const hasValue = dependentes.length > 0 ? true : false;

  const [hasUploadAllFiles, setHasUploadAllFiles] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const updateHasUploadAllFiles = () => {
    const allFilesUploaded = fields.every(
      ({ files }) =>
        files?.documento_scan_rg &&
        files?.documento_scan_cpf &&
        files?.documento_comprovacao_vinculo
    );
    setHasUploadAllFiles(allFilesUploaded);
  };

  const handleDeleteForm = (index) => {
    remove(index);
    clearErrors([
      `dependentes[${index}].nome`,
      `dependentes[${index}].data_nascimento`,
      `dependentes[${index}].rg`,
      `dependentes[${index}].orgao_emissor`,
      `dependentes[${index}].data_expedicao_rg`,
      `dependentes[${index}].cpf`,
      `dependentes[${index}].sexo`,
      `dependentes[${index}].grau_parentesco`
    ]);
    updateHasUploadAllFiles();
  };

  // const handleDeleteDependentForm = (indexNumber) => {
  //   setValue(
  //     'dependentes',
  //     dependentes.filter((_, index) => index !== indexNumber)
  //   );

  //   updateHasUploadAllFiles();
  // };

  const handleInputDependent = (index, key, value) => {
    const updatedDependentes = [...dependentes];
    updatedDependentes[index].files[key] = value;
    setValue('dependentes', updatedDependentes);
    updateHasUploadAllFiles();
  };

  useEffect(() => {
    updateHasUploadAllFiles();
  }, [fields]);

  useEffect(() => {
    if (isFormValid && hasUploadAllFiles) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [isFormValid, hasUploadAllFiles]);

  return (
    <div className="fourth-step">
      <div className="fourth-step__form">
        <div className="fourth-step__container-title">
          <span className="fourth-step__title">
            Adicione os seus dependentes
          </span>
        </div>

        {fields?.map((field, i) => (
          <div
            className={`fourth-step__form fourth-step__form--${
              i > 0 && 'index-form'
            }`}
            key={field.id}
          >
            <p className="fourth-step__p">Dependente {i + 1}</p>
            {i > 0 && (
              <div className="fourth-step__button-remove-dependent">
                {isHrUser ? null : (
                  <Button
                    type="button"
                    variant="remove"
                    onClick={() => handleDeleteForm(i)}
                  >
                    Excluir dependente
                  </Button>
                )}
              </div>
            )}
            <div>
              <Input
                name={`dependentes[${i}].nome`}
                validation="name"
                isRequired
                label={'Nome Completo do Dependente'}
                placeholder={'Nome do primeiro dependente'}
                setIsFixed={setIsFixed}
              />
              <span className="fourth-step-documents__message-error">
                {formState.errors?.dependentes?.[i]?.nome &&
                  formState.errors.dependentes[i].nome.message}
              </span>
            </div>
            <div className="fourth-step-borth-date">
              <Input
                isRequired
                name={`dependentes[${i}].data_nascimento`}
                label={'Data de Nascimento'}
                placeholder="DD/MM/AAAA"
                mask="date"
                validation="date"
                setIsFixed={setIsFixed}
              />
              <span className="fourth-step-documents__message-error">
                {formState.errors?.dependentes?.[i]?.data_nascimento &&
                  formState.errors.dependentes[i].data_nascimento.message}
              </span>
            </div>
            <div>
              <Input
                isRequired
                name={`dependentes[${i}].rg`}
                label={'RG'}
                setIsFixed={setIsFixed}
              />
              <span className="fourth-step-documents__message-error">
                {formState.errors?.dependentes?.[i]?.rg &&
                  formState.errors.dependentes[i].rg.message}
              </span>
            </div>
            <div className="fourth-step-rg-information">
              <div className="fourth-step-rg-information__text-container">
                <Input
                  isRequired
                  name={`dependentes[${i}].orgao_emissor`}
                  label={'Órgão Emissor'}
                  setIsFixed={setIsFixed}
                />
                <span className="fourth-step-documents__message-error">
                  {formState.errors?.dependentes?.[i]?.orgao_emissor &&
                    formState.errors.dependentes[i].orgao_emissor.message}
                </span>
              </div>
              <div className="fourth-step-rg-information__date-container">
                <Input
                  isRequired
                  name={`dependentes[${i}].data_expedicao_rg`}
                  label={'Data de Expedição'}
                  placeholder="DD/MM/AAAA"
                  mask="date"
                  validation="date"
                  setIsFixed={setIsFixed}
                />
                <span className="fourth-step-documents__message-error">
                  {formState.errors?.dependentes?.[i]?.data_expedicao_rg &&
                    formState.errors.dependentes[i].data_expedicao_rg.message}
                </span>
              </div>
            </div>
            <div>
              <Input
                isRequired
                name={`dependentes[${i}].cpf`}
                label={'CPF'}
                mask="cpf"
                validation="cpf"
                setIsFixed={setIsFixed}
              />
              <span className="fourth-step-documents__message-error">
                {formState.errors?.dependentes?.[i]?.cpf &&
                  formState.errors.dependentes[i].cpf.message}
              </span>
            </div>
            <div>
              <Select
                isRequired
                name={`dependentes[${i}].sexo`}
                label={'Gênero'}
                options={[
                  { label: 'Masculino', value: 'Masculino' },
                  { label: 'Feminino', value: 'Feminino' },
                  { label: 'Outros', value: 'Outros' }
                ]}
                setIsFixed={setIsFixed}
              />
              <span className="fourth-step-documents__message-error">
                {formState.errors?.dependentes?.[i]?.sexo &&
                  formState.errors.dependentes[i].sexo.message}
              </span>
            </div>
            <div>
              <Select
                isRequired
                name={`dependentes[${i}].grau_parentesco`}
                label={'Grau de Parentesco'}
                options={relatives.map((item) => ({
                  label: item.option,
                  value: item.value
                }))}
                setIsFixed={setIsFixed}
              ></Select>
              <span className="fourth-step-documents__message-error">
                {formState.errors?.dependentes?.[i]?.grau_parentesco &&
                  formState.errors.dependentes[i].grau_parentesco.message}
              </span>
            </div>
            {isHrUser ? null : (
              <>
                <div className="fourth-step-documents">
                  <span className="fourth-step-documents__span">
                    Inserir uma cópia de cada documento
                  </span>
                  <InputUploadFile
                    name={`dependentes[${i}].files.documento_scan_rg`}
                    label={'+ Adicionar Cópia RG'}
                    accept=".pdf"
                    onChange={(e) => {
                      handleInputDependent(
                        i,
                        'documento_scan_rg',
                        e.target.files[0]
                      );
                    }}
                  />
                  {!dependentes[i].files.documento_scan_rg && (
                    <span className="fourth-step-documents__message-error">
                      documento obrigatório
                    </span>
                  )}
                  <InputUploadFile
                    name={`dependentes[${i}].files.documento_scan_cpf`}
                    label={'+ Adicionar Cópia CPF'}
                    accept=".pdf"
                    onChange={(e) => {
                      handleInputDependent(
                        i,
                        'documento_scan_cpf',
                        e.target.files[0]
                      );
                    }}
                  />
                  {!dependentes[i].files.documento_scan_cpf && (
                    <span className="fourth-step-documents__message-error">
                      documento obrigatório
                    </span>
                  )}
                  <InputUploadFile
                    name={`dependentes[${i}].files.documento_comprovacao_vinculo`}
                    label={'+ Adicionar Comprovante de Vinculo'}
                    accept=".pdf"
                    onChange={(e) => {
                      handleInputDependent(
                        i,
                        'documento_comprovacao_vinculo',
                        e.target.files[0]
                      );
                    }}
                  />
                  {!dependentes[i].files.documento_comprovacao_vinculo && (
                    <span className="fourth-step-documents__message-error">
                      documento obrigatório
                    </span>
                  )}
                </div>
                <div>
                  {dependentes[i].files.documento_scan_rg ? (
                    <span className="fourth-step-documents__span">
                      Lista de documentos do(s) dependente(s)
                    </span>
                  ) : null}
                  {dependentes[i].files.documento_scan_rg && (
                    <div className="fourth-step-documents-list-docs">
                      <a
                        href={createObjectURL(
                          dependentes[i].files.documento_scan_rg
                        )}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span>
                          RG: {dependentes[i].files.documento_scan_rg.name}
                        </span>
                      </a>

                      <Button
                        variant="remove"
                        onClick={() => {
                          handleInputDependent(i, 'documento_scan_rg', null);
                        }}
                      >
                        X
                      </Button>
                    </div>
                  )}
                  {dependentes[i].files.documento_scan_cpf && (
                    <div className="fourth-step-documents-list-docs">
                      <a
                        href={createObjectURL(
                          dependentes[i].files.documento_scan_cpf
                        )}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span>
                          CPF: {dependentes[i].files.documento_scan_cpf.name}
                        </span>
                      </a>

                      <Button
                        variant="remove"
                        onClick={() => {
                          handleInputDependent(i, 'documento_scan_cpf', null);
                        }}
                      >
                        X
                      </Button>
                    </div>
                  )}
                  {dependentes[i].files.documento_comprovacao_vinculo && (
                    <div className="fourth-step-documents-list-docs">
                      <a
                        href={createObjectURL(
                          dependentes[i].files.documento_comprovacao_vinculo
                        )}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span>
                          Comprovante de Vínculo:{' '}
                          {
                            dependentes[i].files.documento_comprovacao_vinculo
                              .name
                          }
                        </span>
                      </a>

                      <Button
                        variant="remove"
                        onClick={() => {
                          handleInputDependent(
                            i,
                            'documento_comprovacao_vinculo',
                            null
                          );
                        }}
                      >
                        X
                      </Button>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        ))}

        <div className="fourth-step__button-add-dependent">
          {isHrUser ? null : (
            <Button
              type="button"
              variant="transparent"
              onClick={() => {
                append({
                  files: {
                    documento_scan_rg: '',
                    documento_scan_cpf: '',
                    documento_comprovacao_vinculo: ''
                  }
                });
              }}
            >
              + Adicionar dependente
            </Button>
          )}
        </div>

        {isHrUser && isFixed ? (
          <div className="fourth-step-save">
            <span className="fourth-step-save__span">
              Caso tenha feito alguma alteração clique em salvar:
            </span>
            <div>
              <Button
                isDisabled={!formState.isValid}
                type="submit"
                variant="save"
              >
                Salvar
              </Button>
              {successMessage && (
                <span className="fourth-step-save__span-success">
                  {successMessage}
                </span>
              )}
            </div>
          </div>
        ) : null}

        <div className="fourth-step__buttons-container">
          <div>
            <Button type="button" variant="back" onClick={prevStep}>
              Voltar
            </Button>
          </div>
          <div>
            {isHrUser ? (
              <Button isDisabled={!hasValue} type="button" onClick={nextStep}>
                Avançar
              </Button>
            ) : (
              <Button
                isDisabled={isDisabled}
                type="button"
                onClick={nextStep}
              >
                Avançar
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormFourthStep;
