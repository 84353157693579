import styled from "styled-components";
import colors from "styles/colors";

export const TabelaMovimentacoesWrapper = styled.div`
  width: 100%;
  height: 100%;

  background-color: ${colors.white};

  font-size: 0.77em;

  table {
    padding: 10px 20px;
    // box-shadow: 0 1px 3px ${colors.darkGray}, 0 1px 2px ${colors.darkGray};
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    border-radius: 0.5%;
    // width: calc(100% - 4px);
    width: 100%;
  }

  thead {
    
    tr {
      display: grid;
      grid-template-columns: 6fr 2fr 2fr 3fr;
    }

    .estipulante {
      display: flex;
      align-items: end;
    }

    .primeira {
      display: flex;
      margin-left: 35px;
    }

    .segunda {
      display: grid;
      grid-template-columns: 50% 50%;

    }

    .segunda_especial {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  tbody {

    tr {
      display: grid;
      grid-template-columns: 6fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      line-height: 0.95em;
      height: 2.5em;
    }

    tr:nth-child(2n + 1) {
      background-color: ${colors.genoaMediumBackgroundGrey};
    }

    td {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    td:first-child {
      justify-content: flex-start;
    }
  }
`