import { useState, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import moment from 'moment';
import { BsSlash } from 'react-icons/bs';
import { RiFilter3Fill } from 'react-icons/ri';
import AsideMenuMovimentacoes from './AsideMenuMovimentacoes';
import DateFilterPopper from './components/DateFilter';
import RelatorioContent from './RelatorioContent';
import { RelatorioMovimentacoesWrapper } from './styles-RelatorioMovimentacoes';
import monthsObject from 'utils/objects/optionsMonth';
import services from 'apps/broker/services';

const RelatorioMovimentacoes = ({ setRelatorioToDisplay }) => {
  const [loading, setLoading] = useState(false);

  // Valores referentes ao comportamento da página
  const [relatorioSelecionado, setRelatorioSelecionado] = useState(
    'movimentacoes abertas'
  );
  const [openFilter, setOpenFilter] = useState(false);
  const [hasInclusao, setInclusao] = useState('active');
  const [hasExclusao, setExclusao] = useState('active');
  const [hasRh, setRh] = useState('active');
  const [hasCorretora, setCorretora] = useState('active');

  // Arrays de valores retornados da requisição do Backend

  // Valores únicos para renderização na tela
  const [graficoAbertas, setGraficoAbertas] = useState([]);
  const [graficosConcluidos, setGraficoConcluidos] = useState([]);

  const [tableEstipulanteValue, setTableEstipulanteValue] = useState(undefined);
  const [tableSeguradora, setTableSeguradora] = useState(undefined);
  const [movimentacoesConcluidas, setMovimentacoesConcluidas] =
    useState(undefined);
  const [movimentacoesSeguradora, setMovimentacoesSeguradora] =
    useState(undefined);
  const [tempoMedio, setTempoMedio] = useState(undefined);
  const [tempoMedioSeguradora, setTempoMedioSeguradora] = useState(undefined);

  // Valores para estipulante selecionado ao clicar em alguma tabela
  const [estipulanteSelected, setEstipulanteSelected] = useState(undefined);

  // Valores de filtragem de data
  const [startDate, setStartDate] = useState(undefined);
  const [finalDate, setFinalDate] = useState(undefined);
  const [lastFilterDates, setLastFilterDates] = useState({
    initialDate: undefined,
    finalDate: undefined
  });

  const getValoresMovimentacoesAbertasGraficoLinear = async () => {
    const response = await services.reports.getMovimentStatusOpen();

    setGraficoAbertas(response.data?.abertos);
    setGraficoConcluidos(response.data?.concluidos);
  };

  const setTableValues = (data) => {
    const { abertos, concluidos } = data;

    reduceValoresMovimentacaoAberta(abertos);

    reduceValoresAbertosSeguradora(abertos);

    tempoMedioConcluidas(concluidos);

    reduceTempoMedioSeguradora(concluidos);

    reduceMovimentacoesConcluidas(concluidos);

    reduceMovimentacoesConcluidaSeguradora(concluidos);
  };

  const reqRelatorioMovimentacoes = async (filterDates) => {
    const body = {
      filterDates
    };

    setLastFilterDates(filterDates);
    const response = await services.reports.getMovimentByFilterDate(body);

    if (response.status === 200) {
      setTableValues(response.data);
    }
  };

  const getRelatorioMovimentacoes = async () => {
    const filterDates = {
      initialDate: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
      finalDate: moment(finalDate).format('YYYY-MM-DD HH:mm:ss')
    };

    await reqRelatorioMovimentacoes(filterDates);
  };

  const reduceValoresMovimentacaoAberta = (abertos) => {
    const valoresRegistro = abertos.reduce((acc, curr) => {
      const { nome_estipulante, created_via, tipo_movimentacao } = curr;

      if (!acc[nome_estipulante]) {
        acc[nome_estipulante] = {
          Rh: {
            Inclusão: 0,
            Exclusão: 0
          },
          Corretora: {
            Inclusão: 0,
            Exclusão: 0
          }
        };
      }

      acc[nome_estipulante] = {
        ...acc[nome_estipulante],
        [created_via]: {
          ...acc[nome_estipulante][created_via],
          [tipo_movimentacao]:
            acc[nome_estipulante][created_via][tipo_movimentacao] + 1
        }
      };
      return acc;
    }, {});

    setTableEstipulanteValue(valoresRegistro);
  };

  const reduceValoresAbertosSeguradora = (abertos) => {
    const valoresSeguradora = abertos.reduce((acc, curr) => {
      const { nome_seguradora, tipo_movimentacao } = curr;

      if (!acc[nome_seguradora]) {
        acc[nome_seguradora] = {
          Inclusão: 0,
          Exclusão: 0
        };
      }

      acc[nome_seguradora] = {
        ...acc[nome_seguradora],
        [tipo_movimentacao]: acc[nome_seguradora][tipo_movimentacao] + 1
      };
      return acc;
    }, {});

    setTableSeguradora(valoresSeguradora);
  };

  const reduceMovimentacoesConcluidas = (concluidos) => {
    const valoresRegistro = concluidos.reduce((acc, curr) => {
      const { nome_estipulante, created_via, tipo_movimentacao } = curr;
      if (!acc[nome_estipulante]) {
        acc[nome_estipulante] = {
          Rh: {
            Inclusão: 0,
            Exclusão: 0
          },
          Corretora: {
            Inclusão: 0,
            Exclusão: 0
          }
        };
      }

      acc[nome_estipulante] = {
        ...acc[nome_estipulante],
        [created_via]: {
          ...acc[nome_estipulante][created_via],
          [tipo_movimentacao]:
            acc[nome_estipulante][created_via][tipo_movimentacao] + 1
        }
      };
      return acc;
    }, {});

    setMovimentacoesConcluidas(valoresRegistro);
  };

  const reduceMovimentacoesConcluidaSeguradora = (concluidos) => {
    const valoresSeguradora = concluidos.reduce((acc, curr) => {
      const { nome_seguradora, tipo_movimentacao } = curr;

      if (!acc[nome_seguradora]) {
        acc[nome_seguradora] = {
          Inclusão: 0,
          Exclusão: 0
        };
      }

      acc[nome_seguradora] = {
        ...acc[nome_seguradora],
        [tipo_movimentacao]: acc[nome_seguradora][tipo_movimentacao] + 1
      };
      return acc;
    }, {});

    setMovimentacoesSeguradora(valoresSeguradora);
  };

  const tempoMedioConcluidas = (concluidos) => {
    const tempoMedio = concluidos.reduce((acc, curr) => {
      const {
        nome_estipulante,
        created_via,
        tipo_movimentacao,
        created_at,
        closed_at
      } = curr;

      let subtraction = new Date(closed_at) - new Date(created_at);

      if (!acc[nome_estipulante]) {
        acc[nome_estipulante] = {
          Rh: {
            Inclusão: {
              tempo_total: 0,
              count: 0
            },
            Exclusão: {
              tempo_total: 0,
              count: 0
            }
          },
          Corretora: {
            Inclusão: {
              tempo_total: 0,
              count: 0
            },
            Exclusão: {
              tempo_total: 0,
              count: 0
            }
          }
        };
      }

      const previousTempoTotal =
        acc[nome_estipulante][created_via][tipo_movimentacao]['tempo_total'];
      const previousCount =
        acc[nome_estipulante][created_via][tipo_movimentacao]['count'];

      acc[nome_estipulante] = {
        ...acc[nome_estipulante],
        [created_via]: {
          ...acc[nome_estipulante][created_via],
          [tipo_movimentacao]: {
            tempo_total: previousTempoTotal + subtraction,
            count: previousCount + 1
          }
        }
      };

      return acc;
    }, {});

    setTempoMedio(tempoMedio);
  };

  const reduceTempoMedioSeguradora = (concluidos) => {
    const tempoMedio = concluidos.reduce((acc, curr) => {
      const { nome_seguradora, tipo_movimentacao, created_at, closed_at } =
        curr;

      let subtraction = new Date(closed_at) - new Date(created_at);

      if (!acc[nome_seguradora]) {
        acc[nome_seguradora] = {
          Inclusão: {
            tempo_total: 0,
            count: 0
          },
          Exclusão: {
            tempo_total: 0,
            count: 0
          }
        };
      }

      const previousTempoTotal =
        acc[nome_seguradora][tipo_movimentacao]['tempo_total'];
      const previousCount = acc[nome_seguradora][tipo_movimentacao]['count'];

      acc[nome_seguradora] = {
        ...acc[nome_seguradora],
        [tipo_movimentacao]: {
          tempo_total: previousTempoTotal + subtraction,
          count: previousCount + 1
        }
      };

      return acc;
    }, {});

    setTempoMedioSeguradora(tempoMedio);
  };

  const filterIconClickHandler = (e) => {
    setOpenFilter((prevState) => !prevState);
  };

  const renderFilterPopper = () => {
    return (
      <DateFilterPopper
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        startDate={startDate}
        finalDate={finalDate}
        setStartDate={setStartDate}
        setFinalDate={setFinalDate}
        getRelatorioMovimentacoes={getRelatorioMovimentacoes}
      />
    );
  };

  const renderInfoPeriodoFiltrado = () => {
    if (lastFilterDates?.initialDate && lastFilterDates?.finalDate) {
      const yearStart = lastFilterDates?.initialDate?.slice(0, 4);
      const monthStart = lastFilterDates?.initialDate?.slice(5, 7);
      const dayStart = lastFilterDates?.initialDate?.slice(8, 10);

      const yearEnd = lastFilterDates?.finalDate?.slice(0, 4);
      const monthEnd = lastFilterDates?.finalDate?.slice(5, 7);
      const dayEnd = lastFilterDates?.finalDate?.slice(8, 10);

      return (
        <>
          <span>{`${dayStart} ${monthsObject[monthStart]}, ${yearStart}`}</span>
          <span>-</span>
          <span>{`${dayEnd} ${monthsObject[monthEnd]}, ${yearEnd}`}</span>
        </>
      );
    }
  };

  const getMovimentsToday = async () => {
    const today = new Date();
    const lastMonth = new Date();

    lastMonth.setMonth(lastMonth.getMonth() - 1);

    const filterDates = {
      initialDate: moment(lastMonth).format('YYYY-MM-DD HH:mm:ss'),
      finalDate: moment(today).format('YYYY-MM-DD HH:mm:ss')
    };

    setLastFilterDates(filterDates);

    const body = {
      filterDates
    };

    setLoading(true);

    const response = await services.reports.getMovimentByFilterDate(body);

    if (response.status === 200) {
      setTableValues(response.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    getMovimentsToday();
    getValoresMovimentacoesAbertasGraficoLinear();
  }, []);

  return loading ? (
    <RelatorioMovimentacoesWrapper>
      <div className="circular-spinner-container">
        <CircularProgress
          size={150}
          thickness={1}
          className="circular-spinner"
        />
        <span>Gerando relatório ...</span>
      </div>
    </RelatorioMovimentacoesWrapper>
  ) : (
    <RelatorioMovimentacoesWrapper>
      <div className="title_container">
        <span
          className="return_link"
          onClick={() => setRelatorioToDisplay(undefined)}
        >
          Todos os relatórios
        </span>
        <BsSlash className="slash_icon" size={20} />
        <span>Relatório Movimentações</span>
      </div>
      <div className="relatorio_concierge__wrapper">
        <div className="relatorio_concierge__header">
          <div className="header_info_wrapper">
            <div className="info_filter__container">
              <span className="info_filter__title">Filtrado por período:</span>
              <div className="info_filter__period">
                {renderInfoPeriodoFiltrado()}
              </div>
            </div>
            <button className="filter_icon_btn">
              <RiFilter3Fill
                className="filter_icon"
                size={16}
                onClick={filterIconClickHandler}
              />
            </button>
          </div>
          <div className="header_tipo_movimentacao__container">
            <div className="button__container">
              <button
                className={`${hasExclusao}`}
                onClick={() =>
                  hasExclusao === 'disabled'
                    ? setExclusao('active')
                    : setExclusao('disabled')
                }
              >
                <span>Exclusão</span>
              </button>
              <button
                className={`${hasInclusao}`}
                onClick={() =>
                  hasInclusao === 'disabled'
                    ? setInclusao('active')
                    : setInclusao('disabled')
                }
              >
                <span>Inclusão</span>
              </button>
            </div>
          </div>
          <div className="header_origem_movimentacao__container">
            <div className="button__container">
              <button
                className={`${hasCorretora}`}
                onClick={() =>
                  hasCorretora === 'disabled'
                    ? setCorretora('active')
                    : setCorretora('disabled')
                }
              >
                <span>Corretora</span>
              </button>
              <button
                className={`${hasRh}`}
                onClick={() =>
                  hasRh === 'disabled' ? setRh('active') : setRh('disabled')
                }
              >
                <span>Rh</span>
              </button>
            </div>
          </div>
        </div>

        <div className="relatorio_concierge__content">
          <AsideMenuMovimentacoes
            relatorioSelecionado={relatorioSelecionado}
            setRelatorioSelecionado={setRelatorioSelecionado}
            estipulanteSelected={estipulanteSelected}
            tableEstipulanteValue={tableEstipulanteValue}
            tableSeguradora={tableSeguradora}
            movimentacoesConcluidas={movimentacoesConcluidas}
            movimentacoesSeguradora={movimentacoesSeguradora}
            tempoMedio={tempoMedio}
            tempoMedioSeguradora={tempoMedioSeguradora}
            hasInclusao={hasInclusao}
            hasExclusao={hasExclusao}
            hasRh={hasRh}
            hasCorretora={hasCorretora}
          />
          <RelatorioContent
            relatorioSelecionado={relatorioSelecionado}
            estipulanteSelected={estipulanteSelected}
            setEstipulanteSelected={setEstipulanteSelected}
            tableEstipulanteValue={tableEstipulanteValue}
            tableSeguradora={tableSeguradora}
            movimentacoesConcluidas={movimentacoesConcluidas}
            movimentacoesSeguradora={movimentacoesSeguradora}
            tempoMedio={tempoMedio}
            tempoMedioSeguradora={tempoMedioSeguradora}
            graficoAbertas={graficoAbertas}
            graficosConcluidos={graficosConcluidos}
          />
        </div>
      </div>
      {openFilter ? renderFilterPopper() : ''}
    </RelatorioMovimentacoesWrapper>
  );
};

export default RelatorioMovimentacoes;
