import InputMask from 'react-input-mask';
import { TextField } from '@material-ui/core';
import { forwardRef } from 'react';

function PhoneTextMask(props) {
  const { inputRef, ...other } = props;

  return (
    <InputMask
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask="(99) 99999-9999"
    />
  );
}

const PhoneTextField = forwardRef((props, ref) => 
  <TextField
    {...props}
    ref={ref}
    InputProps={{
      inputComponent: PhoneTextMask,
    }}
  />
)

export default PhoneTextField;