import styled from 'styled-components';
import colors from 'apps/broker/styles/colors';

export const Div = styled.div`
  .form-remocao-beneficiario-container {
    color: ${colors.genoaGrey};
    padding-top: 30px;
    display: flex;
    justify-content: center;
    font-size: 1.1rem;
  }

  .form-remocao-beneficiario {
    width: 600px;

    .form-remocao-line-row {
      padding-bottom: 10px;
      :hover {
        cursor: pointer;
      }
    }

    .selecao-beneficiario {
      font-family: 'Avenir Next GEO W05 Regular';
      font-size: 1rem;
      padding: 5px 10px;
      border: 0.5px solid ${colors.genoaBlue};
      border-radius: 5px;

      .selecao-beneficiario-hero-title {
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        text-align: center;
        display: grid;
        grid-template-columns: 570px 30px;
        align-items: center;

        .span-placeholder {
          color: #b8b9bb;
        }

        .span-hero-title {
          color: ${colors.genoaGrey};
        }

        :hover {
          cursor: pointer;
        }

        .arrow-icon {
          color: #4c4c4c;
        }
      }

      .tabela-beneficiarios-container {
        height: 0px;
        overflow: hidden;
        transition: 0.7s height ease-in-out;
      }

      .tabela-beneficiarios-container-open {
        height: 400px;
        overflow: hidden;
        transition: 0.7s height ease-in-out;
      }

      .beneficiario-tabela-row {
        padding-top: 20px;
        max-height: 540px;
        height: 235px; 
      }

      .beneficiario-tabela {
        width: 100%;
        background-color: #white;
        height: 235px;
        border-radius: 10px;
        max-height: 235px;
        overflow-y: scroll;

        .table-header-first-row {
          border-top-right-radius: 5px;
          border-top-left-radius: 5px;
          padding-top: 10px;
          padding-left: 20px;
          padding-bottom: 15px;
          height: 25px;
          align-items: center;
          background-color: ${colors.genoaBlue};
          color: white;
          display: grid;
          grid-template-columns: 1.3fr 1fr;
        }

        .table-header-row {
          height: 25px;
          align-items: center;
          background-color: ${colors.genoaBlue};
          color: white;
          display: grid;
          grid-template-columns: 1.9fr 1fr;
          padding-bottom: 3px;
        }

        th: first-child {
          text-align: left;
          padding-left: 30px;
        }
        th: last-child {
          text-align: center;
        }

        td: first-child {
          text-align: left;
          padding-left: 30px;
        }
        td: last-child {
          text-align: center;
          padding-left: 8px;
          user-select: none;
          -moz-user-select: none;
          -webkit-user-select: none;
        }

        .table-name-input {
          border-color: transparent;
          padding-top: 3px;
          padding-bottom: 3px;
          padding-left: 8px;
          padding-right: 8px;
          text-align: center;
          font-size: 0.9 rem;
          border-radius: 5px;

          ::placeholder {
            color: #b8b9bb;
            text-align: center;
          }
        }

        .radio-input-group-container {
          display: flex;
        }

        .radio-input-container {
          padding-left: 10px;
          display: flex;
          align-items: center;

          :hover {
            cursor: pointer;
          }

          span {
            font-size: 0.9rem;
            user-select: none;
            -moz-user-select: none;
            -webkit-user-select: none;
          }
        }

        .radio-input {
          color: white;
        }

        .table-beneficiarios-data-row {
          display: grid;
          grid-template-columns: 1.9fr 1fr;
        }

        .beneficiario-table-body {
          max-height: 285px;
          overflow-y: scroll;
          display: block;

          tr: nth-child(2n) {
            background-color: #89a9cc1c;
          }

          td: first-child {
            user-select: none;
            -moz-user-select: none;
            -webkit-user-select: none;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            
            :hover {
              cursor: pointer;
              font-weight: bold;
            }
          }

          ::-webkit-scrollbar {
            width: 6px;
            background: transparent;
          }
          ::-webkit-scrollbar-thumb {
            background: ${colors.genoaBlue};
            box-shadow: none;
            border-radius: 14px;
          }
          ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 2px grey;
            border-radius: 14px;
          }
        }
      }
    }
  }

  .beneficiario-planos-info-container {
    height: 0px;
    overflow: hidden;
    transition: height 0.7s ease-in-out 0.7s;
  }

  .beneficiario-planos-info-container-open {
    overflow: hidden;
    transition: height 0.7s ease-in-out 0.7s;
  }

  .span-tipo-plano {
    padding-left: 20px;
  }

  .dependente-beneficiario-container-open {
    height: 100px;
    padding-top: 30px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    transition: height 0.3s ease-out;
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 1.3rem;
    color: ${colors.genoaGrey};
    .dependentes-titular-first-row {
      display: flex;
      align-items: center;
      justify-content: center;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;

      .arrow-container {
        margin-left: 5px;
        margin-bottom: -2px;
      }

      .arrow-open-icon {
        transition: all 0.3s;
      }

      .arrow-close-icon {
        transform: rotate(-90deg);
        transition: all 0.3s;
      }
      :hover {
        cursor: pointer;
      }
    }

    .dependentes-titular-second-row {
      padding-top: 10px;
    }
  }

  .dependente-beneficiario-container {
    height: 40px;
    overflow: hidden;
    padding-top: 30px;
    display: flex;
    display: flex;
    justify-content: center;
    transition: height 0.3s ease-out;
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 1.3rem;
    color: ${colors.genoaGrey};
    .dependentes-titular-first-row {
      display: flex;
      align-items: center;
      justify-content: center;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;

      .arrow-container {
        margin-left: 5px;
        margin-bottom: -2px;
      }

      .arrow-open-icon {
        transition: all 0.3s;
      }

      .arrow-close-icon {
        transform: rotate(-90deg);
        transition: all 0.3s;
      }
      :hover {
        cursor: pointer;
      }
    }

    .dependentes-titular-second-row {
      padding-top: 10px;
    }
  }

  .dependentes-titular-lista {
    color: ${colors.genoaGrey};
    margin-top: 10px;
    font-family: Avenir Next GEO W05 Regular;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;

    .titular-nome {
      font-size: 1.1rem;
      
    }
  }

  .remove-beneficiario-button-container {
    height: 0px;
    display: flex;
    justify-content: center;
    padding-top: 30px;
    .send-btn {
      color: white;
      margin-top: 12px;
      padding: 4px 10px;
      background-color: #1e88f2;
      :hover {
        background-color: #1976d2;
      }
    }

    .send-btn-disabled {
      color: white;
      margin-top: 12px;
      padding: 4px 10px;
      }
    }
  }

  .remove-beneficiario-button-container-open {
    height: 48px;
    display: flex;
    justify-content: center;
    padding-top: 30px;
    .send-btn-remocao {
      color: white;
      margin-top: 12px;
      padding: 4px 10px;
      background-color: ${colors.genoaBlue};
      :hover {
        filter: contrast(120%);
      }
    }

    .send-btn-remocao-disabled {
      color: white;
      margin-top: 12px;
      padding: 4px 10px;
      }
    }
  }

  .remove-beneficiario-container {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    align-items: center;
  }

  .remove-info-title {
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 1.3rem;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
  }

  .remove-planos-options {
    padding-top: 20px;
    select {
      font-size: 1em;
      font-family: "Avenir Next GEO W05 Regular";
      padding: 8px;
      width: 320px;
      border-radius: 8px;
      border: 1px ${colors.genoaBlue} solid;
      margin-top: 12px;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;

      :focus-visible {
        outline: 1px ${colors.genoaBlue}f5 auto;
      }
    }
  }

  .remove-plano-option {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    .plano-icon {
      width: 20px;
      height: 20px;
      padding-right: 5px;
    }
    :hover {
      cursor: pointer;
    }
  }

  .remove-plano-option-selected {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    color: ${colors.genoaBlue};
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    .plano-icon {
      width: 20px;
      height: 20px;
      padding-right: 5px;
      fill: currentColor;
    }
    :hover {
      cursor: pointer;
    }
  }

  .remove-plano-option-inactive {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    color: ${colors.genoaDisabledGrey};

    .plano-icon {
      width: 20px;
      height: 20px;
      padding-right: 5px;
      fill: currentColor;
    } 
  }

  .input-data {
    width: 120px;
    border: 1px solid ${colors.genoaBlue};
    display: inline-block;
    overflow: hidden;
    text-align: center;
    font-family: 'Avenir Next GEO W05 Regular';
    border-radius: 7px;
    line-height: 1.2rem;
    padding: 6px;

    .react-datepicker-wrapper {
      display: inline-block;
      padding: 0;
      border: 0;
      width: min-content;
    }

    :hover {
      cursor: pointer;
    }
  }

  .span-date-container {
    padding-top: 20px;
    width: 120px;
    height: 33px;
    display: flex;
    justify-content: space-between;
    transition: all .4s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .span-date-title {
    padding-top: 10px;
  }

  .datepicker-selector-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
  }

  .clear-date-option {
    font-size: 0.8rem;
    color: ${colors.genoaBlue};
    height: 22.8px;
    font-weight: bold;
    padding-top: 5px;
    :hover {
      cursor: pointer;
      border-bottom: 1px ${colors.genoaBlue} solid;
    }
  }

  .clear-date-option-container {
    min-height: 23px;
  }

  .dependente-transition-container{
    transition: height 0.4s ease-in-out 0.7s;
    overflow: hidden;
  }

  .dependentes-quantidade-info {
    margin-left: 7px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
  }

  .aba-remocao-margin-bottom {
    min-height: 250px;
    max-height: 250px;
  }
`;
