import { useState, useEffect } from 'react';
import { FilterNegociosWrapper } from './styles';
import CheckmarkStandard from '../../../../components/Select/Checkmark';


const FilterNegocios = ({
  setOpenDrawer,
  corretores,
  tipo,
  setTipo,
  status,
  setStatus,
  produto,
  setProduto,
  responsavel,
  setResponsavel
}) => {
  const [optionsResponsavel, setOptionsResponsavel] = useState([]);

  useEffect(() => {
    const naoAlocado = {
      value: 0,
      option: 'Não alocado'
    };
    if (corretores?.length) {
      const responsaveis = corretores?.map((item) => ({
        value: item.usuario_id,
        option: `${item.nome}`
      }));

      setOptionsResponsavel([naoAlocado, ...responsaveis]);
    }
  }, [corretores]);

  return (
    <FilterNegociosWrapper>
      <div className="filter_type__container">
        <div className="checkmark_container">
          <CheckmarkStandard
            value={tipo}
            setValue={setTipo}
            options={[
              {
                option: 'Renovação',
                value: 1
              },
              {
                option: 'Novo',
                value: 2
              }
            ]}
            label={'Tipo'}
            selectPlaceHolder="Selecione"
          />
        </div>
      </div>

      <div className="filter_type__container">
        <div className="checkmark_container">
          <CheckmarkStandard
            value={status}
            setValue={setStatus}
            options={[
              {
                option: 'Aberto',
                value: 1
              },
              {
                option: 'Ren. Mesma Cia',
                value: 2
              },
              {
                option: 'Ren. Outra Cia',
                value: 3
              },
              {
                option: 'Cliente perdido',
                value: 4
              }
            ]}
            label={'Status'}
            selectPlaceHolder="Selecione"
          />
        </div>
      </div>

      <div className="filter_type__container">
        <div className="checkmark_container">
          <CheckmarkStandard
            value={produto}
            setValue={setProduto}
            options={[
              {
                option: 'Saúde',
                value: 1
              },
              {
                option: 'Odonto',
                value: 2
              },
              {
                option: 'Vida',
                value: 3
              }
            ]}
            label={'Produto'}
            selectPlaceHolder="Selecione"
          />
        </div>
      </div>

      <div className="filter_type__container">
        <div className="checkmark_container">
          <CheckmarkStandard
            value={responsavel}
            setValue={setResponsavel}
            options={optionsResponsavel}
            label={'Responsável'}
            selectPlaceHolder="Selecione"
            dropDownHeight={'250px'}
          />
        </div>
      </div>
    </FilterNegociosWrapper>
  );
};

export default FilterNegocios;
