import { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import { BsArrowLeftShort } from 'react-icons/bs';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import services from 'apps/broker/services';
import { BiFolderOpen } from 'react-icons/bi';
import CloseIcon from '@material-ui/icons/Close';
import './style.scss';
import Button from 'components/DS/Button';
import { FaSave } from 'react-icons/fa';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1rem',
    '& > * + *': { marginLeft: theme.spacing(2) }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 10,
    color: '#fff'
  },
  load: { color: '#6e3ec1' }
}));

registerLocale('ptBR', ptBR);

const HealthInsurance = ({ estipulante, subs, onBack }) => {
  const classes = useStyles();
  const [loadApi, setLoadApi] = useState(false);
  const [seguradoras, setSeguradoras] = useState([]);

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      planos: [{ nome: '' }],
      produto_id: 1,
      'possui-coparticipacao': 'não',
      'coparticipacao-details': '',
      'custeio-details': '',
      'multa-details': '',
      'numero-apolice': ''
    }
  });

  const {
    fields: planosField,
    append: planosAppend,
    remove: planosRemove
  } = useFieldArray({ control, name: 'planos' });

  const onSubmit = async (data) => {
    setLoadApi(true);
    const body = { ...data, subsestipulantes: [...subs] };

    const response = await services.contracts.addNewContract(body, estipulante);

    if (response.status === 200) {
      if (data.formExt !== null && data.formExt[0]) {
        const formData = new FormData();
        formData.append('file', data.formExt[0]);

        await services.contracts.addContractFiles(formData, response.data.id, 'formExt');
      }
      if (data.arquivo !== null && data.arquivo[0]) {
        const formData = new FormData();
        formData.append('file', data.arquivo[0]);

        await services.contracts.addContractFiles(formData, response.data.id, 'Documento');
      }
      setLoadApi(false);
      onBack();
    }
  };

  const getSeguradora = async () => {
    const response =
      await services.insuranceCompanies.getInsuranceCompanyByType(
        'SeguroSaude'
      );

    setSeguradoras(response.data);
  };

  useEffect(() => {
    getSeguradora();
  }, []);

  return (
    <>
      <Backdrop
        className={classes.backdrop}
        open={loadApi}
        onClick={() => setLoadApi(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="health-insurance">
        <form
          className="health-insurance-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="health-insurance-back">
            <button className="health-insurance-back__button" onClick={onBack}>
              <BsArrowLeftShort size={25} />
              <span className="health-insurance-back__span">Voltar</span>
            </button>
          </div>
          <div className="health-insurance-title">
            <span className="health-insurance-title__span">
              Cadastro de novo benefício
            </span>
          </div>
          <div className="health-insurance-form-content">
            <div className="health-insurance-form-section">
              <div className="health-insurance-form-section-select-group">
                <label className="health-insurance-form-section-select-group__label">
                  Seguradora
                </label>
                <select
                  className="health-insurance-form-section-select-group__select"
                  {...register('seguradora', { required: false })}
                >
                  <option value="">Selecione</option>
                  {seguradoras.map((seguradora) => {
                    return (
                      <option value={seguradora.id}>{seguradora.nome}</option>
                    );
                  })}
                </select>
              </div>

              <div className="health-insurance-form-section-input-group">
                <label className="health-insurance-form-section-input-group__label">
                  Número da Apólice
                </label>
                <input
                  className="health-insurance-form-section-input-group__input"
                  type="text"
                  placeholder="Digite aqui o Número da Apólice  ..."
                  {...register('numero-apolice', { required: false })}
                  onChange={() => console.log(control._formValues)}
                />
              </div>

              <div className="health-insurance-form-section-date-picker-group">
                <label className="health-insurance-form-section-date-picker-group__label">
                  Vigência Inicial
                </label>
                {errors.vigenciaInicial && (
                  <span className="health-insurance-form-section-remove">Campo obrigatório</span>
                )}
                <Controller
                  name="vigenciaInicial"
                  control={control}
                  //defaultValue={new Date()}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      className="health-insurance-form-section-date-picker-group__date-picker"
                      selected={value}
                      locale="ptBR"
                      onChange={onChange}
                      placeholderText="Selecione uma data"
                      dateFormat="dd/MM/yyyy"
                    />
                  )}
                />
              </div>

              <div className="health-insurance-form-section-select-group">
                <label className="health-insurance-form-section-select-group__label">
                  Mês de Aniversário
                </label>
                <select
                  className="health-insurance-form-section-select-group__select"
                  {...register('mesAniversario', { required: false })}
                >
                  <option value="">Selecione</option>
                  {[
                    'Janeiro',
                    'Fevereiro',
                    'Março',
                    'Abril',
                    'Maio',
                    'Junho',
                    'Julho',
                    'Agosto',
                    'Setembro',
                    'Outubro',
                    'Novembro',
                    'Dezembro'
                  ].map((i) => {
                    return <option value={i}>{i}</option>;
                  })}
                </select>
              </div>

              <div className="health-insurance-form-section-textarea-group">
                <label className="health-insurance-form-section-textarea-group__label">
                  Multa Contratual
                </label>
                <textarea
                  className="health-insurance-form-section-textarea-group__textarea"
                  type="text"
                  rows={2}
                  placeholder="Digite aqui a Multa Contratual de Recisão..."
                  {...register('multa-details', { required: false })}
                  onChange={() => console.log(control._formValues)}
                />
              </div>
            </div>

            <div className="health-insurance-form-section">
              <div className="health-insurance-form-section-checkbox-group">
                <label className="health-insurance-form-section-checkbox-group__label">
                  Precificação
                </label>
                <div className="health-insurance-form-section-checkbox-group__options">
                  <label
                    htmlFor="customedio"
                    className={
                      watch('tipoCusto') === 'Custo Médio'
                        ? 'health-insurance-form-section-checkbox-group__select-label health-insurance-form-section-checkbox-group__select-label--active'
                        : 'health-insurance-form-section-checkbox-group__select-label'
                    }
                  >
                    Custo Médio
                  </label>
                  <input
                    id="customedio"
                    type="radio"
                    value="Custo Médio"
                    {...register('tipoCusto', { required: false })}
                  />
                </div>
                <div className="health-insurance-form-section-checkbox-group__options">
                  <label
                    htmlFor="faixaetaria"
                    className={
                      watch('tipoCusto') === 'Faixa Etária'
                        ? 'health-insurance-form-section-checkbox-group__select-label health-insurance-form-section-checkbox-group__select-label--active'
                        : 'health-insurance-form-section-checkbox-group__select-label'
                    }
                  >
                    Faixa Etária
                  </label>
                  <input
                    id="faixaetaria"
                    type="radio"
                    value="Faixa Etária"
                    {...register('tipoCusto', { required: false })}
                  />
                </div>

                <div className="health-insurance-form-section-textarea-group">
                  <label className="health-insurance-form-section-textarea-group__label">
                    Forma de Custeio
                  </label>
                  <textarea
                    className="health-insurance-form-section-textarea-group__textarea"
                    type="text"
                    rows={7}
                    placeholder="Digite aqui os detalhes da Forma de Custeio..."
                    {...register('custeio-details', { required: false })}
                    onChange={() => console.log(control._formValues)}
                  />
                </div>

                <div className="health-insurance-form-section-select-group">
                  <div className="health-insurance-form-section-select-group--select">
                    <label className="health-insurance-form-section-select-group__label">
                      Dia de vencimento da fatura
                    </label>
                    <select
                      className="health-insurance-form-section-select-group__select"
                      {...register('vencimento', { required: false })}
                    >
                      <option value="">Selecione</option>
                      {Array.from(Array(32).keys()).map((i) => {
                        if (i !== 0) return <option value={i}>{i}</option>;
                        return null;
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="health-insurance-form-section">
              <div className="health-insurance-form-section-checkbox-group">
                <label className="health-insurance-form-section-checkbox-group__label">
                  Coparticipação
                </label>
                <div className="health-insurance-form-section-checkbox-group__options">
                  <label
                    htmlFor="sim"
                    className={
                      watch('possui-coparticipacao') === 'sim'
                        ? 'health-insurance-form-section-checkbox-group__select-label health-insurance-form-section-checkbox-group__select-label--active'
                        : 'health-insurance-form-section-checkbox-group__select-label'
                    }
                  >
                    Sim
                  </label>
                  <input
                    id="sim"
                    type="radio"
                    value="sim"
                    {...register('possui-coparticipacao', { required: false })}
                  />
                </div>
                <div className="health-insurance-form-section-checkbox-group__options">
                  <label
                    htmlFor="nao"
                    className={
                      watch('possui-coparticipacao') === 'não'
                        ? 'health-insurance-form-section-checkbox-group__select-label health-insurance-form-section-checkbox-group__select-label--active'
                        : 'health-insurance-form-section-checkbox-group__select-label'
                    }
                  >
                    Não
                  </label>
                  <input
                    id="nao"
                    type="radio"
                    value="não"
                    {...register('possui-coparticipacao', { required: false })}
                  />
                </div>
                <div className="health-insurance-form-section-textarea-group">
                  <label className="health-insurance-form-section-textarea-group__label">
                    Detalhes da Coparticipação
                  </label>
                  <textarea
                    type="text"
                    rows={7}
                    placeholder={
                      watch('possui-coparticipacao') === 'sim'
                        ? 'Digite aqui os detalhes da Coparticipação...'
                        : ''
                    }
                    className={
                      watch('possui-coparticipacao') === 'sim'
                        ? 'health-insurance-form-section-textarea-group__textarea health-insurance-form-section-textarea-group__textarea--active'
                        : 'health-insurance-form-section-textarea-group__textarea health-insurance-form-section-textarea-group__textarea--disabled'
                    }
                    disabled={
                      watch('possui-coparticipacao') === 'sim' ? false : true
                    }
                    {...register('coparticipacao-details', { required: false })}
                  />
                </div>
              </div>

              <div className="health-insurance-form-section-select-group">
                <label className="health-insurance-form-section-select-group__label">
                  Dia de corte
                </label>
                <select
                  className="health-insurance-form-section-select-group__select"
                  {...register('diaCorte', { required: false })}
                >
                  <option value="">Selecione</option>
                  {Array.from(Array(32).keys()).map((i) => {
                    if (i !== 0) return <option value={i}>{i}</option>;
                    return null;
                  })}
                </select>
              </div>
            </div>
          </div>

          <div className="health-insurance-form-plans">
            <div className="health-insurance-form-plans-title">
              <span className="health-insurance-form-plans-title__span">
                Inclusão de planos
              </span>
            </div>
            <div className="health-insurance-form-section-input-group">
              <label className="health-insurance-form-section-input-group__label">
                Coloque os planos que os beneficiários desse contrato poderão
                escolher:
              </label>
              {planosField.map((item, index) => {
                return (
                  <div className="health-insurance-form-section-input-group">
                    <div className="health-insurance-form-section-remove">
                      {' '}
                      {index !== 0 && (
                        <button
                          className="health-insurance-form-section-remove__button"
                          type="button"
                          onClick={() => planosRemove(index)}
                        >
                          remover
                        </button>
                      )}
                    </div>
                    <input
                      className="health-insurance-form-section-remove__input"
                      type="text"
                      placeholder="Ex.: TNQQ"
                      name={`planos${index}.nome`}
                      {...register(`planos.${index}.nome`, { required: false })}
                    />
                  </div>
                );
              })}
            </div>

            <div className="health-insurance-form-section-button-group">
              <button
                className="health-insurance-form-section-button-group__button"
                type="button"
                onClick={() => planosAppend({ nome: '' })}
              >
                + Adicionar mais um plano.
              </button>
            </div>
          </div>

          <div className="health-insurance-form-section-input-file">
            <div>
              <div className="health-insurance-form-section-input-file-title">
                <label className="health-insurance-form-section-input-file-title__label">
                  Documentos
                </label>
              </div>
              <div>
                <span className="health-insurance-form-section-input-file-title__span">
                  Adicione os documentos e contratos que foram utilizados para
                  fechar o contrato.
                </span>
              </div>
              <div className="health-insurance-form-section-input-file-group">
                <label className="health-insurance-form-section-input-file-group__label">
                  {watch('arquivo')?.[0]?.name ? (
                    <span className="health-insurance-form-section-input-file-group__span">
                      <BiFolderOpen />{' '}
                      {`${watch('arquivo')[0]?.name.slice(0, 40)}`}{' '}
                    </span>
                  ) : (
                    <span className="health-insurance-form-section-input-file-group__span">
                      + Adicionar um documento.{' '}
                    </span>
                  )}
                  <input
                    className="health-insurance-form-section-input-file-group__input"
                    {...register('arquivo', { required: false })}
                    type="file"
                  />
                </label>
                <div className="health-insurance-form-section-button-group">
                  {watch('arquivo')?.[0]?.name && (
                    <button
                      className="health-insurance-form-section-remove__button"
                      type="button"
                      onClick={() => {
                        setValue('arquivo', null);
                      }}
                    >
                      <CloseIcon />
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div>
              <div className="health-insurance-form-section-input-file-title">
                <label className="health-insurance-form-section-input-file-title__span">
                  Possui algum formulário externo para adição de novos
                  colaboradores? faça o upload dele
                </label>
              </div>
              <div className="health-insurance-form-section-input-file-group">
                <label className="health-insurance-form-section-input-file-group__label">
                  {watch(`formExt`)?.[0]?.name ? (
                    <span className="health-insurance-form-section-input-file-group__span">
                      <BiFolderOpen />{' '}
                      {`${watch(`formExt`)[0]?.name.slice(0, 40)}`}{' '}
                    </span>
                  ) : (
                    <span className="health-insurance-form-section-input-file-group__span">
                      + Adicionar um formulário.{' '}
                    </span>
                  )}
                  <input
                    className="health-insurance-form-section-input-file-group__input"
                    {...register(`formExt`, { required: false })}
                    type="file"
                  />
                </label>
                <div className="health-insurance-form-section-button-group">
                  {watch(`formExt`)?.[0]?.name && (
                    <button
                      className="health-insurance-form-section-remove__button"
                      type="button"
                      onClick={() => {
                        setValue(`formExt`, null);
                      }}
                    >
                      <CloseIcon />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div>
            <Button type="submit">
              <span>Salvar</span>
              <FaSave />
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default HealthInsurance;
