import React from 'react';
import { Wraper } from './styles';
import { BsArrowRight } from "react-icons/bs";
import InputMask from 'react-input-mask';
import { useForm, Controller } from "react-hook-form";
import DatePicker, { registerLocale } from "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';
import api from 'services/api';
import banks from './banks.json';
import { useEmitTicket } from "contexts/emitTicket";

registerLocale('ptBR', ptBR);

const InfosTitular = () => {
  const { register, handleSubmit, setError, control, watch, setValue, formState: { errors } } = useForm();
  const {
    externalForm,
    updateExternalForm,
    updateExternalFormStage,
  } = useEmitTicket();

  const { infosTitular } = externalForm;
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


  const changeStage = (stage) => {
    updateExternalFormStage(stage);
  }

  const goForward = (data) => {
    updateExternalForm({ infosTitular: { ...data } });
    changeStage('VinculoEmpresa');
  }

  const handleCep = (fun, target) => {
    fun.onChange(target.value)
    if (target.value.replace(/\D/gim, '').length >= 8) {
      api.get(`https://viacep.com.br/ws/${target.value}/json/`)
        .then((resp, error) => {
          if (!resp.data.erro) {
            const { localidade, logradouro, uf } = resp?.data
            setValue('logradouro', logradouro)
            setValue('localidade', `${localidade} / ${uf}`)
          } else {
            console.error(error)
            setError("cep", {
              type: "invalido",
              message: "Cep Inválido",
            });
          }
        })
        .catch(error => {
          console.error(error)
        })
    }
    if (target.value.replace(/\D/gim, '').length === 0) {
      setValue('logradouro', '')
      setValue('localidade', '')
    }
  }

  return (
    <Wraper>
      <fieldset>
        <legend>Informações Gerais do Titular</legend>
        <div className="input-group">
          <div>
            {errors.nomeCompleto && <span className="errorsMsg">Campo obrigatório</span>}
            <input type="text"
              {...register("nomeCompleto", { required: true })}
              defaultValue={infosTitular?.nomeCompleto}
              name="nomeCompleto"
              maxLength={50}
              placeholder="Nome Completo" />
          </div>
          <div>
            {errors.email?.type === 'required' && <span className="errorsMsg">Campo obrigatório</span>}
            {errors.email?.type === 'pattern' && <span className="errorsMsg">Formato do email inválido</span>}
            <input type="email"
              {...register("email", { required: true, pattern: regex },)}
              defaultValue={infosTitular?.email}
              name="email"
              placeholder="E-mail de Trabalho" />
          </div>
          <div>
            {errors.telefone && <span className="errorsMsg">Campo obrigatório</span>}
            <Controller
              name="telefone"
              control={control}
              defaultValue={infosTitular?.telefone || ''}
              rules={{ required: true }}
              render={({ field }) => <InputMask
                {...field}
                maskChar={null}
                mask="(99) 999999999"
                type="text"
                placeholder="Telefone Celular" />}
            />
          </div>
          <div>
            {errors.cep?.type === 'required' && <span className="errorsMsg">Campo obrigatório</span>}
            {errors.cep?.type === 'invalido' && <span className="errorsMsg">{errors.cep.message}</span>}
            <Controller
              name="cep"
              control={control}
              defaultValue={infosTitular?.cep}
              rules={{ required: true }}
              render={({ field }) => <InputMask
                value={field.value}
                maskChar={null}
                mask="99999-999"
                type="text"
                onChange={({ target }) => handleCep(field, target)}
                placeholder="CEP Residencial" />}
            />

          </div>
          {(watch('logradouro') || infosTitular?.logradouro) &&
            <div className="dados-endereco">
              <input className="rua"
                type="text"
                {...register("logradouro")}
                defaultValue={infosTitular?.logradouro}
                name="logradouro"
                readOnly
                placeholder="Rua" />
              <input className="numero"
                type="text"
                {...register("numero", { required: false })}
                defaultValue={infosTitular?.numero}
                name="numero"
                maxLength={10}
                placeholder="Número" />
              <input className="cidade"
                type="text"
                {...register("localidade")}
                defaultValue={infosTitular?.localidade}
                name="localidade"
                readOnly
                placeholder="Cidade / ES" />
              <input className="complemento"
                type="text"
                {...register("complemento", { required: false })}
                defaultValue={infosTitular?.complemento}
                name="complemento"
                maxLength={30}
                placeholder="Complemento" />
            </div>
          }
          <div>
            {errors.dataNascimento && <span className="errorsMsg">Campo obrigatório</span>}
            <Controller
              name="dataNascimento"
              control={control}
              defaultValue={infosTitular?.dataNascimento ? new Date(infosTitular?.dataNascimento) : null}
              rules={{ required: true }}
              render={({ field: { onChange, value, } }) => <DatePicker
                className="input-data"
                selected={value}
                onChange={onChange}
                locale="ptBR"
                maxDate={new Date()}
                placeholderText="Data de Nascimento"
                dateFormat="dd/MM/yyyy"
              />}
            />
          </div>
          <div>
            {errors.rg && <span className="errorsMsg">Campo obrigatório</span>}
            <Controller
              name="rg"
              control={control}
              defaultValue={infosTitular?.rg}
              rules={{ required: true }}
              render={({ field }) => <InputMask
                {...field}
                maskChar={null}
                mask="99.999.999-9"
                type="text"
                maxLength={13}
                placeholder="RG" />}
            />
          </div>

          <div className="inline-group-input">
            <div>
              {errors.orgaoEmissor && <span className="errorsMsg">Campo obrigatório</span>}
              <input type="text"
                {...register("orgaoEmissor",
                  { required: true },
                )}
                defaultValue={infosTitular?.orgaoEmissor}
                name="orgaoEmissor"
                maxLength={13}
                placeholder="Órgão Emissor" />
            </div>
            <div>
              {errors.dataExpedicao && <span className="errorsMsg">Campo obrigatório</span>}
              <Controller
                name="dataExpedicao"
                control={control}
                defaultValue={infosTitular?.dataExpedicao ? new Date(infosTitular?.dataExpedicao) : null}
                rules={{ required: true }}
                render={({ field: { onChange, value, } }) => <DatePicker
                  className="input-data"
                  selected={value}
                  locale="ptBR"
                  onChange={onChange}
                  maxDate={new Date()}
                  placeholderText="Data de Expedição"
                  dateFormat="dd/MM/yyyy"
                />}
              />
            </div>
          </div>

          <div>
            {errors.cpf && <span className="errorsMsg">Campo obrigatório</span>}
            <Controller
              name="cpf"
              control={control}
              defaultValue={infosTitular?.cpf}
              rules={{ required: true }}
              render={({ field }) => <InputMask
                {...field}
                maskChar={null}
                mask="999.999.999-99"
                type="text"
                placeholder="CPF" />}
            />
          </div>

          <div className="select-group">
            <label>Sexo/Gênero</label>
            {errors.sexo && <span className="errorsMsg">Campo obrigatório</span>}
            <select
              {...register('sexo', { required: true })}
              defaultValue={infosTitular?.sexo}>
              <option value="" disabled={infosTitular?.sexo} hidden={infosTitular?.sexo}>Selecione</option>
              <option value="Masculino">Masculino</option>
              <option value="Feminino">Feminino</option>
            </select>
          </div>
          <div className="select-group">
            <label >Estado Civil</label>
            {errors.estadoCivil && <span className="errorsMsg">Campo obrigatório</span>}
            <select
              {...register('estadoCivil', { required: true })}
              defaultValue={infosTitular?.estadoCivil}>
              <option value="" disabled={infosTitular?.estadoCivil} hidden={infosTitular?.estadoCivil}>Selecione</option>
              <option value="Solteiro">Solteiro</option>
              <option value="Casado">Casado</option>
              <option value="Divorciado">Divorciado</option>
              <option value="Viúvo(a)">Viúvo(a)</option>
            </select>
          </div>
          <div>
            {errors.nomeMae && <span className="errorsMsg">Campo obrigatório</span>}
            <input type="text"
              {...register("nomeMae", { required: true })}
              defaultValue={infosTitular?.nomeMae}
              name="nomeMae"
              maxLength={50}
              placeholder="Nome da sua Mãe" />
          </div>

          <div className="select-group">
            <label >Dados Bancários</label>
            {errors.banco && <span className="errorsMsg">Campo obrigatório</span>}
            <select
              {...register('banco', { required: true })}
              defaultValue={infosTitular?.banco}>
              <option value="" disabled={infosTitular?.banco} hidden={infosTitular?.banco}>Selecione seu Banco</option>
              {
                banks.map((element) => {
                  return (
                    <option key={element.id} value={`${element.id} ${element.LongName}`}>{element.id} {element.ShortName}</option>
                  )
                })
              }
            </select>
          </div>
          <div className="inline-group-input">
            <div>
              {errors.agencia && <span className="errorsMsg">Campo obrigatório</span>}
              <input type="text"
                {...register("agencia",
                  { required: true },
                )}
                defaultValue={infosTitular?.agencia}
                name="agencia"
                maxLength={13}
                placeholder="Agencia" />
            </div>
            <div>
              {errors.conta && <span className="errorsMsg">Campo obrigatório</span>}
              <input type="text"
                {...register("conta",
                  { required: true },
                )}
                defaultValue={infosTitular?.conta}
                name="conta"
                maxLength={13}
                placeholder="Conta" />
            </div>
          </div>
        </div>
      </fieldset>
      <div className="button-areas">
        <button type="button" onClick={() => changeStage('programaBeneficios')}>Voltar</button>
        <button type="button" onClick={handleSubmit(goForward)} >Avançar <BsArrowRight /></button>
      </div>
    </Wraper>
  )
}

export default InfosTitular
