import { useEffect, useState } from 'react';
import { ReactComponent as LogoGenoa } from 'assets/imgs/svg/logo-genoa-login-corretor.svg';
import { ReactComponent as WorkerGenoa } from 'assets/imgs/svg/worker-genoa-login-corretor.svg';
import { useHistory } from 'react-router-dom';
import {
  Backdrop,
  Button,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import {
  LoginGrid,
  LoginInputColumn,
  LogoRow,
  SecondRow,
  Wrapper
} from './styles';
import services from 'apps/broker/services';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const RecuperarSenhaCorretora = () => {
  const userKey = 'jwt_token_user';
  const history = useHistory();
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [errorCorretora, setErrorCorretora] = useState();
  const [resp, setResp] = useState();
  const [userCredential, setUserCredential] = useState('');

  const regexCNPJ = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/;

  const corretoraOnSubmit = async () => {
    setLoading(true);
    const body = {
      cnpj: userCredential.replace(regexCNPJ, '$1.$2.$3/$4-$5')
    };

    const response = await services.user.createResetPassword(body);
    if (response.status === 200) {
      setLoading(false);
      setErrorCorretora('');
      setResp('Solicitação enviada com sucesso para seu email');
      setTimeout(() => {
        setResp('');
      }, 3000);
    } else {
      setLoading(false);
      setResp('');
      setErrorCorretora('Algo deu errado, tente novamente mais tarde!');
      setTimeout(() => {
        setErrorCorretora('');
      }, 3000);
    }
  };
  const handleClose = () => {
    setLoading(false);
  };

  const checkSubmit = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      corretoraOnSubmit();
    }
  };

  useEffect(() => {
    const json = localStorage.getItem(userKey);
    const userData = JSON.parse(json);

    if (userData?.corretora) {
      history.push('/corretor/home');
    }
    if (userData?.estipulante) {
      history.push('/rh/home');
    }
  }, [history]);

  return (
    <Wrapper>
      <Backdrop
        className={classes.backdrop}
        open={loading}
        onClick={handleClose}
      >
        <CircularProgress size={120} thickness={2} color="inherit" />
      </Backdrop>
      <LogoRow>
        <LogoGenoa />
      </LogoRow>
      <SecondRow>
        <LoginGrid>
          <LoginInputColumn>
            <div className="title">
              <span>Boas vindas ao</span>
              <span className="heavy-title">Zeca!</span>
            </div>
            <div className="subtitle">
              <span>
                Insira o CNPJ ou e-mail cadastrado. Nós lhe enviaremos um link
                de recadastro.
              </span>
            </div>
            <form autoComplete="off">
              <div className="login-input-wrapper">
                <div className="login-input-container">
                  <label>Email/CNPJ</label>
                  <input
                    type={'text'}
                    value={userCredential.replace(regexCNPJ, '$1.$2.$3/$4-$5')}
                    auto-complete="new-email"
                    onKeyDown={checkSubmit}
                    onChange={(e) => setUserCredential(e.target.value)}
                  />
                </div>
              </div>
            </form>
            <Button className="login-button" onClick={corretoraOnSubmit}>
              <span>Enviar</span>
            </Button>

            <div className="flex-center">
              {errorCorretora && (
                <div className="erro-msg"> {errorCorretora}</div>
              )}
              {resp && <div className="sucesso-msg">{resp}</div>}
            </div>
          </LoginInputColumn>
          <WorkerGenoa className="worker-img" />
        </LoginGrid>
      </SecondRow>
    </Wrapper>
  );
};

export default RecuperarSenhaCorretora;
