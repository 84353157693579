import styled from 'styled-components';
import colors from 'styles/colors';

export const Wrapper = styled.div`
  .form-novas-vidas {
    display: flex;
    flex-direction: column;
    height: 400px;

    .vidas-title {
      color: ${colors.genoaBlue};
      font-size: 17px;
      font-family: 'Avenir Next GEO W05 Bold';
    }

    form {
      padding-left: 15px;
      padding-right: 15px;
    }
    
    .buttons-container {
      display: grid;
      align-items: flex-start;
      grid-template-columns: 48px 320px 120px;
      column-gap: 10px;
      margin-top: 30px;
      height: 25px;

      .hover-pointer {
        :hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }

      .btn-vidas {
        font-size: 16px;

        color: ${colors.genoaBlue};
        cursor: pointer;
        border: none;
        background: transparent;
        font-family: 'Avenir Next GEO W05 Demi';
      }
    }

    input {
      font-family: 'Avenir Next GEO W05 Regular';
    }

    label.default {
      color: ${colors.genoaGrey};
      font-family: 'Avenir Next GEO W05 Demi';
      font-size: 1rem;
      margin-bottom: 10px;
      display: block;
    }
  }

  .input-file-group {
    display: flex;
  }

  .label-file {
    font-size: 16px;
    color: ${colors.genoaBlue};

    cursor: pointer;
    background: transparent;
    border: none;
    font-family: 'Avenir Next GEO W05 Demi';
    &:hover {
    }

    input {
      display: none;
    }
    span {
      display: flex;
      align-items: center;
      color: ${colors.genoaBlue};
      font-size: 16px;
      svg {
        font-size: 1.3rem;
      }
    }
  }

  button.btn-clear-vidas {
    background: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
    margin-left: 10px;
    cursor: pointer;

    svg {
      width: 16px;
    }
  }

  .data-resp {
    margin-top: 50px;
    color: ${colors.genoaBlue};
    font-size: 16px;
    font-family: 'Avenir Next GEO W05 Bold';
  }

  .data-resp-errors {
    .data-error-columns {
      overflow-y: auto;
      max-height: 350px;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    .main-error-message {
      font-family: 'Avenir Next GEO W05 Bold';
      margin-bottom: 30px;
      color: ${colors.genoaBlue};
      font-size: 20px;
      text-decoration: underline;
      align-items: center;
    }
    .line-error-message {
      margin-top: 15px;
    }

    .warning-icon {
      margin-right: 10px;
      margin-bottom: -2px;
    }

    .error-column-message {
      margin-top: 5px;
      margin-left: 10px;
      font-family: 'Avenir Next GEO W05 Regular';
    }
  }
`;
