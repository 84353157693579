import http, { TOKEN_LABEL } from 'services/http';

const login = async ({ credential, password }) => {
  const regexCNPJ = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/;

  const payload = {
    email: credential.replace(regexCNPJ, '$1.$2.$3/$4-$5'),
    password: password
  };

  const res = await http.post('/login/rh', payload);
  sessionStorage.setItem(TOKEN_LABEL, JSON.stringify(res.data));

  return res;
};

const modules = {
  login
};

export default modules;
