import styled from 'styled-components';
import colors from 'styles/colors';

export const BlockInfo = styled.section`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 50px 0px;

  .divIcon {
    svg {
      font-size: 26px;
      color: ${colors.genoaDarkBlue};
    }
  }

  .infos {
    padding-left: 20px;

    .estipulante {
      color: ${colors.genoaDarkBlue};
    }

    .blok-info {
      display: grid;
      grid-template-columns: 40px 1fr;
      margin-bottom: 40px;

      .header-info {
        margin-bottom: 10px;
        display: flex;

        h4 {
          font-family: 'Avenir Next GEO W05 Bold';
          margin-bottom: 30px;
        }

        span {
          font-size: 18px;
          font-family: 'Avenir Next GEO W05 Demi';

          .subsEs {
            font-size: 14px;
            color: ${colors.genoaBlue};
          }
        }

        button {
          display: flex;
          background: none;
          border: none;
          font-size: 18px;
          margin-left: 10px;
          cursor: pointer;
        }
      }

      .dados {
        display: flex;
        flex-direction: column;

        row-gap: 3px;
      }

      .add-sub {
        padding-left: 20px;
        margin-top: 30px;
      }

      .subestipulante_column {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
      }
    }

    .gridcard {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
    }
  }

  .contatos-container {
    padding-left: 20px;
    .contato-info {
      display: grid;
      grid-template-columns: 40px 1fr;

      .infos-container {
        .blok-info {
          display: grid;
          grid-template-columns: 40px 1fr;
          margin-bottom: 40px;

          .header-info {
            margin-bottom: 10px;
            display: flex;

            h4 {
              font-family: 'Avenir Next GEO W05 Bold';
              margin-bottom: 30px;
            }

            span {
              font-size: 18px;
              font-family: 'Avenir Next GEO W05 Demi';

              .subsEs {
                font-size: 14px;
                color: ${colors.genoaBlue};
              }
            }

            button {
              display: flex;
              background: none;
              border: none;
              font-size: 18px;
              margin-left: 10px;
              cursor: pointer;
            }
          }

          .dados {
            display: flex;
            flex-direction: column;

            row-gap: 3px;
          }

          .add-sub {
            margin-top: 30px;
          }
        }

        .gridcard {
          display: flex;
          align-items: center;
          margin-bottom: 30px;
        }
      }
    }
  }
`;
