import { useState, useEffect } from 'react';
import { Button, Fade } from '@material-ui/core';
import { MdClose } from 'react-icons/md';
import { FilterContainer } from './styles';
import DatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';

registerLocale('ptBR', ptBR);

const DateFilterPopper = ({
  openFilter,
  startDate,
  finalDate,
  setStartDate,
  setFinalDate,
  getRelatorioMovimentacoes,
  setOpenFilter
}) => {
  // Estado para posicionamento e dimensionamento de Popper de Filtragem
  const [filterTop, setFilterTop] = useState(0);
  const [filterLeft, setFilterLeft] = useState(0);

  useEffect(() => {
    const setFilterPopperPositionInScreen = () => {
      const filterIcon = document.querySelector('.filter_icon_btn');

      setFilterTop(filterIcon?.offsetTop + 36);
      setFilterLeft(filterIcon?.offsetLeft);

      return;
    };

    setFilterPopperPositionInScreen();

    window.addEventListener('resize', setFilterPopperPositionInScreen);
  }, []);

  return (
    <Fade in={openFilter} timeout={350}>
      <FilterContainer
        style={{
          top: `${filterTop}px`,
          left: `${filterLeft - 200 + 31}px`
        }}
      >
        <div className="filter_header">
          <span className="filter_header__title">Filtrar por</span>
          <MdClose
            className="filter_header__close_btn"
            size={17}
            onClick={() => setOpenFilter(false)}
          />
        </div>

        <div className="filter_content">
          <span className="filter_content__title">Período</span>

          <div className="date_picker__container">
            <span className="date_picker__span">Data inicial</span>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              autoComplete="new-date"
              locale="ptBR"
              maxDate={finalDate ? finalDate : new Date()}
              placeholderText="Insira uma data"
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className="date_picker__container">
            <span className="date_picker__span">Data final</span>

            <DatePicker
              selected={finalDate}
              onChange={(date) => setFinalDate(date)}
              autoComplete="new-date"
              locale="ptBR"
              minDate={startDate ? startDate : undefined}
              maxDate={new Date()}
              placeholderText="Insira uma data"
              dateFormat="dd/MM/yyyy"
            />
          </div>
        </div>
        <div className="date_picker__btn_container">
          <Button
            className={
              !(startDate && finalDate)
                ? 'date_picker__btn_disabled'
                : 'date_picker__btn'
            }
            onClick={getRelatorioMovimentacoes}
            disabled={!(startDate && finalDate)}
          >
            Filtrar
          </Button>
        </div>
      </FilterContainer>
    </Fade>
  );
};

export default DateFilterPopper;
