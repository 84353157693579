import styled from "styled-components";
import colors from "styles/colors";

export const SelectComponent = styled.div`

  @media (max-height: 580px) {
    display: flex;
    flex-direction: column;
  
    label {
      color: ${colors.genoaGrey};
      white-space: nowrap;
      position: relative;
      height: 13.34px;
      font-size: 10.672px;
      transform-origin: top left;
      top: 15.341px;
      transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms
    }
  
    label.translate {
      transform: translate(0, -16.675px) scale(0.75);
      font-family: Avenir Next GEO W05 Demi;
      letter-spacing: 0.2px;
      transform-origin: top left;
      color: ${colors.rhBlue};
      transition: color 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms
    }
  
    .options__wrapper {
      z-index: 2000;
      display: flex;
      flex-direction: column;
      font-size: 10.3px;
      margin-top: 2px;
      border: 1px solid ${colors.rhBlue};
      border-top: none;
      border-radius: 2.67px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      padding-bottom: 2.67px;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
  
      span {
        padding-left: 2.67px;
        padding-right: 2.67px;
  
        :hover {
          color: ${colors.white};
          background-color: ${colors.rhBlue};
          cursor: default;
        }
      }
  
      ::-webkit-scrollbar {
        width: 4px;
        background: transparent;
      }
      ::-webkit-scrollbar-thumb {
        background: ${colors.rhBlue};
        box-shadow: none;
        border-radius: 10.3px;
      }
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px grey;
        border-radius: 10.3px;
      }
    }
  
    
    
    .select__trigger {
      border: none;
      z-index: 1000;
      height: 14.674px;
      font-size: 10.3px;
      background-color: transparent !important;
      font-family: Avenir Next GEO W05 Regular;
      padding-bottom: 3.35px;  
      border-bottom: 2px ${colors.rhTabLightGrey} solid;
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='7px' height='6px' viewBox='0 0 7 6' version='1.1'%3E%3Cg id='surface1'%3E%3Cpath style=' stroke:none;fill-rule:nonzero;fill:%2393A9F5;fill-opacity:1;' d='M 3.5 6 L 0.46875 0.5 L 6.53125 0.5 Z M 3.5 6 '/%3E%3C/g%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position-x: 98%;
      background-position-y: 6.5px;
      cursor: default;
  
      span{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        width: 95%;
      }
  
      :focus {
        outline: none;
      }
  
      :hover{
        border-bottom: 2px ${colors.rhTabDarkGrey} solid;
      }
      
    }
  
    div.focused {
      transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      border-bottom: 1.334px solid ${colors.rhBlue};
      height: 15.272px;
      
      :hover{
        border-bottom: 1.334px ${colors.rhBlue} solid;
      }
      
    }
  
    div.disabled {
      transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      border-bottom: 2px solid ${colors.rhTabLightGrey};
      background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 9L0.73686 0.75L10.2631 0.75L5.5 9Z' fill='%23F1F1F1'/%3E%3C/svg%3E%0A");
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='7px' height='6px' viewBox='0 0 7 6' version='1.1'%3E%3Cg id='surface1'%3E%3Cpath style=' stroke:none;fill-rule:nonzero;fill:%23F1F1F1;fill-opacity:1;' d='M 3.5 6 L 0.46875 0.5 L 6.53125 0.5 Z M 3.5 6 '/%3E%3C/g%3E%3C/svg%3E%0A");
      
      :hover{
        border-bottom: 2px ${colors.rhTabLightGrey} solid;
      }
    }
  
    label.disabled {
      color: ${colors.rhTabLightGrey};
    }
  
    .popper-container {
      z-index: 20000;
    }
  }

  @media (min-height: 580px) and (max-height: 650px) {
    display: flex;
    flex-direction: column;
  
    label {
      color: ${colors.genoaGrey};
      white-space: nowrap;
      position: relative;
      height: 16px;
      font-size: 12.8px;
      transform-origin: top left;
      top: 18.4px;
      transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms
    }
  
    label.translate {
      transform: translate(0, -20px) scale(0.75);
      font-family: Avenir Next GEO W05 Demi;
      letter-spacing: 0.24px;
      transform-origin: top left;
      color: ${colors.rhBlue};
      transition: color 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms
    }
  
    .options__wrapper {
      z-index: 2000;
      display: flex;
      flex-direction: column;
      font-size: 12.8px;
      margin-top: 2.4px;
      border: 1px solid ${colors.rhBlue};
      border-top: none;
      border-radius: 3.2px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      padding-bottom: 3.2px;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
  
      span {
        padding-left: 3.2px;
        padding-right: 3.2px;
  
        :hover {
          color: ${colors.white};
          background-color: ${colors.rhBlue};
          cursor: default;
        }
      }
  
      ::-webkit-scrollbar {
        width: 4.8px;
        background: transparent;
      }
      ::-webkit-scrollbar-thumb {
        background: ${colors.rhBlue};
        box-shadow: none;
        border-radius: 11.2px;
      }
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px grey;
        border-radius: 11.2px;
      }
    }
  
    
    
    .select__trigger {
      border: none;
      z-index: 1000;
      height: 17.6px;
      font-size: 12.8px;
      background-color: transparent !important;
      font-family: Avenir Next GEO W05 Regular;
      padding-bottom: 4px;  
      border-bottom: 2.4px ${colors.rhTabLightGrey} solid;
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='9px' height='7px' viewBox='0 0 8 7' version='1.1'%3E%3Cg id='surface1'%3E%3Cpath style=' stroke:none;fill-rule:nonzero;fill:%2393A9F5;fill-opacity:1;' d='M 4 7 L 0.535156 0.582031 L 7.464844 0.582031 Z M 4 7 '/%3E%3C/g%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position-x: 98%;
      background-position-y: 7.2px;
      cursor: default;
  
      span{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        width: 95%;
      }
  
      :focus {
        outline: none;
      }
  
      :hover{
        border-bottom: 2.4px ${colors.rhTabDarkGrey} solid;
      }
      
    }
  
    div.focused {
      transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      border-bottom: 1.6px solid ${colors.rhBlue};
      height: 18.4px;
      
      :hover{
        border-bottom: 1.6px ${colors.rhBlue} solid;
      }
      
    }
  
    div.disabled {
      transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      border-bottom: 2.4px solid ${colors.rhTabLightGrey};
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='9px' height='7px' viewBox='0 0 8 7' version='1.1'%3E%3Cg id='surface1'%3E%3Cpath style=' stroke:none;fill-rule:nonzero;fill:%23F1F1F1;fill-opacity:1;' d='M 4 7 L 0.535156 0.582031 L 7.464844 0.582031 Z M 4 7 '/%3E%3C/g%3E%3C/svg%3E%0A");

      :hover{
        border-bottom: 2.4px ${colors.rhTabLightGrey} solid;
      }
    }
  
    label.disabled {
      color: ${colors.rhTabLightGrey};
    }
  
    .popper-container {
      z-index: 20000;
    }
  }

  @media (min-height: 650px) and (max-height: 720px) {
    display: flex;
    flex-direction: column;
  
    label {
      color: ${colors.genoaGrey};
      white-space: nowrap;
      position: relative;
      height: 18px;
      font-size: 14.4px;
      transform-origin: top left;
      top: 20.7px;
      transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms
    }
  
    label.translate {
      transform: translate(0, -22.5px) scale(0.75);
      font-family: Avenir Next GEO W05 Demi;
      letter-spacing: 0.207px;
      transform-origin: top left;
      color: ${colors.rhBlue};
      transition: color 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms
    }
  
    .options__wrapper {
      z-index: 2000;
      display: flex;
      flex-direction: column;
      font-size: 14.4px;
      margin-top: 2.07px;
      border: 0.9px solid ${colors.rhBlue};
      border-top: none;
      border-radius: 3.6px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      padding-bottom: 3.6px;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
  
      span {
        padding-left: 3.6px;
        padding-right: 3.6px;
  
        :hover {
          color: ${colors.white};
          background-color: ${colors.rhBlue};
          cursor: default;
        }
      }
  
      ::-webkit-scrollbar {
        width: 5.4x;
        background: transparent;
      }
      ::-webkit-scrollbar-thumb {
        background: ${colors.rhBlue};
        box-shadow: none;
        border-radius: 12.6px;
      }
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 1.8px grey;
        border-radius: 12.6px;
      }
    }
  
    
    
    .select__trigger {
      border: none;
      z-index: 1000;
      height: 19.8px;
      font-size: 14.4px;
      background-color: transparent !important;
      font-family: Avenir Next GEO W05 Regular;
      padding-bottom: 4.5px;  
      border-bottom: 2.7px ${colors.rhTabLightGrey} solid;
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='10px' height='8px' viewBox='0 0 9 8' version='1.1'%3E%3Cg id='surface1'%3E%3Cpath style=' stroke:none;fill-rule:nonzero;fill:%2393A9F5;fill-opacity:1;' d='M 4.5 8 L 0.601562 0.667969 L 8.398438 0.667969 Z M 4.5 8 '/%3E%3C/g%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position-x: 98%;
      background-position-y: 8.1px;
      cursor: default;
  
      span{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        width: 95%;
      }
  
      :focus {
        outline: none;
      }
  
      :hover{
        border-bottom: 2.7px ${colors.rhTabDarkGrey} solid;
      }
      
    }
  
    div.focused {
      transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      border-bottom: 1.8px solid ${colors.rhBlue};
      height: 20.7px;
      
      :hover{
        border-bottom: 1.8px ${colors.rhBlue} solid;
      }
      
    }
  
    div.disabled {
      transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      border-bottom: 2.7px solid ${colors.rhTabLightGrey};
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='10px' height='8px' viewBox='0 0 9 8' version='1.1'%3E%3Cg id='surface1'%3E%3Cpath style=' stroke:none;fill-rule:nonzero;fill: %23F1F1F1;fill-opacity:1;' d='M 4.5 8 L 0.601562 0.667969 L 8.398438 0.667969 Z M 4.5 8 '/%3E%3C/g%3E%3C/svg%3E%0A");
  
      :hover{
        border-bottom: 2.7px ${colors.rhTabLightGrey} solid;
      }
    }
  
    label.disabled {
      color: ${colors.rhTabLightGrey};
    }
  
    .popper-container {
      z-index: 20000;
    }
  }

  @media (min-height: 720px) {
    display: flex;
    flex-direction: column;
  
    label {
      color: ${colors.genoaGrey};
      white-space: nowrap;
      position: relative;
      height: 20px;
      font-size: 16px;
      transform-origin: top left;
      top: 23px;
      transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms
    }
  
    label.translate {
      transform: translate(0, -25px) scale(0.75);
      font-family: Avenir Next GEO W05 Demi;
      letter-spacing: 0.3px;
      transform-origin: top left;
      color: ${colors.rhBlue};
      transition: color 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms
    }
  
    .options__wrapper {
      z-index: 2000;
      display: flex;
      flex-direction: column;
      font-size: 16px;
      margin-top: 3px;
      border: 1px solid ${colors.rhBlue};
      border-top: none;
      border-radius: 4px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      padding-bottom: 4px;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
  
      span {
        padding-left: 4px;
        padding-right: 4px;
  
        :hover {
          color: ${colors.white};
          background-color: ${colors.rhBlue};
          cursor: default;
        }
      }
  
      ::-webkit-scrollbar {
        width: 6px;
        background: transparent;
      }
      ::-webkit-scrollbar-thumb {
        background: ${colors.rhBlue};
        box-shadow: none;
        border-radius: 14px;
      }
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px grey;
        border-radius: 14px;
      }
    }
  
    
    
    .select__trigger {
      border: none;
      z-index: 1000;
      height: 22px;
      font-size: 16px;
      background-color: transparent !important;
      font-family: Avenir Next GEO W05 Regular;
      padding-bottom: 5px;  
      border-bottom: 3px ${colors.rhTabLightGrey} solid;
      background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 9L0.73686 0.75L10.2631 0.75L5.5 9Z' fill='%2393A9F5'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position-x: 98%;
      background-position-y: 9px;
      cursor: default;
  
      span{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        width: 95%;
      }
  
      :focus {
        outline: none;
      }
  
      :hover{
        border-bottom: 3px ${colors.rhTabDarkGrey} solid;
      }
      
    }
  
    div.focused {
      transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      border-bottom: 2px solid ${colors.rhBlue};
      height: 23px;
      
      :hover{
        border-bottom: 2px ${colors.rhBlue} solid;
      }
      
    }
  
    div.disabled {
      transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      border-bottom: 3px solid ${colors.rhTabLightGrey};
      background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 9L0.73686 0.75L10.2631 0.75L5.5 9Z' fill='%23F1F1F1'/%3E%3C/svg%3E%0A");
  
      :hover{
        border-bottom: 3px ${colors.rhTabLightGrey} solid;
      }
    }
  
    label.disabled {
      color: ${colors.rhTabLightGrey};
    }
  
    .popper-container {
      z-index: 20000;
    }
  }
 
`