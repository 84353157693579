import moment from 'moment';

export function idade(data_nascimento) {
  // console.log('data nasc', data_nascimento)
  return moment().diff(data_nascimento, 'years', false);
}

export function dataDiff(createdDate) {
  const dataAntiga = new Date(createdDate);
  const dataAtual = new Date();
  var diferenca = Math.abs(dataAntiga - dataAtual); //diferença em milésimos e positivo
  var dia = 1000 * 60 * 60 * 24; // milésimos de segundo correspondente a um dia
  var total = Math.round(diferenca / dia); //valor total de dias arredondado
  return total;
}

export function dataDiffYear(createdDate) {
  const dataAntiga = new Date(createdDate);
  const dataAtual = new Date();
  var diferenca = Math.abs(dataAntiga - dataAtual); //diferença em milésimos e positivo
  var dia = 1000 * 60 * 60 * 24 * 365; // milésimos de segundo correspondente a um dia
  var total = Math.round(diferenca / dia); //valor total de dias arredondado
  return total;
}

export function transformDate(date) {
  let data = new Date(date);
  data.setHours(data.getHours + 3 * 60 * 60 * 1000);
  return data;
}

export const formatUTC = (dateInt, addOffset = false) => {
  let date = !dateInt || dateInt.length < 1 ? new Date() : new Date(dateInt);
  if (typeof dateInt === 'string') {
    return date;
  } else {
    const offsetDate = new Date();
    offsetDate.setTime(date.getTime() + 180 * 60000);
    return offsetDate;
  }
};

export const parseDate = (dateStr) => {
  if (dateStr) {
    const [day, month, year] = dateStr.split('/');
    const date = new Date(year, month - 1, day);
    return !isNaN(date) ? date : null;
  } else {
    return null;
  }
};
