import { useState } from 'react';
import { Wrapper } from './styles';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '&$expanded': {
      minHeight: '54px'
    }
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '100%',
    flexShrink: 0,
    fontFamily: 'Avenir Next GEO W05 Demi'
  },

  acordionItem: {
    background: 'none',
    boxShadow: 'none',
    '&::before': {
      background: 'none'
    }
  },

  content: {
    fontFamily: 'Avenir Next GEO W05 Regular',
    textAlign: 'justify'
  },
  titleG: {
    textTransform: 'uppercase',
    marginLeft: '15px',
    fontFamily: 'Avenir Next GEO W05 Demi'
  }
}));

const PerguntasRespostas = ({ perguntas }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Wrapper>
      <div className={classes.root}>
        {perguntas?.length ? (
          perguntas.map((item, index) => {
            return (
              <Accordion
                key={index}
                className={classes.acordionItem}
                expanded={expanded === item.id}
                onChange={handleChange(item.id)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.heading}>
                    {item.pergunta}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={classes.content}>
                    {item.resposta}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            );
          })
        ) : (
          <p className="warning">Ainda não existe duvidas cadastradas</p>
        )}
      </div>
    </Wrapper>
  );
};

export default PerguntasRespostas;
