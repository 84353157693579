import styled from "styled-components";
import colors from "styles/colors";

export const GraphDonutWrapper = styled.div`
  width: 200px;
  height: 181px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  justify-self: center;

  .graphBox {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .value {
    font-size: 1.7em;
    font-family: "Avenir Next Geo W05 Demi";
  }

  .tipo {
    font-size: 0.9em;
  }

  .label_tipo {
    position: absolute;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 70px;
    // background-color: ${colors.genoaBlue}45;
    transform: translateX(60px);
  }
`