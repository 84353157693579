import styled from 'styled-components';
import colors from 'styles/colors';

export const DialogWrapper = styled.div`
  width: 370px;
  height: 100px;
  background-color: ${colors.white};
  margin: 15px 25px;
  display: flex;
  flex-direction: column;

  -webkit-justify-content: space-between;
  justify-content: space-between;


`

export const DialogTitle = styled.div `
  display: flex;
  color: ${colors.genoaGrey};
`

export const DialogSubTitle = styled.div`
  margin-top: 5px;
  display: flex;
  color: ${colors.genoaGrey};
`

export const ConfirmationButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 40px;

  .button__container {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 7px;

    :hover {
      cursor: pointer;
      font-weight: bold;
    }
  }

  .button__confirm_request {
    color: ${colors.rhBlue};
  }

  .button__hold_request {
    color: ${colors.genoaPink};
  }
`

export const DialogLoadingSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%
`

export const RequestCompletedContainer = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
  -webkit-justify-content: space-between;
  justify-content: space-between;


`

