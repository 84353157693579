import React from "react";
import { ToastWrapper } from "./style";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { GrTag } from "react-icons/gr";
import { RiWhatsappFill } from "react-icons/ri";

const CustomToast = ({ demands, setOpenDrawer }) => {
  // console.log(demands)
  return (
    <ToastWrapper className="toast-wrapper">
      <div className="toast-header">
        <span>Você tem demandas pegando <b>fogo</b>!</span>
      </div>
      <div className="info-card-container">
        {
          demands?.urgentMovimentacoes?.length ? (
            <div className="info-card mov">
              <AiOutlineUsergroupAdd className="movimentacao-icon" />
              {demands?.urgentMovimentacoes?.length}
            </div>
          ) : ""
        }
        {
          demands?.urgentTickets?.count ? (
            <div className="info-card ticket">
              <GrTag className="ticket-icon" />
              {demands?.urgentTickets?.count}
            </div>
          ) : ""
        }
        {
          demands?.urgentConcierge?.length ? (
            <div className="info-card ticket">
              <RiWhatsappFill className="concierge-icon" />
              2
            </div>
          ) : ""
        }

      </div>
      <div className="toast-footer">
        <span>
          Clique
          <button
            onClick={() => setOpenDrawer(true)}
          >
            aqui
          </button>
          para visualizá-las</span>
      </div>
    </ToastWrapper>
  )
}

export default CustomToast