import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaTooth } from "react-icons/fa";
import { GiHealthNormal } from "react-icons/gi";
import InputStandard from "components/Input/InputStandard";
import { AbaSelecioneTitularWrapper } from "./styles-abaSelecioneTitular";

const AbaSelecioneTitular = (
  {
    state,
    setState,
    beneficiariosFilter,
    titularNameFilter,
    setTitularNameFilter,
    setBeneficiariosAdicionais,
    setInputValue,
    setInputFile,
    setSelectValue,
    camposInclusao,
    setIncluirSaude,
    setIncluirOdonto,
  }) => {
  const [height, setHeight] = React.useState(window.innerHeight);

  React.useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight)

    }
    window.addEventListener('resize', handleResize)
  });

  const selectTitularClickHandler = (titular) => {
    setState((prevState) => ({
      ...prevState,
      titular
    }))

    const event = {
      target: {
        value: titular.nome
      }
    }

    setTitularNameFilter(event)
  }

  const inputTitularNameChangeLocked = () => {
    setTitularNameFilter({
      target: {
        value: state['titular']?.nome
      }
    })
  }

  const resetInput = () => {
    setState((prevState) => ({
      ...prevState,
      titular: undefined,
      contrato_estipulante_saude: undefined,
      contrato_estipulante_odonto: undefined,
      tipo_plano_saude: undefined,
      tipo_plano_odonto: undefined,
    }))

    setIncluirSaude(false)
    setIncluirOdonto(false)

    const event = {
      target: {
        value: ""
      }
    }

    setTitularNameFilter(event)

    setBeneficiariosAdicionais((prevState) => {
      for (let key in prevState) {
        prevState[Number(key)]['incluirSaude'] = false
        prevState[Number(key)]['incluirOdonto'] = false
      }
      return prevState
    })
  }

  const renderContratoIcones = (item) => {
    const hasContratoSaude = item?.contratos.find((item) => item?.produto_id === 1 && item?.status === "Ativo")
    const hasContratoOdonto = item?.contratos.find((item) => item?.produto_id === 2 && item?.status === "Ativo")

    return (
      <div className="contrato-icones-container">
        {
          hasContratoSaude ? (
            <div>
              <GiHealthNormal size={12  * height / 745} />
            </div>
          ) : ""
        }
        {
          hasContratoOdonto ? (
            <div>
              <FaTooth size={12  * height / 745} />
            </div>
          ) : ""
        }
      </div>
    )
  }


  return (
    <AbaSelecioneTitularWrapper>
      <div className="aba-container">
        <div className="aba-input-container">
          <div className="aba-title-container">
            <span>Selecione Titular</span>
          </div>
        </div>

        <div className="table-container">

          <div className="input-container">
            <InputStandard
              label="Filtrar Titular por Nome"
              value={
                state['titular'] && state['titular']?.nome ?
                  state['titular']?.nome : titularNameFilter
              }
              setValue={state['titular'] && state['titular']?.nome ?
                inputTitularNameChangeLocked : setTitularNameFilter
              }
              name="nameFilter"
            />
            {
              state['titular'] && state['titular']?.nome ? (
                <AiOutlineCloseCircle
                  className="remove-file-icon"
                  size={18  * height / 745}
                  onClick={resetInput}
                />
              ) : <></>
            }
          </div>


          <div className="aba-input-row-table">
            <table>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Estipulante</th>
                  <th>Benefícios</th>
                </tr>
              </thead>
              <tbody>
                {
                  beneficiariosFilter?.map((item => (
                    <tr
                      onClick={() => selectTitularClickHandler(item)}
                      className={state['titular'] && state['titular']?.id === item.id ? "selected" : ""}
                    >
                      <td>{item.nome}</td>
                      <td>{item.estipulante}</td>
                      <td>
                        {
                          renderContratoIcones(item)
                        }
                      </td>
                    </tr>
                  )))
                }
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </AbaSelecioneTitularWrapper>
  )
}

export default AbaSelecioneTitular;
