import React from 'react';
import Header from 'apps/broker/components/Header';
import SidemenuRh from 'apps/broker/components/Sidebar';
import MainRh from 'apps/broker/components/MainContent';
import DadosEstipulante from './DadosEstipulante';
import { Wrapper, Content } from './styles';

const CadastroEstipulante = () => {
  return (
    <Wrapper>
      <SidemenuRh />
      <Content>
        <Header />
        <MainRh>
          <div className="main-painel">
            <section className="cadastro">
              <div className="title">
                <h1>Cadastre um Estipulante</h1>
              </div>
              <DadosEstipulante />
            </section>
          </div>
        </MainRh>
      </Content>
    </Wrapper>
  );
};

export default CadastroEstipulante;
