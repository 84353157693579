import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Aside } from './styles';
import { AiOutlineUsergroupAdd } from 'react-icons/ai';
import {
  BiLogOut,
  BiHome,
  BiBuildings,
  BiUser,
  BiCalendar
} from 'react-icons/bi';
import { RiContactsBookFill } from 'react-icons/ri';
import coinsure_logo_icon from 'assets/imgs/logo-coinsure-icon.svg';
import { FaTasks, FaWhatsapp } from 'react-icons/fa';
import { IoStatsChartSharp } from 'react-icons/io5';
import Badge from '@mui/material/Badge';
import { io } from 'socket.io-client';
import { MdOutlineAttachMoney } from 'react-icons/md';
import { useBrokerNotifications } from 'contexts/brokerNotifications';
import { useUser } from 'contexts/user';

const Navigation = () => {
  const { brokerNotifications, updateBrokerNotifications } =
    useBrokerNotifications();
  const sidemenuNotifications = brokerNotifications;

  const { user, logOut } = useUser();

  const [open, setOpen] = useState();
  const [socket, setSocket] = useState(null);
  const [movimentacoesAbertas, setMovimentacoesAbertas] = useState(0);
  const [ticketsAbertos, setTicketsAbertos] = useState(0);
  const [conciergesAberto, setConciergesAberto] = useState(0);
  const [negociosEmAndamentoAberto, setNegociosAbertos] = useState({});
  const [notificacaoAtividades, setNotificacaoAtividades] = useState(0);
  const [negociosSemResponsavel, setNegociosSemResponsavel] = useState(0);

  const notificacaoAtividadeByUsuario = () => {
    const atividadesVencidas = negociosEmAndamentoAberto?.vencidas?.filter(
      (item) => item.usuario_id === user.usuario_id
    );
    const atividadesAbertas = negociosEmAndamentoAberto?.abertas?.filter(
      (item) => item.usuario_id === user.usuario_id
    );

    let somaAtividades = 0;

    if (atividadesVencidas?.length === 0) {
      somaAtividades = atividadesAbertas?.length;
    } else if (atividadesAbertas?.length === 0) {
      somaAtividades = atividadesVencidas?.length;
    } else {
      somaAtividades = atividadesVencidas?.length + atividadesAbertas?.length;
    }

    setNotificacaoAtividades(Number(somaAtividades));
  };

  useEffect(() => {
    setMovimentacoesAbertas(sidemenuNotifications?.movimentacoesAbertas);
    setTicketsAbertos(sidemenuNotifications?.ticketsAbertos);
    setConciergesAberto(sidemenuNotifications?.conciergesAbertos);
    setNegociosAbertos(sidemenuNotifications?.negociosEmAndamentoAberto);
    setNegociosSemResponsavel(sidemenuNotifications?.negocioSemResponsavel);
    notificacaoAtividadeByUsuario();
  }, [sidemenuNotifications]);

  function selectedType(idType) {
    setOpen(idType);
  }

  useEffect(() => {
    setSocket(io(process.env.REACT_APP_BASE_URL_API));
  }, []);

  useEffect(() => {
    socket?.emit('getBadgeNotifications');
    socket?.on('updateBadgeNotifications', (resp) => {
      updateBrokerNotifications(resp);
    });
    socket?.on('retrieveBadgeNotificationsUpdate', () => {
      socket?.emit('getBadgeNotificationsUpdate');
    });
  }, [socket]);

  return (
    <>
      <Aside>
        <div className="navFixed">
          <nav className="menu animeLeft">
            <ul>
              <li className="li-logo">
                <Link to="/corretor/home">
                  <img src={coinsure_logo_icon} alt="Logo_Coinsure"></img>
                </Link>
              </li>
              <li>
                <NavLink to="/corretor/home">
                  <BiHome
                    onMouseEnter={() => selectedType(1)}
                    onMouseLeave={() => {
                      selectedType(0);
                    }}
                  />
                  <div className={`description ${open !== 1 ? 'off' : ''}`}>
                    Home
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink to="/corretor/estipulantes">
                  <BiBuildings
                    onMouseEnter={() => selectedType(2)}
                    onMouseLeave={() => {
                      selectedType(0);
                    }}
                  />
                  <div className={`description ${open !== 2 ? 'off' : ''}`}>
                    Estipulantes
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink to="/corretor/beneficiarios">
                  <BiUser
                    onMouseEnter={() => selectedType(3)}
                    onMouseLeave={() => {
                      selectedType(0);
                    }}
                  />
                  <div className={`description ${open !== 3 ? 'off' : ''}`}>
                    Beneficiários
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink to="/corretor/movimentacao-beneficiarios">
                  <Badge
                    badgeContent={movimentacoesAbertas}
                    color="primary"
                    className={`${!movimentacoesAbertas ? 'invisible' : ''}`}
                    anchorOrigin={{
                      horizontal: 'right',
                      vertical: 'bottom'
                    }}
                    max={50}
                  >
                    <div
                      className="icon-container"
                      onMouseEnter={() => selectedType(4)}
                      onMouseLeave={() => {
                        selectedType(0);
                      }}
                    >
                      <AiOutlineUsergroupAdd />
                    </div>
                  </Badge>
                  <div className={`description ${open !== 4 ? 'off' : ''}`}>
                    Movimentações
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink to="/corretor/gestao-tickets">
                  <Badge
                    badgeContent={ticketsAbertos}
                    className={`${!ticketsAbertos ? 'invisible' : ''}`}
                    color="primary"
                    anchorOrigin={{
                      horizontal: 'right',
                      vertical: 'bottom'
                    }}
                    max={99}
                  >
                    <div
                      className="icon-container"
                      onMouseEnter={() => selectedType(5)}
                      onMouseLeave={() => {
                        selectedType(0);
                      }}
                    >
                      <BiCalendar />
                    </div>
                  </Badge>
                  <div className={`description ${open !== 5 ? 'off' : ''}`}>
                    Tickets
                  </div>
                </NavLink>
              </li>

              <li>
                <NavLink to="/corretor/concierge">
                  <Badge
                    badgeContent={conciergesAberto}
                    className={`${!conciergesAberto ? 'invisible' : ''}`}
                    color="primary"
                    anchorOrigin={{
                      horizontal: 'right',
                      vertical: 'bottom'
                    }}
                    max={50}
                  >
                    <div
                      className="icon-container"
                      onMouseEnter={() => selectedType(6)}
                      onMouseLeave={() => {
                        selectedType(0);
                      }}
                    >
                      <FaWhatsapp />
                    </div>
                  </Badge>

                  <div className={`description ${open !== 6 ? 'off' : ''}`}>
                    Concierge
                  </div>
                </NavLink>
              </li>

              <li>
                <NavLink to="/corretor/relatorios">
                  <IoStatsChartSharp
                    onMouseEnter={() => selectedType(7)}
                    onMouseLeave={() => {
                      selectedType(0);
                    }}
                  />
                  <div className={`description ${open !== 7 ? 'off' : ''}`}>
                    Relatórios
                  </div>
                </NavLink>
              </li>

              <li>
                <NavLink to="/corretor/negocios">
                  <Badge
                    badgeContent={negociosSemResponsavel}
                    className={`negocio_sem_resp ${
                      !negociosSemResponsavel ? 'invisible' : ''
                    }`}
                    color="warning"
                    anchorOrigin={{
                      horizontal: 'left',
                      vertical: 'top'
                    }}
                    max={99}
                  ></Badge>
                  <MdOutlineAttachMoney
                    onMouseEnter={() => selectedType(8)}
                    onMouseLeave={() => {
                      selectedType(0);
                    }}
                    className="money_icon"
                  />
                  <Badge
                    badgeContent={notificacaoAtividades}
                    className={`notificacao_atividades ${
                      !notificacaoAtividades ? 'invisible' : ''
                    }`}
                    color="primary"
                    anchorOrigin={{
                      horizontal: 'right',
                      vertical: 'bottom'
                    }}
                    max={99}
                  ></Badge>
                  <div className={`description ${open !== 8 ? 'off' : ''}`}>
                    Negócios em andamento
                  </div>
                </NavLink>
              </li>

              <li>
                <NavLink to="/corretor/tasks">
                  <FaTasks
                    onMouseEnter={() => selectedType(9)}
                    onMouseLeave={() => {
                      selectedType(0);
                    }}
                  />
                  <div className={`description ${open !== 9 ? 'off' : ''}`}>
                    Tasks
                  </div>
                </NavLink>
              </li>

              <li>
                <NavLink to="/corretor/contatos-seguradoras">
                  <RiContactsBookFill
                    onMouseEnter={() => selectedType(10)}
                    onMouseLeave={() => {
                      selectedType(0);
                    }}
                  />
                  <div className={`description ${open !== 10 ? 'off' : ''}`}>
                    Contatos Seguradoras
                  </div>
                </NavLink>
              </li>
              <li>
                <Link to="/corretor/login" onClick={logOut}>
                  <BiLogOut
                    onMouseEnter={() => selectedType(11)}
                    onMouseLeave={() => {
                      selectedType(0);
                    }}
                  />
                  <div className={`description ${open !== 11 ? 'off' : ''}`}>
                    Sair
                  </div>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </Aside>
    </>
  );
};

export default Navigation;
