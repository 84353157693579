import './style.scss';

const Textarea = (props) => {
  const { label, variant = '' } = props;

  return (
    <div className="ds-textarea">
      <label className="ds-textarea__label" htmlFor={props.id}>
        {label}
      </label>

      <textarea
        className={`ds-textarea__textarea ds-textarea__textarea--${variant} ${props.className}`}
        {...props}
      />
    </div>
  );
};

export default Textarea;
