import { useEffect, useState } from 'react';
import {
  Backdrop,
  Button,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactComponent as LogoGenoa } from 'assets/imgs/svg/logo-genoa-login-corretor.svg';
import { ReactComponent as WorkerGenoa } from 'assets/imgs/svg/worker-genoa-login-corretor.svg';
import {
  LoginGrid,
  LoginInputColumn,
  LogoRow,
  SecondRow,
  Wrapper
} from './styles';
import { useUser } from 'contexts/user';
import services from 'apps/broker/services';

const regexCNPJ = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const LoginCorretor = () => {
  const userKey = 'jwt_token_user';
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorCR, setErrorCR] = useState();
  const [userCredential, setUserCredential] = useState('');
  const [password, setPassword] = useState('');
  const [height, setHeight] = useState(window.innerHeight);

  const { setUser } = useUser();

  const redirectToRedefinirSenha = () => {
    history.push('/corretor/recuperar-senha');
  };

  const submitLogin = () => {
    if (!userCredential || !password) {
      setErrorCR('Por favor preencher todos os campos');
      setShowError(true);
      setTimeout(() => {
        setErrorCR('');
        setShowError(false);
      }, 3500);
      return;
    }

    setLoading(true);

    services.user
      .login({ credential: userCredential, password })
      .then((resp) => {
        localStorage.setItem(userKey, JSON.stringify(resp.data));
        dispatch({ type: 'SET_USER', user: resp.data });

        //context
        setUser(resp.data);

        dispatch({ type: 'RESET_ACESSED_PAGES' });
        history.push('/corretor/home');
      })
      .catch((err) => {
        setErrorCR(err.response.data);
        setShowError(true);
        setTimeout(() => {
          setErrorCR('');
          setShowError(false);
        }, 3500);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    setLoading(false);
  };

  const checkSubmit = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      submitLogin();
    }
  };

  useEffect(() => {
    const json = localStorage.getItem(userKey);
    const userData = JSON.parse(json);

    if (userData?.corretora) {
      history.push('/corretor/home');
    }
    if (userData?.estipulante) {
      history.push('/rh/home');
    }
  }, [history]);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
  });

  return (
    <Wrapper>
      <Backdrop
        className={classes.backdrop}
        open={loading}
        onClick={handleClose}
      >
        <CircularProgress
          size={(180 * height) / 745}
          thickness={(1.3 * height) / 745}
          className="circular-spinner"
        />
      </Backdrop>
      <LogoRow>
        <LogoGenoa />
      </LogoRow>
      <SecondRow>
        <LoginGrid>
          <LoginInputColumn>
            <div className="title">
              <span>Boas vindas ao</span>
              <span className="heavy-title">Zeca!</span>
            </div>
            <div className="subtitle">
              <span>Os clientes da Genoa estão esperando por você!</span>
            </div>
            <form autoComplete="off">
              <div className="login-input-wrapper">
                <div className="login-input-container">
                  <label>Email ou CNPJ</label>
                  <input
                    type={'text'}
                    value={userCredential}
                    onKeyPress={checkSubmit}
                    onChange={(e) =>
                      setUserCredential(
                        e.target.value.replace(regexCNPJ, '$1.$2.$3/$4-$5')
                      )
                    }
                  />
                </div>
                <div className="login-input-container">
                  <label>Senha</label>
                  <input
                    type={'password'}
                    value={password}
                    onKeyPress={checkSubmit}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
            </form>
            <Button className="login-button" onClick={submitLogin}>
              <span>Entrar</span>
            </Button>
            <div className="reset-password-button">
              <span onClick={redirectToRedefinirSenha}>
                Não lembra da sua senha?
              </span>
            </div>

            <div className="flex-center">
              {showError && errorCR && (
                <div className="erro-msg"> {errorCR}</div>
              )}
            </div>
          </LoginInputColumn>
          <WorkerGenoa className="worker-img" />
        </LoginGrid>
      </SecondRow>
    </Wrapper>
  );
};

export default LoginCorretor;
