import React from "react";
import InputStandard from "components/Input/InputStandard";
import maskTelefone from "utils/inputMasks/maskTelefone";

import { AbaDadosContatosWrapper } from "./styles-abaDadosContato.js";

const AbaDadosContato = (
  { values,
    setInputValue,
    setSelectValue,
    camposInclusao
  }) => {

  return (
    <AbaDadosContatosWrapper>
      <div className="aba-container">
        <div className="aba-title-container">
          <span>3. Dados de Contato</span>
        </div>
        <div className="aba-input-container">
          <div className="aba-input-row-email">
            {
              camposInclusao['email'] ? (
                <InputStandard
                  value={values['email']}
                  setValue={setInputValue}
                  label={"E-mail"}
                  name="email"
                />
              ) : ""
            }

          </div>

          <div className="aba-input-last-row">
            {
              camposInclusao['telefone_fixo'] ? (
                <InputStandard
                  label="Telefone fixo"
                  value={values['telefone_fixo']}
                  setValue={setInputValue}
                  name="telefone_fixo"
                  maskFunction={maskTelefone}
                  maxLength={
                    values['telefone_fixo']?.length &&
                      Number(values['telefone_fixo'][5]) === 9 ?
                      11 : 10
                  }
                />
              ) : ""
            }
            {
              camposInclusao['telefone_celular'] ? (
                <InputStandard
                  value={values['telefone_celular']}
                  setValue={setInputValue}
                  label={"Telefone celular"}
                  name="telefone_celular"
                  maskFunction={maskTelefone}
                  maxLength={
                    values['telefone_celular']?.length &&
                      Number(values['telefone_celular'][5]) === 9 ?
                      11 : 10
                  }
                />
              ) : ""
            }

          </div>
        </div>
      </div>
    </AbaDadosContatosWrapper>
  )
}

export default AbaDadosContato;
