import React from 'react';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import { IoLogoWhatsapp } from 'react-icons/io';
import { IoTicketSharp } from 'react-icons/io5';
import { CgArrowsExchangeAlt } from 'react-icons/cg';
import {
  ListaTiposRelatorios,
  RelatorioOptionCard
} from './styles-MainContentRelatorio';

const RelatorioMenu = ({ relatorioToDisplay, setRelatorioToDisplay }) => {
  return (
    <>
      <div className="main-painel">
        <div className="title">
          <h1>Relatórios</h1>
        </div>
      </div>
      <div className="relatorios_tipos_rows">
        <div>
          <div className="sub_title">
            <span>Atendimentos:</span>
          </div>
          <ListaTiposRelatorios>
            <div className="relatorio__tickets">
              <RelatorioOptionCard
                onClick={() => setRelatorioToDisplay('concierge')}
              >
                <div className="relatorio_icon_wrapper">
                  <div className="relatorio_icon atendimento">
                    <IoLogoWhatsapp size={20} />
                  </div>
                </div>

                <span>Concierge</span>
              </RelatorioOptionCard>
              <RelatorioOptionCard
                onClick={() => setRelatorioToDisplay('tickets')}
              >
                <div className="relatorio_icon_wrapper">
                  <div className="relatorio_icon atendimento">
                    <IoTicketSharp size={20} />
                  </div>
                </div>

                <span>Tickets</span>
              </RelatorioOptionCard>
            </div>
          </ListaTiposRelatorios>
        </div>
        <div>
          <div className="sub_title">
            <span>Pedidos/Serviços:</span>
          </div>
          <ListaTiposRelatorios>
            <div className="relatorio__tickets">
              <RelatorioOptionCard
                onClick={() => setRelatorioToDisplay('movimentacoes')}
              >
                <div className="relatorio_icon_wrapper">
                  <div className="relatorio_icon pedidos">
                    <CgArrowsExchangeAlt size={25} />
                  </div>
                </div>

                <span>Movimentações</span>
              </RelatorioOptionCard>
              <RelatorioOptionCard
                onClick={() => setRelatorioToDisplay('cotacoes')}
              >
                <div className="relatorio_icon_wrapper">
                  <div className="relatorio_icon pedidos">
                    <FaFileInvoiceDollar size={20} />
                  </div>
                </div>

                <span>Cotações</span>
              </RelatorioOptionCard>
            </div>
          </ListaTiposRelatorios>
        </div>
      </div>
    </>
  );
};

export default RelatorioMenu;
