import styled from 'styled-components';
import colors from 'apps/broker/styles/colors';

export const Div = styled.div`
  min-height: 42px;
  width: calc(100% - 24px);
  display: grid;
  grid-template-columns: 26px 60px 1.5fr 0.75fr 0.5fr 1.5fr 0.5fr 0.5fr 80px;
  grid-gap: 10px;
  align-items: center;

  .master-checkbox-container {
    padding-left: 16px;
    svg {
      height: 18px;
      width: 18px;
    }
  }

  .checkbox {
    width: 25px;
    height: 30px;
    margin-left: -3px;
    margin-bottom: -2px;

    svg {
      height: 18px;
      width: 18px;
    }
  }

  .button-edit-on-table {
    color: ${colors.genoaDarkBlue};
    margin: 5px;
    margin-bottom: 0;
    margin-bottom: -1px;
    margin-left: 0;
    border-radius: 4px;
  }

  .button-edit-on-table: hover {
    color: ${colors.white};
    background-color: ${colors.genoaDarkBlue};
    padding: 0;
    margin: 5px;
    margin-bottom: 0;
    margin-bottom: -1px;
    margin-left: 0;
    border-radius: 4px;
    cursor: pointer;
  }

  > p {
    font-size: 1rem;
    color: ${colors.genoaGrey};
    font-family: 'Avenir Next GEO W05 Demi';
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
  }
  .tipo-paragraph {
    text-align: center;
    svg {
      text-align: center;
    }
  }

  .filter-icon {
    :hover {
      cursor: pointer;
      color: ${colors.genoaBlue};
    }
  }

  .filter-data-abertura-icon {
    :hover {
      cursor: pointer;
    }
  }
`;
