import styled from "styled-components";
import colors from "styles/colors";

export const DarkBackground = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;

  -webkit-transition: background-color 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -ms-transition: background-color 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  animation: fade-in 0.225s forwards;
  
  ::after {
    animation: fade-out 0.3s forwards;
  }

  @keyframes fade-in {
    from {
      background-color: ${colors.transparent};
    }
    to {
      background-color: rgba(0, 0, 0, 0.7)
    }
  }

  @keyframes fade-out {
    from {
      background-color: rgba(0, 0, 0, 0.7)
    }
    to {
      background-color: ${colors.transparent};
    }
  }

  .leave {
    animation: down-up 0.2s forwards;
  }

  @keyframes down-up {
    from {
      transform: calc(50% - 200px);
    }
    to {
      transform: translateY(-100%);
    }
  }
`

export const DrawerContent = styled.div`
  border-left: 10px solid ${colors.genoaBlue};
  border-radius: 10px;
  background-color: ${colors.white};
  
  position: fixed;
  left: calc(50% - 305px);
  padding: 25px;

  display: flex;
  flex-direction: column;
  row-gap: 30px;
  height: 495px;
  width: 600px;

  animation: up-down 0.2s forwards;


  @keyframes up-down {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: calc(50% - 200px);
    }
  }

  .popper_title {
    font-family: Avenir Next Geo W05 Heavy;
    font-size: 1.2em;
  }

  .form_container {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  .form_option {
    display: flex;
    column-gap: 20px;

    .select_resp {
      font-family: 'Avenir Next GEO W05 Regular';
      appearance: none;
      border-radius: 10px;
      border: none;
      padding: 2px;
      padding-left: 8px;
      width: 160px;
  
      :focus-visible {
        outline: 1px ${colors.genoaBlue}f5 auto;
      }
    }

    .select_estipulante {
      font-family: 'Avenir Next GEO W05 Regular';
      appearance: none;
      border-radius: 15px;
      border: none;
      padding: 2px;
      padding-left: 8px;
      height: 30px;
      width: 350px;
  
      :focus-visible {
        outline: 1px ${colors.genoaBlue}f5 auto;
      }
    }
  
    .select-arrow {
      background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 9L0.73686 0.75L10.2631 0.75L5.5 9Z' fill='%23456AED'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      
      background-position-x: 96%;
      background-position-y: center;
      background-size: 8px;
    }

    .input_container {

      .input_titulo {
        background-color: #fff;
        border: 1px solid ${colors.genoaBlue};
        border-radius: 24px;
        margin-bottom: 6px;
        padding: 12px;
        opacity: 1;
        font-size: 0.8em;
        color: ${colors.genoaGrey};
        font-family: 'Avenir Next GEO W05 Regular';
        height: 30px;
        width: 300px;

        ::placeholder {
          color: #c1c1c1;
        }

        :focus-visible {
          outline-color: ${colors.genoaBlue};
        }
      }

      
    }

    .options {
      display: flex;
      font-family: "Avenir Next GEO W05 Regular";
      font-size: 0.85em;
      column-gap: 20px;

      .MuiRadio-root {
        padding: 0;
      }

      .radio_container {
        display: flex;
        column-gap: 5px;
        align-items: center;
      }

      .MuiSvgIcon-root {
        font-size: 0.75em;
      }
    }

    .textarea_container {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }
  
    textarea {
      resize: none;
      padding: 10px 10px 30px 10px;
      border: 1px solid ${colors.genoaBlue};
      border-radius: 12px;
      font-family: Avenir Next Geo W05 Regular;
      height: 30px;
      width: 500px;
  
      :focus-visible {
        outline: 1px ${colors.genoaBlue}f5 auto;
      }
  
      ::-webkit-scrollbar {
        width: 3px;
        padding: 16px 0px;
        background: rgba(0, 0, 0, 0);
      }
      ::-webkit-scrollbar-thumb {
        background: ${colors.rhLightGrey};
        padding: 16px 0px;
        box-shadow: none;
        
      }
      ::-webkit-scrollbar-track {
        margin-top: 16px;
        margin-bottom: 16px;
        box-shadow: inset 0 0 2px rgb(0, 0 , 0, 0);
        
      }
    }

    .input-date {
      border: 2px solid ${colors.lightGrey};
      display: inline-block;
      text-align: center;
      font-size: 0.75rem;
      font-family: 'Avenir Next GEO W05 Regular';
      border-radius: 7px;
      height: 25px;
      width: 120px;

      :focus-visible {
        outline: 1px ${colors.genoaBlue} auto;
      }
    }
  }

  .criar_task_btn {
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    background-color: ${colors.genoaBlue};
    color: white;
    padding: 4px 8px;
    font-family: Avenir Next Geo W05 Demi;
    text-transform: uppercase;
    font-size: 0.9em;
    border-radius: 6px;
    top: 0;
    right: 0;
  }

  .form_label {
    font-family: Avenir Next Geo W05 Demi;
  }

  .gap_size {
    height: 30px;
    width: 600px;
  }

  .disabled {
    background-color: ${colors.genoaDisabledGrey};
  }

`