import { useState } from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from 'components/DS/Button';
import { GiHealthNormal } from 'react-icons/gi';
import { Box, ClickAwayListener, Paper, Popper } from '@material-ui/core';
import { FaTooth } from 'react-icons/fa';
import HealthInsurance from '../CreateHealthInsurance';
import CreateDentalInsurance from '../CreateDentalInsurance';
import './styles.scss';

const AddBenefits = ({ brokerInformation }) => {
  const [isOpenHealthDrawer, setIsOpenHealthDrawer] = useState(false);
  const [isOpenOdontoDrawer, setIsOpenOdontoDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = (event) => {
    if (event.target && anchorEl !== event.target) {
      setAnchorEl(null);
    }
  };

  const onShowBenefits = ({ target }) => {
    setAnchorEl((prevState) => (prevState ? null : target));
  };

  return (
    <div className="add-benefits">
      <Button variant="transparent" onClick={onShowBenefits}>
        + Benefício
      </Button>

      <Popper
        id={'id'}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom-end"
        transition
        disablePortal
      >
        <Box>
          <ClickAwayListener onClickAway={handleClose}>
            <Paper elevation={7}>
              <Button
                variant="transparent"
                onClick={() => {
                  setAnchorEl(null);
                  setIsOpenHealthDrawer(!isOpenHealthDrawer);
                }}
              >
                <GiHealthNormal size={11} />
                Seguro Saúde
              </Button>
              <Button
                variant="transparent"
                onClick={() => {
                  setAnchorEl(null);
                  setIsOpenOdontoDrawer(!isOpenHealthDrawer);
                }}
              >
                <FaTooth size={11} />
                Seguro Odontológico
              </Button>
            </Paper>
          </ClickAwayListener>
        </Box>
      </Popper>

      <SwipeableDrawer
        anchor="right"
        open={isOpenHealthDrawer}
        onClose={() => setIsOpenHealthDrawer(!isOpenHealthDrawer)}
        onOpen={() => setIsOpenHealthDrawer(!isOpenHealthDrawer)}
      >
        <div className="add-benefits__drawer">
          <HealthInsurance
            estipulante={brokerInformation.id_estipulante}
            subs={brokerInformation.subestipulantes}
            onBack={() => {
              setIsOpenHealthDrawer(!isOpenHealthDrawer);
            }}
          />
        </div>
      </SwipeableDrawer>

      <SwipeableDrawer
        anchor="right"
        open={isOpenOdontoDrawer}
        onClose={() => setIsOpenHealthDrawer(!isOpenOdontoDrawer)}
        onOpen={() => setIsOpenHealthDrawer(!isOpenOdontoDrawer)}
      >
        <div className="add-benefits__drawer">
          <CreateDentalInsurance
            estipulante={brokerInformation.id_estipulante}
            subs={brokerInformation.subestipulantes}
            onBack={() => setIsOpenOdontoDrawer(!isOpenOdontoDrawer)}
          />
        </div>
      </SwipeableDrawer>
    </div>
  );
};

export default AddBenefits;
