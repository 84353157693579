import styled from 'styled-components';


export const Wrapper = styled.div`
.MuiAccordionSummary-content.Mui-expanded{
  margin: 5px 0;

}
.MuiAccordionSummary-root.Mui-expanded {
    min-height: 55px;
  }

.warning{
    margin-top: 20px;
    color: #969ea9;
  }
`