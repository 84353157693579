import React, { useState } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Wrapper } from './styles-DadosEstipulante';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Backdrop from '@material-ui/core/Backdrop';
import ReactDatePicker from 'react-datepicker';
import { FaSave } from 'react-icons/fa';
import api from 'services/api';
import services from 'apps/broker/services';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1rem',
    '& > * + *': { marginLeft: theme.spacing(2) }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 10,
    color: '#fff'
  },
  load: { color: '#6e3ec1' }
}));

const DadosEstipulante = () => {
  const user = useSelector((state) => state.userReducers.user);
  const classes = useStyles();
  const history = useHistory();
  const [dadosReceita, setDadosReceita] = useState({});
  const [loadCnpj, setLoadCnpj] = useState(false);
  const [loadApi, setLoadApi] = useState(false);
  // const [errorFormato, setErrorFormato] = React.useState()
  // const [imagePreviewUrl, setimagePreviewUrl] = React.useState()

  const { register, handleSubmit, setValue, getValues, control, watch } =
    useForm({
      defaultValues: {
        subEstipulante: [{ cnpj: '' }],
        emails: [{ email: '' }],
        gestores: [{ nome: '' }]
      }
    });

  const {
    fields: subEstipulanteFields,
    append: subEstipulanteAppend,
    remove: subEstipulanteRemove
  } = useFieldArray({ control, name: 'subEstipulante' });
  const {
    fields: emailsFields,
    append: emailsAppend,
    remove: emailsRemove
  } = useFieldArray({ control, name: 'emails' });
  const {
    fields: gestorFields,
    append: gestorAppend,
    remove: gestorRemove
  } = useFieldArray({ control, name: 'gestores' });

  async function cnpjSend(value) {
    try {
      if (value.length > 17) {
        setLoadCnpj(true);
        const cnpj = value.replace(/[^\d]+/g, '');

        await services.policyOwner.getRevenueService(cnpj).then((response) => {
          const {
            nome,
            abertura,
            logradouro,
            numero,
            complemento,
            cep,
            uf,
            municipio
          } = response.data;
          const complementoTratado = complemento.replace(';', '');
          setDadosReceita({
            nome,
            abertura,
            logradouro,
            numero,
            complemento: complementoTratado,
            cep,
            uf,
            municipio
          });
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoadCnpj(false);
    }
  }

  async function subsCnpjSend(value, index) {
    try {
      if (value.length > 17) {
        const cnpj = value.replace(/[^\d]+/g, '');

        await services.policyOwner.getRevenueService(cnpj).then((response) => {
          const {
            nome,
            abertura,
            logradouro,
            numero,
            complemento,
            cep,
            uf,
            municipio
          } = response.data;
          const complementoTratado = complemento.replace(';', '');

          setValue(`subEstipulante.${index}.dados`, {
            nome,
            abertura,
            logradouro,
            numero,
            complemento: complementoTratado,
            cep,
            uf,
            municipio
          });
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
    }
  }

  const onSubmit = (data) => {
    setLoadApi(true);

    const body = {
      ...data,
      ...dadosReceita
    };

    // services.policyOwner.createPolicyOwner(body)
    // .then((resp) => {
    //   if (data.arquivo[0]) {
    //     const formData = new FormData();
    //     formData.append("file", data.arquivo[0]);

    //     const headersLogo = {
    //       estipulante: resp.data.id,
    //       authorization: `Bearer ${user.token}`,
    //     };

    //     services.policyOwner.createLogoByUUID(formData, resp.data.id)

    //     // api.post("/corretor/upload-logo-estipulante", formData, {
    //     //   headers: headersLogo,
    //     // });
    //   }
    //   return history.push("/corretor/estipulantes");
    // })
    // .catch((err) => {
    //   return err;
    // })
    // .finally(() => {
    //   setLoadApi(false);
    // });

    api
      .post(
        '/corretor/add/estipulante',
        { ...data, ...dadosReceita },
        {
          headers: {
            id: user.corretora,
            authorization: `Bearer ${user.token}`
          }
        }
      )
      .then((resp) => {
        if (data.arquivo[0]) {
          const formData = new FormData();
          formData.append('file', data.arquivo[0]);

          const headersLogo = {
            estipulante: resp.data.id,
            authorization: `Bearer ${user.token}`
          };

          api.post('/corretor/upload-logo-estipulante', formData, {
            headers: headersLogo
          });
        }
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoadApi(false);
        history.push('/corretor/estipulantes');
      });
  };

  const handleCnpjChange = (field, target) => {
    field.onChange(target.value);
    if (target.value.length > 17) {
      cnpjSend(target.value);
    }
  };
  const handleSubsCnpjChange = (field, target, index) => {
    field.onChange(target.value);
    if (target.value.length > 17) {
      subsCnpjSend(target.value, index);
    }
  };

  const handleRadioChange = ({ target }) => {
    setValue('possui-subEstipulante', target.value);
    if (target.value === 'não') {
      setValue('subEstipulante', [{ cnpj: '' }]);
    }
  };

  // const arquivo = register('arquivo')

  // const handleImgChange = ({ target }) => {
  //   setErrorFormato(null)
  //   setError('arquivo', null)
  //   if (['image/jpeg', 'image/png'].includes(target.files[0].type)) {
  //     setValue('arquivo', target.files)
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setimagePreviewUrl(reader.result)
  //     }
  //     reader.readAsDataURL(target.files[0])
  //   } else {
  //     setErrorFormato("Envie um logo no formato PNG ou JPG")
  //   }
  // }

  return (
    <>
      <Backdrop
        className={classes.backdrop}
        open={loadApi}
        onClick={() => setLoadApi(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Wrapper>
        <form
          className="form-cadastro-estipulante"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="grids">
            <div className="col">
              <div className="input-group">
                <label className="default">CNPJ</label>
                <Controller
                  name="cnpj"
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <InputMask
                      value={field.value || ''}
                      maskChar={null}
                      mask="99.999.999/9999-99"
                      type="text"
                      onChange={({ target }) => handleCnpjChange(field, target)}
                    />
                  )}
                />
                {loadCnpj && (
                  <div className={classes.root}>
                    <CircularProgress size={20} className={classes.load} />
                  </div>
                )}

                {dadosReceita?.nome && (
                  <div className="dados-receita">
                    {dadosReceita.nome} <br />
                    {dadosReceita.logradouro} {dadosReceita.numero}{' '}
                    {dadosReceita?.complemento} <br />
                    {dadosReceita.cep} {dadosReceita.municipio} -{' '}
                    {dadosReceita.uf} <br />
                  </div>
                )}
              </div>

              <div className="input-group">
                <label className="default">
                  Data de inicio de vínculo com este Estipulante
                </label>

                <Controller
                  name={`dataEstipulante`}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <ReactDatePicker
                      className="input-data"
                      selected={value}
                      locale="ptBR"
                      onChange={onChange}
                      //placeholderText=""
                      dateFormat="dd/MM/yyyy"
                    />
                  )}
                />
              </div>

              <div className="checkbox-area">
                <label className="default">
                  Esse estipulante possui sub-estipulantes?
                </label>
                <div className="checkbox-group">
                  <label
                    htmlFor="sim"
                    className={
                      watch('possui-subEstipulante') === 'sim'
                        ? 'select-label active'
                        : 'select-label'
                    }
                  >
                    Sim
                  </label>
                  <input
                    id="sim"
                    type="radio"
                    value="sim"
                    {...register('possui-subEstipulante', { required: false })}
                    onChange={(e) => handleRadioChange(e)}
                  />
                </div>
                <div className="checkbox-group">
                  <label
                    htmlFor="nao"
                    className={
                      watch('possui-subEstipulante') === 'não'
                        ? 'select-label active'
                        : 'select-label'
                    }
                  >
                    Não
                  </label>
                  <input
                    id="nao"
                    type="radio"
                    value="não"
                    {...register('possui-subEstipulante', { required: false })}
                    onChange={(e) => handleRadioChange(e)}
                  />
                </div>
              </div>

              {watch('possui-subEstipulante') === 'sim' && (
                <div className="subs-estip">
                  {subEstipulanteFields.map((item, index) => {
                    return (
                      <div key={index} className="input-group">
                        <div className="label-fields">
                          <label className="default">
                            Insira o CNPJ da Sub-estipulante
                          </label>
                          {index !== 0 && (
                            <button
                              type="button"
                              className="remove-field"
                              onClick={() => subEstipulanteRemove(index)}
                            >
                              remover
                            </button>
                          )}
                        </div>

                        <Controller
                          name={`subEstipulante.${index}.cnpj`}
                          control={control}
                          rules={{ required: false }}
                          defaultValue={`${item.subEstipulante?.[index].cnpj}`}
                          render={({ field }) => (
                            <InputMask
                              {...field}
                              maskChar={null}
                              mask="99.999.999/9999-99"
                              type="text"
                              onChange={({ target }) =>
                                handleSubsCnpjChange(field, target, index)
                              }
                            />
                          )}
                        />

                        {watch(`subEstipulante.${index}.cnpj`).length === 18 &&
                          !watch(`subEstipulante.${index}.dados.nome`) && (
                            <CircularProgress size={20} />
                          )}

                        {watch(`subEstipulante.${index}.dados`) && (
                          <div className="dados-receita">
                            {getValues(`subEstipulante.${index}.dados.nome`)}{' '}
                            <br />
                            {/* {dadosReceita.logradouro} {dadosReceita.numero} {dadosReceita?.complemento} <br />
                          {dadosReceita.cep} {dadosReceita.municipio} - {dadosReceita.uf} <br /> */}
                          </div>
                        )}
                      </div>
                    );
                  })}
                  <button
                    type="button"
                    className="add"
                    onClick={() => subEstipulanteAppend({ cnpj: '' })}
                  >
                    + SUB ESTIPULANTE
                  </button>
                </div>
              )}
            </div>

            <div className="col">
              <div>
                <div className="input-group">
                  <label className="default">E-mails da empresa</label>
                  <div className="descricao">
                    Cadastre a terminação do e-mail da empresa. Dessa forma,
                    todos tickets enviados por essa terminação de e-mail serão
                    agrupados para esse estipulante.
                  </div>

                  {emailsFields.map((item, index) => {
                    return (
                      <div key={index} className="input-group">
                        <div className="remove-btn-area">
                          {' '}
                          {index !== 0 && (
                            <button
                              type="button"
                              className="remove-field"
                              onClick={() => emailsRemove(index)}
                            >
                              remover
                            </button>
                          )}
                        </div>
                        <div className="email-prefix">
                          <span>@</span>
                          <input
                            type="text"
                            name={`emails${index}.email`}
                            {...register(`emails.${index}.email`, {
                              required: false
                            })}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
                <button
                  type="button"
                  className="add"
                  onClick={() => emailsAppend({ email: '' })}
                >
                  + Adicionar e-mail.
                </button>
              </div>
            </div>
            <div className="col">
              {/* <div>
                <div className="input-group">
                  <label className="default">Logo do Estipulante</label>
                  <div className="descricao">
                    Você poderá adicionar ou trocar o logo em Dados Cadastrais do Estipulante
                  </div>

                  {errorFormato && <span className="error-msg">{errorFormato}</span>}
                  {errors.arquivo && <span className="error-msg">{errors.arquivo.message}</span>}
                  <div className="div-input-files">
                    <label className="label-file">
                      {watch('arquivo')?.[0]?.name ?
                        <span> {`${watch('arquivo')[0]?.name.slice(0, 45)}`} </span> :
                        <span> Selecione um Logo </span>
                      }
                      <input
                        ref={arquivo.ref}
                        onChange={(e) => {
                          arquivo.onChange(e);
                          handleImgChange(e);
                        }}
                        className="input-file" type="file" />
                    </label>
                    <div className="buttons">
                      {watch('arquivo')?.[0]?.name &&
                        <button type="button"
                          onClick={() => {
                            setValue('arquivo', null);
                            setimagePreviewUrl(null)
                          }}
                          className="btn-clear"><CloseIcon /></button>
                      }
                    </div>
                  </div>
                  <div className="preview">
                    <div className="logoPreview">
                      {imagePreviewUrl && <img src={imagePreviewUrl} alt="logo" />}
                    </div>
                  </div>

                </div>

              </div> */}
              <div>
                <div className="input-group ">
                  <label className="default">Gestores do Estipulante</label>
                  <div className="descricao">
                    Adicione os gestores do estipulante. Eles terão acesso ao
                    painel do RH.
                  </div>
                  {gestorFields.map((item, index) => {
                    return (
                      <div key={index} className="input-group third-col">
                        <div className="remove-btn-area">
                          {' '}
                          {index !== 0 && (
                            <button
                              type="button"
                              className="remove-field"
                              onClick={() => gestorRemove(index)}
                            >
                              remover
                            </button>
                          )}
                        </div>
                        <input
                          type="text"
                          name={`gestores${index}.nome`}
                          {...register(`gestores.${index}.nome`, {
                            required: false
                          })}
                        />
                      </div>
                    );
                  })}
                </div>
                <button
                  type="button"
                  className="add"
                  onClick={() => gestorAppend({ nome: '' })}
                >
                  + Adicionar gestor.
                </button>
              </div>
            </div>
          </div>

          <div className="row-cadastro-estipulante">
            <Link to="/corretor/estipulantes">Voltar</Link>
            <button className="send-btn-cadastro-estipulantes" type="submit">
              <span>Salvar</span>
              <FaSave />
            </button>
          </div>
        </form>
      </Wrapper>
    </>
  );
};

export default DadosEstipulante;
