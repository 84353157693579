import styled from "styled-components";
import colors from "apps/broker/styles/colors";

export const Wrapper = styled.div`
  display: flex;

  .animeLeft {
    opacity: 0;
    transform: translateX(-20px);
    animation: animeLeft 0.3s forwards;
  }
  @keyframes animeLeft {
    to {
      opacity: 1;
      transform: initial;
    }
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 101vh;
  background: ${colors.genoaLightBackgroundGrey};

  .title{
    margin-top: 1px;
    margin-bottom: 2.5em;
    h1{
      font-family: "Avenir Next GEO W05 Heavy";
      font-size: 1.2rem;
      color: #3B3838;
    }
  }

  
`

export const Main = styled.main`
  display: flex;
  justify-content: center;
  width: calc(100% - 90px);
  height: 100%;
  background: ${colors.genoaLightBackgroundGrey};
  padding: 25px 45px 25px 45px;

`



export const ListaTiposRelatorios = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 20px;
  padding-left: 40px;

  .relatorio__tickets {
    display: flex;
    flex-wrap: wrap;
    column-gap: 80px;
    font-size: 0.8em;
  }

`

export const RelatorioOptionCard = styled.div`

  width: 130px;

  
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  
  .relatorio_icon_wrapper {
    width: 85px;
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .relatorio_icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    background-color: #efeff8;


    :hover {
     
    }
  }

  :hover {
    cursor: pointer;

    .atendimento {
      background-color: springgreen;
    }

    .pedidos {
      background-color: ${colors.genoaBackgroundLightBlue};
    }

    .relatorio_icon {
      box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
      padding: 7.5px 7.5px;
      border-radius: 12px;
    }
  }

  
`

export const MainContentRelatorios = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 200px);
  background-color: white;
  border-radius: 7px;
  padding: 20px;

  .sub_title {
    margin-bottom: 2em;
    width: max-content;
    color: ${colors.genoaGrey};
    border-bottom: 1px solid ${colors.genoaGrey};
    margin-left: 15px;
  }

  .relatorios_tipos_rows {
    display: flex;
    flex-direction: column;
    row-gap: 2.5em;
  }
  
`