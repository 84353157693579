import http from 'services/http';

const getData = () => {
  const res = http.get('')
}

const createMessageByHr = async (formData, data, user, status, type) => {
  const res = await http.post('/rh/answer-ticket', formData, {
    headers: {
      ticket: data.id_ticket,
      usuario: user.uuid,
      estipulante: user.estipulante,
      status: status,
      tipo: type
    }
  });

  return res;
};

const modules = {
  createMessageByHr
};

export default modules;
