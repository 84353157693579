const maskTelefone = (value) => {
    // Telefone Fixo
    // Remove tudo o que não é dígito
    value = value.replace(/\D/g, "")

    // Se o terceiro dígito for 9, o número é celular
    if (Number(value[2]) === 9) {
        // Coloca parênteses em volta dos dois primeiros dígitos e adiciona um espaço              
        value = value.replace(/^(\d\d)(\d)/g, "($1) $2")
        //Coloca hífen entre o quarto e o quinto dígitos
        value = value.replace(/(\d{5})(\d)/, "$1 - $2")
        return value
    }
    // Coloca parênteses em volta dos dois primeiros dígitos e adiciona um espaço              
    value = value.replace(/^(\d\d)(\d)/g, "($1) $2")
    //Coloca hífen entre o quarto e o quinto dígitos
    value = value.replace(/(\d{4})(\d)/, "$1 - $2")
    return value
}

module.exports = maskTelefone;