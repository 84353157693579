import React from "react";
import { Wrapper } from "./styles-drawerRemoverUsuario";

import api from "services/api";
import { useSelector } from "react-redux";

import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";

const DrawerRemoverUsuario = (props) => {
  const user = useSelector(state => state.userReducers.user);

  const removeUsuarioClickHandler = () => {
    const headers = {
      authorization: `Bearer ${user.token}`,
    }

    api.delete('/rh/usuarios', { headers, data: props.data })
      .then((resp) => {
        // console.log(resp);
        props.getData();
        props.closeModal();
      })
  }

  return (
    <Wrapper>
      <section className="remover-usuario-content">
        <div className="title-container">
          <span>Confirmar exclusão</span>
        </div>
        <div className="usuario-info-container">
          <div className="usuario-info">
            <span className="info-title">Nome:</span>
            <span>{props.data.nome} {props.data.sobrenome}</span>
          </div>
          <div className="usuario-info">
            <span className="info-title">Email:</span>
            <span>{props.data.email}</span>
          </div>
          <div className="usuario-info">
            <span className="info-title">Cargo:</span>
            <span>{props.data.cargo}</span>
          </div>
        </div>
        <div className="buttons-container">
          <div className="icon-container check">
            <AiOutlineCheck size={22} onClick={removeUsuarioClickHandler} />
          </div>
          <div className="icon-container close">
            <AiOutlineClose size={22} onClick={props.closeModal} />
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export default DrawerRemoverUsuario;
