import React from "react";
import HeaderRh from "apps/broker/components/Header";
import SidemenuRh from "apps/hr/components/Sidebar-RH";
import MainRh from "apps/hr/components/MainContentRh";
import { Wrapper, Content, Consult } from "./styles";
import api from "services/api";
import { useSelector } from "react-redux";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { RiSendPlaneFill } from "react-icons/ri";
import colors from "styles/colors";
import { usePostHog } from "posthog-js/react";

const TrocarSenha = () => {
  const posthog = usePostHog();
  const user = useSelector((state) => state.userReducers.user);
  // console.log(user)
  const [showPassword, setShowPassword] = React.useState({
    senhaAntiga: false,
    novaSenha: false,
    confirmarSenha: false,
  });

  const [senhaAntiga, setSenhaAntiga] = React.useState("");
  const [senhaNova, setSenhaNova] = React.useState("");
  const [confirmarSenha, setConfirmarSenha] = React.useState("");
  const [senhasDiferentes, setSenhasDiferentes] = React.useState(false);
  const [showResponseMessage, setShowResponseMessage] = React.useState(false);
  const [showErrorMessage, setShowErrorMessage] = React.useState(false);
  const [responseMessage, setResponseMessage] = React.useState("");

  const toggleShowPassword = (name) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const requestPasswordChange = () => {
    if (senhaNova !== confirmarSenha) {
      setSenhasDiferentes(true);
      setShowErrorMessage(true);
      setResponseMessage("Nova senha e sua confirmação precisam ser iguais");
      setShowResponseMessage(true);
      setTimeout(() => {
        setShowResponseMessage(false);
        setShowErrorMessage(false);
        setResponseMessage("");
      }, 3500);
      return;
    }
    setSenhasDiferentes(false);

    const body = {
      usuario: user,
      senhaAntiga,
      senhaNova,
    };

    const headers = {
      authorization: `Bearer ${user.token}`,
    };

    api.post("/rh/usuarios/trocar-senha", body, { headers }).then((resp) => {
      // console.log(resp);
      if (resp.data.message) {
        setSenhasDiferentes(true);
        setShowErrorMessage(resp.data.status !== 200 ? true : false);
        setResponseMessage(resp.data.message);
        setShowResponseMessage(true);
        setTimeout(() => {
          setShowResponseMessage(false);
          setShowErrorMessage(false);
          setResponseMessage("");
        }, 3500);
      }
    });
  };

  // Se senha estiver errada na requisição, quando estiverem iguais não mostrar mais mensagem de erro

  if (senhasDiferentes) {
    if (senhaNova === confirmarSenha) {
      setSenhasDiferentes(false);
    }
  }

  React.useEffect(() => {
    posthog?.capture('Acessou "/rh/trocar-senha"');
  }, []);

  return (
    <Wrapper>
      <SidemenuRh />
      <Content>
        <HeaderRh />
        <MainRh>
          <Consult>
            <div className="title">
              <h1>Alterar sua senha</h1>
            </div>
          </Consult>
          <form>
            <section className="password-inputs-container">
              <div className="input-container">
                <label>Senha atual</label>
                <div className="input-password-container">
                  <input
                    type={showPassword["senhaAntiga"] ? "text" : "password"}
                    value={senhaAntiga}
                    placeholder=""
                    onChange={(e) => setSenhaAntiga(e.target.value)}
                    autoComplete="new-password"
                  />
                  {showPassword["senhaAntiga"] ? (
                    <AiFillEyeInvisible
                      className="show-icon"
                      name="senhaAntiga"
                      onClick={() => toggleShowPassword("senhaAntiga")}
                      size="19"
                    />
                  ) : (
                    <AiFillEye
                      className="show-icon"
                      name="senhaAntiga"
                      onClick={() => toggleShowPassword("senhaAntiga")}
                      size="19"
                    />
                  )}
                </div>
              </div>
              <div className="input-container">
                <label>Nova senha</label>
                <div className="input-password-container">
                  <input
                    style={{
                      border: senhasDiferentes
                        ? `1.5px ${colors.red} solid`
                        : "none",
                    }}
                    value={senhaNova}
                    onChange={(e) => setSenhaNova(e.target.value)}
                    placeholder=""
                    type={showPassword["novaSenha"] ? "text" : "password"}
                  />
                  {showPassword["novaSenha"] ? (
                    <AiFillEyeInvisible
                      className="show-icon"
                      name="novaSenha"
                      onClick={() => toggleShowPassword("novaSenha")}
                      size="19"
                    />
                  ) : (
                    <AiFillEye
                      className="show-icon"
                      name="novaSenha"
                      onClick={() => toggleShowPassword("novaSenha")}
                      size="19"
                    />
                  )}
                </div>
              </div>
              <div className="input-container">
                <label>Confirmar nova senha</label>
                <div className="input-password-container">
                  <input
                    style={{
                      border: senhasDiferentes
                        ? `1.5px ${colors.red} solid`
                        : "none",
                    }}
                    value={confirmarSenha}
                    placeholder=""
                    onChange={(e) => setConfirmarSenha(e.target.value)}
                    type={showPassword["confirmarSenha"] ? "text" : "password"}
                  />
                  {showPassword["confirmarSenha"] ? (
                    <AiFillEyeInvisible
                      className="show-icon"
                      onClick={() => toggleShowPassword("confirmarSenha")}
                      size="19"
                    />
                  ) : (
                    <AiFillEye
                      className="show-icon"
                      onClick={() => toggleShowPassword("confirmarSenha")}
                      size="19"
                    />
                  )}
                </div>
              </div>
              <div className="send-btn" onClick={requestPasswordChange}>
                <span>Alterar senha </span>
                <RiSendPlaneFill className="send-icon" size={11} />
              </div>
              <div>
                {showResponseMessage ? (
                  <span
                    className={
                      showErrorMessage
                        ? "error-message-span"
                        : "confirmation-message-span"
                    }
                  >
                    {responseMessage}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </section>
          </form>
        </MainRh>
      </Content>
    </Wrapper>
  );
};

export default TrocarSenha;
