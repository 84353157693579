/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import ReactDatePicker from "react-datepicker";
// import ReactExport from "react-data-export";
import { Toaster } from 'react-hot-toast';
import moment from 'moment';
import api from 'services/api';
import DatePicker from 'react-datepicker';

import Header from 'apps/hr/components/Header-RH-Novo';
import SidemenuRh from 'apps/hr/components/Sidebar-RH';

import { BiPlus } from 'react-icons/bi';
import { FaTooth } from 'react-icons/fa';
// import { MdEditOff } from "react-icons/md";
import { BsFilter, BsFilterLeft } from 'react-icons/bs';
import {
  HiOutlineArrowNarrowDown,
  HiOutlineArrowNarrowUp
} from 'react-icons/hi';
import { RiSubtractFill } from 'react-icons/ri';

import {
  Consult,
  ContainerBeneficiary,
  Content,
  FiltersInfos,
  Main,
  TypeMovement,
  Wrapper
} from './styles';
import { CircularProgress, FormControl } from '@material-ui/core';
// import { ArrowLeftRounded } from "@material-ui/icons";
import {
  createTheme,
  FormControlLabel,
  Radio,
  RadioGroup,
  ThemeProvider
} from '@mui/material';
// import { blue } from "@material-ui/core/colors";

import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Box from '@mui/material/Box';
// import { VscChromeClose, VscCheck } from "react-icons/vsc";
// import { Controller, useForm } from "react-hook-form";
import colors from 'apps/broker/styles/colors';
import { GiHealthNormal } from 'react-icons/gi';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { usePostHog } from 'posthog-js/react';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const optionsDate = {
  year: 'numeric',
  month: '2-digit',
  day: 'numeric'
};

const orderOptions = {
  1: 'Nenhum',
  2: 'Decrescente',
  3: 'Crescente'
};

const theme = createTheme({
  palette: {
    rhBlue: {
      main: '#93A9F5'
    },
    darkGenoaBlue: {
      main: '#1E2552'
    }
  }
});

export default function GestaoBeneficiariosRh() {
  const posthog = usePostHog();
  const user = useSelector((state) => state.userReducers.user);

  const [beneficiaries, setBeneficiaries] = useState([]);
  const [beneficiariesFilter, setBeneficiariesFilter] = useState([]);
  const [expand, setExpand] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [triggerTest, setTriggerTest] = useState(false);

  const [filterEstipulante, setFilterEstipulante] = useState('mostrarTodos');
  const [filterStatus, setFilterStatus] = useState('Todos');
  const [filterTipo, setFilterTipo] = useState('Todos');
  const [filterName, setFilterName] = useState('');

  const [anchorElDataFilter, setAnchorElDataFilter] = useState(null);

  const [dataTipoFilter, setDataTipoFilter] = useState({
    abertura: false,
    conclusao: false
  });
  const [dateFilterValue, setDateFilterValue] = useState('');
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');

  const [orderSelected, setOrderSelected] = useState(1);

  function getEmpresa() {
    setIsLoading(true);
    // console.log(user)
    api
      .get('/rh/estipulante-sub/movimentacoes', {
        headers: {
          estipulante: user.mainEstipulante,
          authorization: `Bearer ${user.token}`
        }
      })
      .then((resp) => {
        // console.log(resp.data);
        setBeneficiaries(resp.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function openDetails(idBeneficiary, nameProduct) {
    if (expand !== `${idBeneficiary}&${nameProduct}`) {
      posthog?.capture('Expandiu info de "Movimentação"');
      setExpand(`${idBeneficiary}&${nameProduct}`);
    } else {
      posthog?.capture('Retraiu info de "Movimentação"');
      setExpand(null);
    }
  }

  function filterByEstipulante(estipulante) {
    setFilterEstipulante(estipulante);
  }

  function filterByStatusMovimentacao(status) {
    posthog?.capture(`Clicou p/ filtrar status de movimentação por ${status}`);
    setFilterStatus(status);
  }

  function filterByTipo(tipo) {
    posthog?.capture(`Clicou p/ filtrar movimentações por ${tipo}`);
    setFilterTipo(tipo);
  }

  function formateDate(date) {
    let parts = date.split('/');
    const formatdate = new Date(parts[2], parts[1] - 1, parts[0]);
    return new Date(formatdate);
  }

  const orderMovimentacoes = (movimentacoes) => {
    const order = orderOptions[orderSelected];
    switch (order) {
      case 'Decrescente':
        const orderedDecrescente = movimentacoes.sort(
          (a, b) =>
            Number(new Date(b.data_movimentacao)) -
            Number(new Date(a.data_movimentacao))
        );
        return orderedDecrescente;
      case 'Crescente':
        const orderedCrescente = movimentacoes.sort(
          (a, b) =>
            Number(new Date(a.data_movimentacao)) -
            Number(new Date(b.data_movimentacao))
        );
        return orderedCrescente;
      default:
        return movimentacoes;
    }
  };

  const filterMovimentacoesByAberturaDate = (movimentacoes) => {
    if (dataTipoFilter['abertura']) {
      const date = new Date();
      const lastWeek = new Date(
        date.setDate(date.getDate() - 7)
      ).toLocaleDateString('pt-BR', optionsDate);
      const lastMonth = new Date(
        date.setDate(date.getDate() - 31)
      ).toLocaleDateString('pt-BR', optionsDate);
      const initialCreatedDatePeriod =
        initialDate && initialDate.toLocaleDateString('pt-BR', optionsDate);
      const finalCreatedDatePeriod =
        finalDate && finalDate.toLocaleDateString('pt-BR', optionsDate);
      const orderedAndFiltered = movimentacoes.filter((item) => {
        const dateCreatedAtobj = new Date(
          item.data_movimentacao
        ).toLocaleDateString('pt-BR', optionsDate);
        switch (dateFilterValue) {
          case 'ultima semana':
            if (formateDate(dateCreatedAtobj) > formateDate(lastWeek)) {
              return item;
            }
            return false;
          case 'ultimo mes':
            if (formateDate(dateCreatedAtobj) > formateDate(lastMonth)) {
              return item;
            }
            return false;
          case 'data personalizada':
            if (
              formateDate(dateCreatedAtobj) >=
                formateDate(initialCreatedDatePeriod) &&
              formateDate(dateCreatedAtobj) <=
                formateDate(finalCreatedDatePeriod)
            ) {
              return item;
            }
            return false;
          default:
            return item;
        }
      });

      return orderedAndFiltered;
    }
    return movimentacoes;
  };

  const filterMovimentacoesByConclusaoDate = (movimentacoes) => {
    if (dataTipoFilter['conclusao']) {
      const date = new Date();
      const lastWeek = new Date(
        date.setDate(date.getDate() - 7)
      ).toLocaleDateString('pt-BR', optionsDate);
      const lastMonth = new Date(
        date.setDate(date.getDate() - 31)
      ).toLocaleDateString('pt-BR', optionsDate);
      const initialCreatedDatePeriod =
        initialDate && initialDate.toLocaleDateString('pt-BR', optionsDate);
      const finalCreatedDatePeriod =
        finalDate && finalDate.toLocaleDateString('pt-BR', optionsDate);
      const orderedAndFiltered = movimentacoes.filter((item) => {
        if (!item.data_conclusao) {
          return false;
        }
        const dateCreatedAtobj = new Date(
          item.data_conclusao
        ).toLocaleDateString('pt-BR', optionsDate);

        switch (dateFilterValue) {
          case 'ultima semana':
            if (formateDate(dateCreatedAtobj) > formateDate(lastWeek)) {
              return item;
            }
            return false;
          case 'ultimo mes':
            if (formateDate(dateCreatedAtobj) > formateDate(lastMonth)) {
              return item;
            }
            return false;
          case 'data personalizada':
            if (
              formateDate(dateCreatedAtobj) >=
                formateDate(initialCreatedDatePeriod) &&
              formateDate(dateCreatedAtobj) <=
                formateDate(finalCreatedDatePeriod)
            ) {
              return item;
            }
            return false;

          default:
            return item;
        }
      });

      return orderedAndFiltered;
    }
    return movimentacoes;
  };

  const filterMovimentacoes = () => {
    let filteredBeneficiaries = beneficiaries
      .filter((item) => {
        if (filterStatus === 'Todos') {
          return item;
        } else {
          return item.status_movimentacao === filterStatus;
        }
      })
      .filter((item) => {
        if (filterEstipulante !== 'mostrarTodos') {
          return item.estipulante === filterEstipulante;
        } else {
          return item;
        }
      })
      .filter((item) => {
        if (filterTipo === 'Todos') {
          return item;
        } else {
          return item.tipo_movimentacao === filterTipo;
        }
      })
      .filter((item) => {
        if (filterName !== '') {
          return item.nome_beneficiario
            .toLowerCase()
            .includes(filterName.toLowerCase());
        }
        return item;
      });

    filteredBeneficiaries = filterMovimentacoesByAberturaDate(
      filteredBeneficiaries
    );
    filteredBeneficiaries = filterMovimentacoesByConclusaoDate(
      filteredBeneficiaries
    );
    filteredBeneficiaries = orderMovimentacoes(filteredBeneficiaries);

    setBeneficiariesFilter(filteredBeneficiaries);
  };

  useEffect(() => {
    filterMovimentacoes();
  }, [
    beneficiaries,
    filterStatus,
    filterEstipulante,
    filterTipo,
    dataTipoFilter,
    dateFilterValue,
    initialDate,
    finalDate,
    orderSelected,
    filterName
  ]);

  useEffect(() => {
    posthog?.capture('Acessou "/rh/movimentacao-beneficiarios"');
    getEmpresa();
  }, []);

  useEffect(() => {
    if (triggerTest) {
      filterByEstipulante(filterEstipulante);
      setTriggerTest(false);
    }
  }, [triggerTest]);

  const handleCloseDataFilterPopper = (event) => {
    const classList = event.target.className ? event.target.className : '';
    const classListParentNode = event.target.parentNode
      ? event.target.parentNode.className
      : 'close';
    const classListString =
      typeof classList === 'object' ? 'dont-close' : classList;
    const dontClose =
      classListString.includes('dont-close') ||
      classListParentNode.includes('dont-close');

    if (dontClose) return;

    if (event.target && anchorElDataFilter !== event.target) {
      setAnchorElDataFilter(null);
    }
  };

  const dataFilterClickHandler = ({ target }) => {
    setAnchorElDataFilter((prevState) => (prevState ? null : target));
  };

  const dateFilterChangeHandler = ({ target }) => {
    setDateFilterValue((prevState) => {
      if (prevState === target.value) {
        setDataTipoFilter({
          abertura: false,
          conclusao: false
        });
        return '';
      }
      return target.value;
    });
  };

  const aberturaClickHandler = () => {
    setDataTipoFilter((prevState) => ({
      ...prevState,
      abertura: !prevState['abertura']
    }));
  };

  const conclusaoClickHandler = () => {
    setDataTipoFilter((prevState) => ({
      ...prevState,
      conclusao: !prevState['conclusao']
    }));
  };

  const filterOrderClickHandler = () => {
    setOrderSelected((prevState) => {
      if (prevState === 3) {
        return 1;
      }
      return prevState + 1;
    });
  };

  const renderOrderFilterSelected = () => {
    switch (orderSelected) {
      case 3:
        return (
          <HiOutlineArrowNarrowUp
            size={14}
            className="filter-data-abertura-icon dont-close"
            onClick={filterOrderClickHandler}
          />
        );
      case 2:
        return (
          <HiOutlineArrowNarrowDown
            size={14}
            className="filter-data-abertura-icon dont-close"
            onClick={filterOrderClickHandler}
          />
        );

      default:
        return (
          <BsFilterLeft
            size={17}
            className="filter-data-abertura-icon dont-close"
            onClick={filterOrderClickHandler}
          />
        );
    }
  };

  const renderBeneficiarioExpandedContratoInfoInclusao = (contratos) => {
    const contratosSaude = contratos.filter((item) => item.produto_id === 1);
    const maisRecenteSaude = contratosSaude
      .sort(
        (a, b) =>
          Number(new Date(b.contrato_beneficiario_created_at)) -
          Number(new Date(a.contrato_beneficiario_created_at))
      )
      .shift();

    const contratosOdonto = contratos.filter((item) => item.produto_id === 2);
    const maisRecenteOdonto = contratosOdonto
      .sort(
        (a, b) =>
          Number(new Date(b.contrato_beneficiario_created_at)) -
          Number(new Date(a.contrato_beneficiario_created_at))
      )
      .shift();

    const contratosMaisRecentes = [maisRecenteSaude, maisRecenteOdonto];

    return contratosMaisRecentes.map((contrato) => {
      if (!contrato) {
        return <div></div>;
      }
      return (
        <div className="align-left">
          <p className="name-type-contract">
            {contrato.produto_id === 1 ? (
              <GiHealthNormal size={13} className="contrato-icon" />
            ) : (
              <FaTooth size={13} className="contrato-icon" />
            )}
            {contrato.nome}{' '}
            {contrato?.numero_apolice
              ? `(Apólice: ${contrato?.numero_apolice})`
              : null}
          </p>
          <div className="first-column-container-plano">
            <p>
              <span className="plano-title-span">Plano</span>
              {contrato?.tipo_plano}
            </p>
          </div>

          <div className="first-column-container">
            <p>
              <span>Data de inclusão no Plano:</span>
            </p>
            <p className="paragraph-info">
              {contrato?.data_entrada
                ? moment(contrato?.data_entrada).format('DD/MM/YYYY')
                : ''}
            </p>
          </div>

          <div className="first-column-container">
            <p>
              <span>Nº de carteirinha:</span>
            </p>
            <p className="paragraph-info">{contrato.numero_carteirinha}</p>
          </div>
        </div>
      );
    });
  };

  const renderBeneficiarioExpandedContratoInfoExclusao = (contratos) => {
    // console.log(contratos);
    const contratosExclusao = contratos.filter(
      (item) => item.movimentacao_remocao
    );

    const contratosSaudeExclusao = contratosExclusao.filter(
      (item) => item.produto_id === 1
    );
    const maisRecenteContratoSaudeExclusao = contratosSaudeExclusao
      .sort(
        (a, b) =>
          Number(new Date(b.contrato_beneficiario_created_at)) -
          Number(new Date(a.contrato_beneficiario_created_at))
      )
      .shift();

    const contratosOdontoExclusao = contratosExclusao.filter(
      (item) => item.produto_id === 2
    );
    const maisRecenteContratoOdontoExclusao = contratosOdontoExclusao
      .sort(
        (a, b) =>
          Number(new Date(b.contrato_beneficiario_created_at)) -
          Number(new Date(a.contrato_beneficiario_created_at))
      )
      .shift();

    const contratosMaisRecentesExclusao = [
      maisRecenteContratoSaudeExclusao,
      maisRecenteContratoOdontoExclusao
    ];

    // console.log(contratosMaisRecentesExclusao)

    return contratosMaisRecentesExclusao.map((contrato) => {
      if (!contrato) {
        return <div></div>;
      }
      return (
        <div className="align-left">
          <p className="name-type-contract">
            {contrato.produto_id === 1 ? (
              <GiHealthNormal size={13} className="contrato-icon" />
            ) : (
              <FaTooth size={13} className="contrato-icon" />
            )}
            {contrato?.nome}{' '}
            {contrato?.numero_apolice
              ? `(Apólice: ${contrato?.numero_apolice})`
              : null}
          </p>
          <div className="first-column-container-plano">
            <p>
              <span className="plano-title-span">Plano</span>
              {contrato?.tipo_plano}
            </p>
          </div>
          <div className="first-column-container">
            <p>
              <span>Data de exclusão no Plano:</span>
            </p>
            <p className="paragraph-info">
              {contrato?.dataExclusao
                ? moment(contrato.dataExclusao).format('DD/MM/YYYY')
                : ''}
            </p>
          </div>

          {contrato.numero_carteirinha ? (
            <div className="first-column-container">
              <p>
                <span>Nº de carteirinha:</span>
              </p>
              <p className="paragraph-info">{contrato.numero_carteirinha}</p>
            </div>
          ) : null}
        </div>
      );
    });
  };

  const downloadExcelFile = () => {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Beneficiários', {
      properties: { tabColor: { argb: '456AED' } }
    });

    worksheet.columns = [
      {
        header: 'Beneficiário',
        key: 'nome_beneficiario',
        style: { alignment: { vertical: 'middle', horizontal: 'left' } },
        width: 40
      },
      {
        header: 'CPF',
        key: 'cpf',
        style: { alignment: { vertical: 'middle', horizontal: 'left' } },
        width: 25
      },
      {
        header: 'Data do Pedido',
        key: 'created_at',
        style: {
          numFmt: 'dd/mm/yyyy',
          alignment: { vertical: 'middle', horizontal: 'center' }
        },
        width: 25
      },
      {
        header: 'Tipo',
        key: 'tipo',
        style: { alignment: { vertical: 'middle', horizontal: 'left' } },
        width: 25
      },
      {
        header: 'Estipulante',
        key: 'estipulante',
        style: { alignment: { vertical: 'middle', horizontal: 'left' } },
        width: 60
      },
      {
        header: 'Sub-Estipulante',
        key: 'sub_estipulante',
        style: { alignment: { vertical: 'middle', horizontal: 'left' } },
        width: 60
      },
      {
        header: 'Seguradora',
        key: 'seguradora',
        style: { alignment: { vertical: 'middle', horizontal: 'left' } },
        width: 50
      },
      {
        header: 'Produto',
        key: 'produto',
        style: { alignment: { vertical: 'middle', horizontal: 'left' } },
        width: 20
      },
      {
        header: 'Carteirinha',
        key: 'numero_carteirinha',
        style: { alignment: { vertical: 'middle', horizontal: 'left' } },
        width: 25
      },
      {
        header: 'Tipo Movimentação',
        key: 'tipo_movimentacao',
        style: { alignment: { vertical: 'middle', horizontal: 'left' } },
        width: 25
      },
      {
        header: 'Status Movimentação',
        key: 'status_movimentacao',
        style: { alignment: { vertical: 'middle', horizontal: 'left' } },
        width: 25
      }
    ];

    beneficiariesFilter?.map((item) => {
      item?.contratos?.map((contrato) => {
        worksheet.addRow({
          nome_beneficiario: item?.nome_beneficiario,
          cpf: item?.dados?.cpf,
          created_at: moment(item?.created_at).format('DD/MM/YYYY'),
          tipo: item?.tipo,
          estipulante: !item?.estipulantePrincipal
            ? item?.estipulante
            : item?.estipulantePrincipal,
          sub_estipulante: !item?.estipulantePrincipal ? '' : item?.estipulante,
          seguradora: contrato?.nome,
          produto: contrato?.search,
          numero_carteirinha: contrato?.numero_carteirinha,
          status_movimentacao: item?.status_movimentacao,
          tipo_movimentacao: item?.tipo_movimentacao
        });
      });
    });

    worksheet.eachRow({ includeEmpty: true }, function (Row, rowNum) {
      Row.eachCell({ includeEmpty: true }, function (Cell, colNumber) {
        if (rowNum === 1) {
          Cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '456AED' }
          };

          Cell.font = {
            bold: true,
            size: 14,
            color: { argb: 'FFFFFF' }
          };
        }

        Cell.alignment = {
          vertical: 'middle',
          horizontal: 'center'
        };
      });

      Row.height = 30;
    });

    saveFile('Planilha de Movimentações', workbook);
  };

  async function saveFile(fileName, workbook) {
    const xls64 = await workbook.xlsx.writeBuffer({ base64: true });
    saveAs(
      new Blob([xls64], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }),
      fileName
    );
  }

  return (
    <Wrapper>
      <SidemenuRh />

      <Content>
        <Header />

        <Main>
          <div className="main-painel">
            <Consult>
              <div className="title">
                <h1>Movimentações de beneficiários</h1>
              </div>
            </Consult>

            <FiltersInfos>
              <div className="box-input">
                <label>Consultar por nome</label>
                <input
                  className="name-input"
                  value={filterName}
                  placeholder="Pesquisar Beneficiário..."
                  onChange={(e) => {
                    posthog?.capture('Mudou filtro "Nome"');
                    setFilterName(e.target.value);
                  }}
                />
              </div>

              <div>
                <label className="label-title">Consultar por tipo</label>
                <ThemeProvider theme={theme}>
                  <div className="status-options-container dont-close">
                    <div className="checkbox-container dont-close">
                      <Radio
                        value="Todos"
                        size="small"
                        onClick={({ target }) => filterByTipo(target.value)}
                        className="radio-input dont-close"
                        color={'darkGenoaBlue'}
                        checked={filterTipo === 'Todos'}
                      />
                      <label>Todos</label>
                    </div>
                    <div className="checkbox-container dont-close">
                      <Radio
                        value="Exclusão"
                        onClick={({ target }) => filterByTipo(target.value)}
                        size="small"
                        className="radio-input dont-close"
                        color={'darkGenoaBlue'}
                        checked={filterTipo === 'Exclusão'}
                      />
                      <label>Exclusão</label>
                    </div>
                    <div className="checkbox-container dont-close">
                      <Radio
                        value="Inclusão"
                        size="small"
                        onClick={({ target }) => filterByTipo(target.value)}
                        className="radio-input dont-close"
                        color={'darkGenoaBlue'}
                        checked={filterTipo === 'Inclusão'}
                      />
                      <label>Inclusão</label>
                    </div>
                  </div>
                </ThemeProvider>
              </div>

              <div>
                <label className="label-title">Consultar por Status</label>
                <ThemeProvider theme={theme}>
                  <div className="status-options-container dont-close">
                    <div className="checkbox-container dont-close">
                      <Radio
                        value="Todos"
                        size="small"
                        onClick={({ target }) =>
                          filterByStatusMovimentacao(target.value)
                        }
                        className="radio-input dont-close"
                        color={'darkGenoaBlue'}
                        checked={filterStatus === 'Todos'}
                      />
                      <label>Todos</label>
                    </div>
                    <div className="checkbox-container dont-close">
                      <Radio
                        value="Aberto"
                        size="small"
                        onClick={({ target }) =>
                          filterByStatusMovimentacao(target.value)
                        }
                        className="radio-input dont-close"
                        color={'darkGenoaBlue'}
                        checked={filterStatus === 'Aberto'}
                      />
                      <label>Em Aberto</label>
                    </div>
                    <div className="checkbox-container dont-close">
                      <Radio
                        value="Concluído"
                        size="small"
                        onClick={({ target }) =>
                          filterByStatusMovimentacao(target.value)
                        }
                        className="radio-input dont-close"
                        color={'darkGenoaBlue'}
                        checked={filterStatus === 'Concluído'}
                      />
                      <label>Concluído</label>
                    </div>
                  </div>
                </ThemeProvider>
              </div>

              <div className="filtro-data-container">
                <BsFilter size={20} onClick={dataFilterClickHandler} />
              </div>
              <Popper
                id={'id-data'}
                open={Boolean(anchorElDataFilter)}
                anchorEl={anchorElDataFilter}
                placement="bottom-end"
                transition
                disablePortal
              >
                <Box>
                  <ClickAwayListener onClickAway={handleCloseDataFilterPopper}>
                    <Paper
                      className="data-filter-options dont-close"
                      elevation={3}
                    >
                      <div className="filters-infos-second-row">
                        <div className="data-checkbox-container">
                          <FormControl>
                            <RadioGroup
                              className="checkbox-data-group-container"
                              onClick={dateFilterChangeHandler}
                              value={dateFilterValue}
                            >
                              <FormControlLabel
                                value="desde inicio"
                                control={
                                  <Radio
                                    sx={{
                                      padding: 0
                                    }}
                                    size="small"
                                    className="radio-checkbox"
                                  />
                                }
                                label="Desde o Início"
                              />
                              <FormControlLabel
                                value="ultima semana"
                                control={
                                  <Radio
                                    sx={{
                                      padding: 0
                                    }}
                                    size="small"
                                    className="radio-checkbox"
                                  />
                                }
                                label="Última Semana"
                              />
                              <FormControlLabel
                                value="ultimo mes"
                                control={
                                  <Radio
                                    sx={{
                                      padding: 0
                                    }}
                                    size="small"
                                    className="radio-checkbox"
                                  />
                                }
                                label="Último Mês"
                              />
                              <FormControlLabel
                                value="data personalizada"
                                control={
                                  <Radio
                                    sx={{
                                      padding: 0
                                    }}
                                    size="small"
                                    className="radio-checkbox"
                                  />
                                }
                                label="Data Personalizada:"
                              />
                            </RadioGroup>
                            <div className="datepicker-box-space">
                              <div className="datepicker-group-container">
                                <div className="datepicker-selector-container">
                                  <div className="span-date-container">
                                    <span>Data Inicial:</span>
                                  </div>

                                  <DatePicker
                                    className={
                                      dateFilterValue === 'data personalizada'
                                        ? 'input-data'
                                        : 'input-data-disabled'
                                    }
                                    disabled={
                                      dateFilterValue !== 'data personalizada'
                                    }
                                    selected={
                                      dateFilterValue === 'data personalizada'
                                        ? initialDate
                                        : ''
                                    }
                                    popperPlacement="left-start"
                                    locale="ptBR"
                                    onChange={(value) => setInitialDate(value)}
                                    placeholderText={
                                      dateFilterValue === 'data personalizada'
                                        ? '...'
                                        : '...'
                                    }
                                    maxDate={new Date()}
                                    dateFormat="dd/MM/yyyy"
                                  />
                                </div>
                                <div className="datepicker-selector-container">
                                  <div className="span-date-container">
                                    <span>Data Final:</span>
                                  </div>

                                  <DatePicker
                                    className={
                                      dateFilterValue === 'data personalizada'
                                        ? 'input-data'
                                        : 'input-data-disabled'
                                    }
                                    disabled={
                                      dateFilterValue !== 'data personalizada'
                                    }
                                    popperPlacement="right-start"
                                    selected={
                                      dateFilterValue === 'data personalizada'
                                        ? finalDate
                                        : ''
                                    }
                                    locale="ptBR"
                                    onChange={(value) => setFinalDate(value)}
                                    placeholderText={
                                      dateFilterValue === 'data personalizada'
                                        ? '...'
                                        : '...'
                                    }
                                    maxDate={new Date()}
                                    dateFormat="dd/MM/yyyy"
                                  />
                                </div>
                              </div>
                            </div>
                          </FormControl>
                        </div>
                      </div>
                    </Paper>
                  </ClickAwayListener>
                </Box>
              </Popper>
              <Popper
                id={'id-data'}
                open={Boolean(anchorElDataFilter && dateFilterValue !== '')}
                anchorEl={anchorElDataFilter}
                placement="bottom-start"
                transition
                disablePortal
              >
                <Box>
                  <ClickAwayListener onClickAway={(e) => console.log(e.target)}>
                    <Paper
                      className="data-filter-selection dont-close"
                      elevation={6}
                    >
                      <div className="box-input dont-close">
                        <div className="data-filters-container dont-close">
                          <div className="dont-close">
                            <Radio
                              className="dont-close"
                              size={'small'}
                              sx={{
                                padding: 0,
                                marginRight: '10px',
                                '&.Mui-checked': {
                                  color: colors.genoaDarkBlue
                                }
                              }}
                              checked={dataTipoFilter['abertura']}
                              onClick={aberturaClickHandler}
                            />
                            <span className="dont-close">Abertura</span>
                          </div>
                          <div className="dont-close">
                            <Radio
                              className="dont-close"
                              size={'small'}
                              sx={{
                                padding: 0,
                                marginRight: '10px',
                                '&.Mui-checked': {
                                  color: colors.genoaDarkBlue
                                }
                              }}
                              checked={dataTipoFilter['conclusao']}
                              onClick={conclusaoClickHandler}
                            />
                            <span className="dont-close">Conclusão</span>
                          </div>
                        </div>
                      </div>
                    </Paper>
                  </ClickAwayListener>
                </Box>
              </Popper>

              <div className="btn-download">
                <button onClick={downloadExcelFile}>download</button>
                <img
                  width="30"
                  height="30"
                  src="https://img.icons8.com/color/48/microsoft-excel-2019--v1.png"
                  alt="microsoft-excel-2019--v1"
                />
                {/* {console.log("Arquivo Excel", beneficiaries)} */}
              </div>
            </FiltersInfos>

            {beneficiaries.length > 0 ? (
              <ContainerBeneficiary>
                {/* Renderizar TODOS os Novos Beneficiários */}

                <div className="title-box-beneficiary">
                  <div className="master-checkbox-container"></div>
                  <p className="tipo-paragraph">Tipo</p>
                  <p>Beneficiário</p>
                  <p>
                    <span className="data-do-pedido-span">Data do Pedido </span>
                    {/* {console.log(orderSelected)} */}
                    {renderOrderFilterSelected()}
                  </p>
                  <p>Titularidade</p>
                  <p>Estipulante ou Sub Estipulante</p>
                  <p>Produto</p>
                  <p>Status</p>
                </div>
                {beneficiariesFilter &&
                  beneficiariesFilter.map((item, index) => (
                    <div className="box-beneficiary" key={index}>
                      {/* {console.log('movi-benefi-rh', item)} */}
                      <div className="header-box-beneficiary">
                        <div></div>
                        <TypeMovement
                          type={item.tipo_movimentacao}
                          className="tipo-paragraph"
                        >
                          {item.tipo_movimentacao === 'Inclusão' ? (
                            <BiPlus />
                          ) : (
                            item.tipo_movimentacao === 'Exclusão' && (
                              <RiSubtractFill />
                            )
                          )}
                          <span>{item.tipo_movimentacao}</span>
                        </TypeMovement>
                        <p>{item.nome_beneficiario}</p>
                        <p>
                          {moment(item.data_movimentacao).format('DD/MM/YYYY')}
                        </p>
                        <p>{item.tipo}</p>
                        <p>{item.estipulante}</p>
                        {item.contratos && (
                          <p className="flex-products">
                            {item.contratos?.find(
                              (contrato) =>
                                contrato.nome_produto === 'Plano de Saúde'
                            ) ? (
                              <span>{<GiHealthNormal size={18} />}</span>
                            ) : null}
                            {item.contratos?.find(
                              (contrato) =>
                                contrato.nome_produto === 'Plano Odontológico'
                            ) ? (
                              <span>{<FaTooth size={18} />}</span>
                            ) : null}
                          </p>
                        )}
                        <p>{item.status_movimentacao}</p>
                        {expand ===
                        `${item.movimentacao_id}&${item.nome_produto}` ? (
                          <p className="more-infos">
                            <AiFillEyeInvisible
                              onClick={() =>
                                openDetails(
                                  item.movimentacao_id,
                                  item.nome_produto
                                )
                              }
                            />
                          </p>
                        ) : (
                          <p className="more-infos">
                            <AiFillEye
                              onClick={() =>
                                openDetails(
                                  item.movimentacao_id,
                                  item.nome_produto
                                )
                              }
                            />
                          </p>
                        )}
                      </div>
                      {expand ===
                      `${item.movimentacao_id}&${item.nome_produto}` ? (
                        item.tipo_movimentacao === 'Inclusão' ? (
                          <section className="more-infos-beneficiary">
                            <div className="first-column-container">
                              <p>
                                <span>CPF do Beneficiário:</span>{' '}
                                {item.tipo === 'Titular'
                                  ? item.cpfTitular
                                  : item.cpfDependente}
                              </p>

                              {item.tipo === 'Titular' &&
                              item.data_vinculo_titular !== null ? (
                                <p>
                                  <span>Data de vínculo:</span>{' '}
                                  {moment(item.data_vinculo_titular).format(
                                    'DD/MM/YYYY'
                                  )}
                                </p>
                              ) : null}

                              {item.tipo !== 'Titular' &&
                              item.data_vinculo_dependente !== null ? (
                                <p>
                                  <span>Data de vínculo:</span>{' '}
                                  {moment(item.data_vinculo_dependente).format(
                                    'DD/MM/YYYY'
                                  )}
                                </p>
                              ) : null}

                              {/* {item.data_vinculo_titular !== null || item.data_vinculo_dependente !== null ?
                              <p><span>Data de vínculo:</span> {item.tipo === 'Titular' ?
                                moment(item.data_vinculo_titular).format('DD/MM/YYYY') : moment(item.data_vinculo_dependente).format('DD/MM/YYYY')
                              }
                              </p>
                              : null
                            } */}
                            </div>

                            <div
                              className={`infos-inclusion-beneficiary ${
                                item.data_exclusao && 'align-left'
                              }`}
                            >
                              <div className="box-show-infos-plans">
                                {renderBeneficiarioExpandedContratoInfoInclusao(
                                  item?.contratos
                                )}
                              </div>
                            </div>
                          </section>
                        ) : (
                          item.tipo_movimentacao === 'Exclusão' && (
                            <section className="more-infos-beneficiary">
                              {/* {console.log(item)} */}
                              <div className="first-column-container">
                                <p>
                                  <span>CPF do Beneficiário:</span>{' '}
                                  {item.tipo === 'Titular'
                                    ? item.cpfTitular
                                    : item.cpfDependente}
                                </p>

                                {item.tipo === 'Titular' &&
                                item.data_vinculo_titular !== null ? (
                                  <p>
                                    <span>Data de vínculo:</span>{' '}
                                    {moment(item.data_vinculo_titular).format(
                                      'DD/MM/YYYY'
                                    )}
                                  </p>
                                ) : null}

                                {item.tipo !== 'Titular' &&
                                item.data_vinculo_dependente !== null ? (
                                  <p>
                                    <span>Data de vínculo:</span>{' '}
                                    {moment(
                                      item.data_vinculo_dependente
                                    ).format('DD/MM/YYYY')}
                                  </p>
                                ) : null}

                                {/* {item.dados.data_contratacao !== null || item.dados.data_vinculo !== null ?
                              <p><span>Data de vínculo:</span> {item.tipo === 'Titular' ?
                                moment(item.dados.data_contratacao).format('DD/MM/YYYY') : moment(item.dados.data_vinculo).format('DD/MM/YYYY')
                              }</p>
                              : null
                            } */}
                              </div>

                              <div
                                className={`infos-inclusion-beneficiary ${
                                  item.dataExclusao && 'align-left'
                                }`}
                              >
                                <div className="box-show-infos-plans">
                                  {renderBeneficiarioExpandedContratoInfoExclusao(
                                    item?.contratos
                                  )}
                                </div>
                              </div>
                            </section>
                          )
                        )
                      ) : null}
                    </div>
                  ))}
              </ContainerBeneficiary>
            ) : isLoading ? (
              <div className="text-center-movement">
                <CircularProgress
                  size={100}
                  thickness={2.5}
                  className="circular-progress-loading"
                />
                Carregando beneficiários...
              </div>
            ) : (
              ''
            )}
          </div>
        </Main>
      </Content>

      <Toaster position="top-center" />
    </Wrapper>
  );
}
