import { useState, useEffect } from 'react';
import { Box, Checkbox, ClickAwayListener, Paper } from '@material-ui/core';
import { Popper } from '@mui/material';
// import { SelectComponent } from './styles';
import '../Checkmark/style.scss';

const CheckmarkStandard = (props) => {
  const {
    value,
    setValue,
    label,
    name = '',
    options,
    dropDownHeight,
    selectPlaceholder,
    disabled,
    variant = 'broker',
    variantHover = ''
  } = props;

  const [focused, setFocused] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [spanWidth, setSpanWidth] = useState(undefined);

  const renderValue = () => {
    const selectedOptions = options.filter((option) => value[option.value]);
    const todosCheckados = selectedOptions.length === options.length;

    if (todosCheckados) {
      return 'Todos';
    }

    return selectedOptions.map((option) => option.option).join(', ');
  };

  const optionClickHandler = (value) => {
    setValue((prevState) => ({
      ...prevState,
      [value]: !prevState[value]
    }));
  };

  const toggleAllOptions = () => {
    const allSelected =
      options.length === Object.keys(value).filter((key) => value[key]).length;
    const newValue = {};

    options.forEach((option) => {
      newValue[option.value] = !allSelected;
    });

    setValue((prevState) => ({
      ...newValue
    }));
  };

  const renderMaxDropdownheight = () => {
    if (dropDownHeight?.length) {
      return dropDownHeight;
    }
    return '200px';
  };

  const renderOptions = () => {
    return options?.map((item) => (
      <div
        className={`option option--${variantHover}`}
        key={item.value}
        // onClick={() => optionClickHandler(item.value)}
      >
        <div className="checkbox_container">
          <Checkbox
            className={`option__checkbox option__checkbox--${variant}-checkbox`}
            onClick={() => optionClickHandler(item.value)}
            checked={!!value[item.value]}
          />
        </div>
        <span>{item.option}</span>
      </div>
    ));
  };
  const getSpanWidth = () => {
    const element = document.querySelector('.select-trigger');
    const width = element?.offsetWidth * 0.94;

    setSpanWidth(width);
  };

  const renderSelectPlaceholder = () => {
    if (selectPlaceholder?.length) {
      return selectPlaceholder;
    }
    return 'Selecione';
  };

  useEffect(() => {
    getSpanWidth();
    window.addEventListener('resize', getSpanWidth);
  });

  return (
    <ClickAwayListener onClickAway={() => setFocused(false)}>
      <div className="select-checkbox-component">
        <label
          className={`select-checkbox-component__label ${
            disabled
              ? 'select-checkbox-component--disabled'
              : renderValue() || focused
              ? `translate translate--${variant}-label`
              : ''
          }`}
          onClick={() => {
            if (!disabled) {
              setFocused((prevState) => !prevState);
            }
          }}
        >
          {label}
        </label>
        <div
          className={
            disabled
              ? `select-trigger ${name} disabled`
              : value || focused
              ? `select-trigger focused--${variant}-focused ${name}`
              : `select-trigger ${name}`
          }
          onClick={(e) => {
            if (!disabled) {
              setFocused((prevState) => !prevState);
              setAnchorEl(e.currentTarget.parentNode);
            }
          }}
          tabIndex="0"
        >
          <span
            className={disabled ? 'disabled' : ''}
            style={{ width: spanWidth }}
          >
            {disabled
              ? ''
              : focused
              ? renderValue()
                ? renderValue()
                : renderSelectPlaceholder()
              : value
              ? renderValue()
              : ''}
          </span>
        </div>
        {!disabled && options?.length ? (
          <Popper
            open={focused}
            anchorEl={anchorEl}
            placement="bottom-start"
            disablePortal
            className="popper-container"
          >
            <Box>
              <Paper elevation={8}>
                <div
                  className={`select-checkbox-component__options-wrapper select-checkbox-component__options-wrapper--${variant}-options`}
                  style={{
                    width: anchorEl?.offsetWidth,
                    maxHeight: renderMaxDropdownheight()
                  }}
                >
                  <div className={`option option--${variantHover}`}>
                    <div className="checkbox_container">
                      <Checkbox
                        className={`option__checkbox option__checkbox--${variant}-checkbox`}
                        onClick={toggleAllOptions}
                        checked={
                          Object.keys(value).filter((key) => value[key])
                            .length === options.length
                        }
                      />
                    </div>
                    <span>
                      {Object.keys(value).filter((key) => value[key]).length ===
                      options.length
                        ? 'Desmarcar Todos'
                        : 'Selecionar Todos'}
                    </span>
                  </div>
                  {renderOptions()}
                </div>
              </Paper>
            </Box>
          </Popper>
        ) : (
          ''
        )}
      </div>
    </ClickAwayListener>
  );
};

export default CheckmarkStandard;
