const USER_ACTIONS = {
  SET_USER: 'SET_USER',
  ATT_USER: 'ATT_USER',
  ATT_ACESSED_PAGES: 'ATT_ACESSED_PAGES',
  RESET_ACESSED_PAGES: 'RESET_ACESSED_PAGES'
}

const FORM_ACTIONS = {
  SET_STAGE: 'SET_STAGE',
  RESET_STAGE: 'RESET_STAGE',
  SET_DATA: 'SET_DATA',
  RESET_DATA: 'RESET_DATA',
  SET_TICKET: 'SET_TICKET',
  SET_DOWNLOAD_FORM_EXT: 'SET_DOWNLOAD_FORM_EXT'
}

const NOTIFICATIONS_CORRETORA_ACTIONS = {
  SET_SIDEMENU_NOTIFICATIONS: 'SET_SIDEMENU_NOTIFICATIONS'
}

const URGENT_DEMANDS = {
  SHOW_URGENT_DEMAND: 'SHOW_URGENT_DEMAND',
  RESET_SHOW_URGENT_DEMAND: 'RESET_SHOW_URGENT_DEMAND'
}

const USUARIO_LOGADO = {
  SET_USUARIO_LOGADO: 'SET_USUARIO_LOGADO',
  RESET_USUARIO_LOGADO: 'RESET_USUARIO_LOGADO'
}

export {
  USER_ACTIONS,
  FORM_ACTIONS,
  NOTIFICATIONS_CORRETORA_ACTIONS,
  URGENT_DEMANDS,
  USUARIO_LOGADO
}

