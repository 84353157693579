import styled from 'styled-components';
import colors from 'apps/broker/styles/colors';

export const FiltersInfos = styled.section`
  display: grid;
  grid-template-columns: 350px 280px 300px 80px 105px;
  grid-gap: 30px;
  align-items: end;
  color: ${colors.genoaGrey};
  margin-bottom: 15px;

  .edit-buttons-container {
    display: grid;
    grid-template-columns: 120px 120px;
    align-items: center;
    justify-content: center;
  }

  .button-edit {
    display: flex;
    align-items: center;
    background-color: ${colors.genoaGrey};
    border: 0;
    margin: 0;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    color: ${colors.white};
    justify-content: center;
    &:hover {
      cursor: pointer;
    }
  }

  .delete-button {
    color: ${colors.genoaBlue};
    &:hover {
      cursor: pointer;
    }
  }

  .paper-popup-delete-confirmation {
    width: 500px;
    height: 80px;
    background-color: ${colors.white};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .data-filter-options {
    margin-top: 7px;
    width: 230px;
    height: 220px;
    background-color: ${colors.white};
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .data-filter-selection {
    margin-top: 5px;
    margin-left: 10px;
    margin-top: 60px;
    border-radius: 15px;
    width: 150px;
    height: 90px;
    background-color: ${colors.white};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .popup-options-container {
    margin-top: 5px;
  }

  .popup-options {
    margin-left: 10px;
    margin-right: 10px;
    align-items: center;
    &:hover {
      font-weight: bold;
      cursor: pointer;
    }
  }

  .popup-message {
    font-weight: bold;
  }

  .check-icon {
    margin-right: 2px;
    color: green;
  }

  .cross-icon {
    margin-right: 2px;
    color: red;
  }

  div.box-input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    label {
      font-family: 'Avenir Next GEO W05 Demi';
    }

    select {
      font-family: 'Avenir Next GEO W05 Regular';
      padding: 10px;
      min-width: 150px;
      border-radius: 8px;
      border: 1px ${colors.genoaBlue} solid;
      margin-top: 10px;

      :focus-visible {
        outline: 1px ${colors.genoaBlue}f5 auto;
      }
    }

    .select-estipulante {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      font-family: 'Avenir Next GEO W05 Regular';
      padding: 10px;
      padding-left: 20px;
      padding-right: 35px;
      background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 9L0.73686 0.75L10.2631 0.75L5.5 9Z' fill='%23456AED'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position-x: 96%;
      background-position-y: 12px;
      width: 350px;
      border-radius: 20px;
      border: none;
      font-size: 0.7rem;
      margin-top: 12px;
      :focus-visible {
        outline: 1px ${colors.genoaBlue}f5 auto;
      }
    }

    .label-data-filters {
      margin-left: 10px;
    }

    .data-filters-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
      font-size: 0.8rem;
      font-family: 'Avenir Next GEO W05 Regular';
    }
  }

  .filtro-data-container {
    margin-bottom: 8px;
    width: max-content;

    :hover {
      cursor: pointer;
    }
  }

  .status-options-container {
    color: ${colors.genoaGrey};
    display: flex;
    gap: 15px;
    margin-top: 20px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
  }

  .label-title {
    color: ${colors.genoaGrey};
    margin-left: 9px;
    font-family: 'Avenir Next GEO W05 Demi';
  }

  .checkbox-container {
    label {
      font-size: 0.7rem;
      line-height: 13px;
      font-family: 'Avenir Next GEO W05 Regular';
    }
  }
`;
