import CheckboxGroup from 'components/DS/CheckboxGroup';
import Button from 'components/DS/Button';
import './styles.scss';

const FormFirstStep = ({
  selectedOptionFirstStep,
  setSelectedOptionFirstStep,
  nextStep,
  setProgressLegendTotal
}) => {
  const handleOptionChange = (e) => {
    console.log(e.target.value);
    setSelectedOptionFirstStep(e.target.value);
  };

  return (
    <div className="first-step">
      <div className="first-step__form">
        <div>
          <span>Quem irá aderir ao Seguro Saúde?</span>
        </div>
        <div>
          <CheckboxGroup
            text={'Apenas eu.'}
            value={'titular'}
            variant={selectedOptionFirstStep === 'titular' ? 'selected' : 'primary'}
            onClick={(e) => {
              handleOptionChange(e);
              setProgressLegendTotal(4);
            }}
          />
        </div>
        <div>
          <CheckboxGroup
            text={'Eu e meus dependentes.'}
            value={'titular-dependentes'}
            variant={
              selectedOptionFirstStep === 'titular-dependentes' ? 'selected' : 'primary'
            }
            onClick={(e) => {
              handleOptionChange(e);
              setProgressLegendTotal(5);
            }}
          />
        </div>

        <div className="first-step__button-next">
          <Button
            type="button"
            isDisabled={selectedOptionFirstStep !== '' ? false : true}
            onClick={nextStep}
          >
            Avançar
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FormFirstStep;
