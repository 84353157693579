import { useState } from 'react';
import { Documents } from './styles';
import { useForm } from 'react-hook-form';
import { BiFolderOpen } from 'react-icons/bi';
import Loading from 'components/Loading';
import { FiEdit, FiX } from 'react-icons/fi';
import ModalEditDocument from 'components/ModalEditDocument';
import ModalDeleteDocument from 'components/ModalDeleteDocument';
import { BsFileEarmarkText } from 'react-icons/bs';
import services from 'apps/broker/services';
import TopBar from 'apps/broker/components/HorizontalBar/topBar';
import BottomBar from 'apps/broker/components/HorizontalBar/bottomBar';

const DocumentosInner = ({ title, contrato, index, getDataDrawer }) => {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    watch,
    formState: { errors }
  } = useForm();
  const [loadUpdate, setLoadUpdate] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState();

  const onSubmit = (data) => {
    if (data.arquivo?.length === 0 || data.arquivo === null) {
      setError(`${data.contrato}.arquivo`, {
        type: 'manual'
      });
      return;
    } else {
      setLoadUpdate(true);
      const formData = new FormData();
      formData.append('file', data.arquivo[0]);
      formData.append('nome_documento', data.nome_documento);

      const type = 'Documento';

      services.insuranceCompanies
        .createDocumentByUUID(data, formData, type)
        .then(() => {
          getDataDrawer();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoadUpdate(false);
        });
    }
  };

  const onSubmitForm = (data) => {
    if (data.formExt?.length === 0 || data.formExt === null) {
      setError(`${data.contrato}.formExt`, {
        type: 'manual'
      });
      return;
    } else {
      setLoadUpdate(true);
      const formData = new FormData();
      formData.append('file', data.formExt[0]);

      const type = 'formExt';

      services.insuranceCompanies
        .createDocumentByUUID(data, formData, type)
        .then(() => {
          getDataDrawer();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoadUpdate(false);
        });
    }
  };

  return (
    <Documents>
      <Loading open={loadUpdate} setOpen={() => setLoadUpdate(false)} />
      <TopBar title={'Documentos'} />
      <div className="sectionContent" key={index}>
        <div className="listFile">
          <ul>
            {contrato.documentos.map((x) => {
              return (
                <>
                  <li className="file-document">
                    <BsFileEarmarkText size={17} className="icon-documento" />
                    <a href={x.file_link} className="link-text">
                      {x.file_type}
                    </a>
                    <FiX
                      className="delete-file"
                      onClick={() => {
                        setDeleteModal(true);
                        setSelectedDocument(x);
                      }}
                    />
                    <FiEdit
                      className="edit-file"
                      onClick={() => {
                        setOpenModal(true);
                        setSelectedDocument(x);
                      }}
                    />
                  </li>
                </>
              );
            })}
          </ul>
          <div className="div-input-file">
            <form
              onSubmit={handleSubmit((data) =>
                onSubmit(data[contrato.idref_contrato])
              )}
            >
              {errors[contrato.idref_contrato]?.arquivo && (
                <span className="error-msg">
                  Selecione um documento para enviar
                </span>
              )}

              <label className="label-file">
                {watch(`${contrato.idref_contrato}.arquivo`)?.[0]?.name ? (
                  <span>
                    <BiFolderOpen size={19} className="icon-folder" />
                    {`${watch(
                      `${contrato.idref_contrato}.arquivo`
                    )[0]?.name.slice(0, 45)}`}
                  </span>
                ) : (
                  <span>+ Documento </span>
                )}
                <input
                  {...register(`${contrato.idref_contrato}.arquivo`, {
                    required: false
                  })}
                  className="input-file"
                  type="file"
                />

                <input
                  {...register(`${contrato.idref_contrato}.contrato`, {
                    required: false
                  })}
                  value={contrato.idref_contrato}
                  className="input"
                  type="hidden"
                />
              </label>

              <div className="div-input-files">
                <input
                  {...register(`${contrato.idref_contrato}.nome_documento`, {
                    required: false
                  })}
                  className="document-name"
                  type="text"
                  placeholder="Nome do documento"
                />

                <div className="buttons">
                  {watch(`${contrato.idref_contrato}.arquivo`)?.[0]?.name && (
                    <button
                      type="button"
                      onClick={() => {
                        setValue(`${contrato.idref_contrato}.arquivo`, null);
                      }}
                      className="btn-clear"
                    >
                      X
                    </button>
                  )}

                  <button type="submit" className="btn">
                    Salvar
                  </button>
                </div>
              </div>
            </form>
            {errors.arquivo && (
              <span className="errorsMsg">campo obrigatório</span>
            )}
          </div>
          {!contrato.documentos.find(
            (element) => element.file_type === 'Formulário externo'
          ) ? (
            <div className="div-input-file">
              <form
                onSubmit={handleSubmit((data) =>
                  onSubmitForm(data[contrato.idref_contrato])
                )}
              >
                {errors[contrato.idref_contrato]?.arquivo && (
                  <span className="error-msg">
                    Selecione um documento para enviar
                  </span>
                )}
                <div className="div-input-files">
                  <label className="label-file">
                    {watch(`${contrato.idref_contrato}.formExt`)?.[0]?.name ? (
                      <span>
                        <BiFolderOpen />{' '}
                        {`${watch(
                          `${contrato.idref_contrato}.formExt`
                        )[0]?.name.slice(0, 45)}`}{' '}
                      </span>
                    ) : (
                      <span>+ Formulário externo </span>
                    )}
                    <input
                      {...register(`${contrato.idref_contrato}.formExt`, {
                        required: false
                      })}
                      className="input-file"
                      type="file"
                    />

                    <input
                      {...register(`${contrato.idref_contrato}.contrato`, {
                        required: false
                      })}
                      value={contrato.idref_contrato}
                      className="input"
                      type="hidden"
                    />
                  </label>
                  <div className="buttons">
                    {watch(`${contrato.idref_contrato}.formExt`)?.[0]?.name && (
                      <button
                        type="button"
                        onClick={() => {
                          setValue(`${contrato.idref_contrato}.formExt`, null);
                        }}
                        className="btn-clear"
                      >
                        X
                      </button>
                    )}

                    <button type="submit" className="btn">
                      Enviar
                    </button>
                  </div>
                </div>
              </form>
              {errors.arquivo && (
                <span className="errorsMsg">campo obrigatório</span>
              )}
            </div>
          ) : null}
        </div>
      </div>
      <BottomBar />

      <ModalEditDocument
        openModal={openModal}
        setOpenModal={setOpenModal}
        document={selectedDocument}
      />
      <ModalDeleteDocument
        openModal={deleteModal}
        setOpenModal={setDeleteModal}
        document={selectedDocument}
      />
    </Documents>
  );
};

export default DocumentosInner;
