import { Link } from 'react-router-dom';
import './styles.scss';

const LinkComponent = (props) => {
  const { children, className = '', ...rest } = props;

  return (
    <Link className={`ds-link ${className}`} {...rest}>
      {children}
    </Link>
  );
};

export default LinkComponent;
