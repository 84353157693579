import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import UpdateSeguroSaude from './components/UpdateHealthInsurance/SeguroSaudeAtualizacao';
import UpdateSeguroOdonto from './components/UpdateDentalInsurance/SeguroOdontoAtualizacao';
import { BsCircleFill } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { dataDiffYear } from 'utils/calculateFunctions';
import { FiEdit } from 'react-icons/fi';
import StatusCircle from '../StatusCircle';
import StatusContract from '../StatusContract';
import DadosGerais from '../PolicyOwnerDetails/components/Tabs/DadosGerais';
import './styles.scss';

const PolicyOwnerDetails = ({
  brokerInformation,
  onFetchContract,
  contract,
  onEdit,
  ...props
}) => {
  const user = useSelector((state) => state.userReducers.user);
  const [editContract, setEditContract] = useState(false);

  const description = `${brokerInformation?.cnpj} - Cliente há ${
    dataDiffYear(brokerInformation.clienteData.dataVinculo) || '0'
  } anos`;

  const onEditContract = () => {
    setEditContract(contract);
  };

  return (
    <div className="policy-owner-details">
      <div className="details__header">
        <h2 className="details__header-title">
          {brokerInformation.businessName}
        </h2>
        <p className="details__header-description">{description}</p>
      </div>

      <div className="details">
        <div className="details__sub-header container">
          <div className="details__item">
            <span className="details__item-title details__item-title--primary">
              {contract?.nome_completo}
            </span>

            <StatusCircle vigencia_final={contract.vigencia_final} />

            <StatusContract vigencia_final={contract.vigencia_final} />

            <span className="icon-edit" onClick={onEditContract}>
              <FiEdit />
            </span>
          </div>

          <div className="details__item">
            <span className="details__item-title">Apólice:</span>
            <span className="details__item-value">
              {contract.numero_apolice}
            </span>
          </div>

          <div className="details__item">
            <span className="details__item-title">Vigência inicial:</span>
            <span className="details__item-value">
              {moment(contract.vigencia_inicial).format('DD/MM/YYYY')}
            </span>
          </div>

          <div className="details__item">
            <span className="details__item-title">Vidas:</span>
            <span className="details__item-value">
              {contract.lifesInsuranceTotal}
            </span>
          </div>
        </div>
      </div>

      {!editContract && (
        <DadosGerais
          data={[contract]}
          setData={() => {}}
          selectVidas={(idref) => console.log(idref)}
          selectUpdate={(data) => console.log(data)}
          getData={onFetchContract}
        />
      )}

      {editContract?.type === 'saude' && (
        <UpdateSeguroSaude
          estipulante={brokerInformation.id_estipulante}
          subs={brokerInformation.subestipulantes}
          setEditContract={setEditContract}
          getDataDrawer={onFetchContract}
          contrato={editContract}
        />
      )}

      {editContract?.type === 'odonto' && (
        <UpdateSeguroOdonto
          estipulante={brokerInformation.id_estipulante}
          subs={brokerInformation.subestipulantes}
          setEditContract={setEditContract}
          getDataDrawer={onFetchContract}
          contrato={contract}
        />
      )}
    </div>
  );
};

export default PolicyOwnerDetails;
