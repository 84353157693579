import React from "react";
import { Wrapper } from "./styles-drawerEditarUsuario";

// import { useForm } from "react-hook-form";

import api from "services/api";
import { useSelector } from "react-redux";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
// import { GiHealthNormal } from "react-icons/gi";
// import { FaTooth } from "react-icons/fa";
// import { BsFillCalendarEventFill } from "react-icons/bs";
import { RiLockPasswordFill, RiSendPlaneFill } from "react-icons/ri";
import ReactInputMask from "react-input-mask";

const DrawerEditarUsuario = (props) => {
  // console.log(props.data)
  const [showPassword, setShowPassword] = React.useState(false);
  const { data: usuario } = props;
  const [nome, setNome] = React.useState(usuario.nome);
  const [sobrenome, setSobrenome] = React.useState(usuario.sobrenome);
  const [email, setEmail] = React.useState(usuario.email);
  const [telefone, setTelefone] = React.useState(usuario?.telefone ? usuario.telefone : "");
  const [password, setPassword] = React.useState("");
  const [cargo, setCargo] = React.useState(usuario.cargo);
  const [showResponseMessage, setShowResponseMessage] = React.useState(false);
  const [showErrorMessage, setShowErrorMessage] = React.useState(false);
  const [responseMessage, setResponseMessage] = React.useState("");

  const [changePassword, setChangePassword] = React.useState(false);

  // const {
  //   register,
  //   handleSubmit,
  //   watch,
  //   formState: { errors },
  // } = useForm();


  const user = useSelector((state) => state.userReducers.user);

  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  }

  const requestEditUser = () => {
    const headers = {
      authorization: `Bearer ${user.token}`
    }

    const body = {
      nome,
      sobrenome,
      email,
      telefone,
      password,
      changePassword,
      cargo,
      usuario_id: usuario.id
    }

    if (!nome || !sobrenome || !email || !cargo) {
      setShowErrorMessage(true);
      setResponseMessage("Por favor não deixar nenhum campo em branco")
      setShowResponseMessage(true);
      setTimeout(() => {
        setShowResponseMessage(false)
        setShowErrorMessage(false);
        setResponseMessage("")
      }, 3500)

      return
    } else if (changePassword && !password) {
      setShowErrorMessage(true);
      setResponseMessage("Por favor não deixar nenhum campo em branco")
      setShowResponseMessage(true);
      setTimeout(() => {
        setShowResponseMessage(false)
        setShowErrorMessage(false);
        setResponseMessage("")
      }, 3500)

      return
    }

    api.post('/rh/usuarios/editar-usuario', body, { headers })
      .then((resp) => {
        // console.log(resp)
        if (resp.data.message) {
          setResponseMessage(resp.data.message);
          setShowErrorMessage(resp.data.status !== 200 ? true : false);
          setShowResponseMessage(true);
          setTimeout(() => {
            setShowResponseMessage(false)
            setShowErrorMessage(false);
            setResponseMessage("")
          }, 3500)
        }
        props.getData()
      })
  }

  const phoneMask = () => {
    // console.log(telefone?.length)
    return telefone[5] !== "9" ?
      "(99) 9999 - 9999" : "(99) 99999 - 9999"
  }


  return (
    <Wrapper>
      <header>
        <div className="header-title-container">
          <span>Editar Usuário</span>
        </div>
      </header>
      <section className="infos">
        <form autoComplete="off">
          <div className="input-container">
            <label>Nome</label>
            <input
              type={"text"}
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
          </div>
          <div className="input-container">
            <label>Sobrenome</label>
            <input
              type={"text"}
              value={sobrenome}
              onChange={(e) => setSobrenome(e.target.value)}
            />
          </div>
          <div className="input-container">
            <label>Email</label>
            <input
              type={"email"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="new-email"
            />
          </div>
          <div className="input-container">
            <label>Telefone</label>
            <ReactInputMask
              type={"text"}
              value={telefone}
              mask={phoneMask()}
              maskChar={null}
              onChange={(e) => setTelefone(e.target.value)}
              autoComplete="new-email"
            />
          </div>
          
          {
            changePassword ? (
              <div className="input-container">
                <label>Senha</label>
                <div className="input-password-container">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    autocomplete="new-password"
                  />
                  {
                    showPassword ? (
                      <AiFillEyeInvisible
                        className="show-icon"
                        onClick={toggleShowPassword}

                        size="19"
                      />
                    ) : (
                      <AiFillEye
                        className="show-icon"
                        onClick={toggleShowPassword}

                        size="19"
                      />
                    )
                  }
                </div>
              </div>
            ) : (
              <div className="input-container">
                <span onClick={() => setChangePassword(true)}>
                  Mudar senha de Usuário
                  <RiLockPasswordFill size={15} />
                </span>
              </div>
            )
          }
          <div className="input-container">
            <label>Cargo</label>
            <select
              value={cargo}
              onChange={(e) => setCargo(e.target.value)}
            >
              <option disabled value="" style={{ fontWeight: "bold" }}>Selecione</option>
              <option value="Master" >Master</option>
              <option value="Usuário" >Usuário</option>
            </select>
          </div>
        </form>
        <div className="send-btn" onClick={requestEditUser}>
          <span>Editar usuário</span>
          <RiSendPlaneFill
            className="send-icon"
            size={11}
          />
        </div>
        <div>
          {
            showResponseMessage ? (
              <span
                className={showErrorMessage ? "error-message-span" : "confirmation-message-span"}
              >
                {responseMessage}
              </span>
            ) : ""
          }
        </div>
      </section>
    </Wrapper>
  );
};

export default DrawerEditarUsuario;
