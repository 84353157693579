import styled from 'styled-components';
import colors from 'styles/colors';

export const WrapperAddBeneficiary = styled.section`
  min-width: 700px;
  max-width: 900px;
  box-sizing: content-box;

  header {
    background-color: ${colors.genoaBlue};
    padding: 1rem;

    .headerAdd {
      .titleHeader {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;

        color: #fff;
        font-size: 1.125rem;

        > svg {
          font-size: 1.75rem;
        }
      }
    }
  }

  main {
    width: 90%;
    padding: 0.5rem;
    margin: 0 auto;

    .select-company {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.25rem;

      select {
        max-width: 70%;
        border: 1px solid ${colors.genoaBlue};
        border-radius: 0.5rem;
        padding: 0.25rem;

        &:hover,
        &:focus {
          outline: none !important;
        }
      }

      & + .select-company {
        margin-top: 1rem;
      }
    }
  }

  .choose-insurance {
    .container-select-insurance {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.75rem;

      padding: 2rem;

      .box-select-insurance {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .btn-action {
          color: #c2c2c2;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          cursor: pointer;
          background: none;
          border: none;

          transition: all 0.4s;
          :hover {
            color: ${colors.genoaBlue};
            span {
              color: ${colors.genoaBlue};
            }
          }

          span {
            color: #3b3838;
          }

          svg {
            width: 1.5rem;
            height: 1.5rem;
            fill: currentColor;
          }
        }

        .select-custom {
          color: ${colors.genoaBlue};
          span {
            color: ${colors.genoaBlue};
          }
        }

        > select {
          max-width: 60%;
          padding: 0.25rem 0.5rem;
          border: 1px solid ${colors.genoaBlue};
          border-radius: 1rem;

          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  .container-select-typeUser {
    > div {
      width: 100%;
      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
      }
    }
  }
`;

export const Titular = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  .gap {
    & + .gap {
      margin-top: 0.5rem;
    }
  }

  .w-100 {
    width: 50%;
  }

  h2 {
    font-size: 1.25rem;
    padding: 0.75rem 0.5rem;
    color: #3b3838;
  }
  .react-datepicker-wrapper {
    width: 100%;
    > div {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .box-content-titular {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 0.8fr;
    gap: 0.75rem;
    align-items: center;
    justify-content: space-evenly;
  }

  input,
  select {
    width: 100%;
    padding: 0.25rem 0.5rem;
    border: 2px solid #c2c2c2;
    border-radius: 0.5rem;
    color: #3b3838;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.4s;

    &::placeholder {
      color: ${colors.genoaBlue};
    }
    &:focus {
      outline: none;
      border: 2px solid ${colors.genoaBlue};
    }

    &:hover {
      outline: none;
    }
  }

  input {
    &:read-only {
      background: #c5c5c5;

      &:focus {
        border: 2px solid #c2c2c2;
      }
    }
  }

  footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    margin-top: 1rem;

    .text-small {
      font-size: 0.75rem;
      color: #777;
    }

    .text-error {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-family: 'Avenir Next GEO W05 Demi';

      > svg {
        color: #d32f2f;
        font-size: 2rem;
      }
    }

    .text-success {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-family: 'Avenir Next GEO W05 Demi';
      > svg {
        color: #388e3c;
        font-size: 2rem;
      }
    }
    .btn-send-infos {
      border: none;
      border-radius: 1rem;
      background: ${colors.genoaBlue};
      padding: 0.5rem 0.75rem;
      font-size: 1rem;
      color: #fff;

      cursor: pointer;
      transition: filter 0.4s;

      &:hover {
        filter: brightness(0.8);
      }
    }
  }

  .btn-more-dependentes {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    background: none;
    border: 1px solid ${colors.genoaBlue};
    border-radius: 0.25rem;
    color: ${colors.genoaBlue};
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-around;

    padding: 0.5rem;
    font-size: 1rem;

    cursor: pointer;
    transition: all 0.4s;

    > svg {
      font-size: 1.25rem;
      font-weight: bold;
    }

    &:hover {
      background: ${colors.genoaBlue};
      color: #fff;
    }
  }
`;

export const Dependente = styled(Titular)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  .gap {
    & + .gap {
      margin-top: 0.5rem;
    }
  }

  .w-100 {
    width: 50%;
  }

  h2 {
    font-size: 1.25rem;
    padding: 0.75rem 0.5rem;
    color: #3b3838;
  }

  .box-select-titular {
    width: 100%;
  }
`;
