import styled from 'styled-components';
import colors from 'styles/colors';

export const TabelaTasksWrapper = styled.table`
  display: flex;
  flex-direction: column;
  padding-top: 35px;
  padding-left: 35px;
  padding-right: 35px;
  width: 100%;
  row-gap: 10px;

  tr {
    display: grid;
    grid-template-columns: 40px 4fr 1fr 3.5fr 1fr 3fr 100px 1fr 40px;
    column-gap: 20px;
    padding-left: 25px;
    padding-right: 25px;
  }

  thead {
    tr {
      text-align: left;
      font-size: 0.85em;
    }
    
  }

  tbody {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    tr {
      font-size: 0.8em;
      padding-top: 10px;
      padding-bottom: 10px;
      border: 3px solid ${colors.genoaBlue};
      border-radius: 42px;
      height: 38px;
      background-color: ${colors.white};
    }
    
    td {
      display: flex;
      align-items: center;
      line-height: 14px;


      .details_button {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        padding: 4px;
        font-size: 1.25rem;
        color: ${colors.genoaDarkBlue};
        transition: 0.1s ease-in;
        border-radius: 50%;

        :hover {
          background: rgb(204 204 204 / 25%);
        }
      }
    }
`