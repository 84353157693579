import styled from 'styled-components';
import colors from 'styles/colors';

export const DrawerRenMesmaCiaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  border-left: 10px solid ${colors.genoaBlue};
  border-radius: 10px;
  background-color: ${colors.white};
  width: 600px;
  position: fixed;
  left: calc(60% - 325px);
  padding: 25px;

  animation: up-down 0.2s forwards;

  @keyframes up-down {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: calc(50% - 200px);
    }
  }

  .title {
    font-family: Avenir Next Geo W05 Heavy;
    font-size: 1.2em;
  }

  .input_reajuste_container {
    display: grid;
    grid-template-columns: 1fr 1fr minmax(0, max-content) 1fr minmax(0, max-content) 1fr;
    column-gap: 10px;

    label {
      font-size: 0.85em;
      font-family: Avenir Next Geo W05 Demi;
    }

    input {
      width: 100%;
      padding: 4px 6px;
      border-radius: 6px;
      border: 2px solid ${colors.lightGrey};
      outline: none;
    }

    input:focus-visible {
      border-color: ${colors.genoaBlue};
      outline: 1px solid ${colors.genoaBlue};
    }

    .math_symbol {
      font-family: Avenir Next Geo W05 Heavy;
      font-size: 1.1em;
      display: flex;
      align-items: end;
      color: ${colors.genoaGrey};

      span {
        padding-bottom: 4px;
      }
    }

    .input_option {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }
  }

  .input_obs {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    span {
      font-size: 0.85em;
      font-family: Avenir Next Geo W05 Demi;
    }

    textarea {
      height: 50px;
      font-size: 0.8em;
      padding: 10px;
  
      :focus-visible {
        outline: 1px ${colors.genoaBlue} auto;
      }
    }
  }

  .send_reajuste_btn {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    background-color: ${colors.genoaBlue};
    color: ${colors.white};
    padding: 4px 8px;
    font-family: Avenir Next Geo W05 Demi;
    text-transform: uppercase;
    font-size: 0.9em;
    border-radius: 5px;
  }

  .disabled {
    background-color: ${colors.genoaDisabledGrey};
  }
`