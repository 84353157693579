import styled from 'styled-components';

export const Container = styled.main`
  margin-top: 7.5rem;

  .welcome-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 3.4375rem;

    padding-left: 6.4375rem;

    .welcome-container {
      flex: 4;
      .welcome-title {
        display: flex;
        flex-direction: row;
        align-items: center;

        h1 {
          font-size: 4.375rem;
          line-height: 5.9375rem; 
          font-family: "Avenir Next GEO W05 Heavy";
          color: #3B3838;
        }

        .zeca-img {
          width: 347px;
          height: 89px; 
        }        
      }

      p {
        margin-top: 2.5rem;
        max-width: 677px;

        font-size: 1.5625rem;
        line-height: 2.125rem;
        color: #000;
      }

      button {
        margin-top: 48px;

        background: #62A0E2;
        border-radius: 0.9375rem;
        border: none;
        padding: 0.9375rem 3.5rem;
        
        font-size: 1.25rem;
        font-family: "Avenir Next GEO W05 Heavy";
        color: #fff;

        cursor: pointer;

        transition: 0.2s;

        &:hover {
          filter: brightness(0.8);
        }
      }
    }

    .welcome-img{
      flex: 3;
      img{width:100%}

    }
  }

  .info-1-section {
    margin-top: 11.6875rem;
    padding-left: 10rem;
    padding-right:  10rem;

    h2 {
      font-size: 3.125rem;
      line-height: 4.25rem;
      color: #3B3838;
    }

    .info-1-container {
      display: flex;
      justify-content: center;

      margin-top: 11rem;

      .building-img {
        width: 268px;
        height: 254px;
        margin-right: 5.6875rem;
      }

      .info-1-text-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        max-width: 660px;

      }
    }
  }

  .info-2-section {
    display: flex;
    justify-content: center;

    padding-left: 10rem;
    padding-right:  10rem;

    margin-top: 15.5rem;

    .info-2-text-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      
      max-width: 813px;

      text-align: justify;
    }

    .talk-balloons-img {
      width: 399px;
      height: 312px;
      margin-left: 12.1875rem;
    }
  }

  .info-3-section {
    display: flex;
    justify-content: center;
    
    padding-left: 10rem;
    padding-right:  10rem;
    
    margin-top: 13rem;

   

    .info-3-img{
      flex: 3;
      .tickets-img {
        width: 100%;

      filter: drop-shadow(0px 25px 25px rgba(0, 0, 0, 0.25));

      margin-right: 7.1875rem;
    }
    }

    .info-3-text-container {
      flex: 4;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-left: 70px;
      max-width: 735px;
    }
  }

  .info-4-section {
    display: flex;
    
    padding-left: 10rem;
    padding-right:  10rem;
    
    margin-top: 14.875rem;

    .info-4-text-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      max-width: 912px;

    }

    .baseball-player-img {
      width: 422px;
      height: 320px;
      margin-left: 10.25rem;
    }
  }

  .info-5-section {
    background: #F9F9F9;

    padding: 5.8125rem 10rem 12.6875rem 10rem;

    
    margin-top: 13.25rem;

    .info-5-container {
      display: flex;

      .info-5-text-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        max-width: 966px;

      }

      & + .info-5-container {
        margin-top: 9.875rem;
      }

      .mail-sent-img {
        width: 187px;
        height: 168px;
        margin-left: 13.9375rem;
      }

      .attached-file-img {
        width: 270px;
        height: 206px;
        margin-left: 13.9375rem;
      }
    }
  }

  @media (max-width: 1800px) {
    .people-working-img {
     // display: none;
    }

    /* .welcome-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
    }

    .welcome-section {
      flex-direction: column;
    } */

    /* .info-3-section {
      flex-direction: column;
      align-items: center;

      div {
        order: 1;
      }

      .tickets-img {
        order: 2;
        margin: 4rem 0 0;
      }
    } */
  }

  @media(max-width: 1440px) {
    .info-2-section, 
    .info-4-section,
    .info-5-section .info-5-container {
      flex-direction: column;
      align-items: center;
    }

    .info-2-section .talk-balloons-img {
      width: 239.4px;
      height: 187.2px;
    }

    .info-4-section .baseball-player-img {
      width: 337.6px;
      height: 256px;
    }

    .talk-balloons-img,
    .mail-sent-img,
    .baseball-player-img,
    .attached-file-img {
      margin-top: 4rem;
      margin-left: 0 !important;
    }
  }

  @media(max-width: 1240px) {
    .info-1-section {
      text-align: center;

      .info-1-container {
        flex-direction: column;
        align-items: center;

        .building-img {
          margin: 0 0 5rem;
        }
      }
    }
  }

  @media(max-width: 1024px) {

    .welcome-section {
      flex-direction: column;
    } 
    .welcome-img{
     margin-top: 50px;

    }

    .welcome-title {
      flex-direction: column !important;
    }

    .zeca-img {
      margin-top: 3rem;
    }

    .info-3-section {
      flex-direction: column-reverse;


      .info-3-text-container {
      
        padding-left: 0px;
        margin-bottom: 100px;
       
      }
    }

    .welcome-section,
    .info-1-section,
    .info-2-section,
    .info-3-section,
    .info-4-section,
    .info-5-section {
      padding-left: 3.4375rem;
    }

    .info-1-section,
    .info-2-section,
    .info-3-section,
    .info-4-section,
    .info-5-section {
      margin-top: 7.2rem;
    }

    .info-1-section .info-1-container {
      margin-top: 6.4rem;
    }

    .info-5-section {
      padding-bottom: 5.8125rem;

      .info-5-container + .info-5-container {
        margin-top: 7rem;
      }
    }
  }

  @media(max-width: 732px) {
    .welcome-title h1 {  
      font-size: 3.675rem !important;
      line-height: 4.9875rem !important; 
    }

    .info-1-section h2 {
      font-size: 2.625rem;
      line-height: 3.57rem;
    }

    p {
      font-size: 1.3125rem;
      line-height: 1.785rem;
    }

    .zeca-img {
      width: 270px !important;
      height: 68px !important; 
    }
  }

  @media(max-width: 832px) {
    .info-3-section .tickets-img {
      height: 197.4px;
      width: 532.7px;
    }
  }

  @media(max-width: 640px) {
    .welcome-title h1 {  
      font-size: 2.95rem !important;
      line-height: 4rem !important; 
    }

    .info-1-section h2 {
      font-size: 2.125rem;
      line-height: 2.85rem;
    }

    p {
      font-size: 1rem;
      line-height: 1.4rem;
    }

    .building-img {
      width: 187.6px;
      height: 177.8px;
    }

    .info-3-section .tickets-img {
      height: 157.92px;
      width: 426.16px;
    }

    .info-5-section .info-5-container {
      .mail-sent-img {
        width: 149.6px;
        height: 134.4px;
      }

      .attached-file-img {
        width: 216px;
        height: 164.8px;
      }
    }
  }

  @media(max-width: 460px) {
    .welcome-title h1 {  
      font-size: 2rem !important;
      line-height: 3.125rem !important; 
    }

    .zeca-img {
      width: 202.5px !important;
      height: 51px !important; 
      margin-top: 2.2rem;
    }

    .info-1-section .info-1-container .building-img {
      width: 187.6px;
      height: 177.8px;
    }

    .info-3-section .tickets-img {
      height: 125px;
      width: 338px;
    }

    .info-4-section .baseball-player-img {
      width: 253.2px;
      height: 192px;
    }
  }
`;

export const InfoTitle = styled.strong`
  font-size: 3rem;
  font-family: "Avenir Next GEO W05 Heavy";
  line-height: 4.0625rem;
  
  color: #3B3838;
  
  text-align: left;

  @media(max-width: 732px) {
    font-size: 2.5rem;
    line-height: 3.5125rem;
  }

  @media(max-width: 640px) {
    font-size: 2rem;
    line-height: 2.8rem;
    text-align: left;
  }

  @media(max-width: 454px) {
    font-size: 1.4rem;
    line-height: 2rem;
  }
`;

export const InfoText = styled.p`
  margin-top: 4rem;

  font-size: 1.5625rem;
  
  line-height: 2.125rem;
  
  text-align: justify;

  @media(max-width: 1024px) {
    margin-top: 3rem;
  }

  @media(max-width: 640px) {
    text-align: left;
  }
`;

export const Demonstracao = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 5rem 8rem;

  strong {
    text-align: center !important;
  }

  button {
    margin-top: 4.9375rem;
    padding: 0.9375rem 4.125rem;
    
    border: none;
    border-radius: 0.9375rem;

    font-size: 2.25rem;
    color: #fff;

    background: linear-gradient(180deg, #6E43C3 0%, #6CD3FF 100%);

    cursor: pointer;

    transition: 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }

  @media(max-width: 732px) {
    button {
      font-size: 2rem;
      padding: 0.9375rem 3.5rem;
    }
  }

  @media(max-width: 454px) {
    button {
      font-size: 1.25rem;
    }
  }
`;

export const Message = styled.span`
  color: ${(props) => props.color};
  font-size: 1.125rem;
  font-family: "Avenir Next GEO W05 Demi";
`;
