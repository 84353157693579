import {USER_ACTIONS} from '../constants/index'
import api from 'services/api.js'

const INITIAL_STATE = {
    user: {
        id: '',
        token: '',
        exp: '',
        iat: '',
        nome: '',
        email: '',
        estipulante: '',
        corretora: '',
        user_id: '',
        usuario_id: undefined
    },
    acessedPages: {
        tickets: false,
        movimentacoes: false,
    }
}

const userReducers = (state = INITIAL_STATE, action) => {

//   console.log(action.user)

    if (action.type === USER_ACTIONS.SET_USER) {
        state.user = action.user;
        if (action.user) {
            api.defaults.headers.common["authorization"] = `Bearer ${action.user.token}`;
            return { ...state };
        } else {
            delete api.defaults.headers.common["authorization"];
        }
    }

    if(action.type === USER_ACTIONS.ATT_USER){
        return { ...state, user: action.payload }
    }

    if (action.type === USER_ACTIONS.ATT_ACESSED_PAGES) {
        return { ...state, acessedPages: action.payload }
    }

    if (action.type === USER_ACTIONS.RESET_ACESSED_PAGES) {
        return { ...state, acessedPages: INITIAL_STATE.acessedPages }
    }

    return state
}

export { userReducers }