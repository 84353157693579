import { useState } from 'react';
import { FaUserFriends, FaPlus } from 'react-icons/fa';
import { BiPlusCircle } from 'react-icons/bi';
import { ReactComponent as IconTooth } from 'assets/imgs/svg/teeth.svg';
import { ImUserPlus } from 'react-icons/im';
import { Button } from '@material-ui/core';
import TitularForm from '../TitularForm';
import MoreRelativesForm from '../RelativesForms/MoreRelativesForm';
import FilterTitular from '../FilterTitular';
import RelativeForm from '../RelativesForms';
import { ContainerOptions, Div } from './styles';

const MovimentInclusion = ({
  planoSaudeSelected,
  setPlanoSaudeSelected,
  titularDependente,
  setTitularDependente,
  tipoPlanoSaudeSelected,
  setTipoPlanoSaudeSelected,
  planoOdontoSelected,
  setPlanoOdontoSelected,
  tipoPlanoOdontoSelected,
  setTipoPlanoOdontoSelected,
  selectedSubEstipulante,
  allSubEstipulantes,
  beneficiarioOption,
  setBeneficiarioOption,
  selectedEstipulante,
  confirmationMessage,
  beneficiarioJaExistente,
  formIncompleto,
  setFormIncompleto,
  errorMessageTimeout,
  clearErrorMessageTimeout,
  formatNomesTitulares,
  postNewBeneficiario
}) => {

  const [beneficiarioNome, setBeneficiarioNome] = useState('');
  const [cpf, setCpf] = useState();
  const [beneficiaryBirthDate, setBeneficiaryBirthDate] = useState('');
  const [dataVinculo, setDataVinculo] = useState();
  const [numeroDeCarteirinha, setNumeroDeCarteirinha] = useState('');
  const [dataInicio, setDataInicio] = useState('');
  const [grauParentesco, setGrauParentesco] = useState('');
  const [count, setCount] = useState(0);
  const [isMoreDependents, setIsMoreDependents] = useState([]);
  const [nomeDependentesDoTitular, setNomeDependentesDoTitular] = useState({});
  const [cpfDependentesDoTitular, setCpfDependentesDoTitular] = useState({});
  const [dataNascimentoAllDependentes, setDataNascimentoAllDependentes] =
    useState({});
  const [vinculoDependenteWithTitular, setVinculoDependenteWithTitular] =
    useState({});
  const [dataVinculoAllDependentes, setDataVinculoAllDependentes] = useState(
    {}
  );
  const [nCarteirinhaAllDependentes, setNCarteirinhaAllDependentes] = useState(
    {}
  );
  const [dateStartPlanAllDependentes, setDateStartPlanAllDependentes] =
    useState({});

  const planoSaudeClickHandler = (contratoSaude) => {
    setPlanoSaudeSelected((prevState) => {
      if (prevState === contratoSaude.id) {
        return '';
      }
      return contratoSaude.id;
    });
    // Timeout devido a animação de transição
    const isContratoSaudeTitular = titularDependente.contratos?.some(
      (contrato) => contrato.contrato_id === contratoSaude.id
    );
    if (!isContratoSaudeTitular) {
      setTitularDependente('');
    }
    setTimeout(() => setTipoPlanoSaudeSelected(''), 200);
  };

  const planoOdontoClickHandler = (contratoOdonto) => {
    setPlanoOdontoSelected((prevState) => {
      if (prevState === contratoOdonto.id) {
        return '';
      }
      return contratoOdonto.id;
    });
    const isContratoOdontoTitular = titularDependente.contratos?.some(
      (contrato) => contrato.contrato_id === contratoOdonto.id
    );
    if (!isContratoOdontoTitular) {
      setTitularDependente('');
    }
    // Timeout devido a animação de transição
    setTimeout(() => setTipoPlanoOdontoSelected(''), 200);
  };

  const createArrayDependentesAdicionais = () => {
    let arrAllInfosDependents = [];

    Object.entries(nomeDependentesDoTitular).forEach((item) => {
      arrAllInfosDependents.push({
        identification: item[0],
        nome: item[1]
      });
    });

    Object.entries(cpfDependentesDoTitular).forEach((vinculo) => {
      arrAllInfosDependents.forEach((eachItem, index) => {
        if (vinculo[0] === eachItem.identification) {
          eachItem = {
            ...eachItem,
            cpf: vinculo[1]
          };
          arrAllInfosDependents[index] = eachItem;
        }
      });
    });

    Object.entries(vinculoDependenteWithTitular).forEach((vinculo) => {
      arrAllInfosDependents.forEach((eachItem, index) => {
        if (vinculo[0] === eachItem.identification) {
          eachItem = {
            ...eachItem,
            vinculo: vinculo[1]
          };
          arrAllInfosDependents[index] = eachItem;
        }
      });
    });

    Object.entries(dataNascimentoAllDependentes).forEach((vinculo) => {
      arrAllInfosDependents.forEach((eachItem, index) => {
        if (vinculo[0] === eachItem.identification) {
          eachItem = {
            ...eachItem,
            dataNascimento: vinculo[1]
          };
          arrAllInfosDependents[index] = eachItem;
        }
      });
    });

    Object.entries(dataVinculoAllDependentes).forEach((vinculo) => {
      arrAllInfosDependents.forEach((eachItem, index) => {
        if (vinculo[0] === eachItem.identification) {
          eachItem = {
            ...eachItem,
            dataVinculo: vinculo[1]
          };
          arrAllInfosDependents[index] = eachItem;
        }
      });
    });

    Object.entries(nCarteirinhaAllDependentes).forEach((vinculo) => {
      arrAllInfosDependents.forEach((eachItem, index) => {
        if (vinculo[0] === eachItem.identification) {
          eachItem = {
            ...eachItem,
            numeroDeCarteirinha: vinculo[1]
          };
          arrAllInfosDependents[index] = eachItem;
        }
      });
    });

    Object.entries(dateStartPlanAllDependentes).forEach((vinculo) => {
      arrAllInfosDependents.forEach((eachItem, index) => {
        if (vinculo[0] === eachItem.identification) {
          eachItem = {
            ...eachItem,
            inicioPlano: vinculo[1]
          };
          arrAllInfosDependents[index] = eachItem;
        }
      });
    });

    const dependentesAdicionaisFiltrados = arrAllInfosDependents.filter(
      (item) => {
        const isNotDeleted = isMoreDependents.some(
          (dependente) => dependente.identification === item.identification
        );
        if (isNotDeleted) {
          return true;
        }
        return false;
      }
    );

    return dependentesAdicionaisFiltrados;
  };

  const validateDependentesAdicionais = (dependentesAdicionais) => {
    let dependentesValidos = true;

    dependentesAdicionais.forEach((dependente) => {
      const { nome, cpf, dataNascimento, dataVinculo, vinculo } = dependente;
      if (!nome || !cpf || !dataNascimento || !dataVinculo || !vinculo) {
        return (dependentesValidos = false);
      }
    });

    return dependentesValidos;
  };

  const filterSubEstipulante = () => {
    if (selectedSubEstipulante) {
      const subEstipulante = allSubEstipulantes.filter(
        (subEstipulante) => subEstipulante.id === Number(selectedSubEstipulante)
      );
      return subEstipulante;
    }
    return [];
  };

  const cadastroTitularClickHandler = async () => {
    const dependentesAdicionais = createArrayDependentesAdicionais();
    const dependentesAdicionaisValidos = validateDependentesAdicionais(
      dependentesAdicionais
    );

    const form = {
      tipo: beneficiarioOption,
      nome: beneficiarioNome,
      cpf,
      dataNascimento: beneficiaryBirthDate,
      dataVinculo,
      numeroDeCarteirinha,
      dataInicio,
      estipulante: selectedEstipulante,
      subEstipulante: filterSubEstipulante(),
      dependentes: dependentesAdicionais,
      seguro: {
        saude: {
          planoSaudeSelected,
          tipoPlanoSaudeSelected
        },
        odonto: {
          planoOdontoSelected,
          tipoPlanoOdontoSelected
        }
      }
    };

    if (
      beneficiarioNome &&
      cpf &&
      beneficiaryBirthDate &&
      dataVinculo &&
      selectedEstipulante &&
      (tipoPlanoSaudeSelected || tipoPlanoOdontoSelected) &&
      dependentesAdicionaisValidos
    ) {
      postNewBeneficiario({ form });
      setFormIncompleto(false);
      setBeneficiarioNome('');
      setCpf('');
      setBeneficiaryBirthDate('');
      setDataVinculo('');
      setDataInicio('');
      setNumeroDeCarteirinha('');
      setIsMoreDependents([]);
      setTipoPlanoSaudeSelected('');
      setTipoPlanoOdontoSelected('');
    } else {
      setFormIncompleto(true);
      await errorMessageTimeout();
      clearErrorMessageTimeout();
    }
  };

  const cadastroDependenteClickHandler = async () => {
    const dependentesAdicionais = createArrayDependentesAdicionais();
    const dependentesAdicionaisValidos = validateDependentesAdicionais(
      dependentesAdicionais
    );

    const form = {
      tipo: beneficiarioOption,
      nome: beneficiarioNome,
      cpf,
      dataNascimento: beneficiaryBirthDate,
      dataVinculo,
      numeroDeCarteirinha,
      dataInicio,
      grauParentesco,
      titular: titularDependente,
      estipulante: selectedEstipulante,
      subEstipulante: filterSubEstipulante(),
      dependentes: dependentesAdicionais,
      seguro: {
        saude: {
          planoSaudeSelected,
          tipoPlanoSaudeSelected
        },
        odonto: {
          planoOdontoSelected,
          tipoPlanoOdontoSelected
        }
      }
    };

    if (
      beneficiarioNome &&
      cpf &&
      beneficiaryBirthDate &&
      dataVinculo &&
      grauParentesco &&
      titularDependente &&
      (tipoPlanoSaudeSelected || tipoPlanoOdontoSelected) &&
      dependentesAdicionaisValidos
    ) {
      postNewBeneficiario({ form });
      setFormIncompleto(false);
      setBeneficiarioNome('');
      setCpf('');
      setBeneficiaryBirthDate('');
      setDataVinculo('');
      setDataInicio('');
      setNumeroDeCarteirinha('');
      setIsMoreDependents([]);
      setTipoPlanoSaudeSelected('');
      setTipoPlanoOdontoSelected('');
    } else {
      setFormIncompleto(true);
      await errorMessageTimeout();
      clearErrorMessageTimeout();
    }
  };

  const showMoreDependentsClickHandler = () => {
    if (isMoreDependents.length <= 4) {
      setCount((prevState) => prevState + 1);
    }
    if (isMoreDependents.length > 4) {
      return;
    }

    setIsMoreDependents((prevState) => [
      ...prevState,
      {
        identification: `Dependente_${count}`
      }
    ]);
  };

  const renderMensagemDeConfirmacaoInclusao = () => {
    return (
      <div className="confirmation-message">
        {confirmationMessage ? (
          <span>O Beneficiário foi adicionado com Sucesso!</span>
        ) : (
          ''
        )}
        {beneficiarioJaExistente ? (
          <span>Beneficiário com CPF já cadastrado!</span>
        ) : (
          ''
        )}
        {formIncompleto ? (
          <span className="error-message">
            A escolha de um Plano e os campos com * são obrigatórios
          </span>
        ) : (
          ''
        )}
      </div>
    );
  };

  const removerDependenteClickHandler = (identification) => {
    const isMoreDependentsFiltered = isMoreDependents.filter(
      (item) => item.identification !== identification
    );

    const stateWithoutItem = (prevState) => ({
      ...prevState,
      [identification]: ''
    });
    setNomeDependentesDoTitular(stateWithoutItem);
    setCpfDependentesDoTitular(stateWithoutItem);
    setDataNascimentoAllDependentes(stateWithoutItem);
    setVinculoDependenteWithTitular(stateWithoutItem);
    setDataVinculoAllDependentes(stateWithoutItem);
    setNCarteirinhaAllDependentes(stateWithoutItem);
    setDateStartPlanAllDependentes(stateWithoutItem);
    setIsMoreDependents(isMoreDependentsFiltered);
  };

  const selectTipoPlanoOdontoClickHandler = ({ target }) => {
    setTipoPlanoOdontoSelected(target.value);

    const isContratoOdontoTitular = titularDependente.contratos?.some(
      (contrato) =>
        contrato.contrato_id === planoOdontoSelected &&
        contrato.tipo_plano === target.value
    );

    if (!isContratoOdontoTitular) {
      setTitularDependente('');
    }
  };

  const selectTipoPlanoSaudeClickHandler = ({ target }) => {
    setTipoPlanoSaudeSelected(target.value);

    const isContratoSaudeTitular = titularDependente.contratos?.some(
      (contrato) =>
        contrato.contrato_id === planoSaudeSelected &&
        contrato.tipo_plano === target.value
    );

    if (!isContratoSaudeTitular) {
      setTitularDependente('');
    }
  };

  const renderPlanosOptions = () => {
    let count = 0;
    if (selectedEstipulante && selectedEstipulante.contratos) {
      if (selectedEstipulante.contratos.saude) {
        selectedEstipulante.contratos.saude.map((item) =>
          item.status === 'Ativo' ? (count += 1) : count
        );
      }
      if (selectedEstipulante.contratos.odonto) {
        selectedEstipulante.contratos.odonto.map((item) =>
          item.status === 'Ativo' ? (count += 1) : count
        );
      }
    }
    return (
      <ContainerOptions style={{ height: `${count * 50}px` }}>
        {selectedEstipulante && selectedEstipulante.contratos ? (
          <>
            {selectedEstipulante?.contratos?.saude ? (
              <>
                {selectedEstipulante?.contratos?.saude?.map((contratoSaude) => {
                  if (contratoSaude?.status === 'Ativo') {
                    return (
                      <div
                        className={
                          planoSaudeSelected === contratoSaude.id
                            ? 'planos-options-container-selected'
                            : 'planos-options-container'
                        }
                      >
                        <BiPlusCircle
                          className="plano-option-icon"
                          onClick={() => planoSaudeClickHandler(contratoSaude)}
                        />
                        <span
                          onClick={() => planoSaudeClickHandler(contratoSaude)}
                        >
                          {contratoSaude.nome_completo}
                        </span>
                        {contratoSaude?.numero_apolice && <span>Apólice:</span>}
                        {contratoSaude?.numero_apolice && <span>{contratoSaude?.numero_apolice}</span>}
                        <div className="plano-option-select">
                          <select
                            value={tipoPlanoSaudeSelected}
                            onChange={selectTipoPlanoSaudeClickHandler}
                          >
                            <option
                              value=""
                              disabled
                              style={{ fontWeight: 'bold' }}
                            >
                              Selecione um Plano
                            </option>
                            {contratoSaude.contrato_tipos.map((item) => (
                              <option value={item.tipo} key={item.id}>
                                {item.tipo}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    );
                  }
                  return '';
                })}
              </>
            ) : (
              ''
            )}
            {selectedEstipulante?.contratos?.odonto ? (
              <>
                {selectedEstipulante?.contratos?.odonto?.map(
                  (contratoOdonto) => {
                    if (contratoOdonto?.status === 'Ativo') {
                      return (
                        <div
                          className={
                            planoOdontoSelected === contratoOdonto.id
                              ? 'planos-options-container-selected'
                              : 'planos-options-container'
                          }
                        >
                          <IconTooth
                            className="plano-option-icon"
                            onClick={() =>
                              planoOdontoClickHandler(contratoOdonto)
                            }
                          />
                          <span
                            onClick={() =>
                              planoOdontoClickHandler(contratoOdonto)
                            }
                          >
                            {contratoOdonto.nome_completo}
                          </span>
                          {contratoOdonto?.numero_apolice && <span>Apólice:</span>}
                          {contratoOdonto?.numero_apolice && <span>{contratoOdonto?.numero_apolice}</span>}
                          <div className="plano-option-select">
                            <select
                              value={tipoPlanoOdontoSelected}
                              onChange={selectTipoPlanoOdontoClickHandler}
                            >
                              <option
                                value=""
                                disabled
                                style={{ fontWeight: 'bold' }}
                              >
                                Selecione um Plano
                              </option>
                              {contratoOdonto.contrato_tipos.map((item) => (
                                <option value={item.tipo} key={item.tipo}>
                                  {item.tipo}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      );
                    }
                  }
                )}
              </>
            ) : (
              ''
            )}
          </>
        ) : (
          ''
        )}
      </ContainerOptions>
    );
  };

  const renderMoreRelativesForm = () => {
    return (
      <>
        <div
          className="titular-dependente-form-container"
          style={{ height: `${isMoreDependents.length * 275}px` }}
        >
          {isMoreDependents?.map((item) => (
            <MoreRelativesForm
              dependente={item}
              nomeDependentesDoTitular={nomeDependentesDoTitular}
              setNomeDependentesDoTitular={setNomeDependentesDoTitular}
              cpfDependentesDoTitular={cpfDependentesDoTitular}
              setCpfDependentesDoTitular={setCpfDependentesDoTitular}
              dataNascimentoAllDependentes={dataNascimentoAllDependentes}
              setDataNascimentoAllDependentes={setDataNascimentoAllDependentes}
              vinculoDependenteWithTitular={vinculoDependenteWithTitular}
              setVinculoDependenteWithTitular={setVinculoDependenteWithTitular}
              dataVinculoAllDependentes={dataVinculoAllDependentes}
              setDataVinculoAllDependentes={setDataVinculoAllDependentes}
              nCarteirinhaAllDependentes={nCarteirinhaAllDependentes}
              setNCarteirinhaAllDependentes={setNCarteirinhaAllDependentes}
              dateStartPlanAllDependentes={dateStartPlanAllDependentes}
              setDateStartPlanAllDependentes={setDateStartPlanAllDependentes}
              removerDependenteClickHandler={removerDependenteClickHandler}
            />
          ))}
        </div>
        <div className="error-message-container">
          <div>
            <FaPlus size={12} />
            <span onClick={showMoreDependentsClickHandler}>
              Adicionar Dependente
            </span>
          </div>
        </div>
      </>
    );
  };

  return (
    <Div>
      {renderPlanosOptions()}
      <div className="form-beneficiario-container">
        <div className="beneficiario-option-container">
          <label
            className={
              beneficiarioOption === 'Titular'
                ? 'checkbox-label-selected'
                : 'checkbox-label'
            }
            onClick={() => setBeneficiarioOption('Titular')}
          >
            <ImUserPlus />
            <span>Titular</span>
          </label>
          <label
            className={
              beneficiarioOption === 'Dependente'
                ? 'checkbox-label-selected'
                : 'checkbox-label'
            }
            onClick={() => setBeneficiarioOption('Dependente')}
          >
            <FaUserFriends size={21} />
            <span>Dependente</span>
          </label>
        </div>
      </div>
      <div className="form-beneficiario-container">
        <form className="form-beneficiario">
          {beneficiarioOption === 'Titular' ? (
            <div>
              <TitularForm
                beneficiarioNome={beneficiarioNome}
                setBeneficiarioNome={setBeneficiarioNome}
                cpf={cpf}
                setCpf={setCpf}
                beneficiaryBirthDate={beneficiaryBirthDate}
                setBeneficiaryBirthDate={setBeneficiaryBirthDate}
                dataVinculo={dataVinculo}
                setDataVinculo={setDataVinculo}
                numeroDeCarteirinha={numeroDeCarteirinha}
                setNumeroDeCarteirinha={setNumeroDeCarteirinha}
                dataInicio={dataInicio}
                setDataInicio={setDataInicio}
              />
              {renderMoreRelativesForm()}
              <div className="send-btn-container">
                <Button
                  className="send-btn"
                  variant="contained"
                  onClick={cadastroTitularClickHandler}
                >
                  Cadastrar{' '}
                  {isMoreDependents.length > 0
                    ? 'Beneficiários'
                    : 'Beneficiário'}
                </Button>
              </div>
              {renderMensagemDeConfirmacaoInclusao()}
            </div>
          ) : (
            <div>
              <FilterTitular
                selectedEstipulante={selectedEstipulante}
                planoSaudeSelected={planoSaudeSelected}
                setPlanoSaudeSelected={setPlanoSaudeSelected}
                tipoPlanoSaudeSelected={tipoPlanoSaudeSelected}
                setTipoPlanoSaudeSelected={setTipoPlanoSaudeSelected}
                planoOdontoSelected={planoOdontoSelected}
                setPlanoOdontoSelected={setPlanoOdontoSelected}
                tipoPlanoOdontoSelected={tipoPlanoOdontoSelected}
                setTipoPlanoOdontoSelected={setTipoPlanoOdontoSelected}
                selectedSubEstipulante={selectedSubEstipulante}
                titularDependente={titularDependente}
                setTitularDependente={setTitularDependente}
                formatNomesTitulares={formatNomesTitulares}
              />

              <RelativeForm
                beneficiarioNome={beneficiarioNome}
                setBeneficiarioNome={setBeneficiarioNome}
                cpf={cpf}
                setCpf={setCpf}
                beneficiaryBirthDate={beneficiaryBirthDate}
                setBeneficiaryBirthDate={setBeneficiaryBirthDate}
                grauParentesco={grauParentesco}
                setGrauParentesco={setGrauParentesco}
                dataVinculo={dataVinculo}
                setDataVinculo={setDataVinculo}
                dataInicio={dataInicio}
                setDataInicio={setDataInicio}
                numeroDeCarteirinha={numeroDeCarteirinha}
                setNumeroDeCarteirinha={setNumeroDeCarteirinha}
              />
              {renderMoreRelativesForm()}
              <div className="send-btn-container">
                <Button
                  className="send-btn"
                  variant="contained"
                  onClick={cadastroDependenteClickHandler}
                >
                  Cadastrar{' '}
                  {isMoreDependents.length > 0
                    ? 'Beneficiários'
                    : 'Beneficiário'}
                </Button>
              </div>
              {renderMensagemDeConfirmacaoInclusao()}
            </div>
          )}
        </form>
      </div>
    </Div>
  );
};

export default MovimentInclusion;
