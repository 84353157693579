import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { useFormContext, Controller } from 'react-hook-form';
import validations from './input.validations';
import './styles.scss';

const masks = {
  cpf: '999.999.999-99',
  phone: '(99) 99999-9999',
  cep: '99999-999',
  date: '99/99/9999',
  pis: '999.99999.99-9'
};

const Input = ({
  label,
  validation,
  mask,
  isRequired,
  name,
  isDisabled,
  setIsFixed,
  ...props
}) => {
  const {
    control,
    getValues,
    formState: { errors, touchedFields }
  } = useFormContext();
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFixed(true);
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const hasValue = getValues(name) && getValues(name).trim() !== '';
  const shouldMoveLabel = isFocused || hasValue;
  const isInvalid = touchedFields[name] && errors[name];

  return (
    <div
      className={`field__group ${isInvalid ? 'field__group--invalid' : ''} ${
        isDisabled ? 'field__group--disabled' : ''
      }`}
    >
      <label htmlFor={props.id || name} className={`field__label`}>
        {label}
      </label>
      <Controller
        name={name}
        control={control}
        rules={{
          required: { value: isRequired, message: 'Campo obrigatório' },
          validate: validations[validation] || undefined
        }}
        render={({ field }) => (
          <InputMask
            {...field}
            {...props}
            mask={masks[mask]}
            onFocus={handleFocus}
            onBlur={(e) => {
              field.onBlur();
              handleBlur();
            }}
            className={`field__input  ${
              isInvalid ? `field__input--invalid` : ''
            }`}
          />
        )}
      />
      {isInvalid && (
        <div className={`field__input--error-message`}>
          {errors[name]?.message}
        </div>
      )}
    </div>
  );
};

export default Input;
