import styled from "styled-components";
import colors from "styles/colors";

export const RelatorioContentContainer = styled.div`
  background-color: ${colors.genoaLightBackgroundGrey};
  // min-height: 650px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  
  .first_row {
    display: grid;
    grid-template-columns: 2fr 1fr;
    column-gap: 10px;
    height: 270px;
  }

  .fourth_row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    height: 300px;

  }


  .graphs_column {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    background-color: white;
    
    .graphs_container {
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      row-gap: 40px;

    }
  }


`

export const ConciergeTooltip = styled.div`
  border: none;
  outline: none;

  .tooltip-card{
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    padding: 10px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

    .tooltip-info-container {
      display: flex;
      column-gap: 10px;
      font-size: 11px;
      
      .tooltip-info-title {
        font-family: "Avenir Next GEO W05 Bold";
        color: ${colors.genoaGrey};
      }

      .tooltip-info-value-important {
        font-family: "Avenir Next GEO W05 Bold";
        color: ${colors.genoaBlue};
      }

      .tooltip-info-value {
        font-family: "Avenir Next GEO W05 Regular";
        color: ${colors.genoaGrey};
      }
    }
  }
`

