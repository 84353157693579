import './style.scss';

const Select = (props) => {
  const { label, variant = 'broker' } = props;

  return (
    <div className="ds-select">
      <label className={`ds-select__label ${props.classNameLabel}`} htmlFor={props.id}>
        {label}
      </label>

      <select className={`ds-select__select ${props.classNameSelect} ds-select__select--${variant}`} {...props}>
        <option value="0">Selecione</option>

        {props.children}
      </select>
    </div>
  );
};

export default Select;
